import type { FC } from 'react'
import React, { useCallback } from 'react'
import { useListSkuVersionsQuery } from '@helloextend/extend-api-rtk-query'
import { DataReactTable } from '@helloextend/merchants-ui'
import { useHistory } from 'react-router'
import { skuVersionsColumns } from './table-config'

type SkuVersionsProps = {
  skuId: string
}

const SkuVersions: FC<SkuVersionsProps> = ({ skuId }) => {
  const history = useHistory()
  const { versions, isLoading } = useListSkuVersionsQuery(skuId, {
    selectFromResult: ({ data, ...rest }) => ({
      // filter out the current/latest version
      versions: data?.items?.slice(0, -1),
      ...rest,
    }),
  })
  const handleRowClick = useCallback(
    (_e, rowData) => {
      history.push(`/admin/premiums/${skuId}/versions/${rowData.version}`)
    },
    [history, skuId],
  )

  return (
    <DataReactTable
      isLoading={isLoading}
      type="versions"
      data={versions ?? []}
      columns={skuVersionsColumns}
      initialState={{
        sortBy: [{ id: 'version', desc: true }],
        pageSize: 10,
      }}
      autoResetPage={false}
      onRowClick={handleRowClick}
    />
  )
}

export { SkuVersions }
