import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { COLOR } from '@helloextend/client-branding'

type Alignment = 'left' | 'right' | 'center'
export type MenuProps = {
  isOpen: boolean
  position?: Alignment
  verticalAlignment?: number
  width?: number
  horizontalOffset?: number
  verticalOffset?: number
  maxHeight?: number | string
}

/**
 * @deprecated Use Zen Menu component set instead: `import { Menu } from '@helloextend/zen'`
 */
const Menu: FC<MenuProps> = ({
  children,
  position = 'center',
  verticalAlignment = 0,
  isOpen,
  horizontalOffset,
  verticalOffset,
  width = 424,
  maxHeight,
}) => {
  return (
    <MenuWrapper>
      {isOpen && (
        <MenuContainer
          position={position}
          positionY={verticalAlignment}
          width={width}
          x={horizontalOffset}
          y={verticalOffset}
          maxHeight={maxHeight}
        >
          {children}
        </MenuContainer>
      )}
    </MenuWrapper>
  )
}

const enterDown = keyframes({
  from: { opacity: 0, top: 0 },
  to: { opacity: 1, top: 'calc(100% + 8px)' },
})

const MenuWrapper = styled.div({
  position: 'relative',
  width: '100%',
})

const MenuContainer = styled.nav<{
  position: Alignment
  positionY?: number
  width?: number
  x?: number
  y?: number
  maxHeight?: number | string
}>(({ position, positionY = 0, width, x, y, maxHeight }) => ({
  animation: `200ms ${enterDown} forwards`,
  background: COLOR.WHITE,
  border: `1px solid ${COLOR.LIGHT_GRAYISH_BLUE_0}`,
  borderRadius: 4,
  boxShadow: '0px 3px 5px 0px rgba(209, 205, 205, 0.5)',
  display: 'block',
  position: 'absolute',
  top: y ?? 'calc(100% + 8px)',
  width,
  zIndex: 100,
  maxHeight: maxHeight ?? 'none',
  overflowY: maxHeight ? 'scroll' : 'visible',
  ...(position === 'left' && {
    left: x ?? 0,
    transform: `translateX(0) translateY(${positionY}%)`,
    justifyContent: 'flex-start',
  }),
  ...(position === 'right' && {
    right: x ?? 0,
    transform: `translateX(0) translateY(${positionY}%)`,
    justifyContent: 'flex-end',
  }),
  ...(position === 'center' && {
    left: '50%',
    transform: `translateX(-50%) translateY(${positionY}%)`,
    justifyContent: 'center',
  }),
}))

export { Menu }
