import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@helloextend/client-branding'
import type { CSSObject } from '@emotion/core'
import { keyframes } from '@emotion/core'

export type Size = 'sm' | 'md' | 'lg'

export const SIZES: Size[] = ['sm', 'md', 'lg']

interface SpinnerProps {
  color?: string
  size?: Size
}

/**
 * @deprecated Use Zen Spinner component instead: `import { Spinner } from '@helloextend/zen'`
 */
const Spinner: FC<SpinnerProps> = ({ color = COLOR.BLACK, size = 'sm' }) => {
  return <div css={[baseStyle, sizeStyles[size], { color }]} className="spinner" />
}

// Styles
// ==================================================================================
const spinnerBorder = keyframes({ to: { transform: 'rotate(360deg)' } })

const baseStyle: CSSObject = {
  display: 'inline-block',
  verticalAlign: 'text-bottom',
  borderStyle: 'solid',
  borderRightColor: 'transparent',
  borderRadius: '50%',
  animation: `${spinnerBorder} .75s linear infinite`,
}

const sizeStyles: Record<Size, CSSObject> = {
  sm: {
    height: 26,
    width: 26,
    borderWidth: 2,
  },
  md: {
    height: 52,
    width: 52,
    borderWidth: 4,
  },
  lg: {
    height: 104,
    width: 104,
    borderWidth: 8,
  },
}

export { Spinner }
