import type { FC, ChangeEvent } from 'react'
import React, { useCallback } from 'react'
import type { AccountLegacy } from '@helloextend/extend-api-rtk-query'
import type { PlatformType } from '@helloextend/extend-api-rtk-query/src/stores/types'
import {
  useLazyGetAccountsListQuery,
  useCreateAccountProvisionMutation,
  useCreateLegacyAccountProvisionMutation,
  useCreateStoreMutation,
} from '@helloextend/extend-api-rtk-query'
import * as Yup from 'yup'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import debounce from 'lodash/debounce'
import {
  Input,
  InputType,
  Button,
  ButtonGroup,
  InlineAlert,
  InlineAlertColor,
  useToaster,
  ToastColor,
  ToastDuration,
  Select,
} from '@helloextend/zen'
import { useFlags } from 'launchdarkly-react-client-sdk'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { SerializedError } from '@reduxjs/toolkit'
import type { Store } from '@helloextend/extend-api-client'
import { LDFlag } from '../../../../../constants/ld-flags'

const platformOptions: { [key in PlatformType]: string } = {
  magento: 'Magento',
  shopify: 'Shopify',
  'big-commerce': 'Big Commerce',
  custom: 'Custom',
}

const validationSchema = (
  isEnterprise: boolean,
): Yup.ObjectSchema<
  object & {
    accountName: string
    organizationName: string | undefined
    accountOwnerFirstName: string
    accountOwnerLastName: string
    accountOwnerEmail: string
    storeUrl: string | undefined
    platform: PlatformType | undefined
  },
  object
> => {
  return Yup.object()
    .shape({
      isEnterprise: Yup.boolean().optional().default(isEnterprise),
      accountName: Yup.string().required('Please enter your account name'),
      organizationName: Yup.string().optional().default(''),
      accountOwnerFirstName: Yup.string().required('Please enter your first name'),
      accountOwnerLastName: Yup.string().required('Please enter you last name'),
      accountOwnerEmail: Yup.string()
        .email('Please enter a valid email')
        .required('Please enter an  email'),
      storeUrl: Yup.string()
        .optional()
        .when('isEnterprise', {
          is: false,
          then: Yup.string().required('Please enter store url'),
          otherwise: Yup.string().optional(),
        }),
      platform: Yup.mixed<PlatformType>()
        .optional()
        .when('isEnterprise', {
          is: false,
          then: Yup.mixed()
            .required('Please select a platform')
            .oneOf(Object.keys(platformOptions)),
        }),
    })
    .defined()
}

type AddAccountSchema = Yup.InferType<ReturnType<typeof validationSchema>>

type CreateAccountModalFormProps = {
  onClickClose: () => void
}

const CreateAccountModalForm: FC<CreateAccountModalFormProps> = ({ onClickClose }) => {
  const flags = useFlags()
  const FF_ACCOUNT_PROVISION_ENTERPRISE = flags[LDFlag.EnterpriseAccountProvision]
  const [createAccountProvision, { isLoading: provisioningEnterpriseAccount }] =
    useCreateAccountProvisionMutation()
  const [createLegacyAccountProvision, { isLoading: provisioningLegacyAccount }] =
    useCreateLegacyAccountProvisionMutation()
  const [createStore, { isLoading: creatingStore }] = useCreateStoreMutation()
  const [getAccountsListContent, { data: accountsList }] = useLazyGetAccountsListQuery()
  const { toast } = useToaster()

  const { values, isValid, touched, errors, handleSubmit, handleChange, handleBlur } =
    useFormik<AddAccountSchema>({
      enableReinitialize: true,
      initialValues: {
        accountName: '',
        accountOwnerFirstName: '',
        accountOwnerLastName: '',
        accountOwnerEmail: '',
        platform: 'custom',
      },
      validationSchema: validationSchema(FF_ACCOUNT_PROVISION_ENTERPRISE),
      validateOnChange: true,
      validateOnMount: true,
      onSubmit: async () => {
        await provisionAccount()

        onClickClose()
      },
    })

  const provisionAccount = async (): Promise<void> => {
    let response: { data: void | AccountLegacy } | { error: FetchBaseQueryError | SerializedError }
    let storeCreateResponse:
      | { data: Store }
      | { error: FetchBaseQueryError | SerializedError }
      | undefined
    const body = {
      user: {
        firstName: values.accountOwnerFirstName,
        lastName: values.accountOwnerLastName,
        email: values.accountOwnerEmail,
      },
      account: {
        name: values.accountName,
        status: 'Approved',
      },
      ...(FF_ACCOUNT_PROVISION_ENTERPRISE && {
        organization: {
          name: values.organizationName || values.accountName,
        },
      }),
    }

    if (FF_ACCOUNT_PROVISION_ENTERPRISE) {
      response = await createAccountProvision({ body })
    } else {
      response = await createLegacyAccountProvision({ body })

      if ('data' in response) {
        const { data } = response as { data: AccountLegacy }

        storeCreateResponse = await createStore({
          body: {
            accountId: data.id,
            name: data.name,
            platform: values.platform || 'custom',
            domain: values.storeUrl || '',
          },
        })
      }
    }

    if ('error' in response) {
      if ((response as { error: FetchBaseQueryError }).error.status === 409) {
        toast({
          message: 'user already exists',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
      } else {
        toast({
          message: 'There was an error provisioning your account. Please try again.',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
      }
      return
    }

    if (storeCreateResponse && 'error' in storeCreateResponse) {
      toast({
        message: 'Account provision was successful but there was an error creating store.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })

      return
    }

    toast({
      message: 'Your Account has been provisioned successfully',
      toastColor: ToastColor.blue,
      toastDuration: ToastDuration.short,
    })
  }

  // Disabled linter error because debounce confuses linter
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedGetAccounts = useCallback(
    debounce(async (value: string) => {
      await getAccountsListContent({ name: value })
    }, 300),
    [getAccountsListContent],
  )

  const handleAccountNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    handleChange(event)
    if (FF_ACCOUNT_PROVISION_ENTERPRISE) delayedGetAccounts(event.target.value)
  }

  const hasItems = accountsList?.items && accountsList?.items.length > 0

  return (
    <Form onSubmit={handleSubmit}>
      <FieldWrapper>
        <Input
          data-cy="account-name"
          id="accountName"
          label={FF_ACCOUNT_PROVISION_ENTERPRISE ? 'Account Name' : 'Merchant Name'}
          value={values.accountName}
          onChange={(event) => handleAccountNameChange(event)}
          onBlur={handleBlur}
          isError={!!(errors.accountName && touched.accountName)}
          errorFeedback={errors.accountName}
        />
      </FieldWrapper>
      {hasItems && (
        <FieldWrapper>
          <InlineAlert color={InlineAlertColor.red}>This account name already exists.</InlineAlert>
        </FieldWrapper>
      )}
      {FF_ACCOUNT_PROVISION_ENTERPRISE && (
        <FieldWrapper>
          <Input
            data-cy="organization-name"
            id="organizationName"
            label="Organization Name (optional)"
            value={values.organizationName ?? ''}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={values.accountName}
            isError={!!(errors.organizationName && touched.organizationName)}
            errorFeedback={errors.organizationName}
          />
        </FieldWrapper>
      )}
      <FieldWrapper>
        <InputContainer>
          <Input
            data-cy="account-owner-first-name"
            id="accountOwnerFirstName"
            label="Account Owner First Name"
            value={values.accountOwnerFirstName}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!(errors.accountOwnerFirstName && touched.accountOwnerFirstName)}
            errorFeedback={errors.accountOwnerFirstName}
          />
          <Input
            data-cy="account-owner-last-name"
            id="accountOwnerLastName"
            label="Account Owner Last Name"
            value={values.accountOwnerLastName}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!(errors.accountOwnerLastName && touched.accountOwnerLastName)}
            errorFeedback={errors.accountOwnerLastName}
          />
        </InputContainer>
      </FieldWrapper>
      <FieldWrapper>
        <Input
          data-cy="account-owner-email"
          id="accountOwnerEmail"
          label="Account Owner Email"
          value={values.accountOwnerEmail}
          onChange={handleChange}
          onBlur={handleBlur}
          type={InputType.email}
          isError={!!(errors.accountOwnerEmail && touched.accountOwnerEmail)}
          errorFeedback={errors.accountOwnerEmail}
        />
      </FieldWrapper>
      {!FF_ACCOUNT_PROVISION_ENTERPRISE && (
        <>
          <FieldWrapper>
            <Input
              data-cy="storeurl"
              id="storeUrl"
              label="Store URL"
              value={values.storeUrl || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={!!(errors.storeUrl && touched.storeUrl)}
              errorFeedback={errors.storeUrl}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Select
              data-cy="platform:select"
              id="platform"
              label="Platform"
              value={values.platform || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={!!(errors.platform && touched.platform)}
              errorFeedback={errors.platform}
            >
              {Object.keys(platformOptions).map((key: string) => {
                return (
                  <option data-cy="platform-options" value={key}>
                    {platformOptions[key as PlatformType]}
                  </option>
                )
              })}
            </Select>
          </FieldWrapper>
        </>
      )}
      <ButtonWrapper>
        <ButtonGroup>
          <Button emphasis="medium" data-cy="cancel-button" text="Cancel" onClick={onClickClose} />
          <Button
            data-cy="submit-button"
            emphasis="high"
            text="Create Account"
            type="submit"
            isDisabled={!isValid || hasItems}
            isProcessing={
              provisioningEnterpriseAccount || provisioningLegacyAccount || creatingStore
            }
          />
        </ButtonGroup>
      </ButtonWrapper>
    </Form>
  )
}

const FieldWrapper = styled.div({
  width: '100%',
  marginBottom: 24,
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

const InputContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  div: {
    width: '100%',
  },
  'div:first-child': {
    paddingRight: 24,
  },
})

const Form = styled.form({})

export { CreateAccountModalForm }
