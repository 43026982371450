import React from 'react'
import { generateProduct } from '@helloextend/client-test-utils'
import type { Cell, Column } from 'react-table'
import type { Product } from '@helloextend/extend-api-client'
import { CellImage, CellText } from '../components/table'
import type { FilterOptions } from '../components/filters'

const mockTableData = [
  generateProduct({ category: 'house', title: 'product 2' }),
  generateProduct({ category: 'electronics', title: 'product 2' }),
  generateProduct({ category: 'shoes', title: 'product 4' }),
  generateProduct({ category: 'shoes', title: 'product 1' }),
  generateProduct({ category: 'house', title: 'product 2' }),
  generateProduct({ category: 'electronics', title: 'product 2' }),
  generateProduct({ category: 'shoes', title: 'product 4' }),
  generateProduct({ category: 'shoes', title: 'product 1' }),
  generateProduct({ category: 'house', title: 'product 2' }),
  generateProduct({ category: 'electronics', title: 'product 2' }),
  generateProduct({ category: 'shoes', title: 'product 4' }),
  generateProduct({ category: 'shoes', title: 'product 1' }),
  generateProduct({ category: 'house', title: 'product 2' }),
  generateProduct({ category: 'electronics', title: 'product 2' }),
  generateProduct({ category: 'shoes', title: 'product 4' }),
  generateProduct({ category: 'shoes', title: 'product 1' }),
  generateProduct({ category: 'house', title: 'product 2' }),
  generateProduct({ category: 'electronics', title: 'product 2' }),
  generateProduct({ category: 'shoes', title: 'product 4' }),
]

const mockTableColumns: Array<Column<Product>> = [
  {
    Header: 'Image',
    accessor: 'imageUrl',
    Cell: ({ value }: Cell) => <CellImage title="Test" imageUrl={value} />,
  },
  {
    Header: 'Category',
    accessor: 'category',
    Cell: ({ value }: Cell) => <CellText data-cy="product-category">{value}</CellText>,
    filter: 'multiSelectFilter',
  },
  {
    Header: 'Name',
    accessor: 'title',
    Cell: ({ value }: Cell): JSX.Element => <CellText data-cy="product-title">{value}</CellText>,
    filter: 'multiSelectFilter',
  },
  {
    Header: 'Reference Id',
    accessor: 'referenceId',
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="product-referenceId">{value}</CellText>
    ),
  },
]

const mockTableFilterOptions: Record<string, FilterOptions> = {
  category: {
    label: 'Category',
    type: 'checkbox',
    options: {
      shoes: 'Shoes',
      house: 'House',
      electronics: 'Electronics',
    },
  },
  title: {
    label: 'Title',
    type: 'checkbox',
    options: {
      'product 1': 'product 1',
      'product 2': 'product 2',
      'product 4': 'product 4',
    },
  },
}

export { mockTableData, mockTableColumns, mockTableFilterOptions }
