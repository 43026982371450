import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { post } from '@helloextend/extend-api-fetch'
import log from '@helloextend/client-logger'
import { getTokenSub } from '@helloextend/core-api-redux/src/util/jwt'
import type { ClaimFiler } from '../models/claim'
import type {
  InitialReply,
  Reply,
  Slot,
  SlotValue,
  ContactFormValues,
  ClaimSource,
} from '../models/claims-assistant'

interface ConnectOps {
  source: ClaimSource
  filedBy: ClaimFiler
  slot?: Slot
  slotValue?: string
  isSkipOTP?: boolean
}

export async function connect({
  source,
  filedBy,
  slot,
  slotValue,
  isSkipOTP,
}: ConnectOps): Promise<ApiResponse<InitialReply>> {
  return post('/claims-assistant/connect', {
    retry: true,
    body: {
      source,
      slot,
      slotValue,
      filedBy,
      isSkipOTP,
      apiVersion: 'latest',
    },
  })
}

interface UpdateOpts {
  slotValue?: SlotValue
  message?: string
  isSkipOTP?: boolean
}

export async function update(
  slot: Slot,
  accessToken: string,
  opts: UpdateOpts = {},
): Promise<ApiResponse<Reply>> {
  const sessionId = getTokenSub(accessToken)

  const { slotValue, message, isSkipOTP } = opts

  log.info(`Calling /update, sessionId: ${sessionId}`, {
    sessionId,
    slot,
    slotValue,
    timestamp: Date.now(),
  })

  return post('/claims-assistant/update', {
    retry: true,
    accessToken,
    body: {
      slot,
      slotValue,
      message,
      isSkipOTP,
    },
  })
}

export function contact(formValues: ContactFormValues): Promise<ApiResponse> {
  return post('/claims-assistant/contact', {
    body: formValues,
  })
}
