import type { Claim } from '@helloextend/extend-api-client'
import type { ClaimSearchKey } from '@helloextend/extend-api-client/src/models/claim'
import { createActionFailure } from '../../factories'

export const search = (searchKey: ClaimSearchKey, value: string, accessToken: string) =>
  ({ type: 'CLAIMS_SEARCH', payload: { searchKey, value, accessToken } } as const)

export const searchRequest = () => ({ type: 'CLAIMS_SEARCH_REQUEST' } as const)

export const searchSuccess = (claims: Claim[]) =>
  ({
    type: 'CLAIMS_SEARCH_SUCCESS',
    payload: claims,
  } as const)

export const searchFailure = createActionFailure('CLAIMS_SEARCH_FAILURE')
