import type { Contract } from '@helloextend/extend-api-client'
import type { CurrencyCode, Money } from '@helloextend/extend-api-client/src/models/currency'
import { createActionFailure } from '../../factories'

interface AdminContractsCreateRequest {
  transactionId: string
  transactionTotal?: {
    amount: number
    currencyCode?: CurrencyCode
  }
  customer: {
    name: string
    phone?: string
    email: string
    billingAddress?: Address
    shippingAddress?: Address
  }
  transactionDate: number
  adminContractId?: string
  product: {
    referenceId: string
    purchasePrice: Money
    title?: string
    brand?: string
    imageUrl?: string
    serialNumber?: string
  }
  plan: {
    purchasePrice: Money
    planId: string
  }
}

interface Address {
  address1: string
  address2?: string
  city: string
  countryCode: string
  postalCode: string
  // usually the state but not necessarily
  provinceCode: string
}

export const create = (
  sellerId: string,
  contract: AdminContractsCreateRequest,
  accessToken: string,
) =>
  ({
    type: 'CONTRACTS_CREATE',
    payload: { sellerId, contract, accessToken },
  } as const)

export const createRequest = () => ({ type: 'CONTRACTS_CREATE_REQUEST' } as const)

export const createSuccess = (contract: Contract) =>
  ({
    type: 'CONTRACTS_CREATE_SUCCESS',
    payload: contract,
  } as const)

export const createFailure = createActionFailure('CONTRACTS_CREATE_FAILURE')
