import type { SagaIterator } from 'redux-saga'
import { all, takeEvery } from 'redux-saga/effects'
import type { ActionType } from '../actions'
import fetchServiceOrderShipmentByServiceOrderId from './fetch-shipments'

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery(
      'SERVICE_ORDER_SHIPMENTS_FETCH' as ActionType,
      fetchServiceOrderShipmentByServiceOrderId,
    ),
  ])
}
