import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@helloextend/zen'
import { COLOR as BrandingColor } from '@helloextend/client-branding'

type PlaceholderImageProps = {
  fill?: string
  stroke?: string
}

export const PlaceholderImage: FC<PlaceholderImageProps> = ({
  fill = COLOR.WHITE,
  stroke = BrandingColor.LIGHT_GRAYISH_BLUE_0,
}) => (
  <svg viewBox="0 0 60 60">
    <g
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeWidth="1"
      transform="translate(-216 -508) translate(216 508)"
    >
      <path fill={fill} strokeWidth="1.25" d="M0.625 0.625H59.375V59.375H0.625z" />
      <rect width="31" height="34" x="14.5" y="13.5" fill={fill} strokeWidth="1" rx="2" />
      <rect width="25" height="23" x="17.5" y="16.5" fill={fill} strokeWidth="1" rx="2" />
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        transform="translate(18 20)"
      >
        <path d="M0 17L6.35294118 8.09090909 10.5882353 11.9090909 16.2352941 3 24 17" />
        <path d="M9 2.5a2.5 2.5 0 01-5 0 2.5 2.5 0 015 0z" />
      </g>
    </g>
  </svg>
)
