import type { ChangeEvent, FC } from 'react'
import React, { useCallback, useMemo, useState } from 'react'
import type { AdvancedSelectChangeEvent, AdvancedSelectOption } from '@helloextend/zen'
import { Add, Button, Modal, Stack, ToastColor, ToastDuration, useToaster } from '@helloextend/zen'
import { useCreatePlanSetMutation } from '@helloextend/extend-api-rtk-query'
import { uuid } from '@helloextend/extend-sdk-client/lib/utils/util'
import { StackAlign, StackDirection } from '@helloextend/zen/src/components/stack'
import type {
  PlanSetCreateBody,
  PlanSetPriceBand,
  DraftPriceBand,
  PlanSet,
  PlanSetValidationError,
} from '@helloextend/extend-api-client'
import type { PlanIdsCoverageTypeMap } from '@helloextend/extend-api-client/src/models/plan-sets'
import { CategorySelector } from '../category-selector'
import type { PriceBandInputFields } from '../price-band'
import { PriceBand } from '../price-band'
import {
  WARNING_MESSAGES,
  INCLUSIVE_PRICEBAND_MAX,
  INCLUSIVE_PRICEBAND_MIN,
  VALIDATION_ERROR_MESSAGE_PREFIX,
  DEFAULT_TOAST_ERROR_MESSAGE,
  MAX_PRICEBAND_COUNT,
} from '../../lib/constants'
import { GoToMarketCheckbox } from '../go-to-market-checkbox'
import {
  getPlanCategoryErrors,
  getPriceBandFieldErrors,
  getPriceBandSetErrors,
  validPlanSetNameInput,
} from '../../lib/validations'
import { InlineAlerts } from '../inline-alerts'
import { NameInput } from '../name-input'

export interface PlanSetModalProps {
  toggleModal: () => void
  sortedPlanSetCategories: AdvancedSelectOption[]
  planSetToEdit?: PlanSet
  planIdsCoverageTypeMap?: PlanIdsCoverageTypeMap
  isPlanIdsLoading: boolean
}

const PlanSetModal: FC<PlanSetModalProps> = ({
  toggleModal,
  sortedPlanSetCategories,
  planSetToEdit,
  planIdsCoverageTypeMap,
  isPlanIdsLoading,
}) => {
  // Fetching the planId's here prevents the need to re-fetch when an additional plan set is added
  const [selectedPlanSetCategory, setSelectedPlanSetCategory] = useState(
    planSetToEdit?.planCategory || '',
  )
  const [selectedPlanSetCategoryError, setSelectedPlanSetCategoryError] = useState<string[]>([])
  const [goToMarket, setGoToMarket] = useState(planSetToEdit?.isGTM || false)
  const [priceBands, setPriceBands] = useState(
    planSetToEdit ? mapPriceBandsToDraft(planSetToEdit.priceBands) : DEFAULT_PRICE_BAND,
  )
  const [priceBandSetErrors, setPriceBandSetErrors] = useState([] as string[])
  const [planSetName, setPlanSetName] = useState(
    planSetToEdit ? getPlanSetNameFromProps(planSetToEdit) : '',
  )
  const [isEditDisabled, setIsEditDisabled] = useState(false)
  const [create, { isLoading: isCreateProcessing }] = useCreatePlanSetMutation()
  const { toast } = useToaster()

  const handleSubmit = async (): Promise<void> => {
    setIsEditDisabled(true)
    const requestBody = buildPlanSetCreateBody(
      selectedPlanSetCategory,
      planSetName,
      priceBands,
      goToMarket,
      planSetToEdit?.id,
    )
    try {
      const response = await create(requestBody).unwrap()
      toast({
        message: `${response.name} plan set created!`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
      toggleModal()
    } catch (e) {
      toast({
        message: buildToastErrorMessage(e),
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.long,
      })
      setIsEditDisabled(false)
    }
  }

  const handleChangeCategory = useCallback((e: AdvancedSelectChangeEvent<string>): void => {
    const { value: updatedCategory } = e.target
    if (!updatedCategory) setGoToMarket(false)
    setSelectedPlanSetCategoryError(getPlanCategoryErrors(updatedCategory))
    setSelectedPlanSetCategory(updatedCategory)
  }, [])

  const handleChangePlanSetName = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    if (validPlanSetNameInput(value)) {
      setPlanSetName(formatPlanSetName(value))
    }
  }, [])

  const toggleGoToMarket = useCallback((): void => {
    setGoToMarket(!goToMarket)
  }, [goToMarket])

  const handleChangedPriceBandInput = useCallback(
    (
      e: AdvancedSelectChangeEvent<string[]> | React.ChangeEvent<HTMLInputElement>,
      priceBandId: string,
      field: PriceBandInputFields,
    ): void => {
      const newPriceBands = priceBands.map((priceBand) => {
        let newPriceBand = priceBand
        if (priceBand.id === priceBandId) {
          newPriceBand = { ...priceBand, [field]: e.target.value }
          newPriceBand = {
            ...newPriceBand,
            errors: getPriceBandFieldErrors(newPriceBand, field, planIdsCoverageTypeMap),
          }
        }
        return newPriceBand
      })

      setPriceBandSetErrors(getPriceBandSetErrors(newPriceBands))
      setPriceBands(newPriceBands)
    },
    [planIdsCoverageTypeMap, priceBands],
  )

  const handleDeletePriceBand = useCallback(
    (priceBandId: string) => {
      const newPriceBands = priceBands.filter((priceBand) => priceBand.id !== priceBandId)
      setPriceBandSetErrors(getPriceBandSetErrors(newPriceBands))
      setPriceBands(newPriceBands)
    },
    [priceBands],
  )

  const handleAddPriceBand = useCallback((): void => {
    let newPriceBand = generateNewPriceBand(
      Number(priceBands[priceBands.length - 1].maxPrice) + 1,
      INCLUSIVE_PRICEBAND_MAX,
    )
    newPriceBand = { ...newPriceBand, errors: getPriceBandFieldErrors(newPriceBand, 'minPrice') }
    newPriceBand = { ...newPriceBand, errors: getPriceBandFieldErrors(newPriceBand, 'maxPrice') }
    const newPriceBands = [...priceBands, newPriceBand]
    setPriceBandSetErrors(getPriceBandSetErrors(newPriceBands))
    setPriceBands(newPriceBands)
  }, [priceBands])

  const isCreateDisabled = useMemo(
    (): boolean =>
      Boolean(
        !selectedPlanSetCategory.length ||
          priceBandSetErrors.length ||
          !planSetName ||
          priceBands.some((priceBand) => {
            const { errors, plans } = priceBand
            return errors.plansErrors.length || errors.priceErrors.length || !plans.length
          }),
      ),
    [planSetName, priceBandSetErrors.length, priceBands, selectedPlanSetCategory.length],
  )

  return (
    <Modal
      heading="Plan Set Manager"
      onDismissRequest={toggleModal}
      data-cy="plan-sets-modal"
      primaryButtonProps={{
        'data-cy': 'modal-submit',
        onClick: handleSubmit,
        text: planSetToEdit ? 'Save' : 'Create',
        isDisabled: isCreateDisabled || isEditDisabled,
        isProcessing: isCreateProcessing,
      }}
      secondaryButtonProps={{
        'data-cy': 'modal-cancel',
        onClick: toggleModal,
        text: 'Cancel',
        isDisabled: isEditDisabled,
      }}
    >
      <Stack direction={StackDirection.column} align={StackAlign.stretch} spacing={2}>
        <CategorySelector
          onChange={handleChangeCategory}
          options={sortedPlanSetCategories}
          value={selectedPlanSetCategory}
          isDisabled={Boolean(isEditDisabled || planSetToEdit)}
          subtext={
            planSetToEdit ? 'Plan category cannot be modified for existing plan sets.' : undefined
          }
        />
        {Boolean(selectedPlanSetCategoryError.length) && (
          <InlineAlerts
            alerts={[...selectedPlanSetCategoryError]}
            alertType="error"
            data-cy="select-plan-category-errors"
          />
        )}
        <GoToMarketCheckbox
          checked={goToMarket}
          onChange={toggleGoToMarket}
          isDisabled={!selectedPlanSetCategory || isEditDisabled}
        />
        <Stack direction={StackDirection.column} align={StackAlign.stretch} spacing={2}>
          {priceBands.map((priceBand, idx) => {
            const { id, plans, minPrice, maxPrice } = priceBand
            return (
              <PriceBand
                key={id}
                id={id}
                idx={idx}
                planIdsCoverageTypeMap={planIdsCoverageTypeMap || {}}
                plans={plans}
                maxPrice={maxPrice}
                minPrice={minPrice}
                handleChangePrice={handleChangedPriceBandInput}
                handleSelectedPlans={handleChangedPriceBandInput}
                handleDeletePriceBand={handleDeletePriceBand}
                showDelete={priceBands.length > 1}
                isLoadingPlans={isPlanIdsLoading || !planIdsCoverageTypeMap}
                errors={priceBand.errors}
                isDisabled={false}
              />
            )
          })}
          <Button
            icon={Add}
            emphasis="low"
            onClick={handleAddPriceBand}
            text="Map Additional Priceband"
            data-cy="create-new-price-band-button"
            size="small"
            isDisabled={priceBands.length >= MAX_PRICEBAND_COUNT}
            tooltip={
              priceBands.length >= MAX_PRICEBAND_COUNT
                ? `Only ${MAX_PRICEBAND_COUNT} price bands allowed per plan set`
                : ''
            }
          />
          {Boolean(priceBandSetErrors.length) && (
            <InlineAlerts
              alerts={[...priceBandSetErrors]}
              alertType="error"
              data-cy="price-band-set-errors"
            />
          )}
          <NameInput
            handleChangePlanSetName={handleChangePlanSetName}
            planSetName={planSetName}
            prefix={getPlanSetNamePrefix(selectedPlanSetCategory)}
          />
          {goToMarket && (
            <InlineAlerts
              alerts={[WARNING_MESSAGES.PLANSET_GTM_UPDATE(selectedPlanSetCategory)]}
              alertType="warning"
              data-cy="plan-set-warnings"
            />
          )}
        </Stack>
      </Stack>
    </Modal>
  )
}

// Helper functions
const generateNewPriceBand = (minPrice: number, maxPrice: number): DraftPriceBand => ({
  id: uuid(),
  plans: [],
  minPrice,
  maxPrice,
  errors: {
    priceErrors: [],
    plansErrors: [],
  },
})

const DEFAULT_PRICE_BAND = [generateNewPriceBand(INCLUSIVE_PRICEBAND_MIN, INCLUSIVE_PRICEBAND_MAX)]

const mapPriceBandsToDraft = (priceBands: PlanSetPriceBand[]): DraftPriceBand[] =>
  priceBands.map((priceBand) => ({
    id: uuid(),
    errors: {
      priceErrors: [],
      plansErrors: [],
    },
    ...priceBand,
  }))

const formatPlanSetName = (planSetName: string): string => {
  return planSetName.replace(/ /g, '-')
}

const getPlanSetNameFromProps = (planSetToEdit: PlanSet): string =>
  planSetToEdit.name.split(`${formatPlanSetName(planSetToEdit.planCategory)}-`)[1]

const mapPriceBandsForSubmit = (priceBands: DraftPriceBand[]): PlanSetPriceBand[] =>
  priceBands.map((priceBand) => ({
    plans: priceBand.plans,
    minPrice: priceBand.minPrice,
    maxPrice: priceBand.maxPrice,
  }))

const getPlanSetNamePrefix = (category: string): string =>
  category ? `PS-${category.replace(/ /g, '-')}-` : 'PS-'

const buildPlanSetCreateBody = (
  selectedPlanSetCategory: string,
  planSetName: string,
  priceBands: DraftPriceBand[],
  isGTM: boolean,
  id?: string,
): PlanSetCreateBody => {
  const name = `${getPlanSetNamePrefix(selectedPlanSetCategory)}${planSetName}`
  return {
    id,
    name,
    planCategory: selectedPlanSetCategory,
    priceBands: mapPriceBandsForSubmit(priceBands),
    isGTM,
  }
}

// can't guarantee the error shape so must type as any
export const buildToastErrorMessage = (err: any): string => {
  const errorMessage = err?.data?.message

  // return default if the error is not a validation error
  if (!errorMessage || !errorMessage.includes(VALIDATION_ERROR_MESSAGE_PREFIX)) {
    return DEFAULT_TOAST_ERROR_MESSAGE
  }
  // get the stringified validation results from the error message
  const validationsResultsString = errorMessage.split(VALIDATION_ERROR_MESSAGE_PREFIX)[1]
  // parse the validation error results from the string
  const { results: validationErrors } = JSON.parse(validationsResultsString)
  // set the base toast error message
  let toastMessage = 'Validation error(s): '
  // iterate over the validation errors, adding them to the toast error message
  validationErrors.forEach((error: PlanSetValidationError, idx: number) => {
    toastMessage += error.message
    // add a semicolon to handle separating multiple error messages
    if (idx < validationErrors.length - 1) toastMessage += '; '
  })

  return toastMessage
}

export { PlanSetModal }
