const getClaimsUrl = (): string => {
  return '/admin/claims'
}

const getClaimDetailUrl = (claimId: string): string => {
  return `${getClaimsUrl()}/${claimId}`
}

const getContractsUrl = (): string => {
  return `/admin/contracts`
}

const getContractDetailUrl = (contractId: string): string => {
  return `${getContractsUrl()}/${contractId}`
}

export { getClaimDetailUrl, getContractDetailUrl }
