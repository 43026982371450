import type { ChangeEvent, KeyboardEvent, MouseEventHandler } from 'react'
import React, { forwardRef, useCallback, useState, useEffect } from 'react'
import { ColorSwatch } from './color-swatch'
import { parseHexColor } from '../../../../utils/colors'
import type { InputProps } from '../../input/input'
import { Input } from '../../input/input'

const defaultValue = '#000000'

interface HexInputProps extends Omit<InputProps, 'onChange' | 'onBlur'> {
  onChange: (value: string) => void
  onClick?: MouseEventHandler<HTMLInputElement>
  onBlur?: (value: string) => void
  onEnterPress?: (e: KeyboardEvent<HTMLInputElement>) => void
  onTabPress?: (e: KeyboardEvent<HTMLInputElement>) => void
}

const HexInput = forwardRef<HTMLInputElement, HexInputProps>(
  ({ onChange, onClick, onBlur, value, onEnterPress, onTabPress, ...passThroughProps }, ref) => {
    const [typedValue, setTypedValue] = useState(parseHexColor(value) || defaultValue)
    const parsedTypedValue = parseHexColor(typedValue).trim()

    useEffect(() => {
      const newValue = parseHexColor(value) || defaultValue
      setTypedValue(newValue)
    }, [value])

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      setTypedValue(e.target.value)
    }, [])

    const handleBlur = useCallback(() => {
      setTypedValue(value)
      if (value !== parsedTypedValue) {
        onChange(parsedTypedValue)
      }

      if (onBlur) {
        onBlur(parsedTypedValue)
      }
    }, [onBlur, onChange, parsedTypedValue, value])

    const handleKeyPress = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && onEnterPress) {
          onEnterPress(e)
        }
      },
      [onEnterPress],
    )

    const handleKeyDown = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Tab' && onTabPress) {
          onTabPress(e)
        }
      },
      [onTabPress],
    )

    const valueToShow = typedValue === parsedTypedValue ? parsedTypedValue.substring(1) : typedValue

    return (
      <Input
        {...passThroughProps}
        value={valueToShow}
        startAdornment={<ColorSwatch value={parsedTypedValue} />}
        prefix="#"
        onClick={onClick}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyDown}
        ref={ref}
      />
    )
  },
)

export { HexInput, HexInputProps }
