import type { FormikErrors } from 'formik'
import type {
  ServiceOrder,
  ShipmentStatus,
  ServiceOrderShipment,
  ServiceOrderStatus,
  ServiceOrderReplacementStatus,
  Money,
} from '@helloextend/extend-api-client'
import type {
  CollapsibleInfoGroupProps,
  FieldMapperConfig,
} from '../../../../../../../../components/collapsible-info-group'
import { fieldMapper } from '../../../../../../../../components/collapsible-info-group'
import { formatDate, formatStartCase, formatCurrency, formatDateTime } from '../../../../../util'

const SHIP_ENGINE_DOWNLOAD_BASE_URL = 'https://api.shipengine.com/v1/downloads'

interface ShippingInfoValues extends ServiceOrderShipment {}

type MappedShipmentStatus = Extract<ShipmentStatus, 'received_by_carrier'>
const shipmentStatusMap: Record<MappedShipmentStatus, string> = {
  received_by_carrier: 'In Transit',
}

const serviceOrderStatuses: Array<Omit<ServiceOrderStatus, ServiceOrderReplacementStatus>> = [
  'created',
  'assigned',
  'accepted',
  'checked_in',
  'repair_started',
  'fulfilled',
  'payment_requested',
  'payment_approved',
]

const hasMinimumStatus = (serviceOrder?: ServiceOrder, requiredStatus = 'checked_in'): boolean => {
  if (!serviceOrder) return false
  return (
    serviceOrder.status === requiredStatus ||
    serviceOrderStatuses.indexOf(serviceOrder.status) > serviceOrderStatuses.indexOf(requiredStatus)
  )
}

const failedProductFields = [
  {
    key: 'status',
    label: 'Status',
    transformFn: (status?: string): string => {
      return shipmentStatusMap[status as MappedShipmentStatus] ?? formatStartCase(status)
    },
  },
  { key: 'trackingNumber', label: 'Tracking Number' },
  { key: 'shippedAt', label: 'Shipment Date', transformFn: formatDate },
]

const fulfilledProductFields = [
  {
    key: 'status',
    label: 'Status',
    transformFn: (status?: string): string => {
      return shipmentStatusMap[status as MappedShipmentStatus] ?? formatStartCase(status)
    },
  },
  { key: 'trackingNumber', label: 'Return Tracking Number' },
  { key: 'shippedAt', label: 'Return Shipment Date', transformFn: formatDate },
]

const handleDownloadClick = (
  serviceOrder: ServiceOrder | undefined,
  destinationType: string,
): void => {
  if (
    serviceOrder?.configurations?.packingSlipRequired &&
    serviceOrder.attachments?.packingSlip?.packingSlipUrl
  ) {
    window.open(
      serviceOrder.attachments?.packingSlip?.packingSlipUrl,
      '_blank',
      'noopener,noreferrer',
    )
    return
  }

  const labelForDestinationType = serviceOrder?.shippingLabels?.find(
    (label) => label.destinationType === destinationType,
  )

  // falls back to `imageData` if `pdfDownloadUrl` is not present, for backwards compatibility
  const downloadUrl =
    labelForDestinationType?.pdfDownloadUrl || labelForDestinationType?.imageData || ''
  if (!downloadUrl.startsWith(SHIP_ENGINE_DOWNLOAD_BASE_URL)) return

  window.open(downloadUrl, '_blank', 'noopener,noreferrer')
}

/**
 * The majority of service orders will have at least a servicer-bound shipment,
 * so that section will always be rendered. Return shipments are more conditional,
 * so that will only be rendered when applicable.
 */
const getShippingInfoData = (
  failedProductShipment: ShippingInfoValues,
  serviceOrder?: ServiceOrder,
  fulfilledProductShipment?: ShippingInfoValues,
): CollapsibleInfoGroupProps['data'] => {
  const downloadLabel = serviceOrder?.shippingLabels?.find((l) => l.destinationType === 'servicer')
  const downloadLabelUrl = downloadLabel?.pdfDownloadUrl || downloadLabel?.imageData
  const data = [
    {
      header: 'Failed Product',
      values: fieldMapper<ShippingInfoValues>(failedProductShipment, [
        ...failedProductFields,
        {
          key: failedProductShipment.status === 'delivered' ? 'deliveredAt' : 'estimatedDeliveryAt',
          label: failedProductShipment.status === 'delivered' ? 'Delivered Date' : 'ETA Date',
          transformFn: formatDate,
        },
      ]),
      ...(downloadLabelUrl && {
        footerButton: {
          id: 'downloadOutboundLabel',
          text: 'Download Label',
          handleClick: () => handleDownloadClick(serviceOrder, 'servicer'),
        },
      }),
    },
  ]

  getFulfilledProductShipmentInfo(data, fulfilledProductShipment, serviceOrder)

  return data
}

const getFulfilledProductShipmentInfo = (
  data: CollapsibleInfoGroupProps['data'],
  shipment?: ShippingInfoValues,
  serviceOrder?: ServiceOrder,
): void => {
  if (shipment) {
    data.push({
      header: 'Fulfilled Product',
      values: fieldMapper<ShippingInfoValues>(shipment, [
        ...fulfilledProductFields,
        {
          key: shipment.status === 'delivered' ? 'deliveredAt' : 'estimatedDeliveryAt',
          label: shipment.status === 'delivered' ? 'Delivered Date' : 'Return ETA Date',
          transformFn: formatDate,
        },
      ]),
      footerButton: {
        id: 'downloadReturnLabel',
        text: 'Download Return Label',
        handleClick: () => handleDownloadClick(serviceOrder, 'customer'),
      },
    })
    return
  }

  if (serviceOrder?.configurations?.hasCustomReturnLabel) {
    data.push({
      header: 'Fulfilled Product',
      values: [],
    })
  }
}

const getServiceOrderStatusDateField = (
  serviceOrder: ServiceOrder,
): FieldMapperConfig<ServiceOrder> => {
  switch (serviceOrder.status) {
    case 'assigned':
      return {
        key: 'repairMetaData.assignedAt',
        label: 'Assigned Date',
      }
    case 'accepted':
      return {
        key: 'repairMetaData.acceptedAt',
        label: 'Accepted Date',
      }
    case 'checked_in':
      return { key: 'repairMetaData.checkedInAt', label: 'Checked In Date' }
    case 'fulfilled':
      return {
        key: 'repairMetaData.repairCompletedAt',
        label: 'Repair Completed Date',
      }
    default:
      return hasMinimumStatus(serviceOrder, 'repair_started')
        ? { key: 'repairMetaData.repairStartedAt', label: 'Repair Started Date' }
        : { key: 'createdAt', label: 'Created Date' }
  }
}

/**
 * Dynamically shows or hides certain fields based on the current status of the service order
 */
const getServiceOrderInfoData = <S extends Record<string, any>>(
  serviceOrder?: ServiceOrder,
  coverageAmountRemaining?: Money,
  formValues?: { [key: string]: any },
  formErrors?: FormikErrors<S>,
): CollapsibleInfoGroupProps['data'] => {
  if (serviceOrder == null) return []

  const fields = [
    { key: 'id', label: 'Service Order ID' },
    { key: 'serviceType', label: 'Service Type', transformFn: formatStartCase },
    { ...getServiceOrderStatusDateField(serviceOrder), transformFn: formatDate },
    {
      key: 'coverageAmountRemaining',
      label: 'Remaining Limit of Liability',
      transformFn: formatCurrency,
    },
    {
      key: 'assignedServicer.businessName',
      label: 'Servicer',
      isHidden: !hasMinimumStatus(serviceOrder, 'assigned'),
      block: !serviceOrder?.configurations?.rmaNumberRequired,
    },
    {
      key: 'rmaNumber',
      label: 'RMA Number',
      isHidden:
        !hasMinimumStatus(serviceOrder, 'accepted') ||
        !serviceOrder?.configurations?.rmaNumberRequired,
    },
    {
      key: 'repairMetaData.diagnosticExplanation',
      label: 'Diagnostic Explanation',
      isHidden: !hasMinimumStatus(serviceOrder, 'repair_started'),
    },
  ]

  return [
    {
      values: fieldMapper<ServiceOrder & { coverageAmountRemaining?: Money }, S>(
        { ...serviceOrder, coverageAmountRemaining },
        fields,
        formValues,
        formErrors,
      ),
    },
  ]
}

export { getServiceOrderInfoData, getShippingInfoData, formatDateTime, formatDate }
