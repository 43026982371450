import type { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import type { ActionType } from '../actions'
import create from './create'
import fetch from './fetch'
import voidExpense from './void'

export default function* sagas(): SagaIterator {
  yield all([
    takeLatest('SERVICE_ORDER_EXPENSES_CREATE' as ActionType, create),
    takeLatest('SERVICE_ORDER_EXPENSES_FETCH' as ActionType, fetch),
    takeLatest('SERVICE_ORDER_EXPENSES_VOID' as ActionType, voidExpense),
  ])
}
