import { UserStatus } from '@helloextend/extend-api-client'
import type { BadgeColor } from '@helloextend/zen'

type BadgeItem = {
  badgeText: string
  description: string
  badgeColor: BadgeColor
}

const getUserStatusCopy = (userStatus: UserStatus): BadgeItem => {
  switch (userStatus) {
    case UserStatus.ACTIVE:
      return {
        badgeText: 'Active',
        description: 'User has verified their email and can log into the Portal.',
        badgeColor: 'green',
      }
    case UserStatus.DEACTIVATED:
      return {
        badgeText: 'Deactivated',
        description: 'User account has been deactivated and cannot access Portal at this time.',
        badgeColor: 'red',
      }
    case UserStatus.PENDING:
      return {
        badgeText: 'Pending',
        description: 'User has not verified their email and cannot log into the Portal.',
        badgeColor: 'yellow',
      }
    default:
      return {
        badgeText: 'Unknown',
        description: '',
        badgeColor: 'neutral',
      }
  }
}

const statusDescriptions = [
  getUserStatusCopy(UserStatus.ACTIVE),
  getUserStatusCopy(UserStatus.PENDING),
  getUserStatusCopy(UserStatus.DEACTIVATED),
]

export { getUserStatusCopy, statusDescriptions, BadgeItem }
