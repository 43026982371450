import type { BadgeColor } from '@helloextend/zen'
import type { ServiceOrder, ServiceOrderClosedResolution } from '@helloextend/extend-api-client'

export interface BadgeDescriptionItem {
  badgeText: string
  badgeColor: BadgeColor
  description: string
  badgeDetails?: string
}

const mapResolutionCodes = (code?: ServiceOrderClosedResolution): string => {
  switch (code) {
    case 'no_service':
      return 'No service required'
    case 'service_change':
      return 'Service change'
    default:
      return 'Reason unknown'
  }
}

export const getServiceOrderStatusDetails = (
  serviceOrder?: ServiceOrder,
): BadgeDescriptionItem | null => {
  if (!serviceOrder) return null
  const serviceOrderStatusDetailsMap: Record<string, BadgeDescriptionItem> = {
    created: {
      badgeText: 'Created',
      description: '',
      badgeColor: 'yellow',
    },
    assigned: {
      badgeText: 'Assigned',
      description: '',
      badgeColor: 'yellow',
    },
    accepted: {
      badgeText: 'Accepted',
      description: '',
      badgeColor: 'yellow',
    },
    checked_in: {
      badgeText: 'Checked In',
      description: '',
      badgeColor: 'yellow',
    },
    replacement_approved: {
      badgeText: 'Replacement Approved',
      description: '',
      badgeColor: 'yellow',
    },
    action_required: {
      badgeText: 'Tracking Required',
      description: '',
      badgeColor: 'yellow',
    },
    repair_started: {
      badgeText: 'Repair Started',
      description: '',
      badgeColor: 'yellow',
    },
    repair_shipped: {
      badgeText: 'Repair Shipped',
      description: '',
      badgeColor: 'yellow',
    },
    fulfilled: {
      badgeText: serviceOrder.serviceType === 'replace' ? 'Resolved' : 'Repair Completed',
      description: '',
      badgeColor: 'yellow',
    },
    payment_requested: {
      badgeText: 'Payment Requested',
      description: '',
      badgeColor: 'yellow',
    },
    payment_approved: {
      badgeText: 'Payment Approved',
      description: '',
      badgeColor: 'neutral',
    },
    closed: {
      badgeText: 'Closed',
      description: '',
      badgeDetails: mapResolutionCodes(serviceOrder.closedMetaData?.resolution),
      badgeColor: 'neutral',
    },
    paid: {
      badgeText: 'Reimbursement Paid',
      description: '',
      badgeColor: 'green',
    },
  }
  return serviceOrderStatusDetailsMap[serviceOrder.status]
}
