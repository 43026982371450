import type { PlanTermsVersion } from '@helloextend/extend-api-client'
import type { CellContext, ColumnDefs } from '@helloextend/zen'
import { format } from '@helloextend/zen/src/utils/date'
import React from 'react'

const tableColumns: ColumnDefs<PlanTermsVersion> = [
  {
    id: 'version',
    label: 'Version',
    isSortable: true,
  },
  {
    id: 'createdAt',
    label: 'Date Created',
    isSortable: true,
    renderCell: (data: CellContext<PlanTermsVersion, string>) => {
      const { createdAt } = data.row.original
      return <span>{format(createdAt, 'MMM DD YYYY')}</span>
    },
  },
]

export { tableColumns }
