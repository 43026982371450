import type { MouseEvent } from 'react'
import React, { useCallback, useMemo } from 'react'
import type { Row } from 'react-table'
import type { ContextMenuItem } from '../context-menu'
import { ContextMenu } from '../context-menu'
import { TableRow, TableCell } from '../table'
import type { RowContextMenuItem } from './types'

type ReactTableRowProps<TData extends Record<string, unknown>> = {
  row: Row<TData>
  onRowClick: (e: MouseEvent, rowData: TData) => void
  contextMenuItems?: Array<RowContextMenuItem<TData>>
}

const ReactTableRow = <TData extends Record<string, unknown>>({
  row,
  onRowClick,
  contextMenuItems,
}: ReactTableRowProps<TData>): JSX.Element => {
  const handleRowClick = useCallback(
    (e): void => {
      onRowClick(e, row.original as TData)
    },
    [onRowClick, row.original],
  )

  const contextMenuItemsProps: ContextMenuItem[] | undefined = useMemo(() => {
    return contextMenuItems?.map((i) => ({
      ...i,
      onClick: () => {
        if (i.onClick) {
          i.onClick(row.original)
        }
      },
    }))
  }, [contextMenuItems, row.original])

  return (
    <TableRow {...row.getRowProps()} onClick={handleRowClick} data-cy={row.original.name}>
      {row.cells.map((cell) => {
        return (
          <TableCell {...cell.getCellProps()} align={cell.column.textAlign}>
            {cell.render('Cell')}
          </TableCell>
        )
      })}
      {contextMenuItemsProps?.length && (
        <TableCell>
          <ContextMenu position="right" items={contextMenuItemsProps} />
        </TableCell>
      )}
    </TableRow>
  )
}

export { ReactTableRow }
