import type { Action } from '../../actions'

export type ServicersSearchFilterReducerState = string

const initialState: ServicersSearchFilterReducerState = ''

export default function searchFilter(
  state = initialState,
  action: Action,
): ServicersSearchFilterReducerState {
  switch (action.type) {
    case 'SERVICERS_SEARCH_FILTER_SET':
      return action.payload
    case 'SERVICERS_SEARCH_FILTER_CLEAR':
      return ''
    default:
      return state
  }
}
