import * as Yup from 'yup'
import { formatStartCase } from '../../../util'

const dataFields = [
  {
    key: 'fulfillmentMetaData.method',
    label: 'Fulfillment Resolution',
    transformFn: formatStartCase,
  },
  { key: 'fulfillmentMetaData.orderNumber', label: 'Order Number' },
]

const formSchema = Yup.object()
  .shape({
    carrier: Yup.string().required('Required').default(''),
    trackingNumber: Yup.string().required('Required').default(''),
  })
  .defined()

type Values = Yup.InferType<typeof formSchema>

export { dataFields, formSchema }
export type { Values }
