import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  Theme,
  ThemeByIdArgs,
  ThemesGetAllResponse,
  ThemeBase,
  ThemeUpdateRequest,
  UploadThemeLogoRequest,
} from './types'
import { baseQuery } from '../base-query'

export const themesApi = createApi({
  baseQuery,
  reducerPath: 'Themes',
  tagTypes: ['ThemesMinimal', 'ThemeFull'],
  endpoints: (build) => ({
    fetchAllThemes: build.query<ThemesGetAllResponse, string>({
      query: (storeId: string) => ({
        url: `/offers/themes?storeId=${storeId}`,
      }),
      providesTags: (_, _err, storeId) => [{ type: 'ThemesMinimal', id: storeId }],
    }),
    fetchTheme: build.query<Theme, ThemeByIdArgs>({
      query: ({ storeId, themeId }) => ({
        url: `/offers/themes/${themeId}?storeId=${storeId}`,
      }),
      providesTags: (_, _err, { storeId, themeId }) => [
        { type: 'ThemeFull', id: `${storeId}:${themeId}` },
      ],
    }),
    createTheme: build.mutation<Theme, ThemeBase>({
      query: (request) => {
        return {
          url: '/offers/themes',
          method: 'POST',
          body: request,
        }
      },
      invalidatesTags: ['ThemeFull', 'ThemesMinimal'],
    }),
    publishTheme: build.mutation<Pick<ThemeByIdArgs, 'themeId'>, ThemeByIdArgs>({
      query: ({ storeId, themeId }) => {
        return {
          url: `/offers/themes/${themeId}/publish`,
          params: { storeId },
          method: 'POST',
          body: { storeId },
        }
      },
      invalidatesTags: ['ThemeFull', 'ThemesMinimal'],
    }),
    updateTheme: build.mutation<Theme, ThemeUpdateRequest>({
      query: (request) => {
        return {
          url: `/offers/themes/${request.themeId}`,
          method: 'PUT',
          body: request,
        }
      },
      invalidatesTags: ['ThemeFull', 'ThemesMinimal'],
    }),
    deleteTheme: build.mutation<Pick<ThemeByIdArgs, 'themeId'>, ThemeByIdArgs>({
      query: ({ storeId, themeId }) => {
        return {
          url: `/offers/themes/${themeId}`,
          params: { storeId },
          method: 'DELETE',
        }
      },
      invalidatesTags: ['ThemeFull', 'ThemesMinimal'],
    }),
    uploadThemeLogo: build.mutation<string, UploadThemeLogoRequest>({
      query: ({ base64image }) => ({
        url: `/offers/themes/logo`,
        method: 'PUT',
        body: base64image,
      }),
    }),
  }),
})

export const {
  useFetchAllThemesQuery,
  useFetchThemeQuery,
  useLazyFetchAllThemesQuery,
  useLazyFetchThemeQuery,
  useCreateThemeMutation,
  useUpdateThemeMutation,
  usePublishThemeMutation,
  useDeleteThemeMutation,
  useUploadThemeLogoMutation,
} = themesApi
export const {
  fetchAllThemes,
  fetchTheme,
  createTheme,
  publishTheme,
  updateTheme,
  deleteTheme,
  uploadThemeLogo,
} = themesApi.endpoints
