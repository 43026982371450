import type { Contract } from '@helloextend/extend-api-client'
import { map, uniq, isEmpty, chunk } from 'lodash/fp'
import type { SagaIterator } from 'redux-saga'
import { all, call } from 'redux-saga/effects'
import fetch from '../../plans/sagas/fetch'
import { actions as plansActions } from '../../plans/actions'

/*
 * Collects the the unique list of plans from
 *  the contracts, and dispatches the saga to initiate
 *  the GET /plans endpoint.
 *
 *  Errors in this generator fn should bubble
 *  over to the saga that calling this helper
 */
const MAX_CONCURRENT_GET_PLANS_REQUEST = 5

export function* fetchContractSearchPlans(contracts: Contract[]): SagaIterator {
  const plansSet = uniq(map('plan.planId')(contracts))
  if (isEmpty(plansSet)) return

  for (const group of chunk(MAX_CONCURRENT_GET_PLANS_REQUEST, plansSet)) {
    yield all(group.map((planId) => call(fetch, plansActions.fetch(planId))))
  }
}
