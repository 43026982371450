import type {
  CurrencyConfig,
  DropdownConfig,
  FormikMapperConfig,
} from '../../../components/form-text-group/formik-mapper'

const storeInformationFields: Array<FormikMapperConfig | DropdownConfig> = [
  { key: 'storeId', label: 'Store ID' },
]

const customerFields = [
  { key: 'fullName', label: 'Full Name' },
  { key: 'phoneNumber', label: 'Phone Number' },
  { key: 'customerEmail', label: 'Email' },
]

const billingInformationFields = [
  { key: 'billingAddress', label: 'Address', columnCount: 2 },
  { key: 'billingAddressTwo', label: 'Address 2', columnCount: 2 },
  { key: 'billingCity', label: 'City' },
  { key: 'billingProvinceCode', label: 'Province Code' },
  { key: 'billingPostalCode', label: 'Postal Code' },
  { key: 'billingCountryCode', label: 'Country Code' },
]

const shippingInformationFields = [
  { key: 'shippingAddress', label: 'Address', columnCount: 2 },
  { key: 'shippingAddressTwo', label: 'Address 2', columnCount: 2 },
  { key: 'shippingCity', label: 'City' },
  { key: 'shippingProvinceCode', label: 'Province Code' },
  { key: 'shippingPostalCode', label: 'Postal Code' },
  { key: 'shippingCountryCode', label: 'Country Code' },
]

const contractInformationFields: Array<FormikMapperConfig | CurrencyConfig | DropdownConfig> = [
  { key: 'transactionId', label: 'Transaction ID', columnCount: 2 },
  {
    key: 'currencyCode',
    label: 'Currency Code',
    fieldType: 'dropdown',
    options: [
      { label: 'USD', value: 'USD' },
      { label: 'CAD', value: 'CAD' },
    ],
    columnCount: 2,
  },
  {
    key: 'transactionDate',
    label: 'Transaction Date',
    fieldType: 'date',
    columnCount: 2,
  },
  { key: 'planId', label: 'Plan ID', columnCount: 3 },
  {
    key: 'planPurchasePrice',
    label: 'Plan Purchase Price',
    fieldType: 'currency',
    currencyProps: { codeFieldName: 'currencyCode' },
    columnCount: 3,
  },
  {
    key: 'quantity',
    label: 'Number of Contracts Needed',
    fieldType: 'dropdown',
    options: [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
    ],
    columnCount: 3,
  },
]

const productInformationFields: Array<FormikMapperConfig | CurrencyConfig> = [
  { key: 'productName', label: 'Product Name' },
  { key: 'referenceId', label: 'Product ID' },
  {
    key: 'productPurchasePrice',
    label: 'Purchase Price',
    fieldType: 'currency',
    currencyProps: { codeFieldName: 'currencyCode' },
  },
  {
    key: 'productPurchaseDate',
    label: 'Product Purchase Date',
    fieldType: 'date',
  },
]

const sectionTitles = {
  customerInformation: 'Customer Information',
  storeInformation: 'Store Information',
  contractInformation: 'Contract Information',
  productInformation: 'Product Information',
  billingInformation: 'Billing Information',
  shippingInformation: 'Shipping Information',
}

export {
  storeInformationFields,
  customerFields,
  billingInformationFields,
  shippingInformationFields,
  contractInformationFields,
  productInformationFields,
  sectionTitles,
}
