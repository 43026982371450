import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/zen'
import { AdjudicationPromptWrapper } from './adjudication-prompt-wrapper'

interface PromptTextInputProps {
  placeholder: string
  badgeValue: number | string
  onBadgeClick: (val: number | string) => void
}

const AdjudicationPromptTextInput: FC<PromptTextInputProps> = ({
  placeholder,
  badgeValue,
  onBadgeClick,
}) => {
  return (
    <AdjudicationPromptWrapper badgeValue={badgeValue} onBadgeClick={onBadgeClick}>
      <StyledTextInput
        data-cy="adjudication-prompt-text-input"
        disabled
        id={placeholder || 'input'}
        value=""
        onChange={() => {}}
        placeholder={placeholder || 'Input text'}
      />
    </AdjudicationPromptWrapper>
  )
}

const StyledTextInput = styled.input({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px 24px 4px 12px',
  border: `1px solid ${COLOR.NEUTRAL[600]}`,
  lineHeight: '24px',
  borderRadius: '4px',
  flexGrow: 1,
})

export { AdjudicationPromptTextInput }
