import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { ProductsTabs } from './products-tabs'
import { PlanMapper } from './plan-mapper'
import { CategoryMapper } from '../category-mapper'

const Products: FC = () => {
  const history = useHistory()

  return (
    <>
      <Header>Products</Header>
      <ProductsTabs />
      {history.location.pathname.split('/').pop() === 'plan-mapping' ? (
        <PlanMapper />
      ) : (
        <CategoryMapper />
      )}
    </>
  )
}

const Header = styled.h3({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 12,
  fontSize: 32,
  margin: 0,
  padding: 0,
})

export { Products }
