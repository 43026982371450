import type { FormEvent, SyntheticEvent, FC } from 'react'
import React from 'react'
import type { CSSObject } from '@emotion/core'
import { COLOR } from '@helloextend/client-branding'
import { Spinner } from '../spinner'

// TODO: [DEVX-33]-update boolean arguments loading => isLoading, disabled => isDisabled, block => isBlock and codebase
export type ButtonKind =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'dangerSecondary'
  | 'action'
  | 'dark'
  | 'disabled'
  | 'pillButton'
  | 'whiteOutline'
  | 'gridLink'

export const KINDS: ButtonKind[] = [
  'primary',
  'secondary',
  'danger',
  'dangerSecondary',
  'action',
  'dark',
  'disabled',
  'pillButton',
  'whiteOutline',
  'gridLink',
]

export type ButtonType = 'submit' | 'reset' | 'button'

export const TYPES: ButtonType[] = ['submit', 'reset', 'button']

export type ButtonSize = 'xxs' | 'xs' | 'sm' | 'md'

export const SIZES: ButtonSize[] = ['xxs', 'xs', 'sm', 'md']

type EventType = SyntheticEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>

export interface ButtonProps {
  /** @default false */
  block?: boolean
  className?: string
  dataQa?: string
  ariaLabel?: string
  /** @default false */
  disabled?: boolean
  /** @default 'primary' */
  kind?: ButtonKind
  /** @default false */
  loading?: boolean
  onClick?: (event: EventType) => void
  /** @default 'md' */
  size?: ButtonSize
  text: string
  /** @default 'button' */
  type?: ButtonType
  name?: string
  isInverted?: boolean
  title?: string
  dataCy?: string
  id?: string
}

/**
 * @deprecated Use Zen Button component instead: `import { Button } from '@helloextend/zen'`
 */
const Button: FC<ButtonProps> = ({
  block = false,
  className,
  dataQa = null,
  ariaLabel,
  disabled = false,
  kind = 'primary',
  loading = false,
  onClick = function noop() {
    // do nothing
  },
  size = 'md',
  text,
  type = 'button',
  name,
  isInverted = false,
  title,
  dataCy,
  id,
}) => {
  const passThrough = { disabled, onClick, type, className, name, title, id }
  if (dataQa) {
    Object.assign(passThrough, { 'data-cy': dataQa })
  }

  if (ariaLabel) {
    Object.assign(passThrough, { 'aria-label': ariaLabel })
  } else {
    Object.assign(passThrough, { 'aria-label': text })
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      data-cy={dataCy}
      {...passThrough}
      disabled={disabled || loading}
      css={[
        baseStyles,
        isInverted ? kindStylesInverted[kind] : kindStyles[kind],
        sizeStyles[size],
        block && { width: '100%' },
        loading && (loadingStyles[kind] || loadingStyles.default),
        disabled && (disabledStyles[kind] || disabledStyles.default),
      ]}
    >
      {loading && <Spinner color="inherit" />}

      <span css={{ visibility: loading ? 'hidden' : 'visible' }}>{text}</span>
    </button>
  )
}

// Styles
// ==================================================================================
export const baseStyles: CSSObject = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'transparent',
  boxShadow: 'none',
  boxSizing: 'border-box',
  borderRadius: 4,
  color: COLOR.LIGHT_GRAYISH_BLUE_0,
  cursor: 'pointer',
  fontFamily: 'Nunito Sans, sans-serif',
  fontWeight: 700,
  lineHeight: 1,
  position: 'relative',
  transitionProperty: 'background-color, color, border-color',
  transitionDuration: '200ms',
  '&:focus': {
    outline: 'none',
    borderColor: COLOR.STRONG_BLUE,
    boxShadow: `0px 0px 0px 2px ${COLOR.WHITE},
                0px 0px 3px 2px rgba(0, 51, 204, 0.5)`,
  },
}

export const sizeStyles: Record<ButtonSize, CSSObject> = {
  xxs: {
    fontSize: 16,
    height: 40,
    padding: '8px 16px',
  },
  xs: {
    fontSize: 16,
    height: 40,
    padding: '11px 16px',
  },
  sm: {
    fontSize: 16,
    height: 38,
    padding: '8px 32px',
  },
  md: {
    fontSize: 20,
    height: 47,
    padding: '10px 48px',
  },
}

const disabledStyles: CSSObject = {
  primary: {
    backgroundColor: COLOR.GHOST,
    borderColor: COLOR.GHOST,
    color: COLOR.BLACK_SECONDARY,
    cursor: 'not-allowed',
    '&:hover,&:focus': {
      backgroundColor: COLOR.GHOST,
      borderColor: COLOR.GHOST,
    },
  },
  secondary: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.GHOST,
    color: COLOR.BLACK_MUTED,
    cursor: 'not-allowed',
    '&:hover,&:focus': {
      backgroundColor: COLOR.WHITE,
      borderColor: COLOR.GHOST,
      color: COLOR.BLACK_MUTED,
    },
  },
  gridLink: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.GHOST,
    color: COLOR.BLACK_MUTED,
    cursor: 'not-allowed',
    '&:hover,&:focus': {
      backgroundColor: COLOR.WHITE,
      borderColor: COLOR.WHITE,
      color: COLOR.BLACK_MUTED,
    },
  },
  danger: {
    backgroundColor: COLOR.DANGER_LIGHT,
    borderColor: COLOR.DANGER_LIGHT,
    color: COLOR.WHITE,
    cursor: 'not-allowed',
    '&:hover,&:focus': {
      backgroundColor: COLOR.DANGER_LIGHT,
      borderColor: COLOR.DANGER_LIGHT,
    },
  },
  dangerSecondary: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.DANGER_LIGHT,
    color: COLOR.DANGER_LIGHT,
    cursor: 'not-allowed',
    '&:hover,&:focus': {
      backgroundColor: COLOR.WHITE,
      borderColor: COLOR.DANGER_LIGHT,
      color: COLOR.DANGER_LIGHT,
    },
  },
  default: {
    backgroundColor: COLOR.GRAYISH_BLUE_0,
    borderColor: COLOR.GRAYISH_BLUE_0,
    color: COLOR.WHITE,
    cursor: 'not-allowed',
    '&:hover,&:focus': {
      backgroundColor: COLOR.GRAYISH_BLUE_0,
      borderColor: COLOR.GRAYISH_BLUE_0,
    },
    '&:disabled': {
      backgroundColor: COLOR.GRAYISH_BLUE_0,
      borderColor: COLOR.GRAYISH_BLUE_0,
      color: COLOR.WHITE,
      '&:hover': {
        backgroundColor: COLOR.GRAYISH_BLUE_0,
        borderColor: COLOR.GRAYISH_BLUE_0,
      },
    },
  },
}

const loadingStyles: CSSObject = {
  primary: {
    cursor: 'not-allowed',
    [`& .spinner`]: {
      position: 'absolute',
      left: 'calc(50% - 13px)',
      top: 'calc(50% - 15px)',
    },
    backgroundColor: COLOR.EXTEND_BLUE_LIGHT,
    borderColor: COLOR.EXTEND_BLUE_LIGHT,
    color: COLOR.WHITE,
    '&:hover,&:focus': {
      backgroundColor: COLOR.EXTEND_BLUE_LIGHT,
      borderColor: COLOR.EXTEND_BLUE_LIGHT,
      color: COLOR.WHITE,
    },
  },
  secondary: {
    cursor: 'not-allowed',
    [`& .spinner`]: {
      position: 'absolute',
      left: 'calc(50% - 13px)',
      top: 'calc(50% - 15px)',
    },
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.EXTEND_BLUE_LIGHT,
    color: COLOR.EXTEND_BLUE_LIGHT,
    '&:hover,&:focus': {
      backgroundColor: COLOR.WHITE,
      borderColor: COLOR.EXTEND_BLUE_LIGHT,
      color: COLOR.EXTEND_BLUE_LIGHT,
    },
  },
  gridLink: {
    cursor: 'not-allowed',
    [`& .spinner`]: {
      position: 'absolute',
      left: 'calc(50% - 13px)',
      top: 'calc(50% - 15px)',
    },
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.WHITE,
    color: COLOR.EXTEND_BLUE_LIGHT,
    '&:hover,&:focus': {
      backgroundColor: COLOR.WHITE,
      borderColor: COLOR.WHITE,
      color: COLOR.EXTEND_BLUE_LIGHT,
    },
  },
  danger: {
    cursor: 'not-allowed',
    [`& .spinner`]: {
      position: 'absolute',
      left: 'calc(50% - 13px)',
      top: 'calc(50% - 15px)',
    },
    backgroundColor: COLOR.DANGER_LIGHT,
    borderColor: COLOR.DANGER_LIGHT,
    color: COLOR.WHITE,
    '&:hover,&:focus': {
      backgroundColor: COLOR.DANGER_LIGHT,
      borderColor: COLOR.DANGER_LIGHT,
      color: COLOR.WHITE,
    },
  },
  dangerSecondary: {
    cursor: 'not-allowed',
    [`& .spinner`]: {
      position: 'absolute',
      left: 'calc(50% - 13px)',
      top: 'calc(50% - 15px)',
    },
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.DANGER_LIGHT,
    color: COLOR.DANGER_LIGHT,
    '&:hover,&:focus': {
      backgroundColor: COLOR.WHITE,
      borderColor: COLOR.DANGER_LIGHT,
      color: COLOR.DANGER_LIGHT,
    },
  },
  default: {
    cursor: 'not-allowed',
    [`& .spinner`]: {
      position: 'absolute',
      left: 'calc(50% - 13px)',
      top: 'calc(50% - 15px)',
    },
  },
}

export const kindStyles: Record<ButtonKind, CSSObject> = {
  primary: {
    backgroundColor: COLOR.EXTEND_BLUE,
    borderColor: COLOR.EXTEND_BLUE,
    color: COLOR.WHITE,
    '&:focus': {
      backgroundColor: COLOR.EXTEND_BLUE,
      borderColor: COLOR.EXTEND_BLUE,
    },
    '&:hover,&:active': {
      borderColor: COLOR.EXTEND_BLUE_DARK,
      backgroundColor: COLOR.EXTEND_BLUE_DARK,
    },
  },
  secondary: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.EXTEND_BLUE,
    color: COLOR.EXTEND_BLUE,
    '&:focus': {
      borderColor: COLOR.EXTEND_BLUE,
      color: COLOR.EXTEND_BLUE,
    },
    '&:hover,&:active': {
      color: COLOR.EXTEND_BLUE_DARK,
      borderColor: COLOR.EXTEND_BLUE_DARK,
    },
  },
  gridLink: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.WHITE,
    color: COLOR.EXTEND_BLUE,
    '&:focus': {
      borderColor: COLOR.WHITE,
      color: COLOR.EXTEND_BLUE,
    },
    '&:hover,&:active': {
      color: COLOR.EXTEND_BLUE_DARK,
      borderColor: COLOR.WHITE,
    },
  },
  action: {
    backgroundColor: COLOR.VIVID_ORANGE,
    borderColor: COLOR.VIVID_ORANGE,
    color: COLOR.VERY_DARK_BLUE_0,
    '&:hover': {
      backgroundColor: COLOR.LIGHT_YELLOW,
    },
    '&:hover:enabled': {
      backgroundColor: COLOR.LIGHT_YELLOW,
      borderColor: COLOR.LIGHT_YELLOW,
    },
  },
  danger: {
    backgroundColor: COLOR.STATE_DANGER,
    borderColor: COLOR.STATE_DANGER,
    color: COLOR.WHITE,
    '&:focus': {
      borderColor: COLOR.STATE_DANGER,
      backgroundColor: COLOR.STATE_DANGER,
    },
    '&:hover,&:active': {
      borderColor: COLOR.DANGER_DARK,
      backgroundColor: COLOR.DANGER_DARK,
    },
  },
  dangerSecondary: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.STATE_DANGER,
    color: COLOR.STATE_DANGER,
    '&:focus': {
      borderColor: COLOR.STATE_DANGER,
      color: COLOR.STATE_DANGER,
    },
    '&:hover,&:active': {
      borderColor: COLOR.DANGER_DARK,
      color: COLOR.DANGER_DARK,
    },
  },
  dark: {
    borderColor: COLOR.WHITE,
    color: COLOR.WHITE,
    backgroundColor: COLOR.VERY_DARK_GRAYISH_BLUE_1,
    '&:hover': {
      backgroundColor: COLOR.WHITE,
      color: COLOR.BLACK,
    },
  },
  // disabled appearance styling
  disabled: {
    backgroundColor: COLOR.GRAYISH_BLUE_0,
    borderColor: COLOR.GRAYISH_BLUE_0,
    color: COLOR.WHITE,
  },
  // new kind is specific to chat portal buttons
  pillButton: {
    borderColor: COLOR.STRONG_BLUE,
    color: COLOR.STRONG_BLUE,
    backgroundColor: COLOR.WHITE,
    borderRadius: 16,
    fontWeight: 'normal',
    '&:hover': {
      backgroundColor: COLOR.STRONG_BLUE,
      color: COLOR.WHITE,
    },
  },
  // Error banner in customer portal
  whiteOutline: {
    borderColor: COLOR.WHITE,
    backgroundColor: 'transparent',
    color: COLOR.WHITE,
    '&:hover': {
      backgroundColor: COLOR.WHITE,
      color: COLOR.BRIGHT_RED_1,
    },
  },
}

export const kindStylesInverted: Record<ButtonKind, CSSObject> = {
  primary: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.WHITE,
    color: COLOR.EXTEND_BLUE,
    '&:focus': {
      borderColor: COLOR.WHITE,
    },
    '&:hover,&:active': {
      color: COLOR.EXTEND_BLUE_DARK,
    },
  },
  secondary: {
    borderColor: COLOR.WHITE,
    color: COLOR.WHITE,
    '&:focus': {
      backgroundColor: COLOR.EXTEND_BLUE,
    },
    '&:hover,&:active': {
      backgroundColor: COLOR.EXTEND_BLUE_DARK,
    },
  },
  gridLink: {
    borderColor: COLOR.WHITE,
    color: COLOR.WHITE,
    '&:focus': {
      backgroundColor: COLOR.EXTEND_BLUE,
    },
    '&:hover,&:active': {
      backgroundColor: COLOR.EXTEND_BLUE_DARK,
    },
  },
  action: {
    backgroundColor: COLOR.WHITE,
    borderWidth: 1,
    '&:hover': {
      borderColor: COLOR.BLACK,
      color: COLOR.BLACK,
    },
    '&:hover,&:focus': {
      color: COLOR.BLACK,
    },
  },
  danger: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.WHITE,
    color: COLOR.STATE_DANGER,
    '&:focus': {
      borderColor: COLOR.WHITE,
    },
    '&:hover,&:active': {
      color: COLOR.DANGER_DARK,
    },
  },
  dangerSecondary: {
    borderColor: COLOR.WHITE,
    color: COLOR.STATE_DANGER,
    '&:focus': {
      color: COLOR.STATE_DANGER,
      borderColor: COLOR.WHITE,
    },
    '&:hover,&:active': {
      color: COLOR.DANGER_DARK,
    },
  },
  dark: {
    backgroundColor: COLOR.WHITE,
    borderWidth: 1,
    '&:hover': {
      borderColor: COLOR.BLACK,
      color: COLOR.BLACK,
    },
    '&:hover,&:focus': {
      color: COLOR.BLACK,
    },
  },
  // disabled appearance styling
  disabled: {
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.WHITE,
    color: COLOR.GRAYISH_BLUE_0,
  },
  // new kind is specific to chat portal buttons
  pillButton: {
    backgroundColor: COLOR.WHITE,
    borderWidth: 1,
    '&:hover': {
      borderColor: COLOR.BLACK,
      color: COLOR.BLACK,
    },
    '&:hover,&:focus': {
      color: COLOR.BLACK,
    },
  },
  // Error banner in customer portal
  whiteOutline: {
    backgroundColor: COLOR.WHITE,
    borderWidth: 1,
    '&:hover': {
      borderColor: COLOR.BLACK,
      color: COLOR.BLACK,
    },
    '&:hover,&:focus': {
      color: COLOR.BLACK,
    },
  },
}

export { Button }
