import type { FC, SyntheticEvent } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { getIn, useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { Button, COLOR, Input, Grid, useToaster, ToastColor } from '@helloextend/zen'
import { useCreateServicerMutation } from '@helloextend/extend-api-rtk-query'
import type { ServicerCreateRequest } from '@helloextend/extend-api-client'
import { toValidPhoneNumber } from '../../../utils/form-utils'
import { schema } from './schema'
import type { Values } from './schema'
import { Divider } from '../../../components/divider'
import { ServicerPaymentSelect } from '../../../components/servicer-payment-select'

const ServicersCreateForm: FC = () => {
  const { toast } = useToaster()
  const history = useHistory()
  const [createServicer, { isLoading, isError }] = useCreateServicerMutation()

  const {
    values,
    errors,
    touched,
    dirty,
    resetForm,
    setFieldValue,
    handleSubmit,
    handleBlur,
    handleChange,
  } = useFormik<Values>({
    initialValues: { ...schema.default() },
    validationSchema: schema,
    onSubmit: async (formValues: Values) => {
      try {
        const servicer = await createServicer(formValues as ServicerCreateRequest).unwrap()
        history.push(`/admin/servicers/${servicer.id}`)
      } catch (err) {
        // nothing
      }
    },
  })

  const hasError = (field: string): boolean => {
    return dirty && getIn(touched, field) && Boolean(getIn(errors, field))
  }

  const handlePaymentSelect = (e: SyntheticEvent<Element>): void => {
    const target = e.currentTarget as HTMLInputElement
    setFieldValue('settings.paymentPreference', target.value)
  }

  const handleCancel = (): void => {
    history.push('/admin/servicers')
  }

  useEffect(() => {
    if (isError) {
      toast({
        message: 'Servicer could not be created. Please try again later.',
        toastColor: ToastColor.red,
      })
    }
    return () => resetForm()
  }, [isError, toast, resetForm])

  return (
    <FormWrapper data-cy="servicers-create-form">
      <form onSubmit={handleSubmit}>
        <SectionHeader>Contact Information</SectionHeader>
        <Grid
          columns={{
            lg: 2,
            md: 2,
            sm: 1,
          }}
          spacing={{
            lg: 3,
            md: 2,
          }}
        >
          <Input
            data-cy="businessName"
            id="businessName"
            label="Servicer Name"
            value={values.businessName}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={hasError('businessName')}
            errorFeedback={errors.businessName}
          />
          <Input
            data-cy="contactPhone"
            id="contact.phone"
            label="Servicer Phone Number"
            value={values.contact.phone}
            onChange={(e) => {
              e.currentTarget.value = toValidPhoneNumber(e.currentTarget.value)
              handleChange(e)
            }}
            onBlur={handleBlur}
            isError={hasError('contact.phone')}
            errorFeedback={errors.contact?.phone}
          />
          <Input
            data-cy="contactEmail"
            id="contact.email"
            label="Servicer Email"
            value={values.contact.email}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={hasError('contact.email')}
            errorFeedback={errors.contact?.email}
          />
          <Input
            data-cy="contactName"
            id="contact.name"
            label="Servicer Contact Name"
            value={values.contact.name}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={hasError('contact.name')}
            errorFeedback={errors.contact?.name}
          />
        </Grid>
        <Divider margin="32px 0px" />
        <SectionHeader>Payment Type</SectionHeader>
        <SelectWrapper>
          <ServicerPaymentSelect
            value={values.settings?.paymentPreference}
            onChange={handlePaymentSelect}
          />
        </SelectWrapper>
        <ButtonWrapper>
          <Button
            emphasis="medium"
            text="Cancel"
            onClick={handleCancel}
            isDisabled={isLoading}
            data-cy="cancel-servicer-btn"
          />
          <Button
            text="Save"
            type="submit"
            isProcessing={isLoading}
            isDisabled={isLoading}
            data-cy="save-servicer-btn"
          />
        </ButtonWrapper>
      </form>
    </FormWrapper>
  )
}

const SectionHeader = styled.h2({
  color: COLOR.BLACK,
  fontSize: 24,
  lineHeight: '32px',
  fontWeight: 600,
  fontStyle: 'normal',
  margin: 0,
  marginBottom: 24,
})

const FormWrapper = styled.div({
  width: '100%',
  display: 'flex',
  padding: 32,
  flexDirection: 'column',
  border: `1px solid ${COLOR.NEUTRAL[100]}`,
  boxSizing: 'border-box',
  borderRadius: 4,
})

const ButtonWrapper = styled.div({
  display: 'flex',
  gap: 16,
  justifyContent: 'flex-end',
  paddingTop: 40,
})

const SelectWrapper = styled.div({
  maxWidth: 546,
})

export { ServicersCreateForm }
