import type { Action } from '../../actions'

export type ServicersSearchDropdownFilterReducerState = string

const initialState: ServicersSearchDropdownFilterReducerState = ''

export default function searchDropdownFilter(
  state = initialState,
  action: Action,
): ServicersSearchDropdownFilterReducerState {
  switch (action.type) {
    case 'SERVICERS_SEARCH_DROPDOWN_FILTER_SET':
      return action.payload
    case 'SERVICERS_SEARCH_DROPDOWN_FILTER_CLEAR':
      return ''
    default:
      return state
  }
}
