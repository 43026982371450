import type { FC } from 'react'
import React, { useEffect, useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { useGetConversationQuery } from '@helloextend/extend-api-rtk-query'
import { useDispatch } from 'react-redux'
import type { ButtonProps } from '@helloextend/zen'
import { AdjudicationTopnav } from '../components'
import { DashboardSpinner } from '../../../../components/dashboard-spinner'
import { CollapsibleConversation } from '../components/collapsible-conversation/collapsible-conversation'
import { useGetFullConversation } from '../../../../hooks/use-get-full-conversation'
import { setConversationAdjudicationThread } from '../../../../store/slices/amp-slice'
import { usePermissions } from '../../../../hooks/use-permissions'
import { Permission } from '../../../../lib/permissions'

const AdjudicationConversationPreview: FC = () => {
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const {
    data: conversationData,
    isFetching,
    isLoading,
  } = useGetConversationQuery(id, { skip: !id })

  const { hasPermission } = usePermissions()
  const hasEditPermission = hasPermission(Permission.ManageAdjudication)

  const threadIds: string[] = (conversationData?.threads || []).map(
    (thread: string) => thread.split(':').shift() as string,
  )

  const handleTopnavClose = (): void => {
    history.push('/admin/adjudication-management/conversations')
  }

  const handleEditClick = useCallback(() => {
    history.push(`/admin/adjudication-management/conversations/${id}/edit`)
  }, [history, id])

  const getConversationPreviewButtons = (): ButtonProps[] => {
    return [
      {
        text: 'Edit',
        isInverted: true,
        size: 'small',
        emphasis: 'high',
        onClick: handleEditClick,
        'data-cy': 'edit-button',
      },
    ]
  }

  const isEditButtonVisible = useMemo(() => {
    return conversationData && conversationData.status === 'draft' && hasEditPermission
  }, [conversationData, hasEditPermission])

  const {
    conversation: fullConversation,
    isLoading: isFullConversationLoading,
    error: hasFullConversationError,
  } = useGetFullConversation(id)

  useEffect(() => {
    if (fullConversation && !isFullConversationLoading && !hasFullConversationError) {
      const thread = fullConversation.threads.find((item) => item?.type === 'adjudication') ?? null
      dispatch(setConversationAdjudicationThread(thread))
    }
  }, [dispatch, fullConversation, hasFullConversationError, isFullConversationLoading])

  return (
    <>
      <AdjudicationTopnav
        title="Conversation Preview"
        name={conversationData?.title}
        status={conversationData?.status}
        isLoading={isLoading || isFetching}
        actionButtons={isEditButtonVisible ? getConversationPreviewButtons() : undefined}
        onClose={handleTopnavClose}
      />
      {isFetching || isLoading ? (
        <DashboardSpinner data-cy="adjudication-management-conversation-preview-dashboard-spinner" />
      ) : (
        <>
          <CollapsibleConversation threadIds={threadIds} isEditMode={false} />
        </>
      )}
    </>
  )
}

export { AdjudicationConversationPreview }
