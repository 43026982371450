import type { ClaimPhotoDetail } from '@helloextend/extend-api-client'
import type { ReducerState } from './reducers'

type Selector<T> = (state: ReducerState, ...rest: any[]) => T

export const getByClaimId: Selector<ClaimPhotoDetail[] | undefined> = (state, claimId: string) =>
  state.byClaimId[claimId]
export const getIsLoading: Selector<boolean> = (state) => state.isLoading
export const getIsUploading: Selector<boolean> = (state, name: string) =>
  state.isUploadingByName[name]
export const getIsCreating: Selector<boolean> = (state) => state.isCreating
export const getIsDeleting: Selector<boolean> = (state) => state.isDeleting
export const getError: Selector<boolean> = (state, name: string) => state.errorByName[name]
