import React from 'react'
import type { FC } from 'react'
import { useParams } from 'react-router'
import styled from '@emotion/styled'
import {
  Breadcrumbs,
  COLOR,
  Spinner,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@helloextend/zen'
import {
  useGetStoreQuery,
  useListPlanSetsByPlanSetIdsQuery,
} from '@helloextend/extend-api-rtk-query'
import { PlanSetPriceBands } from './plan-set-details-components/plan-set-price-bands'

const PlanSetDetails: FC = () => {
  const { storeId, planSetId } = useParams<{
    storeId: string
    planSetId: string
  }>()
  const { toast } = useToaster()

  const {
    data: storeData,
    isLoading: isStoreDataLoading,
    isError: isStoreError,
  } = useGetStoreQuery({ storeId, version: 'latest' })

  const {
    data: planSetData,
    isLoading: isPlanSetDataLoading,
    isError: isPlanSetDataError,
  } = useListPlanSetsByPlanSetIdsQuery(planSetId)

  const planSetPriceBandData = planSetData?.items[0].priceBands

  if (isStoreDataLoading || isPlanSetDataLoading) {
    return <Spinner color={COLOR.BLUE[800]} />
  }

  if (!storeData || !planSetData || isStoreError || isPlanSetDataError) {
    toast({
      message: 'Something went wrong. Please try again.',
      toastColor: ToastColor.red,
      toastDuration: ToastDuration.long,
    })

    return null
  }

  return (
    <>
      <HeaderContainer>
        <Breadcrumbs
          data-cy="plan-set-details-breadcrumbs"
          crumbs={[
            { text: 'Stores', to: '/admin/stores/' },
            {
              text: storeData?.name || storeData?.id || 'Store',
              to: `/admin/stores/${storeData?.id}`,
            },
            { text: 'Product Protection', to: `/admin/stores/${storeData?.id}/product-protection` },
            {
              text: planSetData?.items[0].name || planSetData?.items[0].id || 'Plan Set',
            },
          ]}
        />
      </HeaderContainer>
      <Title data-cy="plan-set-details-name">{planSetData?.items[0].name}</Title>
      <PlanSetPriceBands planSetPriceBandData={planSetPriceBandData} />
    </>
  )
}

const Title = styled.h1({
  fontSize: 32,
  lineHeight: '44px',
  fontWeight: 800,
  margin: '24px 0 8px',
})

const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

export { PlanSetDetails }
