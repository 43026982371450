import type { User, StoreUser } from '@helloextend/extend-api-client'
import { StoreUserRole } from '@helloextend/extend-api-client'
import type { ReducerState } from './reducers'
import type { UsersErrorReducerState } from './reducers/error'

export function getByAccountId(state: ReducerState, accountId: string): User | null {
  return state.byAccountId[accountId]
}

export const getIsLoading = (state: ReducerState): boolean => {
  return state.isLoading
}

export const getIsUpdating = (state: ReducerState): boolean => {
  return state.isUpdating
}

export const getIsSuccess = (state: ReducerState): boolean => state.isSuccess
export const getUsersError = (state: ReducerState): UsersErrorReducerState => state.error

export function getByStoreAccountId(state: ReducerState): StoreUser[] {
  return Object.values(state.byStoreAccountId).map((user) => user)
}

export function getAccountOwners(state: ReducerState): StoreUser[] {
  return Object.values(state.byStoreAccountId).filter((user) => user.role === StoreUserRole.user)
}

export function getUserById(
  state: ReducerState,
  userId: string,
  storeAccountId: string,
): StoreUser {
  const key = `STORE::${storeAccountId}::USER::${userId}`
  return state.byStoreAccountId[key]
}

export const getIsInviteEmailResent = (state: ReducerState): boolean => {
  return state.isInviteEmailResent
}
