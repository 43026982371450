import type { FC, ChangeEvent, FormEvent } from 'react'
import React, { useState } from 'react'
import type { DefaultMessage } from '@helloextend/extend-api-rtk-query'
import ReactMarkdown from 'react-markdown'
import styled from '@emotion/styled'
import { COLOR, Button, Grid } from '@helloextend/zen'
import type { MultiSelectPrompt, Reply, Slot } from '@helloextend/extend-api-client'
import { getMessagesContent } from '../../utils'

interface AnswerSelectionProps {
  onSubmit: (slot: Slot, slotValue: string | number) => void
  isLoading: boolean
  reply: Reply
}

const AnswerSelection: FC<AnswerSelectionProps> = ({ isLoading, onSubmit, reply }) => {
  // due to some selections on claims-assistant registered as an empty string, we are
  // using "null" to represent an untouched state. This allows us to disable the form
  // up until the site user selects an option
  const [selectedValue, setSelectedValue] = useState<number | null>(null)

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const selectedIndex = +e.target.value
    setSelectedValue(selectedIndex)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (reply.prompt?.slot && selectedValue !== null) {
      const selectedOption = (reply.prompt as MultiSelectPrompt).options[selectedValue]
      onSubmit(reply.prompt.slot, selectedOption.value)
    }
  }

  const content = getMessagesContent(reply.messages as DefaultMessage[])

  return (
    <ContentWrapper>
      {content && (
        <h2 data-cy="message-content">
          <ReactMarkdown>{content}</ReactMarkdown>
        </h2>
      )}
      <form onSubmit={handleSubmit}>
        <Grid columns={{ lg: 2, md: 2, sm: 1 }} spacing={1} data-cy="answer-selection-grid">
          {(reply.prompt as MultiSelectPrompt).options.map(({ outputText, value }, index) => (
            <SelectContainer
              data-cy={value}
              htmlFor={`answer-selection-${value}-${outputText}`}
              key={`${value}-${outputText}`}
            >
              <input
                checked={selectedValue === index}
                aria-checked={selectedValue === index}
                aria-labelledby={`answer-selection-${value}-${outputText}`}
                id={`answer-selection-${value}-${outputText}`}
                disabled={isLoading}
                name="answer-selection"
                onChange={handleChange}
                tabIndex={0}
                type="radio"
                value={index}
              />
              <div className="select-container-box" data-cy="select-container-box">
                {outputText}
                <span className="check-circle" />
              </div>
            </SelectContainer>
          ))}
        </Grid>
        <ButtonWrapper>
          <Button
            data-cy="continue-button"
            text="Continue"
            isDisabled={selectedValue === null}
            isProcessing={isLoading}
            type="submit"
          />
        </ButtonWrapper>
      </form>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  width: '100%',
  maxWidth: 816,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
})

const SelectContainer = styled.label({
  input: {
    display: 'none',
  },
  'input[type=radio]:checked + .select-container-box': {
    border: `1px solid ${COLOR.BLUE[800]}`,
    backgroundColor: COLOR.NEUTRAL[100],
    '> .check-circle': {
      opacity: 1,
    },
  },
  '.select-container-box:hover': {
    border: `1px solid ${COLOR.BLUE[800]}`,
    backgroundColor: COLOR.NEUTRAL[100],
  },
  '.select-container-box': {
    alignItems: 'center',
    backgroundColor: COLOR.WHITE,
    border: `1px solid ${COLOR.NEUTRAL[200]}`,
    borderRadius: 4,
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    height: 80,
    justifyContent: 'space-between',
    marginBottom: 32,
    padding: '0 24px 0 24px',
    transition: 'all .25s ease-in-out',
    width: 392,
  },
  '.check-circle': {
    opacity: 0,
    transition: 'opacity .25s ease-in-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 24,
    width: 24,
    height: 24,
    backgroundColor: `${COLOR.BLUE[800]}`,
    borderRadius: 30,
    // Checkmark
    '::after': {
      content: "' '",
      width: 6,
      height: 12,
      border: `2px solid ${COLOR.WHITE}`,
      borderWidth: '0 2px 2px 0',
      transform: 'rotate(45deg)',
      boxSizing: 'border-box',
    },
  },
})

const ButtonWrapper = styled.div({
  marginTop: 32,
  display: 'flex',
  justifyContent: 'center',
})

export { AnswerSelection, AnswerSelectionProps }
