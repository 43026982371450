import React from 'react'
import type { ProductMappedRecord } from '@helloextend/extend-api-rtk-query'
import type { FilterOptions } from '@helloextend/merchants-ui'
import { CellText } from '@helloextend/merchants-ui'
import type { Column, Cell } from 'react-table'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'

const productMappingColumns = (
  checkboxColumn: Column<ProductMappedRecord>,
): Array<Column<ProductMappedRecord>> => [
  checkboxColumn,
  {
    Header: 'Product Title',
    accessor: 'title',
    filter: 'advancedText',
    width: 75,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellTextMultiLine data-cy="product-productTitle">{value}</CellTextMultiLine>
    ),
  },
  // Prepend 'k_' to product_id for accessor so that the type is forced to be a string (not a number)
  // This helps the filter menu keep the checked values on top (not possible when key is a number)
  // When displaying, also remove this prepended 'k_' so that only product_id is shown
  {
    Header: 'Product Id',
    id: 'product_id',
    accessor: (row) => `k_${row.product_id}`,
    filter: 'multiSelectFilter',
    width: 50,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="product-productId">{value.replace('k_', '')}</CellText>
    ),
  },
  {
    Header: 'Extend Category',
    accessor: 'extend_category',
    width: 50,
    filter: 'multiSelectFilter',
    Cell: ({ value }: Cell): JSX.Element => <CellText data-cy="product-category">{value}</CellText>,
  },
  {
    Header: 'Merchant Category',
    accessor: 'merchant_category',
    width: 50,
    filter: 'multiSelectFilter',
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="product-merchant-category">{value}</CellText>
    ),
  },
  {
    Header: 'SKU',
    accessor: 'sku',
    width: 35,
    Cell: ({ value }: Cell): JSX.Element => <CellText data-cy="product-sku">{value}</CellText>,
  },
  {
    Header: 'Description',
    accessor: 'description',
    filter: 'advancedText',
    width: 50,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="product-description">{value}</CellText>
    ),
  },
  {
    Header: 'Price',
    accessor: 'price',
    filter: 'numberRange',
    width: 25,
    Cell: ({ value }: Cell): JSX.Element => <CellText data-cy="product-price">{value}</CellText>,
  },
  {
    Header: 'Base Plan Id',
    accessor: 'base_plan',
    width: 50,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="product-basePlanId">{value}</CellText>
    ),
  },
  {
    Header: 'Agent Plan Selection',
    filter: 'multiSelectFilter',
    width: 50,
    accessor: (d) =>
      `${d.mapping_status !== 'validation_required' && d.plans ? d.plans.join(', ') : ''}`,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellTextMultiLine data-cy="product-agentPlanSelection">{value}</CellTextMultiLine>
    ),
  },
  {
    Header: 'Mapping Status',
    accessor: 'mapping_status',
    filter: 'multiSelectFilter',
    width: 50,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellText data-cy="product-mappingStatus">{value}</CellText>
    ),
  },
  {
    Header: 'Last Updated',
    accessor: (d) =>
      `${new Date(Date.parse(d.last_updated_at))
        .toLocaleString('en-US', { timeZone: 'UTC' })
        .replace(',', ' @')} ${d.last_updated_by}`,
    width: 50,
    Cell: ({ value }: Cell): JSX.Element => (
      <CellTextMultiLine data-cy="product-lastUpdatedAt">{value}</CellTextMultiLine>
    ),
  },
]

const objectSort = (
  filterObject: Record<string, { label: string }>,
): Record<string, { label: string }> => {
  // sorts Record object keys
  return Object.fromEntries(Object.entries(filterObject).sort((a, b) => a[0].localeCompare(b[0])))
}

const getFilterOptions = (products: ProductMappedRecord[]): Record<string, FilterOptions> => {
  const dynamicFilters = products.reduce(
    (obj, product) => {
      return {
        ...obj,
        mapping_status: objectSort({
          ...obj.mapping_status,
          [product.mapping_status]: { label: product.mapping_status },
        }),
        product_id: objectSort({
          ...obj.product_id,
          [`k_${product.product_id}`]: { label: product.product_id }, // prepend with 'k_' to match accessor for column
        }),
        merchant_category: objectSort({
          ...obj.merchant_category,
          [product.merchant_category ?? '']: { label: product.merchant_category || 'None' },
        }),
        extend_category: objectSort({
          ...obj.extend_category,
          [product.extend_category]: { label: product.extend_category },
        }),
      }
    },
    { mapping_status: {}, product_id: {}, merchant_category: {}, extend_category: {} },
  )

  return {
    title: {
      type: 'advancedText',
      label: 'Product Title',
    },
    description: {
      type: 'advancedText',
      label: 'Description',
    },
    price: {
      type: 'numberRange',
      label: 'Price',
      isCurrency: true,
      lowLabel: 'From',
      highLabel: 'To',
    },
    ...(products.length && {
      mapping_status: {
        label: 'Mapping Status',
        type: 'nestedCheckbox',
        includeSearchBar: true,
        keepCheckedOnSearch: true,
        filters: dynamicFilters.mapping_status,
      },
      product_id: {
        label: 'Product ID',
        type: 'nestedCheckbox',
        includeSearchBar: true,
        keepCheckedOnSearch: true,
        bulkSearchCSV: true,
        filters: dynamicFilters.product_id,
      },
      extend_category: {
        type: 'nestedCheckbox',
        label: 'Extend Category',
        includeSearchBar: true,
        keepCheckedOnSearch: true,
        filters: dynamicFilters.extend_category,
      },
      merchant_category: {
        type: 'nestedCheckbox',
        label: 'Merchant Category',
        includeSearchBar: true,
        keepCheckedOnSearch: true,
        filters: dynamicFilters.merchant_category,
      },
    }),
  }
}

const CellTextMultiLine = styled.p({
  fontSize: 12,
  lineHeight: '24px',
  color: COLOR.BLACK_PRIMARY,
  wordWrap: 'break-word',
  textAlign: 'left',
})

export { productMappingColumns, getFilterOptions }
