import type { Permission } from '../../lib/permissions'

type NavLinkDefinition = {
  text: string
  icon?: React.ReactNode
  dataCy?: string
}

export type NavLink = NavLinkDefinition & {
  to: string
  permission?: Permission
}

export type NavDropdown = NavLinkDefinition & {
  dropdownItems: NavLink[]
}
export const instanceOfDropdown = (object: NavLink | NavDropdown): object is NavDropdown => {
  return 'dropdownItems' in object
}
