import type { Action } from '../actions'
import { createBooleanReducer } from '../../factories'

const isLoading = createBooleanReducer<Action>(
  [
    'CONTRACTS_FETCH_REQUEST',
    'CONTRACTS_FETCH_REQUEST_V2',
    'CONTRACTS_FETCH_ALL_REQUEST',
    'CONTRACT_AUDIT_HISTORY_REQUEST',
  ],
  [
    'CONTRACTS_FETCH_SUCCESS',
    'CONTRACTS_FETCH_SUCCESS_V2',
    'CONTRACTS_FETCH_ALL_SUCCESS',
    'CONTRACTS_FETCH_FAILURE',
    'CONTRACTS_FETCH_FAILURE_V2',
    'CONTRACTS_FETCH_ALL_FAILURE',
    'CONTRACT_AUDIT_HISTORY_SUCCESS',
    'CONTRACT_AUDIT_HISTORY_FAILURE',
  ],
)

export default isLoading
