import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AdjudicationTopnav } from '../components'
import type { RootState } from '../../../../reducers'
import * as selectors from '../../../../reducers/selectors'

type ThreadEditorTopnavProps = {
  isLoading: boolean
  isThreadDirty: boolean
  onClose: () => void
  onClickSave: () => void
  onClickPublish: () => void
}

const ThreadEditorTopnav: FC<ThreadEditorTopnavProps> = ({
  isLoading,
  isThreadDirty,
  onClose,
  onClickPublish,
  onClickSave,
}) => {
  const selectedThread = useSelector((state: RootState) => selectors.getSelectedThread(state))
  const isPublishedAdjudicationThread =
    selectedThread?.type === 'adjudication' && selectedThread?.status === 'published'
  const isThreadTextAssigned = useSelector((state: RootState) =>
    selectors.getIsSelectedThreadTextAssigned(state),
  )
  const isSaveButtonDisabled = useMemo(() => {
    return !isThreadTextAssigned || !isThreadDirty
  }, [isThreadTextAssigned, isThreadDirty])

  const isPublishButtonDisabled = useMemo(() => {
    return (
      !isThreadTextAssigned ||
      selectedThread?.script.length === 0 ||
      (selectedThread?.status === 'published' && !isThreadDirty)
    )
  }, [isThreadTextAssigned, isThreadDirty, selectedThread?.script.length, selectedThread?.status])

  return (
    <AdjudicationTopnav
      name={selectedThread?.title}
      status={selectedThread?.status}
      title="Thread Builder"
      isLoading={isLoading}
      onClose={onClose}
      actionButtons={
        isPublishedAdjudicationThread
          ? []
          : [
              {
                text: 'Save',
                emphasis: 'medium',
                color: 'neutral',
                isInverted: true,
                onClick: onClickSave,
                size: 'small',
                isDisabled: isSaveButtonDisabled,
                'data-cy': 'thread-save-changes-button',
              },
              {
                text: 'Publish',
                isInverted: true,
                size: 'small',
                onClick: onClickPublish,
                isDisabled: isPublishButtonDisabled,
                'data-cy': 'thread-publish-changes-button',
              },
            ]
      }
    />
  )
}

export { ThreadEditorTopnav }
