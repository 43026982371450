import { currency, date } from '@helloextend/client-utils'
import type {
  CreditFulfillmentMetaData,
  Money,
  ServiceOrder,
  ServiceOrderReplacementStatus,
  ServiceOrderStatus,
} from '@helloextend/extend-api-client'
import { startCase } from 'lodash/fp'
import type { FieldMapperConfig } from '../../../../../../../../../../components/data-display-group'
import { fieldMapper } from '../../../../../../../../../../components/data-display-group'
import type { DataDisplayGroupProps } from '../../../../../../../../../../components/data-display-group/data-display-group'

const serviceOrderStatuses: Array<Omit<ServiceOrderStatus, ServiceOrderReplacementStatus>> = [
  'created',
  'assigned',
  'accepted',
  'replacement_approved',
  'checked_in',
  'repair_started',
  'action_required',
  'fulfilled',
  'payment_requested',
  'payment_approved',
]

const hasMinimumStatus = (serviceOrder?: ServiceOrder, requiredStatus = 'checked_in'): boolean => {
  if (!serviceOrder) return false
  return (
    serviceOrder.status === requiredStatus ||
    serviceOrderStatuses.indexOf(serviceOrder.status) > serviceOrderStatuses.indexOf(requiredStatus)
  )
}

const formatCurrency = (value?: Money): string => {
  if (!value) return '—'
  return currency.format(value.amount, value.currencyCode)
}

const formatStartCase = (value?: string): string => startCase(value ?? '')

const formatDate = (value?: number): string => {
  return (typeof value === 'number' && date.format(value, 'MMM DD YYYY')) || ''
}

const getServiceOrderStatusDateField = (
  serviceOrder: ServiceOrder,
): FieldMapperConfig<ServiceOrder> => {
  switch (serviceOrder.status) {
    case 'assigned':
      return {
        key: 'repairMetaData.assignedAt',
        label: 'Assigned Date',
      }
    case 'accepted':
      if (serviceOrder.serviceType === 'repair_depot') {
        return {
          key: 'repairMetaData.acceptedAt',
          label: 'Accepted Date',
        }
      }
      return {
        key: 'createdAt',
        label: 'Created Date',
      }
    case 'replacement_approved':
    case 'action_required':
      return {
        key: '',
        label: 'Replacement Approved Date',
      }
    case 'checked_in':
      return { key: 'repairMetaData.checkedInAt', label: 'Checked In Date' }
    case 'fulfilled':
      if (serviceOrder.serviceType === 'repair_depot') {
        return {
          key: 'repairMetaData.repairCompletedAt',
          label: 'Repair Completed Date',
        }
      }
      return {
        key: '',
        label: 'Resolved Date',
      }
    default:
      if (serviceOrder.serviceType === 'replace') {
        return {
          key: '',
          label: 'Resolved Date',
        }
      }
      return hasMinimumStatus(serviceOrder, 'repair_started')
        ? { key: 'repairMetaData.repairStartedAt', label: 'Repair Started Date' }
        : { key: 'createdAt', label: 'Created Date' }
  }
}

/**
 * Dynamically shows or hides certain fields based on the current status of the service order
 */
const getServiceOrderInfoData = (
  serviceOrder?: ServiceOrder,
  coverageAmountRemaining?: Money,
): DataDisplayGroupProps['data'] => {
  if (serviceOrder == null) return []

  const baseFields = [
    { key: 'id', label: 'Service Order ID' },
    { key: 'serviceType', label: 'Service Type', transformFn: formatStartCase },
    { ...getServiceOrderStatusDateField(serviceOrder), transformFn: formatDate },
    {
      key: 'coverageAmountRemaining',
      label: 'Remaining Limit of Liability',
      transformFn: formatCurrency,
    },
    {
      key: 'assignedServicer.businessName',
      label: 'Servicer',
      isHidden: !hasMinimumStatus(serviceOrder, 'assigned'),
      block:
        serviceOrder?.serviceType !== 'replace' && !serviceOrder?.configurations?.rmaNumberRequired,
    },
  ]

  const repairFields = [
    ...baseFields,
    {
      key: 'rmaNumber',
      label: 'RMA Number',
      isHidden:
        !hasMinimumStatus(serviceOrder, 'accepted') ||
        !serviceOrder?.configurations?.rmaNumberRequired,
    },
    {
      key:
        serviceOrder.serviceType === 'repair_depot'
          ? 'repairMetaData.diagnosticExplanation'
          : 'repairMetaData.repairExplanation',
      label:
        serviceOrder.serviceType === 'repair_depot'
          ? 'Diagnostic Explanation'
          : 'Repair Explanation',
      isHidden: !hasMinimumStatus(serviceOrder, 'repair_started'),
    },
  ]

  const replaceFields = [
    ...baseFields,
    {
      key: 'fulfillmentMetaData.method',
      label: 'Fulfillment Resolution',
      isHidden: !hasMinimumStatus(serviceOrder, 'fulfilled'),
      transformFn: startCase,
    },
    {
      key: 'fulfillmentMetaData.productName',
      label: 'Replacement Item',
      isHidden:
        !hasMinimumStatus(serviceOrder, 'fulfilled') ||
        serviceOrder.fulfillmentMetaData?.method === 'gift_card',
    },
    {
      key: 'fulfillmentMetaData.orderNumber',
      label: 'Order Number',
      isHidden:
        !hasMinimumStatus(serviceOrder, 'fulfilled') ||
        serviceOrder.fulfillmentMetaData?.method === 'gift_card',
    },
    {
      key: 'fulfillmentMetaData.redemptionUrl',
      label: 'Redemption URL',
      isHidden: !(serviceOrder.fulfillmentMetaData as CreditFulfillmentMetaData)?.redemptionUrl,
      includeCopyAction: (serviceOrder.fulfillmentMetaData as CreditFulfillmentMetaData)
        ?.redemptionUrl,
    },
  ]

  const fields = serviceOrder.serviceType === 'replace' ? replaceFields : repairFields

  return [
    {
      values: fieldMapper<ServiceOrder & { coverageAmountRemaining?: Money }>(
        { ...serviceOrder, coverageAmountRemaining },
        fields,
      ),
    },
  ]
}

export { getServiceOrderInfoData }
