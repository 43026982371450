import type { SPPlanPriceBandMapping, Store } from '@helloextend/extend-api-client'
import type { FC } from 'react'
import React from 'react'
import { Form, Formik } from 'formik'
import {
  useGetSPPriceBandMappingsQuery,
  useUpdateSPPriceBandMappingsMutation,
  useUpdateStoreMutation,
} from '@helloextend/extend-api-rtk-query'
import { COLOR, Spinner } from '@helloextend/zen'
import styled from '@emotion/styled'
import { useStandardToast } from '@helloextend/merchants-ui'
import { getDecimalFromPercentage } from '@helloextend/client-utils/src/number'
import { ShippingProtectionRadioGroup } from '../shipping-protection-purchase-model-radio-group/shipping-protection-purchase-model-radio-group'
import type { Values } from './schema'
import { mapFormValuesToPriceBands, getInitialPurchaseModelValues, schema } from './schema'
import { ShippingProtectionPurchaseModelVariableForm } from '../shipping-protection-purchase-model-variable-form/shipping-protection-purchase-model-variable-form'

type ShippingProtectionPurchaseModelFormProps = {
  store: Store
}

const ShippingProtectionPurchaseModelForm: FC<ShippingProtectionPurchaseModelFormProps> = ({
  store,
}) => {
  const { data, isLoading } = useGetSPPriceBandMappingsQuery(store.id)

  const [updateSPPriceBandMappings, { isLoading: isUpdating }] =
    useUpdateSPPriceBandMappingsMutation()

  const [updateStore, { isLoading: isUpdatingStore }] = useUpdateStoreMutation()

  const { toastError, toastCompleted } = useStandardToast()

  const handleSubmit = async (formValues: Values): Promise<void> => {
    const errorMessage = 'Something went wrong. Please try again.'
    const { priceBands, purchaseModel, planId, merchantSpRevenueSharePercentage } = formValues
    const { shippingProtection, id } = store

    const newMerchantSpRevenueSharePercentageValue = getDecimalFromPercentage(
      merchantSpRevenueSharePercentage,
    )

    const shouldUpdateStore =
      shippingProtection?.purchaseModel !== formValues.purchaseModel ||
      shippingProtection?.planId !== formValues.planId ||
      merchantSpRevenueSharePercentage !== newMerchantSpRevenueSharePercentageValue ||
      shippingProtection?.offersByCategoryEnabled !== formValues.offersByCategoryEnabled
    // update store if the purchaseModel or fallback plan details have been updated
    if (shouldUpdateStore) {
      try {
        await updateStore({
          storeId: id,
          data: {
            shippingProtection: {
              purchaseModel,
              planId,
              offersByCategoryEnabled: formValues.offersByCategoryEnabled,
            },
            merchantSpRevenueSharePercentage: newMerchantSpRevenueSharePercentageValue,
          },
          version: 'latest',
        }).unwrap()
      } catch {
        toastError(errorMessage)
      }
    }

    if (purchaseModel === 'PRICE_BAND') {
      try {
        await updateSPPriceBandMappings({
          storeId: store.id,
          priceBands: mapFormValuesToPriceBands(priceBands as SPPlanPriceBandMapping[]),
        }).unwrap()
        toastCompleted('Purchase Model has been successfully updated.')
      } catch {
        toastError(errorMessage)
      }
    }
  }

  if (isLoading)
    return (
      <SpinnerContainer>
        <Spinner data-cy="loading-spinner" color={COLOR.BLUE[800]} />
      </SpinnerContainer>
    )

  return (
    <Formik
      initialValues={getInitialPurchaseModelValues(store || {}, data?.priceBands)}
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <Form>
        <ShippingProtectionRadioGroup />
        <ShippingProtectionPurchaseModelVariableForm isLoading={isUpdating || isUpdatingStore} />
      </Form>
    </Formik>
  )
}

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export { ShippingProtectionPurchaseModelForm }
