import type { FC } from 'react'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { AuthLayout } from '../../hoc/auth-layout'
import * as selectors from '../../reducers/selectors'
import { LoginForm } from './login-form'
import type { RootState } from '../../reducers'
import { HeadTag } from '../../components/head-tag'
import { router as routerActions } from '../../actions'

interface SP {
  isLoggedIn: ReturnType<typeof selectors.getIsLoggedIn>
  loginRedirectPath: ReturnType<typeof selectors.getLoginRedirectPath>
}

interface DP {
  clearRedirectPath: typeof routerActions.clearLoginRedirectPath
}

type LoginPageProps = SP & DP

const Component: FC<LoginPageProps> = ({ isLoggedIn, loginRedirectPath, clearRedirectPath }) => {
  useEffect(() => {
    return () => {
      clearRedirectPath()
    }
  }, [isLoggedIn, clearRedirectPath])

  if (isLoggedIn) {
    const redirectTo = loginRedirectPath || '/'
    return <Redirect to={redirectTo} />
  }

  return (
    <AuthLayout showFooter>
      <HeadTag siteTitle="Extend | Login" />
      <LoginForm />
    </AuthLayout>
  )
}

const Login = connect(
  (state: RootState): SP => ({
    isLoggedIn: selectors.getIsLoggedIn(state),
    loginRedirectPath: selectors.getLoginRedirectPath(state),
  }),
  {
    clearRedirectPath: routerActions.clearLoginRedirectPath,
  } as DP,
)(Component)

export { Component, Login }
