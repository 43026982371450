import type { Action } from '../actions'
import { createErrorReducer } from '../../factories'

const error = createErrorReducer<Action>(
  [
    'SERVICE_ORDERS_FETCH_FAILURE',
    'SERVICE_ORDERS_APPROVE_REPLACEMENT_FAILURE',
    'SERVICE_ORDERS_FULFILL_FAILURE',
    'SERVICE_ORDERS_ASSIGN_FAILURE',
    'SERVICE_ORDERS_START_REPAIR_FAILURE',
    'SERVICE_ORDERS_APPROVE_FOR_PAYMENT_FAILURE',
    'SERVICE_ORDERS_REQUEST_FOR_PAYMENT_FAILURE',
  ],
  [
    'SERVICE_ORDERS_FETCH_SUCCESS',
    'SERVICE_ORDERS_APPROVE_REPLACEMENT_SUCCESS',
    'SERVICE_ORDERS_FULFILL_SUCCESS',
    'SERVICE_ORDERS_ASSIGN_SUCCESS',
    'SERVICE_ORDERS_START_REPAIR_SUCCESS',
    'SERVICE_ORDERS_APPROVE_FOR_PAYMENT_SUCCESS',
    'SERVICE_ORDERS_REQUEST_FOR_PAYMENT_SUCCESS',
    'SERVICE_ORDERS_ERROR_RESET',
  ],
)

export default error
