import {
  useCreatePlanTermsVersionUploadUrlMutation,
  useListPlanTermsQuery,
  useUploadTermsToS3UrlMutation,
} from '@helloextend/extend-api-rtk-query'
import type { FC } from 'react'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useStandardToast } from '@helloextend/merchants-ui'
import styled from '@emotion/styled'
import {
  Breadcrumbs,
  COLOR,
  DisplayLarge,
  Info,
  InlineAlert,
  InlineAlertColor,
  ModalController,
  Paragraph,
  Spinner,
} from '@helloextend/zen'
import { useToggle } from '@helloextend/client-hooks'
import { TermsForm } from '../components/terms-form/terms-form'
import { fileToBuffer } from '../../../../utils/file-to-buffer'
import { TermsNewVersionModal } from '../components/terms-new-version-modal/terms-new-version-modal'
import { findLatestVersion } from '../terms-data-table/table-config'

const CreateTermsVersion: FC = () => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [description, setDescription] = useState<string>('')
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(true)
  const { termsId } = useParams<{ termsId: string }>()
  const history = useHistory()
  const { data: planTermsList, isLoading: isListLoading } = useListPlanTermsQuery()

  const [isModalDisplayed, { on: handleModalOn, off: handleModalOff }] = useToggle(false)

  const [createUrl, { isLoading: isGettingUrl }] = useCreatePlanTermsVersionUploadUrlMutation()
  const [uploadFile, { isLoading: isUploading }] = useUploadTermsToS3UrlMutation()

  const handleCancel = (isRefetch = false): void => {
    history.push(`/admin/terms`, { isRefetch })
  }

  const currentTerms = planTermsList?.find((item) => item.termsId === termsId)

  const currentTermsVersion = Number(findLatestVersion(currentTerms?.versions || [])?.version)

  const { toastCompleted, toastError } = useStandardToast()

  const handleOnUploadClick = async (file: File, descriptionText: string): Promise<void> => {
    setUploadedFile(file)
    setDescription(descriptionText)
    handleModalOn()
  }

  const handleUploadFile = async (): Promise<void> => {
    if (!uploadedFile) return

    let url

    try {
      const res = await createUrl({
        filename: uploadedFile.name,
        description,
        termsId,
      }).unwrap()
      url = res.url
    } catch {
      toastError('Something went wrong. Please try again.')
      return
    }

    const fileBuffer = await fileToBuffer(uploadedFile)
    if (!url || !fileBuffer) return
    try {
      await uploadFile({ url, file: fileBuffer }).unwrap()
      toastCompleted(`${uploadedFile.name} has been successfully uploaded.`)
      handleCancel(true)
    } catch {
      toastError('Something went wrong. Please try again.')
    }
  }

  const handleDismiss = (): void => {
    setIsAlertVisible(false)
  }

  return (
    <>
      <Breadcrumbs
        data-cy="terms-breadcrumbs"
        crumbs={[
          {
            text: 'Terms & Conditions',
            to: '/admin/terms',
          },
          {
            text: `${termsId} Details`,
            to: `/admin/terms/${termsId}/versions`,
          },
          {
            text: 'Create New Terms Version',
          },
        ]}
      />
      <DisplayLargeWithMargin>Edit Terms</DisplayLargeWithMargin>
      {isAlertVisible && (
        <InlineAlertWrapper>
          <InlineAlert
            color={InlineAlertColor.blue}
            icon={Info}
            isDismissable
            onDismiss={handleDismiss}
            data-cy="new-version-inline-alert"
          >
            <Paragraph>
              Upload a new terms and conditions PDF file to create a new version to this Terms ID.{' '}
            </Paragraph>
          </InlineAlert>
        </InlineAlertWrapper>
      )}
      {isListLoading ? (
        <SpinnerContainer>
          <Spinner color={COLOR.BLUE[800]} />
        </SpinnerContainer>
      ) : (
        <>
          <TermsForm
            onCancel={handleCancel}
            onUpload={handleOnUploadClick}
            planTermsList={planTermsList}
            termsId={termsId}
            isUploadProcessing={isGettingUrl || isUploading || isListLoading}
            description={planTermsList?.find((item) => item.termsId === termsId)?.description}
          />
          <ModalController isOpen={isModalDisplayed}>
            <TermsNewVersionModal
              handleModalOff={handleModalOff}
              termsId={termsId}
              currentVersion={currentTermsVersion}
              handleConfirm={handleUploadFile}
              isUploading={isUploading || isGettingUrl}
            />
          </ModalController>
        </>
      )}
    </>
  )
}

const DisplayLargeWithMargin = styled(DisplayLarge)({
  margin: '24px 0 12px',
})

const InlineAlertWrapper = styled.div({
  margin: '24px 0',
})

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export { CreateTermsVersion }
