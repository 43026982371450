import type { FC } from 'react'
import React, { useState } from 'react'
import { Prompt } from 'react-router'
import styled from '@emotion/styled'
import { bp, breakpoints, MAX_WIDTH } from '@helloextend/client-utils/breakpoints'

import { COLOR } from '@helloextend/client-branding'
import { connect } from 'react-redux'

import DashboardLayout from '../../../../hoc/dashboard-layout'
import { GeneralInfoSection } from './general-info-section'
import { PasswordChangeSection } from './password-change-section'
import type { RootState } from '../../../../reducers'
import * as selectors from '../../../../reducers/selectors'
import { DashboardSection } from '../../../../hoc/dashboard-layout/dashboard-layout'

interface SP {
  isLoading: boolean
  changePasswordMessage: ReturnType<typeof selectors.getChangePasswordMessage>
  updateProfileMessage: ReturnType<typeof selectors.getProfileSuccessMessage>
}

type LegacyDashboardProfilePageProps = SP

const Component: FC<LegacyDashboardProfilePageProps> = ({
  isLoading,
  changePasswordMessage,
  updateProfileMessage,
}) => {
  const [hasChangesMade, setHasChangesMade] = useState<boolean>(false)
  return (
    <DashboardLayout section={DashboardSection.None}>
      <ProfileWrapper>
        <Prompt when={hasChangesMade} message="Leave page? Your changes will not be saved" />
        <Header data-cy="profile-page-header">Profile</Header>
        <GeneralInfoSection
          isLoading={isLoading}
          setHasChangesMade={setHasChangesMade}
          profileSuccessMessage={updateProfileMessage}
          data-cy="general-info-section"
        />
        <PasswordChangeSection isLoading={isLoading} resultMessage={changePasswordMessage} />
      </ProfileWrapper>
    </DashboardLayout>
  )
}

const ProfileWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: MAX_WIDTH,
  [bp.sm]: {
    maxWidth: breakpoints[0],
  },
  [bp.md]: {
    maxWidth: breakpoints[1],
  },
  [bp.lg]: {
    maxWidth: breakpoints[2],
  },
  [bp.xxl]: {
    maxWidth: breakpoints[3],
  },
})

const Header = styled.h1({
  color: COLOR.VERY_DARK_BLUE_0,
  fontWeight: 700,
  fontSize: 32,
  margin: '0 0 32px 0',
})

const LegacyDashboardProfilePage = connect<SP, any, any, RootState>((state) => ({
  isLoading: selectors.getIsUserLoading(state),
  changePasswordMessage: selectors.getChangePasswordMessage(state),
  updateProfileMessage: selectors.getProfileSuccessMessage(state),
}))(Component)

export { Component, LegacyDashboardProfilePage }
