import type { MouseEvent, RefObject } from 'react'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import type { Row, Column, TableState } from 'react-table'
import { useTable, useFilters, useGlobalFilter, usePagination, useSortBy } from 'react-table'
import { ArrowDownward, ArrowUpward } from '@helloextend/zen/src/tokens/icons'
import { Icon, IconSize, COLOR } from '@helloextend/zen'
import styled from '@emotion/styled'
import { usePrevious } from '@helloextend/client-hooks'
import FuzzySearch from 'fuzzy-search'
import type { SearchMode } from '../table'
import { Table, ColumnHead, TableHead, TableBody } from '../table'
import { ReactTableRow } from './react-table-row'
import { TableSkeleton } from '../skeletons'
import type {
  DateRangeFilterValues,
  TextFilterValues,
  NumberRangeFilterValues,
  AdvancedTextFilterValues,
} from '../filters'
import { Filters } from '../filters'
import { dateRangeFilterValueToDates } from '../../utils/date-range-filter'
import type {
  CheckboxFilterValues,
  FilterOptions,
  FilterValues,
  NestedCheckboxFilterValues,
} from '../filters/types'
import type { OptionValue } from '../search-bar'
import { SearchBar } from '../search-bar'
import { EmptyMessage } from '../empty-message'
import type { PaginationDirection } from '../pagination'
import { Pagination } from '../pagination'
import { PaginationType } from '../pagination/types'
import { ZeroState } from '../zero-state'
import type { RowContextMenuItem } from './types'

type DataTableSearchMode = SearchMode | 'fuzzy'

export type DataReactTableProps<TData extends Record<string, unknown>> = {
  'data-cy'?: string
  type: string
  data: TData[]
  columns: Array<Column<TData>>
  contextMenuItems?: Array<RowContextMenuItem<TData>>
  initialState?: Partial<TableState<TData>>
  initialSearchKey?: string
  initialSearchValue?: string
  filterOptions?: Record<string, FilterOptions>
  isLoading: boolean
  autoResetPage?: boolean
  searchOptions?: OptionValue[]
  hasSearchBar?: boolean
  enableSearch?: boolean
  searchMessage?: string
  searchMessageDetail?: string
  searchMode?: DataTableSearchMode
  searchPlaceholder?: string
  onlyOneFilterAllowed?: boolean
  emptyMessage?: string
  resetFilters?: () => void
  nextPageCursor?: string
  paginationType?: PaginationType
  onRowLengthChange?: (
    rows: Array<Row<TData>>,
    mappedFilters: Record<string, FilterValues | null>,
  ) => void
  onRowClick?: (e: MouseEvent, rowData: TData) => void
  onServerSearch?: (key?: string, value?: string) => void
  onServerFilter?: (filters: Record<string, FilterValues | null>) => void
  onServerSort?: (sortKey: string, sortAsc: boolean) => void
  onServerPagination?: (cursor: string) => void
  setPagination?: (pageSize: number) => void
  getScrollToRef?: () => RefObject<HTMLElement>
  getAdditionalContextMenuItems?: (
    row: Row<TData>,
    contextMenuItems: Array<RowContextMenuItem<TData>>,
  ) => Array<RowContextMenuItem<TData>>
  disableSortRemove?: boolean
  searchOnly?: boolean
  searchOnlyMessage?: string
  isPaginationShown?: boolean
  totalCount?: number
  isInitialReqFullfilled?: boolean
  shouldDisplayZeroState?: boolean
  zeroState?: React.ReactNode
  manualPagination?: boolean
  // pageCount is a required prop if manualPagination is true
  pageCount?: number
  // called when pageIndex is changed to handle manual pagination
  onPageIndexChange?: (newPageIndex: number) => void
  trackPagination?: (direction: string) => void
}

/**
 * @deprecated Use Zen DataTable component instead: `import { DataTable } from '@helloextend/zen'`
 */
const DataReactTable = <TData extends Record<string, unknown>>({
  'data-cy': dataCy,
  type,
  data,
  isLoading,
  columns,
  contextMenuItems,
  initialState,
  initialSearchKey,
  initialSearchValue,
  filterOptions,
  hasSearchBar,
  enableSearch,
  searchOptions,
  searchMessage = '',
  searchMessageDetail = '',
  searchMode,
  emptyMessage = '',
  paginationType = PaginationType.BASIC,
  nextPageCursor,
  autoResetPage = true,
  onlyOneFilterAllowed,
  onRowLengthChange,
  onServerFilter,
  onServerSearch,
  onServerPagination,
  onRowClick,
  onServerSort,
  setPagination,
  getScrollToRef,
  getAdditionalContextMenuItems,
  disableSortRemove = true,
  searchOnly = false,
  searchOnlyMessage = '',
  isPaginationShown = true,
  totalCount,
  isInitialReqFullfilled,
  shouldDisplayZeroState,
  zeroState,
  manualPagination = false,
  pageCount,
  onPageIndexChange,
  trackPagination,
}: DataReactTableProps<TData>): JSX.Element => {
  const [filteredTableData, setFilteredTableData] = useState<TData[]>()

  const [serverFilters, setServerFilters] = useState<Record<string, FilterValues | null>>(
    initialState?.filters && !filterOptions
      ? initialState.filters.reduce(
          (filterMap, filter) => ({
            ...filterMap,
            [filter.id]: filter.value,
          }),
          {},
        )
      : {},
  )
  const [hasSearched, setHasSearched] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [resetFilters, setResetFilters] = useState(false)

  const multiSelectFilter = useCallback(
    () =>
      (
        rows: Array<Row<TData>>,
        columnIds: string[],
        filterValue: CheckboxFilterValues | NestedCheckboxFilterValues | null,
      ): Array<Row<TData>> => {
        if (filterValue === null || filterValue.values.length === 0) return rows
        const values: string[] =
          filterValue.type === 'nestedCheckbox'
            ? (Object.keys(filterValue.values) as string[])
            : filterValue.values
        return rows.filter((row) => values.includes(String(row.values[columnIds[0]])))
      },
    [],
  )

  const filterTypes = useMemo(
    () => ({
      multiSelectFilter: multiSelectFilter(),
      text: (rows: Array<Row<TData>>, id: string[], filterValue: string): Array<Row<TData>> => {
        return rows.filter((row) => {
          const rowValue = row.values[id[0]]
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : false
        })
      },
      dateRange: (
        rows: Array<Row<TData>>,
        id: string[],
        filterValue: DateRangeFilterValues | null,
      ): Array<Row<TData>> => {
        if (!filterValue) {
          return rows
        }
        return rows.filter((row) => {
          const { start, end } = dateRangeFilterValueToDates(filterValue)
          const rowValue = row.values[id[0]]
          if (!rowValue) {
            return false
          }
          return (!start || rowValue >= start) && (!end || rowValue <= end)
        })
      },
      containsText: (
        rows: Array<Row<TData>>,
        id: string[],
        filterValue: TextFilterValues | null,
      ): Array<Row<TData>> => {
        if (!filterValue) {
          return rows
        }
        return rows.filter((row) => {
          const rowValue = row.values[id[0]]
          if (!rowValue) return false
          return rowValue.toLowerCase().includes(filterValue.value.toLowerCase())
        })
      },
      numberRange: (
        rows: Array<Row<TData>>,
        id: string[],
        filterValue: NumberRangeFilterValues | null,
      ): Array<Row<TData>> => {
        if (!filterValue) {
          return rows
        }
        return rows.filter((row) => {
          const rowValue = row.values[id[0]] * 1
          if (filterValue.low && filterValue.high) {
            return filterValue.low <= rowValue && rowValue <= filterValue.high
          }
          if (filterValue.low) {
            return filterValue.low <= rowValue
          }
          if (filterValue.high) {
            return rowValue <= filterValue.high
          }
          return false
        })
      },
      advancedText: (
        rows: Array<Row<TData>>,
        id: string[],
        filterValue: AdvancedTextFilterValues | null,
      ): Array<Row<TData>> => {
        if (!filterValue) {
          return rows
        }
        const ignoreCase = filterValue?.caseSensitive
        return rows.filter((row) => {
          const rowValue = row.values[id[0]] ?? ''

          if (!rowValue) return false
          const rowValueWithCase = ignoreCase ? rowValue : rowValue.toLowerCase()

          if (filterValue.contains) {
            const filterValueWithCase = ignoreCase
              ? filterValue.contains
              : filterValue.contains.toLowerCase()
            if (!rowValueWithCase.includes(filterValueWithCase)) return false
          }
          if (filterValue.excludes) {
            const filterValueWithCase = ignoreCase
              ? filterValue.excludes
              : filterValue.excludes.toLowerCase()
            if (rowValueWithCase.includes(filterValueWithCase)) return false
          }
          if (filterValue.startsWith) {
            const filterValueWithCase = ignoreCase
              ? filterValue.startsWith
              : filterValue.startsWith.toLowerCase()
            if (!rowValueWithCase.startsWith(filterValueWithCase)) return false
          }
          if (filterValue.endsWith) {
            const filterValueWithCase = ignoreCase
              ? filterValue.endsWith
              : filterValue.endsWith.toLowerCase()
            if (!rowValueWithCase.endsWith(filterValueWithCase)) return false
          }
          return true
        })
      },
    }),
    [multiSelectFilter],
  )

  const {
    headerGroups,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state: { filters, pageIndex, pageSize, sortBy },
    getTableProps,
    getTableBodyProps,
    prepareRow,
    setFilter,
    setAllFilters,
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable<TData>(
    {
      columns,
      data: filteredTableData || data,
      initialState,
      filterTypes,
      manualSortBy: !!onServerSort,
      disableMultiSort: !!onServerSort,
      manualFilters: !!onServerFilter,
      disableSortRemove,
      autoResetPage,
      manualPagination,
      pageCount,
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            ...(resetFilters && { ...initialState, filters: [] }),
          }),
          [state],
        )
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const handleFilters = useCallback(
    (newFilters: Record<string, FilterValues | null>): void => {
      const isEmptyFilter =
        Object.values(newFilters)?.filter((filter) => filter === null).length > 0

      if (onServerFilter) {
        onServerFilter(newFilters)
        setServerFilters(newFilters)
      } else {
        Object.entries(newFilters).forEach(([key, value]): void => {
          setFilter(key, value)
        })
      }
      if (onlyOneFilterAllowed) {
        setGlobalFilter(undefined)
        setResetFilters(true)
      }
      setHasSearched(!isEmptyFilter)
    },
    [onServerFilter, onlyOneFilterAllowed, setFilter, setGlobalFilter],
  )

  const handleSearch = useCallback(
    (key: string, value: string): void => {
      setHasSearched((v) => v || (!!key && !!value))
      if (searchMode === 'fuzzy') {
        setHasSearched(true)
        const searcher = new FuzzySearch(data, [key])
        const result = searcher.search(value)
        setFilteredTableData(result)
      }
      if (onlyOneFilterAllowed) {
        setServerFilters({})
        setAllFilters([])
      }
      if (onServerSearch) {
        setIsSearching(true)
        onServerSearch(key, value)
        return
      }
      setGlobalFilter(value)
    },
    [onServerSearch, onlyOneFilterAllowed, setAllFilters, setGlobalFilter, searchMode, data],
  )

  const handleResetFilters = useCallback(() => {
    setHasSearched(false)
    setGlobalFilter(undefined)
    setAllFilters([])
    setServerFilters({})
    setResetFilters(true)
    onServerFilter?.({})
    onServerSearch?.()
    onServerPagination?.('')
    setFilteredTableData(data)
  }, [onServerFilter, onServerPagination, onServerSearch, setAllFilters, setGlobalFilter, data])

  useEffect(() => {
    if (!isLoading && isSearching) {
      setIsSearching(false)
    }
  }, [isLoading, isSearching])

  useEffect(() => {
    if (resetFilters) {
      setResetFilters(false)
    }
  }, [resetFilters])

  const handleLocalPagination = useCallback(
    (pageAmount: number, pageInd: number): void => {
      if (setPagination) {
        setPagination(pageAmount)
      }
      if (manualPagination && onPageIndexChange) {
        onPageIndexChange(pageInd)
      }
    },
    [manualPagination, onPageIndexChange, setPagination],
  )

  const handlePagination = useCallback(
    (paginationDirection: PaginationDirection): void => {
      if (trackPagination) {
        trackPagination(paginationDirection)
      }
      if (onServerPagination && nextPageCursor && pageOptions.length - 2 === pageIndex) {
        onServerPagination(nextPageCursor)
        nextPage()
      } else if (paginationDirection === 'next') {
        nextPage()
      } else if (paginationDirection === 'previous') {
        previousPage()
      } else if (paginationDirection === 'first') {
        gotoPage(0)
      } else if (paginationDirection === 'last') {
        gotoPage(pageOptions.length - 1)
      }
    },
    [
      gotoPage,
      nextPage,
      nextPageCursor,
      onServerPagination,
      pageIndex,
      pageOptions.length,
      previousPage,
      trackPagination,
    ],
  )

  const handleRowClick = useCallback(
    (e: MouseEvent, rowData: TData): void => {
      if (typeof onRowClick !== 'undefined') {
        onRowClick(e, rowData)
      }
    },
    [onRowClick],
  )

  useEffect(() => {
    let timeoutId: number
    const scrollToRef = getScrollToRef ? getScrollToRef() : null
    if (scrollToRef && scrollToRef.current) {
      // use setTimeout to prevent scroll getting cancelled if button rerenders
      timeoutId = setTimeout(() => scrollToRef.current?.scrollTo({ top: 0, behavior: 'smooth' }))
    }
    handleLocalPagination(pageSize, pageIndex)

    return (): void => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [pageIndex, pageSize, handleLocalPagination, getScrollToRef])

  useEffect(() => {
    if (!onServerSort) {
      return
    }

    if (sortBy.length) {
      onServerSort(sortBy[0].id, !sortBy[0].desc)
    } else {
      onServerSort('', true)
    }
  }, [onServerSort, sortBy])

  const mappedFilters: Record<string, FilterValues | null> = Object.keys(serverFilters).length
    ? serverFilters
    : filters.reduce(
        (filterMap, filter) => ({
          ...filterMap,
          [filter.id]: filter.value,
        }),
        {},
      )

  const prevRowLength = usePrevious(rows?.length)
  const rowsChanged = prevRowLength !== rows?.length

  useEffect(() => {
    if (rowsChanged) onRowLengthChange?.(rows, mappedFilters)
  }, [mappedFilters, onRowLengthChange, rows, rowsChanged])

  const displayTableHeader = (!hasSearched && !searchOnly) || (hasSearched && rows.length > 0)
  const isSearchDisabled =
    (!enableSearch && (shouldDisplayZeroState || !isInitialReqFullfilled || searchOnly)) ||
    (searchOnly && filters.some((filter) => filter.value))

  return (
    <>
      {(hasSearchBar || searchOptions) && (
        <SearchWrapper>
          <SearchBar
            data-cy={dataCy && `${dataCy}:search`}
            id="table-search"
            resetSearch={resetFilters}
            options={searchOptions}
            onSubmit={handleSearch}
            isSearchOnly={searchOnly}
            isLoading={isSearching}
            isDisabled={isSearchDisabled}
            initialKey={initialSearchKey}
            initialValue={initialSearchValue}
          />
        </SearchWrapper>
      )}
      {filterOptions && Object.keys(filterOptions).length > 0 && (
        <Filters
          onFilter={handleFilters}
          values={mappedFilters}
          filters={filterOptions}
          isDisabled={!isInitialReqFullfilled || shouldDisplayZeroState}
          onClear={handleResetFilters}
        />
      )}
      {isLoading && <TableSkeleton columns={columns.length} rows={25} />}
      {shouldDisplayZeroState && zeroState}
      {!isLoading && !shouldDisplayZeroState && (
        <>
          <Table {...getTableProps()} data-cy={dataCy && `${dataCy}:table`}>
            {displayTableHeader &&
              headerGroups.map((headerGroup) => (
                <TableHead {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <ColumnHead
                      textAlign={column.textAlign}
                      columnWidth={column.width}
                      active={false}
                      isSelectable={false}
                      onClick={() => {}}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <HeaderWrapper textAlign={column.textAlign}>
                        <HeaderTextWrapper isSortable={column.canSort}>
                          {column.textAlign === 'right' && column.canSort && (
                            <IconWrapper isVisible={column.isSorted}>
                              <Icon
                                icon={column.isSortedDesc ? ArrowDownward : ArrowUpward}
                                size={IconSize.xsmall}
                                color={column.isSorted ? COLOR.NEUTRAL[1000] : COLOR.NEUTRAL[600]}
                              />
                            </IconWrapper>
                          )}
                          <HeaderText
                            data-tooltip={column.headerTooltip}
                            isSorted={column.isSorted}
                          >
                            {column.render('Header')}
                          </HeaderText>
                          {column.textAlign !== 'right' && column.canSort && (
                            <IconWrapper isVisible={column.isSorted}>
                              <Icon
                                icon={column.isSortedDesc ? ArrowDownward : ArrowUpward}
                                size={IconSize.xsmall}
                                color={column.isSorted ? COLOR.NEUTRAL[1000] : COLOR.NEUTRAL[600]}
                              />
                            </IconWrapper>
                          )}
                        </HeaderTextWrapper>
                        {column.HeaderAdornment && column.render('HeaderAdornment')}
                      </HeaderWrapper>
                    </ColumnHead>
                  ))}
                  {contextMenuItems?.length && <ColumnHead active={false} columnWidth={5} />}
                </TableHead>
              ))}
            <TableBody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <ReactTableRow
                    key={row.id}
                    row={row}
                    onRowClick={handleRowClick}
                    contextMenuItems={
                      getAdditionalContextMenuItems && contextMenuItems
                        ? getAdditionalContextMenuItems(row, contextMenuItems)
                        : contextMenuItems
                    }
                  />
                )
              })}
            </TableBody>
          </Table>
          {hasSearched && rows.length === 0 && !isLoading && (
            <EmptyMessage
              header="We can't find a match for that..."
              message={emptyMessage}
              onClearFilters={handleResetFilters}
            />
          )}
          {!hasSearched && !searchOnly && onServerSearch && data.length === 0 && !isLoading && (
            <EmptyMessage
              header={searchMessage}
              message={searchMessageDetail}
              onClearFilters={handleResetFilters}
            />
          )}
          {searchOnly && !hasSearched && data.length === 0 && !isLoading && (
            <ZeroState title="Search to see results" text={searchOnlyMessage} />
          )}
          {isPaginationShown && (
            <Pagination
              count={totalCount ?? rows.length}
              type={type}
              currPage={pageIndex}
              pageSize={pageSize}
              handlePagination={handlePagination}
              onPageSizeChange={setPageSize}
              hasNext={canNextPage}
              hasPrev={canPreviousPage}
              paginationType={paginationType}
            />
          )}
        </>
      )}
    </>
  )
}

const SearchWrapper = styled.div({
  marginBottom: 32,
})

const HeaderTextWrapper = styled.span<{ isSortable?: boolean }>(({ isSortable }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  lineHeight: '24px',
  '&:hover': isSortable && {
    backgroundColor: COLOR.NEUTRAL[100],
    borderRadius: '4px',
  },
  '&:hover div': {
    opacity: 1,
  },
}))

const IconWrapper = styled.div<{ isVisible: boolean }>(({ isVisible }) => ({
  transition: 'opacity .25s ease-out',
  display: 'flex',
  opacity: isVisible ? 1 : 0,
}))

const HeaderText = styled.span<{ isSorted: boolean }>(({ isSorted }) => ({
  color: isSorted ? COLOR.NEUTRAL[1000] : 'inherit',
}))

const HeaderWrapper = styled.div<{ textAlign?: 'left' | 'center' | 'right' }>(
  ({ textAlign = 'left' }) => ({
    gap: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: textAlign,
    padding: '4px 0',
    'span[data-tooltip]': {
      display: 'inline-flex',
      button: {
        transform: 'translateY(-1px)',
      },
    },
  }),
)

export { DataReactTable }
