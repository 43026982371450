export const Types = Object.freeze({
  CLAIM_APPROVED: 'claim-approved',
  CLAIM_DENIED: 'claim-denied',
  CONTRACT_PURCHASED: 'contract-purchased',
  CONTRACT_REFUNDED: 'contract-refunded',
  CONTRACT_TRANSFERRED: 'contract-transferred',
  DEPOT_REPAIR_CLAIM_APPROVED: 'depot-repair-claim-approved',
  DEPOT_REPAIR_PRODUCT_DELIVERED_TO_SERVICER: 'depot-repair-product-delivered-to-servicer',
  DEPOT_REPAIR_PRODUCT_SHIPPED_TO_CUSTOMER: 'depot-repair-product-shipped-to-customer',
  LEADS_CREATED: 'leads-created',
  MANUAL_SERVICE_ORDER_FULFILLED: 'manual-service-order-fulfilled',
  SERVICE_ORDER_REPLACEMENT_APPROVED: 'service-order-replacement-approved',
  SERVICE_ORDER_REPLACEMENT_APPROVED_SERVICER: 'service-order-replacement-approved-servicer',
  SHIPPING_PROTECTION_CONTRACT_PURCHASED: 'shipping-protection-contract-purchased',
  SHIPPING_PROTECTION_CONTRACT_REFUNDED: 'shipping-protection-contract-refunded',
  SHOPIFY_BALANCE_REMAINING: 'shopify-balance-remaining',
  SHOPIFY_SERVICE_ORDER_FULFILLED: 'shopify-service-order-fulfilled',
  USER_UPDATED: 'user-updated',
} as const)

const EmailTypes = Object.freeze({
  MARKETING: 'marketing',
  TRANSACTIONAL: 'transactional',
} as const)
export type EmailType = typeof EmailTypes[keyof typeof EmailTypes]

export type Type = typeof Types[keyof typeof Types]

export interface ConfigurationMessage {
  name: Type
}

export interface GetConfigurationQueryString {
  storeId: string
  messageName: string
}
export interface ConfigurationsPutRequest<T> {
  message: ConfigurationMessage
  configuration: T
}

export interface SendToMessage {
  name: string
}

export interface SendToData {
  storeId: string
}

export interface TestEmailRequest<T> {
  message: SendToMessage
  to: T[]
  data: SendToData
}

export interface SendToAddress {
  type: string
  value: string
}

export interface SendTestEmailTo {
  address: SendToAddress
}

export interface PostSendTestEmailRequest extends TestEmailRequest<SendTestEmailTo> {}

export interface LeadsStore {
  id: string
  name: string
  merchantLogo?: string
  primaryColor?: string
  lightBackgroundColor?: string
  googleFont?: string
  heroButtonTextColor?: string
  merchantPrivacyPolicyLink?: string
  merchantPhoneNumber?: string
  merchantAddress: string
}

export interface LeadsCreatedConfigurationGetResponse {
  enabled: boolean
  emailType: EmailType
  callToActionUri: string
  storeName: string
  sendFromEmail: string
  replyToEmail: string
  buildFirstCommunication: boolean
  buildSecondCommunication: boolean
  buildThirdCommunication: boolean
  buildFourthCommunication: boolean
  merchantLogo?: string
  primaryColor?: string
  lightBackgroundColor?: string
  googleFont?: string
  headerFont?: string
  heroButtonTextColor?: string
  merchantAddress: string
  merchantPrivacyPolicyLink?: string
  merchantPhoneNumber?: string
}

export interface LeadsCreatedConfigurationPutRequest
  extends ConfigurationsPutRequest<LeadsCreatedConfigurationPut> {}

export interface LeadsCreatedConfigurationPut {
  enabled?: boolean
  emailType: EmailType
  store: LeadsStore
  callToActionUri?: string
  sendFromEmail?: string
  replyToEmail?: string
  buildFirstCommunication?: boolean
  buildSecondCommunication?: boolean
  buildThirdCommunication?: boolean
  buildFourthCommunication?: boolean
}

export interface UploadStoreLogoRequest {
  storeId: string
  base64image: string
}

export interface UploadStoreLogoResponse {
  merchantLogoUrl: string
}
