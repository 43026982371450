import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@helloextend/client-utils/breakpoints'
import { EmailLoginForm } from '../../components/email-login-form'

const LoginForm: FC = () => {
  return (
    <>
      <EmailFormWrapper>
        <EmailLoginForm />
      </EmailFormWrapper>
    </>
  )
}

const FormWrapper = styled.div({
  flex: '1 0 50%',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  maxHeight: 430,
})

const EmailFormWrapper = styled(FormWrapper)({
  maxHeight: 665,
  marginTop: 48,
  [bp.md]: {
    maxHeight: 'none',
  },
})

export { LoginForm }
