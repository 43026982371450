/**
 * Handles the cases of `First Last`, `First Middle Last`, `First Middle Middle Last`, `Last, First`, and `Last, First AlsoFirst`
 * @param fullName
 */
export function splitName(fullName: string): string[] {
  let names: string[] = []
  if (fullName.includes(',')) {
    names = fullName.split(',').reverse()
  } else {
    const [last, ...firstNames] = fullName.split(' ').reverse()
    names = [firstNames.reverse().join(' '), last]
  }

  return names.map((name) => name.trim())
}

export interface PercentageCovered {
  percentage: string
  percentRemaining: string
}

/**
 * Returns the actual percentage of plan years in relation to a manufacturer's warranty`
 * @param planYears
 * @param manufacturersWarrantyYears
 */
export const getPercentageCovered = (options: {
  planYears: number
  manufacturersWarrantyYears?: number
  isAdh?: boolean
}): PercentageCovered => {
  const { planYears, manufacturersWarrantyYears = 1, isAdh = false } = options
  if (isAdh) {
    const value = (1 / (planYears + manufacturersWarrantyYears - 1)) * 100
    const remainingValue = 100 - value
    return { percentage: `${remainingValue.toString()}%`, percentRemaining: `${value.toString()}%` }
  }

  const value = (planYears / (planYears + manufacturersWarrantyYears)) * 100
  const remainingValue = 100 - value
  return { percentage: `${value.toString()}%`, percentRemaining: `${remainingValue.toString()}%` }
}

/**
 * converts a decimal number to a percentage string with symbol
 * @param decimal number to be converted to percentage
 * @returns percentage string
 */
export const getPercentageStringFromDecimal = (decimal: number): string => {
  return decimal.toLocaleString('en', {
    style: 'percent',
    minimumSignificantDigits: 1,
  })
}

/**
 * converts whole percentage string value to decimal
 * @param percentageValue percentage value to be converted to decimal
 * @returns decimal value
 */
export const getDecimalFromPercentageString = (percentageValue: string): number => {
  return Number((parseFloat(percentageValue) / 100).toFixed(3))
}
