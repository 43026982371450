import * as Yup from 'yup'
import { urlRegExp } from '@helloextend/client-utils/validate'

const schema = Yup.object()
  .shape({
    name: Yup.string().notRequired(),
    secondaryName: Yup.string().notRequired(),
    id: Yup.string().required('Store ID is required'),
    accountId: Yup.string().required('Account ID is required'),
    domain: Yup.string().notRequired().matches(urlRegExp, 'Please enter a valid URL'),
    adminDealerNumber: Yup.string().notRequired(),
    adminLocationNumber: Yup.string().notRequired(),
    adminManufacturer: Yup.string().notRequired(),
    syncOrderProducts: Yup.boolean().notRequired(),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export { schema, Values }
