import type { Plan } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetchAll = (accessToken: string) =>
  ({ type: 'PLANS_FETCH_ALL', payload: { accessToken } } as const)

export const fetchAllRequest = () =>
  ({ type: 'PLANS_FETCH_ALL_REQUEST', payload: undefined } as const)

export const fetchAllSuccess = (plans: Plan[]) =>
  ({
    type: 'PLANS_FETCH_ALL_SUCCESS',
    payload: plans,
  } as const)

export const fetchAllFailure = createActionFailure('PLANS_FETCH_ALL_FAILURE')
