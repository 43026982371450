import * as Yup from 'yup'
import type {
  Store,
  Contract20220201GetResponse,
  Plan,
  Product,
  EntitlementsResponse,
} from '@helloextend/extend-api-client'
import { date } from '@helloextend/client-utils'

const storeInformationSchema = Yup.object()
  .shape({
    storeName: Yup.string().required('Store name is required'),
    storeId: Yup.string().required('Store ID is required'),
    customerSupportLine: Yup.string().notRequired(),
    customerSupportEmail: Yup.string().notRequired(),
    escalationContact: Yup.string().notRequired(),
    escalationNumber: Yup.string().notRequired(),
    extension: Yup.string().notRequired(),
    storeEmail: Yup.string().notRequired(),
  })
  .defined()

const contractInformationSchema = Yup.object()
  .shape({
    contractId: Yup.string().required('Contract ID is required'),
    type: Yup.string().required(),
    isTest: Yup.boolean().notRequired(),
    contractStatus: Yup.string().required('Contract Status is required'),
    planName: Yup.string().notRequired(),
    planServiceType: Yup.string().notRequired(),
    planCategory: Yup.string().notRequired(),
    planCoverageIncludes: Yup.string().notRequired(),
    planId: Yup.string().required('Plan ID is required'),
    planWarrantySku: Yup.string().notRequired(),
    planVersion: Yup.number().notRequired(),
    termsId: Yup.string().notRequired(),
    termVersion: Yup.string().notRequired(),
    planTermsUrl: Yup.string().notRequired(),
    planPurchasePrice: Yup.number().required('Plan purchase price is required'),
    planListPrice: Yup.number().notRequired(),
    createdDate: Yup.string().required(),
    planPurchaseDate: Yup.string().notRequired(),
    transactionDate: Yup.number().notRequired(),
    canceledDate: Yup.string().notRequired(),
    refundedDate: Yup.string().notRequired(),
    updatedDate: Yup.string().notRequired(),
    coverageStartDate: Yup.string().notRequired(),
    coverageEndDate: Yup.string().notRequired(),
    limitOfLiabilityAmountType: Yup.string().notRequired(),
    limitOfLiabilityAmount: Yup.number().notRequired(),
    remainingCoverageAmount: Yup.number().notRequired(),
  })
  .defined()

const productInformationSchema = Yup.object()
  .shape({
    referenceId: Yup.string().required('Reference ID is'),
    serialNumber: Yup.string().notRequired(),
    productPurchasePrice: Yup.number().required('Purchase price is required'),
    productName: Yup.string().required('Product name is required'),
    productListPrice: Yup.number().required('List price is required'),
    productPurchaseDate: Yup.string().required('Product purchase date is required'),
    imageUrl: Yup.string().notRequired(),
    brand: Yup.string().notRequired(),
    sku: Yup.string().notRequired(),
    productTransactionId: Yup.string().required('Product transaction ID is required'),
    mfrsWarrantyLengthLabor: Yup.number().required('Mfrs warranty length (labor) is required'),
    mfrsWarrantyLengthParts: Yup.number().required('Mfrs warranty length (parts) is required'),
  })
  .required()

const customerInformationSchema = Yup.object()
  .shape({
    fullName: Yup.string().required('Full name is required'),
    phoneNumber: Yup.string().notRequired(),
    customerEmail: Yup.string().required('Email is required'),
    merchantCustomerId: Yup.string().notRequired(),
    billingAddress: Yup.string().required('Address is required'),
    billingAddressTwo: Yup.string().notRequired(),
    billingCity: Yup.string().required('City is required'),
    billingProvinceCode: Yup.string().required('Province code is required'),
    billingPostalCode: Yup.string().required('Postal code is required'),
    billingCountryCode: Yup.string().required('Country code is required'),
    shippingAddress: Yup.string().required('Address is required'),
    shippingAddressTwo: Yup.string().notRequired(),
    shippingCity: Yup.string().required('City is required'),
    shippingProvinceCode: Yup.string().required('Province code is required'),
    shippingPostalCode: Yup.string().required('Postal code is required'),
    shippingCountryCode: Yup.string().required('Country code is required'),
  })
  .defined()

const transactionInformationSchema = Yup.object()
  .shape({
    transactionId: Yup.string().required('Transaction ID is required'),
    currencyCode: Yup.string().required('Currency code is required'),
    poNumber: Yup.string().notRequired(),
    orderId: Yup.string().notRequired(),
    quoteId: Yup.string().notRequired(),
  })
  .defined()

export const mapContract20220201ToValues = (
  contract: Contract20220201GetResponse | null,
  store: Store | null,
  planTermsUrl?: string,
  plan?: Plan | null,
  product?: Product | null,
  entitlements?: EntitlementsResponse | null,
): V2Values => {
  if (!contract)
    return {
      ...customerInformationSchema.default(),
      ...transactionInformationSchema.default(),
      ...storeInformationSchema.default(),
      ...contractInformationSchema.default(),
      ...productInformationSchema.default(),
    }

  return {
    // customerInformationSchema
    fullName: contract.customer.name,
    phoneNumber: contract.customer.phone,
    customerEmail: contract.customer.email,
    merchantCustomerId: contract.merchantCustomerId,
    billingAddress: contract.customer.billingAddress?.address1 ?? '',
    billingAddressTwo: contract.customer.billingAddress?.address2,
    billingCity: contract.customer.billingAddress?.city ?? '',
    billingProvinceCode: contract.customer.billingAddress?.provinceCode ?? '',
    billingPostalCode: contract.customer.billingAddress?.postalCode ?? '',
    billingCountryCode: contract.customer.billingAddress?.countryCode ?? '',
    shippingAddress: contract.customer.shippingAddress?.address1 ?? '',
    shippingAddressTwo: contract.customer.shippingAddress?.address2,
    shippingCity: contract.customer.shippingAddress?.city ?? '',
    shippingProvinceCode: contract.customer.shippingAddress?.provinceCode ?? '',
    shippingPostalCode: contract.customer.shippingAddress?.postalCode ?? '',
    shippingCountryCode: contract.customer.shippingAddress?.countryCode ?? '',
    // transactionInformationSchema
    transactionId: contract.transactionId,
    currencyCode: contract.purchaseCurrency,
    poNumber: contract.poNumber ?? '',
    orderId: contract.orderId ?? '',
    quoteId: contract.quoteId ?? '',
    // storeInformationSchema
    storeName: contract.sellerName ?? '',
    storeId: contract.sellerId,
    customerSupportLine: store?.supportContact?.phoneNumber?.number,
    customerSupportEmail: store?.supportContact?.email,
    escalationContact: store?.escalationContact?.contactName,
    escalationNumber: store?.escalationContact?.phoneNumber?.number,
    extension: store?.escalationContact?.phoneNumber?.extension,
    storeEmail: store?.escalationContact?.email,
    // contractInformationSchema
    contractId: contract.id,
    type: contract.type,
    isTest: contract.isTest,
    contractStatus: contract.status,
    planId: contract.plan.id ?? '',
    planName: plan?.name ?? '',
    planServiceType: plan?.contract?.service_type ?? '',
    planCategory: plan?.contract?.category ?? '',
    planCoverageIncludes: plan?.contract?.coverage_includes ?? '',
    planWarrantySku: contract.plan.skuId || contract.plan.skuId || '',
    planVersion: contract.plan.version,
    termsId: contract.plan.termsId,
    termVersion: contract.plan.termsVersion,
    planTermsUrl,
    planPurchasePrice: contract.purchasePrice,
    planListPrice: contract.listPrice || contract.expectedPrice,
    createdDate: date.format(Number(contract.createdAt), 'MMM DD YYYY'),
    planPurchaseDate: contract.purchaseDate
      ? date.format(Number(contract.purchaseDate), 'MMM DD YYYY')
      : '',
    canceledDate: contract.cancelledAt
      ? date.format(Number(contract.cancelledAt), 'MMM DD YYYY')
      : '',
    refundedDate: contract.refundedAt
      ? date.format(Number(contract.refundedAt), 'MMM DD YYYY')
      : '',
    updatedDate: date.format(Number(contract.updatedAt), 'MMM DD YYYY'),
    coverageStartDate: contract.coverage
      ? date.format(Number(contract.coverage?.starts), 'MMM DD YYYY')
      : '',
    coverageEndDate: contract.coverage
      ? date.format(Number(contract.coverage?.ends), 'MMM DD YYYY')
      : '',
    // productInformationSchema
    referenceId: contract.product?.referenceId,
    serialNumber: contract.product?.serialNumber,
    productPurchasePrice: contract.product?.purchasePrice,
    productName: contract.product?.name,
    productListPrice: contract.product?.listPrice,
    productPurchaseDate: contract.product?.purchaseDate
      ? date.format(Number(contract.product.purchaseDate), 'MMM DD YYYY')
      : '',
    imageUrl: contract.product?.imageUrl,
    brand: product?.brand ?? '',
    sku: product?.identifiers?.sku ?? '',
    productTransactionId: contract.product?.transactionId,
    mfrsWarrantyLengthLabor: contract.product?.manufacturerWarrantyLengthLabor,
    mfrsWarrantyLengthParts: contract.product?.manufacturerWarrantyLengthParts,
    limitOfLiabilityAmount: contract?.limitOfLiabilityAmount,
    limitOfLiabilityAmountType: contract?.limitOfLiabilityAmountType,
    remainingCoverageAmount: entitlements?.coverageAmountRemaining?.amount ?? 0,
  }
}

type V2Values = Yup.InferType<typeof customerInformationSchema> &
  Yup.InferType<typeof transactionInformationSchema> &
  Yup.InferType<typeof storeInformationSchema> &
  Yup.InferType<typeof contractInformationSchema> &
  Yup.InferType<typeof productInformationSchema>

const V2Schema = new Map()
V2Schema.set('Customer Information', customerInformationSchema)
V2Schema.set('Transaction Information', transactionInformationSchema)
V2Schema.set('Store Information', storeInformationSchema)
V2Schema.set('Contract Information', contractInformationSchema)
V2Schema.set('Product Information', productInformationSchema)

export { V2Schema, V2Values }
