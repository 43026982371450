import type { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorStatus } from '@helloextend/client-utils'
import logger from '@helloextend/client-logger/index'

import * as selectors from '../reducers/selectors'
import * as usersActions from '../actions/users'

export function* updateProfileInfo(action: ReturnType<typeof usersActions.update>): SagaIterator {
  try {
    yield put(usersActions.updateRequest())
    const body = action.payload.data
    const userId = yield select(selectors.getUserId)
    const accessToken = yield select(selectors.getAccessToken)
    const { status, data } = yield call(client.users.updateProfile, userId, accessToken, body)

    if (isErrorStatus(status)) {
      yield put(usersActions.updateFailure(data.message, status))
    } else {
      yield put(usersActions.updateSuccess('Profile updated!'))
    }
  } catch (e) {
    logger.error(e)
  }
}
