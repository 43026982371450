import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'

export interface RowActionsProps {
  reduceLeftPadding?: boolean
  reduceRightPadding?: boolean
}

export const RowActions: FC<RowActionsProps> = ({
  children,
  reduceLeftPadding,
  reduceRightPadding,
}) => {
  return (
    <StyledRowActions reduceLeftPadding={reduceLeftPadding} reduceRightPadding={reduceRightPadding}>
      {children}
    </StyledRowActions>
  )
}

export const StyledRowActions = styled.div<RowActionsProps>(
  ({ reduceLeftPadding = false, reduceRightPadding = false }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 4,
    padding: '4px 0',
    marginTop: -8,
    marginBottom: -8,
    ...(reduceLeftPadding && {
      marginLeft: -8,
      marginRight: -6,
    }),
    ...(reduceRightPadding && {
      marginLeft: -6,
      marginRight: -8,
    }),
  }),
)
