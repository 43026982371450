import { combineReducers } from 'redux'
import type { ErrorReducerState } from '../../types/error'
import type { Action } from '../actions'
import type { ByServiceOrderReducerState } from './by-service-order'
import byServiceOrder from './by-service-order'
import error from './error'
import isLoading from './is-loading'

export interface ReducerState {
  byServiceOrder: ByServiceOrderReducerState
  error: ErrorReducerState
  isLoading: boolean
}

export default combineReducers<ReducerState, Action>({ byServiceOrder, error, isLoading })
