import type { ReactElement, PropsWithChildren } from 'react'
import React, { createContext, useContext, useMemo } from 'react'

export interface MenuContextValue {
  hideAll: () => void
}

export interface MenuContextProviderProps {
  hideAll: () => void
}

const MenuContext = createContext<MenuContextValue | null>(null)

export const MenuContextProvider = ({
  children,
  hideAll,
}: PropsWithChildren<MenuContextProviderProps>): ReactElement => {
  const value = useMemo<MenuContextValue>(
    () => ({
      hideAll,
    }),
    [hideAll],
  )

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
}

export const useMenuContext = (): MenuContextValue => {
  const context = useContext(MenuContext) as MenuContextValue

  if (!context) {
    throw new Error(
      'useMenuContext() can only be used within the subtree of a <MenuContextProvider>',
    )
  }

  return context
}
