import type { FC, SyntheticEvent, CSSProperties } from 'react'
import React, { useEffect, useState, useCallback } from 'react'
import { Spinner, COLOR, Information, Icon } from '@helloextend/zen'
import { ArrowDropDown } from '@helloextend/zen/src/tokens/icons'
import styled from '@emotion/styled'
import { getBorderColor } from '../../utils/get-border-color'

type DropdownOption = {
  label: string
  value: string
  icon?: string
}

type DropdownProps = {
  onChange: (e: SyntheticEvent<HTMLSelectElement | HTMLButtonElement>) => void
  label: string
  name: string
  options: DropdownOption[]
  placeholder?: string
  value: string
  errorMessage?: string
  disabled?: boolean
  isLoading?: boolean
  dataCy?: string
  helperText?: string
  labelFontWeight?: number
}

const spinnerStyleOverrides: CSSProperties = {
  width: 26,
  height: 38,
  margin: 'auto',
  paddingTop: 5,
}

const Dropdown: FC<DropdownProps> = ({
  onChange,
  label,
  name,
  options,
  placeholder = '',
  value,
  errorMessage = '',
  disabled,
  isLoading,
  dataCy,
  helperText,
  labelFontWeight,
}): JSX.Element => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)
  const isTouchscreen =
    'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

  const handleClick = (): void => {
    document.addEventListener('click', hideMenu)
    setIsMenuVisible(true)
  }

  const hideMenu = useCallback((): void => {
    document.removeEventListener('click', hideMenu)
    setIsMenuVisible(false)
  }, [])

  // Field will be empty until options are provided; shunt in the current value until done loading
  const optionSet = isLoading ? [{ value, label: value }] : options

  const dropdownChoice = (option: DropdownOption): JSX.Element => {
    if (!option.icon) return <div>{option.label}</div>

    return (
      <ImgVal>
        <Img alt="img" src={option.icon} />
        <div>{option.label}</div>
      </ImgVal>
    )
  }

  const selectedOption = (selectedValue: string): string => {
    const foundOption = optionSet.find((option: DropdownOption) => option.value === selectedValue)
    return foundOption?.label ?? ''
  }

  useEffect(() => {
    return () => document.removeEventListener('click', hideMenu)
  }, [hideMenu])

  return (
    <Wrapper>
      {(!!label || !!helperText) && (
        <FieldHeader>
          {label && (
            <Label htmlFor={name} fontWeight={labelFontWeight}>
              {label}
            </Label>
          )}
          {helperText && (
            <InformationWrapper>
              <Information buttonSize="xsmall">{helperText}</Information>
            </InformationWrapper>
          )}
        </FieldHeader>
      )}
      {isTouchscreen ? (
        <SelectWrapper>
          <NativeSelect name={name} onChange={onChange}>
            {optionSet.map(
              (option: DropdownOption): JSX.Element => (
                <option key={`native${option.label}`} selected={value === option.value}>
                  {option.label}
                </option>
              ),
            )}
          </NativeSelect>
          <Icon icon={ArrowDropDown} color={COLOR.NEUTRAL[400]} />
        </SelectWrapper>
      ) : (
        <SelectWrapper active={isMenuVisible} error={errorMessage.length > 0} disabled={disabled}>
          <Button
            id={name}
            onClick={handleClick}
            type="button"
            disabled={disabled}
            data-cy="select-from-list-btn"
          >
            {value && value.length > 0 ? selectedOption(value) : placeholder}
          </Button>
          <Menu role="listbox" hidden={!isMenuVisible}>
            {isLoading ? (
              <div style={spinnerStyleOverrides}>
                <Spinner />
              </div>
            ) : (
              optionSet.map((option) => (
                <DropdownButton
                  type="button"
                  data-cy={dataCy}
                  name={name}
                  value={option.value}
                  key={option.label}
                  onKeyPress={onChange}
                  onClick={onChange}
                  isSelected={Boolean(value === option.value)}
                >
                  {dropdownChoice(option)}
                </DropdownButton>
              ))
            )}
          </Menu>
          <Icon icon={ArrowDropDown} color={COLOR.NEUTRAL[400]} />
        </SelectWrapper>
      )}
      {errorMessage.length > 0 && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  )
}

const Wrapper = styled.div({
  boxSizing: 'border-box',
  fontSize: 12,
  position: 'relative',
  width: '100%',
  display: 'block',
})

const Label = styled.label<{ fontWeight?: number }>(({ fontWeight = 600 }) => ({
  boxSizing: 'border-box',
  display: 'block',
  fontFamily: 'Nunito Sans',
  fontSize: 14,
  lineHeight: '18px',
  fontWeight,
  paddingBottom: 5,
  color: COLOR.BLUE[1000],
}))

const DropdownButton = styled.button<{ isSelected: boolean }>(({ isSelected }) => ({
  border: 'none',
  backgroundColor: isSelected ? COLOR.BLUE[800] : COLOR.WHITE,
  color: isSelected ? COLOR.WHITE : COLOR.BLACK,
  width: '100%',
  padding: '8px 8px 8px 16px',
  display: 'flex',
  fontSize: 14,
  '&:hover': {
    backgroundColor: COLOR.NEUTRAL[200],
    color: COLOR.BLACK,
  },
}))

const Button = styled.button({
  border: 'none',
  background: COLOR.WHITE,
  color: COLOR.BLACK,
  boxSizing: 'border-box',
  display: 'block',
  borderRadius: 4,
  cursor: 'pointer',
  fontFamily: 'Nunito Sans',
  fontSize: 14,
  position: 'relative',
  width: '100%',
  height: 40,
  textAlign: 'start',
  paddingLeft: 16,
  ':hover': {
    borderColor: COLOR.NEUTRAL[700],
  },
  ':focus': {
    borderColor: COLOR.BLUE[700],
    boxShadow: `0 0 0 1px inset ${COLOR.BLUE[700]}`,
    outline: 'none',
  },
  ':disabled': {
    background: COLOR.NEUTRAL[100],
  },
})

const Menu = styled.div({
  backgroundColor: COLOR.WHITE,
  border: `1px solid ${COLOR.NEUTRAL[1000]}`,
  zIndex: 1,
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  boxSizing: 'border-box',
  fontFamily: 'Nunito Sans',
  fontWeight: 300,
  lineHeight: '24px',
  position: 'absolute',
  width: '100%',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.1)',
  maxHeight: '300px',
  overflowY: 'auto',
})

const NativeSelect = styled.select({
  appearance: 'none',
  backgroundColor: COLOR.WHITE,
  border: 'none',
  borderRadius: 4,
  fontFamily: 'Nunito Sans',
  fontWeight: 300,
  lineHeight: '24px',
  padding: '0 10px',
  width: 312,
  height: 40,
  '&:focus': {
    outline: 'none',
  },
  '&:active': {
    borderColor: COLOR.BLUE[700],
    boxShadow: `0 0 0 1px ${COLOR.BLUE[700]}`,
    outline: 'none',
  },
})

const SelectWrapper = styled.div<{ active?: boolean; error?: boolean; disabled?: boolean }>(
  ({ active, error, disabled }) => ({
    border: `1px solid ${getBorderColor(error, disabled)}`,
    borderRadius: 4,
    position: 'relative',
    '& > svg': {
      position: 'absolute',
      right: 12,
      top: '50%',
      transform: active ? 'translateY(-50%) rotate(-180deg)' : 'translateY(-50%)',
      transition: '250ms transform ease-in-out',
    },
  }),
)

const ErrorMessage = styled.div({
  fontFamily: 'Nunito Sans',
  color: COLOR.RED[700],
  fontSize: 15,
  marginTop: 4,
})

const Img = styled.img({
  width: 20,
  paddingRight: 8,
})

const ImgVal = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const FieldHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
})

const InformationWrapper = styled.div({
  margin: '-12px -4px -6px',
})

export { Dropdown, DropdownProps, DropdownOption }
