import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { createPortal } from 'react-dom'
import ConfettiNode from 'react-dom-confetti'

const ApprovedConfetti: FC = () => {
  const [isConfettiActive, setIsConfettiActive] = useState<boolean>(false)
  const [isSecondBatchActive, setSecondBatchActive] = useState<boolean>(false)
  useEffect(() => {
    const secondBatchDelayFn = setTimeout(() => {
      setSecondBatchActive(true)
    }, 1000)
    setIsConfettiActive(true)
    return () => {
      clearTimeout(secondBatchDelayFn)
    }
  }, [])

  const baseConfettiConfig = {
    spread: 250,
    width: '10px',
    height: '10px',
    duration: 3000,
    dragFriction: 0.175,
    stagger: 5,
    startVelocity: 200,
    elementCount: 200,
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  }

  return createPortal(
    <>
      <ConfettiWrapper
        aria-label="background confetti"
        css={{ position: 'fixed', bottom: 0, left: 0 }}
      >
        <ConfettiNode active={isConfettiActive} config={{ ...baseConfettiConfig, angle: 45 }} />
      </ConfettiWrapper>

      <ConfettiWrapper
        aria-label="background confetti"
        css={{ position: 'fixed', bottom: 0, left: '10%' }}
      >
        <ConfettiNode active={isSecondBatchActive} config={{ ...baseConfettiConfig, angle: 45 }} />
      </ConfettiWrapper>

      <ConfettiWrapper
        aria-label="background confetti"
        css={{ position: 'fixed', bottom: 0, right: 0 }}
      >
        <ConfettiNode active={isConfettiActive} config={{ ...baseConfettiConfig, angle: 135 }} />
      </ConfettiWrapper>
      <ConfettiWrapper
        aria-label="background confetti"
        css={{ position: 'fixed', bottom: 0, right: '10%' }}
      >
        <ConfettiNode active={isSecondBatchActive} config={{ ...baseConfettiConfig, angle: 135 }} />
      </ConfettiWrapper>
    </>,
    document.body,
  )
}

const ConfettiWrapper = styled.aside()

export { ApprovedConfetti }
