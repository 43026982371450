import type { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import type { ActionType } from '../actions'
import fetchAll from './fetch-all'
import fetchAllForStore from './fetch-all-for-store'
import fetchUser from './fetch'
import update from './update'
import deleteUser from './delete'
import create from './create'
import { resendInvite } from './resend-invite'

export default function* sagas(): SagaIterator {
  yield all([takeLatest('USERS_FETCH_ALL' as ActionType, fetchAll)])
  yield all([takeLatest('USER_FETCH' as ActionType, fetchUser)])
  yield all([takeLatest('ACCOUNT_USERS_FETCH_ALL' as ActionType, fetchAllForStore)])
  yield all([takeLatest('USER_UPDATE' as ActionType, update)])
  yield all([takeLatest('USER_CREATE' as ActionType, create)])
  yield all([takeLatest('USER_DELETE' as ActionType, deleteUser)])
  yield all([takeLatest('RESEND_INVITE_EMAIL' as ActionType, resendInvite)])
}
