import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { OKTA_BASE_URL, OKTA_TOKEN_ENDPOINT, OKTA_SCOPE } from '@helloextend/client-constants'
import type { OktaTokenResponse, TokenRequest } from './types'

export const oktaApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: OKTA_BASE_URL }),
  reducerPath: 'oktaApi',
  tagTypes: ['okta'],
  endpoints: (build) => ({
    getOktaAccessToken: build.query<OktaTokenResponse, TokenRequest>({
      query: (req: TokenRequest) => ({
        url: OKTA_TOKEN_ENDPOINT,
        headers: {
          accept: 'application/json',
          'content-type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          redirect_uri: req.redirectUri,
          client_id: req.clientId,
          code: req.code,
          code_verifier: req.verifier,
          scope: OKTA_SCOPE,
        }),
      }),
      providesTags: (_, _err) => [{ type: 'okta' }],
    }),
  }),
})

export const { useLazyGetOktaAccessTokenQuery, useGetOktaAccessTokenQuery } = oktaApi
