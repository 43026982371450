import styled from '@emotion/styled'
import type { Claim, Contract } from '@helloextend/extend-api-client'
import { COLOR } from '@helloextend/zen'
import type { FC } from 'react'
import React from 'react'
import { useParams } from 'react-router'
import { ContractHeader } from './contract-header'
import { BulletList, DoneMail, GetHelpInverse } from '../../../../components/icons'
import { getContractDetailUrl } from '../../../../utils/route-helper'
import { ApprovedConfetti } from './approved-confetti'
import { useGetClaimResultMessaging } from './use-get-claim-result-messaging'
import { StyledLink } from '../../file-a-claim/file-a-claim'

interface FileClaimDetailsProps {
  handleOnClickContractDetails: () => void
  handleOnClickClaimDetails: () => void
  claim: Claim
  contract: Contract | undefined
}

const FileClaimDetails: FC<FileClaimDetailsProps> = ({
  handleOnClickContractDetails,
  handleOnClickClaimDetails,
  claim,
  contract,
}) => {
  const { headerText, titleText, firstBullet, secondBullet } = useGetClaimResultMessaging(claim)
  const { contractId } = useParams<{ contractId: string; claimId: string }>()

  return (
    <>
      {claim.status === 'approved' && <ApprovedConfetti />}
      <HeaderWrapper>
        <ContractHeader
          pageTitle={headerText}
          buttons={[
            {
              'data-cy': 'claim-details-button',
              text: 'Claim Details',
              emphasis: 'medium',
              onClick: handleOnClickClaimDetails,
            },
            {
              'data-cy': 'contract-details-button',
              text: 'Back to Contract Details',
              onClick: handleOnClickContractDetails,
            },
          ]}
        />
        {contract && contractId && (
          <ClaimIdLine data-cy="contract-transactionId">
            <ContentId>Contract ID:</ContentId>{' '}
            <StyledLink to={getContractDetailUrl(contractId)}>{contractId}</StyledLink>
          </ClaimIdLine>
        )}
      </HeaderWrapper>

      <MessageContainer>
        <MessageHeading>{titleText}</MessageHeading>
        <MessageRow>
          <IconContainer>
            <DoneMail />
          </IconContainer>
          {firstBullet}
        </MessageRow>
        <MessageRow>
          <IconContainer>
            <BulletList />
          </IconContainer>
          {secondBullet}
        </MessageRow>
        <MessageRow>
          <IconContainer>
            <GetHelpInverse />
          </IconContainer>
          <p>
            If the customer does not get an email, or needs additional support they can contact
            Extend online at{' '}
            <MessageLink
              href="https://customers.extend.com/contact"
              target="_blank"
              rel="noreferrer"
            >
              customers.extend.com/contact
            </MessageLink>
            .
          </p>
        </MessageRow>
      </MessageContainer>
    </>
  )
}

const ClaimIdLine = styled.div({
  marginBottom: 8,
  fontSize: 16,
})

const ContentId = styled.strong({
  color: COLOR.NEUTRAL[600],
})

const HeaderWrapper = styled.header({
  marginBottom: 80,
  fontSize: 20,
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
})

const MessageContainer = styled.div({
  width: 545,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  'p:first-of-type': {
    marginTop: 0,
  },
  p: {
    fontSize: 16,
  },
})

const MessageHeading = styled.h2({
  textAlign: 'center',
  marginRight: 'auto',
  marginLeft: 'auto',
  marginBottom: 56,
})

const MessageLink = styled.a({
  color: COLOR.BLUE[600],
  '&:visited': {
    color: COLOR.BLUE[600],
  },
})

const MessageRow = styled.div({
  display: 'flex',
  marginBottom: 56,
})

const IconContainer = styled.div({
  marginRight: 32,
})

export { FileClaimDetails, FileClaimDetailsProps }
