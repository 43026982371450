import styled from '@emotion/styled'
import type { ChangeEvent, FC } from 'react'
import React, { useMemo } from 'react'
import type { DefaultMessage, DefaultReply } from '@helloextend/extend-api-rtk-query'
import { MessageType, useCreatePhotoMutation } from '@helloextend/extend-api-rtk-query'
import { useDispatch } from 'react-redux'
import type { ValidImageExtension } from '@helloextend/zen'
import {
  ImageInput,
  useToaster,
  ToastColor,
  ToastDuration,
  isFileValidImage,
  isFileValidSize,
} from '@helloextend/zen'
import { getBase64 } from '../../utils'
import type { SelectedMessageBlock } from '../../../../../store/slices/amp-slice'
import {
  updateReplyImageSamplePhoto,
  removeReplyImageSamplePhoto,
} from '../../../../../store/slices/amp-slice'

type MessageBlockImageUploaderProps = {
  messageBlock: SelectedMessageBlock
}

const MAX_FILE_SIZE_MB = 1
const SUPPORTED_FILE_EXTENSIONS: ValidImageExtension[] = ['jpeg', 'jpg', 'gif', 'png']

const MessageBlockImageUploader: FC<MessageBlockImageUploaderProps> = ({ messageBlock }) => {
  const [createPhoto] = useCreatePhotoMutation()
  const { toast } = useToaster()
  const dispatch = useDispatch()
  const defaultReply = messageBlock.script.reply as DefaultReply

  const imageUrl = useMemo(() => {
    const currentImage = (defaultReply.messages as DefaultMessage[]).find(
      (message) => message.type === MessageType.image,
    )
    return currentImage?.imageUrl
  }, [defaultReply])

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const imageFile = event.target.files && event.target.files[0]

    if (!imageFile) {
      dispatch(removeReplyImageSamplePhoto())
      return
    }

    const isValidFile =
      isFileValidImage(imageFile, SUPPORTED_FILE_EXTENSIONS) &&
      isFileValidSize(imageFile, MAX_FILE_SIZE_MB)

    if (!isValidFile) {
      return
    }

    try {
      const base64 = await getBase64(imageFile)
      if (base64) {
        const { id } = await createPhoto({ photo: base64 }).unwrap()
        dispatch(
          updateReplyImageSamplePhoto({
            imageUrl: URL.createObjectURL(imageFile),
            content: id,
          }),
        )
      }
    } catch (e: unknown) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  if (!messageBlock) return null

  return (
    <ImageUploadContainer data-cy="image-upload-container">
      <ImageInput
        id="imageUploader"
        data-cy="image-uploader"
        onChange={handleImageChange}
        label="Sample Image (optional)"
        helperText="An additional instruction for consumers while uploading photos."
        currentImage={imageUrl}
        imageExtensions={SUPPORTED_FILE_EXTENSIONS}
        maxSizeMb={MAX_FILE_SIZE_MB}
        height={132}
      />
    </ImageUploadContainer>
  )
}

const ImageUploadContainer = styled.div({
  width: 456,
})

export { MessageBlockImageUploader, MessageBlockImageUploaderProps }
