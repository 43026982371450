import { getAccessToken, setAccessToken } from '../utils/local-storage'
import type { RootState } from '../reducers'

export function save(state: RootState): void {
  setAccessToken(state.auth.accessToken)
}

export function load(): any {
  return {
    auth: {
      accessToken: getAccessToken(),
    },
  }
}
