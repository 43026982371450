import * as selectors from './selectors'
import reducer from './reducers'
import sagas from './sagas'
import { actions } from './actions'

export type { ActionType as UsersActionType, Action as UsersAction } from './actions'
export type { ReducerState as UsersReducerState } from './reducers'

export {
  actions as usersActions,
  selectors as usersSelectors,
  reducer as usersReducer,
  sagas as usersSagas,
}
