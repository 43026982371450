import facepaint from 'facepaint'

export const breakpoints = [415, 768, 1025, 1367, 2049]

export const MAX_WIDTH = 1680

export const fp = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`))

export const bp = {
  sm: `@media (min-width: ${breakpoints[0]}px)`,
  md: `@media (min-width: ${breakpoints[1]}px)`,
  lg: `@media (min-width: ${breakpoints[2]}px)`,
  xl: `@media (min-width: ${breakpoints[3]}px)`,
  maxWidth: `@media (min-width: ${MAX_WIDTH}px)`,
  maxWidthMd: `@media (max-width: ${breakpoints[1]}px)`,
  xxl: `@media (min-width: ${breakpoints[4]}px)`,
}
