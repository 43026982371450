import { faker } from '@faker-js/faker/locale/en'
import type { ProductsSearchItem } from '@helloextend/extend-api-client'

export function generateProductSearchItem(
  overrides: Partial<ProductsSearchItem> = {},
): ProductsSearchItem {
  return {
    imageUrl: faker.image.cats(),
    name: faker.commerce.productName(),
    referenceId: faker.datatype.uuid(),
    sku: faker.datatype.uuid(),
    price: faker.datatype.number({ max: 9999999, min: 0, precision: 1 }),
    enabled: faker.datatype.boolean(),
    variantCount: faker.datatype.number({ max: 10, min: 0, precision: 1 }),
    matchedVariantCount: faker.datatype.number({ max: 10, min: 0, precision: 1 }),
    category: faker.commerce.department(),
    parentReferenceId: undefined,
    warrantyStatus: undefined,
    ...overrides,
  }
}
