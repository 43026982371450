import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { DatePicker } from '@helloextend/merchants-ui'
import type { DateRangeFilterValues } from './types'

type DateRangeFilterProps = {
  property: string
  values?: DateRangeFilterValues
  onFilterChange: (property: string, values: DateRangeFilterValues | null) => void
  onMenuLock: (isLocked: boolean) => void
}

const buildFilterValues = (start: Date | null, end: Date | null): DateRangeFilterValues | null => {
  if (!start && !end) {
    return null
  }

  return {
    type: 'dateRange',
    start,
    end,
  }
}

const DateRangeFilter: FC<DateRangeFilterProps> = ({
  property,
  values = {
    type: 'dateRange',
    start: null,
    end: null,
  },
  onFilterChange,
  onMenuLock,
}) => {
  const today = useMemo(() => {
    const d = new Date()
    d.setHours(0, 0, 0, 0)
    return d
  }, [])

  const handleStartDate = useCallback(
    (newDate: Date | null) => {
      onFilterChange(property, buildFilterValues(newDate, values.end || null))
    },
    [values.end, property, onFilterChange],
  )

  const handleEndDate = useCallback(
    (newDate: Date | null) => {
      onFilterChange(property, buildFilterValues(values.start || null, newDate))
    },
    [values.start, property, onFilterChange],
  )

  const handleCalendarOpen = useCallback(() => {
    onMenuLock(true)
  }, [onMenuLock])

  const handleCalendarClose = useCallback(() => {
    onMenuLock(false)
  }, [onMenuLock])

  return (
    <DateRangeWrapper>
      <DateLabel>From</DateLabel>
      <DatePickerWrapper>
        <DatePicker
          onChange={handleStartDate}
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          selected={values.start || null}
          maxDate={values.end || today}
          placeHolder=""
          data-cy="startDate"
        />
      </DatePickerWrapper>
      <DateLabel>To</DateLabel>
      <DatePickerWrapper>
        <DatePicker
          onChange={handleEndDate}
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          selected={values.end || null}
          minDate={values.start}
          maxDate={today}
          placeHolder=""
          data-cy="endDate"
        />
      </DatePickerWrapper>
    </DateRangeWrapper>
  )
}
const DateRangeWrapper = styled.div({
  padding: '12px 12px 0px 12px',
})

const DateLabel = styled.div({
  fontWeight: 'bold',
  margin: '0px 0px 8px 0px',
})

const DatePickerWrapper = styled.div({
  display: 'flex',
  margin: '0px 0px 16px 0px',
})

export { DateRangeFilter }
