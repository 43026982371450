import type { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import type { ActionType } from '../actions'
import fetch from './fetch'
import create from './create'
import deleteClaimPhoto from './delete'

export default function* sagas(): SagaIterator {
  yield all([takeLatest('CONTRACTS_CLAIMS_PHOTOS_FETCH' as ActionType, fetch)])
  yield all([takeLatest('CONTRACTS_CLAIMS_PHOTOS_CREATE' as ActionType, create)])
  yield all([takeLatest('CONTRACTS_CLAIMS_PHOTOS_DELETE' as ActionType, deleteClaimPhoto)])
}
