import { COLOR } from '../../tokens/colors'
import type { ToastColorArgs } from './types'
import { ToastColor } from './types'

const toastColors: Record<ToastColor, ToastColorArgs> = {
  [ToastColor.blue]: {
    background: COLOR.BLUE[200],
    font: COLOR.BLUE[800],
    indicatorContainer: COLOR.BLUE[300],
    indicator: COLOR.BLUE[500],
    buttonColor: 'blue',
    iconColor: COLOR.BLUE[700],
  },
  [ToastColor.green]: {
    background: COLOR.GREEN[200],
    font: COLOR.GREEN[800],
    indicatorContainer: COLOR.GREEN[300],
    indicator: COLOR.GREEN[500],
    buttonColor: 'green',
    iconColor: COLOR.GREEN[700],
  },
  [ToastColor.neutral]: {
    background: COLOR.NEUTRAL[200],
    font: COLOR.NEUTRAL[1000],
    indicatorContainer: COLOR.NEUTRAL[400],
    indicator: COLOR.NEUTRAL[600],
    buttonColor: 'neutral',
    iconColor: COLOR.NEUTRAL[800],
  },
  [ToastColor.yellow]: {
    background: COLOR.YELLOW[200],
    font: COLOR.YELLOW[800],
    indicatorContainer: COLOR.YELLOW[300],
    indicator: COLOR.YELLOW[500],
    buttonColor: 'yellow',
    iconColor: COLOR.YELLOW[700],
  },
  [ToastColor.red]: {
    background: COLOR.RED[200],
    font: COLOR.RED[800],
    indicatorContainer: COLOR.RED[300],
    indicator: COLOR.RED[500],
    buttonColor: 'red',
    iconColor: COLOR.RED[700],
  },
}

export { toastColors }
