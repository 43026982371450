import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { get } from '@helloextend/extend-api-fetch'
import type { Content } from '../models'

export async function fetch({
  accessToken,
  storeId,
  serviceType,
  coverageType,
  productCategory,
}: {
  accessToken: string
  storeId: string
  serviceType: string
  coverageType: string
  productCategory: string
}): Promise<ApiResponse<Content>> {
  return get('/content/offers', {
    qs: { storeId, serviceType, coverageType, productCategory },
    accessToken,
  })
}
