import { createActionFailure } from '../../factories'

export const importPlanTermsVersion = (termId: string, file: File) =>
  ({
    type: 'PLAN_TERM_VERSION_IMPORT',
    payload: { termId, file, language: 'en' },
  } as const)

export const importPlanTermsVersionRequest = () =>
  ({
    type: 'PLAN_TERM_VERSION_IMPORT_REQUEST',
  } as const)

export const importPlanTermsVersionSuccess = () =>
  ({
    type: 'PLAN_TERM_VERSION_IMPORT_SUCCESS',
  } as const)

export const importPlanTermsVersionFailure = createActionFailure('PLAN_TERM_VERSION_IMPORT_FAILURE')
