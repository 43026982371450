import { createApi } from '@reduxjs/toolkit/query/react'
import type { Sku } from '@helloextend/extend-api-client/src/models/sku'
import { baseQuery } from '../base-query'
import type {
  SkuCreateRequest,
  SkuSearchResponseMapped,
  SkuSearchResponse,
  SkuVersionRequest,
  SkuSearch,
  SkuExportResponse,
  SkuImport,
  SkuImportResponse,
  SkusSearchCount,
  SkuSearchParams,
  SkuQueryResponse,
} from './types'
import { buildMultiValue } from '../utils/querystring'
import { safeBtoa } from '../../helpers'

const findLatestSkuVersion = (versions: Sku[]): Sku => {
  return versions.sort((a: Sku, b: Sku) => b.version - a.version)[0]
}

/**
 * used to encode the complex filter queries and avoid issues with special characters
 */
const buildCacheKey = (args: SkuSearchParams): string => {
  const { offset, limit, ...qsWithoutPagination } = args
  return safeBtoa(JSON.stringify(qsWithoutPagination))
}

export const skusApi = createApi({
  baseQuery,
  reducerPath: 'Skus',
  tagTypes: ['Skus'],
  endpoints: (build) => ({
    createSku: build.mutation<Sku, SkuCreateRequest>({
      query: (request) => {
        return {
          url: 'sku-management',
          method: 'POST',
          body: request,
        }
      },
      invalidatesTags: ['Skus'],
    }),
    getSku: build.query<Sku, string>({
      query: (skuId: string) => ({
        url: `/sku-management/${skuId}?cc=${Date.now()}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_, _err, skuId) => [{ type: 'Skus', id: skuId }],
    }),
    getSkuVersion: build.query<Sku, SkuVersionRequest>({
      query: ({ skuId, versionId }) => ({
        url: `/sku-management/${skuId}?version=${versionId}`,
      }),
      providesTags: (_, _err, { skuId }) => [{ type: 'Skus', id: skuId }],
    }),
    searchSkuById: build.query<SkuSearchResponseMapped, string>({
      query: (skuId?: string) => ({
        url: '/sku-management/search',
        params: { filter: skuId },
      }),
      transformResponse: (response: SkuSearchResponse): SkuSearchResponseMapped => {
        const mappedSkus = response.items.map(
          (item: SkuSearch): Sku => findLatestSkuVersion(item?.versions),
        )
        return { ...response, items: mappedSkus }
      },
      providesTags: (result) => {
        if (result?.items?.length) {
          return [
            ...result.items.map(
              ({ id }: Sku) =>
                ({
                  type: 'Skus',
                  id,
                } as const),
            ),
            { type: 'Skus', id: 'LIST' },
          ]
        }
        return [{ type: 'Skus', id: 'LIST' }]
      },
    }),
    listSkuVersions: build.query<SkuSearchResponseMapped, string>({
      query: (skuId: string) => ({
        url: '/sku-management/search',
        params: { filter: skuId },
      }),
      transformResponse: (response: SkuSearchResponse): SkuSearchResponseMapped => {
        const sortedVersions = response.items[0].versions.sort((a, b) => a.version - b.version)
        return { ...response, items: sortedVersions }
      },
      providesTags: (result) => {
        if (result?.items?.length) {
          return [
            ...result.items.map(
              ({ id }: Sku) =>
                ({
                  type: 'Skus',
                  id,
                } as const),
            ),
            { type: 'Skus', id: 'LIST' },
          ]
        }
        return [{ type: 'Skus', id: 'LIST' }]
      },
    }),
    exportSkus: build.mutation<SkuExportResponse, string>({
      query: (skuId: string) => ({
        url: `/sku-management/export${skuId ? `?skuId=${skuId}` : ''}`,
        method: 'POST',
      }),
    }),
    getUploadUrl: build.mutation<SkuImportResponse, string>({
      query: (fileName) => ({
        url: `/sku-management/import?originalFilename=${fileName}`,
        method: 'POST',
      }),
    }),
    importSkus: build.mutation<string, SkuImport>({
      query: ({ url, fileArrayBuffer }) => {
        return { url, method: 'PUT', body: fileArrayBuffer }
      },
      invalidatesTags: ['Skus'],
    }),
    getSkusSearchCount: build.query<SkusSearchCount, SkuSearchParams>({
      query: (query) => {
        const queryString = query ? buildMultiValue(query as Record<string, unknown>) : ''

        return {
          url: `/sku-management/query-count?${queryString}`,
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest;',
          },
        }
      },
    }),
    querySkus: build.query<SkuQueryResponse, SkuSearchParams>({
      query: (query) => {
        const queryString = query ? buildMultiValue(query as Record<string, unknown>) : ''

        return {
          url: `/sku-management/query?${queryString}`,
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest;',
          },
        }
      },
      providesTags: (_, _err, args) => {
        return [{ type: 'Skus', id: buildCacheKey(args) }]
      },
    }),
  }),
})

export const {
  useCreateSkuMutation,
  useGetSkuQuery,
  useSearchSkuByIdQuery,
  useLazySearchSkuByIdQuery,
  useGetSkuVersionQuery,
  useListSkuVersionsQuery,
  useExportSkusMutation,
  useGetUploadUrlMutation,
  useImportSkusMutation,
  useGetSkusSearchCountQuery,
  useQuerySkusQuery,
} = skusApi
