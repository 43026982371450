import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  GetConfigurationQueryString,
  LeadsCreatedConfigurationGetResponse,
  LeadsCreatedConfigurationPutRequest,
  PostSendTestEmailRequest,
  UploadStoreLogoResponse,
  UploadStoreLogoRequest,
} from '@helloextend/extend-api-client'
import { baseQuery } from '../base-query'

export const messagingApi = createApi({
  baseQuery,
  reducerPath: 'messaging',
  tagTypes: ['messaging'],
  endpoints: (build) => ({
    getConfiguration: build.query<
      LeadsCreatedConfigurationGetResponse,
      GetConfigurationQueryString
    >({
      query: (qs: GetConfigurationQueryString) => ({
        url: `messaging/messages/configurations`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
        params: qs,
      }),
      providesTags: (_, _err) => [{ type: 'messaging' }],
    }),
    saveConfiguration: build.mutation<string, LeadsCreatedConfigurationPutRequest>({
      query: (body: LeadsCreatedConfigurationPutRequest) => ({
        url: 'messaging/messages/configurations',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_, _err) => [{ type: 'messaging' }],
    }),
    sendTestEmail: build.mutation<string, PostSendTestEmailRequest>({
      query: (body: PostSendTestEmailRequest) => ({
        url: 'messaging/messages',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest',
        },
        method: 'POST',
        body,
      }),
    }),
    uploadStoreLogo: build.mutation<UploadStoreLogoResponse, UploadStoreLogoRequest>({
      query: ({ storeId, base64image }) => ({
        url: `messaging/messages/stores/${storeId}/logo`,
        headers: {
          'content-type': 'plain/text',
          accept: 'application/json; version=latest',
        },
        method: 'PUT',
        body: base64image,
      }),
    }),
  }),
})

export const {
  useGetConfigurationQuery,
  useSaveConfigurationMutation,
  useSendTestEmailMutation,
  useUploadStoreLogoMutation,
} = messagingApi
