import type { ButtonSize } from './types'
import { ButtonLayout } from './types'

export const buttonPadding: Record<ButtonSize, Record<ButtonLayout, string>> = {
  xsmall: {
    [ButtonLayout.textOnly]: '4px 10px',
    [ButtonLayout.textOnlyReducedPadding]: '4px 10px',
    [ButtonLayout.graphicOnly]: '4px',
    [ButtonLayout.textWithGraphicLeft]: '4px 10px 4px 6px',
    [ButtonLayout.textWithGraphicRight]: '4px 6px 4px 10px',
  },
  small: {
    [ButtonLayout.textOnly]: '4px 24px',
    [ButtonLayout.textOnlyReducedPadding]: '4px 12px',
    [ButtonLayout.graphicOnly]: '6px',
    [ButtonLayout.textWithGraphicLeft]: '4px 14px 4px 10px',
    [ButtonLayout.textWithGraphicRight]: '4px 10px 4px 14px',
  },
  regular: {
    [ButtonLayout.textOnly]: '8px 32px',
    [ButtonLayout.textOnlyReducedPadding]: '8px 20px',
    [ButtonLayout.graphicOnly]: '8px',
    [ButtonLayout.textWithGraphicLeft]: '8px 20px 8px 16px',
    [ButtonLayout.textWithGraphicRight]: '8px 16px 8px 20px',
  },
  large: {
    [ButtonLayout.textOnly]: '10px 40px',
    [ButtonLayout.textOnlyReducedPadding]: '10px 24px',
    [ButtonLayout.graphicOnly]: '10px',
    [ButtonLayout.textWithGraphicLeft]: '10px 24px 10px 20px',
    [ButtonLayout.textWithGraphicRight]: '10px 20px 10px 24px',
  },
}
