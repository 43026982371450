import type { Store } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const update = (id: string, data: DeepPartial<Store>, accessToken: string) =>
  ({
    type: 'STORES_UPDATE',
    payload: { id, data, accessToken },
  } as const)

export const updateRequest = () => ({ type: 'STORES_UPDATE_REQUEST' } as const)

export const updateSuccess = (store: Store) =>
  ({
    type: 'STORES_UPDATE_SUCCESS',
    payload: store,
  } as const)

export const updateFailure = createActionFailure('STORES_UPDATE_FAILURE')

export const updateSuccessReset = () => ({ type: 'STORES_UPDATE_RESET' } as const)
