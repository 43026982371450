import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@helloextend/client-branding'
import styled from '@emotion/styled'
import { Global, keyframes } from '@emotion/core'
import { bp } from '@helloextend/client-utils/breakpoints'
import { Link } from 'react-router-dom'
import images from '../../images'

const SystemError: FC = () => (
  <>
    <Global styles="body {margin: 0}" />
    <Gradient>
      <Link to="/">
        <Logo src={images.extendLogoWhite} />
      </Link>
      <Main role="main">
        <Title>Something broke...</Title>
        <Description>
          Uh oh, looks like this page blew a fuse.
          <br /> Don’t worry, we got you covered.
        </Description>
        <HomeButton href="/">Back to Home</HomeButton>
        <ImageWrapper>
          <BreakerBox src={images.breakerBox} alt="error" />
          <Sparks src={images.sparksImage} alt="sparks" />
        </ImageWrapper>
      </Main>
    </Gradient>
  </>
)

const BreakerBox = styled.img({
  width: '100%',
  height: 'auto',
})

const spark = keyframes({
  '0%': {
    zIndex: 1,
  },
  '4%': {
    zIndex: -1,
  },
  '97%': {
    zIndex: -1,
  },
  '98%': {
    zIndex: 1,
  },
  '100%': {
    zIndex: -1,
  },
})

const Sparks = styled.img({
  width: '30%',
  position: 'absolute',
  left: '21%',
  animation: `${spark} 3s infinite`,
  // bp when breaker box image starts scaling
  '@media (min-width: 684px)': {
    top: '0',
    left: '145px',
  },
})

const Main = styled.div({
  fontFamily: 'Nunito Sans, Arial, Helvetica, sans-serif',
  padding: 0,
  margin: 0,
  minHeight: '100vh',
  color: COLOR.WHITE,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
})

const Logo = styled.img({
  position: 'absolute',
  padding: 32,
  width: 103,
  height: 24,
})

const Gradient = styled.div<{ page?: string }>({
  position: 'relative',
  background:
    'radial-gradient(ellipse 80% 30% at 50% 71%, rgb(18, 52, 96) 0%, rgb(34, 75, 197) 100%)',
  [bp.md]: {
    background:
      'radial-gradient(ellipse 100% 100% at 50% 100%, rgb(18, 52, 96) 0%, rgb(34, 75, 197) 100%)',
  },
})

const Title = styled.h1({
  margin: 0,
  padding: 0,
  lineHeight: '32px',
  fontWeight: 'bold',
  fontSize: 25,
  marginTop: 203,
  marginBottom: 5,
  [bp.md]: {
    marginTop: 197,
    marginBottom: 5,
    fontSize: 36,
    lineHeight: '45px',
  },
})

const Description = styled.p({
  fontSize: 14,
  lineHeight: '19px',
  margin: 0,
  marginBottom: 24,
  [bp.md]: {
    lineHeight: '27px',
    fontSize: 20,
  },
})

const HomeButton = styled.a({
  fontWeight: 'bold',
  fontSize: 11,
  textDecoration: 'none',
  color: COLOR.WHITE,
  borderRadius: 3,
  border: `1px solid ${COLOR.WHITE}`,
  padding: '6px 12px',
  marginBottom: 24,
  '&:visited': {
    color: COLOR.WHITE,
  },
  '&:hover': {
    color: COLOR.WHITE,
    textDecoration: 'none',
  },
  [bp.md]: {
    fontSize: 16,
    borderRadius: 4,
    padding: '8px 16px',
    marginBottom: 0,
  },
})

const ImageWrapper = styled.div({
  position: 'relative',
  width: 'initial',
  marginTop: '15px',
  [bp.md]: {
    marginTop: '75px',
    width: 'initial',
  },
})

export { SystemError }
