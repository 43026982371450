import type { Contract } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const refund = (contract: Contract, accessToken: string, commit?: boolean) =>
  ({
    type: 'CONTRACTS_REFUND',
    payload: { contract, accessToken, commit },
  } as const)

export const refundRequest = () =>
  ({
    type: 'CONTRACTS_REFUND_REQUEST',
  } as const)

export const refundSuccess = (contract: Contract) =>
  ({
    type: 'CONTRACTS_REFUND_SUCCESS',
    payload: contract,
  } as const)

export const refundFailure = createActionFailure('CONTRACTS_REFUND_FAILURE')
