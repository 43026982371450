import type { VFC, ChangeEvent } from 'react'
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import { If, Then, Else } from 'react-if'
import type { FieldProps } from '../field'
import { Field } from '../field'
import { ToastColor, ToastDuration, useToaster } from '../../toaster'
import { Close, Upload, Warning } from '../../../tokens/icons'
import { Badge } from '../../badge'
import { COLOR } from '../../../tokens/colors'
import { isValidFile } from './file-input-utils'
import { DivButton } from '../../button'
import { Spinner } from '../../spinner'
import { IconSize } from '../../icon'
import { formatHumanReadableFileSize, getFileNameFromUrl, isFileValidSize } from '../../../utils'

export type FileRequirement = {
  label: string
  isError: boolean
}

export interface FileInputProps
  extends Omit<
    FieldProps,
    | 'value'
    | 'startAdornment'
    | 'endAdornment'
    | 'prefix'
    | 'suffix'
    | 'icon'
    | 'onClearRequest'
    | 'maxLength'
    | 'isCounterHiddenWhenValid'
    | 'actionButtonProps'
    | 'hasNoBorder'
    | 'isInternallyFocused'
  > {
  ariaLabel?: string
  displayedFile?: string
  fileExtensions?: string[]
  isUploading?: boolean
  maxSizeMb?: number
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

export const FileInput: VFC<FileInputProps> = ({
  'data-cy': dataCy,
  label,
  displayedFile,
  ariaLabel,
  id,
  fileExtensions = [],
  isDisabled = false,
  isError = false,
  isUploading,
  subtext,
  errorFeedback,
  helperText,
  onChange = () => {},
  maxSizeMb = 6,
  placeholder = 'Select a file',
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const dropZoneRef = useRef<HTMLButtonElement>(null)
  const [file, setFile] = useState<string | null>(displayedFile || null)
  const [fileSize, setFileSize] = useState(0)
  const [fileName, setFileName] = useState('')
  const [isDraggingFileOverDropZone, setIsDraggingFileOverDropZone] = useState(false)
  const [isDraggingFileOverWindow, setIsDraggingFileOverWindow] = useState(false)
  const [isValidFileType, setIsValidFileType] = useState(true)
  const [isValidFileSize, setIsValidFileSize] = useState(true)
  const { toast } = useToaster()
  const windowDragCounter = useRef(0)
  const dropZoneDragCounter = useRef(0)

  const fileRequirements: FileRequirement[] = useMemo(
    () => [
      { label: fileExtensions.join(', ').toUpperCase(), isError: !isValidFileType },
      { label: `${maxSizeMb}MB max`, isError: !isValidFileSize },
    ],
    [fileExtensions, isValidFileType, maxSizeMb, isValidFileSize],
  )

  useEffect(() => {
    if (!displayedFile) {
      setFile('')
      setFileName('')
    }
    if (displayedFile && displayedFile !== file) {
      setFile(displayedFile)
      setFileName(getFileNameFromUrl(displayedFile))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedFile])

  const handleReset = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      setFile('')
      setFileName('')
      setIsValidFileType(true)
      setIsValidFileSize(true)
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
        fileInputRef.current.dispatchEvent(new Event('change', { bubbles: true }))
      }
    },
    [fileInputRef],
  )

  const handleSelectFile = (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>): void => {
    e.stopPropagation()
    fileInputRef.current?.click()
  }

  const handleWindowDragEnter = useCallback((e: DragEvent) => {
    windowDragCounter.current += 1
    if (e.dataTransfer?.types[0] === 'Files') setIsDraggingFileOverWindow(true)
  }, [])

  const handleWindowDragLeave = useCallback(() => {
    windowDragCounter.current -= 1
    if (windowDragCounter.current === 0) {
      setIsDraggingFileOverWindow(false)
    }
  }, [])

  const handleWindowDrop = useCallback(() => {
    setIsDraggingFileOverDropZone(false)
    setIsDraggingFileOverWindow(false)
  }, [])

  useEffect(() => {
    window.addEventListener('dragenter', handleWindowDragEnter)
    window.addEventListener('dragleave', handleWindowDragLeave)
    window.addEventListener('drop', handleWindowDrop)
    return () => {
      window.removeEventListener('dragenter', handleWindowDragEnter)
      window.removeEventListener('dragleave', handleWindowDragLeave)
      window.removeEventListener('drop', handleWindowDrop)
    }
  }, [handleWindowDragEnter, handleWindowDragLeave, handleWindowDrop])

  const handleWindowDragOver = useCallback((e: DragEvent) => {
    e.preventDefault()
  }, [])

  const handleDropZoneDragEnter = useCallback(
    (e: DragEvent): void => {
      if (!e.dataTransfer || isDisabled) return
      dropZoneDragCounter.current += 1
      setIsDraggingFileOverDropZone(true)
    },
    [isDisabled],
  )

  const handleDropZoneDragLeave = useCallback((): void => {
    dropZoneDragCounter.current -= 1
    if (dropZoneDragCounter.current === 0) {
      setIsDraggingFileOverDropZone(false)
    }
  }, [])

  const handleDropZoneDrop = useCallback(
    (e: DragEvent): void => {
      e.preventDefault()

      if (!e.dataTransfer || isDisabled) return

      if (e.dataTransfer.files.length > 1) {
        toast({
          message: 'Please drop a single file.',
          toastColor: ToastColor.yellow,
          icon: Warning,
          toastDuration: ToastDuration.short,
        })
        return
      }

      const { files } = e.dataTransfer
      if (files && files.length > 0) {
        if (fileInputRef.current) {
          fileInputRef.current.files = files
          fileInputRef.current.dispatchEvent(new Event('change', { bubbles: true }))
        }
      }
    },
    [fileInputRef, isDisabled, toast],
  )

  useEffect(() => {
    const ref = dropZoneRef?.current

    ref?.addEventListener('dragover', handleWindowDragOver)
    ref?.addEventListener('dragenter', handleDropZoneDragEnter)
    ref?.addEventListener('dragleave', handleDropZoneDragLeave)
    ref?.addEventListener('drop', handleDropZoneDrop)

    return () => {
      ref?.removeEventListener('dragover', handleWindowDragOver)
      ref?.removeEventListener('dragenter', handleDropZoneDragEnter)
      ref?.removeEventListener('dragleave', handleDropZoneDragLeave)
      ref?.removeEventListener('drop', handleDropZoneDrop)
    }
  }, [handleDropZoneDragEnter, handleDropZoneDragLeave, handleDropZoneDrop, handleWindowDragOver])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange(e)

    if (!e.currentTarget.files || e.currentTarget.files.length === 0) return

    const currentFile = e.currentTarget.files[0]

    setIsValidFileType(isValidFile(currentFile, fileExtensions))
    setIsValidFileSize(isFileValidSize(currentFile, maxSizeMb))

    setFile(URL.createObjectURL(currentFile))
    setFileName(currentFile.name)

    if (fileInputRef.current?.files) setFileSize(fileInputRef.current.files[0].size)
  }

  const mappedFileExtensions = useMemo(() => {
    return fileExtensions.map((fileExtension) => `.${fileExtension}`)
  }, [fileExtensions])

  return (
    <Field
      id={id}
      label={label}
      value=""
      isDisabled={isDisabled}
      errorFeedback={errorFeedback}
      isError={isError}
      subtext={subtext}
      hasNoBorder
      helperText={helperText}
      data-cy={dataCy && `${dataCy}:field`}
    >
      <FieldContent>
        <DropZone
          ref={dropZoneRef}
          isDraggingOverDropZone={isDraggingFileOverDropZone}
          isDraggingOverWindow={isDraggingFileOverWindow}
          onClick={handleSelectFile}
          disabled={isDisabled}
          isError={isError || !isValidFileType || !isValidFileSize}
        >
          <If condition={isDraggingFileOverWindow && !isDisabled}>
            <Then>
              <DropZoneLabel>Drop a file here.</DropZoneLabel>
            </Then>
            <Else>
              {file ? (
                <>
                  <Text isMuted={isUploading}>{`${fileName} (${formatHumanReadableFileSize(
                    fileSize,
                  )})`}</Text>
                  <ButtonContainer>
                    <DivButton
                      data-cy={dataCy && `${dataCy}:reset-button`}
                      icon={Close}
                      size="small"
                      emphasis="low"
                      color="neutral"
                      onClick={handleReset}
                      isDisabled={isDisabled}
                    />
                    {isUploading && (
                      <SpinnerContainer data-tooltip="File is uploading">
                        <Spinner size={IconSize.small} />
                      </SpinnerContainer>
                    )}
                  </ButtonContainer>
                </>
              ) : (
                <>
                  <Placeholder>{placeholder}</Placeholder>
                  <ButtonContainer>
                    <DivButton
                      data-cy={dataCy && `${dataCy}:upload-button`}
                      icon={Upload}
                      size="small"
                      emphasis="low"
                      color="neutral"
                      onClick={handleSelectFile}
                      isDisabled={isDisabled}
                    />
                  </ButtonContainer>
                </>
              )}
            </Else>
          </If>
        </DropZone>
        <Footer>
          <Badges>
            {fileRequirements.map((fileRequirement) => {
              const { label: requirementLabel, isError: isRequirementError } = fileRequirement
              return (
                <Badge
                  size="small"
                  text={requirementLabel}
                  emphasis="low"
                  color={isRequirementError ? 'red' : 'neutral'}
                  {...(isRequirementError && { icon: Warning })}
                  data-cy={dataCy && `${dataCy}:requirement`}
                  key={requirementLabel}
                />
              )
            })}
          </Badges>
        </Footer>
      </FieldContent>
      <HiddenFileInput
        id={id}
        accept={mappedFileExtensions.join(',')}
        ref={fileInputRef}
        type="file"
        data-cy={dataCy && `${dataCy}:input`}
        onChange={handleInputChange}
        disabled={isDisabled}
        aria-label={ariaLabel}
      />
    </Field>
  )
}

const FieldContent = styled.div({
  display: 'grid',
  gridTemplateColumns: 'minmax(0, 1fr)',
  gap: 4,
  width: '100%',
})

const DropZone = styled.button<{
  disabled: boolean
  isDraggingOverDropZone: boolean
  isDraggingOverWindow: boolean
  isError: boolean
}>(({ disabled, isDraggingOverDropZone, isDraggingOverWindow, isError }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 4,
  padding: 0,
  height: 40,
  textAlign: 'left',
  borderWidth: 1,
  borderStyle: 'solid',
  ...(isError
    ? {
        borderColor: isDraggingOverWindow ? COLOR.BLUE[400] : COLOR.RED[700],
      }
    : {
        borderColor: isDraggingOverWindow ? COLOR.BLUE[400] : COLOR.NEUTRAL[400],
      }),
  outline: 'none',
  borderRadius: 4,
  background: 'none',
  '& svg': {
    display: 'block',
  },
  ...(isDraggingOverWindow && {
    backgroundColor: isDraggingOverDropZone ? COLOR.BLUE[200] : COLOR.BLUE[100],
  }),
  ...(!disabled && {
    cursor: 'pointer',
    '&:focus-visible': {
      borderColor: COLOR.BLUE[700],
    },
  }),
}))

const Placeholder = styled.div({
  flex: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '1rem',
  lineHeight: '1.5em',
  padding: '8px 0 8px 12px',
  color: COLOR.NEUTRAL[600],
})

const Text = styled.div<{
  isMuted?: boolean
}>(({ isMuted }) => ({
  flex: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '1rem',
  lineHeight: '1.5em',
  padding: '8px 0 8px 12px',
  ...(isMuted && { color: COLOR.NEUTRAL[600] }),
}))

const ButtonContainer = styled.div({
  display: 'flex',
  padding: 3,
})

const SpinnerContainer = styled.div({
  padding: 6,
})

const DropZoneLabel = styled.div({
  width: '100%',
  height: '100%',
  display: 'grid',
  placeContent: 'center',
})

const Footer = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: 4,
})

const Badges = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: 12,
})

const HiddenFileInput = styled.input({
  display: 'none',
})
