import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { DatePicker } from '@helloextend/merchants-ui'
import { COLOR } from '@helloextend/client-branding'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { Button, ButtonGroup, TextArea } from '@helloextend/zen'
import { useFulfillServiceOrderMutation } from '@helloextend/extend-api-rtk-query'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import { formSchema } from './schema'
import type { Values } from './schema'

interface CompleteRepairFormProps {
  serviceOrder: ServiceOrder
}

const CompleteRepairForm: FC<CompleteRepairFormProps> = ({ serviceOrder }) => {
  const dispatch = useDispatch()
  const [fulfillServiceOrder, { isSuccess, isLoading }] = useFulfillServiceOrderMutation()
  const {
    errors,
    handleSubmit,
    setFieldValue,
    handleChange,
    values: { repairCompletedAt, repairExplanation },
  } = useFormik<Values>({
    enableReinitialize: true,
    initialValues: formSchema.default(),
    validationSchema: formSchema,
    onSubmit: (formValues: Values) => {
      if (!formValues.repairCompletedAt) return
      fulfillServiceOrder({
        serviceOrderId: serviceOrder?.id ?? '',
        body: {
          method: 'repair_onsite',
          repairCompletedAt: formValues.repairCompletedAt,
          repairExplanation: formValues.repairExplanation,
        },
      })
    },
  })

  useEffect(() => {
    if (isSuccess) {
      dispatch(setClaimDetailsToast('Repair completed!'))
      dispatch(setClaimDetailsActiveView(''))
    }
  }, [isSuccess, dispatch])

  const handleDateChange = (selected: Date | null): void => {
    setFieldValue('repairCompletedAt', selected?.getTime())
  }

  return (
    <form onSubmit={handleSubmit}>
      <LabelText>Repair Date</LabelText>
      <LabelWrapper invalid={Boolean(errors.repairCompletedAt)}>
        <DatePicker
          maxDate={new Date()}
          onChange={handleDateChange}
          selected={repairCompletedAt ? new Date(repairCompletedAt) || null : null}
          placeHolder=""
          fullWidth
          data-cy="repair-completed-at"
        />
        <ErrorMessage>{errors.repairCompletedAt || ''}</ErrorMessage>
      </LabelWrapper>
      <LabelText>Repair Explanation (Optional)</LabelText>
      <TextArea
        maxLength={3000}
        onChange={handleChange}
        value={repairExplanation ?? ''}
        data-cy="repair-explanation"
        id="repairExplanation"
      />
      <ButtonWrapper>
        <ButtonGroup>
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => dispatch(setClaimDetailsActiveView(''))}
            isDisabled={isLoading}
          />
          <Button
            type="submit"
            text="Complete Repair"
            data-cy="complete-repair-button"
            isDisabled={isLoading}
            isProcessing={isLoading}
          />
        </ButtonGroup>
      </ButtonWrapper>
    </form>
  )
}

const LabelWrapper = styled.label<{ invalid?: boolean }>(({ invalid = false }) => ({
  fontFamily: 'Nunito Sans, sans-serif',
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  '.react-datepicker-wrapper': {
    marginTop: 5,
    input: {
      height: 40,
      border: `1px solid ${invalid ? COLOR.DANGER : COLOR.LIGHT_GRAYISH_BLUE_0} !important`,
      '&:focus': {
        border: `1px solid ${invalid ? COLOR.DANGER : COLOR.BRIGHT_BLUE} !important`,
        boxShadow: `0 0 0 1px inset ${invalid ? COLOR.DANGER : COLOR.BRIGHT_BLUE}`,
        outline: 'none',
      },
    },
  },
}))

const LabelText = styled.div({
  color: COLOR.BLACK_MUTED,
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
})

export const ErrorMessage = styled.div({
  color: COLOR.STATE_DANGER,
  fontFamily: 'Nunito Sans',
  fontSize: 12,
  lineHeight: '16px',
  marginBottom: 16,
  marginTop: 8,
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

export { CompleteRepairForm }
