import { all, fork, takeLatest } from '@redux-saga/core/effects'
import {
  usersSagas,
  incredibotSagas,
  storesSagas,
  accountSagas,
  plansSagas,
  contractsSagas,
  productsSagas,
} from '@helloextend/core-api-redux'
import type { SagaIterator } from 'redux-saga'
import { authSagas } from './auth'
import { fetchUser } from './fetch-user'
import { createSupportRequest } from './create-support-request'
import { updateProfileInfo } from './update-profile-info'

export default function* allSagas(): SagaIterator {
  yield all([
    fork(authSagas),
    fork(usersSagas),
    fork(incredibotSagas),
    fork(storesSagas),
    fork(accountSagas),
    fork(plansSagas),
    fork(contractsSagas),
    fork(productsSagas),
    takeLatest('USERS_GETME', fetchUser),
    takeLatest('CREATE_SUPPORT', createSupportRequest),
    takeLatest('USERS_UPDATE', updateProfileInfo),
  ])
}
