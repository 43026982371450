import type { FC } from 'react'
import React, { useCallback, useEffect } from 'react'
import { Button, ButtonGroup } from '@helloextend/zen'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import type { InsuranceProgram as InsuranceProgramModel } from '@helloextend/extend-api-client'
import { useToggle } from '@helloextend/client-hooks'
import { LeavePageGuard } from '../../../components/leave-page-guard'
import { formatDateWithTimezone } from '../../../utils/date-formatting'
import { InsuranceProgramUpdatesModal } from './insurance-program-updates-modal'

type InsuranceProgramEditHeaderProps = {
  insuranceData: InsuranceProgramModel
  isFormDirty: boolean
  isCreateSuccess: boolean
  isFormValid: boolean
  isProcessing: boolean
  editButtonCy?: string
  isEditDisabled: boolean
  handleIsEditDisabled: () => void
  resetForm: () => void
  handleSave: () => void
}

const InsuranceProgramEditHeader: FC<InsuranceProgramEditHeaderProps> = ({
  insuranceData,
  isFormDirty,
  isCreateSuccess,
  isFormValid,
  isProcessing,
  editButtonCy,
  resetForm,
  isEditDisabled,
  handleIsEditDisabled,
  handleSave,
}) => {
  const history = useHistory()

  const [isModalDisplayed, { on: displayModalOn, off: displayModalOff }] = useToggle(false)

  const handleLeavePage = useCallback(
    (path: string): void => {
      history.push(path)
    },
    [history],
  )

  const handleCancelClick = useCallback((): void => {
    resetForm()
    handleIsEditDisabled()
  }, [handleIsEditDisabled, resetForm])

  const handleSaveClick = useCallback((): void => {
    displayModalOn()
  }, [displayModalOn])

  const { id, version } = insuranceData

  useEffect(() => {
    if (isCreateSuccess) {
      displayModalOff()
    }
  }, [displayModalOff, isCreateSuccess])

  return (
    <HeaderWrapper>
      <LeavePageGuard
        isNavBlocked={isFormDirty && !isCreateSuccess}
        handleLeavePage={handleLeavePage}
      />
      <InsuranceProgramUpdatesModal
        isVisible={isModalDisplayed}
        versionNumber={version}
        programId={id}
        onClickClose={displayModalOff}
        handleSave={handleSave}
        isProcessing={isProcessing}
      />
      <VersionDetails>
        <p>Version {insuranceData.version}</p>
        <p>
          Last updated: {formatDateWithTimezone(insuranceData.createdAt)} by{' '}
          {insuranceData.createdBy}
        </p>
      </VersionDetails>
      <ButtonGroup>
        {isEditDisabled && (
          <Button
            text="Edit"
            onClick={handleIsEditDisabled}
            emphasis="medium"
            data-cy={editButtonCy}
          />
        )}
        {!isEditDisabled && (
          <>
            <Button
              text="Cancel"
              onClick={handleCancelClick}
              emphasis="medium"
              data-cy="insurance-program-cancel-button"
            />
            <Button
              text="Save"
              onClick={handleSaveClick}
              isDisabled={!isFormDirty || !isFormValid || isProcessing}
              isProcessing={isProcessing}
              data-cy="insurance-program-save-button"
            />
          </>
        )}
      </ButtonGroup>
    </HeaderWrapper>
  )
}

const VersionDetails = styled.div({
  fontSize: 16,
})

const HeaderWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

export { InsuranceProgramEditHeader }
