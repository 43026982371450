import * as createActions from './create'
import * as fetchActions from './fetch'
import * as resetActions from './reset'
import * as uploadActions from './upload'
import * as deleteActions from './delete'
import type { ExtractActions } from '../../types/utility'

export type Action =
  | ExtractActions<typeof createActions>
  | ExtractActions<typeof fetchActions>
  | ExtractActions<typeof resetActions>
  | ExtractActions<typeof uploadActions>
  | ExtractActions<typeof deleteActions>

export type ActionType = Action['type']

const actions = {
  ...fetchActions,
  ...createActions,
  ...resetActions,
  ...uploadActions,
  ...deleteActions,
}

export { actions }
