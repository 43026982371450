import React from 'react'
import { CellText } from '@helloextend/merchants-ui'
import type { Column } from '@helloextend/merchants-ui'
import type { TablePlansSearch } from '../../../types/plans'

const getPlansColumns = (
  exportColumn: Column<TablePlansSearch>,
): Array<Column<TablePlansSearch>> => [
  {
    Header: 'PlanId',
    accessor: 'planId',
    columnWidth: 50,
    Cell: (data: TablePlansSearch) => <CellText title={data.planId}>{data.planId}</CellText>,
    canSearch: true,
  },
  {
    Header: 'Version',
    accessor: 'version',
    columnWidth: 50,
    Cell: (data: TablePlansSearch) => <CellText title={`${data.version}`}>{data.version}</CellText>,
  },
  exportColumn,
]

export { getPlansColumns }
