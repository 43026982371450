import type { SagaIterator } from 'redux-saga'
import { call, put, all } from 'redux-saga/effects'
import type { Claim } from '@helloextend/extend-api-client'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/client-utils/src/request'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.fetchByClaims>
/*
TODO: Currently there is no way to batch retrieve a list of service orders. This increases load time
and waste resources. If customer has many claims, UI can run into availability issues. 
https://helloextend.atlassian.net/browse/CUST-1876 to resolve problem
*/
export function* fetchAllServiceOrders(claimId: string, accessToken: string): SagaIterator {
  try {
    const response: ResolvedType<typeof client.serviceOrders.fetchByClaim> = yield call(
      client.serviceOrders.fetchByClaim,
      claimId,
      accessToken,
    )

    if (isErrorResponse(response)) {
      yield put(actions.fetchByClaimsFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(actions.fetchByClaimsFailure(`An unknown error occurred`, response.status))
      return
    }
    yield put(actions.fetchSingleClaimSuccess(response.data))
  } catch (e) {
    yield put(actions.fetchByClaimsFailure(e.message))
  }
}

export default function* fetch(action: Action): SagaIterator {
  const { claims, accessToken } = action.payload
  yield put(actions.fetchRequest())
  yield all(claims.map((claim: Claim) => call(fetchAllServiceOrders, claim.id, accessToken)))
  yield put(actions.fetchSuccessByClaims())
}
