import type { CurrencyCode } from '@helloextend/extend-api-client'

export function format<T>(
  price?: T,
  currency = 'USD',
  defaultVal = '$0.00',
  locale = 'en-US',
): string {
  if (typeof price !== 'number') {
    return defaultVal
  }
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(price / 100)
}

export function formatWithoutCurrencySymbol(price?: number, currencyCode?: string): string {
  if (!price) {
    return 'N/A'
  }
  const formattedPrice = format(price)
    .replace(/[^0-9.,]/g, '')
    .concat(' ', currencyCode || '')
    .trim()

  return formattedPrice
}

type Locale = 'en-US' | 'fr-CA' | 'en-CA'
const LOCALE_CURRENCY: Record<Locale, CurrencyCode> = {
  'en-US': 'USD',
  'fr-CA': 'CAD',
  'en-CA': 'CAD',
}

export function getCurrencyCodeByLocale(locale: Locale): CurrencyCode {
  return LOCALE_CURRENCY[locale] || 'USD'
}
