import type {
  Action,
  Collect,
  DefaultMessage,
  DefaultReply,
  Option,
  Execute,
  PromptType,
  Replacement,
  ReplacementMessage,
  ScriptItem,
  ThreadResponse,
  Reply,
  Pattern,
  RulesetBase,
  RuleCreate,
  Condition,
  NumericCondition,
  ConditionTypes,
  NumericConditionWithScript,
  NonNumericConditionWithComparand,
} from '@helloextend/extend-api-rtk-query'
import { isEqual } from 'lodash/fp'
import type { AdvancedSelectOption, ColumnFiltersState } from '@helloextend/zen'
import { faker } from '@faker-js/faker/locale/en'
import type { FullConversation } from '../../../types/conversations'

export enum AMPModalType {
  editWarning = 'editWarning',
  save = 'save',
  saveAndPublish = 'saveAndPublish',
}

export function getIndex(option: Option): string | number {
  if (!option) return '-'
  if (option.execute) {
    return option.execute.scriptIndex >= 0 ? option.execute.scriptIndex + 1 : '-'
  }
  return mapActionToIndex(option.action)
}

export function mapActionToIndex(action: Action): string {
  if (action === 'stop') {
    return 'End of Conversation'
  }
  if (action === 'next') {
    return 'Next Thread'
  }
  throw new Error('Unknown action when attempting to map to index')
}

export function getIsReplacementReply(reply: DefaultReply | Replacement): reply is Replacement {
  return (reply as Replacement).replace !== undefined
}

export function getIsReplacementMessage(
  message: DefaultMessage | ReplacementMessage,
): message is ReplacementMessage {
  return (message as ReplacementMessage).replace !== undefined
}

export function getHasReplyScriptItem(scriptItem: ScriptItem): scriptItem is Required<ScriptItem> {
  return scriptItem.reply !== undefined
}

export function getMessageBlockTitle(promptType: PromptType | undefined): string {
  const promptMapping = {
    input: 'Freeform Text',
    addressInput: 'Freeform Text',
    addressVerification: 'Freeform Text',
    buttons: 'Multiple Choice',
    datepicker: 'Date',
    imageUpload: 'Image Upload',
    replacement: 'Not Editable',
    multiselect: 'Multiple Choice',
    carousel: 'Multiple Choice',
    orderCarousel: 'Multiple Choice',
  }
  if (!promptType) return 'Statement'

  return promptMapping[promptType] || 'Statement'
}

export function getImageUrl(message: DefaultMessage): string | undefined {
  return message.imageUrl
}

export function getBase64(file: File): Promise<string | null | void> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}

export const isMessageBlockStatement = (scriptItem: ScriptItem | undefined): boolean => {
  return (scriptItem?.reply as DefaultReply)?.prompt?.type === undefined
}

export function getCustomerResponseBlockTitle(promptType: PromptType): string {
  const promptMapping = {
    input: 'Input Text',
    addressInput: 'Input Text',
    addressVerification: 'Input Text',
    datepicker: 'Pick a Date',
    imageUpload: 'Select a Photo',
  }

  return promptMapping[promptType as keyof typeof promptMapping]
}

export const getPromptOptionValue = (reply: Reply | undefined, index: number): string | null => {
  if (!reply || getIsReplacementReply(reply)) return null

  let replyOptionValue = null

  const { prompt } = reply

  if (prompt && prompt.options && prompt.options[index]) {
    replyOptionValue = prompt.options[index].value
  }

  return replyOptionValue
}

export const findCollectOptionByInputPattern = (
  collect: Collect,
  pattern: string,
): Option | undefined => {
  const { options } = collect
  return options.find((option) => {
    return option.patterns?.find((patternObj) => patternObj?.input === pattern)
  })
}

export const findDefaultCollectOption = (collect: Collect): Option | undefined => {
  const { options } = collect
  return options.find((option) => option.default === true)
}

export const findCollectOptionByRoutingMatch = (
  collect: Collect,
  routingValue: string,
): Option | undefined => {
  const { options } = collect

  if (routingValue === 'End of Conversation') {
    return options.find((option) => option.action === 'stop')
  }

  if (routingValue === 'Next Thread') {
    return options.find((option) => option.action === 'next')
  }
  // make sure we have an index number
  if (Number.isNaN(Number(routingValue))) return undefined

  // account for 0 indexing of threads
  const index = Number(routingValue) - 1

  return options.find((option) => option.execute?.scriptIndex === index)
}

export const getCollectOption = (
  collect: Collect,
  patternValue?: string | null,
): Option | undefined => {
  let option: Option | undefined
  // for type "buttons" & "multichoice" check if the reply prompts option value
  // maps to a collect options pattern
  if (patternValue) {
    option = findCollectOptionByInputPattern(collect, patternValue)
  }
  // if a pattern match was found, return the option
  if (option) return option

  // otherwise get the collect options default
  return findDefaultCollectOption(collect)
}

export const getJumpToValue = (scriptItem: ScriptItem, optionIndex: number): string => {
  const { collect, reply } = scriptItem

  const optionPattern = getPromptOptionValue(reply, optionIndex)

  const jumpToOption = getCollectOption(collect, optionPattern)
  if (!jumpToOption) return ''

  // check if option points to outside of current thread: end of conversation or next thread
  if (jumpToOption.action === 'stop') {
    return 'End of Conversation'
  }

  if (jumpToOption.action === 'next') {
    return 'Next Thread'
  }

  // option connecting to ScriptItem in thread
  const jumpToScriptIndex = jumpToOption.execute?.scriptIndex
  if (jumpToScriptIndex === undefined) return ''

  return jumpToScriptIndex > -1 ? String(jumpToScriptIndex + 1) : ''
}

export const compareThreadsDeepEquality = (
  thread1?: ThreadResponse | null,
  thread2?: ThreadResponse | null,
): boolean => {
  if (!thread1 || !thread2) return false
  const hasScriptStructureChanged = thread1.script.length !== thread2.script.length

  return (
    thread1.editedBy === thread2.editedBy &&
    thread1.id === thread2.id &&
    !hasScriptStructureChanged &&
    thread1.script.every((script, index) =>
      compareScriptItemsDeepEquality(script, thread2.script[index]),
    ) &&
    thread1.status === thread2.status &&
    thread1.title === thread2.title &&
    thread1.type === thread2.type &&
    thread1.version === thread2.version
  )
}

export const compareScriptItemsDeepEquality = (
  scriptItem1: ScriptItem,
  scriptItem2: ScriptItem,
): boolean => {
  if (!scriptItem1 || !scriptItem2) return false

  const areCollectsEqual = isEqual(scriptItem1.collect, scriptItem2.collect)
  const areReplysEqual = isEqual(scriptItem1.reply, scriptItem2.reply)

  return areCollectsEqual && areReplysEqual
}

export const compareConversationsDeepEquality = (
  firstConversation: FullConversation | null,
  secondConversation: FullConversation | null,
): boolean => {
  if (!firstConversation || !secondConversation) return false
  // Compare conversation structure
  if (
    firstConversation.id !== secondConversation.id ||
    firstConversation.title !== secondConversation.title ||
    firstConversation.status !== secondConversation.status ||
    firstConversation.createdAt !== secondConversation.createdAt ||
    firstConversation.updatedAt !== secondConversation.updatedAt ||
    firstConversation.version !== secondConversation.version ||
    firstConversation.editedBy !== secondConversation.editedBy ||
    firstConversation.threads.length !== secondConversation.threads.length
  ) {
    return false
  }

  // Compare Threads
  const hasEqualThreads = firstConversation.threads.every((thread, index) => {
    return compareThreadsDeepEquality(thread, secondConversation.threads[index])
  })

  return hasEqualThreads
}

export enum MessageBlockTypes {
  multipleChoice = 'Multiple Choice',
  date = 'Date',
  freeformText = 'Freeform Text',
  imageUpload = 'Image Upload',
  statement = 'Statement',
}

export enum MessageBlockTypesUIModel {
  multipleChoice = 'multiple-choice',
  date = 'date',
  freeformText = 'freeform-text',
  imageUpload = 'image-upload',
  statement = 'statement',
}
export const getJumpToSelectorText = (scriptItem: ScriptItem): string => {
  const { reply } = scriptItem

  if (reply && !getIsReplacementReply(reply)) {
    const defaultMessage = reply.messages[0] as DefaultMessage
    const replacementMessage = reply.messages[0] as ReplacementMessage

    if (defaultMessage && defaultMessage.content !== undefined) {
      return defaultMessage.content
    }
    if (replacementMessage && replacementMessage.replace !== undefined) {
      return replacementMessage.replace.invoke
    }
  }

  // any scriptItem w/o a reply is a non-customer-facing block
  // render placeholder text until there is a business need for more details
  return 'Non-Customer-Facing Kaley Decision'
}

export const updatePatternsArray = (
  patterns: Pattern[] | undefined,
  pattern: string,
  updateAction: 'append' | 'remove' | 'update',
  updatePattern?: string,
): void => {
  if (!patterns) return

  if (updateAction === 'append') {
    patterns.push({ input: pattern })
  }

  if (updateAction === 'remove') {
    const patternIndex = patterns.findIndex((p) => p.input === pattern)
    if (patternIndex === -1) return
    patterns.splice(patternIndex, 1)
  }

  if (updateAction === 'update' && updatePattern) {
    const patternIndex = patterns.findIndex((p) => p.input === pattern)
    if (patternIndex === -1) return

    patterns.splice(patternIndex, 1, { input: updatePattern })
  }
}

const getAction = (routingValue: string): 'stop' | 'next' | 'execute' => {
  if (routingValue === 'End of Conversation') {
    return 'stop'
  }

  if (routingValue === 'Next Thread') {
    return 'next'
  }
  return 'execute'
}

export const generateNewCollectOption = (routingValue: string, pattern: string): Option => {
  const action = getAction(routingValue)

  let execute: Execute | null = null

  if (action === 'execute' && !Number.isNaN(Number(routingValue))) {
    execute = { scriptIndex: Number(routingValue) - 1 }
  }

  return {
    action,
    ...(execute ? { execute } : {}),
    patterns: [{ input: pattern }],
  }
}

export const findDefaultCollectOptionIndex = (collect: Collect): number => {
  const { options } = collect
  return options.findIndex((option) => option.default === true)
}

export const updateCollectOptionRouting = (option: Option, routingValue: string): Option => {
  const updatedAction = getAction(routingValue)

  let execute: Execute | null = null

  if (updatedAction === 'execute' && !Number.isNaN(Number(routingValue))) {
    execute = { scriptIndex: Number(routingValue) - 1 }
  }

  return {
    action: updatedAction,
    ...(execute ? { execute } : {}),
    ...(option.patterns ? { patterns: option.patterns } : {}),
    ...(option.default ? { default: option.default } : {}),
  }
}

export const cleanEmptyCollectOptionsByPattern = (collect: Collect): void => {
  const { options } = collect

  options.forEach((option) => {
    if (!option.patterns || option.patterns.length === 0) {
      const optionIndex = options.findIndex((o) => o === option)
      options.splice(optionIndex, 1)
    }
  })
}

export const updateCollectOptions = ({
  collect,
  collectOptionMap,
  collectOptionTargetRouting,
  pattern,
  routingValue,
}: {
  collect: Collect
  collectOptionMap?: Option
  collectOptionTargetRouting?: Option
  pattern: string
  routingValue: string
}): void => {
  const { options } = collect
  const collectOptionMapIndex = options.findIndex((option) => isEqual(option, collectOptionMap))

  // when pattern is shared between multiple collect options, we don't need to add mapping to target routing,
  // we need to remove orphaned collect option

  const isPatternShared = collectOptionTargetRouting?.patterns?.find((p) => p.input === pattern)

  if (!isPatternShared && !collectOptionMap && !collectOptionTargetRouting) {
    // currently pointing to default add a new CollectOption
    options.splice(collect.options.length - 1, 0, generateNewCollectOption(routingValue, pattern))
  }

  // has a mapped Collect Option, Options array does not have a Collect Option for new routing
  if (collectOptionMap && !collectOptionTargetRouting) {
    // check if we can modify routing of collectOptionMap
    const canModifyRouting = collectOptionMap.patterns?.length === 1

    if (canModifyRouting) {
      // When yes, generate updated CollectObject and replace
      options.splice(
        collectOptionMapIndex,
        1,
        updateCollectOptionRouting(collectOptionMap, routingValue),
      )
      return
    }

    // when we cannot modify collectOptionMap's routing
    // First, remove mapping in collectOptionMap
    updatePatternsArray(collectOptionMap.patterns, pattern, 'remove')
    // Second, add a new CollectObject mapped to the PromptOption
    options.splice(options.length - 1, 0, generateNewCollectOption(routingValue, pattern))
  }

  // has a mapped Collect Option, Options array has a Collect Option for the target routing value
  if (collectOptionMap && collectOptionTargetRouting) {
    const canRemoveCollectOptionMap = collectOptionMap.patterns?.length === 1

    if (canRemoveCollectOptionMap) {
      // remove the collectOptionMap
      options.splice(collectOptionMapIndex, 1)
    } else {
      // remove the mapping pattern from collect option
      updatePatternsArray(collectOptionMap.patterns, pattern, 'remove')
    }

    // when target routing is default, we won't need to modify it to add mapping
    const isCollectOptionTargetRoutingDefault = collectOptionTargetRouting.default === true

    if (!isCollectOptionTargetRoutingDefault) {
      // when target routing is not default, adding mapping to target routing
      updatePatternsArray(collectOptionTargetRouting.patterns, pattern, 'append')
    }
  }
}

export const isMessageBlockTerminating = (messageBlock: ScriptItem | undefined): boolean => {
  if (isMessageBlockStatement(messageBlock)) return false

  const terminatingOption = messageBlock?.collect.options.find(
    (option) => option.action === 'next' || option.action === 'stop',
  )
  return terminatingOption !== undefined
}

export const getFirstSelectableMessageBlockIndex = (thread: ThreadResponse): number => {
  return thread.script.findIndex((script) => Boolean(script.reply))
}

export enum ThreadConnectorDropdownValues {
  AdjudicationServiceType = 'AdjudicationServiceType',
  AdjudicationType = 'AdjudicationType',
  EndClaim = 'EndClaim',
  FailureDescription = 'FailureDescription',
  FailureLocation = 'FailureLocation',
  FailureType = 'FailureType',
  FailureCause = 'FailureCause',
  IncidentDate = 'IncidentDate',
  JewelryType = 'JewelryType',
  MissingStone = 'MissingStone',
  ProductSection = 'ProductSection',
  SelfTroubleshoot = 'SelfTroubleshoot',
  StoneInSetting = 'StoneInSetting',
  TiresAndWheelsValidation = 'TiresAndWheelsValidation',
}

export const generateThreadConnectorOptions = (): AdvancedSelectOption[] => {
  return Object.values(ThreadConnectorDropdownValues).map((value) => {
    if (value === 'AdjudicationType') {
      return {
        value,
        display: value.concat(' (random failure, accident, etc)'),
      }
    }

    if (value === 'AdjudicationServiceType') {
      return {
        value,
        display: value.concat(' (repair, service, etc)'),
      }
    }

    return {
      value,
      display: value,
    }
  })
}

export const getThreadConnectorOptionsByMessageBlockType = (
  messageBlockType: MessageBlockTypes,
): AdvancedSelectOption[] => {
  const options = generateThreadConnectorOptions()

  switch (messageBlockType) {
    case MessageBlockTypes.multipleChoice:
      return options.filter(
        (option) => option.value !== 'IncidentDate' && option.value !== 'FailureDescription',
      )
    case MessageBlockTypes.date:
      return options.filter((option) => option.value === 'IncidentDate')
    case MessageBlockTypes.freeformText:
      return options.filter((option) => option.value === 'FailureDescription')
    default:
      return options
  }
}

export const getMessageFromScriptItem = (scriptItem: ScriptItem, messageIndex: number): string =>
  ((scriptItem.reply as DefaultReply).messages[messageIndex] as DefaultMessage).content

export const getMessageBlockTypeFromScriptItem = (scriptItem: ScriptItem): MessageBlockTypes => {
  const promptType = (scriptItem.reply as DefaultReply)?.prompt?.type

  if (promptType === 'buttons' || promptType === 'multiselect') {
    return MessageBlockTypes.multipleChoice
  }

  if (promptType === 'datepicker') {
    return MessageBlockTypes.date
  }

  if (promptType === 'input') {
    return MessageBlockTypes.freeformText
  }

  return MessageBlockTypes.statement
}

export const isMessageBlockCustomerFacing = (script: ScriptItem | undefined): boolean => {
  return Boolean(script?.reply)
}

export const getFullConversation = (threads: ThreadResponse[]): FullConversation => {
  return {
    createdAt: '',
    description: '',
    editedBy: '',
    id: faker.datatype.uuid(),
    status: 'draft',
    title: '',
    updatedAt: '',
    version: 0,
    threads,
  }
}

export const isUUID = (str: string): boolean => {
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  return regex.test(str)
}

export const getCollectOptionsIndexByPattern = (
  collect: Collect,
  pattern: string | number,
): number => {
  return collect.options.findIndex((option) => option.patterns?.some((p) => p.input === pattern))
}

export const compareRulesetsDeepEquality = (
  ruleset1: RulesetBase,
  ruleset2: RulesetBase,
): boolean => {
  if (!ruleset1 || !ruleset2) return false

  const {
    approveRules: ruleset1ApproveRules,
    denyRules: ruleset1DenyRules,
    reviewRules: ruleset1ReviewRules,
  } = ruleset1
  const {
    approveRules: ruleset2ApproveRules,
    denyRules: ruleset2DenyRules,
    reviewRules: ruleset2ReviewRules,
  } = ruleset2

  const hasEqualNumberOfApproveRules = ruleset1ApproveRules.length === ruleset2ApproveRules.length
  const hasEqualApproveRules = ruleset1ApproveRules.every((rule, index) =>
    compareRulesDeepEquality(rule, ruleset2ApproveRules[index]),
  )
  const hasEqualNumberOfDenyRules = ruleset1DenyRules.length === ruleset2DenyRules.length
  const hasEqualDenyRules = ruleset1DenyRules.every((rule, index) =>
    compareRulesDeepEquality(rule, ruleset2DenyRules[index]),
  )
  const hasEqualNumberOfReviewRules = ruleset1ReviewRules.length === ruleset2ReviewRules.length
  const hasEqualReviewRules = ruleset1ReviewRules.every((rule, index) =>
    compareRulesDeepEquality(rule, ruleset2ReviewRules[index]),
  )

  return (
    hasEqualNumberOfApproveRules &&
    hasEqualApproveRules &&
    hasEqualNumberOfDenyRules &&
    hasEqualDenyRules &&
    hasEqualNumberOfReviewRules &&
    hasEqualReviewRules
  )
}

export const compareRulesDeepEquality = (
  rule1: RuleCreate | undefined,
  rule2: RuleCreate | undefined,
): boolean => {
  if (!rule1 || !rule2) return false
  if (rule1.conditions.length !== rule2.conditions.length) return false

  return rule1.conditions.every((conditions, index) =>
    compareConditionsDeepEquality(conditions, rule2.conditions[index]),
  )
}

export const compareConditionsDeepEquality = (
  condition1: Condition,
  condition2: Condition,
): boolean => {
  return (
    condition1.script === condition2.script &&
    condition1.value === condition2.value &&
    condition1.comparand === condition2.comparand &&
    (condition1 as NumericCondition).operator === (condition2 as NumericCondition).operator &&
    (condition1 as NumericCondition).offset === (condition2 as NumericCondition).offset
  )
}

export const identifyCondition = (condition: Condition): ConditionTypes | null => {
  const properties = Object.keys(condition)

  const hasScript = properties.includes('script')
  const hasComparand = properties.includes('comparand')
  const hasValue = properties.includes('value')
  const hasOperator = properties.includes('operator')
  const hasOffset = properties.includes('offset')

  if (hasComparand && hasValue && !hasScript && !hasOperator && !hasOffset) {
    return 'nonNumericComparand'
  }

  if (hasScript && hasValue && !hasComparand && !hasOperator && !hasOffset) {
    return 'nonNumericScript'
  }

  if (hasScript && hasComparand && hasOperator && !hasValue) {
    return 'numericScript'
  }

  if (hasValue && hasComparand && hasOperator && !hasScript) {
    return 'numericValue'
  }

  return null
}

export const getValueFromCondition = ({
  condition,
  thread,
}: {
  condition: Condition | null
  thread?: ThreadResponse | null
}): string => {
  if (!condition) return ''
  const conditionType = identifyCondition(condition)

  if (['nonNumericScript', 'numericScript'].includes(conditionType ?? '') && thread) {
    const index = (condition as NumericConditionWithScript).script as number
    // double check scriptItem is valid for Script Condition
    const scriptItemType = (thread.script[index]?.reply as DefaultReply)?.prompt?.type
    if (scriptItemType && ['buttons', 'multiselect', 'datepicker'].includes(scriptItemType)) {
      return String(index) as string
    }
  } else if (['nonNumericComparand'].includes(conditionType ?? '')) {
    return (condition as NonNumericConditionWithComparand).comparand as string
  }

  if (['numericValue', 'nonNumericComparand'].includes(conditionType ?? '')) {
    return String(condition.comparand)
  }

  return ''
}

export const getSearchParams = (columnFilters: ColumnFiltersState): string => {
  const condensedSearchParams = columnFilters.reduce((acc, { id, value }) => {
    let strValue = value
    if (Array.isArray(strValue)) {
      strValue = strValue.join('::')
    }
    return { ...acc, [id]: strValue } as Record<string, string>
  }, {} as Record<string, string>)

  return new URLSearchParams(condensedSearchParams).toString()
}

export const getColumnFiltersFromSearchParams = (
  searchParams: string | undefined,
): ColumnFiltersState => {
  if (!searchParams) return []

  const params = decodeURIComponent(searchParams).slice(1).split('&')

  return params.map((param) => {
    const [key, value] = param.split('=')

    if (key === 'title') {
      return { id: key, value: value.replace(/\+/g, ' ') }
    }

    return {
      id: key,
      value: value.split('::'),
    }
  }) as ColumnFiltersState
}
