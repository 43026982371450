import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  ShippingProtectionPlan,
  ShippingProtectionPlansListResponse,
} from '@helloextend/extend-api-client'
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { baseQuery } from '../base-query'

export const shippingProtectionPlansApi = createApi({
  baseQuery,
  reducerPath: 'ShippingProtectionPlans',
  tagTypes: ['ShippingProtectionPlans'],
  endpoints: (build) => ({
    listAllShippingProtectionPlanIds: build.query<string[], void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        let nextPageCursor
        const data: string[] = []

        do {
          // eslint-disable-next-line no-await-in-loop
          const res: QueryReturnValue = await fetchWithBQ(
            nextPageCursor
              ? `/plans/shippingprotection/list?cursor=${nextPageCursor}`
              : '/plans/shippingprotection/list',
          )
          if (res.error) throw res.error

          const payload = res.data as ShippingProtectionPlansListResponse
          payload.items.forEach((spPlan) => {
            data.push(spPlan.id)
          })
          nextPageCursor = payload.nextPageCursor
        } while (nextPageCursor)

        const sortedPlans = data.sort((plan1, plan2) =>
          plan1.toLowerCase() > plan2.toLowerCase() ? 1 : -1,
        )

        return { data: sortedPlans }
      },
      providesTags: (_, _err) => [{ type: 'ShippingProtectionPlans' }],
    }),
    getShippingProtectionPlanDetails: build.query<ShippingProtectionPlan, string>({
      query: (planId: string) => ({
        url: `/plans/shippingprotection/${planId}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_, _err, planId) => [{ type: 'ShippingProtectionPlans', id: planId }],
    }),
  }),
})

export const {
  useListAllShippingProtectionPlanIdsQuery,
  useGetShippingProtectionPlanDetailsQuery,
} = shippingProtectionPlansApi
