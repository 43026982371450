import styled from '@emotion/styled'
import {
  AdvancedSelect,
  Checkbox,
  Grid,
  InlineAlert,
  InlineAlertColor,
  Input,
  InputType,
  Modal,
} from '@helloextend/zen'
import { Paragraph, Subheading } from '@helloextend/zen/src/tokens/typography'
import type { FormikHandlers, FormikValues } from 'formik'
import type { FC } from 'react'
import React from 'react'
import { Divider } from '../../../../../../../../components/divider'
import { listToAdvancedSelectOptions } from '../../../../../../../../utils/form-utils'
import { PRODUCT_WARRANTY_STATUS } from '../../../../../../../../utils/products'
import { TooltipOptions } from '../../product-details/components/product-status-info/tooltip-options'

type EditProductsModalProps = {
  values: FormikValues
  isPlansListLoading: boolean
  isUpdatingProducts: boolean
  planIds: string[]
  isDirty: boolean
  handleModalOff: () => void
  handleDeleteProduct: () => void
  handleUpdateProduct: () => void
  handleChange: FormikHandlers['handleChange']
  selectedProductsCount?: number
}

const EditProductsModal: FC<EditProductsModalProps> = ({
  values,
  isPlansListLoading,
  isUpdatingProducts,
  planIds,
  isDirty,
  handleUpdateProduct,
  handleModalOff,
  handleChange,
  handleDeleteProduct,
  selectedProductsCount,
}) => {
  return (
    <Modal
      data-cy="edit-products-modal"
      heading="Edit Products"
      primaryButtonProps={{
        text: 'Update',
        'data-cy': 'modal-button-update',
        onClick: handleUpdateProduct,
        isProcessing: isUpdatingProducts,
        isDisabled: !isDirty,
      }}
      secondaryButtonProps={{
        text: 'Cancel',
        'data-cy': 'modal-button-cancel-edit',
        onClick: handleModalOff,
        isDisabled: isUpdatingProducts,
      }}
      altButtonProps={{
        text: 'Delete Product',
        'data-cy': 'button-delete-product',
        color: 'red',
        onClick: handleDeleteProduct,
        isDisabled: isUpdatingProducts,
      }}
    >
      <ContainerWithBottomMargin>
        <InlineAlert color={InlineAlertColor.blue}>
          <Paragraph>Edit {selectedProductsCount} products</Paragraph>
        </InlineAlert>
      </ContainerWithBottomMargin>

      <ContainerWithBottomMargin>
        <AdvancedSelect
          value={values?.warrantyStatus}
          id="warrantyStatus"
          label="Matched Status"
          placeholder="Select"
          data-cy="select-warranty-status"
          multiple={false}
          isNotClearable
          onChange={handleChange}
          helperText={<TooltipOptions />}
          options={[
            { display: PRODUCT_WARRANTY_STATUS.warrantable, value: 'warrantable' },
            { display: PRODUCT_WARRANTY_STATUS.pending, value: 'pending' },
            { display: PRODUCT_WARRANTY_STATUS['non-warrantable'], value: 'non-warrantable' },
          ]}
        />
      </ContainerWithBottomMargin>

      <ContainerWithBottomMargin>
        <Checkbox
          name="displayOffer"
          label="Display Offer"
          data-cy="checkbox-display-offer"
          checked={Boolean(values?.displayOffer)}
          indeterminate={values.displayOffer === null}
          onChange={handleChange}
        />
      </ContainerWithBottomMargin>

      <DividerWithMargin />

      <SubheadingWithMargin>Plan selection</SubheadingWithMargin>
      <ParagraphWithMargin>
        The available plans for this store are assigned by the Programs team.
      </ParagraphWithMargin>

      <ContainerWithBottomMargin>
        <AdvancedSelect
          options={listToAdvancedSelectOptions(planIds)}
          label="Select Plan"
          data-cy="select-plan"
          multiple
          showSearch
          onChange={handleChange}
          value={values?.planIds}
          id="planIds"
          placeholder="Select"
          isLoading={isPlansListLoading}
        />
        {values.planIds?.length > 3 && (
          <CautionAlertContainer>
            <InlineAlert color={InlineAlertColor.yellow} data-cy="inline-alert-plans-selection">
              <Paragraph>Take caution. More than 3 plans have been selected.</Paragraph>
            </InlineAlert>
          </CautionAlertContainer>
        )}
      </ContainerWithBottomMargin>

      <DividerWithMargin />

      <ContainerWithBottomMargin>
        <Grid columns={2} spacing={2}>
          <Input
            id="mfrWarrantyLabor"
            data-cy="input-mfr-warranty-labor"
            value={values.mfrWarrantyLabor}
            label="Mfr Warranty (Labor)"
            onChange={handleChange}
            suffix="Months"
            type={InputType.number}
          />
          <Input
            id="mfrWarrantyParts"
            data-cy="input-mfr-warranty-parts"
            value={values.mfrWarrantyParts}
            label="Mfr Warranty (Parts)"
            onChange={handleChange}
            suffix="Months"
            type={InputType.number}
          />
        </Grid>
      </ContainerWithBottomMargin>

      <DividerWithMargin />

      <ContainerWithBottomMargin>
        <Checkbox
          label="Product Shippable"
          data-cy="checkbox-product-shippable"
          name="productShippable"
          checked={Boolean(values?.productShippable)}
          indeterminate={values.productShippable === null}
          onChange={handleChange}
        />
      </ContainerWithBottomMargin>

      <Checkbox
        label="Shipping Protection Enabled"
        data-cy="checkbox-shipping-protection-enabled"
        name="shippingProtectionEnabled"
        checked={Boolean(values?.shippingProtectionEnabled)}
        indeterminate={values.shippingProtectionEnabled === null}
        onChange={handleChange}
      />
    </Modal>
  )
}

const ContainerWithBottomMargin = styled.div({
  marginBottom: 24,
})

const DividerWithMargin = styled(Divider)({
  marginBottom: 24,
})

const SubheadingWithMargin = styled(Subheading)({
  marginBottom: 16,
})

const ParagraphWithMargin = styled(Paragraph)({
  marginBottom: 16,
})

const CautionAlertContainer = styled.div({
  marginTop: 24,
})

export { EditProductsModal, EditProductsModalProps }
