import type { ProductCsvValidatorPipe, ProductHeader } from './product-csv-headers'

/**
 * Ensures that the numeric values are:
 * - not parsed to a NaN
 * - an integer
 * - greater than/equal to zero
 */
const validateNumericColumns = (meta: ProductCsvValidatorPipe): ProductCsvValidatorPipe => {
  const expectedNumericColumns: ProductHeader[] = [
    'price',
    'reimbursementAmount',
    'mfrWarrantyParts',
    'mfrWarrantyLabor',
  ]

  expectedNumericColumns.forEach((column) => {
    const value = meta.rowData[meta.headerIndexes[column]]
    if (value === '') return

    const colValueToNum = Number(value)
    const isNonNumeric = Number.isNaN(colValueToNum)
    const isLessThanZero = colValueToNum < 0
    const isNonInteger = !Number.isInteger(colValueToNum)
    const isLargerThanLimit = colValueToNum > Number.MAX_SAFE_INTEGER

    if (isNonNumeric || isLessThanZero || isNonInteger) {
      meta.errors.push(`"${column}" should be a non-negative integer (${value})`)
    }

    if (isLargerThanLimit) {
      meta.errors.push(`"${column}" should be less than ${Number.MAX_SAFE_INTEGER} (${value})`)
    }
  })

  return meta
}

export { validateNumericColumns }
