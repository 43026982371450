// for IE11
declare global {
  interface Window {
    msCrypto: Crypto
  }
}
const browserCrypto = window.crypto || window.msCrypto

export function randomBytes(size: number): ArrayBuffer {
  const array = new Uint8Array(size)
  browserCrypto.getRandomValues(array)
  return array.buffer
}
