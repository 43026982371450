import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@helloextend/client-branding'

type SettingsIconProps = {
  fill?: string
}

/**
 * @deprecated Use Zen Icon component instead: `import { Icon } from '@helloextend/zen'`
 */
const SettingsIcon: FC<SettingsIconProps> = ({ fill = COLOR.BLACK_MUTED }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
    <path
      d="M6 8.625c-.452 0-.895-.12-1.287-.35a2.599 2.599 0 01-.943-.96 2.667 2.667 0 01-.003-2.625c.226-.4.55-.731.941-.962a2.531 2.531 0 012.573-.001c.39.23.716.562.941.96a2.667 2.667 0 01.002 2.624c-.226.399-.55.73-.94.96-.39.231-.833.353-1.284.354zm0-4.5c-.404 0-.797.137-1.118.388-.32.252-.55.604-.655 1.003-.105.398-.077.821.078 1.202.154.382.428.7.778.906A1.806 1.806 0 007.3 7.326a1.908 1.908 0 00.292-2.263 1.849 1.849 0 00-.672-.688 1.8 1.8 0 00-.92-.25z"
      fill={fill}
    />
    <path
      d="M4.286 12a.362.362 0 01-.184-.05l-2.18-1.285a.382.382 0 01-.133-.511l.66-1.176c-.16-.2-.305-.412-.431-.635a4.596 4.596 0 01-.304-.664H.367a.364.364 0 01-.26-.11A.379.379 0 010 7.304V4.728a.379.379 0 01.226-.347.361.361 0 01.141-.028H1.7c.174-.473.422-.913.734-1.305l-.67-1.186a.382.382 0 01.133-.513L4.077.062a.36.36 0 01.503.136l.664 1.178c.487-.08.983-.08 1.47 0l.672-1.19A.36.36 0 017.888.05l2.185 1.288a.371.371 0 01.171.228.383.383 0 01-.037.284l-.656 1.17c.312.39.56.829.735 1.3h1.347c.097 0 .19.039.26.109.068.07.107.166.107.265V7.27c0 .1-.039.195-.108.265a.364.364 0 01-.26.11h-1.334a4.752 4.752 0 01-.735 1.305l.671 1.187a.38.38 0 01-.134.513l-2.185 1.287a.362.362 0 01-.502-.137l-.66-1.174a4.55 4.55 0 01-1.47 0l-.67 1.187a.371.371 0 01-.327.188zm-1.68-1.796l1.55.912.62-1.092a.36.36 0 01.397-.179c.555.123 1.13.121 1.684-.006a.36.36 0 01.4.177l.612 1.083 1.549-.913-.613-1.092a.381.381 0 01.047-.44c.383-.432.668-.944.836-1.5a.375.375 0 01.135-.19.362.362 0 01.218-.07h1.224V5.069h-1.24a.362.362 0 01-.218-.072.375.375 0 01-.134-.19 3.977 3.977 0 00-.85-1.487.379.379 0 01-.048-.442l.612-1.084-1.55-.918-.613 1.093a.36.36 0 01-.398.179 3.782 3.782 0 00-1.683.011.36.36 0 01-.4-.179L4.13.898l-1.549.912.619 1.093a.381.381 0 01-.048.441 3.966 3.966 0 00-.835 1.5.375.375 0 01-.137.19.362.362 0 01-.22.069H.734v1.826H1.97a.362.362 0 01.22.07.375.375 0 01.136.191c.171.554.461 1.062.85 1.488a.379.379 0 01.043.447l-.613 1.079z"
      fill={fill}
    />
  </svg>
)

export { SettingsIcon }
