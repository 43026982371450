import type { Store } from '@helloextend/extend-api-client'
import { getOr, reduce, set, unset } from 'lodash/fp'
import type { Action } from '../actions'

export type ByIdReducerState = Record<string, Store>

export const initialState: ByIdReducerState = {}

export default function byId(state = initialState, action: Action): ByIdReducerState {
  switch (action.type) {
    case 'STORES_FETCH_ALL_SUCCESS':
      return reduce(addStore, state)(action.payload)
    case 'STORES_UPDATE_SUCCESS':
      return set(action.payload.id, action.payload)(state)
    case 'STORES_DESTROY_SUCCESS':
      return unset(action.payload)(state)
    case 'STORES_RESET':
      return initialState
    default:
      return state
  }
}

function addStore(state: ByIdReducerState, store: Store): ByIdReducerState {
  const existing = getOr({}, [store.id], state)
  return {
    ...state,
    [store.id]: {
      groupType: null,
      ...existing,
      ...store,
    } as Store,
  }
}
