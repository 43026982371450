import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/client-utils/src/request'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.fetchByClaim>

export function* fetchServiceOrders(payload: {
  claimId: string
  accessToken: string
}): SagaIterator {
  const { claimId, accessToken } = payload
  yield put(actions.fetchRequest())

  try {
    const response: ResolvedType<typeof client.serviceOrders.fetchByClaim> = yield call(
      client.serviceOrders.fetchByClaim,
      claimId,
      accessToken,
    )

    if (isErrorResponse(response)) {
      yield put(actions.fetchFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(actions.fetchFailure(`An unknown error occurred`, response.status))
      return
    }
    yield put(actions.fetchSuccess(response.data))
  } catch (e) {
    yield put(actions.fetchFailure(e.message))
  }
}

export default function* fetch(action: Action): SagaIterator {
  yield call(fetchServiceOrders, action.payload)
}
