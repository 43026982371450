import type { Action } from '../actions'
import type { ModalPlan, PlanImportFile, PlansBatchResult } from '../actions/import'

export type PlansImportState = {
  isLoading: boolean
  modalPlans: ModalPlan[]
  totalModalPlans: number
  uploadResult: PlansBatchResult
  uploadReadyPlans: PlanImportFile[]
  skippedPlans: PlanImportFile[]
  failedPlans: string[]
  errorMessage: string
}

export const initialState: PlansImportState = {
  isLoading: false,
  modalPlans: [],
  totalModalPlans: 0,
  uploadResult: { success: [], failure: [], skipped: [] },
  uploadReadyPlans: [],
  skippedPlans: [],
  failedPlans: [],
  errorMessage: '',
}

export default function plansImport(state = initialState, action: Action): PlansImportState {
  switch (action.type) {
    case 'IMPORT_BATCH_COMPLETE':
      return {
        ...state,
        uploadResult: action.payload,
        isLoading: false,
        uploadReadyPlans: [],
        skippedPlans: [],
        modalPlans: [],
      }
    case 'IMPORT_VALIDATION_START':
      return {
        ...state,
        isLoading: true,
      }

    case 'IMPORT_BATCH_ERROR':
      return {
        ...state,
        errorMessage: action.payload,
      }
    case 'IMPORT_BATCH_START':
      return {
        ...state,
        uploadReadyPlans: action.payload.newPlans,
        modalPlans: action.payload.existingPlans,
        failedPlans: action.payload.failedPlans,
        skippedPlans: [],
        totalModalPlans: action.payload.existingPlans.length,
      }
    case 'IMPORT_MODAL_CANCEL':
      return {
        ...state,
        uploadResult: { success: [], failure: [], skipped: [] },
        isLoading: false,
        uploadReadyPlans: [],
        skippedPlans: [],
        modalPlans: [],
      }
    case 'IMPORT_MODAL_CONFIRM':
      return {
        ...state,
        uploadReadyPlans: state.uploadReadyPlans.concat([
          {
            plan: state.modalPlans[0].plan,
            file: state.modalPlans[0].file,
          },
        ]),
        modalPlans: state.modalPlans.slice(1),
      }
    case 'IMPORT_MODAL_CONFIRM_ALL':
      return {
        ...state,
        uploadReadyPlans: state.uploadReadyPlans.concat(
          state.modalPlans.map((mp) => {
            return { plan: mp.plan, file: mp.file }
          }),
        ),
        modalPlans: [],
      }
    case 'IMPORT_MODAL_SKIP':
      return {
        ...state,
        skippedPlans: state.skippedPlans.concat([
          { plan: state.modalPlans[0].plan, file: state.modalPlans[0].file },
        ]),
        modalPlans: state.modalPlans.slice(1),
      }
    case 'IMPORT_CLEAR':
      return {
        ...state,
        errorMessage: '',
        uploadResult: { success: [], failure: [], skipped: [] },
      }
    default:
      return state
  }
}
