import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isRtkFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isRtkErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  )
}

export function safeBtoa(s: string): string {
  try {
    return btoa(s)
  } catch (e) {
    console.error(e)
    return ''
  }
}

export function safeAtob(s: string): string {
  try {
    return decodeURIComponent(atob(s))
  } catch (e) {
    console.error(e)
    return ''
  }
}
