import React from 'react'
import { CellText } from '@helloextend/merchants-ui'
import type { Column } from '@helloextend/merchants-ui'
import type { TablePlanAttribute } from '../../../types/plans'

const planAttributeColumns: Array<Column<TablePlanAttribute>> = [
  {
    Header: 'Values',
    accessor: 'id',
    Cell: (data) => <CellText title={data.id}>{data.id}</CellText>,
    canSearch: false,
    isSelectable: true,
  },
]

export { planAttributeColumns }
