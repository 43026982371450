import { faker } from '@faker-js/faker/locale/en'
import type { PlanTermsType, PlanTermsLanguage } from '@helloextend/extend-api-client'
import { PlanTermsLanguageStatus } from '@helloextend/extend-api-client/src/models/plan-terms'

export function generatePlanTerms(overrides: Partial<PlanTermsType> = {}): PlanTermsType {
  const termsId = overrides.termsId || '3432-342-43-3fedf-3fdfd'
  const firstCreation = overrides.createdAt || 123456789000

  return {
    termsId,
    createdAt: firstCreation,
    description: 'Auto Parts Replace',
    versions: [
      {
        createdAt: firstCreation,
        termsId,
        version: '1',
        updatedAt: firstCreation,
      },
      {
        createdAt: 1232456789000,
        termsId,
        version: '2',
        updatedAt: 1232456789000,
      },
    ],
    ...overrides,
  }
}

export function generatePlanTermsLanguage(
  overrides?: Partial<PlanTermsLanguage>,
): PlanTermsLanguage {
  return {
    createdAt: Date.now(),
    language: 'en',
    objectKey: 'objectKey',
    status: PlanTermsLanguageStatus.SAVED,
    termsId: faker.datatype.uuid(),
    version: '1',
    ...overrides,
  }
}
