import * as Yup from 'yup'

const storeInformationSchema = Yup.object()
  .shape({
    storeId: Yup.string().required('Store ID is required'),
  })
  .defined()

const contractInformationSchema = Yup.object()
  .shape({
    transactionId: Yup.string().required('Transaction ID is required'),
    currencyCode: Yup.string().required('Currency Code is required'),
    transactionDate: Yup.string().required('Transaction Date is required'),
    planId: Yup.string().required('Plan ID is required'),
    planPurchasePrice: Yup.number().required('Plan purchase price is required'),
    quantity: Yup.number().required(),
  })
  .defined()

const productInformationSchema = Yup.object()
  .shape({
    referenceId: Yup.string().required('Product ID is required'),
    productName: Yup.string().required('Product name is required'),
    productPurchasePrice: Yup.number().required('Purchase price is required'),
    productPurchaseDate: Yup.string().required('Product purchase date is required'),
  })
  .required()

const customerInformationSchema = Yup.object()
  .shape({
    fullName: Yup.string().required('Full name is required'),
    phoneNumber: Yup.string().notRequired(),
    customerEmail: Yup.string().required('Email is required'),
    billingAddress: Yup.string().notRequired(),
    billingAddressTwo: Yup.string().notRequired(),
    billingCity: Yup.string().notRequired(),
    billingProvinceCode: Yup.string().notRequired(),
    billingPostalCode: Yup.string().notRequired(),
    billingCountryCode: Yup.string().notRequired(),
    shippingAddress: Yup.string().notRequired(),
    shippingAddressTwo: Yup.string().notRequired(),
    shippingCity: Yup.string().notRequired(),
    shippingProvinceCode: Yup.string().notRequired(),
    shippingPostalCode: Yup.string().notRequired(),
    shippingCountryCode: Yup.string().notRequired(),
  })
  .defined()

type V1Values = Yup.InferType<typeof customerInformationSchema> &
  Yup.InferType<typeof storeInformationSchema> &
  Yup.InferType<typeof contractInformationSchema> &
  Yup.InferType<typeof productInformationSchema>

const V1Schema = customerInformationSchema
  .concat(storeInformationSchema)
  .concat(contractInformationSchema)
  .concat(productInformationSchema)

export { V1Schema, V1Values }
