import styled from '@emotion/styled'
import { Button, Check, Grid, InlineAlert, InlineAlertColor, Input } from '@helloextend/zen'
import type { ChangeEvent, FC } from 'react'
import React, { createRef, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import type { PlanTermsType } from '@helloextend/extend-api-client'
import { SaveChangesButtonGroup } from '../../../merchants/stores/store-details/settings/components/save-changes-button-group/save-changes-button-group'

const getSchema = (planTermsList?: PlanTermsType[], isEditForm = false): Yup.ObjectSchema => {
  if (isEditForm) {
    return Yup.object().shape({}).defined()
  }
  return Yup.object()
    .shape({
      termsId: Yup.string()
        .required('Terms ID is required')
        .matches(/^\S+$/, 'Terms ID should not contain whitespaces')
        .test('doesExist', 'Terms ID already exists', (value) => {
          if (!value) return true
          return !planTermsList?.some((planTerms) => planTerms.termsId === value)
        }),
      description: Yup.string().required('Terms Name is required'),
    })
    .defined()
}

type TermsFormProps = {
  onCancel: () => void
  onUpload: (file: File, description: string, termsId: string) => Promise<void>
  isUploadProcessing?: boolean
  planTermsList?: PlanTermsType[]
  termsId?: string
  description?: string
}

const TermsForm: FC<TermsFormProps> = ({
  onCancel,
  onUpload,
  isUploadProcessing,
  planTermsList,
  termsId,
  description,
}) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const inputFileRef = createRef<HTMLInputElement>()

  const isEditForm = Boolean(termsId)

  const { values, dirty, isValid, errors, validateForm, handleChange } = useFormik({
    initialValues: {
      termsId: termsId || '',
      description: description || '',
    },
    enableReinitialize: true,
    validationSchema: () => getSchema(planTermsList, isEditForm),
    onSubmit: () => {},
  })

  const handleSelectFile = (): void => {
    resetUploadedFile()
    inputFileRef.current?.click()
  }

  const resetUploadedFile = (): void => {
    const { current } = inputFileRef
    if (current?.value) {
      current.value = ''
    }
    setUploadedFile(null)
  }

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.[0] || null
    setUploadedFile(file)
  }

  const handleUploadFile = async (): Promise<void> => {
    if (!uploadedFile || !isValid) {
      return
    }
    // if terms list is still loading, this will retrigger the validation without triggering the upload after the loading is complete
    const res = await validateForm()
    if (Object.keys(res).length === 0) {
      onUpload(uploadedFile, values.description, values.termsId)
    }
  }

  const handleCancelClick = (): void => {
    resetUploadedFile()
    onCancel()
  }

  const isUploadButtonDisabled = !uploadedFile || !isValid || (!isEditForm && !dirty)

  return (
    <div data-cy="terms-form">
      {uploadedFile ? (
        <InlineAlert
          color={InlineAlertColor.green}
          icon={Check}
          primaryButtonProps={{
            text: 'Replace File',
            onClick: handleSelectFile,
            isDisabled: isUploadProcessing,
          }}
          data-cy="import-success-alert"
        >
          {uploadedFile?.name} is ready to be uploaded.
        </InlineAlert>
      ) : (
        <Button
          data-cy="select-file-button"
          text="Select PDF File"
          onClick={handleSelectFile}
          emphasis="medium"
        />
      )}
      <Container>
        <InputGridContainer>
          <Grid columns={2}>
            <Input
              id="termsId"
              label="Terms ID"
              placeholder="Enter terms ID without whitespaces"
              value={values.termsId}
              onChange={handleChange}
              isError={Boolean(errors.termsId)}
              isDisabled={isEditForm}
              errorFeedback={errors.termsId}
            />
          </Grid>
        </InputGridContainer>
        <Grid columns={2}>
          <Input
            id="description"
            label="Description"
            placeholder="Enter description"
            value={values.description}
            onChange={handleChange}
            isError={Boolean(errors.description)}
            errorFeedback={errors.description}
            isDisabled={isEditForm}
          />
        </Grid>
      </Container>

      <ButtonGroupContainer>
        <SaveChangesButtonGroup
          isSaveDisabled={isUploadButtonDisabled || isUploadProcessing}
          saveButtonText={isEditForm ? 'Save' : 'Upload'}
          id="upload-terms-buttons"
          handleSave={handleUploadFile}
          handleCancel={handleCancelClick}
          isLoading={!isUploadButtonDisabled && isUploadProcessing}
        />
      </ButtonGroupContainer>
      <HiddenInput
        id="inputFile"
        ref={inputFileRef}
        accept=".pdf"
        type="file"
        onChange={handleFileChange}
      />
    </div>
  )
}

const HiddenInput = styled.input({
  display: 'none',
})

const ButtonGroupContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 24,
})

const Container = styled.div({
  marginTop: 24,
})

const InputGridContainer = styled.div({
  marginBottom: 16,
})

export { TermsForm }
