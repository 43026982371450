import { useContext, useEffect } from 'react'
import { ShellContext } from '../components/shell/shell/shell-context'

export const useViewportPaddingOverride = (padding: number | string): void => {
  const context = useContext(ShellContext)

  useEffect(() => {
    if (context) {
      context.setViewportPadding(padding)
    }

    return () => {
      if (context) {
        context.setViewportPadding(null)
      }
    }
  }, [context, padding])
}
