import type { PlanSearch } from '@helloextend/extend-api-client'
import type { Action } from '../actions'

export type PlansSearchReducerState = {
  currentPage: PlanSearch[]
  nextPageCursor: string | null | undefined
  page: number
  filter?: string
  lastFetched: number
  pageGroup: {
    [key: string]: PlanSearch[]
  }
}

export const initialState: PlansSearchReducerState = {
  currentPage: [],
  nextPageCursor: null,
  page: 0,
  lastFetched: 0,
  pageGroup: {
    page1: [],
  },
}

export function search(state = initialState, action: Action): PlansSearchReducerState {
  switch (action.type) {
    case 'PLANS_SEARCH':
      return { ...initialState, filter: action.payload.filter }
    case 'PLANS_INCREMENT_NEXT_PAGE': {
      const page = state.page + 1
      return {
        ...state,
        page,
        currentPage: state.pageGroup[page],
      }
    }
    case 'PLANS_SEARCH_PREV_PAGE': {
      const page = state.page - 1 || 1
      return {
        ...state,
        page,
        currentPage: state.pageGroup[page],
      }
    }
    case 'PLANS_SEARCH_SUCCESS': {
      const nextPage = state.page + 1
      return {
        ...state,
        pageGroup: {
          ...state.pageGroup,
          [nextPage]: action.payload.plans,
        },
        page: nextPage,
        lastFetched: nextPage,
        currentPage: action.payload.plans,
        nextPageCursor: action.payload.nextPageCursor,
      }
    }
    default:
      return state
  }
}
