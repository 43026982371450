import { plansActions } from '@helloextend/core-api-redux'

export const {
  fetch,
  fetchRequest,
  fetchSuccess,
  fetchFailure,
  fetchDetails,
  fetchAll,
  search,
  create,
  createRequest,
  createFailure,
  createSuccess,
  incrementNextPage,
  nextPage,
  prevPage,
} = plansActions
