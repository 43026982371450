import type { FC } from 'react'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import styled from '@emotion/styled'
import 'react-datepicker/dist/react-datepicker.css'
import { bp } from '@helloextend/client-utils/breakpoints'
import { useScreenSize } from '@helloextend/client-hooks'
import { COLOR } from '@helloextend/zen'
import { getBorderColor } from '../../utils/field-border-color'
import CalendarIcon from './calendar-icon'

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  day: 'numeric',
  month: 'long',
}

const formatDate = (displayDate: Date, monthOptions = {}): string =>
  displayDate.toLocaleDateString('en-US', { ...dateOptions, ...monthOptions })

interface DateInputProps {
  'data-cy'?: string
  disabled?: boolean
  disabledKeyboardNavigation?: boolean
  calendarPosition?:
    | 'auto'
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left'
    | 'left-end'
    | 'left-start'
    | 'right'
    | 'right-end'
    | 'right-start'
    | 'top'
    | 'top-end'
    | 'top-start'
  id?: string
  placeHolder?: string
  onChange: (date: Date | null) => void
  onCalendarClose?: () => void
  onCalendarOpen?: () => void
  minDate?: Date | null
  maxDate?: Date | null
  selected: Date | null
  errorFeedback?: string
  fullWidth?: boolean
}

const DatePicker: FC<DateInputProps> = ({
  disabled,
  disabledKeyboardNavigation,
  calendarPosition = 'auto',
  id,
  onChange,
  onCalendarClose,
  onCalendarOpen,
  placeHolder = formatDate(new Date(), { month: 'numeric' }),
  minDate,
  maxDate,
  selected,
  'data-cy': dataCy,
  errorFeedback = '',
  fullWidth = false,
}) => {
  const screenSize = useScreenSize()
  const fill = disabled ? COLOR.NEUTRAL[300] : COLOR.BLUE[800]
  return (
    <>
      <DateWrapper
        data-cy={dataCy}
        disabled={disabled}
        fill={fill}
        errorFeedback={errorFeedback}
        fullWidth={fullWidth}
      >
        <VisuallyHiddenLabel htmlFor="datePicker">Pick a date:</VisuallyHiddenLabel>
        <ReactDatePicker
          data-cy={dataCy}
          disabled={disabled}
          id={id}
          onChange={onChange}
          onCalendarClose={onCalendarClose}
          onCalendarOpen={onCalendarOpen}
          inline={screenSize === 'small'}
          minDate={minDate}
          maxDate={maxDate}
          selected={selected}
          placeholderText={screenSize !== 'small' ? placeHolder : ''}
          disabledKeyboardNavigation={disabledKeyboardNavigation}
          popperPlacement={calendarPosition}
          popperModifiers={{
            flip: {
              enabled: calendarPosition === 'auto' || !calendarPosition,
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: 'viewport',
            },
          }}
        />
        {screenSize !== 'small' && (
          <CalendarIconWrapper>
            <CalendarIcon fill={fill} />
          </CalendarIconWrapper>
        )}
      </DateWrapper>
      {!!errorFeedback && (
        <Feedback>
          <ErrorMessage>{errorFeedback}</ErrorMessage>
        </Feedback>
      )}
    </>
  )
}

const VisuallyHiddenLabel = styled.label({
  opacity: 0,
  position: 'absolute',
})

interface DateWrapperProps {
  disabled: boolean | undefined
  fill: string
  errorFeedback?: string
  fullWidth?: boolean
}

const DateWrapper = styled('div')<DateWrapperProps>(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [bp.md]: {
      flexDirection: 'row',
    },
  },
  ({ disabled, fill, errorFeedback, fullWidth }) => ({
    '.react-datepicker-wrapper': {
      width: fullWidth ? '100%' : 'auto',
      input: {
        width: fullWidth ? '100%' : 'auto',
        boxSizing: fullWidth ? 'border-box' : 'inherit',
      },
    },
    '.react-datepicker': {
      fontFamily: 'Nunito Sans, Arial, Helvetica, sans-serif',
      border: 'none',
      pointerEvents: disabled ? 'none' : 'auto',
      [bp.sm]: {
        border: `1px solid ${COLOR.NEUTRAL[300]}`,
        boxShadow: '0px 3px 5px 0px rgba(209, 205, 205, 0.5)',
        position: 'relative',
      },
    },
    '.react-datepicker__input-container input': {
      borderRadius: '4px',
      border: `1px solid ${getBorderColor(Boolean(errorFeedback), disabled)}`,
      padding: '10px 16px',
      fontSize: '14px',
      color: disabled ? fill : COLOR.NEUTRAL[1000],
      background: 'none',
    },
    '.react-datepicker__input-container input::placeholder': {
      color: disabled ? fill : COLOR.NEUTRAL[1000],
    },
    '.react-datepicker__triangle': {
      display: 'none',
    },
    '.react-datepicker .react-datepicker__header': {
      background: COLOR.WHITE,
      border: 'none',
    },
    [`.react-datepicker .react-datepicker__current-month,
    .react-datepicker .react-datepicker__day, .react-datepicker .react-datepicker__day-name`]: {
      color: COLOR.NEUTRAL[1000],
    },
    [`.react-datepicker .react-datepicker__day--outside-month, .react-datepicker .react-datepicker__day--disabled`]:
      {
        color: COLOR.NEUTRAL[300],
      },
    '.react-datepicker .react-datepicker__day--today.react-datepicker__day--outside-month': {
      color: COLOR.WHITE,
    },
    '.react-datepicker .react-datepicker__day--today': {
      fontWeight: 'normal',
      background: COLOR.NEUTRAL[300],
    },
    '.react-datepicker .react-datepicker__day': {
      transition: 'all .25s ease-in-out',
      width: '32px',
      height: '32px',
      margin: '8px',
      border: `1px solid ${COLOR.WHITE}`,
      fontSize: '12px',
      borderRadius: '50%',
      padding: 0,
      lineHeight: '32px',
    },
    '.react-datepicker .react-datepicker__day:hover': {
      background: COLOR.WHITE,
      borderRadius: '50%',
      border: `1px solid ${fill}`,
    },
    '.react-datepicker .react-datepicker__day--disabled:hover': {
      borderRadius: '50%',
      border: `1px solid ${COLOR.NEUTRAL[300]}`,
    },
    [`.react-datepicker .react-datepicker__day--selected,
  .react-datepicker .react-datepicker__day--selected:hover, .react-datepicker .react-datepicker__day--keyboard-selected:not(.react-datepicker .react-datepicker__day--today)`]:
      {
        background: fill,
        color: COLOR.WHITE,
        border: 'none',
      },
    '.react-datepicker .react-datepicker__day--keyboard-selected:hover': {
      color: COLOR.NEUTRAL[1000],
    },
    '.react-datepicker .react-datepicker__day-name': {
      width: '32px',
      height: '32px',
      margin: '8px',
      fontSize: '12px',
      marginTop: '24px',
      fontWeight: 600,
    },
    '.react-datepicker .react-datepicker__navigation--previous': {
      borderRightColor: fill,
    },
    '.react-datepicker .react-datepicker__navigation--next': {
      borderLeftColor: fill,
    },
    '.react-datepicker .react-datepicker__navigation--previous:hover': {
      outline: 'none',
      '@media (hover: none)': {
        borderRightColor: fill,
      },
      '@media (hover: hover)': {
        borderRightColor: fill,
      },
    },
    '.react-datepicker .react-datepicker__navigation--next:hover': {
      outline: 'none',
      '@media (hover: none)': {
        borderLeftColor: fill,
      },
      '@media (hover: hover)': {
        borderLeftColor: fill,
      },
    },
  }),
)

const CalendarIconWrapper = styled.div({
  alignSelf: 'center',
  display: 'flex',
  marginLeft: '-30px',
})

const Feedback = styled.div({
  fontSize: 15,
  lineHeight: '20px',
  color: COLOR.RED[700],
  marginTop: 4, // to match "gap: 4" in zen field
})

const ErrorMessage = styled.span({
  color: COLOR.RED[700],
})

export { DatePicker }
