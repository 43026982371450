import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { InlineAlert, InlineAlertColor, Input, InputType, Modal } from '@helloextend/zen'
import { useFormik } from 'formik'
import type { Product } from '@helloextend/extend-api-client'
import { Paragraph } from '@helloextend/zen/src/tokens/typography'
import {
  useCreateSubproductMutation,
  useLazyGetProductQuery,
} from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { schema } from './schema'

type AddSubproductItemModalProps = {
  toggleModalOff: () => void
  product: Product
  refetchProduct: () => void
}

const AddSubproductItemModal: FC<AddSubproductItemModalProps> = ({
  toggleModalOff,
  product,
  refetchProduct,
}) => {
  const [isSuccessAlertDisplayed, setIsSuccessAlertDisplayed] = useState(false)
  const [hasSearchedItem, setHasSearchedItem] = useState(false)
  const { values, dirty, isValid, errors, setFieldError, handleChange } = useFormik({
    enableReinitialize: true,
    validationSchema: () => schema(product),
    validateOnBlur: false,
    initialValues: {
      referenceId: '',
      quantity: '1',
    },
    onSubmit: () => {},
  })

  const { toastCompleted, toastError } = useStandardToast()

  const [getProduct, { isFetching, isError: isSearchError }] = useLazyGetProductQuery()

  const [createSubproduct, { isLoading: isAddingItem }] = useCreateSubproductMutation()

  const handleAddClick = async (): Promise<void> => {
    try {
      await createSubproduct({
        storeId: product.storeId,
        productId: product.referenceId,
        subproductId: values.referenceId,
        body: {
          quantity: Number(values.quantity),
        },
      }).unwrap()

      refetchProduct()

      toastCompleted('Bundle Item has been added successfully.')
      toggleModalOff()
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      toastError('An error occurred while adding the item. Please try again.')
    }
  }

  const handleSearchClick = async (): Promise<void> => {
    try {
      await getProduct({
        version: 'latest',
        storeId: product.storeId,
        referenceId: values.referenceId,
      }).unwrap()
      setIsSuccessAlertDisplayed(true)
      setHasSearchedItem(true)
    } catch (error) {
      let referenceIdError =
        'An error occurred while looking up the Reference ID. Please try again.'
      if ((error as FetchBaseQueryError)?.status === 404) {
        referenceIdError = 'No product found with the matched reference ID.'
      }
      setFieldError('referenceId', referenceIdError)
    }
  }

  const handleReferenceIdChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    handleChange(e)
    setIsSuccessAlertDisplayed(false)
    setHasSearchedItem(false)
  }

  const isAddButtonDisabled =
    !dirty || !isValid || !values.referenceId || isSearchError || !hasSearchedItem

  return (
    <Modal
      heading="Add Item"
      data-cy="add-bundle-item-modal"
      onDismissRequest={toggleModalOff}
      primaryButtonProps={{
        text: 'Add',
        'data-cy': 'bundle-item-modal-button-add',
        onClick: handleAddClick,
        isDisabled: isAddButtonDisabled,
        isProcessing: isAddingItem,
      }}
      secondaryButtonProps={{
        text: 'Cancel',
        'data-cy': 'bundle-item-modal-button-cancel',
        onClick: toggleModalOff,
      }}
    >
      <Container>
        <Input
          value={values.referenceId}
          id="referenceId"
          label="Reference ID"
          data-cy="reference-id"
          placeholder="Enter a complete reference ID"
          onChange={handleReferenceIdChange}
          isError={Boolean(errors.referenceId)}
          errorFeedback={errors.referenceId}
          actionButtonProps={{
            text: 'Search',
            color: 'blue',
            emphasis: 'high',
            isDisabled: !values.referenceId || !!errors.referenceId,
            isProcessing: isFetching,
            onClick: handleSearchClick,
          }}
        />
        <Input
          value={values.quantity.toString()}
          id="quantity"
          label="Quantity"
          data-cy="quantity"
          placeholder="Enter a number"
          onChange={handleChange}
          type={InputType.number}
          isError={Boolean(errors.quantity)}
          errorFeedback={errors.quantity}
        />
      </Container>
      {isSuccessAlertDisplayed && (
        <InlineAlertContainer>
          <InlineAlert color={InlineAlertColor.green} data-cy="search-success-inline-alert">
            <Paragraph>
              Product found with the matched reference ID, click &quot;Add&quot; to confirm.
            </Paragraph>
          </InlineAlert>
        </InlineAlertContainer>
      )}
    </Modal>
  )
}

const Container = styled.div({
  display: 'grid',
  gridTemplateColumns: '2fr .7fr',
  gap: '16px',
})

const InlineAlertContainer = styled.div({
  marginTop: 16,
})

export { AddSubproductItemModal }
