import type { FC } from 'react'
import React from 'react'
import { useHistory } from 'react-router'
import { Button } from '@helloextend/zen'
import styled from '@emotion/styled'
import { ClaimsDataTable } from './claims-data-table'

const Claims: FC = () => {
  const { push } = useHistory()

  const handleClick = (): void => {
    push('/admin/contracts')
  }
  return (
    <>
      <Header>
        <Title>Claims</Title>
        <Button text="File a new claim" onClick={handleClick} data-cy="file-claim-button" />
      </Header>
      <ClaimsDataTable />
    </>
  )
}
const Title = styled.h3({
  fontSize: 32,
  margin: 0,
  padding: 0,
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 24,
})

export { Claims }
