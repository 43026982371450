import React from 'react'
import type { ServicerLocation } from '@helloextend/extend-api-client'
import type { FormikErrors } from 'formik'
import * as Yup from 'yup'
import type {
  CollapsibleInfoGroupProps,
  CustomComponentProps,
} from '../../../../../components/collapsible-info-group'
import { fieldMapper } from '../../../../../components/collapsible-info-group'
import { AddressSearch } from '../../../../../components/address-search'

const schema = Yup.object()
  .shape({
    businessName: Yup.string().default('').required('Required'),
    address: Yup.object()
      .shape({
        address1: Yup.string().default('').required('Required'),
        address2: Yup.string().default(''),

        city: Yup.string().default('').required('Required'),
        postalCode: Yup.string().default('').required('Required'),

        provinceCode: Yup.string().default('').required('Required'),
        countryCode: Yup.string().default('USA').required(),
      })
      .defined(),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

const getLocationInfoData = <S extends Record<string, any>>(
  isEditable: boolean,
  servicerLocation?: ServicerLocation,
  formValues?: { [key: string]: any },
  formErrors?: FormikErrors<S>,
): CollapsibleInfoGroupProps['data'] => {
  if (!isEditable && !servicerLocation) return []

  const fields = [
    {
      key: 'businessName',
      label: 'Location Name',
      editable: isEditable,
    },
    {
      key: 'address.address1',
      label: 'Address',
      editable: isEditable,
      CustomComponent: ({ onChange, error, value }: CustomComponentProps) => (
        <AddressSearch
          label="Address"
          errorMessage={error}
          value={value}
          onChange={(changedFields) => {
            onChange('address.address1', changedFields.address1)
            onChange('address.city', changedFields.city)
            onChange('address.provinceCode', changedFields.provinceCode)
            onChange('address.postalCode', changedFields.postalCode)
          }}
        />
      ),
    },
    {
      key: 'address.address2',
      label: 'Unit/Suite',
      editable: isEditable,
    },
    {
      key: 'address.city',
      label: 'City',
      editable: isEditable,
    },
    {
      key: 'address.provinceCode',
      label: 'State',
      editable: isEditable,
    },
    {
      key: 'address.postalCode',
      label: 'Zip Code',
      editable: isEditable,
    },
  ]

  return [
    {
      header: 'Location 1',
      values: fieldMapper<ServicerLocation, S>(
        servicerLocation || ({ ...schema.default() } as ServicerLocation),
        fields,
        formValues,
        formErrors,
      ),
    },
  ]
}

export { schema, getLocationInfoData }
export type { Values }
