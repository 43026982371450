import type { FC } from 'react'
import React from 'react'
import styles from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import { LinkTab } from './link-tab'

export type TabBarLinkDefinition = {
  text: string
  to: string
}

export type TabBarProps = {
  tabBarLinks: TabBarLinkDefinition[]
}

const TabBar: FC<TabBarProps> = ({ tabBarLinks, children }) => (
  <>
    <TabBarWrapper>
      <List data-cy="navigation-bar">
        {tabBarLinks.map((tabBarLink) => (
          <LinkTab key={tabBarLink.text} to={tabBarLink.to}>
            {tabBarLink.text}
          </LinkTab>
        ))}
      </List>
    </TabBarWrapper>
    {children}
  </>
)

const TabBarWrapper = styles.div({
  height: 56,
  zIndex: -1,
  borderBottom: `1px solid ${COLOR.GHOST}`,
  marginBottom: 40,
})

const List = styles.ul({
  listStyleType: 'none',
  display: 'flex',
  justifyContent: 'flex-start',
  gap: 48,
  padding: 0,
  margin: 0,
})

export { TabBar }
