import type { FC } from 'react'
import React, { useCallback, useMemo, useState } from 'react'
import type { SkuSearchParams } from '@helloextend/extend-api-rtk-query'
import {
  useGetSkusSearchCountQuery,
  useListInsuranceProgramsQuery,
  useListPlanAttributesQuery,
  useQuerySkusQuery,
} from '@helloextend/extend-api-rtk-query'
import type { FilterValues } from '@helloextend/merchants-ui'
import { DataReactTable, PaginationType } from '@helloextend/merchants-ui'
import { useHistory } from 'react-router-dom'
import { useQueryStringState } from '@helloextend/client-hooks/src/use-query-string-state'
import type { TableState } from 'react-table'
import { getFilterOptions, getFiltersForApi, searchOptions, skusColumns } from './table-config'

const SkusDataTable: FC = () => {
  const { push } = useHistory()

  const [searchKey, setSearchKey] = useQueryStringState('search', '')
  const [searchValue, setSearchValue] = useQueryStringState('searchValue', '')

  const [sortDirection, setSortDirection] = useQueryStringState<SkuSearchParams['sortDirection']>(
    'sortDirection',
    'asc',
  )
  const [sortField, setSortField] = useQueryStringState<SkuSearchParams['sortField']>(
    'sortField',
    'id',
  )

  const [pageSize, setPageSize] = useQueryStringState('pageSize', 20)
  const [serverPageIndex, setServerPageIndex] = useState(0)

  const [searchFilters, setSearchFilters] = useQueryStringState<
    Record<string, FilterValues | null>
  >('filters', {}, { encode: true, transformDates: true })

  const filtersForApi = useMemo(
    () => getFiltersForApi(searchFilters, searchKey, searchValue),
    [searchFilters, searchKey, searchValue],
  )

  const paramsForApi: SkuSearchParams = useMemo(
    () => ({
      offset: serverPageIndex * pageSize,
      sortDirection,
      sortField,
      limit: pageSize,
      ...filtersForApi,
    }),
    [filtersForApi, pageSize, serverPageIndex, sortDirection, sortField],
  )

  const { data: skusCount } = useGetSkusSearchCountQuery(paramsForApi)

  const { data, isSuccess, isFetching, refetch } = useQuerySkusQuery(paramsForApi)

  const { insuranceProgramIdsList, isSuccess: isInsuranceListSuccess } =
    useListInsuranceProgramsQuery(undefined, {
      selectFromResult: ({ data: insuranceData, ...rest }) => ({
        insuranceProgramIdsList: insuranceData?.items?.map((item) => item.id),
        ...rest,
      }),
    })

  const { planAttributes, isSuccess: isPlanAttributesSuccess } = useListPlanAttributesQuery(
    undefined,
    {
      selectFromResult: ({ data: attributes, ...rest }) => ({
        planAttributes: {
          program: attributes?.items?.find((attr) => attr.id === 'program')?.values ?? [],
          subProgram: attributes?.items?.find((attr) => attr.id === 'sub_program')?.values ?? [],
        },
        ...rest,
      }),
    },
  )

  const isInitialRequestFulfilled = isSuccess && isInsuranceListSuccess && isPlanAttributesSuccess

  const filterOptions = useMemo(() => {
    return insuranceProgramIdsList && planAttributes
      ? getFilterOptions(insuranceProgramIdsList, planAttributes)
      : {}
  }, [insuranceProgramIdsList, planAttributes])

  const initialState = useMemo(
    (): Partial<TableState> => ({
      sortBy: [{ id: sortField || '', desc: sortDirection === 'desc' }],
      pageSize,
      pageIndex: serverPageIndex,
      filters: Object.keys(searchFilters).map((key) => ({
        id: key,
        value: searchFilters[key],
      })),
    }),
    [pageSize, searchFilters, serverPageIndex, sortDirection, sortField],
  )

  const handleRowClick = useCallback(
    (_e, rowData): void => {
      push(`/admin/premiums/${rowData.id}`)
    },
    [push],
  )

  const resetPagination = useCallback(() => {
    setServerPageIndex(0)
  }, [setServerPageIndex])

  const setPagination = useCallback(
    (newPageSize: number) => {
      setPageSize(newPageSize)
    },
    [setPageSize],
  )

  const handleSearch = useCallback(
    (key?: string, value?: string): void => {
      if (key && value) {
        setSearchKey(key)
        setSearchValue(value)
      } else {
        setSearchKey('')
        setSearchValue('')
      }

      resetPagination()
    },
    [resetPagination, setSearchKey, setSearchValue],
  )

  const handleServerFilter = useCallback(
    (filtersRecord: Record<string, FilterValues | null>) => {
      setSearchFilters(filtersRecord)
      resetPagination()
    },
    [resetPagination, setSearchFilters],
  )

  const handleServerSort = useCallback(
    (newSortKey: string, newSortAsc: boolean) => {
      if (newSortKey) {
        const newSortDirection = newSortAsc ? 'asc' : 'desc'
        setSortField(newSortKey)
        setSortDirection(newSortDirection)
        resetPagination()
      }
    },
    [resetPagination, setSortDirection, setSortField],
  )

  const handleCustomPagination = useCallback(
    (tempPageIndex: number) => {
      setServerPageIndex(tempPageIndex)
      refetch()
    },
    [refetch],
  )

  return (
    <DataReactTable
      data={data?.skus ?? []}
      type="premiums"
      initialState={initialState}
      columns={skusColumns}
      onRowClick={handleRowClick}
      onServerSearch={handleSearch}
      onServerFilter={handleServerFilter}
      hasSearchBar
      searchOptions={searchOptions}
      initialSearchKey="id"
      initialSearchValue=""
      filterOptions={filterOptions}
      onServerSort={handleServerSort}
      enableSearch
      data-cy="skus-list"
      paginationType={PaginationType.ENHANCED}
      isInitialReqFullfilled={isInitialRequestFulfilled}
      totalCount={skusCount?.totalSkus ?? 0}
      setPagination={setPagination}
      isLoading={isFetching}
      manualPagination
      pageCount={skusCount && Math.ceil(skusCount.totalSkus / pageSize)}
      onPageIndexChange={handleCustomPagination}
      emptyMessage="Double check that the search term is correct, or try applying different filters to your search."
    />
  )
}

export { SkusDataTable }
