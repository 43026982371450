import { COLOR } from '../../../tokens/colors'

export const getBorderColor = (
  isInternallyFocused?: boolean,
  error?: boolean,
  disabled?: boolean,
): string => {
  if (disabled) {
    return COLOR.NEUTRAL[300]
  }

  if (error) {
    return COLOR.RED[700]
  }

  if (isInternallyFocused) {
    return COLOR.BLUE[700]
  }

  return COLOR.NEUTRAL[400]
}
