import type { WarrantyStatus } from '@helloextend/extend-api-client'
import type { ProductCsvValidatorPipe } from './product-csv-headers'

const warrantyStatusMap: Record<string, WarrantyStatus> = {
  pending: 'pending',
  warrantable: 'warrantable',
  'non-warrantable': 'non-warrantable',
  'needs review': 'review_required',
}

const validateWarrantyStatus = (meta: ProductCsvValidatorPipe): ProductCsvValidatorPipe => {
  const warrantyStatusIndex = meta.headerIndexes.warrantyMatchStatus
  const colValue = meta.rowData[warrantyStatusIndex]
  const warrantyStatus = warrantyStatusMap[colValue?.toLowerCase()]

  if (!warrantyStatus) {
    const WARRANTY_STATUS_VALUES = Object.keys(warrantyStatusMap).join(', ')
    meta.errors.push(
      `"warrantyMatchStatus" must be one of four values: ${WARRANTY_STATUS_VALUES} (${colValue})`,
    )
  }
  return meta
}

export { validateWarrantyStatus, warrantyStatusMap }
