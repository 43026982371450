import type {
  SupportedAlternateLanguages,
  AlternateLanguageSettings,
} from '@helloextend/extend-api-client'
import type { ProductCsvValidatorPipe } from './product-csv-headers'

export const supportedLanguages: SupportedAlternateLanguages[] = ['fr-CA']
export const supportedProperties: Array<keyof AlternateLanguageSettings> = ['title']

const validateAlternateLanguages = (meta: ProductCsvValidatorPipe): ProductCsvValidatorPipe => {
  const alternateLanguagesIndex = meta.headerIndexes.alternateLanguages
  const colValue = meta.rowData[alternateLanguagesIndex]

  // skip validation if alternate language data not provided
  if (!colValue) return meta

  const [language, property, value] = colValue?.split('::') as [
    SupportedAlternateLanguages,
    keyof AlternateLanguageSettings,
    string,
  ]

  // All 3 values must be present to add alternateLanguages
  if (!language || !property || !value) {
    meta.errors.push(
      `"alternateLanguages" must contain properties in language::property::value format.  Ex: "fr-CA::title::title in french" (${colValue})`,
    )
  }
  if (supportedLanguages.includes(language) === false) {
    meta.errors.push(
      `Alternate Language not supported, must be one of: ${supportedLanguages}. (${language})`,
    )
  }

  if (supportedProperties.includes(property) === false) {
    meta.errors.push(
      `Alternate Language property not supported, most be one of: ${String(
        supportedProperties,
      )}. (${property})`,
    )
  }
  return meta
}

export { validateAlternateLanguages }
