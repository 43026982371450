import type { FC } from 'react'
import React from 'react'
import { CheckboxFilter } from './checkbox-filter'
import { DateRangeFilter } from './date-range-filter'
import type { FilterOptions, FilterValues } from './types'

type FilterProps = {
  property: string
  filter: FilterOptions
  values?: FilterValues
  onFilterChange: (property: string, values: FilterValues | null) => void
  onMenuLock: (isLocked: boolean) => void
}

const Filter: FC<FilterProps> = ({ property, filter, values, onFilterChange, onMenuLock }) => {
  switch (filter.type) {
    case 'checkbox':
      return (
        <CheckboxFilter
          property={property}
          onFilterChange={onFilterChange}
          options={filter.options}
          values={values && values.type === 'checkbox' ? values : undefined}
        />
      )
    case 'dateRange':
      return (
        <DateRangeFilter
          property={property}
          onFilterChange={onFilterChange}
          onMenuLock={onMenuLock}
          values={values && values.type === 'dateRange' ? values : undefined}
        />
      )
  }

  return null
}

export { Filter }
