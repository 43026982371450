type TenancyErn = {
  ern: string
  accountResourceId: string
  organizationResourceId?: string
}

const fullTenancyErnRegex = new RegExp(
  '^ERN(:ACC:([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}|\\*))(:ORG:([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}))?$',
)

function validTenancyErn(ern: string): boolean {
  return fullTenancyErnRegex.test(ern)
}

export function parseTenancyErn(ern: string): TenancyErn | undefined {
  if (!validTenancyErn(ern)) {
    return undefined
  }

  const matches = ern.match(fullTenancyErnRegex)
  if (!matches || matches.length !== 5) return undefined

  return {
    ern,
    accountResourceId: matches[1].split(':')[2],
    ...(matches[3] && { organizationResourceId: matches[3].split(':')[2] }),
  }
}

export function isAccountAdmin(
  grants: Array<{ ern: string; role: string }>,
  accountId: string,
): boolean {
  return grants.some((grant) => {
    // switch back from "user" to "MerchantAdmin" after we go live with enterprise roles
    return grant.role === 'user' && accountId === parseTenancyErn(grant.ern)?.accountResourceId
  })
}

export function isERNBoundToAllAccounts(unknownErn: string): boolean {
  if (!validTenancyErn(unknownErn)) return false
  const { accountResourceId, organizationResourceId } = parseTenancyErn(unknownErn)!
  return accountResourceId === '*' && !organizationResourceId
}
