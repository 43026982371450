import type { VFC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../../tokens/colors'

export interface RowImageProps {
  src: string
}

export const RowImage: VFC<RowImageProps> = ({ src }) => {
  return <StyledRowImage src={src} />
}

const StyledRowImage = styled.img({
  display: 'block',
  width: 40,
  height: 40,
  margin: '-8px -16px -8px 0',
  objectFit: 'cover',
  borderRadius: 4,
  backgroundColor: COLOR.NEUTRAL.OPACITY[25],
})
