import type {
  NonNumericConditionWithScript,
  Condition,
  DefaultMessage,
  DefaultReply,
  NonNumericConditionWithComparand,
  ScriptItem,
  ThreadResponse,
} from '@helloextend/extend-api-rtk-query'
import type { AdvancedSelectOption } from '@helloextend/zen'
import { COLOR } from '@helloextend/zen'
import type { AdvancedSelectOptionGroup } from '@helloextend/zen/src/components/fields/advanced-select/advanced-select-types'
import type { ScriptItemUsage } from '../../../store/slices/amp-slice'
import { getKeySelectorOptionsBase } from './key-selector-options-base'
import {
  getIsReplacementMessage,
  getIsReplacementReply,
  getJumpToSelectorText,
  identifyCondition,
} from './utils'

export const generateJumpToOptions = (
  thread: ThreadResponse,
  messageBlockIndex?: number | null,
): AdvancedSelectOption[] => {
  const jumpToOptions = thread.script
    .map((scriptItem, index) => {
      const kaleyText = getJumpToSelectorText(scriptItem)
      return {
        display: kaleyText,
        value: String(index + 1),
        badgeColor: 'neutral',
        badgeText: String(index + 1),
      } as AdvancedSelectOption
    })
    .concat([
      {
        display: '',
        badgeText: 'End of Conversation',
        badgeColor: 'neutral',
        badgeEmphasis: 'high',
        value: 'End of Conversation',
      },
      {
        display: '',
        badgeText: 'Next Thread',
        badgeColor: 'neutral',
        badgeEmphasis: 'high',
        value: 'Next Thread',
      },
    ])

  // if an index is passed in, then it should be excluded from JumpTo Options
  return messageBlockIndex === undefined || messageBlockIndex === null
    ? jumpToOptions
    : jumpToOptions.filter((option) => Number(option.value) - 1 !== messageBlockIndex)
}

export function handleNumericBadgeClick(
  refs: React.MutableRefObject<{
    [key: number]: HTMLElement
  }>,
  value: number,
): void {
  const messageBlockElement = refs.current[value]
  if (messageBlockElement) {
    scrollToAndAnimate(messageBlockElement)
  }
}

export function scrollToAndAnimate(messageBlockElement: Element): void {
  messageBlockElement.scrollIntoView({ behavior: 'smooth' })
  const messageBoxElement = messageBlockElement.children?.item(1)?.children?.item(0)
  if (messageBoxElement) {
    animateMessageBoxBorder(messageBoxElement)
  }
}

const boxShadowAnimationColor = COLOR.NEUTRAL.OPACITY[50]
const borderAnimationNeutralColor = COLOR.NEUTRAL[300]
const borderAnimationBlueColor = COLOR.BLUE[700]
function animateMessageBoxBorder(messageBoxElement: Element): void {
  messageBoxElement.animate(
    {
      borderColor: [
        borderAnimationNeutralColor,
        borderAnimationBlueColor,
        borderAnimationBlueColor,
        borderAnimationNeutralColor,
      ],
      boxShadow: [
        `0px 0px 0px 0px ${boxShadowAnimationColor}`,
        `0px 0px 8px 0px ${boxShadowAnimationColor}`,
        `0px 0px 8px 0px ${boxShadowAnimationColor}`,
        `0px 0px 0px 0px ${boxShadowAnimationColor}`,
      ],
      offset: [0, 0.3, 0.8, 1],
    },
    {
      duration: 2000,
    },
  )
}

export const getAdvancedSelectOptionGroupsForKeySelector = ({
  scriptObjects,
  currentSelection,
}: {
  scriptObjects: ScriptItemUsage[]
  currentSelection?: number
}): AdvancedSelectOptionGroup[] => {
  const options: AdvancedSelectOptionGroup[] = [...getKeySelectorOptionsBase()]
  const messageBlockChoices: AdvancedSelectOption[] = scriptObjects.reduce((acc, scriptObject) => {
    const { script, scriptIndex, inUse } = scriptObject
    const { reply } = script

    if (
      reply &&
      !getIsReplacementReply(reply) &&
      (reply.prompt?.type === 'buttons' ||
        reply.prompt?.type === 'multiselect' ||
        reply.prompt?.type === 'datepicker')
    ) {
      const defaultMessage = reply.messages[0] as DefaultMessage
      const isScriptItemSelected = currentSelection === scriptIndex
      if (
        ((inUse && isScriptItemSelected) || (!inUse && !isScriptItemSelected)) &&
        defaultMessage &&
        !getIsReplacementMessage(defaultMessage)
      ) {
        return [
          ...acc,
          {
            display: defaultMessage.content,
            value: String(scriptIndex),
            badgeEmphasis: 'medium',
            badgeText: String(scriptIndex + 1),
          } as AdvancedSelectOption,
        ]
      }
    }
    return acc
  }, [] as AdvancedSelectOption[])

  if (messageBlockChoices.length === 0) {
    options.splice(
      options.findIndex((option) => option.label === 'Message Blocks'),
      1,
    )
  } else {
    options
      .find((option) => option.label === 'Message Blocks')
      ?.options.push(...messageBlockChoices)
  }

  return options
}

export const getCoverageTypeOptions = (): AdvancedSelectOption[] => {
  return [
    {
      display: 'adh',
      value: 'adh',
    },
    {
      display: 'base',
      value: 'base',
    },
  ]
}

export const getRuleKeySelectorValue = (
  script: ScriptItem,
): { options: AdvancedSelectOption[] } => {
  const { reply } = script
  const keyItemValue =
    reply && !getIsReplacementReply(reply) ? (reply?.messages?.[0] as DefaultMessage).content : ''
  const keyItemOptions = [{ display: keyItemValue, value: keyItemValue }]

  return {
    options: keyItemOptions,
  }
}

export const getRuleValue = (
  condition: Condition,
  script: ScriptItem,
): { value: string | string[]; options: AdvancedSelectOption[] } => {
  const conditionType = condition ? identifyCondition(condition) : null

  if (conditionType === 'nonNumericScript') {
    const scriptCondition = condition as NonNumericConditionWithScript
    return {
      options:
        (script.reply as DefaultReply).prompt?.options?.map((option) => ({
          display: option.title,
          value: option.value,
        })) || [],
      value: scriptCondition ? scriptCondition.value : '',
    }
  }

  if (conditionType === 'nonNumericComparand' && condition?.comparand === 'planHasCoverageType') {
    return {
      options: getCoverageTypeOptions(),
      value: (condition as NonNumericConditionWithComparand).value as string[],
    }
  }

  return {
    options: [],
    value: '',
  }
}
