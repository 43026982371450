import { COLOR } from '../../tokens/colors/color'
import type { ColorArgs } from './types'
import { InlineAlertColor } from './types'

export const inlineAlertColors: Record<InlineAlertColor, ColorArgs> = {
  [InlineAlertColor.blue]: {
    backgroundColor: COLOR.BLUE[100],
    borderColor: COLOR.BLUE[300],
    iconColor: COLOR.BLUE[700],
    textColor: COLOR.BLUE[800],
    buttonColor: 'blue',
  },
  [InlineAlertColor.green]: {
    backgroundColor: COLOR.GREEN[100],
    borderColor: COLOR.GREEN[300],
    iconColor: COLOR.GREEN[700],
    textColor: COLOR.GREEN[800],
    buttonColor: 'green',
  },
  [InlineAlertColor.neutral]: {
    backgroundColor: COLOR.NEUTRAL[100],
    borderColor: COLOR.NEUTRAL[300],
    iconColor: COLOR.NEUTRAL[700],
    textColor: COLOR.NEUTRAL[800],
    buttonColor: 'neutral',
  },
  [InlineAlertColor.yellow]: {
    backgroundColor: COLOR.YELLOW[100],
    borderColor: COLOR.YELLOW[300],
    iconColor: COLOR.YELLOW[700],
    textColor: COLOR.YELLOW[800],
    buttonColor: 'yellow',
  },
  [InlineAlertColor.red]: {
    backgroundColor: COLOR.RED[100],
    borderColor: COLOR.RED[300],
    iconColor: COLOR.RED[700],
    textColor: COLOR.RED[800],
    buttonColor: 'red',
  },
}
