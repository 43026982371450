import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'

const Settings: FC = () => {
  return (
    <Header>
      <Title>Settings</Title>
    </Header>
  )
}

const Header = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 32,
})

const Title = styled.h1({
  color: COLOR.EXTEND_OXFORD,
  fontSize: 32,
  lineHeight: '44px',
  marginTop: 0,
  marginBottom: 4,
})

export { Settings }
