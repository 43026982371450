import type { FC } from 'react'
import React, { useCallback, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import type { Claim } from '@helloextend/extend-api-client'
import type { CollapsibleInfoGroupProps } from '../../../../../../components/collapsible-info-group'
import { CollapsibleInfoGroup } from '../../../../../../components/collapsible-info-group'
import type { Values } from './schema'
import { getCustomerInfo, schema } from './schema'
import { useUpdateClaim } from '../../../../../../hooks/use-update-claim'

interface CustomerInfoSectionProps {
  claim: Claim
}

const CustomerInfoSection: FC<CustomerInfoSectionProps> = ({ claim }) => {
  const { updateClaim } = useUpdateClaim()
  const [isEditing, setIsEditing] = useState(false)
  const customer = claim?.customer

  const { values, errors, resetForm, submitForm, setFieldValue } = useFormik<Values>({
    enableReinitialize: true,
    initialValues: customer,
    validationSchema: schema,
    onSubmit: (body) => {
      updateClaim({
        claimId: claim.id,
        contractId: claim.contractId,
        body: { customer: body },
      })
      toggleIsEditing()
    },
  })
  const customerInfo = useMemo(
    () => getCustomerInfo(isEditing, customer, values, errors),
    [isEditing, customer, values, errors],
  )

  const handleChange = (key: string, value: any): void => {
    setFieldValue(key, value)
  }
  const handleSubmitForm = (): void => {
    /**
     * We only have 1 input field currently for the address.
     * Saving a new address from the google API doesn't support address2 so we clear it out before submitting.
     */
    setFieldValue('shippingAddress', { ...values.shippingAddress, address2: '' })
    submitForm()
  }
  const toggleIsEditing = useCallback(() => {
    setIsEditing(!isEditing)
  }, [setIsEditing, isEditing])

  return (
    <CollapsibleInfoGroup
      data-cy="customer-info-section"
      title="Customer Contact"
      data={customerInfo}
      handleChange={handleChange as CollapsibleInfoGroupProps['handleChange']}
      numColumns={3}
      hasEditButton
      isEditing={isEditing}
      onEditButtonClick={toggleIsEditing}
      primaryButtonText={isEditing ? 'Save Changes' : undefined}
      onPrimaryButtonClick={handleSubmitForm}
      secondaryButtonText={isEditing ? 'Cancel' : undefined}
      onSecondaryButtonClick={() => {
        resetForm()
        toggleIsEditing()
      }}
      buttonAlignment="right"
      isDefaultCollapsed={false}
    />
  )
}

export { CustomerInfoSection }
