import type { SyntheticEvent, FC, FormEvent } from 'react'
import React, { useState, useRef } from 'react'
import styled from '@emotion/styled'
import { TextInput, Button } from '@helloextend/merchants-ui'
import { Select, SelectItem } from '../select'

type OptionValue = {
  label: string
  value: string
}

export type SearchBarProps = {
  id: string
  placeholder?: string
  searchLabel?: string
  onSubmit: (key: string, value: string) => void
  options: OptionValue[]
  isLoading?: boolean
  initialValue?: string
  initialKey?: string
  shouldAutoSubmitOnEmpty?: boolean // auto submit search if the form is cleared
}

const SearchBar: FC<SearchBarProps> = ({
  id,
  placeholder,
  searchLabel = 'Search',
  onSubmit,
  options = [],
  isLoading,
  initialValue = '',
  initialKey,
  shouldAutoSubmitOnEmpty,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchKey, setSearchKey] = useState(initialKey ?? options[0]?.value)
  const [searchValue, setSearchValue] = useState<string>(initialValue)

  const onChangeText = (e: SyntheticEvent<HTMLInputElement>): void => {
    const currentValue = e.currentTarget.value
    setSearchValue(currentValue)
    if (shouldAutoSubmitOnEmpty && !currentValue.length) {
      onSubmit(searchKey, currentValue)
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    inputRef.current?.blur()
    onSubmit(searchKey, searchValue)
  }

  const handleChangeDropdown = (e: SyntheticEvent<Element>): void => {
    const target = e.currentTarget as HTMLInputElement
    const optionsValue = options.find((option) => option.value === target.value)
    setSearchKey(optionsValue?.value || '')
  }

  return (
    <SearchForm onSubmit={handleSubmit}>
      <SelectWrapper>
        <Select onChange={handleChangeDropdown} placeholder="" value={searchKey} inline>
          {options.map((option) => (
            <SelectItem value={option.value} label={option.label} key={option.value} />
          ))}
        </Select>
      </SelectWrapper>
      <SearchInput
        id={id}
        label=""
        placeholder={placeholder}
        onChange={onChangeText}
        value={searchValue}
        width={440}
        inline
        inputRef={inputRef}
      />
      <SearchButton
        dataCy="search-button"
        text={searchLabel}
        kind="primary"
        type="submit"
        size="sm"
        loading={isLoading}
        disabled={Boolean(!searchValue)}
      />
    </SearchForm>
  )
}

const SelectWrapper = styled.div({
  width: 216,
})

const SearchForm = styled.form({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 40,
})

const SearchButton = styled(Button)({
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  fontSize: 16,
})

const SearchInput = styled(TextInput)({
  height: 38,
})

export { SearchBar }
