import type { Action } from '../../actions'

export type LoginRedirectPathReducerState = string | null

const initialState: LoginRedirectPathReducerState = null

export default function servicerOnboardingReducer(
  state = initialState,
  action: Action,
): LoginRedirectPathReducerState {
  switch (action.type) {
    case 'SET_LOGIN_REDIRECT_PATH':
      return action.payload
    case 'CLEAR_LOGIN_REDIRECT_PATH':
      return initialState
    default:
      return state
  }
}
