import type { FC } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import { connect } from 'react-redux'
import { HeadTag } from '../components/head-tag'
import { auth } from '../actions'
import type { RootState } from '../reducers'
import images from '../images'

const LOGOUT_TIMEOUT_MS = 3500

interface DP {
  onLoad: typeof auth.logout
}

type LogoutProps = DP

const Component: FC<LogoutProps> = ({ onLoad }) => {
  useEffect(() => {
    onLoad()
    navigateToLogin()
  }, [onLoad])

  return (
    <Wrapper>
      <HeadTag />
      <main>
        <Logo src={images.extendIcon} alt="Extend" />
        <h2>See you later!</h2>
        <div>
          You have been logged out of <strong>Extend Portal</strong> and will be redirected to the
          login page in a moment.
        </div>
        <a href="/login">Send me back to login</a>
      </main>
    </Wrapper>
  )
}

/**
 * forces page refresh to reroute to login
 */
function navigateToLogin(): void {
  setTimeout(() => {
    window.location.replace('/login')
  }, LOGOUT_TIMEOUT_MS)
}

const Wrapper = styled.main({
  alignItems: 'center',
  backgroundColor: COLOR.MOSTLY_WHITE_0,
  display: 'flex',
  justifyContent: 'center',
  lineHeight: '2rem',
  minHeight: '100vh',
  textAlign: 'center',
  width: ' 100%',
  h2: {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 20,
  },
  strong: {
    fontWeight: 600,
  },
  a: {
    color: COLOR.BRIGHT_BLUE,
  },
})

const Logo = styled.img({
  maxHeight: 50,
})

const Logout = connect<any, DP, any, RootState>(null, {
  onLoad: auth.logout,
})(Component)

export { Component, Logout, LogoutProps, navigateToLogin }
