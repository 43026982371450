import { combineReducers } from 'redux'
import type { ByIdReducerState } from './by-id'
import byId from './by-id'
import error from './error'
import isLoading from './is-loading'
import isUpdating from './is-updating'
import type { ErrorReducerState } from '../../types/error'

export interface ReducerState {
  byId: ByIdReducerState
  error: ErrorReducerState
  isLoading: boolean
  isUpdating: boolean
}

export default combineReducers({
  byId,
  error,
  isLoading,
  isUpdating,
})
