import type { FC } from 'react'
import React from 'react'
import { Button } from '@helloextend/merchants-ui'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import type { FormikHelpers, FormikProps } from 'formik'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'

import { auth } from '../../../../actions'
import * as selectors from '../../../../reducers/selectors'
import PasswordInput from '../../../../components/password/index'
import type { RootState } from '../../../../reducers'
import { PasswordIndicator } from '../../../../components/password-indicator'
import { validateField } from '../../../../utils/validate'

const schema = Yup.object()
  .shape({
    currentPassword: Yup.string().required('Current password required'),
    newPassword: Yup.string()
      .test('password', 'Password does not meet criteria', (value) =>
        validateField('password', value || ''),
      )
      .required('New password required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Password does not match')
      .required('Confirm new password'),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

interface SP {
  accessToken: ReturnType<typeof selectors.getAccessToken>
  isLoading: ReturnType<typeof selectors.getIsAuthLoading>
  resultMessage: ReturnType<typeof selectors.getChangePasswordMessage>
}

interface DP {
  onChangePassword: typeof auth.changePassword
}

interface OP {
  isSubmitting: boolean
}

type PasswordChangeFormProps = SP & DP & OP

const FormikComponent: FC<PasswordChangeFormProps> = ({
  accessToken,
  onChangePassword,
  isSubmitting,
  resultMessage,
  isLoading,
}) => {
  const onSubmit = (values: Values, helpers: FormikHelpers<Values>): void => {
    if (accessToken) {
      onChangePassword(values.currentPassword, values.newPassword, accessToken)
    }
    helpers.resetForm()
  }

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      }}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {Component({ accessToken, onChangePassword, isSubmitting, resultMessage, isLoading })}
    </Formik>
  )
}

type ComponentType = (
  props: Omit<PasswordChangeFormProps, 'onSubmitChangePassword'>,
) => (props: FormikProps<Values>) => JSX.Element

const Component: ComponentType =
  ({ isSubmitting, resultMessage, isLoading }) =>
  ({ errors, touched, values, handleChange, handleBlur, handleReset, handleSubmit }) => {
    const areButtonsDisabled =
      isSubmitting ||
      isLoading ||
      Object.values(touched).every((value) => !value) ||
      Boolean(Object.values(errors).find(Boolean))

    return (
      <PasswordChangeFormWrapper onSubmit={handleSubmit}>
        <PasswordInputWrapper>
          <PasswordInput
            data-cy="password-change-current-password"
            label="Current password"
            name="currentPassword"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.currentPassword}
            errorMessage={errors.currentPassword}
            invalid={Boolean(touched.currentPassword && errors.currentPassword)}
          />
        </PasswordInputWrapper>
        <PasswordInputWrapper>
          <PasswordInput
            data-cy="password-change-new-password"
            label="New password"
            name="newPassword"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.newPassword}
            errorMessage={errors.newPassword}
            invalid={Boolean(touched.newPassword && errors.newPassword)}
          />
          <PasswordIndicator
            word={values.newPassword}
            isVisible={Boolean(touched.newPassword && values.newPassword)}
          />
        </PasswordInputWrapper>
        <PasswordInputWrapper>
          <PasswordInput
            data-cy="password-change-confirm-password"
            label="Confirm password"
            name="confirmPassword"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirmPassword}
            errorMessage={errors.confirmPassword}
            invalid={Boolean(touched.confirmPassword && errors.confirmPassword)}
          />
        </PasswordInputWrapper>
        <ButtonGroup>
          <ButtonWrapper>
            <Button
              dataCy="password-change-save-button"
              type="submit"
              text="Save"
              size="sm"
              loading={isSubmitting || isLoading}
              disabled={areButtonsDisabled}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              dataCy="password-change-cancel-button"
              type="reset"
              text="Cancel"
              kind="secondary"
              size="sm"
              onClick={handleReset}
              disabled={areButtonsDisabled}
            />
          </ButtonWrapper>
        </ButtonGroup>
        {resultMessage && (
          <PasswordUpdatedConfirmation>{resultMessage}</PasswordUpdatedConfirmation>
        )}
      </PasswordChangeFormWrapper>
    )
  }

const PasswordInputWrapper = styled.div({ width: '45%', paddingRight: 32, paddingBottom: 12 })

const ButtonGroup = styled.div({
  display: 'flex',
})

const ButtonWrapper = styled.div({
  paddingRight: 24,
  paddingTop: 24,
})

const PasswordChangeFormWrapper = styled.form({
  display: 'flex',
  width: '70%',
  flexWrap: 'wrap',
  flexDirection: 'column',
})

const PasswordUpdatedConfirmation = styled.p({
  color: COLOR.LIME_GREEN,
  fontSize: 16,
  margin: '16px 0',
})

const PasswordChangeForm = connect(
  (state: RootState) => ({
    accessToken: selectors.getAccessToken(state),
    isLoading: selectors.getIsAuthLoading(state),
    resultMessage: selectors.getChangePasswordMessage(state),
  }),
  {
    onChangePassword: auth.changePassword,
  },
)(FormikComponent)

export { Component, PasswordChangeForm, Values }
