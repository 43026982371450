import type { FC } from 'react'
import React, { useState, useCallback, useEffect } from 'react'
import styled from '@emotion/styled'
import {
  Box,
  Grid,
  DataProperty,
  Stack,
  Button,
  COLOR,
  OpenInNew,
  useToaster,
  ToastColor,
  ToastDuration,
} from '@helloextend/zen'
import { useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { StackAlign, StackDirection } from '@helloextend/zen/src/components/stack'
import { useLazyGetUserGrantsListQuery } from '@helloextend/extend-api-rtk-query'
import { getUserRolesFromGrants } from '../../../utils/user-role-mapper'
import * as selectors from '../../../reducers/selectors'
import type { RootState } from '../../../reducers'
import DashboardLayout from '../../../hoc/dashboard-layout'
import { DashboardSection } from '../../../hoc/dashboard-layout/dashboard-layout'
import { LDFlag } from '../../../constants/ld-flags'
import { LogoutNotificationModal } from './modals/logout-notification-modal'
import { LegacyDashboardProfilePage } from './legacy/legacy-profile-page'

const DashboardProfilePage: FC = () => {
  const flags = useFlags()
  const { toast } = useToaster()

  const userData = useSelector((state: RootState) => selectors.getUserInformation(state))

  const [showLogoutNotificationModal, setShowLogoutNotificationModal] = useState<boolean>(false)
  const [getGrants, { data: userGrants, isLoading }] = useLazyGetUserGrantsListQuery()

  const handleEditButtonClick = (): void => {
    // AO -1580 move this redirect url to environment variables
    window.open('https://extend.okta.com/enduser/settings', '_blank')
    setShowLogoutNotificationModal(true)
  }

  const closeModal = useCallback(() => {
    setShowLogoutNotificationModal(false)
  }, [])

  const editButtonHelperText =
    'Any profile updates made in Okta will only show in the Extend portal the next time you log in.'

  const getUsergrants = useCallback(async () => {
    const grantsResponse = await getGrants(userData.email)
    if ('error' in grantsResponse) {
      toast({
        message: 'Error getting user roles',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [getGrants, toast, userData.email])

  useEffect(() => {
    if (flags[LDFlag.OktaLogin]) {
      getUsergrants()
    }
  }, [flags, getUsergrants])

  return (
    <>
      {flags[LDFlag.OktaLogin] ? (
        <DashboardLayout section={DashboardSection.Admin}>
          <ProfileHeadingContainer>
            <ProfileNameContainer data-cy="profile-name-container">
              {userData.firstName} {userData.lastName}&apos;s Profile
            </ProfileNameContainer>
            <Button
              color="blue"
              emphasis="medium"
              icon={OpenInNew}
              size="regular"
              text="Edit"
              data-cy="edit-button"
              onClick={handleEditButtonClick}
              tooltip={editButtonHelperText}
            />
          </ProfileHeadingContainer>
          <Box padding={4}>
            <Stack align={StackAlign.start} direction={StackDirection.row} spacing={3}>
              <DetailsText>Basic Info</DetailsText>
            </Stack>

            <Stack align={StackAlign.stretch} direction={StackDirection.column} spacing={3}>
              <Grid
                columns={{
                  lg: 3,
                  md: 2,
                  sm: 1,
                }}
                spacing={{
                  lg: 3,
                  md: 2,
                  sm: 1,
                }}
              >
                <DataProperty label="First Name" value={userData?.firstName} data-cy="first-name" />
                <DataProperty label="Last Name" value={userData?.lastName} data-cy="last-name" />
                <DataProperty label="Email" value={userData?.email} data-cy="email" />
                <DataProperty
                  label="Role(s)"
                  value={getUserRolesFromGrants(userGrants?.grants ?? [], 'internal')
                    .map((role) => role.display)
                    .join(', ')}
                  data-cy="roles"
                  isLoading={isLoading}
                />
              </Grid>
            </Stack>
          </Box>
          <LogoutNotificationModal isVisible={showLogoutNotificationModal} toggleOff={closeModal} />
        </DashboardLayout>
      ) : (
        <LegacyDashboardProfilePage />
      )}
    </>
  )
}

const ProfileHeadingContainer = styled.div({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1em',
})

const ProfileNameContainer = styled.p({
  margin: 0,
  fontFamily: 'Nunito Sans',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '24px',
  lineHeight: '32px',
  color: COLOR.NEUTRAL[1000],
})

const DetailsText = styled.p({
  width: '90%',
  minWidth: '50%',
  marginTop: '8px',
  fontFamily: 'Nunito Sans',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '20px',
  lineHeight: '28px',
  color: COLOR.BLUE[1000],
})

export { DashboardProfilePage }
