import type { FC, SyntheticEvent } from 'react'
import React, { useState } from 'react'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import { Button, Checkbox, COLOR } from '@helloextend/zen'
import { useHistory } from 'react-router-dom'
import { date } from '@helloextend/client-utils'
import type { FormikMapperConfig } from '../../../components/form-text-group'
import { CollapsibleFormTextGroup, formikMapper } from '../../../components/form-text-group'
import {
  billingInformationFields,
  contractInformationFields,
  customerFields,
  productInformationFields,
  sectionTitles,
  shippingInformationFields,
  storeInformationFields,
} from './fields'
import type { V1Values } from './schema'
import { V1Schema } from './schema'
import type { InputItem } from '../../../components/form-text-group/types'
import { useContractsCreate } from './use-contracts-create'

const ContractsCreate: FC = () => {
  const history = useHistory()

  const [isCopyShipping, setCopyShipping] = useState(false)
  const [shouldValidateOnChange, setShouldValidateOnChange] = useState(false)

  const {
    values,
    errors,
    touched,
    validateForm,
    handleChange,
    handleBlur,
    setFieldValue,
    setValues,
    setFieldError,
  } = useFormik<V1Values>({
    validationSchema: V1Schema,
    validateOnChange: shouldValidateOnChange,
    validateOnBlur: false,
    initialValues: {
      ...V1Schema.default(),
      currencyCode: 'USD',
      quantity: '1' as unknown as number, // DropdownOption `value` type is a string
      transactionDate: date.format(Date.now(), 'MMM DD YYYY'),
      productPurchaseDate: date.format(Date.now(), 'MMM DD YYYY'),
    },
    onSubmit: (): void => {
      setShouldValidateOnChange(true)
    },
  })

  const { isCreating, createContract } = useContractsCreate({ values, validateForm, setFieldError })

  const setFormValues = (formFields: FormikMapperConfig[]): InputItem[] => {
    return formikMapper<V1Values>(values, touched, errors, formFields)
  }

  const handleCancel = (): void => {
    history.push('/admin/contracts')
  }

  const handleShippingAddressChange = (e: SyntheticEvent | string): void => {
    const { id, value } = (e as SyntheticEvent).currentTarget as HTMLInputElement
    handleChange(e)
    if (isCopyShipping && id && value) {
      setFieldValue(id.replace('shipping', 'billing'), value)
    }
  }

  const handleCopyChange = (e: SyntheticEvent<HTMLInputElement>): void => {
    if (e.currentTarget.checked) {
      setValues({
        ...values,
        billingAddress: values.shippingAddress,
        billingAddressTwo: values.shippingAddressTwo,
        billingCity: values.shippingCity,
        billingProvinceCode: values.shippingProvinceCode,
        billingPostalCode: values.shippingPostalCode,
        billingCountryCode: values.shippingCountryCode,
      })
    }
    setCopyShipping(e.currentTarget.checked)
  }

  return (
    <>
      <Header>
        <Title>Create New Contract</Title>
        <ButtonWrapper>
          <Button
            emphasis="medium"
            text="Cancel"
            onClick={handleCancel}
            isDisabled={isCreating}
            data-cy="cancel-create-contract-btn-top"
          />
          <Button
            text="Save"
            type="submit"
            onClick={createContract}
            isProcessing={isCreating}
            isDisabled={isCreating}
            data-cy="create-contract-btn-top"
          />
        </ButtonWrapper>
      </Header>
      <Container data-cy="contracts-create">
        <LeftContainer>
          <Box>
            <h2>{sectionTitles.customerInformation}</h2>
            <NestedFormGroup
              handleChange={handleChange}
              handleBlur={handleBlur}
              isDisabled={isCreating}
              values={setFormValues(customerFields)}
              numColumns={3}
            />
            <NestedFormGroup
              handleChange={handleShippingAddressChange}
              handleBlur={handleBlur}
              isDisabled={isCreating}
              values={setFormValues(shippingInformationFields)}
              numColumns={4}
              subsectionTitle={sectionTitles.shippingInformation}
            />
            <Checkbox
              label="Billing Information is the same as Shipping Address"
              checked={isCopyShipping}
              onChange={handleCopyChange}
            />
            <NestedFormGroup
              handleChange={handleChange}
              handleBlur={handleBlur}
              isDisabled={isCreating}
              values={setFormValues(billingInformationFields)}
              numColumns={4}
              subsectionTitle={sectionTitles.billingInformation}
            />
          </Box>
          <Box>
            <h2>{sectionTitles.storeInformation}</h2>
            <NestedFormGroup
              handleChange={handleChange}
              handleBlur={handleBlur}
              isDisabled={isCreating}
              numColumns={2}
              values={setFormValues(storeInformationFields)}
            />
          </Box>
          <Box>
            <h2>{sectionTitles.contractInformation}</h2>
            <NestedFormGroup
              handleChange={handleChange}
              handleBlur={handleBlur}
              isDisabled={isCreating}
              numColumns={6}
              values={setFormValues(contractInformationFields)}
              setFieldValue={setFieldValue}
            />
          </Box>
          <Box>
            <h2>{sectionTitles.productInformation}</h2>
            <NestedFormGroup
              handleChange={handleChange}
              handleBlur={handleBlur}
              isDisabled={isCreating}
              numColumns={4}
              values={setFormValues(productInformationFields)}
              setFieldValue={setFieldValue}
            />
          </Box>
          <ButtonWrapper>
            <Button
              emphasis="medium"
              text="Cancel"
              onClick={handleCancel}
              isDisabled={isCreating}
              data-cy="cancel-create-contract-btn"
            />
            <Button
              text="Save"
              type="submit"
              onClick={createContract}
              isProcessing={isCreating}
              isDisabled={isCreating}
              data-cy="create-contract-btn"
            />
          </ButtonWrapper>
        </LeftContainer>
      </Container>
    </>
  )
}

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const Title = styled.h1({
  fontSize: 32,
  lineHeight: '44px',
  fontWeight: 700,
  marginTop: 0,
})

const Box = styled.div({
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  marginBottom: 32,
  padding: 40,
  display: 'flex',
  flexDirection: 'column',
  rowGap: 32,
})

const Container = styled.div({
  display: 'flex',
  gap: 40,
  alignItems: 'flex-start',
})

const LeftContainer = styled.div({
  width: '100%',
})

const NestedFormGroup = styled(CollapsibleFormTextGroup)({
  border: 'none',
  padding: 0,
  margin: 0,
})

const ButtonWrapper = styled.div({
  display: 'flex',
  gap: 16,
  justifyContent: 'flex-end',
})

export { ContractsCreate }
