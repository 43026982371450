import * as Yup from 'yup'
import { urlRegExp } from '@helloextend/client-utils/validate'

const schema = Yup.object()
  .shape({
    url: Yup.string().notRequired().matches(urlRegExp, 'Please enter a valid URL'),
    parts: Yup.number().notRequired(),
    labor: Yup.number().notRequired(),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export { schema, Values }
