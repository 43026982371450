import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Popover, MenuButtonItem, usePopover, Button } from '@helloextend/zen'
import { MessageBlock } from '@helloextend/zen/src/tokens/icons'
import type { ScriptItem } from '@helloextend/extend-api-rtk-query'
import { batch, useSelector, useDispatch } from 'react-redux'
import type { RootState } from '../../../../../reducers'
import * as selectors from '../../../../../reducers/selectors'
import {
  addMessageBlockToThread,
  setSelectedMessageBlock,
  setIsEditorPanelVisible,
} from '../../../../../store/slices/amp-slice'
import { getEmptyBlockByType } from '../../message-blocks/utils'
import { MessageBlockTypes } from '../../utils'

type ThreadAppendButtonsProps = {
  'data-cy': string
}

const ThreadAppendButtons: FC<ThreadAppendButtonsProps> = ({ 'data-cy': dataCy }) => {
  const dispatch = useDispatch()

  const { isPresent, triggerRef, popoverRef, triggerBoundingBox, toggle } =
    usePopover<HTMLButtonElement>()

  const selectedMessageBlockIndex = useSelector((state: RootState) =>
    selectors.getSelectedMessageBlockIndex(state),
  )

  const selectedThread = useSelector((state: RootState) => selectors.getSelectedThread(state))

  const handleClickItem = async (e: SyntheticEvent): Promise<void> => {
    const ele = e?.target as HTMLElement
    const blockType = ele.textContent as MessageBlockTypes
    const block = getEmptyBlockByType(blockType)
    if (!block) return
    toggle()
    handleAddBlockToThread(block)
  }

  const handleAddBlockToThread = (block: ScriptItem): void => {
    const lastMessageBlockIndex =
      selectedThread && selectedThread.script.length !== 0 ? selectedThread.script.length : 0

    batch(() => {
      dispatch(addMessageBlockToThread({ scriptItem: block }))
      dispatch(
        setSelectedMessageBlock(
          selectedMessageBlockIndex !== null
            ? selectedMessageBlockIndex + 1
            : lastMessageBlockIndex,
        ),
      )
      dispatch(setIsEditorPanelVisible(true))
    })
  }

  const handleAddMessageBlock = (): void => {
    toggle()
  }

  return (
    <Container>
      <Button
        ref={triggerRef}
        text="Add Message Block"
        size="small"
        emphasis="medium"
        icon={MessageBlock}
        onClick={handleAddMessageBlock}
        data-cy={dataCy}
      />
      <Popover ref={popoverRef} isPresent={isPresent} triggerBoundingBox={triggerBoundingBox}>
        <MenuItemWrapper>
          {Object.values(MessageBlockTypes).map((blockType) => (
            <MenuButtonItem
              key={blockType}
              onClick={handleClickItem}
              data-cy={`${blockType.toLowerCase().replace(' ', '-')}:popover-item`}
            >
              <Text>{blockType}</Text>
            </MenuButtonItem>
          ))}
        </MenuItemWrapper>
      </Popover>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  gap: 8,
  marginTop: 12,
  marginLeft: 44,
})

const MenuItemWrapper = styled.div({
  width: 184,
  display: 'flex',
  flexDirection: 'column',
  padding: 8,
})

const Text = styled.p({
  fontWeight: 400,
  size: 14,
  lineHeight: '20px',
  padding: 0,
  margin: 0,
})

export { ThreadAppendButtons, ThreadAppendButtonsProps }
