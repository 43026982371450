import type { MeasurementArgs, HeadingSize } from './accordion-section-types'

export const accordionMeasurements: Record<HeadingSize, MeasurementArgs> = {
  large: {
    bodyPaddingTop: 24,
    badgePaddingY: 4,
  },
  small: {
    headingPadding: '2px 0',
    bodyPaddingTop: 20,
    badgePaddingY: 2,
  },
  sub: {
    headingPadding: '7px 0',
    bodyPaddingTop: 16,
    badgeSize: 'small',
    badgeMarginY: -2,
  },
}
