import { servicersActions } from '@helloextend/core-api-redux'

// Search filter
export const searchFilterSet = (val: string) =>
  ({
    type: 'SERVICERS_SEARCH_FILTER_SET',
    payload: val,
  } as const)

export const searchFilterClear = () =>
  ({
    type: 'SERVICERS_SEARCH_FILTER_CLEAR',
  } as const)

export const searchDropdownSet = (val: string) =>
  ({
    type: 'SERVICERS_SEARCH_DROPDOWN_FILTER_SET',
    payload: val,
  } as const)

export const searchDropdownClear = () =>
  ({
    type: 'SERVICERS_SEARCH_DROPDOWN_FILTER_CLEAR',
  } as const)

export const { create, createRequest, createSuccess, createFailure, errorReset } = servicersActions
