import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@helloextend/client-utils/breakpoints'
import { Button } from '@helloextend/zen'
import { Location } from '../../lib/window-location'

import * as pkceOauth from '../../lib/pkce-oauth'

interface PkceLoginFormParams {
  isLoading?: boolean
}

const OktaLoginForm: FC<PkceLoginFormParams> = ({ isLoading = false }) => {
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    const oktaRedirect = await pkceOauth.getOktaRedirectUrl()
    Location.assign(oktaRedirect)
  }

  return (
    <Wrapper>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Button
          size="regular"
          isProcessing={isLoading}
          type="submit"
          fillContainer
          text="Log in"
          data-cy="login-button"
        />
      </Form>
    </Wrapper>
  )
}

export const Form = styled.form({
  margin: '0 auto',
  maxWidth: 400,
})

const Wrapper = styled.div({
  width: 355,
  marginTop: 48,
  [bp.md]: {
    maxHeight: 'none',
  },
})

export { OktaLoginForm }
