import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { useFlags } from 'launchdarkly-react-client-sdk'
import type { Money, ServiceOrder, ServiceOrderShipment } from '@helloextend/extend-api-client'
import { useListServiceOrderShipmentsQuery } from '@helloextend/extend-api-rtk-query'
import { CollapsibleInfoGroup } from '../../../../../../../../components/collapsible-info-group'
import { getShippingInfoData } from './schema'
import { ShippingLabelForm, ActiveServiceOrderSection } from './components'
import { LDFlag } from '../../../../../../../../constants/ld-flags'
import { ExpandedServiceOrderSection } from './components/expanded-service-order-section'

interface ServiceOrderTabProps {
  serviceOrders?: ServiceOrder[] | undefined
  entitlements?: { coverageAmountRemaining: Money }
}

const ServiceOrderTab: FC<ServiceOrderTabProps> = ({ serviceOrders, entitlements }) => {
  const { [LDFlag.ExpandedServiceOrdersSection]: FF_EXPANDED_SO_SECTION } = useFlags()

  const { data: shipments } = useListServiceOrderShipmentsQuery(serviceOrders?.[0].id ?? '', {
    // will need a better refactoring solution for supporting shipments for multiple service orders
    skip: !serviceOrders?.[0],
  })

  const failedProductShipment = useMemo(() => {
    return (
      shipments?.find(({ destinationType }) => destinationType === 'servicer') ||
      ({} as ServiceOrderShipment)
    )
  }, [shipments])
  const fulfilledProductShipment = shipments?.find(
    ({ destinationType }) => destinationType === 'customer',
  )

  const shippingInfo = useMemo(
    () => getShippingInfoData(failedProductShipment, serviceOrders?.[0], fulfilledProductShipment),
    [failedProductShipment, serviceOrders, fulfilledProductShipment],
  )

  return (
    <ContentWrapper>
      {FF_EXPANDED_SO_SECTION ? (
        <ExpandedServiceOrdersWrapper>
          {serviceOrders?.map((serviceOrder, i) => (
            <ExpandedServiceOrderSection
              serviceOrder={serviceOrder}
              key={serviceOrder.id}
              data-cy={`so-${serviceOrder.id}`}
              isActive={i === 0}
              entitlements={entitlements}
            />
          ))}
        </ExpandedServiceOrdersWrapper>
      ) : (
        <>
          <LeftContainer>
            {serviceOrders?.map((serviceOrder) => (
              <ActiveServiceOrderSection
                key={serviceOrder.createdAt}
                data-cy={serviceOrder.createdAt}
                serviceOrder={serviceOrder}
                entitlements={entitlements}
              />
            ))}
          </LeftContainer>
          <RightContainer>
            <CollapsibleInfoGroup
              title="Shipping Information"
              data-cy="shipping-information-section"
              data={shippingInfo}
              isDefaultCollapsed={false}
              handleChange={() => {}}
            >
              {Boolean(!fulfilledProductShipment) &&
                serviceOrders?.[0]?.configurations?.hasCustomReturnLabel && (
                  <ShippingLabelForm serviceOrderId={serviceOrders?.[0].id} />
                )}
            </CollapsibleInfoGroup>
          </RightContainer>
        </>
      )}
    </ContentWrapper>
  )
}

const RightContainer = styled.div({
  width: '30%',
})

const LeftContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '70%',
})

const ExpandedServiceOrdersWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 15,
})

const ContentWrapper = styled.div({
  display: 'flex',
  gap: 20,
  alignItems: 'flex-start',
})

export { ServiceOrderTab, ServiceOrderTabProps }
