export interface PlanTermsVersion {
  createdAt: number
  termsId: string
  version: string
  updatedAt: number
}

export interface PlanTermsType {
  termsId: string
  createdAt: number
  description: string
  versions: PlanTermsVersion[]
}

export interface PlanTermsListResponse {
  limit: number
  nextPageCursor?: string
  planTerms: PlanTermsType[]
}
export interface PlanTermsLanguage {
  createdAt: number
  language: string
  objectKey: string
  status: PlanTermsLanguageStatus
  termsId: string
  version: string
}

export enum PlanTermsLanguageStatus {
  UPLOADING = 'uploading',
  SAVED = 'saved',
}

export interface PlanTermsGetResponse {
  url: string
}
