import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import { PasswordChangeForm } from './password-change-form'

interface PasswordSectionProps {
  isLoading: boolean
  resultMessage: string | null
  'data-cy'?: string
}

const PasswordChangeSection: FC<PasswordSectionProps> = ({ isLoading, resultMessage }) => {
  return (
    <PasswordChangeSectionWrapper
      data-cy="password-change-section"
      hasResult={Boolean(resultMessage)}
    >
      <HeaderGroup>
        <PasswordHeader data-cy="password-header-text">Password</PasswordHeader>
        <p data-cy="password-header-support-text">
          Change the password you use to log in to Extend
        </p>
      </HeaderGroup>
      <PasswordChangeForm isSubmitting={isLoading} data-cy="password-change-form" />
    </PasswordChangeSectionWrapper>
  )
}

const PasswordHeader = styled.h2({
  margin: 0,
  color: COLOR.VERY_DARK_BLUE_0,
})

const HeaderGroup = styled.div({
  width: '25%',
  paddingLeft: 12,
})

interface PasswordChangeSectionWrapperProps {
  hasResult: boolean
}

const PasswordChangeSectionWrapper = styled.section<PasswordChangeSectionWrapperProps>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-content: space-between;
  padding: ${(props) => (props.hasResult ? '40px 40px 0 40px' : '40px')};
  border: 1px solid ${COLOR.GRAYISH_BLUE_0};
  margin-bottom: 32px;
`

export { PasswordChangeSection }
