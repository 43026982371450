import * as selectors from './selectors'
import reducer from './reducers'
import sagas from './sagas'
import { actions } from './actions'

export type { ActionType as ClaimsActionType, Action as ClaimsAction } from './actions'
export type { ReducerState as ClaimsReducerState } from './reducers'

export {
  actions as claimsActions,
  selectors as claimsSelectors,
  reducer as claimsReducer,
  sagas as claimsSagas,
}
