import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@helloextend/client-branding'

type UsersIconProps = {
  fill?: string
}

/**
 * @deprecated Use Zen Icon component instead: `import { Icon } from '@helloextend/zen'`
 */
const UsersIcon: FC<UsersIconProps> = ({ fill = COLOR.BLACK_MUTED }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 10">
    <path
      d="M6.427 3.788A1.894 1.894 0 106.424 0a1.894 1.894 0 00.003 3.788zm0-3.065a1.172 1.172 0 11-1.084.721A1.172 1.172 0 016.427.718v.005zM10.254 5.513a2.333 2.333 0 00-1 .214A3.277 3.277 0 006.427 4.23a3.277 3.277 0 00-2.83 1.497 2.334 2.334 0 00-1.001-.214C1.016 5.513 0 6.899 0 9.043a.36.36 0 00.721 0c0-1.357.492-2.809 1.875-2.809.225-.002.447.042.654.13a6.132 6.132 0 00-.469 2.474.36.36 0 00.722 0c0-1.877.768-3.883 2.923-3.883 2.154 0 2.922 2.002 2.922 3.883a.36.36 0 00.721 0 6.133 6.133 0 00-.468-2.475c.207-.087.429-.131.653-.129 1.383 0 1.876 1.452 1.876 2.81a.36.36 0 10.721 0c0-2.145-1.013-3.531-2.597-3.531zM10.254 5.037a1.405 1.405 0 100-2.81 1.405 1.405 0 000 2.81zm0-2.087a.683.683 0 11-.632.42.683.683 0 01.632-.424v.004zM2.596 5.037a1.404 1.404 0 100-2.809 1.404 1.404 0 000 2.809zm0-2.087a.683.683 0 11-.484.198.684.684 0 01.484-.202v.004z"
      fill={fill}
    />
  </svg>
)

export { UsersIcon }
