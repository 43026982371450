import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import type { Keyframes, LoaderProps } from './types'

const shimmer = (width: string): Keyframes =>
  keyframes({
    '0%': {
      backgroundPosition: `-${width}`,
    },
    '100%': {
      backgroundPosition: width,
    },
  })

export const Loader = styled.div<LoaderProps>(({ width, height, margin }) => ({
  animation: `2.0s ${shimmer(width)} linear forwards infinite`,
  background: `linear-gradient(-90deg,rgb(247,249,249) 0%,rgb(236, 236, 236),rgb(247,249,249) 100%)`,
  display: 'block',
  flex: '1 0',
  height: `${height || '18px'}`,
  margin: `${margin || '0'}`,
  maxHeight: `${height || '18px'}`,
  width: `${width || '100%'}`,
  maxWidth: `${width || '100%'}`,
}))
