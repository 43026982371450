import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../constants/ld-flags'
import type { RootState } from '../reducers'
import * as selectors from '../reducers/selectors'
import type { Permission } from '../lib/permissions'
import { getPermissions } from '../lib/permissions'

const usePermissions = (): {
  permissions: Permission[]
  hasPermission: (permission: Permission) => boolean
} => {
  const flags = useFlags()
  const userRole = useSelector((state: RootState) =>
    flags[LDFlag.OktaLogin] ? state.user.profile.role : selectors.getRoleFromToken(state),
  )

  const permissions = useMemo(() => getPermissions(userRole ?? null, flags), [userRole, flags])

  const hasPermission = useMemo(
    () =>
      (permission: Permission): boolean => {
        return permissions.includes(permission)
      },
    [permissions],
  )

  return { permissions, hasPermission }
}

export { usePermissions }
