import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { currency, date } from '@helloextend/client-utils'
import { COLOR } from '@helloextend/zen'
import type { ServiceOrder } from '@helloextend/extend-api-client'

interface EstimateInfoBannerProps {
  serviceOrder: ServiceOrder
  expenseTotal: number
}

const EstimateInfoBanner: FC<EstimateInfoBannerProps> = ({ serviceOrder, expenseTotal }) => {
  const data = [
    {
      header: 'Amount Requested',
      text: currency.format(expenseTotal),
    },
    {
      header: 'Amount Approved',
      text: currency.format(serviceOrder.status === 'payment_approved' ? expenseTotal : 0),
    },
    {
      header: 'Last Modified',
      text: date.format(serviceOrder.updatedAt, 'MM/DD/YYYY'),
    },
  ]

  return (
    <Banner>
      {data.map((info) => (
        <Info key={info.header}>
          <Header>{info.header}</Header>
          {info.text}
        </Info>
      ))}
    </Banner>
  )
}

const Banner = styled.div({
  display: 'flex',
  gap: 40,
  alignItems: 'center',
  margin: '16px 0',
  backgroundColor: COLOR.BLUE[100],
  borderRadius: 6,
  padding: 15,
  border: `1px solid ${COLOR.BLUE[300]}`,
})

const Info = styled.div({
  display: 'flex',
  gap: 5,
  flexDirection: 'column',
  fontSize: 16,
})

const Header = styled.div({
  fontSize: 14,
  fontWeight: 700,
})

export { EstimateInfoBanner }
