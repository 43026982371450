import React from 'react'
import type { PlanTermsType, PlanTermsVersion } from '@helloextend/extend-api-client'
import type { CellContext, ColumnDefs } from '@helloextend/zen'
import { format } from '@helloextend/zen/src/utils/date'

const findLatestVersion = (versions: PlanTermsVersion[]): PlanTermsVersion => {
  const versionsCopy = [...versions]
  return versionsCopy.sort((a, b) => Number(b.version) - Number(a.version))?.[0]
}

const tableColumns: ColumnDefs<PlanTermsType> = [
  {
    id: 'termsId',
    label: 'Terms ID',
    search: 'explicit',
    isSortable: true,
  },
  {
    id: 'description',
    label: 'Description',
    search: 'explicit',
    isSortable: true,
  },
  {
    id: 'versions',
    label: 'Version',
    renderCell: (data: CellContext<PlanTermsType, string>) => {
      const { versions } = data.row.original
      return <span>{findLatestVersion(versions).version}</span>
    },
  },
  {
    id: 'updatedAt',
    label: 'Last Updated',
    isSortable: true,
    renderCell: (data: CellContext<PlanTermsType, string>) => {
      const { versions } = data.row.original
      const latestVersionUpdatedAt = findLatestVersion(versions).updatedAt
      return <span>{format(latestVersionUpdatedAt, 'MMM DD YYYY')}</span>
    },
  },
]

export { tableColumns, findLatestVersion }
