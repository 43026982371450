import type { ApiResponse } from '../../../extend-api-fetch'
import { get, put } from '../../../extend-api-fetch'
import type { AccessToken, Account } from '../models'

export async function fetchAll(accessToken: AccessToken): Promise<ApiResponse<Account[]>> {
  return get('/auth/accounts', { accessToken })
}

export async function update(
  accountId: string,
  body: DeepPartial<Account>,
  accessToken: AccessToken,
): Promise<ApiResponse> {
  return put(`/auth/accounts/${accountId}`, { accessToken, body })
}
