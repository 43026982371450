import styled from '@emotion/styled'
import type { User, UserGrant } from '@helloextend/extend-api-rtk-query'
import {
  useCreateUserGrantMutation,
  useRevokeUserGrantMutation,
} from '@helloextend/extend-api-rtk-query'
import {
  COLOR,
  Spinner,
  Stack,
  Switch,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@helloextend/zen'
import { StackDirection } from '@helloextend/zen/src/components/stack'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { isAccountAdmin } from '../../../../../../lib/tenancy-ern'

type AdminToggleProps = {
  accountId: string
  user: User
  userGrants: UserGrant[]
  refetch: () => void
}

const AdminToggle: FC<AdminToggleProps> = ({ accountId, user, userGrants, refetch }) => {
  const { toast } = useToaster()

  const [addGrant, { isLoading: isAddingGrant, isError: errorAddingGrant }] =
    useCreateUserGrantMutation()
  const [removeGrant, { isLoading: isRemovingGrant, isError: errorRemovingGrant }] =
    useRevokeUserGrantMutation()

  const handleChange = (add: boolean, userEmail: string): void => {
    if (add) {
      addGrant({
        userId: userEmail,
        ern: `ERN:ACC:${accountId}`,
        role: 'user', // switch back to `MerchantAdmin` after we go live with enterprise roles,
      })
    } else {
      removeGrant({
        userId: userEmail,
        ern: `ERN:ACC:${accountId}`,
        role: 'user', // switch back to `MerchantAdmin` after we go live with enterprise roles,
      })
    }
    refetch()
  }

  const isAdmin = Boolean(userGrants && isAccountAdmin(userGrants, accountId))
  const isLoadingGrantsChange = isAddingGrant || isRemovingGrant

  useEffect(() => {
    if (errorAddingGrant || errorRemovingGrant) {
      toast({
        message: 'Unable to add or revoke grant',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [errorAddingGrant, errorRemovingGrant, toast])

  return (
    <Stack direction={StackDirection.column}>
      <Stack doesWrap>
        <DetailName>Merchant Admin:</DetailName>
        <DetailValue>
          <HeaderContainer>
            <Switch
              id="merchant-admin-switch"
              isOn={isAdmin}
              data-cy="merchant-admin-switch"
              onChange={() => handleChange(!isAdmin, user.email)}
              isDisabled={isLoadingGrantsChange}
            />
            {isLoadingGrantsChange ? <Spinner /> : null}
          </HeaderContainer>
        </DetailValue>
      </Stack>
    </Stack>
  )
}

const HeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const DetailName = styled.span({
  fontSize: 16,
  fontWeight: 'bold',
  color: COLOR.NEUTRAL[600],
  marginRight: 4,
  width: 135,
})

const DetailValue = styled.span({
  marginBottom: 32,
})

export default AdminToggle
