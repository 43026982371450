import { baseQuery } from '@helloextend/extend-api-rtk-query'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import type { ApiVersion, InstoreUserUploadGetS3UrlResponse } from './types'
import { ApiVersions } from './types'

const version: ApiVersion = ApiVersions[20220201]

export const inStoreApi = createApi({
  baseQuery,
  reducerPath: 'inStore',
  tagTypes: ['instore'],
  endpoints: (build) => ({
    getUserUploadS3Url: build.query<
      InstoreUserUploadGetS3UrlResponse,
      { accountId: string; originalFilename?: string }
    >({
      query: ({ accountId, originalFilename }) => ({
        url: `instore/users/${accountId}/import`,
        params: { originalFilename },
        headers: {
          accept: `application/json; version=${version};`,
        },
      }),
    }),
  }),
})

export const { useLazyGetUserUploadS3UrlQuery } = inStoreApi
