import type { FC, SyntheticEvent } from 'react'
import React, { memo, useMemo, useState, useContext, useCallback } from 'react'
import { startCase } from 'lodash'
import styled from '@emotion/styled'
import type { ClaimRecord } from '@helloextend/extend-api-rtk-query'
import { date } from '@helloextend/client-utils'
import { CaptionLegal } from '@helloextend/zen/src/tokens/typography'
import { Badge } from '@helloextend/zen'
import { DataTable } from '../../../components/data-table'
import { AssignUserDropdown } from '../../../components/assign-user-dropdown'
import { EmptyMessage } from '../../../components/data-table/empty-message'
import type { ContractContextValue } from '../contract/components/contract-container'
import { ContractContext } from '../contract/components/contract-container'
import { getStatusBadgeDetails } from '../claims/claim-status'

export interface ClaimAssigned {
  firstName: string
  lastName: string
}

const ClaimsTable: FC = memo(() => {
  const [isAssigneeDropdownOpen, setIsAssigneeDropdownOpen] = useState<boolean>(false)
  const {
    contract,
    claimData,
    isClaimsSearchLoading: isLoading,
  } = useContext(ContractContext) as ContractContextValue

  const { items } = claimData || {}
  const claimsExist = items?.length > 0

  const columns = useMemo(() => {
    return [
      {
        Header: 'Claim Status',
        accessor: 'status',
        columnWidth: 70,
        disableSortBy: true,
        Cell: (row: ClaimRecord): JSX.Element => {
          const badgeData = getStatusBadgeDetails(row)
          return badgeData ? (
            <Badge
              text={badgeData.badgeText}
              emphasis="medium"
              color={badgeData.badgeColor}
              size="regular"
              details={badgeData.badgeDetails}
            />
          ) : (
            <div />
          )
        },
      },
      {
        Header: 'Product Category',
        accessor: 'category',
        columnWidth: 85,
      },
      {
        Header: 'Customer Name',
        accessor: 'customerName',
        columnWidth: 80,
      },
      {
        Header: 'Customer Email',
        accessor: 'customerEmail',
      },
      {
        Header: 'Date Reported',
        accessor: 'dateReported',
        disableSortBy: false,
        columnWidth: 80,
        Cell: ({ dateReported }: ClaimRecord): JSX.Element => (
          <StyledCaptionLegal data-cy="claim-reportedAt">
            {date.format(dateReported as number, 'DD MMM YYYY')}
          </StyledCaptionLegal>
        ),
      },
      {
        Header: 'Claim ID',
        accessor: 'id',
        columnWidth: 150,
      },
      {
        Header: 'Merchant',
        accessor: 'merchant',
        columnWidth: 70,
      },
      {
        Header: 'Assignee',
        headerTooltip: '',
        accessor: 'assignee',
        // columnWidth: 25,
        Cell: (row: ClaimRecord): JSX.Element => {
          const claim = row as ClaimRecord
          return (
            <AssigneeCell data-cy="assignedUser">
              <AssignUserDropdown claimId={claim?.id} onToggle={setIsAssigneeDropdownOpen} />
            </AssigneeCell>
          )
        },
      },
    ]
  }, [])

  const handleRowClick = useCallback(
    (e: SyntheticEvent, rowData: ClaimRecord) => {
      // This logic is very specific to this page and not very extensible.
      // The first two conditions are checking if the click event was triggered
      // by any class/tag related to the assignment dropdown.
      // The third checks if any dropdown is already open - if so, clicking out of it
      // should just close it rather than navigate to a new page.
      const target = e.target as HTMLElement
      if (
        !/AssigneeName|Icon|StyledButton|Children/g.test(target.className) &&
        !/path|svg/g.test(target.tagName) &&
        !isAssigneeDropdownOpen
      ) {
        window.open(`/admin/claims/${rowData.id}`, '_blank', 'noreferrer')
      }
    },
    [isAssigneeDropdownOpen],
  )

  const claimsToDisplay = useMemo(() => {
    if (!items) return []
    const mappedClaims = items.map((claim: ClaimRecord) => {
      const { id, reportedAt, status, adjudicationCategory, customer } = claim
      const assignedUser = claim?.assignedUser as ClaimAssigned
      const assignee = assignedUser
        ? `${assignedUser?.firstName} ${assignedUser?.lastName}`
        : 'Unassigned'

      const category = (adjudicationCategory as string)?.split('_')?.map(startCase)?.join(' ')

      return {
        status,
        id,
        assignee,
        dateReported: reportedAt,
        customerName: customer.name,
        customerEmail: customer.email,
        category,
        merchant: contract.sellerName,
      }
    })

    return mappedClaims || []
  }, [contract.sellerName, items])

  return (
    <>
      <DataTable
        isLoading={isLoading}
        data={claimsToDisplay}
        rowClickEvent={handleRowClick}
        columns={columns}
      />
      {!claimsExist && (
        <EmptyMessage
          header="No claims exist for this contract"
          message="When claims are added to the contract, changes will appear here"
        />
      )}
    </>
  )
})

const AssigneeCell = styled.div({
  display: 'flex',
  flexDirection: 'row',
  fontSize: 12,
  lineHeight: '24px',
  overflow: 'hidden',
  marginBlockStart: '1em',
  marginBlockEnd: '1em',
  gap: 8,
})

const StyledCaptionLegal = styled(CaptionLegal)({
  fontSize: '12px',
})

export { ClaimsTable }
