import { createActionFailure } from '@helloextend/core-api-redux/src/factories'

export const toggleSupport = () =>
  ({
    type: 'TOGGLE_SUPPORT',
  } as const)

export const createSupport = (name: string, email: string, message: string, phoneNumber?: string) =>
  ({
    type: 'CREATE_SUPPORT',
    payload: { name, email, message, phoneNumber },
  } as const)

export const createSupportRequest = () =>
  ({
    type: 'CREATE_SUPPORT_REQUEST',
  } as const)

export const createSupportSuccess = () =>
  ({
    type: 'CREATE_SUPPORT_SUCCESS',
  } as const)

export const createSupportFailure = createActionFailure('CREATE_SUPPORT_FAILURE')
