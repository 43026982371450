import type { Claim, Fulfillment } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetch = (contractId: string, claimId: string, accessToken: string) =>
  ({ type: 'CLAIMS_FETCH', payload: { contractId, claimId, accessToken } } as const)

export const fetchRequest = () => ({ type: 'CLAIMS_FETCH_REQUEST' } as const)

export const fetchSuccess = (claim: Claim & { fulfillments: Fulfillment[] }) =>
  ({
    type: 'CLAIMS_FETCH_SUCCESS',
    payload: claim,
  } as const)

export const fetchFailure = createActionFailure('CLAIMS_FETCH_FAILURE')
