import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'

type DrawerProps = {
  flexBasis?: number
}

/**
 * @deprecated Instead of using `<Drawer />`, use the `sidebar` prop on the Zen `<Shell />` component.
 */
const Drawer: FC<DrawerProps> = ({ children, flexBasis }) => {
  return <Nav flexBasis={flexBasis}>{children}</Nav>
}

const Nav = styled.nav<{ flexBasis?: number }>(({ flexBasis = 200 }) => ({
  borderRight: `1px solid ${COLOR.SLATE}`,
  height: '100%',
  paddingTop: 30,
  paddingBottom: 30,
  flexBasis,
}))

export { Drawer }
