import datadogLogs from '@helloextend/client-monitoring'
import { StatusType } from '@datadog/browser-logs'
import type { Context } from '@datadog/browser-core'
import { isEnv, querystring as utils } from '@helloextend/client-utils'
import { redact, REDACTED } from './redact'

const redactedLog = (
  message: string,
  context?: Context | undefined,
  status?: StatusType,
  location: string = window.location.href,
): void => {
  const isRedacted = isEnv(['production', 'demo'])

  const [baseUrl, qs] = location.split('?')

  const url = new URL(baseUrl)

  const parsed = qs
    ? utils.parse<{
        accessToken?: string
      }>(qs)
    : null

  const redactedLocation =
    isRedacted && parsed && parsed.accessToken
      ? location.replace(parsed.accessToken, REDACTED)
      : location

  const querystring = isRedacted && parsed ? redact(parsed) : parsed

  const redactedContext = isRedacted && context ? redact(context) : context

  const formattedContext = {
    ...redactedContext,
    location: redactedLocation,
    host: url.hostname,
    path: url.pathname,
    port: url.port,
  }

  if (querystring) formattedContext.querystring = querystring

  datadogLogs.logger.log(message, { ...formattedContext }, status)
}

const customLogger = {
  ...datadogLogs,
  logger: {
    ...datadogLogs.logger,
    log: redactedLog,
    info: (message: string, context?: Context | undefined) =>
      redactedLog(message, context, StatusType.info),
    warn: (message: string, context?: Context | undefined) =>
      redactedLog(message, context, StatusType.warn),
    error: (message: string, context?: Context | undefined) =>
      redactedLog(message, context, StatusType.error),
    debug: (message: string, context?: Context | undefined) =>
      redactedLog(message, context, StatusType.debug),
  },
}

export default customLogger.logger
