import type { SyntheticEvent } from 'react'
import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../tokens/colors'
import { Icon, IconSize } from '../icon'
import { ChevronRight } from '../../tokens/icons'
import type { IconProps } from '../../tokens/icons/icon-props'

export interface MenuButtonItemProps {
  ariaExpanded?: boolean
  ariaHasPopup?: 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog'
  children?: React.ReactNode
  'data-cy'?: string
  disabled?: boolean
  endAdornment?: React.ReactElement
  icon?: IconProps
  isDangerous?: boolean
  isIndented?: boolean
  isToggled?: boolean
  label?: string
  onClick?: (event: EventType) => void
  rightAlignAdornment?: boolean
  role?: 'menuitem' | 'option'
  showArrow?: boolean
  tabIndex?: number
  tooltip?: string
  _hasCursor?: boolean
}

type EventType = SyntheticEvent<HTMLButtonElement>

export const MenuButtonItem = forwardRef<HTMLButtonElement, MenuButtonItemProps>(
  (
    {
      ariaExpanded,
      ariaHasPopup,
      children,
      'data-cy': dataCy,
      disabled = false,
      endAdornment,
      icon,
      isDangerous = false,
      isIndented = false,
      isToggled = false,
      label,
      onClick,
      rightAlignAdornment = false,
      role = 'menuitem',
      showArrow = false,
      tabIndex,
      tooltip,
      _hasCursor,
    },
    ref,
  ) => {
    return (
      <StyledMenuButtonItem
        aria-expanded={ariaExpanded}
        aria-haspopup={ariaHasPopup}
        aria-disabled={disabled}
        ref={ref}
        data-cy={dataCy}
        isToggled={isToggled}
        hasCursor={_hasCursor}
        disabled={disabled}
        onClick={onClick}
        isDangerous={isDangerous}
        data-tooltip={tooltip}
        tabIndex={_hasCursor ? 0 : tabIndex}
        role={role}
        {...(_hasCursor !== undefined && { 'data-is-cursorable': true })}
      >
        <StyledArea rightAlignAdornment={rightAlignAdornment} isIndented={isIndented}>
          <Content>
            {!!icon && (
              <IconContainer hasChildren={!!children}>
                {isDangerous ? (
                  <Icon icon={icon} color={disabled ? COLOR.NEUTRAL[500] : COLOR.RED[700]} />
                ) : (
                  <Icon icon={icon} color={disabled ? COLOR.NEUTRAL[500] : COLOR.NEUTRAL[800]} />
                )}
              </IconContainer>
            )}
            {(children || label) && (
              <Children role="none">
                {label}
                {children}
              </Children>
            )}
          </Content>
          {showArrow && (
            <ArrowContainer>
              <Icon
                icon={ChevronRight}
                size={IconSize.small}
                color={disabled ? COLOR.NEUTRAL[500] : COLOR.NEUTRAL[800]}
              />
            </ArrowContainer>
          )}
          {!!endAdornment && (
            <EndAdornmentContainer rightAlignAdornment={rightAlignAdornment}>
              {endAdornment}
            </EndAdornmentContainer>
          )}
        </StyledArea>
      </StyledMenuButtonItem>
    )
  },
)

const StyledArea = styled.div<{
  rightAlignAdornment: boolean
  isIndented: boolean
}>(({ rightAlignAdornment, isIndented }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 4,
  borderRadius: 4,
  padding: `10px 12px 10px ${isIndented ? '20px' : '12px'}`,
  ...(rightAlignAdornment && {
    justifyContent: 'space-between',
  }),
}))

const Content = styled.div({
  display: 'flex',
  gap: 8,
  minWidth: 0,
})

const Children = styled.div({
  position: 'relative',
  maxWidth: '100%',
})

const StyledMenuButtonItem = styled.button<{
  hasCursor?: boolean
  isToggled: boolean
  isDangerous: boolean
}>(({ hasCursor, isToggled, isDangerous }) => ({
  display: 'block',
  width: '100%',
  border: 'none',
  background: 'none',
  padding: '2px 0',
  ...(isDangerous
    ? { color: COLOR.RED[700] }
    : {
        color: isToggled ? COLOR.NEUTRAL[1000] : COLOR.NEUTRAL[800],
      }),
  cursor: 'pointer',
  transition: '50ms',
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: 500,
  textAlign: 'left',
  ...(hasCursor && {
    outline: 'none',
  }),
  ...(isToggled || hasCursor
    ? {
        ...(isToggled && { WebkitTextStroke: '0.04em' }),
        [`& > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[12],
        },
        [`&:hover:not(:disabled) > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[25],
        },
        [`&:active:not(:disabled) > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[12],
        },
      }
    : {
        [`& > ${StyledArea}`]: {
          background: 'transparent',
        },
        [`&:hover:not(:disabled) > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[12],
        },
        [`&:active:not(:disabled) > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[25],
        },
      }),
  '&:disabled': {
    color: COLOR.NEUTRAL[600],
    cursor: 'default',
  },
}))

const IconContainer = styled.div<{ hasChildren: boolean }>(({ hasChildren }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  width: 24,
  height: 24,
  margin: `-2px ${hasChildren ? 0 : '-4px'} -2px ${hasChildren ? '-2px' : '-4px'}`,
}))

const ArrowContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  marginLeft: 'auto',
  marginRight: -4,
})

const EndAdornmentContainer = styled.div<{ rightAlignAdornment: boolean }>(
  ({ rightAlignAdornment }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    marginLeft: 'auto',
    margin: rightAlignAdornment ? '-2px 0px -2px 4px' : '-2px -4px -2px 4px',
  }),
)
