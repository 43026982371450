import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import jwt from 'jsonwebtoken'
import {
  useToaster,
  Stack,
  Button,
  COLOR,
  Spinner,
  ToastColor,
  ToastDuration,
} from '@helloextend/zen'
import { StackJustify } from '@helloextend/zen/src/components/stack'
import {
  useConnectIncredibotMutation,
  useGetContractQuery,
  useUpdateContractMutation,
  useUpdateIncredibotMutation,
} from '@helloextend/extend-api-rtk-query'
import type {
  DecodedAccessToken,
  ClaimFiler,
  InitialReply,
  Reply,
  Contract,
  ContractsUpdateRequest,
} from '@helloextend/extend-api-client'
import { Slot, ClaimSource } from '@helloextend/extend-api-client'
import { usePrevious, useToggle } from '@helloextend/client-hooks'
import * as selectors from '../../../reducers/selectors'
import type { RootState } from '../../../reducers'
import { UserInput } from './components/user-input'
import { ErrorMessage } from './components/error-message'
import { useClaimsSearch } from '../../../hooks/use-claims-search'

const FileAClaim: FC = () => {
  const { toast } = useToaster()
  const [hasUpdatedContract, setHasUpdatedContract] = useState<boolean>(false)
  const history = useHistory()
  const [isNavBlocked, { toggle }] = useToggle()
  const { contractId } = useParams<{ contractId: string }>()
  const accessToken = useSelector((state: RootState) => selectors.getAccessToken(state)) || ''

  const { data: contract, isLoading: isGetContractLoading } = useGetContractQuery({
    contractId,
  })

  const queryParams = useMemo(
    () => ({
      searchVersion: '2',
      containsContractId: contractId,
      matchesClaimStatus: ['review', 'approved'],
    }),
    [contractId],
  )
  const {
    data: claimsSearchData,
    isLoading: isClaimsSearchLoading,
    isSuccess: isClaimsSearchSuccess,
    isError: claimsSearchError,
  } = useClaimsSearch({ queryParams, skip: hasUpdatedContract })

  const [
    updateContract,
    {
      isLoading: isContractUpdateLoading,
      isSuccess: isContractUpdateSuccess,
      error: contractUpdateError,
    },
  ] = useUpdateContractMutation()

  const [
    connectIncredibot,
    {
      isLoading: isIncredibotConnectLoading,
      isSuccess: isIncredibotConnectSuccess,
      error: incredibotConnectError,
      data: incredibotConnectReply,
    },
  ] = useConnectIncredibotMutation()

  const [
    updateIncredibot,
    {
      isLoading: isIncredibotUpdating,
      isSuccess: isIncredibotUpdateSuccess,
      error: incredibotUpdateError,
      data: incredibotUpdateReply,
    },
  ] = useUpdateIncredibotMutation()

  const [currentReply, setCurrentReply] = useState<InitialReply | Reply | undefined>(
    incredibotConnectReply,
  )

  const handleError = useCallback(() => {
    toast({
      message: 'Error occurred. Please try again.',
      toastColor: ToastColor.red,
      toastDuration: ToastDuration.long,
    })
  }, [toast])

  const isLoading =
    isGetContractLoading ||
    isIncredibotConnectLoading ||
    isContractUpdateLoading ||
    isClaimsSearchLoading

  useEffect(() => {
    if (isIncredibotConnectSuccess) {
      setCurrentReply(incredibotConnectReply)
    }
  }, [incredibotConnectReply, isIncredibotConnectSuccess])

  useEffect(() => {
    if (isIncredibotUpdateSuccess) {
      setCurrentReply(incredibotUpdateReply)
    }
  }, [incredibotUpdateReply, isIncredibotUpdateSuccess])

  useEffect(() => {
    if (isContractUpdateSuccess) {
      setHasUpdatedContract(true)
    }
  }, [isContractUpdateSuccess])

  useEffect(() => {
    // only connect to incredibot once the contract info
    // has had a chance to be updated
    if (accessToken && hasUpdatedContract) {
      const { firstName, lastName, email, accountId } = jwt.decode(
        accessToken,
      ) as DecodedAccessToken

      const filedBy: ClaimFiler = {
        firstName,
        lastName,
        email,
        accountId,
      }

      connectIncredibot({
        filedBy,
        slot: Slot.ContractId,
        slotValue: contractId,
        source: ClaimSource.ops_admin,
      })
    }
  }, [accessToken, contractId, connectIncredibot, hasUpdatedContract])

  useEffect(() => {
    const [claim] = claimsSearchData?.items || []
    if (isClaimsSearchSuccess && claim?.id) {
      const claimId = claim.id
      setCurrentReply({ claimId, messages: [] })
    }

    // sets empty initial reply to allow contact from to render
    if (isClaimsSearchSuccess && !claim) {
      setCurrentReply({ messages: [] })
    }
  }, [isClaimsSearchSuccess, claimsSearchData])

  useEffect(() => {
    const contractClaimError =
      contractUpdateError || incredibotUpdateError || incredibotConnectError
    if (contractClaimError) {
      handleError()
    }
  }, [handleError, incredibotConnectError, incredibotUpdateError, contractUpdateError])

  const handleUpdateSession = (slot: Slot, slotValue: string | number): void => {
    const incredibotToken = incredibotConnectReply?.accessToken

    if (incredibotToken) {
      const incredibotData = {
        accessToken: incredibotToken,
        data: { slot, slotValue },
      }
      updateIncredibot(incredibotData)
    }
  }

  const handleUpdateContract = (updates: Partial<Contract>): void => {
    if (accessToken) {
      updateContract({ contractId, data: updates as ContractsUpdateRequest })
    }
  }

  const handleLeavePage = (path: string): void => {
    history.push(path)
  }

  const prevIsLoading = usePrevious<boolean>(isLoading)
  const isLoaded = prevIsLoading && !isLoading
  const hasError =
    contractUpdateError || incredibotConnectError || incredibotUpdateError || claimsSearchError

  if (isLoading) {
    return (
      <LoadingContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </LoadingContainer>
    )
  }

  if (isLoaded && hasError) {
    return <ErrorMessage contractId={contractId} reply={currentReply} />
  }

  return (
    <Container>
      <HeaderContainer>
        <Stack justify={StackJustify.spaceBetween} doesWrap>
          <HeaderDetailsContainer>
            <PageTitle>Filing a Claim</PageTitle>
            {contract && (
              <Stack doesWrap>
                <DetailName>Claim in progress for: </DetailName>
                {contract?.product?.name}
              </Stack>
            )}
            <Stack doesWrap>
              <DetailName>Contract ID: </DetailName>
              <StyledLink to={`/admin/contracts/${contractId}`}>{contractId}</StyledLink>
            </Stack>
          </HeaderDetailsContainer>
          <Button
            emphasis="medium"
            text="Cancel claim"
            onClick={() => handleLeavePage(`/admin/contracts/${contractId}`)}
          />
        </Stack>
      </HeaderContainer>
      {contract && currentReply && (
        <UserInput
          contract={contract}
          hasUpdatedContract={hasUpdatedContract}
          reply={currentReply}
          isIncredibotUpdating={isIncredibotUpdating}
          onIncredibotUpdate={handleUpdateSession}
          onUpdateContract={handleUpdateContract}
          toggleNavBlocked={toggle}
          isNavBlocked={isNavBlocked}
        />
      )}
    </Container>
  )
}

const LoadingContainer = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
const Container = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
})
const HeaderContainer = styled.div({
  width: '100%',
})
const HeaderDetailsContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
})
const PageTitle = styled.p({
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '32px',
  margin: 0,
  marginBottom: 4,
})
const DetailName = styled.span({
  fontSize: 16,
  fontWeight: 'bold',
  color: COLOR.NEUTRAL[600],
  marginRight: 4,
})
export const StyledLink = styled(Link)({
  color: COLOR.BLUE[800],
  fontSize: 16,
  lineHeight: '24px',
  '&:hover': {
    textDecoration: 'underline',
  },
})

export { FileAClaim }
