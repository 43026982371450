import type { FC } from 'react'
import React from 'react'
import { Calendar } from 'react-date-range'
import { COLOR } from '../../tokens/colors'
import { StyledCalendar } from './styled-calendar'

export type DateValue = Date | null | 0

export interface DatePickerProps {
  /**
   * Set the earliest date you want to be able to be selected.
   */
  minDate?: Date
  /**
   * Set the latest date you want to be able to be selected.
   */
  maxDate?: Date
  onChange?: (value: DateValue) => void
  /**
   * Provides an "infinite" scrolling vertical calendar view rather that just previous/next month buttons.
   *
   * Note: To prevent DOM performance issues, it's best to define a `minDate` and `maxDate` when using the vertically
   * scrollable calendar view.
   */
  isScrollable?: boolean
  value?: DateValue
}

export const DatePicker: FC<DatePickerProps> = ({
  minDate,
  maxDate,
  onChange = () => {},
  isScrollable = false,
  value,
}) => {
  return (
    <StyledCalendar>
      <Calendar
        date={value || new Date()}
        onChange={onChange}
        scroll={{ enabled: isScrollable }}
        minDate={minDate}
        maxDate={maxDate}
        color={COLOR.BLUE[700]}
        rangeColors={[COLOR.BLUE[700]]}
        showDateDisplay={false}
        startDatePlaceholder="Unset"
        endDatePlaceholder="Unset"
      />
    </StyledCalendar>
  )
}
