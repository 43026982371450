import React from 'react'
import type { ColumnDefs, CellContext } from '@helloextend/zen'
import type { Account, AccountEnterprise } from '@helloextend/extend-api-rtk-query'
import type { TableAccounts } from '../../../../../types/accounts'
import { AccountStatus } from '../../../../../types/accounts'
import AccountToggleSwitch from '../../components/account-toggle-switch/account-toggle-switch'

const toTableAccount = (account: Account): TableAccounts => {
  return {
    name: account.name ?? '',
    accountId: account.id,
    isLegacyAccount: Object.prototype.hasOwnProperty.call(account, 'isLegacyAccount') ?? true,
    status: (account as AccountEnterprise).status ?? undefined,
  }
}

const toTableAccounts = (accounts: Account[]): TableAccounts[] => accounts.map(toTableAccount)

const accountsColumns: ColumnDefs<TableAccounts> = [
  {
    label: 'Name',
    id: 'name',
    search: 'explicit',
    renderCell: (cellData: CellContext<TableAccounts, string>) => cellData.getValue(),
  },
  {
    label: 'Account Id',
    id: 'accountId',
    search: 'explicit',
    renderCell: (cellData: CellContext<TableAccounts, string>) => cellData.getValue(),
  },
  {
    label: 'Framework',
    id: 'isLegacyAccount',
    renderCell: (cellData: CellContext<TableAccounts, boolean>) => {
      const accountType = cellData.getValue() ? 'Legacy' : 'MultiTenant'
      return accountType
    },
  },
  {
    label: 'Account Approved',
    id: 'status',
    renderCell: (cellData: CellContext<TableAccounts, AccountStatus>): JSX.Element | null => {
      const { name, isLegacyAccount, accountId, status } = cellData.row.original

      const hybridLegacyAccount =
        isLegacyAccount &&
        (status === AccountStatus.Approved || status === AccountStatus.Unapproved)

      if (!isLegacyAccount || hybridLegacyAccount) {
        return (
          <AccountToggleSwitch
            status={cellData.getValue()}
            accountId={accountId}
            name={name}
            hybridLegacyAccount={hybridLegacyAccount}
          />
        )
      }
      return null
    },
  },
]

const searchOptions = [
  { label: 'Name', value: 'name' },
  { label: 'Account Id', value: 'id' },
]

export { toTableAccount, toTableAccounts, accountsColumns, searchOptions }
