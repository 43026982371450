import { combineReducers } from 'redux'
import type { UserProfileReducerState } from './profile'
import profile from './profile'
import isLoading from './is-loading'
import type { ProfileSuccessMessageState } from './profile-success-message'
import profileSuccessMessage from './profile-success-message'

export interface MerchantsUserReducerState {
  profile: UserProfileReducerState
  isLoading: boolean
  profileSuccessMessage: ProfileSuccessMessageState
}

export default combineReducers<MerchantsUserReducerState>({
  profile,
  isLoading,
  profileSuccessMessage,
})
