import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { useThemeSafe } from '../../hooks'
import { COLOR } from '../../tokens/colors'
import { Icon } from '../icon'
import { Spinner } from '../spinner'
import type { ColorArgs, ButtonIconPosition, MeasurementArgs, ButtonProps } from './types'
import { getColors, getPadding, getMeasurements } from './utils'

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      ariaLabel,
      ariaExpanded,
      ariaHasPopup,
      isBold = true,
      emphasis = 'high',
      formId,
      size = 'regular',
      color = 'blue',
      fillContainer = false,
      icon,
      iconPosition = 'left',
      iconTransitionDurationMs,
      tooltip,
      isDisabled = false,
      isInverted = false,
      isProcessing = false,
      isToggled = false,
      onClick,
      onMouseDown,
      hasReducedPadding = false,
      text,
      type = 'button',
      'data-cy': dataCy,
      tabIndex,
    },
    ref,
  ) => {
    const {
      button: { borderRadius },
    } = useThemeSafe()
    const colors = getColors(color, isInverted, emphasis)
    const padding = getPadding(size, !!text, !!icon, isProcessing, iconPosition, hasReducedPadding)
    const measurements = getMeasurements(size)
    return (
      <StyledButton
        ref={ref}
        type={type}
        className={`${isToggled ? 'active' : ''}`}
        isDisabled={isDisabled}
        isProcessing={isProcessing}
        aria-disabled={isDisabled || isProcessing || undefined}
        aria-label={ariaLabel || text}
        aria-expanded={ariaExpanded}
        aria-haspopup={ariaHasPopup}
        data-cy={dataCy}
        form={formId}
        colors={colors}
        iconPosition={iconPosition}
        data-tooltip={tooltip}
        fillContainer={fillContainer}
        padding={padding}
        measurements={measurements}
        borderRadius={borderRadius}
        tabIndex={isDisabled ? -1 : tabIndex}
        onClick={isDisabled || isProcessing ? (e) => e.preventDefault() : onClick}
        onMouseDown={isDisabled || isProcessing ? (e) => e.preventDefault() : onMouseDown}
        isBold={isBold}
      >
        {!!icon && !isProcessing && (
          <Icon
            icon={icon}
            size={measurements.iconSize}
            color={!isDisabled ? colors.contentColor : colors.disabledContentColor}
            transitionDurationMs={iconTransitionDurationMs}
          />
        )}
        {!!isProcessing && (
          <Spinner
            data-cy={dataCy && `${dataCy}:spinner`}
            size={measurements.iconSize}
            color={colors.contentColor}
          />
        )}
        {text}
      </StyledButton>
    )
  },
)

const StyledButton = styled.button<{
  colors: ColorArgs
  fillContainer: boolean
  iconPosition: ButtonIconPosition
  padding: string
  measurements: MeasurementArgs
  borderRadius: string
  isDisabled: boolean
  isProcessing: boolean
  isBold: boolean
}>(
  ({
    colors,
    fillContainer,
    iconPosition,
    padding,
    measurements,
    borderRadius,
    isDisabled,
    isProcessing,
    isBold,
  }) => ({
    /* Base styles */
    position: 'relative',
    boxSizing: 'border-box',
    border: 'none',
    borderRadius,
    fontWeight: isBold ? 700 : 400,
    fontSize: measurements.fontSize,
    lineHeight: measurements.lineHeight,
    gap: measurements.gap,
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 120ms',
    alignSelf: 'flex-start',

    /* Fill container styles */
    display: fillContainer ? 'flex' : 'inline-flex',
    ...(iconPosition === 'right' && {
      flexDirection: 'row-reverse',
    }),
    width: fillContainer ? '100%' : undefined,

    /* Override browser default margins */
    margin: 0,

    /* Padding adjusted by presence and/or position of icon */
    padding,

    /* Color styles */
    background: colors.backgroundColor || 'none',
    color: colors.contentColor,

    /* Before psudo-elements used for medium emphasis border to prevent the need to change padding in base styles */
    ...(colors.borderColor && {
      '&::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        border: `1px solid ${isDisabled ? colors.disabledBorderColor : colors.borderColor}`,
        borderRadius: 'inherit',
        opacity: 0.85,
      },
    }),

    /* After psudo-elements used for focus state style */
    '&::after': {
      content: '""',
      position: 'absolute',
      inset: -2,
      boxShadow: `0 0 4px 2px ${COLOR.BLUE[500]}`,
      borderRadius: 'inherit',
      opacity: 0,
      transition: 'opacity 120ms',
      pointerEvents: 'none',
    },

    /* Disabled styles */
    ...(isDisabled && {
      background: colors.disabledBackgroundColor || 'transparent',
      color: colors.disabledContentColor,
    }),

    /* State styles */
    ...(!isDisabled &&
      !isProcessing && {
        cursor: 'pointer',
        '&:hover': {
          background: colors.hoverBackgroundColor,
        },
        '&:active, &.active': {
          background: colors.activeBackgroundColor,
        },
        '&.active:hover': {
          background: colors.hoverBackgroundColor,
        },
        '&:focus': {
          outline: 'none',
        },
        '&:focus-visible': {
          outline: 'none',
          '&::after': {
            opacity: 1,
          },
        },
      }),
  }),
)
