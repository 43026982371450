import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { COLOR, Icon } from '@helloextend/zen'
import { ChevronRight } from '@helloextend/zen/src/tokens/icons'
import { ListItemButton, Menu } from '@helloextend/merchants-ui'
import type { FilterOptions, FilterValues } from './types'
import { Filter } from './filter'

export type FiltersMenuProps = {
  filters: Record<string, FilterOptions>
  values: Record<string, FilterValues>
  onFilterChange: (property: string, values: FilterValues | null) => void
  onMenuLock: (isLocked: boolean) => void
}

export const getAppliedFilterCount = (
  property: string,
  filters: Record<string, FilterOptions>,
  values: Record<string, FilterValues>,
): number => {
  const filterValues = values[property]
  const filter = filters[property]
  switch (filter?.type) {
    case 'checkbox':
      if (filterValues?.type === 'checkbox') {
        return filterValues.values.length
      }
      break
    case 'dateRange':
      if (filterValues?.type === 'dateRange') {
        return 1
      }
      break
    case 'group': {
      return Object.entries(filter.filters).reduce((acc, [nestedProperty]) => {
        return acc + getAppliedFilterCount(nestedProperty, filter.filters, values)
      }, 0)
    }
  }
  return 0
}

const FiltersMenu: FC<FiltersMenuProps> = ({ filters, values, onFilterChange, onMenuLock }) => {
  const [selectedChild, setSelectedChild] = useState('')

  const handleMenuSelect = useCallback((label: string): void => {
    setSelectedChild(label)
  }, [])

  return (
    <>
      {Object.entries(filters).map(([key, filter]) => {
        const filterValues = values[key]
        const appliedFilterCount = getAppliedFilterCount(key, filters, values)
        return (
          <ItemWrapper key={key}>
            <MenuButton
              id={key}
              isActive={selectedChild === key}
              hoverBackground={COLOR.NEUTRAL[100]}
              onClick={() => handleMenuSelect(key)}
            >
              {filter.label} {appliedFilterCount > 0 && `(${appliedFilterCount})`}
              {selectedChild !== key && <Icon icon={ChevronRight} color={COLOR.NEUTRAL[800]} />}
            </MenuButton>
            <MenuWrapper>
              <Menu isOpen={selectedChild === key} position="right" width={216}>
                {filter.type === 'group' && (
                  <FiltersMenu
                    filters={filter.filters}
                    values={values}
                    onFilterChange={onFilterChange}
                    onMenuLock={onMenuLock}
                  />
                )}
                {filter.type !== 'group' && (
                  <Filter
                    property={key}
                    filter={filter}
                    values={filterValues}
                    onFilterChange={onFilterChange}
                    onMenuLock={onMenuLock}
                  />
                )}
              </Menu>
            </MenuWrapper>
          </ItemWrapper>
        )
      })}
    </>
  )
}

const ItemWrapper = styled.div({
  position: 'relative',
})

const MenuWrapper = styled.div({
  position: 'absolute',
  left: 'calc(100% + 6px)',
  width: '100%',
  top: '-8px',
  height: '100%',
})

const MenuButton = styled(ListItemButton)<{ isActive: boolean }>(({ isActive }) => ({
  backgroundColor: isActive ? COLOR.BLUE[800] : 'transparent',
  color: isActive ? COLOR.WHITE : COLOR.BLACK,
  borderRadius: 0,
  display: 'flex',
  justifyContent: 'space-between',
  ...(isActive && {
    '&:hover': {
      backgroundColor: COLOR.BLUE[800],
      color: COLOR.WHITE,
    },
  }),
}))

export { FiltersMenu }
