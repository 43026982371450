import * as resetActions from './reset'
import * as createActions from './create'
import type { ExtractActions } from '../../types/utility'

export type Action = ExtractActions<typeof resetActions> | ExtractActions<typeof createActions>

export type ActionType = Action['type']

const actions = {
  ...resetActions,
  ...createActions,
}

export { actions }
