export type BoundingBox = {
  left: number
  top: number
  width: number
  height: number
}

export enum PopoverDirection {
  up = 'up',
  right = 'right',
  down = 'down',
  left = 'left',
}

export enum PopoverAlignment {
  start = 'start',
  center = 'center',
  end = 'end',
}
