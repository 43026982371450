import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Shield } from './shield'
import { COLOR } from '../../../tokens/colors'

export interface HeaderLogoLockupProps {
  wordmark: string
  invertShield?: boolean
  'data-cy'?: string
}

export const HeaderLogoLockup: FC<HeaderLogoLockupProps> = ({
  wordmark,
  invertShield = false,
  'data-cy': dataCy,
}) => {
  return (
    <StyledLogoLockup data-cy={dataCy}>
      <Shield isInverted={invertShield} />
      <Wordmark>{wordmark}</Wordmark>
    </StyledLogoLockup>
  )
}

const StyledLogoLockup = styled.div({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
})

const Wordmark = styled.span({
  fontSize: 20,
  fontWeight: 'bold',
  color: COLOR.WHITE,
})
