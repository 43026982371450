import { numberToCurrency } from './number-to-currency'

export const DEFAULT_LOCALE = 'en-us'

// Price Band Count Limits
export const MIN_PRICEBAND_COUNT = 1
export const MAX_PRICEBAND_COUNT = 6
export const MAX_COVERAGE_TYPE_COUNT = 3
// Price Band price restrictions
// These are inclusive in that the price must be between
// or equal
export const INCLUSIVE_PRICEBAND_MIN = 0
export const INCLUSIVE_PRICEBAND_MAX = 5000000
export const PLANSET_ID_PREFIX = 'PS-'
export const DEFAULT_PAGINATION_LIMIT = 25

// Planset validation errors
export const VALIDATION_MESSAGES = {
  PLANSET_MISSING_PLAN_CATEGORY: 'No plan category selected',
  PLANSET_MISSING_PRICE_BANDS: 'Plan set is missing price bands',
  PLANSET_TOO_FEW_PRICE_BANDS: `Minimum number of price bands not met - at least ${MIN_PRICEBAND_COUNT} price band(s) are required`,
  PLANSET_TOO_MANY_PRICE_BANDS: `Maximum number of price bands exceeded - no more than (${MAX_PRICEBAND_COUNT}) price bands can be defined`,
  PLANSET_INVALID_ID_PREFIX: `Plan set id must begin with prefix ${PLANSET_ID_PREFIX}`,
  PLANSET_INVALID_ID_SPACES: `Plan set id cannot contain spaces`,
  PRICEBAND_INVALID_MIN_MAX_ORDER: 'Max price must be greater than min price',
  PRICEBAND_INVALID_MIN_PRICE: `Min price must be greater than ${numberToCurrency(
    INCLUSIVE_PRICEBAND_MIN,
    DEFAULT_LOCALE,
  )}`,
  PRICEBAND_INVALID_MIN_PRICE_HIGH: `Min price must be less than ${numberToCurrency(
    INCLUSIVE_PRICEBAND_MAX,
    DEFAULT_LOCALE,
  )}`,
  PRICEBAND_INVALID_MAX_PRICE: `Max price must be less than ${numberToCurrency(
    INCLUSIVE_PRICEBAND_MAX,
    DEFAULT_LOCALE,
  )}`,
  PRICEBAND_INVALID_MAX_PRICE_LOW: `Max price must be greater than ${numberToCurrency(
    INCLUSIVE_PRICEBAND_MIN,
    DEFAULT_LOCALE,
  )}`,
  PRICEBAND_OVERLAP_ERROR: 'Price band introduces an overlap of price band ranges',
  PRICEBAND_GAP_ERROR: 'Price band introduces gaps between price band ranges',
  PRICEBAND_MISSING_PLANS: 'Price band must have at least 1 plan',
  PRICEBAND_MAX_COVERAGE_TYPE_EXCEEDED: `Price bands may only have ${MAX_COVERAGE_TYPE_COUNT} plans per coverage type`,
  MISSING_IDS_TO_DELETE: 'No plan set ids were provided to delete',
}

export const VALIDATION_ERROR_MESSAGE_PREFIX = 'Plan Set did not pass validation'
export const DEFAULT_TOAST_ERROR_MESSAGE = 'Something went wrong. Please try again.'

export const WARNING_MESSAGES = {
  PLANSET_GTM_UPDATE: (planCategory: string): string =>
    `Creating this GTM Plan Set will override the existing GTM Plan Set for ${planCategory}. \n \n This will affect all products that are connected to the original GTM Plan Set.`,
}
