import type { SagaIterator } from 'redux-saga'
import { call, put, take } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/client-utils/src/request'
import { actions } from '../actions'
import { fetchServiceOrders } from './fetch-by-claim-id'

type Action = ReturnType<typeof actions.approveReplacement>

function* approveReplacement(payload: {
  serviceOrderId: string
  accessToken: string
}): SagaIterator {
  const { serviceOrderId, accessToken } = payload
  yield put(actions.approveReplacementRequest())
  try {
    const response: ResolvedType<typeof client.serviceOrders.approveReplacement> = yield call(
      client.serviceOrders.approveReplacement,
      serviceOrderId,
      accessToken,
    )

    if (isErrorResponse(response)) {
      yield put(actions.approveReplacementFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(actions.approveReplacementFailure(`An unknown error occurred`, response.status))
      return
    }

    yield put(actions.approveReplacementSuccess())
  } catch (e) {
    yield take('SERVICE_ORDERS_FETCH')
    yield put(actions.approveReplacementFailure(e.message))
  }
}

export default function* approveReplacementAndRefetch(action: Action): SagaIterator {
  yield call(approveReplacement, action.payload)
  yield call(fetchServiceOrders, action.payload)
}
