import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useFormik } from 'formik'
import { useCreateClaimNotesMutation } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { TextArea, Button, ButtonGroup, COLOR } from '@helloextend/zen'
import { Add } from '@helloextend/zen/src/tokens/icons'
import { useToggle } from '@helloextend/client-hooks'
import type { ClaimNotesCreateRequest } from '@helloextend/extend-api-client'
import { formSchema, NOTETEXT_MAX } from './schema'
import { DatepickerMenu } from '../../../../../../components/datepicker-menu'
import type { Values } from './schema'
import { FollowUpBadge } from './follow-up-badge'

interface ClaimNotesFormProps {
  claimId: string
}

const ClaimNotesForm: FC<ClaimNotesFormProps> = ({ claimId }) => {
  const [isFormOpen, { toggle, off }] = useToggle()
  const [createClaimNote, { isLoading }] = useCreateClaimNotesMutation()
  const { errors, handleSubmit, handleChange, setFieldValue, values, resetForm } =
    useFormik<Values>({
      enableReinitialize: true,
      initialValues: formSchema.default(),
      validationSchema: formSchema,
      validateOnChange: false,
      onSubmit: async (formValues: Values) => {
        await createClaimNote({ claimId, body: formValues as ClaimNotesCreateRequest })
        handleClose()
      },
    })

  const today = useMemo(() => {
    const d = new Date()
    d.setHours(0, 0, 0, 0)
    return d
  }, [])

  const handleClose = (): void => {
    off()
    resetForm()
  }

  return (
    <ContentWrapper>
      {!isFormOpen && (
        <Button
          text="Add a Note"
          icon={Add}
          emphasis="medium"
          onClick={toggle}
          data-cy="add-note-button"
        />
      )}
      {isFormOpen && (
        <form onSubmit={handleSubmit}>
          <FollowUpWrapper>
            <DatepickerMenu
              buttonText="Follow-up Date"
              minDate={today}
              date={values.followUpDate}
              onChange={(v) => {
                if (v !== values.followUpDate) {
                  setFieldValue('followUpDate', v)
                  setFieldValue('type', v == null ? 'basic' : 'follow-up')
                }
              }}
            />
            {values.followUpDate && <FollowUpBadge timestamp={values.followUpDate} />}
          </FollowUpWrapper>
          <TextArea
            label=""
            maxLength={NOTETEXT_MAX}
            onChange={handleChange}
            value={values.text}
            errorFeedback={errors.text}
            data-cy="note-text"
            id="text"
          />
          <ButtonWrapper>
            <ButtonGroup>
              <Button
                text="Cancel"
                emphasis="medium"
                data-cy="cancel-button"
                onClick={handleClose}
              />
              <Button
                type="submit"
                text="Save"
                data-cy="notes-save-button"
                isDisabled={isLoading}
              />
            </ButtonGroup>
          </ButtonWrapper>
        </form>
      )}
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  flex: 1,
})

export const ErrorMessage = styled.div({
  color: COLOR.RED[700],
  fontFamily: 'Nunito Sans',
  fontSize: 12,
  lineHeight: '16px',
  marginBottom: 16,
  marginTop: 8,
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

const FollowUpWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  marginBottom: 8,
})

export { ClaimNotesForm }
