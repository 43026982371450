import type { FC } from 'react'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import { HeadTag } from '../../components/head-tag'
import { AuthLayout } from '../../hoc/auth-layout'
import { ForgotPassword } from './forgot-password'

const ForgotPasswordPage: FC = () => (
  <AuthLayout>
    <HeadTag siteTitle="Extend | Forgot Password" />
    <ForgotPassword />
    <ButtonText to="/login">Return to login</ButtonText>
  </AuthLayout>
)

const ButtonText = styled(Link)({
  fontSize: 14,
  backgroundColor: 'transparent',
  borderBottom: '1px solid transparent',
  color: COLOR.STRONG_BLUE,
  paddingTop: 24,
  cursor: 'pointer',
  transition: 'border .25s ease-in',
  '&:hover': {
    borderBottom: `1px solid ${COLOR.STRONG_BLUE}`,
  },
  '&:visited': {
    color: COLOR.STRONG_BLUE,
  },
})

export { ForgotPasswordPage }
