import { urlRegExp } from '@helloextend/client-utils/validate'
import * as Yup from 'yup'

const tableauUserIdSchema = Yup.object()
  .shape({
    tableauUserId: Yup.string().notRequired().default(''),
  })
  .defined()

const historicalLookBackPeriod = Yup.object()
  .shape({
    historicalLeadsEnabled: Yup.boolean().notRequired(),
    historicalLeadsLookbackMonths: Yup.number().required('Add look-back period').default(undefined),
  })
  .defined()

const defaultLeadUrlSchema = Yup.object()
  .shape({
    leadUrlConfigurabilityEnabled: Yup.boolean().notRequired(),
    defaultLeadUrl: Yup.string()
      .required('Add default URL format')
      .matches(urlRegExp, 'Please enter a valid URL')
      .default(''),
  })
  .defined()

type TableauUserIdValues = Yup.InferType<typeof tableauUserIdSchema>
type HistoricalLookBackPeriodValues = Yup.InferType<typeof historicalLookBackPeriod>
type DefaultLeadUrlValues = Yup.InferType<typeof defaultLeadUrlSchema>

export {
  TableauUserIdValues,
  HistoricalLookBackPeriodValues,
  DefaultLeadUrlValues,
  tableauUserIdSchema,
  historicalLookBackPeriod,
  defaultLeadUrlSchema,
}
