import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@helloextend/client-branding'

/**
 * @deprecated Use Zen Icon component instead: `import { Icon } from '@helloextend/zen'`
 */
export const DownCaret: FC<{ fill?: string }> = ({ fill = COLOR.GRAYISH_BLUE_0 }) => (
  <svg width="13" height="7" viewBox="0 0 13 7" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g transform="translate(-1136 -522)" stroke={fill} strokeWidth="2">
        <polyline id="Path-Copy-2" points="1137 523 1142.51872 528 1148 523" />
      </g>
    </g>
  </svg>
)
