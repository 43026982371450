import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import type { Claim, Contract, Money, ServiceOrder } from '@helloextend/extend-api-client'
import { DataDisplayGroup, fieldMapper } from '../../../../../../components/data-display-group'
import { AssignServiceOrderForm } from './assign-service-order-form'
import { dataFields } from './schema'

interface DataFields {
  claim: Claim
  serviceOrder: ServiceOrder
  contract: Contract
  entitlements: { coverageAmountRemaining: Money }
}

interface AssignServiceOrderContentProps {
  claim: Claim
  serviceOrder: ServiceOrder
  contract: Contract
  entitlements: { coverageAmountRemaining: Money }
}

const AssignServiceOrderContent: FC<AssignServiceOrderContentProps> = ({
  claim,
  serviceOrder,
  contract,
  entitlements,
}) => {
  const readOnlyData = useMemo(
    () => fieldMapper<DataFields>({ claim, serviceOrder, contract, entitlements }, dataFields),
    [claim, serviceOrder, contract, entitlements],
  )

  return (
    <ContentWrapper>
      <DataDisplayGroup data={[{ values: readOnlyData }]} numColumns={4} />
      <AssignServiceOrderForm serviceOrder={serviceOrder} />
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
})

export { AssignServiceOrderContent }
