interface InitializeOptions {
  userId: string
  email: string
  accountId?: string
  storeId?: string
  role?: string
  additionalAccountProperties?: Record<string, any>
}

type UseAnalyticsType = {
  initialize: (opts: InitializeOptions) => void
  initializeAnonymous: () => void
  trackEvent: (type: string, metaData: Record<string, string | number | boolean>) => void
}

export const useUserAnalytics = (): UseAnalyticsType => {
  const initialize = ({
    userId,
    email,
    accountId,
    storeId,
    role,
    additionalAccountProperties = {},
  }: InitializeOptions): void => {
    const account: pendo.IdentityMetadata = { ...additionalAccountProperties }
    if (accountId) account.id = accountId
    if (storeId) account.storeId = storeId

    const visitor: pendo.IdentityMetadata = {
      id: userId,
      email,
    }
    if (role) visitor.role = role

    if (typeof pendo !== 'undefined') {
      pendo.initialize({
        visitor,
        account,
      })
    }
  }

  const initializeAnonymous = (): void => {
    if (typeof pendo !== 'undefined') {
      pendo.initialize({
        visitor: {
          id: 'anonymous',
        },
      })
    }
  }

  const trackEvent = (type: string, metaData: Record<string, string | number | boolean>): void => {
    if (!pendo) return

    pendo.track(type, metaData)
  }

  return { initialize, initializeAnonymous, trackEvent }
}
