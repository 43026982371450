import { combineReducers } from 'redux'
import isSaving from './is-saving'
import error from './error'
import type { Action } from '../actions'
import type { ByServiceOrderReducerState } from './by-service-order'
import byServiceOrder from './by-service-order'
import isLoading from './is-loading'
import isSuccess from './is-success'
import isVoiding from './is-voiding'

import type { ErrorReducerState } from '../../types/error'

export interface ReducerState {
  byServiceOrder: ByServiceOrderReducerState
  error: ErrorReducerState
  isSaving: boolean
  isVoiding: boolean
  isLoading: boolean
  isSuccess: boolean
}

export default combineReducers<ReducerState, Action>({
  byServiceOrder,
  isLoading,
  isSaving,
  isVoiding,
  isSuccess,
  error,
})
