import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { BasicModal } from '../../../../../components/basic-modal'
import { AddUserForm } from './add-user-form'

interface AddUserModalProps {
  isVisible: boolean
  handleModalToggle: () => void
}

const AddUserModal: FC<AddUserModalProps> = ({ isVisible, handleModalToggle }) => {
  return (
    <BasicModal isVisible={isVisible} onClickClose={handleModalToggle} data-cy="add-user-modal">
      <Title>Add User</Title>
      <AddUserForm handleClose={handleModalToggle} />
    </BasicModal>
  )
}

const Title = styled.h2({
  fontSize: 20,
  lineHeight: '27px',
  fontWeight: 700,
  marginTop: 0,
})

export { AddUserModal }
