import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/client-utils/src/request'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.destroy>

export default function* destroy(action: Action): SagaIterator {
  const { id, accessToken } = action.payload

  yield put(actions.destroyRequest())

  const response: ResolvedType<typeof client.stores.destroy> = yield call(
    client.stores.destroy,
    id,
    accessToken,
  )

  if (isErrorResponse(response)) {
    yield put(actions.destroyFailure(response.data.message, response.status))
    return
  }

  yield put(actions.destroySuccess(id))
}
