import type { ApiResponse } from '../../../extend-api-fetch'
import { post } from '../../../extend-api-fetch'
import type { SupportRequest } from '../models'

export async function createSupportRequest(
  body: SupportRequest,
  accessToken: string,
): Promise<ApiResponse<void>> {
  return post('/support', { accessToken, body })
}
