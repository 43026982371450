import React from 'react'
import type { Claim } from '@helloextend/extend-api-client'
import { isImagesRequired } from '@helloextend/client-utils/validate'

interface ClaimResultMessaging {
  headerText: string
  titleText: string
  firstBullet: JSX.Element
  secondBullet: string | JSX.Element
}

export function useGetClaimResultMessaging(claim: Claim): ClaimResultMessaging {
  const { email } = claim.customer

  const secondBullet = (
    <div>
      <p>This email will include details on the next steps for this claim.</p>
      <p>
        <b>Claim ID:</b> {claim.id}{' '}
      </p>
    </div>
  )

  if (claim.status === 'approved') {
    return {
      headerText: 'Claim Submitted',
      titleText: 'This claim is approved! 🎉',
      firstBullet: (
        <p>
          In a few minutes, the customer will receive an email with next steps from Extend at{' '}
          <b>{email}</b>.
        </p>
      ),
      secondBullet,
    }
  }

  if (claim.status === 'denied') {
    return {
      headerText: 'Claim Submitted',
      titleText: 'This claim is denied',
      firstBullet: (
        <p>
          In a few minutes, the customer will receive an email with next steps from Extend at{' '}
          <b>{email}</b>.
        </p>
      ),
      secondBullet,
    }
  }

  if (isImagesRequired(claim.validationError)) {
    return {
      headerText: 'Images Required',
      titleText: 'Customer photos are required to complete this claim.',
      firstBullet: (
        <p>
          An email has been sent to <b>{email}</b> to upload photos to complete this claim.
        </p>
      ),
      secondBullet:
        'Once the photo prompts are submitted, the customer will recieve a resolution via email.',
    }
  }

  // catch all review claims
  return {
    headerText: 'Claim Submitted',
    titleText: 'Thank you! This claim is in review.',
    firstBullet: (
      <p>
        Extend will review this claim in the next 1-2 business days and send next steps to the
        customer at <b>{email}</b>.
      </p>
    ),
    secondBullet,
  }
}
