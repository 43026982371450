import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@helloextend/client-branding'
import styled from '@emotion/styled'
import { keyframes, Global } from '@emotion/core'
import { bp } from '@helloextend/client-utils/breakpoints'
import { Link } from 'react-router-dom'
import images from '../../images'

const RouterError: FC = () => {
  return (
    <>
      <Global styles="body {margin: 0}" />
      <Gradient>
        <Link to="/">
          <Logo src={images.extendLogoWhite} alt="Extend" loading="lazy" />
        </Link>
        <Main role="main">
          <Title>
            4<Darker>0</Darker>4
          </Title>
          <Description data-cy="turned-off-lights">
            Who turned off all the lights?
            <br /> Sorry, we can’t find this page right now.
          </Description>
          <HomeButton to="/">Back to Home</HomeButton>
          <ImageWrapper>
            <img src={images.disconnectedPlug} alt="error" loading="lazy" />
          </ImageWrapper>
        </Main>
      </Gradient>
    </>
  )
}

const Main = styled.div({
  fontFamily: 'Nunito Sans, Arial, Helvetica, sans-serif',
  padding: 0,
  margin: 0,
  minHeight: '100vh',
  color: COLOR.WHITE,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
})

const Logo = styled.img({
  position: 'absolute',
  marginLeft: 32,
  marginTop: 36,
  width: 103,
  height: 24,
})

const Gradient = styled.div({
  position: 'relative',
  background: 'radial-gradient(ellipse 150% 43% at 50% 62%,rgb(57,97,192) 0%,rgb(12,29,86) 100%)',
  [bp.md]: {
    background: 'radial-gradient(ellipse 47% 43% at 50% 62%,rgb(57,97,192) 0%,rgb(12,29,86) 100%)',
  },
})

const flicker = keyframes`
  0% {
    opacity:0.1;
  }
  2% {
    opacity:1;
  }
  4% {
    opacity:0.1;
  }
  
  8% {
    opacity:1;
  }
  70% {
    opacity:0.7;
  }
  100% {
    opacity:1;
  }
`

const inverseFlicker = keyframes`
  0% {
    opacity: 1;
  }
  2% {
    opacity:.7;
  }
  4% {
    opacity:1;
  }
  
  8% {
    opacity:.8;
  }
  70% {
    opacity:1;
  }
  100% {
    opacity: .8;
  }
`

const Darker = styled.span({
  opacity: 0.8,
  animation: `${inverseFlicker} 3s linear infinite`,
})

const Title = styled.h1({
  margin: 0,
  padding: 0,
  lineHeight: '84px',
  fontWeight: 'bold',
  fontSize: 84,
  marginTop: 172,
  textShadow: `0px 0px 10px ${COLOR.WHITE}`,
  animation: `${flicker} 3s linear infinite`,
  [bp.md]: {
    marginTop: 220,
    lineHeight: '45px',
    marginBottom: 42,
    fontSize: 120,
  },
})

const Description = styled.p({
  fontSize: 14,
  lineHeight: '19px',
  margin: 0,
  marginBottom: 16,
  [bp.md]: {
    marginBottom: 24,
    lineHeight: '27px',
    fontSize: 20,
  },
})

const HomeButton = styled(Link)({
  fontWeight: 'bold',
  fontSize: 11,
  textDecoration: 'none',
  color: COLOR.WHITE,
  borderRadius: 3,
  border: `1px solid ${COLOR.WHITE}`,
  padding: '6px 12px',
  marginBottom: 24,
  '&:visited': {
    color: COLOR.WHITE,
  },
  '&:hover': {
    color: COLOR.WHITE,
    textDecoration: 'none',
  },
  [bp.md]: {
    fontSize: 16,
    borderRadius: 4,
    padding: '8px 16px',
    marginBottom: 0,
  },
})

const ImageWrapper = styled.div({
  width: '172vw',
  marginTop: 'initial',
  [bp.md]: {
    marginTop: 'initial',
    width: '100vw',
  },
  '& img': {
    width: '100%',
    height: 'auto',
  },
})

export { RouterError }
