import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  Contract,
  ContractsGetResponse,
  ContractsV2GetResponse,
  ContractsUpdateResponse,
  Contract20220201GetResponse,
  PlanTermsGetResponse,
} from '@helloextend/extend-api-client'
import {
  mapContractCoverage,
  mapContractsGetToContract,
  mapContractsGet20220201ToContract,
} from '@helloextend/core-api-redux/src/contracts/reducers/by-id'
import { baseQuery, X_EXTEND_ACCESS_TOKEN } from '../base-query'
import type {
  ContractSearchQueryStringOptions,
  ContractSearchV1QueryStringOptions,
  ContractsSearchResponse,
  ContractsSearchV1Response,
  UpdateContractRequest,
  GetContractV1Params,
  GetContractParams,
  ResendContractEmailRequest,
  GetPlanTermsParams,
} from './types'
import { safeBtoa } from '../../helpers'

const buildCacheKeyV1 = (qs: ContractSearchV1QueryStringOptions): string => {
  return safeBtoa(JSON.stringify(qs))
}

const buildCacheKey = (qs: ContractSearchQueryStringOptions): string => {
  const { cursor, limit, ...qsWithoutPagination } = qs
  return safeBtoa(JSON.stringify(qsWithoutPagination))
}

export const contractsApi = createApi({
  baseQuery,
  reducerPath: 'Contracts',
  tagTypes: ['Contracts'],
  endpoints: (build) => ({
    // TODO: Temporary service until issue is resolved around
    // all roles accessing coverage amount details
    // https://helloextend.atlassian.net/browse/CONTRACTS-991
    getContractV1: build.query<Contract, GetContractV1Params>({
      query: ({ storeId, contractId, apiVersion = 'latest' }) => ({
        url: `/stores/${storeId}/contracts/search`,
        params: { contractId },
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
        },
      }),
      transformResponse: (contractList: Contract[]) => {
        return contractList[0]
      },
    }),
    getContract: build.query<Contract, GetContractParams>({
      query: ({ contractId, apiVersion = 'latest', accessToken }) => ({
        url: `contracts/${contractId}`,
        headers: {
          accept: `application/json; version=${apiVersion};`,
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
        },
      }),
      providesTags: (_, _err, { contractId }) => [{ type: 'Contracts', id: contractId }],
      transformResponse: (
        contract: ContractsGetResponse | ContractsV2GetResponse | Contract20220201GetResponse,
        _meta,
        { apiVersion },
      ) => {
        if (apiVersion === '2022-02-01') {
          return mapContractCoverage(
            mapContractsGet20220201ToContract(contract as Contract20220201GetResponse),
          )
        }
        const hasOrderId = 'orderId' in contract
        const contractWithVersion = hasOrderId
          ? ({
              ...contract,
              contractVersion: '2.0',
            } as ContractsV2GetResponse)
          : ({
              ...contract,
              contractVersion: '1.0',
            } as ContractsGetResponse)

        return mapContractCoverage(mapContractsGetToContract(contractWithVersion))
      },
    }),
    getContract20220201: build.query<Contract20220201GetResponse, GetContractParams>({
      query: ({ contractId, apiVersion = '2022-02-01', accessToken }) => ({
        url: `contracts/${contractId}`,
        headers: {
          accept: `application/json; version=${apiVersion};`,
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
        },
      }),
      providesTags: (_, _err, { contractId }) => [{ type: 'Contracts', id: contractId }],
    }),
    resendContractEmail: build.mutation<null, ResendContractEmailRequest>({
      query: ({ contractId, forwardTo }) => ({
        url: `/contracts/${contractId}/resend-email`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
        body: { forwardTo },
      }),
    }),
    searchContractsV1: build.query<ContractsSearchV1Response, ContractSearchV1QueryStringOptions>({
      query: ({ apiVersion = 'latest', ...qs }) => ({
        url: 'contracts/search',
        params: {
          ...qs,
          typeFilter: qs.typeFilter?.join(',') ?? 'pcrs,shipping_protection',
        },
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
        },
      }),
      providesTags: (_, _err, qs) => {
        return [{ type: 'Contracts', id: buildCacheKeyV1(qs) }]
      },
    }),
    searchContracts: build.query<ContractsSearchResponse, ContractSearchQueryStringOptions>({
      query: ({ apiVersion = 'latest', ...qs }) => ({
        url: 'contracts/search',
        params: { typeFilter: ['pcrs', 'shipping_protection'], ...qs },
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
        },
      }),
      providesTags: (_, _err, qs) => {
        return [{ type: 'Contracts', id: buildCacheKey(qs) }]
      },
      async onCacheEntryAdded(arg, { getState, updateCachedData, cacheDataLoaded }) {
        const { cursor } = arg
        const { Contracts } = getState()
        if (cursor?.length) {
          const queryValues = Contracts.queries
          const cacheKey = buildCacheKey(arg)
          const getCachedKeys = Contracts.provided.Contracts[cacheKey]
          const cachedQueries = getCachedKeys.reduce(
            (newArr: ContractsSearchResponse['items'], cachedDataKey) => {
              const cache = queryValues[cachedDataKey]
              const { items } = cache?.data as ContractsSearchResponse
              return [...newArr, ...items]
            },
            [],
          )
          try {
            const { data } = await cacheDataLoaded

            updateCachedData((draft) => ({
              ...draft,
              items: [...cachedQueries, ...data.items],
            }))
          } catch (err) {
            console.error(err)
          }
        }
      },
    }),
    updateContract: build.mutation<ContractsUpdateResponse, UpdateContractRequest>({
      query: ({ apiVersion = 'latest', contractId, data }: UpdateContractRequest) => ({
        url: `contracts/${contractId}`,
        method: 'PUT',
        body: data,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
        },
      }),
      invalidatesTags: ['Contracts'],
    }),
    exportContracts: build.mutation<string, string>({
      query: (sellerId) => ({
        url: `contracts/csv-export?sellerId=${sellerId}`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest',
        },
      }),
    }),
    getTermsVersionLanguageUrl: build.query<string, GetPlanTermsParams>({
      query: ({ apiVersion = 'latest', termsId, version, language = 'en' }) => ({
        url: `/plans/terms/${termsId}/versions/${version}/languages/${language}`,
        headers: {
          accept: `application/json; version=${apiVersion};`,
        },
      }),
      transformResponse: (response: PlanTermsGetResponse) => {
        return response.url
      },
    }),
  }),
})

export const {
  useGetContractQuery,
  useGetContract20220201Query,
  useSearchContractsQuery,
  useSearchContractsV1Query,
  useLazySearchContractsV1Query,
  useUpdateContractMutation,
  useGetContractV1Query,
  useResendContractEmailMutation,
  useExportContractsMutation,
  useGetTermsVersionLanguageUrlQuery,
} = contractsApi
