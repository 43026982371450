import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@helloextend/client-branding'

type HelpIconProps = {
  fill?: string
}

/**
 * @deprecated Use Zen Icon component instead: `import { Icon } from '@helloextend/zen'`
 */
const HelpIcon: FC<HelpIconProps> = ({ fill = COLOR.BLACK_MUTED }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
    <path
      d="M5.653 11.305a5.652 5.652 0 110-11.304 5.652 5.652 0 010 11.304zm0-10.583a4.931 4.931 0 100 9.862 4.931 4.931 0 000-9.862z"
      fill={fill}
    />
    <path
      d="M5.653 7.369a.36.36 0 01-.361-.36v-.26a1.48 1.48 0 01.875-1.337 1.253 1.253 0 00.743-1.215 1.26 1.26 0 00-2.519.06.36.36 0 01-.722 0 1.982 1.982 0 012.075-1.98 1.995 1.995 0 011.887 1.887 1.972 1.972 0 01-1.168 1.9.758.758 0 00-.45.684v.26a.36.36 0 01-.36.361zM5.653 9.213a.572.572 0 110-1.145.572.572 0 010 1.145z"
      fill={fill}
    />
  </svg>
)

export { HelpIcon }
