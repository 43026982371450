import type { Reply, Slot } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const update = (sessionToken: string, slot: Slot, slotValue: string | number) =>
  ({
    type: 'INCREDIBOT_SESSION_UPDATE',
    payload: { sessionToken, slot, slotValue },
  } as const)

export const updateRequest = () =>
  ({
    type: 'INCREDIBOT_SESSION_UPDATE_REQUEST',
  } as const)

export const updateSuccess = (reply: Reply) =>
  ({
    type: 'INCREDIBOT_SESSION_UPDATE_SUCCESS',
    payload: reply,
  } as const)

export const updateFailure = createActionFailure('INCREDIBOT_SESSION_UPDATE_FAILURE')
