import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@helloextend/zen'
import { BasicModal } from '../basic-modal'

type LeavePageModalProps = {
  handleLeavePage: () => void
  handleCloseModal: () => void
  isVisible: boolean
  mainText?: string
  detail?: string
  confirmation?: string
  submitButtonText?: string
  overflow?: string
}

const LeavePageModal: FC<LeavePageModalProps> = ({
  handleLeavePage,
  handleCloseModal,
  isVisible,
  mainText = 'Hang on! You have some unsaved changes on this page',
  detail = 'If you leave, we will discard your unsaved changes, or you can click cancel and save your changes.',
  confirmation = 'Are you sure you want to leave?',
  submitButtonText = 'Leave Page',
  overflow = 'scroll',
}) => (
  <BasicModal onClickClose={handleCloseModal} isVisible={isVisible} overflow={overflow}>
    <Title data-cy="hang-on-unsaved-message">{mainText}</Title>
    {Boolean(detail) && <p data-cy="unsaved-message-copy">{detail}</p>}
    {Boolean(confirmation) && <p data-cy="modal-leave-confirmation">{confirmation}</p>}
    <ButtonGroup>
      <Button text="Cancel" data-cy="cancel-btn" emphasis="medium" onClick={handleCloseModal} />
      <Button
        text={submitButtonText}
        data-cy="leave-page-btn"
        color="red"
        onClick={handleLeavePage}
      />
    </ButtonGroup>
  </BasicModal>
)

const Title = styled.h3({
  fontSize: 20,
  marginBottom: 16,
})

const ButtonGroup = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 16,
  marginTop: 24,
})

export { LeavePageModal, LeavePageModalProps }
