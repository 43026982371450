import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Stack } from '../stack'
import type { RadioGroupProps } from './radio-group-types'
import { getLayoutProperties } from './radio-group-utils'
import { Information } from '../information'
import { COLOR } from '../../tokens/colors'
import RadioGroupContext from './radio-group-context'

export const RadioGroup: FC<RadioGroupProps> = ({
  children,
  disabled = false,
  errorFeedback,
  helperText,
  label,
  layout = 'horizontal',
  isError = false,
  name,
  onChange,
  value,
  'data-cy': dataCy,
}) => {
  const layoutProperties = getLayoutProperties(layout)

  return (
    <RadioGroupContext.Provider
      value={{
        disabled,
        name,
        onChange,
        value,
      }}
    >
      <StyledRadioGroup data-cy={dataCy}>
        {(!!label || !!helperText) && (
          <RadioGroupHeader>
            {Label && (
              <Label id={name} data-cy={dataCy && `${dataCy}:label`}>
                {label}
              </Label>
            )}
            {helperText && (
              <InformationWrapper>
                <Information buttonSize="xsmall" data-cy={dataCy && `${dataCy}:Information`}>
                  {helperText}
                </Information>
              </InformationWrapper>
            )}
          </RadioGroupHeader>
        )}
        <Radios isError={isError} aria-labelledby={name}>
          <Stack horizontalSpacing={3} direction={layoutProperties.direction} doesWrap>
            {children}
          </Stack>
        </Radios>
        {!!errorFeedback && !!isError && (
          <RadioGroupFooter>
            {!!errorFeedback && (
              <Feedback data-cy={dataCy && `${dataCy}:feedback`}>
                <ErrorMessage>{errorFeedback}</ErrorMessage>
              </Feedback>
            )}
          </RadioGroupFooter>
        )}
      </StyledRadioGroup>
    </RadioGroupContext.Provider>
  )
}

const StyledRadioGroup = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
})

const RadioGroupHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
})

const Label = styled.label({
  fontSize: 14,
  lineHeight: '22px',
  fontWeight: 800,
  color: COLOR.NEUTRAL[1000],
})

const InformationWrapper = styled.div({
  margin: -4,
})

const Radios = styled.div<{ isError: boolean }>(({ isError }) => ({
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 4,
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderWidth: '1px 0',
  ...(isError && {
    borderWidth: 1,
    borderColor: COLOR.RED[700],
    paddingLeft: 8,
    paddingRight: 8,
  }),
}))

const RadioGroupFooter = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
})

const Feedback = styled.div({
  fontSize: 15,
  lineHeight: '20px',
  color: COLOR.RED[700],
})

const ErrorMessage = styled.span({
  color: COLOR.RED[700],
})
