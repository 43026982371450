import type { FC } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import type { InsuranceClaim } from '@helloextend/extend-api-client'
import {
  useCreateClaimPhotoMutation,
  useGetClaimPhotosQuery,
  useUploadClaimPhotoMutation,
} from '@helloextend/extend-api-rtk-query'
import { Stack } from '@helloextend/zen'
import { StackDirection, StackJustify } from '@helloextend/zen/src/components/stack'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { usePrevious } from '@helloextend/client-hooks'
import { ImageUpload } from '../../../../../../components/image-upload'
import { ConfirmPhotoDeleteModal } from '../confirm-photo-delete'

const photoIdRegex = /photo\/([A-Za-z0-9-]*)/
const RETRY_ATTEMPTS = 5

interface ClaimPhotosSectionProps {
  claim: InsuranceClaim
}

const ClaimPhotosSection: FC<ClaimPhotosSectionProps> = ({ claim }) => {
  const { data: photoData } = useGetClaimPhotosQuery({
    basePath: 'claims-management',
    claimId: claim.id,
    contractId: claim.contractId,
  })

  const dispatch = useDispatch()

  const [createPhoto, { data: uploadPhotoData }] = useCreateClaimPhotoMutation()
  const [uploadPhoto, { isSuccess, isError }] = useUploadClaimPhotoMutation()
  const prevSuccess = usePrevious(isSuccess)
  const prevError = usePrevious(isError)
  const [currentCreatePhoto, setCurrentCreatePhoto] = useState<string | null>(null)
  const [isFetching, setIsFetching] = useState(false)

  const [deletePhoto, setDeletePhoto] = useState<{ claimId: string; photoId: string } | null>(null)
  const [uploadFile, setUploadFile] = useState<File | null>(null)

  const fetchClaimPhotos = useCallback(() => {
    let retryAttempts = 0
    const intervalId = setInterval(() => {
      if (
        retryAttempts >= RETRY_ATTEMPTS ||
        photoData?.photoDetails.some((photo) => photo.name === currentCreatePhoto && photo.url)
      ) {
        setIsFetching(false)
        clearInterval(intervalId)
        setCurrentCreatePhoto(null)
      }

      retryAttempts += 1

      dispatch({
        type: `Claims/invalidateTags`,
        payload: ['claim-photos'],
      })
    }, 2000)
  }, [currentCreatePhoto, dispatch, photoData, setIsFetching])

  useEffect(() => {
    if (isFetching) return
    if (uploadFile && uploadPhotoData) {
      const { url, fields } = uploadPhotoData
      const formData = new FormData()
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value)
      })
      formData.append('file', uploadFile)
      uploadPhoto({ url, body: formData })
      setUploadFile(null)
    }

    if ((isSuccess && !prevSuccess) || (isError && !prevError)) {
      fetchClaimPhotos()
      setIsFetching(true)
    }
  }, [
    setUploadFile,
    uploadFile,
    uploadPhoto,
    uploadPhotoData,
    isSuccess,
    fetchClaimPhotos,
    isError,
    isFetching,
    setIsFetching,
    prevSuccess,
    prevError,
  ])

  const handleDownloadClick = (url?: string): void => {
    if (!url) return
    window.open(url, '_blank')
  }

  const handleDeleteClick = (url?: string): void => {
    if (!url) return
    const regexResult = photoIdRegex.exec(url)
    if (!regexResult || regexResult.length < 2) return
    const photoId = regexResult[1]
    setDeletePhoto({ claimId: claim.id, photoId })
  }

  const handleModalClose = (): void => {
    setDeletePhoto(null)
  }

  const handlePhotoUpload = (file: File, name: string): void => {
    setCurrentCreatePhoto(name)
    setUploadFile(file)
    createPhoto({ claimId: claim.id, body: { source: 'ops_admin', name } })
  }

  return (
    <>
      {photoData?.photoDetails && (
        <Container>
          <HeaderTitle>Claim Photos</HeaderTitle>
          <Stack direction={StackDirection.column}>
            {photoData.photoDetails.map((details) => {
              return (
                <RowContainer key={details.name}>
                  <Stack spacing={3} justify={StackJustify.spaceBetween}>
                    <ImageUpload
                      title="Customer's Photo"
                      currentImage={details.url}
                      onChange={(file: File) => handlePhotoUpload(file, details.name)}
                      onDeleteClick={() => handleDeleteClick(details.url)}
                      onDownloadClick={() => handleDownloadClick(details.url)}
                    />
                  </Stack>
                </RowContainer>
              )
            })}
          </Stack>
        </Container>
      )}
      <ConfirmPhotoDeleteModal
        isVisible={Boolean(deletePhoto)}
        onClose={handleModalClose}
        claimId={deletePhoto?.claimId}
        photoId={deletePhoto?.photoId}
      />
    </>
  )
}

const Container = styled.div({
  maxWidth: 1550,
})
const RowContainer = styled.div({
  width: '100%',
})

const HeaderTitle = styled.h2({
  fontSize: 24,
  lineHeight: '33px',
  margin: 0,
  marginBottom: 50,
})

export { ClaimPhotosSection }
