import type { PlanCreateRequest } from '@helloextend/extend-api-client'
import type { PlanImportFile } from '../pages/admin/plans-import/actions/import'

const parsePlans = async (files: File[]): Promise<PlansParseResult> => {
  const result = await files.reduce(async (acc, file) => {
    const text = await file.text()
    const currentAcc = await acc
    try {
      const planValues: PlanCreateRequest = JSON.parse(text)
      if (!planValues || !planValues.id) {
        currentAcc.failed.push(file.name)
      } else {
        currentAcc.parsed.push({ file, plan: planValues })
      }
    } catch (ex) {
      currentAcc.failed.push(file.name)
    }
    return acc
  }, Promise.resolve<PlansParseResult>({ failed: [], parsed: [], errorMessage: '' }))

  let unique = true
  const ids: string[] = []
  result.parsed.forEach((current) => {
    if (ids.includes(current.plan.id)) {
      unique = false
    } else {
      ids.push(current.plan.id)
    }
  })
  if (!unique) {
    return {
      failed: [],
      parsed: [],
      errorMessage: 'Files within a batch must contain unique plan ids',
    }
  }
  return result
}

interface PlansParseResult {
  parsed: PlanImportFile[]
  failed: string[]
  errorMessage: string
}

export { parsePlans }
