export function unescape(str: string): string {
  return (str + '==='.slice((str.length + 3) % 4)).replace(/-/g, '+').replace(/_/g, '/')
}

export function escape(str: string): string {
  return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

export const base64UrlEncode = (buf: Buffer): string => {
  return buf.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
}

export const base64UrlDecode = (str: string): Buffer => {
  let s = str.replace(/-/g, '+').replace(/_/g, '/')
  while (s.length % 4) {
    s += '='
  }
  return Buffer.from(s, 'base64')
}
