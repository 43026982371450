import { combineReducers } from 'redux'
import type { SupportStatusReducer } from './support-status'
import { supportStatus } from './support-status'
import { toggleSupport } from './toggle-support'
import isLoading from './is-loading'

export interface SupportReducerState {
  supportStatus: SupportStatusReducer
  toggleSupport: boolean
  isLoading: boolean
}

export default combineReducers<SupportReducerState>({
  supportStatus,
  toggleSupport,
  isLoading,
})
