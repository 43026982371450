import type { FC } from 'react'
import React from 'react'
import { DataTable } from '@helloextend/zen'
import { useListPlanSetsByPlanSetIdsQuery } from '@helloextend/extend-api-rtk-query'
import type { PlanCategory, PlanSet, StorePlanSetMapping } from '@helloextend/extend-api-client'
import type { StorePlanSetTableData } from './plan-sets-data-table-config'
import { planSetsDataTableColumns } from './plan-sets-data-table-config'

type PlanSetsDataTableProps = {
  storeId: string
  planSetMappingList: StorePlanSetMapping[]
  categoryList: PlanCategory[]
}

const PlanSetsDataTable: FC<PlanSetsDataTableProps> = ({
  storeId,
  planSetMappingList = [],
  categoryList = [],
}) => {
  const planSetIds = planSetMappingList.map((mapping: StorePlanSetMapping) => {
    return mapping.planSetId
  })
  const categoriesListOptimized: Record<string, PlanCategory> = {}
  const planSetListDataOptimized: Record<string, PlanSet> = {}

  categoryList?.forEach((category: PlanCategory) => {
    categoriesListOptimized[category.id] = { ...category }
  })
  const {
    data: planSetListData,
    isLoading,
    isFetching,
    isError,
  } = useListPlanSetsByPlanSetIdsQuery(planSetIds?.join(',') ?? '')

  const sortedPlanSetListSorted =
    planSetListData?.items?.length && planSetListData?.items.length > 0
      ? planSetListData?.items
          .map((setListItem) => ({
            ...setListItem,
            storeId,
          }))
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
      : []

  sortedPlanSetListSorted.forEach((category: PlanSet) => {
    planSetListDataOptimized[category.id] = { ...category }
  })
  const tableData: StorePlanSetTableData[] = []
  planSetMappingList?.forEach((mapping: StorePlanSetMapping) => {
    if (
      categoriesListOptimized[mapping.planCategoryId] &&
      planSetListDataOptimized[mapping.planSetId]
    ) {
      const combinedData = {
        planSetMapping: { ...mapping },
        planCategory: categoriesListOptimized[mapping.planCategoryId],
        planSet: planSetListDataOptimized[mapping.planSetId],
      }
      tableData.push(combinedData)
    }
  })

  return (
    <DataTable
      data-cy="plan-sets-data-table"
      isLoading={isLoading || isFetching}
      isError={isError}
      data={tableData}
      stateViews={{
        skeleton: { message: 'Please wait while we retrieve store plan set data' },
        error: { heading: 'Unable to retrieve store plan set data' },
        noData: {
          heading: 'No plan sets yet',
          description: 'Click “Add Plan Set” to select plan sets.',
        },
      }}
      columns={planSetsDataTableColumns}
      hasConfigurableColumns={false}
    />
  )
}

export { PlanSetsDataTable }
