import type {
  PlanCreateRequest,
  ServiceType,
  CurrencyCode,
  PlanSubcategory,
} from '@helloextend/extend-api-client'
import type { Values } from '../pages/admin/plan/schema'
import { arrayToPlanPricing, arrayToPlanMatching } from './plans-mapping'

const stringIntoArray = (inputValue?: string): string[] => {
  if (!inputValue) return []
  return inputValue.split(',').map((value) => value.trim())
}

const planPropertyMapper = (values: Values): PlanCreateRequest => {
  return {
    id: values.id.trim(),
    name: values.name,
    active: values.active,
    secondary_name: values.secondaryName,
    contract: {
      category: values.warrantyCategory.trim(),
      coverage_starts: values.coverageStarts.trim(),
      coverage_includes: values.coverageIncludes,
      term_length: values.termLength,
      pcmi_category: values.pcmiCategory,
      pcmi_subcategory: values.pcmiSubcategory as PlanSubcategory,
      servicer_ids: values.servicerId
        ? values.servicerId.split(',').map((value) => value.trim())
        : [],
      service_type: values.serviceType.trim() as ServiceType,
      deductible: values.deductible ?? 0,
      replacement_type: values.replacementType?.trim() ?? '',
    },
    termsId: values.termsId.trim(),
    underwriter: values.underwriter.trim(),
    administrator: values.administrator ?? '',
    product_notes: values.productNotes,
    allowed_regions: stringIntoArray(values.allowedRegions),
    blocked_sub_division: stringIntoArray(values.blockedSubDivisions),
    currency_code: values.currencyCode.trim() as CurrencyCode,
    tags: values.tags,
    product_types: values.productTypes.trim(),
    vendor: values.vendor,
    program: values.program,
    sub_program: values.subProgram,
    plan_category: values.planCategory,
    wd_tags: stringIntoArray(values.wdTags),
    ...(values.repairThreshold ? { repair_threshold: Number(values.repairThreshold) } : {}),
    pricing: arrayToPlanPricing(values.pricing),
    matching: arrayToPlanMatching(values.pricing),
  }
}

export { planPropertyMapper }
