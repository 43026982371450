import type { FC, SyntheticEvent, ReactNode, ReactElement, ChangeEvent } from 'react'
import React, { useEffect, useState, useCallback } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import { Button, Icon, Input } from '@helloextend/zen'
import { ArrowDropDown, Search } from '@helloextend/zen/src/tokens/icons'

type SelectProps = {
  value: string
  onChange: (e: SyntheticEvent) => void
  placeholder?: string
  inline?: boolean
  label?: string
  labelColor?: string
  labelMarginBottom?: number
  labelDisplayType?: string
  menuHeight?: number
  error?: boolean
  errorMessage?: string
  darkErrorMessageLabel?: boolean
  search?: boolean
  searchInDropdown?: boolean
  isDisabled?: boolean
  onSearchChange?: (e: ChangeEvent<HTMLInputElement>) => void
  'data-cy'?: string
}

const Select: FC<SelectProps> = ({
  value,
  children,
  onChange,
  placeholder,
  label,
  labelColor,
  labelMarginBottom,
  labelDisplayType,
  menuHeight = 300,
  inline,
  search,
  searchInDropdown,
  error,
  isDisabled = false,
  errorMessage = '',
  darkErrorMessageLabel = true,
  onSearchChange,
  'data-cy': dataCy,
}) => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)

  const handleClick = (): void => {
    document.addEventListener('click', hideMenu)
    setIsMenuVisible(true)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!onSearchChange) return
    onSearchChange(event)
    document.addEventListener('click', hideMenu)
    setIsMenuVisible(true)
  }

  const hideMenu = useCallback((e): void => {
    if (e.target.id === 'search') return
    document.removeEventListener('click', hideMenu)
    setIsMenuVisible(false)
  }, [])

  useEffect(() => {
    return () => document.removeEventListener('click', hideMenu)
  }, [hideMenu])

  const childrenArray: ReactNode[] = React.Children.toArray(children)

  const handleItemClick =
    (child: ReactElement) =>
    (event: SyntheticEvent<Element>): void => {
      if (child.props.onClick) {
        child.props.onClick(event)
      }

      onChange(event)
    }

  const items = childrenArray.map((child) => {
    if (!React.isValidElement(child)) {
      return null
    }
    const selected = child.props.value === value
    return React.cloneElement(child, {
      selected,
      isSelected: selected,
      onClick: handleItemClick(child),
      role: 'option',
    })
  })

  const selectedValue = items.find((item) => item?.props.value === value)

  return (
    <>
      {label && (
        <Label
          color={labelColor}
          isDisabled={isDisabled}
          labelDisplayType={labelDisplayType}
          labelMarginBottom={labelMarginBottom}
        >
          {label}
        </Label>
      )}
      <SelectWrapper active={isMenuVisible} inline={inline} searchInDropdown={searchInDropdown}>
        {searchInDropdown && (
          <ButtonWrapper>
            <Button
              icon={ArrowDropDown}
              color="blue"
              emphasis="low"
              onClick={handleClick}
              data-cy={dataCy || 'select-button'}
            />
          </ButtonWrapper>
        )}
        {search && !searchInDropdown && (
          <SearchInput
            onChange={handleSearchChange}
            onClick={handleClick}
            value={value}
            error={error}
            disabled={isDisabled}
          />
        )}
        {!search && !searchInDropdown && (
          <SelectButton
            error={Boolean(error)}
            type="button"
            onClick={handleClick}
            inline={Boolean(inline)}
            disabled={isDisabled}
            data-cy={dataCy || 'select-button'}
          >
            {selectedValue?.props.label || placeholder}
          </SelectButton>
        )}
        <Menu menuHeight={menuHeight} hidden={!isMenuVisible}>
          {searchInDropdown && (
            <InputContainer>
              <Input
                onChange={handleSearchChange}
                id="search"
                value={value}
                icon={Search}
                placeholder="Search"
                data-cy="dropdown-search-input"
              />
            </InputContainer>
          )}
          {items}
        </Menu>
        {!searchInDropdown && <Icon icon={ArrowDropDown} color={COLOR.BLACK_SECONDARY} />}
      </SelectWrapper>
      {error && (
        <ErrorMessage darkErrorMessageLabel={darkErrorMessageLabel}>{errorMessage}</ErrorMessage>
      )}
    </>
  )
}

const Label = styled.label<{
  color?: string
  isDisabled?: boolean
  labelMarginBottom?: number
  labelDisplayType?: string
}>(({ color = COLOR.BLACK_PRIMARY, isDisabled = false, labelMarginBottom, labelDisplayType }) => ({
  boxSizing: 'border-box',
  fontFamily: 'Nunito Sans, sans-serif',
  fontSize: 14,
  lineHeight: '19px',
  fontWeight: 700,
  paddingBottom: 8,
  color: isDisabled ? COLOR.DARK_GRAY : color,
  ...(labelMarginBottom && { marginBottom: 2 }),
  ...(labelDisplayType && { display: 'block' }),
}))

const SelectButton = styled.button<{ error: boolean; inline: boolean }>(({ error, inline }) => ({
  border: error ? `1px solid ${COLOR.STATE_DANGER}` : `1px solid ${COLOR.GHOST}`,
  background: COLOR.WHITE,
  color: COLOR.BLACK_PRIMARY,
  boxSizing: 'border-box',
  display: 'block',
  borderRadius: 4,
  borderTopRightRadius: inline ? 0 : 4,
  borderBottomRightRadius: inline ? 0 : 4,
  cursor: 'pointer',
  fontFamily: 'Nunito Sans, sans-serif',
  fontSize: 16,
  position: 'relative',
  width: '100%',
  height: 38,
  textAlign: 'start',
  paddingLeft: 16,
  ':hover': {
    borderColor: COLOR.GRAYISH_BLUE_0,
  },
  ':focus': {
    borderColor: COLOR.BRIGHT_BLUE,
    boxShadow: `0 0 0 1px inset ${COLOR.EXTEND_BLUE}`,
    outline: 'none',
  },
  ':disabled': {
    background: COLOR.LIGHT_GRAYISH_BLUE_1,
  },
}))

const Menu = styled.div<{
  menuHeight: number
}>(({ menuHeight }) => ({
  backgroundColor: COLOR.WHITE,
  border: `1px solid ${COLOR.LIGHT_GRAYISH_BLUE_0}`,
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: 4,
  position: 'absolute',
  width: '100%',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.1)',
  zIndex: 1,
  maxHeight: menuHeight,
  overflowY: 'auto',
}))

const ButtonWrapper = styled.div({
  paddingTop: 2,
  Button: {
    marginLeft: 4,
    height: 20,
    width: 20,
  },
})

const InputContainer = styled.div({
  width: 'calc(100% - 25px)',
  padding: '10px 15px 10px 10px',
})

const SelectWrapper = styled.div<{
  active?: boolean
  inline?: boolean
  searchInDropdown?: boolean
}>(({ active, inline, searchInDropdown }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  borderRadius: 4,
  ...(searchInDropdown && { minWidth: 250 }),
  borderTopRightRadius: inline ? 0 : 4,
  borderBottomRightRadius: inline ? 0 : 4,
  '& > svg': {
    position: 'absolute',
    right: 12,
    top: '50%',
    transform: active ? 'translateY(-50%) rotate(-180deg)' : 'translateY(-50%)',
    transition: '250ms transform ease-in-out',
    pointerEvents: 'none',
  },
}))

const ErrorMessage = styled.div<{
  darkErrorMessageLabel: boolean
}>(({ darkErrorMessageLabel }) => ({
  fontFamily: 'Nunito Sans',
  color: COLOR.STATE_DANGER,
  fontSize: 12,
  ...(darkErrorMessageLabel && { fontWeight: 700 }),
  marginTop: 4,
}))

const SearchInput = styled.input<{ error?: boolean }>(({ error }) => ({
  border: error ? `1px solid ${COLOR.STATE_DANGER}` : `1px solid ${COLOR.LIGHT_GRAYISH_BLUE_0}`,
  borderRadius: 4,
  boxSizing: 'border-box',
  fontFamily: "'Nunito Sans', Helvetica, sans-serif",
  fontSize: 14,
  lineHeight: '38px',
  padding: '0 12px',
  width: '100%',
  margin: 0,
  '&::placeholder': {
    color: COLOR.WHITE_SECONDARY,
  },
  '&:hover': {
    borderColor: COLOR.LIGHT_GRAYISH_BLUE_0,
  },
  '&:focus': {
    border: `1px solid ${COLOR.BRIGHT_BLUE}`,
    boxShadow: `0 0 0 1px inset ${COLOR.BRIGHT_BLUE}`,
    outline: 'none',
  },
}))

export { Select, SelectProps }
