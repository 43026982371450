import { Permission } from '../../lib/permissions'

export const adminNavList = [
  {
    text: 'Contracts',
    to: '/admin/contracts',
    permission: Permission.ManageContracts,
    dataCy: 'contracts-header',
  },
  {
    text: 'Claims',
    to: '/admin/claims',
    permission: Permission.ManageClaims,
    dataCy: 'claims-header',
  },
  {
    text: 'Programs',
    dropdownItems: [
      {
        text: 'Plans',
        to: '/admin/plans',
        permission: Permission.ManagePlans,
        dataCy: 'plans-header',
      },
      {
        text: 'Plan Sets',
        to: '/admin/plan-sets',
        permission: Permission.ManagePlanSets,
        dataCy: 'plan-sets',
      },
      {
        text: 'Premiums',
        to: '/admin/premiums',
        permission: Permission.ManageSkus,
        dataCy: 'premiums-header',
      },
      {
        text: 'Insurance Programs',
        to: '/admin/insurance-programs',
        permission: Permission.ManageSkus,
        dataCy: 'insurance-programs-header',
      },
      {
        text: 'Terms',
        to: '/admin/terms',
        permission: Permission.ManageTerms,
        dataCy: 'terms-header',
      },
    ],
    dataCy: 'programs-dropdown-header',
  },
  {
    text: 'Adjudication Management',
    to: '/admin/adjudication-management',
    permission: Permission.ManageAdjudication,
    dataCy: 'adjudication-management-header',
  },
  {
    text: 'Merchants',
    permission:
      Permission.ManageStores || Permission.ManageAccounts || Permission.ManageOrganizations,
    dataCy: 'merchants-dropdown-header',
    dropdownItems: [
      {
        text: 'Accounts',
        permission: Permission.ManageAccounts,
        dataCy: 'accounts-merchants-header',
        to: '/admin/accounts',
      },
      {
        text: 'Organizations',
        permission: Permission.ManageOrganizations,
        dataCy: 'organizations-merchants-header',
        to: '/admin/organizations',
      },
      {
        text: 'Stores',
        permission: Permission.ManageStores,
        dataCy: 'stores-merchants-header',
        to: '/admin/stores',
      },
    ],
  },
  {
    text: 'Products',
    to: '/admin/products',
    permission: Permission.ManageProducts,
    dataCy: 'products-header',
  },
  {
    text: 'Servicers',
    to: '/admin/servicers',
    permission: Permission.ManageServicers,
    dataCy: 'servicers-header',
  },
]
