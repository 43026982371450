import type { FC, SyntheticEvent } from 'react'
import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../../tokens/colors'
import { Icon, IconSize } from '../../icon'
import { Information } from '../../information'
import { ArrowDownward, ArrowUpward } from '../../../tokens/icons'
import { CellResizer } from './cell-resizer'
import { useDataTableContext } from '../data-table-context'

export interface HeaderCellProps {
  align?: 'left' | 'center' | 'right'
  children?: React.ReactNode
  index?: number
  information?: React.ReactNode
  isActionsColumn?: boolean
  onClick?: (e: SyntheticEvent<HTMLDivElement>) => void
  sortDirection?: 'asc' | 'desc'
}

export const HeaderCell: FC<HeaderCellProps> = ({
  align = 'left',
  children,
  index = 0,
  information,
  isActionsColumn = false,
  onClick,
  sortDirection,
}) => {
  const cellRef = useRef<HTMLTableCellElement>(null)

  const {
    'data-cy': dataCy,
    columnsPinnedQuantity,
    isTableHorizontallyScrollable,
  } = useDataTableContext()

  const fix = isTableHorizontallyScrollable && index < columnsPinnedQuantity
  const fixRight = isActionsColumn ? isTableHorizontallyScrollable : false

  return (
    <>
      {fix && <CellResizer cellRef={cellRef} />}
      <StyledHeaderCell
        data-cy={dataCy && `${dataCy}:header-cell`}
        align={align}
        ref={cellRef}
        fix={fix}
        fixRight={fixRight}
      >
        {children && typeof children !== 'object' && typeof children !== 'function' ? (
          <HeadingContainer align={align} isSorting={!!sortDirection}>
            <Heading
              data-cy={dataCy && `${dataCy}:column-heading`}
              onClick={onClick}
              hasOnClick={!!onClick}
            >
              {children && <Label>{children}</Label>}
              {sortDirection === 'asc' && <Icon icon={ArrowDownward} size={IconSize.xsmall} />}
              {sortDirection === 'desc' && <Icon icon={ArrowUpward} size={IconSize.xsmall} />}
            </Heading>
            {information && (
              <Information
                data-cy={dataCy && `${dataCy}:${children}:column-information`}
                buttonSize="xsmall"
              >
                {information}
              </Information>
            )}
          </HeadingContainer>
        ) : (
          children
        )}
      </StyledHeaderCell>
    </>
  )
}

const StyledHeaderCell = styled.th<{
  align?: 'left' | 'center' | 'right'
  fix?: boolean
  fixRight?: boolean
}>(({ align = 'left', fix, fixRight }) => ({
  ...(fix && {
    position: 'sticky',
    zIndex: 1,
    backgroundColor: COLOR.NEUTRAL[100],
    '&::after': {
      content: '""',
      position: 'absolute',
      right: -5,
      top: 0,
      bottom: 0,
      width: 5,
      borderLeft: `1px solid ${COLOR.NEUTRAL[300]}`,
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08) 0%, rgba(0,0,0,0) 100%)',
    },
  }),
  ...(fixRight && {
    position: 'sticky',
    right: 0,
    zIndex: 1,
    backgroundColor: COLOR.NEUTRAL[100],
    '&::after': {
      content: '""',
      position: 'absolute',
      left: -5,
      top: 0,
      bottom: 0,
      width: 5,
      borderRight: `1px solid ${COLOR.NEUTRAL[300]}`,
      background: 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.08) 100%)',
    },
  }),
  padding: '0 16px',
  whiteSpace: 'nowrap',
  borderBottom: `1px solid ${COLOR.NEUTRAL[300]}`,
  textAlign: align,
}))

const HeadingContainer = styled.div<{
  isSorting: boolean
  align?: 'left' | 'center' | 'right'
}>(({ isSorting, align = 'left' }) => ({
  display: 'inline-flex',
  margin: '0 -6px',
  paddingTop: 14,
  paddingBottom: 14,
  ...(align === 'left' && {
    paddingRight: isSorting ? 0 : 16,
  }),
  ...(align === 'center' && {
    paddingLeft: isSorting ? 0 : 8,
    paddingRight: isSorting ? 0 : 8,
  }),
  ...(align === 'right' && {
    paddingLeft: isSorting ? 0 : 16,
  }),
}))

const Heading = styled.div<{
  hasOnClick: boolean
}>(({ hasOnClick }) => ({
  display: 'flex',
  padding: '4px 2px',
  borderRadius: 4,
  ...(hasOnClick && {
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      background: COLOR.NEUTRAL.OPACITY[12],
    },
    '&:active': {
      background: COLOR.NEUTRAL.OPACITY[25],
    },
  }),
}))

const Label = styled.span({
  display: 'block',
  padding: '0 4px',
})
