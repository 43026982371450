import { createActionFailure } from '../../factories'

export const changePassword = (oldPassword: string, password: string, accessToken: string) =>
  ({
    type: 'CHANGE_PASSWORD',
    payload: { oldPassword, password, accessToken },
  } as const)

export const changePasswordRequest = () =>
  ({
    type: 'CHANGE_PASSWORD_REQUEST',
  } as const)

export const changePasswordSuccess = (message: string) =>
  ({
    type: 'CHANGE_PASSWORD_SUCCESS',
    payload: message,
  } as const)

export const changePasswordFailure = createActionFailure('CHANGE_PASSWORD_FAILURE')
