// Provides a mechanism to start a window timeout that can be paused/resumed as needed
export class Timer {
  timerId: number | null

  start: number

  remaining: number

  callback: () => void

  constructor(callback: () => void, delay: number) {
    this.remaining = delay
    this.callback = callback

    this.resume()
  }

  resume(): void {
    if (this.timerId) {
      return
    }

    this.start = Date.now()
    this.timerId = window.setTimeout(this.callback, this.remaining)
  }

  clear(): void {
    if (!this.timerId) {
      return
    }

    window.clearTimeout(this.timerId)
    this.timerId = null
  }

  pause(): void {
    this.clear()
    this.remaining -= Date.now() - this.start
  }
}
