import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import dayjs from 'dayjs'
import { Chip, Menu } from '@helloextend/merchants-ui'
import { useClickOutside, useToggle } from '@helloextend/client-hooks'
import styled from '@emotion/styled'
import type { FilterOptions, FilterValues } from './types'
import { Filter } from './filter'

type FilterChipProps = {
  property: string
  filter: FilterOptions
  values: FilterValues
  onFilterChange: (property: string, values: FilterValues | null) => void
}

const formatDate = (date: Date): string => {
  return dayjs(date).format('MM/DD/YYYY')
}

const buildChipText = (filter: FilterOptions, values: FilterValues): string => {
  switch (filter.type) {
    case 'checkbox':
      if (values.type === 'checkbox') {
        return Object.entries(filter.options)
          .filter(([value]) => values.values.includes(value))
          .map(([, label]) => label)
          .join(', ')
      }
      break
    case 'dateRange':
      if (values.type === 'dateRange') {
        if (values.end && values.start) {
          return `${formatDate(values.start)} to ${formatDate(values.end)}`
        }
        if (values.start) {
          return `${formatDate(values.start)} and later`
        }
        if (values.end) {
          return `${formatDate(values.end)} and earlier`
        }
      }
      break
  }

  return ''
}

const FilterChip: FC<FilterChipProps> = ({ property, filter, values, onFilterChange }) => {
  const [isMenuOpen, { toggle, off }] = useToggle()
  const [lockMenu, setLockMenu] = useState(false)

  const { ref } = useClickOutside<HTMLDivElement>(() => {
    if (!lockMenu) {
      off()
    }
  })

  const handleMenuLock = useCallback((isLocked) => {
    setLockMenu(isLocked)
  }, [])

  const handleDismiss = useCallback(() => {
    onFilterChange(property, null)
  }, [property, onFilterChange])

  return (
    <div ref={ref}>
      <Chip
        label={filter.label}
        text={buildChipText(filter, values)}
        isDismissable
        onClick={toggle}
        onDismiss={handleDismiss}
      />
      <ChildWrapper>
        <Menu isOpen={isMenuOpen} position="left" width={216}>
          {filter.type !== 'group' && (
            <Filter
              property={property}
              filter={filter}
              values={values}
              onFilterChange={onFilterChange}
              onMenuLock={handleMenuLock}
            />
          )}
        </Menu>
      </ChildWrapper>
    </div>
  )
}

const ChildWrapper = styled.div({
  position: 'absolute',
  top: 32,
})

export { FilterChip }
