import { useContext } from 'react'
import type { TransitionContextValue } from './animate-presence'
import { TransitionContext } from './animate-presence'

const useTransition = (): TransitionContextValue => {
  const context = useContext(TransitionContext)

  if (!context) {
    return {
      transitionDurationMs: 0,
      isVisible: false,
      isPresent: false,
    }
  }

  return context
}

export { useTransition }
