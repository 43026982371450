import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import { Visibility, VisibilityOff } from '../../../tokens/icons'
import type { InputProps } from '../input/input'
import { Input } from '../input/input'
import { InputType } from '../input/types'

interface PasswordInputProps
  extends Omit<
    InputProps,
    | 'type'
    | 'prefix'
    | 'suffix'
    | 'isMonospace'
    | 'maxLength'
    | 'actionButtonProps'
    | 'startAdornment'
  > {}

const PasswordInput: FC<PasswordInputProps> = (inputProps: PasswordInputProps) => {
  const [isMasked, setIsMasked] = useState(true)
  const handleActionButtonClick = useCallback(() => {
    setIsMasked(!isMasked)
  }, [isMasked])
  return (
    <Input
      {...inputProps}
      type={isMasked ? InputType.password : InputType.text}
      actionButtonProps={{
        emphasis: 'low',
        color: 'neutral',
        icon: isMasked ? VisibilityOff : Visibility,
        onClick: handleActionButtonClick,
      }}
    />
  )
}

export { PasswordInput, PasswordInputProps }
