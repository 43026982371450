import type { FC } from 'react'
import React from 'react'
import type { Store } from '@helloextend/extend-api-client'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ShippingProtectionForm } from '../shipping-protection-form/shipping-protection-form'
import { SectionContainer } from '../../../../../../../../components/section-container'
import { ShippingProtectionOfferType } from '../shipping-protection-offer-type/shipping-protection-offer-type'
import { LDFlag } from '../../../../../../../../constants/ld-flags'
import { ShippingProtectionPurchaseModelForm } from '../shipping-protection-purchase-model-form/shipping-protection-purchase-model-form'
import { LocationFilteringSwitch } from '../location-filtering-switch/location-filtering-switch'

type ShippingProtectionProps = {
  store: Store
}

const ShippingProtection: FC<ShippingProtectionProps> = ({ store }) => {
  const flags = useFlags()
  const SPPurchaseModel = flags[LDFlag.SPPurchaseModel]
  const { shippingProtection, id, merchantSpRevenueSharePercentage } = store
  return (
    <>
      <SectionContainer title="Shipping Protection">
        {SPPurchaseModel ? (
          <LocationFilteringSwitch storeId={id} shippingProtection={shippingProtection || {}} />
        ) : (
          <ShippingProtectionForm
            storeId={id}
            merchantSpRevenueSharePercentage={merchantSpRevenueSharePercentage}
            shippingProtection={shippingProtection || {}}
          />
        )}
      </SectionContainer>
      {SPPurchaseModel && (
        <SectionContainer title="Purchase Model">
          <ShippingProtectionPurchaseModelForm store={store} />
        </SectionContainer>
      )}
      <SectionContainer title="Offer Type">
        <ShippingProtectionOfferType store={store} />
      </SectionContainer>
    </>
  )
}

export { ShippingProtection }
