import type { FC } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Checkbox, COLOR, Information } from '@helloextend/zen'
import { useToggle } from '@helloextend/client-hooks'
import { useFormikContext } from 'formik'
import { InsuranceProgramExtendFeesForm } from './insurance-program-extend-fees-form'
import type { DropdownProps } from '../../../components/dropdown'
import type { Values } from './schema'

type InsuranceProgramExtendFeesProps = {
  obligorIndex: number
  isDisabled?: boolean
  isExtendFeeChecked?: boolean
  handleDropdownChange: DropdownProps['onChange']
}

const InsuranceProgramExtendFees: FC<InsuranceProgramExtendFeesProps> = ({
  obligorIndex,
  isDisabled = false,
  isExtendFeeChecked = false,
  handleDropdownChange,
}) => {
  const [isFormVisible, handleIsFormVisible] = useToggle(isExtendFeeChecked)

  const { setFieldValue, errors } = useFormikContext<Values>()

  useEffect(() => {
    if (!isFormVisible) {
      setFieldValue(`obligor[${obligorIndex}].extendFee`, null)
    }
  }, [isFormVisible, obligorIndex, setFieldValue, errors])

  return (
    <>
      <ExtendCheckmarkWrapper>
        <Checkbox
          label="Fees paid by Extend owned obligors"
          checked={isFormVisible}
          onChange={handleIsFormVisible.toggle}
          disabled={isDisabled}
        />
        <Information>
          <p>
            These are fees paid to a third party provider by the Extend owned obligors, such as a
            CLIP fee, which is the responsibility of the obligor, not Extend, Inc.
          </p>
          <p>
            For example, EWSC/EPC are charged a 7% for their CLIP. In turn, EWSC/EPC will charge
            Extend, Inc. an “obligor” fee, which may also be 7%. The 7% must first pass through
            EWSC/EPC and then paid to the CLIP provider.
          </p>
        </Information>
      </ExtendCheckmarkWrapper>
      {isFormVisible && (
        <InsuranceProgramExtendFeesForm
          obligorIndex={obligorIndex}
          handleDropdownChange={handleDropdownChange}
          isChecked={isFormVisible}
          isDisabled={isDisabled}
        />
      )}
    </>
  )
}

const ExtendCheckmarkWrapper = styled.div({
  borderTop: `1px solid ${COLOR.NEUTRAL[300]}`,
  paddingTop: 32,
  display: 'flex',
})

export { InsuranceProgramExtendFees }
