import type { VFC, ChangeEvent } from 'react'
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import { If, Then, Else } from 'react-if'
import type { FieldProps } from '../field'
import { Field } from '../field'
import { ToastColor, ToastDuration, useToaster } from '../../toaster'
import { Add, Trash, Warning } from '../../../tokens/icons'
import { Badge } from '../../badge'
import { Icon } from '../../icon'
import { COLOR } from '../../../tokens/colors'
import { Button } from '../../button'
import { isFileValidImage } from './image-input-utils'
import type { ValidImageExtension } from './image-input-types'
import { formatHumanReadableFileSize, getFileNameFromUrl, isFileValidSize } from '../../../utils'

export type FileRequirement = {
  label: string
  isError: boolean
}

export interface ImageInputProps
  extends Omit<
    FieldProps,
    | 'value'
    | 'startAdornment'
    | 'endAdornment'
    | 'prefix'
    | 'suffix'
    | 'icon'
    | 'onClearRequest'
    | 'maxLength'
    | 'isCounterHiddenWhenValid'
    | 'actionButtonProps'
    | 'hasNoBorder'
  > {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  currentImage?: string
  maxSizeMb?: number
  imageExtensions?: ValidImageExtension[]
  ariaLabel?: string
  height?: number
}

export const ImageInput: VFC<ImageInputProps> = ({
  'data-cy': dataCy,
  label,
  currentImage,
  ariaLabel,
  height = 64,
  id,
  imageExtensions = ['jpeg', 'jpg', 'gif', 'png'],
  isDisabled = false,
  isError = false,
  subtext,
  errorFeedback,
  helperText,
  onChange = () => {},
  maxSizeMb = 6,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const dropZoneRef = useRef<HTMLButtonElement>(null)
  const [file, setFile] = useState<string | null>(currentImage || null)
  const [fileSize, setFileSize] = useState(0)
  const [fileName, setFileName] = useState('')
  const [isDraggingFileOverDropZone, setIsDraggingFileOverDropZone] = useState(false)
  const [isDraggingFileOverWindow, setIsDraggingFileOverWindow] = useState(false)
  const [isValidFileType, setIsValidFileType] = useState(true)
  const [isValidFileSize, setIsValidFileSize] = useState(true)
  const { toast } = useToaster()
  const windowDragCounter = useRef(0)
  const dropZoneDragCounter = useRef(0)

  const fileRequirements: FileRequirement[] = useMemo(
    () => [
      { label: imageExtensions.join(', ').toUpperCase(), isError: !isValidFileType },
      { label: `${maxSizeMb}MB max`, isError: !isValidFileSize },
    ],
    [imageExtensions, isValidFileType, maxSizeMb, isValidFileSize],
  )

  useEffect(() => {
    if (!currentImage) {
      setFile('')
      setFileName('')
    }
    if (currentImage && currentImage !== file) {
      setFile(currentImage)
      setFileName(getFileNameFromUrl(currentImage))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImage])

  const handleReset = useCallback(() => {
    setFile('')
    setFileName('')
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
      fileInputRef.current.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }, [fileInputRef])

  const handleSelectFile = (): void => {
    fileInputRef.current?.click()
  }

  const handleWindowDragEnter = useCallback((e: DragEvent) => {
    windowDragCounter.current += 1
    if (e.dataTransfer?.types[0] === 'Files') setIsDraggingFileOverWindow(true)
  }, [])

  const handleWindowDragLeave = useCallback(() => {
    windowDragCounter.current -= 1
    if (windowDragCounter.current === 0) {
      setIsDraggingFileOverWindow(false)
    }
  }, [])

  const handleWindowDrop = useCallback(() => {
    setIsDraggingFileOverDropZone(false)
    setIsDraggingFileOverWindow(false)
  }, [])

  useEffect(() => {
    window.addEventListener('dragenter', handleWindowDragEnter)
    window.addEventListener('dragleave', handleWindowDragLeave)
    window.addEventListener('drop', handleWindowDrop)
    return () => {
      window.removeEventListener('dragenter', handleWindowDragEnter)
      window.removeEventListener('dragleave', handleWindowDragLeave)
      window.removeEventListener('drop', handleWindowDrop)
    }
  }, [handleWindowDragEnter, handleWindowDragLeave, handleWindowDrop])

  const handleWindowDragOver = useCallback((e: DragEvent) => {
    e.preventDefault()
  }, [])

  const handleDropZoneDragEnter = useCallback(
    (e: DragEvent): void => {
      if (!e.dataTransfer || isDisabled) return
      dropZoneDragCounter.current += 1
      setIsDraggingFileOverDropZone(true)
    },
    [isDisabled],
  )

  const handleDropZoneDragLeave = useCallback((): void => {
    dropZoneDragCounter.current -= 1
    if (dropZoneDragCounter.current === 0) {
      setIsDraggingFileOverDropZone(false)
    }
  }, [])

  const handleDropZoneDrop = useCallback(
    (e: DragEvent): void => {
      e.preventDefault()

      if (!e.dataTransfer || isDisabled) return

      if (e.dataTransfer.files.length > 1) {
        toast({
          message: 'Please drop a single image file.',
          toastColor: ToastColor.yellow,
          icon: Warning,
          toastDuration: ToastDuration.short,
        })
        return
      }

      const { files } = e.dataTransfer
      if (files && files.length > 0) {
        if (fileInputRef.current) {
          fileInputRef.current.files = files
          fileInputRef.current.dispatchEvent(new Event('change', { bubbles: true }))
        }
      }
    },
    [fileInputRef, isDisabled, toast],
  )

  useEffect(() => {
    const ref = dropZoneRef?.current

    ref?.addEventListener('dragover', handleWindowDragOver)
    ref?.addEventListener('dragenter', handleDropZoneDragEnter)
    ref?.addEventListener('dragleave', handleDropZoneDragLeave)
    ref?.addEventListener('drop', handleDropZoneDrop)

    return () => {
      ref?.removeEventListener('dragover', handleWindowDragOver)
      ref?.removeEventListener('dragenter', handleDropZoneDragEnter)
      ref?.removeEventListener('dragleave', handleDropZoneDragLeave)
      ref?.removeEventListener('drop', handleDropZoneDrop)
    }
  }, [handleDropZoneDragEnter, handleDropZoneDragLeave, handleDropZoneDrop, handleWindowDragOver])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange(e)

    if (!e.currentTarget.files || e.currentTarget.files.length === 0) return

    const currentFile = e.currentTarget.files[0]

    setIsValidFileType(isFileValidImage(currentFile, imageExtensions))
    setIsValidFileSize(isFileValidSize(currentFile, maxSizeMb))

    setFile(URL.createObjectURL(currentFile))
    setFileName(currentFile.name)

    if (fileInputRef.current?.files) setFileSize(fileInputRef.current.files[0].size)
  }

  return (
    <Field
      id={id}
      label={label}
      value=""
      isDisabled={isDisabled}
      isError={isError}
      errorFeedback={errorFeedback}
      subtext={subtext}
      hasNoBorder
      helperText={helperText}
      data-cy={dataCy && `${dataCy}:field`}
    >
      <FieldContent>
        <DropZone
          ref={dropZoneRef}
          isDraggingOverDropZone={isDraggingFileOverDropZone}
          isDraggingOverWindow={isDraggingFileOverWindow}
          onClick={handleSelectFile}
          disabled={isDisabled}
          height={height}
        >
          <If condition={isDraggingFileOverWindow && !isDisabled}>
            <Then>
              <DropZoneLabel>Drop an image here.</DropZoneLabel>
            </Then>
            <Else>
              <If condition={!!file}>
                <Then>
                  <If condition={isValidFileType && isValidFileSize}>
                    <Then>
                      <Image height={height} backgroundImage={file || ''} isDisabled={isDisabled} />
                    </Then>
                    <Else>
                      <InvalidFileNotice>
                        <Badge
                          text="Invalid file"
                          details={`${fileName} (${formatHumanReadableFileSize(fileSize)})`}
                        />
                      </InvalidFileNotice>
                    </Else>
                  </If>
                </Then>
                <Else>{!isDisabled && <Icon icon={Add} color={COLOR.NEUTRAL[800]} />}</Else>
              </If>
            </Else>
          </If>
        </DropZone>
        <Footer>
          {!file || !isValidFileType || !isValidFileSize ? (
            <Badges>
              {fileRequirements.map((fileRequirement) => {
                const { label: requirementLabel, isError: isRequirementError } = fileRequirement
                return (
                  <Badge
                    size="small"
                    text={requirementLabel}
                    emphasis="low"
                    color={isRequirementError ? 'red' : 'neutral'}
                    {...(isRequirementError && { icon: Warning })}
                    data-cy={dataCy && `${dataCy}:requirement`}
                    key={requirementLabel}
                  />
                )
              })}
            </Badges>
          ) : (
            <>
              <FileName data-cy={dataCy && `${dataCy}:filename`} onClick={handleSelectFile}>
                {fileName}
              </FileName>
              <ClearButtonWrapper>
                <Button
                  size="xsmall"
                  icon={Trash}
                  emphasis="low"
                  onClick={handleReset}
                  data-cy={dataCy && `${dataCy}:clear-button`}
                />
              </ClearButtonWrapper>
            </>
          )}
        </Footer>
      </FieldContent>
      <FileInput
        id={id}
        accept="image/*"
        ref={fileInputRef}
        type="file"
        data-cy={dataCy && `${dataCy}:input`}
        onChange={handleInputChange}
        disabled={isDisabled}
        aria-label={ariaLabel}
      />
    </Field>
  )
}

const FieldContent = styled.div({
  display: 'grid',
  gap: 4,
  width: '100%',
})

const DropZone = styled.button<{
  disabled: boolean
  height: number
  isDraggingOverDropZone: boolean
  isDraggingOverWindow: boolean
}>(({ disabled, height, isDraggingOverDropZone, isDraggingOverWindow }) => ({
  border: `1px dashed ${isDraggingOverWindow ? COLOR.BLUE[400] : COLOR.NEUTRAL[400]}`,
  outline: 'none',
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: height,
  borderRadius: 4,
  background: 'none',
  '& svg': {
    display: 'block',
  },
  ...(isDraggingOverWindow && {
    backgroundColor: isDraggingOverDropZone ? COLOR.BLUE[200] : COLOR.BLUE[100],
  }),
  ...(!disabled && {
    cursor: 'pointer',
    '&:hover, &:focus-visible': {
      backgroundColor: isDraggingOverWindow ? COLOR.BLUE[100] : COLOR.NEUTRAL.OPACITY[12],
    },
  }),
}))

const Image = styled.div<{ backgroundImage: string; height: number; isDisabled: boolean }>(
  ({ backgroundImage, height, isDisabled }) => ({
    flex: 1,
    ...(isDisabled && { opacity: '0.75' }),
    height: height - 2,
    borderRadius: 4,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }),
)

const DropZoneLabel = styled.div({
  width: '100%',
  height: '100%',
  display: 'grid',
  placeContent: 'center',
})

const InvalidFileNotice = styled.div({
  margin: '8px 16px',
  overflowWrap: 'anywhere',
})

const Footer = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: 4,
})

const FileName = styled.button({
  color: COLOR.BLUE[800],
  lineHeight: '20px',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  textAlign: 'left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&:hover': {
    textDecoration: 'underline',
  },
  padding: 0,
})

const Badges = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: 12,
})

const ClearButtonWrapper = styled.div({
  display: 'flex',
  margin: '-2px 0',
})

const FileInput = styled.input({
  display: 'none',
})
