import type { FC } from 'react'
import React, { useCallback } from 'react'
import { Input, Switch } from '@helloextend/zen'
import styled from '@emotion/styled'
import { InputType } from '@helloextend/zen/src/components/fields/input/types'
import type { AdvancedTextFilterValues } from './types'

export type AdvancedTextFilterProps = {
  property: string
  values?: AdvancedTextFilterValues
  onFilterChange: (property: string, values: AdvancedTextFilterValues | null) => void
}

const AdvancedTextFilter: FC<AdvancedTextFilterProps> = ({
  property,
  values = {
    type: 'advancedText',
    caseSensitive: false,
    contains: null,
    excludes: null,
    startsWith: null,
    endsWith: null,
  },
  onFilterChange,
}) => {
  const handleChangeCaseSensitive = useCallback((): void => {
    const hasValues = values.contains || values.excludes || values.startsWith || values.endsWith

    onFilterChange(
      property,
      hasValues
        ? {
            type: 'advancedText',
            caseSensitive: !values.caseSensitive,
            contains: values.contains,
            excludes: values.excludes,
            startsWith: values.startsWith,
            endsWith: values.endsWith,
          }
        : null,
    )
  }, [property, values, onFilterChange])

  const handleChangeContains = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const val = e.target.value
      const hasValues = val || values.excludes || values.startsWith || values.endsWith

      onFilterChange(
        property,
        hasValues
          ? {
              type: 'advancedText',
              caseSensitive: values.caseSensitive,
              contains: val.length > 0 ? val : null,
              excludes: values.excludes,
              startsWith: values.startsWith,
              endsWith: values.endsWith,
            }
          : null,
      )
    },
    [property, values, onFilterChange],
  )

  const handleChangeExcludes = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const val = e.target.value
      const hasValues = val || values.contains || values.startsWith || values.endsWith

      onFilterChange(
        property,
        hasValues
          ? {
              type: 'advancedText',
              caseSensitive: values.caseSensitive,
              contains: values.contains,
              excludes: val.length > 0 ? val : null,
              startsWith: values.startsWith,
              endsWith: values.endsWith,
            }
          : null,
      )
    },
    [property, values, onFilterChange],
  )

  const handleChangeStartsWith = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const val = e.target.value
      const hasValues = val || values.contains || values.excludes || values.endsWith

      onFilterChange(
        property,
        hasValues
          ? {
              type: 'advancedText',
              caseSensitive: values.caseSensitive,
              contains: values.contains,
              excludes: values.excludes,
              startsWith: val.length > 0 ? val : null,
              endsWith: values.endsWith,
            }
          : null,
      )
    },
    [property, values, onFilterChange],
  )

  const handleChangeEndsWith = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const val = e.target.value
      const hasValues = val || values.contains || values.excludes || values.startsWith

      onFilterChange(
        property,
        hasValues
          ? {
              type: 'advancedText',
              caseSensitive: values.caseSensitive,
              contains: values.contains,
              excludes: values.excludes,
              startsWith: values.startsWith,
              endsWith: val.length > 0 ? val : null,
            }
          : null,
      )
    },
    [property, values, onFilterChange],
  )

  return (
    <AdvancedTextFilterWrapper>
      <AdvancedTextPickerLabel>Contains</AdvancedTextPickerLabel>
      <AdvancedTextWrapper>
        <Input
          type={InputType.text}
          id="contains"
          onChange={handleChangeContains}
          value={values.contains === null ? '' : values.contains}
        />
      </AdvancedTextWrapper>
      <AdvancedTextPickerLabel>Does Not Contain</AdvancedTextPickerLabel>
      <AdvancedTextWrapper>
        <Input
          type={InputType.text}
          id="excludes"
          onChange={handleChangeExcludes}
          value={values.excludes === null ? '' : values.excludes}
        />
      </AdvancedTextWrapper>
      <AdvancedTextPickerLabel>Begins With</AdvancedTextPickerLabel>
      <AdvancedTextWrapper>
        <Input
          type={InputType.text}
          id="startsWith"
          onChange={handleChangeStartsWith}
          value={values.startsWith === null ? '' : values.startsWith}
        />
      </AdvancedTextWrapper>
      <AdvancedTextPickerLabel>Ends With</AdvancedTextPickerLabel>
      <AdvancedTextWrapper>
        <Input
          type={InputType.text}
          id="endsWith"
          onChange={handleChangeEndsWith}
          value={values.endsWith === null ? '' : values.endsWith}
        />
      </AdvancedTextWrapper>
      <AdvancedTextWrapper>
        <Switch
          label="Case Sensitive"
          isOn={values.caseSensitive}
          isDisabled={!(values.contains || values.excludes || values.startsWith || values.endsWith)}
          onChange={handleChangeCaseSensitive}
        />
      </AdvancedTextWrapper>
    </AdvancedTextFilterWrapper>
  )
}

const AdvancedTextFilterWrapper = styled.div({
  padding: '12px 12px 0px 12px',
})

const AdvancedTextWrapper = styled.div({
  display: 'flex',
  margin: '0px 0px 16px 0px',
})

const AdvancedTextPickerLabel = styled.p({
  fontWeight: 'bold',
  margin: 0,
  padding: 0,
})

export { AdvancedTextFilter }
