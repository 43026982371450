import type { FC, SyntheticEvent } from 'react'
import React, { useEffect } from 'react'
import { getIn, useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { COLOR } from '@helloextend/client-branding'
import styled from '@emotion/styled'
import { useFulfillServiceOrderMutation } from '@helloextend/extend-api-rtk-query'
import type {
  ProductReplacementFulfillmentMetaData,
  ProductReplacementServiceOrdersFulfillRequest,
  ServiceOrder,
  ShippingCarrier,
} from '@helloextend/extend-api-client'
import { Button, ButtonGroup, Input } from '@helloextend/zen'
import type { Values } from './schema'
import { formSchema } from './schema'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import { Select, SelectItem } from '../../../../../../components/select'

interface TrackingInfoFormProps {
  serviceOrder: ServiceOrder
}

const shippingCarrierMap: Record<ShippingCarrier, string> = {
  usps: 'USPS',
  ups: 'UPS',
  fedex_ground: 'Fedex Ground',
  dhl_express: 'DHL Express',
  ontrac: 'OnTrac',
  newgistics: 'Newgistics',
  fedex_standard_overnight: 'Fedex Standard Overnight',
}

const TrackingInfoForm: FC<TrackingInfoFormProps> = ({ serviceOrder }) => {
  const dispatch = useDispatch()
  const [resolveServiceOrder, { isSuccess, isLoading }] = useFulfillServiceOrderMutation()
  const { values, errors, handleSubmit, handleChange, setFieldValue } = useFormik<Values>({
    enableReinitialize: true,
    initialValues: formSchema.default(),
    validationSchema: formSchema,
    onSubmit: (formValues) => {
      const fulfillmentMetaData =
        serviceOrder.fulfillmentMetaData as ProductReplacementFulfillmentMetaData
      if (!fulfillmentMetaData || !serviceOrder.assignedServicer) return
      const body = {
        method: fulfillmentMetaData.method,
        product: {
          name: fulfillmentMetaData.productName,
          referenceId: fulfillmentMetaData.productReferenceId,
          value: fulfillmentMetaData.productValue,
        },
        orderNumber: fulfillmentMetaData.orderNumber,
        resolutionInitializedBy: fulfillmentMetaData.resolutionInitializedBy,
        ...formValues,
      } as ProductReplacementServiceOrdersFulfillRequest
      resolveServiceOrder({
        serviceOrderId: serviceOrder.id,
        body,
      })
    },
  })

  useEffect(() => {
    if (isSuccess) {
      dispatch(setClaimDetailsToast('SO Resolved!'))
      dispatch(setClaimDetailsActiveView(''))
    }
  }, [isSuccess, dispatch])

  const handleSelectChange = (field: string, e: SyntheticEvent<Element>): void => {
    const { value } = e.currentTarget as HTMLInputElement
    setFieldValue(field, value)
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormRow>
        <ItemWrapper error={Boolean(errors.carrier)}>
          <Select
            label="Carrier"
            labelMarginBottom={2}
            labelDisplayType="block"
            menuHeight={175}
            onChange={(e: SyntheticEvent<Element>) => handleSelectChange('carrier', e)}
            value={values.carrier || ''}
            error={Boolean(errors.carrier)}
            errorMessage={errors.carrier}
            darkErrorMessageLabel={false}
          >
            {Object.keys(shippingCarrierMap).map((key: string) => (
              <SelectItem
                key={key}
                value={key}
                label={shippingCarrierMap[key as ShippingCarrier]}
                data-cy={`select-item-${key}`}
              />
            ))}
          </Select>
        </ItemWrapper>
        <ItemWrapper>
          <Input
            label="Tracking Number"
            onChange={handleChange}
            value={getIn(values, 'trackingNumber')}
            errorFeedback={errors.trackingNumber}
            isError={Boolean(errors.trackingNumber)}
            data-cy="tracking-number"
            id="trackingNumber"
          />
        </ItemWrapper>
      </FormRow>
      <ButtonWrapper>
        <ButtonGroup>
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => dispatch(setClaimDetailsActiveView(''))}
            isDisabled={isLoading}
          />
          <Button
            type="submit"
            text="Add Tracking Info"
            data-cy="add-tracking-info-button"
            isDisabled={isLoading}
            isProcessing={isLoading}
          />
        </ButtonGroup>
      </ButtonWrapper>
    </form>
  )
}

const FormRow = styled.div({
  marginTop: 10,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: 25,
})

const ItemWrapper = styled.div<{ error?: boolean }>(({ error }) => ({
  width: '50%',
  button: {
    borderColor: error ? COLOR.DANGER : COLOR.LIGHT_GRAYISH_BLUE_0,
    boxShadow: 'none',
    '&:hover': {
      borderColor: error ? COLOR.DANGER : COLOR.LIGHT_GRAYISH_BLUE_0,
    },
    '&:focus': {
      outline: 'none',
      border: `1px solid ${error ? COLOR.DANGER : COLOR.BRIGHT_BLUE}`,
      boxShadow: `0 0 0 1px inset ${error ? COLOR.DANGER : COLOR.BRIGHT_BLUE}`,
    },
  },
}))

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

export { TrackingInfoForm }
