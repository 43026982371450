import type { ReactNode, FC } from 'react'
import React, { createContext, useState, useContext, useRef, useMemo, useCallback } from 'react'
import type { IconProps } from '../../tokens/icons/icon-props'
import { AnimatedToast } from './animated-toast'
import { ToastContainer } from './toast-container'
import type { ToasterPosition } from './types'
import { ToastColor, ToastDuration } from './types'

interface ToastData {
  id: number
  message: React.ReactNode
  toastColor: ToastColor
  toastDuration: ToastDuration
  icon: IconProps | undefined
}

interface AddToastOptions {
  message: React.ReactNode
  toastColor?: ToastColor
  toastDuration?: ToastDuration
  icon?: IconProps | undefined
}

interface ContextValue {
  toast: (options: AddToastOptions) => number
  dismiss: (id: number) => void
}

const Context = createContext<ContextValue | null>(null)

interface ToasterProps {
  children: ReactNode
  toasterPosition: ToasterPosition
  'data-cy'?: string
}

const Toaster: FC<ToasterProps> = ({ children, toasterPosition, 'data-cy': dataCy }) => {
  const previousId = useRef(0)
  const [toasts, setToasts] = useState<ToastData[]>([])

  const afterClose = useCallback((id: number) => {
    setToasts((t) => t.filter((i) => i.id !== id))
  }, [])

  const deleteToast = useCallback((id: number) => {
    setToasts((t) => t.filter((i) => i.id !== id))
  }, [])

  const addToast = useCallback(
    ({
      message,
      toastColor = ToastColor.neutral,
      toastDuration = ToastDuration.indefinite,
      icon = undefined,
    }: AddToastOptions): number => {
      const id = previousId.current + 1
      previousId.current = id

      setToasts((t) => [
        {
          id,
          message,
          toastColor,
          toastDuration,
          icon,
        },
        ...t,
      ])
      return id
    },
    [],
  )

  const value = useMemo(() => {
    const contextValue: ContextValue = {
      toast: addToast,
      dismiss: deleteToast,
    }

    return contextValue
  }, [addToast, deleteToast])

  return (
    <Context.Provider value={value}>
      {children}
      <ToastContainer toasterPosition={toasterPosition}>
        {toasts.map((toastData) => (
          <AnimatedToast
            key={toastData.id}
            id={toastData.id}
            message={toastData.message}
            toastColor={toastData.toastColor}
            afterClose={afterClose}
            toastDuration={toastData.toastDuration}
            icon={toastData.icon}
            data-cy={`${dataCy}:toast`}
          />
        ))}
      </ToastContainer>
    </Context.Provider>
  )
}

/**
 * Within the subtree of a Toaster, this function provides for creation of toast
 * notifications on the screen to a child component. The toaster should ideally exist
 * near the root and only exist once in the application.
 * ```tsx
 * <Toaster toasterPosition={ToasterPosition.right}>
 *   <Child />
 * </Toaster>
 *
 * // child.tsx
 * const { toast } = useToaster()
 * toast({ message: 'Hello, world!' })
 * ```
 */
const useToaster = (): ContextValue => {
  const context = useContext(Context)

  if (!context) {
    // Return a default context value if we are outside of a context to ensure unit tests that are not using shallow mounting will work
    return {
      toast: () => 0,
      dismiss: () => {},
    }
  }

  return context
}

export { Toaster, ToasterProps, useToaster, ContextValue }
