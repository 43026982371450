import { useCreatePlanMutation } from '@helloextend/extend-api-rtk-query'
import { useMemo } from 'react'
import type { PlanImportFile, PlansBatchResult } from '../actions/import'

const usePerformImport = (): {
  performImport: (
    failedPlanFiles: string[],
    skippedPlanFiles: PlanImportFile[],
    uploadReadyPlans: PlanImportFile[],
  ) => Promise<PlansBatchResult>
} => {
  const [create] = useCreatePlanMutation()
  const performImport = useMemo(
    () =>
      async (
        failedPlanFiles: string[],
        skippedPlanFiles: PlanImportFile[],
        uploadReadyPlans: PlanImportFile[],
      ): Promise<PlansBatchResult> => {
        const results: PlansBatchResult = {
          success: [],
          failure: failedPlanFiles.map((el) => {
            return { name: el, message: 'Could not parse file.' }
          }),
          skipped: skippedPlanFiles.map((planFile) => {
            return planFile.file.name
          }),
        }
        const promises = uploadReadyPlans.map(async (planFile) => {
          try {
            const result = await create(planFile.plan).unwrap()
            results.success.push({ name: planFile.file.name, plan: result })
          } catch (error) {
            const parsedError = error as { data: { message: string } }
            results.failure.push({
              name: planFile.file.name,
              message: parsedError?.data?.message ?? 'Unknown Error',
            })
          }
        })
        await Promise.all(promises)
        return results
      },
    [create],
  )
  return { performImport }
}

export { usePerformImport }
