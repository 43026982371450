import type { FC } from 'react'
import React from 'react'
import { useGetInsuranceClaimQuery } from '@helloextend/extend-api-rtk-query'
import { CellText } from '@helloextend/merchants-ui'
import { date } from '@helloextend/client-utils'

interface DateAssignedDisplayProps {
  claimId: string
}

const DateAssignedDisplay: FC<DateAssignedDisplayProps> = ({ claimId }) => {
  const { data: claim } = useGetInsuranceClaimQuery({
    claimId,
  })

  return (
    <CellText data-cy="claim-userAssignedAt">
      {claim?.userAssignedAt ? date.format(claim.userAssignedAt, 'DD MMM YYYY') : ''}
    </CellText>
  )
}

export { DateAssignedDisplay }
