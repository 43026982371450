import type { CurrencyCode } from '../../utils/currency'

export enum DataPropertyType {
  string = 'string',
  number = 'number',
  date = 'date',
  currency = 'currency',
  custom = 'custom',
}

export interface DataPropertyProps {
  'data-cy'?: string
  includeCopyAction?: boolean
  isLoading?: boolean
  label?: string
  openInNew?: boolean
  prefix?: string
  suffix?: string
  to?: string
  helperText?: string | React.ReactElement
  isError?: boolean
  errorMessage?: string
}

export interface StringDataPropertyProps extends DataPropertyProps {
  type?: DataPropertyType.string
  value?: string
}

export interface NumberDataPropertyProps extends DataPropertyProps {
  type: DataPropertyType.number
  value?: number
}

export interface DateDataPropertyProps extends DataPropertyProps {
  type: DataPropertyType.date
  value?: number
  datePattern?: string
}

export interface CurrencyDataPropertyProps extends DataPropertyProps {
  type: DataPropertyType.currency
  value?: number
  currencyCode: CurrencyCode
}

export interface CustomDataPropertyProps<TValue> extends DataPropertyProps {
  type: DataPropertyType.custom
  value?: TValue
  formatter: (v?: TValue) => string | null
}
