import confetti from 'canvas-confetti'
import { COLOR } from '../../tokens/colors'

const particleCount = 175
const canvasBottomLeft = { x: 0, y: 1 }
const canvasBottomRight = { x: 1, y: 1 }
const velocityMultiplier = (vm: number): number => vm * (window.innerHeight / 1500 + 0.5)
const spreadMultiplier = (sm: number): number => sm * (window.innerWidth / 2000 + 0.5)

const emitter = (countRatio: number, delay: number, options: confetti.Options): void => {
  setTimeout(() => {
    confetti({
      particleCount: Math.floor(particleCount * countRatio),
      colors: [
        COLOR.BLUE[800],
        COLOR.BLUE[400],
        COLOR.RED[400],
        COLOR.YELLOW[400],
        COLOR.GREEN[400],
      ],
      ...options,
    })
  }, delay)
}

export const celebrate = (): void => {
  const scalar = window.innerWidth / 3000 + 0.8
  emitter(0.3, 0, {
    scalar,
    spread: spreadMultiplier(60),
    startVelocity: velocityMultiplier(65),
    angle: 60,
    origin: canvasBottomLeft,
  })
  emitter(0.2, 100, {
    scalar,
    spread: spreadMultiplier(40),
    startVelocity: velocityMultiplier(75),
    angle: 60,
    origin: canvasBottomLeft,
  })
  emitter(0.3, 150, {
    scalar,
    spread: spreadMultiplier(80),
    startVelocity: velocityMultiplier(75),
    angle: 120,
    origin: canvasBottomRight,
  })
  emitter(0.2, 250, {
    scalar,
    spread: spreadMultiplier(40),
    startVelocity: velocityMultiplier(60),
    angle: 120,
    origin: canvasBottomRight,
  })
}
