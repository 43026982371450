import { COLOR } from '../../tokens/colors/color'
import type { ColorArgs, BadgeEmphasis } from './badge-types'
import { BadgeTheme } from './badge-types'

// Empahsis High
const emphasisHighBlue = {
  contentColor: COLOR.WHITE,
  backgroundColor: COLOR.BLUE[700],
}

const emphasisHighBlueInverted = {
  contentColor: COLOR.BLUE[800],
  backgroundColor: COLOR.BLUE[200],
}

const emphasisHighGreen = {
  contentColor: COLOR.WHITE,
  backgroundColor: COLOR.GREEN[700],
}

const emphasisHighGreenInverted = {
  contentColor: COLOR.GREEN[800],
  backgroundColor: COLOR.GREEN[200],
}

const emphasisHighNeutral = {
  contentColor: COLOR.WHITE,
  backgroundColor: COLOR.NEUTRAL[700],
}

const emphasisHighNeutralInverted = {
  contentColor: COLOR.NEUTRAL[800],
  backgroundColor: COLOR.NEUTRAL[200],
}

const emphasisHighYellow = {
  contentColor: COLOR.YELLOW[1000],
  backgroundColor: COLOR.YELLOW[400],
}

const emphasisHighYellowInverted = {
  contentColor: COLOR.YELLOW[800],
  backgroundColor: COLOR.YELLOW[200],
}

const emphasisHighRed = {
  contentColor: COLOR.WHITE,
  backgroundColor: COLOR.RED[700],
}

const emphasisHighRedInverted = {
  contentColor: COLOR.RED[800],
  backgroundColor: COLOR.RED[200],
}

// Empahsis Medium
const emphasisMediumBlue = {
  contentColor: COLOR.BLUE[800],
  backgroundColor: COLOR.BLUE[200],
}

const emphasisMediumBlueInverted = {
  contentColor: COLOR.BLUE[300],
  backgroundColor: COLOR.BLUE.OPACITY[25],
}

const emphasisMediumGreen = {
  contentColor: COLOR.GREEN[800],
  backgroundColor: COLOR.GREEN[200],
}

const emphasisMediumGreenInverted = {
  contentColor: COLOR.GREEN[300],
  backgroundColor: COLOR.GREEN.OPACITY[25],
}

const emphasisMediumNeutral = {
  contentColor: COLOR.NEUTRAL[800],
  backgroundColor: COLOR.NEUTRAL[200],
}

const emphasisMediumNeutralInverted = {
  contentColor: COLOR.NEUTRAL[300],
  backgroundColor: COLOR.NEUTRAL.OPACITY[25],
}

const emphasisMediumYellow = {
  contentColor: COLOR.YELLOW[800],
  backgroundColor: COLOR.YELLOW[200],
}

const emphasisMediumYellowInverted = {
  contentColor: COLOR.YELLOW[300],
  backgroundColor: COLOR.YELLOW.OPACITY[25],
}

const emphasisMediumRed = {
  contentColor: COLOR.RED[800],
  backgroundColor: COLOR.RED[200],
}

const emphasisMediumRedInverted = {
  contentColor: COLOR.RED[300],
  backgroundColor: COLOR.RED.OPACITY[25],
}

// Empahsis Low
const emphasisLowBlue = {
  contentColor: COLOR.BLUE[700],
  dotColor: COLOR.BLUE[600],
}

const emphasisLowBlueInverted = {
  contentColor: COLOR.BLUE[300],
  dotColor: COLOR.BLUE[400],
}

const emphasisLowGreen = {
  contentColor: COLOR.GREEN[700],
  dotColor: COLOR.GREEN[600],
}

const emphasisLowGreenInverted = {
  contentColor: COLOR.GREEN[300],
  dotColor: COLOR.GREEN[400],
}

const emphasisLowNeutral = {
  contentColor: COLOR.NEUTRAL[700],
  dotColor: COLOR.NEUTRAL[600],
}

const emphasisLowNeutralInverted = {
  contentColor: COLOR.NEUTRAL[300],
  dotColor: COLOR.NEUTRAL[400],
}

const emphasisLowYellow = {
  contentColor: COLOR.YELLOW[700],
  dotColor: COLOR.YELLOW[400],
}

const emphasisLowYellowInverted = {
  contentColor: COLOR.YELLOW[300],
  dotColor: COLOR.YELLOW[400],
}

const emphasisLowRed = {
  contentColor: COLOR.RED[700],
  dotColor: COLOR.RED[600],
}

const emphasisLowRedInverted = {
  contentColor: COLOR.RED[300],
  dotColor: COLOR.RED[400],
}

export const badgeColors: Record<BadgeEmphasis, Record<BadgeTheme, ColorArgs>> = {
  high: {
    [BadgeTheme.blue]: emphasisHighBlue,
    [BadgeTheme.blueInverted]: emphasisHighBlueInverted,
    [BadgeTheme.green]: emphasisHighGreen,
    [BadgeTheme.greenInverted]: emphasisHighGreenInverted,
    [BadgeTheme.neutral]: emphasisHighNeutral,
    [BadgeTheme.neutralInverted]: emphasisHighNeutralInverted,
    [BadgeTheme.yellow]: emphasisHighYellow,
    [BadgeTheme.yellowInverted]: emphasisHighYellowInverted,
    [BadgeTheme.red]: emphasisHighRed,
    [BadgeTheme.redInverted]: emphasisHighRedInverted,
  },
  medium: {
    [BadgeTheme.blue]: emphasisMediumBlue,
    [BadgeTheme.blueInverted]: emphasisMediumBlueInverted,
    [BadgeTheme.green]: emphasisMediumGreen,
    [BadgeTheme.greenInverted]: emphasisMediumGreenInverted,
    [BadgeTheme.neutral]: emphasisMediumNeutral,
    [BadgeTheme.neutralInverted]: emphasisMediumNeutralInverted,
    [BadgeTheme.yellow]: emphasisMediumYellow,
    [BadgeTheme.yellowInverted]: emphasisMediumYellowInverted,
    [BadgeTheme.red]: emphasisMediumRed,
    [BadgeTheme.redInverted]: emphasisMediumRedInverted,
  },
  low: {
    [BadgeTheme.blue]: emphasisLowBlue,
    [BadgeTheme.blueInverted]: emphasisLowBlueInverted,
    [BadgeTheme.green]: emphasisLowGreen,
    [BadgeTheme.greenInverted]: emphasisLowGreenInverted,
    [BadgeTheme.neutral]: emphasisLowNeutral,
    [BadgeTheme.neutralInverted]: emphasisLowNeutralInverted,
    [BadgeTheme.yellow]: emphasisLowYellow,
    [BadgeTheme.yellowInverted]: emphasisLowYellowInverted,
    [BadgeTheme.red]: emphasisLowRed,
    [BadgeTheme.redInverted]: emphasisLowRedInverted,
  },
}
