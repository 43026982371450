import type { FC } from 'react'
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Add, Button, DataTable, ToastColor, ToastDuration, useToaster } from '@helloextend/zen'
import type { SortingState, PaginationState, ColumnFiltersState } from '@helloextend/zen'
import type {
  ThreadListItem,
  RulesetResponse,
  ThreadResponse,
} from '@helloextend/extend-api-rtk-query'
import {
  useListThreadsQuery,
  useLazyGetThreadQuery,
  useLazyGetThreadRulesetQuery,
} from '@helloextend/extend-api-rtk-query'
import { bp } from '@helloextend/client-utils/breakpoints'
import { usePermissions } from '../../../../hooks/use-permissions'
import { Permission } from '../../../../lib/permissions'
import { AdjudicationTabs } from '../components'
import { threadColumns, filterDefs } from './adjudication-thread-list.utils'
import { EditWarningModal } from '../components/edit-warning-modal/edit-warning-modal'
import { CreateThreadModal } from '../components/create-thread-modal'
import { defaultThreadItem } from '../mocks/mocked-thread-list-items'
import * as selectors from '../../../../reducers/selectors'
import type { RootState } from '../../../../reducers'
import { DuplicateThreadModal } from '../components/duplicate-thread-modal'
import {
  setIsDuplicateThreadModalVisible,
  setSelectedThreadListItem,
} from '../../../../store/slices/amp-slice'
import { getSearchParams, getColumnFiltersFromSearchParams } from '../utils'

const AdjudicationThreadList: FC = () => {
  const history = useHistory()
  const { search: searchParams } = useLocation()
  const { toast } = useToaster()
  const dispatch = useDispatch()
  const {
    data: threadsData,
    isFetching,
    isLoading,
  } = useListThreadsQuery(undefined, { skip: false })

  const [fetchThread] = useLazyGetThreadQuery()
  const [fetchRuleset] = useLazyGetThreadRulesetQuery()

  const [isEditWarningModalVisible, setIsEditWarningModalVisible] = useState<boolean>(false)
  const [threadToEdit, setThreadToEdit] = useState<ThreadListItem>(defaultThreadItem)

  const [isCreateThreadModalVisible, setIsCreateThreadModalVisible] = useState(false)
  const isDuplicateModalVisible = useSelector((state: RootState) =>
    selectors.getIsDuplicateThreadModalVisible(state),
  )
  const selectedThreadListItem = useSelector((state: RootState) =>
    selectors.getSelectedThreadListItem(state),
  )

  const [sorting, setSorting] = useState<SortingState>([{ id: 'updatedAt', desc: true }])
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })
  const { hasPermission } = usePermissions()
  const isUserAssumedEditorRole = hasPermission(Permission.ManageAdjudication)

  const columnFiltersInit = getColumnFiltersFromSearchParams(searchParams)
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(columnFiltersInit)

  useEffect(() => {
    if (columnFilters.length > 0) {
      const search = getSearchParams(columnFilters)
      history.push({
        pathname: '/admin/adjudication-management/threads',
        search,
      })
      return
    }
    history.push({
      pathname: '/admin/adjudication-management/threads',
      search: '',
    })
  }, [history, columnFilters])

  const handleRoute = (route: string): void => {
    history.push({
      pathname: route,
      search: searchParams,
    })
  }

  const resetState = (): void => {
    setIsEditWarningModalVisible(false)
    setThreadToEdit(defaultThreadItem)
  }

  const handleEditClick = (thread: ThreadListItem): void => {
    if (thread.status === 'published') {
      setThreadToEdit(thread)
      setIsEditWarningModalVisible(true)
    } else {
      handleRoute(`/admin/adjudication-management/threads/${thread.id}/edit`)
    }
  }

  const exportThread = (thread: ThreadResponse, ruleset: RulesetResponse | null): void => {
    const exportObj = { thread, ruleset }
    const fileData = JSON.stringify(exportObj)
    const blob = new Blob([fileData], { type: 'json' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = `thread-export-${thread.title}.json`
    link.href = url
    link.click()
  }

  const handleThreadExportClick = async (thread: ThreadListItem): Promise<void> => {
    let threadToExport: ThreadResponse | null = null
    let threadRulesetToExport: RulesetResponse | null = null
    // It's a legit case that there is no ruleset, so that fetch may return 404
    try {
      threadToExport = await fetchThread(thread.id, true).unwrap()
      threadRulesetToExport = await fetchRuleset(thread.id, true).unwrap()
      // eslint-disable-next-line no-empty
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }

    if (threadToExport) {
      exportThread(threadToExport, threadRulesetToExport)
      toast({
        message: `${thread.title} has been successfully exported.`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    } else {
      toast({
        message: `Something went wrong. Please try again.`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  const handleConfigureRulesClick = (thread: ThreadListItem): void => {
    if (thread.status !== 'draft') {
      setThreadToEdit(thread)
      handleRoute(`/admin/adjudication-management/threads/${thread.id}/edit/adjudication-rules`)
      resetState()
    }
  }

  const handleEditWarningModalConfirm = (): void => {
    if (threadToEdit) {
      const contentPath = threadToEdit.type === 'adjudication' ? '/content' : ''
      handleRoute(`/admin/adjudication-management/threads/${threadToEdit.id}/edit${contentPath}`)
      resetState()
    }
  }

  const handleEditWarningModalCancel = (): void => {
    resetState()
  }

  const handleToggleCreateThread = (): void => {
    setIsCreateThreadModalVisible(!isCreateThreadModalVisible)
  }

  const handleToggleDuplicateThread = (): void => {
    dispatch(setIsDuplicateThreadModalVisible(!isDuplicateModalVisible))

    if (selectedThreadListItem) {
      dispatch(setSelectedThreadListItem(null))
    }
  }

  return (
    <>
      {isCreateThreadModalVisible && (
        <CreateThreadModal
          isVisible={isCreateThreadModalVisible}
          onCancel={handleToggleCreateThread}
        />
      )}
      {isDuplicateModalVisible && (
        <DuplicateThreadModal
          isVisible={isDuplicateModalVisible}
          onCancel={handleToggleDuplicateThread}
          thread={selectedThreadListItem}
        />
      )}
      <AdjudicationTabs />
      <TopRowContainer>
        <ThreadsMessage data-cy="adjudication-management-threads-message">
          The published threads can be used to compose conversations. Claim Adjudication rules can
          only be configured under Adjudication threads.
        </ThreadsMessage>
      </TopRowContainer>
      <TopRowButtonContainer>
        {isUserAssumedEditorRole && (
          <ButtonContainer>
            <Button
              data-cy="adjudication-management-threads-create-button"
              emphasis="medium"
              icon={Add}
              onClick={handleToggleCreateThread}
              text="Create Thread"
              tooltip="Create Thread"
            />
          </ButtonContainer>
        )}
      </TopRowButtonContainer>
      <DataTable
        data-cy="adjudication-management-thread-list"
        isLoading={isLoading || isFetching}
        data={threadsData ?? []}
        columns={threadColumns(
          handleRoute,
          handleEditClick,
          handleConfigureRulesClick,
          handleThreadExportClick,
        )}
        hasConfigurableColumns={false}
        sorting={sorting}
        onSortingChange={setSorting}
        hasManualSorting={false}
        pagination={pagination}
        onPaginationChange={setPagination}
        hasManualPagination={false}
        filterDefs={filterDefs}
        columnFilters={columnFilters}
        onColumnFiltersChange={setColumnFilters}
        hasManualFiltering={false}
      />
      {isEditWarningModalVisible && (
        <EditWarningModal
          isVisible={isEditWarningModalVisible}
          onConfirm={handleEditWarningModalConfirm}
          onCancel={handleEditWarningModalCancel}
          thread={threadToEdit}
        />
      )}
    </>
  )
}

const TopRowContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 40,
})

const TopRowButtonContainer = styled(TopRowContainer)({
  marginBottom: 24,
  [bp.lg]: {
    justifyContent: 'flex-end',
    marginBottom: -40,
  },
})

const ButtonContainer = styled.div({
  flexShrink: 0,
})

const ThreadsMessage = styled.div({
  marginBottom: 28,
  fontSize: 16,
  lineHeight: '24px',
})

export { AdjudicationThreadList }
