import type { FC } from 'react'
import React, { useEffect } from 'react'
import { ToastColor, ToastDuration } from '@helloextend/merchants-ui'
import type { DataTableAction } from '@helloextend/zen'
import { useToaster, DataTable, ChevronRight } from '@helloextend/zen'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { accountsColumns, toTableAccounts } from './table-config'
import { setAccountsUrl } from '../../../../../store/slices/accounts-breadcrumbs'
import { useGetAccounts } from '../../../../../hooks/use-get-accounts'
import type { TableAccounts } from '../../../../../types/accounts'

const AccountsDataTable: FC = () => {
  const { search: queryParams } = useLocation()
  const dispatch = useDispatch()
  const { push } = useHistory()

  useEffect(() => {
    const accountsUrl = `/accounts${queryParams}`
    dispatch(setAccountsUrl(accountsUrl))
  }, [dispatch, queryParams])

  const { accounts, isLoading, isError } = useGetAccounts()

  const { toast } = useToaster()

  const getRowActions = (row: TableAccounts): DataTableAction[] => [
    {
      onClick: () =>
        push(
          `/admin/accounts/${row.accountId}/${row.isLegacyAccount ? 'stores' : 'organizations'}`,
        ),
      icon: ChevronRight,
      emphasis: 'low',
    },
  ]

  useEffect(() => {
    if (isLoading) {
      toast({
        message: 'Retrieving results... This might take a few moments',
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
      return
    }
    if (isError) {
      toast({
        message: 'Unable to retrieve accounts data',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [toast, isLoading, isError])

  return (
    <DataTable
      data-cy="accounts-table"
      data={toTableAccounts(accounts) || []}
      getRowActions={getRowActions}
      isLoading={isLoading}
      columns={accountsColumns}
    />
  )
}

export { AccountsDataTable }
