import type { FC } from 'react'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useClickOutside } from '../../hooks'
import type { ButtonSize } from '../button'
import { DivButton } from '../button'
import { Help } from '../../tokens/icons'
import type { PopoverPosition } from '../../utils/caclulate-popover-position'
import { calculatePopoverPosition } from '../../utils/caclulate-popover-position'
import { InformationSize } from './types'
import { InformationBubble } from './information-bubble'
import type { IconProps } from '../../tokens/icons/icon-props'

const OFFSET_PX = 4
const TRANSITION_DURATION_MS = 200

interface InformationProps {
  children: React.ReactNode
  size?: InformationSize
  buttonSize?: Exclude<ButtonSize, 'large'>
  icon?: IconProps
  'data-cy'?: string
}

const Information: FC<InformationProps> = ({
  children,
  size = InformationSize.regular,
  buttonSize = 'small',
  icon = Help,
  'data-cy': dataCy,
}) => {
  const animationRef = useRef<NodeJS.Timeout | null>(null)
  const [isToggled, setIsToggled] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [isPresent, setIsPresent] = useState(false)
  const [position, setPosition] = useState<PopoverPosition>({
    top: 0,
    bottom: 'unset',
    left: 0,
    right: 'unset',
  })

  const on = useCallback(() => {
    if (animationRef.current) {
      clearTimeout(animationRef.current)
      animationRef.current = null
    }
    setIsToggled(true)
    setIsPresent(true)
  }, [])

  const off = useCallback(() => {
    setIsToggled(false)
    animationRef.current = setTimeout(() => {
      setIsPresent(false)
    }, TRANSITION_DURATION_MS)
  }, [])

  const toggle = useCallback(() => {
    if (isToggled) {
      off()
    } else {
      on()
    }
  }, [on, off, isToggled])

  /* isVisible needs to be set when isToggled changes in order to trigger a rerender and css transition after the initial mount */
  useEffect(() => {
    setIsVisible(isToggled)
  }, [isToggled])

  const bubbleRef = useRef<HTMLDivElement>(null)
  const { ref: buttonRef } = useClickOutside<HTMLDivElement>(off)

  useEffect(() => {
    if (!isPresent || !buttonRef.current || !bubbleRef.current) {
      return
    }

    const targetBounding = buttonRef.current.getBoundingClientRect()
    const refBounding = bubbleRef.current.getBoundingClientRect()
    const newPosition = calculatePopoverPosition(
      window.innerWidth,
      window.innerHeight,
      targetBounding,
      refBounding,
      OFFSET_PX,
    )

    setPosition(newPosition)
  }, [isPresent, buttonRef])

  useEffect(() => {
    // Use capture instead of bubble to ensure any scroll event on the page will remove information bubble
    window.addEventListener('scroll', off, true)

    return () => {
      window.removeEventListener('scroll', off, true)
    }
  }, [off])

  return (
    <>
      <DivButton
        ref={buttonRef}
        onClick={toggle}
        onMouseDown={(e) => e.stopPropagation()}
        onBlur={off}
        size={buttonSize}
        emphasis="low"
        color="blue"
        icon={icon}
        isToggled={isToggled}
        data-cy={dataCy}
      />
      {isPresent && (
        <InformationBubble
          ref={bubbleRef}
          isVisible={isVisible}
          position={position}
          size={size}
          transitionDuration={TRANSITION_DURATION_MS}
          data-cy={dataCy && `${dataCy}:bubble`}
        >
          {children}
        </InformationBubble>
      )}
    </>
  )
}

export { Information }
