import * as connectActions from './connect'
import * as updateActions from './update'
import * as resetActions from './reset'
import type { ExtractActions } from '../../types/utility'

export type Action =
  | ExtractActions<typeof connectActions>
  | ExtractActions<typeof updateActions>
  | ExtractActions<typeof resetActions>

export type ActionType = Action['type']

const actions = { ...connectActions, ...updateActions, ...resetActions }

export { actions }
