import type { FC } from 'react'
import React, { useMemo, useEffect } from 'react'
import styled from '@emotion/styled'
import { currency } from '@helloextend/client-utils'
import { Button, useToaster, ToastDuration, ToastColor } from '@helloextend/zen'
import { useReportRefundByIdMutation } from '@helloextend/extend-api-rtk-query'
import { BasicModal } from '../basic-modal'

type RefundModalProps = {
  visible: boolean
  contractId: string
  amount: number | undefined
  closeModal: () => void
}

const RefundModal: FC<RefundModalProps> = ({ visible, contractId, amount, closeModal }) => {
  const [reportRefundById, { isLoading, isSuccess, isError }] = useReportRefundByIdMutation()
  const { toast } = useToaster()

  useEffect(() => {
    if (isError) {
      toast({
        message: `The refund couldn’t be reported. Please try again later`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }

    if (isSuccess) {
      toast({
        message: 'Refund reported',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    }
  }, [isSuccess, isError, toast])

  const submitUserRefund = async (): Promise<void> => {
    await reportRefundById({ contractId, contractsApiVersion: 'latest' })
    closeModal()
  }

  const refundAmount = useMemo(() => {
    if (typeof amount !== 'number') {
      return '–'
    }

    return currency.format(amount)
  }, [amount])

  return (
    <BasicModal onClickClose={closeModal} isVisible={visible}>
      <Title>Quoted Refund Amount: {refundAmount}</Title>
      <ButtonGroup>
        <Button onClick={closeModal} text="Cancel" emphasis="medium" />
        <Button
          data-cy="refund-btn"
          isProcessing={isLoading}
          text="Refund"
          onClick={submitUserRefund}
        />
      </ButtonGroup>
    </BasicModal>
  )
}

const Title = styled.h3({
  fontSize: 20,
  marginTop: 0,
  marginBottom: 16,
})

const ButtonGroup = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 8,
  marginTop: 40,
})

export { RefundModal, RefundModalProps }
