import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { Store } from '@helloextend/extend-api-client'
import { Grid, Input, Switch } from '@helloextend/zen'
import { useFormik } from 'formik'
import { SaveChangesButtonGroup } from '../save-changes-button-group'
import type { TableauUserIdValues } from './schema'
import { tableauUserIdSchema } from './schema'
import { TableauReports } from '../tableau-reports/tableau-reports'

type AnalyticsTabSettingsProps = {
  store: Store
  handleUpdateStore: (data: Partial<Store>) => void
}

const AnalyticsTabSettings: FC<AnalyticsTabSettingsProps> = ({ store, handleUpdateStore }) => {
  const { values, errors, dirty, handleChange, isValid, handleReset } =
    useFormik<TableauUserIdValues>({
      initialValues: {
        tableauUserId: store?.tableauUserId ?? '',
      },
      onSubmit: () => {},
      validationSchema: tableauUserIdSchema,
      enableReinitialize: true,
    })

  if (!store) return null

  return (
    <>
      <BottomMargin>
        <Switch
          label="Show Analytics Tab"
          id="analyticsDisabled"
          isOn={!store.analyticsDisabled}
          onChange={() => handleUpdateStore({ analyticsDisabled: !store.analyticsDisabled })}
          data-cy="analytics-tab-toggle"
        />
      </BottomMargin>
      <BottomMargin>
        <Grid spacing={0} columns={2}>
          <Input
            label="Tableau User ID"
            isDisabled={store.analyticsDisabled}
            id="tableauUserId"
            value={values.tableauUserId || ''}
            errorFeedback={errors.tableauUserId}
            isError={Boolean(errors.tableauUserId)}
            onChange={handleChange}
            placeholder="Enter Tableau User ID"
            data-cy="tableau-user-id-input"
          />
        </Grid>
      </BottomMargin>
      {dirty && (
        <BottomMargin>
          <SaveChangesButtonGroup
            isSaveDisabled={!isValid}
            isLoading={false}
            id="tableauUserId"
            handleSave={() => handleUpdateStore({ tableauUserId: values.tableauUserId })}
            handleCancel={handleReset}
          />
        </BottomMargin>
      )}
      <BottomMargin>
        <TableauReports store={store} />
      </BottomMargin>
    </>
  )
}

const BottomMargin = styled.div({
  marginBottom: 16,
})

export { AnalyticsTabSettings }
