import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import type { Carrier } from '@helloextend/extend-api-client'
import { Dropdown } from '../dropdown'

interface CarrierSelectProps {
  name?: string
  label?: string
  placeholder?: string
  onChange: (e: SyntheticEvent<HTMLSelectElement | HTMLButtonElement>) => void
  value: string
  errorMessage?: string
}

const carrierMap: Record<Carrier, string> = {
  usps: 'USPS',
  ups: 'UPS',
}

const options = Object.keys(carrierMap).map((carrier) => {
  return {
    label: carrierMap[carrier as Carrier],
    value: carrier,
  }
})

const CarrierSelect: FC<CarrierSelectProps> = ({
  name = 'carrier',
  label = 'Carrier',
  placeholder = '—Select—',
  onChange,
  value,
  errorMessage,
}): JSX.Element => {
  return (
    <Dropdown
      name={name}
      label={label}
      labelFontWeight={700}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      options={options}
      errorMessage={errorMessage}
    />
  )
}

export { CarrierSelect }
export type { CarrierSelectProps }
