import type { Action } from '../actions'

export type AuthAccessTokenReducerState = string | null

const initialState: AuthAccessTokenReducerState = null

export default function accessToken(
  state = initialState,
  action: Action,
): AuthAccessTokenReducerState {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return action.payload
    case 'LOGIN_FAILURE':
    case 'LOGOUT':
      return null
    default:
      return state
  }
}
