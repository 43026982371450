import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from '@emotion/styled'
import FocusTrap from 'focus-trap-react'
import { COLOR } from '../../tokens/colors'
import { useTransition } from '../../transitions'
import type { BoundingBox } from './types'
import { PopoverAlignment, PopoverDirection } from './types'

const TARGET_OFFSET_PX = 4
const WINDOW_OFFSET_PX = 8

type PopoverPosition = {
  top: number
  left: number
}

const calculateAlignment = (
  alignment: PopoverAlignment,
  targetStart: number,
  targetSize: number,
  popoverSize: number,
): number => {
  switch (alignment) {
    case PopoverAlignment.start:
      return targetStart
    case PopoverAlignment.center:
      return targetStart + targetSize / 2 - popoverSize / 2
    case PopoverAlignment.end:
      return targetStart + targetSize - popoverSize
  }

  return 0
}

const calculatePopoverPosition = (
  direction: PopoverDirection,
  alignment: PopoverAlignment,
  containerWidth: number,
  containerHeight: number,
  targetBounding: BoundingBox,
  popoverBounding: BoundingBox,
): PopoverPosition => {
  let top: number
  let left: number

  switch (direction) {
    case PopoverDirection.up:
      top = targetBounding.top - popoverBounding.height - TARGET_OFFSET_PX
      left = calculateAlignment(
        alignment,
        targetBounding.left,
        targetBounding.width,
        popoverBounding.width,
      )
      break
    case PopoverDirection.right:
      top = calculateAlignment(
        alignment,
        targetBounding.top,
        targetBounding.height,
        popoverBounding.height,
      )
      left = targetBounding.left + targetBounding.width + TARGET_OFFSET_PX
      break
    case PopoverDirection.down:
      top = targetBounding.top + targetBounding.height + TARGET_OFFSET_PX
      left = calculateAlignment(
        alignment,
        targetBounding.left,
        targetBounding.width,
        popoverBounding.width,
      )
      break
    case PopoverDirection.left:
      top = calculateAlignment(
        alignment,
        targetBounding.top,
        targetBounding.height,
        popoverBounding.height,
      )
      left = targetBounding.left - popoverBounding.width - TARGET_OFFSET_PX
      break
  }

  if (left < WINDOW_OFFSET_PX) {
    left = WINDOW_OFFSET_PX
  }

  if (left + popoverBounding.width > containerWidth - WINDOW_OFFSET_PX) {
    left = containerWidth - WINDOW_OFFSET_PX - popoverBounding.width
  }

  if (top < WINDOW_OFFSET_PX) {
    top = WINDOW_OFFSET_PX
  }

  if (top + popoverBounding.height > containerHeight - WINDOW_OFFSET_PX) {
    top = containerHeight - WINDOW_OFFSET_PX - popoverBounding.height
  }

  return { top, left }
}

const getTransform = (direction: PopoverDirection, isVisible: boolean): string => {
  const translate = [PopoverDirection.down, PopoverDirection.up].includes(direction)
    ? 'translateY'
    : 'translateX'

  const amount = [PopoverDirection.up, PopoverDirection.left].includes(direction) ? 4 : -4

  return `${translate}(${isVisible ? 0 : amount}px)`
}

interface PopoverPanelProps {
  children: React.ReactNode
  header?: React.ReactNode
  footer?: React.ReactNode
  direction: PopoverDirection
  alignment: PopoverAlignment
  maxWidth?: number
  maxHeight?: number
  triggerBoundingBox: BoundingBox | null
  isInitiallyFocused: boolean
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void
  'data-cy'?: string
  returnFocusOnHide: boolean
}

const PopoverPanel = forwardRef<HTMLDivElement, PopoverPanelProps>(
  (
    {
      children,
      header,
      footer,
      direction,
      alignment,
      maxWidth,
      maxHeight,
      triggerBoundingBox,
      isInitiallyFocused,
      onScroll,
      'data-cy': dataCy,
      returnFocusOnHide,
    },
    ref,
  ) => {
    const panelRef = useRef<HTMLDivElement>(null)
    useImperativeHandle(ref, () => panelRef.current as HTMLDivElement)

    const [position, setPosition] = useState<PopoverPosition>({
      top: 0,
      left: 0,
    })

    const { isVisible, transitionDurationMs } = useTransition()
    const refBounding = panelRef.current ? panelRef.current.getBoundingClientRect() : null

    const refBoundingBox = useMemo<BoundingBox | null>(() => {
      if (refBounding?.left === undefined) {
        return null
      }
      return {
        top: refBounding.top,
        left: refBounding.left,
        width: refBounding.width,
        height: refBounding.height,
      }
    }, [refBounding?.top, refBounding?.left, refBounding?.width, refBounding?.height])

    useEffect(() => {
      if (!triggerBoundingBox || !refBoundingBox) {
        return
      }

      const newPosition = calculatePopoverPosition(
        direction,
        alignment,
        window.innerWidth,
        window.innerHeight,
        triggerBoundingBox,
        refBoundingBox,
      )

      setPosition(newPosition)
    }, [ref, alignment, direction, triggerBoundingBox, refBoundingBox, children])

    const maxWindowWidth = window.innerWidth - WINDOW_OFFSET_PX * 2
    const maxWindowHeight = window.innerHeight - WINDOW_OFFSET_PX * 2

    return createPortal(
      <FocusTrap
        focusTrapOptions={{
          initialFocus: isInitiallyFocused ? undefined : false,
          escapeDeactivates: false,
          clickOutsideDeactivates: true,
          returnFocusOnDeactivate: returnFocusOnHide,
          fallbackFocus: () => panelRef.current || '',
        }}
      >
        <StyledPopover
          ref={panelRef}
          top={position.top}
          left={position.left}
          minWidth={triggerBoundingBox?.width}
          maxWidth={maxWidth && maxWidth < maxWindowWidth ? maxWidth : maxWindowWidth}
          maxHeight={maxHeight && maxHeight < maxWindowHeight ? maxHeight : maxWindowHeight}
          isVisible={isVisible}
          transitionDurationMs={transitionDurationMs}
          $direction={direction}
          data-cy={dataCy}
          tabIndex={-1}
        >
          {!!header && <Header>{header}</Header>}
          <Body onScroll={onScroll}>{children}</Body>
          {!!footer && <Footer>{footer}</Footer>}
        </StyledPopover>
      </FocusTrap>,
      document.body,
    )
  },
)

const StyledPopover = styled.div(
  ({
    top,
    left,
    minWidth,
    maxWidth,
    maxHeight,
    isVisible,
    transitionDurationMs,
    $direction,
  }: {
    top: number
    left: number
    minWidth: number | undefined
    maxWidth: number
    maxHeight: number
    isVisible: boolean
    transitionDurationMs: number
    $direction: PopoverDirection
  }) => ({
    position: 'fixed',
    zIndex: 9999998, // This needs to be astronomically high to guarantee it sits above other established overlayed elements.
    top,
    left,
    minWidth,
    maxWidth,
    maxHeight,
    backgroundColor: COLOR.WHITE,
    borderRadius: 4,
    boxShadow: `0 0 0 1px ${COLOR.NEUTRAL.OPACITY[25]}, 0 6px 24px 0 ${COLOR.NEUTRAL.OPACITY[25]}`,
    overflow: 'auto',
    boxSizing: 'border-box',
    transitionProperty: 'opacity, transform',
    transitionDuration: `${transitionDurationMs}ms`,
    opacity: isVisible ? 1 : 0,
    transform: getTransform($direction, isVisible),
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr auto',
    gridTemplateAreas: '"header" "body" "footer"',
    outline: 'none',
  }),
)

const Header = styled.header({
  gridArea: 'header',
  borderBottom: `1px solid ${COLOR.NEUTRAL[300]}`,
})

const Body = styled.div({
  gridArea: 'body',
  overflow: 'auto',
})

const Footer = styled.footer({
  gridArea: 'footer',
  borderTop: `1px solid ${COLOR.NEUTRAL[300]}`,
})

export { PopoverPanel }
