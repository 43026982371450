import styled from '@emotion/styled'
import type { FC } from 'react'
import React, { useCallback, useRef } from 'react'
import { useMenuContext } from '../../menu/menu-context'

export const FilterForm: FC = ({ children }) => {
  const { hideAll } = useMenuContext()

  const formRef = useRef<HTMLFormElement>(null)

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      formRef.current?.focus()
      hideAll()
    },
    [hideAll],
  )

  return (
    <Form tabIndex={-1} ref={formRef} onSubmit={handleSubmit}>
      {children}
    </Form>
  )
}

const Form = styled.form({
  outline: 'none',
})
