import type { FC } from 'react'
import React, { useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import type { ThreadUpdateBody, ThreadResponse } from '@helloextend/extend-api-rtk-query'
import {
  useSaveThreadMutation,
  usePublishThreadMutation,
  useGetThreadUsedByQuery,
} from '@helloextend/extend-api-rtk-query'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { useStandardToast } from '@helloextend/merchants-ui'
import { omit } from 'lodash/fp'
import { useSelector } from 'react-redux'
import * as selectors from '../../../../../reducers/selectors'
import type { RootState } from '../../../../../reducers'
import { ConfirmationModal } from '../../components/confirmation-modal'
import { ConfirmPublishModal } from '../../components/confirm-publish-modal'

type ModalContainerProps = {
  toggleSaveModal: () => void
  togglePublishModal: () => void
  saveSuccess: (savedThread: ThreadResponse) => void
  publishSucess: (publishedThread: ThreadResponse) => void
  isSaveModalVisible: boolean
  isPublishModalVisible: boolean
  isThreadDirty: boolean
}

const ModalContainer: FC<ModalContainerProps> = ({
  isSaveModalVisible,
  isPublishModalVisible,
  toggleSaveModal,
  togglePublishModal,
  saveSuccess,
  publishSucess,
  isThreadDirty,
}) => {
  const history = useHistory()
  const { toastCompleted, toastError } = useStandardToast()

  const selectedThread = useSelector((state: RootState) => selectors.getSelectedThread(state))
  const { data: usedByList } = useGetThreadUsedByQuery(selectedThread?.id ?? skipToken)

  const [saveThread, { isLoading: isSaveProcessing, reset: resetSaveThreadMutation }] =
    useSaveThreadMutation()
  const [publishThread, { isLoading: isPublishProcessing, reset: resetPublishThreadMutation }] =
    usePublishThreadMutation()

  const isSaveOrPublishProcessing = isSaveProcessing || isPublishProcessing

  const threadUpdatePayload: ThreadUpdateBody = useMemo(
    () => omit(['id', 'editedBy', 'createdAt', 'updatedAt'], selectedThread),
    [selectedThread],
  )

  const isThreadReferencedByConversations = useMemo(
    () => Boolean(usedByList && usedByList.length > 0),
    [usedByList],
  )

  const performSaveAndRetrieveLatestThread = async (
    threadToSave: ThreadResponse,
  ): Promise<ThreadResponse> => {
    const savedThread = await saveThread({
      threadId: threadToSave.id,
      thread: threadUpdatePayload,
    }).unwrap()
    return savedThread
  }

  const hideSaveModal = useCallback(() => {
    if (isSaveModalVisible) {
      toggleSaveModal()
    }
  }, [isSaveModalVisible, toggleSaveModal])

  const hidePublishModal = useCallback(() => {
    if (isPublishModalVisible) {
      togglePublishModal()
    }
  }, [isPublishModalVisible, togglePublishModal])

  if (!selectedThread) return null

  const handleConfirmSave = async (): Promise<void> => {
    try {
      const savedThread = await performSaveAndRetrieveLatestThread(selectedThread)
      saveSuccess(savedThread)
      toastCompleted(`${selectedThread.title ?? 'Your thread'} has been successfully saved.`)
      hideSaveModal()
    } catch {
      toastError('Something went wrong. Please try again.')
      hideSaveModal()
    } finally {
      resetSaveThreadMutation()
    }
  }

  const handleConfirmPublish = async (): Promise<void> => {
    try {
      let savedThread: ThreadResponse
      let publishedThread: ThreadResponse
      let versionToPublish: number
      if (isThreadDirty) {
        savedThread = await performSaveAndRetrieveLatestThread(selectedThread)
        saveSuccess(savedThread)
        toastCompleted(`${selectedThread.title ?? 'Your thread'} has been successfully saved.`)
        resetSaveThreadMutation()
        versionToPublish = savedThread.version
        publishedThread = { ...savedThread, status: 'published' }
      } else {
        versionToPublish = selectedThread.version
        publishedThread = { ...selectedThread, status: 'published' }
      }

      await publishThread({
        threadId: selectedThread.id,
        version: versionToPublish,
      }).unwrap()

      publishSucess(publishedThread)
      toastCompleted(`${selectedThread.title ?? 'Your thread'} has been successfully published.`)
      history.push('/admin/adjudication-management/threads')
    } catch {
      toastError('Something went wrong. Please try again.')
      hidePublishModal()
      resetSaveThreadMutation()
      resetPublishThreadMutation()
    }
  }

  return (
    <>
      {isSaveModalVisible && (
        <ConfirmationModal
          isVisible={isSaveModalVisible}
          onCancel={toggleSaveModal}
          onConfirm={handleConfirmSave}
          isProcessing={isSaveProcessing}
          confirmButtonColor="blue"
          confirmButtonText="Save Changes"
          heading="Save the changes for review"
          description="You are about to save the changes made to the thread. The updated thread will not be available for assigning to conversations unless it is published."
        />
      )}
      {isPublishModalVisible && (
        <ConfirmPublishModal
          isVisible={isPublishModalVisible}
          onCancel={togglePublishModal}
          onConfirm={handleConfirmPublish}
          isProcessing={isSaveOrPublishProcessing}
          threadId={selectedThread.id}
          threadTitle={selectedThread.title}
          isThreadReferencedByConversations={isThreadReferencedByConversations}
        />
      )}
    </>
  )
}

export { ModalContainer, ModalContainerProps }
