import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { plansActions } from '@helloextend/core-api-redux'
import { useDispatch, useSelector } from 'react-redux'
import { Toast } from '@helloextend/merchants-ui'
import { useHistory, useLocation } from 'react-router-dom'
import type { Plan } from '@helloextend/extend-api-client'
import { usePrevious } from '@helloextend/client-hooks'
import type { Values } from '../plan/schema'
import { schema } from '../plan/schema'
import * as selectors from '../../../reducers/selectors'
import { PlanForm } from '../plan/plan-form'
import type { RootState } from '../../../reducers'
import { planPropertyMapper } from '../../../utils/plan-property-mapper'
import { SuccessHeaderPopup } from '../../../components/success-popup'
import { mapSchemaToValues } from '../../../utils/plans-mapping'
import { CreateFormHeader } from '../../../components/create-form-header/create-form-header'

const PlansCreate: FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation<{ planDetails?: Plan }>()
  const planDetails = location?.state?.planDetails
  const accessToken = useSelector((state: RootState) => selectors.getAccessToken(state)) || ''
  const isLoading = useSelector((state: RootState) => selectors.getIsPlansLoading(state))
  const isSaveSuccessful = useSelector((state: RootState) => selectors.getIsPlansSuccess(state))
  const planError = useSelector((state: RootState) => selectors.getPlansError(state))
  const isCreating = useSelector((state: RootState) => selectors.getIsPlansCreating(state))
  const prevCreating = usePrevious(isCreating)

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    isValid,
    dirty,
    resetForm,
    setFieldValue,
    setErrors,
  } = useFormik<Values>({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: {
      ...mapSchemaToValues(planDetails),
      id: '',
    },
    onSubmit: (): void => {},
  })

  const handleSave = (): void => {
    const updatedPlanInfo = planPropertyMapper(values)
    dispatch(plansActions.create(accessToken, updatedPlanInfo))
  }

  const onSuccessEnd = (): void => {
    if (isSaveSuccessful) {
      history.push(`/admin/plans/${values.id.trim()}`)
      dispatch(plansActions.resetSuccess())
    }
  }

  useEffect(() => {
    return () => {
      if (planError && prevCreating) {
        dispatch(plansActions.errorReset())
      }
    }
  }, [planError, prevCreating, dispatch])

  return (
    <>
      {isSaveSuccessful && (
        <SuccessHeaderPopup message="Plan Created" onAnimationEnd={onSuccessEnd} />
      )}
      {planError && (
        <Toast
          message="Plan could not be created. Please try again later."
          isSuccessToaster={false}
        />
      )}

      <form onSubmit={handleSubmit}>
        <CreateFormHeader
          title="Create new plan"
          createButtonText="Create Plan"
          isFormDirty={dirty}
          isCreateSuccess={isSaveSuccessful}
          isFormValid={isValid}
          isProcessing={isLoading}
          titleCy="create-plan-header"
          createButtonCy="create-plan-btn"
          resetForm={resetForm}
          handleSave={handleSave}
        />
        <PlanForm
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          errors={errors}
          touched={touched}
          isCreate
          setFieldValue={setFieldValue}
          setErrors={setErrors}
        />
      </form>
    </>
  )
}

export { PlansCreate }
