import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import type {
  Contract20220201GetResponse,
  ContractFormValuesUpdate,
} from '@helloextend/extend-api-client'
import { useSelector, useDispatch } from 'react-redux'
import {
  useFetchPlanQuery,
  useGetEntitlementsQuery,
  useGetProductQuery,
} from '@helloextend/extend-api-rtk-query'
import { ContractType } from '@helloextend/extend-api-client'
import type { FormikMapperConfig } from '../../../../components/form-text-group'
import {
  formikMapper,
  CollapsibleFormGroupEditWrapper,
  applyEditPermissions,
  CollapsibleFormTextGroup,
} from '../../../../components/form-text-group'
import type { V2Values } from '../schema'
import { V2Schema, mapContract20220201ToValues } from '../schema'
import {
  customerFields,
  billingInformationFields,
  shippingInformationFields,
  storeInformationFields,
  contractInformationFields,
  productInformationFields,
  sectionTitles,
  transactionInformationFields,
} from '../fields'
import type { RootState } from '../../../../reducers'
import * as storesActions from '../../../../actions/stores'
import * as selectors from '../../../../reducers/selectors'
import { LeavePageModal } from '../../../../components/leave-page-guard'
import { permissionsSelectorV2 } from '../edit-permissionsV2'
import { mapContractV2ToLatestUpdateRequest } from '../../../../utils/contract-property-mapper'
import { useUser } from '../../../../hooks/use-user'
import { STORE_ERROR_MESSAGE } from '../constants'
import type { InputItem } from '../../../../components/form-text-group/types'

type PcrsProps = {
  saveUpdates?: (formValues: ContractFormValuesUpdate) => void
  toggleNavBlocked?: (blocked: boolean) => void
  contract: Contract20220201GetResponse
  planTermsUrl?: string
}

const PCRS: FC<PcrsProps> = ({ contract, saveUpdates, toggleNavBlocked, planTermsUrl }) => {
  const dispatch = useDispatch()
  const store = useSelector((state: RootState) => selectors.getStoreById(state, contract?.sellerId))
  const accessToken = useSelector((state: RootState) => selectors.getAccessToken(state))
  const storeError = useSelector((state: RootState) => selectors.getStoreErrors(state))
  const isContractUpdating = useSelector((state: RootState) =>
    selectors.getIsContractUpdating(state),
  )
  const { user } = useUser()
  const [initialFormValues, setInitialFormValues] = useState({} as V2Values)
  const isContractUpdateSuccessful = useSelector((state: RootState) =>
    selectors.getIsContractUpdateSuccess(state),
  )
  const [selectedSection, setSelectedSection] = useState('')
  const [isLeaveSectionModalVisible, setIsLeaveSectionModalVisible] = useState(false)
  const role = user.role ?? 'merchantagent'
  const appliedPermissionRole = permissionsSelectorV2[role]

  const { data: entitlements } = useGetEntitlementsQuery(
    { contractId: contract?.id ?? '' },
    { skip: !contract || contract.type !== ContractType.PCRS },
  )

  const { data: plan } = useFetchPlanQuery(contract?.plan.id ?? '')

  const { data: product } = useGetProductQuery({
    storeId: contract.sellerId,
    referenceId: contract.product.referenceId,
  })

  useEffect(() => {
    if (!store && !storeError && accessToken && contract) {
      dispatch(storesActions.fetch(contract.sellerId, accessToken))
    }
  }, [contract, dispatch, store, accessToken, storeError])

  useEffect(() => {
    if (contract && store) {
      setInitialFormValues(
        mapContract20220201ToValues(contract, store, planTermsUrl, plan, product, entitlements),
      )
    }
  }, [contract, store, planTermsUrl, product, plan, entitlements])

  useEffect(() => {
    if (contract && storeError) {
      setInitialFormValues(
        mapContract20220201ToValues(contract, store, planTermsUrl, plan, product, entitlements),
      )
    }
  }, [contract, store, storeError, planTermsUrl, product, plan, entitlements])

  useEffect(() => {
    if (isContractUpdateSuccessful) {
      setSelectedSection('')
    }
  }, [isContractUpdateSuccessful])

  const { values, errors, touched, dirty, handleChange, handleBlur, resetForm, setFieldValue } =
    useFormik<V2Values>({
      enableReinitialize: true,
      validationSchema: V2Schema.get(selectedSection),
      validateOnChange: true,
      validateOnBlur: false,
      initialValues: initialFormValues,
      onSubmit: (): void => {},
    })

  useEffect(() => {
    if (toggleNavBlocked) toggleNavBlocked(dirty)
  }, [dirty, toggleNavBlocked])

  if (!contract) return null
  const hasErrors = Object.entries(errors).length >= 1

  const setFormValues = (formField: FormikMapperConfig[]): InputItem[] => {
    const updatedFormFields = applyEditPermissions(formField, appliedPermissionRole)
    return formikMapper<V2Values>(
      {
        ...values,
        limitOfLiabilityAmountType: values?.limitOfLiabilityAmountType,
      },
      touched,
      errors,
      updatedFormFields,
    )
  }

  const handleClickEdit = (section: string): void => {
    if (dirty) {
      setIsLeaveSectionModalVisible(true)
      return
    }
    setSelectedSection(section)
  }

  const handleCancel = (): void => {
    resetForm()
    setSelectedSection('')
  }

  const handleFormSave = (): void => {
    const updateValues = mapContractV2ToLatestUpdateRequest(values, contract)
    // the PUT API for some 2.0 contracts environments will throw an error if the original status is passed back to it
    // This check will remove status if it has not been updated.
    if (updateValues.contractUpdate.values.status === initialFormValues.contractStatus) {
      delete updateValues.contractUpdate.values.status
    }
    if (saveUpdates) saveUpdates(updateValues)
    resetForm({ values })
  }

  const handleLeaveSectionModalClose = (): void => {
    setIsLeaveSectionModalVisible(false)
  }

  const handleLeaveSectionModalLeave = (): void => {
    handleCancel()
    setIsLeaveSectionModalVisible(false)
  }

  return (
    <Container data-cy="contract-form-pcrs">
      <LeavePageModal
        isVisible={isLeaveSectionModalVisible}
        handleCloseModal={handleLeaveSectionModalClose}
        handleLeavePage={handleLeaveSectionModalLeave}
      />
      <LeftContainer>
        <CollapsibleFormGroupEditWrapper
          dataCy="customer-section"
          handleSubmit={handleFormSave}
          handleCancel={handleCancel}
          handleClickEdit={handleClickEdit}
          isSubmitDisabled={hasErrors || !dirty}
          isEditable
          isEditing={selectedSection === sectionTitles.customerInformation}
          isSubmitting={isContractUpdating}
          title={sectionTitles.customerInformation}
        >
          <NestedFormGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            isDisabled={selectedSection !== sectionTitles.customerInformation || isContractUpdating}
            isStatic={selectedSection !== sectionTitles.customerInformation}
            values={setFormValues(customerFields)}
            numColumns={4}
            autoFocus
            warning={storeError ? STORE_ERROR_MESSAGE : undefined}
          />
          <NestedFormGroup
            subsectionTitle={sectionTitles.billingInformation}
            handleChange={handleChange}
            handleBlur={handleBlur}
            isDisabled={selectedSection !== sectionTitles.customerInformation || isContractUpdating}
            isStatic={selectedSection !== sectionTitles.customerInformation}
            values={setFormValues(billingInformationFields)}
            numColumns={9}
            autoFocus
          />
          <NestedFormGroup
            subsectionTitle={sectionTitles.shippingInformation}
            handleChange={handleChange}
            handleBlur={handleBlur}
            isDisabled={selectedSection !== sectionTitles.customerInformation}
            isStatic={selectedSection !== sectionTitles.customerInformation}
            values={setFormValues(shippingInformationFields)}
            numColumns={9}
            autoFocus
          />
        </CollapsibleFormGroupEditWrapper>
        <CollapsibleFormGroupEditWrapper
          dataCy="transaction-section"
          handleSubmit={handleFormSave}
          handleCancel={handleCancel}
          handleClickEdit={handleClickEdit}
          isSubmitDisabled={hasErrors || !dirty}
          isEditable
          isEditing={selectedSection === sectionTitles.transactionInformation}
          isSubmitting={isContractUpdating}
          title={sectionTitles.transactionInformation}
          shouldStartExpanded
        >
          <NestedFormGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            isDisabled={
              selectedSection !== sectionTitles.transactionInformation || isContractUpdating
            }
            isStatic={selectedSection !== sectionTitles.transactionInformation}
            numColumns={4}
            values={setFormValues(transactionInformationFields)}
            autoFocus
          />
        </CollapsibleFormGroupEditWrapper>
        <CollapsibleFormGroupEditWrapper
          dataCy="store-section"
          handleSubmit={handleFormSave}
          handleCancel={handleCancel}
          handleClickEdit={handleClickEdit}
          isSubmitting={isContractUpdating}
          title={sectionTitles.storeInformation}
          isSubmitDisabled={hasErrors || !dirty}
          isEditing={selectedSection === sectionTitles.storeInformation}
          shouldStartExpanded
        >
          <NestedFormGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            isDisabled={selectedSection !== sectionTitles.storeInformation || isContractUpdating}
            isStatic={selectedSection !== sectionTitles.storeInformation}
            numColumns={3}
            sectionError={Boolean(storeError)}
            errorMessage={STORE_ERROR_MESSAGE}
            values={setFormValues(storeInformationFields)}
            autoFocus
          />
        </CollapsibleFormGroupEditWrapper>
        <CollapsibleFormGroupEditWrapper
          dataCy="contract-section"
          handleSubmit={handleFormSave}
          handleCancel={handleCancel}
          handleClickEdit={handleClickEdit}
          isSubmitDisabled={hasErrors || !dirty}
          isEditable
          isEditing={selectedSection === sectionTitles.contractInformation}
          isSubmitting={isContractUpdating}
          title={sectionTitles.contractInformation}
          shouldStartExpanded
        >
          <NestedFormGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            isDisabled={selectedSection !== sectionTitles.contractInformation}
            numColumns={4}
            setFieldValue={setFieldValue}
            isStatic={selectedSection !== sectionTitles.contractInformation}
            autoFocus
            values={setFormValues(contractInformationFields)}
          />
        </CollapsibleFormGroupEditWrapper>
        <CollapsibleFormGroupEditWrapper
          dataCy="product-section"
          handleSubmit={handleFormSave}
          handleCancel={handleCancel}
          handleClickEdit={handleClickEdit}
          isSubmitDisabled={hasErrors || !dirty}
          isEditing={selectedSection === sectionTitles.productInformation}
          title={sectionTitles.productInformation}
          isEditable
          isSubmitting={isContractUpdating}
          shouldStartExpanded
        >
          <NestedFormGroup
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            isDisabled={selectedSection !== sectionTitles.productInformation || isContractUpdating}
            isStatic={selectedSection !== sectionTitles.productInformation}
            numColumns={4}
            autoFocus
            values={setFormValues(productInformationFields)}
          />
        </CollapsibleFormGroupEditWrapper>
      </LeftContainer>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  gap: 40,
  alignItems: 'flex-start',
})
const LeftContainer = styled.div({
  width: '100%',
})

const NestedFormGroup = styled(CollapsibleFormTextGroup)({
  border: 'none',
  padding: 0,
  margin: 0,
})

export { PCRS }
