import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@helloextend/client-branding'

type ShieldIconProps = {
  fill?: string
}

const ShieldIcon: FC<ShieldIconProps> = ({ fill = COLOR.BLACK_MUTED }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 13">
    <path
      d="M5.111 12.06a.361.361 0 01-.186-.052L3.1 10.906A6.45 6.45 0 010 5.415V.935A.36.36 0 01.616.681c.547.549 2.645 1.013 4.24-.576a.36.36 0 01.51 0 3.562 3.562 0 002.531 1.096c.81 0 1.453-.265 1.708-.52a.36.36 0 01.615.255v4.48a6.448 6.448 0 01-3.098 5.49l-1.825 1.102a.36.36 0 01-.186.052zM.723 1.599v3.816a5.721 5.721 0 002.748 4.872l1.64.99 1.638-.99A5.721 5.721 0 009.5 5.415V1.602c-1.057.49-2.874.567-4.389-.749-1.515 1.315-3.332 1.238-4.389.75l.002-.004z"
      fill={fill}
    />
    <path
      d="M4.643 7.44a.36.36 0 01-.253-.106L3.23 6.176a.36.36 0 01.508-.506l.905.904 2.125-2.13a.36.36 0 11.508.505l-2.381 2.38a.36.36 0 01-.252.111z"
      fill={fill}
    />
  </svg>
)

export { ShieldIcon }
