import React from 'react'

export const emphasizeSearchString = (display: string, searchString: string): React.ReactNode => {
  if (!searchString) return display

  const start = display.toLowerCase().indexOf(searchString.toLocaleLowerCase())

  if (start < 0) {
    return display
  }

  return (
    <>
      {display.substring(0, start)}
      <b>{display.substring(start, start + searchString.length)}</b>
      {display.substring(start + searchString.length)}
    </>
  )
}
