import type { FC } from 'react'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

type VariantsCountLinkProps = {
  referenceId: string
  count: string
}

const VariantsCountLink: FC<VariantsCountLinkProps> = ({ referenceId, count }) => {
  const { storeId } = useParams<{
    storeId: string
  }>()
  const isVariant = Number(count) > 0
  return isVariant ? (
    <Link to={`/admin/stores/${storeId}/products/${referenceId}/variants`}>{count} variants</Link>
  ) : (
    <span>&#8212;</span>
  )
}

export { VariantsCountLink }
