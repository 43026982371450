import { productsActions } from '@helloextend/core-api-redux'

export const {
  fetch,
  fetchFailure,
  fetchRequest,
  update,
  updateRequest,
  updateSuccess,
  updateFailure,
} = productsActions
