import type { ChangeEvent, FC } from 'react'
import React, { useEffect } from 'react'
import type { OfferPriceType, Store } from '@helloextend/extend-api-client'
import { Radio, Switch } from '@helloextend/zen'
import { useUpdateStoreMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import styled from '@emotion/styled'
import { SectionContainer } from '../../../../../../../../components/section-container'
import { Divider } from '../../../../../../../../components/divider'

type OfferFormProps = {
  store: Store
}

const OfferForm: FC<OfferFormProps> = ({ store }) => {
  const [updateStore, { isError, isSuccess }] = useUpdateStoreMutation()
  const { toastCompleted, toastError } = useStandardToast()
  const isOfferModalV2 = store.offerModalVersion === 'V2'

  const handleUpdateStore = (storeData: Partial<Store>): void => {
    updateStore({
      storeId: store.id,
      data: storeData,
      version: 'latest',
    })
  }

  const handleVersionToggle = (): void => {
    handleUpdateStore({ offerModalVersion: isOfferModalV2 ? 'V1' : 'V2' })
  }

  const handleOfferPriceTypeUpdate = (e: ChangeEvent<HTMLInputElement>): void => {
    handleUpdateStore({ offerPriceType: e.target.value as OfferPriceType })
  }

  useEffect(() => {
    if (isSuccess) {
      toastCompleted('Offer Settings have been successfully updated.')
    }
  }, [isSuccess, toastCompleted])

  useEffect(() => {
    if (isError) {
      toastError('Offer Settings could not be updated. Please try again.')
    }
  }, [isError, toastError])

  return (
    <SectionContainer title="Offer">
      <Switch
        label="Offer Modal V2"
        id="offerModalVersion"
        isOn={isOfferModalV2}
        onChange={handleVersionToggle}
        data-cy="offer-modal-v2-toggle"
      />
      <Divider margin="24px 0" />
      <RadioGroup>
        <RadioButtonsLabel>Display Offer Based On</RadioButtonsLabel>
        <Radio
          checked={store?.offerPriceType === 'product_list_price'}
          onChange={handleOfferPriceTypeUpdate}
          label="Product List Price"
          name="offerPriceType"
          value="product_list_price"
        />
        <Radio
          checked={store?.offerPriceType === 'product_purchase_price'}
          onChange={handleOfferPriceTypeUpdate}
          label="Product Purchase Price"
          name="offerPriceType"
          value="product_purchase_price"
        />
      </RadioGroup>
    </SectionContainer>
  )
}

const RadioGroup = styled.div({
  fontSize: '14px',
})
const RadioButtonsLabel = styled.p({
  fontWeight: 800,
  lineHeight: '16px',
})

export { OfferForm }
