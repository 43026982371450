import { faker } from '@faker-js/faker/locale/en'
import type { Product, ProductsSearchItem } from '@helloextend/extend-api-client'

export function generateProduct(overrides: any = {}): Product {
  const productName = faker.commerce.productName()
  const skuLike = faker.datatype.uuid()
  const plan1Id = '10001-misc-elec-adh-replace-1y'
  const plan2Id = '10001-misc-elec-adh-replace-2y'

  return {
    brand: faker.company.name(),
    category: faker.commerce.department(),
    description: `Description for ${productName}`,
    identifiers: {
      gtin: `gtin-${skuLike}`,
      sku: `sku-${skuLike}`,
      ...overrides.identifiers,
    },
    imageUrl: faker.image.cats(),
    mfrWarranty: {
      labor: faker.datatype.number(100),
      parts: faker.datatype.number(100),
      url: faker.internet.url(),
      ...overrides.mfrWarranty,
    },
    createdAt: faker.date.past().getTime(),
    plans: [plan1Id, plan2Id],
    price: faker.datatype.number(10000),
    referenceId: skuLike,
    storeId: faker.datatype.uuid(),
    title: `Title - ${productName}`,
    approved: true,
    enabled: true,
    settings: {
      isBundle: false,
      partialReplacementEligible: false,
      isProductReturn: true,
      servicingLocationId: null,
    },
    subproducts: [
      { id: faker.datatype.uuid(), quantity: 1 },
      { id: faker.datatype.uuid(), quantity: 2 },
    ],
    ...overrides,
  }
}

export function generateRdsProduct(
  overrides: Partial<ProductsSearchItem> = {},
): ProductsSearchItem {
  return {
    imageUrl: faker.image.cats(),
    name: `${faker.commerce.productName()}`,
    referenceId: faker.datatype.uuid(),
    parentReferenceId: '',
    matchedVariantCount: 0,
    sku: faker.datatype.uuid(),
    price: faker.datatype.number(10000),
    enabled: true,
    warrantyStatus: 'pending',
    variantCount: 0,
    category: faker.commerce.department(),
    ...overrides,
  }
}
