import type { ClaimPhotoDetail } from '@helloextend/extend-api-client'
import type { Action } from '../actions'

export type ByClaimIdReducerState = Record<string, ClaimPhotoDetail[]>

export const initialState: ByClaimIdReducerState = {}

export default function byClaimId(state = initialState, action: Action): ByClaimIdReducerState {
  switch (action.type) {
    case 'CONTRACTS_CLAIMS_PHOTOS_FETCH_SUCCESS':
      return addItems(state, action.payload)
    case 'CONTRACTS_CLAIMS_PHOTOS_RESET':
      return initialState
    default:
      return state
  }
}

function addItems(
  state: ByClaimIdReducerState,
  payload: { claimId: string; photoDetails: ClaimPhotoDetail[] },
): ByClaimIdReducerState {
  const { claimId, photoDetails } = payload
  return { ...state, [claimId]: photoDetails }
}
