import type { FC } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { useClickOutside, useToggle } from '@helloextend/client-hooks'
import { List, ListItem, Menu } from '@helloextend/merchants-ui'
import { Button, ButtonGroup, COLOR } from '@helloextend/zen'
import { More } from '@helloextend/zen/src/tokens/icons'
import { useHistory } from 'react-router-dom'
import { useExportSkusMutation } from '@helloextend/extend-api-rtk-query'
import { SkusDataTable } from './skus-data-table'

const Skus: FC = () => {
  const { push } = useHistory()
  const [isMenuOpen, { toggle: toggleMenu, off }] = useToggle()
  const { ref } = useClickOutside<HTMLDivElement>(() => {
    off()
  })

  const [create, { data: exportData, isLoading: isExporting }] = useExportSkusMutation()

  useEffect(() => {
    if (exportData?.url) {
      window.open(exportData.url, '_blank')
    }
  }, [exportData])

  const handleCreateNewSku = (): void => {
    push('/admin/premiums/create')
  }

  const handleImportClick = (): void => {
    push('/admin/premiums/import')
  }

  const handleExportClick = (): void => {
    create('')
    off()
  }

  return (
    <>
      <Header>
        <Title data-cy="skus-title">Premiums</Title>
        <div ref={ref}>
          <ButtonGroup>
            <Button
              text="Import"
              data-cy="import-button"
              onClick={handleImportClick}
              emphasis="medium"
              isDisabled={isExporting}
            />
            <Button
              icon={More}
              onClick={toggleMenu}
              emphasis="medium"
              isProcessing={isExporting}
              data-cy="list"
            />
            <Menu isOpen={isMenuOpen} width={200} position="right">
              <List fullWidth>
                <ListItem
                  type="button"
                  id="create-sku"
                  onClick={handleCreateNewSku}
                  color={COLOR.BLACK}
                  hoverBackground={COLOR.BLUE.OPACITY[12]}
                >
                  Create Premium
                </ListItem>
                {/* TODO: PUPS-1116 Enable this and refactor when the endpoint supports filters  */}
                {/* <ListItem
                  type="button"
                  id="export-skus-results"
                  onClick={handleExportClick}
                  color={COLOR.BLACK}
                  hoverBackground={COLOR.BLUE.OPACITY[12]}
                >
                  Export results
                </ListItem> */}
                <ListItem
                  type="button"
                  id="export-skus-all"
                  onClick={handleExportClick}
                  color={COLOR.BLACK}
                  hoverBackground={COLOR.BLUE.OPACITY[12]}
                >
                  Export all
                </ListItem>
              </List>
            </Menu>
          </ButtonGroup>
        </div>
      </Header>
      <SkusDataTable />
    </>
  )
}

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 24,
})

const Title = styled.h1({
  color: COLOR.BLUE[1000],
  fontSize: 32,
  lineHeight: '44px',
  fontWeight: 800,
  margin: 0,
})

export { Skus }
