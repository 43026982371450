import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import { GeneralInfoForm } from './general-info-form'

interface GeneralInfoSectionProps {
  isLoading: boolean
  setHasChangesMade: (value: boolean) => void
  profileSuccessMessage: string | null
  'data-cy'?: string
}

const GeneralInfoSection: FC<GeneralInfoSectionProps> = ({
  isLoading,
  setHasChangesMade,
  profileSuccessMessage,
  'data-cy': dataCy,
}) => {
  return (
    <GeneralInfoSectionWrapper data-cy={dataCy}>
      <HeaderGroup>
        <GeneralInfoHeader data-cy="general-info-header-text">General Info</GeneralInfoHeader>
      </HeaderGroup>
      <GeneralInfoForm
        isDisabled={{
          firstName: false,
          lastName: false,
          email: true,
          save: false,
          cancel: false,
        }}
        isLoading={isLoading}
        setHasChangesMade={setHasChangesMade}
        profileSuccessMessage={profileSuccessMessage}
        isButtonsVisible
        data-cy="general-info-form"
      />
    </GeneralInfoSectionWrapper>
  )
}

const HeaderGroup = styled.div({
  width: '25%',
  paddingLeft: 12,
})

const GeneralInfoHeader = styled.h2({
  margin: 0,
  color: COLOR.VERY_DARK_BLUE_0,
})

const GeneralInfoSectionWrapper = styled.section({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  alignContent: 'space-between',
  padding: 40,
  border: `1px solid ${COLOR.GRAYISH_BLUE_0}`,
  marginBottom: 32,
})

export { GeneralInfoSection, GeneralInfoSectionProps }
