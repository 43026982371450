import * as fetchActions from './fetch'
import * as fetchDetailActions from './fetch-details'
import * as fetchAllActions from './fetch-all'
import * as resetActions from './reset'
import * as searchActions from './search'
import * as createActions from './create'
import type { ExtractActions } from '../../types/utility'

export type Action =
  | ExtractActions<typeof fetchActions>
  | ExtractActions<typeof fetchDetailActions>
  | ExtractActions<typeof fetchAllActions>
  | ExtractActions<typeof resetActions>
  | ExtractActions<typeof searchActions>
  | ExtractActions<typeof createActions>

export type ActionType = Action['type']

const actions = {
  ...fetchActions,
  ...fetchDetailActions,
  ...fetchAllActions,
  ...resetActions,
  ...searchActions,
  ...createActions,
}

export { actions }
