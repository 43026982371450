import type { ButtonColor } from '../button'

export enum InlineAlertColor {
  blue = 'blue',
  green = 'green',
  neutral = 'neutral',
  yellow = 'yellow',
  red = 'red',
}

export type ColorArgs = {
  backgroundColor: string
  borderColor: string
  iconColor: string
  textColor: string
  buttonColor: ButtonColor
}
