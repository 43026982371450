import type { FC } from 'react'
import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { startCase } from 'lodash/fp'
import type { ContractCoveredProduct, ContractTrackingInfo } from '@helloextend/extend-api-client'
import { Subheading } from '@helloextend/zen/src/tokens/typography'
import { DataProperty, Grid } from '@helloextend/zen'

import ProductsTable from './products-table'

interface ContractProductTracking extends ContractTrackingInfo {
  products: ContractCoveredProduct[]
}

export interface ProductShippingInformationProps {
  values: ContractProductTracking[]
  purchaseCurrency: string
  productsWithoutTrackingInfo: ContractCoveredProduct[]
  storeId: string
}

const ProductShippingInformation: FC<ProductShippingInformationProps> = ({
  values,
  storeId,
  purchaseCurrency,
  productsWithoutTrackingInfo,
}) => {
  return (
    <>
      {values.map((value, index) => {
        return (
          <Fragment key={value.trackingId}>
            <StyledSubheading data-cy="product-name" style={{ marginBottom: '2rem' }}>
              Shipment {index + 1}{' '}
            </StyledSubheading>
            <Grid
              columns={{ lg: 3, md: 2, sm: 1 }}
              data-cy={`product-shipping-${index}`}
              spacing={{ lg: 3, md: 2, sm: 1 }}
            >
              <DataProperty
                value={startCase(value.shippingProvider)}
                label="Shipping Provider"
                data-cy="shipping-provider"
                includeCopyAction={false}
              />

              <DataProperty
                value={value.trackingId}
                label="Tracking ID"
                data-cy="tracking-id"
                includeCopyAction={false}
              />

              <DataProperty
                value={value.shippingState}
                label="Shipping Status"
                data-cy="shipping-status"
                includeCopyAction={false}
              />

              <DataProperty
                value={value.shipmentId}
                label="Shipment ID"
                data-cy="shipment-id"
                includeCopyAction={false}
              />
            </Grid>
            <ProductsTable
              storeId={storeId}
              products={value.products}
              purchaseCurrency={purchaseCurrency}
            />
          </Fragment>
        )
      })}
      {!!productsWithoutTrackingInfo.length && (
        <ContainNoShipmentTable>
          <StyledSubheading data-cy="product-name-shipment-unassigned">
            No Shipments Assigned
          </StyledSubheading>
          <ProductsTable
            storeId={storeId}
            products={productsWithoutTrackingInfo}
            purchaseCurrency={purchaseCurrency}
          />
        </ContainNoShipmentTable>
      )}
    </>
  )
}

const StyledSubheading = styled(Subheading)({
  textTransform: 'uppercase',
  marginTop: '1rem',
})

const ContainNoShipmentTable = styled.div({
  marginTop: '3.5rem',
})

export { ProductShippingInformation, ContractProductTracking }
