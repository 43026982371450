import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import { useLocation } from 'react-router-dom'
import { querystring } from '@helloextend/client-utils'

const DISPLAY_TEXT = {
  PASS_RESET_SUCCESS_MESSAGE: 'You have successfully changed your password! Log in below.',
}

const LoginSuccessMessage: FC = () => {
  const { search } = useLocation()
  const { password_reset: passwordReset } = querystring.parse<{ password_reset: string }>(search)
  const isPassResetSuccess = passwordReset === 'success'

  if (!isPassResetSuccess) return null

  return <Message>{DISPLAY_TEXT.PASS_RESET_SUCCESS_MESSAGE}</Message>
}

const Message = styled.div({
  color: COLOR.STATE_SUCCESS,
  fontSize: 14,
  marginBottom: 24,
})

export { LoginSuccessMessage }
