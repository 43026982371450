export const reset = () =>
  ({
    type: 'PLANS_RESET',
  } as const)

export const resetSuccess = () =>
  ({
    type: 'PLANS_SUCCESS_RESET',
  } as const)

export const errorReset = () => ({ type: 'PLANS_ERROR_RESET' } as const)
