import React from 'react'
import { formatWithoutCurrencySymbol } from '@helloextend/client-utils/currency'
import type { Money, Plan, PlanContract, PlanMatching } from '@helloextend/extend-api-client'
import type { CellContext, ColumnDefs } from '@helloextend/zen'
import { Link } from 'react-router-dom'

const tableColumns: ColumnDefs<Plan> = [
  {
    id: 'name',
    label: 'Plan Name',
  },
  {
    id: 'id',
    label: 'Plan ID',
    renderCell: (data: CellContext<Plan, string>) => (
      <Link to={`/admin/plans/${data.getValue()}`} target="_blank">
        {data.getValue()}
      </Link>
    ),
  },
  {
    id: 'plan_type',
    label: 'Type',
  },
  {
    id: 'contract',
    label: 'Term',
    renderCell: (data: CellContext<Plan, PlanContract>) => getTermLength(data.getValue()),
  },
  {
    id: 'matching',
    label: 'Price',
    renderCell: (data: CellContext<Plan, PlanMatching[]>) => {
      const { original } = data.row
      return calculatePlanPrice(data.getValue(), original.productPrice as Money)
    },
  },
]

const getTermLength = (planContract: PlanContract): string => {
  return `${planContract?.term_length} months`
}

const calculatePlanPrice = (planMatching: PlanMatching[], productPrice: Money): string => {
  // take the product price and compare it to the price_low and price_high of the planMatching array to determine which premium (plan price) to display
  const { amount } = productPrice || {}
  const matchingPlan = planMatching?.find((plan) => {
    return amount >= plan.price_low && amount <= plan.price_high
  })
  return matchingPlan ? formatWithoutCurrencySymbol(matchingPlan.cost) : 'N/A'
}

export { tableColumns, calculatePlanPrice }
