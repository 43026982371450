import type { ScriptItem } from '@helloextend/extend-api-rtk-query'
import { MessageType } from '@helloextend/extend-api-rtk-query'

export const generateDateMessageBlock = (): ScriptItem => {
  return {
    collect: {
      options: [
        {
          action: 'execute',
          execute: {
            scriptIndex: -1,
          },
          default: true,
        },
      ],
    },
    reply: {
      messages: [
        {
          type: MessageType.text,
          content: '',
        },
      ],
      prompt: {
        type: 'datepicker',
        slot: 'IncidentDate',
        placeholder: 'Pick a date',
        options: [],
      },
    },
  }
}
