import { getFormattedValue } from '@helloextend/zen'

/**
 * Converts a number to a currency string for a given currency type. Behaves as an abstraction
 * to the CurrencyInput's formatting functionality
 * @param value The number to convert as a whole number (e.g. 400.00 is passed in as 40000)
 * @param locale The locale to use for formatting
 * @returns A formatted currency string conforming to the locale specified
 */
export const numberToCurrency = (value: number, locale: string): string => {
  // This is primarily used for validations but since we don't want to tie our validation directly to
  // an internal component's format function, we are using this utility function as an abstraction so
  // if future changes to the formatting break things we can swap it out here without having to update
  // our validation messages. Things will "just work" but we have the flexibility to extend as needed
  // moving forward without the need for control updates to support said extensions.
  return getFormattedValue(`${value}`, locale)
}
