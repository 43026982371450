import type { ChangeEvent, FC } from 'react'
import React from 'react'
import { Input, InputType } from '@helloextend/zen'

export interface NameInputProps {
  handleChangePlanSetName: (e: ChangeEvent<HTMLInputElement>) => void
  planSetName: string
  prefix?: string
}

// TODO: [OFFERS-1615] on save, we need to combine prefix w/ user entered suffix
const NameInput: FC<NameInputProps> = ({ handleChangePlanSetName, planSetName, prefix }) => {
  return (
    <Input
      isDisabled={!prefix}
      ariaLabel="Plan Set Name"
      data-cy="plan-set-name"
      errorFeedback=""
      id="plan-set-name"
      label="Plan Set Name"
      onChange={handleChangePlanSetName}
      prefix={prefix}
      type={InputType.text}
      value={planSetName}
    />
  )
}

export { NameInput }
