import type { FC, VFC } from 'react'
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Popover, PopoverDirection, usePopover } from '../popover'
import { MenuContextProvider, useMenuContext } from './menu-context'
import type { MenuItem, MenuItemsProps, MenuProps, SubmenuProps } from './menu-types'
import { blurActiveElement, keepInView } from './menu-utils'
import { MenuButtonItem, MenuCheckbox, MenuLinkItem } from '../menu-item'
import { MenuDivider } from './menu-divider'
import { COLOR } from '../../tokens/colors'

export const Menu: FC<MenuProps> = ({
  'data-cy': dataCy,
  children,
  direction,
  footer,
  hasHeightAuto,
  header,
  items = [],
  padding = '6px 8px',
  role = 'menu',
  triggerRenderer,
  _isRecursion = false,
  _onSubmenuShow,
  _onSubmenuHide,
}) => {
  const [renderedSubmenuCount, setRenderedSubmenuCount] = useState(0)
  const [isUsingKeyboard, setIsUsingKeyboard] = useState(false)
  const [cursorIndex, setCursorIndex] = useState(-1)
  const menuRef = useRef<HTMLDivElement>(null)
  const { triggerRef, popoverRef, isPresent, toggle, hide, hideAll, triggerBoundingBox } =
    usePopover<HTMLElement>({
      onShow: _onSubmenuShow,
      onHide: _onSubmenuHide,
    })
  const shouldAcceptKeystrokes = items.length > 0 && isPresent && renderedSubmenuCount === 0

  const getCursoredElement = (index: number): HTMLElement => {
    return menuRef.current?.querySelectorAll('[data-is-cursorable]').item(index) as HTMLElement
  }

  const mapItems: (
    menuItems: MenuItem[],
    startingIndex?: number,
  ) => { mappedItems: MenuItem[]; flattenedItems: MenuItem[]; count: number } = useCallback(
    (menuItems, startingIndex = 0) => {
      let count = 0
      let flattenedItems: MenuItem[] = []
      const mapped = menuItems.map((item) => {
        const hasCursor = count + startingIndex === cursorIndex && isUsingKeyboard
        switch (item.type) {
          case 'menu':
            count += 1
            flattenedItems = [...flattenedItems, item]
            return {
              ...item,
              _hasCursor: hasCursor,
              _onSubmenuShow: () => setRenderedSubmenuCount((s) => s + 1),
              _onSubmenuHide: () => setRenderedSubmenuCount((s) => s - 1),
            }
          case 'group': {
            const {
              mappedItems: groupItems,
              flattenedItems: groupFlattenedItems,
              count: groupItemCount,
            } = mapItems(item.items || [], count + startingIndex)
            flattenedItems = [...flattenedItems, ...groupFlattenedItems]
            count += groupItemCount
            return {
              ...item,
              items: groupItems,
            }
          }
          default:
            count += 1
            flattenedItems = [...flattenedItems, item]
            return {
              ...item,
              _hasCursor: hasCursor,
            }
        }
      })

      return {
        mappedItems: mapped,
        flattenedItems,
        count,
      }
    },
    [cursorIndex, isUsingKeyboard],
  )

  const { mappedItems, flattenedItems, count } = useMemo(() => mapItems(items), [items, mapItems])

  const handleTriggerInteraction = useCallback(
    (e) => {
      if (e.nativeEvent.pointerType === '') {
        setIsUsingKeyboard(true)
        setCursorIndex(0)
      }
      toggle()
    },
    [toggle],
  )

  const handleKeyDown = useCallback(
    (e: globalThis.KeyboardEvent) => {
      if (isPresent && e.key === 'Escape') {
        blurActiveElement()
      }
      const validKeys = ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'Enter', ' ']
      if (shouldAcceptKeystrokes) {
        if (validKeys.includes(e.key)) {
          setIsUsingKeyboard(true)
          e.preventDefault()
        }
        switch (e.key) {
          case 'ArrowDown': {
            const newCursorIndex = cursorIndex + 1
            if (newCursorIndex < count) {
              const cursoredElement = getCursoredElement(newCursorIndex)
              setCursorIndex(newCursorIndex)
              cursoredElement.focus({ preventScroll: true })
              keepInView(cursoredElement)
            }
            break
          }
          case 'ArrowUp': {
            const newCursorIndex = cursorIndex - 1
            if (newCursorIndex >= 0) {
              const cursoredElement = getCursoredElement(newCursorIndex)
              setCursorIndex(newCursorIndex)
              cursoredElement.focus({ preventScroll: true })
              keepInView(cursoredElement)
            }
            break
          }
          case 'ArrowLeft': {
            hide()
            blurActiveElement()
            break
          }
          case 'ArrowRight': {
            if (flattenedItems[cursorIndex] && flattenedItems[cursorIndex].type === 'menu') {
              e.stopImmediatePropagation()
              const cursoredElement = getCursoredElement(cursorIndex)
              cursoredElement.click()
            }
            break
          }
          case 'Enter': {
            if (flattenedItems[cursorIndex]) {
              e.stopImmediatePropagation()
              const cursoredElement = getCursoredElement(cursorIndex)
              cursoredElement.click()
              blurActiveElement()
            }
            break
          }
          case ' ': {
            if (flattenedItems[cursorIndex]) {
              e.stopImmediatePropagation()
              const cursoredElement = getCursoredElement(cursorIndex)
              cursoredElement.click()
              blurActiveElement()
            }
            break
          }
        }
      }
    },
    [count, cursorIndex, flattenedItems, hide, isPresent, shouldAcceptKeystrokes],
  )

  const handleDocumentClick = useCallback((e) => {
    if (e.pointerType !== '') {
      setCursorIndex(-1)
      setIsUsingKeyboard(false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [handleDocumentClick])

  useEffect(() => {
    if (!isPresent) {
      setCursorIndex(-1)
      setIsUsingKeyboard(false)
    }
  }, [isPresent])

  const clonedTrigger = React.cloneElement(triggerRenderer(isPresent), {
    ref: triggerRef,
    isToggled: isPresent,
    ariaExpanded: isPresent,
    ariaHasPopup: role,
    onClick: handleTriggerInteraction,
  })

  return (
    <MenuContextProvider hideAll={hideAll}>
      <>
        {clonedTrigger}
        <Popover
          maxHeight={hasHeightAuto ? undefined : 480}
          ref={popoverRef}
          isPresent={isPresent}
          isInitiallyFocused
          triggerBoundingBox={triggerBoundingBox}
          direction={direction}
          header={header}
          footer={footer}
          returnFocusOnHide={!_isRecursion}
        >
          <StyledMenu
            data-cy={dataCy}
            padding={padding}
            ref={menuRef}
            role={role}
            aria-orientation="vertical"
          >
            {children}
            <MenuItems role={role} items={mappedItems} />
          </StyledMenu>
        </Popover>
      </>
    </MenuContextProvider>
  )
}

const MenuItems: VFC<MenuItemsProps> = ({ items, role }) => {
  const { hideAll } = useMenuContext()
  return (
    <>
      {items.map((item, itemIndex) => {
        const key = `item-${itemIndex}`
        switch (item.type) {
          case 'menu':
            return <Submenu key={key} {...item} />
          case 'group':
            return (
              <Fragment key={key}>
                {itemIndex > 0 && <MenuDivider />}
                {item.label && <GroupHeading>{item.label}</GroupHeading>}
                {item.children}
                {item.items && item.items.length > 0 && (
                  <MenuItems role={role} items={item.items} />
                )}
                {itemIndex < items.length - 1 && <MenuDivider />}
              </Fragment>
            )
          case 'button':
            return (
              <MenuButtonItem
                tabIndex={-1}
                key={key}
                {...(role === 'listbox' && { role: 'option' })}
                {...item}
                onClick={(e) => {
                  if (item.onClick) {
                    item.onClick(e)
                  }
                  if (!item.hasRetainMenuOnClick) {
                    hideAll()
                  }
                }}
              />
            )
          case 'link':
            return (
              <MenuLinkItem
                tabIndex={-1}
                key={key}
                {...(role === 'listbox' && { role: 'option' })}
                {...item}
                onClick={(e) => {
                  if (item.onClick) {
                    item.onClick(e)
                  }
                  hideAll()
                }}
              />
            )
          case 'checkbox':
            return (
              <MenuCheckbox
                tabIndex={-1}
                key={key}
                {...(role === 'menu' && { role: 'menuitemcheckbox' })}
                {...item}
              />
            )
          default:
            return null
        }
      })}
    </>
  )
}

export const Submenu: FC<SubmenuProps> = ({
  'data-cy': dataCy,
  children,
  label,
  footer,
  hasHeightAuto,
  header,
  items,
  padding,
  role = 'menu',
  _hasCursor,
  _onSubmenuShow,
  _onSubmenuHide,
}) => {
  return (
    <Menu
      data-cy={dataCy}
      direction={PopoverDirection.right}
      footer={footer}
      hasHeightAuto={hasHeightAuto}
      header={header}
      items={items}
      padding={padding}
      role={role}
      triggerRenderer={() => (
        <MenuButtonItem
          label={label}
          showArrow
          _hasCursor={_hasCursor}
          tabIndex={_hasCursor ? 0 : -1}
        />
      )}
      _isRecursion
      _onSubmenuShow={_onSubmenuShow}
      _onSubmenuHide={_onSubmenuHide}
    >
      {children}
    </Menu>
  )
}

const StyledMenu = styled.div<{
  padding: number | string
}>(({ padding }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding,
  [`& ${MenuDivider} + ${MenuDivider}`]: {
    display: 'none',
  },
}))

const GroupHeading = styled.div({
  fontSize: 12,
  lineHeight: '20px',
  padding: '10px 12px',
  fontWeight: 700,
  textTransform: 'uppercase',
  color: COLOR.NEUTRAL[700],
})
