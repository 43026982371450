import type {
  KeyboardEvent,
  MouseEventHandler,
  ReactElement,
  SyntheticEvent,
  ChangeEvent,
  MouseEvent,
} from 'react'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { If, Then, Else, When } from 'react-if'
import { Field } from '../field'
import { ArrowDropDown, Search, Close, Info } from '../../../tokens/icons'
import { Popover, usePopover } from '../../popover'
import { MenuButtonItem, MenuSkeleton } from '../../menu-item'
import { Input } from '../input'
import { Chip } from '../../chip'
import { Badge } from '../../badge'
import type { BadgeColor, BadgeEmphasis } from '../../badge/badge-types'
import { getLimitText } from '../utils'
import { Spinner } from '../../spinner'
import { Option } from './components/option'
import type {
  AdvancedSelectBlurEvent,
  AdvancedSelectChangeEvent,
  AdvancedSelectOption,
  AdvancedSelectOptionGroup,
  AdvancedSelectProps,
} from './advanced-select-types'
import {
  BadgeContainer,
  Chips,
  DisplayValue,
  DisplayValueContainer,
  EmptyContainer,
  LimitText,
  MenuItems,
  OptionGroup,
  Header,
  StyledAdvancedSelect,
  StyledSubheading,
  SelectedOnlyTrigger,
  SpinnerContainer,
} from './components/styled-components'
import { Information, InformationSize } from '../../information'

interface GroupedAdvancedSelectOption extends AdvancedSelectOption {
  group?: string
}

const asAdvancedSelectOptionGroup = (
  optionOrGroup: AdvancedSelectOption | AdvancedSelectOptionGroup,
): AdvancedSelectOptionGroup | undefined =>
  Object.prototype.hasOwnProperty.call(optionOrGroup, 'options')
    ? (optionOrGroup as AdvancedSelectOptionGroup)
    : undefined

const asAdvancedSelectOption = (
  optionOrGroup: AdvancedSelectOption | AdvancedSelectOptionGroup,
): AdvancedSelectOption | undefined =>
  Object.prototype.hasOwnProperty.call(optionOrGroup, 'options')
    ? undefined
    : (optionOrGroup as AdvancedSelectOption)

/**
 * Designers may choose to display the Advanced Select component when more control is needed over presentation or if
 * multi-select is needed. Options are passed in as an array of objects and selected value is returned as a string or an
 * array accordingly.
 *
 * Note: When a footer action is clicked the select menu popover will automatically be dismissed. In order to disable
 * this, the prop `disableAutoDismiss: true` can be set on each object in `footerActions`.
 */
export const AdvancedSelect = <T extends string | string[]>({
  ariaLabel,
  autoFocus,
  badgePosition = 'end',
  errorFeedback,
  footerActions,
  helperText,
  hideSelectedMenuItems = false,
  isCounterHiddenWhenValid,
  isLoading = false,
  isNotClearable = false,
  icon,
  id,
  isDisabled = false,
  isError = false,
  label,
  maxQuantityToDisplay,
  multiple = false,
  onChange,
  onBlur,
  options,
  pageSize,
  placeholder,
  popoverMaxHeightPx = 320,
  showSearch = false,
  subtext,
  value,
  maxLength,
  'data-cy': dataCy,
}: AdvancedSelectProps<T>): ReactElement => {
  const [searchString, setSearchString] = useState('')
  const [qtyMenuItemsVisible, setQtyMenuItemsVisible] = useState(pageSize || null)
  const [isSelectedOnly, setIsSelectedOnly] = useState(false)
  const [isInternallyFocused, setIsInternallyFocused] = useState(false)

  /* Set element refs in order to interact with the selected option and optional search field */
  const searchInputRef = useRef<HTMLInputElement>(null)

  const hasValue = Array.isArray(value) ? value.length > 0 : !!value
  const showClearButton = hasValue && !isNotClearable && !isDisabled
  const hasShowSelectedOnlyToggle = maxQuantityToDisplay === 0
  const showSelectOnlyTrigger = hasShowSelectedOnlyToggle && value.length > 0 && multiple
  const showPopoverFooter = !!footerActions || showSelectOnlyTrigger

  const {
    triggerRef,
    popoverRef,
    isPresent: isPopoverPresent,
    toggle,
    show,
    triggerBoundingBox,
  } = usePopover<HTMLButtonElement>({
    onShow: () => setIsInternallyFocused(true),
    onHide: () => {
      setIsInternallyFocused(false)
      handleBlur()
    },
  })

  const handleTogglePopover = useCallback(() => {
    if (!isPopoverPresent) {
      if (pageSize) {
        setQtyMenuItemsVisible(pageSize)
      }
      setIsSelectedOnly(false)
      setSearchString('')
    }
    toggle()
  }, [isPopoverPresent, pageSize, toggle])

  const handleQuantitySelectedClick = useCallback(
    (e: MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation()
      if (pageSize) {
        setQtyMenuItemsVisible(pageSize)
      }
      setIsSelectedOnly(true)
      setSearchString('')
      show()
    },
    [pageSize, show],
  )

  const handleBlur = useCallback((): void => {
    const blurEvent = new Event('blur')
    Object.defineProperty(blurEvent, 'target', {
      writable: true,
      value: { value, id },
    })
    if (onBlur) onBlur(blurEvent as AdvancedSelectBlurEvent<T>)
  }, [id, onBlur, value])

  const handleChange = useCallback(
    (
      e:
        | SyntheticEvent<HTMLButtonElement>
        | SyntheticEvent<HTMLDivElement>
        | ChangeEvent<HTMLInputElement>,
      newValue: T,
    ): void => {
      // Redefine target to allow name and value to be read.
      // This allows seamless integration with the most popular form libraries.
      // Clone the event to not override `target` of the original event.
      // Note: This mechanism was copied from MUI's Select component
      const { nativeEvent } = e
      const clonedEvent = new Event(nativeEvent.type, nativeEvent)

      Object.defineProperty(clonedEvent, 'target', {
        writable: true,
        value: { value: newValue, id },
      })

      if (newValue.length === 0) {
        setIsSelectedOnly(false)
      }

      onChange(clonedEvent as AdvancedSelectChangeEvent<T>)
    },
    [id, onChange],
  )

  const handleSelectOption = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>, newValue: string): void => {
      handleChange(e, newValue as unknown as T)
      toggle()
      triggerRef.current?.focus()
    },
    [handleChange, toggle, triggerRef],
  )

  const handleMultiSelectOption = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let newValue = [...value]
      if (e.target.checked) {
        newValue = [...value, e.target.value]
      } else {
        newValue.splice(newValue.indexOf(e.target.value), 1)
      }
      handleChange(e, newValue as unknown as T)
    },
    [handleChange, value],
  )

  const handleChipDismiss = useCallback(
    (e: SyntheticEvent<HTMLDivElement>, valueToRemove: string) => {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
      const newValue = [...value]
      newValue.splice(newValue.indexOf(valueToRemove), 1)
      handleChange(e, newValue as unknown as T)
      triggerRef.current?.focus()
    },
    [handleChange, triggerRef, value],
  )

  const handleSearchChange = useCallback(
    (e) => {
      if (pageSize) {
        setQtyMenuItemsVisible(pageSize)
      }
      setSearchString(e.target.value)
    },
    [pageSize],
  )

  const handleSearchKeyDown = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      setSearchString('')
    }
  }, [])

  const handleSearchClear = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.nativeEvent.stopImmediatePropagation()
      if (pageSize) {
        setQtyMenuItemsVisible(pageSize)
      }
      setSearchString('')
      searchInputRef.current?.focus()
    },
    [pageSize],
  )

  const handleSelectClear = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>): void => {
      if (multiple) {
        handleChange(e, [] as unknown as T)
      } else {
        handleChange(e, '' as unknown as T)
      }
      triggerRef.current?.focus()
    },
    [handleChange, multiple, triggerRef],
  )

  const handleShowSelectedOnlyToggleClick = useCallback(() => {
    setIsSelectedOnly(!isSelectedOnly)
    if (pageSize) {
      setQtyMenuItemsVisible(pageSize)
    }
    setSearchString('')
  }, [isSelectedOnly, pageSize])

  const isOptionSelected = useCallback(
    (optionValue: string): boolean => {
      if (Array.isArray(value)) {
        return value.includes(optionValue)
      }
      return value === optionValue
    },
    [value],
  )

  const flattenedOptions = useMemo(() => {
    return options.reduce<GroupedAdvancedSelectOption[]>((acc, current) => {
      const group = asAdvancedSelectOptionGroup(current)

      if (group) {
        return [
          ...acc,
          ...group.options.map((option) => ({
            ...option,
            group: group.label,
          })),
        ]
      }

      const option = asAdvancedSelectOption(current)

      if (option) {
        return [...acc, option]
      }

      return acc
    }, [])
  }, [options])

  const filteredOptions = useMemo(() => {
    const flattenedAndFiltered = flattenedOptions.filter(
      (option) =>
        option.display.trim().toLowerCase().includes(searchString.toLowerCase()) &&
        (!isSelectedOnly || isOptionSelected(option.value)) &&
        (!hideSelectedMenuItems || !isOptionSelected(option.value)),
    )

    const visible = flattenedAndFiltered.slice(
      0,
      qtyMenuItemsVisible ? qtyMenuItemsVisible - 1 : undefined,
    )

    return visible.reduce<Array<AdvancedSelectOption | AdvancedSelectOptionGroup>>(
      (acc, option) => {
        if (option.group) {
          const existingGroup = acc.find((optionOrGroup) => {
            const group = asAdvancedSelectOptionGroup(optionOrGroup)
            if (group) {
              return group.label === option.group
            }

            return false
          }) as AdvancedSelectOptionGroup | undefined

          if (existingGroup) {
            existingGroup.options = [...existingGroup.options, option]
            return acc
          }
          return [
            ...acc,
            {
              label: option.group,
              options: [option],
            },
          ]
        }

        return [...acc, option]
      },
      [],
    )
  }, [
    flattenedOptions,
    hideSelectedMenuItems,
    isOptionSelected,
    isSelectedOnly,
    qtyMenuItemsVisible,
    searchString,
  ])

  const findOptionByValue = (v: string): AdvancedSelectOption | undefined => {
    return flattenedOptions.find((option) => option.value === v)
  }

  const lookupDisplay = (v: string): string => {
    const selectedOption = findOptionByValue(v)
    return selectedOption ? selectedOption.display : v
  }

  const lookupInformation = (v: string): React.ReactNode | undefined => {
    const selectedOption = findOptionByValue(v)
    return selectedOption?.information || undefined
  }

  const lookupBadgeText = (v: string): string => {
    const selectedOption = findOptionByValue(v)
    return selectedOption?.badgeText || ''
  }

  const lookupBadgeColor = (v: string): BadgeColor => {
    const selectedOption = findOptionByValue(v)
    return selectedOption?.badgeColor || 'neutral'
  }

  const lookupBadgeEmphasis = (v: string): BadgeEmphasis => {
    const selectedOption = findOptionByValue(v)
    return selectedOption?.badgeEmphasis || 'medium'
  }

  const displayValue = lookupDisplay(value as string)
  const displayInformation = lookupInformation(value as string)

  const isOverLimit = useMemo(() => {
    if (!value) return false
    return !!maxLength && value.length > maxLength
  }, [maxLength, value])

  const handlePopoverScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      if (pageSize) {
        const element = e.currentTarget
        const scrollCursor = element.scrollTop + element.clientHeight
        const scrollEnd = element.scrollHeight
        const threshold = 300
        if (scrollCursor >= scrollEnd - threshold) {
          setQtyMenuItemsVisible(qtyMenuItemsVisible ? qtyMenuItemsVisible + pageSize : null)
        }
      }
    },
    [qtyMenuItemsVisible, pageSize],
  )

  return (
    <Field
      data-cy={dataCy}
      errorFeedback={errorFeedback}
      helperText={helperText}
      icon={icon}
      id={id}
      isDisabled={isDisabled}
      isError={isError}
      label={label}
      subtext={subtext}
      maxLength={maxLength}
      value={value}
      isInternallyFocused={isInternallyFocused}
      isCounterHiddenWhenValid={isCounterHiddenWhenValid}
      {...(isLoading
        ? {
            endAdornment: (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            ),
          }
        : {
            actionButtonProps: {
              color: 'neutral',
              emphasis: 'low',
              icon: ArrowDropDown,
              onMouseDown: handleTogglePopover,
              isDisabled,
              'data-cy': dataCy && `${dataCy}:popover-toggle-button`,
            },
          })}
      /* Displays a clear selection action if a value is selected */
      {...(showClearButton && { onClearRequest: handleSelectClear })}
    >
      <StyledAdvancedSelect
        aria-label={ariaLabel}
        autoFocus={autoFocus}
        disabled={isDisabled}
        hasClearButton={showClearButton}
        hasDisplayInfo={!!displayInformation}
        hasIcon={!!icon}
        hasValue={hasValue}
        id={id}
        onMouseDown={handleTogglePopover}
        ref={triggerRef}
        tabIndex={0}
        data-cy={dataCy && `${dataCy}:select`}
      >
        <If condition={hasValue}>
          <Then>
            <If condition={multiple}>
              <Then>
                <If condition={maxQuantityToDisplay === 0}>
                  <Then>
                    {Array.isArray(value) && (
                      <>
                        <span>{`${value.length} selected`}</span>
                        <SelectedOnlyTrigger onMouseDown={handleQuantitySelectedClick}>
                          View
                        </SelectedOnlyTrigger>
                      </>
                    )}
                  </Then>
                  <Else>
                    <Chips>
                      {Array.isArray(value) &&
                        (value as string[]).slice(0, maxQuantityToDisplay).map((v) => (
                          <Chip
                            key={v}
                            label={lookupDisplay(v)}
                            text={lookupBadgeText(v)}
                            isDisabled={isDisabled}
                            {...(!isDisabled && {
                              onDismissClick: (e) => handleChipDismiss(e, v),
                            })}
                          />
                        ))}
                      {!!maxQuantityToDisplay && value.length > maxQuantityToDisplay && (
                        <div>&nbsp;{`+${value.length - maxQuantityToDisplay} more`}</div>
                      )}
                    </Chips>
                  </Else>
                </If>
              </Then>
              <Else>
                <DisplayValueContainer reverse={badgePosition === 'start'}>
                  {displayValue && <DisplayValue>{displayValue}</DisplayValue>}
                  <When condition={lookupBadgeText(value as string)}>
                    <BadgeContainer isDisabled={isDisabled}>
                      <Badge
                        data-cy={dataCy}
                        text={lookupBadgeText(value as string)}
                        color={lookupBadgeColor(value as string)}
                        emphasis={lookupBadgeEmphasis(value as string)}
                        size="small"
                      />
                    </BadgeContainer>
                  </When>
                </DisplayValueContainer>
                {!!displayInformation && (
                  <Information
                    buttonSize="xsmall"
                    data-cy={dataCy && `${dataCy}:display-info`}
                    icon={Info}
                    size={InformationSize.small}
                  >
                    {displayInformation}
                  </Information>
                )}
              </Else>
            </If>
          </Then>
          <Else>
            {/* Display optional placeholder if no value is selected */}
            {placeholder && `—${placeholder}—`}
          </Else>
        </If>
      </StyledAdvancedSelect>
      <Popover
        data-cy={dataCy && `${dataCy}:list`}
        ref={popoverRef}
        isPresent={isPopoverPresent}
        isInitiallyFocused={showSearch}
        triggerBoundingBox={triggerBoundingBox}
        maxHeight={popoverMaxHeightPx}
        maxWidth={triggerBoundingBox ? triggerBoundingBox.width : undefined}
        onScroll={handlePopoverScroll}
        {...((showSearch || !!maxLength) &&
          !isSelectedOnly && {
            header: (
              <>
                {showSearch && (
                  <Header>
                    <Input
                      id="search"
                      ref={searchInputRef}
                      icon={Search}
                      value={searchString}
                      onChange={handleSearchChange}
                      onKeyDown={handleSearchKeyDown}
                      /* If actively searching a search clear button is displayed */
                      {...(searchString && {
                        actionButtonProps: {
                          emphasis: 'low',
                          icon: Close,
                          color: 'neutral',
                          onClick: handleSearchClear,
                          'data-cy': dataCy && `${dataCy}:search-clear-button`,
                        },
                      })}
                    />
                  </Header>
                )}
                {!!maxLength && (
                  <LimitText isError={isOverLimit} isCondensed={showSearch}>
                    {getLimitText(maxLength, value)}
                  </LimitText>
                )}
              </>
            ),
          })}
        {...(showPopoverFooter &&
          !isDisabled && {
            footer: (
              <MenuItems data-cy={dataCy && `${dataCy}:footer-menu-items`}>
                {showSelectOnlyTrigger && (
                  <MenuButtonItem onClick={handleShowSelectedOnlyToggleClick}>
                    {isSelectedOnly ? 'View All' : 'View Selected Only'}
                  </MenuButtonItem>
                )}
                {!!footerActions &&
                  footerActions.map((fa, i) => (
                    <MenuButtonItem
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      {...fa}
                      onClick={(e) => {
                        if (fa.onClick) fa.onClick(e)
                        if (!fa.disableAutoDismiss) toggle()
                      }}
                    />
                  ))}
              </MenuItems>
            ),
          })}
      >
        <MenuItems data-cy={dataCy && `${dataCy}:menu-items`}>
          <If condition={isLoading}>
            <Then>
              <MenuSkeleton rows={5} showCheckbox={multiple} />
            </Then>
            <Else>
              {filteredOptions.map((optionOrGroup) => {
                const optionProps = {
                  isCheckbox: multiple,
                  onSelectOption: handleSelectOption,
                  onMultiSelectOption: handleMultiSelectOption,
                  badgePosition,
                  searchString,
                  dataCy,
                  popoverRef,
                  isPopoverPresent,
                }
                const group = asAdvancedSelectOptionGroup(optionOrGroup)
                if (group) {
                  return (
                    <OptionGroup>
                      <StyledSubheading>{group.label}</StyledSubheading>
                      {group.options.map((option) => (
                        <Option
                          key={option.value}
                          isIndented
                          option={option}
                          isSelected={isOptionSelected(option.value)}
                          isDisabled={isDisabled}
                          {...optionProps}
                        />
                      ))}
                    </OptionGroup>
                  )
                }
                const option = asAdvancedSelectOption(optionOrGroup)

                return option ? (
                  <Option
                    key={option.value}
                    option={option}
                    isSelected={isOptionSelected(option.value)}
                    isDisabled={isDisabled}
                    {...optionProps}
                  />
                ) : (
                  <></>
                )
              })}
              <When condition={filteredOptions.length === 0}>
                <EmptyContainer data-cy={dataCy && `${dataCy}:empty-message`}>
                  We can’t find a match for that...
                </EmptyContainer>
              </When>
            </Else>
          </If>
        </MenuItems>
      </Popover>
    </Field>
  )
}
