import type { ChangeEvent, FocusEvent, KeyboardEvent, MouseEventHandler } from 'react'
import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../../tokens/colors'
import { InputType } from './types'
import type { FieldProps } from '../field'
import { Field } from '../field'

interface InputProps extends FieldProps {
  'data-cy'?: string
  type?: InputType
  placeholder?: string
  ariaLabel?: string
  isMonospace?: boolean
  autoFocus?: boolean
  min?: number
  max?: number
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onClick?: MouseEventHandler<HTMLInputElement>
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  value: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      'data-cy': dataCy,
      type = InputType.text,
      id,
      label,
      value,
      prefix,
      suffix,
      ariaLabel,
      icon,
      isMonospace = false,
      autoFocus,
      min,
      max,
      isDisabled = false,
      isError = false,
      errorFeedback,
      placeholder,
      subtext,
      maxLength,
      helperText,
      isCounterHiddenWhenValid,
      actionButtonProps,
      onClearRequest,
      onChange = () => {},
      onClick,
      onBlur = () => {},
      onFocus = () => {},
      onKeyPress,
      onKeyUp,
      onKeyDown,
      startAdornment,
    },
    ref,
  ) => {
    return (
      <Field
        id={id}
        label={label}
        value={value}
        prefix={prefix}
        suffix={suffix}
        icon={icon}
        isDisabled={isDisabled}
        isError={isError}
        errorFeedback={errorFeedback}
        subtext={subtext}
        maxLength={maxLength}
        helperText={helperText}
        isCounterHiddenWhenValid={isCounterHiddenWhenValid}
        actionButtonProps={actionButtonProps}
        data-cy={dataCy && `${dataCy}:field`}
        onClearRequest={onClearRequest}
        startAdornment={startAdornment}
      >
        <StyledInput
          value={value}
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          aria-label={ariaLabel}
          type={type}
          id={id}
          disabled={isDisabled}
          placeholder={placeholder}
          isMonospace={isMonospace}
          autoFocus={autoFocus}
          min={min}
          max={max}
          ref={ref}
          data-cy={dataCy && `${dataCy}:input`}
        />
      </Field>
    )
  },
)

const StyledInput = styled.input<{
  isMonospace: boolean
  disabled: boolean
}>(({ isMonospace, disabled }) => ({
  flex: 1,
  border: 'none',
  borderRadius: 4,
  color: disabled ? COLOR.NEUTRAL[600] : COLOR.NEUTRAL[1000],
  padding: '8px 12px',
  width: '100%',
  boxSizing: 'border-box',
  fontFamily: isMonospace ? 'monospace, monospace' : '"Nunito Sans", sans-serif',
  fontSize: 16,
  lineHeight: '24px',
  outline: 'none',
  background: 'none',
  '&::placeholder': {
    color: COLOR.NEUTRAL[600],
  },

  /* Override browser default margins */
  margin: 0,
}))

export { Input, InputProps }
