import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/zen'

interface ReadOnlyItemProps {
  label?: string
  value?: string
}

const ReadOnlyItem: FC<ReadOnlyItemProps> = ({ label, value }) => (
  <ReadOnlyItemWrapper>
    <LabelWrapper>{label && <LabelText>{label}</LabelText>}</LabelWrapper>
    <ValueWrapper data-cy={label}>{value}</ValueWrapper>
  </ReadOnlyItemWrapper>
)

export { ReadOnlyItem }

const ReadOnlyItemWrapper = styled.div<{ width?: number }>(({ width }) => ({
  width: width ?? 'inherit',
}))

const LabelWrapper = styled.label({
  fontFamily: 'Nunito Sans, sans-serif',
})

const ValueWrapper = styled.div({
  marginTop: 4,
  lineHeight: '24px',
  display: 'block',
})

const LabelText = styled.div({
  color: COLOR.NEUTRAL[600],
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
  lineHeight: '24px',
})
