import type { Claim, InsuranceClaim } from '@helloextend/extend-api-client'
import type {
  ClaimsUpdateRequest,
  InsuranceClaimsUpdateRequest,
} from '@helloextend/extend-api-rtk-query'
import {
  useUpdateClaimMutation,
  useUpdateInsuranceClaimMutation,
} from '@helloextend/extend-api-rtk-query'
import type { MutationDefinition } from '@reduxjs/toolkit/dist/query'
import type { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../constants/ld-flags'

interface UseUpdateClaimContext {
  updateClaim: MutationTrigger<
    | MutationDefinition<ClaimsUpdateRequest, any, any, Claim, 'Claims'>
    | MutationDefinition<InsuranceClaimsUpdateRequest, any, any, InsuranceClaim, 'Claims'>
  >
  isError?: boolean
  isLoading?: boolean
  isSuccess?: boolean
}

const useUpdateClaim = (): UseUpdateClaimContext => {
  const [
    updateClaim,
    { isError: isErrorContracts, isLoading: isLoadingContracts, isSuccess: isSuccessContracts },
  ] = useUpdateClaimMutation()
  const [
    updateInsuranceClaim,
    {
      isError: isErrorClaimsManagement,
      isLoading: isLoadingClaimsManagement,
      isSuccess: isSuccessClaimsManagement,
    },
  ] = useUpdateInsuranceClaimMutation()
  const { [LDFlag.ClaimsManagementClaimsPut]: FF_CLAIMS_MANAGEMENT_CLAIMS_PUT } = useFlags()

  return {
    updateClaim: FF_CLAIMS_MANAGEMENT_CLAIMS_PUT ? updateInsuranceClaim : updateClaim,
    isError: FF_CLAIMS_MANAGEMENT_CLAIMS_PUT ? isErrorClaimsManagement : isErrorContracts,
    isLoading: FF_CLAIMS_MANAGEMENT_CLAIMS_PUT ? isLoadingClaimsManagement : isLoadingContracts,
    isSuccess: FF_CLAIMS_MANAGEMENT_CLAIMS_PUT ? isSuccessClaimsManagement : isSuccessContracts,
  }
}

export { useUpdateClaim }
