import React, { forwardRef } from 'react'
import { createPortal } from 'react-dom'
import styled from '@emotion/styled'
import { COLOR } from '../../tokens/colors'
import type { PopoverPosition } from '../../utils/caclulate-popover-position'
import { InformationSize } from './types'

const informationWidths: Record<InformationSize, number> = {
  [InformationSize.small]: 240,
  [InformationSize.regular]: 360,
  [InformationSize.large]: 480,
}

interface InformationBubbleProps {
  children: React.ReactNode
  isVisible: boolean
  position: PopoverPosition
  size: InformationSize
  transitionDuration: number
  'data-cy'?: string
}

const InformationBubble = forwardRef<HTMLDivElement, InformationBubbleProps>(
  ({ children, isVisible, position, size, transitionDuration, 'data-cy': dataCy }, ref) => {
    return createPortal(
      <StyledInformationBubble
        ref={ref}
        position={position}
        size={size}
        isVisible={isVisible}
        transitionDuration={transitionDuration}
        onClick={(e) => e.nativeEvent.stopImmediatePropagation()}
        data-cy={dataCy}
      >
        {children}
      </StyledInformationBubble>,
      document.body,
    )
  },
)

const StyledInformationBubble = styled.div(
  ({
    position,
    size,
    isVisible,
    transitionDuration,
  }: {
    position: PopoverPosition
    isVisible: boolean
    size: InformationSize
    transitionDuration: number
  }) => ({
    position: 'fixed',
    zIndex: 9999998, // This needs to be astronomically high to guarantee it sits above other established overlayed elements.
    top: position.top,
    bottom: position.bottom,
    left: position.left,
    right: position.right,
    maxWidth: informationWidths[size],
    backgroundColor: COLOR.NEUTRAL[800],
    color: COLOR.WHITE,
    fontSize: 13,
    fontWeight: 'bold',
    padding: 12,
    lineHeight: '18px',
    borderRadius: 4,
    overflow: 'hidden',
    boxSizing: 'border-box',
    transitionProperty: 'opacity, transform',
    transitionDuration: `${transitionDuration}ms`,
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0px)' : 'translateY(-4px)',
  }),
)

export { InformationBubble }
