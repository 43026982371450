import type { ServicerLocation } from '@helloextend/extend-api-client'
import type { ErrorReducerState } from '../types/error'
import type { ReducerState } from './reducers'

type Selector<T> = (state: ReducerState, ...rest: any[]) => T

export const getAll: Selector<ServicerLocation[]> = (state) => Object.values(state.byId)
export const getById: Selector<ServicerLocation | null> = (state, id: string) => state.byId[id]
export const getIsLoading: Selector<boolean> = (state) => state.isLoading
export const getError: Selector<ErrorReducerState> = (state) => state.error
export const getIsSuccess: Selector<boolean> = (state) => state.isSuccess
