import React from 'react'
import { useFormikContext } from 'formik'
import type { FC } from 'react'
import { Subheading } from '@helloextend/zen'
import styled from '@emotion/styled'
import { useListAllShippingProtectionPlanIdsQuery } from '@helloextend/extend-api-rtk-query'
import type { Values } from '../shipping-protection-purchase-model-form/schema'
import { purchaseModelLabels } from '../shipping-protection-purchase-model-form/schema'
import { PriceBandModel } from '../price-band-model/price-band-model'
import { SaveChangesButtonGroup } from '../save-changes-button-group/save-changes-button-group'
import { ShippingProtectionFallbackForm } from '../shipping-protection-fallback-form/shipping-protection-fallback-form'
import { Divider } from '../../../../../../../../components/divider'
import { PartialReimbursementMerchantCheckbox } from '../partial-reimbursement-merchant-checkbox/partial-reimbursement-merchant-checkbox'

type ShippingProtectionPurchaseModelVariableFormProps = {
  isLoading?: boolean
}

const ShippingProtectionPurchaseModelVariableForm: FC<
  ShippingProtectionPurchaseModelVariableFormProps
> = ({ isLoading }) => {
  const { values, isValid, dirty, handleSubmit, handleReset } = useFormikContext<Values>()
  const { purchaseModel } = values
  const { data: spPlansList, isLoading: isSpPlansListLoading } =
    useListAllShippingProtectionPlanIdsQuery()

  return (
    <div data-cy="purchase-model-variable-form">
      <SubheadingWithMargin>
        {purchaseModelLabels[purchaseModel]} Purchase Model
      </SubheadingWithMargin>
      <PartialReimbursementMerchantCheckbox />

      {purchaseModel === 'CATEGORY' && <div data-cy="category-model-form">category</div>}
      {purchaseModel === 'PRICE_BAND' && (
        <PriceBandModel spPlansList={spPlansList || []} isSpListLoading={isSpPlansListLoading} />
      )}

      {purchaseModel !== 'SINGLE_PLAN' && (
        <>
          <Divider margin="32px 0 24px" />
          <SubheadingWithMargin>Fall-back plan</SubheadingWithMargin>
        </>
      )}

      <ShippingProtectionFallbackForm
        spPlansList={spPlansList || []}
        isSpListLoading={isSpPlansListLoading}
      />
      {dirty && (
        <ContainerWithMargin>
          <SaveChangesButtonGroup
            id="price-band"
            handleSave={handleSubmit}
            handleCancel={handleReset}
            isSaveDisabled={!isValid}
            isLoading={isLoading}
          />
        </ContainerWithMargin>
      )}
    </div>
  )
}

const SubheadingWithMargin = styled(Subheading)({
  margin: '32px 0',
})

const ContainerWithMargin = styled.div({
  marginTop: '16px',
})

export { ShippingProtectionPurchaseModelVariableForm }
