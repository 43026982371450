import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

import styled from '@emotion/styled'
import { COLOR } from '../../tokens/colors'

export const StyledCalendar = styled.div<{
  hasPredefinedOptions?: boolean
}>(({ hasPredefinedOptions = false }) => ({
  '& .rdrDateDisplayWrapper': {
    background: COLOR.NEUTRAL.OPACITY[12],
    borderBottom: `1px solid ${COLOR.NEUTRAL[300]}`,
    padding: 4,
    borderRadius: 'unset',
  },
  '& .rdrDateDisplayItem': {
    position: 'relative',
    boxShadow: 'none',
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    textAlign: 'left',
    '&:not(.rdrDateDisplayItemActive):hover': {
      backgroundColor: COLOR.NEUTRAL.OPACITY[12],
    },
    '& input': {
      height: 'unset',
      lineHeight: '1em',
      fontSize: 15,
      padding: '22px 8px 6px',
      color: COLOR.NEUTRAL[800],
    },
    '&::before': {
      position: 'absolute',
      left: 8,
      top: 6,
      fontSize: 13,
      color: COLOR.NEUTRAL[600],
      pointerEvents: 'none',
    },
    '&:first-of-type::before': {
      content: '"From"',
    },
    '&:last-of-type::before': {
      content: '"To"',
    },
  },
  '& .rdrDateDisplayItemActive': {
    backgroundColor: COLOR.WHITE,
    boxShadow: `0 0 0 1px ${COLOR.NEUTRAL.OPACITY[25]}, 0 6px 24px 0 ${COLOR.NEUTRAL.OPACITY[25]}`,
  },
  '& .rdrNextPrevButton': {
    width: 35,
    height: 35,
    background: 'transparent',
    '&:hover': {
      background: COLOR.NEUTRAL.OPACITY[12],
    },
  },
  '& .rdrNextButton i': {
    marginLeft: 12,
  },
  '& .rdrMonthAndYearPickers select:hover': {
    backgroundColor: COLOR.NEUTRAL.OPACITY[12],
  },
  '& .rdrDefinedRangesWrapper': {
    display: hasPredefinedOptions ? 'unset' : 'none',
    width: 200,
    borderColor: COLOR.NEUTRAL[300],
    fontSize: 14,
  },
  '& .rdrStaticRanges': {
    padding: '6px 8px',
  },
  '& .rdrStaticRange': {
    border: 'none',
    padding: '2px 0',
    '&:hover .rdrStaticRangeLabel': {
      background: COLOR.NEUTRAL.OPACITY[12],
    },
  },
  '& .rdrStaticRangeLabel': {
    lineHeight: '20px',
    padding: 12,
    borderRadius: 4,
  },
  '& .rdrStaticRangeSelected': {
    fontWeight: 'unset',
    WebkitTextStroke: '0.04em',
    '& .rdrStaticRangeLabel': {
      color: COLOR.NEUTRAL[1000],
      background: COLOR.NEUTRAL.OPACITY[12],
    },
    '&:hover .rdrStaticRangeLabel': {
      background: COLOR.NEUTRAL.OPACITY[25],
    },
  },
}))
