import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { COLOR } from '../../tokens/colors'
import { Icon, IconSize } from '../icon'
import { ChevronRight } from '../../tokens/icons'

interface Crumb {
  text: string
  to?: string
}

export interface BreadcrumbsProps {
  crumbs: Crumb[]
  'data-cy'?: string
  'max-width'?: string
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({
  crumbs,
  'data-cy': dataCy,
  'max-width': maxWidth,
}) => {
  return (
    <StyledBreadcrumbs role="navigation" data-cy={dataCy}>
      {crumbs.map((crumb, index) => (
        <StyledCrumb maxWidth={maxWidth} data-cy={dataCy && `${dataCy}:crumb`} key={crumb.text}>
          {crumb.to ? <Link to={crumb.to}>{crumb.text}</Link> : <span>{crumb.text}</span>}
          {index < crumbs.length - 1 && (
            <Icon icon={ChevronRight} size={IconSize.xsmall} color={COLOR.NEUTRAL[600]} />
          )}
        </StyledCrumb>
      ))}
    </StyledBreadcrumbs>
  )
}

const StyledBreadcrumbs = styled.ul({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 2,
  margin: 0,
  padding: 0,
  fontSize: 13,
  lineHeight: '20px',
  color: COLOR.NEUTRAL[1000],
  '& a': {
    color: COLOR.NEUTRAL[600],
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})

const StyledCrumb = styled.li<{ maxWidth?: string }>(
  {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    maxWidth: '100%',
    '& a, span': {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  ({ maxWidth }) => ({
    '& a, span': {
      maxWidth: maxWidth || '38ch',
    },
  }),
)

export { Breadcrumbs, Crumb }
