import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@helloextend/client-branding'
import { AlertText, AlertContainer } from './styles'

interface ErrorMessageProps {
  message: string
  hasIcon?: boolean
}

const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => (
  <AlertContainer backgroundColor={COLOR.RED_SOLID_100}>
    <AlertText title={message} color={COLOR.RED_SOLID_800}>
      {message}
    </AlertText>
  </AlertContainer>
)

export { ErrorMessage }
