import type { Action } from '../actions'
import { createErrorReducer } from '../../factories'

const error = createErrorReducer<Action>(
  [
    'CONTRACTS_FETCH_FAILURE',
    'CONTRACTS_FETCH_FAILURE_V2',
    'CONTRACTS_UPDATE_FAILURE',
    'CONTRACTS_RESEND_FAILURE',
    'CONTRACTS_SEARCH_FAILURE',
    'CONTRACTS_CREATE_FAILURE',
    'CONTRACTS_REFUND_FAILURE',
  ],
  [
    'CONTRACTS_REFUND_REQUEST',
    'CONTRACTS_FETCH_REQUEST',
    'CONTRACTS_FETCH_REQUEST_V2',
    'CONTRACTS_UPDATE_REQUEST',
    'CONTRACTS_RESEND_REQUEST',
    'CONTRACTS_SEARCH_REQUEST',
    'CONTRACTS_CREATE_REQUEST',
    'CONTRACTS_ERROR_RESET',
  ],
)

export default error
