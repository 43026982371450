import { faker } from '@faker-js/faker/locale/en'
import type { PlanDetails } from '@helloextend/extend-api-client'

export function generatePlanDetails(overrides: Partial<PlanDetails> = {}): PlanDetails {
  return {
    planId: faker.datatype.uuid(),
    locale: 'en_US',
    planDetails: {
      header: 'PLAN_DETAILS_HEADER_1',
      headerPlanName: 'PLAN_DETAILS_HEADER_2',
      headerTagline: 'PLAN_DETAILS_HEADER_3',
      headerBannerImage: faker.image.imageUrl(),
      calculatorWithA: faker.random.word(),
      calculatorTermLength: faker.random.word(),
      calculatorProtectionUntil: faker.random.word(),
      calculatorManufacturersWarrantyLabel: faker.random.word(),
      calculatorAccidentalProtectionLabel: faker.random.word(),
      calculatorAccidentMalfunctionProtectionLabel: faker.random.word(),
      calculatorExtendProtectionLabel: faker.random.word(),
      calculatorNote: faker.lorem.words(),
      calculatorFootnote: faker.lorem.words(),
      rundownHeader: faker.random.word(),
      rundownInfoTitle1: faker.random.word(),
      rundownInfoBody1: faker.lorem.words(),
      rundownInfoIcon1: faker.image.imageUrl(),
      rundownInfoTitle2: faker.random.word(),
      rundownInfoBody2: faker.lorem.words(),
      rundownInfoIcon2: faker.image.imageUrl(),
      rundownInfoTitle3: faker.random.word(),
      rundownInfoBody3: faker.lorem.words(),
      rundownInfoIcon3: faker.image.imageUrl(),
      rundownInfoTitle4: faker.random.word(),
      rundownInfoBody4: faker.lorem.words(),
      rundownInfoIcon4: faker.image.imageUrl(),
      rundownInfoTitle5: faker.random.word(),
      rundownInfoBody5: faker.lorem.words(),
      rundownInfoIcon5: faker.image.imageUrl(),
      rundownInfoTitle6: faker.random.word(),
      rundownInfoBody6: faker.lorem.words(),
      rundownInfoIcon6: faker.image.imageUrl(),
      rundownFootnote: faker.random.word(),
      finePrintHeader: faker.random.word(),
      finePrintReviewTerms: faker.lorem.words(),
      finePrintTermsAndConditions: faker.random.word(),
      claimsProcessHeader: faker.random.word(),
      claimsProcessImage: faker.image.imageUrl(),
      claimsProcessTitle1: 'Step 1',
      claimsProcessIcon1: faker.image.imageUrl(),
      claimsProcessBody1: faker.lorem.words(),
      claimsProcessTitle2: 'Step 2',
      claimsProcessIcon2: faker.image.imageUrl(),
      claimsProcessBody2: faker.lorem.words(),
      claimsProcessTitle3: 'Step 3',
      claimsProcessBody3: faker.lorem.words(),
      claimsProcessIcon3: faker.image.imageUrl(),
      faqHeader: faker.random.word(),
      faqTitle1: faker.random.word(),
      faqBody1: faker.lorem.words(),
      faqTitle2: faker.random.word(),
      faqBody2: faker.lorem.words(),
      faqTitle3: faker.random.word(),
      faqBody3: faker.lorem.word(),
      ...overrides.planDetails,
    },
    planTerms: {
      planFullName: faker.random.word(),
      planDetailsTitle: faker.random.word(),
      planDetailsAdhCoverageLabel: faker.random.word(),
      planDetailsServiceTypeLabel: faker.random.word(),
      planDetailsDeductibleLabel: faker.random.word(),
      planSummaryTitle: faker.random.word(),
      planSummaryWhatTitle: faker.random.word(),
      planSummaryWhatBody: faker.lorem.words(),
      planSummaryWhenTitle: faker.random.word(),
      planSummaryWhenBody: faker.lorem.words(),
      planSummaryHowTitle: faker.random.word(),
      planSummaryHowBody: faker.lorem.words(),
      claimStartTitle: faker.random.word(),
      claimStartLinkText: faker.lorem.words(),
      termDuration: faker.random.word(),
      adhCoverageStatus: faker.random.word(),
      serviceTypeStatus: faker.random.word(),
      finePrintTermsAndConditions: faker.random.word(),
      loadingTermsMessage: faker.random.word(),
      monetarySymbol: faker.random.word(),
    },
    planTermsLabels: {
      planDetailsHeader: faker.random.word(),
      productNameLabel: faker.random.word(),
      contractIdLabel: faker.random.word(),
      termLabel: faker.random.word(),
      coverageTermLabel: faker.random.word(),
      adhCoverageLabel: faker.random.word(),
      serviceTypeLabel: faker.random.word(),
      deductibleLabel: faker.random.word(),
      orderDetailsHeader: faker.random.word(),
      customerNameLabel: faker.random.word(),
      customerEmailLabel: faker.random.word(),
      storeNameLabel: faker.random.word(),
      storeOrderIdLabel: faker.random.word(),
      datePurchasedLabel: faker.random.word(),
      productPriceLabel: faker.random.word(),
      protectionPlanPriceLabel: faker.random.word(),
      planSummaryHeader: faker.random.word(),
      fullStoryHeader: faker.random.word(),
      notApplicable: faker.random.word(),
    },
    common: {
      footerCopyrightNote: faker.random.word(),
      footerTermsLinkText: faker.random.word(),
      footerPrivacyPolicyLinkText: faker.random.word(),
    },
    contractPurchaseEmail: {
      benefitTitle1: 'Test title',
      benefitBody1: 'Test body',
      benefitIcon1: 'https://placeimg.com/640/480',
      benefitTitle2: 'Test title',
      benefitBody2: 'Test body',
      benefitIcon2: 'https://placeimg.com/640/480',
      benefitTitle3: 'Test title',
      benefitBody3: 'Test body',
      benefitIcon3: 'https://placeimg.com/640/480',
      benefitTitle4: 'Test title',
      benefitBody4: 'Test body',
      benefitIcon4: 'https://placeimg.com/640/480',
      benefitTitle5: 'Test title',
      benefitBody5: 'Test body',
      benefitIcon5: 'https://placeimg.com/640/480',
      benefitTitle6: 'Test title',
      benefitBody6: 'Test body',
      benefitIcon6: 'https://placeimg.com/640/480',
    },
    createdAt: Date.now(),
    updatedAt: Date.now(),
    ...overrides,
  }
}

export function generateWarrantyPlanDetails(
  overrides: Partial<PlanDetails['planDetails']> = {},
): PlanDetails['planDetails'] {
  return {
    header: 'PLAN_DETAILS_HEADER_1',
    headerPlanName: 'PLAN_DETAILS_HEADER_2',
    headerTagline: 'PLAN_DETAILS_HEADER_3',
    headerBannerImage: faker.image.imageUrl(),
    calculatorWithA: faker.random.word(),
    calculatorTermLength: faker.random.word(),
    calculatorProtectionUntil: faker.random.word(),
    calculatorManufacturersWarrantyLabel: faker.random.word(),
    calculatorAccidentalProtectionLabel: faker.random.word(),
    calculatorAccidentMalfunctionProtectionLabel: faker.random.word(),
    calculatorExtendProtectionLabel: faker.random.word(),
    calculatorNote: faker.lorem.words(),
    calculatorFootnote: faker.lorem.words(),
    rundownHeader: faker.random.word(),
    rundownInfoTitle1: faker.random.word(),
    rundownInfoBody1: faker.lorem.words(),
    rundownInfoIcon1: faker.image.imageUrl(),
    rundownInfoTitle2: faker.random.word(),
    rundownInfoBody2: faker.lorem.words(),
    rundownInfoIcon2: faker.image.imageUrl(),
    rundownInfoTitle3: faker.random.word(),
    rundownInfoBody3: faker.lorem.words(),
    rundownInfoIcon3: faker.image.imageUrl(),
    rundownInfoTitle4: faker.random.word(),
    rundownInfoBody4: faker.lorem.words(),
    rundownInfoIcon4: faker.image.imageUrl(),
    rundownInfoTitle5: faker.random.word(),
    rundownInfoBody5: faker.lorem.words(),
    rundownInfoIcon5: faker.image.imageUrl(),
    rundownInfoTitle6: faker.random.word(),
    rundownInfoBody6: faker.lorem.words(),
    rundownInfoIcon6: faker.image.imageUrl(),
    rundownFootnote: faker.random.word(),
    finePrintHeader: faker.random.word(),
    finePrintReviewTerms: faker.lorem.words(),
    finePrintTermsAndConditions: faker.random.word(),
    claimsProcessHeader: faker.random.word(),
    claimsProcessImage: faker.image.imageUrl(),
    claimsProcessTitle1: 'Step 1',
    claimsProcessIcon1: faker.image.imageUrl(),
    claimsProcessBody1: faker.lorem.words(),
    claimsProcessTitle2: 'Step 2',
    claimsProcessIcon2: faker.image.imageUrl(),
    claimsProcessBody2: faker.lorem.words(),
    claimsProcessTitle3: 'Step 3',
    claimsProcessBody3: faker.lorem.words(),
    claimsProcessIcon3: faker.image.imageUrl(),
    faqHeader: faker.random.word(),
    faqTitle1: faker.random.word(),
    faqBody1: faker.lorem.words(),
    faqTitle2: faker.random.word(),
    faqBody2: faker.lorem.words(),
    faqTitle3: faker.random.word(),
    faqBody3: faker.lorem.word(),
    ...overrides,
  }
}
