export enum CategoryMappingStatus {
  UNMAPPED = 'Unmapped',
  WARRANTABLE = 'Warrantable',
  NON_WARRANTABLE = 'Non-Warrantable',
}

export interface DraftCategoryMapping {
  id?: string
  storeId: string
  planSetId?: string
  mappingStatus: CategoryMappingStatus
  extendCategory?: string
  merchantCategory: string
}

export interface CategoryMapping extends DraftCategoryMapping {
  id: string
  extendCategory: string
  planSetId: string
  createdAt: number
  updatedAt: number
  deletedAt?: number
  version: string
}

export interface CategoryMappingCreateBody extends DraftCategoryMapping {}

export interface CategoryMappingBatchCreateBody extends Array<DraftCategoryMapping> {}

export interface CategoryMappingListResponse {
  items: CategoryMapping[]
  limit: number
  nextPageCursor?: string
}

export interface CategoryMappingListRequest {
  storeId: string
  filter?: string
}
