import React from 'react'
import type { Column, Cell } from 'react-table'
import styled from '@emotion/styled'
import type { BadgeDescriptionItem } from '@helloextend/merchants-ui'
import { CellText, BadgeDescriptions } from '@helloextend/merchants-ui'
import { Badge, Button } from '@helloextend/zen'
import { Edit, Visibility } from '@helloextend/zen/src/tokens/icons'
import { date as datefmt } from '@helloextend/client-utils'
import type { ConversationResponse } from '@helloextend/extend-api-rtk-query'
import { formatDateWithTimezone } from '../../../../utils/date-formatting'
import { usePermissions } from '../../../../hooks/use-permissions'
import { Permission } from '../../../../lib/permissions'

interface OptColumnProps {
  id?: string
  Header?: string
  accessor?: string
  width?: number
  disableSortBy?: boolean
  HeaderAdornment?: () => JSX.Element
  Cell?: (cell: Cell) => JSX.Element
}
type ConversationColumnsType = Column & OptColumnProps

// Temporary till RTK is set up, needed to quiet typecheck
export interface ConversationListItem {
  id?: string
  createdAt?: string
  updatedAt?: string
  editedBy?: string
  version?: number
  isPublished?: boolean
  title?: string
  description?: string
  threads?: string[]
}

enum ConversationStatus {
  Draft = 'draft',
  Published = 'published',
}

const { formatToMilliseconds } = datefmt

export const getBadgeText = (status: ConversationStatus): string => {
  switch (status) {
    case ConversationStatus.Draft:
      return 'Draft'
    case ConversationStatus.Published:
      return 'Published'
    default:
      return 'Unknown'
  }
}

const conversationStatusDetails: Record<ConversationStatus, BadgeDescriptionItem> = {
  draft: {
    badgeText: 'Draft',
    description: 'Draft conversation is open to changes.',
    badgeColor: 'yellow',
  },
  published: {
    badgeText: 'Published',
    description: 'Published conversation is available for assigning to conversations.',
    badgeColor: 'green',
  },
}

export const conversationColumns = (
  handleRoute: (route: string) => void,
): ConversationColumnsType[] => {
  return [
    {
      Header: 'Name',
      accessor: 'title',
      Cell: ({ value }: Cell): JSX.Element => <CellText>{value}</CellText>,
    },
    {
      Header: 'Description',
      accessor: 'description',
      width: 100,
      Cell: ({ value }: Cell): JSX.Element => <CellText>{value}</CellText>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 100,
      HeaderAdornment: (): JSX.Element => (
        <BadgeInfoWrapper>
          <BadgeDescriptions descriptions={Object.values(conversationStatusDetails)} />
        </BadgeInfoWrapper>
      ),
      Cell: ({ value }: Cell): JSX.Element => {
        const badgeColor = value === ConversationStatus.Published ? 'green' : 'yellow'
        return <Badge color={badgeColor} emphasis="medium" text={getBadgeText(value)} />
      },
    },
    {
      Header: 'Last Update',
      accessor: 'updatedAt',
      Cell: ({ row }: Cell): JSX.Element => {
        const { updatedAt, editedBy } = row.original as ConversationListItem
        return (
          <CellText>
            {updatedAt &&
              `${formatDateWithTimezone(formatToMilliseconds(updatedAt))} by ${editedBy}`}
          </CellText>
        )
      },
    },
    {
      id: 'buttons',
      width: 70,
      Cell: ({ row }: Cell): JSX.Element => {
        const { id, status } = row.original as ConversationResponse
        const { hasPermission } = usePermissions()
        const hasEditPermission = hasPermission(Permission.ManageAdjudication)

        const handlePreviewClick = (): void => {
          handleRoute(`/admin/adjudication-management/conversations/${id}/preview`)
        }

        const handleEditClick = (): void => {
          handleRoute(`/admin/adjudication-management/conversations/${id}/edit`)
        }

        return (
          <ButtonsWrapper>
            <Button
              tooltip="Preview"
              size="small"
              color="neutral"
              emphasis="low"
              icon={Visibility}
              onClick={handlePreviewClick}
            />
            {hasEditPermission && status === ConversationStatus.Draft && (
              <Button
                data-cy="adjudication-management-conversation-list-edit-item"
                tooltip="Edit"
                size="small"
                color="neutral"
                emphasis="low"
                icon={Edit}
                onClick={handleEditClick}
              />
            )}
          </ButtonsWrapper>
        )
      },
    },
  ]
}

const BadgeInfoWrapper = styled.div({
  height: 41,
})

const ButtonsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: 8,
  paddingRight: 16,
})
