import React from 'react'
import type { Column, Cell } from 'react-table'
import { CellText } from '@helloextend/merchants-ui'
import styled from '@emotion/styled'
import type { ConversationConfigurationResponse } from '@helloextend/extend-api-rtk-query'
import { date as datefmt } from '@helloextend/client-utils'
import { ConversationNameRowItem } from './conversation-name-row-item'
import { formatDateWithTimezone } from '../../../../utils/date-formatting'

export interface ConversationConfigurationResponseTableRow
  extends ConversationConfigurationResponse,
    Partial<Record<string, string>> {}

const { formatToMilliseconds } = datefmt

export const configurationColumns: Array<Column<ConversationConfigurationResponseTableRow>> = [
  {
    Header: 'Conversation',
    accessor: 'conversationId',
    Cell: ({ value }: Cell): JSX.Element => <ConversationNameRowItem id={value} />,
  },
  {
    Header: 'Adjudication Category',
    accessor: 'category',
    Cell: ({ value }: Cell): JSX.Element => <CellText>{value}</CellText>,
  },
  {
    Header: 'Store ID',
    accessor: 'storeId',
    Cell: ({ value }: Cell): JSX.Element => <CellText>{value}</CellText>,
  },
  {
    Header: 'Plan ID',
    accessor: 'planId',
    Cell: ({ value }: Cell): JSX.Element => <CellText>{value}</CellText>,
  },
  {
    Header: 'Last Update',
    accessor: 'updatedAt',
    Cell: ({ value }: Cell): JSX.Element => (
      <PaddedContainer>
        <CellText>{value}</CellText>
      </PaddedContainer>
    ),
  },
]

export const mapConfigurationsTotableData = (
  configurations?: ConversationConfigurationResponse[],
): ConversationConfigurationResponseTableRow[] => {
  if (!configurations) return []
  return configurations.map((configuration) => {
    const { updatedAt, editedBy } = configuration

    return {
      ...configuration,
      storeId: configuration.storeId || '—',
      planId: configuration.planId || '—',
      updatedAt: `${formatDateWithTimezone(formatToMilliseconds(updatedAt))} by ${editedBy}`,
    }
  })
}

const PaddedContainer = styled.div({
  paddingRight: 16,
})
