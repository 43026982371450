import type { FC } from 'react'
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import type { Fee } from '@helloextend/extend-api-client'
import { useFormikContext } from 'formik'
import { InsuranceProgramAddFeeForm } from './insurance-program-add-fee-form'
import { InsuranceProgramFeesTable } from './insurance-program-fees-table'
import type { Values } from './schema'
import type { DropdownProps } from '../../../components/dropdown'
import { InsuranceProgramExtendFees } from './insurance-program-extend-fees-section'

type InsuranceProgramFeesSectionProps = {
  obligorIndex: number
  handleDropdownChange: DropdownProps['onChange']
  isDisabled?: boolean
}

const InsuranceProgramFeesSection: FC<InsuranceProgramFeesSectionProps> = ({
  obligorIndex,
  handleDropdownChange,
  isDisabled = false,
}) => {
  const { setFieldValue, values, setStatus } = useFormikContext<Values>()
  const currentFees = useMemo(
    () => values?.obligor?.[obligorIndex]?.fees ?? [],
    [obligorIndex, values?.obligor],
  )
  const [addedFees, setAddedFees] = useState<Fee[]>(currentFees)
  const [currentFeeIndex, setCurrentFeeIndex] = useState<number>(currentFees.length)
  const isExtendFeeVisible = useMemo(() => {
    const obligorName = values?.obligor?.[obligorIndex].name
    return obligorName === 'EWSC/EPC' || obligorName === 'Extend, Inc'
  }, [obligorIndex, values?.obligor])
  const isExtendFeeChecked = useMemo(
    () => Boolean(values?.obligor?.[obligorIndex]?.extendFee) ?? false,
    [obligorIndex, values?.obligor],
  )

  useEffect(() => {
    const feesAdded = addedFees.length > 0
    setStatus({
      feesAdded,
    })
  }, [addedFees.length, setStatus])
  /**
   * adds a fee to the list of added fees to use in the table
   * increases the current fee to keep track of all added fees
   */
  const addFee = useCallback(
    (fee: Fee): void => {
      if (fee) {
        setAddedFees([...addedFees, fee])
        setCurrentFeeIndex(currentFeeIndex + 1)
      }
    },
    [addedFees, currentFeeIndex],
  )

  /**
   * removes fee from the list of added fees to update the fees table
   * updates the formik values for the current obligor
   */
  const removeFee = useCallback(
    (feeIndex: number, shouldDecreaseIndex = true): void => {
      // ensure this passes when feeIndex is 0 (falsy value)
      if (typeof feeIndex === 'number') {
        const newFees = [...addedFees.filter((_, index) => index !== feeIndex)]
        setAddedFees(newFees)
        setFieldValue(`obligor[${obligorIndex}].fees`, newFees)
        if (shouldDecreaseIndex) {
          setCurrentFeeIndex(currentFeeIndex - 1)
        }
      }
    },
    [addedFees, currentFeeIndex, obligorIndex, setFieldValue],
  )
  return (
    <>
      <InsuranceProgramFeesTable
        fees={addedFees}
        handleRemoveFee={removeFee}
        obligorIndex={obligorIndex}
        isDisabled={isDisabled}
        data-cy="insurance-program-fees-table"
      />
      <InsuranceProgramAddFeeForm
        obligorIndex={obligorIndex}
        handleAddFee={addFee}
        handleRemoveFee={removeFee}
        currentFeeIndex={currentFeeIndex}
        handleDropdownChange={handleDropdownChange}
        isDisabled={isDisabled}
      />
      {isExtendFeeVisible && (
        <InsuranceProgramExtendFees
          handleDropdownChange={handleDropdownChange}
          obligorIndex={obligorIndex}
          isDisabled={isDisabled}
          isExtendFeeChecked={isExtendFeeVisible && isExtendFeeChecked}
        />
      )}
    </>
  )
}

export { InsuranceProgramFeesSection }
