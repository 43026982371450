import type { AdvancedSelectOption } from '@helloextend/zen'
import * as Yup from 'yup'

const schema = Yup.object()
  .shape({
    firstName: Yup.string().required('Please enter a first name'),
    lastName: Yup.string().required('Please enter a last name'),
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Please enter a valid email address'),
    role: Yup.string().required('Please select a user role'),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

const roleOptions: AdvancedSelectOption[] = [
  { display: 'Account Owner', value: 'user' },
  { display: 'Claims Agent', value: 'merchantagent' },
]

export { roleOptions, Values, schema }
