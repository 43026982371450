import {
  EXTEND_APP_NAME,
  EXTEND_ENV,
  DATADOG_APP_ID,
  DATADOG_CLIENT_TOKEN,
} from '@helloextend/client-constants'
import { isEnv } from '@helloextend/client-utils'
import { datadogRum } from '@datadog/browser-rum'

const DEFAULT_APP_ID = '4262dcb9-8947-4104-add7-e59921439f67'
const DEFAULT_CLIENT_TOKEN = 'pub67e2aa5f91b1f19184b9bbecc9fef3f3'
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const configureDataDogRum = (appSampleRate: number, appPremiumSampleRate: number) => {
  if (isEnv(['production', 'demo'])) {
    datadogRum.init({
      // if App ID and Client Token are not found for the service, use the catchall DD RUM Application/profile
      applicationId: DATADOG_APP_ID || DEFAULT_APP_ID,
      clientToken: DATADOG_CLIENT_TOKEN || DEFAULT_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: EXTEND_APP_NAME,
      env: EXTEND_ENV,
      version: '1.0.0',
      // rate for basic RUM session recording. defaults to 100
      sampleRate: appSampleRate,
      // rate for premium RUM session recording, includes replay of session. Defaults to 100
      premiumSampleRate: appPremiumSampleRate,
      trackInteractions: true,
    })
    datadogRum.startSessionReplayRecording()
  }
}
