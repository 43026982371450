import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import type {
  ServiceOrder,
  ServiceOrderAssignRequest,
  Servicer,
} from '@helloextend/extend-api-client'
import { Button, ButtonGroup } from '@helloextend/zen'
import { useServiceOrderAssignMutation } from '@helloextend/extend-api-rtk-query'
import { ServicerSelect } from '../../../../../../components/servicer-select'
import { servicers as servicersActions } from '../../../../../../actions'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import { formSchema } from './schema'
import type { Values } from './schema'

interface AssignServiceOrderFormProps {
  serviceOrder: ServiceOrder
}

const AssignServiceOrderForm: FC<AssignServiceOrderFormProps> = ({ serviceOrder }) => {
  const dispatch = useDispatch()
  const [assignServiceOrder, { isSuccess, isLoading }] = useServiceOrderAssignMutation()
  const { errors, handleSubmit, setFieldValue } = useFormik<Values>({
    enableReinitialize: true,
    initialValues: formSchema.default(),
    validationSchema: formSchema,
    onSubmit: (formValues: Values) => {
      assignServiceOrder({
        serviceOrderId: serviceOrder?.id ?? '',
        body: formValues as ServiceOrderAssignRequest,
      })
    },
  })

  useEffect(() => {
    if (isSuccess) {
      dispatch(setClaimDetailsToast('Service order assigned!'))
      dispatch(setClaimDetailsActiveView(''))
    }
  }, [isSuccess, dispatch])

  useEffect(() => {
    return () => {
      dispatch(servicersActions.searchDropdownSet(''))
    }
  }, [dispatch])

  return (
    <form onSubmit={handleSubmit}>
      <SelectWrapper>
        <ServicerSelect
          name="servicerId"
          error={Boolean(errors.servicerId)}
          serviceType={serviceOrder?.serviceType}
          errorMessage={errors.servicerId}
          onChange={(name, servicer: Servicer | null) => {
            setFieldValue(name, servicer?.id ?? '')
            setFieldValue('servicerLocationId', servicer?.locations?.[0].id ?? '', false)
          }}
          showAllOptions={false}
        />
      </SelectWrapper>
      <ButtonWrapper>
        <ButtonGroup>
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => dispatch(setClaimDetailsActiveView(''))}
            isDisabled={isLoading}
          />
          <Button
            type="submit"
            text="Assign"
            data-cy="assign-button"
            isDisabled={isLoading}
            isProcessing={isLoading}
          />
        </ButtonGroup>
      </ButtonWrapper>
    </form>
  )
}

const SelectWrapper = styled.div({
  width: 352,
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

export { AssignServiceOrderForm }
