import type { SyntheticEvent } from 'react'
import { useMemo } from 'react'
import { useDispatch, batch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import type { ScriptItem } from '@helloextend/extend-api-rtk-query'
import { useCreateThreadMutation } from '@helloextend/extend-api-rtk-query'
import { v4 as uuid } from 'uuid'
import {
  setIsEditorPanelVisible,
  addThreadToCurrentConversation,
  updateSingleUseThreads,
  addMessageBlockToThread,
  setThread,
  setSelectedMessageBlock,
  setIsPublishValidationModeActive,
  MessageBlockInsertionType,
} from '../../../../../store/slices/amp-slice'
import type { MessageBlockTypes } from '../../utils'
import {
  getSelectedConversation,
  getSelectedMessageBlockIndex,
  getSelectedThread,
  getSingleUseThreadsMap,
} from '../../../../../reducers/selectors'
import type { RootState } from '../../../../../reducers'
import { getEmptyBlockByType } from '../../message-blocks/utils'

export type UseAddMessageBlockResult = {
  handleAddItem: (e: SyntheticEvent) => Promise<void>
  addingMessageBlockError: string | null
}

export function useAddMessageBlock(toggle: () => void): UseAddMessageBlockResult {
  const dispatch = useDispatch()
  const location = useLocation()
  const isInThreadEditor = location.pathname.includes('/threads/')
  let addingMessageBlockError = null

  const [createThread] = useCreateThreadMutation()
  const conversation = useSelector((state: RootState) => getSelectedConversation(state))
  const selectedThread = useSelector((state: RootState) => getSelectedThread(state))
  const isSingleUseThread = selectedThread?.type === 'single_use'
  const selectedMessageBlockIndex = useSelector((state: RootState) =>
    getSelectedMessageBlockIndex(state),
  )
  const singleUseThreadsMap = useSelector((state: RootState) => getSingleUseThreadsMap(state))

  const threadAfterSelectedThread = useMemo(() => {
    if (conversation && selectedThread) {
      // find curr threads index in conversation
      const selectedThreadIndex = conversation.threads.findIndex(
        (threadId) => threadId.split(':').shift() === selectedThread.id,
      )
      // are there any threads in the conversation after selectedThread
      if (conversation.threads.length - 1 <= selectedThreadIndex) return null

      // return next thread
      const nextThread =
        singleUseThreadsMap[conversation.threads[selectedThreadIndex + 1].split(':').shift() ?? '']
      return nextThread
    }
    return null
  }, [conversation, selectedThread, singleUseThreadsMap])

  const handleAddItem = async (e: SyntheticEvent): Promise<void> => {
    const ele = e?.target as HTMLElement
    const blockType = ele.textContent as MessageBlockTypes
    const block = getEmptyBlockByType(blockType)
    addingMessageBlockError = null
    if (!block) return
    toggle()

    // for threadEditor: always update selectedThread
    if (isInThreadEditor) {
      addBlockToSelectedThread(block)
      return
    }

    // for conversation editor update the correct thread
    const isNextThreadSingleUse =
      threadAfterSelectedThread && threadAfterSelectedThread.type === 'single_use'

    // selected thread is reusable, and is followed by a reusable thread: create a new single_use
    if (!isSingleUseThread && !isNextThreadSingleUse) {
      addBlockToNewSingleUseThread(block)
      // selected thread is reusable, and is followed by a single_use: add block to single_use
    } else if (!isSingleUseThread && isNextThreadSingleUse) {
      addBlockToNextThread(block)
    } else {
      // current thread is single_use: add block to current thread
      addBlockToSelectedThread(block)
    }
    dispatch(setIsPublishValidationModeActive(false))
  }

  const addBlockToNewSingleUseThread = async (block: ScriptItem): Promise<void> => {
    if (!conversation) return
    try {
      const result = await createThread({
        type: 'single_use',
        title: uuid(),
        script: [],
        ownedBy: conversation.id,
      }).unwrap()
      batch(() => {
        dispatch(addThreadToCurrentConversation({ threadId: result.id, threadType: 'single_use' }))
        dispatch(updateSingleUseThreads(result))
        dispatch(setThread(result))
        dispatch(addMessageBlockToThread({ scriptItem: block }))
        dispatch(
          setSelectedMessageBlock(selectedMessageBlockIndex ? selectedMessageBlockIndex + 1 : 0),
        )
        dispatch(setIsEditorPanelVisible(true))
      })
    } catch (err: unknown) {
      addingMessageBlockError = 'An error occurred while adding a message block'
    }
  }

  const addBlockToSelectedThread = async (block: ScriptItem): Promise<void> => {
    if (selectedMessageBlockIndex === null) return
    batch(() => {
      dispatch(addMessageBlockToThread({ scriptItem: block }))
      dispatch(setSelectedMessageBlock(selectedMessageBlockIndex + 1))
      dispatch(setIsEditorPanelVisible(true))
    })
  }

  const addBlockToNextThread = async (block: ScriptItem): Promise<void> => {
    if (!threadAfterSelectedThread) return
    batch(() => {
      dispatch(setThread(threadAfterSelectedThread))
      dispatch(setSelectedMessageBlock(null))
      dispatch(
        addMessageBlockToThread({
          scriptItem: block,
          messageBlockInsertionType: MessageBlockInsertionType.Start,
        }),
      )
      dispatch(setSelectedMessageBlock(0))
    })
  }

  return { handleAddItem, addingMessageBlockError }
}
