import React, { useCallback, useEffect, useState } from 'react'
import { useReactTable } from '@tanstack/react-table'
import styled from '@emotion/styled'
import { DataTableContextProvider } from './data-table-context'
import type { DataTableProps } from './data-table-types'
import { Toolbar } from './components/toolbar'
import { AppliedFilters } from './components/applied-filters'
import { ColumnConfiguration } from './components/column-configuration'
import { Table } from './components/table'
import { Skeleton } from './components/skeleton'
import { buildColumnDefs, buildTableOptions } from './data-table-utils'
import { EmptyState } from '../empty-state'
import {
  AccentIconAppError,
  AccentIconSearch,
  AccentIconSearchList,
} from '../../tokens/accent-icons'
import { SearchInput } from './components/search-input'
import { Button } from '../button'

export const DataTable = <TData,>(props: DataTableProps<TData>): JSX.Element => {
  const {
    columns,
    'data-cy': dataCy,
    filterDefs,
    hasRequiredFilter,
    isError,
    isLoading,
    itemName,
    stateViews,
  } = props

  const table = useReactTable<TData>(
    buildTableOptions({ ...props, columns: buildColumnDefs(columns, filterDefs) }),
  )

  const { globalFilter, columnFilters } = table.getState()
  const hasRows = table.getRowModel().rows.length !== 0
  const [isFiltered, setIsFiltered] = useState(globalFilter || columnFilters.length !== 0)
  const isSearchable = table.getAllColumns().filter((c) => c.columnDef.meta?.search).length > 0

  const handleClearSearch = useCallback(() => {
    table.setGlobalFilter('')
    table.setColumnFilters([])
  }, [table])

  useEffect(() => {
    setIsFiltered(globalFilter || columnFilters.length !== 0)
  }, [columnFilters.length, globalFilter])

  const columnDefs = table.getAllColumns().map((c) => c.columnDef)
  const searchOptions = columnDefs.filter(
    (c) => c.meta?.search === 'both' || c.meta?.search === 'explicit',
  )
  const searchOptionsLabels = searchOptions
    .map((option) => {
      return option.header
    })
    .join(', ')
    .replace(/,(?!.*,)/gim, ' or')
    .toLocaleLowerCase()

  const getPreFilterHeading = (hasSearch: boolean): string => {
    if (hasSearch)
      return (
        stateViews?.preFilter?.heading ||
        (itemName ? `Search for a ${itemName}` : 'Search to see results')
      )
    return stateViews?.preFilter?.heading || 'Apply a filter to see results.'
  }

  const getPreFilterDescription = (hasSearch: boolean): string | undefined => {
    if (hasSearch)
      return stateViews?.preFilter?.description || `You may search by ${searchOptionsLabels}.`
    return stateViews?.preFilter?.description
  }

  const renderViews = (): React.ReactNode => {
    /* Always Return error state if isError is true */
    if (isError) {
      return (
        <EmptyState
          data-cy={dataCy && `${dataCy}:error-state`}
          icon={stateViews?.error?.icon || AccentIconAppError}
          heading={stateViews?.error?.heading || 'Something went wrong'}
          description={stateViews?.error?.description}
        />
      )
    }

    /* Always show required filter view if pre-filter is required and is not currently filtered */
    if (hasRequiredFilter && !isFiltered) {
      return (
        <EmptyState
          actionMaxWidth={480}
          data-cy={dataCy && `${dataCy}:search-required-state`}
          icon={stateViews?.preFilter?.icon || AccentIconSearch}
          heading={getPreFilterHeading(isSearchable)}
          description={getPreFilterDescription(isSearchable)}
          {...(isSearchable && { field: <SearchInput /> })}
        />
      )
    }

    /* If rows exist in the row model show the Table component */
    if (hasRows) {
      return <Table />
    }

    /* Show the skeleton when going from nothing (!hasRows) to something (hasRows) */
    if (isLoading) {
      return (
        <Skeleton message={stateViews?.skeleton?.message} delayMs={stateViews?.skeleton?.delayMs} />
      )
    }

    /* Show the filter error state if a filter is applied and there are no rows */
    if (isFiltered) {
      return (
        <EmptyState
          data-cy={dataCy && `${dataCy}:filter-error-state`}
          icon={stateViews?.postFilter?.icon || AccentIconSearchList}
          heading={stateViews?.postFilter?.heading || "We can't find a match for that"}
          description={
            stateViews?.postFilter?.description ||
            'Double-check your search term or apply a different filter.'
          }
          button={
            <Button
              text="Clear Search and Filters"
              onClick={handleClearSearch}
              emphasis="low"
              hasReducedPadding
            />
          }
        />
      )
    }

    /* Show the no data state if no filter is applied and there are no rows */
    return (
      <EmptyState
        data-cy={dataCy && `${dataCy}:empty-state`}
        icon={stateViews?.noData?.icon || AccentIconSearchList}
        heading={stateViews?.noData?.heading || 'No data yet'}
        description={stateViews?.noData?.description}
      />
    )
  }

  return (
    <DataTableContextProvider table={table} {...props}>
      <StyledDataTable data-cy={dataCy}>
        <Toolbar />
        {globalFilter || (columnFilters.length !== 0 && <AppliedFilters />)}
        {renderViews()}
      </StyledDataTable>
      <ColumnConfiguration />
    </DataTableContextProvider>
  )
}

const StyledDataTable = styled.div({
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  maxHeight: '100%',
})
