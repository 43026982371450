import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/zen'

interface DividerProps {
  className?: string
  width?: string | number
  margin?: string
  color?: string
  thickness?: number
}

const Divider: FC<DividerProps> = ({
  className,
  width = '100%',
  margin,
  color = COLOR.NEUTRAL[100],
  thickness = 1,
}) => {
  return <StyledDivider {...{ className, width, margin, color, thickness }} />
}

const StyledDivider = styled.hr<{
  width?: string | number
  margin?: string
  color?: string
  thickness?: number
}>(({ width, margin, color, thickness }) => ({
  border: `${thickness}px solid ${color}`,
  width,
  margin,
}))

export { Divider }
