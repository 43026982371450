import type {
  Servicer,
  ServicerCreateRequest,
} from '@helloextend/extend-api-client/src/models/servicer'
import { createActionFailure } from '../../factories'

export const create = (body: ServicerCreateRequest, accessToken: string) =>
  ({ type: 'SERVICERS_CREATE', payload: { accessToken, body } } as const)

export const createRequest = () => ({ type: 'SERVICERS_CREATE_REQUEST' } as const)

export const createSuccess = (servicer: Servicer) =>
  ({
    type: 'SERVICERS_CREATE_SUCCESS',
    payload: { servicer },
  } as const)

export const createFailure = createActionFailure('SERVICERS_CREATE_FAILURE')
