import type { Claim, Contract } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetchByContractId = (contractId: string, accessToken: string) =>
  ({ type: 'CLAIMS_FETCH_BY_CONTRACT_ID', payload: { contractId, accessToken } } as const)

export const fetchByContract = (contract: Contract, accessToken: string) =>
  ({ type: 'CLAIMS_FETCH_BY_CONTRACT', payload: { contract, accessToken } } as const)

export const fetchByContractRequest = () => ({ type: 'CLAIMS_FETCH_BY_CONTRACT_REQUEST' } as const)

export const fetchByContractSuccess = (
  contractId: string,
  response: Record<'claims', Claim[]>,
  accessToken: string,
) =>
  ({
    type: 'CLAIMS_FETCH_BY_CONTRACT_SUCCESS',
    payload: { contractId, claims: response.claims, accessToken },
  } as const)

export const fetchByContractFailure = createActionFailure('CLAIMS_FETCH_BY_CONTRACT_FAILURE')
