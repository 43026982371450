import * as fetchActions from './fetch'
import * as createActions from './create'
import * as fetchAllActions from './fetch-all'
import * as updateActions from './update'
import * as destroyActions from './destroy'
import * as resetActions from './reset'
import type { ExtractActions } from '../../types/utility'

export type Action =
  | ExtractActions<typeof createActions>
  | ExtractActions<typeof fetchActions>
  | ExtractActions<typeof fetchAllActions>
  | ExtractActions<typeof updateActions>
  | ExtractActions<typeof destroyActions>
  | ExtractActions<typeof resetActions>

export type ActionType = Action['type']

const actions = {
  ...createActions,
  ...fetchActions,
  ...fetchAllActions,
  ...updateActions,
  ...destroyActions,
  ...resetActions,
}

export { actions }
