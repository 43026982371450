import type {
  PlanMatching,
  StorePlanMapping,
  StorePlanPricingUpdateData,
  StorePricing,
} from '@helloextend/extend-api-client'
import type {
  SPCartPriceMap,
  SPPlanPriceBandMapping,
} from '@helloextend/extend-api-client/src/models/store'
import type { MappedStorePlanPricingToWholesalePricing } from '../../../../types/wholesale-pricing'
import type { Values } from './store-details/plan-and-pricing/components/wholesale-pricing-table/schema'

// maps the plan matching to store pricing for the wholesale pricing table
export const mapStorePlanPricingToWholesalePricing = (
  planId: string,
  storeId: string,
  planMatching: PlanMatching[],
  planPricing?: StorePricing[],
): MappedStorePlanPricingToWholesalePricing[] => {
  return planMatching?.reduce((acc, curr): MappedStorePlanPricingToWholesalePricing[] => {
    const matchingStorePlanPricing = planPricing?.find((item) => item.skuId === curr.vendor_sku)

    const sameSkuId = Boolean(acc.find((item) => item.skuId === curr.vendor_sku))
    const isWholesaleFieldDisabled = sameSkuId

    acc.push({
      planId,
      storeId,
      skuId: matchingStorePlanPricing?.skuId || curr.vendor_sku,
      wholesalePrice: matchingStorePlanPricing?.wholesalePrice,
      priceBandLow: curr.price_low,
      priceBandHigh: curr.price_high,
      premium: curr.cost,
      isWholesaleFieldDisabled,
    })

    return acc
  }, [] as MappedStorePlanPricingToWholesalePricing[])
}

export const checkPlansForWholesalePricing = (
  storePlanMapping: StorePlanMapping[],
  storePricing?: StorePricing[],
): string[] => {
  return storePlanMapping?.reduce((acc, curr) => {
    const matchingStorePricing = storePricing?.find((item) => item.planId === curr.planId)

    if (!matchingStorePricing) {
      acc.push(curr.planId)
    }

    return acc
  }, [] as string[])
}

export interface MappedStorePlanPricingValues extends Values {
  wholesalePrices: MappedStorePlanPricingToWholesalePricing[]
}

export const mapValuesToWholesalePricing = (
  planId: string,
  storeId: string,
  values: MappedStorePlanPricingValues['wholesalePrices'],
): StorePlanPricingUpdateData[] => {
  return (
    values?.reduce((acc, curr) => {
      if (!curr.isWholesaleFieldDisabled) {
        acc.push({
          planId,
          storeId,
          skuId: curr.skuId,
          wholesalePrice: Number(curr.wholesalePrice),
        })
      }
      return acc
    }, [] as StorePlanPricingUpdateData[]) ?? []
  )
}

export const hasGapsInPriceband = (
  priceBands: SPCartPriceMap[] | SPPlanPriceBandMapping[],
  currentRow?: SPCartPriceMap | SPPlanPriceBandMapping | null,
): boolean => {
  for (let i = 0; i < priceBands.length; i += 1) {
    const item = priceBands[i]
    if (i !== 0 && item.low !== priceBands[i - 1].high) {
      // Only return errors for current row
      if (currentRow && currentRow === item) {
        return true
      }
    }
  }
  return false
}

export const hasOverlappingPriceBands = (
  priceBands: SPCartPriceMap[] | SPPlanPriceBandMapping[],
  currentRow?: SPCartPriceMap | SPPlanPriceBandMapping | null,
): boolean => {
  for (let i = 0; i < priceBands.length; i += 1) {
    const item = priceBands[i]
    const { low, high } = item
    const isCurrentRow = currentRow && currentRow === item
    if (high && low > high && isCurrentRow) return true
    const previousItem = priceBands[i - 1]?.high
    if (i !== 0 && previousItem && low <= previousItem - 1) {
      // Only return errors for current row
      if (isCurrentRow) {
        return true
      }
    }
  }

  return false
}
