export function mockEmotionBorderRadius(): void {
  jest.mock('@emotion/react', () => ({
    ...jest.requireActual('@emotion/react'),
    useTheme: () => ({
      button: {
        borderRadius: 0,
      },
    }),
  }))
}

export function mockWindowResizeObserver(): void {
  window.ResizeObserver =
    window.ResizeObserver ||
    jest.fn().mockImplementation(() => ({
      disconnect: jest.fn(),
      observe: jest.fn(),
      unobserve: jest.fn(),
    }))
}
