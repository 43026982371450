export const COLOR = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',

  NEUTRAL: {
    100: '#F3F6F9',
    200: '#E3EAF0',
    300: '#C9D1DC',
    400: '#A9B1BB',
    500: '#8C97A5',
    600: '#6B7887',
    700: '#515963',
    800: '#404852',
    900: '#2B3136',
    1000: '#141617',
    OPACITY: {
      12: '#99B2CA1F',
      25: '#92A9BF40',
      50: '#53667780',
      75: '#3A4D5FBF',
    },
  },

  BLUE: {
    100: '#EEF3FF',
    200: '#D1DCFF',
    300: '#A6B8FF',
    400: '#8099FF',
    500: '#5F7EFB',
    600: '#3E64FF',
    700: '#2146DE',
    800: '#0033CC',
    900: '#101F7C',
    1000: '#090637',
    OPACITY: {
      12: '#6F88FF1F',
      25: '#4771FF40',
      50: '#0031FB80',
      75: '#0732E7BF',
    },
  },

  GREEN: {
    100: '#DFFFEA',
    200: '#ACF9CB',
    300: '#7DEDB7',
    400: '#37D68A',
    500: '#0AB26C',
    600: '#00894F',
    700: '#00663B',
    800: '#015735',
    900: '#003C24',
    1000: '#001D11',
    OPACITY: {
      12: '#01F2621F',
      25: '#00CC5240',
      50: '#00903A80',
      75: '#005A1FBF',
    },
  },

  YELLOW: {
    100: '#FFF8D4',
    200: '#FFE89B',
    300: '#FFCF59',
    400: '#F5AD09',
    500: '#CB8600',
    600: '#A66300',
    700: '#884900',
    800: '#7A3B00',
    900: '#532800',
    1000: '#2E1600',
    OPACITY: {
      12: '#EFB9001F',
      25: '#D7A60040',
      50: '#B66D0080',
      75: '#7B3400BF',
    },
  },

  RED: {
    100: '#FFE8EA',
    200: '#FFCAD0',
    300: '#FF939F',
    400: '#F56778',
    500: '#EA4559',
    600: '#D32137',
    700: '#B50318',
    800: '#8A0011',
    900: '#64000C',
    1000: '#3F0008',
    OPACITY: {
      12: '#FF374F1F',
      25: '#FF2C4540',
      50: '#AB001480',
      75: '#A90014BF',
    },
  },
}
