import type { VFC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../tokens/colors'
import { HeadingLarge, Paragraph } from '../../tokens'
import { AccentIcon } from '../accent-icon'
import type { EmptyStateProps } from './empty-state-types'

export const EmptyState: VFC<EmptyStateProps> = ({
  actionMaxWidth,
  button,
  description,
  field,
  heading,
  icon,
  'data-cy': dataCy,
}) => {
  const hasAction = field || button
  return (
    <StyledState data-cy={dataCy}>
      <Content>
        {icon && (
          <AccentIcon data-cy={dataCy && `${dataCy}:icon`} icon={icon} color={COLOR.NEUTRAL[800]} />
        )}
        <Text>
          {heading && (
            <HeadingLarge data-cy={dataCy && `${dataCy}:heading`}>{heading}</HeadingLarge>
          )}
          {description && (
            <Paragraph data-cy={dataCy && `${dataCy}:description`}>{description}</Paragraph>
          )}
        </Text>
        {hasAction && (
          <Action maxWidth={actionMaxWidth}>
            {field}
            {button && <ButtonContainer>{button}</ButtonContainer>}
          </Action>
        )}
      </Content>
    </StyledState>
  )
}

const StyledState = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '72px 24px',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
})

const Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  alignItems: 'center',
})

const Text = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  textAlign: 'center',
  maxWidth: 480,
})

const Action = styled.div<{
  maxWidth?: number
}>(({ maxWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  width: '100%',
  maxWidth: maxWidth || 280,
  alignItems: 'stretch',
}))

const ButtonContainer = styled.div({
  alignSelf: 'center',
})
