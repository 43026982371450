import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Button } from '@helloextend/zen'
import {
  useUpdatePlanAttributesMutation,
  useListPlanAttributesQuery,
} from '@helloextend/extend-api-rtk-query'
import type { PlansAttributesUpsertBody } from '@helloextend/extend-api-client'
import { BasicModal } from '../../../components/basic-modal'
import { SuccessfulSubmit } from '../../../components/successful-submit'

type DeleteAttributeModalProps = {
  isOpen: boolean
  handleModalToggle: () => void
  attributeValue: string
  attributeId: string
}

const DeleteAttributeModal: FC<DeleteAttributeModalProps> = ({
  isOpen,
  handleModalToggle,
  attributeValue,
  attributeId,
}) => {
  const [updatePlanAttributes, { isLoading, isSuccess, error }] = useUpdatePlanAttributesMutation()
  const { attributeValues } = useListPlanAttributesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      attributeValues: data?.items.find((attribute) => {
        return attribute.id === attributeId
      }),
    }),
  })
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)

  const handleDeleteValue = (): void => {
    const updatedValues = attributeValues?.values.filter((value) => {
      return value !== attributeValue
    })

    updatePlanAttributes({
      id: attributeId,
      values: updatedValues,
    } as PlansAttributesUpsertBody)
    setHasSubmitted(true)
  }

  const handelModalSuccessClose = (): void => {
    setHasSubmitted(false)
    handleModalToggle()
  }

  return (
    <BasicModal isVisible={isOpen} onClickClose={handelModalSuccessClose}>
      {isLoading || hasSubmitted ? (
        <SuccessfulSubmit
          isLoading={isLoading}
          submitted={isSuccess && hasSubmitted}
          error={error}
          errorTitle="An error occurred while trying to add a value."
          successTitle="Successfully deleted value"
          buttonText="Close"
          handleClick={handelModalSuccessClose}
        />
      ) : (
        <>
          <Title data-cy="delete-value-header-text">Delete Value</Title>
          <Subtext data-cy="delete-value-copy">
            This will delete this value from our system and the change cannot be undone. This will
            be in effect immediately
          </Subtext>
          <Subtext data-cy="delete-value-confirmation">
            Are you sure you want to delete this value?
          </Subtext>
          <ButtonGroup>
            <Button
              onClick={handleModalToggle}
              text="Cancel"
              type="button"
              size="regular"
              emphasis="medium"
              color="blue"
              data-cy="delete-value-cancel-button"
            />
            <Button
              onClick={handleDeleteValue}
              text="Delete"
              type="button"
              size="regular"
              emphasis="high"
              color="red"
              data-cy="delete-value-delete-button"
            />
          </ButtonGroup>
        </>
      )}
    </BasicModal>
  )
}

const Title = styled.h2({
  fontSize: 20,
  lineHeight: '27px',
  fontWeight: 700,
  marginTop: 0,
})

const ButtonGroup = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 16,
  marginTop: 80,
})

const Subtext = styled.p({
  fontSize: 16,
  lineHeight: '22px',
})

export { DeleteAttributeModal }
