import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import images from '../images'

type AuthLayoutProps = {
  showFooter?: boolean
  showLogo?: boolean
}

const AuthLayout: FC<AuthLayoutProps> = ({ showFooter, children }) => (
  <Wrapper>
    <Card>
      <Logo src={images.extendLogo} alt="Extend" />
      {children}
    </Card>
    {showFooter && (
      <Footer>
        Don&apos;t have an account yet?{' '}
        <ButtonText href="https://www.extend.com/contact">Contact us</ButtonText>
      </Footer>
    )}
  </Wrapper>
)

const Wrapper = styled.section({
  color: COLOR.BLACK_PRIMARY,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: COLOR.LIGHT_GRAYISH_BLUE_1,
  '*': {
    boxSizing: 'border-box',
  },
})

const ButtonText = styled.a({
  display: 'inline-block',
  fontSize: 14,
  backgroundColor: 'transparent',
  borderBottom: '1px solid transparent',
  color: COLOR.STRONG_BLUE,
  cursor: 'pointer',
  transition: 'border .25s ease-in',
  '&:hover': {
    borderBottom: `1px solid ${COLOR.STRONG_BLUE}`,
  },
  '&:visited': {
    color: COLOR.STRONG_BLUE,
  },
})

const Card = styled.div({
  width: 436,
  borderRadius: 12,
  maxWidth: '100%',
  padding: '40px',
  backgroundColor: COLOR.WHITE,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  boxShadow: `0px 0px 40px 0px rgb(163 163 163 / 8%)`,
})

const Logo = styled.img({
  width: 104,
  height: 24,
})

const Footer = styled.div({
  fontFamily: '"Nunito Sans", Helvetica, sans-serif',
  fontSize: 14,
  padding: '24px 0 0',
  textAlign: 'center',
  margin: '0 auto',
  color: COLOR.BLACK_PRIMARY,
})

export { AuthLayout }
