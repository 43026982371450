import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@helloextend/client-branding'
import styled from '@emotion/styled'
import type { support as supportActions } from '../../../actions'

interface ThanksPageProps {
  toggleSupport: typeof supportActions.toggleSupport
}

const ThanksPage: FC<ThanksPageProps> = ({ toggleSupport }) => {
  return (
    <Wrapper>
      <Header>Thanks for reaching out!</Header>
      <Text>We will be in touch soon.</Text>
      <Button name="doneButton" onClick={toggleSupport}>
        Done
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 154,
})

const Header = styled.div({
  fontSize: 22,
  fontWeight: 800,
  lineHeight: '30px',
  marginTop: 32,
})

const Text = styled.div({
  fontSize: 16,
  fontWeight: 700,
  lineHeight: '22px',
  marginTop: 16,
})

const Button = styled.button({
  background: COLOR.EXTEND_BLUE,
  borderRadius: 4,
  height: 44,
  width: 306,
  outline: 'none',
  border: 'none',
  fontSize: 16,
  color: COLOR.WHITE,
  fontWeight: 600,
  marginTop: 224,
  cursor: 'pointer',
})

export default ThanksPage
