import type { FC } from 'react'
import React, { useCallback } from 'react'
import { Modal, ModalController, ToastColor, ToastDuration, useToaster } from '@helloextend/zen'
import {
  useLazyGetUserGrantsListQuery,
  useRevokeUserGrantMutation,
} from '@helloextend/extend-api-rtk-query'
import { filterGrantsByAccount } from '../../../../../utils/user-role-mapper'

type DeactivateUserModalProps = {
  email: string
  isVisible: boolean
  onClickClose: () => void
}

const DeactivateUserModal: FC<DeactivateUserModalProps> = ({ email, isVisible, onClickClose }) => {
  const { toast } = useToaster()
  const [getUserGrants] = useLazyGetUserGrantsListQuery()
  const [revokeGrant, { isLoading }] = useRevokeUserGrantMutation()

  const handleDeactivateButtonClick = useCallback(async () => {
    const userGrantsResponse = await getUserGrants(email)
    if ('error' in userGrantsResponse || !userGrantsResponse.data) {
      toast({
        message: 'An error occurred while getting user grants. Please try again later.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      onClickClose()
      return
    }

    const filteredGrants = filterGrantsByAccount(userGrantsResponse.data.grants)

    if (filteredGrants.length === 0) return

    const response = await Promise.all(
      filteredGrants.map(async (grant) =>
        revokeGrant({ userId: email, ern: grant.ern, role: grant.role }),
      ),
    )

    if (response.some((result) => 'error' in result)) {
      toast({
        message: 'Error occured while deactivating user, please try again later.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      onClickClose()
      return
    }

    toast({
      message: 'User deactivated successfully',
      toastColor: ToastColor.blue,
      toastDuration: ToastDuration.short,
    })
    onClickClose()
  }, [email, getUserGrants, onClickClose, revokeGrant, toast])

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        data-cy="deactivate-user-modal"
        heading="Deactivate this user?"
        primaryButtonProps={{
          onClick: handleDeactivateButtonClick,
          color: 'red',
          text: 'Deactivate User',
          isDisabled: isLoading,
          isProcessing: isLoading,
          'data-cy': 'deactivate-user-modal-confirm-button',
        }}
        secondaryButtonProps={{
          onClick: onClickClose,
          text: 'Cancel',
          isDisabled: isLoading,
          'data-cy': 'deactivate-user-modal-cancel-button',
        }}
      >
        <p>This will remove the user&#39;s access to the Extend portal and assigned role(s).</p>
        <p>Are you sure you want to deactivate this user?</p>
      </Modal>
    </ModalController>
  )
}

export { DeactivateUserModal }
