import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'

const NewUserRoleDescription: FC = () => {
  return (
    <NewUserRoleDescriptionContainer data-cy="create-user-role-description">
      <Title>Roles</Title>
      <strong> Account Owner - </strong> The owner of the store, executive stakeholder, or website
      administrator/technician responsible for the integration.
      <List>
        <ListItems>View Products, Contracts, Leads, Claims, and Analytics</ListItems>
        <ListItems>Edit Contracts</ListItems>
        <ListItems>Enable/disable Offers</ListItems>
        <ListItems>File Claims</ListItems>
        <ListItems>Customize Offers and Store Logo</ListItems>
        <ListItems>Modify Account Settings</ListItems>
        <ListItems>Invite and manage Users</ListItems>
      </List>
      <strong> Claims Agent - </strong> A user with limited permissions to file and maintain Claims
      against Extend protection plans.
      <List>
        <ListItems>View Contracts and Claims</ListItems>
        <ListItems>File Claims</ListItems>
      </List>
    </NewUserRoleDescriptionContainer>
  )
}

const NewUserRoleDescriptionContainer = styled.div({
  padding: 8,
  fontSize: 13,
  lineHeight: '18px',
})

const List = styled.ul({
  marginBottom: 24,
  paddingLeft: 12,
})

const ListItems = styled.li({
  marginLeft: 16,
  fontWeight: 500,
  marginTop: 0,
  marginBottom: 8,
})

const Title = styled.h3({
  fontSize: 20,
  marginBottom: 8,
  marginTop: 0,
})

export { NewUserRoleDescription }
