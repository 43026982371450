import type { FC } from 'react'
import type { FormikErrors } from 'formik'
import { getIn } from 'formik'
import { get } from 'lodash/fp'
import type { GroupItem } from './collapsible-info-group'

interface FieldMapperConfig<T> {
  key: string
  formKey?: string
  editable?: boolean
  label: string
  isCustomComponent?: boolean
  transformFn?: (value: any) => string
  validateFn?: (values: T) => boolean
  isHidden?: boolean
  CustomComponent?: FC<any>
}

const fieldMapper = <T, S = undefined>(
  values: T,
  data: Array<FieldMapperConfig<T>>,
  formikValues?: { [key: string]: any },
  formErrors?: FormikErrors<S>,
): Array<GroupItem | null> => {
  return data.map((item) => {
    const rawVal = get(item.key, values)
    if ((item.validateFn && !item.validateFn(values)) || item.isHidden) {
      return null
    }

    const value = item.transformFn ? item.transformFn(rawVal) : rawVal

    return {
      name: item.editable ? item.formKey ?? item.key : item.key,
      value: item.editable ? getIn(formikValues, item.formKey ?? item.key) ?? value : value,
      error: item.editable ? getIn(formErrors, item.formKey ?? item.key) ?? '' : '',
      ...item,
    }
  })
}

export { fieldMapper, FieldMapperConfig }
