import { createActionFailure } from '../../factories'

export const deleteClaimPhoto = (
  contractId: string,
  claimId: string,
  photoId: string,
  accessToken: string,
) =>
  ({
    type: 'CONTRACTS_CLAIMS_PHOTOS_DELETE',
    payload: { contractId, claimId, photoId, accessToken },
  } as const)

export const deleteClaimPhotoRequest = () =>
  ({ type: 'CONTRACTS_CLAIMS_PHOTOS_DELETE_REQUEST' } as const)

export const deleteClaimPhotoSuccess = () =>
  ({
    type: 'CONTRACTS_CLAIMS_PHOTOS_DELETE_SUCCESS',
  } as const)

export const deleteClaimPhotoFailure = createActionFailure('CONTRACTS_CLAIMS_PHOTOS_DELETE_FAILURE')
