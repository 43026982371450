import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { parseHexColor } from '../../../../utils/colors'
import { COLOR } from '../../../../tokens/colors'

interface ColorSwatchProps {
  value: string
}

const ColorSwatch: FC<ColorSwatchProps> = ({ value }) => {
  const hexColor = parseHexColor(value) || '#000000'
  return <StyledSpan backgroundColor={hexColor} />
}

const StyledSpan = styled.span<{ backgroundColor: string }>(({ backgroundColor }) => ({
  backgroundColor,
  height: 24,
  width: 24,
  borderRadius: 4,
  boxShadow: `inset 0 0 0 1px ${COLOR.NEUTRAL.OPACITY[50]}`,
  boxSizing: 'border-box',
  cursor: 'pointer',
}))

export { ColorSwatch }
