import * as Yup from 'yup'
import { date } from '@helloextend/client-utils'
import { ClaimServiceOrderType } from '@helloextend/extend-api-client/src/models/claim'
import { formatCurrency, formatStartCase } from '../../../util'

const formatDate = (value?: number): string => {
  return (typeof value === 'number' && date.format(value, 'MMM DD YYYY')) || ''
}

const dataFields = {
  [ClaimServiceOrderType.repair_depot]: [
    { key: 'serviceOrder.createdAt', label: 'SO Created Date', transformFn: formatDate },
    {
      key: 'serviceOrder.repairMetaData.checkedInAt',
      label: 'Checked In Date',
      transformFn: formatDate,
    },
    {
      key: 'serviceOrder.repairMetaData.diagnosticCompletedAt',
      label: 'Diagnostic Date',
      transformFn: formatDate,
    },
    {
      key: 'serviceOrder.repairMetaData.repairStartedAt',
      label: 'Repair Started Date',
      transformFn: formatDate,
    },
  ],
  [ClaimServiceOrderType.repair_onsite]: [
    {
      key: 'serviceOrder.serviceType',
      label: 'Service Type',
      transformFn: formatStartCase,
    },
    {
      key: 'entitlements.coverageAmountRemaining',
      label: 'Remaining LOL.',
      transformFn: formatCurrency,
    },
    {
      key: 'contract.product.title',
      label: 'Item Requiring Fulfillment',
    },
    { key: 'contract.sellerName', label: 'Purchased From' },
    { key: 'contract.product.serialNumber', label: 'Serial Number' },
    { key: 'claim.incident.failureType', label: 'Failure Type', transformFn: formatStartCase },
    { key: 'claim.incident.description', label: 'Incident Description' },
  ],
  [ClaimServiceOrderType.repair_home]: [],
  [ClaimServiceOrderType.replace]: [],
  [ClaimServiceOrderType.repair_irobot]: [],
}

const formSchema = Yup.object()
  .shape({
    repairCompletedAt: Yup.mixed<undefined | number>()
      .test({
        name: 'isValidDate',
        message: 'Required',
        test: (value) => value != null && value > 0,
      })
      .default(undefined),
    repairExplanation: Yup.string().default(''),
  })
  .defined()

type Values = Yup.InferType<typeof formSchema>

export { dataFields, formSchema }
export type { Values }
