import { faker } from '@faker-js/faker/locale/en'
import type { Integration, Permissions } from '@helloextend/extend-api-rtk-query'

export const generateIntegrationRecord = (overrides: Partial<Integration> = {}): Integration => {
  const integrationRecord = {
    id: faker.datatype.uuid(),
    accountId: faker.datatype.uuid(),
    organizationId: faker.datatype.uuid(),
    clientId: faker.datatype.uuid(),
    clientSecret: faker.datatype.uuid(),
    createdDateUtc: Date.now(),
    name: `${faker.company.name()} Integration`,
    platform: 'shopify',
    permissions: mockPermissions,
    createdBy: {
      id: faker.datatype.uuid(),
      firstName: faker.name.firstName(),
      lastName: faker.name.lastName(),
      email: faker.datatype.string(),
    },
    ...overrides,
  }

  return integrationRecord
}

const mockPermissions: Permissions[] = [
  {
    name: 'Products',
    scope: 'permissions:products:read',
    level: 'read',
  },
  {
    name: 'Contracts',
    scope: 'permissions:contracts:write',
    level: 'write',
  },
  {
    name: 'Contracts',
    scope: 'permissions:contracts:read',
    level: 'read',
  },
  {
    name: 'Claims',
    scope: 'permissions:products:read',
    level: 'read',
  },
  {
    name: 'Claims',
    scope: 'permissions:products:other',
    level: 'other',
  },
  {
    name: 'Leads',
    scope: 'permissions:products:write',
    level: 'write',
  },
  {
    name: 'Leads',
    scope: 'permissions:products:read',
    level: 'read',
  },
  {
    name: 'Analytics',
    scope: 'permissions:products:read',
    level: 'read',
  },
]

export const generateIntegrationSecretKeys = (): { clientId: string; clientSecret: string } => {
  return {
    clientId: faker.datatype.uuid(),
    clientSecret: faker.datatype.uuid(),
  }
}
