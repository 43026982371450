import type { FC, Ref } from 'react'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/zen'
import type { ScriptItem } from '@helloextend/extend-api-rtk-query'
import { AdjudicationDecisionBlock } from './adjudication-decision-block'
import { AdjudicationErrorBoundary } from './adjudication-error-boundary'
import { AdjudicationMessageTemplate } from './adjudication-message-template'
import { MessageBubble } from '../message-bubble'
import {
  isMessageBlockTextAssigned,
  isMessageBlockOrphaned as getIsMessageBlockOrphaned,
  getIsMessageBlockRoutingsAssigned,
  getIsEachReportingValueAssigned,
} from '../../../../../store/slices/amp-validation'
import type { RootState } from '../../../../../reducers'
import {
  getIsPublishValidationModeActive,
  getCurrentMessageBlockReferenceMap,
} from '../../../../../reducers/selectors'

interface MessageBlockProps {
  className?: string
  isActive?: boolean
  index?: string
  scriptItem: ScriptItem
  ref: Ref<HTMLDivElement>
  threadId?: string
  isClaimsMode?: boolean
  onClick?: () => void
  onBadgeClick: (val: number | string) => void
}

const AdjudicationMessageBlock: FC<MessageBlockProps> = React.forwardRef<
  HTMLDivElement,
  MessageBlockProps
>(
  (
    { className, isActive, index, scriptItem, threadId, isClaimsMode, onClick, onBadgeClick },
    ref,
  ) => {
    const isPublishValidationModeActive = useSelector((state: RootState) =>
      getIsPublishValidationModeActive(state),
    )

    const isEachReportingValuesAssigned = useMemo(() => {
      return getIsEachReportingValueAssigned(scriptItem)
    }, [scriptItem])
    const messageBlockReferenceMap = useSelector((state: RootState) =>
      getCurrentMessageBlockReferenceMap(state),
    )

    const isTextAssigned = useMemo(() => {
      return isMessageBlockTextAssigned(scriptItem)
    }, [scriptItem])

    const hasValidJumpToAssigned = useMemo(() => {
      if (isPublishValidationModeActive) {
        return getIsMessageBlockRoutingsAssigned(scriptItem)
      }
      return true
    }, [isPublishValidationModeActive, scriptItem])

    const isMessageBlockOrphaned = useMemo(() => {
      const numericalIndex = Number(index)
      // first message block of a thread can never be orphaned
      if (numericalIndex === 1) return false

      if (isPublishValidationModeActive) {
        return getIsMessageBlockOrphaned(
          `${threadId}.${numericalIndex - 1}`,
          messageBlockReferenceMap,
        )
      }
      return false
    }, [isPublishValidationModeActive, index, threadId, messageBlockReferenceMap])

    const isMessageBlockValid = useMemo(() => {
      if (!isPublishValidationModeActive) {
        return isTextAssigned
      }

      return (
        isTextAssigned &&
        hasValidJumpToAssigned &&
        !isMessageBlockOrphaned &&
        isEachReportingValuesAssigned
      )
    }, [
      isPublishValidationModeActive,
      isTextAssigned,
      hasValidJumpToAssigned,
      isMessageBlockOrphaned,
      isEachReportingValuesAssigned,
    ])

    return (
      <MessageWrapper
        className={className}
        ref={ref}
        data-cy={`amp-message-block-${index}${threadId ? `-${threadId}` : ''}`}
      >
        <MessageBubble
          isActive={isActive && !isClaimsMode}
          data-cy={
            isActive && !isClaimsMode
              ? `message-bubble-${index}-${threadId || ''}-selected`
              : `message-bubble-${index}-${threadId || ''}`
          }
        >
          {index}
        </MessageBubble>
        <MessageBoxWrapper>
          <MessageBox
            isSelectable={Boolean(onClick)}
            isActive={isActive && !isClaimsMode}
            {...{ onClick, isMessageBlockValid }}
            data-cy={`amp-clickable-message-block-${index}${threadId ? `-${threadId}` : ''}`}
          >
            <AdjudicationErrorBoundary scriptItem={scriptItem}>
              {scriptItem.reply && (
                <AdjudicationMessageTemplate
                  onBadgeClick={onBadgeClick}
                  scriptItem={{ reply: scriptItem.reply, collect: scriptItem.collect }}
                />
              )}
              {!scriptItem.reply && (
                <AdjudicationDecisionBlock scriptItem={scriptItem} onBadgeClick={onBadgeClick} />
              )}
            </AdjudicationErrorBoundary>
          </MessageBox>
          {!isTextAssigned && (
            <ValidationErrorText>Message block contains empty value.</ValidationErrorText>
          )}
          {isPublishValidationModeActive && !hasValidJumpToAssigned && (
            <ValidationErrorText>
              Message block requires Jump To values for all Customer Responses.
            </ValidationErrorText>
          )}

          {isPublishValidationModeActive && !isEachReportingValuesAssigned && (
            <ValidationErrorText>
              Message block requires Reporting Values for all Customer Responses.
            </ValidationErrorText>
          )}
          {isPublishValidationModeActive && isMessageBlockOrphaned && (
            <ValidationErrorText>
              Message block must be pointed to by at least one other message block Jump To value.
            </ValidationErrorText>
          )}
        </MessageBoxWrapper>
      </MessageWrapper>
    )
  },
)

const MessageWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: '16px', // Use 'padding' here instead of 'margin' or 'gap' so that the badge click scrollIntoView effect offsets at the top
  alignSelf: 'flex-start',
})

const MessageBoxWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const getBorderColor = (isActive?: boolean, isMessageBlockValid?: boolean): string => {
  if (!isMessageBlockValid) {
    return COLOR.RED[700]
  }
  return isActive ? COLOR.BLUE[700] : COLOR.NEUTRAL[300]
}

const MessageBox = styled.div<{
  isActive?: boolean
  isSelectable?: boolean
  isMessageBlockValid?: boolean
}>(({ isActive, isSelectable, isMessageBlockValid }) => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  width: 360,
  padding: 16,
  borderRadius: 8,
  background: COLOR.WHITE,
  border: `1px solid ${getBorderColor(isActive, isMessageBlockValid)}`,
  cursor: isSelectable ? 'pointer' : 'not-allowed',
  transition: 'box-shadow 0.3s',
  '&:hover': {
    boxShadow: isSelectable ? `0px 0px 8px ${COLOR.NEUTRAL[300]}` : '',
  },
}))

const ValidationErrorText = styled.span({
  marginTop: 4,
  fontSize: 15,
  lineHeight: '20px',
  color: COLOR.RED[700],
})

export { AdjudicationMessageBlock, MessageBlockProps }
