import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { DatePicker } from '../date-picker'

export type DateRangeFilterCustomRangeProps = {
  start: Date | null
  end: Date | null
  onDateRangeChange: (start: Date | null, end: Date | null) => void
  onMenuLock: (isLocked: boolean) => void
}

const DateRangeFilterCustomRange: FC<DateRangeFilterCustomRangeProps> = ({
  start,
  end,
  onDateRangeChange,
  onMenuLock,
}) => {
  const today = useMemo(() => {
    const d = new Date()
    d.setHours(0, 0, 0, 0)
    return d
  }, [])

  const handleStartDate = useCallback(
    (newDate: Date | null) => {
      onDateRangeChange(newDate, end)
    },
    [end, onDateRangeChange],
  )

  const handleEndDate = useCallback(
    (newDate: Date | null) => {
      onDateRangeChange(start, newDate)
    },
    [start, onDateRangeChange],
  )

  const handleCalendarOpen = useCallback(() => {
    onMenuLock(true)
  }, [onMenuLock])

  const handleCalendarClose = useCallback(() => {
    onMenuLock(false)
  }, [onMenuLock])

  return (
    <DateRangeWrapper>
      <DateLabel>From</DateLabel>
      <DatePickerWrapper>
        <DatePicker
          onChange={handleStartDate}
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          selected={start}
          maxDate={end || today}
          placeHolder={end ? 'Start' : ''}
          data-cy="startDate"
        />
      </DatePickerWrapper>
      <DateLabel>To</DateLabel>
      <DatePickerWrapper>
        <DatePicker
          onChange={handleEndDate}
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          selected={end}
          minDate={start}
          maxDate={today}
          placeHolder={start ? 'Now' : ''}
          data-cy="endDate"
        />
      </DatePickerWrapper>
    </DateRangeWrapper>
  )
}

const DateRangeWrapper = styled.div({
  padding: '12px 12px 0px 12px',
})

const DateLabel = styled.div({
  fontWeight: 'bold',
  margin: '0px 0px 8px 0px',
})

const DatePickerWrapper = styled.div({
  display: 'flex',
  margin: '0px 0px 16px 0px',
})

export { DateRangeFilterCustomRange }
