import type {
  CurrencyConfig,
  DropdownConfig,
  FormikMapperConfig,
  HyperlinkConfig,
} from '../../../components/form-text-group/formik-mapper'

const fieldsToRemove = new Set(['coverageStartDate', 'coverageEndDate'])

const headerInformationFields = [
  { key: 'id', label: 'ID' },
  { key: 'product.name', label: 'Product' },
  { key: 'type', label: 'Type' },
]

const storeInformationFields = [
  { key: 'storeName', label: 'Store Name' },
  { key: 'storeId', label: 'Store ID', columnCount: 2 },
  { key: 'customerSupportLine', label: 'Customer Support Line' },
  { key: 'customerSupportEmail', label: 'Customer Support Email' },
  { key: 'escalationContact', label: 'Escalation Contact' },
  { key: 'escalationNumber', label: 'Escalation Number' },
  { key: 'extension', label: 'Extension' },
  { key: 'storeEmail', label: 'Email' },
]

const customerFields = [
  { key: 'fullName', label: 'Full Name' },
  { key: 'phoneNumber', label: 'Phone Number' },
  { key: 'customerEmail', label: 'Email' },
  { key: 'merchantCustomerId', label: 'Merchant Customer ID' },
]

const billingInformationFields = [
  { key: 'billingAddress', label: 'Address', columnCount: 2 },
  { key: 'billingAddressTwo', label: 'Address 2', columnCount: 2 },
  { key: 'billingCity', label: 'City', columnCount: 2 },
  { key: 'billingProvinceCode', label: 'Province Code' },
  { key: 'billingPostalCode', label: 'Postal Code' },
  { key: 'billingCountryCode', label: 'Country Code' },
]

const shippingInformationFields = [
  { key: 'shippingAddress', label: 'Address', columnCount: 2 },
  { key: 'shippingAddressTwo', label: 'Address 2', columnCount: 2 },
  { key: 'shippingCity', label: 'City', columnCount: 2 },
  { key: 'shippingProvinceCode', label: 'Province Code' },
  { key: 'shippingPostalCode', label: 'Postal Code' },
  { key: 'shippingCountryCode', label: 'Country Code' },
]

const transactionInformationFields = [
  { key: 'transactionId', label: 'Transaction ID' },
  { key: 'currencyCode', label: 'Currency Code' },
  { key: 'poNumber', label: 'PO Number' },
  { key: 'orderId', label: 'Order ID' },
]

const contractInformationFields: Array<
  FormikMapperConfig | CurrencyConfig | HyperlinkConfig | DropdownConfig
> = [
  { key: 'contractId', label: 'Contract ID', columnCount: 2 },
  { key: 'type', label: 'Type' },
  { key: 'isTest', label: 'Is Test', fieldType: 'checkbox' },
  { key: 'contractStatus', label: 'Contract Status' },
  { key: 'planId', label: 'Plan ID', columnCount: 2 },
  { key: 'planName', label: 'Plan Name' },
  { key: 'planCategory', label: 'Plan Category' },
  { key: 'planServiceType', label: 'Plan Service Type' },
  { key: 'planCoverageIncludes', label: 'Plan Coverage Incl.' },
  { key: 'planWarrantySku', label: 'Plan (Warranty) SKU' },
  { key: 'planVersion', label: 'Plan Version' },
  {
    key: 'termsId',
    label: 'Terms ID',
    fieldType: 'hyperlink',
    hyperlinkProps: { hyperlinkUrl: 'planTermsUrl' },
  },
  { key: 'termVersion', label: 'Terms Version' },
  {
    key: 'planPurchasePrice',
    label: 'Plan Purchase Price',
    fieldType: 'currency',
    currencyProps: { codeFieldName: 'currencyCode' },
  },
  {
    key: 'planListPrice',
    label: 'Plan List Price',
    fieldType: 'currency',
    currencyProps: { codeFieldName: 'currencyCode' },
  },
  { key: 'createdDate', label: 'Date Created' },
  { key: 'planPurchaseDate', label: 'Plan Purchase Date', fieldType: 'date' },
  { key: 'canceledDate', label: 'Date Canceled' },
  { key: 'refundedDate', label: 'Date Refunded' },
  { key: 'updatedDate', label: 'Date Updated' },
  { key: 'coverageStartDate', label: 'Coverage Start Date' },
  { key: 'coverageEndDate', label: 'Coverage End Date' },
  {
    key: 'limitOfLiabilityAmountType',
    label: 'Limit of Liability Type',
    fieldType: 'dropdown',
    options: [
      {
        label: 'Product List Price',
        value: 'product_list_price',
      },
      {
        label: 'Product Purchase Price',
        value: 'product_purchase_price',
      },
    ],
  },
  { key: 'limitOfLiabilityAmount', label: 'Limit of Liability Amount', fieldType: 'currency' },
  { key: 'remainingCoverageAmount', label: 'Remaining Coverage Amount', fieldType: 'currency' },
]

const productInformationFields: Array<FormikMapperConfig | CurrencyConfig> = [
  { key: 'referenceId', label: 'Reference ID' },
  { key: 'serialNumber', label: 'Serial Number' },
  {
    key: 'productPurchasePrice',
    label: 'Purchase Price',
    fieldType: 'currency',
    currencyProps: { codeFieldName: 'currencyCode' },
  },
  { key: 'productPurchaseDate', label: 'Product Purchase Date', fieldType: 'date' },
  { key: 'productName', label: 'Product Name', columnCount: 2 },
  {
    key: 'productListPrice',
    label: 'List Price',
    fieldType: 'currency',
    currencyProps: { codeFieldName: 'currencyCode' },
  },
  { key: 'imageUrl', label: 'Image' },
  { key: 'productTransactionId', label: 'Product Transaction ID' },
  { key: 'mfrsWarrantyLengthLabor', label: 'Mfr Warranty Length - Labor' },
  { key: 'mfrsWarrantyLengthParts', label: 'Mfr Warranty Length - Parts ' },
  { key: 'brand', label: 'Brand' },
  { key: 'sku', label: 'SKU' },
]

const contractInformationFieldsSP = contractInformationFields.filter((contractInformationField) => {
  return !fieldsToRemove.has(contractInformationField.key)
})

const transactionInformationFieldsSP = [
  ...transactionInformationFields,
  {
    key: 'quoteId',
    label: 'Quote ID',
  },
]

const sectionTitles = {
  customerInformation: 'Customer Information',
  transactionInformation: 'Transaction Information',
  storeInformation: 'Store Information',
  contractInformation: 'Contract Information',
  productInformation: 'Product Information',
  billingInformation: 'Billing Information',
  shippingInformation: 'Shipping Information',
  productAndShippingInformation: 'Product & Shipping Information',
}

export {
  headerInformationFields,
  transactionInformationFields,
  storeInformationFields,
  customerFields,
  billingInformationFields,
  shippingInformationFields,
  contractInformationFields,
  productInformationFields,
  contractInformationFieldsSP,
  transactionInformationFieldsSP,
  sectionTitles,
}
