import type { Action } from '../actions'
import { createErrorReducer } from '../../factories'

export default createErrorReducer<Action>(
  [
    'LOGIN_FAILURE',
    'FORGOT_PASSWORD_FAILURE',
    'RESET_PASSWORD_FAILURE',
    'REGISTER_FAILURE',
    'CHANGE_PASSWORD_FAILURE',
  ],
  [
    'AUTH_ERROR_RESET',
    'LOGIN_REQUEST',
    'LOGIN_SUCCESS',
    'FORGOT_PASSWORD_REQUEST',
    'FORGOT_PASSWORD_SUCCESS',
    'REGISTER_REQUEST',
    'REGISTER_SUCCESS',
    'LOGOUT',
    'CHANGE_PASSWORD_REQUEST',
    'CHANGE_PASSWORD_SUCCESS',
  ],
)
