import type { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import type { ActionType } from '../actions'
import fetchAll from './fetch-all'
import fetch from './fetch'
import update from './update'
import updateBatch from './update-batch'

export default function* sagas(): SagaIterator {
  yield all([
    takeLatest('PRODUCTS_UPDATE' as ActionType, update),
    takeLatest('PRODUCTS_UPDATE_BATCH' as ActionType, updateBatch),
    takeLatest('PRODUCTS_FETCH' as ActionType, fetch),
    takeLatest('PRODUCTS_FETCH_ALL' as ActionType, fetchAll),
  ])
}
