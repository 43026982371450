import { faker } from '@faker-js/faker/locale/en'
import type { PlanType, StorePlanMapping } from '@helloextend/extend-api-client'

export const generateStorePlanMapping = (
  overrides?: Partial<StorePlanMapping>,
): StorePlanMapping => ({
  storeId: faker.datatype.uuid(),
  planId: faker.datatype.uuid(),
  planName: faker.random.words(3),
  isEnabled: true,
  planType: 'PLAN' as PlanType,
  updatedAt: faker.date.recent().getTime(),
  createdAt: faker.date.recent().getTime(),
  ...overrides,
})
