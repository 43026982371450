import type { Fee } from '@helloextend/extend-api-client'
import { CellText } from '@helloextend/merchants-ui'
import React from 'react'

const getCellElement =
  (feeProperty: keyof Fee, suffix?: string) =>
  (data: Fee): JSX.Element =>
    (
      <CellText>
        {data[feeProperty]}
        {suffix}
      </CellText>
    )

const tableColumns = [
  {
    Header: 'Type',
    accessor: 'type',
    columnWidth: 100,
    Cell: getCellElement('type'),
  },
  {
    Header: 'Fee Amount',
    accessor: 'amount',
    Cell: getCellElement('amount', '%'),
  },
  {
    Header: 'Fee Based On',
    accessor: 'basedOn',
    Cell: getCellElement('basedOn'),
  },
  {
    Header: 'Fee Routing',
    accessor: 'routing',
    Cell: getCellElement('routing'),
  },
  {
    Header: 'Fee Recognition',
    accessor: 'recognition',
    Cell: getCellElement('recognition'),
  },
]

export { tableColumns }
