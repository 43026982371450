import type { FC } from 'react'
import React from 'react'
import { DisplayLarge } from '@helloextend/zen/src/tokens/typography'
import styled from '@emotion/styled'
import { TermsDataTable } from './terms-data-table/terms-data-table'

const Terms: FC = () => {
  return (
    <>
      <DisplayLargeWithMargin>Terms & Conditions</DisplayLargeWithMargin>
      <TermsDataTable />
    </>
  )
}

const DisplayLargeWithMargin = styled(DisplayLarge)({
  marginBottom: 24,
})

export { Terms }
