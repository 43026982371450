import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { FilterOptions } from '@helloextend/merchants-ui'
import { BadgeDescriptions, CellText } from '@helloextend/merchants-ui'
import type { Accessor, Cell, Column } from 'react-table'
import { date } from '@helloextend/client-utils'
import type { ClaimsUser, InsuranceClaim } from '@helloextend/extend-api-client'
import { ClaimSelectStatus } from '@helloextend/extend-api-client'
import { Badge } from '@helloextend/zen'
import { AssignUserDropdown } from '../../../components/assign-user-dropdown'
import { claimStatusDetails, getStatusBadgeDetails } from './claim-status'
import { DateAssignedDisplay } from './date-assigned-display'

interface ColumnProps {
  accessor?: string | Accessor<InsuranceClaim>
  headerTooltip?: string
  HeaderAdornment?: () => JSX.Element
  disableSortBy?: boolean
  width?: number
  Cell: (cell: Cell) => JSX.Element
}

export type InsuranceClaimRecord = InsuranceClaim & {
  [key: string]: unknown
}

interface ConfiguratorProps {
  onToggleAssigneeDropdown?: Dispatch<SetStateAction<boolean>>
}

const getTableColumns = (
  props: ConfiguratorProps = {},
): Array<ColumnProps & Column<InsuranceClaimRecord>> => {
  const { onToggleAssigneeDropdown } = props
  return [
    {
      Header: 'Claim Status',
      HeaderAdornment: (): JSX.Element => (
        <BadgeDescriptions descriptions={Object.values(claimStatusDetails)} />
      ),
      disableSortBy: true,
      width: 15,
      Cell: ({ row }: Cell): JSX.Element => {
        const badgeData = getStatusBadgeDetails(row.original as InsuranceClaim)
        return badgeData ? (
          <Badge
            text={badgeData.badgeText}
            emphasis="medium"
            color={badgeData.badgeColor}
            size="regular"
            details={badgeData.badgeDetails}
          />
        ) : (
          <div />
        )
      },
    },
    {
      Header: 'Customer Name',
      headerTooltip: 'Customer’s name',
      accessor: 'customer.name',
      disableSortBy: true,
      width: 15,
      Cell: ({ value }: Cell): JSX.Element => (
        <CellText data-cy="claim-customerName">{value}</CellText>
      ),
    },
    {
      Header: 'Customer Email',
      headerTooltip: 'Customer’s email address',
      accessor: 'customer.email',
      disableSortBy: true,
      width: 20,
      Cell: ({ value }: Cell): JSX.Element => (
        <CellText data-cy="claim-customerEmail">{value}</CellText>
      ),
    },
    {
      Header: 'Date Reported',
      headerTooltip: 'Date claim was filed',
      accessor: 'reportedAt',
      disableSortBy: false,
      width: 15,
      Cell: ({ value }: Cell): JSX.Element => (
        <CellText data-cy="claim-reportedAt">{date.format(value, 'DD MMM YYYY')}</CellText>
      ),
    },
    {
      Header: 'Claim Id',
      headerTooltip: 'Claim’s ID in Extend’s system',
      accessor: 'id',
      disableSortBy: true,
      width: 25,
      Cell: ({ value }: Cell): JSX.Element => <CellText data-cy="claim-id">{value}</CellText>,
    },
    {
      Header: 'Date Assigned',
      headerTooltip: 'Date claim was assigned',
      accessor: 'userAssignedAt',
      disableSortBy: false,
      width: 15,
      Cell: ({ row }: Cell): JSX.Element => {
        const claim = row.original as InsuranceClaim
        return <DateAssignedDisplay claimId={claim.id} />
      },
    },
    {
      Header: 'Assignee',
      headerTooltip: '',
      disableSortBy: true,
      width: 25,
      Cell: ({ row }: Cell): JSX.Element => {
        const claim = row.original as InsuranceClaim
        return (
          <AssigneeCell data-cy="assignedUser">
            <AssignUserDropdown claimId={claim?.id} onToggle={onToggleAssigneeDropdown} />
          </AssigneeCell>
        )
      },
    },
  ]
}

const AssigneeCell = styled.div({
  display: 'flex',
  flexDirection: 'row',
  fontSize: 12,
  lineHeight: '24px',
  overflow: 'hidden',
  marginBlockStart: '1em',
  marginBlockEnd: '1em',
  gap: 8,
})

const searchOptions = [
  { label: 'Customer Email', value: 'containsCustomerEmail' },
  {
    label: 'Contract ID',
    value: 'containsContractId',
  },
  { label: 'Claim ID', value: 'containsClaimId' },
  { label: 'Phone Number', value: 'containsCustomerPhone' },
  { label: 'Service Order ID', value: 'containsServiceOrderId' },
]

const getFilterOptions = (users: ClaimsUser[]): Record<string, FilterOptions> => ({
  status: {
    label: 'Claim Status',
    type: 'nestedCheckbox',
    filters: {
      [ClaimSelectStatus.approved]: {
        label: claimStatusDetails.approved.badgeText,
      },
      [ClaimSelectStatus.denied]: {
        label: claimStatusDetails.denied.badgeText,
      },
      [ClaimSelectStatus.fulfilled]: {
        label: claimStatusDetails.fulfilled.badgeText,
      },
      [ClaimSelectStatus.review]: {
        label: claimStatusDetails.review.badgeText,
      },
      [ClaimSelectStatus.closed]: {
        label: claimStatusDetails.closed.badgeText,
        options: {
          no_service: 'No service required',
        },
      },
      [ClaimSelectStatus.pending]: {
        label: claimStatusDetails.pending.badgeText,
        options: {
          ship_product: 'Ship defective product',
        },
      },
    },
  },
  ...(users.length && {
    assignee: {
      label: 'Assignee',
      type: 'nestedCheckbox',
      includeSearchBar: true,
      filters: users.reduce((obj, user) => {
        return {
          ...obj,
          [user.id]: {
            label: `${user.firstName} ${user.lastName}`,
          },
        }
      }, {}),
    },
  }),
  dates: {
    type: 'group',
    label: 'Dates',
    filters: {
      reportedAtDate: {
        type: 'dateRange',
        label: 'Reported Date',
      },
    },
  },
  type: {
    label: 'Claim Type',
    type: 'checkbox',
    options: {
      extended_warranty: 'Extended Warranty',
      shipping_protection: 'Shipping Protection',
    },
  },
  serviceType: {
    label: 'Service Type',
    type: 'checkbox',
    options: {
      replace: 'Replacement',
      repair_depot: 'Depot Repair',
      repair_home: 'Home Repair',
      repair_irobot: 'iRobot Repair',
      repair_onsite: 'Onsite Repair',
    },
  },
})

export { getTableColumns, searchOptions, getFilterOptions }
