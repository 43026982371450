import { combineReducers } from 'redux'
import type { ByIdReducerState } from './by-id'
import byId from './by-id'
import type { ByContractReducerState } from './by-contract'
import byContract from './by-contract'
import isCreating from './is-creating'
import isLoading from './is-loading'
import isUpdating from './is-updating'
import error from './error'
import newClaimId from './new-claim-id'
import type { ErrorReducerState } from '../../types/error'

export interface ReducerState {
  byId: ByIdReducerState
  byContract: ByContractReducerState
  error: ErrorReducerState
  isLoading: boolean
  isCreating: boolean
  isUpdating: boolean
  newClaimId: string | null
}

export default combineReducers({
  byId,
  byContract,
  isCreating,
  isLoading,
  isUpdating,
  error,
  newClaimId,
})
