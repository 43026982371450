import * as Yup from 'yup'
import { formatStartCase, formatCurrency } from '../../../util'

const dataFields = [
  { key: 'serviceOrder.serviceType', label: 'Service Type', transformFn: formatStartCase },
  {
    key: 'entitlements.coverageAmountRemaining',
    label: 'Remaining LOL.',
    transformFn: formatCurrency,
  },
  { key: 'contract.product.title', label: 'Item Requiring Fulfillment', columnCount: 2 },
  { key: 'contract.sellerName', label: 'Purchased From' },
  { key: 'contract.product.serialNumber', label: 'Serial Number' },
  { key: 'claim.incident.failureType', label: 'Failure Type', transformFn: formatStartCase },
  { key: 'claim.incident.description', label: 'Incident Description', columnCount: 3 },
]

const formSchema = Yup.object()
  .shape({
    checkedInAt: Yup.mixed<undefined | number>()
      .test({
        name: 'isValidDate',
        message: 'Required',
        test: (value) => value != null && value > 0,
      })
      .default(undefined),
  })
  .defined()

type Values = Yup.InferType<typeof formSchema>

export { dataFields, formSchema }
export type { Values }
