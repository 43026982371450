import type { ChangeEvent, FocusEvent, KeyboardEvent } from 'react'
import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../../tokens/colors'
import type { FieldProps } from '../field'
import { Field } from '../field'
import { Icon } from '../../icon'
import { ArrowDropDown } from '../../../tokens/icons'

export interface SelectProps
  extends Pick<
    FieldProps,
    | 'id'
    | 'label'
    | 'icon'
    | 'isDisabled'
    | 'isError'
    | 'errorFeedback'
    | 'subtext'
    | 'helperText'
    | 'data-cy'
  > {
  children: React.ReactNode
  placeholder?: string
  disablePlaceholder?: boolean
  ariaLabel?: string
  autoFocus?: boolean
  value: string
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
  onBlur?: (e: FocusEvent<HTMLSelectElement>) => void
  onFocus?: (e: FocusEvent<HTMLSelectElement>) => void
  onKeyPress?: (e: KeyboardEvent<HTMLSelectElement>) => void
}

/**
 * The Select component allows users pick a value from a list of predefined options. The children of a Select component
 * can be either `<option>` or `<optgroup>` and configured just like you would for a regular html `<Select>` element.
 */
export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      children,
      id,
      label,
      value,
      ariaLabel,
      icon,
      autoFocus,
      isDisabled = false,
      isError = false,
      errorFeedback,
      placeholder,
      disablePlaceholder = false,
      subtext,
      helperText,
      onChange = () => {},
      onBlur = () => {},
      onFocus = () => {},
      'data-cy': dataCy,
    },
    ref,
  ) => {
    return (
      <Field
        id={id}
        label={label}
        value={value}
        icon={icon}
        isDisabled={isDisabled}
        isError={isError}
        errorFeedback={errorFeedback}
        subtext={subtext}
        helperText={helperText}
        data-cy={dataCy}
        endAdornment={<Icon icon={ArrowDropDown} color={COLOR.NEUTRAL[800]} />}
      >
        <StyledSelect
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          aria-label={ariaLabel}
          id={id}
          disabled={isDisabled}
          placeholder={placeholder}
          autoFocus={autoFocus}
          hasIcon={!!icon}
          ref={ref}
        >
          {placeholder && (
            <option value="" disabled={disablePlaceholder}>
              &mdash;{placeholder}&mdash;
            </option>
          )}
          {children}
        </StyledSelect>
      </Field>
    )
  },
)

const StyledSelect = styled.select<{
  value: string
  disabled: boolean
  hasIcon: boolean
}>(({ value, disabled, hasIcon }) => ({
  appearance: 'none',
  flex: 1,
  border: 'none',
  borderRadius: 4,
  color: disabled || value === '' ? COLOR.NEUTRAL[600] : COLOR.NEUTRAL[1000],
  padding: `8px 40px 8px ${hasIcon ? '40px' : '12px'}`,
  width: '100%',
  boxSizing: 'border-box',
  fontFamily: '"Nunito Sans", sans-serif',
  fontSize: 16,
  lineHeight: '24px',
  outline: 'none',
  background: 'none',
  '& + label': {
    pointerEvents: 'none',
  },
  /* Override browser default margins */
  margin: `0 -28px 0 ${hasIcon ? '-28px' : '0'}`,
}))
