import type { FC, RefObject, SyntheticEvent, ClipboardEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'

type EventType = SyntheticEvent<HTMLInputElement>

interface TextInputProps {
  'data-cy'?: string
  disabled?: boolean
  id: string
  label: string | undefined
  onChange: (e: EventType) => void
  onBlur?: (e: EventType) => void
  placeholder?: string | undefined
  value: string
  className?: string
  width?: number
  inline?: boolean
  inputRef?: RefObject<HTMLInputElement> | null
  hasError?: boolean
  errorMessage?: string
  onPaste?: (e: ClipboardEvent<HTMLInputElement>) => void
  autoFocus?: boolean
}

/**
 * @deprecated Use Zen Input component instead: `import { Input } from '@helloextend/zen'`
 */
const TextInput: FC<TextInputProps> = ({
  'data-cy': dataCy,
  disabled,
  className,
  id,
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  width,
  inline,
  inputRef,
  hasError = false,
  errorMessage,
  onPaste,
  autoFocus,
}) => {
  return (
    <LabelWrapper htmlFor={id} width={width}>
      {label && <LabelText data-cy={`${dataCy}-label`}>{label}</LabelText>}
      <Input
        data-cy={dataCy}
        className={className}
        disabled={disabled || false}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        readOnly={disabled}
        type="text"
        value={value}
        inline={inline}
        ref={inputRef}
        hasError={hasError}
        onPaste={onPaste}
        autoFocus={autoFocus}
      />
      {hasError && <ErrorMessage data-cy={`${dataCy}-error`}>{errorMessage}</ErrorMessage>}
    </LabelWrapper>
  )
}

const LabelWrapper = styled.label<{ width?: number }>(({ width }) => ({
  fontFamily: 'Nunito Sans, sans-serif',
  width: width ?? 'inherit',
}))

const LabelText = styled.div({
  color: COLOR.VERY_DARK_BLUE_0,
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
})

const Input = styled.input<{ inline?: boolean; hasError: boolean }>(({ inline, hasError }) => ({
  border: `1px solid ${hasError ? COLOR.STATE_DANGER : COLOR.LIGHT_GRAYISH_BLUE_0}`,
  boxSizing: 'border-box',
  borderRadius: 4,
  fontFamily: 'inherit',
  fontSize: 16,
  height: 40,
  padding: '0 16px',
  width: 'inherit',
  ...(inline && {
    borderRadius: 0,
    borderRight: 'none',
    borderLeft: 'none',
  }),
  '&:disabled': {
    borderColor: COLOR.GHOST,
    background: COLOR.SNOW,
  },
}))

const ErrorMessage = styled.span({
  color: COLOR.STATE_DANGER,
  fontWeight: 700,
  lineHeight: '16px',
  fontSize: 12,
})

export { TextInput }
