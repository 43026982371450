import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import type { RuleStatus, Operator, NumericCondition } from '@helloextend/extend-api-rtk-query'
import type { AdvancedSelectOption, AdvancedSelectChangeEvent } from '@helloextend/zen'
import { AdvancedSelect } from '@helloextend/zen'
import { updateConditionOperator } from '../../../../../../store/slices/amp-slice'
import * as selectors from '../../../../../../reducers/selectors'
import type { RootState } from '../../../../../../reducers'

type OperatorSelectorProps = {
  rulesetType: RuleStatus
  ruleIndex: number
  conditionIndex: number
  isDisabled?: boolean
}

export const options: AdvancedSelectOption[] = [
  {
    display: 'Greater than',
    value: '>',
  },
  {
    display: 'Greater than or equal to',
    value: '>=',
  },
  {
    display: 'Equal to',
    value: '=',
  },
  {
    display: 'Less than',
    value: '<',
  },
  {
    display: 'Less than or equal to',
    value: '<=',
  },
]

const OperatorSelector: FC<OperatorSelectorProps> = ({
  rulesetType,
  ruleIndex,
  conditionIndex,
  isDisabled,
}) => {
  const dispatch = useDispatch()

  const condition = useSelector((state: RootState) =>
    selectors.getCondition({ state, rulesetType, ruleIndex, conditionIndex }),
  )

  const selectorValue = (condition as NumericCondition)?.operator || ''

  const handleChange = (e: AdvancedSelectChangeEvent): void => {
    const operator = e.target.value as Operator
    dispatch(updateConditionOperator({ rulesetType, ruleIndex, conditionIndex, value: operator }))
  }

  return (
    <Wrapper>
      <AdvancedSelect
        data-cy="claim-validation-operator-selector"
        id="claim-validation-operator-selector"
        onChange={handleChange}
        options={options}
        value={selectorValue}
        multiple={false}
        isDisabled={isDisabled}
        isNotClearable
      />
    </Wrapper>
  )
}

const Wrapper = styled.div({
  flex: '0 0 240px',
})

export { OperatorSelector }
