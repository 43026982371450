import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import isPropValid from '@emotion/is-prop-valid'
import { useThemeSafe } from '../../hooks'
import { COLOR } from '../../tokens/colors'
import { Icon, IconSize } from '../icon'
import type { ColorArgs, ButtonIconPosition, MeasurementArgs, LinkButtonProps } from './types'
import { getColors, getPadding, getMeasurements } from './utils'
import { OpenInNew } from '../../tokens/icons'
import { isStringUrl } from '../../utils'

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  (
    {
      ariaLabel,
      isBold = true,
      emphasis = 'high',
      size = 'regular',
      color = 'blue',
      fillContainer = false,
      icon,
      iconPosition = 'left',
      iconTransitionDurationMs,
      tooltip = '',
      isDisabled = false,
      isInverted = false,
      onClick,
      onMouseDown,
      hasReducedPadding = false,
      text,
      to,
      'data-cy': dataCy,
      tabIndex,
      openInNew = false,
    },
    ref,
  ) => {
    const {
      button: { borderRadius },
    } = useThemeSafe()
    const colors = getColors(color, isInverted, emphasis)
    const padding = getPadding(size, !!text, !!icon, false, iconPosition, hasReducedPadding)
    const measurements = getMeasurements(size)
    return (
      <StyledLinkButton
        ref={ref}
        isDisabled={isDisabled}
        aria-disabled={isDisabled || undefined}
        aria-label={ariaLabel || text}
        data-cy={dataCy}
        colors={colors}
        iconPosition={iconPosition}
        data-tooltip={tooltip}
        fillContainer={fillContainer}
        padding={padding}
        measurements={measurements}
        borderRadius={borderRadius}
        tabIndex={isDisabled ? -1 : tabIndex}
        onClick={isDisabled ? (e) => e.preventDefault() : onClick}
        onMouseDown={isDisabled ? (e) => e.preventDefault() : onMouseDown}
        to={isStringUrl(to) ? { pathname: to } : to}
        target={openInNew || isStringUrl(to) ? '_blank' : undefined}
        isBold={isBold}
      >
        {!!icon && (
          <Icon
            icon={icon}
            size={measurements.iconSize}
            color={!isDisabled ? colors.contentColor : colors.disabledContentColor}
            transitionDurationMs={iconTransitionDurationMs}
          />
        )}
        {text}
        {(openInNew || isStringUrl(to)) && (
          <Icon
            icon={OpenInNew}
            size={IconSize.small}
            color={!isDisabled ? colors.contentColor : colors.disabledContentColor}
            transitionDurationMs={iconTransitionDurationMs}
          />
        )}
      </StyledLinkButton>
    )
  },
)

const StyledLinkButton = styled(Link, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{
  colors: ColorArgs
  fillContainer: boolean
  iconPosition: ButtonIconPosition
  padding: string
  measurements: MeasurementArgs
  borderRadius: string
  isDisabled: boolean
  isBold: boolean
}>(
  ({
    colors,
    fillContainer,
    iconPosition,
    padding,
    measurements,
    borderRadius,
    isDisabled,
    isBold,
  }) => ({
    /* Base styles */
    position: 'relative',
    boxSizing: 'border-box',
    border: 'none',
    borderRadius,
    fontWeight: isBold ? 700 : 400,
    fontSize: measurements.fontSize,
    lineHeight: measurements.lineHeight,
    gap: measurements.gap,
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 120ms',
    alignSelf: 'flex-start',
    textDecoration: 'none',

    /* Fill container styles */
    display: fillContainer ? 'flex' : 'inline-flex',
    ...(iconPosition === 'right' && {
      flexDirection: 'row-reverse',
    }),
    width: fillContainer ? '100%' : undefined,

    /* Override browser default margins */
    margin: 0,

    /* Padding adjusted by presence and/or position of icon */
    padding,

    /* Color styles */
    background: colors.backgroundColor || 'none',
    color: colors.contentColor,
    '&:visited': {
      color: colors.contentColor,
    },

    /* Before psudo-elements used for medium emphasis border to prevent the need to change padding in base styles */
    ...(colors.borderColor && {
      '&::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        border: `1px solid ${isDisabled ? colors.disabledBorderColor : colors.borderColor}`,
        borderRadius: 'inherit',
      },
    }),

    /* After psudo-elements used for focus state style */
    '&::after': {
      content: '""',
      position: 'absolute',
      inset: -2,
      boxShadow: `0 0 4px 2px ${COLOR.BLUE[500]}`,
      borderRadius: 'inherit',
      opacity: 0,
      transition: 'opacity 120ms',
      pointerEvents: 'none',
    },

    /* Disabled styles */
    ...(isDisabled && {
      background: colors.disabledBackgroundColor || 'transparent',
      color: colors.disabledContentColor,
    }),

    /* State styles */
    ...(!isDisabled && {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
        background: colors.hoverBackgroundColor,
      },
      '&:active, &.active': {
        background: colors.activeBackgroundColor,
      },
      '&.active:hover': {
        background: colors.hoverBackgroundColor,
      },
      '&:focus': {
        outline: 'none',
      },
      '&:focus-visible': {
        outline: 'none',
        '&::after': {
          opacity: 1,
        },
      },
    }),
  }),
)
