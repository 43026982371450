import type { CategoryCsvValidatorPipe, CategoryHeader } from './category-csv-headers'

const BOOLEAN_COLUMNS: CategoryHeader[] = ['toDelete', 'shippable', 'shipEnabled']

const validateBooleanColumns = (meta: CategoryCsvValidatorPipe): CategoryCsvValidatorPipe => {
  BOOLEAN_COLUMNS.forEach((attr) => {
    const columnIndex = meta.headerIndexes[attr]
    const columnValue: string = meta.rowData[columnIndex]
    const upperCasedValue = columnValue.toUpperCase()

    if (columnIndex === undefined) {
      meta.errors.push(`"${attr}" column is missing`)
    } else if (
      upperCasedValue.length > 0 &&
      upperCasedValue !== 'TRUE' &&
      upperCasedValue !== 'FALSE'
    ) {
      meta.errors.push(
        `"${attr}" must be one of three values: ['TRUE', 'FALSE', '' (blank)] needs review (${columnValue})`,
      )
    }
  })

  return meta
}

export { validateBooleanColumns }
