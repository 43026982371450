import styled from '@emotion/styled'
import type { VFC } from 'react'
import React, { useEffect, useState } from 'react'
import { Button } from '../../button'
import type { MenuProps } from '../../menu'
import { Menu } from '../../menu'
import { useDataTableContext } from '../data-table-context'
import type { DateRangeFilterDef } from '../data-table-types'
import { FilterForm } from './filter-form'
import type { DateRangeValue } from '../../date-picker'
import { DateRangePicker } from '../../date-picker'

interface DateRangeFilterProps extends MenuProps {
  filterDef: DateRangeFilterDef
}

const oneDayLessOneMillisecond = 86399999

export const DateRangeFilter: VFC<DateRangeFilterProps> = ({
  direction,
  filterDef,
  triggerRenderer,
}) => {
  const { table } = useDataTableContext()

  const filterState = table.getState().columnFilters.find((filter) => filter.id === filterDef.id)

  const [dateRangeValue, setDateRangeValue] = useState<DateRangeValue>({})

  useEffect(() => {
    const hasValidFilterValue = Array.isArray(filterState?.value) && filterState?.value.length
    const filterValue: Array<number | null> = hasValidFilterValue
      ? (filterState?.value as Array<number | null>)
      : [null, null]
    setDateRangeValue({
      start: filterValue[0] && new Date(filterValue[0]),
      end: filterValue[1] && new Date(filterValue[1]),
    })
  }, [filterState])

  const handleDateRangeChange = (value: DateRangeValue): void => {
    setDateRangeValue(value)

    table
      .getColumn(filterDef.id)
      .setFilterValue([
        value.start?.valueOf(),
        value.end?.valueOf() ? value.end?.valueOf() + oneDayLessOneMillisecond : null,
      ])
  }

  return (
    <Menu
      padding={0}
      direction={direction}
      hasHeightAuto
      triggerRenderer={triggerRenderer}
      footer={
        <FilterForm>
          <Actions>
            <Button size="small" text="Done" type="submit" />
          </Actions>
        </FilterForm>
      }
    >
      <Content>
        <DateRangePicker
          value={dateRangeValue}
          minDate={filterDef.minDate}
          maxDate={filterDef.maxDate}
          hasDateDisplay
          predefinedRangeOptions={filterDef.predefinedRangeOptions}
          onChange={handleDateRangeChange}
        />
      </Content>
    </Menu>
  )
}

const Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
})

const Actions = styled.div({
  display: 'flex',
  flexDirection: 'row-reverse',
  padding: 12,
})
