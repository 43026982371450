export enum StackDirection {
  row = 'row',
  rowReverse = 'rowReverse',
  column = 'column',
  columnReverse = 'columnReverse',
}

export enum StackJustify {
  start = 'start',
  center = 'center',
  end = 'end',
  spaceBetween = 'spaceBetween',
}

export enum StackAlign {
  start = 'start',
  center = 'center',
  end = 'end',
  stretch = 'stretch',
  baseline = 'baseline',
}
