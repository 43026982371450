import { createApi } from '@reduxjs/toolkit/query/react'
import type { Offer } from '@helloextend/extend-api-client'
import { baseQuery } from '../base-query'

export const offersApi = createApi({
  baseQuery,
  // TODO: MEXP-614 update these paths once we remove redux from the merchants portal
  reducerPath: 'Offers',
  tagTypes: ['Offers'],
  endpoints: (build) => ({
    getOffers: build.query<Offer, { storeId?: string; productId?: string; version?: string }>({
      query: ({ storeId, productId, version = 'default' }) => ({
        url: '/offers',
        params: { storeId, productId },
        headers: {
          accept: `application/json; version=${version}`,
        },
      }),
      providesTags: (_, _err) => [{ type: 'Offers' }],
    }),
  }),
})

export const { useGetOffersQuery } = offersApi
