import styled from '@emotion/styled'
import type { BadgeColor, ButtonProps, Crumb } from '@helloextend/zen'
import { Badge, Breadcrumbs, Button, ButtonGroup, COLOR } from '@helloextend/zen'
import React from 'react'
import type { BadgeDescriptionItem } from '@helloextend/merchants-ui'
import { BadgeDescriptions } from '@helloextend/merchants-ui'
import { v4 } from 'uuid'
import type { TabBarLinkDefinition } from '../../../../../components/tab-bar'
import { TabBar } from '../../../../../components/tab-bar'

interface ContractHeaderProps {
  children?: React.ReactNode
  pageTitle: string
  pageTitleAside?: React.ReactNode
  buttons?: ButtonProps[]
  crumbs?: Crumb[]
  pageTitleBadge?: {
    badgeText: string
    badgeColor: BadgeColor
    badgeDescriptions: BadgeDescriptionItem[]
  }
  tabBarLinks?: TabBarLinkDefinition[]
  'data-cy'?: string
}

const ContractHeader = React.forwardRef<HTMLDivElement, ContractHeaderProps>(
  (
    {
      children,
      pageTitle,
      pageTitleAside,
      buttons,
      crumbs,
      pageTitleBadge,
      tabBarLinks,
      'data-cy': dataCy,
    },
    ref,
  ) => {
    return (
      <>
        {crumbs && crumbs.length > 0 && (
          <Breadcrumbs data-cy={`${dataCy}:breadcrumbs`} crumbs={crumbs} />
        )}
        <Row ref={ref}>
          {pageTitleAside && <TitleAside>{pageTitleAside}</TitleAside>}
          <GrowCol data-cy={`${dataCy}:header-section`}>
            <Heading data-cy={`${dataCy}:title`}>
              {pageTitle}
              {!!pageTitleBadge?.badgeText && pageTitleBadge.badgeDescriptions.length > 0 && (
                <BadgeContainer data-cy={`${dataCy}:badge-container`}>
                  <Badge text={pageTitleBadge.badgeText} color={pageTitleBadge.badgeColor} />
                  <DescriptionsContainer>
                    <BadgeDescriptions
                      data-cy={`${dataCy}:badge-descriptions`}
                      descriptions={pageTitleBadge.badgeDescriptions}
                      buttonSize="small"
                    />
                  </DescriptionsContainer>
                </BadgeContainer>
              )}
            </Heading>
            {children}
          </GrowCol>
          {buttons && buttons.length > 0 && (
            <ButtonGroup data-cy={`${dataCy}:buttons`}>
              {buttons.map((b) => (
                <Button key={`header-button-${v4()}`} {...b} />
              ))}
            </ButtonGroup>
          )}
        </Row>
        {tabBarLinks && tabBarLinks.length > 0 && (
          <TabBar data-cy="tab-bar" tabBarLinks={tabBarLinks} />
        )}
      </>
    )
  },
)

const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 32,
})

const GrowCol = styled.div({
  flexGrow: 1,
})

const TitleAside = styled.div({
  marginTop: 8,
  width: 172,
  height: 172,
  flexGrow: 0,
})

const BadgeContainer = styled.div({
  display: 'inline-flex',
  position: 'absolute',
  marginLeft: 16,
  alignItems: 'center',
})

const DescriptionsContainer = styled.div({
  paddingLeft: 4,
})

const Heading = styled.h1({
  color: COLOR.NEUTRAL[1000],
  fontSize: 24,
  lineHeight: '32px',
  marginTop: 0,
  marginBottom: 16,
  padding: 0,
})

export { ContractHeader, ContractHeaderProps }
