import { useState, useCallback } from 'react'

interface UseToggleHandlers {
  on: () => void
  off: () => void
  toggle: () => void
  reset: () => void
}

type UseToggle = [boolean, UseToggleHandlers]

export const useToggle = (initialState = false): UseToggle => {
  const [state, setState] = useState(initialState)

  const on = useCallback(() => {
    setState(true)
  }, [])

  const off = useCallback(() => {
    setState(false)
  }, [])

  const toggle = useCallback(() => {
    setState((s) => !s)
  }, [])

  const reset = useCallback(() => {
    setState(initialState)
  }, [initialState])

  const handlers: UseToggleHandlers = {
    on,
    off,
    toggle,
    reset,
  }

  return [state, handlers]
}
