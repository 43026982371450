import {
  authSelectors,
  plansSelectors,
  contractsSelectors,
  productsSelectors,
} from '@helloextend/core-api-redux'
import type { Store, StoreUserRole } from '@helloextend/extend-api-client'
import type { RuleStatus } from '@helloextend/extend-api-rtk-query'
import {
  getThread,
  getIsThreadTextAssigned,
  getIsEditorPanelVisible,
  getConversation,
  getSingleUseThreads,
  getMessageBlockIndex,
  getIsReusableThreadPickerVisible,
  getSelectedMessageBlock,
  getThreadIdToReplace,
  getPlaceholderMessageBlockIndex,
  getIsPublishValidationModeActive as isPublishValidationModeActive,
  getMessageBlockReferenceMap,
  getIsDuplicateThreadModalVisible as isDuplicateModalVisible,
  getSelectedThreadListItem as getThreadListItem,
  getConversationAdjudicationThread,
  getRuleset as getThreadRuleset,
  getCondition as getRuleCondition,
  getRule as getThreadRule,
  getAvailableMessageBlocksForRule as getThreadAvailableMessageBlocksForRule,
  getHasValidConditions as getRulesetHasValidConditions,
} from '../store/slices/amp-slice'
import type { RootState } from '.'
import * as fromUsers from './user/selectors'
import * as fromStores from './stores/selectors'
import * as fromSupport from './support/selectors'
import * as fromRouter from './router/selectors'
import * as fromServicers from './servicers/selectors'
import { getUserRoleFromToken } from '../lib/jwt'
import { getClaimsUrl } from '../store/slices/claim-breadcrumb'
import { getClaimDetailsActiveView, getClaimDetailsToast } from '../store/slices/claim-details'

// auth
// ==================================================================================
export function getAccessToken(state: RootState): ReturnType<typeof authSelectors.getAccessToken> {
  return authSelectors.getAccessToken(state.auth)
}

export function getAuthError(state: RootState): ReturnType<typeof authSelectors.getError> {
  return authSelectors.getError(state.auth)
}

export function getIsAuthLoading(state: RootState): ReturnType<typeof authSelectors.getIsLoading> {
  return authSelectors.getIsLoading(state.auth)
}

export function getIsLoggedIn(
  state: RootState,
  isOktaLogin = false,
): ReturnType<typeof authSelectors.getIsLoggedIn> {
  return authSelectors.getIsLoggedIn(state.auth, isOktaLogin)
}

export function getRoleFromToken(state: RootState): StoreUserRole | null {
  return getUserRoleFromToken(getAccessToken(state))
}

export function getChangePasswordMessage(
  state: RootState,
): ReturnType<typeof authSelectors.getChangePasswordMessage> {
  return authSelectors.getChangePasswordMessage(state.auth)
}

export function getLoginRedirectPath(state: RootState): string | null {
  return fromRouter.getLoginRedirectPath(state.router)
}

// user
// ==================================================================================
export function getUserFirstName(state: RootState): ReturnType<typeof fromUsers.getUserFirstName> {
  return fromUsers.getUserFirstName(state.user)
}

export function getUserLastName(state: RootState): ReturnType<typeof fromUsers.getUserLastName> {
  return fromUsers.getUserLastName(state.user)
}

export function getUserId(state: RootState): ReturnType<typeof fromUsers.getUserId> {
  return fromUsers.getUserId(state.user)
}

export function getIsUserLoading(state: RootState): ReturnType<typeof fromUsers.getIsUserLoading> {
  return fromUsers.getIsUserLoading(state.user)
}

export function getProfileSuccessMessage(
  state: RootState,
): ReturnType<typeof fromUsers.getProfileSuccessMessage> {
  return fromUsers.getProfileSuccessMessage(state.user)
}

export function getUserInformation(state: RootState): ReturnType<typeof fromUsers.getUserProfile> {
  return fromUsers.getUserProfile(state.user)
}

// store
// ==================================================================================
export function getStoreById(state: RootState, storeId?: string): Store | null {
  return fromStores.getById(state.stores, storeId)
}

export function getStoreErrors(state: RootState): ReturnType<typeof fromStores.getError> {
  return fromStores.getError(state.stores)
}

// contracts
// ==================================================================================
export function getContractById(
  state: RootState,
  contractId: string,
): ReturnType<typeof contractsSelectors.getById> {
  return contractsSelectors.getById(state.contracts, contractId)
}

export function getIsContractLoading(
  state: RootState,
): ReturnType<typeof contractsSelectors.getIsLoading> {
  return contractsSelectors.getIsLoading(state.contracts)
}

export function getIsContractSearching(
  state: RootState,
): ReturnType<typeof contractsSelectors.getIsSearching> {
  return contractsSelectors.getIsSearching(state.contracts)
}

export function getContractsError(
  state: RootState,
): ReturnType<typeof contractsSelectors.getError> {
  return contractsSelectors.getError(state.contracts)
}

export function getContractsSearchItems(
  state: RootState,
): ReturnType<typeof contractsSelectors.getContractsSearchItems> {
  return contractsSelectors.getContractsSearchItems(state.contracts)
}

export function getContractsSearchItemsCount(
  state: RootState,
): ReturnType<typeof contractsSelectors.getContractsSearchItemsCount> {
  return contractsSelectors.getContractsSearchItemsCount(state.contracts)
}

export function getContractsHasNextPage(
  state: RootState,
): ReturnType<typeof contractsSelectors.hasNextPage> {
  return contractsSelectors.hasNextPage(state.contracts)
}

export function getContractsHasPrevPage(
  state: RootState,
): ReturnType<typeof contractsSelectors.hasPrevPage> {
  return contractsSelectors.hasPrevPage(state.contracts)
}

export function getContractsPage(
  state: RootState,
): ReturnType<typeof contractsSelectors.getContractsPage> {
  return contractsSelectors.getContractsPage(state.contracts)
}

export function getContractAuditHistory(
  state: RootState,
): ReturnType<typeof contractsSelectors.getAuditHistory> {
  return contractsSelectors.getAuditHistory(state.contracts)
}

export function getContractAuditHistoryCursor(
  state: RootState,
): ReturnType<typeof contractsSelectors.getAuditHistoryCursor> {
  return contractsSelectors.getAuditHistoryCursor(state.contracts)
}

export function getHasContractAuditHistoryPrevPage(
  state: RootState,
): ReturnType<typeof contractsSelectors.hasPrevPageAuditHistory> {
  return contractsSelectors.hasPrevPageAuditHistory(state.contracts)
}

export function getContractAuditHistoryLastFetchedPage(
  state: RootState,
): ReturnType<typeof contractsSelectors.getLastFetchedPageAuditHistory> {
  return contractsSelectors.getLastFetchedPageAuditHistory(state.contracts)
}

export function getContractAuditHistoryCurrentPage(
  state: RootState,
): ReturnType<typeof contractsSelectors.getAuditHistoryCurrentPage> {
  return contractsSelectors.getAuditHistoryCurrentPage(state.contracts)
}

export function getIsContractUpdating(
  state: RootState,
): ReturnType<typeof contractsSelectors.getIsUpdating> {
  return contractsSelectors.getIsUpdating(state.contracts)
}

export function getIsContractUpdateSuccess(
  state: RootState,
): ReturnType<typeof contractsSelectors.getIsSuccess> {
  return contractsSelectors.getIsSuccess(state.contracts)
}

export function getContractSearchTerm(
  state: RootState,
): ReturnType<typeof contractsSelectors.getSearchTerm> {
  return contractsSelectors.getSearchTerm(state.contracts)
}

export function getContractSearchKey(
  state: RootState,
): ReturnType<typeof contractsSelectors.getSearchKey> {
  return contractsSelectors.getSearchKey(state.contracts)
}

// plans
// ==================================================================================
export function getIsPlansLoading(
  state: RootState,
): ReturnType<typeof plansSelectors.getIsLoading> {
  return plansSelectors.getIsLoading(state.plans)
}

export function getIsPlansSuccess(
  state: RootState,
): ReturnType<typeof plansSelectors.getPlansSuccess> {
  return plansSelectors.getPlansSuccess(state.plans)
}

export function getPlansError(state: RootState): ReturnType<typeof plansSelectors.getError> {
  return plansSelectors.getError(state.plans)
}

export function getPlansSearchFilter(
  state: RootState,
): ReturnType<typeof plansSelectors.getPlansSearchFilter> {
  return plansSelectors.getPlansSearchFilter(state.plans)
}

export function getPlansSearchItems(
  state: RootState,
): ReturnType<typeof plansSelectors.getPlansSearchItems> {
  return plansSelectors.getPlansSearchItems(state.plans)
}

export function getPlansSearchCursor(
  state: RootState,
): ReturnType<typeof plansSelectors.getPlansSearchCursor> {
  return plansSelectors.getPlansSearchCursor(state.plans)
}

export function getPlansSearchPage(
  state: RootState,
): ReturnType<typeof plansSelectors.getPlansPage> {
  return plansSelectors.getPlansPage(state.plans)
}

export function getPlansLastFetchedPage(
  state: RootState,
): ReturnType<typeof plansSelectors.getPlansLastFetchedPage> {
  return plansSelectors.getPlansLastFetchedPage(state.plans)
}

export function getHasPlansPrevPage(
  state: RootState,
): ReturnType<typeof plansSelectors.hasPrevPage> {
  return plansSelectors.hasPrevPage(state.plans)
}

export function getIsPlansCreating(
  state: RootState,
): ReturnType<typeof plansSelectors.getIsCreating> {
  return plansSelectors.getIsCreating(state.plans)
}

export function getPlanById(
  state: RootState,
  planId: string,
): ReturnType<typeof plansSelectors.getById> {
  return plansSelectors.getById(state.plans, planId)
}

// support
// ==================================================================================
export function getIsSupportLoading(state: RootState): ReturnType<typeof fromSupport.getIsLoading> {
  return fromSupport.getIsLoading(state.support)
}

export function getSupportStatus(state: RootState): ReturnType<typeof fromSupport.getStatus> {
  return fromSupport.getStatus(state.support)
}

export function getSupportIsVisible(state: RootState): ReturnType<typeof fromSupport.getIsVisible> {
  return fromSupport.getIsVisible(state.support)
}

// servicers
// ===============================================================================
export function getServicersSearchFilter(
  state: RootState,
): ReturnType<typeof fromServicers.getSearchFilter> {
  return fromServicers.getSearchFilter(state.servicersLocal)
}

export function getServicersSearchDropdownFilter(
  state: RootState,
): ReturnType<typeof fromServicers.getSearchDropdownFilter> {
  return fromServicers.getSearchDropdownFilter(state.servicersLocal)
}

// claims
// ===============================================================================
export function getClaimBreadcrumbUrl(state: RootState): ReturnType<typeof getClaimsUrl> {
  return getClaimsUrl(state.claimBreadcrumbs)
}

export function getActiveClaimDetailsView(
  state: RootState,
): ReturnType<typeof getClaimDetailsActiveView> {
  return getClaimDetailsActiveView(state.claimDetails)
}

export function getClaimDetailsToastMessage(
  state: RootState,
): ReturnType<typeof getClaimDetailsToast> {
  return getClaimDetailsToast(state.claimDetails)
}

// AMP
// ===============================================================================
export function getSelectedThread(state: RootState): ReturnType<typeof getThread> {
  return getThread(state.amp)
}

export function getIsSelectedThreadTextAssigned(
  state: RootState,
): ReturnType<typeof getIsThreadTextAssigned> {
  return getIsThreadTextAssigned(state.amp)
}

export function getSelectedThreadMessageBlock(
  state: RootState,
): ReturnType<typeof getSelectedMessageBlock> {
  return getSelectedMessageBlock(state.amp)
}

export function getEditorVisibility(state: RootState): ReturnType<typeof getIsEditorPanelVisible> {
  return getIsEditorPanelVisible(state.amp)
}

export function getReusableThreadPickerVisibility(
  state: RootState,
): ReturnType<typeof getIsReusableThreadPickerVisible> {
  return getIsReusableThreadPickerVisible(state.amp)
}

export function getSelectedConversation(state: RootState): ReturnType<typeof getConversation> {
  return getConversation(state.amp)
}

export function getSingleUseThreadsMap(state: RootState): ReturnType<typeof getSingleUseThreads> {
  return getSingleUseThreads(state.amp)
}

export function getSelectedMessageBlockIndex(
  state: RootState,
): ReturnType<typeof getMessageBlockIndex> {
  return getMessageBlockIndex(state.amp)
}

export function getCurrentPlaceholderMessageBlockIndex(
  state: RootState,
): ReturnType<typeof getPlaceholderMessageBlockIndex> {
  return getPlaceholderMessageBlockIndex(state.amp)
}

export function getSelectedThreadIdToReplace(
  state: RootState,
): ReturnType<typeof getThreadIdToReplace> {
  return getThreadIdToReplace(state.amp)
}

export function getCurrentMessageBlockReferenceMap(
  state: RootState,
): ReturnType<typeof getMessageBlockReferenceMap> {
  return getMessageBlockReferenceMap(state.amp)
}

export function getIsPublishValidationModeActive(
  state: RootState,
): ReturnType<typeof isPublishValidationModeActive> {
  return isPublishValidationModeActive(state.amp)
}

export function getIsDuplicateThreadModalVisible(
  state: RootState,
): ReturnType<typeof isDuplicateModalVisible> {
  return isDuplicateModalVisible(state.amp)
}

export function getSelectedThreadListItem(state: RootState): ReturnType<typeof getThreadListItem> {
  return getThreadListItem(state.amp)
}

export function getCurrentAdjudicationThread(
  state: RootState,
): ReturnType<typeof getConversationAdjudicationThread> {
  return getConversationAdjudicationThread(state.amp)
}

export function getRuleset(state: RootState): ReturnType<typeof getThreadRuleset> {
  return getThreadRuleset(state.amp)
}

export function getRule({
  state,
  rulesetType,
  ruleIndex,
}: {
  state: RootState
  rulesetType: RuleStatus
  ruleIndex: number
}): ReturnType<typeof getThreadRule> {
  return getThreadRule({ state: state.amp, rulesetType, ruleIndex })
}

export function getCondition({
  state,
  rulesetType,
  ruleIndex,
  conditionIndex,
}: {
  state: RootState
  rulesetType: RuleStatus
  ruleIndex: number
  conditionIndex: number
}): ReturnType<typeof getRuleCondition> {
  return getRuleCondition({ state: state.amp, rulesetType, ruleIndex, conditionIndex })
}

export function getAvailableMessageBlocksForRule({
  state,
  source,
  rulesetType,
  ruleIndex,
  conditionIndex,
}: {
  state: RootState
  source: 'conversation' | 'thread'
  rulesetType: RuleStatus
  ruleIndex: number
  conditionIndex: number
}): ReturnType<typeof getThreadAvailableMessageBlocksForRule> {
  return getThreadAvailableMessageBlocksForRule({
    state: state.amp,
    source,
    rulesetType,
    ruleIndex,
    conditionIndex,
  })
}

export function getHasValidConditions(
  state: RootState,
): ReturnType<typeof getRulesetHasValidConditions> {
  return getRulesetHasValidConditions(state.amp)
}

// Products
export function getProductById(
  state: RootState,
  storeId: string,
  referenceId: string,
): ReturnType<typeof productsSelectors.getById> {
  return productsSelectors.getById(state.products, storeId, referenceId)
}

export function getProductsError(state: RootState): ReturnType<typeof productsSelectors.getError> {
  return productsSelectors.getError(state.products)
}
