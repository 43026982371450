import type { FC } from 'react'
import React from 'react'
import sylted from '@emotion/styled'
import type { AdvancedSelectChangeEvent, AdvancedSelectOption } from '@helloextend/zen'
import { AdvancedSelect } from '@helloextend/zen'
import { useDispatch, useSelector } from 'react-redux'
import type {
  NumericConditionWithScript,
  NumericInvokableFunction,
  RuleStatus,
} from '@helloextend/extend-api-rtk-query'
import { updateComparandValue } from '../../../../../../store/slices/amp-slice'
import type { RootState } from '../../../../../../reducers'
import * as selectors from '../../../../../../reducers/selectors'

type ComparandSelectorProps = {
  rulesetType: RuleStatus
  ruleIndex: number
  conditionIndex: number
  isDisabled?: boolean
}

export const options: AdvancedSelectOption[] = [
  {
    display: 'Contract started',
    value: 'contractTransactionDate',
  },
  {
    display: 'Contract ended',
    value: 'contractEndDate',
  },
  {
    display: 'Product is delivered',
    value: 'productDeliveryDate',
  },
]

const ComparandSelector: FC<ComparandSelectorProps> = ({
  rulesetType,
  ruleIndex,
  conditionIndex,
  isDisabled,
}) => {
  const dispatch = useDispatch()
  const condition = useSelector((state: RootState) =>
    selectors.getCondition({ state, rulesetType, ruleIndex, conditionIndex }),
  )

  const selectorValue = (condition as NumericConditionWithScript).comparand || ''

  const handleChange = (e: AdvancedSelectChangeEvent): void => {
    const { value } = e.target as { value: NumericInvokableFunction }

    dispatch(
      updateComparandValue({
        rulesetType,
        ruleIndex,
        conditionIndex,
        value,
      }),
    )
  }
  return (
    <ComparandSelectorWrapper>
      <AdvancedSelect
        data-cy="claim-validation-comparand-selector"
        id="claim-validation-comparand-selector"
        onChange={handleChange}
        options={options}
        value={selectorValue}
        multiple={false}
        isDisabled={isDisabled}
        isNotClearable
      />
    </ComparandSelectorWrapper>
  )
}

const ComparandSelectorWrapper = sylted.div({
  width: 212,
})

export { ComparandSelector }
