import { faker } from '@faker-js/faker/locale/en'
import type { Theme, ButtonRadiusMeasurements } from '@helloextend/extend-api-rtk-query'
import { ThemePublishedStatus } from '@helloextend/extend-api-rtk-query'

const storeId = faker.datatype.uuid()

// Packages can not have dependencies from apps, so we need to duplicate the type here from merchants
interface ThemeGlobalProperties {
  storeLogoUrl: string
  fontFamily: string
  color: string
  backgroundColor: string
  buttonBackgroundColor: string
  buttonFontColor: string
  buttonRadius: {
    size: number
    measurement: ButtonRadiusMeasurements
  }
}

export function generateMockThemesWithPublished(): Theme[] {
  return [
    {
      name: 'Cool Theme 2',
      createdAt: Date.parse('21 Nov 2021 13:23:00 EST'),
      updatedAt: Date.parse('22 Nov 2021 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: 'COOLTHEME2',
      storeId,
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 12, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Palatino Font',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Nighthawk Volcano v1.32',
      createdAt: Date.parse('20 Nov 2021 13:23:00 EST'),
      updatedAt: Date.parse('20 Nov 2021 13:23:00 EST'),
      status: ThemePublishedStatus.published,
      themeId: 'NIGHTHAWKVOLCANOV132',
      storeId,
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 12, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Bookman',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Summer 2022 Final',
      createdAt: Date.parse('17 Sep 2021 13:23:00 EST'),
      updatedAt: Date.parse('22 Nov 2021 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: '3',
      storeId,
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#D3D3D3',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 14, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Palatino Font',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Another Theme',
      createdAt: Date.parse('10 Dec 2021 13:23:00 EST'),
      updatedAt: Date.parse('20 Apr 2022 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: 'ANOTHERTHEME',
      storeId,
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#DDDDDD',
          buttonBackgroundColor: '#5623DF',
          buttonRadius: { size: 16, measurement: 'px' },
          buttonFontColor: '#4287F5',
          fontFamily: 'Courier New',
          color: '#AC3BEF',
        },
      },
    },
    {
      name: 'Offers #19587',
      createdAt: Date.parse('07 Feb 2022 13:23:00 EST'),
      updatedAt: Date.parse('20 Feb 2021 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: '5',
      storeId,
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 14, measurement: 'px' },
          buttonFontColor: '#5C52CA',
          fontFamily: 'Courier New',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Another Theme 2',
      createdAt: Date.parse('03 Jan 2022 13:23:00 EST'),
      updatedAt: Date.parse('20 Feb 2022 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: 'ANOTHERTHEME2',
      storeId,
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 12, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Palatino Font',
          color: '#E2DF17',
        },
      },
    },
  ]
}

export function generateMockThemesWithNoPublished(): Theme[] {
  return [
    {
      name: 'Cool Theme 2',
      storeId,
      createdAt: Date.parse('21 Nov 2021 13:23:00 EST'),
      updatedAt: Date.parse('22 Nov 2021 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: 'COOLTHEME2',
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 12, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Palatino Font',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Nighthawk Volcano v1.32',
      storeId,
      createdAt: Date.parse('20 Nov 2021 13:23:00 EST'),
      updatedAt: Date.parse('20 Nov 2021 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: 'NIGHTHAWKVOLCANOV132',
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 12, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Bookman',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Summer 2022 Final',
      storeId,
      createdAt: Date.parse('17 Sep 2021 13:23:00 EST'),
      updatedAt: Date.parse('22 Nov 2021 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: '3',
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 12, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Palatino Font',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Another Theme',
      storeId,
      createdAt: Date.parse('10 Dec 2021 13:23:00 EST'),
      updatedAt: Date.parse('20 Apr 2022 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: 'ANOTHERTHEME',
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 12, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Palatino Font',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Offers #19587',
      storeId,
      createdAt: Date.parse('07 Feb 2022 13:23:00 EST'),
      updatedAt: Date.parse('20 Feb 2021 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: 'OFFERS#19587',
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 14, measurement: 'px' },
          buttonFontColor: '#5C52CA',
          fontFamily: 'Courier New',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Another Theme 2',
      storeId,
      createdAt: Date.parse('03 Jan 2022 13:23:00 EST'),
      updatedAt: Date.parse('20 Feb 2022 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: 'ANOTHERTHEME2',
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 12, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Palatino Font',
          color: '#E2DF17',
        },
      },
    },
  ]
}

export function generateMockThemesWithNoPublishedAfterDelete(): Theme[] {
  return [
    {
      name: 'Cool Theme 2',
      storeId,
      createdAt: Date.parse('21 Nov 2021 13:23:00 EST'),
      updatedAt: Date.parse('22 Nov 2021 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: 'COOLTHEME2',
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 12, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Palatino Font',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Nighthawk Volcano v1.32',
      storeId,
      createdAt: Date.parse('20 Nov 2021 13:23:00 EST'),
      updatedAt: Date.parse('20 Nov 2021 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: '2',
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 12, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Bookman',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Summer 2022 Final',
      storeId,
      createdAt: Date.parse('17 Sep 2021 13:23:00 EST'),
      updatedAt: Date.parse('22 Nov 2021 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: '3',
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 12, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Palatino Font',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Offers #19587',
      storeId,
      createdAt: Date.parse('07 Feb 2022 13:23:00 EST'),
      updatedAt: Date.parse('20 Feb 2021 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: 'OFFERS#19587',
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 14, measurement: 'px' },
          buttonFontColor: '#5C52CA',
          fontFamily: 'Courier New',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Another Theme 2',
      storeId,
      createdAt: Date.parse('03 Jan 2022 13:23:00 EST'),
      updatedAt: Date.parse('20 Feb 2022 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: 'ANOTHERTHEME2',
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 12, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Palatino Font',
          color: '#E2DF17',
        },
      },
    },
  ]
}

export function generateMockThemesWithPublishedRemovedAfterDelete(): Theme[] {
  return [
    {
      name: 'Cool Theme 2',
      storeId,
      createdAt: Date.parse('21 Nov 2021 13:23:00 EST'),
      updatedAt: Date.parse('22 Nov 2021 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: 'COOLTHEME2',
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 12, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Palatino Font',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Nighthawk Volcano v1.32',
      storeId,
      createdAt: Date.parse('20 Nov 2021 13:23:00 EST'),
      updatedAt: Date.parse('20 Nov 2021 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: '2',
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 12, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Bookman',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Summer 2022 Final',
      storeId,
      createdAt: Date.parse('17 Sep 2021 13:23:00 EST'),
      updatedAt: Date.parse('22 Nov 2021 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: '3',
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 12, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Palatino Font',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Offers #19587',
      storeId,
      createdAt: Date.parse('07 Feb 2022 13:23:00 EST'),
      updatedAt: Date.parse('20 Feb 2021 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: 'OFFERS#19587',
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 14, measurement: 'px' },
          buttonFontColor: '#5C52CA',
          fontFamily: 'Courier New',
          color: '#E2DF17',
        },
      },
    },
    {
      name: 'Another Theme 2',
      storeId,
      createdAt: Date.parse('03 Jan 2022 13:23:00 EST'),
      updatedAt: Date.parse('20 Feb 2022 13:23:00 EST'),
      status: ThemePublishedStatus.unpublished,
      themeId: 'ANOTHERTHEME2',
      contents: {
        global: {
          storeLogoUrl: faker.internet.url(),
          backgroundColor: '#9E3434',
          buttonBackgroundColor: '#368860',
          buttonRadius: { size: 12, measurement: 'px' },
          buttonFontColor: '#5C52CF',
          fontFamily: 'Palatino Font',
          color: '#E2DF17',
        },
      },
    },
  ]
}

export function getMockThemeByIdFromPublished(themeId: string): Theme {
  const defaultTheme = generateMockThemesWithPublished()[0]
  const foundTheme: Theme | undefined = generateMockThemesWithPublished().find(
    (theme) => theme.themeId === themeId,
  )
  return foundTheme ?? defaultTheme
}

export function getMockThemeByIdFromNoPublished(themeId: string): Theme {
  const defaultTheme = generateMockThemesWithNoPublished()[0]
  const foundTheme: Theme | undefined = generateMockThemesWithNoPublished().find(
    (theme) => theme.themeId === themeId,
  )
  return foundTheme ?? defaultTheme
}

export const generateMockDraftTheme = (
  overrides?: Partial<ThemeGlobalProperties>,
): ThemeGlobalProperties => ({
  storeLogoUrl: faker.image.imageUrl(),
  fontFamily: faker.helpers.arrayElement(['Calibri', 'Courier', 'Impact']),
  color: faker.color.rgb({ format: 'hex', casing: 'lower' }),
  backgroundColor: faker.color.rgb({ format: 'hex', casing: 'lower' }),
  buttonBackgroundColor: faker.color.rgb({ format: 'hex', casing: 'lower' }),
  buttonFontColor: faker.color.rgb({ format: 'hex', casing: 'lower' }),
  buttonRadius: {
    size: faker.datatype.number(50),
    measurement: faker.helpers.arrayElement(['px', 'rem']),
  },
  ...overrides,
})
