import type { PlanTermsType } from '@helloextend/extend-api-client'
import type { ReducerState } from './reducers'
import type { PlanTermsErrorReducerState } from './reducers/error'

type Selector<T> = (state: ReducerState, ...rest: any[]) => T

export const getAll: Selector<PlanTermsType[]> = (state) => Object.values(state.byId)

export const getDownloadUrl: Selector<string> = (state) => state.downloadUrl

export const getIsImporting = (state: ReducerState): boolean => state.isImporting

export const getErrors = (state: ReducerState): PlanTermsErrorReducerState => state.error
