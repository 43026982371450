import React from 'react'
import type { FC } from 'react'
import { useDeletePlanSetMappingsMutation } from '@helloextend/extend-api-rtk-query'
import { Modal, useToaster, ToastColor, ToastDuration, ModalController } from '@helloextend/zen'
import styled from '@emotion/styled'

type DeletePlanSetModalProps = {
  storeId: string
  storePlanSetMappingId: string
  planSetMappingName: string
  isModalOpen: boolean
  toggleModalOff: () => void
}

const RemovePlanSetModal: FC<DeletePlanSetModalProps> = ({
  toggleModalOff,
  storeId,
  storePlanSetMappingId,
  planSetMappingName,
  isModalOpen,
}) => {
  const { toast } = useToaster()

  const [deletePlanSetMappings, { isLoading: isDeleting }] = useDeletePlanSetMappingsMutation()
  const handleDeleteConfirm = async (): Promise<void> => {
    try {
      await deletePlanSetMappings({
        storeId,
        storePlanSetMappingId,
      }).unwrap()
      toast({
        message: 'The plan set has been unassigned successfully.',
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
    } catch {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    } finally {
      toggleModalOff()
    }
  }

  return (
    <ModalController isOpen={isModalOpen}>
      <Modal
        data-cy="delete-plan-set-modal"
        heading="Remove plan set from the store"
        onDismissRequest={toggleModalOff}
        primaryButtonProps={{
          onClick: handleDeleteConfirm,
          text: 'Delete',
          color: 'red',
          isDisabled: isDeleting,
          isProcessing: isDeleting,
          'data-cy': 'delete-plan-set-modal-delete-button',
        }}
        secondaryButtonProps={{
          onClick: toggleModalOff,
          text: 'Cancel',
          color: 'blue',
          isDisabled: isDeleting,
          'data-cy': 'delete-plan-set-modal-cancel-button',
        }}
      >
        <>
          <p data-cy="modal-text">
            This plan set will no longer be available for any of the products in the store.{' '}
          </p>
          <BoldSpan>{planSetMappingName}</BoldSpan> is currently mapped.
          <p>Are you sure you want to remove the plan assignment from the store?</p>
        </>
      </Modal>
    </ModalController>
  )
}

const BoldSpan = styled.span({
  fontWeight: 700,
})

export { RemovePlanSetModal }
