import type { Product } from '@helloextend/extend-api-client'
import { merge, reduce, set, update } from 'lodash/fp'
import type { Action } from '../actions'

export type ByIdReducerState = Record<string, Product>

export const initialState: ByIdReducerState = {}

export default function byId(state = initialState, action: Action): ByIdReducerState {
  switch (action.type) {
    case 'PRODUCTS_FETCH_ALL_SUCCESS':
      return reduce(addProduct, initialState)(action.payload)
    case 'PRODUCTS_FETCH_SUCCESS': {
      const { storeId, referenceId } = action.payload
      return set([compKey(storeId, referenceId)], action.payload)(state)
    }
    case 'PRODUCTS_UPDATE_BATCH_SUCCESS': {
      const { referenceIds, storeId, data } = action.payload
      return referenceIds.reduce((result, referenceId) => {
        return update([compKey(storeId, referenceId)], (product) => {
          return merge(product, data)
        })(result)
      }, state)
    }
    case 'PRODUCTS_UPDATE_SUCCESS': {
      const { referenceId, storeId } = action.payload
      return update([compKey(storeId, referenceId)], (product) => {
        return merge(product, action.payload)
      })(state)
    }
    case 'PRODUCTS_RESET':
      return initialState
    default:
      return state
  }
}

export function addProduct(state: ByIdReducerState, product: Product): ByIdReducerState {
  return set([compKey(product.storeId, product.referenceId)], product)(state)
  // const { referenceId, parentReferenceId, storeId } = product
  // const adminProduct = flow(
  //   merge(state[compKey(storeId, referenceId)]),
  //   update('childProductIds', uniq),
  // )(buildAdminProduct(product))
  // const merged = set([compKey(storeId, referenceId)], adminProduct)(state)
  //
  // if (parentReferenceId) {
  //   return update([compKey(storeId, parentReferenceId), 'childProductIds'], ids => {
  //     return ids ? union(ids)([referenceId]) : [referenceId]
  //   })(merged)
  // }
  // return merged
}

// function sanitizeHTML(text?: string): string {
//   if (!text) return ''
//   return new DOMParser().parseFromString(text, 'text/html').body.innerText
// }

export function compKey(storeId: string, referenceId: string): string {
  return `STORE::${storeId}::PRODUCT::${referenceId}`
}
