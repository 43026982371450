import type { ServiceOrder, Claim } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetchByClaim = (claimId: string, accessToken: string) =>
  ({ type: 'SERVICE_ORDERS_FETCH', payload: { claimId, accessToken } } as const)

export const fetchByClaims = (claims: Claim[], accessToken: string) =>
  ({
    type: 'SERVICE_ORDERS_FETCH_BY_CLAIMS',
    payload: {
      claims,
      accessToken,
    },
  } as const)

export const fetchSuccess = (serviceOrders: ServiceOrder[]) =>
  ({
    type: 'SERVICE_ORDERS_FETCH_SUCCESS',
    payload: { serviceOrders },
  } as const)

export const fetchSingleClaimSuccess = (serviceOrders: ServiceOrder[]) =>
  ({ type: 'SERVICE_ORDERS_FETCH_SINGLE_CLAIM_SUCCESS', payload: { serviceOrders } } as const)

export const fetchSuccessByClaims = () =>
  ({ type: 'SERVICE_ORDERS_FETCH_BY_CLAIMS_SUCCESS', payload: undefined } as const)

export const fetchFailure = createActionFailure('SERVICE_ORDERS_FETCH_FAILURE')

export const fetchByClaimsFailure = createActionFailure('SERVICE_ORDERS_FETCH_BY_CLAIMS_FAILURE')

export const fetchRequest = () =>
  ({ type: 'SERVICE_ORDERS_FETCH_REQUEST', payload: undefined } as const)
