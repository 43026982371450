import type { FC } from 'react'
import React from 'react'
import { Button, BrokenLinkIcon, ErrorIcon } from '@helloextend/merchants-ui'
import styled from '@emotion/styled'

type PageErrorProps = {
  handleBackClick?: () => void
  handleRetryClick?: () => void
  title?: string
  subtitle?: string
  iconColor?: string
  'data-cy'?: string
}

const handleBack = (): void => {
  window.history.back()
}

const handleRetry = (): void => {
  window.location.reload()
}

const PageError: FC<PageErrorProps> = ({
  handleBackClick = handleBack,
  handleRetryClick = handleRetry,
  title = 'Something went wrong.',
  subtitle = 'The content for this page failed to load. Please contact support if this issue persists.',
  iconColor,
  'data-cy': dataCy,
}) => {
  return (
    <Wrapper data-cy={dataCy}>
      <IconContainer>
        <BrokenLinkIcon fill={iconColor} />
        <ErrorIcon fill={iconColor} />
      </IconContainer>
      <Title data-cy={`${dataCy}:title`}>{title}</Title>
      <Subtitle data-cy={`${dataCy}:subtitle`}>{subtitle}</Subtitle>
      <ButtonContainer>
        <Button text="Back" name="back" kind="secondary" onClick={handleBackClick} size="sm" />
        <Button text="Retry" name="retry" onClick={handleRetryClick} size="sm" />
      </ButtonContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
})

const IconContainer = styled.div({
  display: 'flex',
  gap: 24,
  height: 47,
  width: 118,
})

const Title = styled.h2({
  fontFamily: 'Nunito Sans, sans-serif',
  fontSize: 24,
  fontWeight: 700,
  lineHeight: '32px',
  margin: '32px 0 24px',
})

const Subtitle = styled.p({
  fontFamily: 'Nunito Sans, sans-serif',
  fontSize: 20,
  lineHeight: '27px',
  margin: 0,
})

const ButtonContainer = styled.div({
  display: 'flex',
  gap: 16,
  marginTop: 32,
})
export { PageError }
