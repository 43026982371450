import type { FC } from 'react'
import React, { Fragment, useState, useCallback } from 'react'
import styled from '@emotion/styled'
import { startCase } from 'lodash/fp'
import type { Product, ContractCoveredProduct } from '@helloextend/extend-api-client'
import { Paragraph } from '@helloextend/zen/src/tokens/typography'
import { DataProperty, COLOR, Grid, Modal, ModalController, Stack } from '@helloextend/zen'
import { useSelector } from 'react-redux'
import { StackDirection } from '@helloextend/zen/src/components/stack'
import { currency } from '@helloextend/client-utils'
import type { RootState } from '../../../../reducers'
import { getProductById } from '../../../../reducers/selectors'

import { Divider } from '../../../../components/divider'
import { format } from '../../../../components/currency-input'
import images from '../../../../images'

export interface ProductsTableProps {
  products: ContractCoveredProduct[]
  purchaseCurrency: string
  storeId: string
}

const ProductsTable: FC<ProductsTableProps> = ({ products, purchaseCurrency, storeId }) => {
  const rootState = useSelector((state: RootState) => state)
  const [openModal, setOpenModal] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
  const tableColumns = { lg: 4, md: 4, sm: 4 }

  const tableHeadings = [
    { label: 'Name' },
    { label: 'Product Reference ID' },
    { label: 'Purchase Price' },
    { label: 'Quantity' },
  ]

  const handleProductModal = useCallback(
    (product: ContractCoveredProduct) => {
      setOpenModal(true)
      setSelectedProduct(getProductById(rootState, storeId, product.referenceId))
    },
    [rootState, storeId],
  )

  return (
    <>
      <TableContainer>
        <Grid columns={tableColumns} data-cy="product-shipping-heading">
          {tableHeadings.map((heading) => {
            return <Label key={heading.label}>{heading.label}</Label>
          })}
        </Grid>
        <Divider />
        <Grid columns={tableColumns}>
          {products.map((product) => {
            const formattedPurchasePrice = format(product.purchasePrice, false, purchaseCurrency)

            return (
              <Fragment key={product.referenceId}>
                <Text>{product.title}</Text>
                <TextLink onClick={() => handleProductModal(product)}>
                  {product.referenceId}
                </TextLink>
                <Text>{`${formattedPurchasePrice} ${purchaseCurrency}`}</Text>
                <Text>{product.quantity}</Text>
              </Fragment>
            )
          })}
        </Grid>
      </TableContainer>
      <ModalController isOpen={openModal}>
        <Modal
          heading="Product Details"
          onDismissRequest={() => setOpenModal(false)}
          primaryButtonProps={{
            onClick: () => setOpenModal(false),
            text: 'Close',
          }}
          size="md"
        >
          <StyledDivider />
          <ModalBody>
            <Stack direction={StackDirection.column} spacing={5}>
              <Stack direction={StackDirection.row} spacing={6}>
                <Image src={selectedProduct?.imageUrl || images.productPlaceholder} />
                <DataProperty
                  value={selectedProduct?.title}
                  label="Product Title"
                  data-cy="product-title"
                  includeCopyAction
                />
              </Stack>
              <Stack direction={StackDirection.column} spacing={6}>
                <DataProperty
                  value={selectedProduct?.description}
                  label="Product Description"
                  data-cy="product-description"
                  includeCopyAction={false}
                />
              </Stack>
              <Stack direction={StackDirection.row} spacing={30}>
                <DataProperty
                  value={currency.format(
                    selectedProduct?.price?.amount,
                    selectedProduct?.price?.currencyCode,
                  )}
                  label="Price Amount"
                  data-cy="product-price-amount"
                  includeCopyAction={false}
                />
                <DataProperty
                  value={startCase(selectedProduct?.mfrWarranty?.labor?.toString() || '')}
                  label="Manufacturer Warranty Labor"
                  data-cy="product-manufacturer-warranty-labor"
                  includeCopyAction={false}
                />
              </Stack>
              <Stack direction={StackDirection.row} spacing={16.5}>
                <DataProperty
                  value={startCase(selectedProduct?.mfrWarranty?.parts?.toString() || '')}
                  label="Manufacturer Warranty parts"
                  data-cy="product-manufacturer-warranty-parts"
                  includeCopyAction={false}
                />
                <DataProperty
                  value={selectedProduct?.brand}
                  label="Brand"
                  data-cy="product-brand"
                  includeCopyAction={false}
                />
              </Stack>
              <Stack direction={StackDirection.row} spacing={32.5}>
                <DataProperty
                  value={selectedProduct?.condition || 'new'}
                  label="Condition"
                  data-cy="product-condition"
                  includeCopyAction={false}
                />
                <DataProperty
                  value={selectedProduct?.identifiers?.sku || ''}
                  label="SKU"
                  data-cy="product-sku"
                  includeCopyAction={false}
                />
              </Stack>
            </Stack>
          </ModalBody>
        </Modal>
      </ModalController>
    </>
  )
}

const Text = styled(Paragraph)({
  fontSize: '16px',
  borderBottom: `1px solid ${COLOR.NEUTRAL[200]}`,
  padding: '1rem 0',
})

const TextLink = styled(Text)({
  textDecoration: 'underline',
  color: COLOR.BLUE['800'],
  lineHeight: '24px',
  padding: `16px 0px`,
  zIndex: 1,
  '&:hover': {
    cursor: 'pointer',
  },
})

const TableContainer = styled.div({
  marginTop: '0.3rem',
})

const Label = styled.div({
  fontSize: 14,
  lineHeight: '22px',
  fontWeight: 800,
  color: COLOR.NEUTRAL[600],
  padding: '1rem 0',
})

const Image = styled.img({
  width: '8rem',
  marginTop: '-0.5rem',
})

const ModalBody = styled.div({
  margin: '0.8rem',
})

const StyledDivider = styled(Divider)({
  marginTop: '0.5rem',
  marginBottom: '2.5rem',
  width: '95%',
})

export default ProductsTable
