import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { COLOR, ButtonGroup, Button, Checkbox } from '@helloextend/zen'
import { BasicModal } from '../../../../../components/basic-modal'
import { ModalWarningText } from '../modal-warning-text/modal-warning-text'
import { AMPModalType } from '../../utils'
import { ReferencedConversations } from '../referenced-conversations'

type ConfirmPublishModalProps = {
  isVisible: boolean
  onConfirm: () => void
  onCancel: () => void
  isProcessing: boolean
  threadId: string
  threadTitle: string
  isThreadReferencedByConversations: boolean
}

const ConfirmPublishModal: FC<ConfirmPublishModalProps> = ({
  isVisible,
  onConfirm,
  onCancel,
  isProcessing,
  threadId,
  threadTitle,
  isThreadReferencedByConversations,
}) => {
  const [isAcknowledged, setIsAcknowledged] = useState(false)

  if (!isThreadReferencedByConversations && !isAcknowledged) {
    setIsAcknowledged(true)
  }

  const handleAcknowledge = (): void => {
    setIsAcknowledged(!isAcknowledged)
  }

  return (
    <BasicModal
      data-cy="confirm-publish-thread-modal"
      isVisible={isVisible}
      onClickClose={onCancel}
      overflow="auto"
    >
      <Heading>Publish the thread</Heading>
      <ModalWarningText
        modalType={AMPModalType.saveAndPublish}
        threadTitle={threadTitle}
        hasReferencedConversations={isThreadReferencedByConversations}
      />
      {isThreadReferencedByConversations && (
        <>
          <ReferencedConversations
            data-cy="publish-modal-referenced-conversations"
            threadId={threadId}
            hasReferencedConversations
          />
          <CheckboxContainer>
            <Checkbox
              data-cy="publish-modal-acknowledged-checkbox"
              label="I am aware of the impact and still want to save and publish the changes."
              onChange={handleAcknowledge}
              checked={isAcknowledged}
            />
          </CheckboxContainer>
        </>
      )}
      <ButtonContainer>
        <ButtonGroup>
          <Button
            data-cy="publish-modal-cancel"
            emphasis="medium"
            text="Cancel"
            onClick={onCancel}
          />
          <Button
            data-cy="publish-modal-confirm"
            color="blue"
            text="Save & Publish"
            isDisabled={!isAcknowledged}
            isProcessing={isProcessing}
            onClick={onConfirm}
          />
        </ButtonGroup>
      </ButtonContainer>
    </BasicModal>
  )
}

const Heading = styled.p({
  marginTop: 0,
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '28px',
  color: COLOR.NEUTRAL[1000],
})

const CheckboxContainer = styled.div({
  padding: '12px 0 12px 0',
})

const ButtonContainer = styled.div({
  paddingTop: 16,
  display: 'flex',
  justifyContent: 'flex-end',
})

export { ConfirmPublishModal, ConfirmPublishModalProps }
