import { Button, Add, useToaster, ToastColor, ToastDuration } from '@helloextend/zen'
import type { FC } from 'react'
import React, { useRef, useCallback, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useGetUsersListWithGrantsQuery } from '@helloextend/extend-api-rtk-query'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useParams } from 'react-router-dom'
import { UsersDataTable } from './users-list/v3/users-data-table'
import CreateUserModal from '../components/create-user-modal/create-user-modal'
import { LDFlag } from '../../../../constants/ld-flags'
import { AccountUsers } from './users-list/v2/account-users'

const Users: FC = () => {
  const { [LDFlag.MerchantV3UserManagement]: FF_MERCHANT_V3_USER_MGMT } = useFlags()

  const { toast, dismiss } = useToaster()
  const { accountId } = useParams<{ accountId: string }>()
  const [showCreateUserModal, setShowCreateUserModal] = useState<boolean>(false)
  const {
    data: userData,
    isLoading,
    isError,
  } = useGetUsersListWithGrantsQuery(
    { account: accountId, internal: false },
    { skip: !FF_MERCHANT_V3_USER_MGMT },
  )

  const loadingToastRef = useRef<number>(0)

  const closeModal = useCallback(() => {
    setShowCreateUserModal(false)
  }, [])

  useEffect(() => {
    if (userData?.length) {
      dismiss(loadingToastRef.current)
      return
    }

    if (isError) {
      dismiss(loadingToastRef.current)

      toast({
        message: 'Unable to retrieve user data',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      return
    }
    if (isLoading) {
      loadingToastRef.current = toast({
        message: 'Retrieving results... This might take a few moments',
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
    }
  }, [dismiss, isError, isLoading, toast, userData])

  return (
    <>
      {FF_MERCHANT_V3_USER_MGMT ? (
        <>
          <HeaderContainer>
            <Button
              text="Invite User"
              data-cy="invite-user-button"
              icon={Add}
              emphasis="medium"
              onClick={() => setShowCreateUserModal(true)}
            />
          </HeaderContainer>{' '}
          <UsersDataTable accountId={accountId} userData={userData || []} isLoading={isLoading} />
          <CreateUserModal visible={showCreateUserModal} toggleOff={closeModal} />
        </>
      ) : (
        <div data-cy="v2-users-table">
          <AccountUsers />
        </div>
      )}
    </>
  )
}

const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

export { Users }
