import type { FC } from 'react'
import React from 'react'

interface IconProps {
  fill?: string
  color?: string
  height?: string
}

export const GetHelpInverse: FC<IconProps> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-505 -587)">
        <g transform="translate(506 588)">
          <circle cx="21.15" cy="21.15" r="21.15" stroke="#0332CC" strokeWidth="1.215" />
          <path
            fill="#0332CC"
            fillRule="nonzero"
            d="M20.328 24.024c0-.832.151-1.535.453-2.11.303-.574.741-1.202 1.316-1.882.438-.53.763-.975.975-1.338.212-.363.318-.771.318-1.225 0-.514-.182-.919-.545-1.213-.363-.295-.87-.443-1.52-.443s-1.334.144-2.052.431a9.084 9.084 0 00-2.12 1.225l-.953-2.132c.62-.56 1.421-1.005 2.404-1.338a9.148 9.148 0 012.948-.499c.923 0 1.74.155 2.45.465.71.31 1.266.748 1.667 1.315.4.567.6 1.214.6 1.94 0 .786-.188 1.474-.566 2.063-.378.59-.922 1.24-1.633 1.95-.62.666-1.085 1.21-1.395 1.634a3.15 3.15 0 00-.578 1.451l-.09.613h-1.68v-.907zm-.703 4.082c0-.864.7-1.565 1.565-1.565h.045a1.565 1.565 0 110 3.13h-.045c-.865 0-1.565-.7-1.565-1.565z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const BulletList: FC<IconProps> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="43" height="36" viewBox="0 0 43 36">
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#0332CC" fillRule="nonzero" transform="translate(-506 -449)">
        <g transform="translate(506 449)">
          <path d="M3.51.702A3.514 3.514 0 000 4.212a3.514 3.514 0 003.51 3.51 3.514 3.514 0 003.51-3.51A3.514 3.514 0 003.51.702zm0 5.616a2.109 2.109 0 01-2.106-2.106c0-1.161.945-2.106 2.106-2.106s2.106.945 2.106 2.106A2.109 2.109 0 013.51 6.318zm7.02-1.404h30.888a.702.702 0 000-1.404H10.53a.702.702 0 000 1.404zM3.51 28.782A3.514 3.514 0 000 32.292a3.514 3.514 0 003.51 3.51 3.514 3.514 0 003.51-3.51 3.514 3.514 0 00-3.51-3.51zm0 5.616a2.109 2.109 0 01-2.106-2.106c0-1.161.945-2.106 2.106-2.106s2.106.945 2.106 2.106a2.109 2.109 0 01-2.106 2.106zM41.418 17.55H10.53a.702.702 0 000 1.404h30.888a.702.702 0 000-1.404zm0 14.04H10.53a.702.702 0 000 1.404h30.888a.702.702 0 000-1.404zM3.51 14.742A3.514 3.514 0 000 18.252a3.514 3.514 0 003.51 3.51 3.514 3.514 0 003.51-3.51 3.514 3.514 0 00-3.51-3.51zm0 5.616a2.109 2.109 0 01-2.106-2.106c0-1.161.945-2.106 2.106-2.106s2.106.945 2.106 2.106a2.109 2.109 0 01-2.106 2.106z" />
        </g>
      </g>
    </g>
  </svg>
)

export const DoneMail: FC<IconProps> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="42" viewBox="0 0 44 42">
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#0332CC" fillRule="nonzero" transform="translate(-505 -349)">
        <g transform="translate(505 349)">
          <path d="M20.629 35.403c-.148 0-.442 0-.59-.148l-4.42-4.425a.714.714 0 010-1.033.712.712 0 011.031 0l3.831 3.836 6.779-6.786a.712.712 0 011.031 0 .714.714 0 010 1.033l-7.367 7.375c.147.148-.148.148-.295.148z" />
          <g>
            <path d="M39.047 29.502h-3.683c-.442 0-.737-.295-.737-.737 0-.443.295-.738.737-.738h3.683a2.957 2.957 0 002.947-2.95V4.425a2.957 2.957 0 00-2.947-2.95H5.157a2.957 2.957 0 00-2.947 2.95v20.652a2.957 2.957 0 002.947 2.95h3.684c.442 0 .737.295.737.738 0 .442-.295.737-.737.737H5.157c-2.505 0-4.42-1.917-4.42-4.425V4.425C.737 1.918 2.652 0 5.157 0h33.89c2.505 0 4.42 1.918 4.42 4.425v20.652c0 2.508-1.915 4.425-4.42 4.425z" />
            <path d="M21.66 16.226c-1.031 0-2.21-.295-2.947-.885L4.715 5.015c-.295-.295-.442-.737-.147-1.032.295-.295.737-.443 1.031-.148l13.998 10.326c1.032.738 3.242.738 4.274 0L37.869 3.835c.294-.295.736-.147 1.031.148.295.295.147.737-.147 1.032L24.755 15.341c-.884.59-1.916.885-3.095.885zM5.894 25.077c-.147 0-.442 0-.59-.147a.714.714 0 010-1.033l8.841-8.85a.712.712 0 011.032 0 .714.714 0 010 1.032l-8.841 8.85c0 .148-.295.148-.442.148zM38.31 25.077c-.147 0-.441 0-.589-.147l-8.84-8.851a.714.714 0 010-1.033.712.712 0 011.03 0l8.842 8.851a.714.714 0 010 1.033c0 .147-.295.147-.442.147z" />
          </g>
          <path d="M22.102 41.303c-6.041 0-11.05-5.015-11.05-11.063 0-6.048 5.009-11.063 11.05-11.063 6.042 0 11.051 5.015 11.051 11.063 0 6.048-5.01 11.063-11.05 11.063zm0-20.651a9.564 9.564 0 00-9.577 9.588 9.564 9.564 0 009.577 9.588 9.564 9.564 0 009.578-9.588 9.564 9.564 0 00-9.578-9.588z" />
        </g>
      </g>
    </g>
  </svg>
)

export const ErrorCrossCircle: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 47" width="46" height="46">
    <g fill="#0A0737">
      <path d="M23.5 47a23.5 23.5 0 110-47 23.5 23.5 0 010 47zm0-45a21.5 21.5 0 100 43 21.5 21.5 0 000-43z" />
      <path d="M32.7 33.7a1 1 0 01-.7-.3L13.6 15a1 1 0 011.4-1.4L33.4 32a1 1 0 01-.7 1.7z" />
      <path d="M14.3 33.7a1 1 0 01-.7-1.7L32 13.6a1 1 0 011.4 1.4L15 33.4a1 1 0 01-.7.3z" />
    </g>
  </svg>
)

export const ErrorBrokenLinks: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46" width="46" height="46">
    <g fill="#0A0737">
      <path d="M10.6 11.5a1 1 0 01-.7-.3L4 5.5A1 1 0 115.5 4l5.7 5.8a1 1 0 01-.6 1.6zm-2 3.8H1a1 1 0 010-1.9h7.6a1 1 0 010 2zm5.8-5.7a1 1 0 01-1-1V1a1 1 0 012 0v7.6c0 .6-.5 1-1 1zm26.8 30.6a1 1 0 01-.7-.2l-5.7-5.8A1 1 0 0136 33l5.8 5.7a1 1 0 01-.7 1.6zm3.8-9.5h-7.6a1 1 0 010-2H45a1 1 0 010 2zM31.6 44a1 1 0 01-1-1v-7.6a1 1 0 012 0V43c0 .6-.5 1-1 1zm-6.4-14.7c-2.2 0-4.4-.8-6.1-2.5a1 1 0 011.3-1.3 6.7 6.7 0 009.5 0l12.2-12.2a6.7 6.7 0 00-9.5-9.5l-8.3 8.3a1 1 0 01-1.4-1.3l8.4-8.4a8.6 8.6 0 0112.2 12.2L31.3 27a8.6 8.6 0 01-6.1 2.5z" />
      <path d="M8.6 46a8.6 8.6 0 01-6-14.7l12-12.3A8.6 8.6 0 0127 19a1 1 0 01-1.4 1.3 6.7 6.7 0 00-9.4 0L3.9 32.6a6.7 6.7 0 009.5 9.5l8.3-8.3a1 1 0 011.4 1.3l-8.4 8.4a8.6 8.6 0 01-6 2.5z" />
    </g>
  </svg>
)
