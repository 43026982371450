import { createBooleanReducer } from '../../factories'
import type { Action } from '../actions'

export type IsImportingdReducerState = boolean

const isImporting = createBooleanReducer<Action>(
  ['PLAN_TERM_VERSION_IMPORT_REQUEST', 'PLAN_TERM_CREATE_REQUEST'],
  [
    'PLAN_TERM_VERSION_IMPORT_SUCCESS',
    'PLAN_TERM_VERSION_IMPORT_FAILURE',
    'PLAN_TERM_CREATE_SUCCESS',
    'PLAN_TERM_CREATE_FAILURE',
  ],
)

export default isImporting
