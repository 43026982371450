import type { FC } from 'react'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { ToastColor, ToastDuration, useToaster } from '@helloextend/zen'
import { useParams } from 'react-router-dom'
import {
  useCreateServicerLocationMutation,
  useGetServicerQuery,
  useUpdateServicerLocationMutation,
} from '@helloextend/extend-api-rtk-query'
import { useFormik } from 'formik'
import { CollapsibleInfoGroup } from '../../../../../components/collapsible-info-group'
import type { CollapsibleInfoGroupProps } from '../../../../../components/collapsible-info-group/collapsible-info-group'
import type { Values } from './schema'
import { getLocationInfoData, schema } from './schema'

const LocationsTab: FC = () => {
  const { servicerId } = useParams<{ servicerId: string }>()
  const { data: servicer, isSuccess } = useGetServicerQuery(servicerId)
  const { toast } = useToaster()
  const location = servicer?.locations ? servicer.locations[0] : undefined
  const [isEditable, setIsEditable] = useState(false)
  const [createLocation] = useCreateServicerLocationMutation()
  const [updateLocation] = useUpdateServicerLocationMutation()

  useLayoutEffect(() => {
    if (isSuccess && !location) {
      setIsEditable(true)
    }
  }, [isSuccess, location, setIsEditable])

  const { values, errors, resetForm, setFieldValue, handleSubmit } = useFormik<Values>({
    validationSchema: schema,
    initialValues: location || { ...schema.default() },
    onSubmit: () => {
      if (!servicer) return

      const props = {
        servicerId: servicer.id,
        body: {
          contact: servicer.contact,
          ...values,
        },
      }

      if (location) {
        updateLocation({ ...props, servicerLocationId: location.id })
      } else {
        createLocation(props)
      }

      toast({
        message: `Location ${location?.id ? 'updated' : 'added'}!`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
      toggleIsEditable()
    },
  })

  const toggleIsEditable = useCallback(() => {
    setIsEditable(!isEditable)
  }, [setIsEditable, isEditable])

  const locationInfo = useMemo(
    () => getLocationInfoData(isEditable, location, values, errors),
    [isEditable, location, values, errors],
  )

  const handleChange = (key: string, value: any, shouldValidate?: boolean): void => {
    setFieldValue(key, value, shouldValidate)
  }

  const locationText = isEditable ? `${location ? 'Edit' : 'Add'} Location` : 'Locations'

  return (
    <CollapsibleInfoGroup
      data-cy="locations-tab"
      title={locationText}
      data={locationInfo}
      handleChange={handleChange as CollapsibleInfoGroupProps['handleChange']}
      numColumns={4}
      isCollapsible={false}
      primaryButtonText={isEditable ? 'Save' : undefined}
      onPrimaryButtonClick={handleSubmit}
      secondaryButtonText={location && isEditable ? 'Cancel' : undefined}
      onSecondaryButtonClick={() => {
        resetForm()
        toggleIsEditable()
      }}
      buttonAlignment={isEditable ? 'right' : 'left'}
      hasEditButton={location && !isEditable}
      isEditing={location && isEditable}
      onEditButtonClick={toggleIsEditable}
    />
  )
}

export { LocationsTab }
