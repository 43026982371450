import { combineReducers } from 'redux'
import type { ByAccountIdReducerState } from './by-account-id'
import byAccountId from './by-account-id'
import type { ByStoreAccountIdReducerState } from './by-store-account-id'
import byStoreAccountId from './by-store-account-id'
import isLoading from './is-loading'
import isUpdating from './is-updating'
import isSuccess from './is-success'
import isInviteEmailResent from './is-invite-email-resent'
import type { UsersErrorReducerState } from './error'
import error from './error'
import type { Action } from '../actions'

export interface ReducerState {
  byAccountId: ByAccountIdReducerState
  byStoreAccountId: ByStoreAccountIdReducerState
  isLoading: boolean
  isUpdating: boolean
  error: UsersErrorReducerState
  isSuccess: boolean
  isInviteEmailResent: boolean
}

export default combineReducers<ReducerState, Action>({
  byAccountId,
  isLoading,
  isUpdating,
  byStoreAccountId,
  error,
  isSuccess,
  isInviteEmailResent,
})
