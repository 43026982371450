import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, InlineAlert, InlineAlertColor, Icon, IconSize } from '@helloextend/zen'
import { Error } from '@helloextend/zen/src/tokens/icons'

interface ErrorBannerProps {
  header: string
}

const ErrorBanner: FC<ErrorBannerProps> = ({ header }) => {
  return (
    <InlineAlert color={InlineAlertColor.red} data-cy="error-banner">
      <Header>
        <Icon icon={Error} color={COLOR.RED[700]} size={IconSize.large} />
        {header}
      </Header>
    </InlineAlert>
  )
}

export { ErrorBanner, ErrorBannerProps }

const Header = styled.div({
  color: COLOR.RED[700],
  display: 'flex',
  alignItems: 'center',
  gap: 8,
})
