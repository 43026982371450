import type { PresignedPost } from 'aws-sdk/clients/s3'
import type { ClaimStatus, ShippingStatus } from './claim'
import type { ServiceType } from './plan'

export enum Slot {
  HelpType = 'HelpType',
  ClaimType = 'ClaimType',
  ContractsSearchKey = 'ContractsSearchKey',
  ResetContractSearch = 'ResetContractSearch',
  VerificationCode = 'VerificationCode',
  ContractId = 'ContractId',
  ClaimId = 'ClaimId',
  IncidentDate = 'IncidentDate',
  ClaimExists = 'ClaimExists',
  AdjudicationType = 'AdjudicationType',
  FailureType = 'FailureType',
  FailureDescription = 'FailureDescription',
  EndClaim = 'EndClaim',
  ClaimPhotoUpload = 'ClaimPhotoUpload',
  ProductSection = 'ProductSection',
  ShippingAddress = 'ShippingAddress',
  ClaimItem = 'ClaimItem',
}

export interface ClaimSession {
  slots?: {
    [Slot.AdjudicationType]?: string
    [Slot.ClaimExists]?: string
    [Slot.ContractId]?: string
    [Slot.ClaimId]?: string
    [Slot.ClaimType]?: ClaimType
    [Slot.ContractsSearchKey]?: string
    [Slot.VerificationCode]?: string
    [Slot.FailureDescription]?: string
    [Slot.FailureType]?: string
    [Slot.HelpType]?: HelpTypeSlotValue
    [Slot.IncidentDate]?: number
    [Slot.ProductSection]?: string
    [Slot.EndClaim]?: SlotValue
    [Slot.ClaimItem]?: string
  }
}

type ClaimType = 'extended_warranty' | 'shipping_protection'

export type PromptType =
  | 'input'
  | 'buttons'
  | 'carousel'
  | 'datepicker'
  | 'multiselect'
  | 'imageUpload'
  | 'addressInput'

export interface BasePrompt<TType extends PromptType> {
  slot: Slot
  type: TType
}

export interface InputPrompt extends BasePrompt<'input'> {
  placeholder?: string
}

export interface ClaimCardTrackingActivity {
  message: string
  date: Date
}

export interface Card {
  contractId: string
  claimId: string
  updatedAt: number
  status: ClaimStatus
  value?: string
  sellerName: string
  title: string
  imageUrl?: string
  shippingStatus?: ShippingStatus
  claimTrackingActivity: ClaimCardTrackingActivity[]
  serviceType: ServiceType
}

export interface ButtonsPrompt extends BasePrompt<'buttons'> {
  options: SelectPromptOption[]
  card?: Card
}

export interface CarouselPrompt extends BasePrompt<'carousel'> {
  options: CarouselPromptOption[]
}

export type CarouselPromptOption = ContractCarouselPromptOption | OrderCarouselPromptOption

export type ContractCarouselPromptOption = SelectPromptOption & {
  // this is the generic version, for now, use hard coded value based on actual expected values from api
  // attributes: Record<string, string | number>
  attributes: {
    storeName: string
    transactionDate: number
    purchasePrice: number
  }
  status?: ClaimStatus
  serviceType?: ServiceType
  type: 'contract'
}

export interface OrderCarouselPromptOption {
  additionalItems: number
  items: OrderCarouselItem[]
  orderId: string
  outputText: string
  purchaseDate: number
  purchasePrice: number
  title: string
  type: 'order'
  value: string
}

export interface OrderCarouselItem {
  imageUrl: string
  purchasePrice: number
  quantity: number
  title: string
}

export interface DatePickerPrompt extends BasePrompt<'datepicker'> {}

export interface SelectPromptOption {
  title: string
  value: string
  imageUrl?: string
  outputText?: string
  redirectUrl?: string
}

export interface ImageUploadPrompt extends BasePrompt<'imageUpload'> {
  description: string
  name: string
  presignedPost: PresignedPost
  type: 'imageUpload'
}

export interface MultiSelectPrompt extends BasePrompt<'multiselect'> {
  title: string
  options: MultiSelectPromptOption[]
}
export type MultiSelectPromptOption = {
  title: string
  outputText: string
  value: string
  imageUrl?: string
}

export interface AddressInputPrompt extends BasePrompt<'addressInput'> {}

export type Prompt =
  | InputPrompt
  | ButtonsPrompt
  | CarouselPrompt
  | DatePickerPrompt
  | MultiSelectPrompt
  | ImageUploadPrompt
  | AddressInputPrompt

// a robot's reply when the user sends claim information
export interface Reply {
  messages: Message[]
  prompt?: Prompt
  poll?: Poll
  claimId?: string
}

export interface InitialReply extends Reply {
  prompt: Prompt
  accessToken: string
}

export interface Poll {
  url: string
  accessToken?: string
}

export interface Message {
  type: 'text' | 'textSelect'
  content: string
  elements?: Element[]
  fallbackMessage?: string
}

export interface Element {
  elementId: string
  elementType: ElementType
  link?: LinkContent
  resendCodeData?: ResendCodeData
}

export enum ElementType {
  Link = 'Link',
  Bold = 'Bold',
  ResendCodeLink = 'ResendCodeLink',
}

type ResendCodeData = {
  value: string
  text: string
}

export interface LinkContent {
  href: string
  isExternal: boolean
  text: string
  downloadTitle?: string
}

// Slot values

export enum HelpTypeSlotValue {
  START_NEW_CLAIM = 'START_NEW_CLAIM',
  TRACK_CLAIM = 'TRACK_CLAIM',
  END_CLAIM = 'END_CLAIM',
}

export enum FailureTypeSlotValue {
  ACCIDENT_DROP = 'ACCIDENT_DROP',
  ACCIDENT_LIQUID = 'ACCIDENT_LIQUID',
  ELECTRIC = 'ELECTRIC',
  MECHANICAL = 'MECHANICAL',
}

export type SlotValue = FailureTypeSlotValue | HelpTypeSlotValue | string | number

// Claims assitant contact form
export interface ContactFormValues {
  name: string
  email: string
  phoneNumber: string
  requestType: string
  message: string
  locale: string
}

export enum ClaimSource {
  chatbot = 'chatbot',
  ops_admin = 'ops_admin',
  merch_admin = 'merch_admin',
}
