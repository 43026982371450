import styled from '@emotion/styled'
import { COLOR } from '@helloextend/zen'

export const MessageBubble = styled.div<{ isActive?: boolean }>(({ isActive }) => ({
  visibility: 'visible',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  height: 24,
  marginRight: 20,
  padding: '0 8px',
  borderRadius: 12,
  background: isActive ? COLOR.BLUE[700] : COLOR.NEUTRAL[700],
  color: COLOR.WHITE,
  fontWeight: 700,
  fontSize: 13,
  lineHeight: '18px',
}))
