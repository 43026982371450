import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import type { FormikErrors, FormikHandlers, FormikHelpers, FormikTouched } from 'formik'
import { getIn } from 'formik'
import { formatToMilliseconds } from '@helloextend/client-utils/date'
import {
  useListInsuranceProgramsQuery,
  useListPlanAttributesQuery,
} from '@helloextend/extend-api-rtk-query'
import { InputType } from '@helloextend/zen/src/components/fields/input/types'
import { FormTextGroup } from '../../../components/form-text-group'
import type { Values } from './schema'
import type {
  DropdownItem,
  DateItem,
  CurrencyInputItem,
} from '../../../components/form-text-group/form-text-group'

import { SkuRates } from './sku-rates'
import { CurrencyInputAlignment } from '../../../components/currency-input/currency-input'
import { toPlanAttributesDropdownOptions } from '../../../utils/plan-attributes-dropdown-mapping'
import { obligorOptionsList } from '../../../utils/obligor-options-list'

// Workaround due to typing issue
const getFormikError = (errors: FormikErrors<Values>, fieldName: string): string => {
  const error = getIn(errors, fieldName)
  return error || ''
}

type SkuFormProps = {
  values: Values
  errors: FormikErrors<Values>
  handleChange: FormikHandlers['handleChange']
  handleBlur: FormikHandlers['handleBlur']
  touched: FormikTouched<Values>
  setFieldValue: FormikHelpers<Values>['setFieldValue']
  setErrors: FormikHelpers<Values>['setErrors']
  handleSubmit: () => void
  isDisabled?: boolean
}

const SkuForm: FC<SkuFormProps> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
  setErrors,
  handleSubmit,
  isDisabled = false,
}) => {
  const { data: planAttributes, isFetching } = useListPlanAttributesQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => {
      const attributesList = toPlanAttributesDropdownOptions(data?.items || [])
      return {
        data: attributesList,
        ...rest,
      }
    },
  })
  const { insuranceProgramIds, isLoading } = useListInsuranceProgramsQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => {
      const insuranceProgramsOptions = (data?.items || []).map(({ id }) => ({
        value: id,
        label: id,
      }))
      return {
        insuranceProgramIds: insuranceProgramsOptions,
        ...rest,
      }
    },
  })

  const handleDropdownChange = (e: SyntheticEvent<HTMLSelectElement | HTMLButtonElement>): void => {
    const { value, name } = e.currentTarget
    setFieldValue(name, value)
    setErrors({ ...errors })
  }
  // workaround for formik because date picker doesn't pass regular synthetic event, it passes a date object instead
  const handleDateChange = (name: string, date: Date | null): void => {
    const transformedDate = date ? formatToMilliseconds(date.toDateString()) : null
    setFieldValue(name, transformedDate)
    setErrors({ ...errors })
  }
  return (
    <form data-cy="sku-form" onSubmit={handleSubmit}>
      <FormTextGroup
        title="Basic Info"
        handleChange={handleChange}
        handleBlur={handleBlur}
        isDisabled={isDisabled}
        numColumns={2}
        values={[
          {
            name: 'lineOfBusiness',
            label: 'Line of Business',
            value: values.lineOfBusiness,
            error: errors.lineOfBusiness,
            fieldType: 'dropdown',
            options: [{ value: 'serviceContract', label: 'Service Contract' }],
            handleDropdownChange,
            touched: touched.lineOfBusiness,
          },
          {
            name: 'isActive',
            label: 'Active',
            value: values.isActive,
            error: errors.isActive,
            fieldType: 'dropdown',
            options: [
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' },
            ],
            handleDropdownChange,
            touched: touched.isActive,
          },
        ]}
      />
      <FormTextGroup
        title="Premium Details"
        handleChange={handleChange}
        handleBlur={handleBlur}
        isDisabled={isDisabled}
        numColumns={2}
        values={[
          {
            name: 'id',
            label: 'SKU ID',
            value: values.id,
            isItemDisabled: true,
          },
          {
            name: 'name',
            label: 'SKU Name',
            value: values.name,
            error: errors.name,
            touched: touched.name,
            placeholder: 'E.g. 2 YR Care $350 - $499.99',
          },
          {
            name: 'activeFrom',
            label: 'Activate From',
            value: values.activeFrom || undefined,
            error: errors?.activeFrom,
            fieldType: 'date',
            selected: values.activeFrom ? new Date(values?.activeFrom) : null,
            touched: Boolean(touched.activeFrom),
            handleDateChange,
            fullWidth: true,
            isItemDisabled: isDisabled,
            placeholder: 'Select a date',
          } as DateItem,
          {
            name: 'activeTo',
            label: 'Activate To (optional)',
            value: values.activeTo || undefined,
            error: errors?.activeTo,
            fieldType: 'date',
            touched: touched.activeTo,
            selected: values.activeTo ? new Date(values.activeTo) : null,
            handleDateChange,
            fullWidth: true,
            minDate: values.activeFrom ? new Date(values.activeFrom) : null,
            isItemDisabled: isDisabled,
            placeholder: 'Select a date',
          } as DateItem,
          {
            name: 'program',
            label: 'Program',
            value: values.program,
            error: errors.program,
            fieldType: 'dropdown',
            options: planAttributes?.program || [],
            isLoading: isFetching,
            handleDropdownChange,
            touched: touched.program,
          },
          {
            name: 'subprogram',
            label: 'Subprogram',
            value: values.subprogram,
            error: errors.subprogram,
            fieldType: 'dropdown',
            options: planAttributes?.sub_program || [],
            isLoading: isFetching,
            handleDropdownChange,
            touched: touched.subprogram,
          },
          {
            name: 'obligor',
            label: 'Obligor',
            value: values.obligor,
            error: errors.obligor,
            fieldType: 'dropdown',
            options: obligorOptionsList,
            handleDropdownChange,
            touched: touched.obligor,
          },
          {
            name: 'insuranceProgramId',
            label: 'Insurance Program ID',
            value: values.insuranceProgramId,
            error: errors.insuranceProgramId,
            fieldType: 'dropdown',
            options: insuranceProgramIds,
            isLoading,
            handleDropdownChange,
            touched: touched.insuranceProgramId,
          },
          {
            name: 'coverageType',
            label: 'Coverage Type',
            value: values.coverageType,
            error: errors.coverageType,
            fieldType: 'dropdown',
            options: [
              { value: 'base', label: 'Base' },
              { value: 'adh', label: 'ADH' },
            ],
            handleDropdownChange,
            touched: touched.coverageType,
          },
          {
            name: 'term',
            label: 'Term Length',
            value: values.term,
            error: errors.term,
            placeholder: 'Enter the number of month(s)',
            touched: touched.term,
            suffix: 'months',
            type: InputType.number,
          },
          {
            name: 'serviceType',
            label: 'Service Type',
            value: values.serviceType,
            error: errors.serviceType,
            fieldType: 'dropdown',
            options: [
              { value: 'repair', label: 'Repair' },
              { value: 'repairOnsite', label: 'Repair Onsite' },
              { value: 'repairIrobot', label: 'Repair IRobot' },
              { value: 'repairDepot', label: 'Repair Depot' },
              { value: 'replace', label: 'Replace' },
              { value: 'partialReplace', label: 'Partial Replace' },
            ],
            handleDropdownChange,
            touched: touched.serviceType,
          },
          {
            name: 'productCondition',
            label: 'Product Condition',
            value: values.productCondition,
            error: errors.productCondition,
            fieldType: 'dropdown',
            options: [
              { value: 'new', label: 'New' },
              { value: 'used', label: 'Used' },
              { value: 'refurbished', label: 'Refurbished' },
              { value: 'other', label: 'Other' },
              { value: 'all', label: 'All' },
            ],
            handleDropdownChange,
            touched: touched.productCondition,
          },
          {
            name: 'purchasePriceOfProduct',
            label: 'Purchase Price of Product',
            value: values.purchasePriceOfProduct,
            error: errors.purchasePriceOfProduct,
            fieldType: 'dropdown',
            options: [
              { value: 'item', label: 'Item' },
              { value: 'cart', label: 'Cart' },
              { value: 'bundle', label: 'Bundle' },
              { value: 'membership', label: 'Membership' },
            ],
            handleDropdownChange,
            touched: touched.purchasePriceOfProduct,
          },
          {
            name: 'limitOfLiabilityBasedUpon',
            label: 'Limit of Liability Based Upon',
            value: values.limitOfLiabilityBasedUpon,
            error: errors.limitOfLiabilityBasedUpon,
            fieldType: 'dropdown',
            options: [
              { value: 'product', label: 'Product' },
              { value: 'contractLimit', label: 'Contract Limit' },
            ],
            handleDropdownChange,
            touched: touched.limitOfLiabilityBasedUpon,
          },
          {
            name: 'paymentModel',
            label: 'Payment Model',
            value: values.paymentModel,
            error: errors.paymentModel,
            fieldType: 'dropdown',
            options: [
              { value: 'single', label: 'Single' },
              { value: 'monthly', label: 'Monthly' },
              { value: 'quarterly', label: 'Quarterly' },
            ],
            handleDropdownChange,
            touched: touched.paymentModel,
          },
          {
            name: 'cancellation',
            label: 'Cancellation (optional)',
            value: values.cancellation,
            error: getFormikError(errors, 'cancellation'),
            fieldType: 'dropdown',
            options: [{ value: 'TBD', label: 'TBD' }],
            handleDropdownChange,
            touched: Boolean(touched.cancellation),
          } as DropdownItem,
          {
            name: 'isDeductible',
            label: 'Deductible',
            value: values.isDeductible,
            error: getFormikError(errors, 'isDeductible'),
            fieldType: 'dropdown',
            options: [
              { value: 'no', label: 'No' },
              { value: 'yes', label: 'Yes' },
            ],
            handleDropdownChange,
            touched: Boolean(touched.isDeductible),
          },
          ...(values.isDeductible === 'yes'
            ? [
                {
                  name: 'deductibleAmount',
                  label: 'Deductible Amount',
                  value: values.deductibleAmount,
                  error: getFormikError(errors, 'deductibleAmount'),
                  placeholder: 'Enter dollar amount',
                  touched: Boolean(touched.deductibleAmount),
                  fieldType: 'currency',
                  showSymbol: false,
                  currencyProps: {
                    codeValue: values.currencyCode ?? 'USD',
                    codeFieldName: 'priceBandHigh',
                  },
                  isCurrencyCodeDisplayed: false,
                  handleCurrencyChange: (val: string | number, fieldName) => {
                    setFieldValue(fieldName, val)
                  },
                  isGroup: false,
                  alignment: CurrencyInputAlignment.Left,
                } as CurrencyInputItem,
              ]
            : []),
        ]}
      />
      <FormTextGroup
        title="Geographical Details"
        handleChange={handleChange}
        handleBlur={handleBlur}
        isDisabled={isDisabled}
        numColumns={2}
        values={[
          {
            name: 'currencyCode',
            label: 'Currency',
            value: values.currencyCode,
            error: errors.currencyCode,
            fieldType: 'dropdown',
            options: [
              { value: 'CAD', label: 'Canadian Dollar' },
              { value: 'USD', label: 'United States Dollar' },
            ],
            handleDropdownChange,
            touched: touched.currencyCode,
          },
        ]}
      />
      <SkuRates
        handleChange={handleChange}
        handleBlur={handleBlur}
        isDisabled={isDisabled}
        values={values}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        setErrors={setErrors}
        getFormikError={getFormikError}
      />
    </form>
  )
}
export { SkuForm }
