import type { FC, ChangeEvent } from 'react'
import React, { useState } from 'react'
import {
  ModalController,
  Modal,
  Input,
  AdvancedSelect,
  useToaster,
  ToastColor,
  ToastDuration,
} from '@helloextend/zen'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import type { ThreadTypes } from '@helloextend/extend-api-rtk-query'
import { useCreateThreadMutation } from '@helloextend/extend-api-rtk-query'
import type { ApiResponse, ErrorResponse } from '@helloextend/extend-api-fetch'
import { useHistory } from 'react-router'
import * as Yup from 'yup'

type CreateThreadModalProps = {
  isVisible: boolean
  onCancel: () => void
}

const CreateThreadModal: FC<CreateThreadModalProps> = ({ isVisible, onCancel }) => {
  const history = useHistory()
  const { toast } = useToaster()

  const [isDuplicateThreadName, setIsDuplicateThreadName] = useState<boolean>(false)
  const [createThread, { isLoading: isCreateThreadProcessing }] = useCreateThreadMutation()

  const schema = Yup.object()
    .shape({
      name: Yup.string().required('Thread name is required'),
      type: Yup.string()
        .matches(/(adjudication|intro|outro|troubleshooting|other)/)
        .required('Thread type is required'),
    })
    .defined()

  const {
    handleChange,
    setFieldValue,
    values,
    errors,
    handleBlur,
    touched,
    isValid,
    dirty: isDirty,
  } = useFormik<Yup.InferType<typeof schema>>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      type: '',
    },
    validationSchema: schema,
    onSubmit: () => {},
  })

  const handleNameInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue('name', e.target.value)

    if (isDuplicateThreadName) {
      setIsDuplicateThreadName(false)
    }
  }

  const onProceed = async (): Promise<void> => {
    try {
      const createdThread = await createThread({
        title: values.name,
        type: values.type as ThreadTypes,
        script: [],
      }).unwrap()

      history.push(`/admin/adjudication-management/threads/${createdThread.id}/edit`)
    } catch (err: unknown) {
      const responsePayload = (err as ApiResponse)?.data
      const code = (responsePayload as ErrorResponse)?.code
      if (code === 'title_already_exists') {
        setIsDuplicateThreadName(true)
        return
      }
      // fallback to prevent strange UI states
      if (isDuplicateThreadName) {
        setIsDuplicateThreadName(false)
      }
      toast({
        message: 'Something went wrong. Please try again.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  const isDisabled = !(isDirty && isValid) || isCreateThreadProcessing || isDuplicateThreadName

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        data-cy="amp-create-thread-modal"
        heading="Create a new thread"
        primaryButtonProps={{
          'data-cy': 'amp-create-thread-modal-submit',
          onClick: onProceed,
          text: 'Proceed to Thread Builder',
          isDisabled,
          isProcessing: isCreateThreadProcessing,
        }}
        secondaryButtonProps={{
          'data-cy': 'amp-create-thread-modal-cancel',
          onClick: onCancel,
          text: 'Cancel',
        }}
        onDismissRequest={onCancel}
      >
        <>
          <NameWrapper>
            <Input
              data-cy="amp-create-thread-name"
              id="name"
              label="Name"
              onChange={handleNameInputChange}
              onBlur={handleBlur}
              placeholder="Name the new thread"
              value={values.name}
              isError={(Boolean(errors.name) && touched.name) || isDuplicateThreadName}
              errorFeedback={
                errors.name || (isDuplicateThreadName ? 'The thread name is taken' : '')
              }
            />
          </NameWrapper>
          <AdvancedSelect
            data-cy="amp-create-thread-type"
            id="type"
            label="Thread Type"
            multiple={false}
            onChange={handleChange}
            placeholder="Select"
            value={values.type}
            isError={Boolean(errors.type) && touched.type}
            errorFeedback={errors.type}
            options={[
              {
                display: 'Adjudication',
                value: 'adjudication',
              },
              {
                display: 'Intro',
                value: 'intro',
              },
              {
                display: 'Outro',
                value: 'outro',
              },
              {
                display: 'Troubleshooting',
                value: 'troubleshooting',
              },
            ]}
          />
        </>
      </Modal>
    </ModalController>
  )
}

const NameWrapper = styled.div({
  marginBottom: 16,
})

export { CreateThreadModal, CreateThreadModalProps }
