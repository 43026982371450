import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import { validatePassword } from '../utils/validate'
import images from '../images'

const DISPLAY_TEXT = Object.freeze({
  PASSWORD_STRENGTH: 'Password strength',
  WEAK: 'Weak',
  STRONG: 'Strong',
  CRITERIA_LENGTH: 'Must be at least 8 characters',
  CHAR_INVALID: 'Must not include invalid characters',
  CRITERIA_CHARS: 'Must contain all of these:',
  CHAR_LETTER: 'Letters',
  CHAR_SYMBOLS: 'At least 1 special character (excluding " \\)',
  CHAR_NUMBERS: 'Numbers',
})

type PasswordContainsType = Record<string, boolean | null>

interface OP {
  word: string
  isVisible: boolean
}

type PasswordIndicatorProps = OP

const PasswordIndicator: FC<PasswordIndicatorProps> = ({ word, isVisible }) => {
  const [isStrong, setIsStrong] = useState<boolean | null>(null)
  const [passwordContains, setPasswordContains] = useState<PasswordContainsType>({
    length: null,
    strength: null,
    letter: null,
    symbol: null,
    number: null,
    invalid: null,
  })

  useEffect(() => {
    validateInput(word)
  }, [word])

  const validateInput = (input: string): void => {
    setIsStrong(Boolean(validatePassword(input, 'STRENGTH') && validatePassword(input, 'LENGTH')))
    setPasswordContains({
      length: validatePassword(input, 'LENGTH'),
      strength: validatePassword(input, 'STRENGTH'),
      letter: validatePassword(input, 'LETTER'),
      symbol: validatePassword(input, 'SYMBOL'),
      number: validatePassword(input, 'NUMBER'),
      valid: validatePassword(input, 'VALID'),
      invalid: validatePassword(input, 'INVALID'),
    })
  }

  if (!isVisible) return null

  return (
    <Indicator>
      <Rating isStrong={isStrong}>
        {DISPLAY_TEXT.PASSWORD_STRENGTH}: {isStrong ? DISPLAY_TEXT.STRONG : DISPLAY_TEXT.WEAK}
      </Rating>
      <List>
        <ListItems active={!isStrong || Boolean(!passwordContains.valid)}>
          <ListItem isValid={passwordContains.length}>{DISPLAY_TEXT.CRITERIA_LENGTH}</ListItem>
          <ListItem isValid={!passwordContains.invalid}>{DISPLAY_TEXT.CHAR_INVALID}</ListItem>
          <ListItem isValid={passwordContains.strength}>
            {DISPLAY_TEXT.CRITERIA_CHARS}
            <CharsList>
              <CharsListItem isValid={passwordContains.letter}>
                {DISPLAY_TEXT.CHAR_LETTER}
              </CharsListItem>
              <CharsListItem isValid={passwordContains.symbol}>
                {DISPLAY_TEXT.CHAR_SYMBOLS}
              </CharsListItem>
              <CharsListItem isValid={passwordContains.number}>
                {DISPLAY_TEXT.CHAR_NUMBERS}
              </CharsListItem>
            </CharsList>
          </ListItem>
        </ListItems>
      </List>
    </Indicator>
  )
}

const Indicator = styled.div({
  fontSize: '0.75rem',
  lineHeight: '1.25rem',
})

const Rating = styled.div<{ isStrong: boolean | null }>(({ isStrong }) => ({
  fontSize: 14,
  fontWeight: 600,
  color: isStrong ? COLOR.STATE_SUCCESS : COLOR.STATE_DANGER,
}))

const List = styled.ul({
  padding: '0',
  marginTop: '0',
})

const setListItemColorAttr = (isValid: boolean | null): string => {
  if (isValid === null) return 'inherit'
  return isValid ? COLOR.STATE_SUCCESS : COLOR.STATE_DANGER
}

const ListItem = styled.li<{ isValid: boolean | null }>(
  {
    lineHeight: '1.38rem',
    fontSize: 14,
  },
  ({ isValid }) => ({
    color: setListItemColorAttr(isValid),
    '::before': {
      content: `url(${isValid ? images.checkmark : images.crossmark})`,
      marginRight: '7px',
      width: '8px',
      height: '8px',
      transform: 'translateX(-20px)',
    },
  }),
)

const ListItems = styled.div<{ active: boolean }>(
  {
    height: 'auto',
    overflowY: 'hidden',
    transition: 'max-height 350ms ease-in-out',
  },
  ({ active }) => ({
    maxHeight: active ? 200 : 0,
  }),
)

const CharsList = styled.ul({
  listStyle: 'none',
  padding: '0',
})

const CharsListItem = styled(ListItem)({
  marginLeft: '1rem',
})

export { PasswordIndicator }
