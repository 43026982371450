import * as Yup from 'yup'

const schema = Yup.object()
  .shape({
    paymentId: Yup.string().required('Required').default(''),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export { schema }
export type { Values }
