import type { VFC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { When } from 'react-if'
import { useDataTableContext } from '../data-table-context'
import { Modal, ModalController } from '../../modal'
import { InlineAlert, InlineAlertColor } from '../../inline-alert'
import { Pin, PinSolid, Warning } from '../../../tokens/icons'
import { Stack, StackAlign, StackDirection } from '../../stack'
import { Box } from '../../box'
import { MenuCheckbox, MenuButtonItem } from '../../menu-item'

export const ColumnConfiguration: VFC = () => {
  const {
    'data-cy': dataCy,
    table,
    hasConfigurableColumns,
    isConfiguringColumns,
    setIsConfiguringColumns,
  } = useDataTableContext()

  const isSomeColumnsVisible = table.getIsSomeColumnsVisible()
  const isAllColumnsVisible = table.getIsAllColumnsVisible()

  const sortedColumns = [...table.getAllLeafColumns()]
    .sort((a, b) => a.getPinnedIndex() - b.getPinnedIndex())
    .sort((a, b) => Number(b.getIsPinned() === 'left') - Number(a.getIsPinned() === 'left'))

  return (
    <When condition={hasConfigurableColumns}>
      <ModalController isOpen={isConfiguringColumns}>
        <Modal
          data-cy={dataCy && `${dataCy}:column-configuration-modal`}
          heading="Configure Columns"
          primaryButtonProps={{
            text: 'Done',
            onClick: () => setIsConfiguringColumns(false),
            isDisabled: !isSomeColumnsVisible,
          }}
          {...(isSomeColumnsVisible
            ? {
                onDismissRequest: () => setIsConfiguringColumns(false),
              }
            : {
                footerContent: (
                  <InlineAlert color={InlineAlertColor.yellow} icon={Warning}>
                    You must have at least one column selected.
                  </InlineAlert>
                ),
              })}
          size="sm"
        >
          <Stack direction={StackDirection.column} spacing={2} align={StackAlign.stretch}>
            <div>
              Select columns you want to display and pin columns you want to lock to the left.
            </div>
            <Box padding={1}>
              <MenuCheckbox
                data-cy="menu-checkbox"
                checked={isAllColumnsVisible}
                indeterminate={!isAllColumnsVisible && isSomeColumnsVisible}
                onChange={table.getToggleAllColumnsVisibilityHandler()}
              >
                All Columns
              </MenuCheckbox>
              {sortedColumns.map((column) => {
                const isPinned = column.getIsPinned() !== false
                const canPin = column.getCanPin()
                const isColumnVisible = column.getIsVisible()
                const isPinningDisabled = !isColumnVisible || !column.getCanPin()
                return (
                  <ColumnConfigurationRow key={column.id}>
                    <ColumnConfigurationToggle>
                      <MenuCheckbox
                        data-cy="menu-checkbox-item"
                        checked={isColumnVisible}
                        onChange={column.getToggleVisibilityHandler()}
                        indentLevel={1}
                        disabled={!column.getCanHide()}
                      >
                        {column.columnDef.header ? column.columnDef.header : `[${column.id}]`}
                      </MenuCheckbox>
                    </ColumnConfigurationToggle>
                    <ColumnConfigurationPin isPinned={isPinned} isDisabled={isPinningDisabled}>
                      {isPinned ? (
                        <MenuButtonItem
                          onClick={() => column.pin(false)}
                          icon={PinSolid}
                          disabled={isPinningDisabled}
                          tooltip={!canPin ? 'Always Pinned' : ''}
                        />
                      ) : (
                        <MenuButtonItem
                          onClick={() => column.pin('left')}
                          icon={Pin}
                          disabled={isPinningDisabled}
                          tooltip={!canPin ? 'Always Pinned' : ''}
                        />
                      )}
                    </ColumnConfigurationPin>
                  </ColumnConfigurationRow>
                )
              })}
            </Box>
          </Stack>
        </Modal>
      </ModalController>
    </When>
  )
}

const ColumnConfigurationToggle = styled.div({
  flex: 1,
})

const ColumnConfigurationPin = styled.div<{ isPinned: boolean; isDisabled: boolean }>(
  ({ isPinned, isDisabled }) => ({
    flex: 0,
    opacity: isPinned ? 1 : 0.25,
    ...(!isDisabled && {
      '&:hover': {
        opacity: 1,
      },
    }),
  }),
)

const ColumnConfigurationRow = styled.div({
  display: 'flex',
})
