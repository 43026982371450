import type {
  ProductMappedRecord,
  StoreResponse,
  WarrantyStatusesResponse,
  MappingStatusesResponse,
} from '@helloextend/extend-api-rtk-query'
import { faker } from '@faker-js/faker/locale/en'

export const generateProductSearchResponse = (numProducts: number): ProductMappedRecord[] => {
  const productArr = Array<ProductMappedRecord>(numProducts)
  const mockStatus = 'review_required'
  for (let i = 0; i < numProducts; i += 1) {
    const mockStore = faker.company.name()
    productArr[i] = {
      title: faker.commerce.productName(),
      product_id: faker.datatype.uuid(),
      store_name: mockStore,
      store_id: mockStore,
      warranty_status: mockStatus,
      extend_category: `category_${numProducts - i}`,
      taxonomy_id: faker.datatype.uuid(),
      store_id_warranty_status: `${mockStore}:${mockStatus}`,
      store_id_mapping_status: `${mockStore}:${mockStatus}`,
      last_updated_by: faker.name.firstName(),
      last_updated_at: faker.date.recent().toUTCString(),
      mapping_status: mockStatus,
      base_plan: 'B5-VEAPS',
      description: `description ${i}`,
      price: faker.commerce.price(),
      sku: `BHWLS11KF172${i}`,
      evaluation_code: 'valid',
      meta: '',
      action_state: 'validation_required',
      merchant_category: `merch cat ${i}`,
    }
  }
  return productArr
}

export const generateStoreResponse = (overides: Partial<StoreResponse> = {}): StoreResponse => {
  const merchArr = Array(5).fill({
    store_id: faker.datatype.uuid(),
    store_name: faker.company.name(),
    product_count_by_category: { x_key: 'x_value' },
  })
  return {
    store_list: merchArr,
    ...overides,
  }
}

export const generateWarrantyStatusResponse = (
  overides: Partial<WarrantyStatusesResponse> = {},
): WarrantyStatusesResponse => {
  const warrantyStatusArr = Array(5).fill(faker.datatype.string())
  return {
    warranty_status: warrantyStatusArr,
    ...overides,
  }
}

export const generateMappingStatusResponse = (
  overides: Partial<MappingStatusesResponse> = {},
): MappingStatusesResponse => {
  const mappingStatusArr = Array(5).fill(faker.datatype.string())
  return {
    mapping_status: mappingStatusArr,
    ...overides,
  }
}
