import type { FC } from 'react'
import React from 'react'
import { useToggle } from '@helloextend/client-hooks'
import type { Product } from '@helloextend/extend-api-client'
import { useDeleteSubproductMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import { ModalController } from '@helloextend/zen'
import { SectionContainer } from '../../../../../../../../../components/section-container'
import { AddSubproductItemModal } from '../add-subproduct-item-modal/add-subproduct-item-modal'
import { BundleManagementTable } from '../bundle-management-table/bundle-management-table'

type BundleManagementProps = {
  product: Product
  refetchProduct: () => void
}

const BundleManagement: FC<BundleManagementProps> = ({ product, refetchProduct }) => {
  const [isModalOpen, { on: toggleModalOn, off: toggleModalOff }] = useToggle(false)

  const { toastCompleted, toastError } = useStandardToast()

  const [deleteSubproduct, { isLoading: isDeletingProduct }] = useDeleteSubproductMutation()

  const handleDeleteItem = async (referenceId: string): Promise<void> => {
    try {
      await deleteSubproduct({
        storeId: product.storeId,
        productId: product.referenceId,
        subproductId: referenceId,
      }).unwrap()
      toastCompleted('Bundle Item has been successfully deleted.')
      refetchProduct()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      toastError('Something went wrong. Please try again.')
    }
  }

  return (
    <SectionContainer title="Bundle Management">
      <BundleManagementTable
        subproducts={product?.subproducts || []}
        handleDeleteItem={handleDeleteItem}
        toggleModalOn={toggleModalOn}
        isDeletingProduct={isDeletingProduct}
      />
      <ModalController isOpen={isModalOpen}>
        <AddSubproductItemModal
          toggleModalOff={toggleModalOff}
          product={product}
          refetchProduct={refetchProduct}
        />
      </ModalController>
    </SectionContainer>
  )
}

export { BundleManagement }
