import { combineReducers } from 'redux'
import error from './error'
import isLoading from './is-loading'
import isSuccess from './is-success'
import type { ByIdReducerState } from './by-id'
import byId from './by-id'
import type { ErrorReducerState } from '../../types/error'
import type { Action } from '../actions'

export interface ReducerState {
  byId: ByIdReducerState
  error: ErrorReducerState
  isLoading: boolean
  isSuccess: boolean
}

export default combineReducers<ReducerState, Action>({
  error,
  isLoading,
  byId,
  isSuccess,
})
