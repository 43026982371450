export type InstoreUserUploadGetS3UrlResponse = {
  url: string
}

// versions specific to in-store node core service
export const ApiVersions = Object.freeze({
  LATEST: 'latest',
  2022_02_01: '2022-02-01',
} as const)
export type ApiVersion = typeof ApiVersions[keyof typeof ApiVersions]
