import type { VFC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../../tokens/colors'
import { Pagination } from './pagination'
import { useDataTableContext } from '../data-table-context'

export const Footer: VFC = () => {
  const { 'data-cy': dataCy } = useDataTableContext()

  return (
    <StyledFooter data-cy={dataCy && `${dataCy}:footer`}>
      <Pagination />
    </StyledFooter>
  )
}

const StyledFooter = styled.div({
  flexShrink: 0,
  display: 'flex',
  flexWrap: 'wrap',
  gap: '12px 24px',
  borderColor: COLOR.NEUTRAL[300],
  borderStyle: 'solid',
  borderWidth: '0 1px 1px',
  padding: '10px',
  background: COLOR.NEUTRAL[100],
})
