import * as selectors from './selectors'
import reducer from './reducers'
import sagas from './sagas'
import { actions } from './actions'

export type {
  Action as ServiceOrdersAction,
  ActionType as ServiceOrdersActionType,
} from './actions'
export type { ReducerState as ServiceOrdersReducerState } from './reducers'

export {
  actions as serviceOrdersActions,
  reducer as serviceOrdersReducer,
  sagas as serviceOrdersSagas,
  selectors as serviceOrdersSelectors,
}
