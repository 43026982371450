import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { BadgeProps, BadgeEmphasis, ColorArgs, MeasurementArgs } from './badge-types'
import { getColors, getMeasurements } from './badge-utils'
import { Icon, IconSize } from '../icon'

const Badge: FC<BadgeProps> = ({
  color = 'neutral',
  details,
  emphasis = 'medium',
  invert = false,
  icon,
  size = 'regular',
  text,
  onClick,
  'data-cy': dataCy,
}) => {
  const colors = getColors(color, invert, emphasis)
  const measurements = getMeasurements(size)
  return (
    <StyledBadge
      data-cy={dataCy}
      colors={colors}
      measurements={measurements}
      emphasis={emphasis}
      onClick={onClick}
      hasOnClick={!!onClick}
      hasIcon={!!icon}
    >
      {icon && (
        <IconWrapper measurements={measurements}>
          <Icon icon={icon} size={IconSize.xsmall} color={colors.contentColor} />
        </IconWrapper>
      )}
      <div>
        {text && (
          <Text data-cy={dataCy && `${dataCy}:text`}>
            {text}
            {details && ': '}
          </Text>
        )}
        {details && <span data-cy={dataCy && `${dataCy}:details`}>{details}</span>}
      </div>
    </StyledBadge>
  )
}

const StyledBadge = styled.div<{
  colors: ColorArgs
  emphasis: BadgeEmphasis
  measurements: MeasurementArgs
  hasOnClick: boolean
  hasIcon: boolean
}>(({ colors, emphasis, measurements, hasOnClick, hasIcon }) => ({
  /* Base styles */
  display: 'inline-flex',
  fontFamily: '"Nunito Sans", sans-serif',
  padding: `3px ${emphasis === 'low' ? '0' : '8px'}`,
  borderRadius: 12,
  alignItems: 'baseline',
  alignSelf: 'center',
  textAlign: 'left',

  /* Hover and Active styles */
  ...(hasOnClick && {
    cursor: 'pointer',
    ...(emphasis === 'low' && {
      '&:hover:not(:active)': {
        filter: 'brightness(1.2)',
      },
    }),
    ...(emphasis === 'medium' && {
      '&:hover:not(:active)': {
        filter: 'brightness(0.96)',
      },
    }),
    ...(emphasis === 'high' && {
      '&:hover:not(:active)': {
        filter: 'brightness(1.12)',
      },
    }),
  }),

  /* Colors styles */
  backgroundColor: colors.backgroundColor,
  color: colors.contentColor,

  /* Size styles */
  fontSize: measurements.fontSizePx,
  lineHeight: measurements.lineHeight,

  /* Low emphasis dot styles */
  ...(emphasis === 'low' &&
    !hasIcon && {
      '&::before': {
        content: '""',
        flexShrink: 0,
        alignSelf: 'start',
        borderRadius: '50%',
        marginRight: 4,
        marginTop: measurements.dotTopMarginPx,
        width: measurements.dotSizePx,
        height: measurements.dotSizePx,
        backgroundColor: colors.dotColor,
      },
    }),

  /* Fix SVG alignment */
  '& svg': {
    display: 'block',
  },
}))

const IconWrapper = styled.div<{
  measurements: MeasurementArgs
}>(({ measurements }) => ({
  alignSelf: 'start',
  width: measurements.iconWrapperSizePx,
  height: measurements.iconWrapperSizePx,
  marginRight: 4,
  display: 'grid',
  placeContent: 'center',
}))

const Text = styled.span({
  fontWeight: 800,
})

export { Badge, BadgeProps }
