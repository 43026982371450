import { TextEncoder } from 'text-encoding'
import * as base64 from './base64'
import * as random from './random'

// Based off auth0 impelementation of msCrypto
// https://github.com/auth0/auth0-spa-js/blob/67bc0b92faf9d7749b2c8d5431d53c591f45dfa5/src/utils.ts#L148-L178
function getCrypto(): Crypto {
  // IE11 uses msCrypto
  return (window.crypto || (window.msCrypto as any)) as Crypto
}

// msCrypto (IE11) uses the old spec, which is not Promise based.
// https://msdn.microsoft.com/en-us/expression/dn904640(v=vs.71)
// Instead of returning a promise, it returns a CryptoOperation
// with a result property in it.
// The various events need to be handled. These events just call resolve
// or reject depending on their intention.
async function sha256Digest(message: string): Promise<ArrayBuffer> {
  const digestOperation: Promise<ArrayBuffer> | any = getCrypto().subtle.digest(
    'SHA-256',
    new TextEncoder().encode(message),
  )

  // IE11 check
  if (window.msCrypto as any) {
    return new Promise((res, rej) => {
      digestOperation.oncomplete = (e: any) => {
        res(e.target.result)
      }

      digestOperation.onerror = (e: ErrorEvent) => {
        rej(e.error)
      }

      digestOperation.onabort = () => {
        rej(new Error('The digest operation was aborted'))
      }
    })
  }

  return digestOperation
}

export interface PKCEKeyPair {
  codeVerifier: string
  codeChallenge: string
}

export async function generateKeyPair(): Promise<PKCEKeyPair> {
  const byteArray = random.randomBytes(64)
  const codeVerifier = base64.escape(arrayBufferToBase64(byteArray))
  // code challenge
  const digest = await sha256Digest(codeVerifier)
  const base64Digest = arrayBufferToBase64(digest)
  const codeChallenge = base64.escape(base64Digest)
  return { codeVerifier, codeChallenge }
}

// https://stackoverflow.com/a/9458996
export function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}
