import type { FC } from 'react'
import React, { useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { InlineAlert, InlineAlertColor, COLOR } from '@helloextend/zen'
import type { PromptType, ThreadResponse } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import { MessageTypeDropdown } from '../message-type-dropdown'
import { Divider } from '../../../../../components/divider'
import { KaleyText } from '../kaley-text'
import { CustomerResponse } from '../customer-response'
import { ThreadConnectorDropdown } from '../thread-connector-dropdown'
import {
  getIsReplacementReply,
  getMessageBlockTitle,
  getMessageBlockTypeFromScriptItem,
  MessageBlockTypes,
} from '../../utils'
import type { SelectedMessageBlock } from '../../../../../store/slices/amp-slice'
import {
  removeMessageBlockFromThread,
  resetThread,
  setIsEditorPanelVisible,
  setSelectedMessageBlock,
} from '../../../../../store/slices/amp-slice'
import { MessageBlockImageUploader } from '../message-block-image-uploader'
import { ConfirmationModal } from '../confirmation-modal'
import * as selectors from '../../../../../reducers/selectors'
import type { RootState } from '../../../../../reducers'

type MessageBlockEditorProps = {
  thread: ThreadResponse
  messageBlock: SelectedMessageBlock
  isThreadStructureLocked: boolean
  isThreadEditor?: boolean
}

const MessageBlockEditor: FC<MessageBlockEditorProps> = ({
  thread,
  messageBlock,
  isThreadStructureLocked,
  isThreadEditor = true,
}) => {
  const { reply } = messageBlock.script

  const dispatch = useDispatch()

  const { toastError } = useStandardToast()

  const [isConfirmDeleteMessageBlockModalVisible, setIsConfirmDeleteMessageBlockModalVisible] =
    useState(false)

  const selectedMessageBlockIndex = useSelector((state: RootState) =>
    selectors.getSelectedMessageBlockIndex(state),
  )

  const messageBlockType = getMessageBlockTypeFromScriptItem(messageBlock.script)
  const messageType = !getIsReplacementReply(reply) ? getMessageBlockTitle(reply.prompt?.type) : ''
  const prompt = getIsReplacementReply(reply)
    ? { type: 'replacement' as PromptType, slot: 'REPLACEMENT' }
    : reply?.prompt

  const slot = prompt?.slot ?? ''

  const isStatementAlertEnabled = messageType === 'Statement' // not ideal logic, but this is shorter than checking prompt.type is NOT a bunch of other types
  const isImageUploadEnabled = prompt?.type === 'imageUpload'

  const toggleConfirmDeleteMessageBlockModal = (): void => {
    setIsConfirmDeleteMessageBlockModalVisible(!isConfirmDeleteMessageBlockModalVisible)
  }

  const hideDeleteMessageBlockModal = (): void => {
    if (isConfirmDeleteMessageBlockModalVisible) {
      toggleConfirmDeleteMessageBlockModal()
    }
  }

  const handleConfirmDeleteMessageBlock = async (): Promise<void> => {
    try {
      if (selectedMessageBlockIndex === null)
        throw new Error('handleConfirmDeleteMessageBlock: selectedMessageBlockIndex is null')
      batch(() => {
        dispatch(removeMessageBlockFromThread(selectedMessageBlockIndex))
        dispatch(setIsEditorPanelVisible(false))
        dispatch(setSelectedMessageBlock(null))
        // Only if this is the last message block, call resetThread action
        if (thread.type === 'single_use' && selectedMessageBlockIndex === 0) {
          dispatch(resetThread())
        }
      })
      hideDeleteMessageBlockModal()
    } catch (error: unknown) {
      toastError('Something went wrong. Please try again.')
      hideDeleteMessageBlockModal()
    }
  }

  return (
    <>
      <ConfirmationModal
        data-cy="delete-message-block-confirmation-modal"
        isVisible={isConfirmDeleteMessageBlockModalVisible}
        onCancel={toggleConfirmDeleteMessageBlockModal}
        onConfirm={handleConfirmDeleteMessageBlock}
        isProcessing={false}
        confirmButtonColor="red"
        confirmButtonText="Delete"
        heading="Delete message block"
        description="This action cannot be undone. Are you sure you want to continue?"
        size="sm"
      />
      <EditorContainer data-cy="message-block-editor">
        <MessageTypeDropdown
          index={messageBlock.index}
          value={messageType}
          isDisabled={isThreadStructureLocked}
          handleDeleteButtonClick={toggleConfirmDeleteMessageBlockModal}
        />
        {isStatementAlertEnabled && (
          <StatementWrapper>
            <InlineAlert color={InlineAlertColor.blue} data-cy="message-block-editor-inline-alert">
              Statement Message block can only be used at the end of a conversation.
            </InlineAlert>
          </StatementWrapper>
        )}
        <Divider color={COLOR.NEUTRAL[300]} />
        <KaleyWrapper>
          <KaleyText
            messageBlock={messageBlock}
            isThreadStructureLocked={isThreadStructureLocked}
          />
        </KaleyWrapper>
        {isThreadEditor && messageBlockType !== MessageBlockTypes.statement && (
          <ThreadConnectorDropdown
            value={slot}
            isDisabled={isThreadStructureLocked}
            messageBlockType={messageBlockType}
          />
        )}
        {isImageUploadEnabled && (
          <ImageUploadWrapper>
            <MessageBlockImageUploader messageBlock={messageBlock} />
          </ImageUploadWrapper>
        )}
        <CustomerResponse
          thread={thread}
          messageBlock={messageBlock}
          messageBlockIndex={selectedMessageBlockIndex}
          isThreadStructureLocked={isThreadStructureLocked}
        />
      </EditorContainer>
    </>
  )
}

const EditorContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  padding: '0px 32px',
})

const StatementWrapper = styled.div({
  borderRadius: '6',
  padding: '16',
  margin: '10px 0 10px 0',
})

const KaleyWrapper = styled.div({
  padding: '24px 0 32px 0',
})

const ImageUploadWrapper = styled.div({
  padding: '24px 0 32px 0',
})

export { MessageBlockEditor, MessageBlockEditorProps }
