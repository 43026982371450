import type { ClaimPhotoDetail } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetch = (contractId: string, claimId: string, accessToken: string) =>
  ({
    type: 'CONTRACTS_CLAIMS_PHOTOS_FETCH',
    payload: { contractId, claimId, accessToken },
  } as const)

export const fetchRequest = () => ({ type: 'CONTRACTS_CLAIMS_PHOTOS_FETCH_REQUEST' } as const)

export const fetchSuccess = (claimId: string, photoDetails: ClaimPhotoDetail[]) =>
  ({
    type: 'CONTRACTS_CLAIMS_PHOTOS_FETCH_SUCCESS',
    payload: { claimId, photoDetails },
  } as const)

export const fetchFailure = createActionFailure('CONTRACTS_CLAIMS_PHOTOS_FETCH_FAILURE')
