import type { FC } from 'react'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@helloextend/zen'
import { Add } from '@helloextend/zen/src/tokens/icons'
import type { RuleStatus } from '@helloextend/extend-api-rtk-query'
import { addRuleToRuleset } from '../../../../../store/slices/amp-slice'

type AddRuleButtonProps = {
  rulesetType: RuleStatus
  addRuleKey: () => void
}

const AddRuleButton: FC<AddRuleButtonProps> = ({ rulesetType, addRuleKey }) => {
  const dispatch = useDispatch()

  const handleButtonClick = (): void => {
    addRuleKey()
    dispatch(
      addRuleToRuleset({
        rule: {
          conditions: [{ script: -1, value: [] }],
        },
        rulesetType,
      }),
    )
  }

  return (
    <Button
      color="blue"
      emphasis="low"
      icon={Add}
      text="Add Rule"
      data-cy={`add-${rulesetType}-rule-button`}
      onClick={handleButtonClick}
    />
  )
}

export { AddRuleButton }
