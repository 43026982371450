import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import { AdvancedSelect, Paragraph, getFormattedValue } from '@helloextend/zen'
import { Visibility, VisibilityOff } from '@helloextend/zen/src/tokens/icons'
import { validate } from '@helloextend/client-utils'
import type { FormikHandlers } from 'formik'
import { useGetShippingProtectionPlanDetailsQuery } from '@helloextend/extend-api-rtk-query'
import { listToAdvancedSelectOptions } from '../../../../../../../../utils/form-utils'

type ShippingProtectionPlanDropdownProps = {
  onChange: FormikHandlers['handleChange']
  value: string
  isError?: boolean
  errorFeedback?: string
  options?: string[]
  onBlur?: (e: Event) => void
  isLoading?: boolean
  label?: string
  id?: string
  dataCy?: string
  isPlanDetailsDisplayed?: boolean
}
/**
 * select fields required to toggle shippingProtection "enabled" property
 */
const ShippingProtectionPlanDropdown: FC<ShippingProtectionPlanDropdownProps> = ({
  onChange,
  value,
  isError,
  errorFeedback,
  options,
  isLoading,
  onBlur,
  label = 'Plan ID',
  id = 'planId',
  dataCy = 'planId-dropdown',
  isPlanDetailsDisplayed = false,
}) => {
  const [isGuidSPPlansFiltered, setIsGuidSPPlansFiltered] = useState(false)

  const filteredOptions = useMemo(
    () =>
      isGuidSPPlansFiltered ? options?.filter((plan) => !validate.isValidUuid(plan)) : options,
    [isGuidSPPlansFiltered, options],
  )

  const { data: spDetails, isLoading: isSpDetailsLoading } =
    useGetShippingProtectionPlanDetailsQuery(value, { skip: !isPlanDetailsDisplayed || !value })

  const spAdditionalDetails = useMemo(
    () => (
      <>
        {isSpDetailsLoading ? (
          <Paragraph>Loading...</Paragraph>
        ) : (
          <>
            <Paragraph>
              shipCoverageMax:{' '}
              {spDetails?.shipCoverageMax ? (
                getFormattedValue(spDetails?.shipCoverageMax?.toString())
              ) : (
                <span>&#8212;</span>
              )}
            </Paragraph>
            <Paragraph>
              productCategory:{' '}
              {spDetails?.productCategory ? spDetails?.productCategory : <span>&#8212;</span>}
            </Paragraph>
          </>
        )}
      </>
    ),
    [isSpDetailsLoading, spDetails],
  )

  return (
    <>
      <AdvancedSelect
        label={label}
        id={id}
        isNotClearable
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder="Select a Plan ID"
        isError={isError}
        isLoading={isLoading}
        showSearch
        errorFeedback={errorFeedback}
        options={listToAdvancedSelectOptions(
          filteredOptions || [],
          isPlanDetailsDisplayed ? value : undefined,
          isPlanDetailsDisplayed ? spAdditionalDetails : undefined,
        )}
        multiple={false}
        data-cy={dataCy}
        pageSize={50}
        footerActions={[
          {
            onClick: () => setIsGuidSPPlansFiltered(!isGuidSPPlansFiltered),
            children: isGuidSPPlansFiltered ? 'Show Test Plans' : 'Hide Test Plans',
            icon: isGuidSPPlansFiltered ? Visibility : VisibilityOff,
            'data-cy': 'toggle-test-plans',
            disableAutoDismiss: true,
          },
        ]}
      />
    </>
  )
}

export { ShippingProtectionPlanDropdown }
