import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@helloextend/client-branding'

type CustomizeIconProps = {
  fill?: string
}

/**
 * @deprecated Use Zen Icon component instead: `import { Icon } from '@helloextend/zen'`
 */
const CustomizeIcon: FC<CustomizeIconProps> = ({ fill = COLOR.BLACK_MUTED }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
    <path
      d="M11.24 7.565a2.596 2.596 0 00-2.37-.705l-.549-.548 1.749-1.747 1.583-1.583a.39.39 0 000-.55L9.57.346a.39.39 0 00-.552 0L7.436 1.93l-1.75 1.75-.549-.548A2.594 2.594 0 001.624.189a.39.39 0 00-.13.636L2.755 2.08l-.678.678L.823 1.503a.39.39 0 00-.636.13 2.594 2.594 0 002.942 3.505l.548.548-2.266 2.261a.422.422 0 00-.052.065s0 .012-.01.018a.405.405 0 00-.03.064v.013l-.943 3.026a.39.39 0 00.487.488l3.023-.939h.013a.415.415 0 00.065-.028l.018-.01a.386.386 0 00.065-.052L6.312 8.32l.548.548a2.594 2.594 0 003.515 2.941.39.39 0 00.13-.636L9.245 9.92l.676-.675 1.255 1.255a.388.388 0 00.636-.13 2.594 2.594 0 00-.573-2.805zM9.291 1.172l1.536 1.533-1.039 1.038L8.26 2.204l1.032-1.032zM3.522 4.43a.39.39 0 00-.389-.096A1.818 1.818 0 01.776 2.551l1.025 1.025a.39.39 0 00.552 0l1.222-1.221a.39.39 0 000-.551L2.549.779a1.818 1.818 0 011.785 2.356.389.389 0 00.096.39l.705.704-.909.908-.703-.706zm2.438.073l1.75-1.747 1.536 1.531-1.75 1.75L3.77 9.763 2.236 8.226 5.96 4.504zm-4.62 6.154l.526-1.7 1.169 1.168-1.696.532zm8.856-2.235a.39.39 0 00-.55 0L8.423 9.644a.39.39 0 000 .55l1.025 1.026a1.816 1.816 0 01-1.784-2.355.39.39 0 00-.098-.39l-.705-.704.909-.908.705.704a.39.39 0 00.39.097A1.818 1.818 0 0111.22 9.45l-1.025-1.026z"
      fill={fill}
    />
  </svg>
)

export { CustomizeIcon }
