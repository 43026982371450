import { querystring } from '@helloextend/client-utils'
import type { ApiResponse } from '../../../extend-api-fetch'
import { get, post } from '../../../extend-api-fetch'
import type {
  Plan,
  PlanDetails,
  PlanSearchResponse,
  PlanCreateRequest,
  PlanFetchAllResponse,
} from '../models'

export async function fetch(id: string, accessToken?: string): Promise<ApiResponse<Plan>> {
  // We need this to prevent API get caching.
  const cc = Date.now()
  return get(`/plans/${id}?cc=${cc}`, { accessToken })
}

export async function fetchAll(
  accessToken: string,
  cursor?: string,
): Promise<ApiResponse<PlanFetchAllResponse>> {
  return get(`/plans${cursor ? `?cursor=${cursor}` : ''}`, { accessToken, apiVersion: 'latest' })
}

export async function fetchDetails(id: string): Promise<ApiResponse<PlanDetails>> {
  return get(`/plans/${id}/details`, { apiVersion: '2021-01-01' })
}

export async function search(
  accessToken: string,
  filter?: string,
  limit?: number,
  cursor?: string,
): Promise<ApiResponse<PlanSearchResponse>> {
  const queryString = querystring.build({ filter, limit, cursor })
  return get(`/plans/search/?${queryString}`, { accessToken })
}

export async function createPlan(
  accessToken: string,
  plan: PlanCreateRequest,
): Promise<ApiResponse<Plan>> {
  return post(`/plans`, { body: plan, accessToken })
}
