import { combineReducers } from 'redux'
import type {
  AuthReducerState,
  PlansReducerState,
  UsersReducerState,
  ServicersReducerState,
  ContractsReducerState,
  ProductsReducerState,
} from '@helloextend/core-api-redux'
import {
  authReducer,
  plansReducer,
  usersReducer,
  servicersReducer,
  contractsReducer,
  productsReducer,
} from '@helloextend/core-api-redux'
import {
  claimsApi,
  contractsApi,
  productsApi,
  serviceOrdersApi,
  plansApi,
  servicersApi,
  productsMappingApi,
  entitlementsApi,
  skusApi,
  claimsAssignmentUsersApi,
  ordersApi,
  incredibotApi,
  conversationsApi,
  claimPhotosApi,
  virtualCardsApi,
  storesApi,
  usersApi,
  shippingProtectionPlansApi,
  refundsApi,
  accountsApi,
  usersV3Api,
  oktaApi,
  grantsApi,
  productsRdbApi,
  productCategoriesRdbApi,
  plansTermsApi,
  wdOrchestratorApi,
} from '@helloextend/extend-api-rtk-query'
import { insuranceProgramsApi } from '@helloextend/extend-api-rtk-query/src/insurance-programs'
import type { MerchantsUserReducerState } from './user'
import user from './user'
import type { LocalServicersReducerState } from './servicers'
import servicersLocal from './servicers'
import type { MerchantsStoreReducerState } from './stores'
import store from './stores'
import type { SupportReducerState } from './support'
import support from './support'
import type { RouterReducerState } from './router'
import router from './router'
import type { SlicesState } from '../store/slices'
import { slicesReducers } from '../store/slices'

export interface RootState extends SlicesState {
  auth: AuthReducerState
  contracts: ContractsReducerState
  products: ProductsReducerState
  plans: PlansReducerState
  stores: MerchantsStoreReducerState
  user: MerchantsUserReducerState
  users: UsersReducerState
  support: SupportReducerState
  servicers: ServicersReducerState
  servicersLocal: LocalServicersReducerState
  router: RouterReducerState
  [serviceOrdersApi.reducerPath]: ReturnType<typeof serviceOrdersApi.reducer>
  [plansApi.reducerPath]: ReturnType<typeof plansApi.reducer>
  [contractsApi.reducerPath]: ReturnType<typeof contractsApi.reducer>
  [productsApi.reducerPath]: ReturnType<typeof productsApi.reducer>
  [claimsApi.reducerPath]: ReturnType<typeof claimsApi.reducer>
  [servicersApi.reducerPath]: ReturnType<typeof servicersApi.reducer>
  [productsMappingApi.reducerPath]: ReturnType<typeof productsMappingApi.reducer>
  [entitlementsApi.reducerPath]: ReturnType<typeof entitlementsApi.reducer>
  [skusApi.reducerPath]: ReturnType<typeof skusApi.reducer>
  [claimsAssignmentUsersApi.reducerPath]: ReturnType<typeof claimsAssignmentUsersApi.reducer>
  [claimPhotosApi.reducerPath]: ReturnType<typeof claimPhotosApi.reducer>
  [ordersApi.reducerPath]: ReturnType<typeof ordersApi.reducer>
  [incredibotApi.reducerPath]: ReturnType<typeof incredibotApi.reducer>
  [insuranceProgramsApi.reducerPath]: ReturnType<typeof insuranceProgramsApi.reducer>
  [conversationsApi.reducerPath]: ReturnType<typeof conversationsApi.reducer>
  [storesApi.reducerPath]: ReturnType<typeof storesApi.reducer>
  [usersApi.reducerPath]: ReturnType<typeof usersApi.reducer>
  [refundsApi.reducerPath]: ReturnType<typeof refundsApi.reducer>
  [shippingProtectionPlansApi.reducerPath]: ReturnType<typeof shippingProtectionPlansApi.reducer>
  [accountsApi.reducerPath]: ReturnType<typeof accountsApi.reducer>
  [virtualCardsApi.reducerPath]: ReturnType<typeof virtualCardsApi.reducer>
  [oktaApi.reducerPath]: ReturnType<typeof oktaApi.reducer>
  [usersV3Api.reducerPath]: ReturnType<typeof usersV3Api.reducer>
  [grantsApi.reducerPath]: ReturnType<typeof grantsApi.reducer>
  [productsRdbApi.reducerPath]: ReturnType<typeof productsRdbApi.reducer>
  [productCategoriesRdbApi.reducerPath]: ReturnType<typeof productCategoriesRdbApi.reducer>
  [plansTermsApi.reducerPath]: ReturnType<typeof plansTermsApi.reducer>
  [wdOrchestratorApi.reducerPath]: ReturnType<typeof wdOrchestratorApi.reducer>
}

export default combineReducers<RootState>({
  auth: authReducer,
  contracts: contractsReducer,
  products: productsReducer,
  plans: plansReducer,
  stores: store,
  user,
  users: usersReducer,
  support,
  servicers: servicersReducer,
  servicersLocal,
  router,
  [serviceOrdersApi.reducerPath]: serviceOrdersApi.reducer,
  [plansApi.reducerPath]: plansApi.reducer,
  [contractsApi.reducerPath]: contractsApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [claimsApi.reducerPath]: claimsApi.reducer,
  [servicersApi.reducerPath]: servicersApi.reducer,
  [productsMappingApi.reducerPath]: productsMappingApi.reducer,
  [entitlementsApi.reducerPath]: entitlementsApi.reducer,
  [skusApi.reducerPath]: skusApi.reducer,
  [virtualCardsApi.reducerPath]: virtualCardsApi.reducer,
  [claimsAssignmentUsersApi.reducerPath]: claimsAssignmentUsersApi.reducer,
  [claimPhotosApi.reducerPath]: claimPhotosApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [incredibotApi.reducerPath]: incredibotApi.reducer,
  [insuranceProgramsApi.reducerPath]: insuranceProgramsApi.reducer,
  [conversationsApi.reducerPath]: conversationsApi.reducer,
  [storesApi.reducerPath]: storesApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [refundsApi.reducerPath]: refundsApi.reducer,
  [shippingProtectionPlansApi.reducerPath]: shippingProtectionPlansApi.reducer,
  [accountsApi.reducerPath]: accountsApi.reducer,
  [oktaApi.reducerPath]: oktaApi.reducer,
  [usersV3Api.reducerPath]: usersV3Api.reducer,
  [grantsApi.reducerPath]: grantsApi.reducer,
  [productsRdbApi.reducerPath]: productsRdbApi.reducer,
  [productCategoriesRdbApi.reducerPath]: productCategoriesRdbApi.reducer,
  [plansTermsApi.reducerPath]: plansTermsApi.reducer,
  [wdOrchestratorApi.reducerPath]: wdOrchestratorApi.reducer,
  ...slicesReducers,
})
