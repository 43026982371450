import type { PlanPricing, PlanMatching, Plan } from '@helloextend/extend-api-client'
import type { Values } from '../pages/admin/plan/schema'
import { schema } from '../pages/admin/plan/schema'

/**
 * @usage maps an array of pricing objects to it's corresponding Pricing object for a POST/PUT request for plans
 * @param array - accepts an array of PlanPricingRow objects
 * @returns an array of PlanPricing objects
 */
const arrayToPlanPricing = (array?: Values['pricing']): PlanPricing[] => {
  if (!array?.length) return []
  return array.map((item) => {
    return {
      price_high: item.priceBandHigh,
      price_low: item.priceBandLow,
      retail_max: item.retailMax,
      retail_min: 0, // Setting to 0 as this is still required in the API
      retail_target: item.retailTarget,
      fixed_price:
        (typeof item.fixedPrice === 'string' && item.fixedPrice !== '') ||
        typeof item.fixedPrice === 'number' // ensure that an existing value isn't being reset
          ? Number(item.fixedPrice)
          : undefined,
    }
  })
}

/**
 * @usage maps an array of pricing objects objects to it's corresponding Matching object for a POST/PUT request for plans
 * @param array - accepts an array of PlanMatchingRow objects
 * @returns an array of PlanMatching objects
 */
const arrayToPlanMatching = (array?: Values['pricing']): PlanMatching[] => {
  if (!array?.length) return []
  return array.map((item) => {
    return {
      cost: item.cost,
      price_high: item.priceBandHigh,
      price_low: item.priceBandLow,
      vendor_sku: item.vendorSku,
    }
  })
}

const arrayToString = (arr: string[]): string => {
  if (!arr?.length) return ''
  return arr.map((id) => id).join(', ')
}

const mapSchemaToValues = (planDetails?: Plan | null): Values => {
  if (!planDetails) return schema.default()
  return {
    id: planDetails.id ?? '',
    name: planDetails.name ?? '',
    secondaryName: planDetails.secondary_name ?? '',
    warrantyCategory: planDetails.contract.category ?? '',
    coverageStarts: planDetails.contract.coverage_starts ?? '',
    coverageIncludes: planDetails.contract.coverage_includes ?? '',
    termLength: planDetails.contract.term_length ?? 0,
    active: planDetails.active ?? false,
    underwriter: planDetails.underwriter ?? '',
    serviceType: planDetails.contract.service_type ?? '',
    replacementType: planDetails.contract.replacement_type ?? '',
    deductible: planDetails.contract.deductible,
    administrator: planDetails.administrator ?? '',
    termsId: planDetails.termsId ?? '',
    servicerId: arrayToString(planDetails?.contract.servicer_ids),
    productNotes: planDetails.product_notes ?? '',
    currencyCode: planDetails.currency_code ?? '',
    allowedRegions: arrayToString(planDetails?.allowed_regions),
    blockedSubDivisions: arrayToString(planDetails?.blocked_sub_division),
    pcmiCategory: planDetails?.contract.pcmi_category ?? '',
    pcmiSubcategory: planDetails?.contract.pcmi_subcategory ?? '',
    tags: (planDetails?.tags as string) ?? '',
    vendor: planDetails?.vendor ?? '',
    productTypes: planDetails?.product_types ?? '',
    repairThreshold: planDetails?.repair_threshold,
    program: planDetails?.program ?? '',
    subProgram: planDetails?.sub_program ?? '',
    planCategory: planDetails?.plan_category ?? '',
    wdTags: arrayToString(planDetails?.wd_tags),
    pricing: planDetails.matching?.map((matching: PlanMatching, index: number) => ({
      vendorSku: matching.vendor_sku,
      priceBandLow: planDetails.pricing?.[index]?.price_low ?? 0,
      priceBandHigh: planDetails.pricing?.[index]?.price_high ?? 0,
      cost: matching.cost,
      retailTarget: planDetails.pricing?.[index]?.retail_target ?? 0,
      fixedPrice: planDetails.pricing?.[index]?.fixed_price,
      retailMax: planDetails.pricing?.[index]?.retail_max ?? 0,
    })),
  }
}

export { arrayToPlanPricing, arrayToPlanMatching, mapSchemaToValues }
