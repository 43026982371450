import * as Yup from 'yup'
import { urlRegExp } from '@helloextend/client-utils/validate'

const schema = Yup.object()
  .shape({
    isBundle: Yup.string().required('Product Type is required'),
    warrantyStatus: Yup.string().required('Matched Status is required'),
    title: Yup.string().required('Product name is required'),
    category: Yup.string().required('Merchant Category is required'),
    priceAmount: Yup.number().required('Product List Price is required').nullable(true),
    currencyCode: Yup.string().required('Currency is required').nullable(true),
    imageUrl: Yup.string()
      .required('Product Image is required')
      .matches(urlRegExp, 'Please enter a valid Image URL'),
    description: Yup.string().notRequired(),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export { schema, Values }
