import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Icon, COLOR, IconSize } from '@helloextend/zen'
import { Close } from '@helloextend/zen/src/tokens/icons'

type ChipProps = {
  label?: string
  text?: string
  isDisabled?: boolean
  isDismissable?: boolean
  onClick?: (e: SyntheticEvent) => void
  onDismiss?: (e: SyntheticEvent) => void
}

/**
 * @deprecated Use Zen Chip component instead: `import { Chip } from '@helloextend/zen'`
 */
const Chip: FC<ChipProps> = ({ label, text, isDisabled, isDismissable, onClick, onDismiss }) => {
  const handleChipClick = (e: SyntheticEvent): void => {
    const target = e.target as HTMLElement
    if (target.tagName === 'path' || target.tagName === 'svg') {
      onDismiss?.(e)
    } else {
      onClick?.(e)
    }
  }

  return (
    <ChipWrapper
      type="button"
      data-cy="chip-button"
      disabled={isDisabled}
      hasIcon={isDismissable}
      onClick={handleChipClick}
    >
      <TextWrapper>
        {label && (
          <Label data-cy="chip-button-label">
            {label}
            {text && <span>:</span>}
          </Label>
        )}
        {text && <span data-cy="chip-button-text">{text}</span>}
      </TextWrapper>
      {isDismissable && (
        <Icon
          data-cy="chip-button-close-icon"
          icon={Close}
          size={IconSize.small}
          color={COLOR.NEUTRAL[400]}
        />
      )}
    </ChipWrapper>
  )
}

const ChipWrapper = styled.button<{ hasIcon?: boolean }>(({ hasIcon }) => ({
  alignItems: 'center',
  backgroundColor: COLOR.NEUTRAL.OPACITY[12],
  borderColor: COLOR.NEUTRAL[300],
  borderStyle: 'solid',
  borderRadius: 16,
  borderWidth: 1,
  color: COLOR.NEUTRAL[800],
  display: 'flex',
  fontSize: 15,
  gap: 4,
  justifyContent: 'space-between',
  lineHeight: '20px',
  padding: hasIcon ? '6px 6px 6px 12px' : '6px 12px',
  transition: '120ms background-color',
  '&:hover': {
    backgroundColor: COLOR.NEUTRAL.OPACITY[25],
    cursor: 'pointer',
  },
  '&:disabled': {
    borderColor: COLOR.NEUTRAL.OPACITY[25],
    color: COLOR.NEUTRAL[500],
    backgroundColor: COLOR.NEUTRAL.OPACITY[12],
    cursor: 'auto',
  },
}))

const TextWrapper = styled.span({
  display: 'flex',
  gap: 4,
})

const Label = styled.span({
  fontWeight: 700,
})

export { Chip }
