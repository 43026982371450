import * as Yup from 'yup'

const depotRepairSchema = Yup.object()
  .shape({
    autoAssignDepotRepairServicer: Yup.boolean().required(),
    primaryDepotRepairServicerId: Yup.string().required('Primary servicer is required'),
  })
  .defined()

const onsiteRepairSchema = Yup.object()
  .shape({
    autoAssignOnsiteServicer: Yup.boolean().required(),
    primaryOnsiteServicerId: Yup.string().required('Primary servicer is required'),
  })
  .defined()

type DepotRepairValues = Yup.InferType<typeof depotRepairSchema>
type OnsiteRepairValues = Yup.InferType<typeof onsiteRepairSchema>

export { depotRepairSchema, DepotRepairValues, onsiteRepairSchema, OnsiteRepairValues }
