import type { FC } from 'react'
import React from 'react'
import { DatePicker } from '@helloextend/merchants-ui'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'

interface DatePickerItemProps {
  label: string
  date?: Date | null
  onChange: (value: number | null) => void
  invalid?: boolean
  errorMessage?: string
  'data-cy'?: string
}

const FormDatePicker: FC<DatePickerItemProps> = ({
  label,
  date,
  onChange,
  invalid,
  errorMessage,
  'data-cy': dataCy,
}) => {
  const handleDateChange = (selected: Date | null): void => {
    onChange(selected?.getTime() || null)
  }

  return (
    <LabelWrapper invalid={invalid}>
      {label && <LabelText>{label}</LabelText>}
      <DatePicker
        data-cy={dataCy}
        maxDate={new Date()}
        onChange={handleDateChange}
        selected={date || null}
        placeHolder=""
        fullWidth
      />
      <ErrorMessage>{(invalid && errorMessage) || ''}</ErrorMessage>
    </LabelWrapper>
  )
}

export { FormDatePicker }

const LabelWrapper = styled.label<{ width?: number; invalid?: boolean }>(
  ({ width, invalid = false }) => ({
    fontFamily: 'Nunito Sans, sans-serif',
    width: width ?? 'inherit',
    '.react-datepicker-wrapper': {
      marginTop: 5,
      input: {
        height: 40,
        border: `1px solid ${invalid ? COLOR.DANGER : COLOR.LIGHT_GRAYISH_BLUE_0} !important`,
        '&:focus': {
          border: `1px solid ${invalid ? COLOR.DANGER : COLOR.BRIGHT_BLUE} !important`,
          boxShadow: `0 0 0 1px inset ${invalid ? COLOR.DANGER : COLOR.BRIGHT_BLUE}`,
          outline: 'none',
        },
      },
    },
  }),
)

const LabelText = styled.div({
  color: COLOR.BLACK_MUTED,
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
})

const ErrorMessage = styled.div({
  color: COLOR.STATE_DANGER,
  fontFamily: 'Nunito Sans',
  fontSize: 12,
  lineHeight: '16px',
  marginBottom: 16,
  marginTop: 8,
})
