import type { SagaIterator } from 'redux-saga'
import { all, takeEvery } from 'redux-saga/effects'
import type { ActionType } from '../actions'
import fetchByClaimId from './fetch-by-claim-id'
import fetchByClaims from './fetch-by-claims'
import approveReplacement from './approve-replacement'
import fulfill from './fulfill'
import startRepair from './start-repair'
import approveForPayment from './approve-for-payment'
import requestForPayment from './request-payment'

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery('SERVICE_ORDERS_FETCH_BY_CLAIMS' as ActionType, fetchByClaims),
    takeEvery('SERVICE_ORDERS_FETCH' as ActionType, fetchByClaimId),
    takeEvery('SERVICE_ORDERS_APPROVE_REPLACEMENT' as ActionType, approveReplacement),
    takeEvery('SERVICE_ORDERS_FULFILL' as ActionType, fulfill),
    takeEvery('SERVICE_ORDERS_START_REPAIR' as ActionType, startRepair),
    takeEvery('SERVICE_ORDERS_APPROVE_FOR_PAYMENT' as ActionType, approveForPayment),
    takeEvery('SERVICE_ORDERS_REQUEST_FOR_PAYMENT' as ActionType, requestForPayment),
  ])
}
