import { createBooleanReducer } from '@helloextend/core-api-redux/src/factories'
import type { Action } from '../actions'

const isSuccess = createBooleanReducer<Action>(
  ['USER_CREATE_SUCCESS', 'USER_UPDATE_SUCCESS'],
  [
    'USER_CREATE_REQUEST',
    'USER_CREATE_FAILURE',
    'USER_CREATE_FLOW_RESET',
    'USER_UPDATE_REQUEST',
    'USER_UPDATE_FAILURE',
    'ACCOUNT_USERS_FETCH_ALL',
    'USER_UPDATE_RESET',
  ],
)

export default isSuccess
