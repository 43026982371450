import type { FC, SyntheticEvent } from 'react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import { useGetServicerQuery, useUpdateServicerMutation } from '@helloextend/extend-api-rtk-query'
import { Button, COLOR, ToastColor, ToastDuration, useToaster } from '@helloextend/zen'
import { schema } from './schema'
import type { Values } from './schema'
import { ServicerPaymentSelect } from '../../../../../components/servicer-payment-select/servicer-payment-select'

const PaymentTab: FC = () => {
  const { servicerId } = useParams<{ servicerId: string }>()
  const { data: servicer } = useGetServicerQuery(servicerId)

  const [updateServicer, { isLoading, isSuccess }] = useUpdateServicerMutation()
  const { toast } = useToaster()
  const initialValues = { paymentPreference: servicer?.settings?.paymentPreference ?? '' }
  const { values, setValues, setFieldValue, submitForm, isValid } = useFormik<Values>({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    validateOnMount: true,
    onSubmit: (formValues) => {
      updateServicer({
        servicerId,
        body: {
          settings: {
            ...(servicer?.settings || {}),
            ...formValues,
          },
        },
      })
    },
  })

  const handleChange = (e: SyntheticEvent<Element>): void => {
    const target = e.currentTarget as HTMLInputElement
    setFieldValue('paymentPreference', target.value)
  }

  useEffect(() => {
    if (isSuccess) {
      toast({
        message: 'Changes saved!',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
    }
  }, [isSuccess, toast])

  const isDisabled =
    !isValid ||
    isLoading ||
    values.paymentPreference === (servicer?.settings.paymentPreference ?? '')

  return (
    <ContentWrapper data-cy="payment-tab">
      <Header>
        <Title>Payment Preferences</Title>
      </Header>
      <SectionHeader>
        Please select how this servicer should be paid for claim expenses.
      </SectionHeader>
      <DropdownWrapper>
        <ServicerPaymentSelect value={values.paymentPreference} onChange={handleChange} />
      </DropdownWrapper>
      <ButtonGroup>
        <Button
          data-cy="cancel-button"
          isDisabled={isDisabled}
          text="Cancel"
          onClick={() => setValues(initialValues)}
          emphasis="medium"
        />
        <Button
          data-cy="save-button"
          isDisabled={isDisabled}
          text="Save"
          type="submit"
          onClick={() => submitForm()}
          isProcessing={isLoading}
        />
      </ButtonGroup>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  marginBottom: 32,
  padding: 40,
  borderRadius: 4,
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  margin: 0,
})

const SectionHeader = styled.p({
  margin: '24px 0px',
  fontSize: 16,
  lineHeight: '20px',
})

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '32px',
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  marginRight: 12,
})

const DropdownWrapper = styled.div({
  width: 200,
})

const ButtonGroup = styled.div({
  display: 'flex',
  gap: 16,
  marginTop: 16,
})

export { PaymentTab }
