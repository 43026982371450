import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
/**
 * @param {number} epochDateSeconds
 * @param {string} datePattern
 * @returns {string}
 */

export const standardDateFormat = 'MMM D YYYY'

export function format(epochDateSeconds: number, datePattern = 'MMMM DD, YYYY'): string {
  if (!epochDateSeconds) {
    return 'Invalid date'
  }

  dayjs.extend(relativeTime)

  // allows for more datePattern options
  dayjs.extend(advancedFormat)
  // allows for converting to a relative timezone; dependent on utc plugin
  dayjs.extend(timezone)
  dayjs.extend(utc)

  return dayjs(adjustDateToMilliseconds(epochDateSeconds)).format(datePattern)
}

export function formatToMilliseconds(dateString: string): number {
  const date = new Date(dateString)

  return date.getTime()
}

export function getRelativeTimeSinceTimestamp(epochDateSeconds: number): string {
  dayjs.extend(relativeTime)
  const date = dayjs(adjustDateToMilliseconds(epochDateSeconds))

  return date.fromNow(true)
}

/**
 * Takes in a time. If it's on the scale of milliseconds, converts it to milliseconds, otherwise leaves it in seconds.
 */
function adjustDateToMilliseconds(time: number): number {
  if (time === 0) return 0
  if (Date.now() / time > 100) {
    return time * 1000
  }
  return time
}

export function monthsToYears(months: number): string {
  if (months <= 0) {
    return '0 years'
  }
  if (months < 12) {
    return '< 1 year'
  }

  return `${Math.floor(months / 12)} year${months >= 24 ? 's' : ''}`
}

// note: this was taken from node-core and used when building the csv for contracts
export function addMonths(date: Date, months: number): Date {
  const newDate = new Date(date)
  const dayOfMonth = newDate.getDate()

  // The JS Date object supports date math by accepting out-of-bounds values for
  // month, day, etc. For example, new Date(2020, 1, 0) returns 31 Dec 2019 and
  // new Date(2020, 13, 1) returns 1 Feb 2021.  This is *almost* the behavior we
  // want except that dates will wrap around the end of a month, meaning that
  // new Date(2020, 13, 31) will return 3 Mar 2021 not 28 Feb 2021 as desired. So
  // we'll default to the end of the desired month by adding 1 to the desired
  // month and using a date of 0 to back up one day to the end of the desired
  // month.
  const endOfDesiredMonth = new Date(newDate.getTime())
  const monthsPlusOne = Math.abs(months + 1) * (Math.sign(months) !== 0 ? Math.sign(months) : 1)
  endOfDesiredMonth.setMonth(newDate.getMonth() + monthsPlusOne, 0)
  const daysInMonth = endOfDesiredMonth.getDate()
  if (dayOfMonth >= daysInMonth) {
    // If we're already at the end of the month, then this is the correct date
    // and we're done.

    // if we're going back over daylight savings, we find the time-zone difference
    // and adjust, so that we don't end up being one day off
    const timezoneDiff = date.getTimezoneOffset() - endOfDesiredMonth.getTimezoneOffset()
    endOfDesiredMonth.setMinutes(endOfDesiredMonth.getMinutes() + timezoneDiff)
    return endOfDesiredMonth
  }
  // Otherwise, we now know that setting the original day-of-month value won't
  // cause an overflow, so set the desired day-of-month. Note that we can't
  // just set the date of `endOfDesiredMonth` because that object may have had
  // its time changed in the unusual case where where a DST transition was on
  // the last day of the month and its local time was in the hour skipped or
  // repeated next to a DST transition.  So we use `date` instead which is
  // guaranteed to still have the original time.
  newDate.setFullYear(endOfDesiredMonth.getFullYear(), endOfDesiredMonth.getMonth(), dayOfMonth)

  // if we're going back over daylight savings, we find the time-zone difference
  // and adjust, so that we don't end up being one day off
  const timezoneDiff = date.getTimezoneOffset() - newDate.getTimezoneOffset()
  newDate.setMinutes(newDate.getMinutes() + timezoneDiff)
  return newDate
}
export const formatDateRange = (
  start?: number,
  end?: number,
  datePattern = standardDateFormat,
): string => {
  if (!start) {
    return ''
  }

  const formattedStart = format(start, datePattern)

  if (!end) {
    return formattedStart
  }

  return `${formattedStart} - ${format(end, datePattern)}`
}

/** Given a date, this function returns a new date that represents the beginning of the day
 * in the given timezone. This would be midnight of the given day: the first millisecond of
 * that day.
 */
export const getStartOfDay = (date: Date): Date => {
  const d = new Date(date)
  d.setHours(0, 0, 0, 0)
  return d
}

/** Given a date, this function returns a new date that represents the end of the day
 * in the given timezone. This would be the millisecond before midnight: the last
 * millisecond of that day.
 */
export const getEndOfDay = (date: Date): Date => {
  const d = new Date(date)
  d.setHours(23, 59, 59, 999)
  return d
}
