import {
  useLazyGetConversationConfigurationQuery,
  useLazyGetConversationQuery,
} from '@helloextend/extend-api-rtk-query'
import type { ConversationConfigurationDetail } from '../types/conversations'

type ConfigurationDetailResult = {
  data: ConversationConfigurationDetail | null
  isLoading: boolean
}

export function useConversationConfigurationDetail(
  configId: string,
  handleFetchComplete: () => void,
): ConfigurationDetailResult {
  const [
    triggerFetchConversationConfiguration,
    {
      data: currentConfiguration,
      isLoading: isLoadingConversationConfiguration,
      isSuccess: isSuccessConversationConfiguration,
    },
  ] = useLazyGetConversationConfigurationQuery()

  const [
    triggerFetchConversation,
    {
      data: currentConversation,
      isLoading: isLoadingConversation,
      isSuccess: isSuccessConversation,
    },
  ] = useLazyGetConversationQuery()

  if (!currentConfiguration && !isLoadingConversationConfiguration) {
    triggerFetchConversationConfiguration(configId)
      .unwrap()
      .then(() => {
        handleFetchComplete()
      })
  }

  if (!currentConversation && !isLoadingConversation && currentConfiguration) {
    triggerFetchConversation(currentConfiguration.conversationId)
  }

  const data: ConversationConfigurationDetail | null = currentConfiguration
    ? {
        ...currentConfiguration,
        conversationTitle: currentConversation?.title ?? '',
      }
    : null

  return {
    data,
    isLoading:
      isLoadingConversationConfiguration ||
      isLoadingConversation ||
      !isSuccessConversationConfiguration ||
      !isSuccessConversation,
  }
}
