import type { ScriptItem } from '@helloextend/extend-api-rtk-query'
import type { MessageBlockTypes } from '../utils'
import { generateDateMessageBlock } from './date'
import { generateFreeformTextMessageBlock } from './freeform-text'
import { generateImageUploadMessageBlock } from './image-upload'
import { generateMultipleChoiceMessageBlock } from './multiple-choice'
import { generateStatementMessageBlock } from './statement'

export const getEmptyBlockByType = (type: MessageBlockTypes): ScriptItem | undefined => {
  const typeToBlockMap: { [key in MessageBlockTypes]: ScriptItem | undefined } = {
    Date: generateDateMessageBlock(),
    'Multiple Choice': generateMultipleChoiceMessageBlock(),
    'Freeform Text': generateFreeformTextMessageBlock(),
    Statement: generateStatementMessageBlock(),
    'Image Upload': generateImageUploadMessageBlock(),
  }
  return typeToBlockMap[type]
}
