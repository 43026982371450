import React from 'react'
import { CellText } from '@helloextend/merchants-ui'
import type { CellProps, Column } from 'react-table'
import type { Organization } from '@helloextend/extend-api-rtk-query'
import type { TableAccountOrganization } from '../../../../../types/organizations'

const toTableOrganization = (organization: Organization): TableAccountOrganization => {
  return {
    name: organization.name,
    organizationId: organization.id,
  }
}

const toTableOrganizations = (organizations: Organization[]): TableAccountOrganization[] => {
  return organizations.map((organization) => toTableOrganization(organization))
}

const organizationsColumns: Array<Column<TableAccountOrganization>> = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value }: CellProps<TableAccountOrganization, string>): JSX.Element => (
      <CellText>{value}</CellText>
    ),
  },
  {
    Header: 'Organization ID',
    accessor: 'organizationId',
    Cell: ({ value }: CellProps<TableAccountOrganization, string>): JSX.Element => (
      <CellText>{value}</CellText>
    ),
  },
]

export { toTableOrganizations, organizationsColumns }
