import React from 'react'
import type {
  CheckboxFilterOptions,
  CheckboxFilterValues,
  FilterOptions,
  FilterValues,
} from '@helloextend/merchants-ui'
import { CellText } from '@helloextend/merchants-ui'
import type { Column, Cell } from 'react-table'
import { format } from '@helloextend/client-utils/src/currency'
import { Badge } from '@helloextend/zen'
import type { SkuSearchParams } from '@helloextend/extend-api-rtk-query'
import { obligorOptionsList } from '../../../utils/obligor-options-list'

interface OptColumnProps {
  Header: string
  accessor: string
  disableSortBy?: boolean
  width?: number
  Cell?: (cell: Cell) => JSX.Element
}
type SkusColumnType = Column & OptColumnProps

const skusColumns: SkusColumnType[] = [
  {
    Header: 'Premium ID',
    accessor: 'id',
    Cell: ({ value }: Cell): JSX.Element => <CellText>{value}</CellText>,
  },
  {
    Header: 'Premium Name',
    accessor: 'name',
    Cell: ({ value }: Cell): JSX.Element => <CellText>{value}</CellText>,
  },
  {
    Header: 'Premium',
    accessor: 'premium',
    Cell: ({ value }: Cell): JSX.Element => <CellText>{format(value)}</CellText>,
  },
  {
    Header: 'Program',
    accessor: 'program',
    Cell: ({ value }: Cell): JSX.Element => <CellText>{value}</CellText>,
  },
  {
    Header: 'Subprogram',
    accessor: 'subProgram',
    Cell: ({ value }: Cell): JSX.Element => <CellText>{value}</CellText>,
  },
  {
    Header: 'Coverage Type',
    accessor: 'coverageType',
    Cell: ({ value }: Cell): JSX.Element => <CellText>{value}</CellText>,
  },
  {
    Header: 'Term Length',
    accessor: 'term',
    Cell: ({ value }: Cell): JSX.Element => <CellText>{value}</CellText>,
  },
  {
    Header: 'Service Type',
    accessor: 'serviceType',
    Cell: ({ value }: Cell): JSX.Element => <CellText>{value}</CellText>,
  },
  {
    Header: 'Status',
    accessor: 'isActive',
    Cell: ({ value }: Cell): JSX.Element => {
      const isActive = Boolean(value)
      const badgeColor = isActive ? 'green' : 'yellow'
      return <Badge color={badgeColor} emphasis="medium" text={isActive ? 'Active' : 'Inactive'} />
    },
  },
]

const searchOptions = [
  { label: 'Premium ID', value: 'id' },
  { label: 'Premium Name', value: 'name' },
]

const listToFilterOptionsList = (list: string[]): CheckboxFilterOptions['options'] => {
  return list.reduce((acc, currentOption) => {
    acc[currentOption] = currentOption
    return acc
  }, {} as Record<string, string>)
}

const getFilterOptions = (
  insuranceProgramsList: string[],
  planAttributes: Record<string, string[]>,
): Record<string, FilterOptions> => {
  const obligors = obligorOptionsList.map((obligor) => obligor.value)

  return {
    program: {
      label: 'Program',
      type: 'checkbox',
      options: listToFilterOptionsList(planAttributes.program),
      includeSearchBar: true,
    },
    subProgram: {
      label: 'Subprogram',
      type: 'checkbox',
      options: listToFilterOptionsList(planAttributes.subProgram),
      includeSearchBar: true,
    },
    coverageType: {
      label: 'Coverage Type',
      type: 'checkbox',
      options: {
        base: 'Base',
        adh: 'ADH',
      },
    },
    insuranceProgramId: {
      label: 'Insurance Program',
      type: 'checkbox',
      options: listToFilterOptionsList(insuranceProgramsList),
      includeSearchBar: true,
    },
    isActive: {
      label: 'Status',
      type: 'checkbox',
      options: {
        true: 'Active',
        false: 'Inactive',
      },
    },
    obligor: { label: 'Obligor', type: 'checkbox', options: listToFilterOptionsList(obligors) },
  }
}

const getFiltersForApi = (
  filters: Record<string, FilterValues | null>,
  searchKey: string,
  searchValue: string,
): SkuSearchParams => {
  let args = {}
  if (searchKey && searchValue) {
    args = { ...args, [searchKey]: searchValue }
  }
  const { program, subProgram, coverageType, insuranceProgramId, isActive, obligor } = filters || {}

  if ((program as CheckboxFilterValues)?.values?.length) {
    args = { ...args, program: (program as CheckboxFilterValues).values }
  }

  if ((subProgram as CheckboxFilterValues)?.values?.length) {
    args = { ...args, subProgram: (subProgram as CheckboxFilterValues).values }
  }

  if ((coverageType as CheckboxFilterValues)?.values?.length) {
    args = { ...args, coverageType: (coverageType as CheckboxFilterValues).values }
  }

  if ((insuranceProgramId as CheckboxFilterValues)?.values?.length) {
    args = { ...args, insuranceProgramId: (insuranceProgramId as CheckboxFilterValues).values }
  }

  // if both active and inactive are selected, don't filter by status since it's the default behavior on the BE
  if ((isActive as CheckboxFilterValues)?.values?.length === 1) {
    args = { ...args, isActive: (isActive as CheckboxFilterValues)?.values?.[0] }
  }

  if ((obligor as CheckboxFilterValues)?.values?.length) {
    args = { ...args, obligor: (obligor as CheckboxFilterValues).values }
  }
  return args
}

export { skusColumns, searchOptions, getFilterOptions, listToFilterOptionsList, getFiltersForApi }
