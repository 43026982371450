import type {
  RepairMetaData,
  ServiceOrderItemExtendedWarranty,
  ServiceOrderItemProduct,
  ServiceOrderItemShippingCost,
  ServiceOrderItemTax,
  ServicerType,
} from '@helloextend/extend-api-client/src/models/service-order'
import type {
  ServiceOrder,
  ServiceOrderShipment,
  ProductReplacementFulfillmentMetaData,
  CreditFulfillmentMetaData,
  Expense,
  AssignedServicer,
  ServiceOrdersStartTrackingResponse,
} from '@helloextend/extend-api-client'
import { ClaimType } from '@helloextend/extend-api-client'
import { faker } from '@faker-js/faker/locale/en'

export const generateServiceRepairMetaData = (
  overrides: Partial<RepairMetaData> = {},
): RepairMetaData => {
  return {
    repairStartedAt: faker.date.recent().getTime(),
    repairCompletedAt: faker.date.recent().getTime(),
    ...overrides,
  }
}
export const generateServiceOrderShipment = (
  overrides: Partial<ServiceOrderShipment> = {},
): ServiceOrderShipment => {
  return {
    id: faker.datatype.uuid(),
    shipmentId: faker.datatype.uuid(),
    serviceOrderId: faker.datatype.uuid(),
    carrier: 'fedex_ground',
    broker: 'ship_engine',
    destinationType: 'servicer',
    destination: {
      city: faker.address.city(),
      address1: faker.address.streetAddress(),
      address2: faker.address.secondaryAddress(),
      provinceCode: faker.address.stateAbbr(),
      countryCode: 'US',
      postalCode: faker.address.zipCode(),
      contact: {
        phone: faker.phone.number('###-###-####'),
        companyName: faker.company.name(),
      },
    },
    status: 'created',
    origin: {
      city: faker.address.city(),
      address1: faker.address.streetAddress(),
      provinceCode: faker.address.stateAbbr(),
      countryCode: 'US',
      postalCode: faker.address.zipCode(),
      contact: {
        phone: faker.phone.number('###-###-####'),
        companyName: faker.company.name(),
      },
    },
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    ...overrides,
  }
}

export const generateTrackingInfo = (
  overrides: Partial<ServiceOrdersStartTrackingResponse> = {},
): ServiceOrdersStartTrackingResponse => {
  return {
    trackingNumber: faker.datatype.uuid(),
    carrierCode: 'usps',
    serviceOrderId: faker.datatype.uuid(),
    ...overrides,
  }
}

export const generateServiceOrder = (
  overrides: Partial<ServiceOrder> = {},
  claimServicerType: ServicerType = 'extend',
): ServiceOrder => {
  const items = overrides.items || [generateServiceOrderItemProduct()]
  return {
    id: faker.datatype.uuid(),
    claimId: faker.datatype.uuid(),
    contractId: faker.datatype.uuid(),
    status: 'created',
    serviceType: 'replace',
    product: {
      value: {
        amount: 20000,
        currencyCode: 'USD',
      },
      title: 'Product title',
    },
    items,
    servicer: { id: faker.datatype.uuid(), entity: 'extend' },
    customerEmail: faker.internet.email(),
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    claimType: ClaimType.EXTENDED_WARRANTY,
    assignedServicer: {
      servicerType: claimServicerType,
      businessName: 'gabe Inc.',
      servicerId: '6fac5b95-4b2d-4e04-8b42-751efa79b469',
      authOrganizationId: '03f23260-7fb8-4215-9449-2daaa6e5a426',
      servicingLocation: {
        createdAt: 1634857605057,
        address: {
          city: 'Philipsburg',
          address1: '116 East Pine Street',
          countryCode: 'USA',
          provinceCode: 'PA',
          postalCode: '16866',
        },
        contact: {
          name: 'Extend Returns',
          phone: '555-555-5555',
          email: 'returns@extend.com',
        },
        businessName: 'Extend Returns',
        id: 'b4a32b4c-4dc9-410b-a55d-a6836daba7da',
        updatedAt: 1634857605057,
      },
      contact: {
        name: 'Gabe',
        phone: '(877) 248-7707',
        email: 'support@gmail.com',
      },
    },
    ...overrides,
  }
}

export const generateServiceOrderItemProduct = (
  overrides: Partial<ServiceOrderItemProduct> = {},
): ServiceOrderItemProduct => {
  return {
    id: faker.datatype.uuid(),
    referenceItemId: faker.datatype.uuid(),
    referenceItemType: 'product',
    claimId: faker.datatype.uuid(),
    quantity: 1,
    currencyCode: 'USD',
    listPrice: 100,
    purchasePrice: 80,
    title: faker.commerce.productName(),
    category: faker.commerce.department(),
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    sellerId: faker.datatype.uuid(),
    ...overrides,
  }
}

export const generateServiceOrderItemExtendedWarranty = (
  overrides: Partial<ServiceOrderItemExtendedWarranty> = {},
): ServiceOrderItemExtendedWarranty => {
  return {
    id: faker.datatype.uuid(),
    referenceItemId: faker.datatype.uuid(),
    referenceItemType: 'extended_warranty',
    claimId: faker.datatype.uuid(),
    quantity: 1,
    currencyCode: 'USD',
    value: 80,
    title: 'Extended Warranty',
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    sellerId: faker.datatype.uuid(),
    productId: faker.datatype.uuid(),
    ...overrides,
  }
}

export const generateServiceOrderItemTax = (
  overrides: Partial<ServiceOrderItemTax> = {},
): ServiceOrderItemTax => {
  return {
    id: faker.datatype.uuid(),
    referenceItemId: faker.datatype.uuid(),
    referenceItemType: 'tax',
    claimId: faker.datatype.uuid(),
    quantity: 1,
    currencyCode: 'USD',
    value: 80,
    title: 'Tax',
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    ...overrides,
  }
}

export const generateServiceOrderItemShippingCost = (
  overrides: Partial<ServiceOrderItemShippingCost> = {},
): ServiceOrderItemShippingCost => {
  return {
    id: faker.datatype.uuid(),
    referenceItemId: faker.datatype.uuid(),
    referenceItemType: 'shipping_cost',
    claimId: faker.datatype.uuid(),
    quantity: 1,
    currencyCode: 'USD',
    value: 80,
    title: 'Shipping Cost',
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    ...overrides,
  }
}

export const generateFulfilledServiceOrder = (
  overrides: Partial<ServiceOrder> = {},
): ServiceOrder => {
  return {
    id: faker.datatype.uuid(),
    claimId: faker.datatype.uuid(),
    customerEmail: faker.internet.email(),
    contractId: faker.datatype.uuid(),
    status: 'fulfilled',
    serviceType: 'replace',
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    product: {
      value: { amount: 12999 },
      title: 'Product title',
    },
    ...overrides,
  }
}

export const generateReplacementApprovedServiceOrder = (
  overrides: Partial<ServiceOrder> = {},
): ServiceOrder => {
  return {
    id: faker.datatype.uuid(),
    claimId: faker.datatype.uuid(),
    customerEmail: faker.internet.email(),
    contractId: faker.datatype.uuid(),
    status: 'replacement_approved',
    serviceType: 'replace',
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    product: {
      value: { amount: 12999 },
      title: 'Product title',
    },
    ...overrides,
  }
}

export const generateProductReplacementFulfillmentMetaData = (
  overrides: Partial<ProductReplacementFulfillmentMetaData> = {},
): ProductReplacementFulfillmentMetaData => {
  return {
    method: 'product_replacement',
    productReferenceId: faker.datatype.uuid(),
    productName: faker.random.words(),
    productValue: {
      amount: 1999,
      currencyCode: 'USD',
    },
    trackingNumber: faker.datatype.uuid(),
    carrier: 'fedex_ground',
    ...overrides,
  }
}

export const generateCreditFulfillmentMetaData = (
  overrides: Partial<CreditFulfillmentMetaData> = {},
): CreditFulfillmentMetaData => {
  return {
    method: 'credit',
    productValue: {
      amount: 200000,
      currencyCode: 'USD',
    },
    redemptionUrl: faker.internet.url(),
    ...overrides,
  }
}

export const generateServiceOrderWithAssignedServicer = (
  overrides: Partial<ServiceOrder> = {},
): ServiceOrder => {
  const serviceOrderId = faker.datatype.uuid()
  return generateServiceOrder({
    id: serviceOrderId,
    serviceType: 'repair_depot',
    status: 'accepted',
    assignedServicer: generateAssignedServicer(),
    ...overrides,
  })
}

export const generateExpense = (overrides: Partial<Expense> = {}): Expense => {
  return {
    id: faker.datatype.uuid(),
    serviceOrderId: faker.datatype.uuid(),
    type: 'store_credit',
    cost: {
      amount: 1999,
      currencyCode: 'USD',
      formattedAmount: '19.99',
    },
    description: faker.random.words(3),
    status: 'created',
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    ...overrides,
  }
}

export const generateAssignedServicer = (
  overrides: Partial<AssignedServicer> = {},
): AssignedServicer => {
  return {
    servicerId: faker.datatype.uuid(),
    authOrganizationId: faker.datatype.uuid(),
    businessName: faker.company.name(),
    contact: {
      email: faker.internet.exampleEmail().toLocaleLowerCase(),
      phone: faker.phone.number('###-###-####'),
      name: faker.name.firstName(),
    },
    servicingLocation: {
      id: faker.datatype.uuid(),
      address: {
        address1: faker.address.streetAddress(),
        address2: faker.address.secondaryAddress(),
        city: faker.address.city(),
        countryCode: faker.address.countryCode(),
        provinceCode: faker.address.stateAbbr(),
        postalCode: faker.address.zipCode(),
      },
      businessName: faker.name.jobDescriptor(),
      contact: {
        email: faker.internet.exampleEmail().toLocaleLowerCase(),
        phone: faker.phone.number('###-###-####'),
        name: faker.name.firstName(),
      },
      createdAt: faker.date.recent().getTime(),
      updatedAt: faker.date.recent().getTime(),
    },
    servicerType: 'merchant',
    settings: {},
    ...overrides,
  }
}
