import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/zen'
import { TextArea } from '@helloextend/merchants-ui'
import type { ChangeFunction, SetFieldFunction, StringInputItem } from './types'

type TextAreaItemProps = {
  handleChange: ChangeFunction & SetFieldFunction
  handleBlur: (e: SyntheticEvent) => void
  isDisabled: boolean
  item: StringInputItem
}

const TextAreaItem: FC<TextAreaItemProps> = ({ handleChange, handleBlur, isDisabled, item }) => {
  return (
    <>
      <Label>{item.label}</Label>
      <Subtitle>{item.subtitle}</Subtitle>
      <TextArea
        id={item.name}
        value={item.value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={item.isItemDisabled ?? isDisabled}
        placeholder=""
        resize="none"
        width={100}
        hasError={Boolean(item.error)}
        errorMessage={item.error}
      />
    </>
  )
}

const Label = styled.div({
  color: COLOR.BLUE[1000],
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
})

const Subtitle = styled.div({
  color: COLOR.NEUTRAL[700],
  fontSize: 14,
  marginBottom: 4,
})

export { TextAreaItem, TextAreaItemProps }
