import type { FC } from 'react'
import React from 'react'
import type { ButtonProps } from '@helloextend/zen'
import { Button } from './button'
import { OverlayButtonContainer } from './overlay-button-container'
import { OverlayButtonPosition } from './types'

export interface OverlayButtonProps extends ButtonProps {
  buttonPosition?: OverlayButtonPosition
}

const OverlayButton: FC<OverlayButtonProps> = ({
  buttonPosition = OverlayButtonPosition.right,
  size,
  color,
  icon,
  iconPosition,
  isInverted,
  isDisabled,
  isToggled,
  onClick,
  text,
  'data-cy': dataCy,
}) => {
  return (
    <OverlayButtonContainer buttonPosition={buttonPosition}>
      <Button
        size={size}
        color={color}
        icon={icon}
        iconPosition={iconPosition}
        isInverted={isInverted}
        isDisabled={isDisabled}
        isToggled={isToggled}
        onClick={onClick}
        text={text}
        data-cy={dataCy}
      />
    </OverlayButtonContainer>
  )
}

export { OverlayButton }
