import { useState, useEffect } from 'react'
import { debounce } from 'lodash/fp'

type ScreenSizes = 'small' | 'medium' | 'large' | 'extra_large'
export const useScreenSize = (): ScreenSizes => {
  const [width, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = (): void => setWindowWidth(window.innerWidth)
    const debouncedHandleResize = debounce(500, handleResize)
    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  if (width < 768) {
    return 'small'
  }
  if (width < 1025) {
    return 'medium'
  }

  if (width < 1367) {
    return 'large'
  }

  return 'extra_large'
}
