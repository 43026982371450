import { combineReducers } from 'redux'
import type { AuthAccessTokenReducerState } from './access-token'
import accessToken from './access-token'
import type { ChangePasswordMessageReducerState } from './change-password-message'
import changePasswordMessage from './change-password-message'
import error from './error'
import isLoading from './is-loading'
import type { ErrorReducerState } from '../../types/error'
import type { Action } from '../actions'

export interface ReducerState {
  accessToken: AuthAccessTokenReducerState
  error: ErrorReducerState
  isLoading: boolean
  changePasswordMessage?: ChangePasswordMessageReducerState
}

export default combineReducers<ReducerState, Action>({
  accessToken,
  changePasswordMessage,
  error,
  isLoading,
})
