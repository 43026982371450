import type { FilterOptions, FilterValues } from '../components/filters/types'
import { DateRange } from '../components/filters/types'

export const mockFilterOptions: Record<string, FilterOptions> = {
  checkbox: {
    type: 'checkbox',
    label: 'Checkbox',
    options: { true: 'Yes', false: 'No' },
  },
  dateRange: {
    type: 'dateRange',
    label: 'Date Range',
  },
  group: {
    type: 'group',
    label: 'Group',
    filters: {
      groupDateRange1: {
        type: 'dateRange',
        label: 'Date Range',
      },
      groupDateRange2: {
        type: 'dateRange',
        label: 'Date Range',
      },
    },
  },
}

export const mockFilterValues: Record<string, FilterValues> = {
  checkbox: {
    type: 'checkbox',
    values: ['true', 'false'],
  },
  dateRange: {
    type: 'dateRange',
    range: DateRange.custom,
    start: new Date(),
    end: new Date(),
  },
  groupDateRange1: {
    type: 'dateRange',
    range: DateRange.custom,
    start: new Date(),
    end: new Date(),
  },
  groupDateRange2: {
    range: DateRange.custom,
    type: 'dateRange',
    start: new Date(),
    end: new Date(),
  },
}
