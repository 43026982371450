import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'

interface CardProps {
  dataQa?: string
  width?: number
  height?: number
}

/**
 * @deprecated Use Zen Box component instead: `import { Box } from '@helloextend/zen'`
 */
const Card: FC<CardProps> = ({ children, dataQa, width, height }) => {
  return (
    <StyledWrapper data-qa={dataQa} width={width} height={height}>
      {children}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div<{ width?: number; height?: number }>(({ width, height }) => ({
  border: `1px solid ${COLOR.LIGHT_GRAYISH_BLUE_0}`,
  borderRadius: 4,
  boxSizing: 'border-box',
  fontFamily: 'Nunito Sans, sans-serif',
  minHeight: height || 210,
  lineHeight: '22px',
  maxWidth: '100%',
  padding: '40px 50px',
  width: width || '100%',
}))

export { Card }
