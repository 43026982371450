import styled from '@emotion/styled'
import {
  useLazyGetPlanTermsVersionLanguageUrlQuery,
  useLazyGetPlanTermsVersionLanguagesQuery,
  useListPlanTermsQuery,
} from '@helloextend/extend-api-rtk-query'
import type { DataTableAction, SortingState } from '@helloextend/zen'
import {
  COLOR,
  Breadcrumbs,
  Button,
  DataTable,
  DisplayLarge,
  Paragraph,
  Visibility,
} from '@helloextend/zen'
import type { FC } from 'react'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import type { PlanTermsVersion } from '@helloextend/extend-api-client'
import { tableColumns } from './table-config'

const TermsVersions: FC = () => {
  const { termsId } = useParams<{ termsId: string }>()
  const { data, isLoading } = useListPlanTermsQuery(undefined, {
    selectFromResult: ({ data: termsData, ...rest }) => ({
      data: termsData?.filter((term) => term.termsId === termsId)[0],
      ...rest,
    }),
  })
  const [sorting, setSorting] = useState<SortingState>([{ id: 'version', desc: true }])

  const history = useHistory()

  const [getVersionLanguages] = useLazyGetPlanTermsVersionLanguagesQuery()
  const [getVersionLanguageUrl] = useLazyGetPlanTermsVersionLanguageUrlQuery()

  const handleGetVersionUrlClick = async (row: PlanTermsVersion): Promise<void> => {
    const { version } = row
    const languages = await getVersionLanguages({
      termsId,
      version,
    }).unwrap()

    const { url } = await getVersionLanguageUrl({
      version,
      termsId,
      language: languages[0].language,
    }).unwrap()

    window.open(url, '_blank')
  }

  const getRowActions = (row: PlanTermsVersion): DataTableAction[] => {
    return [
      {
        icon: Visibility,
        onClick: () => handleGetVersionUrlClick(row),
        emphasis: 'low',
        'data-cy': `view-terms-button-${row.version}`,
      },
    ]
  }

  const handleUploadNewVersion = (): void => {
    history.push(`/admin/terms/${termsId}/create-version`)
  }

  return (
    <>
      <Breadcrumbs
        data-cy="terms-breadcrumbs"
        crumbs={[
          {
            text: 'Terms & Conditions',
            to: '/admin/terms',
          },
          {
            text: `${termsId} Details`,
          },
        ]}
      />
      <HeaderContainer>
        <DisplayLargeWithMargin>Terms & Condition Details</DisplayLargeWithMargin>
        <ButtonContainer>
          <Button
            text="Edit"
            emphasis="medium"
            onClick={handleUploadNewVersion}
            data-cy="upload-new-version-button"
          />
        </ButtonContainer>
      </HeaderContainer>
      <Paragraph data-cy="header-id">
        <SpanBold>ID:</SpanBold> {termsId}
      </Paragraph>
      {!isLoading && data && termsId && (
        <Paragraph data-cy="header-description">
          <SpanBold>Description:</SpanBold> {data?.description || 'N/A'}
        </Paragraph>
      )}
      <DataTable
        data-cy="terms-data-table"
        isLoading={isLoading}
        columns={tableColumns}
        data={data?.versions || []}
        sorting={sorting}
        onSortingChange={setSorting}
        hasManualSorting={false}
        getRowActions={getRowActions}
      />
    </>
  )
}

const DisplayLargeWithMargin = styled(DisplayLarge)({
  margin: '24px 0',
})

const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

const SpanBold = styled.span({
  fontWeight: 700,
  color: COLOR.NEUTRAL[600],
})

export { TermsVersions }
