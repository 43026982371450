import type { SagaIterator } from 'redux-saga'
import { all, takeEvery } from 'redux-saga/effects'
import type { ActionType } from '../actions'
import { connect } from './connect'
import { update } from './update'

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery('INCREDIBOT_SESSION_CONNECT' as ActionType, connect),
    takeEvery('INCREDIBOT_SESSION_UPDATE' as ActionType, update),
  ])
}
