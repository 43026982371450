import type { RefObject } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useTransition } from './use-transition'

interface UseHeightTransition {
  contentRef: RefObject<HTMLDivElement>
  bodyRef: RefObject<HTMLDivElement>
  isVisible: boolean
  height?: number | string
  isOverflowVisible: boolean
  transitionDurationMs: number
}

export const useHeightTransition = (): UseHeightTransition => {
  const { isVisible, isPresent, transitionDurationMs } = useTransition()
  const contentRef = useRef<HTMLDivElement>(null)
  const bodyRef = useRef<HTMLDivElement>(null)

  // default to isVisible to handle the case where the panel is open to start with and ensure height is set to 'auto'
  const [isTransitionComplete, setIsTransitionComplete] = useState(isVisible)

  useEffect(() => {
    bodyRef.current?.addEventListener('transitionstart', (e) => {
      if (e.target === bodyRef.current) {
        setIsTransitionComplete(false)
      }
    })

    bodyRef.current?.addEventListener('transitionend', (e) => {
      if (e.target === bodyRef.current) {
        setIsTransitionComplete(true)
      }
    })
  }, [])

  let height: number | string | undefined
  let isOverflowVisible = false

  if (!isVisible) {
    height = 0
  } else if (isPresent && isTransitionComplete) {
    height = 'auto'
    isOverflowVisible = true
  } else {
    height = contentRef.current?.offsetHeight
  }

  return { contentRef, bodyRef, isVisible, height, isOverflowVisible, transitionDurationMs }
}
