import type { ReactNode, FC } from 'react'
import React, { createContext, useState, useRef, useMemo, useEffect, useLayoutEffect } from 'react'

interface TransitionContextValue {
  transitionDurationMs: number
  isVisible: boolean
  isPresent: boolean
}

const TransitionContext = createContext<TransitionContextValue>({
  transitionDurationMs: 0,
  isVisible: false,
  isPresent: false,
})

interface AnimatePresenceProps {
  children: ReactNode
  isPresent: boolean
  transitionDurationMs: number
}

const AnimatePresence: FC<AnimatePresenceProps> = ({
  children,
  isPresent,
  transitionDurationMs,
}) => {
  const animationRef = useRef<NodeJS.Timeout | null>(null)
  const [isVisible, setIsVisible] = useState(false)
  const [isRendered, setIsRendered] = useState(false)
  const hasBeenRendered = useRef(false)

  useEffect(() => {
    if (isPresent) {
      setIsRendered(true)
      setIsVisible(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    if (isPresent) {
      if (animationRef.current) {
        setIsVisible(true)
        clearTimeout(animationRef.current)
        animationRef.current = null
      }
      setIsRendered(true)
      hasBeenRendered.current = true
    } else if (hasBeenRendered.current) {
      animationRef.current = setTimeout(() => {
        setIsRendered(false)
        animationRef.current = null
      }, transitionDurationMs)
      setTimeout(() => {
        setIsVisible(false)
      })
    }
    return () => {
      if (animationRef.current) {
        clearTimeout(animationRef.current)
      }
    }
  }, [isPresent, transitionDurationMs])

  /* isVisible needs to be true when isPresent changes to true in order to trigger a rerender and css transition after the initial mount */
  useEffect(() => {
    if (isRendered) {
      requestAnimationFrame(() => {
        setTimeout(() => {
          setIsVisible(true)
        })
      })
    }
  }, [isRendered])

  const value = useMemo<TransitionContextValue>(() => {
    return {
      transitionDurationMs,
      isVisible,
      isPresent,
    }
  }, [isPresent, isVisible, transitionDurationMs])

  return (
    <TransitionContext.Provider value={value}>{isRendered && children}</TransitionContext.Provider>
  )
}

export { AnimatePresence, TransitionContext, TransitionContextValue }
