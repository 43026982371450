import type { DraftCategoryMapping } from '@helloextend/extend-api-client/src/models'
import { VALIDATION_MESSAGES } from './constants'

export const checkNonUniqueWarrantyStatus = (mappings: DraftCategoryMapping[]): string[] => {
  const errors = []
  const newMappings = [...mappings]
  if (mappings.length > 0) {
    // check if any of the mappings have a different warranty status
    if (!hasSameWarrantyStatus(newMappings)) {
      errors.push(VALIDATION_MESSAGES.NON_UNIQUE_WARRANTY_STATUS_IN_MAPPINGS)
    }
  }

  return errors
}

export const checkDifferentPlanSetsForCategory = (
  allMappings: DraftCategoryMapping[],
  category: string,
  planSetId: string,
  storeName: string,
): string => {
  const mappingsWithDifferentPlanSets = allMappings.filter(
    (mapping) => mapping.extendCategory === category && mapping.planSetId !== planSetId,
  )
  return mappingsWithDifferentPlanSets.length
    ? VALIDATION_MESSAGES.INCONSISTENT_PLAN_SETS_FOR_EXTEND_CATEGORY(
        category,
        mappingsWithDifferentPlanSets.length,
        storeName,
      )
    : ''
}

const hasSameWarrantyStatus = (mappings: DraftCategoryMapping[]): boolean => {
  const { mappingStatus } = mappings[0]
  return mappings.every(({ mappingStatus: status }) => status === mappingStatus)
}
