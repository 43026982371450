import type { VFC } from 'react'
import React from 'react'
import { createPortal } from 'react-dom'
import type { ModalFooterProps } from './modal-types'
import { useModalContext } from './modal-context'
import { SharedFooter } from './shared-footer'

export const ModalFooter: VFC<ModalFooterProps> = (props) => {
  const { getModalFooterRef } = useModalContext()
  const modalFooterRef = getModalFooterRef().current
  if (modalFooterRef !== null) {
    return createPortal(<SharedFooter {...props} />, modalFooterRef)
  }
  return <></>
}
