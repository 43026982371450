import type { ErrorObject } from '@helloextend/core-api-redux/src/types/error'
import type { Action } from '../actions'

export type PlanTermsErrorReducerState = ErrorObject | null

export const initialState: PlanTermsErrorReducerState = null

export default function error(state = initialState, action: Action): PlanTermsErrorReducerState {
  switch (action.type) {
    case 'PLAN_TERM_VERSION_IMPORT':
    case 'PLAN_TERM_VERSION_IMPORT_REQUEST':
    case 'PLAN_TERM_VERSION_IMPORT_SUCCESS':
    case 'PLAN_TERM_VERSION_IMPORT_ERROR_RESET':
    case 'PLAN_TERM_CREATE_SUCCESS':
    case 'PLAN_TERM_CREATE_REQUEST':
      return null
    case 'PLAN_TERM_VERSION_IMPORT_FAILURE':
    case 'PLAN_TERM_CREATE_FAILURE':
      return action.payload
    default:
      return state
  }
}
