import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import type { AdvancedSelectOption } from '@helloextend/zen'
import { ModalController } from '@helloextend/zen'
import {
  useListAllPlanIdsWithCoverageTypeQuery,
  useListPlanCategoriesQuery,
  useListPlanSetsQuery,
} from '@helloextend/extend-api-rtk-query'
import type { PlanSet } from '@helloextend/extend-api-client'
import { PlanSetModal } from './modal'
import { PlanSetsTable } from './table'

const PlanSets: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { data: { values: planSetCategories = [] } = {} } = useListPlanCategoriesQuery()
  const { data: planSets = [], isFetching: isListPlanSetsLoading } = useListPlanSetsQuery()
  const { data: planIdsCoverageTypeMap, isLoading: isPlanIdsLoading } =
    useListAllPlanIdsWithCoverageTypeQuery()
  const [planSetToEdit, setPlanSetToEdit] = useState<PlanSet>()

  const sortedPlanSetCategories = getSortedCategoryOptions(planSetCategories)

  const toggleModal = useCallback((): void => {
    setPlanSetToEdit(undefined)
    setIsModalOpen(!isModalOpen)
  }, [isModalOpen])

  const handleEditPlanSet = useCallback(
    (planSetId: string): void => {
      const targetPlanSet = planSets.find(({ id }) => id === planSetId)
      setIsModalOpen(true)
      setPlanSetToEdit(targetPlanSet)
    },
    [planSets],
  )

  return (
    <>
      <ModalController isOpen={isModalOpen}>
        <PlanSetModal
          toggleModal={toggleModal}
          sortedPlanSetCategories={sortedPlanSetCategories}
          planSetToEdit={planSetToEdit}
          planIdsCoverageTypeMap={planIdsCoverageTypeMap}
          isPlanIdsLoading={isPlanIdsLoading}
        />
      </ModalController>
      <PlanSetsTable
        planSets={planSets}
        sortedPlanSetCategories={sortedPlanSetCategories}
        isFetching={isListPlanSetsLoading}
        handleCreatePlanSet={toggleModal}
        handleEditPlanSet={handleEditPlanSet}
      />
    </>
  )
}

export const getSortedCategoryOptions = (categories: string[]): AdvancedSelectOption[] =>
  [...categories].sort().map((category) => ({
    display: category,
    value: category,
  }))

export { PlanSets }
