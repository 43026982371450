import { TextInput } from '@helloextend/merchants-ui'
import type { ClipboardEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { FormikErrors } from 'formik'
import { getIn } from 'formik'
import type { RowPlanPricingSku } from '../../../types/plans'
import type { Values } from './schema'

// Workaround due to typing issue
const getFormikError = (errors: FormikErrors<Values>, index: number, fieldName: string): string => {
  const error = getIn(errors, `pricing.[${index}].${fieldName}`)
  return error || null
}

const toRow = (
  data: RowPlanPricingSku,
  index: number,
  handlePasteEvent: (e: ClipboardEvent, index: number) => void,
  errors: FormikErrors<Values>,
  isLabelHidden = false,
): JSX.Element => {
  return (
    <Container>
      <TextInput
        label={isLabelHidden ? '' : 'Vendor SKU'}
        id={`vendorSku-${index}`}
        onChange={(e) => data.handleChange(e, index)}
        value={String(data.vendorSku)}
        disabled={data.isDisabled}
        onPaste={(e) => handlePasteEvent(e, index)}
        hasError={Boolean(getFormikError(errors, index, 'vendorSku'))}
        errorMessage={getFormikError(errors, index, 'vendorSku')}
      />
      <TextInput
        label={isLabelHidden ? '' : 'Price Band Low'}
        id={`priceBandLow-${index}`}
        onChange={(e) => data.handleChange(e, index)}
        value={String(data.priceBandLow)}
        disabled={data.isDisabled}
        onPaste={(e) => handlePasteEvent(e, index)}
        hasError={Boolean(getFormikError(errors, index, 'priceBandLow'))}
        errorMessage={getFormikError(errors, index, 'priceBandLow')}
      />
      <TextInput
        label={isLabelHidden ? '' : 'Price Band High'}
        id={`priceBandHigh-${index}`}
        onChange={(e) => data.handleChange(e, index)}
        value={String(data.priceBandHigh)}
        disabled={data.isDisabled}
        onPaste={(e) => handlePasteEvent(e, index)}
        hasError={Boolean(getFormikError(errors, index, 'priceBandHigh'))}
        errorMessage={getFormikError(errors, index, 'priceBandHigh')}
      />
      <TextInput
        label={isLabelHidden ? '' : 'Cost'}
        id={`cost-${index}`}
        onChange={(e) => data.handleChange(e, index)}
        value={String(data.cost)}
        disabled={data.isDisabled}
        onPaste={(e) => handlePasteEvent(e, index)}
        hasError={Boolean(getFormikError(errors, index, 'cost'))}
        errorMessage={getFormikError(errors, index, 'cost')}
      />
      <TextInput
        label={isLabelHidden ? '' : 'Retail Target'}
        id={`retailTarget-${index}`}
        onChange={(e) => data.handleChange(e, index)}
        value={String(data.retailTarget)}
        disabled={data.isDisabled}
        onPaste={(e) => handlePasteEvent(e, index)}
        hasError={Boolean(getFormikError(errors, index, 'retailTarget'))}
        errorMessage={getFormikError(errors, index, 'retailTarget')}
      />
      <TextInput
        label={isLabelHidden ? '' : 'Fixed Price'}
        id={`fixedPrice-${index}`}
        onChange={(e) => data.handleChange(e, index)}
        value={typeof data.fixedPrice === 'number' ? String(data.fixedPrice) : ''}
        disabled={data.isDisabled}
        onPaste={(e) => handlePasteEvent(e, index)}
        hasError={Boolean(getFormikError(errors, index, 'fixedPrice'))}
        errorMessage={getFormikError(errors, index, 'fixedPrice')}
      />
      <TextInput
        label={isLabelHidden ? '' : 'Retail Max'}
        id={`retailMax-${index}`}
        onChange={(e) => data.handleChange(e, index)}
        value={String(data.retailMax)}
        disabled={data.isDisabled}
        onPaste={(e) => handlePasteEvent(e, index)}
        hasError={Boolean(getFormikError(errors, index, 'retailMax'))}
        errorMessage={getFormikError(errors, index, 'retailMax')}
      />
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  width: '100%',
  gap: 8,
})

export { toRow }
