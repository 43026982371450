import { date } from '@helloextend/client-utils'
import { CellText } from '@helloextend/merchants-ui'
import React from 'react'
import type { Column, Cell } from 'react-table'
import { RemoveUserButton } from './components/remove-user-button'

interface ColumnProps {
  accessor: string
  disableSortBy?: boolean
  width?: number
  Cell: (cell: Cell) => JSX.Element
}

type ClaimsUserRowData = Column & ColumnProps

interface ConfiguratorProps {
  delCallback(userId: string): void
}

const getTableColumns = (props: ConfiguratorProps): ClaimsUserRowData[] => {
  const { delCallback } = props
  return [
    {
      Header: 'user name',
      accessor: 'fullName',
      Cell: ({ value }: Cell): JSX.Element => <CellText>{value}</CellText>,
      disableSortBy: true,
      width: 15,
    },
    {
      Header: 'email',
      accessor: 'email',
      Cell: ({ value }: Cell): JSX.Element => (
        <CellText data-cy="claim-assignment-email">{value}</CellText>
      ),
      disableSortBy: true,
      width: 15,
    },
    {
      Header: 'date added',
      accessor: 'createdAt',
      Cell: ({ value }: Cell): JSX.Element => (
        <CellText>{value ? date.format(Number(value)) : '-'}</CellText>
      ),
      width: 15,
    },
    {
      accessor: 'id',
      Cell: ({ value: userId }: Cell): JSX.Element => (
        <RemoveUserButton userId={userId} onClick={() => delCallback(userId)} />
      ),
      width: 15,
      textAlign: 'right',
    },
  ]
}

export { getTableColumns }
