import { combineReducers } from 'redux'
import type { ByClaimIdReducerState } from './by-claim-id'
import byClaimId from './by-claim-id'
import error from './error'
import isLoading from './is-loading'
import isCreating from './is-creating'
import isDeleting from './is-deleting'
import type { ByNameReducerState } from './is-uploading-by-name'
import isUploadingByName from './is-uploading-by-name'
import type { ByNameErrorReducerState } from './errorByName'
import errorByName from './errorByName'
import type { ErrorReducerState } from '../../types/error'
import type { Action } from '../actions'

export interface ReducerState {
  byClaimId: ByClaimIdReducerState
  error: ErrorReducerState
  isLoading: boolean
  isCreating: boolean
  isDeleting: boolean
  isUploadingByName: ByNameReducerState
  errorByName: ByNameErrorReducerState
}

export default combineReducers<ReducerState, Action>({
  byClaimId,
  error,
  isLoading,
  isCreating,
  isDeleting,
  isUploadingByName,
  errorByName,
})
