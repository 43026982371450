import { useLayoutEffect } from 'react'
import { Breakpoint } from '../tokens/breakpoints'

export type BreakpointValues<T> = {
  [k in keyof typeof Breakpoint]?: T
}

function findBreakpoint(width: number): keyof typeof Breakpoint {
  let k: keyof typeof Breakpoint
  for (k in Breakpoint) {
    if (Object.prototype.hasOwnProperty.call(Breakpoint, k)) {
      if (!width || width <= Breakpoint[k]) {
        return k
      }
    }
  }

  return 'xl'
}

function findBreakpointValue<T>(
  breakpoint: keyof typeof Breakpoint,
  values: BreakpointValues<T>,
): T | null {
  if (typeof values !== 'object') {
    return values
  }

  let k: keyof typeof Breakpoint
  let lastValue: T | undefined
  let lookAhead = false
  for (k in Breakpoint) {
    if (Object.prototype.hasOwnProperty.call(Breakpoint, k)) {
      if (values[k]) {
        lastValue = values[k]
      }

      if (lookAhead) {
        if (lastValue !== undefined) {
          return lastValue
        }
      } else if (k === breakpoint) {
        if (lastValue === undefined) {
          lookAhead = true
        } else {
          return lastValue
        }
      }
    }
  }

  return null
}

export function useBreakpoints<T>(
  width: number,
  values: BreakpointValues<T> | T,
  callback: (value: T) => void,
): void {
  useLayoutEffect(() => {
    if (typeof values === 'object') {
      const breakpoint = findBreakpoint(width)
      const value = findBreakpointValue(breakpoint, values)
      if (value !== null) {
        callback(value)
      }
    } else if (values !== null) {
      callback(values)
    }
  }, [width, callback, values])
}
