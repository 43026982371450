import { badgeColors } from './badge-colors'
import { badgeMeasurements } from './badge-measurements'
import type {
  BadgeColor,
  BadgeSize,
  BadgeEmphasis,
  ColorArgs,
  MeasurementArgs,
} from './badge-types'
import { BadgeTheme } from './badge-types'

export const getMeasurements = (size: BadgeSize): MeasurementArgs => {
  return badgeMeasurements[size]
}

const getTheme = (style: BadgeColor, isInverted: boolean): BadgeTheme => {
  switch (style) {
    case 'red':
      if (isInverted) {
        return BadgeTheme.redInverted
      }
      return BadgeTheme.red

      break
    case 'yellow':
      if (isInverted) {
        return BadgeTheme.yellowInverted
      }
      return BadgeTheme.yellow

      break
    case 'neutral':
      if (isInverted) {
        return BadgeTheme.neutralInverted
      }
      return BadgeTheme.neutral

      break
    case 'green':
      if (isInverted) {
        return BadgeTheme.greenInverted
      }
      return BadgeTheme.green

      break
    default:
      if (isInverted) {
        return BadgeTheme.blueInverted
      }
      return BadgeTheme.blue

      break
  }
}

export const getColors = (
  style: BadgeColor,
  isInverted: boolean,
  emphasis: BadgeEmphasis,
): ColorArgs => {
  const type = getTheme(style, isInverted)
  return badgeColors[emphasis][type]
}
