import type { ChangeEvent, FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, Radio } from '@helloextend/zen'
import type { StoreIntegrationSettings } from '@helloextend/extend-api-client'
import type { FormikHelpers } from 'formik'
import type { Values } from './schema'

type ContractCreationRadioProps = {
  createContracts: StoreIntegrationSettings['createContracts']
  contractEvent: StoreIntegrationSettings['contractEvent']
  isDisabled: boolean
  setFieldValue: FormikHelpers<Values>['setFieldValue']
}

const ContractCreationRadio: FC<ContractCreationRadioProps> = ({
  createContracts,
  contractEvent,
  isDisabled,
  setFieldValue,
}) => {
  /**
   * controls both createContracts and contractEvent properties with one set of radio buttons
   */
  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === 'createContracts') {
      setFieldValue('createContracts', false)
      setFieldValue('contractEvent', undefined)
    } else {
      setFieldValue('createContracts', true)
      setFieldValue('contractEvent', e.target.value)
    }
  }
  return (
    <div data-cy="contract-creation-radio">
      <Header>Contract Creation</Header>
      <RadioWrapper>
        <Radio
          name="createContracts"
          checked={createContracts === false}
          onChange={handleRadioChange}
          label="No contract creation"
          value="false"
          disabled={isDisabled}
        />
        <Radio
          name="contractEvent"
          checked={contractEvent === 'orderFulfill' && createContracts}
          onChange={handleRadioChange}
          label="Create contract when order is fulfilled"
          value="orderFulfill"
          disabled={isDisabled}
        />
        <Radio
          name="contractEvent"
          checked={contractEvent === 'orderCreate' && createContracts}
          onChange={handleRadioChange}
          label="Create contract when order is created"
          value="orderCreate"
          disabled={isDisabled}
        />
      </RadioWrapper>
    </div>
  )
}

const Header = styled.h3({
  fontSize: 14,
  margin: '8px 0',
  lineHeigh: '16px',
  color: COLOR.NEUTRAL[1000],
})

const RadioWrapper = styled.span({
  label: {
    fontSize: 15,
    marginBottom: 0,
    lineHeight: '18px',
  },
})

export { ContractCreationRadio }
