import React from 'react'
import type { FC } from 'react'
import type { BadgeColor } from '@helloextend/zen'
import { Badge, Subheading } from '@helloextend/zen'
import styled from '@emotion/styled'
import type { Condition } from '@helloextend/extend-api-rtk-query'
import { useGetAdjudicationRuleQuery } from '@helloextend/extend-api-rtk-query'
import type { Claim } from '@helloextend/extend-api-client'
import type { Ruleset } from '@helloextend/extend-api-client/src/models/claim'
import { AdjudicationRow } from './adjudication-row'

export type AdjudicationClaim = Claim & {
  ruleset: Ruleset
}

type AdjudicationRuleProps = {
  claim: AdjudicationClaim
}

const AdjudicationRule: FC<AdjudicationRuleProps> = ({ claim }) => {
  const { data: rule, isLoading } = useGetAdjudicationRuleQuery({
    ruleId: claim.ruleset.ruleId,
    rulesetId: claim.ruleset.rulesetId,
    version: claim.ruleset.version,
    expand: true,
  })

  const { badgeTitle, badgeColor }: { badgeTitle: string; badgeColor: BadgeColor } =
    claim.status === 'approved'
      ? { badgeTitle: 'Claim Approved', badgeColor: 'green' }
      : { badgeTitle: 'Claim Denied', badgeColor: 'red' }

  if (isLoading) return null

  return (
    <Container>
      <ClaimResult>
        <Description data-cy="adjudication-rule-header">AMP claim result:</Description>
        <Badge data-cy="claim-rule-badge" color={badgeColor} emphasis="medium" text={badgeTitle} />
      </ClaimResult>
      <Subheading>Applied AMP Adjudication Rule:</Subheading>
      {rule &&
        rule.conditions.map((condition: Condition, conditionIndex: number) => {
          return <AdjudicationRow rule={rule} condition={condition} index={conditionIndex} />
        })}
    </Container>
  )
}

const Description = styled.p({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
})

const ClaimResult = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 12,
  paddingBottom: 32,
})

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
})

export { AdjudicationRule }
