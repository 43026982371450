import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  CreateUserGrantReq,
  FilteredUserGrantsResponse,
  GetUserGrantsFilteredReq,
  RevokeExtendEnterpriseUserGrantReq,
  UserGrant,
} from './types'

import { baseQuery } from '../base-query'

export const grantsApi = createApi({
  baseQuery,
  reducerPath: 'Grants',
  tagTypes: ['Grants'],
  endpoints: (build) => ({
    createUserGrant: build.mutation<{ success: boolean }, CreateUserGrantReq>({
      query: ({ userId, ern, role }: CreateUserGrantReq) => ({
        url: `/auth/grants`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        method: 'POST',
        body: { userId, ern, role },
      }),
      invalidatesTags: ['Grants'],
    }),
    revokeUserGrant: build.mutation<
      {
        success: boolean
      },
      RevokeExtendEnterpriseUserGrantReq
    >({
      query: ({ userId, ern, role }: RevokeExtendEnterpriseUserGrantReq) => ({
        url: `/auth/grants`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        method: 'DELETE',
        body: { userId, ern, role },
      }),
      invalidatesTags: ['Grants'],
    }),
    getUserGrantsList: build.query<{ grants: UserGrant[] }, string>({
      query: (email) => ({
        url: `/auth/grants/users/${email}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
      }),
      providesTags: (_, _err, userId) => [{ type: 'Grants', id: userId }],
    }),
    getUserGrantsFiltered: build.query<FilteredUserGrantsResponse, GetUserGrantsFilteredReq>({
      query: (requestBody) => ({
        url: '/auth/grants/filter-grants-for-ern',
        method: 'POST',
        body: requestBody,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
      }),
    }),
  }),
})

export const {
  useCreateUserGrantMutation,
  useRevokeUserGrantMutation,
  useGetUserGrantsListQuery,
  useLazyGetUserGrantsListQuery,
  useLazyGetUserGrantsFilteredQuery,
} = grantsApi
