import * as fetchAllActions from './fetch-all'
import * as fetchAllForStoreActions from './fetch-all-for-store'
import * as fetchUserActions from './fetch'
import * as updateActions from './update'
import * as createActions from './create'
import * as deleteActions from './delete'
import * as errorReset from './error-reset'
import * as resendEmailActions from './resend-invite'
import type { ExtractActions } from '../../types/utility'

export type Action =
  | ExtractActions<typeof fetchAllActions>
  | ExtractActions<typeof fetchAllForStoreActions>
  | ExtractActions<typeof fetchUserActions>
  | ExtractActions<typeof updateActions>
  | ExtractActions<typeof createActions>
  | ExtractActions<typeof errorReset>
  | ExtractActions<typeof deleteActions>
  | ExtractActions<typeof resendEmailActions>

export type ActionType = Action['type']

const actions = {
  ...fetchAllActions,
  ...fetchAllForStoreActions,
  ...fetchUserActions,
  ...updateActions,
  ...createActions,
  ...deleteActions,
  ...errorReset,
  ...resendEmailActions,
}

export { actions }
