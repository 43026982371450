import type { Action } from '../../actions'

export type ProfileSuccessMessageState = {
  message: string | null
}
const initialState: ProfileSuccessMessageState = {
  message: null,
}

export default function profileSuccessMessage(
  state = initialState,
  action: Action,
): ProfileSuccessMessageState {
  switch (action.type) {
    case 'USERS_UPDATE_SUCCESS':
      return {
        message: action.payload.message,
      }
    case 'CLEAR_PROFILE_SUCCESS_MESSAGE':
      return {
        message: null,
      }
    default:
      return state
  }
}
