import type { FC } from 'react'
import React from 'react'
import { ButtonGroup, Button } from '@helloextend/zen'
import styled from '@emotion/styled'
import type { ThreadListItem } from '@helloextend/extend-api-rtk-query/src/conversations'
import { ReferencedConversations } from '../referenced-conversations'
import { BasicModal } from '../../../../../components/basic-modal'
import { AMPModalType } from '../../utils'
import { ModalWarningText } from '../modal-warning-text/modal-warning-text'

type EditWarningModalProps = {
  isVisible: boolean
  onConfirm: () => void
  onCancel: () => void
  thread: ThreadListItem
}

const EditWarningModal: FC<EditWarningModalProps> = ({
  isVisible,
  onConfirm,
  onCancel,
  thread,
}) => {
  const hasReferencedConversations = thread.usedByConversations.length > 0

  return (
    <BasicModal
      data-cy="amp-edit-warning-modal"
      isVisible={isVisible}
      onClickClose={onCancel}
      overflow="auto"
    >
      <ModalContent>
        <ModalHeader data-cy="amp-edit-warning-modal-header">Edit thread</ModalHeader>
        <ModalBody data-cy="amp-edit-warning-modal-context">
          <ModalWarningText
            modalType={AMPModalType.editWarning}
            threadTitle={thread.title}
            hasReferencedConversations={hasReferencedConversations}
          />
          <ReferencedConversations
            data-cy="referenced-conversations"
            threadId={thread.id}
            hasReferencedConversations={hasReferencedConversations}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button emphasis="medium" data-cy="modal-cancel" text="Cancel" onClick={onCancel} />
            <Button
              onClick={onConfirm}
              color="blue"
              type="submit"
              data-cy="modal-submit"
              text="Proceed to Thread Builder"
            />
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </BasicModal>
  )
}

const ModalContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const ModalHeader = styled.div({
  fontSize: 20,
  fontWeight: 700,
  lineHeight: '28px',
})

const ModalBody = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '8px 0 8px 0',
})

const ModalFooter = styled.div({
  alignSelf: 'flex-end',
  paddingTop: 16,
})

export { EditWarningModal, EditWarningModalProps }
