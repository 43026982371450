import type {
  Contract,
  ContractsGetResponse,
  ContractsV2GetResponse,
  ContractsUpdateResponse,
  ContractsV2UpdateResponse,
  Contract20220201GetResponse,
  Money,
} from '@helloextend/extend-api-client'
import { ContractType } from '@helloextend/extend-api-client'
import type { PlanContract } from '@helloextend/extend-api-client/src/models/plan'
import { merge, set, flow, isObject, isNumber } from 'lodash/fp'
import type { Action } from '../actions'

function getNumberAmount(input: number | Money): number {
  return isNumber(input) ? input : input.amount
}

// TODO: https://helloextend.atlassian.net/browse/CUST-758 - fix this type/pattern
export type ByIdReducerState = Record<string, Contract & { planDetails?: PlanContract }>

export const initialState: ByIdReducerState = {}

export default function byId(state = initialState, action: Action): ByIdReducerState {
  switch (action.type) {
    case 'CONTRACTS_FETCH_ALL_SUCCESS':
      return reduceSearchByStoreResults(action.payload)
    case 'CONTRACTS_SEARCH_BY_STORE_SUCCESS':
      return reduceSearchByStoreResults(action.payload)
    case 'CONTRACTS_SEARCH_SUCCESS':
      return reduceSearchResults(action.payload)
    case 'CONTRACTS_FETCH_SUCCESS':
    case 'CONTRACTS_FETCH_SUCCESS_V2': {
      const hasOrderId = 'orderId' in action.payload.contract
      const newContract = action.payload.contract
      const hasPurchasePrice = 'purchasePrice' in action.payload.contract

      if (hasOrderId || hasPurchasePrice) {
        newContract.contractVersion = '2.0'
      } else {
        newContract.contractVersion = '1.0'
      }

      return addItem(state, mapContractCoverage(mapContractsGetToContract(newContract)))
    }
    case 'CONTRACTS_CREATE_SUCCESS':
      return addItem(state, action.payload)
    case 'CONTRACTS_UPDATE_SUCCESS': {
      const updatedContract: ContractsUpdateResponse = { ...action.payload, contractVersion: '1.0' }
      return set(
        action.payload.id,
        merge(
          state[action.payload.id],
          mapContractCoverage(mapContractsGetToContract(updatedContract)),
        ),
      )(state)
    }
    case 'CONTRACTS_UPDATE_SUCCESS_V2': {
      const updatedContract: ContractsV2UpdateResponse = {
        ...action.payload,
        contractVersion: '2.0',
      }
      return set(
        action.payload.id,
        merge(state[action.payload.id], mapContractsGetToContract(updatedContract)),
      )(state)
    }
    case 'CONTRACTS_REFUND_SUCCESS':
      // merges response body on existing contract,
      // as updates may append additional attributes
      // that were undefined, or optional, during contract creation
      return set(action.payload.id, merge(state[action.payload.id], action.payload))(state)
    // In general, only API actions should be in the core-api-redux package. Because it maintains
    // its own state, this utility action and usage are important to clear the internal state.
    case 'CONTRACTS_SEARCH_REQUEST':
    case 'CONTRACTS_FETCH_ALL_REQUEST':
    case 'CONTRACTS_LIST_RESET':
      return initialState
    case 'CONTRACT_SEARCH_RESET':
      return initialState
    default:
      return state
  }
}
function reduceSearchResults(contracts: ContractsV2GetResponse[]): ByIdReducerState {
  return contracts.reduce<ByIdReducerState>((memo, contract) => {
    return { ...memo, [contract.id]: mapContractsGetToContract(contract) }
  }, {})
}

function reduceSearchByStoreResults(contracts: ContractsGetResponse[]): ByIdReducerState {
  return contracts.reduce<ByIdReducerState>((memo, contract) => {
    const hasOrderId = 'orderId' in contract
    const hasPurchasePrice = 'purchasePrice' in contract
    const newContract = { ...contract }
    if (!hasOrderId && !hasPurchasePrice) {
      newContract.contractVersion = '1.0'
    }
    return { ...memo, [contract.id]: mapContractCoverage(mapContractsGetToContract(newContract)) }
  }, {})
}

export function mapContractCoverage(contract: Contract): Contract {
  return flow(set('coverageAmount', setContractCoverageAmount(contract.product)))(contract)
}

function addItem(state: ByIdReducerState, item: Contract): ByIdReducerState {
  return { ...state, [item.id]: item }
}

function setContractCoverageAmount(contractProduct: Contract['product']): number {
  return contractProduct && contractProduct.purchasePrice
}

function extractPricingInfo(value: Money | number): number {
  return isObject(value) ? value.amount : value
}

export function mapContractsGetToContract(
  contractsAPIResponse: ContractsGetResponse | ContractsV2GetResponse,
): Contract & { planDetails?: PlanContract } {
  if (contractsAPIResponse.contractVersion === '1.0') {
    return {
      type: ContractType.PCRS,
      ...contractsAPIResponse,
      plan: {
        ...contractsAPIResponse.plan,
        subcategory: contractsAPIResponse.planDetails?.pcmi_subcategory,
        purchasePrice: contractsAPIResponse.plan.purchasePrice?.amount,
        expectedPrice: contractsAPIResponse.plan.expectedPrice?.amount,
      },
      product: contractsAPIResponse.product && {
        ...contractsAPIResponse.product,
        listPrice: extractPricingInfo(contractsAPIResponse.product.listPrice),
        purchasePrice: extractPricingInfo(contractsAPIResponse.product.purchasePrice),
        reimbursementAmount: extractPricingInfo(contractsAPIResponse.product.reimbursementAmount),
        manufacturerWarrantyLengthLabor:
          contractsAPIResponse.product.manufacturerWarrantyLengthLabor || 0,
        manufacturerWarrantyLengthParts:
          contractsAPIResponse.product.manufacturerWarrantyLengthParts || 0,
        // Contracts api responses for contract versions have different product objects and title or name are sometimes undefined.
        title: contractsAPIResponse.product.title || contractsAPIResponse.product.name || '',
      },
      transactionTotal: getNumberAmount(contractsAPIResponse.transactionTotal || 0) ?? undefined,
      planDetails: contractsAPIResponse.planDetails,
    }
  }

  return {
    type: ContractType.PCRS,
    ...contractsAPIResponse,
    plan: contractsAPIResponse.plan && {
      ...contractsAPIResponse.plan,
      expectedPrice: contractsAPIResponse.plan?.expectedPrice,
      planId: contractsAPIResponse.plan.id,
      planSku: contractsAPIResponse.plan.skuId,
    },
    product: contractsAPIResponse.product && {
      ...contractsAPIResponse.product,
      listPrice: extractPricingInfo(contractsAPIResponse.product.listPrice),
      purchasePrice: extractPricingInfo(contractsAPIResponse.product.purchasePrice),
      reimbursementAmount: extractPricingInfo(contractsAPIResponse.product.reimbursementAmount),
      manufacturerWarrantyLengthLabor: contractsAPIResponse.product.manufacturerWarrantyLengthLabor,
      manufacturerWarrantyLengthParts: contractsAPIResponse.product.manufacturerWarrantyLengthParts,
      title: contractsAPIResponse.product.name || '',
      imageUrl: '',
      transactionDate: contractsAPIResponse.purchaseDate,
    },
    listPrice: extractPricingInfo(contractsAPIResponse.listPrice || 0),
    transactionTotal: contractsAPIResponse.transactionTotal,
    transactionDate: contractsAPIResponse.purchaseDate,
  }
}

export function mapContractsGet20220201ToContract(
  contractsAPIResponse: Contract20220201GetResponse,
): Contract & { planDetails?: PlanContract } {
  return {
    ...contractsAPIResponse,
    currency: contractsAPIResponse.purchaseCurrency,
    plan: contractsAPIResponse.plan && {
      ...contractsAPIResponse.plan,
      expectedPrice: contractsAPIResponse.listPrice,
      planId: contractsAPIResponse.plan.id,
      planSku: contractsAPIResponse.plan.skuId,
    },
    product: contractsAPIResponse.product && {
      ...contractsAPIResponse.product,
      listPrice: extractPricingInfo(contractsAPIResponse.product.listPrice),
      purchasePrice: extractPricingInfo(contractsAPIResponse.product.purchasePrice),
      reimbursementAmount: contractsAPIResponse.product.reimbursementAmount ?? 0,
      manufacturerWarrantyLengthLabor: contractsAPIResponse.product.manufacturerWarrantyLengthLabor,
      manufacturerWarrantyLengthParts: contractsAPIResponse.product.manufacturerWarrantyLengthParts,
      title: contractsAPIResponse.product.name || '',
      imageUrl: '',
      transactionDate: contractsAPIResponse.purchaseDate,
    },
    listPrice: contractsAPIResponse.listPrice,
    transactionDate: contractsAPIResponse.purchaseDate,
    purchasePrice: contractsAPIResponse.purchasePrice,
  }
}
