import type { ButtonColor } from '../button'

enum ToasterPosition {
  left = 'left',
  right = 'right',
  center = 'center',
}

enum ToastColor {
  blue = 'blue',
  green = 'green',
  neutral = 'neutral',
  yellow = 'yellow',
  red = 'red',
}

type ToastColorArgs = {
  background: string
  font: string
  indicatorContainer: string
  indicator: string
  buttonColor: ButtonColor
  iconColor: string
}

enum ToastDuration {
  indefinite = 'indefinite',
  short = 'short',
  long = 'long',
}

export { ToastColor, ToastColorArgs, ToastDuration, ToasterPosition }
