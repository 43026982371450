import type { Action } from '../actions'
import { createBooleanReducer } from '../../factories'

const isLoading = createBooleanReducer<Action>(
  ['SERVICERS_LIST_REQUEST', 'SERVICERS_CREATE_REQUEST'],
  [
    'SERVICERS_LIST_SUCCESS',
    'SERVICERS_LIST_FAILURE',
    'SERVICERS_CREATE_SUCCESS',
    'SERVICERS_CREATE_FAILURE',
  ],
)

export default isLoading
