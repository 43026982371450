import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'

const Small = styled.small({
  display: 'block',
  fontFamily: 'Nunito Sans, sans-serif',
  fontWeight: 700,
  margin: '0 0 4px 0',
  color: COLOR.DARK_GRAYISH_BLUE,
  textTransform: 'uppercase',
})

export { Small }
