import type { StoreUser } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

// users fetch all
// =================================================================================
export const userCreate = (data: Partial<StoreUser>, accessToken: string, accountId: string) =>
  ({
    type: 'USER_CREATE',
    payload: { data, accessToken, accountId },
  } as const)

export const userCreateRequest = () =>
  ({
    type: 'USER_CREATE_REQUEST',
  } as const)

export const userCreateSuccess = (user: StoreUser) =>
  ({
    type: 'USER_CREATE_SUCCESS',
    payload: user,
  } as const)

export const userCreateFlowReset = () =>
  ({
    type: 'USER_CREATE_FLOW_RESET',
  } as const)

export const userCreateFailure = createActionFailure('USER_CREATE_FAILURE')
