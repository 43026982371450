import type { Action } from '../actions'
import { createBooleanReducer } from '../../factories'

const isSaving = createBooleanReducer<Action>(
  ['SERVICE_ORDER_EXPENSES_CREATE_SUCCESS', 'SERVICE_ORDER_EXPENSES_VOID_SUCCESS'],
  [
    'SERVICE_ORDER_EXPENSES_CREATE_REQUEST',
    'SERVICE_ORDER_EXPENSES_CREATE_FAILURE',
    'SERVICE_ORDER_EXPENSES_VOID_REQUEST',
    'SERVICE_ORDER_EXPENSES_VOID_FAILURE',
    'SERVICE_ORDER_EXPENSES_ERROR_RESET',
  ],
)

export default isSaving
