/* eslint-disable react/no-array-index-key */
import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import { Loader } from './styles'

type TableSkeletonProps = {
  columns?: number
  rows?: number
}

/**
 * @deprecated Instead of using this separate `<TableSkeleton />` component, use the isLoading prop that exist within
 * the Zen DataTable.
 */
const TableSkeleton: FC<TableSkeletonProps> = ({ columns = 3, rows = 5 }) => (
  <Wrapper>
    <Table data-cy="table-skeleton:table">
      <Header>
        {new Array(columns).fill(null).map((_, i) => (
          <Loader key={`header-column-${i}`} width="120px" margin="0 100px 0 0" />
        ))}
      </Header>
      {new Array(rows).fill(null).map((_, i) => (
        <Row key={`variant-${i}`}>
          {new Array(columns).fill(null).map((__, j) => (
            <Loader key={`row-${i}-column-${j}`} width="120px" margin="0 100px 0 0" />
          ))}
        </Row>
      ))}
    </Table>
  </Wrapper>
)

const Wrapper = styled.div({
  display: 'block',
  marginTop: '1rem',
  width: '100%',
})

const Table = styled.div({
  marginTop: '1rem',
})

const Header = styled.div({
  display: 'flex',
  padding: '12px 32px',
})

const Row = styled.div({
  display: 'flex',
  padding: '12px 32px',
  border: `0.5px solid ${COLOR.GHOST}`,
  borderWidth: '0.5px 0.5px 0 0.5px',
  '&:last-of-type': {
    borderWidth: '0.5px',
  },
})

export { TableSkeleton }
