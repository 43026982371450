const getScrollingAncestor = (element: HTMLElement): HTMLElement | undefined => {
  if (!element) {
    return undefined
  }

  let parent = element.parentElement
  while (parent) {
    const { overflow } = window.getComputedStyle(parent)
    if (overflow.split(' ').every((o) => o === 'auto' || o === 'scroll')) {
      return parent
    }
    parent = parent.parentElement
  }

  return document.documentElement
}

export const keepInView = (el?: HTMLElement): void => {
  if (el) {
    const scrollingAncestor = getScrollingAncestor(el)

    if (scrollingAncestor) {
      const scrollingAncestorTop = scrollingAncestor.offsetTop
      const scrollingAncestorScrollTop = scrollingAncestor.scrollTop
      const scrollingAncestorHeight = scrollingAncestor.offsetHeight
      const scrollingAncestorBottom = scrollingAncestorScrollTop + scrollingAncestorHeight
      const elTop = el.offsetTop - scrollingAncestorTop
      const elBottom = elTop + el.offsetHeight

      if (elTop < scrollingAncestorScrollTop) {
        el.scrollIntoView()
        scrollingAncestor.scrollBy(0, -6)
      }

      if (elBottom > scrollingAncestorBottom) {
        el.scrollIntoView(false)
        scrollingAncestor.scrollBy(0, 6)
      }
    }
  }
}

export const blurActiveElement = (): void => {
  if (document.activeElement) {
    const activeElement = document.activeElement as HTMLElement
    activeElement.blur()
  }
}
