import type { AdvancedSelectOptionGroup } from '@helloextend/zen/src/components/fields/advanced-select/advanced-select-types'

export const getKeySelectorOptionsBase = (): AdvancedSelectOptionGroup[] => {
  return [
    {
      label: 'Plan Attributes',
      options: [
        {
          display: 'Coverage Type',
          value: 'planHasCoverageType',
        },
      ],
    },
    {
      label: 'Contract Attributes',
      options: [
        {
          display: 'Total of the purchase prices',
          value: 'totalOfPurchasePrices',
        },
        {
          display: 'Number of shipments',
          value: 'numberOfShipments',
        },
      ],
    },
    {
      label: 'Message Blocks',
      options: [],
    },
  ]
}
