import type { FC } from 'react'
import React, { useState, useCallback } from 'react'
import {
  useUpdateAccountEnterpriseMutation,
  useUpdateAccountLegacyMutation,
} from '@helloextend/extend-api-rtk-query'
import { Switch, useToaster, ToastColor, ToastDuration } from '@helloextend/zen'
import { AccountStatus } from '../../../../../types/accounts'

type AccountToggleSwitchProps = {
  status: AccountStatus
  accountId: string
  name: string
  hybridLegacyAccount: boolean
}

const AccountToggleSwitch: FC<AccountToggleSwitchProps> = ({
  status,
  accountId,
  name,
  hybridLegacyAccount,
}) => {
  const [updateAccountEnterprise, { isLoading: isLoadingAccountEnterprise }] =
    useUpdateAccountEnterpriseMutation()
  const [updateAccountLegacy, { isLoading: isLoadingAccountLegacy }] =
    useUpdateAccountLegacyMutation()
  const [isEnabled, setIsEnabled] = useState<boolean>(status === AccountStatus.Approved)

  const { toast } = useToaster()

  const handleOnChange = useCallback(async () => {
    let response

    if (hybridLegacyAccount) {
      response = await updateAccountLegacy({
        body: { status: isEnabled ? AccountStatus.Unapproved : AccountStatus.Approved },
        accountId,
      })
    } else {
      response = await updateAccountEnterprise({
        body: { status: isEnabled ? AccountStatus.Unapproved : AccountStatus.Approved, name },
        accountId,
      })
    }

    if ('data' in response) {
      setIsEnabled(!isEnabled)
      toast({
        message: `${name} Account is successfully ${
          isEnabled ? AccountStatus.Unapproved : AccountStatus.Approved
        }.`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
    } else {
      toast({
        message: `Error approving ${name} Account.`,
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [
    updateAccountEnterprise,
    updateAccountLegacy,
    toast,
    accountId,
    name,
    isEnabled,
    hybridLegacyAccount,
  ])

  return (
    <Switch
      data-cy="account-toggle-switch"
      isOn={isEnabled}
      isDisabled={isLoadingAccountEnterprise || isLoadingAccountLegacy}
      onChange={handleOnChange}
    />
  )
}

export default AccountToggleSwitch
