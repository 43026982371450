import type { PlanTermsType } from '@helloextend/extend-api-client'
import type { Action } from '../actions'

export type ByIdReducerState = Record<string, PlanTermsType>

const initialState: ByIdReducerState = {}

export default function byId(state = initialState, action: Action): ByIdReducerState {
  switch (action.type) {
    case 'PLAN_TERMS_FETCH_ALL_SUCCESS':
      return action.payload.reduce(addItem, state)
    default:
      return state
  }
}

function addItem(state: ByIdReducerState, item: PlanTermsType): ByIdReducerState {
  return { ...state, [item.termsId]: item }
}
