import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useGetAccountQuery } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import {
  COLOR,
  Breadcrumbs,
  Spinner,
  useToaster,
  ToastDuration,
  ToastColor,
} from '@helloextend/zen'
import { format } from '@helloextend/zen/src/utils/date'
import { PageHeader } from '../../../../../components/page-header'
import { TabMenu } from '../../../../../components/tab-menu/tab-menu'
import { Organizations as OrganizationsList } from '../../organizations/organizations'
import { Users as UsersList } from '../../users/users'
import { StoresDataTable } from '../../stores/stores-data-table'

enum AccountsTab {
  Organizations = 'organizations',
  Stores = 'stores',
  Users = 'users',
}

const AccountDetails: FC = () => {
  const { toast } = useToaster()
  const { accountId, tab } = useParams<{ accountId: string; tab: AccountsTab }>()
  const history = useHistory()

  const { data, isLoading } = useGetAccountQuery({ accountId })

  const handleTabClick = (tabKey: string): void => {
    history.push(`/admin/accounts/${accountId}/${tabKey}`)
  }

  const isLegacyAccount = useMemo(() => {
    const legacyAccount = data && `isLegacyAccount` in data
    return legacyAccount
  }, [data])

  if (isLoading) {
    return <Spinner color={COLOR.BLUE[800]} />
  }

  if (!data) {
    toast({
      message: 'Account not found',
      toastDuration: ToastDuration.short,
      toastColor: ToastColor.red,
    })

    return null
  }

  return (
    <>
      <BreadcrumbsWrapper>
        <Breadcrumbs
          crumbs={[
            { text: 'Merchant Accounts', to: '/admin/accounts' },
            { text: data.name.length ? data.name : data.id },
          ]}
        />
      </BreadcrumbsWrapper>
      <HeaderContainer>
        <PageHeader
          data-cy="account-name-title"
          title={data.name.length ? data.name : `Account ID: ${accountId}`}
        />
        <HeaderInfoTitle data-cy="account-id-info">
          Account ID: <HeaderInfoText>{accountId}</HeaderInfoText>
        </HeaderInfoTitle>
        <HeaderInfoTitle data-cy="account-framework-info">
          Framework: <HeaderInfoText>{isLegacyAccount ? 'Legacy' : 'MultiTenant'}</HeaderInfoText>
        </HeaderInfoTitle>
        <HeaderInfoTitle data-cy="account-date-created-info">
          Date created:{' '}
          {data?.createdAt && (
            <HeaderInfoText>{format(data.createdAt, 'MMMM DD YYYY, HH:mm A z')}</HeaderInfoText>
          )}
        </HeaderInfoTitle>
      </HeaderContainer>
      <TabContent>
        <TabMenu
          tabs={[
            isLegacyAccount
              ? { text: 'Stores', key: AccountsTab.Stores }
              : { text: 'Organizations', key: AccountsTab.Organizations },
            { text: 'Users', key: AccountsTab.Users },
          ]}
          onClick={handleTabClick}
          activeTab={tab}
        />
      </TabContent>
      {tab === AccountsTab.Stores && (
        <StoresDataTable accountId={accountId} isAccountIdColumnDisplayed={false} />
      )}
      {tab === AccountsTab.Organizations && <OrganizationsList />}
      {tab === AccountsTab.Users && <UsersList />}
    </>
  )
}

const HeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const HeaderInfoTitle = styled.div({
  color: COLOR.NEUTRAL[600],
  marginBottom: 5,
})

const TabContent = styled.div({
  marginBottom: 32,
  marginTop: 40,
})

const HeaderInfoText = styled.span({
  color: COLOR.BLACK,
})

const BreadcrumbsWrapper = styled.div({
  marginBottom: 24,
})

export { AccountDetails }
