import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import styled from '@emotion/styled'
import { LDFlag } from '@helloextend/portal/src/constants/ld-flags'
import type { InsuranceClaim, Contract, ServiceOrder } from '@helloextend/extend-api-client'
import { Badge, COLOR, Stack } from '@helloextend/zen'
import { StackAlign, StackDirection } from '@helloextend/zen/src/components/stack'
import { Link } from 'react-router-dom'
import type { BadgeDescriptionItem } from '../../../../claims/claim-status'
import { getStatusBadgeDetails } from '../../../../claims/claim-status'
import { getServiceOrderStatusDetails } from '../../../service-order-status'
import { AssignUserDropdown } from '../../../../../../components/assign-user-dropdown'

interface ClaimHeaderProps {
  claim: InsuranceClaim
  product: Contract['product']
  serviceOrder?: ServiceOrder
}

const ClaimHeader: FC<ClaimHeaderProps> = ({ claim, product, serviceOrder }) => {
  const { [LDFlag.AssignUser]: assignUserEnabled } = useFlags()
  const statusBadgeDescription = useMemo((): BadgeDescriptionItem | null => {
    return getStatusBadgeDetails(claim)
  }, [claim])

  const serviceOrderStatusBadgeDescription = useMemo((): BadgeDescriptionItem | null => {
    return getServiceOrderStatusDetails(serviceOrder)
  }, [serviceOrder])

  return (
    <Container data-cy="claim-header">
      <Stack direction={StackDirection.column}>
        <Stack align={StackAlign.center} spacing={1} doesWrap>
          <Title>{`${claim.customer?.name}'s Claim`}</Title>
          {statusBadgeDescription && (
            <Badge
              text={`Claim ${statusBadgeDescription.badgeText}`}
              details={statusBadgeDescription.badgeDetails}
              color={statusBadgeDescription.badgeColor}
            />
          )}
          {serviceOrderStatusBadgeDescription && (
            <Badge
              text={`SO ${serviceOrderStatusBadgeDescription.badgeText}`}
              color={serviceOrderStatusBadgeDescription.badgeColor}
              details={serviceOrderStatusBadgeDescription.badgeDetails}
            />
          )}
        </Stack>
        <Stack direction={StackDirection.column}>
          <Stack doesWrap>
            <DetailName>Claim ID: </DetailName>
            <DetailValue>{claim.id}</DetailValue>
          </Stack>
          {product && (
            <Stack doesWrap>
              <DetailName>Covered Product: </DetailName>
              <DetailValue>{product.title}</DetailValue>
            </Stack>
          )}
          <Stack doesWrap>
            <DetailName data-cy="detail-contract-id">Contract ID: </DetailName>
            <StyledLink
              data-cy="detail-contract-id-link"
              to={`/admin/contracts/${claim.contractId}`}
            >
              {claim.contractId}
            </StyledLink>
          </Stack>
          {assignUserEnabled && (
            <Stack data-cy="assignee-row" doesWrap>
              <DetailName data-cy="detail-assignee">Assignee: </DetailName>
              <AssignUserDropdown claimId={claim.id} />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const Title = styled.h1({
  color: COLOR.NEUTRAL[1000],
  fontSize: 32,
  lineHeight: '44px',
  fontWeight: 800,
  marginTop: 0,
  marginBottom: 8,
})

const DetailName = styled.span({
  fontSize: 16,
  fontWeight: 'bold',
  color: COLOR.NEUTRAL[600],
  marginRight: 4,
  width: 135,
})

const DetailValue = styled.span({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontSize: 16,
  color: COLOR.NEUTRAL[1000],
})

const StyledLink = styled(Link)({
  color: COLOR.BLUE[800],
  '&:hover': {
    textDecoration: 'underline',
  },
})

export { ClaimHeader }
