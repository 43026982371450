import { createActionFailure } from '../../factories'

export const login = (username: string, password: string) =>
  ({
    type: 'LOGIN',
    payload: { username, password },
  } as const)

export const loginRequest = () => ({ type: 'LOGIN_REQUEST', payload: undefined } as const)

export const loginSuccess = (accessToken: string) =>
  ({
    type: 'LOGIN_SUCCESS',
    payload: accessToken,
  } as const)

export const loginFailure = createActionFailure('LOGIN_FAILURE')
