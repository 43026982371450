import type { ApiResponse } from '../../../extend-api-fetch'
import { get, put, post } from '../../../extend-api-fetch'
import type { PlanTermsGetResponse, PlanTermsListResponse, PlanTermsLanguage } from '../models'

export async function fetchAll(
  accessToken: string,
  cursor?: string,
): Promise<ApiResponse<PlanTermsListResponse>> {
  return get(`/plans/terms${cursor ? `?cursor=${cursor}` : ''}`, { accessToken })
}

export async function fetchTermsVersionLanguages(
  accessToken: string,
  termsId: string,
  version: string,
): Promise<ApiResponse<PlanTermsLanguage[]>> {
  return get(`/plans/terms/${termsId}/versions/${version}/languages`, { accessToken })
}

export async function fetchTermsVersionLanguageUrl(
  termsId: string,
  version: string,
  language = 'en',
): Promise<ApiResponse<PlanTermsGetResponse>> {
  return get(`/plans/terms/${termsId}/versions/${version}/languages/${language}`)
}

// Term Version Upload, returns a signed S3 url
export async function createImportUrl(
  termId: string,
  accessToken: string,
  language: string,
  filename: string,
): Promise<ApiResponse<{ url: string }>> {
  return put(`/plans/terms/${termId}`, {
    accessToken,
    body: { language, filename },
    apiVersion: 'latest',
  })
}

// Uploads to S3 using the signed url
export async function uploadToS3Url(url: string, file: File): Promise<ApiResponse> {
  return put(url, { body: file, dataType: 'pdf' })
}

export async function createPlanTerm(
  accessToken: string,
  termsId: string,
  description: string,
  language = 'en',
  filename: string,
): Promise<ApiResponse<{ url: string }>> {
  return post(`/plans/terms`, {
    accessToken,
    apiVersion: 'latest',
    body: {
      termsId,
      language,
      description,
      filename,
    },
  })
}
