import React, { forwardRef, useRef, useState, Fragment, useMemo } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { useLazyGetThreadQuery } from '@helloextend/extend-api-rtk-query'
import { CollapsibleBlock, ConversationStartBlock } from '../index'
import { setIsEditorPanelVisible, setThread } from '../../../../../store/slices/amp-slice'
import type { RootState } from '../../../../../reducers'
import {
  getReusableThreadPickerVisibility,
  getSelectedThread,
  getSelectedThreadIdToReplace,
} from '../../../../../reducers/selectors'
import { ConversationAppendButtons } from '../conversation-append-buttons'
import { handleNumericBadgeClick, scrollToAndAnimate } from '../../utils-ui'
import { ThreadPlaceholder } from '../thread-placeholder/thread-placeholder'

type CollapsibleConversationProps = {
  threadIds: string[]
  isEditMode?: boolean
}

const CollapsibleConversation = forwardRef<HTMLDivElement, CollapsibleConversationProps>(
  ({ threadIds, isEditMode = false }, ref) => {
    const selectedThread = useSelector((state: RootState) => getSelectedThread(state))
    const firstMessageBlockRefs = useRef<{ [key: string]: HTMLElement }>({})
    const setFirstMessageBlockRef = (threadId: string, element: HTMLElement): void => {
      firstMessageBlockRefs.current[threadId] = element
    }

    const isReusableThreadPickerVisible = useSelector((state: RootState) =>
      getReusableThreadPickerVisibility(state),
    )

    const selectedThreadIdToReplace = useSelector((state: RootState) =>
      getSelectedThreadIdToReplace(state),
    )

    const [fetchThread] = useLazyGetThreadQuery()

    const dispatch = useDispatch()

    const [isCollapsedMap, setIsCollapsedMap] = useState<{ [key: string]: boolean }>({})
    // scrolls to the first message block of the given threadId
    const scrollToFirstMessageBlock = (threadId: string): void => {
      const messageBlockElement = firstMessageBlockRefs.current[threadId]
      if (messageBlockElement) {
        setIsCollapsedMap({ ...isCollapsedMap, [threadId]: false })
        // call scrollToAndAnimate in the next iteration of the event loop to let setIsCollapsedMap side effects run first
        setImmediate(() => scrollToAndAnimate(messageBlockElement))
      }
    }

    const handleBadgeClick = (
      refs: React.MutableRefObject<{
        [key: number]: HTMLElement
      }>,
      value: number | string,
      nextThreadId?: string,
    ): void => {
      if (typeof value === 'number') {
        handleNumericBadgeClick(refs, value)
      } else if (value === 'Next Thread' && nextThreadId) {
        scrollToFirstMessageBlock(nextThreadId)
      }
    }

    const onStartBlockClick = (): void => {
      scrollToFirstMessageBlock(threadIds[0])
    }

    const handleClick = async (threadId: string): Promise<void> => {
      if (!isEditMode) return
      dispatch(setIsEditorPanelVisible(true))
      // lazy query always ignores cache unless true boolean is passed as second argument
      const fetchedThread = await fetchThread(threadId, true).unwrap()
      dispatch(setThread(fetchedThread))
    }

    const isReplacePlaceholderVisible = (index: number): boolean => {
      return Boolean(
        isReusableThreadPickerVisible &&
          selectedThread &&
          selectedThreadIdToReplace &&
          selectedThread.id === threadIds[index],
      )
    }

    const isAddPlaceholderVisible = useMemo(() => {
      return Boolean(
        selectedThread &&
          selectedThread.type !== 'single_use' &&
          isReusableThreadPickerVisible &&
          !selectedThreadIdToReplace,
      )
    }, [isReusableThreadPickerVisible, selectedThread, selectedThreadIdToReplace])

    const isEndOfThreadCTAsVisible = useMemo(() => {
      // if the thread picker is active return false
      if (isReusableThreadPickerVisible) return false

      // only show these CTAs after a reusable thread since the collapsible-block component will handle after message block CTAs
      return selectedThread?.type !== 'single_use'
    }, [isReusableThreadPickerVisible, selectedThread?.type])

    return (
      <ConversationBlockContainer data-cy="conversation-collapsible-block" ref={ref}>
        <ConversationStartBlock onClick={onStartBlockClick} />
        {threadIds.map((thread, index) => {
          const onBadgeClick = (
            refs: React.MutableRefObject<{
              [key: number]: HTMLElement
            }>,
            value: number | string,
          ): void => {
            handleBadgeClick(refs, value, threadIds[index + 1])
          }
          return (
            <Fragment key={thread}>
              {isReplacePlaceholderVisible(index) ? (
                <ThreadPlaceholder description="replace" />
              ) : (
                <CollapsibleBlock
                  data-cy={`collapsible-block-${thread}`}
                  key={thread}
                  threadId={thread}
                  setFirstMessageBlockRef={setFirstMessageBlockRef}
                  onBadgeClick={onBadgeClick}
                  isCollapsedMap={isCollapsedMap}
                  setIsCollapsedMap={setIsCollapsedMap}
                  onClick={() => handleClick(thread)}
                  isActive={selectedThread?.id === thread && isEditMode === true}
                  firstThreadId={threadIds.length ? threadIds[0] : undefined}
                />
              )}

              {isEditMode && selectedThread?.id === thread && (
                <>
                  {isAddPlaceholderVisible ? (
                    <>
                      <ThreadPlaceholder description="add-collapsible-conversation" />
                    </>
                  ) : (
                    <>
                      {isEndOfThreadCTAsVisible && (
                        <ConversationAppendButtons
                          description={`after-reusable-thread-append-${thread}`}
                          isAtThreadTermination
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Fragment>
          )
        })}
      </ConversationBlockContainer>
    )
  },
)

const ConversationBlockContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  width: 522,
})

export { CollapsibleConversation, CollapsibleConversationProps }
