import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import {
  Header,
  HeaderMenuItem,
  HeaderUserInfo,
  MenuLinkItem,
  Popover,
  PopoverAlignment,
  Shell,
  Stack,
  usePopover,
  COLOR,
} from '@helloextend/zen'
import { Help, Account, Export, UserManagement } from '@helloextend/zen/src/tokens/icons'
import { StackAlign, StackDirection } from '@helloextend/zen/src/components/stack'
import { StoreUserRole } from '@helloextend/extend-api-client'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../../constants/ld-flags'
import { users as usersActions, support as supportActions } from '../../actions'
import * as selectors from '../../reducers/selectors'
import Support from '../merchant-dashboard/support'
import { HeadTag } from '../../components/head-tag'
import type { RootState } from '../../reducers'
import { mapUserType } from '../../utils/user-role-mapper'
import { DashboardNavLinks } from './dashboard-navlinks'
import { adminNavList } from './nav-list'
import type { NavDropdown, NavLink } from './types'
import { RoleSwitcher } from '../../components/role-switcher'

export enum DashboardSection {
  Admin = 'ADMIN',
  None = 'NONE',
}

const navMenuLinks: Record<DashboardSection, Array<NavLink | NavDropdown>> = {
  [DashboardSection.Admin]: adminNavList,
  [DashboardSection.None]: [],
}

type DashboardLayoutProps = {
  section: DashboardSection
}

const DashboardLayout: FC<React.PropsWithChildren<DashboardLayoutProps>> = ({
  children,
  section,
}: React.PropsWithChildren<DashboardLayoutProps>) => {
  const dispatch = useDispatch()

  const {
    [LDFlag.ExtendUserManagement]: FF_EXTEND_USER_MANAGEMENT,
    [LDFlag.OktaLogin]: FF_PORTAL_OKTALOGIN,
  } = useFlags()

  const accessToken = useSelector((state: RootState) => selectors.getAccessToken(state))
  const userId = useSelector((state: RootState) => selectors.getUserId(state))
  const userFirstName = useSelector((state: RootState) => selectors.getUserFirstName(state))
  const userLastname = useSelector((state: RootState) => selectors.getUserLastName(state))
  const userInitials =
    ((userFirstName && userFirstName[0]) || '') + ((userLastname && userLastname[0]) || '')
  const userRole = useSelector((state: RootState) =>
    mapUserType(selectors.getRoleFromToken(state) || StoreUserRole.user),
  )
  const supportIsVisible = useSelector((state: RootState) => selectors.getSupportIsVisible(state))
  const handleSupport = (): void => {
    dispatch(supportActions.toggleSupport())
  }

  const threadPreviewLocation = useRouteMatch(
    '/admin/adjudication-management/threads/:threadId/preview',
  )

  useEffect(() => {
    if (!userId && accessToken) {
      dispatch(usersActions.getMe(accessToken))
    }
  }, [accessToken, dispatch, userId])

  const { triggerRef, popoverRef, isPresent, toggle, hide, triggerBoundingBox } =
    usePopover<HTMLDivElement>()
  const {
    triggerRef: userManagementTriggerRef,
    popoverRef: userManagementPopoverRef,
    isPresent: isUserManagementPresent,
    toggle: toggleUserManagement,
    hide: hideUserManagement,
    triggerBoundingBox: triggerUserManagementBoundingBox,
  } = usePopover<HTMLDivElement>()

  const navList = navMenuLinks[section]

  return (
    <>
      <HeadTag siteTitle="Extend | Portal Dashboard" />
      <Shell
        viewportPadding="40px 32px"
        header={
          <Header
            color={COLOR.BLUE[800]}
            data-cy="portal-header"
            end={
              <Stack spacing={1}>
                {section === DashboardSection.Admin && (
                  <>
                    <HeaderMenuItem
                      ref={userManagementTriggerRef}
                      isToggled={isUserManagementPresent}
                      onClick={toggleUserManagement}
                      data-cy="user-management-menu"
                      icon={UserManagement}
                      tooltip="User Management"
                    />
                    <Popover
                      ref={userManagementPopoverRef}
                      isPresent={isUserManagementPresent}
                      triggerBoundingBox={triggerUserManagementBoundingBox}
                    >
                      <Stack
                        direction={StackDirection.column}
                        align={StackAlign.stretch}
                        padding={1}
                      >
                        {FF_EXTEND_USER_MANAGEMENT && (
                          <MenuLinkItem
                            to="/admin/user-management"
                            data-cy="user-management-page-link"
                            onClick={hideUserManagement}
                          >
                            User Management
                          </MenuLinkItem>
                        )}
                        <MenuLinkItem
                          to="/admin/claim-assignment"
                          data-cy="claim-assignment-page-link"
                          onClick={hideUserManagement}
                        >
                          Claim Assignment List
                        </MenuLinkItem>
                      </Stack>
                    </Popover>
                  </>
                )}
                <HeaderMenuItem
                  onClick={handleSupport}
                  isToggled={supportIsVisible}
                  icon={Help}
                  tooltip="Support"
                />
                <HeaderMenuItem ref={triggerRef} isToggled={isPresent} onClick={toggle}>
                  <HeaderUserInfo
                    data-cy="header-user-info"
                    initials={userInitials}
                    label={userRole}
                  />
                </HeaderMenuItem>
                <Popover
                  ref={popoverRef}
                  alignment={PopoverAlignment.end}
                  isPresent={isPresent}
                  triggerBoundingBox={triggerBoundingBox}
                  maxWidth={280}
                  header={
                    <Stack direction={StackDirection.column} align={StackAlign.stretch} padding={1}>
                      <MenuLinkItem icon={Account} to="/profile" onClick={hide}>
                        Profile
                      </MenuLinkItem>
                    </Stack>
                  }
                  footer={
                    <Stack direction={StackDirection.column} align={StackAlign.stretch} padding={1}>
                      <MenuLinkItem icon={Export} to="/logout" onClick={hide}>
                        Log out
                      </MenuLinkItem>
                    </Stack>
                  }
                >
                  {FF_PORTAL_OKTALOGIN ? (
                    <Wrapper>
                      <Stack
                        direction={StackDirection.column}
                        align={StackAlign.stretch}
                        padding={1}
                      >
                        <RoleSwitcher />
                      </Stack>
                    </Wrapper>
                  ) : null}
                </Popover>
              </Stack>
            }
            start={<DashboardNavLinks navList={navList} />}
          />
        }
        {...(threadPreviewLocation?.isExact && { viewportBackground: COLOR.NEUTRAL[100] })}
      >
        {children}
      </Shell>
      <Support />
    </>
  )
}

const Wrapper = styled.div({
  width: 280,
})

export { DashboardLayout }
