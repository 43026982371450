import * as selectors from './selectors'
import reducer from './reducers'
import sagas from './sagas'
import { actions } from './actions'

export type { ActionType as ClaimsPhotosActionType, Action as ClaimsPhotosAction } from './actions'
export type { ReducerState as ClaimsPhotosReducerState } from './reducers'

export {
  actions as claimsPhotosActions,
  selectors as claimsPhotosSelectors,
  reducer as claimsPhotosReducer,
  sagas as claimsPhotosSagas,
}
