import { omit } from 'lodash/fp'
import type { ApiResponse } from '../../../extend-api-fetch'
import { get, put, post } from '../../../extend-api-fetch'
import type { Product } from '../models'

export async function fetch(
  storeId: string,
  referenceId: string,
  accessToken: string,
): Promise<ApiResponse<Product>> {
  return get(`/stores/${storeId}/products/${referenceId}`, {
    accessToken,
    apiVersion: '2020-08-01',
  })
}

export async function fetchAllForStore(
  storeId: string,
  accessToken: string,
): Promise<ApiResponse<Product[]>> {
  return get(`/stores/${storeId}/products`, { accessToken, apiVersion: '2020-08-01' })
}

export async function fetchAllForStoreLatest(
  storeId: string,
  accessToken: string,
): Promise<ApiResponse<Product[]>> {
  return get(`/stores/${storeId}/products`, { accessToken, apiVersion: 'latest' })
}

export async function update(
  storeId: string,
  productId: string,
  body: any,
  accessToken: string,
): Promise<ApiResponse<Product>> {
  const res = await put(`/stores/${storeId}/products/${productId}`, {
    body,
    accessToken,
    apiVersion: '2021-07-01',
  })

  // add the product ID to response
  return {
    status: res.status,
    data: res.status === 200 ? { ...res.data, productId } : res.data,
  }
}
/**
 *
 * @param storeId id of store
 * @param accessToken auth
 *
 * @success {Object} data.url - presigned url good for 24 hours to fetch products as CSV from S3 bucket
 * @successExample {
      "url": "https://extendcoreapidevextendco-productsdownloadbucket34-tbrajzuk2pco.s3.amazonaws.com/63989478-c3f7-4e5c-a98f-12b2e43f853f-1580493668057.csv?AWSAccessKeyId=...
    }
 */
export async function exportAllForStore(
  storeId: string,
  accessToken: string,
): Promise<ApiResponse> {
  return post(`/stores/${storeId}/products/export`, { accessToken })
}

// temporary batch update implementation until we implement a backend for this or fix
// current upsert so that it doesnt overwrite createdAt
export type BatchUpdateProduct = WithRequiredProperties<Partial<Product>, 'referenceId'>
export interface BatchUpdateResponse {
  updated: string[]
  failed: string[]
}

export async function batchUpdate(
  storeId: string,
  data: BatchUpdateProduct[],
  accessToken: string,
): Promise<BatchUpdateResponse> {
  if (!data.every((p) => p.referenceId)) {
    throw new Error('Missing referenceId')
  }

  const response: BatchUpdateResponse = {
    updated: [],
    failed: [],
  }
  await Promise.all(
    data.map(async (product) => {
      try {
        const res = await update(
          storeId,
          product.referenceId,
          omit('referenceId')(product),
          accessToken,
        )

        if (res.status === 200) {
          response.updated.push(product.referenceId)
        } else {
          response.failed.push(product.referenceId)
        }
      } catch (e) {
        response.failed.push(product.referenceId)
      }
    }),
  )

  return response
}

// Products upload
export async function createImportUrl(
  storeId: string,
  accessToken: string,
  originalFilename: string,
): Promise<ApiResponse<{ url: string }>> {
  return post(`/stores/${storeId}/products/import`, { accessToken, qs: { originalFilename } })
}
