import styled from '@emotion/styled'
import type { VFC } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import { COLOR } from '../../../tokens/colors'
import { Button } from '../../button'
import { Input, InputType } from '../../fields'
import type { MenuProps } from '../../menu'
import { Menu } from '../../menu'
import { useDataTableContext } from '../data-table-context'
import type { NumberRangeFilterDef } from '../data-table-types'
import { FilterForm } from './filter-form'

interface NumberRangeFilterProps extends MenuProps {
  filterDef: NumberRangeFilterDef
}

export const NumberRangeFilter: VFC<NumberRangeFilterProps> = ({
  direction,
  filterDef,
  triggerRenderer,
}) => {
  const { table } = useDataTableContext()

  const filterState = table.getState().columnFilters.find((filter) => filter.id === filterDef.id)

  const [minValue, setMinValue] = useState<string>('')
  const [maxValue, setMaxValue] = useState<string>('')

  useEffect(() => {
    const hasValidFilterValue = Array.isArray(filterState?.value) && filterState?.value.length
    const filterValue: Array<number | null> = hasValidFilterValue
      ? (filterState?.value as Array<number | null>)
      : [null, null]

    setMinValue(`${filterValue[0] || ''}`)
    setMaxValue(`${filterValue[1] || ''}`)
  }, [filterState])

  const handleBlur = useCallback((): void => {
    table
      .getColumn(filterDef.id)
      .setFilterValue([parseInt(minValue, 10) || null, parseInt(maxValue, 10) || null])
  }, [table, filterDef.id, minValue, maxValue])

  return (
    <Menu padding={20} direction={direction} triggerRenderer={triggerRenderer}>
      <FilterForm>
        <Content>
          <Range>
            <Input
              id="min"
              type={InputType.number}
              label={filterDef.inputLabel || 'Range'}
              placeholder="No Min"
              value={minValue}
              onBlur={handleBlur}
              onChange={(e) => setMinValue(e.target.value)}
            />
            <Dash />
            <Input
              id="max"
              type={InputType.number}
              value={maxValue}
              placeholder="No Max"
              onBlur={handleBlur}
              onChange={(e) => setMaxValue(e.target.value)}
            />
          </Range>
          <Actions>
            <Button size="small" text="Done" type="submit" />
          </Actions>
        </Content>
      </FilterForm>
    </Menu>
  )
}

const Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
})

const Range = styled.div({
  display: 'flex',
  alignItems: 'end',
  maxWidth: 320,
  gap: 6,
})

const Dash = styled.div({
  flexShrink: 0,
  width: 10,
  height: 2,
  margin: '19px 0',
  borderRadius: 1,
  backgroundColor: COLOR.NEUTRAL[400],
})

const Actions = styled.div({
  display: 'flex',
  flexDirection: 'row-reverse',
})
