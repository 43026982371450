import { urlRegExp, validateIsNumberEntered } from '@helloextend/client-utils/validate'
import * as Yup from 'yup'

const PHONE_NUMBER_MIN_LENGTH_USA = 10

const schema = Yup.object()
  .shape({
    supportNumber: Yup.string()
      .notRequired()
      .test('phone number validation', 'Please enter a valid phone number', (value) => {
        return !value || validateIsNumberEntered(value, PHONE_NUMBER_MIN_LENGTH_USA)
      }),
    supportEmail: Yup.string().email('Please enter a valid email').notRequired(),
    supportURL: Yup.string().notRequired().matches(urlRegExp, 'Please enter a valid URL'),
    supportHours: Yup.string().notRequired(),
    escalationName: Yup.string().notRequired(),
    escalationEmail: Yup.string().email('Please enter a valid email').notRequired(),
    escalationNumber: Yup.string()
      .notRequired()
      .test('phone number validation', 'Please enter a valid phone number', (value) => {
        return !value || validateIsNumberEntered(value, PHONE_NUMBER_MIN_LENGTH_USA)
      }),
    escalationExtension: Yup.string().notRequired(),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export { schema, Values }
