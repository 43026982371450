import type { ChangeEvent } from 'react'
import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import type { Row as TableRow } from '@tanstack/react-table'
import { flexRender } from '@tanstack/react-table'
import { Else, If, Then, When } from 'react-if'
import { BodyCell } from './body-cell'
import { Button } from '../../button'
import { ExpandMore, ExpandLess, More } from '../../../tokens/icons'
import { Checkmark } from '../../checkbox'
import { RowActions } from './row-actions'
import { COLOR } from '../../../tokens/colors'
import type { MenuButtonItemProps, MenuLinkItemProps } from '../../menu-item'
import { MenuButtonItem, MenuLinkItem } from '../../menu-item'
import { Popover, PopoverAlignment, usePopover } from '../../popover'
import { useDataTableContext } from '../data-table-context'

export interface RowProps<TData> {
  isSelected?: boolean
  isSelectedOnChange?: (e: ChangeEvent<HTMLInputElement>) => void
  row: TableRow<TData>
}

export const Row = <TData,>({
  isSelected = false,
  isSelectedOnChange,
  row,
}: RowProps<TData>): JSX.Element => {
  const {
    columnCount,
    'data-cy': dataCy,
    getIsRowExpanded,
    getRowActions,
    getRowHasDetail,
    getRowMenuItems,
    hasConfigurableColumns,
    hasSelectableRows,
    highlightedRowIds,
    isTableHorizontallyScrollable,
    rowDetailRenderer,
    tableContainerWidth,
    toggleRow,
  } = useDataTableContext()

  const actions = getRowActions ? getRowActions(row.original) : undefined
  const menuItems = getRowMenuItems ? getRowMenuItems(row.original) : undefined

  const { triggerRef, popoverRef, isPresent, toggle, triggerBoundingBox } =
    usePopover<HTMLButtonElement>()
  const handleMenuToggle = useCallback(
    (e) => {
      e.stopPropagation()
      toggle()
    },
    [toggle],
  )
  const hasRowDetail = rowDetailRenderer && (getRowHasDetail ? getRowHasDetail(row.original) : true)
  const isRowExpanded = getIsRowExpanded(row.index)
  const hasStartCell = hasSelectableRows || !!rowDetailRenderer
  const areRowDetailsVisible = hasRowDetail && isRowExpanded
  const hasActionsOrMenu = !!menuItems || !!actions
  const hasEndCell = hasActionsOrMenu || hasConfigurableColumns

  let expandedRowSpan = columnCount
  if (hasStartCell) expandedRowSpan += 1
  if (hasEndCell) expandedRowSpan += 1

  const handleToggleRow = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      toggleRow(row.index)
    },
    [row.index, toggleRow],
  )

  const isHighlighted = highlightedRowIds?.includes(row.id)

  return (
    <>
      <StyledRow
        data-cy={dataCy && `${dataCy}:row`}
        isHighlighted={isHighlighted}
        isSelected={isSelected}
      >
        <When condition={hasStartCell}>
          <BodyCell hug>
            <RowActions reduceRightPadding>
              {hasRowDetail && (
                <Button
                  size="small"
                  emphasis="low"
                  color="neutral"
                  icon={getIsRowExpanded(row.index) ? ExpandLess : ExpandMore}
                  iconTransitionDurationMs={200}
                  onClick={handleToggleRow}
                />
              )}
              {hasSelectableRows && (
                <CheckboxContainer onClick={(e) => e.stopPropagation()}>
                  <Checkmark checked={isSelected} onChange={isSelectedOnChange} />
                </CheckboxContainer>
              )}
            </RowActions>
          </BodyCell>
        </When>
        <If condition={row.getVisibleCells().length === 0}>
          <Then>
            <BodyCell index={0} />
          </Then>
          <Else>
            {row.getVisibleCells().map((cell, i) => (
              <BodyCell
                key={cell.id}
                align={cell.column.columnDef.meta?.align}
                hug={row.getVisibleCells().length === 1 ? false : cell.column.columnDef.meta?.hug}
                maxWidth={cell.column.columnDef.meta?.maxWidth}
                index={i}
              >
                {cell.getContext().getValue() !== null
                  ? flexRender(cell.column.columnDef.cell, cell.getContext())
                  : '\u2014'}
              </BodyCell>
            ))}
          </Else>
        </If>
        <When condition={hasEndCell}>
          <BodyCell hug fixRight={hasActionsOrMenu && isTableHorizontallyScrollable}>
            <When condition={hasActionsOrMenu}>
              <RowActions reduceLeftPadding data-cy={dataCy && `${dataCy}:row-actions`}>
                <When condition={!!actions}>
                  {actions?.map((fa, i) => (
                    <Button
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      hasReducedPadding
                      size="small"
                      {...fa}
                      onClick={(e) => {
                        e.stopPropagation()
                        if (fa.onClick) fa.onClick(e)
                      }}
                    />
                  ))}
                </When>
                <When condition={!!menuItems}>
                  <Button
                    size="small"
                    emphasis="low"
                    color="neutral"
                    icon={More}
                    data-cy={dataCy && `${dataCy}:more-actions-button`}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleMenuToggle(e)
                    }}
                    isToggled={isPresent}
                    ref={triggerRef}
                  />
                  <Popover
                    ref={popoverRef}
                    alignment={PopoverAlignment.end}
                    isPresent={isPresent}
                    triggerBoundingBox={triggerBoundingBox}
                  >
                    <MenuItems data-cy={dataCy && `${dataCy}:more-actions-menu-items`}>
                      {menuItems?.map((fa, i) => {
                        if ('to' in fa) {
                          const typedFa = fa as MenuLinkItemProps
                          return (
                            <MenuLinkItem
                              // eslint-disable-next-line react/no-array-index-key
                              key={i}
                              {...typedFa}
                              onClick={(e) => {
                                e.stopPropagation()
                                if (typedFa.onClick) typedFa.onClick(e)
                                toggle()
                              }}
                            />
                          )
                        }
                        const typedFa = fa as MenuButtonItemProps
                        return (
                          <MenuButtonItem
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            {...typedFa}
                            onClick={(e) => {
                              e.stopPropagation()
                              if (typedFa.onClick) typedFa.onClick(e)
                              toggle()
                            }}
                          />
                        )
                      })}
                    </MenuItems>
                  </Popover>
                </When>
              </RowActions>
            </When>
          </BodyCell>
        </When>
      </StyledRow>
      {rowDetailRenderer && areRowDetailsVisible && (
        <ExpandedRow
          data-cy={dataCy && `${dataCy}:more`}
          isSelected={isSelected}
          fixLeftActions={isTableHorizontallyScrollable}
        >
          <td colSpan={expandedRowSpan}>
            <ExpandedContent tableContainerWidth={tableContainerWidth}>
              {rowDetailRenderer(row.original)}
            </ExpandedContent>
          </td>
        </ExpandedRow>
      )}
    </>
  )
}

const ExpandedRow = styled.tr<{
  isSelected: boolean
  fixLeftActions: boolean
}>(({ isSelected, fixLeftActions }) => ({
  position: 'relative',
  '& > td': {
    padding: 0,
    '&::before': {
      content: '""',
      display: isSelected || fixLeftActions ? 'block' : 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: 1,
      background: COLOR.NEUTRAL[300],
    },
    '&::after': {
      display: 'none',
    },
  },
}))

const StyledRow = styled.tr<{
  hasOnClick?: boolean
  isHighlighted?: boolean
  isSelected?: boolean
}>(({ hasOnClick, isHighlighted, isSelected }) => ({
  position: 'relative',
  background: COLOR.WHITE,
  '& > td': {
    background: isSelected ? COLOR.BLUE[100] : 'transparent',
    '&.table-cell-fixed': {
      background: isSelected ? COLOR.BLUE[100] : COLOR.NEUTRAL[100],
      filter: isSelected ? 'brightness(0.98) saturate(1.2)' : undefined,
    },
  },
  // Row Divider
  '&:not(:first-of-type) > td:nth-last-of-type(2)::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    height: 1,
    background: COLOR.NEUTRAL[300],
  },
  ...(hasOnClick && {
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(0.98) saturate(1.2)',
      // Display row divider line of expanded row directly below when hovering
      [`& + ${ExpandedRow} > td::before`]: {
        display: 'block',
      },
    },
  }),
  // Row Highlighter
  '&::after': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    inset: 0,
    pointerEvents: 'none',
    background: COLOR.BLUE.OPACITY[25],
    opacity: isHighlighted ? 0.75 : 0,
    transition: isHighlighted ? '200ms' : '1000ms',
  },
}))

const ExpandedContent = styled.div<{
  tableContainerWidth: number
}>(({ tableContainerWidth }) => ({
  position: 'sticky',
  left: 0,
  width: tableContainerWidth,
  padding: 16,
}))

const CheckboxContainer = styled.div({
  display: 'inline-flex',
  paddingLeft: 4,
  paddingRight: 4,
})

const MenuItems = styled.div({
  padding: '6px 8px',
})
