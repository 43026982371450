import type { FC } from 'react'
import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { useLazyGetOktaAccessTokenQuery } from '@helloextend/extend-api-rtk-query'
import { ToastColor, ToastDuration, useToaster } from '@helloextend/zen'
import { authActions } from '@helloextend/core-api-redux'
import { OKTA_CLIENT_ID, PORTAL_APP_REDIRECT_URI } from '@helloextend/client-constants'
import { getPkceCodeVerifier, getPkceState } from '../../utils/local-storage'
import { decodeToken } from '../../lib/jwt'
import { AuthLayout } from '../../hoc/auth-layout'
import { HeadTag } from '../../components/head-tag'
import { OktaLoginForm } from './okta-login-form'
import { useOktaExchangeToken } from '../../hooks/use-okta-exchange-token'

const OktaLoginCallback: FC = () => {
  const { toast } = useToaster()
  const history = useHistory()
  const dispatch = useDispatch()

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const code = params.get('code')
  const oktaCallbackError = params.get('error')
  const state = params.get('state')

  const [getOktaAccessToken, { isFetching: isGettingOktaToken }] = useLazyGetOktaAccessTokenQuery()
  const { exchangeToken, isLoading: isGettingExtendToken } = useOktaExchangeToken()

  const verifier = getPkceCodeVerifier()
  const pkceState = getPkceState()

  const handleLoginCallback = useCallback(async (): Promise<void> => {
    try {
      if (oktaCallbackError || !code || !verifier || pkceState !== state) {
        throw new Error('Error verifying PKCE state')
      }

      const oktaAccessTokenResponse = await getOktaAccessToken({
        code,
        verifier,
        redirectUri: PORTAL_APP_REDIRECT_URI,
        clientId: OKTA_CLIENT_ID,
      })

      if ('error' in oktaAccessTokenResponse) {
        throw new Error('error while getting okta accessToken')
      }

      if (!oktaAccessTokenResponse.data?.access_token) throw new Error('Okta token is undefined')

      const oktaAccessToken = oktaAccessTokenResponse.data.access_token

      const decodedToken = decodeToken(oktaAccessToken)
      if (!decodedToken) throw new Error('okta token not valid')

      // loads the bearer token into auth state to be used for the token exchange in `base-query.ts`
      dispatch(authActions.loginSuccess(oktaAccessToken))

      await exchangeToken({ accessToken: oktaAccessTokenResponse.data.access_token })
    } catch (err) {
      toast({
        message: 'Something went wrong during login',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      history.push('/login')
    }
  }, [
    oktaCallbackError,
    code,
    state,
    verifier,
    pkceState,
    getOktaAccessToken,
    exchangeToken,
    history,
    toast,
    dispatch,
  ])

  useEffect(() => {
    handleLoginCallback()
  }, [handleLoginCallback])

  return (
    <AuthLayout showFooter>
      <HeadTag siteTitle="Extend | Login" />
      <OktaLoginForm isLoading={isGettingOktaToken || isGettingExtendToken} />
    </AuthLayout>
  )
}

export { OktaLoginCallback }
