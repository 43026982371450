import type { UploadValidatorPipe } from './csv-validation'
import { getColumnValue } from './csv-validation'

const expectedDateColumns = [
  {
    name: 'activateFrom',
    required: true,
    userFriendlyColumnName: 'Activate From',
  },
  {
    name: 'activateTo',
    required: false,
    userFriendlyColumnName: 'Activate To',
  },
]

const validateActivateTo = (validationPipeResults: UploadValidatorPipe): UploadValidatorPipe => {
  const activateFrom = getColumnValue(validationPipeResults, 'activateFrom' as string)
  const activateTo = getColumnValue(validationPipeResults, 'activateTo')

  const activateFromDate = new Date(activateFrom).getTime()
  const activateToDate = new Date(activateTo).getTime()
  if (activateTo !== '' && activateFromDate > activateToDate) {
    validationPipeResults.errors.push(`Activate To must be later than Activate From`)
  }
  return validationPipeResults
}

const validateDateColumns = (validationPipeResults: UploadValidatorPipe): UploadValidatorPipe => {
  expectedDateColumns.forEach((column) => {
    const value = getColumnValue(validationPipeResults, column.name)
    // don't check if the column is not required
    if (value === '' && !column.required) return
    if (value === '' && column.required) {
      validationPipeResults.errors.push(`${column.userFriendlyColumnName} is required`)
      return
    }

    // ensures the date format to be MM/DD/YYYY or M/D/YYYY; up to the year of 2099
    const dateRegex = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/
    if (!dateRegex.test(value)) {
      validationPipeResults.errors.push(`${column.userFriendlyColumnName} is not a valid date`)
    }
  })

  return validationPipeResults
}

export { validateActivateTo, validateDateColumns }
