import {
  useLazyFetchPlanQuery,
  usePrefetchPlanTerms,
  usePrefetchPlans,
} from '@helloextend/extend-api-rtk-query'
import type { FC, SyntheticEvent } from 'react'
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import type { Column } from '@helloextend/merchants-ui'
import { Button } from '@helloextend/zen'
import { useDispatch, useSelector } from 'react-redux'
import { COLOR } from '@helloextend/client-branding'
import { useHistory } from 'react-router-dom'
import * as selectors from '../../../reducers/selectors'
import { getPlansColumns } from './table-config'
import type { RootState } from '../../../reducers'
import { plans as plansActions } from '../../../actions'
import { toTablePlanItems } from './to-table-plan-items'
import type { TablePlansSearch } from '../../../types/plans'
import { DataTable } from '../../../components/data-table'
import { usePermissions } from '../../../hooks/use-permissions'
import { Permission } from '../../../lib/permissions'
import { TabMenu } from '../../../components/tab-menu/tab-menu'

enum PlansTab {
  ServiceContracts = 'serviceContracts',
}

const Plans: FC = () => {
  const dispatch = useDispatch()

  const [exportingPlan, setExportingPlan] = useState<string>('')
  const [activeTab, setActiveTab] = useState<PlansTab>(PlansTab.ServiceContracts)
  const plans = useSelector((state: RootState) => selectors.getPlansSearchItems(state))
  const tablePlans = toTablePlanItems(plans)
  const accessToken = useSelector((state: RootState) => selectors.getAccessToken(state))
  const isLoading = useSelector((state: RootState) => selectors.getIsPlansLoading(state))
  const searchFilter = useSelector((state: RootState) => selectors.getPlansSearchFilter(state))
  const [searchValue, setSearchValue] = useState(searchFilter)
  const nextPageCursor = useSelector((state: RootState) => selectors.getPlansSearchCursor(state))
  const hasPrevPage = useSelector((state: RootState) => selectors.getHasPlansPrevPage(state))
  const page = useSelector((state: RootState) => selectors.getPlansSearchPage(state))
  const lastFetchedPage = useSelector((state: RootState) =>
    selectors.getPlansLastFetchedPage(state),
  )

  const prefetchTerms = usePrefetchPlanTerms('listPlanTerms')
  const prefetchAttributes = usePrefetchPlans('listPlanAttributes')

  const { hasPermission } = usePermissions()

  const { push } = useHistory()

  const exportColumn: Column<TablePlansSearch> = {
    Header: '',
    accessor: 'id',
    Cell: (data: TablePlansSearch) => (
      <ButtonContainer>
        <Button
          isDisabled={!!exportingPlan}
          isProcessing={exportingPlan === data.id}
          text="Export"
          emphasis="low"
          onClick={(e) => {
            setExportingPlan(data.id)
            e.stopPropagation()
            fetchPlan(data.id)
          }}
        />
      </ButtonContainer>
    ),
  }

  const [fetchPlan, { data: currentPlan }] = useLazyFetchPlanQuery()

  useEffect(() => {
    if (exportingPlan && currentPlan && 'id' in currentPlan && exportingPlan === currentPlan.id) {
      const { termsVersion, version, ...exportPlan } = currentPlan
      const fileData = JSON.stringify(exportPlan)
      const blob = new Blob([fileData], { type: 'text/plain' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.download = `Plan_${exportPlan.id}.json`
      link.href = url
      link.click()
      setExportingPlan('')
    }
  }, [currentPlan, exportingPlan])

  const handleSearch = (_key: string, value: string): void => {
    if (accessToken && value) {
      setSearchValue(value)
      dispatch(plansActions.search(accessToken, value))
    }
  }

  const handleRowClick = (_e: SyntheticEvent, plan: TablePlansSearch): void => {
    push(`/admin/plans/${plan.id}`)
  }

  const handleCreateNewPlan = (): void => {
    push('/admin/plans/create')
  }

  const handleViewPlanAttributes = (): void => {
    push('/admin/plans/attributes')
  }

  const handleTabClick = (tab: string): void => {
    setActiveTab(tab as PlansTab)
    const planUrl = '/admin/plans/service-contracts'
    push(planUrl)
  }

  useEffect(() => {
    prefetchTerms()
    prefetchAttributes()
  }, [prefetchTerms, prefetchAttributes])

  const handlePlanImport = (): void => {
    push('/admin/plans/import')
  }

  const fetchNextPage = (): void => {
    if (accessToken && nextPageCursor && searchValue && page === lastFetchedPage) {
      dispatch(plansActions.nextPage(accessToken, searchValue, nextPageCursor))
    } else {
      dispatch(plansActions.incrementNextPage())
    }
  }

  const getPrevPage = (): void => {
    dispatch(plansActions.prevPage())
  }

  return (
    <>
      <Header>
        <Title data-cy="plans-header-text">Plans</Title>
        <ButtonContainer>
          {hasPermission(Permission.ManagePlansAttributes) && (
            <Button
              text="View Plan Attributes"
              emphasis="medium"
              onClick={handleViewPlanAttributes}
              data-cy="view-plan-attributes-btn"
            />
          )}
          <Button
            data-cy="import-plan-btn"
            text="Import Plan"
            emphasis="medium"
            onClick={handlePlanImport}
          />
          <Button
            data-cy="create-new-plan-btn"
            text="Create New Plan"
            emphasis="medium"
            onClick={handleCreateNewPlan}
          />
        </ButtonContainer>
      </Header>
      <TabContent>
        <TabMenu
          tabs={[{ text: 'Service Contracts', key: PlansTab.ServiceContracts }]}
          onClick={handleTabClick}
          activeTab={activeTab}
        />
      </TabContent>
      <DataTable
        data={tablePlans}
        columns={getPlansColumns(exportColumn)}
        searchMode="server"
        onSearch={handleSearch}
        isLoading={isLoading}
        searchMessage="Search for plans by plan ID"
        rowClickEvent={handleRowClick}
        paginationType="plans"
        currPage={page}
        onPrevPage={getPrevPage}
        onNextPage={fetchNextPage}
        hasNext={Boolean(nextPageCursor) || page < lastFetchedPage}
        hasPrev={hasPrevPage}
        searchTerm={searchValue}
      />
    </>
  )
}

const Title = styled.h1({
  color: COLOR.EXTEND_OXFORD,
  fontSize: 32,
  lineHeight: '44px',
  fontWeight: 800,
  marginTop: 0,
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 16,
})

const TabContent = styled.div({
  marginBottom: 40,
})

export { Plans }
