import type { StoreUser } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const userDelete = (user: StoreUser, accessToken: string) =>
  ({
    type: 'USER_DELETE',
    payload: { user, accessToken },
  } as const)

export const userDeleteRequest = () =>
  ({
    type: 'USER_DELETE_REQUEST',
  } as const)

export const userDeleteSuccess = (user: StoreUser) =>
  ({
    type: 'USER_DELETE_SUCCESS',
    payload: { user },
  } as const)

export const userDeleteFailure = createActionFailure('USER_DELETE_FAILURE')

export const userDeleteReset = () => ({ type: 'USER_DELETE_RESET' } as const)
