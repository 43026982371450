import type { FC, MouseEvent } from 'react'
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import type {
  NestedCheckboxFilterValues,
  DateRangeFilterValues,
  FilterValues,
  CheckboxFilterValues,
} from '@helloextend/merchants-ui'
import { DataReactTable, PaginationType } from '@helloextend/merchants-ui'
import type { InsuranceClaimsListQueryStringOptions } from '@helloextend/extend-api-rtk-query'
import {
  useListClaimsAssignmentUsersQuery,
  useListInsuranceClaimsQuery,
  useLazyGetServiceOrderQuery,
} from '@helloextend/extend-api-rtk-query'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useQueryStringState } from '@helloextend/client-hooks/src/use-query-string-state'
import { date } from '@helloextend/client-utils'
import type { InsuranceClaim } from '@helloextend/extend-api-client'
import { useShellContext } from '@helloextend/zen'
import { getTableColumns, searchOptions, getFilterOptions } from './table-config'
import { setClaimsUrl } from '../../../store/slices/claim-breadcrumb'

const EMPTY_FILTER_MESSAGE =
  'Double check that the search term is correct, or try applying different filters to your search.'

type Filters = Pick<
  InsuranceClaimsListQueryStringOptions,
  | 'matchesClaimType'
  | 'matchesClaimServiceType'
  | 'matchesClaimStatus'
  | 'matchesClosedResolution'
  | 'matchesPendingDisposition'
  | 'matchesAssignedUser'
  | 'createdAtBegin'
  | 'createdAtEnd'
  | 'reportedAtBegin'
  | 'reportedAtEnd'
  | 'updatedAtBegin'
  | 'updatedAtEnd'
>

const ClaimsDataTable: FC = () => {
  const { search: queryParams } = useLocation()
  const dispatch = useDispatch()
  const [isAssigneeDropdownOpen, setIsAssigneeDropdownOpen] = useState<boolean>(false)

  useEffect(() => {
    // Sync the breadcrumb whenever the query params change
    const claimsUrl = `/admin/claims${queryParams}`
    dispatch(setClaimsUrl(claimsUrl))
  }, [dispatch, queryParams])

  const { getScrollableRegionRef } = useShellContext()
  const [searchFilters, setSearchFilters] = useQueryStringState<
    Record<string, FilterValues | null>
  >('filters', {}, { encode: true, transformDates: true })
  const { data: users } = useListClaimsAssignmentUsersQuery(undefined)

  const [search, setSearch] = useState<Record<string, string>>({})
  const [nextPageCursor, setNextPageCursor] = useState('')
  const [pageSize, setPageSize] = useQueryStringState('pageSize', 50)
  const [autoResetPage, setAutoResetPage] = useState(true)
  const [getServiceOrder] = useLazyGetServiceOrderQuery()

  const filterOptions = useMemo(() => {
    return getFilterOptions(users?.items ?? [])
  }, [users])

  const filtersForApi = useMemo(() => {
    const newFilters: Filters = {}

    if (
      searchFilters.status &&
      Object.keys((searchFilters.status as NestedCheckboxFilterValues).values).length
    ) {
      const filterValues = (searchFilters.status as NestedCheckboxFilterValues).values
      newFilters.matchesClaimStatus = []
      newFilters.matchesClosedResolution = undefined
      Object.entries(filterValues).forEach(([status, subStatuses]) => {
        ;(newFilters.matchesClaimStatus as string[]).push(status)
        if (status === 'closed' && subStatuses?.length) {
          newFilters.matchesClosedResolution = subStatuses
        } else if (status === 'pending' && subStatuses?.length) {
          newFilters.matchesPendingDisposition = subStatuses
        }
      })
    }

    if (searchFilters.reportedAtDate) {
      const { start, end } = searchFilters.reportedAtDate as DateRangeFilterValues
      if (start) newFilters.reportedAtBegin = date.getStartOfDay(start).getTime()
      if (end) newFilters.reportedAtEnd = date.getEndOfDay(end).getTime()
    }

    if (
      searchFilters.assignee &&
      Object.keys((searchFilters.assignee as NestedCheckboxFilterValues).values).length
    ) {
      newFilters.matchesAssignedUser = Object.keys(
        (searchFilters.assignee as NestedCheckboxFilterValues).values,
      ).join(',')
    }

    if (searchFilters.type) {
      const claimTypes = (searchFilters.type as CheckboxFilterValues).values
      if (claimTypes.length) {
        newFilters.matchesClaimType = claimTypes.join(',')
      }
    }

    if (searchFilters.serviceType) {
      const serviceTypes = (searchFilters.serviceType as CheckboxFilterValues).values
      if (serviceTypes.length) {
        newFilters.matchesClaimServiceType = serviceTypes.join(',')
      }
    }

    return newFilters
  }, [
    searchFilters.reportedAtDate,
    searchFilters.status,
    searchFilters.assignee,
    searchFilters.type,
    searchFilters.serviceType,
  ])

  const { data, isLoading, isFetching } = useListInsuranceClaimsQuery({
    minLimit: 100,
    cursor: nextPageCursor || undefined,
    ...search,
    ...filtersForApi,
  })

  const handleServerPagination = useCallback((cursor: string) => {
    setNextPageCursor(cursor || '')
    setAutoResetPage(false)
  }, [])

  const setPagination = useCallback(
    (newPageSize: number) => {
      setPageSize(newPageSize)
    },
    [setPageSize],
  )

  const columns = useMemo(
    () =>
      getTableColumns({
        onToggleAssigneeDropdown: setIsAssigneeDropdownOpen,
      }),
    [setIsAssigneeDropdownOpen],
  )

  const handleRowClick = useCallback(
    (e: MouseEvent, rowData: InsuranceClaim) => {
      // This logic is very specific to this page and not very extensible.
      // The first two conditions are checking if the click event was triggered
      // by any class/tag related to the assignment dropdown.
      // The third checks if any dropdown is already open - if so, clicking out of it
      // should just close it rather than navigate to a new page.
      const target = e.target as HTMLElement
      if (
        !/AssigneeName|Icon|StyledButton|Children/g.test(target.className) &&
        !/path|svg/g.test(target.tagName) &&
        !isAssigneeDropdownOpen
      ) {
        window.open(`/admin/claims/${rowData.id}`, '_blank', 'noreferrer')
      }
    },
    [isAssigneeDropdownOpen],
  )

  const handleSearch = useCallback(
    async (key?: string, value?: string): Promise<void> => {
      if (key && value) {
        let updatedValue = ''
        if (key === 'containsServiceOrderId') {
          try {
            const serviceOrder = await getServiceOrder({ serviceOrderId: value }).unwrap()
            updatedValue = serviceOrder.claimId
          } catch (e) {
            updatedValue = ''
          }
        }
        updatedValue = updatedValue || value
        const updatedKey = key === 'containsServiceOrderId' ? 'containsClaimId' : key
        setSearch({ [updatedKey]: updatedValue })
      } else {
        setSearch({})
      }
    },
    [setSearch, getServiceOrder],
  )

  const initialState = useMemo(() => {
    return {
      sortBy: [{ id: 'reportedAt', desc: true }],
      pageSize,
      filters: Object.keys(searchFilters).map((key) => ({ id: key, value: searchFilters[key] })),
    }
  }, [pageSize, searchFilters])

  const resetPagination = useCallback(() => {
    setNextPageCursor('')
    setAutoResetPage(true)
  }, [])

  const handleServerFilter = useCallback(
    (filtersRecord: Record<string, FilterValues | null>) => {
      setSearchFilters(filtersRecord)
      resetPagination()
    },
    [resetPagination, setSearchFilters],
  )

  return (
    <DataReactTable
      hasSearchBar
      searchOptions={searchOptions}
      enableSearch
      shouldDisplayZeroState={false}
      isInitialReqFullfilled
      onServerSearch={handleSearch}
      initialState={initialState}
      data={data?.items ?? []}
      isLoading={isLoading || isFetching}
      columns={columns}
      emptyMessage={EMPTY_FILTER_MESSAGE}
      type="claims"
      getScrollToRef={getScrollableRegionRef}
      onRowClick={handleRowClick}
      autoResetPage={autoResetPage}
      nextPageCursor={data?.nextPageCursor}
      paginationType={PaginationType.ENHANCED_SERVER_SIDE}
      onServerPagination={handleServerPagination}
      setPagination={setPagination}
      filterOptions={filterOptions}
      onServerFilter={handleServerFilter}
    />
  )
}

export { ClaimsDataTable }
