import type { FC } from 'react'
import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { RouterError } from '@helloextend/component-commons'

import DashboardLayout, { DashboardSection } from '../../hoc/dashboard-layout'
import { ProtectedRoute } from '../../hoc/protected-route'
import { Permission } from '../../lib/permissions'
import { Plans } from './plans'
import { Plan } from './plan'
import { Servicers } from './servicers'
import { Servicer } from './servicer'
import { ServicersCreate } from './servicers-create'
import { Products } from './products'
import { PlansCreate } from './plans-create'
import { Contracts } from './contracts'
import { Contract } from './contract'
import { ContractAuditLog } from './contract-audit-log'
import { ContractClaims } from './contract-claims'
import { Settings } from './settings'
import { PlanAttributes } from './plan-attributes'
import { PlansImport } from './plans-import'
import { PlanAttribute } from './plan-attribute'
import { PlanVersion } from './plan-version'
import { Claims } from './claims'
import { Claim } from './claim'
import { Skus } from './skus'
import { SkusCreate } from './skus-create'
import { Sku } from './sku'
import { SkuVersion } from './sku-version'
import { ClaimAssignmentUsers } from './claim-assignment-users'
import { SkusImport } from './skus-import'
import { InsuranceProgramsCreate } from './insurance-programs-create'
import { InsurancePrograms } from './insurance-programs'
import {
  AdjudicationLanding,
  AdjudicationConversationList,
  AdjudicationConversationPreview,
  AdjudicationConversationEdit,
  AdjudicationThreadList,
  AdjudicationThreadPreview,
  AdjudicationThreadEdit,
  ConversationConfigurationLanding,
  ConversationConfigurationDetails,
} from './adjudication-management'
import { InsuranceProgram } from './insurance-program'
import { FileAClaim } from './file-a-claim'
import { FileClaimResults } from './file-claim-results/file-claim-results'
import { AccountDetails, Accounts, UserDetails as MerchantUserDetails } from './merchants'
import { StoreDetails } from './merchants/stores/store-details'
import { Stores } from './merchants/stores/stores'
import { Organizations } from './merchants/organizations/organizations'
import { UserManagement } from './user-management'
import { PlanSets } from './plan-sets'
import { ConversationConfigurationCreate } from './adjudication-management/conversation-configuration/conversation-configuration-create'
import { ServicingLocations } from './merchants/stores/store-details/settings/components/servicing-locations'
import { UserDetails } from './user-management/user-details'
import { WholesalePricing } from './merchants/stores/store-details/plan-and-pricing/components/wholesale-pricing'
import { PricingDetails } from './merchants/stores/store-details/plan-and-pricing/components/pricing-details'
import { ProductDetails } from './merchants/stores/store-details/products/product-details'
import { ContractsCreate } from './contracts-create/contracts-create'
import { ProductImport } from './merchants/stores/store-details/products/product-import/product-import'
import { Terms } from './terms/terms'
import { CreateTerms } from './terms/create-terms/create-terms'
import { TermsVersions } from './terms/terms-versions/terms-versions'
import { CreateTermsVersion } from './terms/create-terms-version/create-terms-version'
import { ProductVariants } from './merchants/stores/store-details/products/product-variants/product-variants'
import { PlanSetDetails } from './merchants/stores/store-details/plan-and-pricing/components/plan-set-details/plan-set-details'

const Admin: FC = () => {
  return (
    <DashboardLayout section={DashboardSection.Admin}>
      <Switch>
        <Redirect from="/admin" to="/admin/contracts" exact />
        <Redirect from="/admin/plans" to="/admin/plans/service-contracts" exact />
        <Redirect from="/admin/products" to="/admin/products/plan-mapping" exact />
        <ProtectedRoute
          requiredPermissions={[Permission.ManagePlans]}
          exact
          path="/admin/plans/service-contracts"
          component={Plans}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManagePlans]}
          exact
          path="/admin/plans/create"
          component={PlansCreate}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManagePlanSets]}
          exact
          path="/admin/plan-sets"
          component={PlanSets}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManagePlansAttributes]}
          exact
          path="/admin/plans/attributes"
          component={PlanAttributes}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManagePlansAttributes]}
          exact
          path="/admin/plans/attributes/:planAttributeId"
          component={PlanAttribute}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManagePlans]}
          exact
          path="/admin/plans/import"
          component={PlansImport}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManagePlans]}
          exact
          path="/admin/plans/:planId"
          component={Plan}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManagePlans]}
          exact
          path="/admin/plans/:planId/versions"
          component={Plan}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManagePlans]}
          exact
          path="/admin/plans/:planId/versions/:versionId"
          component={PlanVersion}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageServicers]}
          exact
          path="/admin/servicers"
          component={Servicers}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageServicers]}
          exact
          path="/admin/servicers/create"
          component={ServicersCreate}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageServicers]}
          exact
          path="/admin/servicers/:servicerId"
          component={Servicer}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageContracts]}
          exact
          path="/admin/contracts"
          errorRedirectPath="/admin/plans" // TODO: MEXP-471 remove when contracts ff is on in all environments
          component={Contracts}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageContracts]}
          exact
          path="/admin/contracts/create"
          component={ContractsCreate}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageContracts]}
          exact
          path="/admin/contracts/:contractId/claims"
          component={ContractClaims}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageContracts]}
          exact
          path="/admin/contracts/:contractId/auditlog"
          component={ContractAuditLog}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageContracts]}
          exact
          path="/admin/contracts/:contractId"
          component={Contract}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageContracts, Permission.ManageClaims]}
          exact
          path="/admin/contracts/:contractId/file-a-claim"
          component={FileAClaim}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageContracts, Permission.ManageClaims]}
          exact
          path="/admin/contracts/:contractId/file-a-claim/:claimId"
          component={FileClaimResults}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageSettings]}
          exact
          path="/admin/settings"
          component={Settings}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageProducts]}
          exact
          path="/admin/products/plan-mapping"
          component={Products}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageCategories]}
          exact
          path="/admin/products/category-mapping"
          component={Products}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageClaims]}
          exact
          path="/admin/claims"
          component={Claims}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageClaims]}
          exact
          path="/admin/claims/:claimId"
          component={Claim}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageSkus]}
          exact
          path="/admin/premiums"
          component={Skus}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageSkus]}
          exact
          path="/admin/premiums/create"
          component={SkusCreate}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageSkus]}
          exact
          path="/admin/premiums/import"
          component={SkusImport}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageSkus]}
          exact
          path="/admin/premiums/:skuId"
          component={Sku}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageSkus]}
          exact
          path="/admin/premiums/:skuId/versions"
          component={Sku}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageSkus]}
          exact
          path="/admin/premiums/:skuId/versions/:versionId"
          component={SkuVersion}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ViewAdjudication]}
          exact
          path="/admin/adjudication-management"
          component={AdjudicationLanding}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ViewAdjudication]}
          exact
          path="/admin/adjudication-management/conversations"
          component={AdjudicationConversationList}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ViewAdjudication]}
          exact
          path="/admin/adjudication-management/conversations/:id/preview"
          component={AdjudicationConversationPreview}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageAdjudication]}
          exact
          path="/admin/adjudication-management/conversations/:id/edit"
          component={AdjudicationConversationEdit}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ViewAdjudication]}
          exact
          path="/admin/adjudication-management/threads"
          component={AdjudicationThreadList}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ViewAdjudication]}
          exact
          path="/admin/adjudication-management/threads/:id/preview"
          component={AdjudicationThreadPreview}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageAdjudication]}
          exact
          path="/admin/adjudication-management/threads/:id/edit"
          component={AdjudicationThreadEdit}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageAdjudication]}
          exact
          path="/admin/adjudication-management/threads/:id/edit/content"
          component={AdjudicationThreadEdit}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageAdjudication]}
          exact
          path="/admin/adjudication-management/threads/:id/edit/adjudication-rules"
          component={AdjudicationThreadEdit}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ViewAdjudication]}
          exact
          path="/admin/adjudication-management/conversation-configuration"
          component={ConversationConfigurationLanding}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageAdjudication]}
          exact
          path="/admin/adjudication-management/conversation-configuration/create"
          component={ConversationConfigurationCreate}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ViewAdjudication]}
          exact
          path="/admin/adjudication-management/conversation-configuration/:id"
          component={ConversationConfigurationDetails}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageClaims]}
          exact
          path="/admin/claim-assignment"
          component={ClaimAssignmentUsers}
        />

        <ProtectedRoute
          requiredPermissions={[Permission.ManageSkus]}
          exact
          path="/admin/insurance-programs"
          component={InsurancePrograms}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageSkus]}
          exact
          path="/admin/insurance-programs/create"
          component={InsuranceProgramsCreate}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageSkus]}
          exact
          path="/admin/insurance-programs/:id"
          component={InsuranceProgram}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageSkus]}
          exact
          path="/admin/insurance-programs/:id/versions"
          component={InsuranceProgram}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores"
          component={Stores}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores/:storeId"
          component={StoreDetails}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores/:storeId/products"
          component={StoreDetails}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores/:storeId/products/import"
          component={ProductImport}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores/:storeId/products/:referenceId/locations"
          component={ServicingLocations}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores/:storeId/products/:referenceId/variants"
          component={ProductVariants}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores/:storeId/products/:variantReferenceId/variants/:referenceId"
          component={ProductDetails}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores/:storeId/products/:referenceId"
          component={ProductDetails}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores/:storeId/shipping-protection"
          component={StoreDetails}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores/:storeId/product-protection"
          component={StoreDetails}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores/:storeId/product-protection/:planId/wholesale-pricing"
          component={WholesalePricing}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores/:storeId/product-protection/:planId/pricing"
          component={PricingDetails}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores/:storeId/plan-set/:planSetId"
          component={PlanSetDetails}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores/:storeId/settings"
          component={StoreDetails}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores/:storeId/settings/locations"
          component={ServicingLocations}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores/:storeId/integration-settings"
          component={StoreDetails}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageStores]}
          exact
          path="/admin/stores/:storeId/wd-configuration"
          component={StoreDetails}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageAccounts]}
          exact
          path="/admin/accounts"
          component={Accounts}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageAccounts]}
          exact
          path="/admin/accounts/:accountId/:tab"
          component={AccountDetails}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageAccounts]}
          exact
          path="/admin/accounts/:accountId/users/:userId"
          component={MerchantUserDetails}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageOrganizations]}
          exact
          path="/admin/organizations"
          component={Organizations}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageUsers]}
          exact
          path="/admin/user-management"
          component={UserManagement}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageUsers]}
          exact
          path="/admin/user-management/users/:userId"
          component={UserDetails}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageTerms]}
          exact
          path="/admin/terms"
          component={Terms}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageTerms]}
          exact
          path="/admin/terms/:termsId/create-version"
          component={CreateTermsVersion}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageTerms]}
          exact
          path="/admin/terms/:termsId/versions"
          component={TermsVersions}
        />
        <ProtectedRoute
          requiredPermissions={[Permission.ManageTerms]}
          exact
          path="/admin/terms/create"
          component={CreateTerms}
        />
        <Route>
          <RouterError />
        </Route>
      </Switch>
    </DashboardLayout>
  )
}

export { Admin }
