import type { FC } from 'react'
import React, { useState } from 'react'
import { COLOR } from '@helloextend/client-branding'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { AnimatedSpinnerCheck } from '../animated-spinner-check'
import { WarningTriangle } from '../icons'
import { Button } from '../button'

interface ToastProps {
  message: string
  isSuccessToaster?: boolean
  onAnimationEnd?: () => void
}

/**
 * @deprecated Use Zen Toaster component instead: `import { Toaster } from '@helloextend/zen'`
 */
const Toast: FC<ToastProps> = ({ message, onAnimationEnd, isSuccessToaster = true }) => {
  const [showClass, setShowClass] = useState<string>('show')

  const autoHide = (): void => {
    if (!isSuccessToaster) return

    setShowClass('exit-toast')
    if (onAnimationEnd) {
      onAnimationEnd()
    }
  }

  const handleClickDismiss = (): void => {
    setShowClass('exit-toast')
    if (onAnimationEnd) {
      onAnimationEnd()
    }
  }

  const Success = (
    <>
      <AnimatedSpinnerCheck
        data-cy="success-icon"
        size={32}
        loading={false}
        thickness={1}
        color={COLOR.WHITE}
        hideMargin
      />
      <Message>{message}</Message>
    </>
  )

  const Error = (
    <>
      <ErrorMessageContainer data-cy="error-message-container">
        <IconBox>
          <WarningTriangle fill={COLOR.WHITE} />
        </IconBox>
        <Message>{message}</Message>
      </ErrorMessageContainer>
      <DismissButton text="Dismiss" kind="danger" size="sm" onClick={handleClickDismiss} />
    </>
  )

  return (
    <ToasterPopupWrapper
      data-cy="toast-message"
      className={showClass}
      onAnimationEnd={autoHide}
      isSuccessToaster={isSuccessToaster}
    >
      <Container data-cy="container-message">{isSuccessToaster ? Success : Error}</Container>
    </ToasterPopupWrapper>
  )
}

const Message = styled.div({
  paddingLeft: 16,
  fontSize: 18,
  fontWeight: 700,
})

const ErrorMessageContainer = styled.div({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const IconBox = styled.div({
  width: 36,
  height: 36,
})

const DismissButton = styled(Button)({
  fontSize: 14,
})

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 100,
  width: '100%',
  whiteSpace: 'nowrap',
})

const drop = keyframes({
  '0%': {
    transform: 'translateY(-100%)',
  },
  '100%': {
    transform: 'translateY(0%)',
  },
})

const out = keyframes({
  '0%': {
    transform: 'translateY(0%)',
  },
  '100%': {
    transform: 'translateY(-100%)',
  },
})

interface ToasterPopupWrapperProps {
  isSuccessToaster: boolean
}

const ToasterPopupWrapper = styled.div<ToasterPopupWrapperProps>(({ isSuccessToaster }) => ({
  display: 'none',
  position: 'fixed',
  top: 0,
  left: 0,
  backgroundColor: isSuccessToaster ? COLOR.EXTEND_BLUE : COLOR.STATE_DANGER,
  height: 59,
  width: '100%',
  boxSizing: 'border-box',
  padding: '10px 20px 8px',
  alignItems: 'center',
  justifyContent: 'center',
  color: COLOR.WHITE,
  zIndex: 1000000,
  '&.show': {
    display: 'flex',
    animation: `${drop} 0.6s`,
  },
  '&.exit-toast': {
    display: 'flex',
    animation: `${out} 0.6s forwards`,
    animationDelay: '1000ms',
  },
  '&.hide': {
    display: 'none',
  },
}))

export { Toast, ToastProps }
