import { createApi } from '@reduxjs/toolkit/query/react'
import type { Order } from '@helloextend/extend-api-client'
import { baseQuery } from '../base-query'

export const ordersApi = createApi({
  baseQuery,
  reducerPath: 'Orders',
  tagTypes: ['Orders'],
  endpoints: (build) => ({
    getOrder: build.query<Order, string>({
      query: (orderId: string) => ({
        url: `orders/${orderId}`,
        headers: {
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_, _err, orderId) => [{ type: 'Orders', id: orderId }],
    }),
  }),
})

export const { useGetOrderQuery } = ordersApi
