import { storesActions } from '@helloextend/core-api-redux'
import type { Store } from '@helloextend/extend-api-client'

// stores set active
// ==================================================================================
export const setActiveId = (id: string) =>
  ({
    type: 'STORES_SET_ACTIVE_ID',
    payload: id,
  } as const)

// Search filter
export const searchFilterSet = (val: string) =>
  ({
    type: 'STORES_SEARCH_FILTER_SET',
    payload: val,
  } as const)

export const searchFilterClear = () =>
  ({
    type: 'STORES_SEARCH_FILTER_CLEAR',
  } as const)

// stores update
// ==================================================================================

export const updateSuccess = (store: Store) =>
  ({
    type: 'ADMIN_STORES_UPDATE_SUCCESS',
    payload: store,
  } as const)

export const {
  fetchAll,
  fetchAllRequest,
  fetchAllSuccess,
  fetchAllFailure,
  fetch,
  fetchRequest,
  fetchSuccess,
  fetchFailure,
} = storesActions
