import type { FC } from 'react'
import React from 'react'
import { InlineAlert, InlineAlertColor, Error, Warning, Info } from '@helloextend/zen'
import { uuid } from '@helloextend/extend-sdk-client/lib/utils/util'
import styled from '@emotion/styled'

export interface InlineAlertsProps {
  alerts: string[]
  alertType: 'warning' | 'error' | 'info'
  'data-cy': string
}

export interface AlertType {
  icon: typeof Error | typeof Warning | typeof Info
  color: InlineAlertColor
}

const InlineAlerts: FC<InlineAlertsProps> = ({ alerts, alertType, 'data-cy': dataCy }) => {
  const { icon, color } = getIconAndColor(alertType)
  return (
    <InlineAlert data-cy={dataCy} key={uuid()} color={color} icon={icon}>
      {alerts.map((alert) => {
        // Adds div for line breaks when \n included in alert string
        return alert.split('\n').map((text) => <Text key={uuid()}>{text}</Text>)
      })}
    </InlineAlert>
  )
}

const getIconAndColor = (alertType: 'warning' | 'error' | 'info'): AlertType => {
  switch (alertType) {
    case 'error':
      return { icon: Error, color: InlineAlertColor.red }
    case 'warning':
      return { icon: Warning, color: InlineAlertColor.yellow }
    case 'info':
      return { icon: Info, color: InlineAlertColor.blue }
    default:
      return { icon: Warning, color: InlineAlertColor.yellow }
  }
}

const Text = styled.div({
  minHeight: '20px',
})

export { InlineAlerts }
