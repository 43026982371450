import styled from '@emotion/styled'
import {
  useSaveConversationConfigurationMutation,
  useDeleteConfigurationMutation,
} from '@helloextend/extend-api-rtk-query'
import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import { useParams, useHistory } from 'react-router'
import { Box, Breadcrumbs, COLOR, IconSize, Spinner } from '@helloextend/zen'
import { useStandardToast } from '@helloextend/merchants-ui'

import { useConversationConfigurationDetail } from '../../../../hooks/use-get-conversation-configuration-detail'
import type { ConfigurationFormValues } from './conversation-configuration-form'
import { ConversationConfigurationForm } from './conversation-configuration-form'
import { ConfirmationModal } from '../components/confirmation-modal'

const ConversationConfigurationDetails: FC = () => {
  // **** State Initialization *** //
  const { id: configId } = useParams<{ id: string }>()

  const { push } = useHistory()
  const { toastCompleted, toastError } = useStandardToast()
  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState<boolean>(false)

  const [conversationIdRequestedToSave, setConversationIdRequestedToSave] = useState<
    string | undefined
  >(undefined)

  const handleFetchComplete = (): void => {
    if (!isDirty && isSaveSuccess) {
      setConversationIdRequestedToSave(undefined)
      resetSave()
    }
  }
  const { data: configurationDetail, isLoading: isLoadingConfigurationDetail } =
    useConversationConfigurationDetail(configId, handleFetchComplete)

  const [saveConfig, { isLoading: isSaveProcessing, isSuccess: isSaveSuccess, reset: resetSave }] =
    useSaveConversationConfigurationMutation()

  const [
    deleteConfiguration,
    { isLoading: isDeleteProcessing, error: deleteError, reset: resetDelete },
  ] = useDeleteConfigurationMutation()

  const isDirty = useMemo(() => {
    return conversationIdRequestedToSave
      ? configurationDetail?.conversationId !== conversationIdRequestedToSave
      : false
  }, [conversationIdRequestedToSave, configurationDetail?.conversationId])

  const isConfigSaveProcessing = useMemo(() => {
    return isSaveProcessing || (!isSaveProcessing && isDirty && isSaveSuccess)
  }, [isSaveProcessing, isDirty, isSaveSuccess])

  // **** Event Handlers *** //
  const handleToggleDeleteModal = (): void => {
    setIsConfirmDeleteVisible(!isConfirmDeleteVisible)
  }

  const handleSave = async (configuration: ConfigurationFormValues): Promise<void> => {
    const { conversation: conversationId } = configuration
    if (configurationDetail?.id) {
      setConversationIdRequestedToSave(conversationId)

      try {
        await saveConfig({
          id: configurationDetail?.id,
          conversationId,
        }).unwrap()
        toastCompleted('The conversation configuration has been successfully updated.')
      } catch (error) {
        toastError('Something went wrong. Please try again.')
        resetSave()
        setConversationIdRequestedToSave(undefined)
      }
    }
  }

  const handleDelete = async (): Promise<void> => {
    try {
      await deleteConfiguration(configId).unwrap()

      toastCompleted('The conversation configuration has been successfully deleted.')
      push('/admin/adjudication-management/conversation-configuration')
    } catch (error) {
      handleToggleDeleteModal()
      toastError('Something went wrong. Please try again.')
    }
  }

  if (deleteError) {
    resetDelete()
  }

  return (
    <>
      <ConfirmationModal
        isVisible={isConfirmDeleteVisible}
        onCancel={handleToggleDeleteModal}
        onConfirm={handleDelete}
        confirmButtonColor="red"
        confirmButtonText="Delete"
        heading="Delete configuration?"
        description="This can not be undone. Are you sure you want to delete the conversation configuration?"
        isProcessing={isDeleteProcessing}
      />
      {isLoadingConfigurationDetail && (
        <LoadingContainer>
          <Spinner
            color={COLOR.BLUE[800]}
            data-cy="loading-conversation-spinner"
            size={IconSize.large}
          />
        </LoadingContainer>
      )}
      {!isLoadingConfigurationDetail && configurationDetail && (
        <ConversationConfigurationWrapper>
          <Breadcrumbs
            crumbs={[
              {
                text: 'Adjudication Management',
                to: '/admin/adjudication-management',
              },
              {
                text: `Conversation Configurations`,
                to: `/admin/adjudication-management/conversation-configuration`,
              },
              {
                text: 'Configuration',
              },
            ]}
            data-cy="user-details-breadcrumbs"
          />
          <PresentationContainer>
            <Title>Configuration</Title>
            <Box padding={0}>
              <ConversationConfigurationForm
                configurationDetail={configurationDetail}
                isSaveProcessing={isConfigSaveProcessing}
                handleSave={handleSave}
                onDelete={handleToggleDeleteModal}
              />
            </Box>
          </PresentationContainer>
        </ConversationConfigurationWrapper>
      )}
    </>
  )
}

const LoadingContainer = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '20px 0 60px 0',
})

const PresentationContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '50%',
})

const Title = styled.h2({
  color: COLOR.BLUE[1000],
  margin: '16px 0 24px 0',
})

const ConversationConfigurationWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export { ConversationConfigurationDetails }
