import type { VFC } from 'react'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { useDataTableContext } from '../data-table-context'
import { COLOR } from '../../../tokens/colors'
import { AnimatePresence } from '../../../transitions'
import { Loader } from './loader'

interface SkeletonProps {
  delayMs?: number
  message?: string
}

export const Skeleton: VFC<SkeletonProps> = ({ delayMs, message }) => {
  const { 'data-cy': dataCy, table } = useDataTableContext()
  const rows = table.getState().pagination.pageSize

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    })
    return () => clearTimeout(timer)
  })

  return (
    <>
      <Table isVisible={isVisible} data-cy={dataCy && `${dataCy}:skeleton-table`}>
        <AnimatePresence isPresent={isVisible} transitionDurationMs={200}>
          <Loader isOnlySecondary message={message} delayMs={delayMs} />
        </AnimatePresence>
        <Header>
          <SkeletonAnimation />
          <SkeletonAnimation />
          <SkeletonAnimation />
        </Header>
        <div>
          {[...Array(rows)].map((_row, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Row key={i}>
              <SkeletonAnimation />
              <SkeletonAnimation />
              <SkeletonAnimation />
            </Row>
          ))}
        </div>
      </Table>
      <Footer isVisible={isVisible} data-cy={dataCy && `${dataCy}:skeleton-footer`} />
    </>
  )
}

const Table = styled.div<{
  isVisible?: boolean
}>(({ isVisible }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  transition: 'opacity',
  transitionDuration: '0ms',
  transitionDelay: '200ms',
  opacity: isVisible ? 1 : 0,
}))

const Header = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
  gap: 20,
  padding: '0 20px',
  height: 53,
  backgroundColor: COLOR.NEUTRAL[100],
  border: `solid ${COLOR.NEUTRAL[300]}`,
  borderWidth: '0 0 1px',
})

const Row = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
  padding: '0 20px',
  height: 52,
  border: `solid ${COLOR.NEUTRAL[300]}`,
  borderWidth: '0 0 1px',
  '&:first-of-type': {
    height: 53,
  },
  '&:last-of-type': {
    borderBottom: 'none',
  },
})

const animateBar = keyframes({
  '0%': {
    left: '-75%',
  },
  '100%': {
    left: '100%',
  },
})

const SkeletonAnimation = styled.div({
  position: 'relative',
  overflow: 'hidden',
  flex: 1,
  height: 20,
  background: COLOR.NEUTRAL[200],
  borderRadius: 4,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '75%',
    background: `linear-gradient(90deg, transparent 0%, ${COLOR.NEUTRAL[300]} 50%, transparent 100%)`,
    animation: `${animateBar} 1.75s infinite`,
    opacity: 0.5,
  },
})

const Footer = styled.div<{
  isVisible?: boolean
}>(({ isVisible }) => ({
  height: 52,
  backgroundColor: COLOR.NEUTRAL[100],
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderTop: 'none',
  transition: 'opacity',
  transitionDuration: '0ms',
  transitionDelay: '200ms',
  opacity: isVisible ? 1 : 0,
}))
