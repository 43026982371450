import type { Claim } from '@helloextend/extend-api-client'
import type { Action } from '../actions'

export type ByContractReducerState = Record<string, Claim[]>

export const initialState: ByContractReducerState = {}

export default function byContract(state = initialState, action: Action): ByContractReducerState {
  switch (action.type) {
    case 'CLAIMS_FETCH_BY_CONTRACT_SUCCESS':
      return addItems(state, action.payload.contractId, action.payload.claims)
    default:
      return state
  }
}

function addItems(
  state: ByContractReducerState,
  contractId: string,
  items: Claim[],
): ByContractReducerState {
  if (items.length) {
    return { ...state, [contractId]: items }
  }

  return { ...state, [contractId]: [] }
}
