import React from 'react'
import ReactDOM from 'react-dom'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import { configureDataDogRum } from '@helloextend/client-rum'
import { LD_CLIENT_ID_PORTAL } from '@helloextend/client-constants'
import { ErrorBoundary, SystemError } from '@helloextend/component-commons'
import { merchants, Toaster, ToasterPosition, Tooltip } from '@helloextend/zen'
import { defaultLDFlagValues } from './constants/ld-flags'
import { Router } from './pages/router'
import configureStore from './store'
import { GlobalStyle } from './components/global-style'

const store = configureStore()

;(async () => {
  configureDataDogRum(15, 100)
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LD_CLIENT_ID_PORTAL,
    flags: defaultLDFlagValues,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  })
  ReactDOM.render(
    <LDProvider>
      <Provider store={store}>
        <ThemeProvider theme={merchants}>
          <Toaster toasterPosition={ToasterPosition.left}>
            <BrowserRouter>
              <ErrorBoundary page={SystemError}>
                <GlobalStyle />
                <Router />
                <Tooltip />
              </ErrorBoundary>
            </BrowserRouter>
          </Toaster>
        </ThemeProvider>
      </Provider>
    </LDProvider>,
    document.querySelector('#root'),
  )
})()
