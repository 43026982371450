export enum ModalPosition {
  center = 'center',
  bottomRight = 'bottomRight',
}

export enum ModalBackground {
  boxShadow = 'boxShadow',
  default = 'default',
}

export const ModalPositioning: Record<ModalPosition, { [key: string]: unknown }> = {
  [ModalPosition.center]: {
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  [ModalPosition.bottomRight]: {
    position: 'fixed',
    right: 40,
    bottom: 40,
    border: '1px solid #C9D1DC',
    boxShadow: '0px 4px 40px 0px #0000001F',
  },
}
