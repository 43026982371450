import type { FC } from 'react'
import React, { createContext, useState, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import type { ContractFormValuesUpdate } from '@helloextend/extend-api-client'
import { ClaimSelectStatus, ContractType } from '@helloextend/extend-api-client'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { PageError, Toast } from '@helloextend/merchants-ui'
import { usePrevious } from '@helloextend/client-hooks'
import { Breadcrumbs, Button, Stack } from '@helloextend/zen'
import { StackJustify } from '@helloextend/zen/src/components/stack'
import {
  useGetContract20220201Query,
  useGetTermsVersionLanguageUrlQuery,
  useSearchClaimsQuery,
  useListInsuranceClaimsQuery,
} from '@helloextend/extend-api-rtk-query'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { RefundModal } from '../../../../../components/refund-modal/refund-modal'
import * as contractsActions from '../../../../../actions/contracts'
import { TabBar } from '../../../../../components/tab-bar'
import { LeavePageGuard } from '../../../../../components/leave-page-guard'
import { SaveContractModal } from '../../../../../components/save-contract-modal'
import { SuccessHeaderPopup } from '../../../../../components/success-popup'
import * as selectors from '../../../../../reducers/selectors'
import type { RootState } from '../../../../../reducers'
import { DashboardSpinner } from '../../../../../components/dashboard-spinner'
import { ResendDetailsModal } from '../resend-details-modal'
import { CellStatusBadge } from '../../../../../components/cell-status-badge'
import { getContractStatusCopy } from '../../../../../lib/contract-status'
import { HeaderDetails } from '../header-details'
import { LDFlag } from '../../../../../constants/ld-flags'

interface ContractContextValue {
  contract: ReturnType<typeof useGetContract20220201Query>['data']
  saveUpdates?: (formValues: ContractFormValuesUpdate) => void
  toggleNavBlocked?: (isNavBlocked: boolean) => void
  isClaimsSearchLoading?: boolean
  claimData?: ReturnType<typeof useSearchClaimsQuery>['data']
  planTermsUrl?: string
}

const ContractContext = createContext<ContractContextValue | null>(null)

const ContractContainer: FC = ({ children }) => {
  const { contractId } = useParams<{ contractId: string }>()
  const dispatch = useDispatch()
  const history = useHistory()
  const { [LDFlag.UseClaimsManagement]: FF_USE_CLAIMS_MANAGEMENT } = useFlags()
  const isContractUpdateSuccessful = useSelector((state: RootState) =>
    selectors.getIsContractUpdateSuccess(state),
  )
  const {
    data: contract,
    isLoading: isContractLoading,
    isError: isContractError,
  } = useGetContract20220201Query({
    contractId,
  })

  const { data: planTermsUrl } = useGetTermsVersionLanguageUrlQuery(
    {
      termsId: contract?.plan.termsId || '',
      version: contract?.plan.termsVersion || '',
    },
    { skip: !contract },
  )
  const contractError = useSelector((state: RootState) => selectors.getContractsError(state))
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false)
  const [isRefundModalVisible, setIsRefundModalVisible] = useState(false)
  const [isNavBlocked, setIsNavBlocked] = useState(false)
  const [updateValues, setUpdateValues] = useState<ContractFormValuesUpdate>()

  const isContractUpdating = useSelector((state: RootState) =>
    selectors.getIsContractUpdating(state),
  )
  const prevUpdating = usePrevious(isContractUpdating)
  const [hasUpdateError, setHasUpdateError] = useState<boolean>(false)
  const [isResendDetailsModalVisible, setIsResendDetailsModalVisible] = useState<boolean>(false)

  const toggleNavBlocked = (blocked: boolean): void => {
    setIsNavBlocked(blocked)
  }

  const handleLeavePage = (path: string): void => {
    history.push(path)
  }

  const toggleSaveModalVisibility = (): void => {
    setIsSaveModalVisible(!isSaveModalVisible)
  }

  const toggleRefundModalVisibility = (): void => {
    setIsRefundModalVisible(!isRefundModalVisible)
  }

  const toggleResendModalVisibility = (): void => {
    setIsResendDetailsModalVisible(!isResendDetailsModalVisible)
  }

  const saveUpdates = (values: ContractFormValuesUpdate): void => {
    if (values.productUpdate) {
      setUpdateValues(values)
    } else {
      setUpdateValues({ contractUpdate: values.contractUpdate })
    }
    setIsSaveModalVisible(true)
  }

  const onAnimationEnd = (): void => {
    setTimeout(() => {
      dispatch(contractsActions.updateSuccessReset())
    }, 1500)
  }

  const onToastAnimationEnd = (): void => {
    setTimeout(() => {
      dispatch(contractsActions.errorReset())
      setHasUpdateError(false)
    }, 1500)
  }

  const handleBackClick = (): void => {
    history.push('/admin/contracts')
  }

  useEffect(() => {
    if (prevUpdating && contractError) {
      setHasUpdateError(true)
    }
  }, [prevUpdating, contractError])

  const queryParams = useMemo(() => {
    const matchesClaimStatus = [
      ClaimSelectStatus.review,
      ClaimSelectStatus.approved,
      ClaimSelectStatus.denied,
      ClaimSelectStatus.pending,
      ClaimSelectStatus.fulfilled,
      ClaimSelectStatus.closed,
    ]

    return {
      sellerId: contract?.sellerId,
      searchVersion: '2',
      containsContractId: contractId,
      matchesClaimStatus,
    }
  }, [contract?.sellerId, contractId])

  const { data: contractsClaims, isLoading: isContractClaimsSearchLoading } = useSearchClaimsQuery(
    queryParams,
    {
      refetchOnMountOrArgChange: true,
      skip: !contract || FF_USE_CLAIMS_MANAGEMENT,
    },
  )

  const { data: insuranceClaims, isLoading: isInsuranceClaimSearchLoading } =
    useListInsuranceClaimsQuery(queryParams, {
      refetchOnMountOrArgChange: true,
      skip: !contract || !FF_USE_CLAIMS_MANAGEMENT,
    })

  const claimsData = contractsClaims || insuranceClaims
  const isClaimsSearchLoading = isContractClaimsSearchLoading || isInsuranceClaimSearchLoading

  const filteredClaimsByStatus = useMemo(() => {
    const validClaimStatuses = new Set([ClaimSelectStatus.review, ClaimSelectStatus.approved])
    return claimsData?.items?.filter((claim) =>
      validClaimStatuses.has(claim.status as ClaimSelectStatus),
    )
  }, [claimsData?.items])

  const hasExistingClaims = Boolean(filteredClaimsByStatus?.length)
  const isRefundButtonDisabled =
    hasExistingClaims || isClaimsSearchLoading || contract?.status !== 'live' || isContractLoading

  if (isContractError && !hasUpdateError && !prevUpdating) {
    return (
      <ErrorWrapper>
        <PageError data-cy="error-message" handleBackClick={handleBackClick} />
      </ErrorWrapper>
    )
  }

  if (!contract || isContractLoading) return <DashboardSpinner />

  const refundAmount = contract?.purchasePrice
  const items = claimsData?.items
  const claims = Array.isArray(items) && items.length > 0 ? claimsData : []
  const contextValue = {
    contract,
    claimData: claims,
    saveUpdates,
    toggleNavBlocked,
    isClaimsSearchLoading,
    planTermsUrl,
  }

  return (
    <>
      <ContractContext.Provider value={contextValue}>
        <LeavePageGuard isNavBlocked={isNavBlocked} handleLeavePage={handleLeavePage} />
        {isContractUpdateSuccessful && (
          <SuccessHeaderPopup message="Contract changes saved!" onAnimationEnd={onAnimationEnd} />
        )}
        {hasUpdateError && (
          <Toast
            message="Changes couldn’t be saved due to a system error. Please try again later."
            onAnimationEnd={onToastAnimationEnd}
            isSuccessToaster={false}
          />
        )}
        <SaveContractModal
          isVisible={isSaveModalVisible}
          toggle={toggleSaveModalVisibility}
          contractId={contractId}
          updateValues={updateValues}
        />
        <ResendDetailsModal
          isVisible={isResendDetailsModalVisible}
          contract={contract}
          toggle={toggleResendModalVisibility}
          data-cy="resend-modal"
        />
        <RefundModal
          visible={isRefundModalVisible}
          contractId={contractId}
          amount={refundAmount}
          closeModal={toggleRefundModalVisibility}
          data-cy="refund-modal"
        />
        <HeaderContainer>
          <Stack justify={StackJustify.spaceBetween} doesWrap>
            <Header>
              <Breadcrumbs
                crumbs={[
                  {
                    text: 'Contracts',
                    to: '/admin/contracts',
                  },
                  {
                    text: `Contract ID: ${contractId}`,
                  },
                ]}
                data-cy="contract-details-breadcrumbs"
                max-width="50ch"
              />
              <TitleContainer>
                <Title data-cy="contract-id-header">{contract.customer.name}&apos;s Contract</Title>
                <CellStatusBadge badgeData={getContractStatusCopy(contract.status)} />
              </TitleContainer>
              <HeaderDetails contract={contract} />
            </Header>
            <ButtonContainer>
              <Button
                emphasis="medium"
                text="Refund"
                data-cy="refund-button"
                onClick={toggleRefundModalVisibility}
                isDisabled={isRefundButtonDisabled}
              />
              <Button
                emphasis="medium"
                text="File a Claim"
                data-cy="file-claim-button"
                onClick={() => handleLeavePage(`/admin/contracts/${contractId}/file-a-claim`)}
                isDisabled={
                  contract?.status === 'fulfilled' ||
                  contract?.type === ContractType.SHIPPING_PROTECTION
                }
              />
              <Button
                emphasis="medium"
                text="Resend Email"
                data-cy="resend-button"
                onClick={() => toggleResendModalVisibility()}
                isDisabled={contract?.status !== 'live'}
              />
            </ButtonContainer>
          </Stack>
        </HeaderContainer>
        <TabBar
          tabBarLinks={[
            { to: `/admin/contracts/${contractId}`, text: 'Contract Details' },
            { to: `/admin/contracts/${contractId}/claims`, text: 'Claims' },
            { to: `/admin/contracts/${contractId}/auditlog`, text: 'Audit Log' },
          ]}
        />
        {children}
      </ContractContext.Provider>
    </>
  )
}

const ButtonContainer = styled.div({
  display: 'flex',
  gap: 8,
})

const HeaderContainer = styled.div({
  marginBottom: 32,
})

const Header = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const TitleContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

const Title = styled.h1({
  color: COLOR.EXTEND_OXFORD,
  fontSize: 32,
  lineHeight: '44px',
  marginTop: 0,
  marginBottom: 4,
  marginRight: 16,
})

const ErrorWrapper = styled.div({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 535,
  margin: '0 auto',
})

export { ContractContainer, ContractContext, ContractContextValue }
