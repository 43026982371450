import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { COLOR } from '@helloextend/client-branding'

type SpinnerCheckProps = {
  size: number
  thickness: number
  color: string
  loading: boolean
  hideMargin?: boolean
}

const AnimatedSpinnerCheck: FC<SpinnerCheckProps> = ({
  loading,
  thickness = 1,
  size = 50,
  color = COLOR.STRONG_BLUE,
  hideMargin,
}) => {
  const checkMarkHeight = size / 2
  const checkMarkWidth = checkMarkHeight / 2
  const checkLeftPosition = size / 6 + size / 12

  return (
    <Spinner
      thickness={thickness}
      isLoading={loading}
      color={color}
      size={size}
      hideMargin={Boolean(hideMargin)}
      data-cy="spinner"
    >
      <Checkmark
        isLoading={loading}
        width={checkMarkWidth}
        height={checkMarkHeight}
        left={checkLeftPosition}
        thickness={thickness}
        color={color}
      />
    </Spinner>
  )
}

const loaderSpinAnimation = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
})

const checkmarkAnimation = (height: number, width: number): ReturnType<typeof keyframes> =>
  keyframes({
    '0%': { height: 0, width: 0 },
    '20%': { height: 0, width },
    '40%': { height, width },
    '100%': { height, width },
  })

const Spinner = styled.div<{
  isLoading: boolean
  color: string
  size: number
  thickness: number
  hideMargin: boolean
}>(({ isLoading, color, size, thickness, hideMargin }) => ({
  animation: isLoading ? `${loaderSpinAnimation} 1s infinite linear` : 'none',
  borderColor: isLoading ? 'transparent' : color,
  borderLeftColor: color,
  borderRadius: '50%',
  borderStyle: 'solid',
  borderWidth: thickness,
  display: 'inline-block',
  height: size,
  marginBottom: !hideMargin ? size / 2 : 0,
  transition: 'border 500ms ease-out',
  position: 'relative',
  width: size,
  verticalAlign: 'top',
}))

const Checkmark = styled.div<{
  isLoading: boolean
  color: string
  thickness: number
  left: number
  height: number
  width: number
}>(({ isLoading, width, height, left, thickness, color }) => ({
  display: isLoading ? 'none' : 'block',
  ':after': {
    animationDuration: '1500ms',
    animationName: `${checkmarkAnimation(height, width)}`,
    animationTimingFunction: 'ease',
    borderRight: `${thickness}px solid ${color}`,
    borderTop: `${thickness}px solid ${color}`,
    content: '""',
    height,
    left,
    opacity: 1,
    position: 'absolute',
    top: height,
    transform: 'scaleX(-1) rotate(135deg)',
    transformOrigin: 'left top',
    width,
  },
}))

export { AnimatedSpinnerCheck }
