import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { ExpandLess, ExpandMore } from '../../tokens/icons'
import { AnimatePresence, TransitionContext } from '../../transitions'
import { getMeasurements } from './accordion-section-utils'
import type { AccordionSectionProps, MeasurementArgs } from './accordion-section-types'
import { Accordion } from '../accordion'
import { Badge } from '../badge'
import { HeadingLarge, HeadingSmall, Subheading } from '../../tokens/typography'
import { Button } from '../button'

const TRANSITION_DURATION_MS = 200

export const AccordionSection: FC<AccordionSectionProps> = ({
  badgeProps,
  children,
  heading,
  headingSize = 'small',
  hideToolbarWhenCollapsed = false,
  isToggleDisabled = false,
  isExpanded = false,
  toolbar,
  onToggleRequest,
  'data-cy': dataCy,
}) => {
  const measurements = getMeasurements(headingSize)

  const [isExpandedInternal, setIsExpandedInternal] = useState(isExpanded)

  const handleToggle = useCallback(() => {
    if (onToggleRequest) {
      onToggleRequest()
    } else {
      setIsExpandedInternal(!isExpandedInternal)
    }
  }, [onToggleRequest, isExpandedInternal])

  const isAccordionExpanded = onToggleRequest ? isExpanded : isExpandedInternal

  return (
    <StyledAccordion data-cy={dataCy}>
      <Header>
        <HeadingContainer
          onClick={isToggleDisabled ? () => {} : handleToggle}
          hasClickEvent={!isToggleDisabled}
          headingPadding={measurements.headingPadding}
          data-cy={dataCy && `${dataCy}:heading-container`}
          {...(isToggleDisabled && { tabIndex: -1 })}
        >
          <Heading data-cy={dataCy && `${dataCy}:heading`}>
            {headingSize === 'large' && <HeadingLarge>{heading}</HeadingLarge>}
            {headingSize === 'small' && <HeadingSmall>{heading}</HeadingSmall>}
            {headingSize === 'sub' && <Subheading>{heading}</Subheading>}
            {badgeProps && (
              <BadgeContainer
                data-cy={dataCy && `${dataCy}:badge-container`}
                measurements={measurements}
              >
                <Badge
                  data-cy={dataCy && `${dataCy}:badge`}
                  {...badgeProps}
                  size={measurements.badgeSize}
                />
              </BadgeContainer>
            )}
          </Heading>
        </HeadingContainer>
        {(toolbar || !isToggleDisabled) && (
          <Actions>
            {toolbar && (
              <AnimatePresence
                isPresent={hideToolbarWhenCollapsed ? isAccordionExpanded : true}
                transitionDurationMs={TRANSITION_DURATION_MS}
              >
                <TransitionContext.Consumer>
                  {({ isVisible }) => <Toolbar isPresent={isVisible}>{toolbar}</Toolbar>}
                </TransitionContext.Consumer>
              </AnimatePresence>
            )}
            {!isToggleDisabled && (
              <Button
                size="small"
                emphasis="low"
                color="neutral"
                icon={isAccordionExpanded ? ExpandLess : ExpandMore}
                iconTransitionDurationMs={TRANSITION_DURATION_MS}
                data-cy={dataCy && `${dataCy}:chevron`}
                onClick={isToggleDisabled ? () => {} : handleToggle}
              />
            )}
          </Actions>
        )}
      </Header>
      <Accordion isExpanded={isAccordionExpanded} transitionDurationMs={TRANSITION_DURATION_MS}>
        <Content paddingTop={measurements.bodyPaddingTop}>{children}</Content>
      </Accordion>
    </StyledAccordion>
  )
}

const StyledAccordion = styled.div({})

const Header = styled.div({
  display: 'flex',
  gap: 8,
  alignItems: 'start',
})

const HeadingContainer = styled.button<{
  hasClickEvent: boolean
  headingPadding?: string
}>(({ hasClickEvent, headingPadding }) => ({
  appearance: 'none',
  border: 'none',
  background: 'none',
  display: 'flex',
  flex: 1,
  gap: 6,
  padding: headingPadding || 0,
  ...(hasClickEvent && {
    cursor: 'pointer',
    userSelect: 'none',
  }),
}))

const Heading = styled.div({
  display: 'flex',
  gap: 8,
  alignItems: 'start',
})

const BadgeContainer = styled.div<{
  measurements: MeasurementArgs
}>(({ measurements: { badgePaddingY, badgeMarginY } }) => ({
  display: 'flex',
  flexShrink: 0,
  paddingTop: badgePaddingY,
  paddingBottom: badgePaddingY,
  marginTop: badgeMarginY,
  marginBottom: badgeMarginY,
}))

const Actions = styled.div({
  display: 'flex',
  gap: 8,
})

const Toolbar = styled.div<{
  isPresent: boolean
}>(({ isPresent }) => ({
  display: 'flex',
  gap: 8,
  opacity: isPresent ? 1 : 0,
  transition: `${TRANSITION_DURATION_MS}ms`,
}))

const Content = styled.div<{
  paddingTop: number
}>(({ paddingTop }) => ({
  paddingTop,
}))
