import type { ErrorObject } from '@helloextend/core-api-redux/src/types/error'
import { createErrorReducer } from '../../factories'
import type { Action } from '../actions'

export type UsersErrorReducerState = ErrorObject | null

export const initialState: UsersErrorReducerState = null

const error = createErrorReducer<Action>(
  ['USER_CREATE_FAILURE', 'USER_FORM_ERROR', 'USER_UPDATE_FAILURE', 'USER_DELETE_FAILURE'],
  [
    'USER_UPDATE',
    'USER_CREATE',
    'USER_CREATE_REQUEST',
    'USER_CREATE_SUCCESS',
    'USER_UPDATE_REQUEST',
    'USER_UPDATE_SUCCESS',
    'USER_ERROR_RESET',
  ],
)

export default error
