import type { FC } from 'react'
import React, { useState, useEffect } from 'react'
import type { Location } from 'history'
import { Prompt } from 'react-router-dom'
import { LeavePageModal } from './leave-page-modal'

interface LeavePageGuardProps {
  isNavBlocked: boolean
  handleLeavePage: (path: string) => void
  mainText?: string
  detail?: string
  confirmation?: string
  submitButtonText?: string
  overflow?: string
}

const LeavePageGuard: FC<LeavePageGuardProps> = ({
  isNavBlocked,
  handleLeavePage,
  mainText,
  detail,
  confirmation,
  submitButtonText,
  overflow = 'scroll',
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const handleCloseModal = (): void => {
    setIsVisible(false)
  }

  const handleAllowNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation) {
      setIsVisible(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = (): void => {
    setIsVisible(false)
    setConfirmedNavigation(true)
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      handleLeavePage(lastLocation.pathname)
    }
  }, [confirmedNavigation, lastLocation, handleLeavePage])

  return (
    <>
      <Prompt when={isNavBlocked} message={handleAllowNavigation} />
      <LeavePageModal
        isVisible={isVisible}
        handleCloseModal={handleCloseModal}
        handleLeavePage={handleConfirmNavigationClick}
        mainText={mainText}
        detail={detail}
        confirmation={confirmation}
        submitButtonText={submitButtonText}
        overflow={overflow}
      />
    </>
  )
}

export { LeavePageGuard, LeavePageGuardProps }
