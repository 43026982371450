import * as Yup from 'yup'

const DESCRIPTION_MAX = 1000
const answerTextAreaSchema = Yup.object()
  .shape({
    description: Yup.string()
      .label('Description')
      .required('Description is required')
      .max(DESCRIPTION_MAX),
  })
  .defined()

export { answerTextAreaSchema, DESCRIPTION_MAX }
