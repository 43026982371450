import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import type { AdvancedSelectChangeEvent, AdvancedSelectOption } from '@helloextend/zen'
import { Paragraph } from '@helloextend/zen/src/tokens/typography'
import {
  Modal,
  ModalController,
  Stack,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@helloextend/zen'
import { StackAlign, StackDirection } from '@helloextend/zen/src/components/stack'
import {
  useCreatePlanSetMappingsMutation,
  useListPlanSetsQuery,
} from '@helloextend/extend-api-rtk-query'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import styled from '@emotion/styled'
import { CategorySelector } from '../category-selector'
import { PlanSetSelector } from '../plan-set-selector'

export interface AddPlanSetModalProps {
  isModalOpen: boolean
  categories: AdvancedSelectOption[]
  storeId: string
  closeModal: () => void
}

const AddPlanSetModal: FC<AddPlanSetModalProps> = ({
  isModalOpen,
  categories,
  storeId,
  closeModal,
}) => {
  const [isCreateProcessing, setIsCreateProcessing] = useState<boolean>(false)
  const [IsPlanSetExistErrorInCreate, setIsPlanSetExistErrorInCreate] = useState<boolean>(false)
  const [category, setCategory] = useState<string>('')
  const [categoryId, setCategoryId] = useState<string>('')
  const [planSetId, setPlanSetId] = useState<string>('')
  const [planSetName, setPlanSetName] = useState<string>('')
  const [create] = useCreatePlanSetMappingsMutation()
  const { data: planSets = [] } = useListPlanSetsQuery()
  const { toast } = useToaster()

  const handleCloseModal = (): void => {
    closeModal()
  }

  const handleSubmit = async (): Promise<void> => {
    setIsCreateProcessing(true)
    const response = await create({
      planCategoryId: categoryId,
      planSetId,
      storeId,
    })

    if ('error' in response) {
      if ((response as { error: FetchBaseQueryError }).error.status === 409) {
        setIsPlanSetExistErrorInCreate(true)
        setIsCreateProcessing(false)
      } else {
        setIsCreateProcessing(false)
        toast({
          message: 'Something went wrong. Please try again.',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.long,
        })
        handleCloseModal()
      }
      return
    }

    setIsCreateProcessing(false)
    handleCloseModal()
    toast({
      message: `${planSetName} has been successfully added.`,
      toastColor: ToastColor.blue,
      toastDuration: ToastDuration.short,
    })
  }

  const categoryIdHelper = (
    categoryList: AdvancedSelectOption[],
    selectedCategory: string,
  ): string => {
    const selectedOption = categoryList.find((cat) => cat.display === selectedCategory)

    return selectedOption?.id ? selectedOption.id : ''
  }

  const handleCategoryChange = useCallback(
    (e: AdvancedSelectChangeEvent<string>) => {
      // ensure we have a value for getting the categoryId
      if (!e.target.value) return
      setCategory(e.target.value)
      setPlanSetId('')

      setCategoryId(categoryIdHelper(categories, e.target.value))
    },
    [categories],
  )

  const getPlanSetName = useCallback(
    (selectedPlanSetId: string): string => {
      planSets.filter((planSet) => selectedPlanSetId === planSet.id)
      setPlanSetName(planSets[0].name)
      return planSets[0].name
    },
    [planSets],
  )

  const handlePlanSetChange = useCallback(
    (e: AdvancedSelectChangeEvent<string>) => {
      const newPlanSetId = e.target.value
      setPlanSetId(newPlanSetId)
      getPlanSetName(newPlanSetId)
    },
    [getPlanSetName],
  )

  const isSaveDisabled = (): boolean => isCreateProcessing || !category || !planSetId
  const errorFeedbackMsg = 'The store already has a designated plan set for the category.  '

  return (
    <ModalController isOpen={isModalOpen}>
      <Modal
        heading="Add Plan Set"
        onDismissRequest={handleCloseModal}
        data-cy="add-plan-set-modal"
        primaryButtonProps={{
          'data-cy': 'modal-submit',
          onClick: handleSubmit,
          text: 'Save',
          isDisabled: isSaveDisabled(),
          isProcessing: isCreateProcessing,
        }}
        secondaryButtonProps={{
          'data-cy': 'modal-cancel',
          onClick: handleCloseModal,
          text: 'Cancel',
        }}
      >
        <ParagraphWrapper data-cy="add-plan-set-model-body-text">
          Only the added plan sets and plans can be mapped to the products in this store.
        </ParagraphWrapper>
        <Stack direction={StackDirection.column} align={StackAlign.stretch} spacing={2}>
          <CategorySelector
            onChange={handleCategoryChange}
            value={category}
            categories={categories}
            isDisabled={isCreateProcessing}
            showSelector
            isError={IsPlanSetExistErrorInCreate}
            errorFeedback={errorFeedbackMsg}
          />
          <PlanSetSelector
            onChange={handlePlanSetChange}
            planSetId={planSetId}
            category={category}
            isDisabled={!category || isCreateProcessing}
            showSelector
          />
        </Stack>
      </Modal>
    </ModalController>
  )
}

const ParagraphWrapper = styled(Paragraph)({
  marginBottom: 16,
})

export { AddPlanSetModal }
