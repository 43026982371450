import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { COLOR } from '../../tokens/colors'

interface BoxProps {
  children: React.ReactNode
  /**
   * Base unit multiplier
   */
  padding?: number
  'data-cy'?: string
}

const Box: FC<BoxProps> = ({ children, padding = 3, 'data-cy': dataCy }) => {
  const { unit } = useTheme()
  return (
    <StyledBox data-cy={dataCy} padding={padding} unit={unit}>
      {children}
    </StyledBox>
  )
}

const StyledBox = styled.div<{
  padding: number
  unit: number
}>(({ padding, unit }) => ({
  padding: padding * unit,
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
}))

export { Box, BoxProps }
