import type {
  ServiceOrder,
  ServiceOrderShipment,
  ShipmentStatus,
} from '@helloextend/extend-api-client'
import { useListServiceOrderShipmentsQuery } from '@helloextend/extend-api-rtk-query'
import { isEmpty } from 'lodash/fp'
import { AccordionSection, Stack, DataProperty, Button } from '@helloextend/zen'
import { StackJustify } from '@helloextend/zen/src/components/stack'
import type { FC } from 'react'
import React, { useState, useMemo } from 'react'
import { ShippingLabelForm } from '..'
import { formatDate, formatStartCase } from '../../../../../../../util'

const SHIP_ENGINE_DOWNLOAD_BASE_URL = 'https://api.shipengine.com/v1/downloads'
interface FulfilledProductShipmentSectionProps {
  serviceOrder: ServiceOrder
  isExpanded?: boolean
}

type MappedShipmentStatus = Extract<ShipmentStatus, 'received_by_carrier'>
const shipmentStatusMap: Record<MappedShipmentStatus, string> = {
  received_by_carrier: 'In Transit',
}

const FulfilledProductShipmentSection: FC<FulfilledProductShipmentSectionProps> = ({
  serviceOrder,
  isExpanded,
}) => {
  const [isExpandedInternal, setIsExpanded] = useState(isExpanded)

  const handleToggle = (): void => {
    setIsExpanded(!isExpandedInternal)
  }

  const { data: shipments } = useListServiceOrderShipmentsQuery(serviceOrder.id)

  const shipment = useMemo(() => {
    return (
      shipments?.find(({ destinationType }) => destinationType === 'customer') ||
      ({} as ServiceOrderShipment)
    )
  }, [shipments])

  function getShipmentDeliveredDate(failedShipment?: ServiceOrderShipment): string {
    return failedShipment?.status === 'delivered'
      ? parseDate(failedShipment?.deliveredAt)
      : parseDate(failedShipment?.estimatedDeliveryAt)
  }

  function parseDate(date?: string): string {
    if (!date) return ''
    return formatDate(parseInt(date, 10))
  }

  const downloadLabel = serviceOrder?.shippingLabels?.find((l) => l.destinationType === 'customer')
  const downloadLabelUrl = downloadLabel?.pdfDownloadUrl || downloadLabel?.imageData

  const handleDownloadShippingLabel = (serviceOrderDownload: ServiceOrder | undefined): void => {
    const labelForDestinationType = serviceOrderDownload?.shippingLabels?.find(
      (label) => label.destinationType === 'customer',
    )

    // falls back to `imageData` if `pdfDownloadUrl` is not present, for backwards compatibility
    const downloadUrl =
      labelForDestinationType?.pdfDownloadUrl || labelForDestinationType?.imageData || ''
    if (!downloadUrl.startsWith(SHIP_ENGINE_DOWNLOAD_BASE_URL)) return

    window.open(downloadUrl, '_blank', 'noopener,noreferrer')
  }

  return (
    <AccordionSection
      heading="Fulfilled Product Shipment"
      headingSize="sub"
      onToggleRequest={handleToggle}
      isExpanded={isExpandedInternal}
      data-cy={`${serviceOrder.id}-fulfilled-product-shipment`}
    >
      {!isEmpty(shipment) ? (
        <>
          <Stack justify={StackJustify.spaceBetween} data-cy={`${serviceOrder.id}-props`}>
            <DataProperty
              data-cy={`${serviceOrder.id}-status`}
              label="Status"
              value={
                shipmentStatusMap[shipment.status as MappedShipmentStatus] ??
                formatStartCase(shipment.status)
              }
            />
            <DataProperty
              data-cy={`${serviceOrder.id}-return-tracking-number`}
              label="Return Tracking Number"
              value={shipment.trackingNumber}
            />
            <DataProperty
              data-cy={`${serviceOrder.id}-return-ship-date`}
              label="Return Shipment Date"
              value={formatDate(shipment.shippedAt)}
            />
            <DataProperty
              data-cy={`${serviceOrder.id}-delivered-date`}
              label={shipment.status === 'delivered' ? 'Delivered Date' : 'ETA Date'}
              value={getShipmentDeliveredDate(shipment)}
            />
          </Stack>
          {downloadLabelUrl && (
            <Stack>
              <Button
                data-cy={`${serviceOrder.id}-download-button`}
                text="Download Label"
                type="button"
                emphasis="medium"
                onClick={() => {
                  handleDownloadShippingLabel(serviceOrder)
                }}
              />
            </Stack>
          )}
        </>
      ) : (
        isEmpty(shipment) &&
        serviceOrder?.configurations?.hasCustomReturnLabel && (
          <ShippingLabelForm serviceOrderId={serviceOrder?.id} />
        )
      )}
    </AccordionSection>
  )
}

export { FulfilledProductShipmentSection }
