import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Badge, Button, COLOR, Icon } from '@helloextend/zen'
import { Check } from '@helloextend/zen/src/tokens/icons'
import type { ClaimNote } from '@helloextend/extend-api-client'
import type { FollowUpClaimNote } from '@helloextend/extend-api-client/src/models/claim-note'
import { getRelativeTimeSinceTimestamp } from '@helloextend/client-utils/date'
import { useUpdateClaimNoteMutation } from '@helloextend/extend-api-rtk-query'
import { FollowUpBadge } from './follow-up-badge'

interface ClaimNotesEntryProps {
  claimNote: ClaimNote
}

const ClaimNotesEntry: FC<ClaimNotesEntryProps> = ({ claimNote }) => {
  const [showMore, setShowMore] = useState(false)
  const [updateClaimNote, { isLoading }] = useUpdateClaimNoteMutation()

  const handleMarkComplete = (): void => {
    updateClaimNote({
      claimId: claimNote.claimId,
      noteId: claimNote.id,
      body: { ...claimNote, completed: true },
    })
  }

  return (
    <ContentWrapper data-cy="claim-note-entry">
      <NoteDate>{getRelativeTimeSinceTimestamp(claimNote.createdAt)} ago</NoteDate>
      <NoteAuthor>
        <Badge
          color="neutral"
          data-cy="notes-author-badge"
          emphasis="low"
          size="small"
          text={claimNote.createdBy}
        />
      </NoteAuthor>
      <NoteText data-cy="claim-note-entry-text">
        {showMore ? claimNote.text : `${claimNote.text.substring(0, 200)}`}
        {claimNote.text.length >= 200 && (
          <ShowMoreButton className="see-more-btn" onClick={() => setShowMore(!showMore)}>
            {showMore ? '...see less' : '...see more'}
          </ShowMoreButton>
        )}
      </NoteText>
      {claimNote.type === 'follow-up' && (
        <FollowUpRow>
          <FollowUpBadge
            timestamp={(claimNote as FollowUpClaimNote).followUpDate}
            isCompleted={(claimNote as FollowUpClaimNote).completed}
          />
          {(claimNote as FollowUpClaimNote).completed ? (
            <Icon data-cy="claim-note-complete-icon" icon={Check} />
          ) : (
            <Button
              text="Mark Completed"
              emphasis="low"
              color="neutral"
              onClick={handleMarkComplete}
              isProcessing={isLoading}
              data-cy="claim-note-mark-complete"
            />
          )}
        </FollowUpRow>
      )}
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '12px 0',
  gridAutoFlow: 'row',
  gridTemplateAreas: `
    'note-date note-author'
    'note-text note-text'`,
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  padding: 16,
  marginTop: 8,
})

const NoteDate = styled.div({
  gridArea: 'note-date',
  fontSize: 15,
})

const NoteAuthor = styled.div({
  gridArea: 'note-author',
  textAlign: 'right',
})

const NoteText = styled.div({
  gridArea: 'note-text',
  fontSize: 16,
})

const FollowUpRow = styled.div({
  gridArea: 'edit-row x',
  gridColumnEnd: 'span 2',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const ShowMoreButton = styled.a({ cursor: 'pointer' })

export { ClaimNotesEntry }
