import type { FC, ChangeEvent, FormEvent } from 'react'
import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import styled from '@emotion/styled'
import type { ValidImageExtension } from '@helloextend/zen'
import { Button, ImageInput, isFileValidImage, isFileValidSize } from '@helloextend/zen'
import type { DefaultMessage, DefaultReply } from '@helloextend/extend-api-rtk-query'
import { useUploadClaimPhotoMutation, MessageType } from '@helloextend/extend-api-rtk-query'
import type { Reply, Slot, ImageUploadPrompt } from '@helloextend/extend-api-client'
import { getBase64 } from '../../../adjudication-management/utils'

interface AnswerImageUploadProps {
  onSubmit: (slot: Slot, slotValue: string | number) => void
  isLoading: boolean
  reply: Reply
}

const MAX_FILE_SIZE_MB = 1
const SUPPORTED_FILE_EXTENSIONS: ValidImageExtension[] = ['jpeg', 'jpg', 'gif', 'png']

const AnswerImageUpload: FC<AnswerImageUploadProps> = ({ isLoading, onSubmit, reply }) => {
  const [imageData, setImageData] = useState<string | undefined>(undefined)
  const [imageFile, setImageFile] = useState<File | undefined>(undefined)
  const [uploadClaimPhoto] = useUploadClaimPhotoMutation()

  const handleChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const fileImage = event.target.files && event.target.files[0]

    if (!fileImage) {
      return
    }

    const isValidFile =
      isFileValidImage(fileImage, SUPPORTED_FILE_EXTENSIONS) &&
      isFileValidSize(fileImage, MAX_FILE_SIZE_MB)

    if (!isValidFile) {
      return
    }

    setImageFile(fileImage)
  }

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault()
    if (reply.prompt?.slot && imageFile !== undefined) {
      const prompt = reply?.prompt as ImageUploadPrompt
      const base64 = await getBase64(imageFile)

      if (base64 && prompt) {
        const { url, fields } = prompt.presignedPost
        const formData = new FormData()
        Object.entries(fields).forEach(([key, value]) => {
          formData.append(key, value)
        })
        formData.append('file', imageFile)

        uploadClaimPhoto({ url, body: formData })
        setImageData(base64)
      }

      onSubmit(reply.prompt.slot, imageFile.name)
    }
  }

  const defaultReply = reply as DefaultReply
  const content: string | undefined = (defaultReply.messages as DefaultMessage[])
    .reduce(
      (output: string, message: DefaultMessage) =>
        message.type === MessageType.text ? `${output}${message.content.trim()} ` : output,
      '',
    )
    .trim()

  return (
    <ContentWrapper>
      {content && (
        <h2 data-cy="message-content">
          <ReactMarkdown>{content}</ReactMarkdown>
        </h2>
      )}
      <ImageUploadForm data-cy="image-upload-wrapper" onSubmit={handleSubmit}>
        <ImageInput
          id="imageUploader"
          data-cy="image-uploader"
          onChange={handleChange}
          label="Customer Image"
          helperText="Photo provided by customer to upload for review."
          currentImage={imageData}
          imageExtensions={SUPPORTED_FILE_EXTENSIONS}
          maxSizeMb={MAX_FILE_SIZE_MB}
          height={132}
        />
        <ButtonWrapper>
          <Button
            data-cy="continue-button"
            text="Continue"
            isDisabled={imageFile === undefined}
            isProcessing={isLoading}
            type="submit"
          />
        </ButtonWrapper>
      </ImageUploadForm>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  width: '100%',
  maxWidth: 816,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
})

const ImageUploadForm = styled.form({
  '.imageupload-form-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 56,
  },
  'div[class*="ImageWrapper"]': {
    justifyContent: 'center',
  },
})

const ButtonWrapper = styled.div({
  marginTop: 32,
  display: 'flex',
  justifyContent: 'center',
})

export { AnswerImageUpload, AnswerImageUploadProps }
