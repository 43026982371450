import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import { useListPlanAttributesQuery } from '@helloextend/extend-api-rtk-query'
import { useHistory } from 'react-router-dom'
import { DataTable } from '../../../components/data-table'
import { planAttributesColumns } from './table-config'
import { toTablePlanAttributesItems } from './to-table-plan-attributes-items'
import type { TablePlanAttributesSearch } from '../../../types/plans'

const PlanAttributes: FC = () => {
  const { push } = useHistory()
  const { data: attributes, isFetching, isLoading } = useListPlanAttributesQuery()
  const planData = toTablePlanAttributesItems(attributes)

  const handleRowClick = (_e: SyntheticEvent, planAttribute: TablePlanAttributesSearch): void => {
    push(`/admin/plans/attributes/${planAttribute.id}`)
  }

  return (
    <>
      <Title data-cy="plan-attributes-header">Plan Attributes</Title>
      <DataTable
        data={planData}
        columns={planAttributesColumns}
        isLoading={isFetching || isLoading}
        rowClickEvent={handleRowClick}
        shouldAutoSubmitOnEmpty
      />
    </>
  )
}

const Title = styled.h1({
  fontWeight: 700,
  color: COLOR.VERY_DARK_BLUE_0,
  fontSize: 32,
  lineHeight: '44px',
})

export { PlanAttributes }
