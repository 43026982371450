import type { FC } from 'react'
import React from 'react'
import type { MenuProps } from '../../menu'
import type { FilterDef } from '../data-table-types'
import { NumberRangeFilter } from './number-range-filter'
import { DateRangeFilter } from './date-range-filter'
import { MultiSelectFilter } from './multi-select-filter'
import { SelectFilter } from './select-filter'
import { CurrencyRangeFilter } from './currency-range-filter'

export interface FilterProps extends MenuProps {
  filterDef: FilterDef
}

const Filter: FC<FilterProps> = ({ direction, filterDef, triggerRenderer }) => {
  switch (filterDef.type) {
    case 'select':
      return filterDef.isMultiSelect ? (
        <MultiSelectFilter
          direction={direction}
          filterDef={filterDef}
          triggerRenderer={triggerRenderer}
          hasSearch={filterDef.hasSearch}
        />
      ) : (
        <SelectFilter
          direction={direction}
          filterDef={filterDef}
          triggerRenderer={triggerRenderer}
          hasSearch={filterDef.hasSearch}
        />
      )
    case 'numberRange':
      return (
        <NumberRangeFilter
          direction={direction}
          filterDef={filterDef}
          triggerRenderer={triggerRenderer}
        />
      )
    case 'dateRange':
      return (
        <DateRangeFilter
          direction={direction}
          filterDef={filterDef}
          triggerRenderer={triggerRenderer}
        />
      )
    case 'currencyRange':
      return (
        <CurrencyRangeFilter
          direction={direction}
          filterDef={filterDef}
          triggerRenderer={triggerRenderer}
        />
      )
    default:
      return null
  }
}

export { Filter }
