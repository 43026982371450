import type { FC } from 'react'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useHistory } from 'react-router'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import { Toast } from '@helloextend/merchants-ui'
import { Breadcrumbs, Button } from '@helloextend/zen'
import { useFormik } from 'formik'
import { date } from '@helloextend/client-utils'
import { usePrevious } from '@helloextend/client-hooks'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  useCreatePlanMutation,
  useFetchPlanQuery,
  useFetchPlanVersionsListQuery,
} from '@helloextend/extend-api-rtk-query'
import type { Values } from './schema'
import { schema } from './schema'
import { PlanForm } from './plan-form'
import { SuccessHeaderPopup } from '../../../components/success-popup'
import { planPropertyMapper } from '../../../utils/plan-property-mapper'
import { mapSchemaToValues } from '../../../utils/plans-mapping'
import { LeavePageGuard } from '../../../components/leave-page-guard'
import { LDFlag } from '../../../constants/ld-flags'
import { TabMenu } from '../../../components/tab-menu/tab-menu'
import { PlanVersions } from '../plan-versions/plan-versions'
import { DashboardSpinner } from '../../../components/dashboard-spinner'

enum PlanTab {
  PlanDetails = 'planDetails',
  VersionHistory = 'versionHistory',
}

const Plan: FC = () => {
  const params = useParams<{ planId: string }>()
  const history = useHistory()

  const { [LDFlag.PlanManagerPlansVersionBrowser]: FF_PLAN_MANAGER_PLANS_VERSION_BROWSER } =
    useFlags()

  const { data: planDetails } = useFetchPlanQuery(params.planId)
  const [create, { isLoading, isSuccess: isSaveSuccessful, isError }] = useCreatePlanMutation()
  const { isLoading: isVersionsListLoading, data: { versions: allVersionsList = [] } = {} } =
    useFetchPlanVersionsListQuery(params.planId, {
      skip: !params.planId || !FF_PLAN_MANAGER_PLANS_VERSION_BROWSER,
    })

  const menuTabs = useMemo(() => {
    const tabs = [{ text: 'Plan Details', key: PlanTab.PlanDetails }]
    if (FF_PLAN_MANAGER_PLANS_VERSION_BROWSER && allVersionsList.length > 1) {
      tabs.push({ text: 'Version History', key: PlanTab.VersionHistory })
    }
    return tabs
  }, [allVersionsList, FF_PLAN_MANAGER_PLANS_VERSION_BROWSER])

  const [isErrorToastVisible, setIsErrorToastVisible] = useState<boolean>(false)
  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(true)
  const [activeTab, setActiveTab] = useState<PlanTab>(
    history.location.pathname.indexOf('/versions') > 0 && FF_PLAN_MANAGER_PLANS_VERSION_BROWSER
      ? PlanTab.VersionHistory
      : PlanTab.PlanDetails,
  )

  const prevCreating = usePrevious(isLoading)

  const {
    values,
    errors,
    dirty,
    touched,
    handleChange,
    handleBlur,
    resetForm,
    setFieldValue,
    setErrors,
  } = useFormik<Values>({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: mapSchemaToValues(planDetails),
    onSubmit: (): void => {},
  })
  const hasErrors = Object.entries(errors).length >= 1

  const toggleIsEditDisabled = (): void => {
    setIsEditDisabled(!isEditDisabled)
  }

  const handleClickEdit = (): void => {
    toggleIsEditDisabled()
  }

  const handleCancelEdit = (): void => {
    resetForm()
    toggleIsEditDisabled()
  }

  const handleSave = (): void => {
    if (planDetails) {
      const updatedPlanInfo = planPropertyMapper(values)
      create(updatedPlanInfo)
    }
  }

  useEffect(() => {
    if (isError) {
      setIsErrorToastVisible(true)
    }
  }, [isError])

  useEffect(() => {
    // reset error toast if exit edit mode or click save
    if ((isLoading || isEditDisabled) && !prevCreating) {
      setIsErrorToastVisible(false)
    }
  }, [isLoading, isEditDisabled, prevCreating])

  const handleTabClick = (tab: string): void => {
    setActiveTab(tab as PlanTab)
    const planUrl = `/admin/plans/${params.planId}`
    history.push(tab === 'planDetails' ? planUrl : `${planUrl}/versions`)
  }

  const handleDuplicateClick = (): void => {
    history.push('/admin/plans/create', { planDetails })
  }

  const onSuccessEnd = (): void => {
    if (isSaveSuccessful && !isEditDisabled) {
      toggleIsEditDisabled()
    }
  }

  const handleLeavePage = (path: string): void => {
    history.push(path)
  }

  if (!planDetails || isVersionsListLoading) return <DashboardSpinner />

  const { version, termsVersion, ...exportPlan } = planDetails

  return (
    <>
      {isSaveSuccessful && (
        <SuccessHeaderPopup message="Plan Updated" onAnimationEnd={onSuccessEnd} />
      )}
      {isErrorToastVisible && (
        <Toast
          message="Plan could not be updated. Please try again later."
          isSuccessToaster={false}
        />
      )}
      <Breadcrumbs
        crumbs={[{ to: '/admin/plans', text: 'Plans' }, { text: `Plan ID: ${params.planId}` }]}
      />
      <Title data-cy="plan-id-header-text">Plan ID: {params.planId} </Title>

      <TabMenuContainer>
        <TabMenu tabs={menuTabs} onClick={handleTabClick} activeTab={activeTab} />
      </TabMenuContainer>
      {(activeTab === PlanTab.PlanDetails || !FF_PLAN_MANAGER_PLANS_VERSION_BROWSER) && (
        <div>
          <LeavePageGuard isNavBlocked={dirty} handleLeavePage={handleLeavePage} />
          <HeaderPlanDetails>
            <PlanVersionContainer>
              <DetailText>Version {planDetails.version}</DetailText>
              <DetailText>
                Last Update: {date.format(Number(planDetails.updated), 'MMM D YYYY')}
              </DetailText>
              <DetailText data-qa="created">
                Date Created: {date.format(Number(planDetails.created), 'MMM D YYYY')}
              </DetailText>
            </PlanVersionContainer>
            <ButtonContainer>
              {isEditDisabled ? (
                <>
                  <Button
                    data-cy="edit-button"
                    text="Edit"
                    emphasis="medium"
                    onClick={handleClickEdit}
                  />
                  <Button
                    text="Duplicate"
                    data-cy="duplicate-button"
                    emphasis="medium"
                    onClick={handleDuplicateClick}
                  />
                  <a
                    href={`data:text/json;charset=utf-8,${encodeURIComponent(
                      JSON.stringify(exportPlan),
                    )}`}
                    download={`Plan_${params.planId}.json`}
                  >
                    <Button data-cy="export-button" text="Export" emphasis="medium" />
                  </a>
                </>
              ) : (
                <>
                  <Button
                    text="Cancel"
                    data-cy="cancel-button"
                    emphasis="medium"
                    onClick={handleCancelEdit}
                  />
                  <Button
                    text="Save"
                    data-cy="save-button"
                    onClick={handleSave}
                    isDisabled={!dirty || hasErrors}
                    isProcessing={isLoading}
                  />
                </>
              )}
            </ButtonContainer>
          </HeaderPlanDetails>
          <PlanContainer>
            <PlanForm
              isDisabled={isEditDisabled}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setErrors={setErrors}
            />
          </PlanContainer>
        </div>
      )}
      {activeTab === PlanTab.VersionHistory && FF_PLAN_MANAGER_PLANS_VERSION_BROWSER && (
        <PlanVersions planId={planDetails.id} />
      )}
    </>
  )
}

const PlanVersionContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const HeaderPlanDetails = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'baseline',
  gap: 16,
})

const Title = styled.h1({
  fontWeight: 700,
  fontSize: 32,
  lineHeight: '44px',
  marginTop: 0,
  color: COLOR.EXTEND_OXFORD,
  margin: '24px 0 32px',
})

const DetailText = styled.p({
  fontSize: 20,
  lineHeight: '28px',
  margin: 0,
})

const PlanContainer = styled.div({
  display: 'flex',
  marginTop: 24,
  flexDirection: 'column',
})

const TabMenuContainer = styled.div({
  marginBottom: 24,
})

export { Plan }
