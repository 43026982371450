import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Input } from '../input/input'
import { InputType } from '../input/types'
import type { CurrencyInputProps } from './currency-input-types'
import {
  getCurrencyFormatting,
  getFormattedNumber,
  getFormattedValue,
  getNumericValue,
} from './currency-input-utils'

const NON_DIGITS = /\D/g

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ value, onChange, locale, currency, ...inputProps }, ref) => {
    const [internalValue, setInternalValue] = useState(getFormattedValue(value, locale))
    const [cursorPositionIndex, setCursorPositionIndex] = useState(0)

    const inputRef = useRef<HTMLInputElement>(null)
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement)

    const { decimalSeparator, groupSeparator, symbol, hasSuffixSymbol } = useMemo(
      () => getCurrencyFormatting(currency, locale),
      [currency, locale],
    )

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        // Prefix with 0 if decimal separator entered first
        e.target.value =
          e.target.value === decimalSeparator ? `0${decimalSeparator}` : e.target.value

        // Split everything before and after decimal separator into two parts
        const valueParts = e.target.value.split(decimalSeparator)
        const beforeDecimal = valueParts[0].replace(NON_DIGITS, '')
        const afterDecimal = valueParts.length > 1 ? valueParts[1].replace(NON_DIGITS, '') : null

        // Get current cursor poisition
        const currentCursorPosition = e.target.selectionStart || 0

        // Set value and input text to an empty string if the last digit before the decimal is removed
        if (!beforeDecimal) {
          setInternalValue('')
          e.target.value = ''
          onChange?.(e)
          return
        }

        // Create new formatted number string based on value change
        const formattedValue = `${
          getFormattedNumber(beforeDecimal, locale) +
          (afterDecimal !== null ? decimalSeparator + afterDecimal : '')
        }`

        setInternalValue(formattedValue)

        // Set target index of cursor (triggers useEffect below)
        setCursorPositionIndex(
          formattedValue.length - e.target.value.length + currentCursorPosition,
        )

        // Finally reset target value to numeric value for external use
        e.target.value = `${getNumericValue(formattedValue, groupSeparator)}`

        onChange?.(e)
      },
      [decimalSeparator, locale, groupSeparator, onChange],
    )

    // Keep cursor in the appropriate place while typing
    useEffect(() => {
      inputRef.current?.setSelectionRange(cursorPositionIndex, cursorPositionIndex)
    }, [cursorPositionIndex])

    // Updates the internal value when value is changed externally
    useEffect(() => {
      setInternalValue(getFormattedValue(value, locale))
    }, [locale, value])

    return (
      <Input
        {...inputProps}
        placeholder={`0${decimalSeparator}00`}
        ref={inputRef}
        value={internalValue}
        onChange={handleChange}
        prefix={!hasSuffixSymbol ? symbol : undefined}
        suffix={hasSuffixSymbol ? symbol : undefined}
        type={InputType.text}
      />
    )
  },
)
