import type { StoreUser } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

// users fetch all by store
// TODO: MEXP-338 - cleanup naming
// =================================================================================
export const fetchAllForStore = (storeAccountId: string, accessToken: string) =>
  ({
    type: 'ACCOUNT_USERS_FETCH_ALL',
    payload: { accessToken, storeAccountId },
  } as const)

export const fetchAllForStoreRequest = () =>
  ({
    type: 'ACCOUNT_USERS_FETCH_ALL_REQUEST',
  } as const)

export const fetchAllForStoreSuccess = (users: StoreUser[], accountId: string) =>
  ({
    type: 'ACCOUNT_USERS_FETCH_ALL_SUCCESS',
    payload: { users, accountId },
  } as const)

export const resetAllStoreUsers = () =>
  ({
    type: 'ACCOUNT_USERS_RESET',
  } as const)

export const fetchAllForStoreFailure = createActionFailure('ACCOUNT_USERS_FETCH_ALL_FAILURE')
