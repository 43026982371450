const images = {
  /* eslint-disable global-require */
  extendIcon: require('./extend-icon.png'),
  extendLogo: require('./extend-logo.png'),
  extendLogoWhite: require('./extend-logo-white.png'),
  checkmark: require('./icons/checkmark.svg'),
  crossmark: require('./icons/crossmark.svg'),
  shopify: require('./platforms/shopify.png'),
  magento: require('./platforms/magento.png'),
  bigCommerce: require('./platforms/big-commerce.png'),
  wooCommerce: require('./platforms/woocommerce.png'),
  volusion: require('./platforms/volusion.png'),
  salesforce: require('./platforms/salesforce-commerce.png'),
  custom: require('./platforms/custom.png'),
  products: require('./products.png'),
  rocket: require('./rocket.png'),
  headphones: require('./onboarding-preview-headphones.png'),
  puzzleSparkle: require('./puzzle-sparkle.gif'),
  wavingHand: require('./waving-hand.png'),
  hands: require('./hands-celebration.png'),
  kaleyFullColor: require('./kaley-full-color.png'),
  productPlaceholder: require('./product-placeholder.png'),
}

export default images
