import {
  Button,
  MenuButtonItem,
  Popover,
  PopoverAlignment,
  PopoverDirection,
  Stack,
  usePopover,
} from '@helloextend/zen'
import { StackAlign, StackDirection } from '@helloextend/zen/src/components/stack'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useDispatch, batch } from 'react-redux'
import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import { More } from '@helloextend/zen/src/tokens/icons'
import type { ThreadListItem } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { LDFlag } from '../../../../../constants/ld-flags'
import { usePermissions } from '../../../../../hooks/use-permissions'
import { Permission } from '../../../../../lib/permissions'
import {
  setSelectedThreadListItem,
  setIsDuplicateThreadModalVisible,
} from '../../../../../store/slices/amp-slice'
import { DeleteThreadModal } from '../delete-thread-modal'

type ThreadEditActionMenuProps = {
  thread: ThreadListItem
  handleEdit: (thread: ThreadListItem) => void
  handleConfigureRules: (thread: ThreadListItem) => void
  handleThreadExport: (thread: ThreadListItem) => void
}

const ThreadEditActionMenu: FC<ThreadEditActionMenuProps> = ({
  thread,
  handleEdit,
  handleConfigureRules,
  handleThreadExport,
}) => {
  const dispatch = useDispatch()
  const { [LDFlag.ThreadEditEnabled]: FF_THREAD_EDIT_ENABLED } = useFlags()
  const { hasPermission } = usePermissions()
  const hasEditPermission = hasPermission(Permission.ManageAdjudication)
  const { triggerRef, popoverRef, isPresent, toggle, triggerBoundingBox } =
    usePopover<HTMLButtonElement>()

  const [isDeleteThreadModalOpen, setIsDeleteThreadModalOpen] = useState<boolean>(false)

  const openDeleteModal = (): void => {
    toggle()
    toggleDeleteModal()
  }

  const toggleDeleteModal = useCallback(() => {
    setIsDeleteThreadModalOpen(!isDeleteThreadModalOpen)
  }, [isDeleteThreadModalOpen])

  const { status, type } = thread

  if (!hasEditPermission || !FF_THREAD_EDIT_ENABLED) return null

  const handleClickDuplicate = (): void => {
    toggle()
    batch(() => {
      dispatch(setIsDuplicateThreadModalVisible(true))
      dispatch(setSelectedThreadListItem(thread))
    })
  }

  return (
    <>
      <DeleteThreadModal
        isVisible={isDeleteThreadModalOpen}
        thread={thread}
        handleCancel={toggleDeleteModal}
      />
      <div data-cy={`thread-edit-action-menu-${thread.id}`}>
        <Button
          size="small"
          color="neutral"
          emphasis="low"
          icon={More}
          onClick={toggle}
          ref={triggerRef}
          data-cy="edit-thread-popover-button"
        />
        <Popover
          direction={PopoverDirection.down}
          alignment={PopoverAlignment.end}
          ref={popoverRef}
          triggerBoundingBox={triggerBoundingBox}
          isPresent={isPresent}
        >
          <Stack direction={StackDirection.column} align={StackAlign.stretch} padding={1}>
            <DropdownWrapper>
              <MenuButtonItem onClick={() => handleEdit(thread)} data-cy="edit-thread-link">
                Edit
              </MenuButtonItem>
              <MenuButtonItem onClick={handleClickDuplicate} data-cy="duplicate-thread-link">
                Duplicate
              </MenuButtonItem>
              {type === 'adjudication' && (
                <MenuButtonItem
                  disabled={status === 'draft'}
                  data-cy="configure-adjudication-rules-thread-link"
                  onClick={() => handleConfigureRules(thread)}
                  tooltip="Only Published and Changes Pending claim adjudication rules can be configured"
                >
                  Configure claim adjudication rules
                </MenuButtonItem>
              )}
              {status !== 'published' && (
                <MenuButtonItem data-cy="delete-thread-link" onClick={openDeleteModal}>
                  Delete
                </MenuButtonItem>
              )}
              {type === 'adjudication' && (
                <MenuButtonItem onClick={() => handleThreadExport(thread)} data-cy="export-thread">
                  Export
                </MenuButtonItem>
              )}
            </DropdownWrapper>
          </Stack>
        </Popover>
      </div>
    </>
  )
}

const DropdownWrapper = styled.div({
  width: 240,
})

export { ThreadEditActionMenu }
