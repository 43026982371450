import type { NestedCheckboxFilterOptions } from '../components/filters/types'

const searchNestedCheckboxFilters = (
  filters: NestedCheckboxFilterOptions['filters'],
  searchString: string,
  selectedFilterValues: Record<string, string[]> = {},
  keepCheckedOnSearch = false,
  bulkSearchCSV = false,
): NestedCheckboxFilterOptions['filters'] => {
  const searchArr: string[] =
    bulkSearchCSV && searchString.includes(',') ? searchString.split(',') : [searchString]
  const trimmedSearchArr = searchArr.map((value) => value.trim())

  return Object.keys(filters).reduce((obj, key) => {
    const { label, options } = filters[key]

    // keep checked items on top of list regardless of search
    if (keepCheckedOnSearch && Object.keys(selectedFilterValues).includes(key)) {
      return { [key]: { label, options }, ...obj }
    }

    // check label and children options for matches to the search strings
    const searchMatch = trimmedSearchArr.some(
      (searchStr) =>
        label.toLowerCase().includes(searchStr) ||
        (options && Object.keys(options).find((op) => op.toLowerCase().includes(searchStr))),
    )

    // keep search matches for checkbox and its children
    return searchMatch ? { ...obj, [key]: { label, options } } : obj
  }, {} as NestedCheckboxFilterOptions['filters'])
}

export { searchNestedCheckboxFilters }
