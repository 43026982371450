import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/client-utils/src/request'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.fetch>

export default function* fetch(action: Action): SagaIterator {
  const { termId, version } = action.payload

  yield put(actions.fetchRequest())

  const response = yield call(client.planTerms.fetchTermsVersionLanguageUrl, termId, version)

  if (isErrorResponse(response)) {
    yield put(actions.fetchFailure(response.data.message, response.status))
    return
  }

  yield put(actions.fetchSuccess(response.data.url))
}
