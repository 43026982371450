import styled from '@emotion/styled'
import { Breadcrumbs } from '@helloextend/zen'
import { DisplayLarge, Paragraph } from '@helloextend/zen/src/tokens/typography'
import type { FC } from 'react'
import React from 'react'
import { useParams } from 'react-router-dom'
import { PricingDetailsTable } from '../pricing-details-table'

const PricingDetails: FC = () => {
  const { storeId, planId } = useParams<{ storeId: string; planId: string }>()
  return (
    <>
      <Breadcrumbs
        crumbs={[
          {
            text: 'Stores',
            to: '/admin/stores',
          },
          {
            text: 'Plan & Pricing',
            to: `/admin/stores/${storeId}/product-protection`,
          },
          {
            text: 'Customize Pricing',
          },
        ]}
        data-cy="pricing-details-breadcrumbs"
      />
      <DisplayLargeWithPadding data-cy="pricing-details-heading">
        Pricing Details
      </DisplayLargeWithPadding>
      <Paragraph data-cy="pricing-plan-id">
        Plan ID:{' '}
        <a href={`/admin/plans/${planId}`} target="_blank" rel="noreferrer">
          {planId}
        </a>
      </Paragraph>
      <PricingDetailsTable storeId={storeId} planId={planId} />
    </>
  )
}

const DisplayLargeWithPadding = styled(DisplayLarge)({
  padding: '24px 0 8px',
})

export { PricingDetails }
