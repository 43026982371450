import type { Money } from './currency'

interface LeadCustomer {
  email?: string
}

export interface LeadProduct {
  brand?: string
  imageUrl?: string
  listPrice: Money
  manufacturerWarrantyLengthLabor: number
  manufacturerWarrantyLengthParts: number
  purchasePrice: Money
  referenceId: string
  serialNumber?: string
  title: string
  transactionDate: number
  transactionId: string
}

export enum LeadStatus {
  LIVE = 'live',
  CONSUMED = 'consumed',
  EXPIRED = 'expired',
}

export interface Lead {
  createdAt: number
  customer: LeadCustomer
  leadToken: string
  product: LeadProduct
  quantity: number
  quantityConsumed: number
  sellerId: string
  updatedAt: number
  status: LeadStatus
}

export interface LeadSearchResponse {
  items: Lead[]
  limit: number
  nextPageCursor?: string
}
