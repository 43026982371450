import type { InsuranceProgram, Obligor } from '@helloextend/extend-api-client'
import type { InsuranceProgramCreateRequest } from '@helloextend/extend-api-rtk-query'
import type { Values } from '../pages/admin/insurance-program/schema'

const insuranceProgramFormInitialValues = (insuranceProgram?: InsuranceProgram): Values => ({
  id: insuranceProgram?.id ?? '',
  name: insuranceProgram?.name ?? '',
  activateFrom: insuranceProgram?.activateFrom ?? 0,
  activateTo: insuranceProgram?.activateTo ?? 0,
  permittedGeo: insuranceProgram?.permittedGeo ?? '',
  insuranceProgramNotes: insuranceProgram?.notes ?? '',
  profitSharePrimaryObligor: insuranceProgram?.profitSharePrimaryObligor ?? null,
  profitSharePartner: insuranceProgram?.profitSharePartner ?? '',
  extendProfitShare: insuranceProgram?.extendProfitShare ?? null,
  lossAccrual: insuranceProgram?.lossAccrual ?? null,
  targetLossToReserveRatio: insuranceProgram?.targetLossToReserveRatio ?? null,
  actualLossToReserveRatio: insuranceProgram?.actualLossToReserveRatio ?? null,
  plannedLossToPremiumRatio: insuranceProgram?.plannedLossToPremiumRatio ?? null,
  actualLossToPremiumRatio: insuranceProgram?.actualLossToPremiumRatio ?? null,
  obligor: (insuranceProgram?.obligor || [{} as Obligor]).map((obligor) => ({
    name: obligor.name ?? '',
    riskOwnership: obligor.riskOwnership ?? undefined,
    notes: obligor.notes ?? '',
    extendFee: obligor.extendFee
      ? {
          type: obligor.extendFee.type,
          amount: obligor.extendFee.amount,
          feeAmountOfType: obligor.extendFee.feeAmountOfType,
          basedOn: obligor.extendFee.basedOn,
          thirdPartyProvider: obligor.extendFee.thirdPartyProvider,
        }
      : null,
    fees: obligor.fees?.map((fee) => ({
      type: fee.type ?? '',
      amount: fee.amount ?? null,
      feeAmountOfType: fee.feeAmountOfType ?? '',
      basedOn: fee.basedOn ?? '',
      routing: fee.routing ?? '',
      recognition: fee.recognition ?? '',
      otherFee: fee.otherFee ?? '',
    })),
  })),
})

const insuranceProgramPropertiesMapper = (
  values: Values,
  updatedNote?: string,
): InsuranceProgramCreateRequest => {
  return {
    id: values.id,
    name: values.name,
    activateFrom: values.activateFrom,
    activateTo: values.activateTo,
    permittedGeo: values.permittedGeo,
    notes: values.insuranceProgramNotes ?? '',
    obligor: values.obligor.map((obligorItem) => ({
      name: obligorItem.name,
      riskOwnership: obligorItem.riskOwnership,
      notes: obligorItem.notes ?? '',
      fees: obligorItem.fees.map((fee) => ({
        type: fee.type,
        amount: fee.amount,
        feeAmountOfType: fee.feeAmountOfType,
        basedOn: fee.basedOn,
        routing: fee.routing,
        recognition: fee.recognition,
        otherFee: fee.otherFee,
      })),
      extendFee: obligorItem.extendFee ?? undefined,
    })),
    profitSharePrimaryObligor: values.profitSharePrimaryObligor ?? 0,
    profitSharePartner: values.profitSharePartner ?? '',
    extendProfitShare: values.extendProfitShare ?? 0,
    lossAccrual: values.lossAccrual ?? 0,
    targetLossToReserveRatio: values.targetLossToReserveRatio ?? 0,
    actualLossToReserveRatio: values.actualLossToReserveRatio ?? 0,
    plannedLossToPremiumRatio: values.plannedLossToPremiumRatio ?? 0,
    actualLossToPremiumRatio: values.actualLossToPremiumRatio ?? 0,
    isActive: true,
    // updatedNote is only required when updating a program and it's not a part of regular insurance program schema
    updatedNote,
  }
}

export { insuranceProgramFormInitialValues, insuranceProgramPropertiesMapper }
