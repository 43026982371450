import type { FC } from 'react'
import React from 'react'
import { ErrorMessage } from './error-message'
import { WarningMessage } from './warning-message'
import { SuccessMessage } from './success-message'
import { InfoMessage } from './info-message'

export type InlineAlertProps = {
  message: string
  type: 'success' | 'error' | 'warning' | 'info'
  hasIcon?: boolean
}

/**
 * @deprecated Use Zen InlineAlert component instead: `import { InlineAlert } from '@helloextend/zen'`
 */
const InlineAlert: FC<InlineAlertProps> = ({ message, type, hasIcon }) => {
  if (type === 'error') return <ErrorMessage message={message} hasIcon={hasIcon} />
  if (type === 'warning') return <WarningMessage message={message} hasIcon={hasIcon} />
  if (type === 'info') return <InfoMessage message={message} hasIcon={hasIcon} />
  return <SuccessMessage message={message} hasIcon={hasIcon} />
}

export { InlineAlert }
