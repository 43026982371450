import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { BasicModal } from '../../../../../components/basic-modal'
import { CreateAccountModalForm } from './create-account-modal-form'

type CreateAccountModalProps = {
  isVisible: boolean
  onClickClose: () => void
}

const CreateAccountModal: FC<CreateAccountModalProps> = ({ isVisible, onClickClose }) => {
  return (
    <BasicModal
      data-cy="create-account-modal"
      isVisible={isVisible}
      onClickClose={onClickClose}
      width="600px"
    >
      <Title>Create Account</Title>
      <SubText>
        By creating a merchant account, an email with an update password link will be sent to the
        account owner email address.
      </SubText>
      <CreateAccountModalForm onClickClose={onClickClose} />
    </BasicModal>
  )
}

const Title = styled.div({
  fontSize: 20,
  marginBottom: 16,
  weight: 700,
})

const SubText = styled.div({
  marginBottom: 24,
})

export { CreateAccountModal }
