import type { FC, SyntheticEvent } from 'react'
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useLazyListServicersQuery } from '@helloextend/extend-api-rtk-query'
import { usePrevious } from '@helloextend/client-hooks'
import { Button } from '@helloextend/zen'
import { Add } from '@helloextend/zen/src/tokens/icons'
import { debounce } from 'lodash'
import { PageHeader } from '../../../components/page-header'
import { DataTable } from '../../../components/data-table'
import { servicerColumns } from './table-config'
import { toTableServicerItems } from './to-table-servicer-items'
import type { TableServicersSearch } from '../../../types/servicers'
import { servicers as servicersActions } from '../../../actions'
import type { RootState } from '../../../reducers'
import * as selectors from '../../../reducers/selectors'

const pageSize = 10
const LIMIT = 500

const Servicers: FC = () => {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const searchFilter = useSelector((state: RootState) => selectors.getServicersSearchFilter(state))
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc')

  const [listServicers, { isLoading, isFetching, items, nextPageCursor }] =
    useLazyListServicersQuery({
      selectFromResult: (result) => {
        return {
          ...result,
          ...result.data,
        }
      },
    })

  const [mappedData, setMappedData] = useState<TableServicersSearch[]>(
    toTableServicerItems(items || []),
  )

  const [page, setPage] = useState(0)
  const [hasFetched, setHasFetched] = useState(false)
  const [fetch, setFetch] = useState(true)

  const prevCursor = usePrevious(nextPageCursor)
  const debouncedClear = debounce(() => {
    setHasFetched(false)
    setFetch(true)
  }, 1000)

  useEffect(() => {
    if (isLoading) return

    if (items) {
      if (!hasFetched && !isFetching && !fetch) {
        setMappedData(toTableServicerItems(items))
        setHasFetched(true)
        setPage(0)
        return
      }

      if (prevCursor && prevCursor !== nextPageCursor) {
        setMappedData([...mappedData, ...toTableServicerItems(items)])
        return
      }
    }

    if (!hasFetched && fetch) {
      listServicers({ limit: LIMIT, containsBusinessName: searchFilter, asc: direction === 'asc' })
      setFetch(false)
    }
  }, [
    fetch,
    setFetch,
    items,
    nextPageCursor,
    listServicers,
    mappedData,
    isLoading,
    isFetching,
    prevCursor,
    hasFetched,
    searchFilter,
    direction,
  ])

  const handleSort = (): void => {
    setDirection(direction === 'asc' ? 'desc' : 'asc')
    debouncedClear()
  }

  const handleOnboardClick = (): void => {
    push('/admin/servicers/create')
  }

  const handleRowClick = (_e: SyntheticEvent, servicer: TableServicersSearch): void => {
    push(`/admin/servicers/${servicer.servicerId}`)
  }

  const handleNextClick = (): void => {
    setPage(page + 1)
    if (nextPageCursor && (page + 2) * pageSize >= mappedData.length - 10) {
      listServicers({
        cursor: nextPageCursor,
        limit: LIMIT,
        containsBusinessName: searchFilter,
        asc: direction === 'asc',
      })
    }
  }

  const handlePrevClick = (): void => {
    setPage(page - 1)
  }

  const handleSearch = (_key: string, value: string): void => {
    dispatch(servicersActions.searchFilterSet(value))
    if (!isLoading && !isFetching) {
      debouncedClear()
    }
    setPage(0)
  }

  const getServicersForPage = (): TableServicersSearch[] => {
    if (!mappedData?.length || page * pageSize >= mappedData.length) return []
    return mappedData.slice(page * pageSize, (page + 1) * pageSize)
  }

  useEffect(() => {
    return () => {
      dispatch(servicersActions.searchFilterClear())
    }
  }, [dispatch])

  return (
    <>
      <PageHeader title="Servicers" />
      <ButtonWrapper>
        <Button
          icon={Add}
          emphasis="medium"
          onClick={handleOnboardClick}
          text="Create 3rd Party Servicer"
          data-cy="create-third-party-btn"
        />
      </ButtonWrapper>
      <DataTable
        data={getServicersForPage()}
        columns={servicerColumns}
        isLoading={isLoading || isFetching}
        sortKey="servicerName"
        sortDirection={direction}
        handleSort={handleSort}
        searchPlaceholder="Search by servicer name"
        emptyMessage="No servicers found"
        rowClickEvent={handleRowClick}
        paginationType="servicers"
        pageSize={10}
        hasNext={(page + 1) * pageSize < mappedData.length}
        hasPrev={page > 0}
        currPage={page + 1}
        onNextPage={handleNextClick}
        onPrevPage={handlePrevClick}
        searchTerm={searchFilter}
        onSearch={handleSearch}
        searchMode="fuzzy"
      />
    </>
  )
}

const ButtonWrapper = styled.div({
  display: 'flex',
  position: 'relative',
  float: 'right',
  zIndex: 2,
})

export { Servicers }
