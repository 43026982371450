import type { Action } from '../actions'

export type DownloadUrlState = string

export const initialState: DownloadUrlState = ''

export default function downloadUrl(state = initialState, action: Action): DownloadUrlState {
  switch (action.type) {
    case 'PLAN_TERMS_VERSION_LANGUAGE_SUCCESS':
      return action.payload
    case 'PLAN_TERMS_VERSION_LANGUAGE_OPENED':
    case 'PLAN_TERMS_VERSION_LANGUAGE_FAILURE':
    case 'PLAN_TERMS_VERSION_LANGUAGE_REQUEST':
      return ''
    default:
      return state
  }
}
