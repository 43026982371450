import React from 'react'
import { Button } from '@helloextend/zen'
import { Trash, Add } from '@helloextend/zen/src/tokens/icons'
import styled from '@emotion/styled'

type RepeatableRowProps<TData extends Record<string, any>> = {
  data: TData[]
  renderRow: (data: TData, index: number) => JSX.Element
  handleRowAdd: () => void
  handleRowDelete: (id: number) => void
}

const RepeatableRow = <TData extends Record<string, any>>({
  data,
  renderRow,
  handleRowAdd,
  handleRowDelete,
}: RepeatableRowProps<TData>): JSX.Element => {
  return (
    <>
      {data.map((row, index) => (
        <Row key={row.id ?? index}>
          {renderRow(row, index)}
          <IconContainerWrapper isLabelHidden={index > 0}>
            <Button
              emphasis="low"
              color="red"
              icon={Trash}
              onClick={() => handleRowDelete(index)} // Pass index of TData[], instead of row index
              isDisabled={row.isDisabled ?? false}
            />
          </IconContainerWrapper>
        </Row>
      ))}
      <ButtonContainer>
        <Button
          text={!data.length ? 'Add Row' : 'Add Another Row'}
          emphasis="medium"
          icon={Add}
          onClick={handleRowAdd}
        />
      </ButtonContainer>
    </>
  )
}

const Row = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  margin: '8px 0',
})

const IconContainer = styled.div({
  marginLeft: 8,
})

const IconContainerWrapper = styled(IconContainer)<{ isLabelHidden?: boolean }>(
  ({ isLabelHidden }) => ({
    marginTop: isLabelHidden ? 0 : 22,
  }),
)

const ButtonContainer = styled.div({
  marginTop: 8,
  display: 'flex',
  flexDirection: 'column',
  button: {
    alignSelf: 'unset',
  },
})

export { RepeatableRow }
