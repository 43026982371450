import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { BadgeColor, ButtonSize } from '@helloextend/zen'
import { Badge } from '@helloextend/zen'

type BadgeDescriptionItem = {
  badgeText: string
  badgeColor: BadgeColor
  description: string
}

type BadgeDescriptionsProps = {
  buttonSize?: Exclude<ButtonSize, 'regular' | 'large'>
  'data-cy'?: string
}

const USER_STATUS_COLOR: Record<string, BadgeColor> = {
  deactivated: 'neutral',
  active: 'green',
  pending: 'yellow',
  noStatus: 'neutral',
}

const ACTIVE_STATUS_OPTIONS: BadgeDescriptionItem = {
  badgeText: 'Active',
  description: 'External user has not verified their email and cannot log into the Extend Portal.',
  badgeColor: USER_STATUS_COLOR.active,
}

const DEACTIVATED_STATUS_OPTIONS: BadgeDescriptionItem = {
  badgeText: 'Deactivated',
  description: 'User can no longer log into the Extend Portal. ',
  badgeColor: USER_STATUS_COLOR.deactivated,
}

const PENDING_STATUS_OPTIONS: BadgeDescriptionItem = {
  badgeText: 'Pending',
  description: 'User has not verified their email and cannot log into the Extend Portal.',
  badgeColor: USER_STATUS_COLOR.pending,
}

export const userStatusBadgeDescriptionItems: Record<string, BadgeDescriptionItem> = {
  ACTIVE: ACTIVE_STATUS_OPTIONS,
  PENDING: PENDING_STATUS_OPTIONS,
  DEACTIVATED: DEACTIVATED_STATUS_OPTIONS,
}

const statusDescriptions: BadgeDescriptionItem[] = Object.values(userStatusBadgeDescriptionItems)

const StatusBadgeDescriptions: FC<Omit<BadgeDescriptionsProps, 'buttonSize'>> = ({
  'data-cy': dataCy,
}) => {
  return (
    <table>
      <tbody>
        {statusDescriptions.map((d) => (
          <tr key={d.badgeText}>
            <BadgeCell>
              <Badge text={d.badgeText} color={d.badgeColor} data-cy={`${dataCy}:badge`} />
            </BadgeCell>
            <td>
              <TextContainer data-cy={`${dataCy}:description`}>{d.description}</TextContainer>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const BadgeCell = styled.td({
  marginLeft: 16,
  marginRight: 7,
  paddingTop: 5,
  textAlign: 'right',
  verticalAlign: 'top',
  whiteSpace: 'nowrap',
  'tr:not(:last-child) &': {
    paddingBottom: 19,
  },
})

const TextContainer = styled.div({
  fontSize: 13,
  marginLeft: 16,
  lineHeight: '18px',
  'tr:not(:last-child) &': {
    marginBottom: 19,
  },
})

export default StatusBadgeDescriptions
