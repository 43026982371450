import type { FC } from 'react'
import React from 'react'
import { useGetConversationQuery } from '@helloextend/extend-api-rtk-query'
import { CellText } from '@helloextend/merchants-ui'

type ConversationNameRowItemProps = {
  id: string
}
const ConversationNameRowItem: FC<ConversationNameRowItemProps> = ({ id }) => {
  const { data, isFetching } = useGetConversationQuery(id)
  if (!data || isFetching) return null
  return <CellText data-cy="conversation-name>">{data.title}</CellText>
}

export { ConversationNameRowItem }
