import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/client-utils/src/request'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.update>

export default function* update(action: Action): SagaIterator {
  const { contractId, claimId, accessToken, data } = action.payload

  yield put(actions.updateRequest())

  try {
    const response: ResolvedType<typeof client.claims.update> = yield call(
      client.claims.update,
      contractId,
      claimId,
      accessToken,
      data,
    )

    if (isErrorResponse(response)) {
      yield put(actions.updateFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(actions.updateFailure(`An unknown error occurred`, response.status))
      return
    }

    yield put(actions.updateSuccess(response.data))
  } catch (e) {
    yield put(actions.updateFailure(e.message))
  }
}
