import type { FC } from 'react'
import React from 'react'
import { HeadTag } from '../components/head-tag'
import ResetPasswordForm from '../components/reset-password-form'

import { AuthLayout } from '../hoc/auth-layout'

const PasswordReset: FC = () => (
  <AuthLayout>
    <HeadTag siteTitle="Extend | Reset Password" />
    <ResetPasswordForm />
  </AuthLayout>
)

export { PasswordReset }
