const skuCsvHeaders = [
  'skuId',
  'skuName',
  'lineOfBusiness',
  'program',
  'subprogram',
  'underwriter',
  'insuranceProgramId',
  'coverageType',
  'termLength',
  'serviceType',
  'productCondition',
  'purchasePriceOfProduct',
  'limitOfLiabilityBasedUpon',
  'paymentModel',
  'cancellationId',
  'deductible',
  'deductibleAmount',
  'currency',
  'priceBandLow',
  'priceBandHigh',
  'lossCost',
  'targetLossRatio',
  'obligorFeeRate',
  // same as underwritingProfit
  'obligorFeeAmount',
  'reserve',
  // same as obligorFee
  'obligorFeeRateAmount',
  'premium',
  'activateTo',
  'activateFrom',
]

export { skuCsvHeaders }
