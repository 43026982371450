import type { UserGrant } from '@helloextend/extend-api-rtk-query'

const localStorageKeys = Object.freeze({
  ACCESS_TOKEN: `${__EXTEND_ENV__}_PORTAL_ACCESS_TOKEN`,
  REMEMBER_ME_EMAIL: 'REMEMBER_ME_EMAIL',
  OAUTH_PKCE_STATE: 'OAUTH_PKCE_STATE',
  OAUTH_PKCE_CODE_VERIFIER: 'OAUTH_PKCE_CODE_VERIFIER',
  SELECTED_GRANT: 'SELECTED_GRANT',
})

type LocalStorageKey = typeof localStorageKeys[keyof typeof localStorageKeys]

const setLocalStorageItem = (key: LocalStorageKey, value: string | null): void => {
  if (!value) {
    localStorage.removeItem(key)
    return
  }
  localStorage.setItem(key, value)
}

const getLocalStorageItem = (key: LocalStorageKey): string | undefined => {
  return localStorage.getItem(key) ?? undefined
}

export const setAccessToken = (accessToken: string | null): void => {
  setLocalStorageItem(localStorageKeys.ACCESS_TOKEN, accessToken)
}

export const getAccessToken = (): string | undefined => {
  const accessToken = getLocalStorageItem(localStorageKeys.ACCESS_TOKEN)

  if (!accessToken) return undefined

  return accessToken
}

export const setRememberMeEmail = (email: string | null): void => {
  setLocalStorageItem(localStorageKeys.REMEMBER_ME_EMAIL, email)
}

export const getRememberMeEmail = (): string | undefined => {
  const email = getLocalStorageItem(localStorageKeys.REMEMBER_ME_EMAIL)

  if (!email) return undefined

  return email
}

export const setPkceState = (pkceState: string): void => {
  setLocalStorageItem(localStorageKeys.OAUTH_PKCE_STATE, pkceState)
}

export const getPkceState = (): string | undefined => {
  const pkceState = getLocalStorageItem(localStorageKeys.OAUTH_PKCE_STATE)

  if (!pkceState) return undefined

  return pkceState
}

export const setPkceCodeVerifier = (pkceCodeverifier: string): void => {
  setLocalStorageItem(localStorageKeys.OAUTH_PKCE_CODE_VERIFIER, pkceCodeverifier)
}

export const getPkceCodeVerifier = (): string | undefined => {
  const pkceCodeVerifier = getLocalStorageItem(localStorageKeys.OAUTH_PKCE_CODE_VERIFIER)

  if (!pkceCodeVerifier) return undefined

  return pkceCodeVerifier
}

export const saveUserSelectedGrant = (userGrant: UserGrant | null): void => {
  setLocalStorageItem(localStorageKeys.SELECTED_GRANT, JSON.stringify(userGrant))
}

export const getUserSelectedGrant = (): UserGrant | undefined => {
  const grant = getLocalStorageItem(localStorageKeys.SELECTED_GRANT)

  if (!grant) return undefined

  return JSON.parse(grant) as UserGrant
}
