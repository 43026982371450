import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import {
  COLOR,
  useToaster,
  ToastDuration,
  ToastColor,
  Modal,
  ModalController,
  Grid,
  Input,
  GridItem,
  AdvancedSelect,
  Accordion,
  InlineAlert,
  InlineAlertColor,
  Error as InlineError,
} from '@helloextend/zen'
import {
  useCreateUserGrantMutation,
  useInviteUserMutation,
} from '@helloextend/extend-api-rtk-query'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { LDFlag } from '../../../../constants/ld-flags'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { enterpriseOptions, legacyInternalOptions } from '../../../../utils/user-role-mapper'

const schema = Yup.object()
  .shape({
    firstName: Yup.string().required('Please enter a first name'),
    lastName: Yup.string().required('Please enter a last name'),
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Please enter a valid email address'),
    roles: Yup.array().of(Yup.string()).required('Please select at least one user role'),
  })
  .defined()

type CreateUserModalProps = {
  visible: boolean
  toggleOff: () => void
}

const CreateExtendUserModal: FC<CreateUserModalProps> = ({ visible, toggleOff }) => {
  const { toast } = useToaster()
  const [isInlineAlertVisible, setIsInlineAlertVisible] = useState(false)

  const { [LDFlag.EnterpriseRoles]: FF_ENTERPRISE_ROLES } = useFlags()

  const [createUser, { isLoading: isSendingInvite }] = useInviteUserMutation()

  const [addGrantToUser, { isLoading: isAddGrantToUserLoading }] = useCreateUserGrantMutation()
  const handleToggleInlineAlert = (): void => {
    setIsInlineAlertVisible(!isInlineAlertVisible)
  }

  const { touched, errors, values, dirty, handleChange, handleSubmit, handleBlur } = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      roles: [],
    },
    onSubmit: async (formData): Promise<void> => {
      try {
        await sendUserInviteAndAddGrants(formData)
      } catch (error) {
        toast({
          message: 'Something went wrong. Please try again.',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.red,
        })
      }
    },
  })

  const sendUserInviteAndAddGrants = async (formdata: typeof values): Promise<void> => {
    const createUserResponse = await createUser({
      email: formdata.email,
      firstName: formdata.firstName,
      lastName: formdata.lastName,
    })
    if ('error' in createUserResponse) {
      const { status } = createUserResponse.error as FetchBaseQueryError
      if (status === 409) {
        handleToggleInlineAlert()
        return
      }
      throw new Error()
    }
    const results = []
    for (const role of formdata.roles) {
      const grant = { userId: formdata.email, role, ern: 'ERN:ACC:*' } // all internal Extend roles will have cross-org and cross-admin tenancy boundary for initial Extend Enterprise release
      results.push(addGrantToUser(grant))
    }
    await Promise.all(results).then((responses) => {
      responses.forEach((rsp) => {
        if ('error' in rsp) {
          throw new Error()
        }
      })
    })
    toast({
      message: `The invite has been sent to ${formdata.email} successfully!`,
      toastDuration: ToastDuration.short,
      toastColor: ToastColor.blue,
    })
    toggleOff()
  }
  const hasErrors = useMemo(() => Object.entries(errors).length > 0, [errors])

  return (
    <ModalController isOpen={visible}>
      <Modal
        size="md"
        heading="Invite user"
        primaryButtonProps={{
          onClick: function noRefCheck() {
            handleSubmit()
          },
          text: 'Invite',
          isDisabled: !dirty || hasErrors || isSendingInvite || isAddGrantToUserLoading,
          isProcessing: isSendingInvite || isAddGrantToUserLoading,
          'data-cy': 'submit-invite-user-button',
        }}
        secondaryButtonProps={{
          onClick: function noRefCheck() {
            toggleOff()
          },
          text: 'Cancel',
          'data-cy': 'cancel-invite-user-button',
        }}
        data-cy="invite-user-modal"
      >
        <DetailText>Invite a user to the Extend portal and assign user role(s).</DetailText>
        <InlineAlertWrapper isInlineAlertVisible={isInlineAlertVisible}>
          <Accordion isExpanded={isInlineAlertVisible} transitionDurationMs={250}>
            <InlineAlert
              color={InlineAlertColor.red}
              icon={InlineError}
              data-cy="create-configuration-inline-alert"
            >
              <InlineAlertText>
                This user is already in the Extend Portal. You can modify the role(s) for this user
                via the user management list.
              </InlineAlertText>
            </InlineAlert>
          </Accordion>
        </InlineAlertWrapper>
        <FormGroup isFullWidth>
          <Grid columns={{ lg: 2, md: 2, sm: 1 }} spacing={{ lg: 3, md: 3, sm: 2 }}>
            <Input
              id="firstName"
              label="First Name"
              data-cy="firstName"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={touched.firstName && Boolean(errors.firstName)}
              errorFeedback={errors.firstName}
            />
            <Input
              id="lastName"
              data-cy="lastName"
              label="Last Name"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={touched.lastName && Boolean(errors.lastName)}
              errorFeedback={errors.lastName}
            />
            <GridItem fillWidth>
              <Input
                id="email"
                label="Email"
                data-cy="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isError={touched.email && Boolean(errors.email)}
                errorFeedback={errors.email}
              />
            </GridItem>
            <GridItem fillWidth>
              <AdvancedSelect
                id="roles"
                label="Role"
                maxQuantityToDisplay={8}
                multiple
                data-cy="select-roles"
                onChange={handleChange}
                options={FF_ENTERPRISE_ROLES ? enterpriseOptions : legacyInternalOptions}
                placeholder="Select"
                value={values.roles}
              />
            </GridItem>
          </Grid>
        </FormGroup>
      </Modal>
    </ModalController>
  )
}

const DetailText = styled.p({
  color: COLOR.NEUTRAL[1000],
  fontSize: 16,
  marginTop: 0,
  marginBottom: 24,
})

const FormGroup = styled.div<{ isFullWidth: boolean }>(({ isFullWidth }) => ({
  width: isFullWidth ? '100%' : '55%',
}))
const InlineAlertWrapper = styled.div<{ isInlineAlertVisible: boolean }>(
  ({ isInlineAlertVisible }) => ({
    maxWidth: 708,
    paddingBottom: isInlineAlertVisible ? 24 : 0,
  }),
)
const InlineAlertText = styled.p({
  fontWeight: 400,
  fontSize: 15,
  lineHeight: '20px',
  margin: 0,
})

export { CreateExtendUserModal, CreateUserModalProps }
