import type { InsuranceProgram } from '@helloextend/extend-api-client'
import { CellText } from '@helloextend/merchants-ui'
import React from 'react'
import type { CellProps, Column } from 'react-table'
import { formatDateWithTimezone } from '../../../utils/date-formatting'

const insuranceProgramVersionColumns: Column[] = [
  {
    Header: 'Version',
    accessor: 'version',
    Cell: ({ value }: CellProps<InsuranceProgram, string>): JSX.Element => (
      <CellText>{value}</CellText>
    ),
  },
  {
    Header: 'Updated Reason',
    accessor: 'updatedNote',
    Cell: ({ value }: CellProps<InsuranceProgram, string>): JSX.Element => (
      <CellText>{value}</CellText>
    ),
  },
  {
    Header: 'Date Activated',
    accessor: 'createdAt',
    Cell: ({ row, value }: CellProps<InsuranceProgram, number>): JSX.Element => {
      const { createdBy } = row.original
      return (
        <CellText>
          {value ? formatDateWithTimezone(value) : '-'} by {createdBy}
        </CellText>
      )
    },
  },
  {
    Header: 'Date Deactivated',
    accessor: 'updatedAt',
    Cell: ({ row, value }: CellProps<InsuranceProgram, number>): JSX.Element => {
      const { updatedBy } = row.original
      return <CellText>{value ? `${formatDateWithTimezone(value)} by ${updatedBy}` : '-'}</CellText>
    },
  },
]

export { insuranceProgramVersionColumns }
