export type Image = keyof typeof Images

const Images = {
  /* eslint-disable global-require */
  extendIcon: require('./extend-icon.png'),
  extendLogo: require('./extend-logo.png'),
  extendLogoWhite: require('./extend-logo-white.png'),
  checkmark: require('./checkmark.svg'),
  crossmark: require('./crossmark.svg'),
  shopify: require('./shopify.png'),
  magento: require('./magento.png'),
  bigCommerce: require('./big-commerce.png'),
  wooCommerce: require('./woocommerce.png'),
  volusion: require('./volusion.png'),
  salesforce: require('./salesforce-commerce.png'),
  custom: require('./custom.png'),
  products: require('./products.png'),
  rocket: require('./rocket.png'),
  headphones: require('./onboarding-preview-headphones.png'),
  puzzleSparkle: require('./puzzle-sparkle.gif'),
  wavingHand: require('./waving-hand.png'),
  hands: require('./hands-celebration.png'),
  shopifyInstall: require('./shopify-install.svg'),
  placeholderImage: require('./extend-placeholder-mountains.svg'),
  helpBasicsImage: require('./extend-help-basics-thumbnail.svg'),
  helpBillingImage: require('./extend-help-billing-thumbnail.svg'),
  helpLeadsImage: require('./extend-help-leads-thumbnail.svg'),
  cloudsError: require('./clouds-error.svg'),
}

export default Images
