import type { AdvancedSelectOption, FilterDef } from '@helloextend/zen'
import { CategoryMappingStatus } from '@helloextend/extend-api-client'

const { WARRANTABLE, NON_WARRANTABLE, UNMAPPED } = CategoryMappingStatus
export const getCategoryMapperFilterDefs = (categories: string[]): FilterDef[] => {
  return [
    {
      type: 'group',
      label: 'Filters',
      filterDefs: [
        {
          id: 'extendCategory',
          type: 'select',
          label: 'Extend Plan Category',
          options: getCategoryOptions(categories),
          isMultiSelect: true,
        },
        {
          id: 'mappingStatus',
          type: 'select',
          label: 'Warranty Status',
          options: [
            { display: WARRANTABLE, value: WARRANTABLE },
            { display: NON_WARRANTABLE, value: NON_WARRANTABLE },
            { display: UNMAPPED, value: UNMAPPED },
          ],
          isMultiSelect: true,
        },
      ],
    },
  ]
}

export const getCategoryOptions = (categories: string[]): AdvancedSelectOption[] =>
  categories.map((category) => ({
    display: category,
    value: category,
  }))
