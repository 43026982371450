import type { ServiceOrder } from '@helloextend/extend-api-client'
import type { Action } from '../actions'

export interface ServiceOrderClaim {
  [key: string]: ServiceOrder[]
}

export type ByClaimReducerState = ServiceOrderClaim

export const initialState: ByClaimReducerState = {}

export default function byClaim(state = initialState, action: Action): ByClaimReducerState {
  switch (action.type) {
    case 'SERVICE_ORDERS_FETCH_SUCCESS':
      return addItems(state, action.payload.serviceOrders)
    case 'SERVICE_ORDERS_FETCH_SINGLE_CLAIM_SUCCESS':
      return addItems(state, action.payload.serviceOrders)
    case 'SERVICE_ORDERS_RESET':
      return initialState
    default:
      return state
  }
}

function addItems(state: ByClaimReducerState, items: ServiceOrder[]): ByClaimReducerState {
  if (items.length) {
    return { ...state, [items[0].claimId]: items }
  }

  return state
}
