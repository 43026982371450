/* eslint-disable no-param-reassign */
import { isArray, isPlainObject } from 'lodash/fp'
import type { BlackList } from './blacklist'
import { BLACKLIST, isBlacklisted } from './blacklist'

export const REDACTED = '[REDACTED]'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function redact(obj: any, blacklist: BlackList = BLACKLIST): any {
  if (isArray(obj)) {
    return obj.map((o) => redact(o, blacklist))
  }
  return Object.entries(obj).reduce((memo, [key, value]) => {
    let returnValue = value
    if (isBlacklisted(key, blacklist)) {
      returnValue = REDACTED
    } else if (isPlainObject(value)) {
      returnValue = redact(value, blacklist)
    } else if (isArray(value) && isPlainObject(value[0])) {
      returnValue = value.map((v) => redact(v, blacklist))
    }
    return { ...memo, [key]: returnValue }
  }, {})
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function redactJsonReplacer(key: string, value: any): any {
  if (isBlacklisted(key, BLACKLIST)) {
    return REDACTED
  }
  return value
}
