import styled from '@emotion/styled'
import { COLOR } from '../../../../tokens/colors'
import { Subheading } from '../../../../tokens/typography'

export const StyledAdvancedSelect = styled.button<{
  hasValue: boolean
  disabled: boolean
  hasIcon: boolean
  hasClearButton: boolean
  hasDisplayInfo?: boolean
}>(({ hasValue, disabled, hasIcon, hasClearButton, hasDisplayInfo }) => {
  return {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
    appearance: 'none',
    flex: 1,
    border: 'none',
    borderRadius: 4,
    color: disabled || !hasValue ? COLOR.NEUTRAL[600] : COLOR.NEUTRAL[1000],
    paddingBlock: 8,
    paddingInlineStart: hasIcon ? 40 : 12,
    paddingInlineEnd: 40 + (hasClearButton ? 36 : 0) + (hasDisplayInfo ? 24 : 0),
    width: '100%',
    boxSizing: 'border-box',
    fontFamily: '"Nunito Sans", sans-serif',
    fontSize: 16,
    lineHeight: '24px',
    minHeight: 40,
    textAlign: 'left',
    outline: 'none',
    background: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '& + label': {
      pointerEvents: 'none',
    },
    margin: `0 ${hasClearButton ? '-76px' : '-40px'} 0 ${hasIcon ? '-28px' : '0'}`,
  }
})

export const SpinnerContainer = styled.span({
  display: 'grid',
  placeContent: 'center',
  width: 40,
  height: 40,
  marginLeft: 4,
  marginRight: -8,
})

export const Header = styled.div({
  padding: 8,
})

export const MenuItems = styled.div({
  padding: '6px 8px',
})

export const EmptyContainer = styled.div({
  fontSize: 14,
  lineHeight: '20px',
  padding: 12,
  color: COLOR.NEUTRAL[600],
})

export const Chips = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: 2,
  margin: '-4px -4px -4px -8px',
})

export const DisplayValueContainer = styled.span<{
  reverse: boolean
}>(({ reverse }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 6,
  maxWidth: '100%',
  ...(reverse && {
    flexDirection: 'row-reverse',
  }),
}))

export const DisplayValue = styled.span({
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

export const BadgeContainer = styled.span<{
  isDisabled: boolean
}>(({ isDisabled }) => ({
  ...(isDisabled && {
    opacity: 0.65,
  }),
}))

export const LimitText = styled.div<{
  isError?: boolean
  isCondensed?: boolean
}>(({ isError, isCondensed }) => ({
  fontSize: 13,
  lineHeight: '16px',
  padding: isCondensed ? '0 8px 8px 8px' : '12px 20px',
  color: !isError ? COLOR.NEUTRAL[600] : COLOR.RED[700],
}))

export const OptionGroup = styled.div({
  position: 'relative',
})

export const StyledSubheading = styled(Subheading)({
  background: COLOR.WHITE,
  position: 'sticky',
  zIndex: 1,
  top: 0,
  marginTop: -6,
  padding: '18px 12px 12px',
})

export const SelectedOnlyTrigger = styled.span({
  cursor: 'pointer',
  display: 'inline-block',
  margin: '-4px -6px -4px 2px',
  padding: '5px 6px 3px',
  borderRadius: 4,
  color: COLOR.BLUE[800],
  fontWeight: 700,
  fontSize: 13,
  '&:hover': {
    background: COLOR.BLUE.OPACITY[12],
  },
  '&:active': {
    background: COLOR.BLUE.OPACITY[25],
  },
})
