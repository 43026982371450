import { createActionFailure } from '../../factories'

export const voidExpense = (serviceOrderId: string, expenseId: string, accessToken: string) =>
  ({
    type: 'SERVICE_ORDER_EXPENSES_VOID',
    payload: { serviceOrderId, expenseId, accessToken },
  } as const)

export const voidExpenseSuccess = () => ({ type: 'SERVICE_ORDER_EXPENSES_VOID_SUCCESS' } as const)

export const voidExpenseFailure = createActionFailure('SERVICE_ORDER_EXPENSES_VOID_FAILURE')

export const voidExpenseRequest = () => ({ type: 'SERVICE_ORDER_EXPENSES_VOID_REQUEST' } as const)
