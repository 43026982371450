import * as selectors from './selectors'
import reducer from './reducers'
import sagas from './sagas'
import { actions } from './actions'

export type {
  Action as ServicerLocationsAction,
  ActionType as ServicerLocationsActionType,
} from './actions'
export type { ReducerState as ServicerLocationsReducerState } from './reducers'

export {
  actions as servicerLocationsActions,
  reducer as servicerLocationsReducer,
  sagas as servicerLocationsSagas,
  selectors as servicerLocationsSelectors,
}
