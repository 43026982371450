import type { AuditItem, Contract } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetchAuditHistory = (
  accessToken: string,
  contractId: string,
  cursor?: string,
  limit = 25,
) =>
  ({
    type: 'CONTRACT_FETCH_AUDIT_HISTORY',
    payload: { contractId, accessToken, cursor, limit },
  } as const)

export const auditHistoryRequest = () => ({ type: 'CONTRACT_AUDIT_HISTORY_REQUEST' } as const)

export const auditHistorySuccess = (items: Array<AuditItem<Contract>>, nextPageCursor?: string) =>
  ({ type: 'CONTRACT_AUDIT_HISTORY_SUCCESS', payload: { items, nextPageCursor } } as const)

export const auditHistoryFailure = createActionFailure('CONTRACT_AUDIT_HISTORY_FAILURE')

export const nextAuditPage = (
  accessToken: string,
  contractId: string,
  cursor?: string,
  limit = 25,
) =>
  ({
    type: 'CONTRACT_AUDIT_HISTORY_NEXT_PAGE',
    payload: { accessToken, contractId, cursor, limit },
  } as const)

export const incrementNextAuditPage = () =>
  ({
    type: 'CONTRACT_AUDIT_HISTORY_INCREMENT_NEXT_PAGE',
  } as const)

export const prevAuditPage = () =>
  ({
    type: 'CONTRACT_AUDIT_HISTORY_PREV_PAGE',
  } as const)
