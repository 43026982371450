const images = {
  /* eslint-disable global-require */
  breakerBox: require('./breaker-box.png'),
  disconnectedPlug: require('./disconnected-plug-404.png'),
  extendLogoWhite: require('./extend-logo-white.png'),
  sparksImage: require('./sparks-image.png'),
  /* eslint-enable global-require */
}

export default images
