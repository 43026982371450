import type { FC } from 'react'
import React from 'react'
import { CheckboxFilter } from './checkbox-filter'
import { DateRangeFilter } from './date-range-filter'
import { TextFilter } from './text-filter'
import { NumberRangeFilter } from './number-range-filter'
import { AdvancedTextFilter } from './advanced-text-filter'
import type { FilterOptions, FilterValues } from './types'
import { NestedCheckboxFilter } from './nested-checkbox-filter'

export type FilterProps = {
  property: string
  filter: FilterOptions
  values?: FilterValues | null
  onFilterChange: (property: string, values: FilterValues | null) => void
  onMenuLock: (isLocked: boolean) => void
}

const Filter: FC<FilterProps> = ({ property, filter, values, onFilterChange, onMenuLock }) => {
  switch (filter.type) {
    case 'checkbox':
      return (
        <CheckboxFilter
          property={property}
          onFilterChange={onFilterChange}
          includeSearchBar={filter.includeSearchBar}
          options={filter.options}
          values={values && values.type === 'checkbox' ? values : undefined}
        />
      )
    case 'nestedCheckbox':
      return (
        <NestedCheckboxFilter
          property={property}
          onFilterChange={onFilterChange}
          includeSearchBar={filter.includeSearchBar}
          bulkSearchCSV={filter.bulkSearchCSV}
          keepCheckedOnSearch={filter.keepCheckedOnSearch}
          filters={filter.filters}
          values={values && values.type === 'nestedCheckbox' ? values : undefined}
        />
      )
    case 'dateRange':
      return (
        <DateRangeFilter
          property={property}
          onFilterChange={onFilterChange}
          onMenuLock={onMenuLock}
          ranges={filter.ranges}
          values={values && values.type === 'dateRange' ? values : undefined}
        />
      )
    case 'text':
      return (
        <TextFilter
          property={property}
          onFilterChange={onFilterChange}
          values={values && values.type === 'text' ? values : undefined}
        />
      )
    case 'advancedText':
      return (
        <AdvancedTextFilter
          property={property}
          onFilterChange={onFilterChange}
          values={values && values.type === 'advancedText' ? values : undefined}
        />
      )
    case 'numberRange':
      return (
        <NumberRangeFilter
          property={property}
          onFilterChange={onFilterChange}
          values={values && values.type === 'numberRange' ? values : undefined}
          highLabel={filter.highLabel ?? ''}
          lowLabel={filter.lowLabel ?? ''}
          isCurrency={filter.isCurrency ?? false}
        />
      )
  }

  return null
}

export { Filter }
