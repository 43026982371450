import type { FC } from 'react'
import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../../tokens/colors'
import { CellResizer } from './cell-resizer'
import { useDataTableContext } from '../data-table-context'

export interface BodyCellProps {
  align?: 'left' | 'center' | 'right'
  hug?: boolean
  fixRight?: boolean
  maxWidth?: number | string
  colSpan?: number
  index?: number
}

export const BodyCell: FC<BodyCellProps> = ({
  children,
  align = 'left',
  fixRight,
  maxWidth,
  hug = false,
  colSpan,
  index = 0,
}) => {
  const cellRef = useRef<HTMLTableCellElement>(null)

  const {
    columnsPinnedQuantity,
    'data-cy': dataCy,
    isTableHorizontallyScrollable,
  } = useDataTableContext()

  const fix =
    fixRight === undefined && isTableHorizontallyScrollable && index < columnsPinnedQuantity

  return (
    <>
      {fix && <CellResizer cellRef={cellRef} />}
      <StyledCell
        data-cy={dataCy && `${dataCy}:cell`}
        align={align}
        hug={hug || !!maxWidth}
        fix={fix}
        fixRight={fixRight}
        colSpan={colSpan}
        ref={cellRef}
        className={fix || fixRight ? 'table-cell-fixed' : undefined}
      >
        <Content maxWidth={maxWidth}>{children}</Content>
      </StyledCell>
    </>
  )
}

const StyledCell = styled.td<{
  align?: 'left' | 'center' | 'right'
  hug: boolean
  fix: boolean
  fixRight?: boolean
}>(({ align, hug, fix, fixRight = false }) => ({
  textAlign: align,
  ...(fix && {
    position: 'sticky',
    zIndex: 1,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: -5,
      top: 0,
      bottom: 0,
      width: 5,
      borderLeft: `1px solid ${COLOR.NEUTRAL[300]}`,
      background: 'linear-gradient(90deg, rgba(0,0,0,0.08) 0%, rgba(0,0,0,0) 100%)',
    },
  }),
  ...(fixRight && {
    position: 'sticky',
    right: 0,
    zIndex: 1,
    '&::after': {
      content: '""',
      position: 'absolute',
      left: -5,
      top: 0,
      bottom: 0,
      width: 5,
      borderRight: `1px solid ${COLOR.NEUTRAL[300]}`,
      background: 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.08) 100%)',
    },
  }),
  ...(hug && { width: 0 }),
  padding: '0 16px',
  lineHeight: '24px',
  whiteSpace: 'nowrap',
  '&:empty': {
    padding: 0,
  },
  '& a': {
    textDecoration: 'none',
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const Content = styled.div<{
  maxWidth?: number | string
}>(({ maxWidth }) => ({
  display: 'inline-flex',
  paddingTop: 14,
  paddingBottom: 14,
  maxWidth,
  ...(maxWidth && {
    '& > *': {
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
}))
