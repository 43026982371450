import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { get } from '@helloextend/extend-api-fetch'

export async function fetch(
  storeId: string,
  reportId: string,
  accessToken: string,
): Promise<ApiResponse<{ url: string }>> {
  return get(`/stores/${storeId}/reports/${reportId}`, { accessToken, apiVersion: 'latest' })
}
