import type { ContractType, ContractsV2GetResponse } from '@helloextend/extend-api-client'
import type { TableContractsSearch } from '../../../types/contracts'

export const toTableContractItem = (
  contractSearch: ContractsV2GetResponse,
): TableContractsSearch => ({
  id: contractSearch.id,
  contractId: contractSearch.id,
  currencyCode: contractSearch.currency || contractSearch.purchaseCurrency || 'USD',
  customerName: contractSearch.customer.name,
  storeName: contractSearch.sellerName,
  productName: getProductNameOrShippingProductCount(contractSearch),
  productSerialNumber: contractSearch.product?.serialNumber,
  customerEmail: contractSearch.customer.email,
  customerPhone: contractSearch.customer.phone,
  planPrice: typeof contractSearch.purchasePrice === 'number' ? contractSearch.purchasePrice : 0,
  status: contractSearch.status,
  type: contractSearch?.type || ('pcrs' as ContractType),
  transactionId: contractSearch.transactionId,
  purchaseDate: contractSearch.purchaseDate,
  planCategory: contractSearch.planDetails?.category ?? '',
  merchantCustomerId: contractSearch.merchantCustomerId ?? '',
})

const getProductNameOrShippingProductCount = (contract: ContractsV2GetResponse): string => {
  if (contract.type === 'shipping_protection' && contract.productsList) {
    return `${contract.productsList.length} product${contract.productsList.length > 1 ? 's' : ''}`
  }
  if (contract.type === 'category' && contract.productIds) {
    return `${contract.productIds.length} product${contract.productIds.length > 1 ? 's' : ''}`
  }
  return contract.product?.name || '--'
}

export const toTableContractItems = (
  contractSearchItems: ContractsV2GetResponse[] | undefined,
): TableContractsSearch[] => {
  if (contractSearchItems) {
    return contractSearchItems.map(toTableContractItem)
  }
  return []
}
