import styled from '@emotion/styled'
import {
  useFetchPlanQuery,
  useGetStorePlanPricingListQuery,
  useUpdateStorePlanPricingMutation,
} from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import { Button, COLOR, Spinner } from '@helloextend/zen'
import type { FormikErrors } from 'formik'
import { getIn, useFormik } from 'formik'
import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import type { MappedStorePlanPricingToWholesalePricing } from '../../../../../../../../types/wholesale-pricing'
import {
  mapStorePlanPricingToWholesalePricing,
  mapValuesToWholesalePricing,
} from '../../../../utils'
import { SaveChangesButtonGroup } from '../../../settings/components/save-changes-button-group'
import { WholesalePricingRow } from '../wholesale-pricing-row'
import type { Values } from './schema'
import { schema } from './schema'

type WholesalePricingTableProps = {
  storeId: string
  planId: string
}

const getFormikError = (errors: FormikErrors<Values>, index: number, fieldName: string): string => {
  const error = getIn(errors, `wholesalePrices[${index}]${fieldName}`)
  return error ?? undefined
}

const WholesalePricingTable: FC<WholesalePricingTableProps> = ({ storeId, planId }) => {
  const { data, isLoading } = useGetStorePlanPricingListQuery({
    storeId,
    planId,
    version: 'latest',
  })
  const { data: planData } = useFetchPlanQuery(planId)

  const [updateStorePlanPricing, { isLoading: isUpdating }] = useUpdateStorePlanPricingMutation()
  const [isEditEnabled, setIsEditDisabled] = useState(false)

  const mappedStorePlanPricing = useMemo(
    () =>
      mapStorePlanPricingToWholesalePricing(planId, storeId, planData?.matching || [], data?.items),
    [data, planData, planId, storeId],
  )

  const { values, handleChange, errors, isValid, dirty, resetForm, setValues } = useFormik<Values>({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    initialValues: {
      wholesalePrices: mappedStorePlanPricing,
    },
    onSubmit: (): void => {},
  })
  const { toastCompleted, toastError } = useStandardToast()

  const handleEditClick = (): void => {
    setIsEditDisabled(true)
  }

  const handleCancel = (): void => {
    resetForm()
    setIsEditDisabled(false)
  }

  const handleSave = async (): Promise<void> => {
    const valuesToSave = mapValuesToWholesalePricing(
      planId,
      storeId,
      values.wholesalePrices as MappedStorePlanPricingToWholesalePricing[],
    )
    try {
      await updateStorePlanPricing({
        storeId,
        data: valuesToSave,
        isBatchUpdate: true,
      }).unwrap()
      toastCompleted('Wholesale Pricing has been successfully updated')
      setIsEditDisabled(false)
    } catch {
      toastError('Something went wrong. Please try again.')
    }
  }

  /**
   * ensure that the wholesale price is updated for each row with the same SKU ID
   */
  const handleWholesaleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { id, value } = e.target
    const regex = /\[(\d+)\]/
    // extract the index of the row from the id
    const match = id.match(regex)?.[1]

    if (match) {
      const matchingRow = values?.wholesalePrices?.[Number(match)]
      const newValues = values?.wholesalePrices?.map((item) => {
        if (item.skuId === matchingRow?.skuId) {
          return {
            ...item,
            wholesalePrice: value,
          }
        }
        return item
      })
      setValues({
        wholesalePrices: newValues as MappedStorePlanPricingToWholesalePricing[],
      })
    }
    handleChange(e)
  }

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner color={COLOR.BLUE[800]} data-cy="loading-spinner" />
      </SpinnerContainer>
    )
  }

  return (
    <TableContainer data-cy="wholesale-pricing-table">
      {!!mappedStorePlanPricing?.length && (
        <>
          <ButtonContainer>
            {isEditEnabled ? (
              <SaveChangesButtonGroup
                id="wholesale-pricing-save-button-group"
                handleSave={handleSave}
                handleCancel={handleCancel}
                isSaveDisabled={!isValid || !dirty}
                isLoading={isUpdating}
              />
            ) : (
              <Button text="Edit" onClick={handleEditClick} />
            )}
          </ButtonContainer>

          {mappedStorePlanPricing?.map((item, index) => (
            <RowContainer
              key={`${item.skuId}-${item.priceBandLow}-${item.priceBandHigh}`}
              data-cy="wholesale-pricing-row"
            >
              <WholesalePricingRow
                rowData={item}
                isEditEnabled={isEditEnabled}
                isLabelVisible={index === 0}
                wholesalePrice={values?.wholesalePrices?.[index]?.wholesalePrice}
                rowIndex={index}
                handleChange={handleWholesaleChange}
                error={getFormikError(errors, index, 'wholesalePrice')}
              />
            </RowContainer>
          ))}
        </>
      )}
    </TableContainer>
  )
}

const RowContainer = styled.div({
  marginBottom: 16,
})

const TableContainer = styled.div({
  marginTop: 16,
})

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '24px 0',
})

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

export { WholesalePricingTable }
