import type { FormikProps, FieldMetaProps } from 'formik'

type Overrides<Values> = Pick<
  FormikProps<Values>,
  'values' | 'errors' | 'touched' | 'initialValues'
>

type Value = any

export function generateFormikProps<Values>(overrides: Overrides<Values>): FormikProps<Values> {
  return {
    ...overrides,
    initialErrors: {},
    initialTouched: {},
    dirty: false,
    handleReset: () => {},
    handleSubmit: () => {},
    submitForm: () => Promise.resolve(),
    isValid: false,
    getFieldHelpers: () => ({
      setValue: () => {},
      setTouched: () => {},
      setError: () => {},
    }),
    getFieldMeta: (value): FieldMetaProps<Value> => ({
      value,
      touched: false,
      initialTouched: false,
    }),
    getFieldProps: (name) => ({
      value: name,
      name,
      onChange: () => {},
      onBlur: () => {},
    }),
    handleBlur: () => {},
    handleChange: () => {},
    registerField: () => {},
    resetForm: () => {},
    setErrors: () => {},
    setFieldError: () => {},
    setFieldTouched: () => {},
    setFieldValue: () => {},
    setFormikState: () => {},
    setStatus: () => {},
    setSubmitting: () => {},
    setTouched: () => {},
    setValues: () => {},
    unregisterField: () => {},
    validateField: () => {},
    validateForm: (values) => values,
    isSubmitting: false,
    isValidating: false,
    submitCount: 0,
  }
}
