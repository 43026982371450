import type { FC, ChangeEvent } from 'react'
import React, { useRef } from 'react'
import { currency } from '@helloextend/client-utils'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/zen'
import { getBorderColor } from '../../utils/get-border-color'

enum CurrencyInputAlignment {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

interface CurrencyInputProps {
  disabled?: boolean
  name: string
  onChange?: (value?: number) => void
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  value: number | undefined
  showSymbol?: boolean
  currencyCode: string
  invalid?: boolean
  isGroup?: boolean
  label?: string
  alignment?: CurrencyInputAlignment
  'data-cy'?: string
  isZeroAllowed?: boolean
}

const CurrencyInput: FC<CurrencyInputProps> = ({
  'data-cy': dataCy,
  disabled = false,
  name,
  onChange,
  onBlur,
  placeholder,
  value,
  showSymbol = true,
  currencyCode,
  invalid,
  label,
  isGroup = true,
  alignment = CurrencyInputAlignment.Right,
  isZeroAllowed = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <>
      {!isGroup && label && <Label htmlFor={name}>{label}</Label>}
      <GroupInputField
        onChange={(e) => onChange && onChange(unformat(e.target.value, isZeroAllowed))}
        onBlur={onBlur}
        data-cy={dataCy}
        invalid={invalid}
        disabled={disabled}
        name={name}
        ref={inputRef}
        placeholder={disabled ? '' : placeholder}
        value={format(value, showSymbol, currencyCode)}
        isGroup={isGroup}
        alignment={alignment}
      />
    </>
  )
}

const format = (money: number | undefined, showSymbol: boolean, currencyCode: string): string => {
  const formatted = currency.format(money, currencyCode, '')
  return !showSymbol ? formatted.replace(/[^\d,.]+/g, '') : formatted
}

const unformat = (formatted: string | undefined, isZeroAllowed?: boolean): number | undefined => {
  if (typeof formatted !== 'string') return undefined
  const unformatted = Number(formatted.replace(/[^\d]+/g, ''))
  if (isZeroAllowed && unformatted === 0) {
    return 0
  }
  return unformatted === 0 ? undefined : unformatted
}

export { CurrencyInput, CurrencyInputAlignment, format, unformat }

const GroupInputField = styled.input<{
  isGroup?: boolean
  invalid?: boolean
  alignment: CurrencyInputAlignment
}>(({ isGroup, invalid, alignment }) => ({
  border: `1px solid ${getBorderColor(invalid)}`,
  borderRadius: 4,
  boxSizing: 'border-box',
  fontFamily: 'inherit',
  fontSize: 16,
  height: 40,
  padding: '0 16px',
  width: isGroup ? '75%' : '100%',
  marginRight: isGroup ? 0 : undefined,
  textAlign: alignment,
  '&::placeholder': {
    color: COLOR.NEUTRAL[600],
  },
  '&:disabled': {
    borderColor: COLOR.NEUTRAL[200],
    background: COLOR.NEUTRAL[100],
  },
  '&:focus': {
    border: `1px solid ${invalid ? COLOR.RED[700] : COLOR.BLUE[700]}`,
    outline: 'none',
  },
  borderColor: invalid ? COLOR.RED[700] : COLOR.NEUTRAL[400],
  borderTopRightRadius: isGroup ? 0 : 4,
  borderBottomRightRadius: isGroup ? 0 : 4,
}))

const Label = styled.label({
  display: 'block',
  color: `${COLOR.BLUE[1000]}`,
  fontFamily: 'Nunito Sans',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '18px',
  paddingBottom: 5,
})
