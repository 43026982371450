export type TableAccounts = {
  name: string
  accountId: string
  isLegacyAccount: boolean
  status?: AccountStatus | undefined
}

export enum AccountStatus {
  Approved = 'Approved',
  Unapproved = 'Unapproved',
  Suspended = 'Suspended',
  Deactivated = 'Deactivated',
}
