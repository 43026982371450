import type { FC } from 'react'
import React from 'react'
import { PageHeader } from '../../../components/page-header'
import { ServicersCreateForm } from './servicers-create-form'

const ServicersCreate: FC = () => {
  return (
    <>
      <PageHeader title="Create 3rd Party Servicer" />
      <ServicersCreateForm />
    </>
  )
}

export { ServicersCreate }
