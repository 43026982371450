import type {
  ServiceOrder,
  ServiceOrderShipment,
  ShipmentStatus,
} from '@helloextend/extend-api-client'
import { useListServiceOrderShipmentsQuery } from '@helloextend/extend-api-rtk-query'
import { OpenInNew } from '@helloextend/zen/src/tokens/icons'
import { Icon, IconSize } from '@helloextend/zen/src/components/icon/index'
import styled from '@emotion/styled'
import { AccordionSection, Stack, DataProperty, Button, COLOR } from '@helloextend/zen'
import { StackJustify } from '@helloextend/zen/src/components/stack'
import type { FC } from 'react'
import React, { useState, useMemo } from 'react'
import { formatDate, formatStartCase } from '../../../../../../../util'

const SHIP_ENGINE_DOWNLOAD_BASE_URL = 'https://api.shipengine.com/v1/downloads'
interface FailedProductShipmentSectionProps {
  serviceOrder: ServiceOrder
  isExpanded?: boolean
}

type MappedShipmentStatus = Extract<ShipmentStatus, 'received_by_carrier'>
const shipmentStatusMap: Record<MappedShipmentStatus, string> = {
  received_by_carrier: 'In Transit',
}

const FailedProductShipmentSection: FC<FailedProductShipmentSectionProps> = ({
  serviceOrder,
  isExpanded,
}) => {
  const [isExpandedInternal, setIsExpanded] = useState(isExpanded)

  const handleToggle = (): void => {
    setIsExpanded(!isExpandedInternal)
  }

  const { data: shipments } = useListServiceOrderShipmentsQuery(serviceOrder.id)

  const shipment = useMemo(() => {
    return (
      shipments?.find(({ destinationType }) => destinationType === 'servicer') ||
      ({} as ServiceOrderShipment)
    )
  }, [shipments])

  function getShipmentDeliveredDate(failedShipment?: ServiceOrderShipment): string {
    return failedShipment?.status === 'delivered'
      ? parseDate(failedShipment?.deliveredAt)
      : parseDate(failedShipment?.estimatedDeliveryAt)
  }

  function parseDate(date?: string): string {
    if (!date) return ''
    return formatDate(parseInt(date, 10))
  }

  const downloadLabel = serviceOrder?.shippingLabels?.find((l) => l.destinationType === 'servicer')
  const downloadLabelUrl = downloadLabel?.pdfDownloadUrl || downloadLabel?.imageData

  const handleDownloadShippingLabel = (serviceOrderDownload: ServiceOrder | undefined): void => {
    if (
      serviceOrderDownload?.configurations?.packingSlipRequired &&
      serviceOrderDownload.attachments?.packingSlip?.packingSlipUrl
    ) {
      window.open(
        serviceOrderDownload.attachments?.packingSlip?.packingSlipUrl,
        '_blank',
        'noopener,noreferrer',
      )
    }

    const labelForDestinationType = serviceOrderDownload?.shippingLabels?.find(
      (label) => label.destinationType === 'servicer',
    )

    // falls back to `imageData` if `pdfDownloadUrl` is not present, for backwards compatibility
    const downloadUrl =
      labelForDestinationType?.pdfDownloadUrl || labelForDestinationType?.imageData || ''
    if (!downloadUrl.startsWith(SHIP_ENGINE_DOWNLOAD_BASE_URL)) return

    window.open(downloadUrl, '_blank', 'noopener,noreferrer')
  }

  return (
    <AccordionSection
      heading="Failed Product Shipment"
      headingSize="sub"
      onToggleRequest={handleToggle}
      isExpanded={isExpandedInternal}
      data-cy={`${serviceOrder.id}-failed-product-shipment`}
    >
      <Stack justify={StackJustify.spaceBetween} data-cy={`${serviceOrder.id}-props`}>
        <DataProperty
          data-cy={`${serviceOrder.id}-status`}
          label="Status"
          value={
            shipmentStatusMap[shipment.status as MappedShipmentStatus] ??
            formatStartCase(shipment.status)
          }
        />
        <DataProperty
          data-cy={`${serviceOrder.id}-tracking-number`}
          label="Tracking Number"
          value={shipment.trackingNumber}
        />
        <DataProperty
          data-cy={`${serviceOrder.id}-ship-date`}
          label="Shipment Ship Date"
          value={formatDate(shipment.shippedAt)}
        />
        <DataProperty
          data-cy={`${serviceOrder.id}-delivered-date`}
          label={shipment.status === 'delivered' ? 'Delivered Date' : 'ETA Date'}
          value={getShipmentDeliveredDate(shipment)}
        />
        <StyledDataProperty data-cy={`${serviceOrder.id}-packing-slip`}>
          <Label>Packing Slip</Label>
          <ValueWrapper>
            {serviceOrder.attachments?.packingSlip?.packingSlipUrl ? (
              <StyledLink>
                <a
                  href={serviceOrder.attachments?.packingSlip?.packingSlipUrl ?? ''}
                  target="_blank"
                  rel="noreferrer"
                >
                  <DetailsStyle>
                    <u>View Details</u>
                    <Icon icon={OpenInNew} size={IconSize.xsmall} color={COLOR.BLUE[700]} />
                  </DetailsStyle>
                </a>
              </StyledLink>
            ) : (
              <Value>—</Value>
            )}
          </ValueWrapper>
        </StyledDataProperty>
      </Stack>
      {downloadLabelUrl && (
        <Stack>
          <Button
            data-cy={`${serviceOrder.id}-download-button`}
            text="Download Label"
            type="button"
            emphasis="medium"
            onClick={() => {
              handleDownloadShippingLabel(serviceOrder)
            }}
          />
        </Stack>
      )}
    </AccordionSection>
  )
}
const StyledDataProperty = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
})

const ValueWrapper = styled.div({
  display: 'flex',
  gap: 2,
  marginBottom: 16,
  alignItems: 'center',
})

const Value = styled.span({
  lineHeight: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const Label = styled.div({
  fontSize: 14,
  lineHeight: '22px',
  fontWeight: 800,
  color: COLOR.NEUTRAL[600],
})

const StyledLink = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textDecoration: 'none',
  color: COLOR.BLUE[700],
  'a, a:visited, a:hover, a:active': {
    color: 'inherit',
  },
})

const DetailsStyle = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 6,
})

export { FailedProductShipmentSection }
