import * as listActions from './list'
import * as resetActions from './reset'
import * as createActions from './create'
import * as errorActions from './error'

import type { ExtractActions } from '../../types/utility'

export type Action =
  | ExtractActions<typeof listActions>
  | ExtractActions<typeof resetActions>
  | ExtractActions<typeof createActions>
  | ExtractActions<typeof errorActions>

export type ActionType = Action['type']

const actions = {
  ...listActions,
  ...resetActions,
  ...createActions,
  ...errorActions,
}

export { actions }
