import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useHistory, useParams } from 'react-router'
import { useGetServicerQuery } from '@helloextend/extend-api-rtk-query'
import { PageError } from '@helloextend/merchants-ui'
import { DashboardSpinner } from '../../../components/dashboard-spinner'

interface WrapperProps {}

const ServicerPageWrapper: FC<React.PropsWithChildren<WrapperProps>> = ({ children }) => {
  const { servicerId } = useParams<{ servicerId: string }>()
  const history = useHistory()
  const { isError, isLoading } = useGetServicerQuery(servicerId)

  const handleBackClick = (): void => {
    history.push('/admin/servicers')
  }

  if (isLoading) return <DashboardSpinner data-cy="spinner" />

  return (
    <>
      {isError && (
        <ErrorWrapper>
          <PageError handleBackClick={handleBackClick} data-cy="page-error" />
        </ErrorWrapper>
      )}
      {!isError && children}
    </>
  )
}

const ErrorWrapper = styled.div({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 535,
  margin: '0 auto',
})

export { ServicerPageWrapper }
