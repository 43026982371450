const buildMultiValue = (obj: Record<string, unknown>): string => {
  const params = new URLSearchParams()

  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      value.forEach((v) => params.append(key, v))
    } else if (typeof value === 'string') {
      params.set(key, value)
    } else if (typeof value === 'boolean') {
      params.set(key, value ? 'true' : 'false')
    } else if (typeof value !== 'undefined') {
      params.set(key, `${value}`)
    }
  }

  return params.toString()
}

export { buildMultiValue }
