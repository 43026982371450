import { createApi } from '@reduxjs/toolkit/query/react'
import type { MerchantSupportRequest } from '@helloextend/extend-api-client'
import { baseQuery } from '../base-query'
import type { NetsuiteACHInformation } from './types'

export const supportApi = createApi({
  baseQuery,
  reducerPath: 'Support',
  tagTypes: ['support'],
  endpoints: (build) => ({
    createSupport: build.mutation<null, MerchantSupportRequest>({
      query: ({ shouldUseZenDesk = false, body }) => ({
        url: `/support`,
        body,
        method: 'POST',
        params: { shouldUseZenDesk },
      }),
    }),
    getNetsuiteACH: build.query<NetsuiteACHInformation, string>({
      query: (storeId) => ({
        url: `/support/netsuite/${storeId}`,
        headers: {
          'content-type': 'application/json',
        },
      }),
      providesTags: ['support'],
    }),
    updateNetsuiteACH: build.mutation<null, Partial<NetsuiteACHInformation>>({
      query: (body) => ({
        url: `/support/netsuite/${body.storeId}`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['support'],
    }),
  }),
})

export const { useCreateSupportMutation, useGetNetsuiteACHQuery, useUpdateNetsuiteACHMutation } =
  supportApi
