import type { Product } from '@helloextend/extend-api-client'
import type { ReducerState } from './reducers'
import { compKey } from './reducers/by-id'
import type { ErrorReducerState } from '../types/error'

export const getIsLoading = (state: ReducerState): boolean => {
  return state.isLoading
}
export const getIsUpdating = (state: ReducerState): boolean => {
  return state.isUpdating
}
export const getError = (state: ReducerState): ErrorReducerState => {
  return state.error
}
export const getAll = (state: ReducerState): Product[] => {
  return Object.values(state.byId)
}

export const getAllForStoreId = (state: ReducerState, storeId: string): Product[] => {
  return getAll(state).filter((p) => p.storeId === storeId)
}

export function getById(state: ReducerState, storeId: string, referenceId: string): Product | null {
  return state.byId[compKey(storeId, referenceId)]
}

export function getAllReferenceIds(state: ReducerState): string[] {
  return getAll(state).map((product) => product.referenceId)
}
