import type { User } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const register = (firstName: string, lastName: string, username: string, password: string) =>
  ({
    type: 'REGISTER',
    payload: { firstName, lastName, username, password },
  } as const)

export const registerRequest = () => ({ type: 'REGISTER_REQUEST' } as const)

export const registerSuccess = (user: User) =>
  ({
    type: 'REGISTER_SUCCESS',
    payload: user,
  } as const)

export const registerFailure = createActionFailure('REGISTER_FAILURE')
