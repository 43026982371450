import React from 'react'
import type { ColumnDefs, CellContext } from '@helloextend/zen'
import type { StorePlanMapping } from '@helloextend/extend-api-client'
import { AssignedPlansRowButtons } from './table-components/assigned-plan-row-buttons'
import type { MappedStorePlanMapping } from './table-components/types'

const columns: ColumnDefs<StorePlanMapping> = [
  {
    label: 'Plan ID',
    id: 'planId',
    renderCell: (cellData: CellContext<StorePlanMapping, string>) => (
      <a href={`/admin/plans/${cellData.getValue()}`} target="_blank" rel="noreferrer">
        {cellData.getValue()}
      </a>
    ),
  },
  {
    label: 'Plan Name',
    id: 'planName',
    renderCell: (cellData: CellContext<StorePlanMapping, string>) => cellData.getValue(),
  },
  {
    label: '',
    id: 'button',
    renderCell: (cellData: CellContext<StorePlanMapping, string>) => (
      <AssignedPlansRowButtons storePlanMapping={cellData.row.original as MappedStorePlanMapping} />
    ),
  },
]

export { columns }
