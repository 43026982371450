import { validateIsNumberEntered } from '@helloextend/client-utils/validate'
import * as Yup from 'yup'

const PHONE_NUMBER_MIN_LENGTH_USA = 10
const digits = /^[0-9]+$/
const locationNameError = 'Please enter a minimum of 3 characters'
const contactEmailError = 'Email is required'
const contactPhoneError = 'Valid phone number is required'
const zipcodeError = 'Valid zipcode is required'

const schema = Yup.object({
  adminServicerNumber: Yup.string().required().default('234DFSD'), // unused but required so value is arbitrary
  businessName: Yup.string().min(3, locationNameError).required(locationNameError).default(''),
  contact: Yup.object({
    name: Yup.string().required('Contact name is required').default(''),
    email: Yup.string().email(contactEmailError).required(contactEmailError).default(''),
    phone: Yup.string()
      .test('phone number validation', 'Please enter a valid phone number', (value) => {
        return !value || validateIsNumberEntered(value, PHONE_NUMBER_MIN_LENGTH_USA)
      })
      .required(contactPhoneError)
      .default(''),
  }).defined(),
  address: Yup.object({
    address1: Yup.string().required('Street address is required').default(''),
    address2: Yup.string().default(''),
    city: Yup.string().required('City is required').default(''),
    provinceCode: Yup.string().required('State is required').default(''),
    postalCode: Yup.string()
      .matches(digits, zipcodeError)
      .min(5, zipcodeError)
      .max(5, zipcodeError)
      .required(zipcodeError)
      .default(''),
    countryCode: Yup.string().required().default('US'),
  }).defined(),
}).defined()

type Values = Yup.InferType<typeof schema>

export { Values, schema }
