import {
  useGetPlanMappingsQuery,
  useGetStorePlanPricingListQuery,
  useGetStoreQuery,
  useGetPlanSetCategoriesQuery,
  useGetPlanSetMappingsQuery,
} from '@helloextend/extend-api-rtk-query'
import {
  Add,
  Button,
  Info,
  Error,
  InlineAlert,
  InlineAlertColor,
  Spinner,
  Edit,
  ModalController,
} from '@helloextend/zen'
import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Paragraph } from '@helloextend/zen/src/tokens/typography'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useToggle } from '@helloextend/client-hooks'
import { ProductProtectionInfo } from './components/product-protection-info'
import { SectionContainer } from '../../../../../../components/section-container'
import { ProductProtectionForm } from './components/product-protection-form'
import { TabMenu } from '../../../../../../components/tab-menu/tab-menu'
import { AssignedPlans } from './components/assigned-plans'
import { LDFlag } from '../../../../../../constants/ld-flags'
import { checkPlansForWholesalePricing } from '../../utils'
import { AddPlanSetModal } from './components/add-plan-set-modal'
import { PlanSetsDataTable } from './components/plan-sets-data-table/plan-sets-data-table'

type PlanAndPricingProps = {
  storeId: string
}

enum PlanAssignmentTabs {
  PlanSets = 'plan-sets',
  IndividualPlans = 'individual-plans',
}

const PlanAndPricing: FC<PlanAndPricingProps> = ({ storeId }) => {
  const [isEditEnabled, setIsEditEnabled] = useState(false)
  const [activeTab, setActiveTab] = useState<string>(PlanAssignmentTabs.PlanSets)
  const [isModalOpen, { on, off }] = useToggle(false)
  const { data, isLoading } = useGetStoreQuery({ storeId, version: 'latest' })
  const { data: planMappings, isLoading: isPlanMappingsLoading } = useGetPlanMappingsQuery({
    storeId,
  })
  const { data: storePlanPricingList } = useGetStorePlanPricingListQuery({
    storeId,
    version: 'latest',
  })
  const { data: planSetCategoryListResponse } = useGetPlanSetCategoriesQuery()
  const { data: getPlanSetMappingListResponse, isLoading: isLoadingPlanSetMappingList } =
    useGetPlanSetMappingsQuery({ storeId })

  const sortedCategories = useMemo(() => {
    if (!planSetCategoryListResponse) {
      return []
    }

    const categories = planSetCategoryListResponse.items.map((category) => ({
      display: category.name,
      value: category.name,
      id: category.id,
    }))

    return categories.sort((a, b) => a.display.localeCompare(b.display))
  }, [planSetCategoryListResponse])

  const {
    [LDFlag.StorePlanMapping]: FF_STORE_PLAN_MAPPING,
    [LDFlag.AssignedPlanSets]: FF_ASSIGNED_PLAN_SETS,
  } = useFlags()

  const showLegacyAssignedPlans = !FF_ASSIGNED_PLAN_SETS && FF_STORE_PLAN_MAPPING

  const menuTabs = [
    { text: 'Plan Sets', key: PlanAssignmentTabs.PlanSets },
    { text: 'Individual Plans', key: PlanAssignmentTabs.IndividualPlans },
  ]

  const handleTabClick = (tab: string): void => {
    setActiveTab(tab)
  }

  const handleCancel = (): void => {
    setIsEditEnabled(false)
  }

  const handleEditClick = (): void => {
    setIsEditEnabled(true)
  }

  const isWholesale = data?.merchantEwRevenueAgreement === 'wholesale'

  const plansWithoutWholesalePricing = useMemo(() => {
    if (!FF_STORE_PLAN_MAPPING || !planMappings?.items?.length) return []
    return checkPlansForWholesalePricing(planMappings?.items, storePlanPricingList?.items)
  }, [FF_STORE_PLAN_MAPPING, planMappings?.items, storePlanPricingList?.items])

  if (isLoading || isLoadingPlanSetMappingList) {
    return <Spinner data-cy="spinner" />
  }

  return (
    <>
      {isWholesale && planMappings?.items?.length === 0 && (
        <InlineAlertContainer>
          <InlineAlert
            icon={Info}
            color={InlineAlertColor.yellow}
            data-cy="wholesale-revenue-model-alert"
          >
            <Paragraph>
              For the Wholesale Revenue Model, it is required to map wholesale values under
              individual plans.
            </Paragraph>
          </InlineAlert>
        </InlineAlertContainer>
      )}
      {isWholesale && Boolean(plansWithoutWholesalePricing.length) && (
        <InlineAlertContainer>
          <InlineAlert
            icon={Error}
            color={InlineAlertColor.red}
            data-cy="plans-without-wholesale-pricing-alert"
          >
            <Paragraph>
              For the Wholesale Revenue Model, it is required to map wholesale values under
              individual plans. Go to Wholesale Pricing page to map wholesale values. Assigned plans
              with missing wholesale values:
            </Paragraph>
            <List>
              {plansWithoutWholesalePricing.map((plan) => (
                <li key={plan}>{plan}</li>
              ))}
            </List>
          </InlineAlert>
        </InlineAlertContainer>
      )}
      <SectionContainer
        data-cy="product-protection-settings-section"
        title="Product Protection Settings"
        {...(!isEditEnabled && {
          actionItem: (
            <Button text="Edit" emphasis="low" size="small" onClick={handleEditClick} icon={Edit} />
          ),
        })}
      >
        {!isEditEnabled && data && <ProductProtectionInfo store={data} />}
        {isEditEnabled && data && (
          <ProductProtectionForm store={data} handleCancel={handleCancel} />
        )}
      </SectionContainer>
      {FF_ASSIGNED_PLAN_SETS && (
        <SectionContainer data-cy="plan-assignment-section" title="Plan Assignment">
          <TabMenu tabs={menuTabs} onClick={handleTabClick} activeTab={activeTab} />
          {activeTab === PlanAssignmentTabs.PlanSets && (
            <>
              <HeaderContainer>
                <Button
                  text="Add Plan Set"
                  icon={Add}
                  emphasis="medium"
                  onClick={on}
                  data-cy="add-plan-set-button"
                />
              </HeaderContainer>
              <PlanSetsDataTable
                storeId={storeId}
                planSetMappingList={getPlanSetMappingListResponse?.items || []}
                categoryList={planSetCategoryListResponse?.items || []}
              />
            </>
          )}
          {activeTab === PlanAssignmentTabs.IndividualPlans && (
            <AssignedPlansContainer>
              {FF_STORE_PLAN_MAPPING && (
                <AssignedPlans
                  planMappings={planMappings?.items}
                  isLoading={isPlanMappingsLoading}
                  storeId={storeId}
                  isWholesale={isWholesale}
                />
              )}
            </AssignedPlansContainer>
          )}
        </SectionContainer>
      )}
      {showLegacyAssignedPlans && (
        <AssignedPlans
          planMappings={planMappings?.items}
          isLoading={isPlanMappingsLoading}
          storeId={storeId}
          isWholesale={isWholesale}
        />
      )}
      <ModalController isOpen={isModalOpen}>
        <AddPlanSetModal
          isModalOpen={isModalOpen}
          closeModal={off}
          storeId={storeId}
          categories={sortedCategories}
        />
      </ModalController>
    </>
  )
}

const InlineAlertContainer = styled.div({
  marginBottom: 16,
})
const List = styled.ul({
  margin: 0,
  paddingInlineStart: 24,
})

const AssignedPlansContainer = styled.div({
  padding: '40px 0',
})

const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '40px 0',
})

export { PlanAndPricing }
