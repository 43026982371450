import type { FC, ChangeEvent, FocusEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../../tokens/colors'
import type { FieldProps } from '../field'
import { Field } from '../field'

interface TextAreaProps extends FieldProps {
  placeholder?: string
  ariaLabel?: string
  isMonospace?: boolean
  isResizable?: boolean
  rows?: number
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void
  value: string
}

const TextArea: FC<TextAreaProps> = ({
  id,
  label,
  value,
  prefix,
  suffix,
  ariaLabel,
  icon,
  isMonospace = false,
  isDisabled = false,
  isError = false,
  errorFeedback,
  placeholder,
  subtext,
  maxLength,
  isCounterHiddenWhenValid,
  helperText,
  isResizable = true,
  actionButtonProps,
  rows = 2,
  onChange = () => {},
  onBlur = () => {},
  'data-cy': dataCy,
}: TextAreaProps) => {
  return (
    <Field
      id={id}
      label={label}
      value={value}
      prefix={prefix}
      suffix={suffix}
      icon={icon}
      isDisabled={isDisabled}
      isError={isError}
      errorFeedback={errorFeedback}
      subtext={subtext}
      maxLength={maxLength}
      helperText={helperText}
      isCounterHiddenWhenValid={isCounterHiddenWhenValid}
      actionButtonProps={actionButtonProps}
      data-cy={dataCy}
    >
      <StyledTextArea
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        aria-label={ariaLabel}
        id={id}
        isResizable={isResizable}
        disabled={isDisabled}
        placeholder={placeholder}
        isMonospace={isMonospace}
        rows={rows}
      />
    </Field>
  )
}

const StyledTextArea = styled.textarea<{
  isMonospace: boolean
  disabled: boolean
  isResizable: boolean
}>(({ isMonospace, disabled, isResizable }) => ({
  border: 'none',
  borderRadius: 4,
  color: disabled ? COLOR.NEUTRAL[600] : COLOR.NEUTRAL[1000],
  padding: '8px 12px',
  width: '100%',
  minHeight: 40,
  boxSizing: 'border-box',
  fontFamily: isMonospace ? 'monospace, monospace' : '"Nunito Sans", sans-serif',
  fontSize: 16,
  lineHeight: '24px',
  outline: 'none',
  background: 'none',
  resize: isResizable ? 'vertical' : 'none',
  '&::placeholder': {
    color: COLOR.NEUTRAL[600],
  },

  /* Override browser default margins */
  margin: 0,
}))

export { TextArea, TextAreaProps }
