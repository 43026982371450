import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useDataTableContext } from '../data-table-context'

export const Body: FC = ({ children }) => {
  const { 'data-cy': dataCy, isLoading } = useDataTableContext()

  return (
    <StyledBody data-cy={dataCy && `${dataCy}:body`} isSubdued={isLoading}>
      {children}
    </StyledBody>
  )
}

const StyledBody = styled.tbody<{
  isSubdued?: boolean
}>(({ isSubdued }) => ({
  fontSize: 14,
  '& > tr': {
    transition: '200ms',
    transitionProperty: 'opacity',
    opacity: isSubdued ? 0.5 : 1,
  },
}))
