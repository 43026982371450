import { COLOR } from '../../tokens/colors/color'
import type { ColorArgs, ButtonEmphasis } from './types'
import { ButtonTheme } from './types'

// Empahsis High
const emphasisHighBlue = {
  contentColor: COLOR.WHITE,
  backgroundColor: COLOR.BLUE[800],
  hoverBackgroundColor: COLOR.BLUE[900],
  activeBackgroundColor: COLOR.BLUE[800],
  disabledContentColor: COLOR.NEUTRAL[600],
  disabledBackgroundColor: COLOR.NEUTRAL[300],
}

const emphasisHighBlueInverted = {
  contentColor: COLOR.BLUE[800],
  backgroundColor: COLOR.BLUE[200],
  hoverBackgroundColor: COLOR.BLUE[100],
  activeBackgroundColor: COLOR.BLUE[200],
  disabledContentColor: COLOR.NEUTRAL[500],
  disabledBackgroundColor: COLOR.NEUTRAL.OPACITY[75],
}

const emphasisHighGreen = {
  contentColor: COLOR.WHITE,
  backgroundColor: COLOR.GREEN[700],
  hoverBackgroundColor: COLOR.GREEN[800],
  activeBackgroundColor: COLOR.GREEN[700],
  disabledContentColor: COLOR.NEUTRAL[600],
  disabledBackgroundColor: COLOR.NEUTRAL[300],
}

const emphasisHighGreenInverted = {
  contentColor: COLOR.GREEN[800],
  backgroundColor: COLOR.GREEN[200],
  hoverBackgroundColor: COLOR.GREEN[100],
  activeBackgroundColor: COLOR.GREEN[200],
  disabledContentColor: COLOR.NEUTRAL[500],
  disabledBackgroundColor: COLOR.NEUTRAL.OPACITY[75],
}

const emphasisHighNeutral = {
  contentColor: COLOR.WHITE,
  backgroundColor: COLOR.NEUTRAL[800],
  hoverBackgroundColor: COLOR.NEUTRAL[900],
  activeBackgroundColor: COLOR.NEUTRAL[800],
  disabledContentColor: COLOR.NEUTRAL[600],
  disabledBackgroundColor: COLOR.NEUTRAL[300],
}

const emphasisHighNeutralInverted = {
  contentColor: COLOR.NEUTRAL[800],
  backgroundColor: COLOR.NEUTRAL[100],
  hoverBackgroundColor: COLOR.WHITE,
  activeBackgroundColor: COLOR.NEUTRAL[100],
  disabledContentColor: COLOR.NEUTRAL[500],
  disabledBackgroundColor: COLOR.NEUTRAL.OPACITY[75],
}

const emphasisHighYellow = {
  contentColor: COLOR.NEUTRAL[1000],
  backgroundColor: COLOR.YELLOW[300],
  hoverBackgroundColor: COLOR.YELLOW[400],
  activeBackgroundColor: COLOR.YELLOW[300],
  disabledContentColor: COLOR.NEUTRAL[600],
  disabledBackgroundColor: COLOR.NEUTRAL[300],
}

const emphasisHighYellowInverted = {
  contentColor: COLOR.YELLOW[800],
  backgroundColor: COLOR.YELLOW[200],
  hoverBackgroundColor: COLOR.YELLOW[100],
  activeBackgroundColor: COLOR.YELLOW[200],
  disabledContentColor: COLOR.NEUTRAL[500],
  disabledBackgroundColor: COLOR.NEUTRAL.OPACITY[75],
}

const emphasisHighRed = {
  contentColor: COLOR.WHITE,
  backgroundColor: COLOR.RED[700],
  hoverBackgroundColor: COLOR.RED[800],
  activeBackgroundColor: COLOR.RED[700],
  disabledContentColor: COLOR.NEUTRAL[600],
  disabledBackgroundColor: COLOR.NEUTRAL[300],
}

const emphasisHighRedInverted = {
  contentColor: COLOR.RED[800],
  backgroundColor: COLOR.RED[200],
  hoverBackgroundColor: COLOR.RED[100],
  activeBackgroundColor: COLOR.RED[200],
  disabledContentColor: COLOR.NEUTRAL[500],
  disabledBackgroundColor: COLOR.NEUTRAL.OPACITY[75],
}

// Empahsis Medium
const emphasisMediumBlue = {
  contentColor: COLOR.BLUE[700],
  borderColor: COLOR.BLUE[700],
  hoverBackgroundColor: COLOR.BLUE.OPACITY[12],
  activeBackgroundColor: COLOR.BLUE.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
  disabledBorderColor: COLOR.NEUTRAL[400],
}

const emphasisMediumBlueInverted = {
  contentColor: COLOR.BLUE[400],
  borderColor: COLOR.BLUE[400],
  hoverBackgroundColor: COLOR.BLUE.OPACITY[12],
  activeBackgroundColor: COLOR.BLUE.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
  disabledBorderColor: COLOR.NEUTRAL[600],
}

const emphasisMediumGreen = {
  contentColor: COLOR.GREEN[700],
  borderColor: COLOR.GREEN[700],
  hoverBackgroundColor: COLOR.GREEN.OPACITY[12],
  activeBackgroundColor: COLOR.GREEN.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
  disabledBorderColor: COLOR.NEUTRAL[400],
}

const emphasisMediumGreenInverted = {
  contentColor: COLOR.GREEN[400],
  borderColor: COLOR.GREEN[400],
  hoverBackgroundColor: COLOR.GREEN.OPACITY[12],
  activeBackgroundColor: COLOR.GREEN.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
  disabledBorderColor: COLOR.NEUTRAL[600],
}

const emphasisMediumNeutral = {
  contentColor: COLOR.NEUTRAL[800],
  borderColor: COLOR.NEUTRAL[800],
  hoverBackgroundColor: COLOR.NEUTRAL.OPACITY[12],
  activeBackgroundColor: COLOR.NEUTRAL.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
  disabledBorderColor: COLOR.NEUTRAL[400],
}

const emphasisMediumNeutralInverted = {
  contentColor: COLOR.WHITE,
  borderColor: COLOR.WHITE,
  hoverBackgroundColor: COLOR.NEUTRAL.OPACITY[12],
  activeBackgroundColor: COLOR.NEUTRAL.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
  disabledBorderColor: COLOR.NEUTRAL[600],
}

const emphasisMediumYellow = {
  contentColor: COLOR.YELLOW[700],
  borderColor: COLOR.YELLOW[700],
  hoverBackgroundColor: COLOR.YELLOW.OPACITY[12],
  activeBackgroundColor: COLOR.YELLOW.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
  disabledBorderColor: COLOR.NEUTRAL[400],
}

const emphasisMediumYellowInverted = {
  contentColor: COLOR.YELLOW[400],
  borderColor: COLOR.YELLOW[400],
  hoverBackgroundColor: COLOR.YELLOW.OPACITY[12],
  activeBackgroundColor: COLOR.YELLOW.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
  disabledBorderColor: COLOR.NEUTRAL[600],
}

const emphasisMediumRed = {
  contentColor: COLOR.RED[700],
  borderColor: COLOR.RED[700],
  hoverBackgroundColor: COLOR.RED.OPACITY[12],
  activeBackgroundColor: COLOR.RED.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
  disabledBorderColor: COLOR.NEUTRAL[400],
}

const emphasisMediumRedInverted = {
  contentColor: COLOR.RED[400],
  borderColor: COLOR.RED[400],
  hoverBackgroundColor: COLOR.RED.OPACITY[12],
  activeBackgroundColor: COLOR.RED.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
  disabledBorderColor: COLOR.NEUTRAL[600],
}

// Empahsis Low
const emphasisLowBlue = {
  contentColor: COLOR.BLUE[700],
  hoverBackgroundColor: COLOR.BLUE.OPACITY[12],
  activeBackgroundColor: COLOR.BLUE.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
}

const emphasisLowBlueInverted = {
  contentColor: COLOR.BLUE[400],
  hoverBackgroundColor: COLOR.BLUE.OPACITY[12],
  activeBackgroundColor: COLOR.BLUE.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
}

const emphasisLowGreen = {
  contentColor: COLOR.GREEN[700],
  hoverBackgroundColor: COLOR.GREEN.OPACITY[12],
  activeBackgroundColor: COLOR.GREEN.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
}

const emphasisLowGreenInverted = {
  contentColor: COLOR.GREEN[400],
  hoverBackgroundColor: COLOR.GREEN.OPACITY[12],
  activeBackgroundColor: COLOR.GREEN.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
}

const emphasisLowNeutral = {
  contentColor: COLOR.NEUTRAL[800],
  hoverBackgroundColor: COLOR.NEUTRAL.OPACITY[12],
  activeBackgroundColor: COLOR.NEUTRAL.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
}

const emphasisLowNeutralInverted = {
  contentColor: COLOR.WHITE,
  hoverBackgroundColor: COLOR.NEUTRAL.OPACITY[12],
  activeBackgroundColor: COLOR.NEUTRAL.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
}

const emphasisLowYellow = {
  contentColor: COLOR.YELLOW[700],
  hoverBackgroundColor: COLOR.YELLOW.OPACITY[12],
  activeBackgroundColor: COLOR.YELLOW.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
}

const emphasisLowYellowInverted = {
  contentColor: COLOR.YELLOW[400],
  hoverBackgroundColor: COLOR.YELLOW.OPACITY[12],
  activeBackgroundColor: COLOR.YELLOW.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
}

const emphasisLowRed = {
  contentColor: COLOR.RED[700],
  hoverBackgroundColor: COLOR.RED.OPACITY[12],
  activeBackgroundColor: COLOR.RED.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
}

const emphasisLowRedInverted = {
  contentColor: COLOR.RED[400],
  hoverBackgroundColor: COLOR.RED.OPACITY[12],
  activeBackgroundColor: COLOR.RED.OPACITY[25],
  disabledContentColor: COLOR.NEUTRAL[500],
}

export const buttonColors: Record<ButtonEmphasis, Record<ButtonTheme, ColorArgs>> = {
  high: {
    [ButtonTheme.blue]: emphasisHighBlue,
    [ButtonTheme.blueInverted]: emphasisHighBlueInverted,
    [ButtonTheme.green]: emphasisHighGreen,
    [ButtonTheme.greenInverted]: emphasisHighGreenInverted,
    [ButtonTheme.neutral]: emphasisHighNeutral,
    [ButtonTheme.neutralInverted]: emphasisHighNeutralInverted,
    [ButtonTheme.yellow]: emphasisHighYellow,
    [ButtonTheme.yellowInverted]: emphasisHighYellowInverted,
    [ButtonTheme.red]: emphasisHighRed,
    [ButtonTheme.redInverted]: emphasisHighRedInverted,
  },
  medium: {
    [ButtonTheme.blue]: emphasisMediumBlue,
    [ButtonTheme.blueInverted]: emphasisMediumBlueInverted,
    [ButtonTheme.green]: emphasisMediumGreen,
    [ButtonTheme.greenInverted]: emphasisMediumGreenInverted,
    [ButtonTheme.neutral]: emphasisMediumNeutral,
    [ButtonTheme.neutralInverted]: emphasisMediumNeutralInverted,
    [ButtonTheme.yellow]: emphasisMediumYellow,
    [ButtonTheme.yellowInverted]: emphasisMediumYellowInverted,
    [ButtonTheme.red]: emphasisMediumRed,
    [ButtonTheme.redInverted]: emphasisMediumRedInverted,
  },
  low: {
    [ButtonTheme.blue]: emphasisLowBlue,
    [ButtonTheme.blueInverted]: emphasisLowBlueInverted,
    [ButtonTheme.green]: emphasisLowGreen,
    [ButtonTheme.greenInverted]: emphasisLowGreenInverted,
    [ButtonTheme.neutral]: emphasisLowNeutral,
    [ButtonTheme.neutralInverted]: emphasisLowNeutralInverted,
    [ButtonTheme.yellow]: emphasisLowYellow,
    [ButtonTheme.yellowInverted]: emphasisLowYellowInverted,
    [ButtonTheme.red]: emphasisLowRed,
    [ButtonTheme.redInverted]: emphasisLowRedInverted,
  },
}
