import * as updateActions from './update'
import type { ExtractActions } from '../../types/utility'

export type Action = ExtractActions<typeof updateActions>

export type ActionType = Action['type']

const actions = {
  ...updateActions,
}

export { actions }
