import { createApi } from '@reduxjs/toolkit/query/react'
import type { ProductCountsGetResponse, ProductsSearchPage } from '@helloextend/extend-api-client'
import { buildMultiValue } from '../utils/querystring'
import type { ProductsSearchArguments, ProductCountsArguments, ProductSearchCount } from './types'
import { baseQuery } from '../base-query'
import { safeBtoa } from '../../helpers'

const buildCacheKey = (args: ProductsSearchArguments): string => {
  const { offset, limit, ...qsWithoutPagination } = args
  return safeBtoa(JSON.stringify(qsWithoutPagination))
}

const productsRdbApi = createApi({
  baseQuery,
  reducerPath: 'ProductsRdb',
  tagTypes: ['ProductsRdb', 'ProductCountsRdb'],
  endpoints: (build) => ({
    searchProducts: build.query<ProductsSearchPage, ProductsSearchArguments>({
      query: ({ storeId, ...query }) => {
        const queryString = query ? buildMultiValue(query as Record<string, unknown>) : ''

        return {
          url: `/merchants-portal/stores/${storeId}/products/search?${queryString}`,
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest;',
          },
        }
      },
      providesTags: (_, _err, args) => {
        return [{ type: 'ProductsRdb', id: buildCacheKey(args) }]
      },
      async onCacheEntryAdded(arg, { getState, updateCachedData, cacheDataLoaded }) {
        const { offset, limit } = arg
        const cursor = offset && limit ? `${offset}-${limit}` : undefined

        if (!cursor) {
          return
        }

        const { ProductsRdb } = getState()
        const queryValues = ProductsRdb.queries

        const cacheKey = buildCacheKey(arg)
        const getCachedKeys = ProductsRdb.provided.ProductsRdb[cacheKey]

        const cachedQueries = getCachedKeys?.reduce(
          (newArr: ProductsSearchPage['products'], cachedDataKey) => {
            const cache = queryValues[cachedDataKey]
            const { products } = cache?.data as ProductsSearchPage
            return [...newArr, ...products]
          },
          [],
        )

        try {
          const { data } = await cacheDataLoaded

          updateCachedData((draft) => ({
            ...draft,
            products: [...cachedQueries, ...data.products],
          }))
        } catch (err) {
          console.error(err)
        }
      },
    }),
    // we need a separate function here since we can't pass in extra params to `onCacheEntryAdded`
    // passing in the `disableOnCacheEntryAdded` as a queryparam causes an error and we were using
    // this as a way to prevent adding to the same cache for products when doing pagination via limit/offset
    searchProductsPaginated: build.query<ProductsSearchPage, ProductsSearchArguments>({
      query: ({ storeId, ...query }) => {
        const queryString = query ? buildMultiValue(query as Record<string, unknown>) : ''

        return {
          url: `/merchants-portal/stores/${storeId}/products/search?${queryString}`,
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest;',
          },
        }
      },
      providesTags: (_, _err, args) => {
        return [{ type: 'ProductsRdb', id: buildCacheKey(args) }]
      },
    }),
    getSearchCount: build.query<ProductSearchCount, ProductsSearchArguments>({
      query: ({ storeId, ...query }) => {
        const queryString = query ? buildMultiValue(query as Record<string, unknown>) : ''

        return {
          url: `/merchants-portal/stores/${storeId}/products/search-count?${queryString}`,
          headers: {
            'content-type': 'application/json',
            accept: 'application/json; version=latest;',
          },
        }
      },
    }),
    getCounts: build.query<ProductCountsGetResponse, ProductCountsArguments>({
      query: ({ storeId, referenceId }) => ({
        url: `/merchants-portal/stores/${storeId}/products/${encodeURIComponent(
          referenceId,
        )}/counts`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_result, _err, { referenceId }) => [
        { type: 'ProductCountsRdb', id: referenceId },
      ],
    }),
  }),
})

const {
  useSearchProductsQuery,
  useSearchProductsPaginatedQuery,
  useGetCountsQuery,
  useGetSearchCountQuery,
} = productsRdbApi

export {
  productsRdbApi,
  useSearchProductsQuery,
  useGetCountsQuery,
  useGetSearchCountQuery,
  useSearchProductsPaginatedQuery,
}
