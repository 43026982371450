import type { ServiceOrderStartRepairRequest } from '@helloextend/extend-api-client/src/models/service-order'
import { createActionFailure } from '../../factories'

export const startRepair = (
  serviceOrderId: string,
  body: ServiceOrderStartRepairRequest,
  claimId: string,
  accessToken: string,
) =>
  ({
    type: 'SERVICE_ORDERS_START_REPAIR',
    payload: { serviceOrderId, body, claimId, accessToken },
  } as const)

export const startRepairRequest = () => ({ type: 'SERVICE_ORDERS_START_REPAIR_REQUEST' } as const)

export const startRepairSuccess = () =>
  ({
    type: 'SERVICE_ORDERS_START_REPAIR_SUCCESS',
  } as const)

export const startRepairFailure = createActionFailure('SERVICE_ORDERS_START_REPAIR_FAILURE')
