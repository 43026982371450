import type { SagaIterator } from 'redux-saga'
import { takeLatest, all } from 'redux-saga/effects'
import type { ActionType } from '../actions'
import { login } from './login'
import { forgotPassword } from './forgot-password'
import { resetPassword } from './reset-password'
import { register } from './register'
import { changePassword } from './change-password'

export default function* sagas(): SagaIterator {
  yield all([
    takeLatest('LOGIN' as ActionType, login),
    takeLatest('FORGOT_PASSWORD' as ActionType, forgotPassword),
    takeLatest('RESET_PASSWORD' as ActionType, resetPassword),
    takeLatest('REGISTER' as ActionType, register),
    takeLatest('CHANGE_PASSWORD' as ActionType, changePassword),
  ])
}
