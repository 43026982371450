import type { ContractsV2GetResponse } from '@helloextend/extend-api-client'
import type {
  ContractSearchKey,
  ContractType,
} from '@helloextend/extend-api-client/src/models/contract'
import { createActionFailure } from '../../factories'

export const search = (
  searchKey: ContractSearchKey,
  value: string,
  accessToken: string,
  typeFilter?: ContractType[],
  pageSize?: number,
) =>
  ({
    type: 'CONTRACTS_SEARCH',
    payload: { pageSize, typeFilter, searchKey, value, accessToken },
  } as const)

export const searchRequest = () => ({ type: 'CONTRACTS_SEARCH_REQUEST' } as const)

export const searchSuccess = (contracts: ContractsV2GetResponse[], pageSize?: number) =>
  ({
    type: 'CONTRACTS_SEARCH_SUCCESS',
    payload: contracts,
    pageSize,
  } as const)

export const searchFailure = createActionFailure('CONTRACTS_SEARCH_FAILURE')

export const nextPage = () =>
  ({
    type: 'CONTRACTS_NEXT_PAGE',
  } as const)

export const prevPage = () =>
  ({
    type: 'CONTRACTS_PREV_PAGE',
  } as const)

export const firstPage = () =>
  ({
    type: 'CONTRACTS_FIRST_PAGE',
  } as const)

export const lastPage = () =>
  ({
    type: 'CONTRACTS_LAST_PAGE',
  } as const)
