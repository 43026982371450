import type { Action } from 'redux'
import { runSaga } from 'redux-saga'
import type { Saga } from 'redux-saga'
import { merge } from 'lodash/fp'

export async function recordSaga<State>(
  saga: Saga,
  initialState: DeepPartial<State>,
  ...args: any[]
): Promise<Action[]> {
  const dispatched: Action[] = []

  runSaga(
    {
      dispatch: (action: Action) => dispatched.push(action),
      getState: () => merge({}, initialState),
    },
    saga,
    ...args,
  )
  // flush promises
  await (() => new Promise(setImmediate))()

  return dispatched
}
