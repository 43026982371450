import type {
  ColumnDef as ReactTableColumnDef,
  FilterFnOption,
  TableOptions,
  TableState,
} from '@tanstack/react-table'
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table'
import type { DataTableOptionProps, FilterDef, ColumnDefs } from './data-table-types'

export const buildTableOptions = <TData>({
  columnFilters,
  columnPinning,
  columns,
  columnVisibility,
  data,
  getRowId,
  globalFilter,
  hasManualFiltering,
  hasManualPagination,
  hasManualSorting,
  onColumnFiltersChange,
  onColumnPinningChange,
  onColumnVisibilityChange,
  onGlobalFilterChange,
  onPaginationChange,
  onSortingChange,
  pagination,
  rowCount,
  sorting,
}: DataTableOptionProps<TData>): TableOptions<TData> => {
  const tableOptions: TableOptions<TData> = {
    columns,
    data,
    enableSortingRemoval: false,
    globalFilterFn: 'includesString',
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId,
    initialState: {
      columnPinning: { left: columns.filter((c) => c.meta?.pin).map((c) => c.id || '') },
    },
  }

  // react-table internally checks if the options object contains a key instead of checking for undefined so we cannot
  // just pass our properties straight through. We need to only add them when defined, otherwise the built-in
  // funtionality of the table breaks.
  if (onColumnVisibilityChange) tableOptions.onColumnVisibilityChange = onColumnVisibilityChange

  if (onColumnPinningChange) tableOptions.onColumnPinningChange = onColumnPinningChange

  if (hasManualPagination && onPaginationChange) {
    tableOptions.onPaginationChange = onPaginationChange
    tableOptions.manualPagination = true
  } else {
    tableOptions.getPaginationRowModel = getPaginationRowModel()
    if (onPaginationChange) tableOptions.onPaginationChange = onPaginationChange
  }

  if (hasManualSorting && onSortingChange) {
    tableOptions.onSortingChange = onSortingChange
    tableOptions.manualSorting = true
  } else {
    tableOptions.getSortedRowModel = getSortedRowModel()
    if (onSortingChange) tableOptions.onSortingChange = onSortingChange
  }

  if (hasManualFiltering && onColumnFiltersChange) {
    tableOptions.onColumnFiltersChange = onColumnFiltersChange
    tableOptions.manualFiltering = true
  } else {
    tableOptions.getFilteredRowModel = getFilteredRowModel()
    if (onColumnFiltersChange) tableOptions.onColumnFiltersChange = onColumnFiltersChange
  }

  if (hasManualFiltering && onGlobalFilterChange) {
    tableOptions.onGlobalFilterChange = onGlobalFilterChange
    tableOptions.manualFiltering = true
  } else {
    tableOptions.getFilteredRowModel = getFilteredRowModel()
    if (onGlobalFilterChange) tableOptions.onGlobalFilterChange = onGlobalFilterChange
  }

  if (rowCount !== undefined && pagination)
    tableOptions.pageCount = Math.ceil(rowCount / pagination.pageSize)

  const tableState: Partial<TableState> = {}

  if (columnVisibility) tableState.columnVisibility = columnVisibility
  if (columnFilters) tableState.columnFilters = columnFilters
  if (columnFilters) tableState.globalFilter = globalFilter
  if (columnPinning) tableState.columnPinning = columnPinning
  if (pagination) tableState.pagination = pagination
  if (sorting) tableState.sorting = sorting

  tableOptions.state = tableState

  return tableOptions
}

export const findFilterDef = (id: string, filterDefs?: FilterDef[]): FilterDef | null => {
  if (filterDefs) {
    for (const filterDef of filterDefs) {
      switch (filterDef.type) {
        case 'group': {
          const def = findFilterDef(id, filterDef.filterDefs)
          if (def) return def
          break
        }
        default:
          if (filterDef.id === id) return filterDef
      }
    }
  }

  return null
}

export const buildColumnDefs = <TData>(
  columns: ColumnDefs<TData>,
  filterDefs?: FilterDef[],
): Array<ReactTableColumnDef<TData, any>> => {
  return columns.map((columnDef) => {
    const filterDef = findFilterDef(columnDef.id || '', filterDefs)

    const filterFns: Record<string, FilterFnOption<TData>> = {
      select: 'arrIncludesSome',
      numberRange: 'inNumberRange',
      dateRange: 'inNumberRange',
      string: 'includesString',
      currencyRange: 'inNumberRange',
    }
    const filterFn = filterFns[filterDef?.type || 'string']

    const column: ReactTableColumnDef<TData, any> = {
      id: columnDef.id,
      accessorKey: columnDef.id,
      accessorFn: columnDef.accessorFn,
      header: columnDef.label,
      ...(columnDef.renderCell && { cell: columnDef.renderCell }),
      meta: {
        hug: columnDef.isHugged,
        pin: columnDef.isAlwaysPinned || columnDef.isPinned,
        align: columnDef.align,
        information: columnDef.information,
        maxWidth: columnDef.maxWidth,
        search: columnDef.search,
        validateSearch: columnDef.validateSearch,
      },
      enableGlobalFilter: ['global', 'both'].includes(columnDef.search || ''),
      enablePinning: !columnDef.isAlwaysPinned,
      enableSorting: !!columnDef.isSortable,
      filterFn,
    }

    return column
  })
}
