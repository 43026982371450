import type { User } from '@helloextend/extend-api-client'
import type { Action } from '../actions'

export type ByAccountIdReducerState = Record<string, User>

export const initialState: ByAccountIdReducerState = {}

export default function byAccountId(state = initialState, action: Action): ByAccountIdReducerState {
  switch (action.type) {
    case 'USERS_FETCH_ALL_SUCCESS':
      return action.payload.reduce<ByAccountIdReducerState>((memo, user) => {
        return { ...memo, [user.accountId as string]: user }
      }, {})
    default:
      return state
  }
}
