import type { FC } from 'react'
import React, { useEffect } from 'react'
import { AdvancedSelect, Grid, Input } from '@helloextend/zen'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import { useCreateServicerLocationMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import type { Values } from './schema'
import { schema } from './schema'
import { SaveChangesButtonGroup } from '../save-changes-button-group'
import { listToAdvancedSelectOptions, states } from '../../../../../../../../utils/form-utils'

type AddLocationFormProps = {
  toggleFormVisibility: () => void
  servicerId: string
}

const AddLocationForm: FC<AddLocationFormProps> = ({ toggleFormVisibility, servicerId }) => {
  const [createLocation, { isLoading, isError, isSuccess }] = useCreateServicerLocationMutation()
  const { toastCompleted, toastError } = useStandardToast()

  const { values, errors, dirty, isValid, handleChange, handleBlur, handleSubmit, resetForm } =
    useFormik<Values>({
      initialValues: schema.default(),
      validationSchema: schema,
      validateOnChange: true,
      validateOnBlur: false,
      onSubmit: (formValues) => {
        createLocation({
          servicerId,
          body: formValues,
        })
      },
    })

  useEffect(() => {
    if (isSuccess) {
      toastCompleted('Location has been added successfully.')
      toggleFormVisibility()
    }
  }, [isSuccess, toastCompleted, toggleFormVisibility])

  useEffect(() => {
    if (isError) {
      toastError('Something went wrong. Please try again.')
    }
  }, [isError, toastError])

  const handleCancelClick = (): void => {
    resetForm()
    toggleFormVisibility()
  }
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid columns={2} spacing={2}>
          <Input
            id="businessName"
            label="Location Name"
            placeholder="Enter location name"
            value={values.businessName}
            isError={Boolean(errors.businessName)}
            errorFeedback={errors.businessName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Input
            id="contact.name"
            label="Contact Name"
            placeholder="Enter contact name"
            value={values.contact.name}
            isError={Boolean(errors.contact?.name)}
            errorFeedback={errors.contact?.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Input
            id="contact.email"
            label="Contact Email"
            placeholder="Enter contact email"
            value={values.contact.email}
            isError={Boolean(errors.contact?.email)}
            errorFeedback={errors.contact?.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Input
            id="contact.phone"
            label="Contact Phone Number"
            placeholder="Enter contact phone number"
            value={values.contact.phone}
            isError={Boolean(errors.contact?.phone)}
            errorFeedback={errors.contact?.phone}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <ContainerWithMargin>
          <Grid columns={6} spacing={2}>
            <Input
              id="address.address1"
              label="Street Address"
              placeholder="Enter street address"
              value={values.address.address1}
              isError={Boolean(errors.address?.address1)}
              errorFeedback={errors.address?.address1}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              id="address.address2"
              label="Suite, Dock #"
              placeholder="Enter suite, dock #"
              value={values.address.address2 ?? ''}
              isError={Boolean(errors.address?.address2)}
              errorFeedback={errors.address?.address2}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              id="address.city"
              label="City"
              placeholder="Enter city"
              value={values.address.city}
              isError={Boolean(errors.address?.city)}
              errorFeedback={errors.address?.city}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <AdvancedSelect
              id="address.provinceCode"
              label="State"
              placeholder="Select"
              showSearch
              multiple={false}
              value={values.address.provinceCode}
              isError={Boolean(errors.address?.provinceCode)}
              errorFeedback={errors.address?.provinceCode}
              onChange={handleChange}
              options={listToAdvancedSelectOptions(states)}
            />
            <Input
              id="address.postalCode"
              label="Zip Code"
              placeholder="Enter zip code"
              value={values.address.postalCode}
              isError={Boolean(errors.address?.postalCode)}
              errorFeedback={errors.address?.postalCode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              id="address.countryCode"
              label="Country Code"
              placeholder="Enter country code"
              data-cy="countryCode"
              isDisabled
              value={values.address.countryCode}
              isError={Boolean(errors.address?.countryCode)}
              errorFeedback={errors.address?.countryCode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </ContainerWithMargin>
        <ContainerWithMargin>
          <SaveChangesButtonGroup
            id="location-group"
            handleSave={handleSubmit}
            handleCancel={handleCancelClick}
            isSaveDisabled={!dirty || !isValid}
            isLoading={isLoading}
          />
        </ContainerWithMargin>
      </form>
    </>
  )
}

const ContainerWithMargin = styled.div({
  marginTop: 16,
})

export { AddLocationForm }
