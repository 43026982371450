import { useFetchAllThemesQuery } from '@helloextend/extend-api-rtk-query'

import type { ThemeMinimal } from '@helloextend/extend-api-rtk-query'

export type MockUseFetchAllThemesQueryProps = {
  themes: ThemeMinimal[]
  isLoading?: boolean
  isFetching?: boolean
  isSuccess?: boolean
}

export const mockUseFetchAllThemesQuery = ({
  themes,
  isLoading,
  isFetching,
  isSuccess,
}: MockUseFetchAllThemesQueryProps): void => {
  ;(useFetchAllThemesQuery as jest.Mock).mockReturnValue({
    data: { themes },
    isLoading,
    isFetching,
    isSuccess,
  })
}
