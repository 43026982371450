import { buttonColors } from './button-colors'
import { buttonPadding } from './button-padding'
import { buttonMeasurements } from './button-measurements'
import type {
  ColorArgs,
  ButtonEmphasis,
  ButtonIconPosition,
  ButtonSize,
  MeasurementArgs,
  ButtonColor,
} from './types'
import { ButtonTheme, ButtonLayout } from './types'

export const getMeasurements = (size: ButtonSize): MeasurementArgs => {
  return buttonMeasurements[size]
}

/* Get padding style based on if text or an icon is provided */
const getLayout = (
  hasText: boolean,
  hasReducedPadding: boolean,
  hasGraphic: boolean,
  iconPosition: ButtonIconPosition,
): ButtonLayout => {
  if (!hasText && hasGraphic) {
    return ButtonLayout.graphicOnly
  }

  if (hasText && hasGraphic) {
    if (iconPosition === 'left') {
      return ButtonLayout.textWithGraphicLeft
    }
    return ButtonLayout.textWithGraphicRight
  }

  if (hasReducedPadding) {
    return ButtonLayout.textOnlyReducedPadding
  }

  return ButtonLayout.textOnly
}

export const getPadding = (
  size: ButtonSize,
  hasText: boolean,
  hasIcon: boolean,
  isProcessing: boolean,
  iconPosition: ButtonIconPosition,
  hasReducedPadding: boolean,
): string => {
  const hasGraphic = isProcessing || hasIcon
  const content = getLayout(hasText, hasReducedPadding, hasGraphic, iconPosition)
  return buttonPadding[size][content]
}

/* Get color styles based on emphasis level, default/danger, and default/inverted */
const getType = (style: ButtonColor, isInverted: boolean): ButtonTheme => {
  switch (style) {
    case 'red':
      if (isInverted) {
        return ButtonTheme.redInverted
      }
      return ButtonTheme.red

      break
    case 'yellow':
      if (isInverted) {
        return ButtonTheme.yellowInverted
      }
      return ButtonTheme.yellow

      break
    case 'neutral':
      if (isInverted) {
        return ButtonTheme.neutralInverted
      }
      return ButtonTheme.neutral

      break
    case 'green':
      if (isInverted) {
        return ButtonTheme.greenInverted
      }
      return ButtonTheme.green

      break
    default:
      if (isInverted) {
        return ButtonTheme.blueInverted
      }
      return ButtonTheme.blue

      break
  }
}

export const getColors = (
  style: ButtonColor,
  isInverted: boolean,
  emphasis: ButtonEmphasis,
): ColorArgs => {
  const type = getType(style, isInverted)
  return buttonColors[emphasis][type]
}
