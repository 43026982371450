import type {
  FC,
  ChangeEvent,
  MouseEvent,
  RefObject,
  SyntheticEvent,
  MouseEventHandler,
} from 'react'
import React, { useState, useMemo, useRef, createRef } from 'react'
import styled from '@emotion/styled'
import {
  Button,
  COLOR,
  Input,
  InputType,
  Spinner,
  DataProperty,
  usePopover,
  Popover,
  PopoverAlignment,
  MenuButtonItem,
} from '@helloextend/zen'
import { ArrowBack, Search, Visibility, More, Edit } from '@helloextend/zen/src/tokens/icons'
import { useListThreadsQuery, useLazyGetThreadQuery } from '@helloextend/extend-api-rtk-query'
import { date as datefmt } from '@helloextend/client-utils'
import { batch, useDispatch, useSelector } from 'react-redux'
import {
  addThreadToCurrentConversation,
  setIsEditorPanelVisible,
  setThread,
  resetThread,
  setIsReusableThreadPickerVisible,
  setSelectedThreadIdToReplace,
  removeThreadFromCurrentConversation,
  setPlaceholderMessageBlock,
  setSelectedMessageBlock,
} from '../../../../../store/slices/amp-slice'
import type { RootState } from '../../../../../reducers'
import {
  getSelectedThread,
  getReusableThreadPickerVisibility,
  getSelectedConversation,
} from '../../../../../reducers/selectors'
import { formatDateWithTimezone } from '../../../../../utils/date-formatting'

type ConversationEditorThreadPanelProps = {
  onClickEditThread?: MouseEventHandler<HTMLButtonElement> | undefined
}

const ConversationEditorThreadPanel: FC<ConversationEditorThreadPanelProps> = ({
  onClickEditThread,
}) => {
  const dispatch = useDispatch()
  const [searchString, setSearchString] = useState('')
  const [hoveredThread, setHoveredThread] = useState<Node | null>(null)
  const { data: threadList, isLoading } = useListThreadsQuery()
  const { formatToMilliseconds } = datefmt

  const publishedThreads = useMemo(() => {
    if (threadList) {
      return threadList.filter((thread) => thread.status !== 'draft')
    }
    return []
  }, [threadList])

  const hoveredThreadId = useRef<string>('')
  const threadRefs = useRef<Array<RefObject<Node | null>>>([])

  const selectedThread = useSelector((state: RootState) => getSelectedThread(state))

  const activeConversation = useSelector((state: RootState) => getSelectedConversation(state))

  const activeThreadIds = useMemo(() => {
    return (
      activeConversation && activeConversation.threads.map((thread) => thread.split(':').shift())
    )
  }, [activeConversation])

  const filteredThreads = useMemo(
    () =>
      publishedThreads
        .filter((thread) => thread.title.trim().toLowerCase().includes(searchString.toLowerCase()))
        .filter((thread) => activeThreadIds && !activeThreadIds.includes(thread.id)),
    [activeThreadIds, publishedThreads, searchString],
  )

  const isReusableThreadPickerVisible = useSelector((state: RootState) =>
    getReusableThreadPickerVisibility(state),
  )
  const [fetchThread] = useLazyGetThreadQuery()

  if (threadRefs.current.length !== publishedThreads.length) {
    threadRefs.current = publishedThreads.map(() => createRef<Node | null>())
  }

  const { isPresent, triggerRef, popoverRef, triggerBoundingBox, toggle } =
    usePopover<HTMLButtonElement>()

  const handleMouseOver = (e: MouseEvent<HTMLButtonElement>): void => {
    setHoveredThread(e.target as Node)
  }

  const handleMouseLeave = (): void => {
    setHoveredThread(null)
  }

  const handleClickArrowBack = (): void => {
    if (selectedThread && selectedThread.type !== 'single_use') {
      batch(() => {
        dispatch(setIsReusableThreadPickerVisible(false))
        dispatch(setSelectedThreadIdToReplace(null))
      })
      return
    }

    if (selectedThread && selectedThread.type === 'single_use') {
      const lastMessageBlockIndex = selectedThread.script.length - 1
      batch(() => {
        dispatch(setIsReusableThreadPickerVisible(false))
        dispatch(setPlaceholderMessageBlock(null))
        dispatch(setSelectedMessageBlock(lastMessageBlockIndex))
      })
      return
    }

    batch(() => {
      dispatch(setPlaceholderMessageBlock(null))
      dispatch(setIsEditorPanelVisible(false))
      dispatch(setIsReusableThreadPickerVisible(false))
      dispatch(setSelectedThreadIdToReplace(null))
    })
  }

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchString(e.target.value)
  }

  const handleClickThreadPreview = (e: SyntheticEvent): void => {
    e.stopPropagation()
    window.open(
      `/admin/adjudication-management/threads/${hoveredThreadId.current}/preview`,
      '_blank',
    )
  }

  const handleAddThread = async (threadId: string, threadType: string): Promise<void> => {
    batch(() => {
      dispatch(setPlaceholderMessageBlock(null))
      dispatch(setIsReusableThreadPickerVisible(false))
      dispatch(addThreadToCurrentConversation({ threadId, threadType }))
      dispatch(setSelectedThreadIdToReplace(null))
    })

    const threadData = await fetchThread(threadId).unwrap()

    batch(() => {
      dispatch(setThread(threadData))
    })
  }

  const handleClickThreadDetailEllipsis = (): void => {
    toggle()
  }

  const handleSelectAnotherThread = (): void => {
    if (selectedThread) {
      batch(() => {
        dispatch(setPlaceholderMessageBlock(null))
        dispatch(setSelectedThreadIdToReplace(selectedThread.id))
        dispatch(setIsReusableThreadPickerVisible(true))
      })
      toggle()
    }
  }

  const handleRemoveThread = (): void => {
    if (selectedThread) {
      batch(() => {
        toggle()
        dispatch(removeThreadFromCurrentConversation(selectedThread.id))
        dispatch(resetThread())
        dispatch(setIsEditorPanelVisible(false))
        dispatch(setIsReusableThreadPickerVisible(false))
      })
    }
  }

  return (
    <Container>
      {selectedThread && !isReusableThreadPickerVisible ? (
        <>
          <ThreadHeader data-cy="reusable-thread-info-pane">
            <Heading>THREAD</Heading>
            <ThreadHeaderOptions>
              <Button
                emphasis="low"
                icon={Edit}
                onClick={onClickEditThread}
                data-cy="thread-information-edit"
                isDisabled={!selectedThread}
                tooltip="Edit thread"
                color="neutral"
              />
              <Button
                emphasis="low"
                icon={More}
                ref={triggerRef}
                onClick={handleClickThreadDetailEllipsis}
                data-cy="thread-information-ellipsis"
                isDisabled={!selectedThread}
              />
              <Popover
                ref={popoverRef}
                isPresent={isPresent}
                triggerBoundingBox={triggerBoundingBox}
                alignment={PopoverAlignment.end}
              >
                <MenuItemWrapper>
                  <MenuButtonItem
                    onClick={handleSelectAnotherThread}
                    data-cy="reselect-thread-popover-item"
                  >
                    <Text>Select another</Text>
                  </MenuButtonItem>
                  <MenuButtonItem onClick={handleRemoveThread} data-cy="delete-thread-popover-item">
                    <Text>Remove</Text>
                  </MenuButtonItem>
                </MenuItemWrapper>
              </Popover>
            </ThreadHeaderOptions>
          </ThreadHeader>
          <ThreadInfoContainer data-cy="thread-information-container">
            <DataProperty label="Name" value={selectedThread.title} />
            <DataProperty label="Type" value={selectedThread.type} />
            <DataProperty
              label="Last Updated"
              value={`${formatDateWithTimezone(
                formatToMilliseconds(selectedThread.updatedAt),
              )} by ${selectedThread.editedBy}`}
            />
          </ThreadInfoContainer>
        </>
      ) : (
        <>
          <HeadingContainer data-cy="reusable-thread-picker">
            <Button
              icon={ArrowBack}
              emphasis="low"
              color="neutral"
              onClick={handleClickArrowBack}
              data-cy="conversation-editor-arrowback-icon-btn"
            />
            <Heading>SELECT A PUBLISHED THREAD</Heading>
          </HeadingContainer>
          <InputContainer>
            <Input
              id="thread-search"
              icon={Search}
              type={InputType.search}
              placeholder="Search"
              onChange={handleSearchInputChange}
              value={searchString}
            />
          </InputContainer>
          {isLoading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <ThreadListContainer>
              {filteredThreads.map((thread, i) => {
                const hovered =
                  threadRefs.current[i]?.current &&
                  (threadRefs.current[i].current as Node).contains(hoveredThread)

                if (hovered) {
                  hoveredThreadId.current = thread.id
                }
                return (
                  <ThreadList
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseLeave}
                    key={thread.id}
                    ref={threadRefs.current[i] as RefObject<HTMLButtonElement>}
                    data-cy={`conversation-editor-thread-list-item-${thread.title}`}
                    onClick={() => handleAddThread(thread.id, thread.type)}
                  >
                    <ThreadInfo>
                      <ThreadName>{thread.title}</ThreadName>
                      {hovered && (
                        <Button
                          icon={Visibility}
                          emphasis="low"
                          color="neutral"
                          onClick={handleClickThreadPreview}
                          data-cy="conversation-editor-thread-preview-btn"
                        />
                      )}
                    </ThreadInfo>
                  </ThreadList>
                )
              })}
            </ThreadListContainer>
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div({
  paddingLeft: '32px',
})

const HeadingContainer = styled.div({
  padding: '32px 32px 24px 12px',
  display: 'flex',
  alignItems: 'center',
  gap: 24,
})

const Heading = styled.p({
  margin: 0,
  fontWeight: 700,
  fontSize: 14,
  lineHeight: '18px',
  color: COLOR.NEUTRAL[1000],
})

const MenuItemWrapper = styled.div({
  width: 184,
  display: 'flex',
  flexDirection: 'column',
  padding: 8,
})

const Text = styled.p({
  fontWeight: 400,
  size: 14,
  lineHeight: '20px',
  padding: 0,
  margin: 0,
})

const InputContainer = styled.div({
  margin: '0 32px 24px 0',
})

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  padding: 24,
})

const ThreadInfo = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const ThreadName = styled.span({
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '40px',
  color: COLOR.NEUTRAL[1000],
  margin: 0,
})

const ThreadListContainer = styled.div({
  paddingRight: 17,
  marginRight: 4,
  overflowX: 'hidden',
  overflowY: 'scroll',
  // Firefox
  scrollbarColor: `${COLOR.NEUTRAL[600]} ${COLOR.WHITE}`,
  scrollbarWidth: 'auto',
  // Chrome, Safari, Edge & Opera
  '&::-webkit-scrollbar': {
    width: 11,
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: 8,
    backgroundColor: COLOR.NEUTRAL[600],
  },
})

const ThreadInfoContainer = styled.div({
  paddingRight: 17,
  marginRight: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
})

const ThreadHeader = styled.div({
  padding: '32px 32px 24px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const ThreadHeaderOptions = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

const ThreadList = styled.span({
  padding: '14px 20px',
  display: 'block',
  maxWidth: 440,
  textAlign: 'left',
  background: 'none',
  border: `1px solid ${COLOR.NEUTRAL[400]}`,
  borderBottom: 'none',
  '&:last-child': {
    borderBottom: `1px solid ${COLOR.NEUTRAL[400]}`,
  },
  '&:hover': {
    cursor: 'pointer',
    background: COLOR.NEUTRAL.OPACITY[12],
  },
})

export { ConversationEditorThreadPanel }
