import * as Yup from 'yup'

const revenueModelOptions = [
  {
    display: 'Net Revenue Share',
    value: 'net_revenue_share',
  },
  {
    display: 'Gross Revenue Share',
    value: 'gross_revenue_share',
  },
  {
    display: 'Wholesale',
    value: 'wholesale',
  },
]

const revenueSchema = Yup.object()
  .shape({
    merchantEwRevenueAgreement: Yup.string()
      .required('Revenue model is required')
      .default(null)
      .nullable(true),
    merchantEwRevenueSharePercentage: Yup.number()
      .default(undefined)
      .when('merchantEwRevenueAgreement', {
        is: (value) => value === 'wholesale',
        then: Yup.number().notRequired(),
        otherwise: Yup.number()
          .required('Merchant revenue share is required')
          .min(0, 'Merchant revenue share must be greater than 0')
          .max(100, 'Merchant revenue share must be equal or less than 100'),
      }),
  })
  .defined()

const partialReimbursementSchema = Yup.object()
  .shape({
    partialReimbursementPercentage: Yup.number()
      .required('Partial reimbursement percentage is required')
      .min(0, 'Partial reimbursement percentage must be greater than 0')
      .max(100, 'Partial reimbursement percentage must be equal or less than 100'),
  })
  .defined()

const customExtendCutSchema = Yup.object()
  .shape({
    extendCut: Yup.number()
      .required('Extend cut is required')
      .min(0, 'Custom Extend cut must be greater than 0')
      .max(100, 'Custom Extend cut must be equal or less than 100'),
  })
  .defined()

type RevenueValues = Yup.InferType<typeof revenueSchema>

export {
  RevenueValues,
  revenueSchema,
  revenueModelOptions,
  partialReimbursementSchema,
  customExtendCutSchema,
}
