import { isRegExp } from 'lodash/fp'

export type BlackList = Array<string | RegExp>

export const BLACKLIST: BlackList = [
  /authorization/i,
  /password/i,
  /access-?token/i,
  /provider_?/i,
  /email/i,
  /(first|last|full)[_-]?name/i,
  /phone/i,
  /address?/i,
  /browser_ip/i,
  /city/i,
  /zip/i,
  /province/i,
  /country/i,
  /company/i,
  /latitude/i,
  /longitude/i,
]

export function isBlacklisted(key: string, blacklist: BlackList): boolean {
  for (const blacklistItem of blacklist) {
    if (isRegExp(blacklistItem) && blacklistItem.test(key)) {
      return true
    }
    if (blacklistItem === key) {
      return true
    }
  }
  return false
}
