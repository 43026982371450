import type { FC } from 'react'
import React, { useState } from 'react'
import type { AdvancedSelectChangeEvent } from '@helloextend/zen'
import { Button, AdvancedSelect } from '@helloextend/zen'
import { Trash } from '@helloextend/zen/src/tokens/icons'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import type { ScriptItem, DefaultReply, Reply } from '@helloextend/extend-api-rtk-query'
import { replaceMessageBlockInThread } from '../../../../../store/slices/amp-slice'
import { MessageBubble } from '../message-bubble'
import { MessageBlockTypes } from '../../utils'
import { getEmptyBlockByType } from '../../message-blocks/utils'
import type { RootState } from '../../../../../reducers'
import { getSelectedThreadMessageBlock } from '../../../../../reducers/selectors'
import { ConfirmationModal } from '../confirmation-modal'

type MessageTypeDropdownProps = {
  index: number
  value: string
  isDisabled?: boolean
  handleDeleteButtonClick: () => void
}

const MessageTypeDropdown: FC<MessageTypeDropdownProps> = ({
  index,
  value,
  isDisabled = false,
  handleDeleteButtonClick,
}) => {
  const dispatch = useDispatch()

  const [isConfirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false)
  const [newMessageBlockType, setNewMessageBlockType] = useState<MessageBlockTypes>()

  const selectedMessageBlock = useSelector((state: RootState) =>
    getSelectedThreadMessageBlock(state),
  )
  const options = Object.values(MessageBlockTypes).map((messageType) => ({
    value: messageType,
    display: messageType,
  }))

  // onchange handler for advanced select
  const handleMessageTypeChange = (changeEvent: AdvancedSelectChangeEvent): void => {
    // check if the selected message block is the same as the new message block type
    if (changeEvent.target.value === value) return
    setNewMessageBlockType(changeEvent.target.value as MessageBlockTypes)
    setConfirmationModalVisible(true)
  }

  const handleConfirmMessageTypeUpdate = async (): Promise<void> => {
    setConfirmationModalVisible(false)
    const block = getEmptyBlockByType(newMessageBlockType as MessageBlockTypes) as ScriptItem
    // update new block with existing selected message block data
    const selectedMessageBlockReply: Reply | undefined = selectedMessageBlock?.script.reply
    if ((selectedMessageBlockReply as DefaultReply)?.messages) {
      block.reply = {
        ...block.reply,
        messages: (selectedMessageBlockReply as DefaultReply).messages,
      }
    }

    // replace message block in thread
    dispatch(replaceMessageBlockInThread(block as ScriptItem))
  }

  const handleCancelMessageTypeUpdate = (): void => {
    setConfirmationModalVisible(false)
  }
  return (
    <Container>
      <ConfirmationModal
        isVisible={isConfirmationModalVisible}
        onCancel={handleCancelMessageTypeUpdate}
        onConfirm={handleConfirmMessageTypeUpdate}
        confirmButtonColor="blue"
        confirmButtonText="Update"
        heading="Update message block type?"
        description="This can not be undone and some content might be lost. Are you sure you want to update
        message block type?"
        isProcessing={false}
        data-cy="amp-update-message-block-type-modal"
        size="sm"
      />
      <MessageBubble isActive data-cy="message-type-index">
        {index + 1}
      </MessageBubble>
      <DropdownWrapper>
        <AdvancedSelect
          data-cy="message-type-dropdown"
          id="selectorValue"
          value={value}
          onChange={handleMessageTypeChange}
          multiple={false}
          options={options}
          maxQuantityToDisplay={8}
          badgePosition="start"
          isDisabled={isDisabled}
          isNotClearable
        />
      </DropdownWrapper>
      <Button
        data-cy="message-type-delete-button"
        emphasis="low"
        color="neutral"
        icon={Trash}
        onClick={handleDeleteButtonClick}
        isDisabled={isDisabled}
      />
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '32px 0',
  gap: 20,
})

const DropdownWrapper = styled.div({
  flex: 1,
})

export { MessageTypeDropdown }
