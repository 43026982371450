import type { Claim } from '@helloextend/extend-api-client'
import type { ClaimUpdateRequest } from '@helloextend/extend-api-client/src/models/claim'
import { createActionFailure } from '../../factories'

export const update = (
  contractId: string,
  claimId: string,
  accessToken: string,
  data: ClaimUpdateRequest,
) =>
  ({
    type: 'CLAIMS_UPDATE',
    payload: { contractId, claimId, accessToken, data },
  } as const)

export const updateRequest = () => ({ type: 'CLAIMS_UPDATE_REQUEST' } as const)

export const updateSuccess = (claim: Claim) =>
  ({
    type: 'CLAIMS_UPDATE_SUCCESS',
    payload: claim,
  } as const)

export const updateFailure = createActionFailure('CLAIMS_UPDATE_FAILURE')
