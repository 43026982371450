import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/zen'
import { TableCell } from '@helloextend/merchants-ui'

interface EmptyTableMessageProps {
  cols: number
  message: string
}

/**
 * @deprecated Use Zen DataTable component instead: `import { DataTable } from '@helloextend/zen'`
 */
const EmptyTableMessage: FC<EmptyTableMessageProps> = ({ cols, message }) => {
  return (
    <EmptyRow>
      <TableCell colspan={cols}>
        <RowText>{message}</RowText>
      </TableCell>
    </EmptyRow>
  )
}

const EmptyRow = styled.tr({
  borderRight: 0,
  borderLeft: 0,
  borderTop: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderBottom: `1px solid ${COLOR.NEUTRAL[300]}`,
  backgroundColor: 'transparent',
})

const RowText = styled.p({
  fontSize: 13,
  lineHeight: '24px',
  color: COLOR.NEUTRAL[800],
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textAlign: 'center',
  marginTop: 8,
  marginBottom: 8,
})

export { EmptyTableMessage, EmptyTableMessageProps }
