import type { PlanAttribute } from '@helloextend/extend-api-client'
import type { TablePlanAttribute } from '../../../types/plans'

export const toTablePlanAttributeItem = (value: string): TablePlanAttribute => ({
  id: value,
})

export const toTablePlanAttributeItems = (planAttribute?: PlanAttribute): TablePlanAttribute[] => {
  if (!planAttribute) return []
  return planAttribute.values.map(toTablePlanAttributeItem)
}
