import type { FC } from 'react'
import React from 'react'
import type { ServicerLocation } from '@helloextend/extend-api-client'
import { DataProperty, Grid } from '@helloextend/zen'

type ServicingLocationProps = {
  location: ServicerLocation
}

const ServicingLocation: FC<ServicingLocationProps> = ({ location }) => {
  return (
    <>
      <Grid columns={2} spacing={2}>
        <DataProperty label="Location Name" value={location.businessName} />
        <DataProperty label="Contact Name" value={location.contact.name} />
        <DataProperty label="Contact Email" value={location.contact.email} />
        <DataProperty label="Contact Phone Number" value={location.contact.phone} />
      </Grid>
      <Grid columns={6} spacing={2}>
        <DataProperty label="Street Address" value={location.address.address1} />
        <DataProperty label="Suite, Dock #" value={location.address.address2} />
        <DataProperty label="City" value={location.address.city} />
        <DataProperty label="State" value={location.address.provinceCode} />
        <DataProperty label="Zip Code" value={location.address.postalCode} />
        <DataProperty label="Country Code" value={location.address.countryCode} />
      </Grid>
    </>
  )
}

export { ServicingLocation }
