import type { FC, SyntheticEvent } from 'react'
import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/zen'
import type { Claim, Contract, Money, ServiceOrder } from '@helloextend/extend-api-client'
import { dataFields } from './schema'
import { DataDisplayGroup, fieldMapper } from '../../../../../../components/data-display-group'
import { ResolveProductReplacementForm } from './resolve-product-replacement-form'
import { formatCurrency } from '../../../util'
import { Select, SelectItem } from '../../../../../../components/select'
import { ResolveGiftCardForm } from './resolve-gift-card-form'

interface DataFields {
  claim: Claim
  serviceOrder: ServiceOrder
  contract: Contract
  entitlements?: { coverageAmountRemaining: Money }
}

interface ResolveServiceOrderContentProps {
  claim: Claim
  serviceOrder: ServiceOrder
  contract: Contract
  entitlements?: { coverageAmountRemaining: Money }
}

const ResolveServiceOrderContent: FC<ResolveServiceOrderContentProps> = ({
  claim,
  serviceOrder,
  contract,
  entitlements,
}) => {
  const readOnlyData = useMemo(
    () => fieldMapper<DataFields>({ claim, serviceOrder, contract, entitlements }, dataFields),
    [claim, serviceOrder, contract, entitlements],
  )
  const [fulfillmentMethod, setFulfillmentMethod] = useState(
    claim.type === 'shipping_protection' ? 'gift_card' : 'product_replacement',
  )

  return (
    <ContentWrapper>
      <DataDisplayGroup data={[{ values: readOnlyData }]} numColumns={4} />
      <DataRow>
        <ItemWrapper>
          <LabelWrapper>
            <LabelText>Fulfillment Resolution</LabelText>
          </LabelWrapper>
          <Select
            onChange={(e: SyntheticEvent<Element>): void => {
              const { value } = e.currentTarget as HTMLInputElement
              setFulfillmentMethod(value)
            }}
            value={fulfillmentMethod}
            isDisabled={claim.type === 'shipping_protection'}
            data-cy="fulfillment-resolution-select"
          >
            <SelectItem
              key="product_replacement"
              value="product_replacement"
              label="Product Replacement"
              data-cy="select-item-product-replacement"
            />
            <SelectItem
              key="gift_card"
              value="gift_card"
              label="Gift Card"
              data-cy="select-item-gift-card"
            />
          </Select>
        </ItemWrapper>
        <ItemWrapper>
          <LabelWrapper>
            <LabelText>Total Replacement Value</LabelText>
          </LabelWrapper>
          <ValueWrapper>{formatCurrency(serviceOrder.totalItemsValue)}</ValueWrapper>
        </ItemWrapper>
      </DataRow>
      {fulfillmentMethod === 'product_replacement' && (
        <ResolveProductReplacementForm contract={contract} serviceOrder={serviceOrder} />
      )}
      {fulfillmentMethod === 'gift_card' && <ResolveGiftCardForm serviceOrder={serviceOrder} />}
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
})

const DataRow = styled.div({
  display: 'flex',
  width: '100%',
})

const ItemWrapper = styled.div({
  width: 'calc(25% - 16px)',
  marginRight: 16,
  display: 'flex',
  flexDirection: 'column',
})

const LabelWrapper = styled.label({
  fontFamily: 'Nunito Sans, sans-serif',
})

const ValueWrapper = styled.div({
  marginTop: 4,
  lineHeight: '24px',
  display: 'block',
})

const LabelText = styled.div({
  color: COLOR.NEUTRAL[600],
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
  lineHeight: '24px',
})

export { ResolveServiceOrderContent }
