import type { FC } from 'react'
import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { COLOR } from '../../tokens/colors'
import { ContentCopy, OpenInNew, Warning } from '../../tokens/icons'
import { Icon, IconSize } from '../icon'
import { ToastColor, ToastDuration, useToaster } from '../toaster'
import { LoadingText } from '../loading-text'
import { isStringUrl } from '../../utils'
import { Button } from '../button'

interface DataPropertyContentProps {
  isError?: boolean
  isLoading?: boolean
  errorMessage?: string
  to?: string
  valueToDisplay: string | null
  label?: string
  openInNew?: boolean
  includeCopyAction?: boolean
  dataCy?: string
}

export const DataPropertyContent: FC<DataPropertyContentProps> = ({
  isError,
  isLoading,
  errorMessage = 'Unavailable',
  valueToDisplay,
  label,
  openInNew,
  includeCopyAction,
  to,
  dataCy,
}) => {
  const { toast } = useToaster()

  const hasLabel = !!label

  const handleCopyToClipboard = useCallback(async () => {
    try {
      if (valueToDisplay) {
        await navigator.clipboard.writeText(
          to ? `${window.location.protocol}//${window.location.host}${to}` : valueToDisplay,
        )
        toast({
          message: `${hasLabel ? label : 'Text'} copied to clipboard`,
          toastDuration: ToastDuration.short,
        })
      }
    } catch (e: unknown) {
      toast({
        message: `${label} failed to copy to clipboard`,
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [valueToDisplay, to, toast, hasLabel, label])

  if (isLoading)
    return (
      <Value data-cy={dataCy && `${dataCy}:value`}>
        <LoadingText data-cy={dataCy && `${dataCy}:loading-text`} />
      </Value>
    )

  if (isError)
    return (
      <>
        <IconContainer>
          <Icon icon={Warning} size={IconSize.small} color={COLOR.NEUTRAL[600]} />
        </IconContainer>
        <ErrorMessage data-cy={dataCy && `${dataCy}:error-message`}>{errorMessage}</ErrorMessage>
      </>
    )

  return (
    <>
      {valueToDisplay && to ? (
        <StyledLink
          to={isStringUrl(to) ? { pathname: to } : to}
          target={openInNew || isStringUrl(to) ? '_blank' : undefined}
          data-cy={dataCy && `${dataCy}:link`}
        >
          <Value data-cy={dataCy && `${dataCy}:value`}>{valueToDisplay}</Value>
          {(openInNew || isStringUrl(to)) && (
            <Icon icon={OpenInNew} size={IconSize.xsmall} color={COLOR.BLUE[700]} />
          )}
        </StyledLink>
      ) : (
        <Value data-cy={dataCy && `${dataCy}:value`}>{valueToDisplay || '—'}</Value>
      )}
      {includeCopyAction && !!valueToDisplay && (
        <Button
          icon={ContentCopy}
          emphasis="low"
          size="xsmall"
          onClick={handleCopyToClipboard}
          tooltip={to ? 'Copy Link to Clipboard' : 'Copy to Clipboard'}
          data-cy={dataCy && `${dataCy}:copy-to-clipboard`}
        />
      )}
    </>
  )
}

const Value = styled.span({
  lineHeight: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const StyledLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const IconContainer = styled.div({
  padding: 2,
  '& > svg': {
    display: 'block',
  },
})

const ErrorMessage = styled.div({
  color: COLOR.NEUTRAL[600],
})
