import type { MerchantsUserReducerState } from '.'
import type { UserProfileReducerState } from './profile'

export function getUserFirstName(state: MerchantsUserReducerState): string | null {
  return state.profile.firstName ?? null
}

export function getUserLastName(state: MerchantsUserReducerState): string | null {
  return state.profile.lastName ?? null
}

export function getUserId(state: MerchantsUserReducerState): string | null {
  return state.profile.id
}

export function getIsUserLoading(state: MerchantsUserReducerState): boolean {
  return state.isLoading
}

export function getProfileSuccessMessage(state: MerchantsUserReducerState): string | null {
  return state.profileSuccessMessage.message
}

export function getUserProfile(state: MerchantsUserReducerState): UserProfileReducerState {
  return state.profile
}
