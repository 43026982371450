import type {
  Plan,
  PlanContract,
  PlanCreateRequest,
  PlanExternal,
  PlanSearch,
} from '@helloextend/extend-api-client'

export function generatePlan(overrides: any = {}): Plan {
  return {
    active: true,
    administrator: 'Aon',
    contract: {
      category: 'auto_parts',
      coverage_includes: 'base',
      coverage_starts: 'after',
      deductible: 0,
      pcmi_category: 'Automotive',
      pcmi_subcategory: 'Battery',
      replacement_type: 'new',
      service_type: 'replace',
      term_length: 12,
      servicer_ids: ['123'],
      ...overrides.contract,
    },
    created: '2019-05-17T00:00:00.000Z',
    id: '10001-auto-battery-12-23-base-replace-1y',
    name: 'Extend Protection Plan - Auto Battery',
    secondary_name: 'Auto Battery',
    pricing: [
      {
        cost: 1005,
        price_high: 9999,
        price_low: 0,
        retail_max: 0.3,
        retail_min: 0,
        retail_target: 0.1,
        vendor_sku: 'EXTABATT29',
      },
      {
        cost: 1918,
        price_high: 14999,
        price_low: 10000,
        retail_max: 0.3,
        retail_min: 0,
        retail_target: 0.1,
        vendor_sku: 'EXTABATT30',
      },
    ],
    matching: [
      {
        price_low: 0,
        price_high: 9999,
        cost: 1005,
        vendor_sku: 'EXTABATT29',
      },
      {
        price_low: 10000,
        price_high: 14999,
        cost: 1918,
        vendor_sku: 'EXTABATT30',
      },
    ],
    product_notes: "Auto battery with manufacturer's warranty 12-23 months long",
    product_types: 'Auto_battery_(mfr_warranty_12_23)',
    tags: null,
    termsId: 'TERMS-ID-MOCK',
    termsVersion: '1',
    underwriter: 'Fortegra',
    updated: '2019-09-12T00:00:00.000Z',
    vendor: 'Aon',
    version: 1,
    allowed_regions: ['US'],
    repair_threshold: 500,
    program: 'test-program',
    sub_program: 'test-sub-program',
    plan_category: 'test-plan-category',
    wd_tags: ['test-wd-tag'],
    ...overrides,
  }
}

export function generatePlanExternal(options: {
  id?: string
  adh?: boolean
  overrides?: Partial<PlanExternal>
  isWheelsTires?: boolean
}): PlanExternal {
  const { adh, overrides, isWheelsTires } = options

  let plan: PlanExternal = {
    id: '10001-misc-elec-base-replace-1y',
    name: 'Extend Protection Plan - Electronics',
    contract: {
      category: 'consumer_electronics',
      coverage_includes: 'base',
      coverage_starts: 'after',
      deductible: 0,
      pcmi_category: 'Replacement',
      pcmi_subcategory: 'General Electronic Devices',
      replacement_type: 'new',
      service_type: 'replace',
      servicer_ids: ['100000001'],
      term_length: 12,
    },
  }

  if (adh)
    plan = {
      id: '10001-computer-adh-repair-1y',
      name: 'Extend Protection Plan with ADH - Computer',
      contract: {
        category: 'consumer_electronics',
        coverage_includes: 'adh',
        coverage_starts: 'immediate',
        deductible: 0,
        pcmi_category: 'Computing',
        pcmi_subcategory: 'Computer',
        replacement_type: 'new',
        service_type: 'repair_depot',
        servicer_ids: [],
        term_length: 12,
      },
    }

  if (isWheelsTires) {
    plan.contract.category = 'wheel_tire'
    return plan
  }

  return {
    ...plan,
    ...overrides,
  }
}

export const generateCreatePlan = (overrides: any = {}): PlanCreateRequest => {
  return {
    id: '10001-auto-battery-12-23-base-replace-1y',
    name: 'Extend Protection Plan - Auto Battery',
    active: true,
    secondary_name: 'Auto Battery',
    contract: {
      category: 'auto_parts',
      coverage_starts: 'after',
      coverage_includes: 'base',
      deductible: 0,
      pcmi_category: 'Automotive',
      pcmi_subcategory: 'Battery',
      replacement_type: 'new',
      service_type: 'replace',
      term_length: 12,
      servicer_ids: ['123'],
      ...overrides.contract,
    },
    administrator: 'Aon',
    pricing: [
      {
        price_high: 9999,
        price_low: 0,
        retail_max: 0.3,
        retail_min: 0,
        retail_target: 0.1,
      },
      {
        price_high: 14999,
        price_low: 10000,
        retail_max: 0.3,
        retail_min: 0,
        retail_target: 0.1,
      },
    ],
    matching: [
      {
        cost: 1005,
        price_high: 9999,
        price_low: 0,
        vendor_sku: 'EXTABATT29',
      },
      {
        cost: 1918,
        price_high: 14999,
        price_low: 10000,
        vendor_sku: 'EXTABATT30',
      },
    ],
    allowed_regions: ['US'],
    blocked_sub_division: ['CA'],
    product_notes: "Auto battery with manufacturer's warranty 12-23 months long",
    product_types: 'Auto battery (mfr warranty 12-23)',
    tags: 'tags',
    termsId: 'TERMS-ID-MOCK',
    underwriter: 'Fortegra',
    vendor: 'Aon',
    currency_code: 'USD',
    repair_threshold: 500,
    program: 'test-program',
    sub_program: 'test-sub-program',
    plan_category: 'test-plan-category',
    wd_tags: ['test-wd-tag'],
    ...overrides,
  }
}

export function generatePlanContract(overrides: Partial<PlanContract> = {}): PlanContract {
  return {
    category: 'consumer_electronics',
    coverage_includes: 'base',
    coverage_starts: 'after',
    deductible: 0,
    pcmi_category: 'Replacement',
    pcmi_subcategory: 'General Electronic Devices',
    replacement_type: 'new',
    service_type: 'replace',
    servicer_ids: ['100000001'],
    term_length: 12,
    ...overrides,
  }
}

export const generateCreatePlanForTests = (overrides: any): Plan => {
  const { contract, ...rest } = overrides
  return {
    active: true,
    administrator: 'Extend',
    allowed_regions: ['US'],
    blocked_sub_division: ['CA'],
    contract: generatePlanContract(contract),
    created: '1676495111934',
    createdAt: '1676495111934',
    currency_code: 'USD',
    id: '10001-auto-part-base-replace-1y',
    matching: [
      {
        cost: 123,
        price_high: 9999,
        price_low: 0,
        vendor_sku: 'EXTRPL1',
      },
    ],
    name: 'Extend Protection Plan - Auto Part',
    plan_category: 'Mattresses',
    plan_start_date: '1643042000376',
    plan_end_date: null,
    plan_type: 'service_contract',
    pricing: [
      {
        price_high: 9999,
        price_low: 0,
        retail_max: 0.3,
        retail_min: 0.05,
        retail_target: 0.1,
      },
    ],
    product_notes: 'Anything',
    product_types: 'Miscellaneous',
    program: 'Mattresses and Bedding',
    repair_threshold: 1000,
    schemaVersion: 4,
    secondary_name: 'Auto Part',
    sub_program: 'Mattresses',
    termsId: 'test-terms-id',
    underwriter: 'Fortegra',
    updated: '1676495111934',
    vendor: 'Aon',
    version: 1,
    wd_tags: ['tag1', 'tag2'],
    ...rest,
  }
}

export const generatePlanSearch = (overrides: any = {}): PlanSearch => {
  const plan = generatePlan(overrides)
  return {
    createdAt: plan.created,
    planId: plan.id,
    versions: [plan],
  }
}

export function generateTestPlanFile(
  data: Plan | { [key: string]: string },
  filename: string,
): File {
  const blob: Blob = new Blob([JSON.stringify(data)], { type: 'json' })
  return {
    ...blob,
    lastModified: 123,
    webkitname: '',
    webkitRelativePath: '',
    name: filename,
    lastModifiedDate: new Date(),
    text: async () => JSON.stringify(data),
  } as File
}
