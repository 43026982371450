import type { Action } from '../actions'
import { createBooleanReducer } from '../../factories'

const isUpdating = createBooleanReducer<Action>(
  ['STORES_UPDATE_REQUEST', 'STORES_DESTROY_REQUEST'],
  [
    'STORES_UPDATE_SUCCESS',
    'STORES_UPDATE_FAILURE',
    'STORES_DESTROY_SUCCESS',
    'STORES_DESTROY_FAILURE',
  ],
)

export default isUpdating
