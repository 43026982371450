import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { COLOR } from '@helloextend/client-branding'
import images from '../../../images'
import { support as supportActions } from '../../../actions'
import ThanksPage from './thanks-page'
import * as selectors from '../../../reducers/selectors'
import SupportModalForm from './support-modal-form'
import type { RootState } from '../../../reducers'

interface SP {
  user: ReturnType<typeof selectors.getUserInformation>
  supportRequestStatus: ReturnType<typeof selectors.getSupportStatus>
  isSupportLoading: ReturnType<typeof selectors.getIsSupportLoading>
}

interface DP {
  sendSupportRequest: typeof supportActions.createSupport
  toggleSupport: typeof supportActions.toggleSupport
}

type SupportModalProps = SP & DP

const Component: FC<SupportModalProps> = ({
  user,
  supportRequestStatus,
  isSupportLoading,
  sendSupportRequest,
  toggleSupport,
}) => {
  const [show, setShow] = useState(false)
  const [enter, setEnter] = useState(false)
  const { firstName, lastName, email } = user
  const name = `${firstName} ${lastName}`

  useEffect(() => {
    setShow(true)
    setTimeout(() => {
      setEnter(true)
    }, 50)
  }, [setShow, setEnter])

  return (
    <>
      <Wrapper show={show} enter={enter}>
        {supportRequestStatus !== 'SUCCESS' ? (
          <>
            <Header show={show} enter={enter}>
              Hey {user.firstName}&nbsp;
              <Wave src={images.wavingHand} />
            </Header>
            <Text show={show} enter={enter}>
              Questions, bug reports, feedback, feature requests - we are here to help!
            </Text>
            <SupportModalForm
              name={name}
              email={email}
              toggleSupport={toggleSupport}
              isSupportLoading={isSupportLoading}
              onSubmit={sendSupportRequest}
              show={show}
              enter={show}
            />
          </>
        ) : (
          <ThanksPage toggleSupport={toggleSupport} />
        )}
      </Wrapper>
      <Overlay onClick={toggleSupport} />
    </>
  )
}

const SupportModal = connect(
  (state: RootState) => ({
    user: selectors.getUserInformation(state),
    supportRequestStatus: selectors.getSupportStatus(state),
    isSupportLoading: selectors.getIsSupportLoading(state),
  }),
  {
    sendSupportRequest: supportActions.createSupport,
    toggleSupport: supportActions.toggleSupport,
  },
)(Component)

const Overlay = styled.div({
  position: 'absolute',
  height: 'calc(100vh - 60px)',
  width: '100%',
  zIndex: 2,
})

const Wave = styled.img({
  height: 18,
})

const Wrapper = styled.div<{ show: boolean; enter: boolean }>(
  {
    display: 'none',
    flexDirection: 'column',
    fontFamily: 'Nunito Sans, Helvetica, sans-serif',
    borderRadius: 4,
    border: `1px solid ${COLOR.GHOST}`,
    boxShadow: '0px 3px 5px 0px rgba(209, 205, 205, 0.5)',
    height: 643,
    width: 340,
    color: COLOR.VERY_DARK_BLUE_0,
    padding: '32px 16px',
    boxSizing: 'border-box',
    position: 'absolute',
    right: 108,
    top: 76,
    backgroundColor: COLOR.WHITE,
    zIndex: 3,
  },
  (props) => [
    props.show && {
      display: 'flex',
      opacity: '0',
      transform: 'translateY(1rem)',
    },
    props.enter && {
      opacity: '1',
      transform: 'translateY(0)',
      transition: 'all 200ms linear',
    },
  ],
)

const Header = styled.span<{ show: boolean; enter: boolean }>(
  {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '28px',
    display: 'none',
  },
  (props) => [
    props.show && {
      display: 'flex',
      alignItems: 'center',
      opacity: '0',
      transform: 'translateY(0.5rem)',
    },
    props.enter && {
      opacity: '1',
      transform: 'translateY(0)',
      transition: 'all 300ms 50ms linear',
    },
  ],
)

const Text = styled.span<{ show: boolean; enter: boolean }>(
  {
    display: 'none',
    fontSize: 18,
    padding: '8px 0px 12px',
  },
  (props) => [
    props.show && { display: 'block', opacity: '0', transform: 'translateY(0.5rem)' },
    props.enter && {
      opacity: '1',
      transform: 'translateY(0)',
      transition: 'all 300ms 50ms linear',
    },
  ],
)

export { SupportModal, Component }
