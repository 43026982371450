import type { ApiResponse } from '../../../extend-api-fetch'
import { get, post } from '../../../extend-api-fetch'
import type { Fulfillment, FulfillmentCreateRequest } from '../models/fulfillment'

export async function fetchByClaim(
  contractId: string,
  claimId: string,
  accessToken: string,
): Promise<ApiResponse<Fulfillment[]>> {
  return get(`/contracts/${contractId}/claims/${claimId}/fulfillments`, {
    accessToken,
  })
}

export async function create(
  contractId: string,
  claimId: string,
  body: FulfillmentCreateRequest,
  accessToken: string,
): Promise<ApiResponse<Fulfillment>> {
  return post(`/contracts/${contractId}/claims/${claimId}/fulfillments`, {
    body,
    apiVersion: '2020-08-01',
    accessToken,
  })
}
