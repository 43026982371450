import type { FC } from 'react'
import React from 'react'
import { useGetStoreQuery } from '@helloextend/extend-api-rtk-query'
import { Grid, Spinner } from '@helloextend/zen'
import { ProductProtection } from './components/product-protection'
import { OfferForm } from './components/offer-form'
import { MerchantPortalForm } from './components/merchant-portal-form'
import { SectionContainer } from '../../../../../../components/section-container'
import { ContactForm } from './components/contact-form'
import { Servicing } from './components/servicing'
import { BasicInfoForm } from './components/basic-info-form'

type SettingsProps = {
  storeId: string
}

const Settings: FC<SettingsProps> = ({ storeId }) => {
  const { data, isLoading } = useGetStoreQuery({ storeId, version: 'latest' })

  if (isLoading) {
    return <Spinner />
  }
  if (!data) return null

  return (
    <>
      <SectionContainer title="Basic">
        <BasicInfoForm store={data} />
      </SectionContainer>
      <Grid columns={2} spacing={2}>
        <MerchantPortalForm store={data} />
        <OfferForm store={data} />
      </Grid>
      <ProductProtection store={data} />
      <SectionContainer title="Servicing">
        <Servicing store={data} />
      </SectionContainer>
      <SectionContainer title="Contact">
        <ContactForm store={data} />
      </SectionContainer>
    </>
  )
}

export { Settings }
