import { createActionFailure } from '../../factories'

export const approveReplacement = (serviceOrderId: string, claimId: string, accessToken: string) =>
  ({
    type: 'SERVICE_ORDERS_APPROVE_REPLACEMENT',
    payload: { serviceOrderId, claimId, accessToken },
  } as const)

export const approveReplacementRequest = () =>
  ({ type: 'SERVICE_ORDERS_APPROVE_REPLACEMENT_REQUEST' } as const)

export const approveReplacementSuccess = () =>
  ({
    type: 'SERVICE_ORDERS_APPROVE_REPLACEMENT_SUCCESS',
  } as const)

export const approveReplacementFailure = createActionFailure(
  'SERVICE_ORDERS_APPROVE_REPLACEMENT_FAILURE',
)
