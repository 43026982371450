import { useEffect } from 'react'
import { debounce } from 'lodash'

export const useDetectScreenResize = (
  handleResize: () => void,
  resizeDelay = 200,
  shouldDebounce = true,
): void => {
  useEffect(() => {
    let adjustedHandleResize = handleResize
    if (shouldDebounce) {
      adjustedHandleResize = debounce(handleResize, resizeDelay)
    }

    window.addEventListener('resize', adjustedHandleResize)

    return () => window.removeEventListener('resize', adjustedHandleResize)
  })
}
