import * as Yup from 'yup'

export const formSchema = Yup.object()
  .shape({
    reason: Yup.string().default('').required('Required'),
    notes: Yup.string().default(''),
  })
  .defined()

export type Values = Yup.InferType<typeof formSchema>
