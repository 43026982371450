export type FilterOptions =
  | CheckboxFilterOptions
  | DateRangeFilterOptions
  | FilterGroupOptions
  | TextFilterOptions
  | NumberRangeFilterOptions
  | NestedCheckboxFilterOptions
  | AdvancedTextFilterOptions

export enum DateRange {
  custom,
  today,
  last7days,
  last30days,
}

export interface BaseFilterOptions {
  label: string
}

export interface CheckboxFilterOptions extends BaseFilterOptions {
  type: 'checkbox'
  options: Record<string, string>
  includeSearchBar?: boolean
}

export interface DateRangeFilterOptions extends BaseFilterOptions {
  type: 'dateRange'
  ranges?: DateRange[]
}

export interface FilterGroupOptions extends BaseFilterOptions {
  type: 'group'
  label: string
  filters: Record<string, FilterOptions>
}

export interface NestedCheckboxFilterOptions extends BaseFilterOptions {
  type: 'nestedCheckbox'
  label: string
  includeSearchBar?: boolean
  keepCheckedOnSearch?: boolean
  bulkSearchCSV?: boolean
  filters: Record<string, { label: string; options?: Record<string, string> }>
}

export interface TextFilterOptions extends BaseFilterOptions {
  type: 'text'
  label: string
}

export interface NumberRangeFilterOptions extends BaseFilterOptions {
  type: 'numberRange'
  label: string
  highLabel?: string
  lowLabel?: string
  isCurrency?: boolean
}

export interface AdvancedTextFilterOptions extends BaseFilterOptions {
  type: 'advancedText'
  label: string
}

export enum FilterValuesTypes {
  checkbox = 'checkbox',
  nestedCheckbox = 'nestedCheckbox',
  dateRange = 'dateRange',
  text = 'text',
  numberRange = 'numberRange',
  advancedText = 'advancedText',
}

export type FilterValues =
  | CheckboxFilterValues
  | DateRangeFilterValues
  | TextFilterValues
  | NumberRangeFilterValues
  | NestedCheckboxFilterValues
  | AdvancedTextFilterValues

export type CheckboxFilterValues = {
  type: 'checkbox'
  values: string[]
}

export type NestedCheckboxFilterValues = {
  type: 'nestedCheckbox'
  values: Record<string, string[]>
}

export type DateRangeFilterValues = {
  type: 'dateRange'
  range: DateRange
  start: Date | null
  end: Date | null
}

export type TextFilterValues = {
  type: 'text'
  value: string
}

export type NumberRangeFilterValues = {
  type: 'numberRange'
  low: number | null
  high: number | null
  highLabel?: string
  lowLabel?: string
  isCurrency: boolean
}

export type AdvancedTextFilterValues = {
  type: 'advancedText'
  caseSensitive: boolean
  contains: string | null
  excludes: string | null
  startsWith: string | null
  endsWith: string | null
}
