import styled from '@emotion/styled'
import type { FC } from 'react'
import React from 'react'
import type { TextAlign } from './table-styles'

type TableCellProps = {
  columnWidth?: number
  align?: TextAlign
  isCollapsible?: boolean
  colspan?: number
  'data-cy'?: string
}

const TableCell: FC<TableCellProps> = ({
  children,
  columnWidth = 100,
  align = 'left',
  isCollapsible = false,
  colspan = 1,
  'data-cy': dataCy,
}) => (
  <CellContainer
    width={columnWidth}
    colSpan={colspan}
    align={align}
    isCollapsible={isCollapsible}
    data-cy={dataCy}
  >
    {children}
  </CellContainer>
)

const CellContainer = styled.td<{ width: number; align: TextAlign; isCollapsible?: boolean }>(
  ({ width, align, isCollapsible = false }) => ({
    width: `${width}%`,
    textAlign: align,
    ...(isCollapsible && {
      display: 'flex',
      alignItems: 'center',
    }),
  }),
)

export { TableCell }
