import type { VFC } from 'react'
import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import { When } from 'react-if'
import { Button } from '../../button'
import { ChevronStart, ChevronEnd, ChevronLeft, ChevronRight } from '../../../tokens/icons'
import { MenuButtonItem } from '../../menu-item'
import { Popover, PopoverAlignment, PopoverDirection, usePopover } from '../../popover'
import { useDataTableContext } from '../data-table-context'

export const Pagination: VFC = () => {
  const {
    'data-cy': dataCy,
    table,
    pageSizeOptions,
    rowCount,
    hasMoreRows,
    isLoading,
  } = useDataTableContext()

  const { triggerRef, popoverRef, isPresent, toggle, triggerBoundingBox } =
    usePopover<HTMLButtonElement>()

  const paginationFactor =
    table.getState().pagination.pageIndex * table.getState().pagination.pageSize
  const internalRowCount = table.getRowModel().rows.length

  const hasUnboundedPagination = !!hasMoreRows

  const getPaginationButtonText = useCallback(() => {
    const startIndex = paginationFactor + 1
    const endIndex = paginationFactor + internalRowCount
    const text = `${startIndex}–${endIndex}`
    const totalRowCount = hasUnboundedPagination
      ? undefined
      : rowCount || table.getFilteredRowModel().rows.length
    return hasUnboundedPagination ? text : `${text} of ${totalRowCount}`
  }, [hasUnboundedPagination, internalRowCount, paginationFactor, rowCount, table])

  return (
    <StyledPagination data-cy={dataCy && `${dataCy}:pagination`}>
      <When condition={!isLoading}>
        <Button
          text={getPaginationButtonText()}
          color="neutral"
          emphasis="low"
          size="small"
          isBold={false}
          hasReducedPadding
          ref={triggerRef}
          isToggled={isPresent}
          onClick={toggle}
        />
        <Popover
          ref={popoverRef}
          alignment={PopoverAlignment.center}
          direction={PopoverDirection.up}
          isPresent={isPresent}
          triggerBoundingBox={triggerBoundingBox}
        >
          <MenuItems>
            {pageSizeOptions?.map((pageSize) => (
              <MenuButtonItem
                key={pageSize}
                isToggled={table.getState().pagination.pageSize === pageSize}
                onClick={() => {
                  table.setPageSize(pageSize)
                  toggle()
                }}
              >
                Show {pageSize} per page
              </MenuButtonItem>
            ))}
          </MenuItems>
        </Popover>
      </When>
      {!hasUnboundedPagination && (
        <Button
          icon={ChevronStart}
          color="neutral"
          emphasis="low"
          size="small"
          onClick={() => table.setPageIndex(0)}
          isDisabled={!table.getCanPreviousPage()}
          data-cy={dataCy && `${dataCy}:pagination-start-button`}
        />
      )}
      <Button
        icon={ChevronLeft}
        color="neutral"
        emphasis="low"
        size="small"
        onClick={() => table.previousPage()}
        isDisabled={!table.getCanPreviousPage()}
        data-cy={dataCy && `${dataCy}:pagination-previous-button`}
      />
      <Button
        icon={ChevronRight}
        color="neutral"
        emphasis="low"
        size="small"
        onClick={() => table.nextPage()}
        isDisabled={hasUnboundedPagination ? !hasMoreRows : !table.getCanNextPage()}
        data-cy={dataCy && `${dataCy}:pagination-next-button`}
      />
      {!hasUnboundedPagination && (
        <Button
          icon={ChevronEnd}
          color="neutral"
          emphasis="low"
          size="small"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          isDisabled={!table.getCanNextPage()}
          data-cy={dataCy && `${dataCy}:pagination-end-button`}
        />
      )}
    </StyledPagination>
  )
}

const StyledPagination = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 8,
  marginLeft: 'auto',
})

const MenuItems = styled.div({
  padding: '6px 8px',
})
