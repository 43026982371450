import type { FormikHelpers, FormikValues } from 'formik'
import type { ChangeEvent } from 'react'
import { useCallback } from 'react'
import { roundToTwoDecimalPlaces } from '../utils/sku-rates-calculations'

type HookReturn = {
  handleOnBlurCustom: (e: ChangeEvent<HTMLInputElement>) => void
}

/**
 * handleBlur for number fields to limit decimal to two places
 */
export const useDecimalsOnBlur = (
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'],
  setFieldTouched?: FormikHelpers<FormikValues>['setFieldTouched'],
): HookReturn => {
  const handleOnBlurCustom = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      const { id, value } = e.target
      const valueToNumber = Number(value)
      const formattedValue =
        valueToNumber > 0 ? roundToTwoDecimalPlaces(valueToNumber).toFixed(2) : value
      setFieldValue(id, formattedValue)
      if (setFieldTouched) {
        setFieldTouched(id, true)
      }
    },
    [setFieldTouched, setFieldValue],
  )
  return { handleOnBlurCustom }
}
