import type { FC } from 'react'
import React from 'react'
import { useFormik } from 'formik'
import { useLocation } from 'react-router'
import styled from '@emotion/styled'
import * as Yup from 'yup'
import { isValidEmail } from '@helloextend/client-utils/validate'
import { Button } from '@helloextend/merchants-ui'
import type { auth } from '../../actions'
import { getParamsFromUrl } from '../../utils/get-params-from-url'
import Input from '../input'

const schema = Yup.object()
  .shape({
    email: Yup.string()
      .required('Email address required')
      .test('is-email-valid', 'Please enter a valid email address', (email) =>
        email ? isValidEmail(email) : false,
      ),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

type ForgotPasswordFormProps = {
  isLoading: boolean
  onSubmit: typeof auth.forgotPassword
}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ isLoading, onSubmit }) => {
  const paramsEmail = getParamsFromUrl('user')
  const location = useLocation<{ username?: string }>()
  const username = location?.state?.username

  const { touched, errors, values, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      email: username || paramsEmail || '',
    },
    onSubmit: (vals: Values): void => {
      onSubmit(vals.email)
    },
  })

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        errorMessage={errors.email}
        formGroupCSS={inputStyle}
        invalid={touched.email && !!errors.email}
        isDisabled={isLoading}
        label="Email Address"
        name="email"
        onChange={handleChange}
        type="text"
        value={values.email}
      />
      <PasswordButton
        disabled={isLoading}
        loading={isLoading}
        type="submit"
        text="Send me instructions"
        kind="primary"
        size="xs"
      />
    </Form>
  )
}

const Form = styled.form({
  textAlign: 'center',
  marginTop: 32,
  width: '100%',
})

const PasswordButton = styled(Button)({
  width: '100%',
})

const inputStyle = {
  marginBottom: 25,
  height: 'auto',
  textAlign: 'left',
}

export { ForgotPasswordForm }
