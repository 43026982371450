import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { post } from '@helloextend/extend-api-fetch'
import type { ConsumerSignUpFormValues, ConsumerSignUpResponse } from '../models'

export async function signUpFormSubmit(
  signUpFormValues: ConsumerSignUpFormValues,
): Promise<ApiResponse<ConsumerSignUpResponse>> {
  return post<ConsumerSignUpResponse>(`/auth/consumers`, {
    body: signUpFormValues,
  })
}
