import type { MeasurementArgs, BadgeSize } from './badge-types'

export const badgeMeasurements: Record<BadgeSize, MeasurementArgs> = {
  small: {
    lineHeight: '14px',
    fontSizePx: 12,
    dotSizePx: 8,
    dotTopMarginPx: 3,
    iconWrapperSizePx: 14,
  },
  regular: {
    lineHeight: '18px',
    fontSizePx: 13,
    dotSizePx: 10,
    dotTopMarginPx: 4,
    iconWrapperSizePx: 18,
  },
}
