import type { FC } from 'react'
import React from 'react'
import type { WalletGetResponse } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { useFlags } from 'launchdarkly-react-client-sdk'
import type {
  InsuranceClaim,
  Contract,
  Money,
  Order,
  ServiceOrder,
  Plan,
  Expense,
} from '@helloextend/extend-api-client'
import { Stack } from '@helloextend/zen'
import { StackJustify } from '@helloextend/zen/src/components/stack'
import { ClaimHeader } from './components/claim-header'
import { ActionButtons } from './components/action-buttons'
import { ActionModal } from './components/action-modal'
import { TabSection } from './components/tab-section'
import { ClaimInfoSection } from './components/claim-info-section'
import { ProductInfoSection } from './components/product-info-section'
import { CustomerInfoSection } from './components/customer-info-section'
import { ClaimNotes } from './components/claim-notes'
import { LDFlag } from '../../../../constants/ld-flags'

interface ClaimDetailsProps {
  claim: InsuranceClaim
  contract: Contract
  serviceOrders?: ServiceOrder[]
  entitlements?: { coverageAmountRemaining: Money }
  order?: Order
  expenses?: Expense[]
  plan?: Plan
  wallet?: WalletGetResponse
}

const ClaimDetails: FC<ClaimDetailsProps> = ({
  claim,
  contract,
  serviceOrders,
  entitlements,
  order,
  plan,
  expenses,
  wallet,
}) => {
  const { [LDFlag.ClaimNotesEnabled]: FF_CLAIM_NOTES_ENABLED } = useFlags()
  const serviceOrder = serviceOrders && serviceOrders.length > 0 ? serviceOrders[0] : undefined

  return (
    <ClaimPageWrapper data-cy="claim-details">
      <Stack spacing={2} justify={StackJustify.spaceBetween} doesWrap>
        <ClaimHeader claim={claim} product={contract.product} serviceOrder={serviceOrder} />
        <ActionButtons claim={claim} serviceOrder={serviceOrder} />
      </Stack>
      <ClaimInfoWrapper>
        <CustomerInfoSection claim={claim} />
        <ClaimInfoSection
          claim={claim}
          contract={contract}
          serviceOrder={serviceOrder}
          plan={claim.serviceType !== 'replace' ? plan : undefined}
        />
        <ProductInfoSection {...{ serviceOrder, order, contract, claim }} />
        <TabSection serviceOrders={serviceOrders} entitlements={entitlements} claim={claim} />
      </ClaimInfoWrapper>
      <ActionModal
        claim={claim}
        serviceOrders={serviceOrders}
        contract={contract}
        entitlements={entitlements}
        expenses={expenses}
        wallet={wallet}
      />
      {FF_CLAIM_NOTES_ENABLED && <ClaimNotes claim={claim} />}
    </ClaimPageWrapper>
  )
}

const ClaimPageWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
})

const ClaimInfoWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 0,
})

export { ClaimDetails }
