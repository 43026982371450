import styled from '@emotion/styled'
import type { Sku } from '@helloextend/extend-api-client/src/models/sku'
import { useCreateSkuMutation, useListSkuVersionsQuery } from '@helloextend/extend-api-rtk-query'
import {
  Badge,
  Breadcrumbs,
  COLOR,
  Spinner,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@helloextend/zen'
import { useFormik } from 'formik'
import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { LeavePageGuard } from '../../../components/leave-page-guard'
import { RestoreHeaderButtonGroup } from '../../../components/restore-header-button-group/restore-header-button-group'
import { RestoreVersionModal } from '../../../components/restore-version-modal/restore-version-modal'
import { skuFormInitialValues, skuPropertiesMapper } from '../../../utils/sku-property-mapper'
import type { Values } from '../sku/schema'
import { schema } from '../sku/schema'
import { SkuForm } from '../sku/sku-form'

const SkuVersion: FC = () => {
  const { skuId, versionId } = useParams<{ skuId: string; versionId: string }>()
  const history = useHistory()
  const { toast } = useToaster()

  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(true)
  const [isRestoreModalOpen, setIsRestoreModalOpen] = useState<boolean>(false)

  const { data: skusData, isLoading } = useListSkuVersionsQuery(skuId)
  const [create, { isLoading: isCreateProcessing, isSuccess, isError, data: restoredSku }] =
    useCreateSkuMutation()

  const latestSkuVersion = useMemo((): Sku | undefined => {
    const skuItems = skusData?.items
    if (!skuItems) return undefined
    return skuItems[skuItems.length - 1]
  }, [skusData])

  // filters out the version provided in the url
  const matchingSkuVersion = useMemo(() => {
    return skusData?.items?.find((skuItem: Sku) => skuItem.version === Number(versionId))
  }, [skusData, versionId])

  const { version, schemaVersion, ...exportSku } = matchingSkuVersion || {}

  useEffect(() => {
    if (isSuccess && restoredSku) {
      toast({
        message: `SKU ${restoredSku.id} has been restored.`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
      history.push(`/admin/premiums/${restoredSku.id}`)
    }
    if (isError) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [isSuccess, isError, toast, restoredSku, history])

  const toggleIsEditDisabled = useCallback((): void => {
    setIsEditDisabled(!isEditDisabled)
  }, [isEditDisabled])

  const toggleRestoreModal = useCallback((): void => {
    setIsRestoreModalOpen(!isRestoreModalOpen)
  }, [isRestoreModalOpen])

  const handleRestoreClick = (): void => {
    if (!matchingSkuVersion) return
    const mappedValues = skuPropertiesMapper(values)
    create(mappedValues)
  }

  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setErrors,
    resetForm,
  } = useFormik<Values>({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: skuFormInitialValues((matchingSkuVersion as Sku) || {}),
    onSubmit: (): void => {},
  })

  const handleLeavePage = (path: string): void => {
    history.push(path)
  }

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )
  }

  if (!matchingSkuVersion || !latestSkuVersion) {
    history.push('/404')
    return null
  }
  return (
    <>
      <LeavePageGuard isNavBlocked={dirty && !isSuccess} handleLeavePage={handleLeavePage} />
      <Breadcrumbs
        crumbs={[
          { to: '/admin/premiums', text: 'Premiums' },
          { to: `/admin/premiums/${skuId}`, text: `Premium ID: ${skuId}` },
          { text: `Version: ${versionId}` },
        ]}
      />
      <Header>
        <TitleContainer>
          <Title data-cy="sku-version-title">Version {versionId}</Title>
          <BadgeContainer>
            <Badge text="Inactive" color="yellow" data-cy="sku-version-status" />
          </BadgeContainer>
        </TitleContainer>
        <RestoreHeaderButtonGroup
          isEditDisabled={isEditDisabled}
          toggleIsEditDisabled={toggleIsEditDisabled}
          resetForm={resetForm}
          restoreDetails={matchingSkuVersion}
          toggleRestoreModal={toggleRestoreModal}
          isRestoreDisabled={!isValid || !dirty}
          isDuplicateDisplayed={false}
          exportDetails={exportSku}
          exportFilePrefix="SKU"
        />
      </Header>
      <SkuForm
        values={values}
        errors={errors}
        isDisabled={isEditDisabled}
        touched={touched}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        setErrors={setErrors}
        handleSubmit={handleSubmit}
      />
      <RestoreVersionModal
        isOpen={isRestoreModalOpen}
        handleModalToggle={toggleRestoreModal}
        handleRestoreClick={handleRestoreClick}
        id={skuId}
        latestVersionId={latestSkuVersion.version}
        isProcessing={isCreateProcessing}
        type="SKU"
      />
    </>
  )
}

export { SkuVersion }

const SpinnerWrapper = styled.div({
  width: 100,
  margin: '0 auto',
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '24px 0 48px',
})

const TitleContainer = styled.div({
  display: 'flex',
})

const Title = styled.h1({
  fontWeight: 700,
  fontSize: 32,
  lineHeight: '44px',
  margin: 0,
  color: COLOR.BLUE[1000],
})

const BadgeContainer = styled.div({
  alignSelf: 'center',
  display: 'inline-flex',
  marginLeft: 16,
})
