import type { FilterValues } from '@helloextend/merchants-ui'
import type { ColumnFiltersState, FilterDef } from '@helloextend/zen'

export const filterOptions: FilterDef[] = [
  {
    type: 'group',
    filterDefs: [
      {
        type: 'group',
        label: 'Product Protection',
        filterDefs: [
          {
            id: 'approved',
            type: 'select',
            label: 'Approval Status',
            chipLabel: 'Product Protection',
            options: [
              { display: 'Approved', value: 'Approved' },
              { display: 'Not Approved', value: 'Not Approved' },
            ],
            hasSearch: false,
          },
          {
            id: 'enabled',
            type: 'select',
            label: 'Enabled/Disabled',
            chipLabel: 'Product Protection',
            options: [
              { display: 'Enabled', value: 'Enabled' },
              { display: 'Disabled', value: 'Disabled' },
            ],
            hasSearch: false,
          },
        ],
      },
      {
        type: 'group',
        label: 'Shipping Protection',
        filterDefs: [
          {
            id: 'shippingProtectionApproved',
            type: 'select',
            label: 'Approval Status',
            chipLabel: 'Shipping Protection',
            options: [
              { display: 'Approved', value: 'Approved' },
              { display: 'Not Approved', value: 'Not Approved' },
            ],
            hasSearch: false,
          },
          {
            id: 'shippingProtectionEnabled',
            type: 'select',
            label: 'Enabled/Disabled',
            chipLabel: 'Shipping Protection',
            options: [
              { display: 'Enabled', value: 'Enabled' },
              { display: 'Disabled', value: 'Disabled' },
            ],
            hasSearch: false,
          },
        ],
      },
    ],
  },
  // TODO: PUPS-1209
  // {
  //   id: 'platform',
  //   type: 'select',
  //   label: 'Integration Partner',
  //   options: [
  //     { display: 'Shopify', value: 'shopify' },
  //     { display: 'Magento', value: 'magento' },
  //     { display: 'Salesforce', value: 'salesforce' },
  //     { display: 'Big Commerce', value: 'big-commerce' },
  //     { display: 'Woo Commerce', value: 'woo-commerce' },
  //     { display: 'Custom', value: 'custom' },
  //   ],
  // },
]

export const getFiltersForApi = (
  columnFilters: ColumnFiltersState,
): Record<string, FilterValues | null> => {
  let args = {}

  columnFilters.forEach((filter) => {
    switch (filter.id) {
      case 'storeName':
        args = { ...args, storeName: filter.value }
        break
      case 'accountId':
        args = { ...args, accountId: filter.value }
        break
      case 'storeId':
        args = { ...args, storeId: filter.value }
        break
      case 'approved':
        args = { ...args, approved: (filter.value as string[]).includes('Approved') }
        break
      case 'enabled':
        args = { ...args, enabled: (filter.value as string[]).includes('Enabled') }
        break
      case 'shippingProtectionApproved':
        args = {
          ...args,
          shippingProtectionApproved: (filter.value as string[]).includes('Approved'),
        }
        break
      case 'shippingProtectionEnabled':
        args = {
          ...args,
          shippingProtectionEnabled: (filter.value as string[]).includes('Enabled'),
        }
        break
      // TODO: PUPS-1209
      // case 'platform':
      //   args = { ...args, platform: filter.value }
    }
  })

  return args
}
