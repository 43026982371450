export const labelMap: Record<string, string> = {
  'So what would you like to do for your jewelry?': 'What would you like to do?',
  '{toggleAction} to pick from our list.': 'What is the issue with your jewelry?',
  'Was the damage to your jewelry due to an accident or another unknown circumstance?':
    'What caused the damage to your jewelry?',
  'Please provide some extra details of the service you would like for your jewelry.':
    'Please type how this occurred',
}

export const outputTextMap: Record<string, string> = {
  'Unknown Circumstance': 'It just broke',
}
