import type { User, StoreUser } from '@helloextend/extend-api-client'
import { StoreUserRole } from '@helloextend/extend-api-client'
import type { User as AccountUser } from '@helloextend/extend-api-rtk-query'
import dayjs from 'dayjs'
import { faker } from '@faker-js/faker/locale/en'

export function generateUser(overrides: Partial<User> = {}): User {
  const firstName = faker.name.firstName()
  const lastName = faker.name.lastName()

  return {
    id: faker.datatype.uuid(),
    email: faker.internet.exampleEmail(firstName, lastName),
    firstName,
    lastName,
    createdAt: faker.date.past().getTime(),
    accountId: faker.datatype.uuid(),
    role: StoreUserRole.user,
    loginStatus: 'pending',
    ...overrides,
  }
}

export function generateStoreUser(overrides: Partial<StoreUser> = {}): StoreUser {
  return {
    ...generateUser({ role: StoreUserRole.admin }),
    ...overrides,
  }
}

export function generateActiveUser(overrides: Partial<User> = {}): User {
  return {
    ...generateUser({ loginStatus: 'active', createdAt: dayjs().valueOf() }),
    ...overrides,
  }
}

export function generateAccountUser(overrides: Partial<AccountUser> = {}): AccountUser {
  const email = faker.internet.email()
  return {
    email: faker.internet.email(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    login: email,
    status: 'PROVISIONED',
    uuid: faker.datatype.uuid(),
    ...overrides,
  }
}
