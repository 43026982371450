import type { ChangeEvent, FC, SyntheticEvent } from 'react'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import type { ServiceOrder, Servicer } from '@helloextend/extend-api-client'
import { useLazyListServicersQuery } from '@helloextend/extend-api-rtk-query'
import { COLOR } from '@helloextend/zen'
import { servicers as servicersActions } from '../../actions'
import { Select, SelectItem } from '../select'
import * as selectors from '../../reducers/selectors'
import type { RootState } from '../../reducers'

interface ServicerSelectProps {
  name: string
  label?: string
  errorMessage?: string
  error?: boolean
  onChange: (name: string, fieldValue: Servicer | null) => void
  noMatchesText?: string
  placeholder?: string
  serviceType?: ServiceOrder['serviceType']
  showAllOptions?: boolean
}

const ServicerSelect: FC<ServicerSelectProps> = ({
  name,
  onChange,
  label = 'Servicer',
  errorMessage = '',
  error = false,
  noMatchesText = 'No matches found',
  placeholder = 'Search above to see options',
  showAllOptions = false,
  serviceType,
}) => {
  const dispatch = useDispatch()
  const [hasFetched, setHasFetched] = useState(false)
  const [currentFilter, setCurrentFilter] = useState('')
  const filter = useSelector((state: RootState) =>
    selectors.getServicersSearchDropdownFilter(state),
  )

  const [listServicers, { isLoading, isFetching, items }] = useLazyListServicersQuery({
    selectFromResult: (result) => {
      return {
        ...result,
        ...result.data,
      }
    },
  })

  const debouncedFetchData = debounce((query: string) => {
    if (query) {
      setCurrentFilter(query)
      listServicers({ serviceType, containsBusinessName: query, asc: true })
      setHasFetched(true)
    }
  }, 1000)

  useEffect(() => {
    if (filter === currentFilter || (isLoading && isFetching && hasFetched)) {
      return
    }
    if (filter) {
      setHasFetched(false)
      debouncedFetchData(filter)
    }
  }, [filter, currentFilter, isLoading, isFetching, debouncedFetchData, hasFetched, setHasFetched])

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    debouncedFetchData.cancel()

    const { value } = e.target

    if (!value) onChange(name, null)

    dispatch(servicersActions.searchDropdownSet(value))
  }

  const handleSelectChange = (e: SyntheticEvent<Element>): void => {
    const target = e.currentTarget as HTMLInputElement

    const servicer = items?.find((s) => s.id === target.value) || null

    if (servicer?.businessName) {
      dispatch(servicersActions.searchDropdownSet(servicer.businessName))
    }

    onChange(name, servicer)
  }

  return (
    <DropdownWrapper>
      <Select
        label={label}
        labelColor={COLOR.NEUTRAL[600]}
        onChange={handleSelectChange}
        value={filter}
        errorMessage={errorMessage}
        error={error}
        search
        onSearchChange={handleSearchChange}
      >
        {/* Only display selectable items if filter has been entered
         * and matching records are found
         */}
        {items?.length &&
          items?.map((servicer) => (
            <SelectItem key={servicer.id} value={servicer.id} label={servicer.businessName || ''} />
          ))}
        {/* Display item when no matches are found */}
        {filter.length && !items?.length && !isLoading && !isFetching && hasFetched && (
          <SelectItem value="" label={noMatchesText} selectable={false} />
        )}
        {/* Display placeholder item when no filter has been entered, if applicable */}
        {!showAllOptions && !filter.length && (
          <SelectItem value="" label={placeholder} selectable={false} />
        )}
      </Select>
    </DropdownWrapper>
  )
}

const DropdownWrapper = styled.div({
  marginBottom: 16,
})

export { ServicerSelect }
