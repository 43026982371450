import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { DataDisplayGroup, fieldMapper } from '../../../../../../components/data-display-group'
import { dataFields } from './schema'
import { TrackingInfoForm } from './tracking-info-form'

interface TrackingInfoContentProps {
  serviceOrder: ServiceOrder
}

const TrackingInfoContent: FC<TrackingInfoContentProps> = ({ serviceOrder }) => {
  const readOnlyData = useMemo(
    () => fieldMapper<ServiceOrder>(serviceOrder, dataFields),
    [serviceOrder],
  )

  return (
    <ContentWrapper>
      <DataDisplayGroup data={[{ values: readOnlyData }]} numColumns={2} />
      <TrackingInfoForm serviceOrder={serviceOrder} />
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
})

export { TrackingInfoContent }
