import type { Claim, Fulfillment } from '@helloextend/extend-api-client'
import type { Action } from '../actions'

export type ByIdReducerState = Record<string, Claim | (Claim & { fulfillments: Fulfillment[] })>

export const initialState: ByIdReducerState = {}

export default function byId(state = initialState, action: Action): ByIdReducerState {
  switch (action.type) {
    case 'CLAIMS_SEARCH_REQUEST':
      return {}
    case 'CLAIMS_FETCH_SUCCESS':
    case 'CLAIMS_CREATE_SUCCESS':
    case 'CLAIMS_UPDATE_SUCCESS':
      return addItem(state, action.payload)
    case 'CLAIMS_SEARCH_SUCCESS':
      return action.payload.reduce<ByIdReducerState>(addItem, state)
    // In general, only API actions should be in the core-api-redux package. Because it maintains
    // its own state, this utility action and usage are important to clear the internal state.
    case 'CLAIMS_LIST_RESET':
      return initialState
    default:
      return state
  }
}

function addItem(state: ByIdReducerState, item: Claim): ByIdReducerState {
  return { ...state, [item.id]: item }
}
