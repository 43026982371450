import type { FC } from 'react'
import React, { useCallback } from 'react'
import type { RangeKeyDict } from 'react-date-range'
import { DateRangePicker as ReactDateRange } from 'react-date-range'
import { isArray } from 'lodash'
import { StyledCalendar } from './styled-calendar'
import { COLOR } from '../../tokens/colors'

export interface DateRangeValue {
  start?: DateValue
  end?: DateValue
}

export type DateValue = Date | null | 0

export type PredefinedRangeOptions = {
  startDate: Date
  endDate: Date
  label: string
}

export interface DateRangePickerProps {
  hasDateDisplay?: boolean
  minDate?: Date
  /**
   * Set the latest date you want to be able to be selected.
   */
  maxDate?: Date
  onChange?: (value: DateRangeValue) => void
  /**
   * Show a default set of predefined range options by setting to true. Otherwise, set an array of custom predefined
   * options objects each with label, startDate, and endDate.
   */
  predefinedRangeOptions?: PredefinedRangeOptions[] | boolean
  /**
   * Provides an "infinite" scrolling vertical calendar view rather that just previous/next month buttons.
   *
   * Note: To prevent DOM performance issues, it's best to define a `minDate` and `maxDate` when using the vertically
   * scrollable calendar view.
   */
  isScrollable?: boolean
  value?: DateRangeValue
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
  hasDateDisplay = false,
  minDate,
  maxDate,
  onChange = () => {},
  predefinedRangeOptions = false,
  isScrollable = false,
  value,
}) => {
  const handleDateRangeChange = useCallback(
    (item: RangeKeyDict): void => {
      const range = item.selection
      onChange({ start: range.startDate, end: range.endDate })
    },
    [onChange],
  )

  const staticRanges = isArray(predefinedRangeOptions)
    ? predefinedRangeOptions.map((obj) => ({
        label: obj.label,
        range: () => ({ startDate: obj.startDate, endDate: obj.endDate }),
        isSelected: () => false,
      }))
    : undefined

  return (
    <StyledCalendar hasPredefinedOptions={!!predefinedRangeOptions}>
      <ReactDateRange
        ranges={[
          {
            key: 'selection',
            startDate: value?.start || new Date(),
            endDate: value?.end || new Date(),
          },
        ]}
        onChange={handleDateRangeChange}
        scroll={{ enabled: isScrollable }}
        minDate={minDate}
        maxDate={maxDate}
        inputRanges={[]}
        color={COLOR.BLUE[700]}
        rangeColors={[COLOR.BLUE[700]]}
        showDateDisplay={hasDateDisplay}
        startDatePlaceholder="Unset"
        endDatePlaceholder="Unset"
        staticRanges={staticRanges}
      />
    </StyledCalendar>
  )
}
