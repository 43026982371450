import type { FC, PropsWithChildren } from 'react'
import React from 'react'
import styled from '@emotion/styled'

interface ButtonGroupProps {
  isReversed?: boolean
  'data-cy'?: string
}

const ButtonGroup: FC<PropsWithChildren<ButtonGroupProps>> = ({
  'data-cy': dataCy,
  isReversed = false,
  children,
}) => {
  return (
    <StyledButtonGroup data-cy={dataCy} isReversed={isReversed}>
      {children}
    </StyledButtonGroup>
  )
}

export { ButtonGroup }

const StyledButtonGroup = styled.div<{
  isReversed: boolean
}>(({ isReversed }) => ({
  display: 'flex',
  gap: 8,
  ...(isReversed && {
    flexDirection: 'row-reverse',
  }),
  alignItems: 'center',
  flexWrap: 'wrap',
}))
