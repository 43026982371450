import type { StoreUser } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

// users fetch
// =================================================================================
export const fetchUser = (userId: string, accessToken: string) =>
  ({
    type: 'USER_FETCH',
    payload: { accessToken, userId },
  } as const)

export const fetchUserRequest = () =>
  ({
    type: 'USER_FETCH_REQUEST',
  } as const)

export const fetchUserSuccess = (user: StoreUser) =>
  ({
    type: 'USER_FETCH_SUCCESS',
    payload: user,
  } as const)

export const fetchUserFailure = createActionFailure('USER_FETCH_FAILURE')
