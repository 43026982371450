import React from 'react'
import { Radio, RadioGroup } from '@helloextend/zen'
import { useFormikContext } from 'formik'
import type { FC } from 'react'
import type { Values } from '../shipping-protection-purchase-model-form/schema'
import { purchaseModelLabels } from '../shipping-protection-purchase-model-form/schema'

const ShippingProtectionRadioGroup: FC = () => {
  const { values, handleChange } = useFormikContext<Values>()
  return (
    <RadioGroup
      data-cy="sp-purchase-model-radio"
      label="Select a Purchase Model"
      name="purchaseModel"
      onChange={handleChange}
      value={values.purchaseModel}
    >
      <Radio label={purchaseModelLabels.SINGLE_PLAN} value="SINGLE_PLAN" />
      <Radio label={purchaseModelLabels.CATEGORY} value="CATEGORY" />
      <Radio label={purchaseModelLabels.PRICE_BAND} value="PRICE_BAND" />
    </RadioGroup>
  )
}

export { ShippingProtectionRadioGroup }
