import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/zen'
import { AdjudicationPromptWrapper } from './adjudication-prompt-wrapper'

interface PromptButtonProps {
  text: string
  bordered: boolean
  badgeValue: number | string
  onBadgeClick: (val: number | string) => void
  promptIndex: number
}

const AdjudicationPromptButton: FC<PromptButtonProps> = ({
  text,
  bordered,
  badgeValue,
  onBadgeClick,
  promptIndex,
}) => {
  return (
    <AdjudicationPromptWrapper
      badgeValue={badgeValue}
      hasBottomBorder={!bordered}
      onBadgeClick={onBadgeClick}
      promptIndex={promptIndex}
    >
      <StyledButton data-cy="adjudication-management-prompt-button" bordered={bordered}>
        {text === '' ? '-' : text}
      </StyledButton>
    </AdjudicationPromptWrapper>
  )
}

const StyledButton = styled.div<{ bordered?: boolean }>(({ bordered }) => {
  return {
    borderRadius: bordered ? '20px' : undefined,
    padding: bordered ? '4px 12px' : '4px 0px',
    border: bordered ? '1px solid #6B7887' : undefined,
    borderColor: bordered ? COLOR.NEUTRAL[600] : undefined,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: COLOR.NEUTRAL[800],
    flexGrow: 1,
  }
})

export { AdjudicationPromptButton }
