const isoDateStringRegex =
  /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/

/** Recursively search a value for ISO date strings and replace them with Date type values.
 * This is useful for JSON parsed values because JSON cannot represent Date types and instead
 * replaces them with ISO date strings.
 */
const jsonDateFix = (v: unknown): unknown => {
  if (Array.isArray(v)) {
    return v.map(jsonDateFix)
  }

  if (typeof v === 'object' && v) {
    const newObj: Record<string, unknown> = {}

    for (const key of Object.keys(v)) {
      newObj[key] = jsonDateFix((v as Record<string, unknown>)[key])
    }

    return newObj
  }

  if (typeof v === 'string' && v && v.match(isoDateStringRegex)) {
    return new Date(v)
  }

  return v
}

export { jsonDateFix }
