import type { ChangeEvent } from 'react'
import React, { useCallback, useImperativeHandle, useRef, forwardRef } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../tokens/colors'
import { Checkmark } from '../checkbox'

export interface MenuCheckboxProps {
  'aria-label'?: string
  checked: boolean
  children?: React.ReactNode
  'data-cy'?: string
  disabled?: boolean
  indentLevel?: number
  indeterminate?: boolean
  label?: string
  name?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  role?: 'menuitem' | 'option' | 'menuitemcheckbox'
  tabIndex?: number
  value?: string
  _hasCursor?: boolean
}

export const MenuCheckbox = forwardRef<HTMLLabelElement, MenuCheckboxProps>(
  (
    {
      'aria-label': ariaLabel,
      checked = false,
      children,
      'data-cy': dataCy,
      disabled = false,
      indentLevel = 0,
      indeterminate = false,
      label,
      name,
      onChange = () => {},
      role = 'option',
      tabIndex,
      value,
      _hasCursor,
    },
    ref,
  ) => {
    const menuCheckboxRef = useRef<HTMLLabelElement>(null)
    useImperativeHandle(ref, () => menuCheckboxRef.current as HTMLLabelElement)

    const handleMenuCheckboxClick = useCallback(() => {
      menuCheckboxRef.current?.focus()
    }, [])

    return (
      <StyledMenuCheckbox
        data-cy={dataCy && `${dataCy}`}
        isChecked={checked}
        isDisabled={disabled}
        hasCursor={_hasCursor}
        ref={menuCheckboxRef}
        role={role}
        aria-checked={checked}
        aria-selected={checked}
        aria-disabled={disabled}
        aria-label={ariaLabel}
        tabIndex={_hasCursor ? 0 : tabIndex}
        onClick={handleMenuCheckboxClick}
        {...(_hasCursor !== undefined && { 'data-is-cursorable': true })}
      >
        <StyledArea>
          <Content indentLevel={indentLevel}>
            <CheckmarkContainer>
              <Checkmark
                data-cy={dataCy && `${dataCy}:checkmark`}
                value={value}
                name={name}
                checked={checked}
                indeterminate={indeterminate}
                disabled={disabled}
                onChange={onChange}
                hideInteractivity
              />
            </CheckmarkContainer>
            <Children>
              {label}
              {children}
            </Children>
          </Content>
        </StyledArea>
      </StyledMenuCheckbox>
    )
  },
)

const StyledArea = styled.span({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 4,
  borderRadius: 4,
  padding: '10px 12px',
})

const Content = styled.span<{
  indentLevel: number
}>(({ indentLevel }) => ({
  display: 'flex',
  gap: 8,
  minWidth: 0,
  paddingLeft: indentLevel * 30,
}))

const Children = styled.span({
  position: 'relative',
  display: 'block',
  maxWidth: '100%',
})

const StyledMenuCheckbox = styled.label<{
  hasCursor?: boolean
  isChecked: boolean
  isDisabled: boolean
}>(({ hasCursor, isChecked, isDisabled }) => ({
  display: 'block',
  padding: '2px 0',
  color: isChecked && !isDisabled ? COLOR.NEUTRAL[1000] : COLOR.NEUTRAL[800],
  transition: '50ms',
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: 500,
  ...(hasCursor
    ? {
        outline: 'none',
        [`& > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[12],
        },
        ...(!isDisabled && {
          cursor: 'pointer',
          [`&:hover > ${StyledArea}`]: {
            background: COLOR.NEUTRAL.OPACITY[25],
          },
          [`&:active > ${StyledArea}`]: {
            background: COLOR.NEUTRAL.OPACITY[12],
          },
        }),
      }
    : {
        [`& > ${StyledArea}`]: {
          background: 'transparent',
        },
        ...(!isDisabled && {
          cursor: 'pointer',
          [`&:hover > ${StyledArea}`]: {
            background: COLOR.NEUTRAL.OPACITY[12],
          },
          [`&:active > ${StyledArea}`]: {
            background: COLOR.NEUTRAL.OPACITY[25],
          },
        }),
      }),
  ...(isChecked && {
    WebkitTextStroke: '0.04em',
  }),
}))

const CheckmarkContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  width: 24,
  height: 24,
  margin: '-2px 0 -2px -2px',
})
