import { EXTEND_API_HOST } from '@helloextend/client-constants'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { OAuthTokenRequest, OAuthTokenResponse, ResendEmailRequest } from './types'

export const popaApi = createApi({
  reducerPath: 'popa',
  baseQuery: fetchBaseQuery({ baseUrl: `https://${EXTEND_API_HOST}` }),
  tagTypes: ['popa'],
  endpoints: (build) => ({
    oAuthToken: build.mutation<OAuthTokenResponse, OAuthTokenRequest>({
      query: (body) => ({
        url: '/auth/oauth/token',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['popa'],
    }),
    resendPopaEmail: build.mutation<void, ResendEmailRequest>({
      query: ({ serviceOrderId, body }) => ({
        url: `/service-orders/${serviceOrderId}/start-popa-challenge`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['popa'],
    }),
  }),
})

export const { useOAuthTokenMutation, useResendPopaEmailMutation } = popaApi
