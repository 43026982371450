import type { FC } from 'react'
import React, { useMemo, useState, useRef, useEffect } from 'react'
import type {
  Money,
  ProductReplacementFulfillmentMetaData,
  ServiceOrder,
} from '@helloextend/extend-api-client'
import { ClaimSelectStatus } from '@helloextend/extend-api-client'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { StackJustify } from '@helloextend/zen/src/components/stack'
import styled from '@emotion/styled'
import { useGetWalletQuery } from '@helloextend/extend-api-rtk-query'
import { currency, date } from '@helloextend/client-utils'
import type {
  PoPAEmailsMetaData,
  ServiceOrderPoPAEmails,
  VirtualCardFulfillmentMetaData,
} from '@helloextend/extend-api-client/src/models/service-order'
import { AccordionSection, Box, Button, COLOR, DataProperty, Stack } from '@helloextend/zen'
import { startCase } from 'lodash/fp'
import { useDispatch, useSelector } from 'react-redux'
import type { BadgeDescriptionItem } from '../../../../../../../service-order-status'
import { getServiceOrderStatusDetails } from '../../../../../../../service-order-status'
import { ServiceOrderActionsMenu } from '../../../../../service-order-actions-menu'
import { FailedProductShipmentSection } from './failed-product-shipment'
import { FulfilledProductShipmentSection } from './fulfilled-product-shipment'
import { Expenses } from './expenses'
import type { RootState } from '../../../../../../../../../../reducers'
import * as selectors from '../../../../../../../../../../reducers/selectors'
import { setClaimDetailsActiveView } from '../../../../../../../../../../store/slices/claim-details'
import { ClaimPageTab } from '../../../../types'
import { LDFlag } from '../../../../../../../../../../constants/ld-flags'
import { getServiceOrderModalMap } from '../../../../../../../service-order-modal-map'

interface ExpandedServiceOrderSectionProps {
  serviceOrder: ServiceOrder
  isActive: boolean
  entitlements?: { coverageAmountRemaining: Money }
}

const formatStartCase = (value?: string): string => startCase(value ?? '')

const ExpandedServiceOrderSection: FC<ExpandedServiceOrderSectionProps> = ({
  serviceOrder,
  isActive,
  entitlements,
}) => {
  const [isExpanded, setIsExpanded] = useState(isActive)
  const [expensesExpandSignal, setExpensesExpandSignal] = useState(false)
  const badgeDescription = useMemo((): BadgeDescriptionItem | null => {
    return getServiceOrderStatusDetails(serviceOrder)
  }, [serviceOrder])

  const { [LDFlag.CancelVirtualCard]: FF_CANCEL_VIRTUAL_CARD } = useFlags()

  const { data: wallet } = useGetWalletQuery(
    serviceOrder
      ? (serviceOrder.fulfillmentMetaData as VirtualCardFulfillmentMetaData)?.walletId ?? ''
      : '',
    {
      skip:
        !FF_CANCEL_VIRTUAL_CARD ||
        !serviceOrder ||
        !serviceOrder.fulfillmentMetaData ||
        !(serviceOrder.fulfillmentMetaData as VirtualCardFulfillmentMetaData).walletId,
    },
  )

  const handleToggle = (): void => {
    setIsExpanded(!isExpanded)
  }
  const dispatch = useDispatch()
  const activeView = useSelector((state: RootState) => selectors.getActiveClaimDetailsView(state))
  const scrollRef = useRef<HTMLDivElement>(null)

  const buttonProps = useMemo(() => {
    if (!serviceOrder?.status) return null
    const {
      text,
      action,
      buttonProps: props,
    } = getServiceOrderModalMap(serviceOrder.status, serviceOrder?.serviceType) ?? {}
    if (!action) return null
    return {
      text: `${text}`,
      onClick: () => {
        dispatch(setClaimDetailsActiveView(action))
      },
      emphasis: props?.emphasis ?? 'high',
    }
  }, [serviceOrder?.status, serviceOrder?.serviceType, dispatch])

  useEffect(() => {
    if (activeView === ClaimPageTab.Expenses && serviceOrder.status !== ClaimSelectStatus.closed) {
      setIsExpanded(true)
      setExpensesExpandSignal(!expensesExpandSignal)

      // TODO: [DEPOT-2379] Abstract auto-scrolling functionality
      const element = scrollRef.current
      if (element) {
        setTimeout(
          () => element.scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'nearest' }),
          400,
        )
        dispatch(setClaimDetailsActiveView(''))
      }
    }
  }, [activeView, dispatch, scrollRef, expensesExpandSignal, serviceOrder.status])

  const getFulfillmentMethodLabel = (): string => {
    switch (serviceOrder?.configurations?.replacementFulfillmentMethod) {
      case 'discount_code':
        return 'Credit'
      case 'manual':
        return 'Manual'
      case 'virtual_card':
        return `Virtual Card${
          serviceOrder?.fulfillmentMetaData?.status === 'cancelled' ? ' - Canceled' : ''
        }`
      default:
        return '—'
    }
  }

  const getReasonText = (): string => {
    if (!wallet?.closedMetaData?.reason) return '—'
    switch (wallet.closedMetaData?.reason) {
      case 'address_verification_failure':
        return 'Address verification failure on merchant site'
      case 'generic_card_decline':
        return 'Generic card decline on merchant site'
      case 'product_unavailable':
        return 'Product unavailable / Out of stock at merchant'
      default:
        return 'other'
    }
  }

  const getVirtualCardEmailSentDate = (popaEmailsMetaData?: PoPAEmailsMetaData): string => {
    if (!popaEmailsMetaData) return '—'
    const popaEvents: ServiceOrderPoPAEmails[] = [
      'virtual-card-service-order-fulfilled',
      'virtual-card-partially-redeemed',
    ]
    const lastSentDate = popaEvents.reduce(
      (latestDate: number, currentKey: ServiceOrderPoPAEmails) => {
        const eventMetadata = popaEmailsMetaData[currentKey]
        if (!eventMetadata?.lastSentAt) return latestDate
        if (eventMetadata.lastSentAt > latestDate)
          return popaEmailsMetaData[currentKey]?.lastSentAt ?? latestDate
        return latestDate
      },
      0,
    )

    return lastSentDate === 0 ? '—' : `Sent ${date.format(lastSentDate, 'MMM DD YYYY')}`
  }

  return (
    <Box padding={4}>
      <AccordionSection
        isExpanded={isExpanded}
        onToggleRequest={handleToggle}
        headingSize="large"
        data-cy={`${serviceOrder.id}-base-accordion`}
        toolbar={
          serviceOrder.status !== 'closed' && (
            <>
              <ServiceOrderActionsMenu
                serviceOrder={serviceOrder}
                buttonText="SO Actions"
                buttonSize="small"
                cyPrefix="so-tab"
              />
            </>
          )
        }
        badgeProps={
          badgeDescription
            ? {
                text: badgeDescription.badgeText,
                color: badgeDescription.badgeColor,
                details: badgeDescription.badgeDetails,
              }
            : undefined
        }
        heading={`${isActive ? 'Active ' : ''} Service Order`}
      >
        <Stack justify={StackJustify.spaceBetween} data-cy={`${serviceOrder.id}-props`} doesWrap>
          <DataProperty label="Service Order ID" value={serviceOrder.id} />
          <DataProperty label="Service Type" value={formatStartCase(serviceOrder.serviceType)} />
          <DataProperty
            label="Created Date"
            value={date.format(serviceOrder.createdAt, 'MMM DD YYYY')}
          />
          <DataProperty
            label="Remaining Limit of Liability"
            value={
              entitlements
                ? currency.format(
                    entitlements.coverageAmountRemaining.amount,
                    entitlements.coverageAmountRemaining.currencyCode,
                  )
                : '—'
            }
          />
          <DataProperty
            label="Servicer"
            data-cy="servicer-label"
            value={serviceOrder.assignedServicer?.businessName || '—'}
          />
          {serviceOrder.serviceType === 'replace' && (
            <>
              <DataProperty
                data-cy="replacement-item-label"
                label="Replacement Item"
                value={serviceOrder?.items?.[0]?.title || '—'}
              />
              {serviceOrder.fulfillmentMetaData?.method === 'product_replacement' && (
                <DataProperty
                  data-cy="order-number-label"
                  label="Order Number"
                  value={
                    (serviceOrder?.fulfillmentMetaData as ProductReplacementFulfillmentMetaData)
                      ?.orderNumber || '—'
                  }
                />
              )}
              <DataProperty
                data-cy="fulfillment-resolution-label"
                label="Fulfillment Resolution"
                value={getFulfillmentMethodLabel()}
              />
              {serviceOrder.fulfillmentMetaData?.method === 'virtual_card' && (
                <>
                  <DataProperty
                    data-cy="fulfillment-email-label"
                    label="Fulfillment Email"
                    value={getVirtualCardEmailSentDate(serviceOrder.popaEmailsMetaData)}
                  />
                  {serviceOrder.fulfillmentMetaData?.status === 'cancelled' && wallet && (
                    <>
                      <DataProperty
                        data-cy="reason-virtual-card-canceled-label"
                        label="Reason Virtual Card Canceled"
                        value={getReasonText()}
                      />
                      <DataProperty
                        data-cy="notes-label"
                        label="Notes"
                        value={wallet.closedMetaData?.notes ?? '—'}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
          {serviceOrder.serviceType === 'repair_depot' && (
            <>
              <DataProperty
                data-cy="repair-completed-date-label"
                label="Repair Completed Date"
                value={
                  serviceOrder?.repairMetaData?.repairCompletedAt
                    ? date.format(serviceOrder?.repairMetaData?.repairCompletedAt, 'MMM DD YYYY')
                    : '—'
                }
              />
              <DataProperty
                data-cy="rma-number-label"
                label="RMA Number"
                value={serviceOrder?.rmaNumber || '—'}
              />
              <DataProperty
                data-cy="diagnostic-explanation-label"
                label="Diagnostic Explanation"
                value={serviceOrder?.repairMetaData?.diagnosticExplanation || '—'}
              />
            </>
          )}
          {serviceOrder.fulfillmentMetaData?.method === 'credit' && (
            <DataProperty
              data-cy="redemption-url-label"
              label="Redemption URL"
              value={serviceOrder.fulfillmentMetaData?.redemptionUrl || '—'}
            />
          )}
        </Stack>
        <Line />
        <FailedProductShipmentSection serviceOrder={serviceOrder} />
        <Line />
        <FulfilledProductShipmentSection serviceOrder={serviceOrder} />
        <Line />
        <Expenses serviceOrder={serviceOrder} expandSignal={expensesExpandSignal} />
        <Line />
        {isActive && buttonProps && (
          <ButtonRow>
            <Button data-cy={`${buttonProps.text}-btn`} {...buttonProps} size="regular" />
          </ButtonRow>
        )}
        <div ref={scrollRef} />
      </AccordionSection>
    </Box>
  )
}

const ButtonRow = styled.div({
  display: 'flex',
  gap: 20,
  marginTop: 40,
})

const Line = styled.div({
  width: '100%',
  height: 1,
  borderTop: `1px solid ${COLOR.NEUTRAL[300]}`,
  margin: '25px 0',
})

export { ExpandedServiceOrderSection }
