import jwt from 'jsonwebtoken'
import { all, call, takeLatest } from 'redux-saga/effects'
import { isUndefined } from 'lodash/fp'
import type { SagaIterator } from 'redux-saga'
import { getParamsFromUrl } from '../utils/get-params-from-url'
import type { ActionType } from '../actions'
import { redirectTo } from '../utils/redirecto'

/**
 * Listens for reset-password success/failed sagas
 * and derives for the correct redirect location
 */
export function* resetPasswordRedirects(): SagaIterator {
  yield all([
    takeLatest('RESET_PASSWORD_SUCCESS' as ActionType, forgotPasswordSuccess),
    takeLatest('RESET_PASSWORD_FAILURE' as ActionType, forgotPasswordFailure),
  ])
}

export function* forgotPasswordSuccess(): SagaIterator {
  const accessToken = yield call(getParamsFromUrl, 'code')
  const response = yield call(jwt.decode, accessToken)
  const email = response.sub
  if (isUndefined(email)) {
    yield call(redirectTo, '/login?password_reset=success')
    return
  }
  const encodedEmail = encodeURIComponent(email)
  yield call(redirectTo, `/login?password_reset=success&user=${encodedEmail}`)
}

export function* forgotPasswordFailure(): SagaIterator {
  const accessToken = yield call(getParamsFromUrl, 'code')
  const response = yield call(jwt.decode, accessToken)
  const email = response.sub
  // in the case where the token is un-identifiable
  // redirect the client to sans email
  if (isUndefined(email)) {
    yield call(redirectTo, '/password/forgot?error=expired_token')
    return
  }
  const encodedEmail = encodeURIComponent(email)
  yield call(redirectTo, `/password/forgot?error=expired_token&user=${encodedEmail}`)
}
