import type { FC, SyntheticEvent, ClipboardEvent } from 'react'
import React, { useEffect, useState, useMemo } from 'react'
import type { FormikErrors, FormikTouched } from 'formik'
import {
  useListPlanTermsQuery,
  useListPlanAttributesQuery,
} from '@helloextend/extend-api-rtk-query'
import { Button, InlineAlert, InlineAlertColor, COLOR } from '@helloextend/zen'
import { Info, ContentCopy, Trash } from '@helloextend/zen/src/tokens/icons'
import styled from '@emotion/styled'
import { FormTextGroup } from '../../../components/form-text-group'
import type {
  DropdownItem,
  FormTextGroupProps,
  TextAreaInputItem,
} from '../../../components/form-text-group/form-text-group'
import type { DropdownOption } from '../../../components/dropdown'
import type { Values } from './schema'
import { configureARandBSDFieldValidationInfo } from '../../../utils/allowed-region-blocked-subdivision-validation'
import { RepeatableRow } from '../../../components/repeatable-row'
import { toRepeatableRowItems } from './to-repeatable-row-items'
import { toRow } from './row-config'
import {
  isClipboardCSV,
  mapClipboardToPricing,
  mapPricingToClipboard,
  mergePricingBySku,
} from '../../../utils/plans-csv-utils'
import type { PricingRow } from '../../../types/plans'
import { toPlanAttributesDropdownOptions } from '../../../utils/plan-attributes-dropdown-mapping'

type PlanFormProps = {
  handleChange: FormTextGroupProps['handleChange']
  handleBlur: (e: SyntheticEvent) => void
  errors: FormikErrors<Values>
  touched: FormikTouched<Values>
  isDisabled?: boolean
  isCreate?: boolean
  values: Values
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<any>
  setErrors: (errors: FormikErrors<Values>) => void
}

const PlanForm: FC<PlanFormProps> = ({
  handleChange,
  handleBlur,
  errors,
  touched,
  isDisabled = false,
  isCreate = false,
  values,
  setFieldValue,
  setErrors,
}) => {
  // Plans attributes validation feature flag
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(true)

  const { data: planTerms, isFetching: isFetchingTerms } = useListPlanTermsQuery()
  const { data: planAttributes, isFetching: isFetchingAttributes } = useListPlanAttributesQuery(
    undefined,
    {
      selectFromResult: ({ data, ...rest }) => {
        const attributesList = toPlanAttributesDropdownOptions(data?.items || [])
        return {
          data: attributesList,
          ...rest,
        }
      },
    },
  )

  // Used in place of the standard `useFormik` handleChange function in order to fix a UI bug where clicking directly
  // on the text in a dropdown option didn't register.
  const handleDropdownChange = (e: SyntheticEvent<HTMLSelectElement | HTMLButtonElement>): void => {
    const { value, name } = e.currentTarget
    const { [name]: removedError, ...rest } = errors as { [key: string]: string }
    setFieldValue(name, value)
    setErrors(rest)
  }

  const handlePricingChange = (e: SyntheticEvent<HTMLInputElement>, index: number): void => {
    const { value, id } = e.currentTarget
    setFieldValue(`pricing[${index}].${id.split('-')[0]}`, value, true)
  }

  const handleRowDelete = (index: number): void => {
    if (!values.pricing || !Array.isArray(values.pricing)) return
    const newPricing = values.pricing
      .slice(0, index)
      .concat(values.pricing.slice(index + 1, values.pricing.length))
    setFieldValue('pricing', newPricing)
  }

  const handleRowAdd = (): void => {
    const emptyPricingRow = {
      vendorSku: '',
      priceBandLow: '',
      priceBandHigh: '',
      cost: '',
      retailTarget: '',
      fixedPrice: '',
      retailMax: '',
    }
    if (!values.pricing || !Array.isArray(values.pricing)) {
      setFieldValue('pricing', [emptyPricingRow])
    } else {
      const newPricing = [...values.pricing, emptyPricingRow]
      setFieldValue('pricing', newPricing)
    }
  }

  const handlePasteEvent = (e: ClipboardEvent, rowIndex: number): void => {
    e.preventDefault()
    const { currentTarget } = e
    const clipboardData = e.clipboardData.getData('text')

    if (!isClipboardCSV(clipboardData)) {
      // Handle paste event for non-CSV data
      setFieldValue(`pricing[${rowIndex}].${currentTarget.id}`, clipboardData)
      return
    }
    const pricingData = mapClipboardToPricing(clipboardData)
    const finalData = mergePricingBySku(values.pricing, pricingData)
    setFieldValue('pricing', finalData)
  }

  const handleCloseAlert = (): void => {
    setIsAlertVisible(false)
  }

  const planTermOptions: DropdownOption[] =
    planTerms?.map((term) => {
      return {
        label: term.termsId,
        value: term.termsId,
      }
    }) || []

  // Once `planAttributes` have been loaded by the query, they are passed to `configureARandBSDFieldValidationInfo`
  // so that the loaded `allowed_regions` and `blocked_sub_divisions` values can be used for validating user input.
  useEffect(() => {
    if (!planAttributes || !planAttributes.allowed_regions || !planAttributes.blocked_sub_division)
      return

    configureARandBSDFieldValidationInfo(planAttributes)
  }, [planAttributes])

  useEffect(() => {
    const expandTextArea = (id: string): void => {
      const element = document.getElementById(id)
      if (element) {
        element.style.height = `${element.scrollHeight}px`
      }
    }
    setTimeout(() => {
      expandTextArea('sku')
    }, 0)
    setTimeout(() => {
      expandTextArea('pricing')
    }, 0)
  }, [])

  const isPcmiCategoryValid = useMemo(
    () =>
      values.pcmiCategory &&
      planAttributes?.pcmi_category?.some((attr) => attr.value === values.pcmiCategory),
    [planAttributes?.pcmi_category, values.pcmiCategory],
  )

  const isPcmiSubCategoryValid = useMemo(
    () =>
      values.pcmiSubcategory &&
      planAttributes?.pcmi_subcategory?.some((attr) => attr.value === values.pcmiSubcategory),
    [planAttributes?.pcmi_subcategory, values.pcmiSubcategory],
  )

  useEffect(() => {
    const isEditModeAndPcmiCategoryInvalid = !isCreate && !isDisabled && !isPcmiCategoryValid
    const isEditModeAndPcmiSubcategoryInvalid = !isCreate && !isDisabled && !isPcmiSubCategoryValid
    if (isEditModeAndPcmiCategoryInvalid) {
      setFieldValue('pcmiCategory', undefined)
    }
    if (isEditModeAndPcmiSubcategoryInvalid) {
      setFieldValue('pcmiSubcategory', undefined)
    }
  }, [setFieldValue, isCreate, isDisabled, isPcmiCategoryValid, isPcmiSubCategoryValid])

  const handleClickCopyValues = (): void => {
    const { pricing }: { pricing: PricingRow[] } = values
    if (!values.pricing || !Array.isArray(values.pricing)) return
    const mappedValues = mapPricingToClipboard(pricing)
    navigator.clipboard.writeText(mappedValues)
  }

  const handleClickDeleteAllRows = (): void => {
    if (!values.pricing || !Array.isArray(values.pricing)) return
    setFieldValue('pricing', [])
  }
  return (
    <>
      <FormTextGroup
        title="Plan Details"
        handleChange={handleChange}
        handleBlur={handleBlur}
        isDisabled={isDisabled}
        numColumns={2}
        values={[
          {
            name: 'id',
            label: 'Plan ID',
            value: values.id,
            isItemDisabled: !isCreate,
            error: errors.id,
            touched: touched.id,
          },
          {
            name: 'name',
            label: 'Plan Name',
            value: values.name,
            error: errors.name,
            touched: touched.name,
          },
          {
            name: 'warrantyCategory',
            label: 'Warranty Category',
            value: values.warrantyCategory,
            error: errors.warrantyCategory,
            touched: touched.warrantyCategory,
            fieldType: 'dropdown',
            options: planAttributes?.category || [],
            isLoading: isFetchingAttributes,
            handleDropdownChange,
            helperText: 'Adjudication attribute',
          },
          {
            name: 'secondaryName',
            label: 'Secondary Name',
            value: values.secondaryName,
            error: errors.secondaryName,
            touched: touched.secondaryName,
          },
          {
            name: 'program',
            label: 'Program',
            value: values.program,
            handleDropdownChange,
            error: errors.program,
            touched: touched.program,
            fieldType: 'dropdown',
            options: planAttributes?.program ?? [],
            isLoading: isFetchingAttributes,
          },
          {
            name: 'subProgram',
            label: 'Subprogram',
            value: values.subProgram,
            handleDropdownChange,
            error: errors.subProgram,
            touched: touched.subProgram,
            fieldType: 'dropdown',
            options: planAttributes?.sub_program ?? [],
            isLoading: isFetchingAttributes,
          },
          {
            name: 'planCategory',
            label: 'Plan Category',
            value: values.planCategory,
            handleDropdownChange,
            error: errors.planCategory,
            touched: touched.planCategory,
            fieldType: 'dropdown',
            options: planAttributes?.plan_category ?? [],
            isLoading: isFetchingAttributes,
          },
          {
            name: 'wdTags',
            label: 'Warranty Decision Tags',
            value: values.wdTags,
            fieldType: 'textarea',
            placeholder: 'Comma separated list',
            error: errors.wdTags,
            touched: touched.wdTags,
          } as TextAreaInputItem,
        ]}
      />
      <FormTextGroup
        title="Plan Terms"
        handleChange={handleChange}
        handleBlur={handleBlur}
        isDisabled={isDisabled}
        numColumns={2}
        values={[
          {
            name: 'coverageStarts',
            label: 'Coverage Starts',
            value: values.coverageStarts,
            handleDropdownChange,
            error: errors.coverageStarts,
            touched: touched.coverageStarts,
            fieldType: 'dropdown',
            options: planAttributes?.coverage_starts || [],
            isLoading: isFetchingAttributes,
          },
          {
            name: 'coverageIncludes',
            label: 'Coverage Type',
            value: values.coverageIncludes,
            error: errors.coverageIncludes,
            touched: touched.coverageIncludes,
            fieldType: 'dropdown',
            options: planAttributes?.coverage_includes || [],
            isLoading: isFetchingAttributes,
            handleDropdownChange,
          },
          {
            name: 'termLength',
            label: 'Term Length',
            value: values.termLength,
            error: errors.termLength,
            touched: touched.termLength,
          },
          {
            name: 'active',
            label: 'Active',
            value: values.active,
            error: errors.active,
            touched: touched.active,
          },
          {
            name: 'underwriter',
            label: 'Underwriter',
            value: values.underwriter,
            error: errors.underwriter,
            touched: touched.underwriter,
            fieldType: 'dropdown',
            options: planAttributes?.underwriter || [],
            isLoading: isFetchingAttributes,
            handleDropdownChange,
          },
          {
            name: 'serviceType',
            label: 'Service Type',
            value: values.serviceType,
            error: errors.serviceType,
            touched: touched.serviceType,
            fieldType: 'dropdown',
            options: planAttributes?.service_type || [],
            isLoading: isFetchingAttributes,
            handleDropdownChange,
          },
          {
            name: 'replacementType',
            label: 'Replacement Type',
            value: values.replacementType ?? '',
            error: errors.replacementType,
            touched: touched.replacementType,
            fieldType: 'dropdown',
            options: planAttributes?.replacement_type || [],
            isLoading: isFetchingAttributes,
            handleDropdownChange,
          },
          {
            name: 'deductible',
            label: 'Deductible',
            value: values.deductible ?? undefined,
            error: errors.deductible,
            touched: touched.deductible,
          },
          {
            name: 'administrator',
            label: 'Administrator',
            value: values.administrator ?? '',
            error: errors.administrator,
            touched: touched.administrator,
            fieldType: 'dropdown',
            options: planAttributes?.administrator || [],
            isLoading: isFetchingAttributes,
            handleDropdownChange,
          },
          {
            name: 'termsId',
            label: 'Terms ID',
            value: values.termsId,
            error: errors.termsId,
            touched: touched.termsId,
            fieldType: 'dropdown',
            options: planTermOptions,
            isLoading: isFetchingTerms,
            handleDropdownChange,
          },
          {
            name: 'servicerId',
            label: 'Servicer ID',
            value: values.servicerId ?? '',
            error: errors.servicerId,
            touched: touched.servicerId,
          },
          {
            name: 'productTypes',
            label: 'Product Types',
            value: values.productTypes ?? '',
            error: errors.productTypes,
            touched: touched.productTypes,
          },
          {
            name: 'vendor',
            label: 'Vendor',
            value: values.vendor ?? '',
            error: errors.vendor,
            touched: touched.vendor,
            fieldType: 'dropdown',
            options: planAttributes?.vendor || [],
            isLoading: isFetchingAttributes,
            handleDropdownChange,
          },
          {
            name: 'repairThreshold',
            label: 'Repair Threshold',
            value: values.repairThreshold ?? undefined,
            error: errors.repairThreshold,
            touched: touched.repairThreshold,
            placeholder: 'Enter whole dollar amount',
          },
          {
            name: 'productNotes',
            label: 'Product Notes',
            value: values.productNotes ?? '',
            fieldType: 'textarea',
            error: errors.productNotes,
            touched: touched.productNotes,
          },
        ]}
      />
      <FormTextGroup
        title="Geographical Details"
        handleChange={handleChange}
        handleBlur={handleBlur}
        isDisabled={isDisabled}
        numColumns={2}
        values={[
          {
            name: 'currencyCode',
            label: 'Currency Code',
            block: true,
            value: values.currencyCode,
            error: errors.currencyCode,
            touched: touched.currencyCode,
            fieldType: 'dropdown',
            options: planAttributes?.currency_code || [],
            isLoading: isFetchingAttributes,
            handleDropdownChange,
          },
          {
            name: 'allowedRegions',
            label: 'Allowed Regions',
            value: values.allowedRegions,
            fieldType: 'textarea',
            placeholder: 'Comma separated list',
            error: errors.allowedRegions,
            touched: touched.allowedRegions,
          },
          {
            name: 'blockedSubDivisions',
            label: 'Blocked Sub Divisions',
            value: values.blockedSubDivisions ?? '',
            fieldType: 'textarea',
            isItemDisabled: isDisabled || (!values.blockedSubDivisions && !values.allowedRegions),
            placeholder: 'Comma separated list',
            error: errors.blockedSubDivisions,
            touched: touched.blockedSubDivisions,
          },
        ]}
      />
      <Container>
        <Title>Pricing & Premiums</Title>
        {isAlertVisible && (
          <AlertContainer>
            <InlineAlert
              color={InlineAlertColor.blue}
              isDismissable
              icon={Info}
              onDismiss={handleCloseAlert}
            >
              You can copy and paste directly from a spreadsheet—the number of rows will expand to
              fit your data.
            </InlineAlert>
          </AlertContainer>
        )}
        <PricingContainer>
          {values?.pricing?.length > 0 && (
            <ButtonsContainer>
              <Button
                text="Delete All Rows"
                emphasis="low"
                icon={Trash}
                color="red"
                onClick={handleClickDeleteAllRows}
                isDisabled={isDisabled}
                data-cy="delete-all-rows"
              />
              <Button
                text="Copy All"
                emphasis="low"
                icon={ContentCopy}
                onClick={handleClickCopyValues}
                data-cy="copy-all"
              />
            </ButtonsContainer>
          )}
          <RepeatableRow
            handleRowAdd={handleRowAdd}
            handleRowDelete={(id) => handleRowDelete(id)}
            data={toRepeatableRowItems(values.pricing ?? [], handlePricingChange, isDisabled)}
            renderRow={(row, index) => toRow(row, index, handlePasteEvent, errors, index > 0)}
          />
        </PricingContainer>
      </Container>

      <FormTextGroup
        title="Other"
        handleChange={handleChange}
        handleBlur={handleBlur}
        isDisabled={isDisabled}
        numColumns={2}
        values={[
          {
            name: 'pcmiCategory',
            label: 'PCMI Category',
            value: values.pcmiCategory,
            error: errors.pcmiCategory,
            touched: touched.pcmiCategory,
            fieldType: 'dropdown',
            options: planAttributes?.pcmi_category || [],
            isLoading: isFetchingAttributes,
            handleDropdownChange,
          } as DropdownItem,
          {
            name: 'pcmiSubcategory',
            label: 'PCMI Subcategory',
            value: values.pcmiSubcategory,
            error: errors.pcmiSubcategory,
            touched: touched.pcmiSubcategory,
            fieldType: 'dropdown',
            options: planAttributes?.pcmi_subcategory || [],
            isLoading: isFetchingAttributes,
            handleDropdownChange,
          } as DropdownItem,
          {
            name: 'tags',
            label: 'Tags',
            value: values.tags ?? '',
            error: errors.tags,
            touched: touched.tags,
          },
        ]}
      />
    </>
  )
}

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '33px',
  color: COLOR.BLUE[1000],
  margin: '0 0 32px 0',
})

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  marginBottom: 32,
  padding: 40,
})

const PricingContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const AlertContainer = styled.div({
  marginBottom: 16,
})

const ButtonsContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 8,
  marginBottom: 8,
})

export { PlanForm }
