import type { FC } from 'react'
import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Button, COLOR } from '@helloextend/zen'
import { Add } from '@helloextend/zen/src/tokens/icons'
import { DataReactTable } from '@helloextend/merchants-ui'
import { useListClaimsAssignmentUsersQuery } from '@helloextend/extend-api-rtk-query'
import type { ClaimsUser } from '@helloextend/extend-api-client'
import { useToggle } from '@helloextend/client-hooks'
import { date } from '@helloextend/client-utils'
import { getTableColumns } from './table-config'
import { PageHeader } from '../../../components/page-header'
import { AddUserModal, RemoveUserModal } from './components'

const ClaimAssignmentUsers: FC = () => {
  const [isDeleteModalOpen, deleteModalActions] = useToggle()
  const [isAddModalOpen, addModalActions] = useToggle()
  const handleAddUserClick = addModalActions.toggle

  const { activeUsers, items, isFetching } = useListClaimsAssignmentUsersQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      activeUsers: [...(data?.items || [])]
        .filter((user: ClaimsUser) => user.isActive)
        .map((user) => ({
          ...user,
          fullName: `${user.firstName} ${user.lastName}`,
        }))
        .sort((a, b) => b.createdAt - a.createdAt),
      items: data?.items,
      ...rest,
    }),
  })

  const [currentUserId, setCurrentUserId] = useState<string>()
  const currentUser = activeUsers.find((user: ClaimsUser) => user.id === currentUserId)

  const lastModified = useMemo(() => {
    if (!items?.length) return ''
    const sorted = [...items].sort((a, b) => b.updatedAt - a.updatedAt)
    return `${date.format(sorted[0]?.updatedAt)} by ${sorted[0]?.updatedBy}`
  }, [items])

  const columns = getTableColumns({
    delCallback: (userId: string) => {
      deleteModalActions.toggle()
      setCurrentUserId(userId)
    },
  })

  return (
    <>
      <Header>
        <TitleWrapper>
          <PageHeader title="Claim Assignment List" />
          <DetailRow data-cy="total-users-header">
            <DetailName>Total Users: </DetailName>
            {activeUsers?.length ?? 0}
          </DetailRow>
          <DetailRow data-cy="last-modified-header">
            <DetailName>Last Modified: </DetailName>
            {lastModified}
          </DetailRow>
        </TitleWrapper>
        <Button
          text="Add User"
          icon={Add}
          emphasis="medium"
          onClick={handleAddUserClick}
          data-cy="add-user-button"
        />
      </Header>
      <DataReactTable
        isLoading={isFetching}
        type="users"
        data={activeUsers}
        columns={columns}
        autoResetPage={false}
      />
      <AddUserModal isVisible={isAddModalOpen} handleModalToggle={addModalActions.off} />
      <RemoveUserModal
        isVisible={isDeleteModalOpen}
        handleModalToggle={deleteModalActions.off}
        user={currentUser}
      />
    </>
  )
}

const Header = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const TitleWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const DetailRow = styled.p({
  display: 'flex',
  margin: 0,
  marginBottom: 4,
  fontSize: 16,
  color: COLOR.NEUTRAL[1000],
})

const DetailName = styled.span({
  fontSize: 16,
  fontWeight: 'bold',
  color: COLOR.NEUTRAL[600],
  marginRight: 4,
})

export { ClaimAssignmentUsers }
