import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import {
  COLOR,
  useToaster,
  ToastDuration,
  ToastColor,
  Modal,
  ModalController,
  Grid,
  Input,
  GridItem,
  Select,
} from '@helloextend/zen'
import {
  useAddAccountToUserMutation,
  useInviteUserMutation,
  useCreateUserGrantMutation,
  useLazyGetUserDetailsQuery,
} from '@helloextend/extend-api-rtk-query'
import { useParams } from 'react-router'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

const schema = Yup.object()
  .shape({
    firstName: Yup.string().required('Please enter a first name'),
    lastName: Yup.string().required('Please enter a last name'),
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Please enter a valid email address'),
    role: Yup.string().required('Please select a user role'),
  })
  .defined()

const errorsMessages = Object.freeze({
  USER_EXISTS: 'user already exists on this account',
})

type CreateUserModalProps = {
  visible: boolean
  toggleOff: () => void
}

const CreateUserModal: FC<CreateUserModalProps> = ({ visible, toggleOff }) => {
  const { accountId } = useParams<{ accountId: string }>()
  const { toast } = useToaster()

  const [getUserDetails, { isLoading: isGettingUserDetails }] = useLazyGetUserDetailsQuery()
  const [createUser, { isLoading: isSendingInvite }] = useInviteUserMutation()
  const [addAccountToUser, { isLoading: isAddAccountToUserLoading }] = useAddAccountToUserMutation()
  const [createUserGrant, { isLoading: isCreateUserGrantLoading }] = useCreateUserGrantMutation()

  const { touched, errors, values, dirty, handleChange, handleSubmit, handleBlur, resetForm } =
    useFormik({
      enableReinitialize: true,
      validationSchema: schema,
      validateOnChange: true,
      validateOnBlur: true,
      initialValues: {
        firstName: '',
        lastName: '',
        email: '',
        role: 'user', // switch back to `MerchantAdmin` after we go live with enterprise roles,
      },
      onSubmit: async (formData): Promise<void> => {
        if (!accountId) {
          toast({
            message: `AccountId is missing. Cannot send invite.`,
            toastDuration: ToastDuration.short,
            toastColor: ToastColor.red,
          })
        }

        try {
          await sendUserInvite(formData)
        } catch (error) {
          if ((error as Error).message === errorsMessages.USER_EXISTS) {
            toast({
              message: errorsMessages.USER_EXISTS,
              toastDuration: ToastDuration.short,
              toastColor: ToastColor.yellow,
            })
            toggleOff()
          } else {
            toast({
              message: 'Something went wrong. Please try again.',
              toastDuration: ToastDuration.short,
              toastColor: ToastColor.red,
            })
          }
        } finally {
          resetForm()
        }
      },
    })

  const sendUserInvite = async (formdata: typeof values): Promise<void> => {
    const createUserResponse = await createUser(formdata)
    if ('error' in createUserResponse) {
      if ((createUserResponse.error as FetchBaseQueryError).status !== 409) throw new Error()

      const userDetailsResponse = await getUserDetails(formdata.email)
      if ('error' in userDetailsResponse) throw new Error()

      if (userDetailsResponse.data?.accounts?.includes(accountId))
        throw new Error(errorsMessages.USER_EXISTS)
    }

    const addAccountToUserResponse = await addAccountToUser({ email: formdata.email, accountId })
    if ('error' in addAccountToUserResponse) {
      throw new Error()
    }

    const createUserGrantResponse = await createUserGrant({
      role: formdata.role,
      ern: `ERN:ACC:${accountId}`,
      userId: formdata.email,
    })

    if ('error' in createUserGrantResponse) {
      throw new Error()
    }

    toast({
      message: `The invite has been sent to ${formdata.email} successfully!`,
      toastDuration: ToastDuration.short,
      toastColor: ToastColor.blue,
    })
    toggleOff()
  }

  const hasErrors = useMemo(() => Object.entries(errors).length > 0, [errors])
  const isLoading =
    isSendingInvite || isAddAccountToUserLoading || isCreateUserGrantLoading || isGettingUserDetails

  return (
    <ModalController isOpen={visible}>
      <Modal
        size="md"
        heading="Invite a new user"
        primaryButtonProps={{
          onClick: function noRefCheck() {
            handleSubmit()
          },
          text: 'Invite User',
          isDisabled: !dirty || hasErrors || isLoading,
          isProcessing: isLoading,
          'data-cy': 'submit-invite-user-button',
        }}
        secondaryButtonProps={{
          onClick: function noRefCheck() {
            resetForm()
            toggleOff()
          },
          text: 'Cancel',
          'data-cy': 'cancel-invite-user-button',
        }}
        data-cy="invite-user-modal"
      >
        <DetailText>Send an email to invite a new merchant user to the account.</DetailText>
        <FormGroup isFullWidth>
          <Grid columns={{ lg: 2, md: 2, sm: 1 }} spacing={{ lg: 3, md: 3, sm: 2 }}>
            <Input
              id="firstName"
              label="First Name"
              data-cy="firstName"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={touched.firstName && Boolean(errors.firstName)}
              errorFeedback={errors.firstName}
            />
            <Input
              id="lastName"
              data-cy="lastName"
              label="Last Name"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={touched.lastName && Boolean(errors.lastName)}
              errorFeedback={errors.lastName}
            />
            <GridItem fillWidth>
              <Input
                id="email"
                label="Email"
                data-cy="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isError={touched.email && Boolean(errors.email)}
                errorFeedback={errors.email}
              />
            </GridItem>
            <GridItem fillWidth>
              <Select
                id="role"
                label="Role"
                data-cy="role"
                value={values.role}
                onChange={handleChange}
                onBlur={handleBlur}
                errorFeedback={errors.role}
                isError={touched.role && Boolean(errors.role)}
                isDisabled
                helperText="Only User role can be added via the Extend portal"
              >
                <option value={values.role} disabled>
                  User
                </option>
              </Select>
            </GridItem>
          </Grid>
        </FormGroup>
      </Modal>
    </ModalController>
  )
}

const DetailText = styled.p({
  color: COLOR.NEUTRAL[1000],
  fontSize: 16,
  marginTop: 0,
  marginBottom: 24,
})

const FormGroup = styled.div<{ isFullWidth: boolean }>(({ isFullWidth }) => ({
  width: isFullWidth ? '100%' : '55%',
}))

export default CreateUserModal
