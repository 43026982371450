import type { Servicer } from '@helloextend/extend-api-client'
import type { ErrorReducerState } from '../types/error'
import type { ReducerState } from './reducers'

type Selector<T> = (state: ReducerState, ...rest: any[]) => T

export const getAll: Selector<Servicer[]> = (state) => Object.values(state.byId)
export const getById: Selector<Servicer | null> = (state, id: string) => state.byId[id]
export const getIsLoading: Selector<boolean> = (state) => state.isLoading
export const getError: Selector<ErrorReducerState> = (state) => state.error
export const getIsSuccess: Selector<boolean> = (state) => state.isSuccess
export const getName: Selector<Servicer['businessName']> = (state, id: string) =>
  state.byId[id]?.businessName
