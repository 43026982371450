import { createActionFailure } from '../../factories'

export const resetPassword = (password: string, token: string) =>
  ({
    type: 'RESET_PASSWORD',
    payload: { token, password },
  } as const)

export const resetPasswordRequest = () =>
  ({
    type: 'RESET_PASSWORD_REQUEST',
  } as const)

export const resetPasswordSuccess = () =>
  ({
    type: 'RESET_PASSWORD_SUCCESS',
  } as const)

export const resetPasswordFailure = createActionFailure('RESET_PASSWORD_FAILURE')
