import type { SyntheticEvent } from 'react'
import type { RowPlanPricingSku } from '../../../types/plans'
import type { Values } from './schema'

type ChangeEvent = (e: SyntheticEvent<HTMLInputElement>, index: number) => void

const toRepeatableRowItem = (
  data: Values['pricing'][number],
  onChangeEvent: ChangeEvent,
  isDisabled: boolean,
): RowPlanPricingSku => {
  return {
    vendorSku: data.vendorSku,
    priceBandLow: data.priceBandLow,
    priceBandHigh: data.priceBandHigh,
    cost: data.cost,
    retailTarget: data.retailTarget,
    fixedPrice: data.fixedPrice,
    retailMax: data.retailMax,
    handleChange: onChangeEvent,
    isDisabled,
  }
}

const toRepeatableRowItems = (
  data: Values['pricing'],
  onChangeEvent: ChangeEvent,
  isDisabled: boolean,
): RowPlanPricingSku[] => {
  if (!data || !Array.isArray(data)) return []
  return data.map((row) => toRepeatableRowItem(row, onChangeEvent, isDisabled))
}

export { toRepeatableRowItem, toRepeatableRowItems }
