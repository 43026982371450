import type { Store } from '@helloextend/extend-api-client'
import type { ErrorReducerState } from '@helloextend/core-api-redux/src/types/error'
import type { MerchantsStoreReducerState } from '.'

export function getById(state: MerchantsStoreReducerState, storeId?: string): Store | null {
  if (!storeId) return null
  return state.byId[storeId]
}

export function getError(state: MerchantsStoreReducerState): ErrorReducerState {
  return state.error
}
