import type { SupportedCurrencyCode } from '@helloextend/extend-api-client/src/models/currency'

type DropdownCurrencyCode = {
  value: string
  label: string
}

const countryToCurrencyCode: { [countryCode: string]: SupportedCurrencyCode } = {
  US: 'USD',
  CA: 'CAD',
  EU: 'EUR',
  UK: 'GBP',
}

export const getDropdownCurrencyCode = (countryCode = 'US'): DropdownCurrencyCode => {
  const currencyCode = countryToCurrencyCode[countryCode || 'US'] // in case countryCode is an empty string
  return { value: currencyCode, label: currencyCode }
}
