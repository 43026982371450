import type { FC } from 'react'
import React from 'react'
import type { Store } from '@helloextend/extend-api-client/src/models'
import { AdvancedSelect, Checkbox, Grid, Input, InputType, Switch } from '@helloextend/zen'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import { useUpdateStoreMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import type { MerchantExtendedWarrantyRevenueAgreementType } from '@helloextend/extend-api-client/src/models/store'
import { SaveChangesButtonGroup } from '../../../settings/components/save-changes-button-group'
import type { Values } from './schema'
import { schema } from './schema'
import { revenueModelOptions } from '../../../settings/components/product-protection-form/schema'
import { useDecimalsOnBlur } from '../../../../../../../../hooks/use-decimals-on-blur'
import { Divider } from '../../../../../../../../components/divider'

type ProductProtectionFormProps = {
  store: Store
  handleCancel: () => void
}

const ProductProtectionForm: FC<ProductProtectionFormProps> = ({ store, handleCancel }) => {
  const { toastError, toastCompleted } = useStandardToast()

  const {
    merchantEwRevenueAgreement,
    merchantEwRevenueSharePercentage,
    partialReimbursementPercentage,
    extendCut,
    id,
    partialReimbursementEnabled,
    locationFilteringEnabled,
  } = store
  const partialReimbursementPercentageValue = partialReimbursementPercentage
    ? Number((partialReimbursementPercentage * 100).toFixed(1))
    : undefined

  const customExtendCutPercentageValue = extendCut
    ? Number((extendCut * 100).toFixed(1))
    : undefined

  const { values, errors, dirty, isValid, handleChange, resetForm, setFieldValue, setValues } =
    useFormik<Values>({
      initialValues: {
        merchantEwRevenueAgreement: merchantEwRevenueAgreement || 'net_revenue_share',
        merchantEwRevenueSharePercentage: merchantEwRevenueSharePercentage
          ? merchantEwRevenueSharePercentage * 100
          : 0,
        partialReimbursementPercentage: partialReimbursementPercentageValue,
        extendCut: customExtendCutPercentageValue,
        partialReimbursementEnabled,
        isExtendCutOn: Boolean(customExtendCutPercentageValue),
        locationFilteringEnabled,
      },
      validationSchema: schema,
      onSubmit: () => {},
      validateOnChange: true,
    })

  const [updateStore, { isLoading }] = useUpdateStoreMutation()

  const { handleOnBlurCustom } = useDecimalsOnBlur(setFieldValue)

  const handleCancelClick = (): void => {
    resetForm()
    handleCancel()
  }

  const handleSaveClick = async (): Promise<void> => {
    const isWholesale = values.merchantEwRevenueAgreement === 'wholesale'

    const data = {
      merchantEwRevenueAgreement:
        values.merchantEwRevenueAgreement as MerchantExtendedWarrantyRevenueAgreementType,
      merchantEwRevenueSharePercentage: isWholesale
        ? null
        : (values.merchantEwRevenueSharePercentage as number) / 100,
      partialReimbursementEnabled: values.partialReimbursementEnabled,
      partialReimbursementPercentage: values.partialReimbursementEnabled
        ? (values.partialReimbursementPercentage as number) / 100
        : null,
      extendCut: values.isExtendCutOn ? (values.extendCut as number) / 100 : null,
      locationFilteringEnabled: values.locationFilteringEnabled,
    } as Partial<Store>

    try {
      await updateStore({
        storeId: id,
        data,
        version: 'latest',
      }).unwrap()

      handleCancel()
      toastCompleted('Product Protection Settings have been successfully updated.')
    } catch {
      toastError('Something went wrong. Please try again.')
    }
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = e.currentTarget
    let propertyToUpdate = ''

    if (name === 'partialReimbursementEnabled') {
      propertyToUpdate = 'partialReimbursementPercentage'
    }

    if (name === 'isExtendCutOn') {
      propertyToUpdate = 'extendCut'
    }

    setValues({
      ...values,
      // update the checkbox value
      [name]: checked,
      // update the value of the input
      [propertyToUpdate]: checked ? undefined : null,
    })
  }

  return (
    <div data-cy="product-protection-form">
      <MarginBottom>
        <Grid columns={4} spacing={2}>
          <AdvancedSelect
            isNotClearable
            label="Revenue Model"
            id="merchantEwRevenueAgreement"
            value={values.merchantEwRevenueAgreement as string}
            onChange={handleChange}
            placeholder="Select"
            options={revenueModelOptions}
            multiple={false}
            isError={Boolean(errors?.merchantEwRevenueAgreement)}
            errorFeedback={errors?.merchantEwRevenueAgreement}
            data-cy="revenue-model-select"
          />
          {values.merchantEwRevenueAgreement !== 'wholesale' && (
            <Input
              id="merchantEwRevenueSharePercentage"
              onChange={handleChange}
              onBlur={handleOnBlurCustom}
              type={InputType.number}
              value={values.merchantEwRevenueSharePercentage?.toString() || ''}
              isError={Boolean(errors?.merchantEwRevenueSharePercentage)}
              errorFeedback={errors?.merchantEwRevenueSharePercentage}
              label="Merchant Revenue Share"
              suffix="%"
              placeholder="Enter a number"
              min={0}
              max={100}
              data-cy="merchant-cut-input"
            />
          )}
        </Grid>
      </MarginBottom>
      <Divider margin="0 0 24px 0" />
      <MarginBottom>
        <Checkbox
          label="Partial Reimbursement Merchant"
          name="partialReimbursementEnabled"
          checked={values.partialReimbursementEnabled}
          onChange={handleCheckboxChange}
          data-cy="partial-reimbursement-checkbox"
        />
        {values.partialReimbursementEnabled && (
          <Grid columns={4}>
            <Input
              label="Partial Reimbursement Percentage"
              id="partialReimbursementPercentage"
              value={values.partialReimbursementPercentage?.toString() || ''}
              suffix="%"
              placeholder="Enter a number"
              onChange={handleChange}
              onBlur={handleOnBlurCustom}
              isError={Boolean(errors?.partialReimbursementPercentage)}
              errorFeedback={errors?.partialReimbursementPercentage}
            />
          </Grid>
        )}
      </MarginBottom>
      <MarginBottom>
        <Checkbox
          label="Custom ExtendCut"
          name="isExtendCutOn"
          checked={values.isExtendCutOn}
          onChange={handleCheckboxChange}
          data-cy="custom-extendCut-checkbox"
        />
        {values.isExtendCutOn && (
          <Grid columns={4}>
            <Input
              label="Custom Extend Cut Percentage"
              id="extendCut"
              value={values.extendCut?.toString() || ''}
              suffix="%"
              placeholder="Enter a number"
              onChange={handleChange}
              onBlur={handleOnBlurCustom}
              isError={Boolean(errors?.extendCut)}
              errorFeedback={errors?.extendCut}
            />
          </Grid>
        )}
      </MarginBottom>
      <MarginBottom>
        <Switch
          id="locationFilteringEnabled"
          data-cy="location-filtering-toggle"
          label="Location Filtering"
          isOn={values.locationFilteringEnabled}
          onChange={handleChange}
        />
      </MarginBottom>
      <ButtonGroupWrapper>
        <SaveChangesButtonGroup
          id="product-protection"
          handleSave={handleSaveClick}
          handleCancel={handleCancelClick}
          isSaveDisabled={!dirty || !isValid}
          isLoading={isLoading}
        />
      </ButtonGroupWrapper>
    </div>
  )
}

const ButtonGroupWrapper = styled.div({
  marginTop: 16,
})

const MarginBottom = styled.div({
  marginBottom: 24,
})
export { ProductProtectionForm }
