import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import { COLOR, ToastColor, ToastDuration, useToaster } from '@helloextend/zen'
import { batch, useDispatch, useSelector } from 'react-redux'
import type { ThreadResponse, RulesetBase } from '@helloextend/extend-api-rtk-query'
import {
  useGetThreadQuery,
  useGetThreadRulesetQuery,
  useCreateThreadRulesetMutation,
  useUpdateThreadRulesetMutation,
} from '@helloextend/extend-api-rtk-query'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { DashboardSpinner } from '../../../../components/dashboard-spinner'
import { LeavePageGuard } from '../../../../components/leave-page-guard'
import * as selectors from '../../../../reducers/selectors'
import {
  resetThread,
  setSelectedMessageBlock,
  setThread,
  setIsEditorPanelVisible,
  setIsPublishValidationModeActive,
  setMessageBlockReferenceCountingMapFromThread,
  setRuleset,
} from '../../../../store/slices/amp-slice'
import type { RootState } from '../../../../reducers'
import { ModalContainer } from './modal-container'
import { MessageBlockEditor } from '../components/message-block-editor'
import { compareThreadsDeepEquality, compareRulesetsDeepEquality } from '../utils'
import {
  getIsMessageBlockRoutingsAssigned,
  hasRequiredSlots,
  getIsEachReportingValueAssigned,
} from '../../../../store/slices/amp-validation'
import { TabMenu } from '../../../../components/tab-menu/tab-menu'
import { ClaimValidation } from '../components/claim-validation'
import { ThreadEditorPreview } from './thread-editor-preview'
import { ThreadEditTabsEnum } from './types'
import { ThreadEditorTopnav } from './thread-editor-topnav'
import { ThreadEditorBottomnav } from './thread-editor-bottomnav'

const AdjudicationThreadEdit: FC = () => {
  const history = useHistory()
  const { search } = useLocation()

  const [isConfirmSaveModalVisible, setIsConfirmSaveModalVisible] = useState(false)
  const [isConfirmPublishModalVisible, setIsConfirmPublishModalVisible] = useState(false)
  const [createRuleset, { isLoading: isCreateRulesetLoading }] = useCreateThreadRulesetMutation()
  const [updateRuleset, { isLoading: isUpdateRulesetLoading }] = useUpdateThreadRulesetMutation()
  const { toast } = useToaster()

  const activeTab = useMemo((): ThreadEditTabsEnum => {
    const tabPath = history.location.pathname.split('/').pop()
    switch (tabPath) {
      case `content`:
        return ThreadEditTabsEnum.Content
      case `adjudication-rules`:
        return ThreadEditTabsEnum.AdjudicationRules
      default:
        return ThreadEditTabsEnum.Content
    }
  }, [history.location.pathname])

  const handleRoute = useCallback(
    (route: string): void => {
      history.push({
        pathname: route,
        search,
      })
    },
    [history, search],
  )

  const handleTabClick = (tab: string): void => {
    switch (tab) {
      case ThreadEditTabsEnum.Content:
        handleRoute(`./${ThreadEditTabsEnum.Content}`)
        break
      case ThreadEditTabsEnum.AdjudicationRules:
        handleRoute(`./${ThreadEditTabsEnum.AdjudicationRules}`)
        break
    }
  }

  const isEditPaneVisible = useSelector((state: RootState) => selectors.getEditorVisibility(state))
  const [isInitialMessageBlockSelection, setIsInitialMessageBlockSelection] = useState(true)
  const editorRef = useRef<HTMLDivElement>(null)
  const threadFetchCounter = useRef(0)

  const selectedThread = useSelector((state: RootState) => selectors.getSelectedThread(state))
  const isSelectedThreadStructureLocked = selectedThread?.status !== 'draft'

  const messageBlockReferenceMap = useSelector((state: RootState) =>
    selectors.getCurrentMessageBlockReferenceMap(state),
  )

  const selectedMessageBlock = useSelector((state: RootState) =>
    selectors.getSelectedThreadMessageBlock(state),
  )

  const selectedRuleset = useSelector((state: RootState) => selectors.getRuleset(state))

  const { id } = useParams<{ id: string }>()

  const {
    data: thread,
    isFetching: isThreadFetching,
    isLoading,
  } = useGetThreadQuery(id, { skip: !id })

  const {
    data: ruleset,
    isFetching: isRulesetFetching,
    isLoading: isRulesetLoading,
    error: rulesetError,
  } = useGetThreadRulesetQuery(id, { skip: !id })

  const dispatch = useDispatch()

  const isThreadDirty = useMemo(() => {
    if (selectedThread && thread && !isThreadFetching) {
      return !compareThreadsDeepEquality(selectedThread, thread)
    }

    return false
  }, [selectedThread, thread, isThreadFetching])

  const isRulesetDirty = useMemo(() => {
    const emptyRuleset: RulesetBase = {
      id: thread?.id ?? '',
      approveRules: [],
      denyRules: [],
      reviewRules: [],
    }
    if (selectedRuleset && !isRulesetFetching) {
      return (
        thread?.type === 'adjudication' &&
        thread?.status !== 'draft' &&
        !compareRulesetsDeepEquality(ruleset ?? emptyRuleset, selectedRuleset)
      )
    }
    return false
  }, [ruleset, selectedRuleset, thread?.id, thread?.status, thread?.type, isRulesetFetching])

  // effect updates selectedThread and thread for edit comparison
  // on initial fetch & fetches triggered via save mutation
  useEffect(() => {
    // Note: we want to refresh amp.selectedThread & ref count map ONLY on the initial fetch of the thread
    if (thread && !isThreadFetching && threadFetchCounter.current === 0) {
      threadFetchCounter.current += 1
      dispatch(setThread(thread))
      dispatch(setMessageBlockReferenceCountingMapFromThread(thread))
    }
  }, [dispatch, isLoading, isThreadFetching, thread])

  useEffect(() => {
    if (!isRulesetFetching && thread?.type === 'adjudication' && thread?.status !== 'draft') {
      dispatch(
        setRuleset(
          ruleset ?? {
            id: thread.id,
            approveRules: [],
            denyRules: [],
            reviewRules: [],
          },
        ),
      )
    }
  }, [dispatch, isRulesetLoading, isRulesetFetching, ruleset, thread])

  useEffect(() => {
    return () => {
      dispatch(resetThread())
    }
  }, [dispatch])

  const scripts = useMemo(() => {
    return selectedThread ? selectedThread.script : []
  }, [selectedThread])

  // on initial load only: set first editable message block as the selectedMessageBlock
  useEffect(() => {
    if (isInitialMessageBlockSelection && scripts.length > 0) {
      const firstSelectableIndex = scripts.findIndex((script) => Boolean(script.reply))
      dispatch(setSelectedMessageBlock(firstSelectableIndex))
      dispatch(setIsEditorPanelVisible(true))
      setIsInitialMessageBlockSelection(false)
    }
  }, [isInitialMessageBlockSelection, scripts, dispatch])

  useEffect(() => {
    dispatch(setIsPublishValidationModeActive(false))
  }, [scripts.length, dispatch])

  const handleNavClose = (): void => {
    const state = history.location.state as { from: string }
    handleRoute(state?.from || '/admin/adjudication-management/threads')
  }

  const handleToggleConfirmSaveModal = async (): Promise<void> => {
    if (
      activeTab === ThreadEditTabsEnum.AdjudicationRules &&
      selectedRuleset &&
      selectedThread &&
      selectedThread.id
    ) {
      try {
        if (rulesetError && (rulesetError as FetchBaseQueryError)?.status === 404) {
          await createRuleset({ data: selectedRuleset, threadId: selectedThread.id }).unwrap()
        } else {
          await updateRuleset({ data: selectedRuleset, threadId: selectedThread.id }).unwrap()
        }
        toast({
          message: `Automated adjudication rules for ${selectedThread.title} has been successfully saved.`,
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.blue,
        })
      } catch {
        toast({
          message: `Something went wrong. Please try again.`,
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.red,
        })
      }
    } else {
      setIsConfirmSaveModalVisible(!isConfirmSaveModalVisible)
    }
  }

  const handleToggleConfirmPublishModal = (): void => {
    setIsConfirmPublishModalVisible(!isConfirmPublishModalVisible)
  }

  // Note: we want to refresh amp.selectedThread on a successful save
  const handleSaveSuccess = (savedThread: ThreadResponse): void => {
    if (savedThread) {
      dispatch(setThread(savedThread))
    }
  }

  // Note: we want to refresh amp.selectedThread on a successful publish
  const handlePublishSuccess = (publishedThread: ThreadResponse): void => {
    if (publishedThread) {
      dispatch(setThread(publishedThread))
    }
  }

  const handleLeavePage = useCallback(
    (path: string): void => {
      handleRoute(path)
    },
    [handleRoute],
  )

  const handlePublishChangesClick = (): void => {
    // Gather the index of the first message block failure so we can auto select it and pull up the editor pane
    let firstFailIndex = -1

    // skip the first item while looking for counts, the first MB does not need to pass this check
    const referenceFailIndex = Object.values(messageBlockReferenceMap).findIndex(
      (refCount, i) => i > 0 && refCount === 0,
    )

    if (referenceFailIndex > -1) firstFailIndex = referenceFailIndex

    const jumpToAssignmentFailIndex = scripts.findIndex((scriptItem) => {
      return !getIsMessageBlockRoutingsAssigned(scriptItem)
    })

    const reportingValueFailIndex = scripts.findIndex((scriptItem) => {
      return !getIsEachReportingValueAssigned(scriptItem)
    })

    if (referenceFailIndex === -1) firstFailIndex = jumpToAssignmentFailIndex
    else if (jumpToAssignmentFailIndex === -1 && reportingValueFailIndex === -1)
      firstFailIndex = referenceFailIndex
    else
      firstFailIndex = Math.min(
        referenceFailIndex,
        jumpToAssignmentFailIndex,
        reportingValueFailIndex,
      )

    if (
      firstFailIndex > -1 ||
      (selectedThread?.type === 'adjudication' && !hasRequiredSlots(selectedThread))
    ) {
      batch(() => {
        dispatch(setIsPublishValidationModeActive(true))
        if (firstFailIndex > -1) {
          dispatch(setSelectedMessageBlock(firstFailIndex))
          dispatch(setIsEditorPanelVisible(true))
        }
      })

      return
    }

    handleToggleConfirmPublishModal()
  }

  const isPublishedAdjudicationThread =
    selectedThread?.type === 'adjudication' && selectedThread?.status !== 'draft'

  return (
    <>
      <LeavePageGuard
        isNavBlocked={isThreadDirty || isRulesetDirty}
        handleLeavePage={handleLeavePage}
        mainText="Hang on! You have some unsaved changes"
        detail="If you leave the page without saving, all unsaved changes will be lost."
        overflow="auto"
      />
      <ModalContainer
        isSaveModalVisible={isConfirmSaveModalVisible}
        isPublishModalVisible={isConfirmPublishModalVisible}
        toggleSaveModal={handleToggleConfirmSaveModal}
        togglePublishModal={handleToggleConfirmPublishModal}
        saveSuccess={handleSaveSuccess}
        publishSucess={handlePublishSuccess}
        isThreadDirty={isThreadDirty}
      />
      <ThreadEditorTopnav
        isLoading={isLoading || isThreadFetching}
        isThreadDirty={isThreadDirty}
        onClose={handleNavClose}
        onClickPublish={handlePublishChangesClick}
        onClickSave={handleToggleConfirmSaveModal}
      />
      {isThreadFetching || isLoading ? (
        <DashboardSpinner />
      ) : (
        <>
          {isPublishedAdjudicationThread && (
            <TabMenuWrapper>
              <TabMenu
                tabs={[
                  { text: 'Content', key: ThreadEditTabsEnum.Content },
                  { text: 'Adjudication Rules', key: ThreadEditTabsEnum.AdjudicationRules },
                ]}
                activeTab={activeTab}
                onClick={handleTabClick}
                data-cy="adjudication-thread-edit-tabs"
              />
            </TabMenuWrapper>
          )}

          <ThreadPreview isPublishedAdjudicationThread={isPublishedAdjudicationThread}>
            {!isPublishedAdjudicationThread || activeTab === 'content' ? (
              <MessageBlockContainer
                isPublishedAdjudicationThread={isPublishedAdjudicationThread}
                isVisible={isEditPaneVisible}
                ref={editorRef}
                data-cy="message-block-container"
              >
                {selectedThread && selectedMessageBlock && (
                  <MessageBlockEditor
                    thread={selectedThread}
                    messageBlock={selectedMessageBlock}
                    isThreadStructureLocked={isSelectedThreadStructureLocked}
                  />
                )}
              </MessageBlockContainer>
            ) : (
              <ClaimValidationWrapper
                isVisible={activeTab === ThreadEditTabsEnum.AdjudicationRules}
              >
                <ClaimValidation isPublished={selectedThread?.status !== 'draft'} />
              </ClaimValidationWrapper>
            )}
            <ThreadEditorPreview
              activeTab={activeTab}
              isEditPaneVisible={isEditPaneVisible}
              selectedThread={selectedThread}
              editorRef={editorRef}
            />
          </ThreadPreview>
          {isPublishedAdjudicationThread && (
            <ThreadEditorBottomnav
              isProcessing={isCreateRulesetLoading || isUpdateRulesetLoading}
              onClickClose={handleNavClose}
              onClickSave={handleToggleConfirmSaveModal}
              onClickPublish={handlePublishChangesClick}
              isThreadDirty={isThreadDirty}
              isRulesetDirty={isRulesetDirty}
              activeTab={activeTab}
            />
          )}
        </>
      )}
    </>
  )
}

const ClaimValidationWrapper = styled.div<{ isVisible: boolean }>((isVisible) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  width: 800,
  transform: isVisible ? 'translateX(0)' : 'translateX(-100%)',
  transition: '0.25s',
  height: 'calc(100% - 176px)',
  position: 'fixed',
  left: 0,
  padding: '0px 24px',
  background: COLOR.WHITE,
  boxShadow: '0px 9px 10px rgba(0, 0, 0, 0.15)',
  overflowY: 'auto',
  overflowX: 'hidden',
}))

const TabMenuWrapper = styled.div({
  paddingTop: 12,
  paddingLeft: 32,
  position: 'fixed',
  top: 56,
  left: 0,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100vw',
  background: COLOR.WHITE,
  zIndex: 1,
  flexDirection: 'column',
})

const ThreadPreview = styled.div<{ isPublishedAdjudicationThread: boolean }>(
  ({ isPublishedAdjudicationThread }) => ({
    margin: isPublishedAdjudicationThread ? '29px -32px -40px -32px' : '-40px -32px', // negative margin to offset <DashboardLayout /> padding
    display: 'flex',
    background: COLOR.NEUTRAL[100],
    minHeight: isPublishedAdjudicationThread ? 'calc(100vh - 112px)' : 'calc(100vh - 56px)',
  }),
)

const MessageBlockContainer = styled.div<{
  isVisible: boolean
  isPublishedAdjudicationThread: boolean
}>(({ isVisible, isPublishedAdjudicationThread }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 520,
  transform: isVisible ? 'translateX(0)' : 'translateX(-100%)',
  transition: '0.25s',
  height: isPublishedAdjudicationThread ? 'calc(100% - 208px)' : 'calc(100% - 56px)',
  position: 'fixed',
  left: 0,
  padding: '0px 0px 32px 0px',
  background: COLOR.WHITE,
  boxShadow: '0px 9px 10px rgba(0, 0, 0, 0.15)',
  overflowY: 'auto',
  overflowX: 'hidden',
}))

export { AdjudicationThreadEdit }
