import * as Yup from 'yup'

const schema = Yup.object()
  .shape({
    depotRepairEnabled: Yup.boolean().required(),
    onsiteRepairEnabled: Yup.boolean().required(),
    preventAutoAccept: Yup.boolean(),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

interface CheckboxProps {
  key: keyof Values
  label: string
  dataCy: string
}

const serviceTypeFields: CheckboxProps[] = [
  { key: 'depotRepairEnabled', label: 'Depot Repair', dataCy: 'depot-repair-checkbox' },
  { key: 'onsiteRepairEnabled', label: 'On-site Repair', dataCy: 'onsite-repair-checkbox' },
]

const settingsFields: CheckboxProps[] = [
  {
    key: 'preventAutoAccept',
    label: 'Require the servicer to manually accept assigned repair service orders',
    dataCy: 'prevent-auto-accept-checkbox',
  },
]

export { schema, serviceTypeFields, settingsFields }
export type { Values }
