import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@helloextend/client-branding'

type UsersIconProps = {
  fill?: string
}

/**
 * @deprecated Use Zen Icon component instead: `import { Icon } from '@helloextend/zen'`
 */
const WarningTriangle: FC<UsersIconProps> = ({ fill = COLOR.BLACK_MUTED }) => (
  <svg viewBox="0 -3.25 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 1L33.5885 28H2.41154L18 1Z"
      stroke={fill}
      strokeWidth="1.94595"
      strokeLinejoin="round"
    />
    <path d="M18 11V17.5" stroke={fill} strokeWidth="2" strokeLinecap="round" />
    <path d="M18 22V23" stroke={fill} strokeWidth="2" strokeLinecap="round" />
  </svg>
)

export { WarningTriangle }
