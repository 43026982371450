import type { InternalModalPropsSize, MeasurementArgs } from './modal-types'
import { Breakpoint } from '../../tokens/breakpoints'

export const modalMeasurements: Record<InternalModalPropsSize, MeasurementArgs> = {
  xs: {
    widthPx: Breakpoint.xs,
  },
  sm: {
    widthPx: Breakpoint.sm,
  },
  md: {
    widthPx: Breakpoint.md,
  },
  lg: {
    widthPx: Breakpoint.lg,
  },
  xl: {
    widthPx: Breakpoint.xl,
  },
}
