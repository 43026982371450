import { createActionFailure } from '../../factories'

export const destroy = (id: string, accessToken: string) =>
  ({ type: 'STORES_DESTROY', payload: { id, accessToken } } as const)

export const destroyRequest = () => ({ type: 'STORES_DESTROY_REQUEST' } as const)

export const destroySuccess = (id: string) =>
  ({
    type: 'STORES_DESTROY_SUCCESS',
    payload: id,
  } as const)

export const destroyFailure = createActionFailure('STORES_DESTROY_FAILURE')
