import type { StoreUser } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const update = (userId: string, data: StoreUser, accessToken: string) =>
  ({
    type: 'USER_UPDATE',
    payload: { userId, data, accessToken },
  } as const)

export const updateRequest = () => ({ type: 'USER_UPDATE_REQUEST' } as const)

export const updateSuccess = (user: StoreUser) =>
  ({
    type: 'USER_UPDATE_SUCCESS',
    payload: user,
  } as const)

export const updateFailure = createActionFailure('USER_UPDATE_FAILURE')

export const userFormError = (message: string) =>
  ({
    type: 'USER_FORM_ERROR',
    payload: { message },
  } as const)

export const updateReset = () =>
  ({
    type: 'USER_UPDATE_RESET',
  } as const)
