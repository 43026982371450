import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Button, Add, Modal } from '@helloextend/zen'
import { Edit } from '@helloextend/zen/src/tokens/icons'
import type { CustomTableauReport, Store } from '@helloextend/extend-api-client'
import { useSetAtom } from 'jotai/react'
import { selectedTableauReportAtom, openTableauReportModalAtom } from './atoms/tableauReportAtom'

interface TableauReportsProps {
  store: Store
}
export const ManageCustomTableauReportsModal: FC<TableauReportsProps> = ({ store }) => {
  const customReports = store.customReports || []

  const setOpenTableauReportModal = useSetAtom(openTableauReportModalAtom)

  const setSelectedReport = useSetAtom(selectedTableauReportAtom)

  const openEditModal = (report?: CustomTableauReport): void => {
    if (report && report?.title && report.url) {
      setSelectedReport(report)
    }
    setOpenTableauReportModal('edit')
  }

  const openAddModal = (): void => {
    setOpenTableauReportModal('add')
  }

  const hasCustomReports = customReports.length > 0

  const handleClickCancel = (): void => {
    setOpenTableauReportModal(null)
  }

  return (
    <Modal heading="Custom Tableau Reports" size="sm" onDismissRequest={handleClickCancel}>
      {hasCustomReports && (
        <>
          <ReportList>
            {customReports.map((report, index) => (
              <ListItem key={report?.title || ''}>
                {report?.title || ''}
                <EditButtonWrapper>
                  <Button
                    ariaLabel={`edit-button-${index}`}
                    icon={Edit}
                    emphasis="low"
                    onClick={() => openEditModal(report)}
                  />
                </EditButtonWrapper>
              </ListItem>
            ))}
          </ReportList>
        </>
      )}
      <Button
        icon={Add}
        text="Add Custom Tableau Report"
        emphasis="medium"
        size="regular"
        onClick={() => openAddModal()}
        fillContainer
      />
    </Modal>
  )
}

const ReportList = styled.ul({
  listStyleType: 'none',
  paddingLeft: 0,
})

const ListItem = styled.li({
  display: 'flex',
  marginBottom: 8,
  alignItems: 'center',
})

const EditButtonWrapper = styled.span({
  paddingLeft: 8,
})
