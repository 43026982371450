const roundToTwoDecimalPlaces = (value: number): number => {
  return Math.round(value * 1e2) / 1e2
}

const calculateReserve = ({
  lossCost,
  targetLossRatio,
}: {
  lossCost?: number
  targetLossRatio?: number
}): number => {
  let reserveValue = 0
  if (lossCost && targetLossRatio) {
    reserveValue = (lossCost / targetLossRatio) * 100
  }
  return Math.round(reserveValue)
}

const calculatePremium = ({
  reserve,
  obligorFeeRate,
  underwritingProfit,
}: {
  reserve?: number
  obligorFeeRate?: number
  underwritingProfit?: number
  obligorFee?: number
}): number => {
  let premiumValue = 0
  if (reserve && obligorFeeRate) {
    premiumValue = reserve / (1 - obligorFeeRate / 100) + (underwritingProfit || 0)
  } else if (reserve && !obligorFeeRate && underwritingProfit) {
    premiumValue = reserve + underwritingProfit
  }
  return Math.round(premiumValue)
}

const calculateObligorFee = ({
  obligorFeeRate,
  reserve,
}: {
  obligorFeeRate?: number
  reserve: number
}): number => {
  let obligorFeeValue = 0
  if (obligorFeeRate && reserve) {
    obligorFeeValue = (reserve * obligorFeeRate) / 100
  }
  return Math.round(obligorFeeValue)
}

export { calculateReserve, calculatePremium, calculateObligorFee, roundToTwoDecimalPlaces }
