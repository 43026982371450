import type { Expense } from '@helloextend/extend-api-client'
import type { ReducerState } from './reducers'
import type { ErrorReducerState } from '../types/error'

type Selector<T> = (state: ReducerState, ...rest: any[]) => T

export const getByServiceOrderId: Selector<Expense[]> = (state, serviceOrderId: string) =>
  state.byServiceOrder[serviceOrderId] || []
export const getIsSaving: Selector<boolean> = (state) => state.isSaving
export const getIsVoiding: Selector<boolean> = (state) => state.isVoiding
export const getIsSuccess: Selector<boolean> = (state) => state.isSuccess
export const getIsLoading: Selector<boolean> = (state) => state.isLoading
export const getIsActive: Selector<Expense[]> = (state, serviceOrderId: string) =>
  (state.byServiceOrder[serviceOrderId] || []).filter((expense) => expense.status !== 'voided')
export const getError: Selector<ErrorReducerState> = (state) => state.error
