import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Breadcrumbs,
  Button,
  ButtonGroup,
  COLOR,
  Badge,
  ToastColor,
  ToastDuration,
  useToaster,
  Spinner,
} from '@helloextend/zen'
import { useHistory, useParams } from 'react-router'
import styled from '@emotion/styled'
import {
  useCreateSkuMutation,
  useGetSkuQuery,
  useListSkuVersionsQuery,
} from '@helloextend/extend-api-rtk-query'
import { More } from '@helloextend/zen/src/tokens/icons'
import { useClickOutside, useToggle } from '@helloextend/client-hooks'
import { List, ListItem, Menu } from '@helloextend/merchants-ui'
import { useFormik } from 'formik'
import type { Sku as SkuModel } from '@helloextend/extend-api-client/src/models/sku'
import { SkuForm } from './sku-form'
import type { Values } from './schema'
import { schema } from './schema'
import { skuFormInitialValues, skuPropertiesMapper } from '../../../utils/sku-property-mapper'
import { TabMenu } from '../../../components/tab-menu/tab-menu'
import { SkuVersions } from '../sku-versions'
import { formatDateWithTimezone } from '../../../utils/date-formatting'
import { LeavePageGuard } from '../../../components/leave-page-guard'

enum SkuTab {
  SkuDetails = 'skuDetails',
  VersionHistory = 'versionHistory',
}

const Sku: FC = () => {
  const params = useParams<{ skuId: string }>()
  const history = useHistory()
  const { toast } = useToaster()

  const [isMenuOpen, { toggle: toggleMenu, off }] = useToggle()

  const { ref } = useClickOutside<HTMLDivElement>(() => {
    off()
  })
  const [isEditDisabled, setIsEditDisabled] = useState<boolean>(true)

  const [activeTab, setActiveTab] = useState<SkuTab>(
    history.location.pathname.indexOf('/versions') > 0 ? SkuTab.VersionHistory : SkuTab.SkuDetails,
  )

  const { data: skuDetails, isLoading } = useGetSkuQuery(params.skuId)

  const [create, { isLoading: isCreating, isSuccess, isError, data: createdSku }] =
    useCreateSkuMutation()

  const { skuVersions, isLoading: isLoadingSkuVersions } = useListSkuVersionsQuery(params.skuId, {
    selectFromResult: ({ data, ...rest }) => ({
      // filter out the current/latest version
      skuVersions: data?.items?.slice(0, -1),
      ...rest,
    }),
  })

  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setErrors,
    resetForm,
  } = useFormik<Values>({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: skuFormInitialValues((skuDetails as SkuModel) || {}),
    onSubmit: (): void => {},
  })

  useEffect(() => {
    if (isSuccess && createdSku) {
      toast({
        message: `SKU ${createdSku.id} has been updated.`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
    }
    if (isError) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [isSuccess, isError, toast, createdSku, history])

  const menuTabs = useMemo(() => {
    const tabs = [{ text: 'Premium Details', key: SkuTab.SkuDetails }]

    if (skuVersions && skuVersions.length) {
      tabs.push({ text: 'Version History', key: SkuTab.VersionHistory })
    }
    return tabs
  }, [skuVersions])

  const handleTabClick = (tab: string): void => {
    setActiveTab(tab as SkuTab)
    const skuUrl = `/admin/premiums/${params.skuId}`
    history.push(tab === SkuTab.SkuDetails ? skuUrl : `${skuUrl}/versions`)
  }

  const toggleIsEditDisabled = useCallback((): void => {
    setIsEditDisabled(!isEditDisabled)
  }, [isEditDisabled, setIsEditDisabled])

  const handleDuplicateClick = (): void => {
    history.push('/admin/premiums/create', { skuDetails })
  }

  const handleSave = (): void => {
    const mappedValues = skuPropertiesMapper(values)
    create(mappedValues)
    toggleIsEditDisabled()
  }

  const handleCancelClick = useCallback(() => {
    resetForm()
    toggleIsEditDisabled()
  }, [resetForm, toggleIsEditDisabled])

  const handleLeavePage = useCallback(
    (path: string): void => {
      history.push(path)
    },
    [history],
  )

  const { version, schemaVersion, ...exportSku } = skuDetails || {}

  if (!skuDetails || isLoading || isLoadingSkuVersions)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )
  return (
    <>
      <Breadcrumbs
        crumbs={[
          { to: '/admin/premiums', text: 'Premium' },
          { text: `Premium ID: ${params.skuId}` },
        ]}
      />
      <HeaderSkuDetails>
        <TitleContainer>
          <Title data-cy="sku-id-header-text">Premium ID: {params.skuId}</Title>
          <BadgeContainer>
            <Badge
              data-cy="sku-id-header-badge"
              text={skuDetails.isActive ? 'Active' : 'Inactive'}
              color={skuDetails.isActive ? 'green' : 'yellow'}
            />
          </BadgeContainer>
        </TitleContainer>
        {activeTab === SkuTab.SkuDetails && (
          <ButtonGroup>
            {isEditDisabled ? (
              <>
                <Button
                  emphasis="medium"
                  text="Edit"
                  onClick={toggleIsEditDisabled}
                  data-cy="edit-sku-button"
                />
                <div ref={ref}>
                  <Button
                    icon={More}
                    onClick={toggleMenu}
                    emphasis="medium"
                    data-cy="sku-edit-menu-dropdown"
                  />
                  <Menu isOpen={isMenuOpen} width={200} position="right">
                    <List fullWidth>
                      <ListItem
                        type="button"
                        id="duplicate"
                        onClick={handleDuplicateClick}
                        color={COLOR.BLACK}
                        hoverBackground={COLOR.BLUE.OPACITY[12]}
                      >
                        Duplicate
                      </ListItem>
                      <a
                        href={`data:text/json;charset=utf-8,${encodeURIComponent(
                          JSON.stringify(exportSku),
                        )}`}
                        download={`Plan_${skuDetails.id}.json`}
                        data-cy="export-sku-link"
                      >
                        <ListItem
                          type="button"
                          id="export"
                          onClick={() => {}}
                          color={COLOR.BLACK}
                          hoverBackground={COLOR.BLUE.OPACITY[12]}
                        >
                          Export
                        </ListItem>
                      </a>
                    </List>
                  </Menu>
                </div>
              </>
            ) : (
              <>
                <Button
                  emphasis="medium"
                  text="Cancel"
                  onClick={handleCancelClick}
                  isDisabled={isCreating}
                />
                <Button
                  text="Save"
                  onClick={handleSave}
                  isDisabled={!dirty || !isValid || isCreating}
                  isProcessing={isCreating}
                  data-cy="save-sku-button"
                />
              </>
            )}
          </ButtonGroup>
        )}
      </HeaderSkuDetails>
      <TabMenuContainer>
        <TabMenu tabs={menuTabs} onClick={handleTabClick} activeTab={activeTab} />
      </TabMenuContainer>
      {activeTab === SkuTab.SkuDetails && (
        <>
          <VersionDetails>
            <p>Version {skuDetails.version}</p>
            <p>
              Last updated: {formatDateWithTimezone(skuDetails?.createdAt)} by{' '}
              {skuDetails.createdBy}
            </p>
          </VersionDetails>
          <SkuForm
            values={values}
            errors={errors}
            isDisabled={isEditDisabled}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setErrors={setErrors}
            handleSubmit={handleSubmit}
          />
          <LeavePageGuard isNavBlocked={dirty} handleLeavePage={handleLeavePage} />
        </>
      )}
      {activeTab === SkuTab.VersionHistory && <SkuVersions skuId={skuDetails.id} />}
    </>
  )
}

const Title = styled.h1({
  color: COLOR.BLUE[1000],
  fontSize: 32,
  lineHeight: '44px',
  fontWeight: 800,
  margin: 0,
})

const HeaderSkuDetails = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '24px 0 48px',
})

const TitleContainer = styled.div({
  display: 'flex',
})

const BadgeContainer = styled.div({
  alignSelf: 'center',
  display: 'inline-flex',
  marginLeft: 16,
})

const SpinnerWrapper = styled.div({
  width: 100,
  margin: '0 auto',
})

const VersionDetails = styled.div({
  fontSize: 16,
})

const TabMenuContainer = styled.div({
  marginBottom: 24,
})

export { Sku }
