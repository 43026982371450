import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, Button } from '@helloextend/zen'

export type EmptyMessageProps = {
  header: string
  message: string
  onClearFilters?: () => void
}

const EmptyMessage: FC<EmptyMessageProps> = ({ header, message, onClearFilters }) => {
  return (
    <EmptyMessageWrapper data-cy="empty-message-wrapper">
      <h2>{header}</h2>
      <Message>{message}</Message>
      {onClearFilters && (
        <div>
          <Button emphasis="low" size="regular" onClick={onClearFilters} text="Clear all filters" />
        </div>
      )}
    </EmptyMessageWrapper>
  )
}

const EmptyMessageWrapper = styled.div({
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  color: COLOR.BLUE[1000],
  display: 'flex',
  flexDirection: 'column',
  marginTop: 96,
  textAlign: 'center',
  width: '100%',
})

const Message = styled.p({
  fontSize: 20,
  lineHeight: '27px',
  maxWidth: 624,
})

export { EmptyMessage }
