import type { PlanTermsType } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetchAll = (accessToken: string) =>
  ({
    type: 'PLAN_TERMS_FETCH_ALL',
    payload: { accessToken },
  } as const)

export const fetchAllRequest = () =>
  ({
    type: 'PLAN_TERMS_FETCH_ALL_REQUEST',
  } as const)

export const fetchAllSuccess = (planTerms: PlanTermsType[]) =>
  ({
    type: 'PLAN_TERMS_FETCH_ALL_SUCCESS',
    payload: planTerms,
  } as const)

export const fetchAllFailure = createActionFailure('PLAN_TERMS_FETCH_ALL_FAILURE')
