import type { FC } from 'react'
import React from 'react'
import { useGetIntegrationConfigQuery } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { InlineAlert, InlineAlertColor, Spinner } from '@helloextend/zen'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { BasicInfo } from './components/basic-info'
import { IntegrationSettingsForm } from './components/integration-settings-form'

type IntegrationSettingsProps = {
  storeId: string
}

const IntegrationSettings: FC<IntegrationSettingsProps> = ({ storeId }) => {
  const { data, error, isFetching } = useGetIntegrationConfigQuery({ storeId, version: 'latest' })

  if (!data && (error as FetchBaseQueryError)?.status === 'PARSING_ERROR')
    return (
      <InlineAlert color={InlineAlertColor.blue} data-cy="integration-settings-main-alert">
        <Text>There is no integration settings available for API merchants.</Text>
      </InlineAlert>
    )

  if (isFetching)
    return (
      <SpinnerContainer>
        <Spinner data-cy="integration-settings-spinner" />
      </SpinnerContainer>
    )

  if (!data) return null

  return (
    <Container>
      <BasicInfoWrapper>
        <BasicInfo integrationConfig={data} />
      </BasicInfoWrapper>
      <IntegrationSettingsWrapper>
        <IntegrationSettingsForm integrationConfig={data} />
      </IntegrationSettingsWrapper>
    </Container>
  )
}

const Container = styled.div({
  display: 'grid',
  gap: 16,
})

const BasicInfoWrapper = styled.div({
  gridColumn: '1 / 2',
})

const IntegrationSettingsWrapper = styled.div({
  gridColumn: '2 / 4',
})

const Text = styled.p({
  margin: 0,
})

const SpinnerContainer = styled.div({
  textAlign: 'center',
})

export { IntegrationSettings }
