import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface ClaimDetailsState {
  activeView: string
  toastMessage: string
}

const initialState: ClaimDetailsState = {
  activeView: '',
  toastMessage: '',
}

const claimDetailsSlice = createSlice({
  name: 'ClaimsDetails',
  initialState,
  reducers: {
    setClaimDetailsActiveView(state, action: PayloadAction<string>) {
      return {
        ...state,
        activeView: action.payload,
      }
    },
    setClaimDetailsToast(state, action: PayloadAction<string>) {
      return {
        ...state,
        toastMessage: action.payload,
      }
    },
  },
})

const getClaimDetailsActiveView = (state: ClaimDetailsState): string => {
  return state.activeView
}

const getClaimDetailsToast = (state: ClaimDetailsState): string => {
  return state.toastMessage
}

const claimDetailsReducer = claimDetailsSlice.reducer
const { setClaimDetailsActiveView, setClaimDetailsToast } = claimDetailsSlice.actions

export {
  claimDetailsReducer,
  setClaimDetailsActiveView,
  getClaimDetailsActiveView,
  setClaimDetailsToast,
  getClaimDetailsToast,
  ClaimDetailsState,
}
