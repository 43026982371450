import type { Plan, PlanCreateRequest } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const create = (accessToken: string, plan: PlanCreateRequest) =>
  ({
    type: 'PLANS_CREATE',
    payload: { accessToken, plan },
  } as const)

export const createRequest = () => ({ type: 'PLANS_CREATE_REQUEST' } as const)

export const createSuccess = (plan: Plan) =>
  ({ type: 'PLANS_CREATE_SUCCESS', payload: plan } as const)

export const createFailure = createActionFailure('PLANS_CREATE_FAILURE')
