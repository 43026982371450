export enum DownloadStatus {
  export_started = 'export_started',
  export_completed = 'export_completed',
  export_failed = 'export_failed',
  download_completed = 'download_completed',
}

export enum DownloadChunkStatus {
  EXPORT_STARTED = 'export_started',
  EXPORT_COMPLETED = 'export_completed',
  EXPORT_FAILED = 'export_failed',
}

export interface Download {
  id: string
  status: DownloadStatus
  createdBy?: string
  createdAt?: number
  updatedAt?: number
  chunks?: DownloadChunk[]
}

export interface DownloadChunk {
  createdAt: number
  downloadId: string
  id: string
  objectKey: string
  status: DownloadChunkStatus
  updatedAt: number
  url: string
}
