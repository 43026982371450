import type { FC } from 'react'
import React, { useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button } from '@helloextend/zen'
import {
  useUpdatePlanAttributesMutation,
  useListPlanAttributesQuery,
} from '@helloextend/extend-api-rtk-query'
import type { PlansAttributesUpsertBody } from '@helloextend/extend-api-client'
import { usePrevious } from '@helloextend/client-hooks'
import { BasicModal } from '../../../components/basic-modal'
import Input from '../../../components/input'
import { SuccessfulSubmit } from '../../../components/successful-submit'
import { blockedFields } from './blocked-fields'

type BaseProps = {
  isOpen: boolean
  handleModalToggle: () => void
  attributeName: string
}

type UpdateProps =
  | { isUpdateModal: true; handleDelete: () => void; defaultValue: string }
  | { isUpdateModal?: false; handleDelete?: never; defaultValue?: never }

type AttributeModalProps = BaseProps & UpdateProps

const schema = Yup.object()
  .shape({
    value: Yup.string()
      .required('Please enter a value')
      .test(
        'leading or trailing spaces',
        'No leading or trailing spaces are allowed',
        (value): boolean => {
          return !value || value === value?.trim()
        },
      ),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

const AttributeModal: FC<AttributeModalProps> = ({
  isOpen,
  handleModalToggle,
  attributeName,
  isUpdateModal,
  defaultValue,
  handleDelete,
}) => {
  const [updatePlanAttributes, { isLoading, isSuccess, error }] = useUpdatePlanAttributesMutation()
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
  const prevLoading = usePrevious(isLoading)
  const isDisabled = blockedFields.includes(attributeName)
  const { attributeValues } = useListPlanAttributesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      attributeValues: data?.items.find((attribute) => {
        return attribute.id === attributeName
      }),
    }),
  })
  const doesDuplicateAttributeExist = useCallback(
    (value: string): boolean => {
      return attributeValues?.values
        ? attributeValues.values.some(
            (attribute) => attribute.toLowerCase() === value.toLowerCase(),
          )
        : false
    },
    [attributeValues],
  )

  const { touched, errors, values, handleChange, handleSubmit, handleBlur, resetForm, setErrors } =
    useFormik({
      validationSchema: schema,
      validateOnChange: false,
      validateOnBlur: false,
      enableReinitialize: true,
      initialValues: {
        value: defaultValue ?? '',
      },
      onSubmit: (formValues: Values) => {
        let updatedValues: string[] | undefined
        const isDuplicate = doesDuplicateAttributeExist(formValues.value)
        if (isDuplicate) {
          setErrors({ value: 'The value already exists' })
          return
        }
        if (!isUpdateModal) {
          updatedValues = attributeValues?.values.concat(formValues.value)
        } else {
          updatedValues = attributeValues?.values.map((value) => {
            if (value === defaultValue) return formValues.value
            return value
          })
        }
        if (updatedValues) {
          updatePlanAttributes({
            id: attributeName,
            values: updatedValues,
          } as PlansAttributesUpsertBody)
          setHasSubmitted(true)
        }
      },
    })

  useEffect(() => {
    if (isSuccess && prevLoading) {
      resetForm()
    }
  }, [isSuccess, prevLoading, resetForm])

  const handelModalSuccessClose = (): void => {
    setHasSubmitted(false)
    handleModalToggle()
  }

  const modalActionText = isUpdateModal ? 'Update Value' : 'Add Value'

  return (
    <BasicModal isVisible={isOpen} onClickClose={handelModalSuccessClose}>
      {isLoading || hasSubmitted ? (
        <SuccessfulSubmit
          isLoading={isLoading}
          submitted={isSuccess && hasSubmitted}
          error={error}
          errorTitle="An error occurred while trying to add a value."
          successTitle={`Successfully ${isUpdateModal ? 'updated' : 'added'} value`}
          buttonText="Close"
          handleClick={handelModalSuccessClose}
        />
      ) : (
        <>
          <Title>{modalActionText}</Title>
          <Subtitle>Attribute:</Subtitle>
          <AttributeText data-cy="attribute-category">{attributeName}</AttributeText>
          <form onSubmit={handleSubmit}>
            <Input
              name="value"
              label="Value"
              value={values.value}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={touched.value && Boolean(errors.value)}
              errorMessage={errors.value}
              isDisabled={isDisabled}
            />
            <ButtonGroup>
              {isUpdateModal && !isDisabled && (
                <Button
                  onClick={handleDelete}
                  text="Delete"
                  color="red"
                  emphasis="medium"
                  data-cy="delete"
                />
              )}
              <Button
                onClick={handleModalToggle}
                text="Cancel"
                emphasis="medium"
                data-cy="cancel"
              />
              <div data-tooltip={isDisabled ? 'Value cannot be modified.' : ''}>
                <Button
                  type="submit"
                  text={modalActionText}
                  isDisabled={isDisabled}
                  data-cy="update"
                />
              </div>
            </ButtonGroup>
          </form>
        </>
      )}
    </BasicModal>
  )
}

const Title = styled.h2({
  fontSize: 20,
  lineHeight: '27px',
  fontWeight: 700,
  marginTop: 0,
})

const Subtitle = styled.h3({
  fontSize: 14,
  fontWeight: 700,
  lineHeight: '22px',
  margin: 0,
})

const AttributeText = styled.p({
  fontSize: 14,
  lineHeight: '22px',
  margin: '0 0 24px',
})

const ButtonGroup = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 16,
  marginTop: 80,
})

export { AttributeModal, AttributeModalProps }
