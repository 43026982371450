import type { VFC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { COLOR } from '../../tokens/colors'

export interface MenuSkeletonProps {
  rows?: number
  showCheckbox?: boolean
  'data-cy'?: string
}

/**
 * In cases where it's not possible to fetch menu data before a menu is triggered, while waiting a `<MenuSkeleton>` should be
 * displayed in place of the menu items. The number of rows presented isn't expected to match the number of expected
 * results, but rather should be set based on what looks or feels right for each individual use case. In most cases the
 * default of 3 rows should be fine.
 */
export const MenuSkeleton: VFC<MenuSkeletonProps> = ({
  rows = 3,
  showCheckbox = false,
  'data-cy': dataCy,
}) => (
  <>
    {new Array(rows).fill(null).map(() => (
      <StyledMenuSkeleton data-cy={dataCy}>
        <StyledArea>
          <Content>
            {showCheckbox && (
              <CheckmarkContainer>
                <CheckboxSkeleton />
              </CheckmarkContainer>
            )}
            <SkeletonAnimation />
          </Content>
        </StyledArea>
      </StyledMenuSkeleton>
    ))}
  </>
)

const StyledArea = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 4,
  borderRadius: 4,
  padding: '10px 12px',
  pointerEvents: 'none',
  minWidth: 180,
})

const Content = styled.div({
  display: 'flex',
  gap: 8,
  minWidth: 0,
  width: '100%',
})

const animateBar = keyframes({
  '0%': {
    left: '-75%',
  },
  '100%': {
    left: '100%',
  },
})

const SkeletonAnimation = styled.div({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: 20,
  background: COLOR.NEUTRAL[200],
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '75%',
    background: `linear-gradient(90deg, transparent 0%, ${COLOR.NEUTRAL[300]} 50%, transparent 100%)`,
    animation: `${animateBar} 1.75s infinite`,
  },
})

const StyledMenuSkeleton = styled.div({
  display: 'block',
  padding: '2px 0',
})

const CheckmarkContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  width: 24,
  height: 24,
  margin: '-2px 0 -2px -2px',
})

const CheckboxSkeleton = styled.div({
  width: 20,
  height: 20,
  borderRadius: 2,
  background: COLOR.NEUTRAL[200],
})
