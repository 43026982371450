import type { FC } from 'react'
import React from 'react'
import type { Store } from '@helloextend/extend-api-client'
import { InputType } from '@helloextend/zen'
import { historicalLookBackPeriod } from './schema'
import { CheckboxWithInput } from '../checkbox-with-input'
import type { SaveClickProps } from '../checkbox-with-input/checkbox-with-input'

type HistoricalLeadsSettingsProps = {
  store: Store
  isLoading?: boolean
  handleUpdateStore: (data: Partial<Store>) => void
}

const HistoricalLeadsSettings: FC<HistoricalLeadsSettingsProps> = ({
  store,
  isLoading,
  handleUpdateStore,
}) => {
  const handleSave = (data: SaveClickProps): void => {
    handleUpdateStore({
      historicalLeadsLookbackMonths: Number(data.value),
      historicalLeadsEnabled: data.isChecked,
    })
  }

  if (!store) return null

  return (
    <>
      <CheckboxWithInput
        schema={historicalLookBackPeriod}
        checkboxLabel="Show Historical Leads"
        inputLabel="Look-Back Period"
        inputId="historicalLeadsLookbackMonths"
        suffix="months"
        inputType={InputType.number}
        inputValue={store.historicalLeadsLookbackMonths?.toString()}
        placeholder="Enter number of months"
        checkboxId="historicalLeadsEnabled"
        isInitialChecked={Boolean(store.historicalLeadsEnabled)}
        handleSave={handleSave}
        isLoading={isLoading}
        informationChildren={
          <p>
            Control whether the merchant would like to disable historical lead creation and
            configure the number of months for the look-back period to be used.
          </p>
        }
      />
    </>
  )
}

export { HistoricalLeadsSettings }
