import { createActionFailure } from '../../factories'

export const createPlanTerm = (termsId: string, description: string, file: File, language = 'en') =>
  ({
    type: 'PLAN_TERM_CREATE',
    payload: { termsId, description, language, file },
  } as const)

export const createPlanTermRequest = () =>
  ({
    type: 'PLAN_TERM_CREATE_REQUEST',
  } as const)

export const createPlanTermSuccess = () =>
  ({
    type: 'PLAN_TERM_CREATE_SUCCESS',
  } as const)

export const createPlanTermFailure = createActionFailure('PLAN_TERM_CREATE_FAILURE')

export const createPlanTermFileUploadRequest = () =>
  ({
    type: 'PLAN_TERM_CREATE_FILE_UPLOAD_REQUEST',
  } as const)

export const createPlanTermFileUploadSuccess = () =>
  ({
    type: 'PLAN_TERM_CREATE_FILE_UPLOAD_SUCCESS',
  } as const)
export const createPlanTermFileUploadFailure = createActionFailure(
  'PLAN_TERM_CREATE_FILE_UPLOAD_FAILURE',
)
