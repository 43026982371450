import type { FC, KeyboardEvent } from 'react'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import styled from '@emotion/styled'
import { parseHexColor } from '../../../utils/colors'
import { ColorPicker } from './components/color-picker'
import { HexInput } from './components/hex-input'
import { Button } from '../../button'
import { Reset } from '../../../tokens/icons'
import type { InputProps } from '../input/input'
import { Popover, usePopover } from '../../popover'

const DEFAULT_VALUE = '#000000'

interface ColorInputProps extends Pick<InputProps, 'id' | 'label' | 'isDisabled' | 'data-cy'> {
  onChange: (value: string) => void
  value: string
}

const ColorInput: FC<ColorInputProps> = ({ value, onChange, ...passThroughProps }) => {
  const [resetValue, setResetValue] = useState(value || DEFAULT_VALUE)
  const [closeOnClickOutside, setCloseOnClickOutside] = useState(true)
  const hexInput = useRef<HTMLInputElement>(null)

  const handleShow = useCallback(() => {
    setResetValue(value || DEFAULT_VALUE)
  }, [value])

  const { triggerRef, popoverRef, isPresent, show, hide, toggle, triggerBoundingBox } =
    usePopover<HTMLDivElement>({
      closeOnClickOutside,
      onShow: handleShow,
    })

  useEffect(() => {
    const validValue = parseHexColor(value) || DEFAULT_VALUE

    if (value !== validValue) {
      onChange(validValue)
    }
  }, [onChange, value])

  const handleLockFlyout = useCallback(() => {
    setCloseOnClickOutside(false)
  }, [])

  const handleUnlockFlyout = useCallback(() => {
    hexInput.current?.focus()
    setCloseOnClickOutside(true)
  }, [hexInput])

  const handleReset = useCallback(() => {
    onChange(resetValue || DEFAULT_VALUE)
  }, [onChange, resetValue])

  const handleChange = useCallback(
    (color: string) => {
      const newHex = parseHexColor(color) || DEFAULT_VALUE
      onChange(newHex)
    },
    [onChange],
  )

  const handleEnterPress = useCallback(() => {
    triggerRef.current?.blur()
    toggle()
  }, [triggerRef, toggle])

  const handleTabPress = useCallback(() => {
    hide()
  }, [hide])

  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleEnterPress()
      }
    },
    [handleEnterPress],
  )

  return (
    <>
      <div ref={triggerRef}>
        <HexInput
          {...passThroughProps}
          ref={hexInput}
          value={value}
          onClick={show}
          onChange={handleChange}
          onEnterPress={handleEnterPress}
          onTabPress={handleTabPress}
        />
      </div>
      <Popover ref={popoverRef} isPresent={isPresent} triggerBoundingBox={triggerBoundingBox}>
        <PickerWrapper onKeyPress={handleKeyPress}>
          <ColorPicker
            color={value}
            onChange={handleChange}
            onMouseDown={handleLockFlyout}
            onMouseUp={handleUnlockFlyout}
          />
          <ButtonContainer>
            <Button
              text="Reset"
              icon={Reset}
              emphasis="low"
              color="blue"
              size="small"
              onClick={handleReset}
              tabIndex={-1}
            />
          </ButtonContainer>
        </PickerWrapper>
      </Popover>
    </>
  )
}

const PickerWrapper = styled.div({
  minWidth: 220,
  padding: '16px 16px 0 16px',
  boxSizing: 'border-box',
})

const ButtonContainer = styled.div({
  margin: 4,
  textAlign: 'center',
})

export { ColorInput, ColorInputProps }
