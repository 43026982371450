import type { Account } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const update = (accountId: string, accessToken: string, data: DeepPartial<Account>) =>
  ({
    type: 'ACCOUNTS_UPDATE',
    payload: { accountId, accessToken, data },
  } as const)

export const updateRequest = () => ({ type: 'ACCOUNTS_UPDATE_REQUEST' } as const)

export const updateSuccess = () => ({ type: 'ACCOUNTS_UPDATE_SUCCESS' } as const)

export const updateFailure = createActionFailure('ACCOUNTS_UPDATE_FAILURE')
