import type { ScriptItem } from '@helloextend/extend-api-rtk-query'
import { MessageType } from '@helloextend/extend-api-rtk-query'

export const generateStatementMessageBlock = (content?: string): ScriptItem => ({
  collect: {
    options: [
      {
        action: 'stop',
        default: true,
      },
    ],
  },
  reply: {
    messages: [
      {
        type: MessageType.text,
        content: content ?? '',
      },
    ],
  },
})
