import { combineReducers } from 'redux'
import type { ByIdReducerState } from './by-id'
import byId from './by-id'
import error from './error'
import isLoading from './is-loading'
import isSuccess from './is-success'
import isCreating from './is-creating'
import type { PlansSearchReducerState } from './search'
import { search } from './search'
import type { ErrorReducerState } from '../../types/error'
import type { Action } from '../actions'

export interface ReducerState {
  byId: ByIdReducerState
  error: ErrorReducerState
  isLoading: boolean
  search: PlansSearchReducerState
  isSuccess: boolean
  isCreating: boolean
}

export default combineReducers<ReducerState, Action>({
  byId,
  error,
  isLoading,
  search,
  isSuccess,
  isCreating,
})
