import type { SagaIterator } from 'redux-saga'
import { takeLatest, all } from 'redux-saga/effects'
import type { ActionType } from '../actions'
import fetch from './fetch'
import fetchAll from './fetch-all'
import search from './search'
import create from './create'

export default function* sagas(): SagaIterator {
  yield all([
    takeLatest('PLANS_FETCH' as ActionType, fetch),
    takeLatest('PLANS_FETCH_ALL' as ActionType, fetchAll),
    takeLatest(['PLANS_SEARCH' as ActionType, 'PLANS_SEARCH_NEXT_PAGE' as ActionType], search),
    takeLatest('PLANS_CREATE' as ActionType, create),
  ])
}
