import type { VFC } from 'react'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import styled from '@emotion/styled'
import { debounce } from 'lodash'
import useResizeObserver from 'use-resize-observer'
import type { Row as ReactTableRow } from '@tanstack/react-table'
import { COLOR } from '../../../tokens/colors'
import { useDataTableContext } from '../data-table-context'
import { Header } from './header'
import { Body } from './body'
import { Row } from './row'
import { Footer } from './footer'
import { AnimatePresence } from '../../../transitions'
import { Loader } from './loader'

export const Table: VFC = () => {
  const {
    'data-cy': dataCy,
    collapseAllRows,
    hasAllTableRowsSelected,
    setHasAllTableRowsSelected,
    setIsTableHorizontallyScrollable,
    setTableContainerWidth,
    table,
    isLoading,
  } = useDataTableContext()

  const tableRef = useRef<HTMLTableElement>(null)
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const stickColumnsIfScrollable = useCallback(() => {
    const currentRef = tableContainerRef?.current
    if (currentRef) {
      setTableContainerWidth(currentRef.clientWidth)
      setIsTableHorizontallyScrollable(currentRef.scrollWidth > currentRef.clientWidth)
    }
  }, [setIsTableHorizontallyScrollable, setTableContainerWidth])

  const handleResizeDebounced = useMemo(
    () => debounce(stickColumnsIfScrollable, 200, { leading: true }),
    [stickColumnsIfScrollable],
  )

  const tableState = table.getState()
  useEffect(() => {
    setTimeout(() => {
      stickColumnsIfScrollable()
    })
  }, [stickColumnsIfScrollable, tableState])

  useResizeObserver<HTMLDivElement>({
    ref: tableContainerRef,
    onResize: handleResizeDebounced,
  })

  const rowModel = table.getRowModel()

  const handleRowSelectionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, row: ReactTableRow<unknown>) => {
      if (hasAllTableRowsSelected) {
        setHasAllTableRowsSelected(false)
        table.resetRowSelection()
        table.toggleAllPageRowsSelected(true)
      }
      row.getToggleSelectedHandler()(e)
    },
    [hasAllTableRowsSelected, setHasAllTableRowsSelected, table],
  )

  /**
   * Any time table row model changes causing a new group of rows to be rendered all rows will be collapsed. And table will
   * be scrolled to top
   */
  useEffect(() => {
    if (tableContainerRef.current?.scrollTo) tableContainerRef.current.scrollTo(0, 0)
    collapseAllRows()
  }, [collapseAllRows, rowModel, table])

  return (
    <>
      <StyledTable data-cy={dataCy && `${dataCy}:table`} ref={tableContainerRef}>
        <AnimatePresence isPresent={isLoading} transitionDurationMs={200}>
          <Loader />
        </AnimatePresence>
        <table ref={tableRef}>
          {table.getHeaderGroups().map((headerGroup) => (
            <Header key={headerGroup.id} headerGroup={headerGroup} />
          ))}
          <Body>
            {table.getRowModel().rows.map((row) => {
              return (
                <Row
                  key={row.id}
                  isSelected={row.getIsSelected()}
                  isSelectedOnChange={(e) => handleRowSelectionChange(e, row)}
                  row={row}
                />
              )
            })}
          </Body>
        </table>
      </StyledTable>
      <Footer />
    </>
  )
}

const StyledTable = styled.div({
  position: 'relative',
  borderStyle: 'solid',
  borderColor: COLOR.NEUTRAL[300],
  borderWidth: 1,
  overflow: 'auto',

  // Table
  '& > table': {
    width: '100%',
    borderSpacing: 0,
  },
})
