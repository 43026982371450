import type { FC } from 'react'
import React from 'react'
import { ArrowDropDown, Filter as FilterIcon } from '../../../tokens/icons'
import { Button } from '../../button'
import { Menu } from '../../menu'
import { useDataTableContext } from '../data-table-context'
import { FilterSubmenu } from './filter-submenu'
import { Filter } from './filter'
import { PopoverDirection } from '../../popover'

export const FilterMenu: FC = () => {
  const { 'data-cy': dataCy, filterDefs } = useDataTableContext()

  return (
    <>
      {filterDefs?.map((filterDef, i) =>
        filterDef.type === 'group' ? (
          <Menu
            key={filterDef.label || i}
            triggerRenderer={() => (
              <Button
                icon={filterDef.label ? ArrowDropDown : FilterIcon}
                iconPosition={filterDef.label ? 'right' : 'left'}
                emphasis="medium"
                color="neutral"
                isBold={false}
                text={filterDef.label}
                {...(!filterDef.label && {
                  ariaLabel: filterDefs.length > 1 ? 'More Filters' : 'Filters',
                  tooltip: filterDefs.length > 1 ? 'More Filters' : 'Filters',
                })}
                data-cy={dataCy && `${dataCy}:filters-button`}
              />
            )}
          >
            <FilterSubmenu filterDefs={filterDef.filterDefs} />
          </Menu>
        ) : (
          <Filter
            key={filterDef.id}
            triggerRenderer={() => (
              <Button
                icon={ArrowDropDown}
                iconPosition="right"
                text={filterDef.label}
                isBold={false}
                emphasis="medium"
                color="neutral"
                ariaLabel={`Filter ${filterDef.label}`}
              />
            )}
            filterDef={filterDef}
            direction={PopoverDirection.down}
          />
        ),
      )}
    </>
  )
}
