import type { FC } from 'react'
import React from 'react'
import { Modal, ModalController, ToastColor, ToastDuration, useToaster } from '@helloextend/zen'
import { useHistory, useParams } from 'react-router-dom'
import type { User } from '@helloextend/extend-api-rtk-query'
import {
  useLazyGetUserGrantsListQuery,
  useRevokeUserGrantMutation,
} from '@helloextend/extend-api-rtk-query'
import { filterGrantsByAccount } from '../../../../../utils/user-role-mapper'

type DeactivateUserModalProps = {
  user: User
  isVisible: boolean
  toggle: () => void
}

const DeactivateUserModal: FC<DeactivateUserModalProps> = ({ user, isVisible, toggle }) => {
  const { accountId } = useParams<{ accountId: string }>()
  const [getUserGrants] = useLazyGetUserGrantsListQuery()
  const [revokeGrant, { isLoading }] = useRevokeUserGrantMutation()
  const history = useHistory()
  const { toast } = useToaster()

  const handleDeactivateUser = async (): Promise<void> => {
    const userGrantsResponse = await getUserGrants(user.email)
    if ('error' in userGrantsResponse || !userGrantsResponse.data) {
      toast({
        message: 'An error occurred while getting user grants. Please try again later.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      return
    }

    const filteredGrants = filterGrantsByAccount(userGrantsResponse.data.grants, accountId)

    if (filteredGrants.length === 0) return

    const response = await Promise.all(
      filteredGrants.map(async (grant) =>
        revokeGrant({ userId: user.email, ern: grant.ern, role: grant.role }),
      ),
    )

    if (response.some((result) => 'error' in result)) {
      toast({
        message: 'Error occured while deactivating user, please try again later.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      return
    }

    toast({
      message: 'User deactivated successfully',
      toastColor: ToastColor.blue,
      toastDuration: ToastDuration.short,
    })
    history.push(`/admin/accounts/${accountId}/users`)
  }

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        data-cy="deactivate-user-modal"
        heading="Deactivate this user?"
        primaryButtonProps={{
          color: 'red',
          onClick: async () => {
            await handleDeactivateUser()
          },
          text: 'Deactivate User',
          'data-cy': 'deactivate-user-confirmation-button',
          isDisabled: isLoading,
          isProcessing: isLoading,
        }}
        secondaryButtonProps={{
          color: 'neutral',
          onClick: function noRefCheck() {
            toggle()
          },
          text: 'Cancel',
          'data-cy': 'deactivate-user-cancel-button',
        }}
        size="md"
      >
        <p>This will remove the user&#39;s access to this account and assigned role(s).</p>
        <p>Are you sure you want to deactivate this user?</p>
      </Modal>
    </ModalController>
  )
}

export { DeactivateUserModal }
