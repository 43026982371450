import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/client-utils'
import type { SagaIterator } from 'redux-saga'
import { put, call } from '@redux-saga/core/effects'
import { users as usersActions } from '../actions'

type Action = ReturnType<typeof usersActions.getMe>

export function* fetchUser(action: Action): SagaIterator {
  const accessToken = action.payload
  try {
    yield put(usersActions.getMeRequest())

    const response = yield call(client.auth.getMe, accessToken)

    if (isErrorResponse(response)) {
      yield put(usersActions.getMeFailure(response.data.message))
      return
    }

    yield put(usersActions.getMeSuccess(response.data))
    return
  } catch (error) {
    yield put(usersActions.getMeFailure(error.message))
  }
}
