import type { Claim, ClaimCreateRequest } from '@helloextend/extend-api-client/src/models/claim'
import { createActionFailure } from '../../factories'

export const create = (
  contractId: string,
  claim: ClaimCreateRequest,
  accessToken: string,
  shouldUseClaimsManagement: boolean,
) =>
  ({
    type: 'CLAIMS_CREATE',
    payload: { contractId, claim, accessToken, shouldUseClaimsManagement },
  } as const)

export const createRequest = () => ({ type: 'CLAIMS_CREATE_REQUEST' } as const)

export const createSuccess = (claim: Claim) =>
  ({
    type: 'CLAIMS_CREATE_SUCCESS',
    payload: claim,
  } as const)

export const createFailure = createActionFailure('CLAIMS_CREATE_FAILURE')
