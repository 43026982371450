import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { COLOR } from '../../tokens/colors'
import { IconSize } from '../icon'

interface SpinnerProps {
  color?: string
  size?: IconSize
  'data-cy'?: string
}

const Spinner: FC<SpinnerProps> = ({
  color = COLOR.BLACK,
  size = IconSize.medium,
  'data-cy': dataCy,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-cy={dataCy}
    >
      <clipPath id="clip">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
        />
      </clipPath>
      <foreignObject width="24" height="24" clipPath="url(#clip)">
        <Gradient gradientColor={color} />
      </foreignObject>
    </Svg>
  )
}

export { Spinner, SpinnerProps }

/* STYLES */
const rotate360 = keyframes({ to: { transform: 'rotate(360deg)' } })

const Svg = styled.svg(() => ({
  display: 'inline-block',
  animation: `${rotate360} .75s linear infinite`,
}))

const Gradient = styled.div<{
  gradientColor: string
}>(({ gradientColor }) => ({
  width: '100%',
  height: '100%',
  background: `conic-gradient(${getGradientStartColor(gradientColor)},${gradientColor})`,
}))

/* If provided color is a 6-character RGB hex value append '00' for the alpha channel otherwise replace the included alpha channel with '00' */
const getGradientStartColor = (gradientColor: string): string => {
  if (gradientColor.length === 7) {
    return `${gradientColor}00`
  }
  return `${gradientColor.slice(0, -2)}00`
}
