import type { FC } from 'react'
import React from 'react'
import type { AdvancedSelectChangeEvent } from '@helloextend/zen'
import { Button, Trash } from '@helloextend/zen'
import styled from '@emotion/styled'
import type { PlanIdsCoverageTypeMap } from '@helloextend/extend-api-client/src/models/plan-sets'
import type { PriceBandErrors } from '@helloextend/extend-api-client'
import { PriceInput } from './price-input'
import { PlansSelect } from './plans-select'
import { InlineAlerts } from '../inline-alerts'

export type PriceBandInputFields = 'plans' | 'minPrice' | 'maxPrice'

export type PriceBandChangeEvent<
  EventType = AdvancedSelectChangeEvent<string[]> | React.ChangeEvent<HTMLInputElement>,
> = (e: EventType, priceBandId: string, field: PriceBandInputFields) => void

export interface PriceBandProps {
  planIdsCoverageTypeMap: PlanIdsCoverageTypeMap
  plans: string[]
  minPrice: number
  maxPrice: number
  id: string
  idx: number
  isLoadingPlans: boolean
  handleChangePrice: PriceBandChangeEvent<React.ChangeEvent<HTMLInputElement>>
  handleSelectedPlans: PriceBandChangeEvent<AdvancedSelectChangeEvent<string[]>>
  handleDeletePriceBand: (priceBandId: string) => void
  showDelete: boolean
  errors: PriceBandErrors
  isDisabled: boolean
}

const PriceBand: FC<PriceBandProps> = ({
  planIdsCoverageTypeMap,
  isLoadingPlans,
  id,
  idx,
  minPrice,
  maxPrice,
  handleChangePrice,
  handleSelectedPlans,
  handleDeletePriceBand,
  plans,
  showDelete,
  errors,
  isDisabled,
}) => {
  const priceBandErrors = [...errors.plansErrors, ...errors.priceErrors]
  const priceBandNumber = idx + 1
  return (
    <>
      <Container data-cy={`plans-and-price-mapper-${idx}`}>
        <PlansSelect
          onChange={(e) => handleSelectedPlans(e, id, 'plans')}
          value={plans}
          planIdsCoverageTypeMap={planIdsCoverageTypeMap}
          isLoading={isLoadingPlans}
          isError={!!errors.plansErrors.length}
          isDisabled={isDisabled}
        />
        <End>
          <Range>
            <PriceInput
              id="min-price"
              data-cy="min-price"
              label={`Price Band ${priceBandNumber} Min`}
              ariaLabel={`Minimum price for price band ${priceBandNumber}`}
              value={minPrice.toString()}
              onChange={(e) => handleChangePrice(e, id, 'minPrice')}
              isError={!!errors.priceErrors.length}
              isDisabled={isDisabled}
              currency="USD"
            />
            <PriceInput
              id="max-price"
              data-cy="max-price"
              label={`Price Band ${priceBandNumber} Max`}
              ariaLabel={`Maximum price for price band ${priceBandNumber}`}
              value={maxPrice.toString()}
              onChange={(e) => handleChangePrice(e, id, 'maxPrice')}
              isError={!!errors.priceErrors.length}
              isDisabled={isDisabled}
              currency="USD"
            />
          </Range>
          {showDelete && (
            <ButtonContainer>
              <Button
                icon={Trash}
                color="neutral"
                emphasis="low"
                onClick={() => handleDeletePriceBand(id)}
                data-cy={`delete-price-band-button-${idx}`}
                isDisabled={isDisabled}
              />
            </ButtonContainer>
          )}
        </End>
      </Container>
      {!!priceBandErrors.length && (
        <InlineAlerts
          alerts={[...errors.plansErrors, ...errors.priceErrors]}
          alertType="error"
          data-cy="price-band-errors"
        />
      )}
    </>
  )
}

const Container = styled.div({
  display: 'flex',
  alignItems: 'start',
  gap: 12,
})

const End = styled.div({
  display: 'flex',
  gap: 8,
})

const Range = styled.div({
  display: 'flex',
  alignItems: 'end',
  gap: 6,
})

const ButtonContainer = styled.div({
  marginTop: 26,
  flex: 0,
})

export { PriceBand }
