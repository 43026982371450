import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Button, ButtonGroup, TextArea } from '@helloextend/zen'
import { useFormik } from 'formik'
import { BasicModal } from '../../../components/basic-modal'
import { updatedNoteSchema } from './schema'

type InsuranceProgramUpdatesModalProps = {
  versionNumber: number
  programId: string
  isVisible: boolean
  isProcessing: boolean
  onClickClose: () => void
  handleSave: (notes: string) => void
}

const InsuranceProgramUpdatesModal: FC<InsuranceProgramUpdatesModalProps> = ({
  versionNumber,
  programId,
  isVisible,
  isProcessing,
  onClickClose,
  handleSave,
}) => {
  const { values, errors, isValid, handleChange, handleBlur } = useFormik({
    initialValues: {
      updatedNote: '',
    },
    onSubmit: () => {},
    validationSchema: updatedNoteSchema,
  })

  return (
    <BasicModal isVisible={isVisible} onClickClose={onClickClose}>
      <Title>Save Updates</Title>
      <Text>
        You are about to save the updates made to this insurance program. The saved insurance
        program will become version <Bold>{versionNumber + 1}</Bold> of program ID:{' '}
        <Bold>{programId}</Bold>. This may affect existing offers.
      </Text>
      <Text>Are you sure you want to save the changes?</Text>
      <form onSubmit={() => handleSave(values.updatedNote)}>
        <TextArea
          id="updatedNote"
          value={values.updatedNote}
          label="Updated Reason"
          isError={!isValid}
          errorFeedback={errors.updatedNote}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ButtonGroupWrapper>
          <ButtonGroup>
            <Button
              text="Cancel"
              onClick={onClickClose}
              isDisabled={isProcessing}
              emphasis="medium"
            />
            <Button
              text="Save"
              onClick={() => handleSave(values.updatedNote)}
              isDisabled={!isValid || !values.updatedNote || isProcessing}
              isProcessing={isProcessing}
              data-cy="insurance-program-modal-save-button"
            />
          </ButtonGroup>
        </ButtonGroupWrapper>
      </form>
    </BasicModal>
  )
}

const Title = styled.h3({
  fontSize: 20,
  lineHeight: '28px',
  marginTop: 0,
})

const Text = styled.p({
  lineHeight: '24px',
})

const Bold = styled.span({
  fontWeight: 700,
})

const ButtonGroupWrapper = styled.div({
  marginTop: 24,
  display: 'flex',
  justifyContent: 'flex-end',
})

export { InsuranceProgramUpdatesModal }
