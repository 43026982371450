import type { RefObject } from 'react'
import { useEffect, useRef } from 'react'

interface ClickOutside<TElementType> {
  ref: RefObject<TElementType>
}

export function useClickOutside<TElementType extends Node>(
  onClickOutside: (e: Event) => void,
): ClickOutside<TElementType> {
  const ref = useRef<TElementType>(null)

  useEffect(() => {
    const handleClickOutside = <T extends Event>(e: T): void => {
      if (ref.current && e.target instanceof Node && !ref.current.contains(e.target)) {
        onClickOutside(e)
      }
    }
    document.addEventListener('click', handleClickOutside)
    document.addEventListener('ontouchstart', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
      document.removeEventListener('ontouchstart', handleClickOutside)
    }
  }, [onClickOutside, ref])

  return { ref }
}
