import type { FC } from 'react'
import React, { useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { Grid, Button, useToaster, ToastColor, ToastDuration } from '@helloextend/zen'
import type { ClaimsUser } from '@helloextend/extend-api-client'
import { useUpdateClaimsUserMutation } from '@helloextend/extend-api-rtk-query'
import { ReadOnlyItem } from '../../../../../components/collapsible-info-group/read-only-item'

interface RemoveUserFormProps {
  user?: ClaimsUser
  handleClose: () => void
}

const RemoveUserFormBase: FC<RemoveUserFormProps> = ({ user, handleClose }) => {
  const { toast } = useToaster()
  const [updateClaimsUser, { isSuccess, isLoading }] = useUpdateClaimsUserMutation()

  const handleModalClose = useCallback((): void => {
    handleClose()
  }, [handleClose])

  useEffect(() => {
    if (isSuccess) {
      toast({
        message: 'User removed!',
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
      handleModalClose()
    }
  }, [toast, isSuccess, handleModalClose])

  const handleSubmit = async (): Promise<void> => {
    if (!user) return
    await updateClaimsUser({ userId: user.id, body: { isActive: false } })
  }

  return (
    <FormWrapper>
      <Grid
        columns={{
          lg: 2,
          md: 2,
          sm: 1,
        }}
        spacing={{
          lg: 4,
          md: 2,
          sm: 1,
        }}
      >
        <ReadOnlyItem label="First Name" value={user?.firstName} />
        <ReadOnlyItem label="Last Name" value={user?.lastName} />
        <ReadOnlyItem label="Email" value={user?.email} />
      </Grid>
      <ButtonGroup>
        <Button
          data-cy="cancel-modal-button"
          text="Cancel"
          emphasis="medium"
          onClick={handleModalClose}
        />
        <Button
          data-cy="delete-user-modal-button"
          text="Delete User"
          color="red"
          onClick={handleSubmit}
          isDisabled={isLoading || isSuccess}
        />
      </ButtonGroup>
    </FormWrapper>
  )
}

const FormWrapper = styled.div()

const ButtonGroup = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 16,
  marginTop: 24,
})

const RemoveUserForm = React.memo(RemoveUserFormBase, (prevProps, nextProps) => {
  if (prevProps.user && !nextProps.user) return true
  return false
})

export { RemoveUserForm }
