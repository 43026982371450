import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'

interface GridItemProps {
  children: React.ReactNode
  fillWidth: boolean
  'data-cy'?: string
}

const GridItem: FC<GridItemProps> = ({ children, fillWidth, 'data-cy': dataCy }) => {
  return (
    <StyledGridItem fillWidth={fillWidth} data-cy={dataCy}>
      {children}
    </StyledGridItem>
  )
}

const StyledGridItem = styled.div<{
  fillWidth: boolean
}>(({ fillWidth }) => ({
  ...(fillWidth && {
    gridColumn: '1/-1',
  }),
}))

export { GridItem, GridItemProps }
