import type { VFC, ChangeEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../tokens/colors'
import { Checkmark } from './checkmark'

export interface CheckboxProps {
  label: string
  'aria-label'?: string
  subtext?: string
  value?: string
  name?: string
  checked: boolean
  disabled?: boolean
  indeterminate?: boolean
  indentLevel?: number
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  'data-cy'?: string
}

export const Checkbox: VFC<CheckboxProps> = ({
  label,
  'aria-label': ariaLabel,
  subtext,
  value,
  name,
  checked = false,
  disabled = false,
  indeterminate = false,
  indentLevel = 0,
  onChange = () => {},
  'data-cy': dataCy,
}) => {
  return (
    <StyledCheckbox data-cy={dataCy} indentLevel={indentLevel}>
      <Label disabled={disabled}>
        <CheckmarkContainer>
          <Checkmark
            value={value}
            name={name}
            checked={checked}
            indeterminate={indeterminate}
            disabled={disabled}
            onChange={onChange}
            aria-label={ariaLabel}
          />
        </CheckmarkContainer>
        <LabelText>
          {label}
          {subtext && <Subtext>{subtext}</Subtext>}
        </LabelText>
      </Label>
    </StyledCheckbox>
  )
}

const StyledCheckbox = styled.div<{
  indentLevel: number
}>(({ indentLevel }) => ({
  display: 'flex',
  paddingLeft: indentLevel * 30,
}))

const CheckmarkContainer = styled.span({
  display: 'block',
  paddingTop: 3,
})

const Label = styled.label<{
  disabled: boolean
}>(({ disabled }) => ({
  display: 'inline-flex',
  gap: 8,
  fontSize: 15,
  lineHeight: '20px',
  color: disabled ? COLOR.NEUTRAL[600] : COLOR.NEUTRAL[1000],
  cursor: disabled ? 'default' : 'pointer',
}))

const LabelText = styled.span({
  display: 'inline-block',
  padding: '6px 0',
})

const Subtext = styled.span({
  display: 'block',
  fontSize: 13,
  lineHeight: '18px',
})
