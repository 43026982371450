import type { Action } from '../actions'
import { createBooleanReducer } from '../../factories'

const isSearching = createBooleanReducer<Action>(
  ['CONTRACTS_SEARCH_BY_STORE_REQUEST', 'CONTRACTS_SEARCH_REQUEST'],
  [
    'CONTRACTS_SEARCH_BY_STORE_SUCCESS',
    'CONTRACTS_SEARCH_BY_STORE_FAILURE',
    'CONTRACTS_SEARCH_SUCCESS',
    'CONTRACTS_SEARCH_FAILURE',
    'CONTRACT_SEARCH_RESET',
  ],
)

export default isSearching
