import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { get, post } from '@helloextend/extend-api-fetch'
import type { Offer } from '../models'

export async function fetch(storeId: string, productId: string): Promise<ApiResponse<Offer>> {
  return get('/offers', { qs: { storeId, productId, skipVerify: true } })
}

export async function fetchExportUrl(
  storeId: string,
  accessToken: string,
): Promise<ApiResponse<{ url: string }>> {
  return post(`/offers/${storeId}/export`, {
    accessToken,
    apiVersion: '2020-08-01',
  })
}
