import type { Claim, Contract, Plan, ServiceOrder } from '@helloextend/extend-api-client'
import { useFormik } from 'formik'
import type { FC } from 'react'
import React, { useCallback, useMemo, useState } from 'react'
import type { CollapsibleInfoGroupProps } from '../../../../../../components/collapsible-info-group'
import { CollapsibleInfoGroup } from '../../../../../../components/collapsible-info-group'
import { getClaimInfo, schema } from './schema'
import type { Values } from './schema'
import { useUpdateClaim } from '../../../../../../hooks/use-update-claim'

interface ClaimInfoSectionProps {
  claim: Claim
  contract: Contract
  serviceOrder?: ServiceOrder
  plan?: Plan
}

const ClaimInfoSection: FC<ClaimInfoSectionProps> = ({ claim, contract, serviceOrder, plan }) => {
  const { updateClaim } = useUpdateClaim()
  const [isEditing, setIsEditing] = useState(false)

  const { values, resetForm, setFieldValue, handleSubmit } = useFormik<Values>({
    validationSchema: schema,
    initialValues: { failureType: claim.incident?.failureType ?? '' },
    onSubmit: (formValues: Values) => {
      updateClaim({
        contractId: claim.contractId,
        claimId: claim.id,
        body: {
          incident: {
            ...claim.incident,
            ...formValues,
          },
        },
      })
      toggleIsEditing()
    },
  })

  const claimInfo = useMemo(
    () => getClaimInfo(isEditing, claim, contract, serviceOrder, plan, values),
    [isEditing, claim, contract, values, serviceOrder, plan],
  )

  const toggleIsEditing = useCallback(() => {
    setIsEditing(!isEditing)
  }, [setIsEditing, isEditing])

  const handleChange = (key: string, value: any): void => {
    setFieldValue(key, value)
  }

  return (
    <CollapsibleInfoGroup
      data-cy="claim-info-section"
      title="Claim Details"
      isDefaultCollapsed={false}
      data={claimInfo}
      handleChange={handleChange as CollapsibleInfoGroupProps['handleChange']}
      numColumns={6}
      hasEditButton
      onEditButtonClick={toggleIsEditing}
      isEditing={isEditing}
      primaryButtonText={isEditing ? 'Save Changes' : undefined}
      onPrimaryButtonClick={handleSubmit}
      secondaryButtonText={isEditing ? 'Cancel' : undefined}
      onSecondaryButtonClick={() => {
        resetForm()
        toggleIsEditing()
      }}
      buttonAlignment={isEditing ? 'right' : 'left'}
    />
  )
}

export { ClaimInfoSection }
