import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'

export type SelectItemProps = {
  value: string
  label: string
  onClick?: (e: SyntheticEvent) => void
  isSelected?: boolean
  selectable?: boolean
  'data-cy'?: string
}

const SelectItem: FC<SelectItemProps> = ({
  value,
  label,
  onClick,
  isSelected,
  selectable = true,
  'data-cy': dataCy,
}) => {
  return (
    <>
      {selectable ? (
        <Button
          onClick={onClick}
          value={value}
          type="button"
          isSelected={Boolean(isSelected)}
          data-cy={dataCy || `select-item-${label}`}
        >
          {label}
        </Button>
      ) : (
        <DropdownText>{label}</DropdownText>
      )}
    </>
  )
}
const Button = styled.button<{ isSelected: boolean }>(({ isSelected }) => ({
  border: 'none',
  backgroundColor: isSelected ? COLOR.STRONG_BLUE_2 : COLOR.WHITE,
  color: isSelected ? COLOR.WHITE : COLOR.BLACK_PRIMARY,
  cursor: 'pointer',
  width: '100%',
  padding: '8px 8px 8px 16px',
  display: 'flex',
  fontSize: 16,
  '&:hover': {
    backgroundColor: isSelected ? COLOR.STRONG_BLUE_2 : COLOR.SLATE,
  },
}))

const DropdownText = styled.div({
  border: 'none',
  backgroundColor: COLOR.WHITE,
  color: COLOR.BLACK_PRIMARY,
  cursor: 'pointer',
  width: 'auto',
  padding: '8px 8px 8px 16px',
  display: 'flex',
  fontSize: 14,
  overflowX: 'hidden',
})

export { SelectItem }
