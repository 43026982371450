import type { FC } from 'react'
import React from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { DatePicker } from '@helloextend/merchants-ui'
import { AdjudicationPromptWrapper } from './adjudication-prompt-wrapper'

interface PromptDatePickerProps {
  onChange: (date: Date | null) => void
  disabled?: boolean
  selected?: Date
  placeholder?: string
  badgeValue: number | string
  onBadgeClick: (val: number | string) => void
}

const AdjudicationPromptDatePicker: FC<PromptDatePickerProps> = ({
  onChange,
  disabled,
  selected,
  placeholder,
  badgeValue,
  onBadgeClick,
}) => {
  return (
    <AdjudicationPromptWrapper badgeValue={badgeValue} onBadgeClick={onBadgeClick}>
      <DatePickerWrapper>
        <DatePicker
          placeHolder={placeholder || dayjs().format('MMM D, YYYY')}
          fullWidth
          disabled={disabled}
          onChange={onChange}
          selected={selected || null}
          data-cy="adjudication-management-date-picker"
        />
      </DatePickerWrapper>
    </AdjudicationPromptWrapper>
  )
}

const DatePickerWrapper = styled.div({
  flexGrow: 1,
})

export { AdjudicationPromptDatePicker, PromptDatePickerProps }
