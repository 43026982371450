import { useFormikContext } from 'formik'
import type { FC } from 'react'
import React from 'react'
import { Checkbox } from '@helloextend/zen'
import type { Values } from '../shipping-protection-purchase-model-form/schema'

const PartialReimbursementMerchantCheckbox: FC = () => {
  const { values, handleChange } = useFormikContext<Values>()

  return (
    <Checkbox
      label="Partial Reimbursement Merchant"
      checked={Boolean(values.partialReimbursement)}
      onChange={handleChange}
      name="partialReimbursement"
      data-cy="partial-reimbursement-merchant-checkbox"
    />
  )
}
export { PartialReimbursementMerchantCheckbox }
