import type { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import type { ActionType } from '../actions'
import create from './create'
import destroy from './destroy'
import fetch from './fetch'
import fetchAll from './fetch-all'
import update from './update'
// import updateBatch from './update-batch'

export default function* sagas(): SagaIterator {
  yield all([
    takeLatest('STORES_DESTROY' as ActionType, destroy),
    takeLatest('STORES_FETCH' as ActionType, fetch),
    takeLatest('STORES_FETCH_ALL' as ActionType, fetchAll),
    takeLatest('STORES_UPDATE' as ActionType, update),
    takeLatest('STORES_CREATE' as ActionType, create),
    // takeLatest('STORES_UPDATE_BATCH' as ActionType, updateBatch),
  ])
}
