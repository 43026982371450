import * as createActions from './create'
import * as voidActions from './void'
import * as fetchActions from './fetch'
import * as errorActions from './error'
import type { ExtractActions } from '../../types/utility'

export type Action =
  | ExtractActions<typeof createActions>
  | ExtractActions<typeof errorActions>
  | ExtractActions<typeof fetchActions>
  | ExtractActions<typeof voidActions>

export type ActionType = Action['type']

const actions = {
  ...createActions,
  ...fetchActions,
  ...errorActions,
  ...voidActions,
}

export { actions }
