import * as Yup from 'yup'

const deductibleMetaDataSchema = Yup.number()
  .required('Deductible Amount is required')
  .typeError('Please enter a number')
  .default(null)

const schema = Yup.object()
  .shape(
    {
      // TODO 370: will use a service to generate a SKU ID
      id: Yup.string().required().default('test-sku-id'),
      name: Yup.string().required('SKU name is required'),
      activeFrom: Yup.mixed<undefined | number>()
        .required('Active From is required')
        .default(undefined),
      activeTo: Yup.mixed<undefined | number>()
        .notRequired()
        .default(undefined)
        .test(
          'activeTo',
          'Activate To must be later than Activate From',
          function testActiveTo(value) {
            const { activeFrom } = this.parent
            if (!activeFrom || value === null || value === undefined) return true
            return value >= activeFrom
          },
        ),
      program: Yup.string().required('Program is required'),
      subprogram: Yup.string().required('Subprogram is required'),
      coverageType: Yup.string().required('Coverage Type is required'),
      term: Yup.number()
        .required('Term is required')
        .typeError('Please enter a number')
        .default(null),
      serviceType: Yup.string().required('Service Type is required'),
      lineOfBusiness: Yup.string().required('Line of Business is required'),
      currencyCode: Yup.string().required('Currency is required'),
      productCondition: Yup.string().required('Product Condition is required'),
      purchasePriceOfProduct: Yup.string().required('Purchase Price of Product is required'),
      limitOfLiabilityBasedUpon: Yup.string().required('Limit of Liability Based Upon is required'),
      paymentModel: Yup.string().required('Payment Model is required'),
      cancellation: Yup.string().notRequired(),
      obligor: Yup.string().required('Obligor is required'),
      insuranceProgramId: Yup.string().required('Insurance Program ID is required'),
      priceBandLow: Yup.number()
        .required('Price Band Low is required')
        .typeError('Please enter a number')
        .default(null)
        .test(
          'is lower than price band high',
          'Value in Price Band Low needs to be lower than the value in Price Band Low',
          function testPriceBandLow(value) {
            const { priceBandHigh } = this.parent
            return (value || 0) <= priceBandHigh
          },
        ),
      priceBandHigh: Yup.number()
        .required('Price Band High is required')
        .typeError('Please enter a number')
        .default(null)
        .test(
          'is higher than price band low',
          'Value in Price Band High needs to be higher than the value in Price Band Low',
          function testPriceBandHigh(value) {
            const { priceBandLow } = this.parent
            return (value || 0) >= priceBandLow
          },
        ),
      isDeductible: Yup.string().required('Deductible is required'),
      deductibleAmount: Yup.number()
        .default(undefined)
        .when('isDeductible', {
          is: (value) => value === 'yes',
          then: deductibleMetaDataSchema,
          otherwise: Yup.number().notRequired(),
        }),
      lossCost: Yup.number()
        .required('Loss Cost is required')
        .typeError('Please enter a number')
        .default(null),
      targetLossRatio: Yup.number()
        .required('Target Loss Ratio is required')
        .typeError('Please enter a number')
        .max(100, 'Target Loss Ratio must be less than or equal to 100')
        .default(null),
      underwritingProfit: Yup.number()
        .typeError('Please enter a number')
        .default(null)
        .when('obligorFeeRate', {
          is: (value) => {
            return value !== null || value > 0
          },
          then: Yup.number().notRequired().nullable(),
          otherwise: Yup.number()
            .required('Obligor Fee Rate or Obligor Fee Amount is required')
            .nullable(),
        }),
      reserve: Yup.number()
        .required('Reserve is required')
        .typeError('Please enter a number')
        .default(null),
      obligorFee: Yup.number()
        .required('Obligor Fee Rate Amount is required')
        .typeError('Please enter a number')
        .default(null),
      obligorFeeRate: Yup.number()
        .typeError('Please enter a number')
        .max(100, 'Obligor Fee Rate must be less than or equal to 100')
        .default(null)
        .when('underwritingProfit', {
          is: (value) => value !== null || value > 0,
          then: Yup.number().notRequired().nullable(),
          otherwise: Yup.number()
            .required('Obligor Fee Rate or Obligor Fee Amount is required')
            .nullable(),
        }),
      premium: Yup.number().default(0).required('Premium is required'),
      isActive: Yup.string().default('yes').required('Active is required').oneOf(['yes', 'no']),
    },
    // to avoid formik cyclic dependency
    [['obligorFeeRate', 'underwritingProfit']],
  )
  .defined()

type Values = Yup.InferType<typeof schema>

export { schema, Values }
