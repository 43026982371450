import type { Store } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetch = (storeId: string, accessToken: string) =>
  ({ type: 'STORES_FETCH', payload: { storeId, accessToken } } as const)

export const fetchRequest = () => ({ type: 'STORES_FETCH_REQUEST' } as const)

export const fetchSuccess = (stores: Store) =>
  ({
    type: 'STORES_FETCH_SUCCESS',
    payload: stores,
  } as const)

export const fetchFailure = createActionFailure('STORES_FETCH_FAILURE')
