import { calculateObligorFee, calculatePremium, calculateReserve } from '../sku-rates-calculations'
import type { UploadValidatorPipe } from './csv-validation'
import { getColumnValue } from './csv-validation'

const validateReserveColumn = (validationPipeResults: UploadValidatorPipe): UploadValidatorPipe => {
  const lossCostToNum = Number(getColumnValue(validationPipeResults, 'lossCost'))
  const targetLossRatio = Number(getColumnValue(validationPipeResults, 'targetLossRatio'))

  const correctReserve = calculateReserve({
    lossCost: lossCostToNum,
    targetLossRatio,
  })
  const actualReserve = getColumnValue(validationPipeResults, 'reserve')
  if (correctReserve !== Number(actualReserve)) {
    validationPipeResults.warnings.push('Reserve value is not equal to lossCost / targetLossRatio')
  }
  return validationPipeResults
}

const validateObligorFeeRateAmountColumn = (
  validationPipeResults: UploadValidatorPipe,
): UploadValidatorPipe => {
  // when obligorFeeRate is not provided
  const obligorFeeRateAmount = Number(getColumnValue(validationPipeResults, 'obligorFeeRateAmount'))
  const obligorFeeRate = Number(getColumnValue(validationPipeResults, 'obligorFeeRate'))
  if (!obligorFeeRate && obligorFeeRateAmount > 0) {
    validationPipeResults.warnings.push(
      'Obligor Fee Rate Amount should not be more than 0 when Obligor Fee Rate is not provided',
    )
    return validationPipeResults
  }

  // when obligorFeeRate is provided
  const reserveValue = Number(getColumnValue(validationPipeResults, 'reserve'))
  const correctObligorFeeRateAmount = calculateObligorFee({
    reserve: reserveValue,
    obligorFeeRate,
  })
  if (obligorFeeRateAmount !== correctObligorFeeRateAmount) {
    validationPipeResults.warnings.push(
      'Obligor Fee Rate Amount is not equal to Reserve * Obligor Fee Rate',
    )
  }
  return validationPipeResults
}

const validatePremiumColumn = (validationPipeResults: UploadValidatorPipe): UploadValidatorPipe => {
  const obligorFeeRate = Number(getColumnValue(validationPipeResults, 'obligorFeeRate'))
  const obligorFeeAmount = Number(getColumnValue(validationPipeResults, 'obligorFeeAmount'))
  const reserve = Number(getColumnValue(validationPipeResults, 'reserve'))

  const correctPremium = calculatePremium({
    obligorFeeRate,
    reserve,
    underwritingProfit: obligorFeeAmount,
  })
  const actualPremium = Number(getColumnValue(validationPipeResults, 'premium'))

  if (correctPremium !== actualPremium && !obligorFeeRate) {
    validationPipeResults.warnings.push(
      'Premium is not equal to Reserve / (1 - Obligor Fee Rate) + Obligor Fee Amount',
    )
  } else if (correctPremium !== actualPremium && obligorFeeRate) {
    validationPipeResults.warnings.push('Premium is not equal to Reserve + Obligor Fee Amount')
  }
  return validationPipeResults
}

export { validateReserveColumn, validateObligorFeeRateAmountColumn, validatePremiumColumn }
