import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { get, post } from '@helloextend/extend-api-fetch'
import type {
  Servicer,
  ServicerCreateRequest,
  ServicerLocation,
  ServicerLocationCreateRequest,
} from '../models'

export async function listServicers(accessToken: string): Promise<ApiResponse<Servicer[]>> {
  return get('/servicers', { accessToken })
}

export async function createServicer(
  body: ServicerCreateRequest,
  accessToken: string,
): Promise<ApiResponse<Servicer>> {
  return post('/servicers', { accessToken, body })
}

export async function createServicerLocation(
  servicerId: string,
  body: ServicerLocationCreateRequest,
  accessToken: string,
): Promise<ApiResponse<ServicerLocation>> {
  return post(`/servicers/${servicerId}/servicer-locations`, { accessToken, body })
}
