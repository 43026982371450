import type { InvokeNeed, Need, Option, Pattern } from '@helloextend/extend-api-rtk-query'
import { getIndex } from '../../utils'

export interface DecisionValues {
  value: string
  index: number | string
}

export function getDecisionBlockTitle(needs: Need[]): string | undefined {
  const invokeNeed: InvokeNeed | undefined = needs.find((need: Need): need is InvokeNeed => {
    return 'invoke' in need
  })

  if (invokeNeed) {
    return invokeNeed.invoke
  }
  return undefined
}

export function getDecisionBlockValues(options: Option[]): DecisionValues[] {
  return options
    .map((option: Option): DecisionValues[] => {
      const index: number | string = getIndex(option)

      if (option.default) {
        return [{ value: 'none of the above', index }]
      }
      if (option.patterns) {
        return getValuesFromPatterns(option.patterns, index)
      }
      throw new Error('Improperly formatted script option, must have default or patterns')
    })
    .reduce((acc, currentValue) => {
      return acc.concat(currentValue)
    }, [] as DecisionValues[])
}

function getValuesFromPatterns(patterns: Pattern[], index: number | string): DecisionValues[] {
  return patterns.map((pattern: Pattern): DecisionValues => {
    if (pattern.input) {
      return { value: `customer supplied: ${pattern.input}`, index }
    }

    if (pattern.params) {
      const keys: string[] = Object.keys(pattern.params)
      const hasMultipleResultUsages =
        keys.filter((key: string) => key.startsWith('result')).length > 1

      const values: string[] = []
      for (const key of keys) {
        // We only want to show result.state if were not using another result property
        // Essentially do not show failure if we are already going to show the error code
        if (key === 'result.state' && !hasMultipleResultUsages) {
          values.push(String(pattern.params[key]))
        } else if (key === 'result.errorCode') {
          values.push(String(pattern.params[key]).replace(/_/g, ' '))
        } else if (!key.startsWith('result')) {
          values.push(`${key} is equal to ${pattern.params[key]}`)
        }
      }
      return { value: values.join(' & '), index }
    }
    throw new Error('Improperly formatted pattern block, must have input or params')
  })
}
