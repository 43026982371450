import type { FC } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import type { DataTableAction } from '@helloextend/zen'
import { DataTable, ChevronRight } from '@helloextend/zen'
import { ZeroState } from '@helloextend/merchants-ui'
import { usersColumns, toTableUsers, FILTER_DEFS } from './table-config'
import type { TableAccountUser } from './table-config'
import type { UserWithGrants } from '../../../../../../types/users'

type UsersDataTableProps = {
  accountId: string
  userData: UserWithGrants[]
  isLoading: boolean
}

const UsersDataTable: FC<UsersDataTableProps> = ({ accountId, userData, isLoading }) => {
  const { push } = useHistory()

  const getRowActions = (row: TableAccountUser): DataTableAction[] => [
    {
      onClick: () => push(`/admin/accounts/${accountId}/users/${row.uuid}`),
      icon: ChevronRight,
      emphasis: 'low',
      'data-cy': `merchant-users-table:row:action:edit`,
    },
  ]

  return (
    <>
      {!isLoading && !userData.length ? (
        <ZeroState
          title="No Users found under this account"
          text="Users should be listed here"
          data-cy="account-users-zero-state:message-container"
        />
      ) : (
        <DataTable
          data={toTableUsers(userData, accountId)}
          isLoading={isLoading}
          columns={usersColumns}
          getRowActions={getRowActions}
          filterDefs={FILTER_DEFS}
          data-cy="merchant-users-table"
        />
      )}
    </>
  )
}

export { UsersDataTable }
