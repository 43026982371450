import type { FC } from 'react'
import React from 'react'
import { FieldArray, useFormikContext } from 'formik'
import { Add, Button, Trash } from '@helloextend/zen'
import styled from '@emotion/styled'
import type { SPPlanPriceBandMapping } from '@helloextend/extend-api-client'
import type { Values } from '../shipping-protection-purchase-model-form/schema'
import { getInitialPriceBandValues } from '../shipping-protection-purchase-model-form/schema'
import { PriceBandModelRow } from '../price-band-model-row/price-band-model-row'

type PriceBandModelFormProps = {
  spPlansList: string[]
  isSpListLoading?: boolean
}

const PriceBandModelForm: FC<PriceBandModelFormProps> = ({ spPlansList, isSpListLoading }) => {
  const { values, handleChange, errors } = useFormikContext<Values>()

  const isDeleteButtonDisplayed = values.priceBands && values?.priceBands.length > 1

  return (
    <div data-cy="price-bands-form">
      <FieldArray
        name="priceBands"
        render={({ push, remove }) => (
          <>
            {values?.priceBands?.map((value, index) => (
              <>
                <RowContainer key={value.toString()} isDeleteDisplayed={isDeleteButtonDisplayed}>
                  <PriceBandModelRow
                    index={index}
                    data={value as SPPlanPriceBandMapping}
                    errors={errors}
                    handleOnChange={handleChange}
                    isLabelDisplayed={index === 0}
                    isPartialReimbursementEnabled={values.partialReimbursement}
                    spPlansList={spPlansList}
                    isSpListLoading={isSpListLoading}
                  />
                  {isDeleteButtonDisplayed && (
                    <ButtonContainer
                      isLabelHidden={index > 0}
                      isError={!!errors?.priceBands?.[index]}
                    >
                      <Button
                        icon={Trash}
                        onClick={() => remove(index)}
                        emphasis="low"
                        data-cy={`delete-row-${index}`}
                      />
                    </ButtonContainer>
                  )}
                </RowContainer>
              </>
            ))}
            <Button
              text="Add Price Band"
              data-cy="add-price-band-button"
              icon={Add}
              emphasis="low"
              onClick={() => push(getInitialPriceBandValues())}
            />
          </>
        )}
      />
    </div>
  )
}

const ButtonContainer = styled.div<{ isLabelHidden?: boolean; isError?: boolean }>(
  ({ isLabelHidden, isError }) => ({
    ...(!isLabelHidden &&
      !isError && {
        marginTop: '22px',
      }),
    ...(isLabelHidden &&
      isError && {
        marginTop: '-27px',
      }),
    display: 'flex',
    alignSelf: 'center',
  }),
)

const RowContainer = styled.div<{ isDeleteDisplayed?: boolean }>(({ isDeleteDisplayed }) => ({
  marginBottom: 16,
  columnGap: 8,
  ...(isDeleteDisplayed && {
    display: 'grid',
    gridTemplateColumns: '3fr .1fr',
  }),
}))

export { PriceBandModelForm }
