import type { AuditItem, Contract } from '@helloextend/extend-api-client'
import type { Action } from '../actions'

export type AuditLogReducerState = {
  currentPage: Array<AuditItem<Contract>>
  nextPageCursor: string | null | undefined
  page: number
  lastFetched: number
  pageGroup: {
    [key: string]: Array<AuditItem<Contract>>
  }
}

export const initialState: AuditLogReducerState = {
  currentPage: [],
  nextPageCursor: null,
  page: 0,
  lastFetched: 0,
  pageGroup: {
    1: [],
  },
}

export default function auditLog(state = initialState, action: Action): AuditLogReducerState {
  switch (action.type) {
    case 'CONTRACT_FETCH_AUDIT_HISTORY':
      return initialState
    case 'CONTRACT_AUDIT_HISTORY_INCREMENT_NEXT_PAGE': {
      const page = state.page + 1
      return {
        ...state,
        page,
        currentPage: state.pageGroup[page],
      }
    }
    case 'CONTRACT_AUDIT_HISTORY_PREV_PAGE': {
      const page = state.page - 1 || 1
      return {
        ...state,
        page,
        currentPage: state.pageGroup[page],
      }
    }
    case 'CONTRACT_AUDIT_HISTORY_SUCCESS': {
      const nextPage = state.page + 1
      const auditItems = action.payload.items.sort((a, b) => b.eventTime - a.eventTime)

      // Removes last item, as it will be the first change recorded which we dont care about
      auditItems.pop()

      return {
        ...state,
        pageGroup: {
          ...state.pageGroup,
          [nextPage]: auditItems,
        },
        page: nextPage,
        lastFetched: nextPage,
        currentPage: auditItems,
        nextPageCursor: action.payload.nextPageCursor,
      }
    }
    default:
      return state
  }
}
