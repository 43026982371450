export enum ExternalErrorCodes {
  service_order_not_found = 'service_order_not_found',
  service_order_could_not_assign = 'service_order_could_not_assign',
  service_order_could_not_accept = 'service_order_could_not_accept',
  service_order_could_not_approve_payment = 'service_order_could_not_approve_payment',
  unknown_error_when_registering_third_party_label_for_tracking = 'unknown_error_when_registering_third_party_label_for_tracking',
  invalid_status_transition = 'invalid_status_transition',
  expense_not_found = 'expense_not_found',
  ship_engine_503_http = 'ship_engine_503_http',
  service_unavailable = 'service_unavailable',
  tracking_number_invalid_or_unavailable = 'tracking_number_invalid_or_unavailable',
}
