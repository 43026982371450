import * as fetchActions from './fetch'
import * as fetchAllActions from './fetch-all'
import * as updateActions from './update'
import * as updateBatchActions from './update-batch'
import * as resetActions from './reset'
import type { ExtractActions } from '../../types/utility'

export type Action =
  | ExtractActions<typeof fetchActions>
  | ExtractActions<typeof fetchAllActions>
  | ExtractActions<typeof updateActions>
  | ExtractActions<typeof updateBatchActions>
  | ExtractActions<typeof resetActions>

export type ActionType = Action['type']

const actions = {
  ...fetchActions,
  ...fetchAllActions,
  ...updateActions,
  ...updateBatchActions,
  ...resetActions,
}

export { actions }
