import type { Expense } from '@helloextend/extend-api-client'
import type { Action } from '../actions'

export type ByServiceOrderReducerState = Record<string, Expense[]>

export const initialState: ByServiceOrderReducerState = {}

export default function byServiceOrder(
  state = initialState,
  action: Action,
): ByServiceOrderReducerState {
  switch (action.type) {
    case 'SERVICE_ORDER_EXPENSES_FETCH_SUCCESS':
      return addItems(state, action.payload.serviceOrderExpenses)
    default:
      return state
  }
}

function addItems(state: ByServiceOrderReducerState, items: Expense[]): ByServiceOrderReducerState {
  if (items.length) {
    return { ...state, [items[0].serviceOrderId]: items }
  }

  return state
}
