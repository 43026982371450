import type { FC } from 'react'
import React from 'react'
import type { Store } from '@helloextend/extend-api-client'
import { defaultLeadUrlSchema } from './schema'
import { CheckboxWithInput } from '../checkbox-with-input'
import type { SaveClickProps } from '../checkbox-with-input/checkbox-with-input'

type DefaultUrlSettingsProps = {
  store: Store
  handleUpdateStore: (data: Partial<Store>) => void
  isLoading?: boolean
}

const DefaultUrlSettings: FC<DefaultUrlSettingsProps> = ({
  store,
  handleUpdateStore,
  isLoading,
}) => {
  const handleSave = ({ value, isChecked }: Partial<SaveClickProps>): void => {
    handleUpdateStore({
      defaultLeadUrl: value as string,
      customLeadUrl: store.customLeadUrl || (value as string),
      leadUrlConfigurabilityEnabled: isChecked,
    })
  }

  return (
    <>
      <CheckboxWithInput
        schema={defaultLeadUrlSchema}
        checkboxLabel="Show Lead URLs"
        inputId="defaultLeadUrl"
        inputValue={store?.defaultLeadUrl || ''}
        inputLabel="Default URL Format"
        placeholder="Enter Default URL Format"
        isInitialChecked={Boolean(store?.leadUrlConfigurabilityEnabled)}
        handleSave={handleSave}
        checkboxId="leadUrlConfigurabilityEnabled"
        isLoading={isLoading}
        informationChildren={
          <>
            <p>
              Control whether the merchant can copy lead URLs, and what their default format is.
            </p>
            <p>Enter a default URL and click Save to enable this feature.</p>
          </>
        }
      />
    </>
  )
}

export { DefaultUrlSettings }
