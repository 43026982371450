import * as Yup from 'yup'

const schema = Yup.object()
  .shape({
    contact: Yup.object()
      .shape({
        name: Yup.string().default('').required('Required'),
        email: Yup.string().default('').required('Required'),
        phone: Yup.string().default('').required('Required'),
      })
      .required()
      .defined(),
    businessName: Yup.string().default('').required('Required'),
    type: Yup.string().default('third-party').required(),
    settings: Yup.object()
      .shape({
        paymentPreference: Yup.string(),
      })
      .defined(),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export { schema }
export type { Values }
