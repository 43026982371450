import type {
  InsuranceClaim,
  Contract,
  Order,
  LineItemShipments,
  ServiceOrder,
} from '@helloextend/extend-api-client'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import { flatten } from 'lodash/fp'
import { CollapsibleInfoGroup } from '../../../../../../components/collapsible-info-group'
import type { GroupSection } from '../../../../../../components/collapsible-info-group/collapsible-info-group'
import {
  getProductInfo,
  getShippingCostTaxInfo,
  getTotalCostInfo,
  getShippingProtectionProductsInfo,
  getClaimItemInfo,
} from './schema'
import {
  isShippingProtectionType,
  getShippingProtectionProducts,
  getTotalCost,
} from '../../../util'

export interface ProductInfoSectionProps {
  contract: Contract
  claim: InsuranceClaim
  order?: Order
  serviceOrder?: ServiceOrder
}

interface ShippingCostTaxProps {
  name: string
  detail: string
  purchasePrice: number
  replacementValue: number
}

interface TotalCostProps {
  name: string
  totalCost: number
}

function getProductInfoHelper(
  contract: Contract,
  claim: InsuranceClaim,
  order?: Order,
): GroupSection[] {
  if (isShippingProtectionType(claim.type)) {
    if (order) {
      const shipmentLineItems = order.lineItems.filter(
        (lineItem) => lineItem.type === 'shipments',
      ) as LineItemShipments[]
      const [shipmentLineItem] = shipmentLineItems
      const shippingProducts = getShippingProtectionProducts(
        order.lineItems,
        shipmentLineItem.lineItemIds ?? [],
      )
      const products = shippingProducts.map((product) => getShippingProtectionProductsInfo(product))

      return flatten(products)
    }
    return []
  }

  if (!claim.items) return getProductInfo(contract)
  const products = claim.items.map((item) => getClaimItemInfo(contract, item))
  return flatten(products)
}

const ProductInfoSection: FC<ProductInfoSectionProps> = ({ contract, claim, order }) => {
  const productInfo = useMemo(
    () => getProductInfoHelper(contract, claim, order),
    [contract, claim, order],
  )

  const shippingCostTaxInfo = useMemo(() => {
    if (isShippingProtectionType(claim.type) && order) {
      const shippingCostTaxTotal = (order.shippingCostTotal ?? 0) + (order.taxCostTotal ?? 0)
      const shippingCostTotalValue: ShippingCostTaxProps = {
        name: 'Misc Fee',
        detail: 'Taxes & Shipping',
        purchasePrice: shippingCostTaxTotal,
        replacementValue: shippingCostTaxTotal,
      }
      return getShippingCostTaxInfo(shippingCostTotalValue)
    }
    return []
  }, [order, claim])

  const totalCost = getTotalCost(order)

  const totalCostInfo = useMemo(() => {
    if (isShippingProtectionType(claim.type) && totalCost) {
      const totalCostValue: TotalCostProps = {
        name: 'Total',
        totalCost,
      }
      return getTotalCostInfo(totalCostValue)
    }
    return []
  }, [claim, totalCost])

  const productWithShippingCost = [...productInfo, ...shippingCostTaxInfo, ...totalCostInfo]
  return (
    <CollapsibleInfoGroup
      data-cy="product-info-section"
      title="Items Requiring Fulfillment"
      isDefaultCollapsed={false}
      data={productWithShippingCost}
      handleChange={() => {}}
      numColumns={4}
    />
  )
}

export { ProductInfoSection }
