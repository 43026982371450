import type { SagaIterator } from 'redux-saga'
import { all, takeEvery } from 'redux-saga/effects'
import type { ActionType } from '../actions'
import { list } from './list'
import { create } from './create'

export default function* sagas(): SagaIterator {
  yield all([
    takeEvery('SERVICERS_LIST' as ActionType, list),
    takeEvery('SERVICERS_CREATE' as ActionType, create),
  ])
}
