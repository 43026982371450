import type { VFC, ChangeEvent, MouseEvent } from 'react'
import React, { useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../tokens/colors'

export interface CheckmarkProps {
  'aria-label'?: string
  value?: string
  name?: string
  checked: boolean
  disabled?: boolean
  indeterminate?: boolean
  hideInteractivity?: boolean
  tabIndex?: number
  tooltip?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onClick?: (e: MouseEvent<HTMLInputElement>) => void
  'data-cy'?: string
}

export const Checkmark: VFC<CheckmarkProps> = ({
  'aria-label': ariaLabel,
  value,
  name,
  checked = false,
  disabled = false,
  indeterminate = false,
  hideInteractivity = false,
  tabIndex,
  tooltip,
  onChange = () => {},
  onClick = () => {},
  'data-cy': dataCy,
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null)
  const appearsChecked = checked || indeterminate
  useEffect(() => {
    if (checkboxRef.current) {
      if (indeterminate === true) {
        checkboxRef.current.indeterminate = true
      } else {
        checkboxRef.current.indeterminate = false
      }
    }
  }, [indeterminate])
  return (
    <CheckmarkContainer data-tooltip={tooltip}>
      <Input
        aria-disabled={disabled}
        aria-checked={checked}
        aria-label={ariaLabel}
        role="checkbox"
        data-cy={dataCy}
        ref={checkboxRef}
        type="checkbox"
        value={value}
        name={name}
        appearsChecked={appearsChecked}
        checked={checked}
        indeterminate={indeterminate}
        disabled={disabled}
        hideInteractivity={hideInteractivity}
        onChange={onChange}
        onClick={onClick}
        tabIndex={hideInteractivity ? -1 : tabIndex}
      />
      <CheckmarkIcon>
        <path d="M10.492.754a1 1 0 0 1 .379 1.363l-4.942 8.75a1 1 0 0 1-1.68.095L1.19 6.746A1 1 0 1 1 2.81 5.57l2.144 2.956 4.175-7.394a1 1 0 0 1 1.363-.379Z" />
      </CheckmarkIcon>
    </CheckmarkContainer>
  )
}

const CheckmarkContainer = styled.span({
  display: 'flex',
  position: 'relative',
  flexShrink: 0,
})

const CheckmarkIcon = styled.svg({
  position: 'absolute',
  width: 12,
  height: 12,
  pointerEvents: 'none',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  fill: COLOR.WHITE,
  opacity: 0,
  transition: '120ms',
})

const Input = styled.input<{
  appearsChecked: boolean
  checked: boolean
  indeterminate: boolean
  disabled: boolean
  hideInteractivity: boolean
}>(({ appearsChecked, checked, indeterminate, disabled, hideInteractivity }) => ({
  position: 'relative',
  appearance: 'none',
  width: 32,
  height: 32,
  margin: -4,
  borderRadius: 4,
  cursor: disabled ? 'default' : 'pointer',
  transition: '50ms',
  outline: 'none',
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 6,
    borderRadius: 2,
    boxShadow: disabled
      ? `inset 0 0 0 1px ${COLOR.NEUTRAL[300]}`
      : `inset 0 0 0 1px ${COLOR.NEUTRAL[400]}`,
    backgroundColor: disabled ? COLOR.NEUTRAL.OPACITY[25] : 'transparent',
    transition: '120ms',
    ...(appearsChecked && {
      boxShadow: 'none',
      backgroundColor: disabled ? COLOR.NEUTRAL[500] : COLOR.BLUE[800],
    }),
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 10,
    top: 15,
    width: 12,
    height: 2,
    borderRadius: 1,
    backgroundColor: COLOR.WHITE,
    opacity: 0,
    ...(indeterminate && {
      opacity: 1,
    }),
  },
  ...(checked &&
    !indeterminate && {
      [`& + ${CheckmarkIcon}`]: {
        opacity: 1,
      },
    }),
  ...(!disabled && {
    ...(!hideInteractivity && {
      '&:hover': {
        backgroundColor: COLOR.NEUTRAL.OPACITY[12],
      },
    }),
    '&:focus-visible': {
      boxShadow: `inset 0 0 0 2px ${COLOR.BLUE[700]}`,
    },
  }),
}))
