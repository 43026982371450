import { combineReducers } from 'redux'
import type { ByIdReducerState } from './by-id'
import byId from './by-id'
import type { AuditLogReducerState } from './audit-log'
import auditLog from './audit-log'
import error from './error'
import isLoading from './is-loading'
import isUpdating from './is-updating'
import isSearching from './is-searching'
import isResending from './is-resending'
import isCreating from './is-creating'
import isSuccess from './is-success'
import type { ErrorReducerState } from '../../types/error'
import type { Action } from '../actions'
import type { ContractsSearchReducerState } from './search'
import search from './search'

export interface ReducerState {
  byId: ByIdReducerState
  error: ErrorReducerState
  isLoading: boolean
  isUpdating: boolean
  isSearching: boolean
  isResending: boolean
  isCreating: boolean
  isSuccess: boolean
  auditLog: AuditLogReducerState
  search: ContractsSearchReducerState
}

export default combineReducers<ReducerState, Action>({
  byId,
  error,
  isLoading,
  isUpdating,
  isSearching,
  isResending,
  isCreating,
  isSuccess,
  auditLog,
  search,
})
