// This file is derived from react-colorful
// https://github.com/omgovich/react-colorful/blob/a85e5b36b55cae7e95c73c8ecde0bc881e8e3b1f/src/components/common/Hue.tsx

import type { FC } from 'react'
import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import type { Interaction } from './interactive'
import { Interactive } from './interactive'
import { Pointer } from './pointer'

interface HueProps {
  hue: number
  onChange: (newHue: { h: number }) => void
  onMouseUp?: () => void
  onMouseDown?: () => void
}

const HueBase: FC<HueProps> = ({ hue, onChange, onMouseDown, onMouseUp }) => {
  const handleMove = useCallback(
    (interaction: Interaction): void => {
      onChange({ h: 360 * interaction.left })
    },
    [onChange],
  )

  return (
    <Wrapper>
      <Interactive onMove={handleMove} onMouseUp={onMouseUp} onMouseDown={onMouseDown}>
        <Pointer left={hue / 360} color={`hsl(${hue}, 100%, 50%)`} />
      </Interactive>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  position: 'relative',
  height: 24,
  borderRadius: 4,
  background: `linear-gradient(
    to right,
    #f00 0%,
    #ff0 17%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 83%,
    #f00 100%
  )`,
})

const Hue = React.memo(HueBase)
export { Hue }
