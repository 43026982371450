import { combineReducers } from 'redux'
import type { LoginRedirectPathReducerState } from './login-redirect-path'
import loginRedirectPath from './login-redirect-path'

export interface RouterReducerState {
  loginRedirectPath: LoginRedirectPathReducerState
}

export default combineReducers<RouterReducerState>({
  loginRedirectPath,
})
