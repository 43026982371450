import type { FC } from 'react'
import React from 'react'
import { COLOR } from '../../tokens/colors'
import type { NavIconProps as LibraryNavIconProps } from '../../tokens/nav-icons'

interface NavIconProps {
  icon: React.ElementType<LibraryNavIconProps>
  color?: string
  filled?: boolean
  'data-cy'?: string
}

const NavIcon: FC<NavIconProps> = ({
  icon: NavIconComponent,
  filled = false,
  color = COLOR.BLACK,
  'data-cy': dataCy,
}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={{ display: 'inline-block', flexGrow: 0, flexShrink: 0 }}
      data-cy={dataCy}
    >
      <NavIconComponent filled={filled} color={color} />
    </svg>
  )
}

export { NavIcon, NavIconProps }
