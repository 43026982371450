import type { Action } from '../actions'
import { createErrorReducer } from '../../factories'

const error = createErrorReducer<Action>(
  ['SERVICE_ORDER_EXPENSES_CREATE_FAILURE', 'SERVICE_ORDER_EXPENSES_VOID_FAILURE'],
  [
    'SERVICE_ORDER_EXPENSES_CREATE_REQUEST',
    'SERVICE_ORDER_EXPENSES_VOID_REQUEST',
    'SERVICE_ORDER_EXPENSES_ERROR_RESET',
  ],
)

export default error
