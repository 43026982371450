// This file is derived from react-colorful
// https://github.com/omgovich/react-colorful/blob/a85e5b36b55cae7e95c73c8ecde0bc881e8e3b1f/src/components/common/Interactive.tsx

import styled from '@emotion/styled'
import type { FC } from 'react'
import React, { useRef, useMemo, useEffect } from 'react'
import { clamp } from '../../../../utils/math'

interface Interaction {
  left: number
  top: number
}

// Returns a relative position of the pointer inside the node's bounding box
const getRelativePosition = (node: HTMLDivElement, event: MouseEvent): Interaction => {
  const rect = node.getBoundingClientRect()

  return {
    left: clamp((event.pageX - (rect.left + window.pageXOffset)) / rect.width),
    top: clamp((event.pageY - (rect.top + window.pageYOffset)) / rect.height),
  }
}

interface InteractiveProps {
  onMove: (interaction: Interaction) => void
  children: React.ReactNode
  onMouseUp?: () => void
  onMouseDown?: () => void
}

const InteractiveBase: FC<InteractiveProps> = ({ onMove, onMouseUp, onMouseDown, ...rest }) => {
  const container = useRef<HTMLDivElement>(null)

  const [handleMoveStart, toggleDocumentEvents] = useMemo(() => {
    const handleMoveStartInner = ({ nativeEvent }: React.MouseEvent): void => {
      const el = container.current

      if (!el) {
        return
      }

      // Prevent text selection
      nativeEvent.preventDefault()

      el.focus()
      onMove(getRelativePosition(el, nativeEvent))
      toggleDocumentEventsInner(true)

      if (onMouseDown) {
        onMouseDown()
      }
    }

    const handleMove = (event: MouseEvent): void => {
      event.preventDefault()

      // If user moves the pointer outside of the window or iframe bounds and release it there,
      // `mouseup`/`touchend` won't be fired. In order to stop the picker from following the cursor
      // after the user has moved the mouse/finger back to the document, we check `event.buttons`
      // and `event.touches`. It allows us to detect that the user is just moving his pointer
      // without pressing it down
      const isDown = event.buttons > 0

      if (isDown && container.current) {
        onMove(getRelativePosition(container.current, event))
      } else {
        toggleDocumentEventsInner(false)
      }
    }

    const handleMoveEnd = (): void => {
      toggleDocumentEventsInner(false)

      if (onMouseUp) {
        onMouseUp()
      }
    }

    function toggleDocumentEventsInner(state?: boolean): void {
      const toggleEvent = state ? window.addEventListener : window.removeEventListener
      toggleEvent('mousemove', handleMove)
      toggleEvent('mouseup', handleMoveEnd)
    }

    return [handleMoveStartInner, toggleDocumentEventsInner]
  }, [onMouseDown, onMouseUp, onMove])

  // Remove window event listeners before unmounting
  useEffect(() => toggleDocumentEvents, [toggleDocumentEvents])

  return (
    <Container
      {...rest}
      onMouseDown={handleMoveStart}
      ref={container}
      tabIndex={-1}
      role="slider"
      aria-valuenow={0}
    />
  )
}

const Container = styled.div({
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  borderRadius: 'inherit',
  outline: 'none',
})

const Interactive = React.memo(InteractiveBase)
export { Interaction, Interactive }
