const USER_LOCALE =
  navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language

function format<T>(
  price?: T,
  currency = 'USD',
  defaultVal = '$0.00',
  locale = USER_LOCALE,
): string {
  if (typeof price !== 'number') {
    return defaultVal
  }
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(price / 100)
}

export function currencyWithoutSymbol(price?: number, currencyCode?: string): string {
  if (!price) {
    return 'N/A'
  }
  const formattedPrice = format(price)
    .replace(/[^0-9.,]/g, '')
    .concat(' ', currencyCode || '')
    .trim()

  return formattedPrice
}
