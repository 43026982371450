import type { SyntheticEvent } from 'react'
import React, { forwardRef, useCallback } from 'react'
import styled from '@emotion/styled'
import { Icon, IconSize } from '../icon'
import { Close } from '../../tokens/icons'
import { COLOR } from '../../tokens/colors'

export type ChipProps = {
  isDisabled?: boolean
  isToggled?: boolean
  label?: string
  text?: string
  /**
   * Handles the click event on the chip itself
   */
  onClick?: (e: SyntheticEvent<HTMLDivElement>) => void
  /**
   * When a function is provided for onDismissClick, a dismiss button is displayed inside the chip to handle this event.
   */
  onDismissClick?: (e: SyntheticEvent<HTMLDivElement>) => void
  'data-cy'?: string
}

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
  (
    { isDisabled, isToggled = false, label, text, onClick, onDismissClick, 'data-cy': dataCy },
    ref,
  ) => {
    const handleChipClick = (e: SyntheticEvent<HTMLDivElement>): void => {
      onClick?.(e)
    }

    const handleDismiss = (e: SyntheticEvent<HTMLDivElement>): void => {
      onDismissClick?.(e)
    }

    const handleDismissMouseDown = (e: SyntheticEvent<HTMLDivElement>): void => {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
    }

    const hasOnClick = !!onClick

    const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
      if (['Enter', ' '].includes(e.key)) {
        e.currentTarget.click()
      }
    }, [])

    return (
      <ChipWrapper data-cy={dataCy} isDisabled={isDisabled} hasOnClick={hasOnClick} ref={ref}>
        <StyledChip
          data-cy={dataCy && `${dataCy}:chip-button`}
          isDisabled={isDisabled}
          hasCloseIcon={!!onDismissClick}
          {...(hasOnClick && {
            onClick: handleChipClick,
            tabIndex: 0,
            hasOnClick,
            onKeyDown: handleKeyDown,
          })}
          /* Classes are used here so this element can be styled based on psudo-classes on the parent ChipWrapper */
          className={isToggled ? 'chip toggled' : 'chip'}
        >
          <span>
            {label && (
              <Label data-cy={`${dataCy}:label`}>
                {label}
                {text && <span>: </span>}
              </Label>
            )}
            {text && (
              <span className="text" data-cy={`${dataCy}:text`}>
                {text}
              </span>
            )}
          </span>
        </StyledChip>
        {!!onDismissClick && (
          <CloseButton
            className="foo"
            tabIndex={0}
            onClick={handleDismiss}
            onMouseDown={handleDismissMouseDown}
            onKeyDown={handleKeyDown}
            data-cy={dataCy && `${dataCy}:close-button`}
          >
            <div className="icon">
              <Icon
                data-cy={`${dataCy}:close-icon`}
                icon={Close}
                size={IconSize.small}
                color={COLOR.NEUTRAL[800]}
              />
            </div>
          </CloseButton>
        )}
      </ChipWrapper>
    )
  },
)

const ChipWrapper = styled.div<{ isDisabled?: boolean; hasOnClick?: boolean }>(
  ({ isDisabled, hasOnClick }) => ({
    display: 'inline-block',
    position: 'relative',
    borderRadius: 16,
    ...(isDisabled && {
      pointerEvents: 'none',
    }),
    ...(hasOnClick &&
      !isDisabled && {
        '&:hover .chip': {
          backgroundColor: COLOR.NEUTRAL[200],
        },
      }),
    '.chip.toggled': {
      backgroundColor: COLOR.NEUTRAL[200],
    },
  }),
)

const StyledChip = styled.div<{
  hasCloseIcon?: boolean
  hasOnClick?: boolean
  isDisabled?: boolean
}>(({ hasCloseIcon, hasOnClick, isDisabled }) => ({
  alignItems: 'center',
  textAlign: 'left',
  backgroundColor: COLOR.NEUTRAL[100],
  borderColor: isDisabled ? COLOR.NEUTRAL.OPACITY[25] : COLOR.NEUTRAL[300],
  borderStyle: 'solid',
  borderRadius: 16,
  borderWidth: 1,
  color: isDisabled ? COLOR.NEUTRAL[500] : COLOR.NEUTRAL[800],
  display: 'flex',
  fontSize: 15,
  gap: 4,
  justifyContent: 'space-between',
  lineHeight: '20px',
  padding: hasCloseIcon ? '5px 30px 5px 12px' : '5px 12px',
  transitionProperty: 'filter, background-color',
  transitionDuration: '50ms',
  ...(hasOnClick &&
    !isDisabled && {
      cursor: 'pointer',
      '&:active': {
        filter: 'brightness(0.96)',
      },
    }),
  '&.toggled': {
    color: COLOR.NEUTRAL[1000],
    '& .text': {
      WebkitTextStroke: '0.04em',
    },
    ...(hasOnClick &&
      !isDisabled && {
        '&:hover': {
          filter: 'brightness(0.96)',
        },
        '&:active': {
          filter: 'none',
        },
      }),
  },
}))

const CloseButton = styled.div({
  position: 'absolute',
  right: 0,
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 24,
  height: 24,
  border: 'none',
  padding: 0,
  margin: 4,
  borderRadius: '50%',
  background: 'none',
  cursor: 'pointer',
  transition: '50ms',
  '& .icon': {
    display: 'flex',
    opacity: 0.5,
    transition: '50ms',
  },
  '&:hover .icon': {
    opacity: 0.85,
  },
  '&:active': {
    backgroundColor: COLOR.NEUTRAL.OPACITY[25],
  },
})

const Label = styled.span({
  fontWeight: 700,
})
