import type { CcsParams } from './types'

/** Enforce ordering of CCS parameters for caching in CloudFront */
export function orderCcsParams(params: CcsParams): CcsParams {
  const sanitized: CcsParams = Object.fromEntries(
    Object.entries(params).map(([key, value]) => [key, value?.toLowerCase()]),
  )

  const { coverageType, productCategory, serviceType, storeId, locale } = sanitized

  // WARNING: parameters must remain in this order for CCS caching optimization
  return { coverageType, productCategory, serviceType, storeId, locale }
}
