import type { ScriptItem } from '@helloextend/extend-api-rtk-query'
import { MessageType } from '@helloextend/extend-api-rtk-query'
import { v4 as uuid } from 'uuid'

export const generateMultipleChoiceMessageBlock = (
  {
    slot = undefined,
  }: {
    slot?: string
  } = { slot: undefined },
): ScriptItem => {
  const firstOptionValue = uuid()
  const secondOptionValue = uuid()

  // option object script indexes are set to an invalid number (-1) as a flag to render a '-' placeholder
  return {
    collect: {
      options: [
        {
          action: 'execute',
          execute: {
            scriptIndex: -1,
          },
          patterns: [
            {
              input: firstOptionValue,
            },
          ],
        },
        {
          action: 'execute',
          execute: {
            scriptIndex: -1,
          },
          patterns: [
            {
              input: secondOptionValue,
            },
          ],
        },
      ],
    },
    reply: {
      messages: [
        {
          type: MessageType.text,
          content: '',
        },
      ],
      prompt: {
        type: 'buttons',
        slot: slot || uuid(),
        options: [
          {
            title: '',
            value: firstOptionValue,
            outputText: '',
          },
          {
            title: '',
            value: secondOptionValue,
            outputText: '',
          },
        ],
      },
    },
  }
}
