import { StoreUserRole } from '@helloextend/extend-api-client'
import { LDFlag } from '../../constants/ld-flags'

export type RuleDefinition = {
  allowedRoles: StoreUserRole[]
  requiredFeatureFlags?: LDFlag[]
}

export enum Permission {
  ManageProfile = 'MANAGE_PROFILE',

  ManagePlans = 'MANAGE_PLANS',

  ManagePlansAttributes = 'MANAGE_PLANS_ATTRIBUTES',

  ManagePlanSets = 'MANAGE_PLAN_SETS',

  ManageServicers = 'MANAGE_SERVICERS',

  ManageContracts = 'MANAGE_CONTRACTS',

  ManageSettings = 'MANAGE_SETTINGS',

  ManageServiceOrders = 'MANAGE_SERVICE_ORDERS',

  ManageProducts = 'MANAGE_PRODUCTS',

  ManageCategories = 'MANAGE_CATEGORIES',

  ManageClaims = 'MANAGE_CLAIMS',

  ManageSkus = 'MANAGE_SKUS',

  ManageAdjudication = 'MANAGE_ADJUDICATION',

  ViewAdjudication = 'VIEW_ADJUDICATION',

  ManageStores = 'MANAGE_STORES',

  ManageOrganizations = 'MANAGE_ORGANIZATIONS',

  ManageAccounts = 'MANAGE_ACCOUNTS',

  ManageUsers = 'MANAGE_USERS',

  ManageWarrantyDecisioning = 'MANAGE_WARRANTY_DECISIONING',

  ManageTerms = 'MANAGE_TERMS',
}

// ------------------------------------------------------------------------------------------------
// New permission rules should be added as follows:
//  1. Define a permission in the Permission enum
//  2. Add a rule for the new permission in the rules below.
//    - allowedRoles: User roles that a user must be one of for the permission to be granted
//    - requiredFeatureFlags: Feature flags that must be enabled in LaunchDarkly for the permission
//      to be granted
// ------------------------------------------------------------------------------------------------
export const rules: Record<Permission, RuleDefinition> = {
  // ACCOUNT
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageProfile]: {
    allowedRoles: [
      StoreUserRole.superadmin,
      StoreUserRole.admin,
      StoreUserRole.user,
      StoreUserRole.merchantagent,
      StoreUserRole.agent,
      StoreUserRole.conversationsadmin,
      StoreUserRole.conversationseditor,
      StoreUserRole.conversationsviewer,
    ],
  },

  // PLANS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManagePlans]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin],
  },

  // PLANS ATTRIBUTES
  [Permission.ManagePlansAttributes]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin],
  },

  // PLAN SETS
  [Permission.ManagePlanSets]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin],
    requiredFeatureFlags: [LDFlag.PlanSetsAdmin],
  },

  // SERVICERS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageServicers]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin],
  },

  // CONTRACTS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageContracts]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin, StoreUserRole.agent],
  },

  // SETTINGS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageSettings]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin],
    requiredFeatureFlags: [LDFlag.SettingsManagement],
  },

  // SERVICE ORDERS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageServiceOrders]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin],
    requiredFeatureFlags: [LDFlag.ServiceOrderManagement],
  },

  // PRODUCTS
  // ----------------------------------------------------------------------------------------------

  // Plan Mapping Validation
  [Permission.ManageProducts]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin],
    requiredFeatureFlags: [LDFlag.ProductsManagement],
  },

  // Category Mapping
  [Permission.ManageCategories]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin],
    requiredFeatureFlags: [LDFlag.CategoriesManagement],
  },

  // CLAIMS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageClaims]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin],
    requiredFeatureFlags: [LDFlag.ClaimsManagement],
  },

  // SKUS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageSkus]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin],
    requiredFeatureFlags: [LDFlag.SkuManagement],
  },

  // ADJUDICATION
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageAdjudication]: {
    allowedRoles: [StoreUserRole.conversationsadmin, StoreUserRole.conversationseditor],
    requiredFeatureFlags: [LDFlag.AdjudicationManagement],
  },

  [Permission.ViewAdjudication]: {
    allowedRoles: [
      StoreUserRole.conversationsadmin,
      StoreUserRole.conversationseditor,
      StoreUserRole.conversationsviewer,
    ],
    requiredFeatureFlags: [LDFlag.AdjudicationManagement],
  },

  // STORES
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageStores]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin],
    requiredFeatureFlags: [LDFlag.StoresManagement],
  },

  // ORGANIZATIONS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageOrganizations]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin],
    requiredFeatureFlags: [LDFlag.OrganizationsManagement],
  },

  // ACCOUNTS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageAccounts]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin],
    requiredFeatureFlags: [LDFlag.AccountsManagement],
  },

  // USERS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageUsers]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin],
    requiredFeatureFlags: [LDFlag.ExtendUserManagement],
  },

  // WARRANTY DECISIONING
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageWarrantyDecisioning]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin],
  },

  // TERMS
  // ----------------------------------------------------------------------------------------------
  [Permission.ManageTerms]: {
    allowedRoles: [StoreUserRole.superadmin, StoreUserRole.admin],
    requiredFeatureFlags: [LDFlag.TermsManagement],
  },
}
