import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import type { DefaultMessage, Message } from '@helloextend/extend-api-rtk-query'
import { MessageType } from '@helloextend/extend-api-rtk-query'
import { COLOR } from '@helloextend/zen'
import { getIsReplacementMessage } from '../../utils'

interface AdjudicationMessageProps {
  message: Message
  isReplacementReply: boolean
  messageIndex: number
}

const AdjudicationMessage: FC<AdjudicationMessageProps> = ({
  message,
  isReplacementReply,
  messageIndex,
}) => {
  const isReplacementMessage = getIsReplacementMessage(message)
  const isImageMessage = isReplacementMessage
    ? false
    : (message as DefaultMessage).type === MessageType.image

  const messageText = useMemo(() => {
    const text = isReplacementMessage
      ? 'This Kaley text is dynamic based on the customer input.'
      : (message as DefaultMessage).content
    return text === '' ? '-' : text
  }, [isReplacementMessage, message])

  const imageUrl = useMemo(() => {
    return !isReplacementMessage ? (message as DefaultMessage).imageUrl || '' : ''
  }, [isReplacementMessage, message])

  return (
    <>
      {isImageMessage ? (
        <>
          {imageUrl && (
            <MessageContent
              data-cy={`adjudication-message-content-${messageIndex}`}
              isItalic={isReplacementReply || isReplacementMessage}
            >
              <Image backgroundImage={imageUrl} />
            </MessageContent>
          )}
        </>
      ) : (
        <MessageContent
          data-cy={`adjudication-message-content-${messageIndex}`}
          isItalic={isReplacementReply || isReplacementMessage}
        >
          {messageText}
        </MessageContent>
      )}
    </>
  )
}

const MessageContent = styled.div<{ isItalic: boolean }>(({ isItalic }) => ({
  padding: 8,
  borderRadius: 8,
  color: COLOR.NEUTRAL[800],
  background: COLOR.NEUTRAL.OPACITY[12],
  fontWeight: 700,
  fontSize: 14,
  lineHeight: '19px',
  fontStyle: isItalic ? 'italic' : 'normal',
}))

const Image = styled.div<{ backgroundImage: string }>(({ backgroundImage }) => ({
  borderRadius: 4,
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: 173,
  maxWidth: 311,
}))

export { AdjudicationMessage }
