import React from 'react'
import type { OktaUserStatus, UserGrant } from '@helloextend/extend-api-rtk-query'
import type { ColumnDefs, CellContext, FilterDef } from '@helloextend/zen'
import UserRole from '../../../../components/user-role/user-role'
import StatusBadgeDescriptions from '../../../../components/status-badge-descriptions/status-badge-descriptions'
import UserStatusBadge from '../../../../components/user-status-badge/user-status-badge'
import type { UserWithGrants } from '../../../../types/users'
import { getUserStatus } from '../../../../utils/user-status'

type TableUserManagementUser = {
  firstName: string
  lastName: string
  email: string
  uuid: string
  status: OktaUserStatus
  grants: UserGrant[]
}

const toTableUserManagementUser = (user: UserWithGrants): TableUserManagementUser => {
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    uuid: user.uuid,
    status: user.status,
    grants: user.grants,
  }
}

const toTableUserManagementUsers = (users: UserWithGrants[]): TableUserManagementUser[] => {
  return users.map((user) => toTableUserManagementUser(user))
}

const userManagementColumns: ColumnDefs<TableUserManagementUser> = [
  {
    id: 'firstName',
    label: 'First Name',
    search: 'explicit',
    renderCell: (cellData: CellContext<TableUserManagementUser, string>) => cellData.getValue(),
  },
  {
    id: 'lastName',
    label: 'Last Name',
    search: 'explicit',
    renderCell: (cellData: CellContext<TableUserManagementUser, string>) => cellData.getValue(),
  },
  {
    id: 'email',
    label: 'Email',
    search: 'explicit',
    renderCell: (cellData: CellContext<TableUserManagementUser, string>) => cellData.getValue(),
  },
  {
    id: 'grants',
    label: 'Roles',
    renderCell: (cellData: CellContext<TableUserManagementUser, UserGrant[]>) => (
      <UserRole grants={cellData.getValue()} roleFilter="internal" />
    ),
  },
  {
    id: 'status',
    label: 'status',
    information: <StatusBadgeDescriptions data-cy="status-descriptions" />,
    accessorFn: (row: TableUserManagementUser) => {
      const { status, grants } = row

      return getUserStatus(status, grants, 'internal')
    },
    renderCell: (cellData: CellContext<TableUserManagementUser, string>) => {
      return (
        <UserStatusBadge
          grants={cellData.row.original.grants}
          oktaUserStatus={cellData.row.original.status}
          roleFilter="internal"
        />
      )
    },
  },
]

const FILTER_DEFS: FilterDef[] = [
  {
    type: 'group',
    filterDefs: [
      {
        id: 'status',
        type: 'select',
        label: 'Status',
        isMultiSelect: true,
        options: [
          {
            display: 'Active',
            value: 'Active',
          },
          { display: 'Pending', value: 'Pending' },
          {
            display: 'Deactivated',
            value: 'Deactivated',
          },
        ],
      },
    ],
  },
]

export { toTableUserManagementUsers, userManagementColumns, FILTER_DEFS, TableUserManagementUser }
