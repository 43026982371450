import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useFormikContext, getIn } from 'formik'
import styled from '@emotion/styled'
import { InputType } from '@helloextend/zen'
import type { DropdownProps } from '../../../components/dropdown'
import { FormTextGroup } from '../../../components/form-text-group'
import type { DropdownItem, InputItem } from '../../../components/form-text-group/form-text-group'
import type { Values } from './schema'
import { getDropdownCurrencyCode } from './util'
import { useDecimalsOnBlur } from '../../../hooks/use-decimals-on-blur'

type InsuranceProgramExtendFeesFormProps = {
  obligorIndex: number
  handleDropdownChange: DropdownProps['onChange']
  isChecked: boolean
  isDisabled?: boolean
}

const InsuranceProgramExtendFeesForm: FC<InsuranceProgramExtendFeesFormProps> = ({
  obligorIndex,
  handleDropdownChange,
  isChecked,
  isDisabled = false,
}) => {
  const { handleChange, handleBlur, setFieldValue, values, errors, touched } =
    useFormikContext<Values>()

  const { handleOnBlurCustom } = useDecimalsOnBlur(setFieldValue)

  useEffect(() => {
    return () => {
      // reset extend fee form
      setFieldValue(`obligor[${obligorIndex}].extendFee`, null)
    }
  }, [isChecked, obligorIndex, setFieldValue])

  const getFormikError = useCallback(
    (fieldName: string): string => {
      const error = getIn(errors, `obligor[${obligorIndex}]extendFee.${fieldName}`)
      return error ?? ''
    },
    [errors, obligorIndex],
  )

  const getFormikTouched = useCallback(
    (fieldName: string) => getIn(touched, `obligor[${obligorIndex}]extendFee.${fieldName}`),
    [obligorIndex, touched],
  )

  const extendFeeValues = useMemo(
    () => values?.obligor?.[obligorIndex]?.extendFee,
    [obligorIndex, values?.obligor],
  )

  return (
    <FormTextGroupWrapper
      title=""
      handleChange={handleChange}
      handleBlur={handleBlur}
      numColumns={4}
      isDisabled={isDisabled}
      values={[
        {
          name: `obligor[${obligorIndex}]extendFee.thirdPartyProvider`,
          label: 'Third Party Provider',
          value: extendFeeValues?.thirdPartyProvider,
          touched: getFormikTouched('thirdPartyProvider'),
          error: getFormikError('thirdPartyProvider'),
          fieldType: 'dropdown',
          columnCount: 1,
          options: [
            {
              value: 'CNA',
              label: 'CNA',
            },
          ],
          handleDropdownChange,
        } as DropdownItem,
        {
          name: `obligor[${obligorIndex}]extendFee.type`,
          label: 'Fee Type',
          value: extendFeeValues?.type,
          error: getFormikError('type'),
          touched: getFormikTouched('type'),
          fieldType: 'dropdown',
          options: [
            { value: 'Admin', label: 'Admin' },
            { value: 'CLIP', label: 'CLIP' },
            { value: 'Obligor', label: 'Obligor' },
            { value: 'Ceding', label: 'Ceding' },
            { value: 'Other', label: 'Other' },
          ],
          columnCount: 1,
          handleDropdownChange,
        } as DropdownItem,
        {
          name: `obligor[${obligorIndex}]extendFee.amount`,
          label: 'Fee Amount',
          value: extendFeeValues?.amount,
          error: getFormikError('amount'),
          touched: getFormikTouched('amount'),
          type: InputType.number,
          columnCount: 0.75,
          handleOnBlurCustom,
        } as InputItem,
        {
          name: `obligor[${obligorIndex}]extendFee.feeAmountOfType`,
          label: 'Fee Amount Type',
          value: extendFeeValues?.feeAmountOfType,
          error: getFormikError('feeAmountOfType'),
          touched: getFormikTouched('feeAmountOfType'),
          fieldType: 'dropdown',
          options: [
            { value: 'percentage', label: '%' },
            { ...getDropdownCurrencyCode(values.permittedGeo) },
          ],
          columnCount: extendFeeValues?.feeAmountOfType === 'percentage' ? 0.5 : 1.25,
          handleDropdownChange,
        } as DropdownItem,
        ...(extendFeeValues?.feeAmountOfType === 'percentage'
          ? [
              {
                name: `obligor[${obligorIndex}]extendFee.basedOn`,
                label: 'Based On',
                value: extendFeeValues?.basedOn,
                error: getFormikError('basedOn'),
                touched: getFormikTouched('basedOn'),
                fieldType: 'dropdown',
                options: [
                  { value: 'Premium', label: 'Premium' },
                  { value: 'Reserve', label: 'Reserve' },
                ],
                columnCount: 0.75,
                handleDropdownChange,
              } as DropdownItem,
            ]
          : []),
      ]}
    />
  )
}

const FormTextGroupWrapper = styled(FormTextGroup)({
  padding: 0,
  border: 0,
})

export { InsuranceProgramExtendFeesForm }
