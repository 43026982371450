import type { FC } from 'react'
import React from 'react'
import type { AdvancedSelectChangeEvent } from '@helloextend/zen'
import { AdvancedSelect } from '@helloextend/zen'
import { AdjudicationCategory } from '@helloextend/extend-api-rtk-query'

type CategoryDropdownProps = {
  onChange: (e: AdvancedSelectChangeEvent) => void
  value?: string
}

const dropdownOptions = Object.entries(AdjudicationCategory).map(([key, value = '']) => ({
  value: key,
  display: value,
}))

const CategoryDropdown: FC<CategoryDropdownProps> = ({ onChange, value = '' }) => {
  return (
    <AdvancedSelect
      id="category"
      label="Adjudication Category*"
      placeholder={value === '' ? 'Select' : value}
      value={value}
      onChange={onChange}
      options={dropdownOptions}
      data-cy="configuration-category-selector"
      showSearch
      maxQuantityToDisplay={10}
      multiple={false}
    />
  )
}

export { CategoryDropdown }
