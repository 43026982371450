import type { FC } from 'react'
import React from 'react'
import { Button } from '@helloextend/zen'
import { Trash } from '@helloextend/zen/src/tokens/icons'

interface RemoveUserButtonProps {
  userId?: string
  onClick(): void
}

export const RemoveUserButton: FC<RemoveUserButtonProps> = ({ onClick, userId }) => {
  const dataCy = userId ? `remove-button-for-${userId}` : `remove-button`

  return (
    <Button
      data-cy={dataCy}
      icon={Trash}
      size="xsmall"
      emphasis="low"
      color="neutral"
      onClick={onClick}
    />
  )
}
