import styled from '@emotion/styled'
import { Breadcrumbs, COLOR, HeadingLarge, Spinner } from '@helloextend/zen'
import type { FC } from 'react'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetProductQuery, useGetStoreQuery } from '@helloextend/extend-api-rtk-query'
import { ProductsDataTable } from '../products-data-table'

const ProductVariants: FC = () => {
  const { storeId, referenceId } = useParams<{ storeId: string; referenceId: string }>()
  const { data, isLoading } = useGetStoreQuery({ storeId, version: 'latest' })
  const { data: productData, isLoading: isProductLoading } = useGetProductQuery({
    storeId,
    referenceId,
    version: 'latest',
  })

  if (isLoading || isProductLoading) {
    return (
      <SpinnerContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </SpinnerContainer>
    )
  }

  return (
    <>
      <Breadcrumbs
        crumbs={[
          {
            text: 'Stores',
            to: '/admin/stores',
          },
          {
            text: `${data?.name}`,
            to: `/admin/stores/${storeId}`,
          },
          {
            text: `Variants: ${referenceId}`,
          },
        ]}
        data-cy="product-variants-breadcrumbs"
      />
      <HeadingLargeWrapper>Variants: {productData?.title}</HeadingLargeWrapper>
      <ProductsDataTable storeId={storeId} parentReferenceId={referenceId} />
    </>
  )
}

const HeadingLargeWrapper = styled(HeadingLarge)({
  margin: '24px 0',
})

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export { ProductVariants }
