import type {
  ContractsGetResponse,
  ContractsV2GetResponse,
  ContractSearchKey,
} from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

interface ContractsStoreFetch {
  accessToken: string
  storeId: string
  contractId: string
}

interface ContractsByStoreIdSearch {
  accessToken: string
  searchKey: ContractSearchKey
  storeId: string
  value: string
}

// Contracts 1.0
export const fetch = (id: string, accessToken: string) =>
  ({ type: 'CONTRACTS_FETCH', payload: { id, accessToken } } as const)

export const fetchRequest = () => ({ type: 'CONTRACTS_FETCH_REQUEST', payload: undefined } as const)

// FIXME: *WHY* is there an access token in the contract success response payload??
export const fetchSuccess = (
  contract: ContractsGetResponse | ContractsV2GetResponse,
  accessToken?: string,
) =>
  ({
    type: 'CONTRACTS_FETCH_SUCCESS',
    payload: { contract, accessToken },
  } as const)

export const fetchFailure = createActionFailure('CONTRACTS_FETCH_FAILURE')

// Contracts 2.0
export const fetchV2 = (id: string, accessToken: string) =>
  ({ type: 'CONTRACTS_FETCH_V2', payload: { id, accessToken } } as const)

export const fetchRequestV2 = () =>
  ({ type: 'CONTRACTS_FETCH_REQUEST_V2', payload: undefined } as const)

export const fetchSuccessV2 = (contract: ContractsV2GetResponse) =>
  ({
    type: 'CONTRACTS_FETCH_SUCCESS_V2',
    payload: { contract },
  } as const)

export const fetchFailureV2 = createActionFailure('CONTRACTS_FETCH_FAILURE_V2')

// By store
export const fetchByStore = ({ accessToken, contractId, storeId }: ContractsStoreFetch) =>
  ({
    type: 'CONTRACTS_FETCH_BY_STORE',
    payload: { accessToken, storeId, contractId },
  } as const)

export const fetchByStoreRequest = () =>
  ({ type: 'CONTRACTS_FETCH_BY_STORE_REQUEST', payload: undefined } as const)

export const fetchByStoreSuccess = (contract: ContractsGetResponse, accessToken?: string) =>
  ({
    type: 'CONTRACTS_FETCH_BY_STORE_SUCCESS',
    payload: { contract, accessToken },
  } as const)
export const fetchByStoreFailure = createActionFailure('CONTRACTS_FETCH_BY_STORE')

export const searchByStore = ({
  accessToken,
  searchKey,
  storeId,
  value,
}: ContractsByStoreIdSearch) =>
  ({
    type: 'CONTRACTS_SEARCH_BY_STORE',
    payload: { accessToken, searchKey, storeId, value },
  } as const)

export const searchByStoreRequest = () => ({ type: 'CONTRACTS_SEARCH_BY_STORE_REQUEST' } as const)

export const searchByStoreSuccess = (contracts: ContractsGetResponse[]) =>
  ({
    type: 'CONTRACTS_SEARCH_BY_STORE_SUCCESS',
    payload: contracts,
  } as const)

export const searchByStoreFailure = createActionFailure('CONTRACTS_SEARCH_BY_STORE_FAILURE')

export const searchReset = () =>
  ({
    type: 'CONTRACT_SEARCH_RESET',
  } as const)
