import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { useListInsuranceProgramsQuery } from '@helloextend/extend-api-rtk-query'
import { DataReactTable, ZeroState } from '@helloextend/merchants-ui'
import { Button, COLOR, Spinner } from '@helloextend/zen'
import { useHistory } from 'react-router-dom'
import { insuranceProgramColumns } from './table-config'

const InsurancePrograms: FC = () => {
  const { push } = useHistory()
  const handleCreateProgramClick = useCallback(() => {
    push('/admin/insurance-programs/create')
  }, [push])

  const { data, isLoading } = useListInsuranceProgramsQuery(undefined)

  const doInsuranceProgramsExist = useMemo(() => Boolean(data?.items?.length), [data])

  const handleRowClick = useCallback(
    (_e, rowData) => {
      push(`/admin/insurance-programs/${rowData.id}`)
    },
    [push],
  )

  return (
    <>
      <Header>
        <Title data-cy="insurance-program-title">Insurance Programs</Title>
        <Button
          text="Create Program"
          emphasis="medium"
          onClick={handleCreateProgramClick}
          data-cy="create-insurance-program-button"
        />
      </Header>
      {isLoading && (
        <SpinnerLoading>
          <Spinner />
        </SpinnerLoading>
      )}
      {!doInsuranceProgramsExist && !isLoading && (
        <ZeroState
          data-cy="insurance-program-no-results"
          title="No insurance programs yet"
          text='Click "Create Program" to add new insurance program.'
        />
      )}
      {doInsuranceProgramsExist && (
        <DataReactTable
          isLoading={isLoading}
          type="insurance programs"
          data={data?.items ?? []}
          initialState={{ pageSize: 10, sortBy: [{ id: 'id', desc: true }] }}
          onRowClick={handleRowClick}
          columns={insuranceProgramColumns}
          data-cy="insurance-programs-table"
        />
      )}
    </>
  )
}

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 24,
})

const Title = styled.h1({
  color: COLOR.BLUE[1000],
  fontSize: 32,
  lineHeight: '44px',
  fontWeight: 800,
  margin: 0,
})

const SpinnerLoading = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

export { InsurancePrograms }
