import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import {
  MenuButtonItem,
  COLOR,
  Icon,
  ToastColor,
  ToastDuration,
  useToaster,
  Spinner,
} from '@helloextend/zen'
import type { UserGrant } from '@helloextend/extend-api-rtk-query'
import { useGetUserGrantsListQuery } from '@helloextend/extend-api-rtk-query'
import { Check } from '@helloextend/zen/src/tokens/icons'
import { useHistory } from 'react-router'
import type { RootState } from '../../reducers'
import * as selectors from '../../reducers/selectors'
import { getUserEmailFromToken } from '../../lib/jwt'
import { useOktaExchangeToken } from '../../hooks/use-okta-exchange-token'
import { getUserSelectedGrant } from '../../utils/local-storage'
import type { UserRole } from '../../utils/user-role-mapper'
import { isLegacyInternalRole, mapUserType } from '../../utils/user-role-mapper'

const RoleSwitcher: FC = () => {
  const history = useHistory()
  const { toast } = useToaster()
  const selectedGrant = getUserSelectedGrant()
  const { exchangeToken } = useOktaExchangeToken()
  const accessToken = useSelector((state: RootState) => selectors.getAccessToken(state)) || ''
  const { data, isLoading, isError } = useGetUserGrantsListQuery(
    getUserEmailFromToken(accessToken) ?? '',
  )

  useEffect(() => {
    if (isError) {
      toast({
        message: 'Something went wrong when getting a list of roles',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [isError, toast])

  const handleOnClick = async (grant: UserGrant): Promise<void> => {
    try {
      await exchangeToken({ accessToken, grant })
    } catch (error) {
      toast({
        message: 'Something went wrong when selecting a role',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      history.push('/')
    }
  }

  const validRoles: UserGrant[] | undefined = data?.grants.filter((grant) =>
    isLegacyInternalRole(grant.role),
  )

  return (
    <>
      <MenuButtonItem data-cy="role-switcher-menu" disabled>
        <MenuHeaderText>SWITCH ROLES</MenuHeaderText>
      </MenuButtonItem>
      {isLoading ? (
        <Spinner />
      ) : (
        <AvailableRolesWrapper>
          {validRoles?.map((grant) => {
            const selectedRole =
              selectedGrant && selectedGrant.role === grant.role && selectedGrant.ern === grant.ern
            return (
              <MenuButtonItem
                rightAlignAdornment
                data-cy="role-switcher-menu-item"
                endAdornment={
                  selectedRole ? <Icon icon={Check} color={COLOR.BLUE[800]} /> : undefined
                }
                onClick={() => handleOnClick(grant)}
                key={`${grant.role}-${grant.ern}`}
              >
                {mapUserType(grant.role as UserRole)}
              </MenuButtonItem>
            )
          })}
        </AvailableRolesWrapper>
      )}
    </>
  )
}

const MenuHeaderText = styled.div({
  fontWeight: 700,
  fontSize: 12,
  color: COLOR.NEUTRAL[700],
  letterSpacing: 1,
  background: COLOR.WHITE,
})

const AvailableRolesWrapper = styled.div({
  overflow: 'auto',
  maxHeight: 440,
})

export { RoleSwitcher }
