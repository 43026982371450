import styled from '@emotion/styled'
import { Modal, Input, useToaster, ToastColor, ToastDuration } from '@helloextend/zen'
import type { ReactElement } from 'react'
import React, { useCallback, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useUpdateStoreMutation } from '@helloextend/extend-api-rtk-query'
import type { CustomTableauReport } from '@helloextend/extend-api-client'
import { useDispatch } from 'react-redux'
import { useAtomValue, useSetAtom } from 'jotai/react'
import { stores as storesActions } from '../../../../../../../../actions'
import { selectedTableauReportAtom, openTableauReportModalAtom } from './atoms/tableauReportAtom'

const schema = Yup.object()
  .shape({
    customReportName: Yup.string().required('Please enter a name for your custom report'),
    customReportUrl: Yup.string().required('Please enter a valid URL'),
  })
  .defined()

export interface AddTableauReportsModalProps {
  storeId: string
  customReports: CustomTableauReport[]
}

export const AddTableauReportsModal = ({
  storeId,
  customReports = [],
}: AddTableauReportsModalProps): ReactElement => {
  const dispatch = useDispatch()
  const [updateStore, { isError, isLoading: isSaveProcessing }] = useUpdateStoreMutation()
  const { toast } = useToaster()

  const setOpenTableauReportModal = useSetAtom(openTableauReportModalAtom)
  const selectedCustomReport = useAtomValue(selectedTableauReportAtom)
  const [hasUpdatedReport, setHasUpdatedReport] = useState(false)

  const { values, errors, touched, handleChange, handleBlur, isValid } = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      customReportName: '',
      customReportUrl: '',
    },
    onSubmit: async () => {},
  })

  const getReportsToBeUpdated = useCallback(() => {
    return customReports.concat({ title: values.customReportName, url: values.customReportUrl })
  }, [customReports, values.customReportName, values.customReportUrl])

  const filteredReports = useMemo(
    () => customReports.filter((report) => report.title !== selectedCustomReport?.title),
    [customReports, selectedCustomReport],
  )
  const isUnique = !filteredReports.some((report) => report.title === values.customReportName)
  const shouldShowError = (Boolean(errors.customReportName) || !isUnique) && !hasUpdatedReport
  const uniquenessErrorMessage = isUnique ? '' : 'Report name already exists'

  const errorFeedback = errors.customReportName || uniquenessErrorMessage

  const updateCustomReports = async (reportsToBeUpdated: CustomTableauReport[]): Promise<void> => {
    const res = await updateStore({
      storeId,
      data: {
        customReports: reportsToBeUpdated,
      },
      version: 'latest',
    })

    if (res && 'data' in res && !isError) {
      toast({
        message: 'Report added!',
        toastColor: ToastColor.green,
        toastDuration: ToastDuration.short,
      })
      setHasUpdatedReport(true)
      dispatch(storesActions.updateSuccess(res.data))
      setOpenTableauReportModal(null)
    } else {
      toast({
        message: `Something went wrong`,
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }

  const handleSubmit = (): void => {
    if (isValid && isUnique) {
      updateCustomReports(getReportsToBeUpdated())
    }
  }

  const handleClickCancel = (): void => {
    setOpenTableauReportModal(null)
  }

  return (
    <Modal
      heading="New Custom Report"
      size="sm"
      onDismissRequest={handleClickCancel}
      primaryButtonProps={{
        onClick: handleSubmit,
        text: 'Save',
        'data-cy': 'edit-tableau-report-modal-save-button',
        isProcessing: isSaveProcessing,
      }}
      secondaryButtonProps={{
        onClick: handleClickCancel,
        text: 'Cancel',
        'data-cy': 'edit-tableau-report-modal-cancel-button',
        isDisabled: isSaveProcessing,
      }}
    >
      <form>
        <Input
          id="customReportName"
          label="Custom Report Name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.customReportName}
          isError={shouldShowError}
          errorFeedback={errorFeedback}
          isDisabled={isSaveProcessing}
        />
        <Spacer />
        <Input
          id="customReportUrl"
          label="Custom Report URL"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.customReportUrl}
          isError={touched.customReportUrl && Boolean(errors.customReportUrl)}
          errorFeedback={errors.customReportUrl}
          isDisabled={isSaveProcessing}
        />
      </form>
    </Modal>
  )
}

const Spacer = styled.div({ marginBottom: 16 })
