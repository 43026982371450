import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import useResizeObserver from 'use-resize-observer'
import type { BreakpointValues } from '../../hooks'
import { useBreakpoints, useThemeSafe } from '../../hooks'

interface GridProps {
  children: React.ReactNode
  columns?: BreakpointValues<number> | number
  spacing?: BreakpointValues<number> | number
  'data-cy'?: string
}

const Grid: FC<GridProps> = ({ children, columns = 1, spacing = 0, 'data-cy': dataCy }) => {
  const { unit } = useThemeSafe()

  const [responsiveColumns, setResponsiveColumns] = useState(0)
  const [responsiveSpacing, setResponsiveSpacing] = useState(0)

  const { ref, width = 0 } = useResizeObserver<HTMLDivElement>()

  useBreakpoints(width, columns, setResponsiveColumns)
  useBreakpoints(width, spacing, setResponsiveSpacing)

  return (
    <StyledGrid
      ref={ref}
      data-cy={dataCy}
      columns={responsiveColumns}
      spacing={responsiveSpacing}
      unit={unit}
    >
      {children}
    </StyledGrid>
  )
}

const StyledGrid = styled.div<{
  columns: number
  spacing: number
  unit: number
}>(({ columns, spacing, unit }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
  gap: spacing * unit,
}))

export { Grid, GridProps }
