import type { VFC } from 'react'
import React, { useCallback, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Else, If, Then } from 'react-if'
import { Input, InputWithSelect } from '../../fields'
import { Search } from '../../../tokens/icons'
import { useDataTableContext } from '../data-table-context'

interface SearchInputProps {
  maxWidth?: number
}

export const SearchInput: VFC<SearchInputProps> = ({ maxWidth }) => {
  const { 'data-cy': dataCy, table } = useDataTableContext()
  const [errorFeedback, setErrorFeedback] = useState('')

  const inputRef = useRef<HTMLInputElement>(null)

  const columnDefs = table.getAllColumns().map((c) => c.columnDef)
  const canSearchAll =
    columnDefs.filter((c) => c.meta?.search === 'both' || c.meta?.search === 'global').length > 0

  const searchOptions = columnDefs.filter(
    (c) => c.meta?.search === 'both' || c.meta?.search === 'explicit',
  )

  const [searchInputValue, setSearchInputValue] = useState('')
  const [searchSelectValue, setSearchSelectValue] = useState(
    canSearchAll ? 'all' : searchOptions[0]?.id || '',
  )

  const handleSearchChange = useCallback(
    (e) => {
      if (errorFeedback) {
        const validator = table.getColumn(searchSelectValue).columnDef.meta?.validateSearch
        if (validator) {
          const error = validator(e.target.value)
          if (!error) {
            setErrorFeedback('')
          }
        }
      }
      setSearchInputValue(e.target.value)
    },
    [errorFeedback, searchSelectValue, table],
  )

  const handleSearchSelectChange = useCallback((e) => {
    setErrorFeedback('')
    setSearchSelectValue(e.target.value)
  }, [])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      const validator = table.getColumn(searchSelectValue).columnDef.meta?.validateSearch
      if (validator) {
        const error = validator(searchInputValue)
        if (error) {
          setErrorFeedback(error)
          return
        }
      }
      setErrorFeedback('')
      table.resetGlobalFilter()
      searchOptions.forEach((option) => {
        table.getColumn(option.id || '').setFilterValue('')
      })
      if (searchSelectValue === 'all') {
        table.setGlobalFilter(searchInputValue)
      } else {
        table.getColumn(searchSelectValue).setFilterValue(searchInputValue)
      }
      table.setPagination({ pageIndex: 0, pageSize: table.getState().pagination.pageSize })
    },
    [searchInputValue, searchOptions, searchSelectValue, table],
  )

  const handleClearSearchInput = useCallback(() => {
    setSearchInputValue('')
    inputRef.current?.focus()
  }, [])

  return (
    <StyledSearchInput
      onSubmit={handleSubmit}
      data-cy={dataCy && `${dataCy}:search`}
      maxWidth={maxWidth}
    >
      <If condition={searchOptions.length > 1}>
        <Then>
          <InputWithSelect
            data-cy={dataCy && `${dataCy}:search-input-with-select`}
            id="Search"
            value={searchInputValue}
            selectValue={searchSelectValue}
            onChange={handleSearchChange}
            onSelectChange={handleSearchSelectChange}
            {...(searchInputValue !== '' && { onClearRequest: handleClearSearchInput })}
            ref={inputRef}
            autoFocus
            isError={!!errorFeedback}
            errorFeedback={errorFeedback}
            actionButtonProps={{
              type: 'submit',
              icon: Search,
              emphasis: 'low',
              color: 'neutral',
            }}
          >
            {canSearchAll && <option value="all">All</option>}
            {searchOptions.map((option) => {
              return (
                <option key={option.id} value={option.id}>
                  {option.header}
                </option>
              )
            })}
          </InputWithSelect>
        </Then>
        <Else>
          <Input
            data-cy={dataCy && `${dataCy}:search-input`}
            id="Search"
            value={searchInputValue}
            onChange={handleSearchChange}
            {...(searchInputValue !== '' && { onClearRequest: handleClearSearchInput })}
            autoFocus
            ref={inputRef}
            isError={!!errorFeedback}
            errorFeedback={errorFeedback}
            placeholder={searchOptions.length ? `Search by ${searchOptions[0]?.header}` : 'Search'}
            actionButtonProps={{
              type: 'submit',
              icon: Search,
              emphasis: 'low',
              color: 'neutral',
            }}
          />
        </Else>
      </If>
    </StyledSearchInput>
  )
}

const StyledSearchInput = styled.form<{
  maxWidth?: number
}>(({ maxWidth }) => ({
  width: '100%',
  maxWidth,
}))
