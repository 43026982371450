import React from 'react'
import type { Column } from '@helloextend/merchants-ui'
import { CellText } from '@helloextend/merchants-ui'
import type { BadgeColor } from '@helloextend/zen'
import { Badge } from '@helloextend/zen'
import { startCase } from 'lodash'
import type { TableServicersSearch } from '../../../types/servicers'

const servicerColumns: Array<Column<TableServicersSearch>> = [
  {
    Header: 'Servicer Name',
    accessor: 'servicerName',
    columnWidth: 70,
    Cell: (data) => <CellText title={`${data.servicerName}`}>{data.servicerName}</CellText>,
    defaultSort: 'desc',
    isSelectable: true,
  },

  {
    Header: 'Status',
    accessor: 'status',
    columnWidth: 40,
    Cell: (data: TableServicersSearch): JSX.Element => {
      if (!data.status) return <CellText title="blank" />
      let badgeColor: BadgeColor
      switch (data.status) {
        case 'active':
          badgeColor = 'green'
          break
        case 'inactive':
          badgeColor = 'yellow'
          break
        default:
          badgeColor = 'red'
      }
      return <Badge color={badgeColor} emphasis="medium" text={startCase(data.status)} />
    },
  },
  {
    Header: 'Email',
    accessor: 'email',
    columnWidth: 60,
    Cell: (data) => <CellText title={`${data.email}`}>{data.email}</CellText>,
  },
  {
    Header: 'Phone',
    accessor: 'phone',
    columnWidth: 40,
    Cell: (data) => <CellText title={data.phone}>{data.phone}</CellText>,
  },
]

export { servicerColumns }
