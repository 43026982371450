import type { Product, ProductsSearchItem } from '@helloextend/extend-api-client'
import type { ProductBulkUpdatableFields } from '@helloextend/extend-api-rtk-query/src/stores/types'
import type { FormikValues } from 'formik'

/**
 *  ensure all products have the same status
 * @returns boolean for enabled, null for indeterminate
 */
const getProductsCheckboxValue = (
  products: ProductsSearchItem[] = [],
  productProperty: 'enabled' | 'shippable' | 'shipEnabled',
): boolean | null => {
  if (products.length === 0) {
    return false
  }
  const isEveryProductEnabled = products.every((product) => product[productProperty] === true)
  if (isEveryProductEnabled) {
    return true
  }
  const isEveryProductDisabled = products.every((product) => product[productProperty] === false)
  if (isEveryProductDisabled) {
    return false
  }
  return null
}

/**
 *  ensure all products have the same "warrantyStatus" status
 * @returns 'warrantable' | 'pending' | 'non-warrantable' | ''
 */
const getWarrantyStatusValue = (products: ProductsSearchItem[] = []): string => {
  if (products.length === 0) {
    return ''
  }
  const isEveryProductWarrantable = products.every(
    (product) => product?.warrantyStatus === 'warrantable',
  )
  if (isEveryProductWarrantable) {
    return 'warrantable'
  }
  const isEveryProductPending = products.every((product) => product?.warrantyStatus === 'pending')

  if (isEveryProductPending) {
    return 'pending'
  }

  const isEveryProductNonWarrantable = products.every(
    (product) => product?.warrantyStatus === 'non-warrantable',
  )
  if (isEveryProductNonWarrantable) {
    return 'non-warrantable'
  }

  return ''
}

const mapProductUpdatesToBatchUpdate = (
  values: FormikValues,
  products: ProductsSearchItem[],
): Array<Partial<Product>> => {
  return products?.map((product) => {
    const plans = values?.planIds?.length ? values.planIds : []
    return {
      title: product.name,
      warrantyStatus: values.warrantyStatus,
      enabled: values?.displayOffer || product.enabled,
      mfrWarranty: {
        parts: Number(values.mfrWarrantyParts) || product?.mfrWarrantyLabor || null,
        labor: Number(values.mfrWarrantyLabor) || product?.mfrWarrantyParts || null,
      },
      referenceId: product.referenceId,
      shippable: values.productShippable || product.shippable,
      shipEnabled: values.shippingProtectionEnabled || product.shipEnabled,
      ...(plans?.length && { plans }),
    }
  })
}

function isEmpty(value: undefined | null | string): boolean {
  return value === undefined || value === null || value === ''
}

function mapValuesToJobBulkUpdate(values: FormikValues): ProductBulkUpdatableFields {
  const {
    warrantyStatus,
    displayOffer,
    productShippable,
    shippingProtectionEnabled,
    mfrWarrantyLabor,
    mfrWarrantyParts,
    planIds,
  } = values

  return {
    ...(warrantyStatus && { warrantyStatus }),
    ...(!isEmpty(displayOffer) && { enabled: displayOffer }),
    ...((mfrWarrantyParts || mfrWarrantyLabor) && {
      mfrWarranty: {
        parts: Number(mfrWarrantyParts) || null,
        labor: Number(mfrWarrantyLabor) || null,
      },
    }),
    ...(!isEmpty(productShippable) && { shippable: productShippable }),
    ...(!isEmpty(shippingProtectionEnabled) && { shipEnabled: shippingProtectionEnabled }),
    ...(planIds?.length && { plans: planIds }),
  }
}

export {
  getProductsCheckboxValue,
  getWarrantyStatusValue,
  mapProductUpdatesToBatchUpdate,
  mapValuesToJobBulkUpdate,
}
