import type { FC, ReactNode } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Button, COLOR } from '@helloextend/zen'
import { ExpandLess, ExpandMore } from '@helloextend/zen/src/tokens/icons'

interface CollapsibleProps {
  headerContent: ReactNode
  startExpanded?: boolean
  controlledValue?: boolean
  onControlledToggle?: () => void
  'data-cy'?: string
}

/**
 * @deprecated Use Zen Accordion component instead: `import { Accordion } from '@helloextend/zen'`
 */
const Collapsible: FC<CollapsibleProps> = ({
  headerContent,
  children,
  startExpanded = false,
  controlledValue,
  onControlledToggle,
  'data-cy': dataCy,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!startExpanded)
  const shouldBeCollapsed = controlledValue ?? isCollapsed
  const icon = shouldBeCollapsed ? ExpandMore : ExpandLess

  const toggleIsCollapsed = (): void => {
    if (controlledValue === undefined) {
      setIsCollapsed(!shouldBeCollapsed)
    } else {
      onControlledToggle?.()
    }
  }

  return (
    <Wrapper>
      <HeaderWrapper isCollapsed={shouldBeCollapsed}>
        {headerContent}
        <div>
          <Button
            emphasis="low"
            color="neutral"
            icon={icon}
            onClick={toggleIsCollapsed}
            data-cy={dataCy && `${dataCy}:collapsed-toggle`}
          />
        </div>
      </HeaderWrapper>
      {!shouldBeCollapsed && (
        <ChildWrapper data-cy={dataCy && `${dataCy}:collapsed-wrapper`}>{children}</ChildWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div({
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  marginBottom: 32,
})

const ChildWrapper = styled.div({
  padding: '0 40px 40px 40px',
})

const HeaderWrapper = styled.div<{ isCollapsed: boolean }>(({ isCollapsed }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: 'auto',
  alignItems: 'center',
  margin: isCollapsed ? '40px 28px 40px 40px' : '40px 28px 24px 40px',
}))

export { Collapsible, CollapsibleProps }
