import { AdvancedSelect } from '@helloextend/zen'
import type { FormikHandlers } from 'formik'
import type { FC } from 'react'
import React from 'react'
import { listToAdvancedSelectOptions } from '../../../../../../../../utils/form-utils'
import { locationFilteringOptions } from './schema'

type LocationFilteringSelectProps = {
  value?: string
  isError?: boolean
  errorFeedback?: string
  onChange: FormikHandlers['handleChange']
  onBlur?: (e: Event) => void
}

const LocationFilteringSelect: FC<LocationFilteringSelectProps> = ({
  value = '',
  isError,
  errorFeedback,
  onChange,
  onBlur,
}) => {
  return (
    <AdvancedSelect
      label="Location Filtering"
      isNotClearable
      id="locationFilteringEnabled"
      onChange={onChange}
      value={value}
      isError={isError}
      onBlur={onBlur}
      errorFeedback={errorFeedback}
      placeholder="Select"
      options={listToAdvancedSelectOptions(locationFilteringOptions)}
      multiple={false}
      helperText="Shipping Protection offers will be blocked in certain regions if this setting is set to Undefined or Enabled.  SP Offers will ignore region if this setting is set to Disabled.  Blocked regions/subdivisions are defined on the SP Plan."
      data-cy="location-filtering-dropdown"
    />
  )
}

export { LocationFilteringSelect }
