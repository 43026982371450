export function formatPhoneNumber(number: string | undefined): string {
  if (!number) return ''

  const strippedNum = number.replace(/\D/g, '')
  return strippedNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
}

export function formatInteger(number: number): string {
  return new Intl.NumberFormat('en').format(Math.round(number))
}

/**
 * converts decimal value to percentage value with precision
 * @param decimal
 * @param precision
 * @returns percentage value
 */
export const getPercentageFromDecimal = (decimal: number, precision = 2): number => {
  return parseFloat((decimal * 100).toFixed(precision))
}

/**
 * converts percentage number value to decimal
 * @param percentageValue percentage value to be converted to decimal
 * @returns decimal value
 */
export const getDecimalFromPercentage = (percentageValue: number, precision = 4): number => {
  return parseFloat((percentageValue / 100).toFixed(precision))
}
