import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { get, post, put } from '@helloextend/extend-api-fetch'
import type {
  AuthChangePasswordRequestBody,
  AuthForgotPasswordResponse,
  AuthGetMeResponse,
  AuthLoginResponse,
  AuthRegisterRequestBody,
  User,
} from '../models'

export async function changePassword(
  body: AuthChangePasswordRequestBody,
  accessToken: string,
): Promise<ApiResponse<void>> {
  return put('/auth/users', { body, accessToken })
}

export async function requestToken(
  email: string,
  password: string,
  accessToken: string,
): Promise<ApiResponse<{ access_token: string }>> {
  return post('/auth/oauth/token', {
    accessToken,
    body: {
      email,
      password,
      grant_type: 'password',
      scope: 'offline all',
    },
  })
}

export async function requestConsumerToken(
  clientCode: string,
  codeVerifier: string,
): Promise<ApiResponse<{ access_token: string }>> {
  return post<{ access_token: string }>('/auth/oauth/token', {
    body: {
      code_verifier: codeVerifier,
      code: clientCode,
      grant_type: 'authorization_code',
    },
  })
}

export async function forgotPassword(
  email: string,
): Promise<ApiResponse<AuthForgotPasswordResponse>> {
  return post('/auth/password-reset', { body: { email } })
}

export async function setPassword(email: string): Promise<ApiResponse<AuthForgotPasswordResponse>> {
  return post('/auth/password-reset', { body: { email, usage: 'set' } })
}

export async function resetPassword(token: string, password: string): Promise<ApiResponse<void>> {
  return post('/auth/password-reset', { body: { token, password } })
}

export async function loginUser(
  email: string,
  password: string,
): Promise<ApiResponse<AuthLoginResponse>> {
  return post(`/auth/login`, { body: { email, password } })
}

export async function registerUser({
  email,
  password,
  firstName,
  lastName,
}: AuthRegisterRequestBody): Promise<ApiResponse<User>> {
  return post('/auth/register', { body: { email, password, firstName, lastName } })
}

export async function getMe(accessToken: string): Promise<ApiResponse<AuthGetMeResponse>> {
  return get('/auth/me', { accessToken })
}
