import type { VFC } from 'react'
import React, { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { When } from 'react-if'
import { Button } from '../../button'
import { Popover, PopoverAlignment, usePopover } from '../../popover'
import { ArrowDropDown } from '../../../tokens/icons'
import { useDataTableContext } from '../data-table-context'
import { SearchInput } from './search-input'
import type { MenuButtonItemProps, MenuLinkItemProps } from '../../menu-item'
import { MenuButtonItem, MenuLinkItem } from '../../menu-item'
import { FilterMenu } from './filter-menu'
import type { DataTableAction, DataTableMenuItem } from '../data-table-types'

export const Toolbar: VFC = () => {
  const {
    'data-cy': dataCy,
    filterDefs,
    table,
    getTableActions,
    getTableMenuItems,
    heading,
  } = useDataTableContext()

  const isFilterable = !!filterDefs
  const isSearchable = table.getAllColumns().filter((c) => c.columnDef.meta?.search).length > 0

  const { triggerRef, popoverRef, isPresent, toggle, triggerBoundingBox } =
    usePopover<HTMLButtonElement>()

  const handleMenuToggle = useCallback(
    (e) => {
      e.stopPropagation()
      toggle()
    },
    [toggle],
  )

  const selectedRows = table.getSelectedRowModel().rows

  const tableActions = useMemo<DataTableAction[]>(() => {
    return getTableActions ? getTableActions(selectedRows.map((row) => row.original)) : []
  }, [getTableActions, selectedRows])

  const tableMenuItems = useMemo<DataTableMenuItem[]>(() => {
    return getTableMenuItems ? getTableMenuItems(selectedRows.map((row) => row.original)) : []
  }, [getTableMenuItems, selectedRows])

  return (
    <When condition={isSearchable || isFilterable || !!tableActions || !!tableMenuItems}>
      <StyledToolbar data-cy={dataCy && `${dataCy}:toolbar`}>
        <When condition={!!heading || isSearchable || isFilterable}>
          <Start>
            {heading}
            <When condition={isSearchable || isFilterable}>
              <Filter>
                {isSearchable && <SearchInput maxWidth={440} />}
                {isFilterable && <FilterMenu />}
              </Filter>
            </When>
          </Start>
        </When>
        <When condition={tableActions.length > 0 || tableMenuItems.length > 0}>
          <End>
            <When condition={tableActions.length > 0}>
              {tableActions?.map((fa, i) => (
                <Button
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  {...fa}
                />
              ))}
            </When>
            <When condition={tableMenuItems.length > 0}>
              <Button
                text="Actions"
                icon={ArrowDropDown}
                iconPosition="right"
                emphasis="medium"
                onClick={handleMenuToggle}
                ref={triggerRef}
                isToggled={isPresent}
              />
              <Popover
                ref={popoverRef}
                alignment={PopoverAlignment.end}
                isPresent={isPresent}
                triggerBoundingBox={triggerBoundingBox}
              >
                <MenuItems data-cy={dataCy && `${dataCy}:table-menu-items`}>
                  {tableMenuItems?.map((fa, i) => {
                    if ('to' in fa) {
                      const typedFa = fa as MenuLinkItemProps
                      return (
                        <MenuLinkItem
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          {...typedFa}
                          onClick={(e) => {
                            e.stopPropagation()
                            if (typedFa.onClick) typedFa.onClick(e)
                            toggle()
                          }}
                        />
                      )
                    }
                    const typedFa = fa as MenuButtonItemProps
                    return (
                      <MenuButtonItem
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        {...typedFa}
                        onClick={(e) => {
                          e.stopPropagation()
                          if (typedFa.onClick) typedFa.onClick(e)
                          toggle()
                        }}
                      />
                    )
                  })}
                </MenuItems>
              </Popover>
            </When>
          </End>
        </When>
      </StyledToolbar>
    </When>
  )
}

const StyledToolbar = styled.div({
  flexShrink: 0,
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  gap: '12px 8px',
  marginBottom: 16,
  alignItems: 'end',
})

const Start = styled.div({
  display: 'flex',
  flex: 1,
  gap: 8,
  flexDirection: 'column',
  marginRight: 'auto',
})

const Filter = styled.div({
  display: 'flex',
  gap: 8,
})

const End = styled.div({
  display: 'flex',
  gap: 8,
  marginLeft: 'auto',
})

const MenuItems = styled.div({
  padding: '6px 8px',
})
