import type { FC } from 'react'
import React from 'react'
import type { StoreUser } from '@helloextend/extend-api-client'
import { Modal } from '@helloextend/zen'
import { useDeleteUserMutation } from '@helloextend/extend-api-rtk-query'
import { useHistory, useParams } from 'react-router-dom'
import { useStandardToast } from '@helloextend/merchants-ui'

type DeleteUserModalProps = {
  user: StoreUser
  toggleOff: () => void
}

const DeleteUserModal: FC<DeleteUserModalProps> = ({ user, toggleOff }) => {
  const { accountId } = useParams<{ accountId: string }>()
  const [deleteUser, { isLoading }] = useDeleteUserMutation()
  const history = useHistory()
  const { toastError, toastCompleted } = useStandardToast()

  const handleDeleteButtonClick = async (): Promise<void> => {
    if (user) {
      try {
        await deleteUser(user.id).unwrap()
        toastCompleted('User deleted successfully')
        history.push(`/admin/accounts/${accountId}/users`)
      } catch {
        toastError('Something went wrong. Please try again.')
      }
    }
  }

  return (
    <Modal
      data-cy="delete-user-modal"
      heading="Permanently delete this user?"
      onDismissRequest={toggleOff}
      primaryButtonProps={{
        onClick: handleDeleteButtonClick,
        text: 'Delete User',
        isProcessing: isLoading,
        color: 'red',
        'data-cy': 'delete-user-confirmation-button',
      }}
      secondaryButtonProps={{
        onClick: toggleOff,
        text: 'Cancel',
        isDisabled: isLoading,
        'data-cy': 'delete-user-cancel-button',
      }}
    >
      <div data-cy="delete-text">
        <p>
          This will remove the user&apos;s access to this account, including any stores they
          currently have access to. You will no longer have access to view or manage this user as
          well.
        </p>
        <p>Are you sure you want to delete this user?</p>
      </div>
    </Modal>
  )
}

export { DeleteUserModal, DeleteUserModalProps }
