/**
 * verifies if the string contains the following signatures:
 * 1.12345E10
 * 1.12345e10
 * 1.12345E+10
 * 1.12345E-10
 */
export const isScientificNotation = (str: string): boolean => {
  return /^(\d\.)(\d*)([Ee]([+-]*)\d+)$/.test(str)
}

export const isEncoded = (str: string): boolean => {
  // catch any errors if string is malformed
  try {
    return str !== decodeURIComponent(str)
  } catch (err) {
    return false
  }
}
