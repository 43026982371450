import * as Yup from 'yup'

const schema = Yup.object()
  .shape({
    merchantEwRevenueAgreement: Yup.string()
      .required('Revenue model is required')
      .default(null)
      .nullable(true),
    merchantEwRevenueSharePercentage: Yup.number()
      .default(undefined)
      .when('merchantEwRevenueAgreement', {
        is: (value) => value === 'wholesale',
        then: Yup.number().notRequired(),
        otherwise: Yup.number()
          .required('Merchant revenue share is required')
          .min(0, 'Merchant revenue share must be greater than 0')
          .max(100, 'Merchant revenue share must be equal or less than 100'),
      }),
    partialReimbursementPercentage: Yup.number()
      .min(0, 'Partial reimbursement percentage must be greater than 0')
      .max(100, 'Partial reimbursement percentage must be equal or less than 100')
      .when('partialReimbursementEnabled', {
        is: true,
        then: Yup.number().required('Partial reimbursement percentage is required'),
      })
      .nullable(true),
    extendCut: Yup.number()
      .min(0, 'Custom Extend cut must be greater than 0')
      .max(100, 'Custom Extend cut must be equal or less than 100')
      .when('isExtendCutOn', {
        is: true,
        then: Yup.number().required('Extend cut is required'),
      })
      .nullable(true),
    partialReimbursementEnabled: Yup.boolean(),
    isExtendCutOn: Yup.boolean(),
    locationFilteringEnabled: Yup.boolean(),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export { schema, Values }
