import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { format as formatDate } from '../../utils/date'
import type {
  CurrencyDataPropertyProps,
  CustomDataPropertyProps,
  DateDataPropertyProps,
  NumberDataPropertyProps,
  StringDataPropertyProps,
} from './data-property-types'
import { DataPropertyType } from './data-property-types'
import { currencyWithoutSymbol } from './data-property-utils'
import { DataPropertyContent } from './data-property-content'
import { Label } from '../label'

export const DataProperty = <TValue,>({
  'data-cy': dataCy,
  includeCopyAction,
  isLoading = false,
  label,
  openInNew,
  prefix,
  suffix,
  to,
  helperText,
  isError,
  errorMessage,
  ...typeProps
}:
  | StringDataPropertyProps
  | NumberDataPropertyProps
  | DateDataPropertyProps
  | CurrencyDataPropertyProps
  | CustomDataPropertyProps<TValue>): JSX.Element => {
  let strValue: string | undefined

  switch (typeProps.type) {
    case DataPropertyType.number:
      strValue =
        typeProps.value || typeProps.value === 0
          ? Intl.NumberFormat('en').format(typeProps.value)
          : undefined
      break
    case DataPropertyType.date:
      strValue = typeProps.value ? formatDate(typeProps.value, typeProps.datePattern) : undefined
      break
    case DataPropertyType.currency:
      strValue =
        typeProps.value || typeProps.value === 0
          ? currencyWithoutSymbol(typeProps.value, typeProps.currencyCode)
          : undefined
      break
    case DataPropertyType.custom:
      strValue = typeProps.formatter(typeProps.value) || ''
      break
    default:
      strValue = typeProps.value
  }

  const valueToDisplay = useMemo(() => {
    if (strValue && strValue.trim()) {
      const parts: string[] = []
      if (prefix) parts.push(prefix)
      parts.push(strValue.trim())
      if (suffix) parts.push(suffix)

      return parts.join(' ')
    }
    return null
  }, [strValue, prefix, suffix])

  return (
    <StyledDataProperty data-cy={dataCy}>
      <Label isMuted helperText={helperText} data-cy={dataCy}>
        {label}
      </Label>
      <ValueWrapper>
        <DataPropertyContent
          valueToDisplay={valueToDisplay}
          to={to}
          includeCopyAction={includeCopyAction}
          openInNew={openInNew}
          label={label}
          dataCy={dataCy}
          isError={isError}
          isLoading={isLoading}
          errorMessage={errorMessage}
        />
      </ValueWrapper>
    </StyledDataProperty>
  )
}

const StyledDataProperty = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
})

const ValueWrapper = styled.div({
  display: 'flex',
  gap: 2,
  marginBottom: 16,
  alignItems: 'center',
})
