import type { Servicer, ServicerLocation } from '@helloextend/extend-api-client'
import type { AdvancedSelectProps } from '@helloextend/zen'

const formatFulfillmentOption = (FF_SO_DIRECT_PAYMENT: boolean): AdvancedSelectProps['options'] => {
  const methods = [
    {
      display: 'Extend virtual card',
      value: 'virtual_card',
    },
    {
      display: 'Direct Payment',
      value: 'direct_payment',
    },
    {
      display: 'Manual via portal',
      value: 'manual',
    },
  ]
  return methods.filter(({ value }) => value !== 'direct_payment' || FF_SO_DIRECT_PAYMENT)
}

const formatLocation = (servicerLocation: ServicerLocation): string => {
  if (!servicerLocation) return 'Extend Warehouse'
  const { address, businessName } = servicerLocation
  return `${businessName} - ${address.city} ${address.postalCode}`
}

const getServicerLocationOptions = (
  locations: ServicerLocation[] | undefined,
): AdvancedSelectProps['options'] => {
  const options = []

  options.push({ display: 'Extend Warehouse', value: 'extend' })

  if (!locations?.length) return options

  locations.forEach((location) => {
    options.push({ display: formatLocation(location), value: location.id })
  })
  return options
}

const getServicerOptions = (
  servicers: Servicer[] | undefined,
  isLoading = false,
): AdvancedSelectProps['options'] => {
  if (!servicers && isLoading) {
    return [{ display: 'Loading...', value: '' }]
  }
  if (!servicers) {
    return []
  }
  return servicers.map((s) => ({ display: s.businessName, value: s.id }))
}

export { formatFulfillmentOption, getServicerLocationOptions, getServicerOptions }
