import { StackDirection } from '../stack'
import type { LayoutArgs, RadioGroupLayout } from './radio-group-types'

export const radioGroupLayouts: Record<RadioGroupLayout, LayoutArgs> = {
  horizontal: {
    direction: StackDirection.row,
  },
  vertical: {
    direction: StackDirection.column,
  },
}
