import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import type { Servicer } from '@helloextend/extend-api-client'
import { Select, SelectItem } from '../select'

export const paymentOptions: Array<{
  key: Servicer['settings']['paymentPreference']
  label: string
}> = [
  { key: 'credit_memo', label: 'Credit Memo' },
  { key: 'corporate_card', label: 'Corporate Card' },
  { key: 'invoice', label: 'Invoice' },
  {
    key: 'other',
    label: 'Other',
  },
]

interface ServicerPaymentSelectProps {
  onChange: (e: SyntheticEvent<Element>) => void
  value: string
  'data-cy'?: string
}

const ServicerPaymentSelect: FC<ServicerPaymentSelectProps> = ({
  onChange,
  value,
  'data-cy': dataCy,
}) => (
  <Select
    data-cy={dataCy ?? 'servicer-payment-select'}
    value={value}
    label="Reimbursement Type"
    onChange={onChange}
  >
    {paymentOptions.map(({ key, label }) => (
      <SelectItem data-cy={key} value={key ?? ''} key={key} label={label} />
    ))}
  </Select>
)

export { ServicerPaymentSelect }
