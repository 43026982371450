import * as Yup from 'yup'
import {
  cityRegex,
  phoneRegEx,
  states,
  toValidPhoneNumber,
  zipCodeRegEx,
} from '../../../../../../utils/form-utils'

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email address is required'),
  phone: Yup.string()
    .matches(phoneRegEx, 'Please enter a valid phone number')
    .required('Phone number is required'),
  shippingAddress: Yup.object()
    .shape({
      address1: Yup.string().required('Shipping address is required'),
      address2: Yup.string(),
      city: Yup.string()
        .matches(cityRegex, 'Please Enter a valid city')
        .required('City is required'),
      provinceCode: Yup.string().required('Select a state'),
      postalCode: Yup.string()
        .matches(zipCodeRegEx, 'Enter a valid zip code')
        .required('Zip Code is required'),
      countryCode: Yup.string().default('US'),
    })
    .required(),
})

export { schema, states, toValidPhoneNumber }
