import { getOr, merge } from 'lodash/fp'
import type { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'
import type { Product } from '@helloextend/extend-api-client'
import { client } from '@helloextend/extend-api-client'
import type {
  BatchUpdateProduct,
  BatchUpdateResponse,
} from '@helloextend/extend-api-client/src/api/products'
import type { ProductOverrides } from '@helloextend/extend-api-client/src/models/product'
import { actions } from '../actions'
import * as selectors from '../selectors'

type Action = ReturnType<typeof actions.updateBatch>

export default function* updateBatch(action: Action): SagaIterator {
  const { storeId, referenceIds, accessToken, data } = action.payload

  yield put(actions.updateBatchRequest())

  // kind of a hack because product update endpoint doesnt support partial updates to nested properties
  const payload: BatchUpdateProduct[] = []
  for (let i = 0; i < referenceIds.length; i += 1) {
    const referenceId = referenceIds[i]
    const adminProduct: Product = yield select(selectors.getById, storeId, referenceId)
    const apiProduct = buildProductUpdateItem(adminProduct, data)
    payload.push(apiProduct)
  }

  const response: BatchUpdateResponse = yield call(
    client.products.batchUpdate,
    storeId,
    payload,
    accessToken,
  )

  const numFailed = response.failed.length
  const numUpdated = response.updated.length

  if (numFailed) {
    yield put(actions.updateBatchFailure(`Failed to update ${numFailed} of ${numUpdated} products`))
  }

  if (numUpdated && numUpdated !== numFailed) {
    yield put(actions.updateBatchSuccess(storeId, referenceIds, data))
  }
}

function buildProductUpdateItem(originalProduct: Product, productUpdates: any): BatchUpdateProduct {
  // TODO: find a more elegant way to do this
  const overrides: ProductOverrides = merge(originalProduct.overrides, {
    mfrWarranty: {
      parts: productUpdates.mfrWarrantyParts,
      labor: productUpdates.mfrWarrantyLabor,
      url: productUpdates.mfrWarrantyUrl,
    },
  })

  return {
    referenceId: originalProduct.referenceId,
    overrides,
    enabled: getOr(originalProduct.enabled, 'enabled', productUpdates),
    approved: getOr(originalProduct.approved, 'approved', productUpdates),
    plans: getOr(originalProduct.plans, 'plans', productUpdates),
  }
}
