import type { ServiceOrderShipment } from '@helloextend/extend-api-client'
import type { Action } from '../actions'

export type ByServiceOrderReducerState = Record<string, ServiceOrderShipment[]>

export const initialState: ByServiceOrderReducerState = {}

export default function byServiceOrder(
  state = initialState,
  action: Action,
): ByServiceOrderReducerState {
  switch (action.type) {
    case 'SERVICE_ORDER_SHIPMENTS_FETCH_SUCCESS':
      return addItems(state, action.payload.serviceOrderShipments)
    default:
      return state
  }
}

function addItems(
  state: ByServiceOrderReducerState,
  items: ServiceOrderShipment[],
): ByServiceOrderReducerState {
  if (items.length) {
    return { ...state, [items[0].serviceOrderId]: items }
  }

  return state
}
