import type { FC } from 'react'
import React from 'react'
import { ErrorBanner } from '../../../../../../../../../../components/error-banner'
import { ExternalErrorCodes } from '../../../../../../../../../../utils/service-order-error-mapping'

interface ShippingLabelErrorBannerProps {
  errorCode: ExternalErrorCodes
}

const mapErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case ExternalErrorCodes.tracking_number_invalid_or_unavailable:
      return 'The tracking number or carrier contains errors or the label has not been generated yet. Please review and try again.'
    case ExternalErrorCodes.service_unavailable:
    case ExternalErrorCodes.ship_engine_503_http:
      return 'An error occurred while retrieving the label. Our team has been notified, please try again later.'
    default:
      return 'Something went wrong. Please try again later.'
  }
}

const ShippingLabelErrorBanner: FC<ShippingLabelErrorBannerProps> = ({ errorCode }) => {
  return <ErrorBanner header={mapErrorMessage(errorCode)} />
}

export { ShippingLabelErrorBanner }
