import type { FC } from 'react'
import React, { useEffect, createRef, useCallback } from 'react'
import { createPortal } from 'react-dom'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import { Icon, IconSize } from '@helloextend/zen'
import { Close } from '@helloextend/zen/src/tokens/icons'

interface BasicModalProps {
  isVisible: boolean
  isCloseButtonHidden?: boolean
  onClickClose: () => void
  width?: string
  padding?: string
  'data-cy'?: string
}

/**
 * @deprecated Use Zen Modal components instead: `import { ModalController, Modal } from '@helloextend/zen'`
 */
const BasicModal: FC<BasicModalProps> = ({
  children,
  isCloseButtonHidden,
  onClickClose,
  isVisible,
  width = '536px',
  padding = '48px 32px 32px',
  'data-cy': dataCy,
}) => {
  const modalRef = createRef<HTMLDivElement>()

  const getFocusableElements = useCallback((): HTMLElement[] => {
    return Array.prototype.slice
      .call(modalRef.current?.querySelectorAll('select, input, textarea, button, a'), 0)
      .filter((element) => window.getComputedStyle(element).display !== 'none')
  }, [modalRef])

  // Adds key listeners
  useEffect(() => {
    const handleClickKeydown = (e: KeyboardEvent): void => {
      const focusableElements = getFocusableElements()
      const lastFocusableElement = focusableElements[focusableElements.length - 1]
      const firstFocusableElement = focusableElements[0]

      if (!isCloseButtonHidden && e.key === 'Escape') {
        onClickClose()
      }
      if (e.key !== 'Tab') {
        return
      }

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus()
          e.preventDefault()
        }
      } else if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus()
        e.preventDefault()
      }
    }

    if (modalRef.current && isVisible) {
      document.addEventListener('keydown', handleClickKeydown)
    }

    return () => {
      document.removeEventListener('keydown', handleClickKeydown)
    }
  }, [modalRef, onClickClose, getFocusableElements, isVisible, isCloseButtonHidden])

  // Set focus on modal
  useEffect(() => {
    if (modalRef.current && isVisible) {
      const focusableElements = getFocusableElements()

      if (!isCloseButtonHidden) {
        const inputNodes = focusableElements.filter(
          (el) =>
            el.tagName.toLowerCase() === 'input' ||
            el.tagName.toLowerCase() === 'textarea' ||
            el.tagName.toLowerCase() === 'select',
        )

        const currentFocusedInputNode = inputNodes.find((x) => x === document.activeElement)

        if (currentFocusedInputNode) {
          currentFocusedInputNode.focus()
        } else if (inputNodes.length) {
          inputNodes[0].focus()
        } else {
          focusableElements[0].focus()
        }
      }
    }
  }, [modalRef, isVisible, getFocusableElements, isCloseButtonHidden])

  if (!isVisible) return null

  return createPortal(
    <Overlay>
      <Modal
        aria-modal
        role="dialog"
        tabIndex={-1}
        ref={modalRef}
        width={width}
        padding={padding}
        data-cy={dataCy && `${dataCy}:modal`}
      >
        {!isCloseButtonHidden && (
          <CloseButton
            data-cy="basic-modal-xIcon"
            aria-labelledby="close-modal"
            onClick={onClickClose}
          >
            <Icon size={IconSize.small} color={COLOR.VERY_DARK_BLUE_0} icon={Close} />
          </CloseButton>
        )}
        <Content>{children}</Content>
      </Modal>
    </Overlay>,
    document.body,
  )
}

const CloseButton = styled.button({
  background: 'none',
  border: 'none',
  position: 'absolute',
  right: 16,
  top: 16,
  cursor: 'pointer',
  padding: 0,
})

const Content = styled.div({
  display: 'block',
})

const Modal = styled.aside<{ width?: string; padding: string }>(({ width, padding }) => ({
  background: COLOR.WHITE,
  borderRadius: 4,
  padding,
  position: 'relative',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width,
}))

const Overlay = styled.div({
  background: 'rgba(0,0,0,0.5)',
  display: 'block',
  height: '100vh',
  position: 'absolute',
  width: '100vw',
  top: 0,
  left: 0,
  zIndex: 15,
  overflow: 'auto',
})

export { BasicModal, BasicModalProps, CloseButton }
