import type { CategoryMapping, StoreIdAndName } from '@helloextend/extend-api-client'
import type { AdvancedSelectOption, BadgeProps } from '@helloextend/zen'

// depending on the mapping status, return the appropriate badge and label
export const statusBadgeProps: Record<CategoryMapping['mappingStatus'], BadgeProps> = {
  Unmapped: {
    color: 'yellow',
    emphasis: 'medium',
    text: 'Unmapped',
  },
  'Non-Warrantable': {
    color: 'neutral',
    emphasis: 'medium',
    text: 'Non-Warrantable',
  },
  Warrantable: {
    color: 'green',
    emphasis: 'medium',
    text: 'Warrantable',
  },
}

// given a list of stores, transform the items into a list of AdvancedSelectOptions
export const getStoresSelectOptions = (stores: StoreIdAndName[]): AdvancedSelectOption[] =>
  stores.map(({ name, id }) => ({
    display: name || '',
    value: id,
  }))
