import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import type { CSSObject } from '@emotion/styled'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'

type JustifyContentProps = 'flex-start' | 'center' | 'flex-end'

type ButtonProps = {
  type?: 'button'
  isSelected?: boolean
  isBold?: boolean
  id: string
  color?: string
  onClick: (event: SyntheticEvent, id: string) => void
  justifyContent?: JustifyContentProps
  hoverBackground?: string
}

type Item = {
  id: string
  type?: 'li'
  color?: string
  isSelected?: boolean
  isBold?: boolean
  justifyContent?: JustifyContentProps
  hoverBackground?: string
  onClick?: (event: SyntheticEvent, id: string) => void
}

type ListItemProps = Item | ButtonProps

const ListItem: FC<ListItemProps> = ({
  children,
  id,
  color = COLOR.BLACK_MUTED,
  hoverBackground = COLOR.SLATE,
  type = 'li',
  justifyContent = 'flex-start',
  isBold = false,
  isSelected = false,
  onClick,
}) => (
  <StyledListItem justifyContent={justifyContent}>
    {type === 'button' ? (
      <ListItemButton
        data-cy="store-list-item"
        isBold={isBold}
        isSelected={isSelected}
        color={color}
        type="button"
        aria-label="button"
        hoverBackground={hoverBackground}
        onClick={(e: SyntheticEvent) => onClick && onClick(e, id)}
      >
        {children}
      </ListItemButton>
    ) : (
      <ListItemText color={color}>{children}</ListItemText>
    )}
  </StyledListItem>
)

const StyledListItem = styled.li<{ justifyContent?: JustifyContentProps }>(
  ({ justifyContent = 'flex-start' }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    margin: '8px 0',
    width: '100%',
    justifyContent,
  }),
)

export const ListItemText = styled.span<{
  fontSize?: number
  isBold?: boolean
  lineHeight?: string
  color?: string
}>(({ fontSize = 14, isBold = false, lineHeight = '22px', color = 'inherit' }) => ({
  fontSize,
  fontWeight: isBold ? 'bold' : 300,
  lineHeight,
  color,
  display: 'inline-flex',
  justifyContent: 'inherit',
}))

export const ListButtonBase: CSSObject = {
  alignItems: 'center',
  backgroundColor: 'transparent',
  gap: 16,
  border: 'none',
  borderRadius: 4,
  boxShadow: 'none',
  color: COLOR.BLACK_MUTED,
  cursor: 'pointer',
  display: 'flex',
  fontSize: 'inherit',
  fontFamily: 'inherit',
  fontWeight: 'inherit',
  justifyContent: 'inherit',
  margin: 0,
  padding: '8px 16px',
  textAlign: 'inherit',
  transition: 'background-color 50ms ease-in',
  width: '100%',
  '&:hover': {
    backgroundColor: COLOR.SLATE,
    color: COLOR.EXTEND_OXFORD,
    textDecoration: 'none',
  },
}

export const ListItemButton = styled.button<{
  color?: string
  isSelected?: boolean
  isBold?: boolean
  hoverBackground?: string
}>(
  ({
    isSelected = false,
    isBold = false,
    color = COLOR.BLACK_MUTED,
    hoverBackground = COLOR.SLATE,
  }) => ({
    ...ListButtonBase,
    color: isSelected ? COLOR.EXTEND_OXFORD : color,
    fontWeight: isSelected && isBold ? 700 : 'inherit',
    '&:hover': {
      backgroundColor: hoverBackground,
      color: COLOR.EXTEND_OXFORD,
    },
  }),
)

export { ListItem, ListItemProps }
