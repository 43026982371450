import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'

export type EmptyMessageProps = {
  header: string
  message: string
}

const EmptyMessage: FC<EmptyMessageProps> = ({ header, message }) => {
  return (
    <EmptyMessageWrapper>
      <h2 data-cy="no-search-results">{header}</h2>
      <Message data-cy="message-audit-log">{message}</Message>
    </EmptyMessageWrapper>
  )
}

const EmptyMessageWrapper = styled.div({
  alignItems: 'center',
  boxSizing: 'border-box',
  color: COLOR.VERY_DARK_BLUE_0,
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Nunito Sans, sans-serif',
  marginTop: 96,
  textAlign: 'center',
  width: '100%',
})

const Message = styled.p({
  fontSize: 20,
  lineHeight: '27px',
  maxWidth: 624,
})

export { EmptyMessage }
