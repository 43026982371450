import type { FC } from 'react'
import React from 'react'
import { CurrencyInput, Grid, Input, InputType } from '@helloextend/zen'
import styled from '@emotion/styled'
import type { PricingRow } from '../../../../../../../../types/plans'

type PricingDetailsRowProps = {
  pricingRow: PricingRow
  isLabelDisplayed?: boolean
}

const PricingDetailsRow: FC<PricingDetailsRowProps> = ({ pricingRow, isLabelDisplayed }) => {
  const { vendorSku, priceBandLow, priceBandHigh, cost, retailTarget, fixedPrice } = pricingRow

  return (
    <GridWrapper data-cy="pricing-details-row">
      <Grid columns={6} spacing={2}>
        <Input
          id="vendorSku"
          label={isLabelDisplayed ? 'Premium ID' : undefined}
          value={vendorSku}
          isDisabled
        />
        <CurrencyInput
          currency="USD"
          id="priceBandLow"
          label={isLabelDisplayed ? 'Price Band Low' : undefined}
          value={priceBandLow.toString()}
          isDisabled
        />
        <CurrencyInput
          currency="USD"
          id="priceBandHigh"
          label={isLabelDisplayed ? 'Price Band High' : undefined}
          value={priceBandHigh.toString()}
          isDisabled
        />
        <CurrencyInput
          currency="USD"
          id="cost"
          label={isLabelDisplayed ? 'Premium' : undefined}
          value={cost.toString()}
          isDisabled
        />
        <Input
          type={InputType.number}
          id="retailTarget"
          suffix="%"
          label={isLabelDisplayed ? 'Retail Target' : undefined}
          value={(retailTarget * 100).toString()}
          isDisabled
        />
        <CurrencyInput
          currency="USD"
          id="fixedPrice"
          label={isLabelDisplayed ? 'Fixed Price' : undefined}
          value={fixedPrice ? fixedPrice.toString() : ''}
          isDisabled
        />
      </Grid>
    </GridWrapper>
  )
}

const GridWrapper = styled.div({
  paddingBottom: 16,
})

export { PricingDetailsRow }
