import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { RouterError, SplashScreen } from '@helloextend/component-commons'
import { useSelector } from 'react-redux'
import { StoreUserRole } from '@helloextend/extend-api-client'
import { useUserAnalytics } from '@helloextend/client-hooks'
import { PasswordReset } from './password-reset'
import { Login } from './login'
import { OktaLogin, OktaLoginCallback, OktaLoginRoleSelector } from './okta-login'
import { Logout } from './logout'
import { ForgotPasswordPage } from './forgot-password'
import { ProtectedRoute } from '../hoc/protected-route'
import * as selectors from '../reducers/selectors'
import type { RootState } from '../reducers'
import { Permission } from '../lib/permissions'
import { Admin } from './admin'
import { useUser } from '../hooks/use-user'
import { DashboardProfilePage } from './dashboard/profile/dashboard-profile-page'
import { LDFlag } from '../constants/ld-flags'

const Router: FC = () => {
  const {
    id: userId,
    email,
    accountId,
    role,
  } = useSelector((state: RootState) => selectors.getUserInformation(state))
  const ldClient = useLDClient()
  const { initialize } = useUserAnalytics()
  const flags = useFlags()

  const { user, isLoggedIn, isLoaded } = useUser()

  useEffect(() => {
    const ldUser = !userId
      ? {
          anonymous: true,
        }
      : {
          key: userId,
          email,
          custom: {
            accountId,
          },
          privateAttributeNames: ['email'],
        }

    ldClient?.identify(ldUser)
  }, [ldClient, userId, email, accountId])

  useEffect(() => {
    if (userId && email && accountId && role) {
      initialize({ userId, email, accountId, role })
    }
  }, [initialize, userId, email, accountId, role])

  const rootRedirectTo = useMemo(() => {
    if (!isLoggedIn) {
      return '/login'
    }

    if (
      user.role &&
      [StoreUserRole.admin, StoreUserRole.superadmin, StoreUserRole.agent].includes(user.role)
    ) {
      return '/admin'
    }

    if (
      user.role &&
      [StoreUserRole.conversationseditor, StoreUserRole.conversationsviewer].includes(user.role)
    ) {
      return '/admin/adjudication-management'
    }

    return '/profile'
  }, [user, isLoggedIn])

  if (isLoggedIn && !isLoaded) return <SplashScreen />

  return (
    <Switch>
      <Redirect from="/" to={rootRedirectTo} exact />

      <Route path="/login/callback" exact>
        {isLoggedIn ? <Redirect to="/" /> : <OktaLoginCallback />}
      </Route>

      <Route path="/login">{flags[LDFlag.OktaLogin] ? <OktaLogin /> : <Login />}</Route>

      {flags[LDFlag.OktaLogin] && (
        <Route path="/role-selector">
          <OktaLoginRoleSelector />
        </Route>
      )}

      <Route path="/logout">
        <Logout />
      </Route>

      <Route path="/password/forgot">
        <ForgotPasswordPage />
      </Route>

      <Route path="/password/reset">
        <PasswordReset />
      </Route>

      <ProtectedRoute path="/admin" component={Admin} />

      <ProtectedRoute
        requiredPermissions={[Permission.ManageProfile]}
        path="/profile"
        component={DashboardProfilePage}
      />

      <Route>
        <RouterError />
      </Route>
    </Switch>
  )
}

export { Router }
