import { useState } from 'react'
import { v4 as uuid } from 'uuid'

type UseGetUniqueKeys = {
  keys: string[]
  addKey: (index: number) => void
  removeKey: (index: number) => void
  generateInitialKeys: (count: number) => void
}

const useGetUniqueKeys = (): UseGetUniqueKeys => {
  const [keys, setKeys] = useState<string[]>([])

  const handleAddKey = (index: number): void => {
    const key = uuid()
    const withNewKey = Array.from(keys)
    withNewKey.splice(index, 0, key)
    setKeys(withNewKey)
  }

  const handleRemoveKey = (index: number): void => {
    const withKeyRemoved = Array.from(keys)
    withKeyRemoved.splice(index, 1)
    setKeys(withKeyRemoved)
  }

  const generateInitialKeys = (count: number): void => {
    const initialKeys = Array.from({ length: count }, () => uuid())
    setKeys(initialKeys)
  }

  return {
    keys,
    addKey: handleAddKey,
    removeKey: handleRemoveKey,
    generateInitialKeys,
  }
}

export { useGetUniqueKeys }
