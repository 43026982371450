export const EXTEND_AGENTS_HOST =
  typeof __EXTEND_AGENTS_HOST__ !== 'undefined' ? __EXTEND_AGENTS_HOST__ : ''
export const EXTEND_API_HOST = typeof __EXTEND_API_HOST__ !== 'undefined' ? __EXTEND_API_HOST__ : ''
export const EXTEND_APP_NAME = typeof __EXTEND_APP_NAME__ !== 'undefined' ? __EXTEND_APP_NAME__ : ''
export const EXTEND_CUSTOMERS_HOST =
  typeof __EXTEND_CUSTOMERS_HOST__ !== 'undefined' ? __EXTEND_CUSTOMERS_HOST__ : ''
export const EXTEND_ENV = typeof __EXTEND_ENV__ !== 'undefined' ? __EXTEND_ENV__ : ''
export const EXTEND_MERCHANTS_HOST =
  typeof __EXTEND_MERCHANTS_HOST__ !== 'undefined' ? __EXTEND_MERCHANTS_HOST__ : ''
export const DEPOT_REPAIR_GOOGLE_MAPS_API_KEY =
  typeof __DEPOT_REPAIR_GOOGLE_MAPS_API_KEY__ !== 'undefined'
    ? __DEPOT_REPAIR_GOOGLE_MAPS_API_KEY__
    : ''
export const GOOGLE_MAPS_API_KEY =
  typeof __GOOGLE_MAPS_API_KEY__ !== 'undefined' ? __GOOGLE_MAPS_API_KEY__ : ''
export const LD_CLIENT_ID = typeof __LD_CLIENT_ID__ !== 'undefined' ? __LD_CLIENT_ID__ : ''
export const LD_CLIENT_ID_MERCHANTS =
  typeof __LD_CLIENT_ID_MERCHANTS__ !== 'undefined' ? __LD_CLIENT_ID_MERCHANTS__ : ''
export const LD_CLIENT_ID_CUSTOMERS =
  typeof __LD_CLIENT_ID_CUSTOMERS__ !== 'undefined' ? __LD_CLIENT_ID_CUSTOMERS__ : ''
export const LD_CLIENT_ID_PORTAL =
  typeof __LD_CLIENT_ID_PORTAL__ !== 'undefined' ? __LD_CLIENT_ID_PORTAL__ : ''
export const LD_CLIENT_ID_AGENTS =
  typeof __LD_CLIENT_ID_AGENTS__ !== 'undefined' ? __LD_CLIENT_ID_AGENTS__ : ''
export const SHOPINT_HOST = typeof __SHOPINT_HOST__ !== 'undefined' ? __SHOPINT_HOST__ : ''
export const SHOPINT2_HOST = typeof __SHOPINT2_HOST__ !== 'undefined' ? __SHOPINT2_HOST__ : ''
export const SHOPIFY_APP_URL = typeof __SHOPIFY_APP_URL__ !== 'undefined' ? __SHOPIFY_APP_URL__ : ''
export const MAGENTO_HOST = typeof __MAGENTO_HOST__ !== 'undefined' ? __MAGENTO_HOST__ : ''
export const COGNITO_CONSUMERS_CLIENT_ID =
  typeof __COGNITO_CONSUMERS_CLIENT_ID__ !== 'undefined' ? __COGNITO_CONSUMERS_CLIENT_ID__ : ''
export const COGNITO_CONSUMERS_HOST =
  typeof __COGNITO_CONSUMERS_HOST__ !== 'undefined' ? __COGNITO_CONSUMERS_HOST__ : ''
export const DATADOG_APP_ID = typeof __DATADOG_APP_ID__ !== 'undefined' ? __DATADOG_APP_ID__ : ''
export const DATADOG_CLIENT_TOKEN =
  typeof __DATADOG_CLIENT_TOKEN__ !== 'undefined' ? __DATADOG_CLIENT_TOKEN__ : ''
export const ANALYTICS_PRODUCTS_REPORT =
  typeof __ANALYTICS_PRODUCTS_REPORT__ !== 'undefined' ? __ANALYTICS_PRODUCTS_REPORT__ : ''

export const OKTA_BASE_URL = typeof __OKTA_BASE_URL__ !== 'undefined' ? __OKTA_BASE_URL__ : ''
export const OKTA_RESET_PASSWORD_URL =
  typeof __OKTA_RESET_PASSWORD_URL__ !== 'undefined' ? __OKTA_RESET_PASSWORD_URL__ : ''
export const OKTA_CLIENT_ID = typeof __OKTA_CLIENT_ID__ !== 'undefined' ? __OKTA_CLIENT_ID__ : ''
export const OKTA_CLIENT_ID_MERCHANTS =
  typeof __OKTA_CLIENT_ID_MERCHANTS__ !== 'undefined' ? __OKTA_CLIENT_ID_MERCHANTS__ : ''
export const OKTA_CLIENT_ID_INSTORE =
  typeof __OKTA_CLIENT_ID_INSTORE__ !== 'undefined' ? __OKTA_CLIENT_ID_INSTORE__ : ''
export const OKTA_AUTHORIZATION_URL =
  typeof __OKTA_AUTHORIZATION_URL__ !== 'undefined' ? __OKTA_AUTHORIZATION_URL__ : ''
export const OKTA_TOKEN_ENDPOINT =
  typeof __OKTA_TOKEN_ENDPOINT__ !== 'undefined' ? __OKTA_TOKEN_ENDPOINT__ : ''
export const PORTAL_DOMAIN_HOST =
  typeof __PORTAL_DOMAIN_HOST__ !== 'undefined' ? __PORTAL_DOMAIN_HOST__ : ''
export const PORTAL_APP_REDIRECT_URI =
  typeof __PORTAL_APP_REDIRECT_URI__ !== 'undefined' ? __PORTAL_APP_REDIRECT_URI__ : ''
export const MERCHANTS_APP_REDIRECT_URI =
  typeof __MERCHANTS_APP_REDIRECT_URI__ !== 'undefined' ? __MERCHANTS_APP_REDIRECT_URI__ : ''
export const INSTORE_APP_REDIRECT_URI =
  typeof __INSTORE_APP_REDIRECT_URI__ !== 'undefined' ? __INSTORE_APP_REDIRECT_URI__ : ''
export const OKTA_SCOPE = typeof __OKTA_SCOPE__ !== 'undefined' ? __OKTA_SCOPE__ : ''
export const ANALYTICS_SHIPPING_REPORT =
  typeof __ANALYTICS_SHIPPING_REPORT__ !== 'undefined' ? __ANALYTICS_SHIPPING_REPORT__ : ''
