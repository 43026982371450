import type { FC } from 'react'
import React, { useState, useMemo } from 'react'
import { ContractType } from '@helloextend/extend-api-client'
import { useHistory } from 'react-router-dom'
import type { SortingState, ColumnFiltersState } from '@helloextend/zen'
import { HeadingLarge, Add, DataTable } from '@helloextend/zen'
import type { ContractSearchV1QueryStringOptions } from '@helloextend/extend-api-rtk-query'
import { useSearchContractsV1Query } from '@helloextend/extend-api-rtk-query'
import { contractsColumns } from './tableConfig'
import type { TableContractsSearch } from '../../../types/contracts'
import { formatAndSortContractItems, prepareContractSearchArgs } from './utils'

const Contracts: FC = () => {
  const history = useHistory()

  const [sorting, setSorting] = useState<SortingState>([{ id: 'type', desc: true }])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

  const argsForApi: ContractSearchV1QueryStringOptions = useMemo(
    () => prepareContractSearchArgs(columnFilters),
    [columnFilters],
  )

  const { data, isFetching, isError } = useSearchContractsV1Query(argsForApi, {
    skip: columnFilters.length === 0,
  })

  const contracts = useMemo(
    () => (data?.contracts ? formatAndSortContractItems(data.contracts, sorting) : []),
    [data?.contracts, sorting],
  )

  const handleClick = (): void => {
    history.push('/admin/contracts/create')
  }

  const handleRowClick = (contract: TableContractsSearch): void => {
    if ([ContractType.PCRS, ContractType.SHIPPING_PROTECTION].includes(contract.type)) {
      window.open(`/admin/contracts/${contract.contractId}`, '_blank')
    }
  }

  return (
    <DataTable
      heading={<HeadingLarge>Contracts</HeadingLarge>}
      itemName="contract"
      data-cy="contracts"
      isError={isError}
      isLoading={isFetching}
      data={contracts}
      columns={contractsColumns}
      rowCount={contracts.length}
      sorting={sorting}
      onSortingChange={setSorting}
      hasManualSorting
      hasManualFiltering
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      getRowId={(row) => row.contractId}
      stateViews={{
        preFilter: {
          description: 'Select your search criteria before submitting.',
        },
      }}
      getRowActions={(row) => [
        {
          onClick: () => handleRowClick(row),
          text: 'View',
          emphasis: 'low',
        },
      ]}
      getTableActions={() => [
        {
          emphasis: 'medium',
          text: 'Create Contract',
          onClick: handleClick,
          icon: Add,
        },
      ]}
      hasRequiredFilter
    />
  )
}

export { Contracts }
