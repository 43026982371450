import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import { Button, Input, useToaster, ToastColor, ToastDuration } from '@helloextend/zen'
import { useServiceOrderSetAsPaidMutation } from '@helloextend/extend-api-rtk-query'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { setClaimDetailsActiveView } from '../../../../../../store/slices/claim-details'
import { ClaimPageTab } from '../tab-section/types'
import { schema } from './schema'
import type { Values } from './schema'

interface MarkAsPaidContentProps {
  serviceOrder: ServiceOrder
}

const MarkAsPaidContent: FC<MarkAsPaidContentProps> = ({ serviceOrder }) => {
  const dispatch = useDispatch()
  const { toast } = useToaster()
  const [setAsPaid, { isSuccess, isLoading }] = useServiceOrderSetAsPaidMutation()

  const { values, handleSubmit, handleChange, errors, touched, isValid } = useFormik<Values>({
    enableReinitialize: true,
    initialValues: schema.default(),
    validationSchema: schema,
    validateOnMount: true,
    onSubmit: (body) => {
      setAsPaid({ serviceOrderId: serviceOrder.id, body })
    },
  })

  useEffect(() => {
    if (isSuccess) {
      toast({
        message: 'Service order marked as paid!',
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
      dispatch(setClaimDetailsActiveView(ClaimPageTab.ServiceOrder))
    }
  }, [isSuccess, dispatch, toast])

  return (
    <form onSubmit={handleSubmit}>
      <Input
        id="paymentId"
        onChange={handleChange}
        value={values.paymentId}
        data-cy="payment-id"
        errorFeedback={(touched.paymentId && errors.paymentId) || ''}
        isError={touched.paymentId && !!errors.paymentId}
        label="Payment ID (NetSuite ID, Invoice ID, Brex Transaction ID)"
      />
      <ButtonGroup>
        <Button
          text="Cancel"
          emphasis="medium"
          data-cy="cancel-button"
          onClick={() => dispatch(setClaimDetailsActiveView(ClaimPageTab.ServiceOrder))}
          isDisabled={isLoading}
        />
        <Button
          type="submit"
          text="Submit"
          data-cy="mark-paid-button"
          isDisabled={isLoading || !isValid}
          onClick={() => handleSubmit()}
          isProcessing={isLoading}
        />
      </ButtonGroup>
    </form>
  )
}

const ButtonGroup = styled.div({
  display: 'flex',
  marginTop: 32,
  width: '100%',
  justifyContent: 'flex-end',
  gap: 16,
})

export { MarkAsPaidContent }
