import type { Expense } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetchByServiceOrderId = (serviceOrderId: string, accessToken: string) =>
  ({ type: 'SERVICE_ORDER_EXPENSES_FETCH', payload: { serviceOrderId, accessToken } } as const)

export const fetchServiceOrderExpensesSuccess = (serviceOrderExpenses: Expense[]) =>
  ({
    type: 'SERVICE_ORDER_EXPENSES_FETCH_SUCCESS',
    payload: { serviceOrderExpenses },
  } as const)

export const fetchServiceOrderExpensesFailure = createActionFailure(
  'SERVICE_ORDER_EXPENSES_FETCH_FAILURE',
)

export const fetchServiceOrderExpensesRequest = () =>
  ({ type: 'SERVICE_ORDER_EXPENSES_FETCH_REQUEST' } as const)
