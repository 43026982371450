import { useFormikContext } from 'formik'
import type { FC } from 'react'
import React from 'react'
import { Checkbox, Grid, Information, Input, InputType } from '@helloextend/zen'
import styled from '@emotion/styled'
import { ShippingProtectionPlanDropdown } from '../shipping-protection-plan-dropdown/shipping-protection-plan-dropdown'
import { RevenueShareInput } from '../shipping-protection-form/revenue-share-input'
import type { Values } from '../shipping-protection-purchase-model-form/schema'

type ShippingProtectionFallbackFormProps = {
  spPlansList: string[]
  isSpListLoading?: boolean
}

const ShippingProtectionFallbackForm: FC<ShippingProtectionFallbackFormProps> = ({
  spPlansList,
  isSpListLoading,
}) => {
  const { values, errors, handleChange, handleBlur } = useFormikContext<Values>()

  return (
    <div data-cy="fallback-sp-settings">
      <ContainerWithMargin>
        <Grid columns={3}>
          <ShippingProtectionPlanDropdown
            value={values.planId}
            onChange={handleChange}
            onBlur={handleBlur}
            isLoading={isSpListLoading}
            options={spPlansList}
            isError={Boolean(errors.planId)}
            errorFeedback={errors.planId}
            id="planId"
            isPlanDetailsDisplayed
          />
        </Grid>
      </ContainerWithMargin>
      <ContainerWithMargin>
        <Grid columns={3}>
          <RevenueShareInput
            value={values.merchantSpRevenueSharePercentage}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={Boolean(errors.merchantSpRevenueSharePercentage)}
            errorFeedback={errors.merchantSpRevenueSharePercentage}
          />
        </Grid>
      </ContainerWithMargin>
      {values.partialReimbursement && (
        <ContainerWithMargin>
          <Grid columns={3}>
            <Input
              value={values?.partialReimbursementSPPercentage?.toString() || ''}
              id="partialReimbursementSPPercentage"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Partial Reimbursement Percentage"
              placeholder="Enter percentage"
              type={InputType.number}
              suffix="%"
              isError={Boolean(errors.partialReimbursementSPPercentage)}
              errorFeedback={errors.partialReimbursementSPPercentage}
              data-cy="fallback-sp-partial-reimbursement-percentage"
            />
          </Grid>
        </ContainerWithMargin>
      )}
      <ContainerWithMargin>
        <CheckboxContainer>
          <Checkbox
            label="Offers by Category Enabled"
            name="offersByCategoryEnabled"
            checked={values?.offersByCategoryEnabled || false}
            onChange={handleChange}
            data-cy="sp-offers-by-category-toggle"
          />
          <Information data-cy="sp-offers-by-category-info">
            <p>
              Extend can offer Shipping Protection on products that don&apos;t exist in the store
              product catalog if this setting is Enabled.
            </p>
          </Information>
        </CheckboxContainer>
      </ContainerWithMargin>
    </div>
  )
}

const ContainerWithMargin = styled.div({
  marginTop: 16,
})

const CheckboxContainer = styled.div({
  display: 'flex',
})

export { ShippingProtectionFallbackForm }
