import { combineReducers } from 'redux'
import type { ErrorReducerState } from '@helloextend/core-api-redux/src/types/error'
import type { StoresByIdReducerState } from './by-id'
import byId from './by-id'
import error from './error'

export interface MerchantsStoreReducerState {
  byId: StoresByIdReducerState
  error: ErrorReducerState
}

export default combineReducers<MerchantsStoreReducerState>({
  byId,
  error,
})
