import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import type { StoreIntegrationSettings } from '@helloextend/extend-api-client'
import { Button, Checkbox, Edit, InlineAlert, InlineAlertColor } from '@helloextend/zen'
import { useFormik } from 'formik'
import { useUpdateIntegrationConfigMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import { SectionContainer } from '../../../../../../../../components/section-container'
import { SaveChangesButtonGroup } from '../../../settings/components/save-changes-button-group'
import { OfferSyncTypeRadio } from './offer-sync-type-radio'
import { ContractCreationRadio } from './contract-creation-radio'
import type { Values } from './schema'
import { checkboxConfig } from './schema'

type IntegrationSettingsFormProps = {
  integrationConfig: StoreIntegrationSettings
}

const IntegrationSettingsForm: FC<IntegrationSettingsFormProps> = ({ integrationConfig }) => {
  const [isEditEnabled, setIsEditEnabled] = useState(false)
  const isShopifyPlatform = integrationConfig?.platform === 'shopify'
  const isBigCommercePlatform = integrationConfig?.platform === 'big-commerce'
  const isIntegrationSettingsAvailable = isShopifyPlatform || isBigCommercePlatform

  const [updateConfig, { isError, isSuccess, isLoading }] = useUpdateIntegrationConfigMutation()
  const { toastCompleted, toastError } = useStandardToast()

  const {
    offerSyncType,
    contractEvent,
    createContracts,
    autoRefunds,
    createLeads,
    barcodes,
    autoFulfillPlans,
    claimsFullfillment,
    productsUpdate,
    productsCreate,
    productsDelete,
  } = integrationConfig

  const { dirty, values, setFieldValue, handleChange, resetForm } = useFormik<Values>({
    initialValues: {
      offerSyncType,
      contractEvent,
      createContracts,
      autoRefunds,
      createLeads,
      barcodes,
      autoFulfillPlans,
      claimsFullfillment,
      productsUpdate,
      productsCreate,
      productsDelete,
    },
    onSubmit: () => {},
    enableReinitialize: true,
  })

  const handleSaveChanges = (): void => {
    updateConfig({ data: { ...integrationConfig, ...values }, storeId: integrationConfig.storeId })
  }

  const handleCancelChanges = (): void => {
    setIsEditEnabled(false)
    resetForm()
  }

  useEffect(() => {
    if (isSuccess) {
      toastCompleted('Integration settings have been successfully updated.')
      resetForm()
      setIsEditEnabled(false)
    }
  }, [isSuccess, resetForm, toastCompleted])

  useEffect(() => {
    if (isError) {
      toastError('Integration settings could not be updated. Please try again.')
    }
  }, [isError, toastError])

  if (!isIntegrationSettingsAvailable)
    return (
      <SectionContainer title="Integration Settings">
        <InlineAlert color={InlineAlertColor.blue} data-cy="integration-settings-form-alert">
          <Text>
            There is no integration settings available for {integrationConfig.platform} merchants.
          </Text>
        </InlineAlert>
      </SectionContainer>
    )

  return (
    <SectionContainer
      title="Integration Settings"
      {...(!isEditEnabled && {
        actionItem: (
          <Button
            text="Edit"
            emphasis="low"
            icon={Edit}
            size="small"
            onClick={() => setIsEditEnabled(true)}
            data-cy="integration-settings-edit-button"
          />
        ),
      })}
    >
      {isShopifyPlatform && (
        <OfferSyncTypeRadio
          offerSyncType={values.offerSyncType}
          isDisabled={!isEditEnabled}
          handleChange={handleChange}
        />
      )}

      <FieldContainerWithMargin>
        <ContractCreationRadio
          contractEvent={values.contractEvent}
          createContracts={values.createContracts}
          isDisabled={!isEditEnabled}
          setFieldValue={setFieldValue}
        />
      </FieldContainerWithMargin>

      {checkboxConfig.map(
        ({ label, name, platform }) =>
          (integrationConfig?.platform === platform || platform === 'all') && (
            <FieldContainerWithMargin key={name}>
              <Checkbox
                label={label}
                name={name}
                checked={(values as StoreIntegrationSettings)[name] as boolean}
                onChange={handleChange}
                disabled={!isEditEnabled}
                data-cy={`${name}-checkbox`}
              />
            </FieldContainerWithMargin>
          ),
      )}

      {isEditEnabled && (
        <ButtonGroupWrapper>
          <SaveChangesButtonGroup
            handleSave={handleSaveChanges}
            id="integration-settings"
            handleCancel={handleCancelChanges}
            isSaveDisabled={!dirty || isLoading}
            isLoading={isLoading}
            saveButtonText="Save Changes"
            data-cy="integration-settings-save-changes-buttons"
          />
        </ButtonGroupWrapper>
      )}
    </SectionContainer>
  )
}

const Text = styled.p({
  margin: 0,
})

const FieldContainerWithMargin = styled.div({
  marginTop: 24,
})

const ButtonGroupWrapper = styled.div({
  marginTop: 32,
})

export { IntegrationSettingsForm }
