import { createApi } from '@reduxjs/toolkit/query/react'
import type { ProductCategoriesGetResponse } from '@helloextend/extend-api-client'
import { baseQuery } from '../base-query'

const productCategoriesRdbApi = createApi({
  baseQuery,
  reducerPath: 'ProductCategoriesRdb',
  tagTypes: ['ProductCategoriesRdb'],
  endpoints: (build) => ({
    getStoreCategories: build.query<ProductCategoriesGetResponse, string>({
      query: (storeId) => ({
        url: `/merchants-portal/stores/${storeId}/product-categories`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      providesTags: (_result, _err, storeId) => [{ type: 'ProductCategoriesRdb', id: storeId }],
    }),
  }),
})

const { useGetStoreCategoriesQuery } = productCategoriesRdbApi

export { productCategoriesRdbApi, useGetStoreCategoriesQuery }
