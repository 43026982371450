import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import { useGetUsersQuery } from '@helloextend/extend-api-rtk-query'
import { DataReactTable, PaginationType } from '@helloextend/merchants-ui'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@helloextend/zen'
import { Add } from '@helloextend/zen/src/tokens/icons'
import styled from '@emotion/styled'
import { useToggle } from '@helloextend/client-hooks'
import { usersColumns, toTableUsers, searchOptions } from './table-config'
import { CreateUserModal } from '../../../components/v2/create-user-modal/create-user-modal'

const AccountUsers: FC = () => {
  const { push } = useHistory()
  const { accountId } = useParams<{ accountId: string }>()
  const { data, isLoading } = useGetUsersQuery(accountId || '')
  const [isModalDisplayed, { on: displayModalOn, off: displayModalOff }] = useToggle(false)
  const tableUsers = useMemo(() => toTableUsers(data ?? []), [data])

  const handleRowClick = useCallback(
    (_e, rowData) => {
      push(`/admin/accounts/${accountId}/users/${rowData.id}`)
    },
    [accountId, push],
  )

  return (
    <>
      <ButtonContainer>
        <ButtonWrapper>
          <Button
            text="Add User"
            icon={Add}
            emphasis="medium"
            onClick={displayModalOn}
            data-cy="add-user-button"
          />
        </ButtonWrapper>
      </ButtonContainer>
      <DataReactTable
        enableSearch
        type="users"
        isLoading={isLoading}
        hasSearchBar
        data={tableUsers}
        columns={usersColumns}
        searchOptions={searchOptions}
        pageCount={-1}
        paginationType={PaginationType.ENHANCED}
        emptyMessage="Double check that your search terms are correct, or add a new user using the button above."
        onRowClick={handleRowClick}
        data-cy="account-users-table"
      />
      <CreateUserModal isVisible={isModalDisplayed} toggleOff={displayModalOff} />
    </>
  )
}

const ButtonContainer = styled.div({
  position: 'relative',
})

const ButtonWrapper = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
})

export { AccountUsers }
