import type { FC } from 'react'
import React from 'react'
import Helmet from 'react-helmet'
import { addGTagManager } from '@helloextend/client-hooks'

interface HeadTagProps {
  siteTitle?: string
}

const HeadTag: FC<HeadTagProps> = ({ siteTitle = 'Extend', children = null }) => (
  <>
    <Helmet title={siteTitle}>
      {children}
      {addGTagManager('UA-133302759-5')}
    </Helmet>
  </>
)

export { HeadTag }
