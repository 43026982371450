import type { ProductCsvValidatorPipe } from './product-csv-headers'
import { isEncoded, isScientificNotation } from '../validation-helpers'

/**
 * checks the existence and formatting of the product reference ID
 * @param meta - piped object
 */
const validateProductId = (meta: ProductCsvValidatorPipe): ProductCsvValidatorPipe => {
  const referenceIdIndex = meta.headerIndexes.referenceId
  const productId = meta.rowData[referenceIdIndex]
  if (!productId) {
    meta.errors.push('"referenceId" should be defined')
    return meta
  }

  if (productId.trim() !== productId) {
    meta.errors.push(`"referenceId" should not have leading or trailing whitespace (${productId})`)
  }

  if (isEncoded(productId)) {
    meta.errors.push(`"referenceId" should not be encoded (${productId})`)
  }

  if (isScientificNotation(productId)) {
    meta.errors.push(`"referenceId" may be formatted in scientific notation (${productId})`)
  }

  return meta
}

export { validateProductId }
