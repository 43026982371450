import * as errorActions from './error'
import * as forgotPasswordActions from './forgot-password'
import * as loginActions from './login'
import * as logoutActions from './logout'
import * as registerActions from './register'
import * as resetPasswordActions from './reset-password'
import * as changePasswordActions from './change-password'
import type { ExtractActions } from '../../types/utility'

export type Action =
  | ExtractActions<typeof errorActions>
  | ExtractActions<typeof loginActions>
  | ExtractActions<typeof logoutActions>
  | ExtractActions<typeof registerActions>
  | ExtractActions<typeof forgotPasswordActions>
  | ExtractActions<typeof resetPasswordActions>
  | ExtractActions<typeof changePasswordActions>

export type ActionType = Action['type']

const actions = {
  ...errorActions,
  ...loginActions,
  ...logoutActions,
  ...registerActions,
  ...forgotPasswordActions,
  ...resetPasswordActions,
  ...changePasswordActions,
}

export { actions }
