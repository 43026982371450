import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useHeightTransition } from '../../transitions'

export interface AccordionContentProps {
  children: React.ReactNode
}

export const AccordionContent: FC<AccordionContentProps> = ({ children }) => {
  const { contentRef, bodyRef, isVisible, height, isOverflowVisible, transitionDurationMs } =
    useHeightTransition()

  return (
    <StyledAccordionContent
      isVisible={isVisible}
      transitionDurationMs={transitionDurationMs}
      bodyHeight={height}
      isOverflowVisible={isOverflowVisible}
      ref={bodyRef}
    >
      <Content ref={contentRef}>{children}</Content>
    </StyledAccordionContent>
  )
}

const StyledAccordionContent = styled.div<{
  isVisible: boolean
  transitionDurationMs: number
  bodyHeight?: number | string
  isOverflowVisible?: boolean
}>(({ isVisible, transitionDurationMs, bodyHeight, isOverflowVisible }) => ({
  height: bodyHeight,
  transitionProperty: 'opacity, transform, height',
  transitionDuration: `${transitionDurationMs}ms`,
  transform: isVisible ? 'translateY(0px)' : 'translateY(-4px)',
  opacity: isVisible ? 1 : 0,
  overflow: isOverflowVisible ? 'visible' : 'hidden',
}))

const Content = styled.div({
  display: 'inline-block',
  width: '100%',
})
