import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { get, post, destroy } from '@helloextend/extend-api-fetch'
import type {
  ClaimPhotoDeleteRequest,
  ClaimPhotosCreateRequest,
  ClaimPhotosCreateResponse,
  ClaimPhotosGetResponse,
} from '../models/claim-photos'

export async function create(
  contractId: string,
  claimId: string,
  body: ClaimPhotosCreateRequest,
  accessToken: string,
): Promise<ApiResponse<ClaimPhotosCreateResponse>> {
  return post(`/contracts/${contractId}/claims/${claimId}/photos`, {
    body,
    accessToken,
  })
}

export async function uploadToS3(url: string, formData: FormData): Promise<ApiResponse> {
  return post(url, { body: formData })
}

export async function fetch(
  contractId: string,
  claimId: string,
  accessToken: string,
): Promise<ApiResponse<ClaimPhotosGetResponse>> {
  return get(`/contracts/${contractId}/claims/${claimId}/photos`, {
    accessToken,
  })
}

export async function deleteClaimPhoto(
  contractId: string,
  claimId: string,
  photoId: string,
  body: ClaimPhotoDeleteRequest,
  accessToken: string,
): Promise<ApiResponse> {
  return destroy(`/contracts/${contractId}/claims/${claimId}/photos/${photoId}`, {
    body,
    accessToken,
  })
}
