import type { FC } from 'react'
import React from 'react'

const TableHead: FC = ({ children }) => {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  )
}

export { TableHead }
