import type { FC } from 'react'
import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import type { UserGrant } from '@helloextend/extend-api-rtk-query'
import { useGetUserGrantsListQuery } from '@helloextend/extend-api-rtk-query'
import { ToastColor, ToastDuration, useToaster, COLOR } from '@helloextend/zen'
import { SplashScreen } from '@helloextend/component-commons'
import { useOktaExchangeToken } from '../../hooks/use-okta-exchange-token'
import type { RootState } from '../../reducers'
import * as selectors from '../../reducers/selectors'
import { getUserEmailFromToken } from '../../lib/jwt'
import { isLegacyInternalRole } from '../../utils/user-role-mapper'

const OktaLoginRoleSelector: FC = () => {
  const { toast } = useToaster()
  const history = useHistory()
  const accessToken = useSelector((state: RootState) => selectors.getAccessToken(state))

  const email = getUserEmailFromToken(accessToken)

  const { exchangeToken, isLoading } = useOktaExchangeToken()

  const {
    data,
    isLoading: isGettingGrants,
    isError: isErrorGettingGrants,
  } = useGetUserGrantsListQuery(email ?? '')

  const showErrorToastAndRedirectToLogin = useCallback(
    (message: string) => {
      toast({
        message,
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
      history.push('/login')
    },
    [history, toast],
  )

  const handleRoleSelection = async (grant: UserGrant): Promise<void> => {
    try {
      if (!accessToken) throw new Error()
      await exchangeToken({ accessToken, grant })
    } catch (error) {
      showErrorToastAndRedirectToLogin('Unable to login with the selected role')
    }
  }

  useEffect(() => {
    if (!accessToken) {
      showErrorToastAndRedirectToLogin('No token available to exchange')
    }

    if (isErrorGettingGrants) {
      showErrorToastAndRedirectToLogin('Error getting user roles')
    }

    if (data?.grants && data.grants.length === 0) {
      showErrorToastAndRedirectToLogin('User has no roles assigned')
    }
  }, [accessToken, data?.grants, history, isErrorGettingGrants, showErrorToastAndRedirectToLogin])

  if (isLoading || isGettingGrants) return <SplashScreen />

  return (
    <RoleSelectorContainer data-cy="login-role-selector">
      <RoleSelector>
        <RoleSelectorHeader>Select A Role</RoleSelectorHeader>
        <RoleSelectorItemContainer>
          {data && data.grants
            ? data.grants
                .filter((grant) => isLegacyInternalRole(grant.role))
                .map((grant) => {
                  return (
                    <RoleSelectorItem
                      key={`${grant.role}-${grant.ern}`}
                      data-cy="login-role-selector-item"
                      onClick={() => handleRoleSelection(grant)}
                    >
                      {grant.role}
                    </RoleSelectorItem>
                  )
                })
            : null}
        </RoleSelectorItemContainer>
      </RoleSelector>
    </RoleSelectorContainer>
  )
}

const RoleSelectorContainer = styled.div({
  background: '#F2F2F2',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Nunito Sans',
})

const RoleSelector = styled.div({
  width: 480,
  minHeight: 292,
  maxHeight: 630,
  background: COLOR.WHITE,
  borderRadius: 12,
  boxShadow: '0px 0px 40px rgba(163, 163, 163, 0.0813688)',
  padding: '0 40px 40px',
  boxSizing: 'border-box',
  overflow: 'auto',
})

const RoleSelectorItem = styled.button({
  background: COLOR.WHITE,
  width: 400,
  height: 80,
  marginTop: 17,
  marginBottom: 16,
  textAlign: 'left',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  paddingLeft: 32,
  fontSize: 14,
  color: COLOR.NEUTRAL[1000],
  cursor: 'pointer',
  '&:hover': {
    border: `1px solid ${COLOR.BLUE[800]}`,
    background: COLOR.NEUTRAL[100],
  },
})
const RoleSelectorItemContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 60,
})

const RoleSelectorHeader = styled.div({
  fontSize: 12,
  letterSpacing: 1,
  fontStyle: 'normal',
  fontWeight: 700,
  textTransform: 'uppercase',
  position: 'fixed',
  paddingTop: 40,
  paddingBottom: 20,
  width: 400,
  background: COLOR.WHITE,
})

export { OktaLoginRoleSelector }
