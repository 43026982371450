import type { Expense, ServiceOrderExpensesCreateRequest } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const create = (
  serviceOrderId: string,
  body: ServiceOrderExpensesCreateRequest,
  accessToken: string,
) =>
  ({
    type: 'SERVICE_ORDER_EXPENSES_CREATE',
    payload: { serviceOrderId, body, accessToken },
  } as const)

export const createSuccess = (expense: Expense) =>
  ({
    type: 'SERVICE_ORDER_EXPENSES_CREATE_SUCCESS',
    payload: { expense },
  } as const)

export const createFailure = createActionFailure('SERVICE_ORDER_EXPENSES_CREATE_FAILURE')

export const createRequest = () => ({ type: 'SERVICE_ORDER_EXPENSES_CREATE_REQUEST' } as const)
