import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { InlineAlert, InlineAlertColor } from '@helloextend/zen'
import { Error } from '@helloextend/zen/src/tokens/icons'
import type { PublishValidationDetails } from '../../../../../store/slices/amp-validation'

type ConversationPublishInlineAlertProps = {
  publishValidationMap: PublishValidationDetails
}

const ConversationPublishInlineAlert: FC<ConversationPublishInlineAlertProps> = ({
  publishValidationMap,
}) => {
  const {
    isConversationEmpty,
    isFirstThreadIntro,
    isIntroThreadMissing,
    isOutroThreadMissing,
    hasDuplicateThreadTypes,
    reusableThreadsTypeCounts,
  } = publishValidationMap

  const duplicateThreadList = useMemo(() => {
    if (!reusableThreadsTypeCounts) return ''

    const duplicateReusableThreadTypes = Object.entries(reusableThreadsTypeCounts).reduce(
      (acc, [threadType, count]) => {
        if (count > 1) {
          return [...acc, threadType.concat(' thread')]
        }
        return acc
      },
      [] as string[],
    )

    return duplicateReusableThreadTypes.length > 1
      ? duplicateReusableThreadTypes.join(', ')
      : `${duplicateReusableThreadTypes[0]} `
  }, [reusableThreadsTypeCounts])

  // conversation can be invalid because of only single_use thread errors
  // if all reusable thread validations are ok, don't render the banner
  if (
    !isConversationEmpty &&
    isFirstThreadIntro &&
    !isIntroThreadMissing &&
    !isOutroThreadMissing &&
    !hasDuplicateThreadTypes
  ) {
    return null
  }

  return (
    <InlineAlertContainer>
      <InlineAlert
        color={InlineAlertColor.red}
        icon={Error}
        data-cy="publish-structure-inline-alerts"
      >
        {(isConversationEmpty || !isFirstThreadIntro) && (
          <InlineAlertText data-cy="first-thread-not-intro">
            The intro thread should be placed at the beginning of the conversation.
          </InlineAlertText>
        )}
        {hasDuplicateThreadTypes && (
          <InlineAlertText data-cy="duplicated-reusable-thead-types">{`There can only be a single ${duplicateThreadList} embedded in the conversation.`}</InlineAlertText>
        )}
        {(isIntroThreadMissing || isOutroThreadMissing) && (
          <>
            <InlineAlertText data-cy="missing-threads-list">
              Following item(s) is required:
            </InlineAlertText>
            <InlineAlertList>
              {isIntroThreadMissing && (
                <li data-cy="missing-threads-list:intro">An intro thread</li>
              )}
              {isOutroThreadMissing && (
                <li data-cy="missing-threads-list:outro">An outro thread</li>
              )}
            </InlineAlertList>
          </>
        )}
      </InlineAlert>
    </InlineAlertContainer>
  )
}

const InlineAlertContainer = styled.div({
  marginBottom: 16,
  marginLeft: 44,
  position: 'fixed',
  top: 72,
  zIndex: 2,
})

const InlineAlertText = styled.p({
  fontWeight: 400,
  fontSize: 15,
  lineHeight: '20px',
  margin: 0,
})

const InlineAlertList = styled.ul({
  margin: 0,
  paddingLeft: `28px`,
})

export { ConversationPublishInlineAlert, ConversationPublishInlineAlertProps }
