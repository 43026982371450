import type { PlanSet, PlanSetListResponse, PlanSetPriceBand } from '@helloextend/extend-api-client'
import { faker } from '@faker-js/faker/locale/en'
import type { DraftPlanSet } from '@helloextend/extend-api-client/src/models/plan-sets'
import { uuid } from '@helloextend/extend-sdk-client/lib/utils/util'

export const generatePlanSetName = (planCategory = 'Jewelry', suffix = 'test-plan'): string =>
  `PS-${planCategory.replace(/\s/g, '-')}-${suffix}`

export const generatePlanSet = (overrides: Partial<PlanSet> = {}): PlanSet => {
  return {
    id: uuid(),
    name: overrides.planCategory
      ? generatePlanSetName(overrides.planCategory)
      : generatePlanSetName(),
    planCategory: faker.random.word(),
    priceBands: [
      { plans: ['plan-id-1'], minPrice: 0, maxPrice: 100 },
      { plans: ['plan-id-2'], minPrice: 101, maxPrice: 1000 },
    ],
    isGTM: true,
    setVersion: 1,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    ...overrides,
  }
}
export const generatePlanSets = (amount = 3): PlanSet[] => {
  return Array.from({ length: amount }, generatePlanSet)
}

export const generatePlanSetListResponse = (
  overrides?: Partial<PlanSetListResponse>,
): PlanSetListResponse => {
  return {
    items: [generatePlanSet()],
    limit: 500,
    nextPageCursor: undefined,
    ...overrides,
  }
}

export const generatePriceBand = (
  overrides: DeepPartial<PlanSetPriceBand> = {},
): PlanSetPriceBand => ({
  minPrice: 1,
  maxPrice: 2,
  plans: ['plan-id-1'],
  ...overrides,
})

export const generatePlanSetForTest = (overrides: Partial<DraftPlanSet> = {}): DraftPlanSet => {
  const suffix = `${faker.random.word()}-${faker.datatype.number()}`
  const plansCategory = 'Bikes'
  const planSetName = generatePlanSetName(plansCategory, suffix)

  return {
    name: planSetName,
    planCategory: plansCategory,
    isGTM: false,
    priceBands: [generatePriceBand({ minPrice: 1, maxPrice: 100, plans: ['plan-id-1'] })],
    ...overrides,
  }
}
