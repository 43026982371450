import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { get, post } from '@helloextend/extend-api-fetch'
import type {
  ServiceOrder,
  ProductReplacementServiceOrdersFulfillRequest,
  SerivceOrdersFulfillResponse,
  ServiceOrderAssignRequest,
  ServiceOrderAssignResponse,
  ServiceOrderStartRepairRequest,
  ServiceOrderStartRepairResponse,
  ServiceOrderExpensesCreateRequest,
  ServiceOrderExpensesCreateResponse,
  ServiceOrderExpensesGetResponse,
} from '../models/service-order'

export async function fetchByClaim(
  claimId: string,
  accessToken: string,
): Promise<ApiResponse<ServiceOrder[]>> {
  return get(`/service-orders/search?claimId=${claimId}`, {
    accessToken,
    apiVersion: '2021-07-01',
  })
}

export async function approveReplacement(
  serviceOrderId: string,
  accessToken: string,
): Promise<ApiResponse<null>> {
  return post(`/service-orders/${serviceOrderId}/approve-replacement`, { accessToken })
}

export async function fulfill(
  serviceOrderId: string,
  body: ProductReplacementServiceOrdersFulfillRequest,
  accessToken: string,
): Promise<ApiResponse<SerivceOrdersFulfillResponse>> {
  return post(`/service-orders/${serviceOrderId}/fulfill`, {
    accessToken,
    body,
  })
}

export async function assign(
  serviceOrderId: string,
  body: ServiceOrderAssignRequest,
  accessToken: string,
): Promise<ApiResponse<ServiceOrderAssignResponse>> {
  return post(`/service-orders/${serviceOrderId}/assign`, {
    accessToken,
    body,
  })
}

export async function startRepair(
  serviceOrderId: string,
  body: ServiceOrderStartRepairRequest,
  accessToken: string,
): Promise<ApiResponse<ServiceOrderStartRepairResponse>> {
  return post(`/service-orders/${serviceOrderId}/start-repair`, {
    accessToken,
    body,
  })
}

export async function createExpense(
  serviceOrderId: string,
  body: ServiceOrderExpensesCreateRequest,
  accessToken: string,
): Promise<ApiResponse<ServiceOrderExpensesCreateResponse>> {
  return post(`/service-orders/${serviceOrderId}/expenses`, {
    accessToken,
    body,
  })
}

export async function fetchExpensesByServiceOrder(
  serviceOrderId: string,
  accessToken: string,
): Promise<ApiResponse<ServiceOrderExpensesGetResponse[]>> {
  return get(`/service-orders/${serviceOrderId}/expenses`, {
    accessToken,
  })
}

export async function approveForPayment(
  serviceOrderId: string,
  accessToken: string,
): Promise<ApiResponse<{ id: string }>> {
  return post(`/service-orders/${serviceOrderId}/approve-payment`, { accessToken })
}

export async function serviceOrderExpensesVoid(
  serviceOrderId: string,
  expenseId: string,
  accessToken: string,
): Promise<ApiResponse> {
  return post(`/service-orders/${serviceOrderId}/expenses/${expenseId}/void`, {
    accessToken,
  })
}

export async function requestForPayment(
  serviceOrderId: string,
  accessToken: string,
): Promise<ApiResponse<{ id: string }>> {
  return post(`/service-orders/${serviceOrderId}/request-payment`, { accessToken })
}
