import React from 'react'

const RadioGroupContext = React.createContext<
  | undefined
  | {
      disabled?: boolean
      name?: string
      value?: string
      onChange?: React.ChangeEventHandler<HTMLInputElement>
    }
>(undefined)

export default RadioGroupContext
