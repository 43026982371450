import type { RefObject } from 'react'

/* Utility to attach an onscroll listener to each node up the tree.
   onscroll events do not bubble for performance reasons. If we want to be able to close the popover on scroll of any
   ancestor, we either need to use capture the scroll at the document level or listen for scroll events on each
   ancestor. Capture is not an option because it prevents us from being able to make a distinction on which element
   scrolls so any scroll event on the page will close the popover, including a scroll in the popover container
   itself. */
export const attachScrollEvent = (node: Node | null, handler: () => void): void => {
  let currNode = node ?? null
  while (currNode) {
    currNode.addEventListener('scroll', handler)
    currNode = currNode.parentNode
  }
}

export const detachScrollEvent = (node: Node | null, handler: () => void): void => {
  let currNode = node ?? null
  while (currNode) {
    currNode.removeEventListener('scroll', handler)
    currNode = currNode.parentNode
  }
}

/* Popovers render inside React Portals. To be able to ensure that clicking in nested popovers don't close their
   parents, we need to maintain an in memory "tree" of popovers so that they can be aware of each other. */
export const popoverTree: Array<Array<RefObject<HTMLElement>>> = []

export const addToTree = (target: Node | null, popover: RefObject<HTMLDivElement>): void => {
  // Look to see if the target of the new popover exists in a popover already
  for (const branch of popoverTree) {
    for (const node of branch) {
      if (node.current && node.current.contains(target)) {
        // Add the popover to the branch of the tree
        branch.push(popover)
        return
      }
    }
  }

  // Start a new tree branch
  popoverTree.push([popover])
}

export const removeFromTree = (popover: RefObject<HTMLElement>): void => {
  // Find the popover in the tree.
  for (let i = 0; i < popoverTree.length; i += 1) {
    const branch = popoverTree[i]
    for (let j = 0; j < branch.length; j += 1) {
      if (branch[j].current === popover.current) {
        // Popover has been found. Remove it from the branch
        branch.splice(j, 1)

        // If the branch now doesn't have any nodes, remove it from the tree
        if (!branch.length) {
          popoverTree.splice(i, 1)
        }
        return
      }
    }
  }
}

export const isClickInSelfOrChild = (
  target: Node | null,
  popover: RefObject<HTMLDivElement>,
): boolean => {
  // Search through all branches for the popover
  for (const branch of popoverTree) {
    let isPopoverInBranch = false
    for (const node of branch) {
      if (node.current === popover.current) {
        // Popover is found. Flag to look at current node and all children
        isPopoverInBranch = true
      }

      if (isPopoverInBranch && node.current && node.current.contains(target)) {
        // Trigger element for the new popover was found in the current popover or one of its children.
        return true
      }
    }
  }

  return false
}
