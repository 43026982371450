import type { FC } from 'react'
import React, { useState } from 'react'

import { useListConversationsQuery } from '@helloextend/extend-api-rtk-query'
import { useHistory } from 'react-router'
import { DataReactTable } from '@helloextend/merchants-ui'
import styled from '@emotion/styled'
import { Add, Button, COLOR } from '@helloextend/zen'
import { bp } from '@helloextend/client-utils/breakpoints'
import { Link } from 'react-router-dom'
import { AdjudicationTabs } from '../components'
import { conversationColumns } from './adjudication-conversation-list.utils'
import { usePermissions } from '../../../../hooks/use-permissions'
import { Permission } from '../../../../lib/permissions'
import { CreateConversationModal } from '../components/create-conversation-modal/create-conversation-modal'

const AdjudicationConversationList: FC = () => {
  const history = useHistory()
  const { data: conversationsData, isFetching, isLoading } = useListConversationsQuery()
  const [isCreateConversationModalVisible, setIsCreateConversationModalVisible] = useState(false)

  const { hasPermission } = usePermissions()
  const isUserAssumedEditorRole = hasPermission(Permission.ManageAdjudication)

  const handleRoute = (route: string): void => {
    history.push(route)
  }

  const handleToggleCreateConversation = (): void => {
    setIsCreateConversationModalVisible(!isCreateConversationModalVisible)
  }

  return (
    <>
      {isCreateConversationModalVisible && (
        <CreateConversationModal
          isVisible={isCreateConversationModalVisible}
          onCancel={handleToggleCreateConversation}
        />
      )}
      <AdjudicationTabs />
      <TopRowContainer>
        <ConversationsMessage data-cy="adjudication-management-conversations-message">
          A conversation is the end-to-end experience customers will go through when they file
          claims via Kaley. The surfacing of the published conversations can be managed in the{' '}
          <ConfigurationsLink
            to="/admin/adjudication-management/conversation-configuration"
            data-cy="adjudication-management-configurations-link"
          >
            conversation configuration settings
          </ConfigurationsLink>
          .
        </ConversationsMessage>
      </TopRowContainer>
      <TopRowButtonContainer>
        {isUserAssumedEditorRole && (
          <ButtonContainer>
            <Button
              data-cy="adjudication-management-conversations-create-button"
              emphasis="medium"
              icon={Add}
              onClick={handleToggleCreateConversation}
              text="Create Conversation"
              tooltip="Create Conversation"
            />
          </ButtonContainer>
        )}
      </TopRowButtonContainer>
      <DataReactTable
        data-cy="adjudication-management-conversation-list"
        isLoading={isLoading || isFetching}
        type="conversations"
        data={conversationsData ?? []}
        columns={conversationColumns(handleRoute)}
        initialState={{
          sortBy: [{ id: 'updatedAt', desc: true }],
          pageSize: 50,
        }}
        pageCount={-1}
        searchMode="fuzzy"
        enableSearch
        hasSearchBar
        searchOptions={[
          {
            label: 'Name',
            value: 'title',
          },
          {
            label: 'Description',
            value: 'description',
          },
        ]}
      />
    </>
  )
}

const TopRowContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 40,
})

const TopRowButtonContainer = styled(TopRowContainer)({
  marginBottom: 24,
  [bp.lg]: {
    justifyContent: 'flex-end',
    marginBottom: -40,
  },
})

const ConversationsMessage = styled.div({
  marginBottom: 28,
  fontSize: 16,
  lineHeight: '24px',
})

const ButtonContainer = styled.div({
  flexShrink: 0,
})

const ConfigurationsLink = styled(Link)({
  color: COLOR.BLUE[700],
})

export { AdjudicationConversationList }
