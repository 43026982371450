export const getLimitText = (max: number, value: string | string[]): string => {
  const counter = max - value.length
  const absCounter = Math.abs(counter)
  const label = Array.isArray(value)
    ? `selection${absCounter === 1 ? '' : 's'}`
    : `character${absCounter === 1 ? '' : 's'}`
  const qualifier = counter >= 0 ? 'remaining' : 'over'
  const text = `${absCounter} ${label} ${qualifier}`
  return text
}
