import type { Action } from '../actions'

export type ApproveReplacementReducerState = string

export const initialState: ApproveReplacementReducerState = ''

export default function approveReplacement(
  state = initialState,
  action: Action,
): ApproveReplacementReducerState {
  switch (action.type) {
    case 'SERVICE_ORDERS_APPROVE_REPLACEMENT_SUCCESS':
      return state
    case 'SERVICE_ORDERS_RESET':
      return initialState
    default:
      return state
  }
}
