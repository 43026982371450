import type { ServiceOrder } from '@helloextend/extend-api-client'
import type { ServiceOrderClaim } from './reducers/by-claim'
import type { ErrorReducerState } from '../types/error'
import type { ReducerState } from './reducers'

type Selector<T> = (state: ReducerState, ...rest: any[]) => T

export const getByClaim: Selector<ServiceOrder[]> = (state, claimId: string) =>
  state.byClaim[claimId] || []

export const getByAllClaims: Selector<ServiceOrderClaim> = (state: ReducerState) =>
  state.byClaim || {}
export const getIsLoading: Selector<boolean> = (state) => state.isLoading
export const getIsFulfilling: Selector<boolean> = (state) => state.isFulfilling
export const getIsSubmittingForPayment: Selector<boolean> = (state) => state.isSubmittingForPayment
export const getServiceOrderStatus: Selector<ServiceOrder['status']> = (state, claimId: string) => {
  const serviceOrder = (state.byClaim[claimId] || [])[0]
  return serviceOrder?.status
}
export const getError: Selector<ErrorReducerState> = (state) => state.error
