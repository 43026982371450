import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../../tokens/colors'
import { ShellContext } from './shell-context'

export interface ShellProps {
  banner?: React.ReactNode
  children?: React.ReactNode
  header?: React.ReactNode
  sidebar?: React.ReactNode
  sidebarWidth?: number | string
  viewportBackground?: string
  viewportPadding?: number | string
  'data-cy'?: string
}

export const Shell = forwardRef<HTMLDivElement, ShellProps>(
  (
    {
      banner,
      children,
      header,
      sidebar,
      sidebarWidth = 200,
      viewportPadding: internalViewportPadding = 24,
      viewportBackground = 'transparent',
      'data-cy': dataCy,
    },
    ref,
  ) => {
    const viewportRef = useRef<HTMLDivElement>(null)
    useImperativeHandle(ref, () => viewportRef.current as HTMLDivElement)
    const [viewportPadding, setViewportPadding] = useState<number | string | null>(null)

    const getScrollableRegionRef = useCallback(() => viewportRef, [])

    return (
      <ShellContext.Provider value={{ getScrollableRegionRef, setViewportPadding }}>
        <StyledShell data-cy={dataCy}>
          <HeaderContainer>
            {banner && <Banner data-cy={dataCy && `${dataCy}:banner`}>{banner}</Banner>}
            {header && <Header data-cy={dataCy && `${dataCy}:header`}>{header}</Header>}
          </HeaderContainer>
          <Page hasSidebar={!!sidebar}>
            {sidebar && (
              <Sidebar width={sidebarWidth} data-cy={dataCy && `${dataCy}:sidebar`}>
                {sidebar}
              </Sidebar>
            )}
            <Viewport
              ref={viewportRef}
              background={viewportBackground}
              padding={viewportPadding === null ? internalViewportPadding : viewportPadding}
              data-cy={dataCy && `${dataCy}:viewport`}
            >
              {children}
            </Viewport>
          </Page>
        </StyledShell>
      </ShellContext.Provider>
    )
  },
)

const StyledShell = styled.div({
  display: 'grid',
  gridTemplateRows: 'min-content minmax(0, 1fr)',
  height: '100%',
})

const HeaderContainer = styled.div()

const Header = styled.div({
  position: 'relative',
  boxShadow: `inset 0 -1px 0 ${COLOR.NEUTRAL[300]}`,
})

const Banner = styled.div({
  position: 'relative',
})

const Page = styled.div<{
  hasSidebar: boolean
}>(({ hasSidebar }) => ({
  display: 'grid',
  ...(hasSidebar && { gridTemplateColumns: 'min-content minmax(0, 1fr)' }),
}))

const Sidebar = styled.div<{ width: number | string }>(({ width }) => ({
  position: 'relative',
  width,
  borderRight: `1px solid ${COLOR.NEUTRAL[300]}`,
  overflow: 'auto',
}))

const Viewport = styled.main<{
  background: string
  padding: number | string
}>(({ background, padding }) => ({
  position: 'relative',
  overflow: 'auto',
  background,
  padding,
}))
