import type { PlanAttribute, PlanAttributesListResponse } from '@helloextend/extend-api-client'
import type { TablePlanAttributesSearch } from '../../../types/plans'

export const toTablePlanAttributesItem = (search: PlanAttribute): TablePlanAttributesSearch => ({
  id: search.id,
  planAttribute: search.id,
})

export const toTablePlanAttributesItems = (
  searchItems?: PlanAttributesListResponse,
): TablePlanAttributesSearch[] => {
  if (!searchItems) return []
  return searchItems.items.map((item: PlanAttribute) => toTablePlanAttributesItem(item))
}
