import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../base-query'
import type { GetReportRequest, Report } from './types'

export const reportsApi = createApi({
  baseQuery,
  reducerPath: 'Reports',
  endpoints: (build) => ({
    getReport: build.query<Report, GetReportRequest>({
      query: ({ storeId, reportId, shouldUseTableau = false }) => ({
        url: `/stores/${storeId}/reports/${reportId}`,
        headers: {
          'content-type': 'application/json',
        },
        params: { shouldUseTableau },
      }),
    }),
  }),
})

export const { useGetReportQuery, useLazyGetReportQuery } = reportsApi
