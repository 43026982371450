import { faker } from '@faker-js/faker/locale/en'
import type { Card as BaseCard, WalletGetResponse } from '@helloextend/extend-api-rtk-query'

export function generateWallet(overrides: Partial<WalletGetResponse> = {}): WalletGetResponse {
  return {
    id: faker.datatype.uuid(),
    createdAt: faker.date.past().getTime(),
    updatedAt: faker.date.recent().getTime(),
    initialValue: { amount: 1000 },
    remainingValue: { amount: 1020 },
    initialBufferedValue: { amount: 1020 },
    metadata: {
      type: 'serviceOrder',
      id: faker.datatype.uuid(),
    },
    userEmail: faker.internet.email(),
    cards: [],
    status: 'active',
    ...overrides,
  }
}

type Card = Omit<BaseCard, 'transation' | 'virtualCardProviderDetails'>

export function generateCard(overrides: Partial<Card>): Card {
  return {
    id: faker.datatype.uuid(),
    walletId: faker.datatype.uuid(),
    createdAt: faker.date.past().getTime(),
    updatedAt: faker.date.recent().getTime(),
    expiresAt: faker.date.recent().getTime(),
    value: { amount: 1000 },
    initializedBy: 'wallet_created',
    status: 'active',
    ...overrides,
  }
}
