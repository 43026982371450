import type { FC } from 'react'
import React from 'react'
import type { FormikErrors, FormikHandlers } from 'formik'
import { FieldArray } from 'formik'
import { Add, Button, Trash } from '@helloextend/zen'
import styled from '@emotion/styled'
import type { SPCartPriceMap } from '@helloextend/extend-api-client/src/models/store'
import { OfferTypeByPriceBandRow } from '../offer-type-by-price-band-row/offer-type-by-price-band-row'
import type { Values } from './schema'
import { initialPriceBandValues } from './schema'

type OfferTypeByPriceBandFormProps = {
  spMapValues: Values
  handleOnChange: FormikHandlers['handleChange']
  errors: FormikErrors<Values>
}

const OfferTypeByPriceBandForm: FC<OfferTypeByPriceBandFormProps> = ({
  spMapValues,
  handleOnChange,
  errors,
}) => {
  const isDeleteButtonDisplayed = spMapValues.priceBands && spMapValues?.priceBands.length > 1

  return (
    <div data-cy="price-bands-form">
      <FieldArray
        name="priceBands"
        render={({ push, remove }) => (
          <>
            {spMapValues?.priceBands?.map((value, index) => (
              <>
                <RowContainer
                  key={(value as SPCartPriceMap).toString()}
                  isDeleteDisplayed={isDeleteButtonDisplayed}
                >
                  <OfferTypeByPriceBandRow
                    index={index}
                    data={value as SPCartPriceMap}
                    errors={errors}
                    handleOnChange={handleOnChange}
                    isLabelDisplayed={index === 0}
                  />
                  {isDeleteButtonDisplayed && (
                    <ButtonContainer
                      isLabelHidden={index > 0}
                      isError={!!errors?.priceBands?.[index]}
                    >
                      <Button
                        icon={Trash}
                        onClick={() => remove(index)}
                        emphasis="low"
                        data-cy={`delete-row-${index}`}
                      />
                    </ButtonContainer>
                  )}
                </RowContainer>
              </>
            ))}
            <Button
              text="Add Price Band"
              data-cy="add-price-band-button"
              icon={Add}
              emphasis="low"
              onClick={() => push(initialPriceBandValues())}
            />
          </>
        )}
      />
    </div>
  )
}

const ButtonContainer = styled.div<{ isLabelHidden?: boolean; isError?: boolean }>(
  ({ isLabelHidden, isError }) => ({
    ...(!isLabelHidden &&
      !isError && {
        marginTop: '22px',
      }),
    ...(isLabelHidden &&
      isError && {
        marginTop: '-27px',
      }),
    display: 'flex',
    alignSelf: 'center',
  }),
)

const RowContainer = styled.div<{ isDeleteDisplayed?: boolean }>(({ isDeleteDisplayed }) => ({
  marginBottom: 16,
  columnGap: 8,
  ...(isDeleteDisplayed && {
    display: 'grid',
    gridTemplateColumns: '3fr .1fr',
  }),
}))

export { OfferTypeByPriceBandForm }
