import * as Yup from 'yup'
import type { ClaimNoteType } from '@helloextend/extend-api-client/src/models/claim-note'

export const NOTETEXT_MAX = 3000
const formSchema = Yup.object()
  .shape({
    type: Yup.string<ClaimNoteType>().default('basic'),
    text: Yup.string().default('').required('Required'),
    followUpDate: Yup.number(),
  })
  .defined()

type Values = Yup.InferType<typeof formSchema>

export { formSchema }
export type { Values }
