import * as fetchActions from './fetch'
import type { ExtractActions } from '../../types/utility'

export type Action = ExtractActions<typeof fetchActions>

export type ActionType = Action['type']

const actions = {
  ...fetchActions,
}

export { actions }
