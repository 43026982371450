import type { FC } from 'react'
import React, { useState } from 'react'
import { Button } from '@helloextend/zen'
import { Subheading } from '@helloextend/zen/src/tokens/typography'
import styled from '@emotion/styled'
import { AddLocationForm } from './add-location-form'

type AddServicingLocationProps = {
  locationIndex: number
  servicerId: string
}

const AddServicingLocation: FC<AddServicingLocationProps> = ({ locationIndex, servicerId }) => {
  const [isAddLocationBtnVisible, setIsAddLocationBtnVisible] = useState(true)

  const toggleAddLocationVisibility = (): void => {
    setIsAddLocationBtnVisible(!isAddLocationBtnVisible)
  }

  return (
    <>
      {isAddLocationBtnVisible && (
        <Button text="Add Location" emphasis="medium" onClick={toggleAddLocationVisibility} />
      )}
      {!isAddLocationBtnVisible && (
        <>
          <SubheadingWrapper>Location {locationIndex}</SubheadingWrapper>
          <AddLocationForm
            toggleFormVisibility={toggleAddLocationVisibility}
            servicerId={servicerId}
          />
        </>
      )}
    </>
  )
}

const SubheadingWrapper = styled(Subheading)({
  marginBottom: 16,
})

export { AddServicingLocation }
