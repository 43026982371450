import type { FC } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import {
  usePopover,
  Popover,
  MenuButtonItem,
  Button,
  ToastColor,
  ToastDuration,
  useToaster,
} from '@helloextend/zen'
import { Notes, MessageBlock } from '@helloextend/zen/src/tokens/icons'
import { useDispatch, batch, useSelector } from 'react-redux'
import type { RootState } from '../../../../../reducers'
import {
  setIsEditorPanelVisible,
  setIsReusableThreadPickerVisible,
  setPlaceholderMessageBlock,
  setSelectedMessageBlock,
} from '../../../../../store/slices/amp-slice'
import { MessageBlockTypes } from '../../utils'
import { useAddMessageBlock } from './use-add-message-block'
import { getSelectedMessageBlockIndex } from '../../../../../reducers/selectors'

type ConversationAppendButtonsProps = {
  description: string
  isAtThreadTermination?: boolean
}

const ConversationAppendButtons: FC<ConversationAppendButtonsProps> = ({
  description,
  isAtThreadTermination = true,
}) => {
  const dispatch = useDispatch()
  const { toast } = useToaster()
  const { isPresent, triggerRef, popoverRef, triggerBoundingBox, toggle } =
    usePopover<HTMLButtonElement>()

  const selectedMessageBlockIndex = useSelector((state: RootState) =>
    getSelectedMessageBlockIndex(state),
  )

  const { handleAddItem, addingMessageBlockError } = useAddMessageBlock(toggle)

  useEffect(() => {
    if (addingMessageBlockError !== null) {
      toast({
        message: 'An error occurred while adding a message block',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }, [addingMessageBlockError, toast])

  const handleAddThread = (): void => {
    batch(() => {
      dispatch(setPlaceholderMessageBlock(selectedMessageBlockIndex))
      dispatch(setSelectedMessageBlock(null))
      dispatch(setIsEditorPanelVisible(true))
      dispatch(setIsReusableThreadPickerVisible(true))
    })
  }
  const handleAddMessageBlock = (): void => {
    toggle()
  }

  return (
    <Container data-cy={`amp-${description}-ctas`}>
      {isAtThreadTermination && (
        <Button
          text="Add Thread"
          size="small"
          emphasis="medium"
          icon={Notes}
          onClick={handleAddThread}
          data-cy={`add-thread-button-${description}`}
        />
      )}
      <Button
        ref={triggerRef}
        text="Add Message Block"
        size="small"
        emphasis="medium"
        icon={MessageBlock}
        onClick={handleAddMessageBlock}
        data-cy={`add-message-block-button-${description}`}
      />
      <Popover ref={popoverRef} isPresent={isPresent} triggerBoundingBox={triggerBoundingBox}>
        <MenuItemWrapper>
          {Object.values(MessageBlockTypes).map((blockType) => (
            <MenuButtonItem
              key={blockType}
              onClick={handleAddItem}
              data-cy={`${blockType.toLowerCase().replace(' ', '-')}:popover-item`}
            >
              <Text>{blockType}</Text>
            </MenuButtonItem>
          ))}
        </MenuItemWrapper>
      </Popover>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  gap: 8,
  marginTop: 12,
  marginLeft: 44,
})

const MenuItemWrapper = styled.div({
  width: 184,
  display: 'flex',
  flexDirection: 'column',
  padding: 8,
})

const Text = styled.p({
  fontWeight: 400,
  size: 14,
  lineHeight: '20px',
  padding: 0,
  margin: 0,
})
export { ConversationAppendButtons }
