import type { FC } from 'react'
import React, { useState, useCallback } from 'react'
import styled from '@emotion/styled'
import { Button } from '@helloextend/zen'
import { Add as AddIcon } from '@helloextend/zen/src/tokens/icons'
import { PageHeader } from '../../../../components/page-header'
import { CreateAccountModal } from '../components/create-account-modal/create-account-modal'
import { AccountsDataTable } from './accounts-data-table/accounts-data-table'

const Accounts: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const toggleModal = useCallback((): void => {
    setIsModalVisible(!isModalVisible)
  }, [isModalVisible])

  return (
    <>
      <HeaderContainer>
        <PageHeader title="Merchant Accounts" />
        <Button
          text="Create Account"
          onClick={toggleModal}
          data-cy="create-account-button"
          emphasis="medium"
          icon={AddIcon}
        />
      </HeaderContainer>
      <CreateAccountModal isVisible={isModalVisible} onClickClose={toggleModal} />
      <AccountsDataTable />
    </>
  )
}

const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export { Accounts }
