import type { VFC } from 'react'
import React from 'react'
import type { ButtonOrLinkButtonProps, ButtonProps, LinkButtonProps } from './types'
import { LinkButton } from './link-button'
import { Button } from './button'

export const ButtonOrLinkButton: VFC<ButtonOrLinkButtonProps> = (props) =>
  Object.prototype.hasOwnProperty.call(props, 'to') ? (
    <LinkButton {...(props as LinkButtonProps)} />
  ) : (
    <Button {...(props as ButtonProps)} />
  )
