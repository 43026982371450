import { Button } from '@helloextend/merchants-ui'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import type { FormikHelpers } from 'formik'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { COLOR } from '@helloextend/client-branding'
import type { RootState } from '../../../../reducers'
import Input from '../../../../components/input/index'
import * as selectors from '../../../../reducers/selectors'
import { clearSuccessMessage, update } from '../../../../actions/users'

const schema = Yup.object()
  .shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email required'),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

interface SP {
  user: ReturnType<typeof selectors.getUserInformation>
}

interface DP {
  onSubmitSaveProfile: typeof update
  onUnmount: typeof clearSuccessMessage
}

interface OP {
  isDisabled: {
    firstName: boolean
    lastName: boolean
    email: boolean
    save: boolean
    cancel: boolean
  }
  isLoading: boolean
  setHasChangesMade: (value: boolean) => void
  profileSuccessMessage?: string | null
  isButtonsVisible?: boolean
  'data-cy'?: string
}

type GeneralInfoFormProps = SP & DP & OP

const Component: FC<GeneralInfoFormProps> = ({
  isLoading,
  isDisabled,
  profileSuccessMessage,
  isButtonsVisible,
  user,
  onSubmitSaveProfile,
  setHasChangesMade,
  onUnmount,
  'data-cy': dataCy,
}) => {
  const { touched, errors, dirty, values, handleChange, handleBlur, handleSubmit, handleReset } =
    useFormik<Values>({
      enableReinitialize: true,
      validationSchema: schema,
      initialValues: {
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
        email: user.email ?? '',
      },
      onSubmit: (vals: Values, helpers: FormikHelpers<Values>): void => {
        onSubmitSaveProfile({
          firstName: vals.firstName,
          lastName: vals.lastName,
        })

        helpers.setSubmitting(false)
        helpers.resetForm({ values: vals })
      },
    })

  const formEnabledButtons = Object.entries(isDisabled)
    .filter(([_name, value]) => !value)
    .map(([name]) => name) as Array<keyof Values>

  const areButtonsDisabled =
    isLoading ||
    formEnabledButtons.map((name) => touched[name]).every((value) => !value) ||
    Boolean(formEnabledButtons.map((name) => errors[name]).find(Boolean))

  useEffect(() => {
    setHasChangesMade(Boolean(Object.values(touched).find(Boolean) && dirty))
    return () => {
      setHasChangesMade(false)
      onUnmount()
    }
  }, [setHasChangesMade, onUnmount, touched, dirty])

  return (
    <GeneralInfoFormWrapper onSubmit={handleSubmit} data-cy={dataCy}>
      <InputWrapper>
        <Input
          data-cy="general-info-first-name"
          label="First name"
          name="firstName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.firstName}
          invalid={touched.firstName && !!errors.firstName}
          errorMessage={errors.firstName}
          isDisabled={isLoading || isDisabled.firstName}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          data-cy="general-info-last-name"
          label="Last name"
          name="lastName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.lastName}
          invalid={touched.lastName && !!errors.lastName}
          errorMessage={errors.lastName}
          isDisabled={isLoading || isDisabled.lastName}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          data-cy="general-info-email"
          label="Email address"
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          invalid={touched.email && !!errors.email}
          errorMessage={errors.email}
          isDisabled={isLoading || isDisabled.email}
        />
      </InputWrapper>
      {isButtonsVisible && (
        <ButtonGroup>
          <ButtonWrapper>
            <Button
              dataCy="general-info-save-button"
              type="submit"
              text="Save"
              size="sm"
              disabled={isDisabled.save || areButtonsDisabled}
              loading={isLoading}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              dataCy="general-info-cancel-button"
              type="reset"
              text="Cancel"
              kind="secondary"
              size="sm"
              disabled={isDisabled.cancel || areButtonsDisabled}
              onClick={handleReset}
            />
          </ButtonWrapper>
        </ButtonGroup>
      )}
      {profileSuccessMessage && <ProfileUpdatedText>{profileSuccessMessage}</ProfileUpdatedText>}
    </GeneralInfoFormWrapper>
  )
}

const ProfileUpdatedText = styled.p({
  color: COLOR.LIME_GREEN,
  fontSize: 16,
  flex: '1 0 100%',
  margin: '16px 0',
})

const InputWrapper = styled.div({
  width: '45%',
  paddingRight: 32,
  paddingBottom: 12,
})

const ButtonGroup = styled.div({
  breakBefore: 'left',
  display: 'flex',
})

const ButtonWrapper = styled.div({
  paddingRight: 24,
  paddingTop: 24,
})

const GeneralInfoFormWrapper = styled.form({
  display: 'flex',
  width: '70%',
  flexWrap: 'wrap',
  flexDirection: 'column',
})

const GeneralInfoForm = connect(
  (state: RootState): SP => ({
    user: selectors.getUserInformation(state),
  }),
  {
    onSubmitSaveProfile: update,
    onUnmount: clearSuccessMessage,
  },
)(Component)

export { Component, GeneralInfoForm, GeneralInfoFormProps, Values }
