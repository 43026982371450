import { faker } from '@faker-js/faker/locale/en'
import type { ShippingProtectionPlan } from '@helloextend/extend-api-client'

export function generateShippingProtectionPlan(
  overrides: DeepPartial<ShippingProtectionPlan> = {},
): ShippingProtectionPlan {
  return {
    id: faker.datatype.uuid(),
    version: 1,
    premiumRate: faker.datatype.number({ max: 100, precision: 0.1 }),
    premiumMin: faker.datatype.number(100),
    shipCoverageMin: 500,
    shipCoverageMax: 5000,
    name: faker.commerce.productName(),
    termsId: faker.random.word(),
    termsVersion: 1,
    obligor: 'Extend',
    productCategory: 'Electronics',
    active: true,
    programType: 'Test Program',
    insuranceProgram: 'Test Insurance Program',
    createdAt: faker.date.past().getTime(),
    updatedAt: faker.date.past().getTime(),
    planParentCreatedAt: faker.date.past().getTime(),
    planStartDate: faker.date.past().getTime(),
    planEndDate: faker.date.future().getTime(),
    schemaVersion: faker.datatype.number(10),
    currencyCode: 'USD',
    allowedRegions: ['US'],
    blockedSubdivisions: [],
    ...overrides,
  }
}
