import type { FC } from 'react'
import React from 'react'
import { useHistory, useParams } from 'react-router'
import styled from '@emotion/styled'
import { COLOR, Spinner } from '@helloextend/zen'
import { usePrevious } from '@helloextend/client-hooks'
import { useGetContractQuery } from '@helloextend/extend-api-rtk-query'
import { FileClaimDetails, FileClaimError } from './components'
import { getClaimDetailUrl, getContractDetailUrl } from '../../../utils/route-helper'
import { useClaimsSearch } from '../../../hooks/use-claims-search'

const FileClaimResults: FC = () => {
  const { contractId, claimId } = useParams<{ contractId: string; claimId: string }>()
  const history = useHistory()
  const contractDetailsUrl = getContractDetailUrl(contractId)
  const {
    data,
    isLoading: isClaimLoading,
    isError: searchClaimsError,
  } = useClaimsSearch({
    queryParams: {
      containsClaimId: claimId,
      searchVersion: '2',
    },
  })
  const { data: contractData, isLoading: isContractLoading } = useGetContractQuery({
    contractId,
  })
  const [claim] = data?.items || []
  const prevIsClaimsLoading = usePrevious<boolean>(isClaimLoading)

  const handleOnClickClaimDetails = (): void => {
    history.push(claim?.id ? getClaimDetailUrl(claimId) : contractDetailsUrl)
  }

  const handleOnClickContractDetails = (): void => {
    history.push(contractDetailsUrl)
  }

  if (isClaimLoading || isContractLoading) {
    return (
      <LoadingContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </LoadingContainer>
    )
  }

  if (prevIsClaimsLoading && !isClaimLoading && !claim) {
    return (
      <FileClaimError
        handleOnClickContractDetails={handleOnClickContractDetails}
        contractId={contractId}
        claimId={claimId}
        error={JSON.stringify(searchClaimsError)}
      />
    )
  }

  return (
    <FileClaimDetails
      handleOnClickClaimDetails={handleOnClickClaimDetails}
      handleOnClickContractDetails={handleOnClickContractDetails}
      claim={claim}
      contract={contractData}
    />
  )
}

const LoadingContainer = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export { FileClaimResults }
