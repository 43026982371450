import React from 'react'
import styled from '@emotion/styled'
import type { BadgeDescriptionItem } from '@helloextend/merchants-ui'
import { Badge, Button, COLOR } from '@helloextend/zen'
import type { ColumnDef, CellContext, FilterDef } from '@helloextend/zen'
import { Visibility } from '@helloextend/zen/src/tokens/icons'
import type {
  ThreadListItem,
  ThreadStatus,
} from '@helloextend/extend-api-rtk-query/src/conversations'
import { date as datefmt } from '@helloextend/client-utils'
import { formatDateWithTimezone } from '../../../../utils/date-formatting'
import { ThreadEditActionMenu } from '../components/thread-edit-action-menu'
import { ThreadBadgeDescriptions } from '../components/BadgeDescriptions/thread-badge-descriptions'

const { formatToMilliseconds } = datefmt

export const getBadgeText = (status: ThreadStatus): string => {
  switch (status) {
    case 'draft':
      return 'Draft'
    case 'pending_changes':
      return 'Changes Pending'
    case 'published':
      return 'Published'
    default:
      return 'Unknown'
  }
}

const threadStatusDetails: Record<ThreadStatus, BadgeDescriptionItem> = {
  draft: {
    badgeText: 'Draft',
    description: 'Draft thread is open to changes.',
    badgeColor: 'yellow',
  },
  pending_changes: {
    badgeText: 'Changes Pending',
    description:
      'Changes Pending thread will not be available for assigning to conversations unless it is published. \nIf this thread is currently assigned to conversations, the assigned thread is the latest published version. Go to thread preview to view details.',
    badgeColor: 'yellow',
  },
  published: {
    badgeText: 'Published',
    description: 'Published thread is available for assigning to conversations.',
    badgeColor: 'green',
  },
}

export const threadColumns = (
  handleRoute: (route: string) => void,
  handleEdit: (thread: ThreadListItem) => void,
  handleConfigureRules: (thread: ThreadListItem) => void,
  handleThreadExportClick: (thread: ThreadListItem) => void,
): Array<ColumnDef<ThreadListItem>> => {
  return [
    {
      id: 'title',
      label: 'Name',
      maxWidth: 400,
      renderCell: ({ row }: CellContext<ThreadListItem, string>) => (
        <CellText>{row.original.title}</CellText>
      ),
      search: 'explicit',
    },
    {
      id: 'type',
      label: 'Thread Type',
      renderCell: ({ row }: CellContext<ThreadListItem, string>): JSX.Element => (
        <CellText>{row.original.type}</CellText>
      ),
    },
    {
      id: 'status',
      label: 'Status',
      information: <ThreadBadgeDescriptions descriptions={Object.values(threadStatusDetails)} />,
      renderCell: ({ row }: CellContext<ThreadListItem, string>): JSX.Element => {
        const { status, id } = row.original
        const badgeColor = status === 'published' ? 'green' : 'yellow'
        return (
          <Badge
            color={badgeColor}
            emphasis="medium"
            text={getBadgeText(status)}
            data-cy={`badge-${id}`}
          />
        )
      },
    },
    {
      id: 'updatedAt',
      label: 'Last Update',
      renderCell: ({ row }: CellContext<ThreadListItem, string>): JSX.Element => {
        const { updatedAt, editedBy } = row.original
        return (
          <CellText>
            {updatedAt &&
              `${formatDateWithTimezone(formatToMilliseconds(updatedAt))} by ${editedBy}`}
          </CellText>
        )
      },
      isSortable: true,
    },
    {
      id: 'buttons',
      align: 'right',
      renderCell: ({ row }: CellContext<ThreadListItem, string>): JSX.Element => {
        const { id } = row.original
        const thread = row.original
        const handlePreviewClick = (): void => {
          handleRoute(`/admin/adjudication-management/threads/${id}/preview`)
        }
        return (
          <ButtonsWrapper>
            <Button
              tooltip="Preview"
              size="small"
              color="blue"
              emphasis="low"
              icon={Visibility}
              onClick={handlePreviewClick}
              data-cy={`preview-${id}`}
            />
            <ThreadEditActionMenu
              thread={thread}
              handleEdit={handleEdit}
              handleConfigureRules={handleConfigureRules}
              handleThreadExport={handleThreadExportClick}
            />
          </ButtonsWrapper>
        )
      },
    },
  ]
}

export const filterDefs: FilterDef[] = [
  {
    type: 'group',
    label: 'Filters',
    filterDefs: [
      {
        id: 'type',
        label: 'Type',
        type: 'select',
        options: [
          { display: 'Adjudication', value: 'adjudication' },
          { display: 'Intro', value: 'intro' },
          { display: 'Outro', value: 'outro' },
          { display: 'Troubleshooting', value: 'troubleshooting' },
        ],
        isMultiSelect: true,
      },
      {
        id: 'status',
        label: 'Status',
        type: 'select',
        options: [
          { display: 'Published', value: 'published' },
          { display: 'Draft', value: 'draft' },
          { display: 'Changes Pending', value: 'pending_changes' },
        ],
        isMultiSelect: true,
      },
    ],
  },
]

const ButtonsWrapper = styled.div({
  display: 'flex',
  gap: 8,
})

const CellText = styled.p({
  fontSize: 12,
  lineHeight: '24px',
  color: COLOR.NEUTRAL[1000],
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
})
