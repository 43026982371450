import { createActionFailure } from '../../factories'

export const fetch = (termId: string, version: string) =>
  ({
    type: 'PLAN_TERMS_FETCH',
    payload: { termId, version },
  } as const)

export const fetchRequest = () =>
  ({
    type: 'PLAN_TERMS_FETCH_REQUEST',
  } as const)

export const fetchSuccess = (url: string) =>
  ({
    type: 'PLAN_TERMS_FETCH_SUCCESS',
    payload: url,
  } as const)

export const fetchFailure = createActionFailure('PLAN_TERMS_FETCH_FAILURE')
