import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../tokens/colors'
import type { AccentIconProps as AccentIconPathProps } from '../../tokens/accent-icons/accent-icon-props'

export interface AccentIconProps {
  icon: AccentIconPathProps
  color?: string
  'data-cy'?: string
}

export const AccentIcon: FC<AccentIconProps> = ({
  icon,
  color = COLOR.BLACK,
  'data-cy': dataCy,
}) => {
  const path = icon.d
  return (
    <svg
      width={64}
      height={64}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={{ display: 'inline-block', flexGrow: 0, flexShrink: 0 }}
      data-cy={dataCy}
    >
      {Array.isArray(path) ? (
        path.map((p: string) => <Path color={color} d={p} key={p} />)
      ) : (
        <Path color={color} d={path} />
      )}
    </svg>
  )
}

const Path = styled.path<{
  color: string
  d: string
}>(({ color, d }) => ({
  fill: color,
  d: `path("${d}")`,
}))
