import type { CurrencyCode } from '@helloextend/extend-api-client'
import type { Sku } from '@helloextend/extend-api-client/src/models/sku'
import type { SkuCreateRequest } from '@helloextend/extend-api-rtk-query'
import type { Values } from '../pages/admin/sku/schema'

const skuPropertiesMapper = (values: Values): SkuCreateRequest => {
  return {
    id: values.id,
    name: values.name,
    activeFrom: values.activeFrom,
    activeTo: values.activeTo,
    program: values.program,
    subProgram: values.subprogram,
    coverageType: values.coverageType,
    term: Number(values.term),
    serviceType: values.serviceType,
    lineOfBusiness: values.lineOfBusiness,
    currencyCode: values.currencyCode as CurrencyCode,
    productCondition: values.productCondition,
    purchasePriceOfProduct: values.purchasePriceOfProduct,
    limitOfLiabilityBasedUpon: values.limitOfLiabilityBasedUpon,
    paymentModel: values.paymentModel,
    cancellation: values.cancellation,
    obligor: values.obligor,
    insuranceProgramId: values.insuranceProgramId,
    priceBandLow: Number(values.priceBandLow),
    priceBandHigh: Number(values.priceBandHigh),
    isDeductible: values.isDeductible === 'YES',
    deductibleAmount: Number(values.deductibleAmount),
    lossCost: Number(values.lossCost),
    underwritingProfit: Number(values.underwritingProfit) ?? 0,
    obligorFee: Number(values.obligorFee),
    reserve: Number(values.reserve),
    premium: Number(values.premium),
    targetLossRatio: Number(values.targetLossRatio),
    obligorFeeRate: Number(values.obligorFeeRate) ?? 0,
    isActive: values.isActive === 'yes',
  }
}

const skuFormInitialValues = (skuDetails: Sku): Values => ({
  id: skuDetails.id ?? '',
  name: skuDetails.name ?? '',
  activeFrom: skuDetails.activeFrom ?? 0,
  activeTo: skuDetails.activeTo ?? undefined,
  program: skuDetails.program ?? '',
  subprogram: skuDetails.subProgram ?? '',
  coverageType: skuDetails.coverageType ?? '',
  term: skuDetails.term ?? null,
  serviceType: skuDetails.serviceType ?? '',
  lineOfBusiness: skuDetails.lineOfBusiness ?? 'serviceContract',
  currencyCode: skuDetails.currencyCode ?? undefined,
  productCondition: skuDetails.productCondition ?? '',
  purchasePriceOfProduct: skuDetails.purchasePriceOfProduct ?? '',
  limitOfLiabilityBasedUpon: skuDetails.limitOfLiabilityBasedUpon ?? '',
  paymentModel: skuDetails.paymentModel ?? '',
  cancellation: skuDetails.cancellation ?? '',
  obligor: skuDetails.obligor ?? '',
  insuranceProgramId: skuDetails.insuranceProgramId ?? '',
  priceBandLow: skuDetails.priceBandLow ?? null,
  priceBandHigh: skuDetails.priceBandHigh ?? null,
  isDeductible: skuDetails.isDeductible ? 'yes' : 'no',
  deductibleAmount: skuDetails.deductibleAmount ?? undefined,
  lossCost: skuDetails.lossCost ?? null,
  underwritingProfit: skuDetails.underwritingProfit ?? null,
  obligorFee: skuDetails.obligorFee ?? null,
  reserve: skuDetails.reserve ?? null,
  premium: skuDetails.premium ?? null,
  targetLossRatio: skuDetails.targetLossRatio ?? null,
  obligorFeeRate: skuDetails.obligorFeeRate ?? null,
  isActive: skuDetails.isActive ? 'yes' : 'no',
})

export { skuPropertiesMapper, skuFormInitialValues }
