import type { MeasurementArgs, ButtonSize } from './types'
import { IconSize } from '../icon'

export const buttonMeasurements: Record<ButtonSize, MeasurementArgs> = {
  xsmall: {
    lineHeight: '16px',
    fontSize: '12px',
    iconSize: IconSize.xsmall,
    gap: '4px',
  },
  small: {
    lineHeight: '24px',
    fontSize: '14px',
    iconSize: IconSize.small,
    gap: '4px',
  },
  regular: {
    lineHeight: '24px',
    fontSize: '16px',
    iconSize: IconSize.medium,
    gap: '4px',
  },
  large: {
    lineHeight: '28px',
    fontSize: '20px',
    iconSize: IconSize.large,
    gap: '8px',
  },
}
