import type { Action } from '../actions'

export type ChangePasswordMessageReducerState = string | null

const initialState: ChangePasswordMessageReducerState = null

export default function changePasswordMessage(
  state = initialState,
  action: Action,
): ChangePasswordMessageReducerState {
  switch (action.type) {
    case 'CHANGE_PASSWORD_SUCCESS':
      return action.payload
    case 'CHANGE_PASSWORD':
      return null
    default:
      return state
  }
}
