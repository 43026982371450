import { pipe } from 'lodash/fp'
import type { ParseResult } from 'papaparse'
import Papa from 'papaparse'
import type { CsvImportParseResults } from '../../types/skus'
import type { PlanAttributesMap } from '../plan-attributes-dropdown-mapping'
import { formatPipeErrorMessage } from './csv-validation'
import {
  validateObligorFeeRateAmountColumn,
  validateReserveColumn,
  validatePremiumColumn,
} from './validate-autocalculated-columns'
import { validateActivateTo, validateDateColumns } from './validate-date-columns'
import { validateDropdownColumns } from './validate-dropdown-columns'
import { validateHeader } from './validate-header'
import { validateNameColumn } from './validate-name-column'
import { validateNumericColumns } from './validate-numeric-columns'
import { validateTaxonomyDropdown } from './validate-taxonomy-dropdown'

type PipeResults = {
  errors: string[]
  warnings: string[]
  rowData: string[]
}

async function parseSkuCsv(
  file: File,
  { taxonomyAttributes }: { taxonomyAttributes: Partial<PlanAttributesMap> },
): Promise<CsvImportParseResults> {
  const parseResults: CsvImportParseResults = {
    data: [],
    errors: [],
    warnings: [],
  }
  let isHeaderScanned = false
  let currRowStep = 0

  const pipeResultsToParseResults = (pipeResults: PipeResults): void => {
    let isError = false
    if (pipeResults.errors.length) {
      const errorMessage = formatPipeErrorMessage(currRowStep, pipeResults.errors)
      parseResults.errors.push(errorMessage)
      isError = true
    }

    if (pipeResults.warnings.length) {
      const warningMessage = formatPipeErrorMessage(currRowStep, pipeResults.warnings)
      parseResults.warnings.push(warningMessage)
    }

    // allow data to be added to parseResults if there are warnings but not if there are errors
    if (!isError) {
      parseResults.data.push(pipeResults.rowData)
    }
  }

  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      step: (result: ParseResult<string>, parser): void => {
        // only apply this validation to the header (first row)
        currRowStep += 1
        if (!isHeaderScanned) {
          isHeaderScanned = true
          const headerValidationErrors = validateHeader(result.data)
          if (headerValidationErrors.length > 0) {
            headerValidationErrors.forEach((error) => parseResults.errors.push(error))
            resolve(parseResults)
            parser.abort()
          }
        } else {
          const validationResults = pipe(
            validateNumericColumns,
            validateNameColumn,
            validateDropdownColumns,
            validateDateColumns,
            validateActivateTo,
            validateReserveColumn,
            validateObligorFeeRateAmountColumn,
            validatePremiumColumn,
            (pipeResults) =>
              validateTaxonomyDropdown(pipeResults, 'program', taxonomyAttributes?.program),
            (pipeResults) =>
              validateTaxonomyDropdown(pipeResults, 'subprogram', taxonomyAttributes?.sub_program),
          )({
            errors: [],
            warnings: [],
            rowData: result.data,
          })

          pipeResultsToParseResults(validationResults)
        }
      },
      complete() {
        resolve(parseResults)
      },
      error(error) {
        reject(error)
      },
    })
  })
}

export { parseSkuCsv }
