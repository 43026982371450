export const mockComponentGlobalEvents = (): Record<
  keyof WindowEventMap,
  EventListenerOrEventListenerObject
> => {
  const mountedEvents: Record<string, EventListenerOrEventListenerObject> = {}
  jest.spyOn(window, 'addEventListener').mockImplementation((event, callback) => {
    mountedEvents[event] = callback
  })
  return mountedEvents
}
