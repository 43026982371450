import type { UserGrant } from '@helloextend/extend-api-rtk-query'
import { isERNBoundToAllAccounts, parseTenancyErn } from '../lib/tenancy-ern'

const legacyMerchantRoleMapping = {
  planmanageradmin: 'Plan Manager Admin',
  user: 'User',
  merchantagent: 'Merchant Agent',
  agent: 'Agent',
  storeadmin: 'Store Admin',
  salesassociate: 'Sales Associate',
}

const legacyInternalRoleMapping = {
  superadmin: 'Super Admin',
  admin: 'Admin',
  conversationseditor: 'Conversations Editor',
  conversationsviewer: 'Conversations Viewer',
}

export const UserRoleMapping = {
  conversationsadmin: 'Conversations Admin',
  ExtendAdmin: 'Extend Admin',
  ProvisioningManager: 'Provisioning Manager',
  MerchantSuccess: 'Merchant Success',
  CustomerSupportAgent: 'Customer Support Agent',
  WarrantyMappingSpecialist: 'Warranty Mapping Specialist',
  PlanManager: 'Plan Manager',
  PlanAnalyst: 'Plan Analyst',
  FinanceAnalyst: 'Finance Analyst',
  ...legacyInternalRoleMapping,
  ...legacyMerchantRoleMapping,
}

export type UserRole = keyof typeof UserRoleMapping

export const mapUserType = (userType: UserRole): string => {
  return UserRoleMapping[userType]
}

export const getUserRolesFromGrants = (
  grants: UserGrant[],
  roleFilter: 'internal' | 'merchant',
  accountId?: string,
): Array<{ role: UserRole; display: string }> => {
  const rolesFromGrants = filterGrantsByAccount(grants, accountId)
    .filter((it) => {
      return roleFilter === 'merchant'
        ? isLegacyMerchantRole(it.role as UserRole)
        : isLegacyInternalRole(it.role as UserRole)
    })
    .map((grant) => {
      const role = grant.role as UserRole
      return { role, display: mapUserType(role) }
    })

  return rolesFromGrants
}

export const filterGrantsByAccount = (grants: UserGrant[], accountId?: string): UserGrant[] => {
  return grants.filter((grant) => {
    if (accountId) {
      return parseTenancyErn(grant.ern)?.accountResourceId === accountId
    }

    return isERNBoundToAllAccounts(grant.ern)
  })
}

export const isLegacyMerchantRole = (role: string): boolean => {
  return Object.keys(legacyMerchantRoleMapping).includes(role)
}

export const isLegacyInternalRole = (role: string): boolean => {
  return Object.keys(legacyInternalRoleMapping).includes(role)
}

export const enterpriseOptions = [
  {
    display: 'Extend Portal Admin',
    value: 'ExtendPortalAdmin',
  },
  {
    display: 'Provisioning Manager',
    value: 'ProvisioningManager',
  },
  {
    display: 'Merchant Success',
    value: 'MerchantSuccess',
  },
  {
    display: 'Solution Engineer',
    value: 'SolutionEngineer',
  },
  {
    display: 'Customer Support Agent',
    value: 'CustomerSupportAgent',
  },
  {
    display: 'Warranty Mapping Specialist',
    value: 'WarrantyMappingSpecialist',
  },
  {
    display: 'Plan Manager',
    value: 'PlanManager',
  },
  {
    display: 'Plan Analyst',
    value: 'PlanAnalyst',
  },
  {
    display: 'Finance Analyst',
    value: 'FinanceAnalyst',
  },
]

export const legacyInternalOptions = [
  {
    display: 'Super Admin',
    value: 'superadmin',
  },
  {
    display: 'Admin',
    value: 'admin',
  },
  {
    display: 'Conversations Viewer',
    value: 'conversationsviewer',
  },
  {
    display: 'Conversations Editor',
    value: 'conversationseditor',
  },
]
