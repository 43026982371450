import { createApi } from '@reduxjs/toolkit/query/react'
import type { Consumer } from '@helloextend/extend-api-client'
import { baseQuery } from '../base-query'
import type { UpdateConsumerRequest } from './types'

export const consumersApi = createApi({
  baseQuery,
  reducerPath: 'consumers',
  tagTypes: ['consumers'],
  endpoints: (build) => ({
    getConsumer: build.query<Consumer, string>({
      query: (consumerId: string) => ({
        url: `auth/consumers/${consumerId}`,
      }),
      providesTags: (_, _err, consumerId) => [{ type: 'consumers', id: consumerId }],
    }),
    updateConsumer: build.mutation<Consumer, UpdateConsumerRequest>({
      query: ({ consumerId, data }) => {
        return {
          url: `auth/consumers/${consumerId}/update`,
          method: 'PUT',
          body: data,
        }
      },
      invalidatesTags: ['consumers'],
    }),
  }),
})

export const { useGetConsumerQuery, useUpdateConsumerMutation } = consumersApi
