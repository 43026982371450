import type { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/client-utils'
import { authSelectors } from '@helloextend/core-api-redux'
import logger from '@helloextend/client-logger'
import { support as supportActions } from '../actions'

type Action = ReturnType<typeof supportActions.createSupport>

export function* createSupportRequest(action: Action): SagaIterator {
  try {
    yield put(supportActions.createSupportRequest())

    const { name, email, message, phoneNumber } = action.payload
    const accessToken = yield select(authSelectors.getAccessToken)
    const res = yield call(
      client.support.createSupportRequest,
      { name, email, message, phoneNumber, createdAt: new Date().getTime() },
      accessToken,
    )

    if (isErrorResponse(res)) {
      yield put(supportActions.createSupportFailure(res.data.message, res.status))
    } else {
      yield put(supportActions.createSupportSuccess())
    }
  } catch (e) {
    logger.log(e)
  }
}
