import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/zen'
import PlaceholderImage from './placeholder-image'

type ProductImageSize = 'default' | 'small'

type ProductMetaCellProps = {
  title?: string
  imageUrl?: string
  size?: ProductImageSize
}

const ProductImageCell: FC<ProductMetaCellProps> = ({ title, imageUrl, size = 'default' }) => {
  const defaultTooltipText = imageUrl ? '' : 'This product has no image'
  const [tooltipText, setTooltipText] = useState(defaultTooltipText)
  const [shouldShowPlaceholder, setShouldShowPlaceholder] = useState(true)

  return (
    <ProductCellWrapper>
      <ImageColumn size={size}>
        <span data-cy="image:tooltip" data-tooltip={tooltipText}>
          <ProductImageWrapper shouldShowPlaceholder={shouldShowPlaceholder} size={size}>
            <ProductImage
              data-cy={imageUrl ? 'meta-image' : 'meta-placeholder'}
              src={imageUrl || PlaceholderImage}
              alt={imageUrl ? `shop display, ${title}` : 'This product has no image'}
              onLoad={() => setShouldShowPlaceholder(false)}
              onError={({ currentTarget }) => {
                /* eslint-disable no-param-reassign */
                currentTarget.onerror = null // prevents looping
                currentTarget.src = PlaceholderImage
                currentTarget.alt = 'The link to this image is broken'
                currentTarget.dataset.cy = 'meta-placeholder'
                /* eslint-enable no-param-reassign */
                setTooltipText('The link to this image is broken')
                setShouldShowPlaceholder(true)
              }}
            />
          </ProductImageWrapper>
        </span>
      </ImageColumn>
    </ProductCellWrapper>
  )
}
export const ProductCellWrapper = styled.div({
  padding: '8px 0',
})

export const ImageColumn = styled.div<{ size: string }>(({ size }) => ({
  display: 'block',
  width: size === 'small' ? 40 : 178,
  height: size === 'small' ? 40 : 178,
  overflowY: 'hidden',
}))

const getHeight = (size: ProductImageSize, shouldShowPlaceholder: boolean): string => {
  if (size === 'default') {
    return '178px'
  }

  return shouldShowPlaceholder ? '40px' : '100%'
}

export const ProductImageWrapper = styled.div<{
  shouldShowPlaceholder: boolean
  size: ProductImageSize
}>(({ shouldShowPlaceholder, size = 'small' }) => ({
  background: shouldShowPlaceholder ? `url(${PlaceholderImage}) center no-repeat` : 'none',
  height: getHeight(size, shouldShowPlaceholder),
  backgroundSize: 'cover',
}))

export const ProductImage = styled.img({
  height: '100%',
  width: '100%',
  objectFit: 'contain',
  wordBreak: 'break-all',
  overflowWrap: 'anywhere',
  display: 'inline-block',
})

export const MetadataColumn = styled.div(
  {
    color: COLOR.NEUTRAL[700],
    fontSize: '0.75rem',
    flex: '1 0',
    lineHeight: '16px',
    marginLeft: 14,
  },
  ({ isSingle }: { isSingle: boolean }) => ({
    paddingLeft: isSingle ? 32 : 92,
  }),
)

export const Name = styled.div({
  color: COLOR.NEUTRAL[1000],
  fontSize: '0.875rem',
  fontWeight: 600,
  maxWidth: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

export { ProductImageCell }
