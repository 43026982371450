import * as selectors from './selectors'
import reducer from './reducers'
import sagas from './sagas'
import { actions } from './actions'

export type { ActionType as ProductsActionType, Action as ProductsAction } from './actions'
export type { ReducerState as ProductsReducerState } from './reducers'

export {
  actions as productsActions,
  selectors as productsSelectors,
  reducer as productsReducer,
  sagas as productsSagas,
}
