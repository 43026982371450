import type { FC, SyntheticEvent } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../../tokens/colors'
import { getBorderColor } from './utils'
import { Button } from '../../button'
import { Icon } from '../../icon'
import type { IconProps } from '../../../tokens/icons/icon-props'
import { getLimitText } from '../utils'
import { Close } from '../../../tokens/icons'
import type { ButtonProps } from '../../button/types'
import { Label } from '../../label'

interface FieldProps {
  id: string
  label?: string
  value: string | string[]
  startAdornment?: JSX.Element
  endAdornment?: JSX.Element
  prefix?: string
  suffix?: string
  icon?: IconProps
  hasNoBorder?: boolean
  isDisabled?: boolean
  isError?: boolean
  isInternallyFocused?: boolean
  onClearRequest?: (e: SyntheticEvent<HTMLButtonElement>) => void
  errorFeedback?: string
  subtext?: string
  maxLength?: number
  isCounterHiddenWhenValid?: boolean
  helperText?: string | React.ReactElement
  /* Omit is used here to prevent the option to change size of the button. These are set below. */
  actionButtonProps?: Omit<ButtonProps, 'size'>
  'data-cy'?: string
}

interface NonSharedFieldProps {
  children: React.ReactNode
}

const Field: FC<FieldProps & NonSharedFieldProps> = ({
  id,
  label,
  value,
  startAdornment,
  endAdornment,
  prefix,
  suffix,
  icon,
  hasNoBorder = false,
  isDisabled = false,
  isError = false,
  isInternallyFocused,
  errorFeedback,
  subtext,
  maxLength,
  isCounterHiddenWhenValid,
  onClearRequest,
  helperText,
  children,
  actionButtonProps,
  'data-cy': dataCy,
}) => {
  const isOverLimit = useMemo(() => {
    if (!value) return false
    return !!maxLength && value.length > maxLength
  }, [maxLength, value])

  const showLimitText = !(!isOverLimit && isCounterHiddenWhenValid)

  return (
    <StyledField data-cy={dataCy}>
      <Label htmlFor={id} helperText={helperText} data-cy={dataCy}>
        {label}
      </Label>
      <FieldBody>
        <InputWrapper
          error={isError || isOverLimit}
          disabled={isDisabled}
          hasNoBorder={hasNoBorder}
          isInternallyFocused={isInternallyFocused}
        >
          {!!startAdornment && (
            <StartAdornmentWrapper htmlFor={id}>{startAdornment}</StartAdornmentWrapper>
          )}
          {!!icon && (
            <IconWrapper htmlFor={id}>
              <Icon icon={icon} color={COLOR.NEUTRAL[800]} />
            </IconWrapper>
          )}
          {!!prefix && <Prefix htmlFor={id}>{prefix}</Prefix>}
          {children}
          {!!suffix && <Suffix htmlFor={id}>{suffix}</Suffix>}
          {!!endAdornment && <EndAdornmentWrapper htmlFor={id}>{endAdornment}</EndAdornmentWrapper>}
          {(actionButtonProps || onClearRequest) && (
            <ButtonWrapper>
              {onClearRequest && (
                <Button
                  emphasis="low"
                  color="neutral"
                  size="small"
                  icon={Close}
                  onClick={onClearRequest}
                  onMouseDown={(e) => e.nativeEvent.stopImmediatePropagation()}
                  data-cy={dataCy && `${dataCy}:clear-button`}
                />
              )}
              {actionButtonProps && (
                <Button {...actionButtonProps} size="small" hasReducedPadding />
              )}
            </ButtonWrapper>
          )}
        </InputWrapper>
      </FieldBody>
      {((!!errorFeedback && !!isError) || !!subtext || !!maxLength) && (
        <FieldFooter>
          {!!errorFeedback && (
            <Feedback data-cy={dataCy && `${dataCy}:error-feedback`}>
              <ErrorMessage>{errorFeedback}</ErrorMessage>
            </Feedback>
          )}
          {!!subtext && <Subtext>{subtext}</Subtext>}
          {!!maxLength && showLimitText && (
            <Subtext error={isOverLimit}>{getLimitText(maxLength, value)}</Subtext>
          )}
        </FieldFooter>
      )}
    </StyledField>
  )
}

const StyledField = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
})

const FieldBody = styled.div({
  display: 'flex',
  gap: 8,
})

const Prefix = styled.label({
  lineHeight: '24px',
  paddingLeft: 12,
  paddingTop: 8,
  marginRight: -2,
  alignSelf: 'flex-start',
  color: COLOR.NEUTRAL[600],
  cursor: 'text',
  whiteSpace: 'nowrap',
})

const Suffix = styled.label({
  alignSelf: 'flex-end',
  lineHeight: '24px',
  paddingRight: 12,
  paddingBottom: 8,
  marginLeft: -2,
  color: COLOR.NEUTRAL[600],
  cursor: 'text',
  whiteSpace: 'nowrap',
})

const ButtonWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  padding: 4,
  alignSelf: 'flex-end',
})

const StartAdornmentWrapper = styled.label({
  height: 40,
  paddingLeft: 8,
  marginRight: -4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'flex-start',
})

const EndAdornmentWrapper = styled.label({
  height: 40,
  paddingRight: 8,
  marginLeft: -4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'flex-start',
})

const IconWrapper = styled.label({
  height: 40,
  paddingLeft: 8,
  marginRight: -4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'flex-start',
})

const FieldFooter = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
})

const InputWrapper = styled.div<{
  error?: boolean
  disabled?: boolean
  hasNoBorder?: boolean
  isInternallyFocused?: boolean
}>(({ error, disabled, hasNoBorder, isInternallyFocused }) => ({
  maxWidth: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  backgroundColor: COLOR.WHITE,
  ...(disabled && {
    ...(!hasNoBorder && { backgroundColor: COLOR.NEUTRAL.OPACITY[12] }),
    [`& > ${StartAdornmentWrapper}, & > ${EndAdornmentWrapper}, & > ${IconWrapper}`]: {
      opacity: 0.65,
    },
  }),
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    ...(!hasNoBorder && { borderStyle: 'solid' }),
    borderWidth: 1,
    borderColor: getBorderColor(isInternallyFocused, error, disabled),
    borderRadius: 4,
    pointerEvents: 'none',
    transition: '50ms',
  },
  ...(!error && {
    '&:focus-within::before': {
      borderColor: COLOR.BLUE[700],
    },
  }),
}))

const Feedback = styled.div({
  fontSize: 15,
  lineHeight: '20px',
  color: COLOR.RED[700],
})

const ErrorMessage = styled.span({
  color: COLOR.RED[700],
})

const Subtext = styled.div<{
  error?: boolean
}>(({ error }) => ({
  fontSize: 13,
  lineHeight: '16px',
  color: !error ? COLOR.NEUTRAL[600] : COLOR.RED[700],
}))

export { Field, FieldProps }
