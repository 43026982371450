type CssPosition = number | 'auto'
type TooltipPosition = {
  top: CssPosition
  bottom: CssPosition
  left: CssPosition
  right: CssPosition
}

const calculateTooltipPosition = (
  containerWidth: number,
  containerHeight: number,
  targetBounding: DOMRect,
  tooltipBounding: DOMRect,
  offsetPx: number,
): TooltipPosition => {
  const widthDiff = targetBounding.width - tooltipBounding.width

  let top: CssPosition = targetBounding.bottom + targetBounding.height + offsetPx
  let bottom: CssPosition = top + tooltipBounding.height
  let left: CssPosition = targetBounding.left + widthDiff / 2
  let right: CssPosition = left + tooltipBounding.width

  // Adjust if the tooltip will be below the screen bottom
  if (bottom > containerHeight) {
    top = 'auto'
    bottom = containerHeight - targetBounding.top + offsetPx
  } else {
    bottom = 'auto'
  }

  // Adjust if the tooltip will be off the screen to the left or right
  if (left < 0) {
    left = offsetPx
    right = 'auto'
  } else if (right > containerWidth) {
    left = 'auto'
    right = offsetPx
  } else {
    right = 'auto'
  }

  return { top, bottom, left, right }
}

export { calculateTooltipPosition, CssPosition, TooltipPosition }
