import type { Action } from '../../actions'

type ToggleSupportState = boolean

const intialState: ToggleSupportState = false

export function toggleSupport(state = intialState, action: Action): ToggleSupportState {
  switch (action.type) {
    case 'TOGGLE_SUPPORT': {
      return !state
    }
    default:
      return state
  }
}
