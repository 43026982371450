import type { ChangeEvent, FC } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Fuse from 'fuse.js'
import {
  useAssignClaimMutation,
  useListClaimsAssignmentUsersQuery,
  useGetInsuranceClaimQuery,
} from '@helloextend/extend-api-rtk-query'
import {
  Button,
  Input,
  MenuButtonItem,
  Popover,
  PopoverAlignment,
  PopoverDirection,
  Stack,
  usePopover,
} from '@helloextend/zen'
import { ArrowDropDown, Search } from '@helloextend/zen/src/tokens/icons'
import { StackAlign, StackDirection } from '@helloextend/zen/src/components/stack'
import type { ClaimsUser } from '@helloextend/extend-api-client'
import styled from '@emotion/styled'

interface AssignUserDropdownProps {
  claimId: string
  alignment?: PopoverAlignment
  onToggle?: (isPresent: boolean) => void
}

const AssignUserDropdown: FC<AssignUserDropdownProps> = ({
  claimId,
  alignment = PopoverAlignment.end,
  onToggle,
}): JSX.Element => {
  const [filter, setFilter] = useState('')

  const { popoverRef, triggerRef, isPresent, toggle, triggerBoundingBox } =
    usePopover<HTMLButtonElement>()

  const handleToggle = useCallback(() => {
    // Since the claims data table has a listener for all click events,
    // we need to intercept the dropdown click before it navigates to the details page.
    if (typeof onToggle !== 'undefined') {
      onToggle(!isPresent)
    }
    toggle()
  }, [isPresent, toggle, onToggle])

  useEffect(() => {
    // This updates the parent component state in the event that the popover is
    // closed via something other than a direct button click.
    if (typeof onToggle !== 'undefined') {
      onToggle(isPresent)
    }
  }, [onToggle, isPresent])

  const { userList, isSuccess } = useListClaimsAssignmentUsersQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      userList: (result.data?.items ?? [])
        .filter((item) => item.isActive)
        .map((item) => {
          return {
            ...item,
            fullName: `${item.firstName} ${item.lastName}`,
          }
        }),
    }),
  })

  const { data: claim } = useGetInsuranceClaimQuery({
    claimId,
  })

  const [assignUserToClaim] = useAssignClaimMutation()

  const handleSelection = useCallback(
    (userId: string): void => {
      const user = userList.find((u: ClaimsUser) => u.id === userId)
      if (!user) return
      assignUserToClaim({
        claimId,
        user: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        },
      })
      handleToggle()
    },
    [assignUserToClaim, claimId, userList, handleToggle],
  )

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFilter(e.currentTarget.value)
  }

  const filteredData = useMemo(() => {
    if (!userList) return []
    if (filter === '') {
      return userList
    }

    const fuse = new Fuse(userList, {
      shouldSort: true,
      keys: ['fullName'],
      minMatchCharLength: 3,
      distance: 100,
      threshold: 0.3,
    })

    return fuse.search(filter)
  }, [userList, filter])

  return isSuccess ? (
    <>
      <AssigneeName>
        {claim?.assignedUser
          ? `${claim?.assignedUser?.firstName} ${claim?.assignedUser?.lastName}`
          : 'Unassigned'}
      </AssigneeName>
      <Button
        ref={triggerRef}
        icon={ArrowDropDown}
        color="blue"
        emphasis="low"
        size="xsmall"
        onClick={handleToggle}
        data-cy="user-dropdown-button"
        isToggled={isPresent}
      />
      <Popover
        data-cy="assign-user-popover"
        ref={popoverRef}
        direction={PopoverDirection.down}
        alignment={PopoverAlignment[alignment]}
        isPresent={isPresent}
        triggerBoundingBox={triggerBoundingBox}
        maxHeight={298}
        maxWidth={222}
      >
        <Stack direction={StackDirection.column} align={StackAlign.stretch} padding={1}>
          <Input
            onChange={handleSearchChange}
            id="search"
            value={filter}
            icon={Search}
            placeholder="Search"
            data-cy="dropdown-search-input"
          />
          {filteredData.length ? (
            filteredData.map((user) => (
              <MenuButtonItem
                data-cy={user.fullName || 'select-item'}
                key={user.id}
                onClick={() => handleSelection(user.id)}
              >
                {user.fullName || ''}
              </MenuButtonItem>
            ))
          ) : (
            <MenuButtonItem data-cy="no-matches">No matches found</MenuButtonItem>
          )}
        </Stack>
      </Popover>
    </>
  ) : (
    <></>
  )
}

const AssigneeName = styled.span({})

export { AssignUserDropdown }
