import type { FC } from 'react'
import React from 'react'
import { Button, ButtonGroup } from '@helloextend/zen'
import type { FormikHandlers } from 'formik'

type SaveChangesButtonGroupProps = {
  id: string
  handleSave: () => void
  handleCancel: FormikHandlers['handleReset']
  isSaveDisabled?: boolean
  isLoading?: boolean
  saveButtonText?: string
}

const SaveChangesButtonGroup: FC<SaveChangesButtonGroupProps> = ({
  id,
  handleSave,
  handleCancel,
  isSaveDisabled = false,
  isLoading = false,
  saveButtonText = 'Save',
}) => {
  return (
    <ButtonGroup>
      <Button
        text="Cancel"
        onClick={handleCancel}
        emphasis="medium"
        isDisabled={isLoading}
        data-cy={`${id}-cancel-button`}
      />
      <Button
        text={saveButtonText}
        onClick={handleSave}
        emphasis="high"
        isDisabled={isSaveDisabled}
        isProcessing={isLoading}
        data-cy={`${id}-save-button`}
      />
    </ButtonGroup>
  )
}

export { SaveChangesButtonGroup }
