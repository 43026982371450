import type { StoreUser } from '@helloextend/extend-api-client'
import { merge, update, omit } from 'lodash/fp'
import type { Action } from '../actions'

export type ByStoreAccountIdReducerState = Record<string, StoreUser>

export const initialState: ByStoreAccountIdReducerState = {}

export default function byStoreAccountId(
  state = initialState,
  action: Action,
): ByStoreAccountIdReducerState {
  switch (action.type) {
    case 'ACCOUNT_USERS_FETCH_ALL_SUCCESS': {
      return action.payload.users.reduce<ByStoreAccountIdReducerState>(addItem, state)
    }
    case 'USER_FETCH_SUCCESS': {
      return update([userKey(action.payload.accountId, action.payload.id)], (user) => {
        return merge(user, action.payload)
      })(state)
    }
    case 'USER_CREATE_SUCCESS':
    case 'USER_UPDATE_SUCCESS': {
      const { id, accountId } = action.payload
      return update([userKey(accountId, id)], (user) => {
        return merge(user, action.payload)
      })(state)
    }
    case 'USER_DELETE_SUCCESS': {
      const { user } = action.payload
      return deleteItem(state, user)
    }
    case 'ACCOUNT_USERS_RESET':
      return {}
    default:
      return state
  }
}

export const userKey = (accountId: string, userId: string): string =>
  `STORE::${accountId}::USER::${userId}`

function addItem(
  state: ByStoreAccountIdReducerState,
  item: StoreUser,
): ByStoreAccountIdReducerState {
  const key = userKey(item.accountId, item.id)
  return { ...state, [key]: item }
}

function deleteItem(
  state: ByStoreAccountIdReducerState,
  item: StoreUser,
): ByStoreAccountIdReducerState {
  const key = userKey(item.accountId, item.id)
  return omit(key, state)
}
