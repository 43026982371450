export const obligorOptionsList = [
  { value: 'AIG', label: 'AIG' },
  { value: 'Assurant', label: 'Assurant' },
  { value: 'CNA', label: 'CNA' },
  { value: 'DAC', label: 'DAC' },
  { value: 'EWSC/EPC', label: 'EWSC/EPC' },
  { value: 'Extend, Inc', label: 'Extend, Inc' },
  { value: 'Fortegra', label: 'Fortegra' },
  { value: 'Guardsman', label: 'Guardsman' },
  { value: 'OTIS', label: 'OTIS' },
  { value: 'Peloton', label: 'Peloton' },
]
