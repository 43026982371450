import type { FC, MouseEvent } from 'react'
import React, { memo, useRef, useEffect, useState, useCallback } from 'react'
import styled from '@emotion/styled'
import type { DateValue } from '@helloextend/zen'
import { DatePicker as DatePickerComponent, Input } from '@helloextend/zen'
import type { StringInputItem } from './types'

type DatePickerItemProps = {
  handleChange: (value: DateValue) => void
  calendarOpen?: boolean
  item: StringInputItem
}

const DatePicker: FC<DatePickerItemProps> = ({ handleChange, calendarOpen, item }) => {
  const { label, value } = item
  const datePickerRef = useRef<HTMLDivElement>(null)
  const [openCalendar, setOpenCalendar] = useState(calendarOpen || false)

  const noop = useCallback(() => {}, [])
  const onDateChange = useCallback(
    (date: DateValue) => {
      handleChange(date)
      setOpenCalendar(false)
    },
    [handleChange],
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent<HTMLDivElement> | TouchEvent): void => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as HTMLDivElement)
      ) {
        setOpenCalendar(false)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside as EventListener)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside as EventListener)
    }
  }, [datePickerRef])

  return (
    <DatePickerWrapper ref={datePickerRef}>
      <Input
        ariaLabel="Date Picker Input"
        data-cy="date-picker-input-item"
        errorFeedback=""
        id="date-picker-input"
        label={label}
        onChange={noop}
        placeholder={value}
        value={value}
        onFocus={() => setOpenCalendar(true)}
      />
      {openCalendar && (
        <ContainDatePicker>
          <DatePickerComponent onChange={onDateChange} value={new Date(value)} />
        </ContainDatePicker>
      )}
    </DatePickerWrapper>
  )
}

const DatePickerWrapper = styled.div({
  marginRight: 24,
  marginTop: '-0.2rem',
  width: '100%',
})

const ContainDatePicker = styled.div({
  border: '1px solid #E5E5E5',
  position: 'absolute',
  marginTop: '0.3rem',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  zIndex: 1,
  marginLeft: '-3rem',
})

const DatePickerItem = memo(DatePicker, (prevProps, nextProps) => {
  return prevProps.item.value === nextProps.item.value
})

export { DatePickerItem, DatePickerItemProps }
