import type { ChangeEvent, FC, SyntheticEvent } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import { PasswordToggle } from './password-toggle'

/*
 * added ChangeEvent declaration
 * on event handlers to support
 * input-fields-login-form onBlur/onChange
 * event.target typing (mainly for its mounted test suite)
 */
type PasswordInputProps = {
  errorMessage?: string
  invalid?: boolean
  isDisabled?: boolean
  label: string
  name: string
  onBlur?: (e: SyntheticEvent<HTMLInputElement> & ChangeEvent<HTMLInputElement>) => void
  onChange: (e: SyntheticEvent<HTMLInputElement> & ChangeEvent<HTMLInputElement>) => void
  value: string
  'data-cy'?: string
}

const PasswordInput: FC<PasswordInputProps> = ({
  errorMessage,
  invalid,
  isDisabled,
  label,
  name,
  onBlur,
  onChange,
  value,
  'data-cy': dataCy,
}) => {
  const [inputType, setInputType] = useState('password')

  const isTypeInput = inputType === 'input'

  const handleToggleInputType = (): void => {
    setInputType(inputType === 'input' ? 'password' : 'input')
  }

  return (
    <>
      <Label htmlFor={name}>
        <div>{label}</div>
        <InputWrapper>
          <Input
            data-cy={dataCy}
            autoComplete="password"
            disabled={isDisabled}
            id={name}
            invalid={invalid}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            type={inputType}
            value={value}
          />
          <PasswordToggle onClick={handleToggleInputType} isChecked={isTypeInput} />
        </InputWrapper>
      </Label>
      {invalid && (
        <ErrorMessage data-error-msg-for={name}>
          {errorMessage || 'Please enter a password'}
        </ErrorMessage>
      )}
    </>
  )
}

const Label = styled.label({
  color: COLOR.VERY_DARK_BLUE_0,
  fontFamily: '"Nunito Sans", sans-serif',
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '18px',
  position: 'relative',
})

const InputWrapper = styled.div({
  position: 'relative',
})

const Input = styled.input<{ invalid?: boolean }>(
  {
    borderRadius: 4,
    border: `1px solid ${COLOR.LIGHT_GRAYISH_BLUE_0}`,
    boxSizing: 'border-box',
    fontFamily: '"Nunito Sans", sans-serif',
    fontSize: 14,
    lineHeight: '18px',
    width: '100%',
    margin: '5px 0 8px',
    padding: '10px 12px',
    '&:hover': {
      borderColor: COLOR.LIGHT_GRAYISH_BLUE_0,
    },
    '&:focus': {
      outline: 'none',
      borderColor: COLOR.BRIGHT_BLUE,
      boxShadow: `0 0 0 1px inset ${COLOR.BRIGHT_BLUE}`,
    },
  },
  ({ invalid }) => ({
    borderColor: invalid ? COLOR.DANGER : COLOR.LIGHT_GRAYISH_BLUE_0,
    '&:focus': {
      borderColor: invalid ? COLOR.DANGER : COLOR.BRIGHT_BLUE,
      boxShadow: `0 0 0 1px inset ${invalid ? COLOR.DANGER : COLOR.BRIGHT_BLUE}`,
    },
  }),
)

const ErrorMessage = styled.div({
  color: COLOR.STATE_DANGER,
  fontFamily: '"Nunito Sans", sans-serif',
  fontSize: 14,
  lineHeight: '14px',
  marginBottom: 8,
})

export { PasswordInput }
