import type { ClaimsSearchResponseV2 } from '@helloextend/extend-api-rtk-query'
import {
  useListInsuranceClaimsQuery,
  useSearchClaimsQuery,
} from '@helloextend/extend-api-rtk-query'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../constants/ld-flags'

interface QueryParams {
  searchVersion?: string
  containsContractId?: string
  containsClaimId?: string
  matchesClaimStatus?: string[]
}

const useClaimsSearch = ({
  queryParams,
  skip = false,
}: {
  queryParams: QueryParams
  skip?: boolean
}): {
  data?: ClaimsSearchResponseV2
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
} => {
  const { [LDFlag.UseClaimsManagement]: FF_CLAIMS_MANAGEMENT_ENABLED } = useFlags()

  const {
    data: contractClaimsSearchData,
    isLoading: isContractClaimsSearchLoading,
    isError: contractClaimsSearchError,
    isSuccess: contractClaimsSearchSuccess,
  } = useSearchClaimsQuery(queryParams, { skip: FF_CLAIMS_MANAGEMENT_ENABLED || skip })

  const {
    data: claimsManagementClaimsSearchData,
    isLoading: isClaimsManagementClaimsSearchLoading,
    isError: claimsManagementSearchError,
    isSuccess: claimsManagementSearchSuccess,
  } = useListInsuranceClaimsQuery(queryParams, { skip: !FF_CLAIMS_MANAGEMENT_ENABLED || skip })

  return {
    data: FF_CLAIMS_MANAGEMENT_ENABLED
      ? claimsManagementClaimsSearchData
      : contractClaimsSearchData,
    isLoading: FF_CLAIMS_MANAGEMENT_ENABLED
      ? isClaimsManagementClaimsSearchLoading
      : isContractClaimsSearchLoading,
    isError: FF_CLAIMS_MANAGEMENT_ENABLED ? claimsManagementSearchError : contractClaimsSearchError,
    isSuccess: FF_CLAIMS_MANAGEMENT_ENABLED
      ? claimsManagementSearchSuccess
      : contractClaimsSearchSuccess,
  }
}

export { useClaimsSearch }
