import type { Store } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetchAll = (accessToken: string) =>
  ({ type: 'STORES_FETCH_ALL', payload: accessToken } as const)

export const fetchAllRequest = () => ({ type: 'STORES_FETCH_ALL_REQUEST' } as const)

export const fetchAllSuccess = (stores: Store[]) =>
  ({
    type: 'STORES_FETCH_ALL_SUCCESS',
    payload: stores,
  } as const)

export const fetchAllFailure = createActionFailure('STORES_FETCH_ALL_FAILURE')
