import type { FC } from 'react'
import React from 'react'
import { COLOR } from '../../../tokens/colors'

export interface ShieldProps {
  isInverted?: boolean
  'data-cy'?: string
}

export const Shield: FC<ShieldProps> = ({ isInverted = false, 'data-cy': dataCy }) => {
  return (
    <svg data-cy={dataCy} viewBox="0 0 33 34" width="33" height="34">
      <path
        d="M32.99,2.02l-.72-.2c-3.07-.87-5.38-1.43-6.95-1.71-.94-.16-1.81-.08-2.77,.23-.29,.09-.59,.2-.95,.34-.17,.07-.79,.32-.92,.38-1.54,.62-2.66,.89-4.17,.88-1.51,0-2.51-.27-3.8-.87-.09-.04-.18-.08-.29-.14,0,0-.23-.11-.29-.14-.24-.12-.42-.2-.6-.28C10.45,.05,9.53-.11,8.4,.07,6.72,.35,4.17,.93,.75,1.81L.01,2.01v.76c-.09,6.4,1.17,12.07,3.77,16.99,2.6,4.91,6.75,9.5,12.46,13.78l.62,.46,.61-.48c5.39-4.25,9.34-8.85,11.84-13.78,2.5-4.95,3.73-10.61,3.69-16.97v-.75Zm-5.48,16.82c-2.26,4.48-5.82,8.69-10.68,12.63-5.17-3.98-8.92-8.19-11.28-12.65C3.22,14.4,2.03,9.32,2.01,3.55c2.99-.76,5.23-1.27,6.71-1.51,.72-.12,1.26-.02,2,.3,.15,.06,.31,.14,.53,.24,.06,.03,.29,.14,.29,.14,.12,.06,.21,.1,.31,.15,1.53,.72,2.82,1.06,4.65,1.06,1.81,0,3.17-.32,4.93-1.03,.15-.06,.76-.31,.92-.37,.32-.13,.57-.22,.81-.29,.68-.21,1.23-.27,1.82-.16,1.36,.24,3.37,.72,6.01,1.46-.06,5.75-1.22,10.85-3.47,15.31Z"
        fill={isInverted ? COLOR.WHITE : COLOR.BLUE[1000]}
      />
      <path
        d="M23.76,19.86h-4.52s-2.72-3.2-2.86-3.39c-.67,.89-2.72,3.39-2.72,3.39h-4.45l4.95-5.94-4.72-5.48h4.5s3.28,3.81,9.83,11.42Z"
        fill={isInverted ? COLOR.WHITE : COLOR.BLUE[1000]}
      />
      <path
        d="M17.06,10.74l1.8-2.3h4.37l-3.98,4.85-2.2-2.55Z"
        fill={isInverted ? COLOR.WHITE : '#27AEE4'}
      />
      <path
        fillRule="evenodd"
        d="M24.98,2.08c-.6-.1-1.15-.05-1.82,.16-.24,.07-.49,.17-.81,.29-.16,.06-.77,.31-.92,.37-1.76,.7-3.11,1.03-4.93,1.03-1.83,0-3.12-.35-4.65-1.06-.09-.04-.19-.09-.31-.15,0,0-.23-.11-.29-.14-.22-.11-.38-.18-.53-.24-.74-.32-1.29-.41-2-.3-1.49,.24-3.73,.74-6.71,1.51,.03,5.77,1.21,10.85,3.55,15.27,2.35,4.45,6.11,8.67,11.28,12.65,4.86-3.94,8.42-8.15,10.68-12.63,2.25-4.46,3.41-9.56,3.47-15.31-2.65-.73-4.66-1.22-6.01-1.46Zm-6.12,6.36h4.37l-3.98,4.85-2.2-2.55,1.8-2.3Zm.38,11.42s-2.72-3.2-2.86-3.39c-.67,.89-2.72,3.39-2.72,3.39h-4.45l4.95-5.94-4.72-5.48h4.5s3.28,3.81,9.83,11.42h-4.52Z"
        fill={isInverted ? 'transparent' : COLOR.WHITE}
      />
    </svg>
  )
}
