import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import type { CellContext, DataTableAction } from '@helloextend/zen'
import { Badge, COLOR, DataTable, Stack } from '@helloextend/zen'
import type { CategoryMapping, PlanSet, StoreIdAndName } from '@helloextend/extend-api-client'
import { CategoryMappingStatus } from '@helloextend/extend-api-client'
import {
  useListCategoryMappingsQuery,
  useListPlanSetsQuery,
} from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import type { ColumnFiltersState } from '@tanstack/react-table'
import { StackAlign, StackDirection } from '@helloextend/zen/src/components/stack'
import { statusBadgeProps } from './utils'
import { getCategoryMapperFilterDefs } from './table/lib/get-filter-defs'

interface CategoryMapperTableProps {
  selectedStore: StoreIdAndName
  toggleModal: () => void
  handleSelectedMappings: (selectedRows: CategoryMapping[]) => void
  categories: string[]
}

export interface CategoryMappingTableItem extends CategoryMapping {
  planSetName?: string
}

const CategoryMapperTable: FC<CategoryMapperTableProps> = ({
  selectedStore: { id: storeId, name },
  handleSelectedMappings,
  categories,
}) => {
  const { data: { items: mappings = [] } = {}, isFetching: isMappingLoading } =
    useListCategoryMappingsQuery({ storeId })
  const { data: planSets = [], isFetching: isPlanSetsLoading } = useListPlanSetsQuery()
  const [filteredMappings, setFilteredMappings] = useState<CategoryMapping[]>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    { id: 'mappingStatus', value: [CategoryMappingStatus.UNMAPPED] },
  ])

  const getTableActions = (selectedRows: CategoryMapping[]): DataTableAction[] => {
    return [
      {
        'data-cy': 'map-category-button',
        text: 'Map',
        emphasis: 'high',
        isDisabled: Boolean(!selectedRows.length),
        onClick: () => handleSelectedMappings(selectedRows),
      },
    ]
  }

  useEffect(() => {
    const categoryFilters = getFilters('extendCategory', columnFilters)
    const statusFilters = getFilters('mappingStatus', columnFilters)

    const newMappings = columnFilters.length
      ? filterMappings(mappings, categoryFilters, statusFilters)
      : mappings

    // prevents continually re-rendering while fetching the paginated mappings/plan sets
    if (!isPlanSetsLoading && !isMappingLoading) {
      setFilteredMappings(newMappings)
    }
  }, [columnFilters, isMappingLoading, isPlanSetsLoading, mappings])

  return (
    <>
      <Header data-cy="category-mapper-header-text">{name}</Header>
      <Stack direction={StackDirection.column} align={StackAlign.stretch} spacing={2}>
        <SubTitle data-cy="category-mapper-sub-header-text">
          <StoreIdTitle>Store ID:</StoreIdTitle>
          {storeId}
        </SubTitle>
        <DataTable
          data-cy="category-mapper-data-table"
          isLoading={isMappingLoading || isPlanSetsLoading}
          columns={TABLE_COLUMNS}
          data={getTableItems(filteredMappings, planSets)}
          columnFilters={columnFilters}
          onColumnFiltersChange={setColumnFilters}
          hasManualFiltering={false}
          filterDefs={getCategoryMapperFilterDefs(categories)}
          hasSelectableRows
          getTableActions={getTableActions}
        />
      </Stack>
    </>
  )
}

const getTableItems = (
  mappings: CategoryMapping[],
  planSets: PlanSet[],
): CategoryMappingTableItem[] =>
  mappings.map((mapping) => ({
    ...mapping,
    planSetName: planSets.find(({ id }) => id === mapping.planSetId)?.name,
  }))

const getFilters = (
  filterId: 'extendCategory' | 'mappingStatus',
  columnFilters: ColumnFiltersState,
): string[] | undefined =>
  columnFilters.find(({ id }) => id === filterId)?.value as undefined | string[]

const filterMappings = (
  mappings: CategoryMapping[],
  categoryFilters?: string[],
  statusFilters?: string[],
): CategoryMapping[] =>
  mappings.filter(
    ({ extendCategory, mappingStatus }) =>
      (!categoryFilters || categoryFilters.includes(extendCategory)) &&
      (!statusFilters || statusFilters.includes(mappingStatus)),
  )

const TABLE_COLUMNS = [
  {
    id: 'merchantCategory',
    label: 'Merchant Category',
    renderCell: (cellData: CellContext<CategoryMapping, string>) => (
      <>{cellData.getValue()?.trim().length ? cellData.getValue() : <Dash />}</>
    ),
  },
  {
    id: 'extendCategory',
    label: 'Extend Plan Category',
    renderCell: (cellData: CellContext<CategoryMapping, string>) => (
      <>{cellData.getValue()?.trim().length ? cellData.getValue() : <Dash />}</>
    ),
  },
  {
    id: 'mappingStatus',
    label: 'Warranty Status',
    renderCell: (cellData: CellContext<CategoryMapping, CategoryMappingStatus>) => (
      <Badge {...statusBadgeProps[cellData.getValue()]} />
    ),
  },
  {
    id: 'planSetName',
    label: 'Plan Set',
    renderCell: (cellData: CellContext<CategoryMapping, string>) => (
      <>{cellData.getValue()?.trim().length ? cellData.getValue() : <Dash />}</>
    ),
  },
]

const StoreIdTitle = styled.span({
  color: '#6B7887',
  fontSize: 16,
  fontWeight: 800,
  marginRight: 5,
})

const Header = styled.h1({
  color: COLOR.BLACK,
  fontSize: 20,
  lineHeight: '28px',
  fontWeight: 800,
  marginTop: 30,
})

const SubTitle = styled.span({
  color: COLOR.BLACK,
  fontSize: 16,
  margin: 0,
})

const Dash = styled.hr({
  borderTop: 0,
  borderStyle: 'solid',
  borderColor: '#141617',
  width: 14,
  marginLeft: 0,
})

export { CategoryMapperTable }
