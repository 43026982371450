import { createApi } from '@reduxjs/toolkit/query/react'
import type { LeadRequest } from '@helloextend/extend-api-client/src/models/growth'
import { baseQuery } from '../base-query'

export const growthApi = createApi({
  baseQuery,
  reducerPath: 'Growth',
  tagTypes: ['Growth'],
  endpoints: (build) => ({
    createLead: build.mutation<void, LeadRequest>({
      query: (body: LeadRequest) => ({
        url: 'growth/leads',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const { useCreateLeadMutation } = growthApi
