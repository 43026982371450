import type { FC, ChangeEvent } from 'react'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  ModalController,
  Modal,
  Input,
  Select,
  useToaster,
  ToastColor,
  ToastDuration,
} from '@helloextend/zen'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import type { ThreadTypes, ThreadListItem } from '@helloextend/extend-api-rtk-query'
import { useLazyGetThreadQuery, useCreateThreadMutation } from '@helloextend/extend-api-rtk-query'
import type { ApiResponse, ErrorResponse } from '@helloextend/extend-api-fetch'
import { useHistory } from 'react-router'
import * as Yup from 'yup'
import { setIsDuplicateThreadModalVisible } from '../../../../../store/slices/amp-slice'

type DuplicateThreadModalProps = {
  isVisible: boolean
  onCancel: () => void
  thread: ThreadListItem | null
}

const DuplicateThreadModal: FC<DuplicateThreadModalProps> = ({ isVisible, onCancel, thread }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { toast } = useToaster()

  const [isDuplicateThreadName, setIsDuplicateThreadName] = useState<boolean>(false)

  const [createThread, { isLoading: isCreateThreadProcessing }] = useCreateThreadMutation()

  const [fetchThread, { isLoading: isFetchLoading }] = useLazyGetThreadQuery()

  const schema = Yup.object()
    .shape({
      name: Yup.string().required('Thread name is required'),
      type: Yup.string()
        .matches(/(adjudication|intro|outro|troubleshooting|other)/)
        .required('Thread type is required'),
    })
    .defined()

  const {
    handleChange,
    setFieldValue,
    values,
    errors,
    handleBlur,
    touched,
    isValid,
    dirty: isDirty,
  } = useFormik<Yup.InferType<typeof schema>>({
    enableReinitialize: true,
    initialValues: {
      name: thread?.title ? `Copy - ${thread.title}` : '',
      type: '',
    },
    validationSchema: schema,
    onSubmit: () => {},
  })

  const isProcessing = isCreateThreadProcessing || isFetchLoading
  const isDisabled = !(isDirty && isValid) || isCreateThreadProcessing

  const handleNameInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue('name', e.target.value)

    if (isDuplicateThreadName) {
      setIsDuplicateThreadName(false)
    }
  }

  const handleProceed = async (): Promise<void> => {
    if (!thread) return
    try {
      const sourceThread = await fetchThread(thread.id, true).unwrap()

      const duplicatedThread = await createThread({
        title: values.name,
        type: values.type as ThreadTypes,
        script: sourceThread.script,
      }).unwrap()

      toast({
        message: `${duplicatedThread.title} has been successfully created.`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
      history.push(`/admin/adjudication-management/threads/${duplicatedThread.id}/edit`)
      dispatch(setIsDuplicateThreadModalVisible(false))
    } catch (err: unknown) {
      // duplicate names are flagged in API but don't render a toast
      const responsePayload = (err as ApiResponse)?.data
      const code = (responsePayload as ErrorResponse)?.code
      if (code === 'title_already_exists') {
        setIsDuplicateThreadName(true)
      } else {
        toast({
          message: 'Something went wrong. Please try again.',
          toastColor: ToastColor.red,
          toastDuration: ToastDuration.short,
        })
      }
    }
  }

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        data-cy="amp-duplicate-thread-modal"
        heading="Duplicate thread"
        primaryButtonProps={{
          'data-cy': 'amp-duplicate-thread-modal-submit',
          onClick: handleProceed,
          text: 'Proceed to Thread Builder',
          isDisabled,
          isProcessing,
        }}
        secondaryButtonProps={{
          'data-cy': 'amp-duplicate-thread-modal-cancel',
          onClick: onCancel,
          text: 'Cancel',
        }}
        onDismissRequest={onCancel}
      >
        <>
          <NameWrapper>
            <Input
              data-cy="amp-duplicate-thread-name"
              id="name"
              label="Name"
              onChange={handleNameInputChange}
              onBlur={handleBlur}
              placeholder="Name the new thread"
              value={values.name}
              isError={(Boolean(errors.name) && touched.name) || isDuplicateThreadName}
              errorFeedback={
                errors.name || (isDuplicateThreadName ? 'The thread name is taken' : '')
              }
            />
          </NameWrapper>
          <Select
            data-cy="amp-duplicate-thread-type"
            id="type"
            label="Thread Type"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Select"
            value={values.type}
          >
            <option data-cy="adjudication" value="adjudication">
              Adjudication
            </option>
            <option value="intro">Intro</option>
            <option value="outro">Outro</option>
            <option value="troubleshooting">Troubleshooting</option>
            <option value="other">Other</option>
          </Select>
        </>
      </Modal>
    </ModalController>
  )
}

const NameWrapper = styled.div({
  marginBottom: 16,
})

export { DuplicateThreadModal, DuplicateThreadModalProps }
