import type { FC } from 'react'
import React, { useCallback, useEffect } from 'react'
import styled from '@emotion/styled'
import type { HeaderGroup, SortDirection } from '@tanstack/react-table'
import { flexRender } from '@tanstack/react-table'
import { Else, If, Then, When } from 'react-if'
import { COLOR } from '../../../tokens/colors'
import { HeaderCell } from './header-cell'
import { Checkmark } from '../../checkbox'
import { RowActions } from './row-actions'
import { ExpandAll, ConfigureColumns, CollapseAll } from '../../../tokens/icons'
import { Button } from '../../button'
import { useDataTableContext } from '../data-table-context'

export interface HeaderProps {
  headerGroup: HeaderGroup<unknown>
}

export const Header: FC<HeaderProps> = ({ headerGroup }) => {
  const {
    collapseAllRows,
    columnCount,
    'data-cy': dataCy,
    expandAllRows,
    table,
    getAreAllRowsExpanded,
    getRowActions,
    getRowMenuItems,
    hasAllTableRowsSelected,
    hasConfigurableColumns,
    hasMoreRows,
    hasSelectableRows,
    itemName,
    onSelectAllRowsChange,
    setHasAllTableRowsSelected,
    setIsConfiguringColumns,
    rowDetailRenderer,
    rowCount,
    tableContainerWidth,
  } = useDataTableContext()
  const hasAllPageRowsSelected = table.getIsAllPageRowsSelected()
  const hasAllDataRowsSelected = table.getIsAllRowsSelected()
  const hasSomePageRowsSelected = table.getIsSomePageRowsSelected()
  const hasRowsSelected =
    hasSomePageRowsSelected || hasAllDataRowsSelected || hasAllPageRowsSelected

  const dataRowCount = rowCount || table.getFilteredRowModel().rows.length
  const visibleRowCount = table.getRowModel().rows.length

  const hasMoreRowsToSelect = hasMoreRows || dataRowCount > visibleRowCount
  const isSelectMoreHeaderVisible = hasAllPageRowsSelected && hasMoreRowsToSelect
  const quantitySelected =
    rowCount && hasAllTableRowsSelected ? rowCount : table.getSelectedRowModel().rows.length

  const { globalFilter, columnFilters } = table.getState()
  const allSelectedText =
    globalFilter || columnFilters.length !== 0 ? 'from the applied filter' : 'in this table'

  useEffect(() => {
    if (onSelectAllRowsChange) {
      onSelectAllRowsChange(hasAllTableRowsSelected)
    }
  }, [hasAllTableRowsSelected, onSelectAllRowsChange])

  const handleSelectionCheckboxClick = useCallback(
    () => (hasRowsSelected ? table.resetRowSelection() : table.toggleAllPageRowsSelected(true)),
    [hasRowsSelected, table],
  )

  const handleSelectAllTableRows = useCallback(() => {
    table.toggleAllRowsSelected(true)
    setHasAllTableRowsSelected(true)
  }, [setHasAllTableRowsSelected, table])

  const handleClearRowSelection = useCallback(() => {
    table.resetRowSelection()
    setHasAllTableRowsSelected(false)
  }, [setHasAllTableRowsSelected, table])

  const handleSetIsConfiguringColumns = useCallback(() => {
    setIsConfiguringColumns(true)
  }, [setIsConfiguringColumns])

  const hasRowsWithDetails = !!rowDetailRenderer

  const areAllRowsExpanded = getAreAllRowsExpanded()

  const hasStartCell = hasRowsWithDetails || hasSelectableRows
  const hasEndCell = hasConfigurableColumns || !!getRowActions || !!getRowMenuItems

  const headerDetailsSpan = columnCount + (hasStartCell ? 1 : 0) + (hasEndCell ? 1 : 0)

  /**
   * Any time table row model changes causing a new group of rows to be rendered all rows will be deselected.
   */
  const rowModel = table.getRowModel()
  useEffect(() => {
    table.resetRowSelection()
    setHasAllTableRowsSelected(false)
  }, [rowModel, setHasAllTableRowsSelected, table])

  return (
    <StyledHeader data-cy={dataCy && `${dataCy}:header`}>
      <tr>
        <When condition={hasStartCell}>
          <HeaderCell>
            <RowActions reduceRightPadding>
              <When condition={hasRowsWithDetails}>
                <Button
                  size="small"
                  emphasis="low"
                  color="neutral"
                  icon={areAllRowsExpanded ? CollapseAll : ExpandAll}
                  tooltip={areAllRowsExpanded ? 'Collapse All Rows' : 'Expand All Rows'}
                  onClick={areAllRowsExpanded ? collapseAllRows : expandAllRows}
                />
              </When>
              <When condition={hasSelectableRows}>
                <CheckboxContainer>
                  <Checkmark
                    checked={hasAllPageRowsSelected}
                    indeterminate={hasSomePageRowsSelected}
                    onClick={handleSelectionCheckboxClick}
                  />
                </CheckboxContainer>
              </When>
            </RowActions>
          </HeaderCell>
        </When>
        <If condition={columnCount === 0}>
          <Then>
            <HeaderCell index={0} />
          </Then>
          <Else>
            {headerGroup.headers.map((header, i) => (
              <HeaderCell
                key={header.id}
                align={header.column.columnDef.meta?.align}
                information={header.column.columnDef.meta?.information}
                index={i}
                {...(header.column.getCanSort() && {
                  onClick: header.column.getToggleSortingHandler(),
                })}
                sortDirection={
                  header.column.getIsSorted()
                    ? (header.column.getIsSorted() as SortDirection)
                    : undefined
                }
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
              </HeaderCell>
            ))}
          </Else>
        </If>
        <When condition={hasEndCell}>
          <HeaderCell isActionsColumn>
            {hasConfigurableColumns && (
              <RowActions reduceLeftPadding>
                <Button
                  size="small"
                  emphasis="low"
                  color="neutral"
                  icon={ConfigureColumns}
                  data-cy="configure-columns"
                  tooltip="Configure Columns"
                  onClick={handleSetIsConfiguringColumns}
                />
              </RowActions>
            )}
          </HeaderCell>
        </When>
      </tr>
      {isSelectMoreHeaderVisible && (
        <tr>
          <HeaderDetails colSpan={headerDetailsSpan}>
            <HeaderDetailsContent tableContainerWidth={tableContainerWidth}>
              <HeaderDetailsLabel>
                All{' '}
                <strong>{hasAllTableRowsSelected && hasMoreRows ? '' : quantitySelected}</strong>{' '}
                {itemName}s {hasAllTableRowsSelected ? allSelectedText : 'on this page'} are
                selected.
              </HeaderDetailsLabel>
              {hasAllTableRowsSelected ? (
                <Button
                  size="small"
                  emphasis="low"
                  text="Clear selection"
                  hasReducedPadding
                  isBold={false}
                  onClick={handleClearRowSelection}
                />
              ) : (
                <Button
                  size="small"
                  emphasis="low"
                  text={`Select all ${
                    hasMoreRows ? '' : rowCount || table.getFilteredRowModel().rows.length
                  } ${itemName}s ${allSelectedText}`}
                  hasReducedPadding
                  isBold={false}
                  onClick={handleSelectAllTableRows}
                />
              )}
            </HeaderDetailsContent>
          </HeaderDetails>
        </tr>
      )}
    </StyledHeader>
  )
}

const StyledHeader = styled.thead({
  position: 'sticky',
  top: 0,
  zIndex: 3,
  fontSize: 12,
  lineHeight: '16px',
  fontWeight: 800,
  color: COLOR.NEUTRAL[800],
  background: COLOR.NEUTRAL[100],
})

const CheckboxContainer = styled.div({
  display: 'inline-flex',
  paddingLeft: 4,
  paddingRight: 4,
})

const HeaderDetails = styled.td({
  textAlign: 'center',
  fontWeight: 500,
  fontSize: 14,
  borderBottom: `1px solid ${COLOR.NEUTRAL[300]}`,
  padding: 0,
})

const HeaderDetailsLabel = styled.div({})

const HeaderDetailsContent = styled.div<{
  tableContainerWidth: number
}>(({ tableContainerWidth }) => ({
  position: 'sticky',
  left: 0,
  width: tableContainerWidth,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px 16px',
}))
