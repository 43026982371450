import type { Action } from '../actions'

export type ByNameReducerState = Record<string, boolean>

export const initialState: ByNameReducerState = {}

export default function isUploadingByName(
  state = initialState,
  action: Action,
): ByNameReducerState {
  switch (action.type) {
    case 'ADMIN_CLAIM_PHOTO_UPLOAD_REQUEST':
      return { ...state, [action.payload.name]: true }
    case 'ADMIN_CLAIM_PHOTO_UPLOAD_SUCCESS':
    case 'ADMIN_CLAIM_PHOTO_UPLOAD_FAILURE':
      return { ...state, [action.payload.name]: false }
    default:
      return state
  }
}
