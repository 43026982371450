import * as fetchAllActions from './fetch-all'
import * as fetchActions from './fetch'
import * as createActions from './create'
import * as fetchTermVersionLanguagesActions from './fetch-term-version-languages'
import * as termsVersionImportActions from './terms-verison-import'
import * as errorReset from './error-reset'
import type { ExtractActions } from '../../types/utility'

export type ActionType = Action['type']

export type Action =
  | ExtractActions<typeof fetchAllActions>
  | ExtractActions<typeof fetchActions>
  | ExtractActions<typeof fetchTermVersionLanguagesActions>
  | ExtractActions<typeof termsVersionImportActions>
  | ExtractActions<typeof errorReset>
  | ExtractActions<typeof createActions>

const actions = {
  ...fetchAllActions,
  ...termsVersionImportActions,
  ...fetchActions,
  ...fetchTermVersionLanguagesActions,
  ...errorReset,
  ...createActions,
}

export { actions }
