import type { FC } from 'react'
import React from 'react'
import { Redirect } from 'react-router'
import { AuthLayout } from '../../hoc/auth-layout'
import { HeadTag } from '../../components/head-tag'
import { OktaLoginForm } from './okta-login-form'
import { useUser } from '../../hooks/use-user'

const OktaLogin: FC = () => {
  const { isLoggedIn } = useUser()
  if (isLoggedIn) {
    return <Redirect to="/" />
  }

  return (
    <AuthLayout showFooter>
      <HeadTag siteTitle="Extend | Login" />
      <OktaLoginForm />
    </AuthLayout>
  )
}

export { OktaLogin }
