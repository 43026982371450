import styled from '@emotion/styled'
import { Badge, Grid } from '@helloextend/zen'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import { getProductStatusTooltipOptions } from '../../../../../../../../../utils/products'

const TooltipOptions: FC = () => {
  const tooltipOptions = useMemo(() => getProductStatusTooltipOptions(), [])

  return (
    <>
      {tooltipOptions.map((option) => (
        <TooltipOptionContainer key={option.title}>
          <Grid columns={2}>
            <BadgeContainer>
              <Badge
                text={option.title}
                color={option.color}
                data-cy={`info-${option.title}-badge`}
              />
            </BadgeContainer>
            <Paragraph data-cy={`info-${option.title}-description`}>{option.description}</Paragraph>
          </Grid>
        </TooltipOptionContainer>
      ))}
    </>
  )
}

const TooltipOptionContainer = styled.div({
  margin: '16px 0',
})

const BadgeContainer = styled.div({
  textAlign: 'right',
  paddingRight: 16,
})

const Paragraph = styled.p({
  margin: 0,
  padding: 0,
})

export { TooltipOptions }
