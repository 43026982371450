import type { ScriptItem } from '@helloextend/extend-api-rtk-query'
import { MessageType } from '@helloextend/extend-api-rtk-query'

export const generateImageUploadMessageBlock = (): ScriptItem => ({
  collect: {
    options: [
      {
        action: 'execute',
        execute: {
          scriptIndex: -1,
        },
        default: true,
      },
    ],
  },
  reply: {
    messages: [
      {
        type: MessageType.text,
        content: '',
      },
    ],
    prompt: {
      options: [],
      type: 'imageUpload',
      slot: 'ClaimPhotoUpload',
      presignedPost: {
        action: 'replace',
        replace: {
          invoke: 'generatePresignedPost',
          expects: {
            type: 'object',
            schema: 'PresignedPost',
          },
        },
      },
    },
  },
})
