import { faker } from '@faker-js/faker/locale/en'
import type { ClaimsUser } from '@helloextend/extend-api-client'

export const generateClaimsUser = (overrides: Partial<ClaimsUser> = {}): ClaimsUser => {
  return {
    id: faker.datatype.uuid(),
    firstName: faker.name.firstName(),
    lastName: faker.name.lastName(),
    email: faker.internet.exampleEmail(),
    isActive: true,
    updatedBy: faker.name.firstName(),
    createdAt: faker.date.recent().getTime(),
    updatedAt: faker.date.recent().getTime(),
    ...overrides,
  }
}
