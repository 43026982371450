import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useToggle } from '@helloextend/client-hooks'
import type { Servicer, ServicerReplacementFulfillmentMethod } from '@helloextend/extend-api-client'
import type { AdvancedSelectChangeEvent } from '@helloextend/zen'
import { AdvancedSelect, Grid, Information, Stack, Switch } from '@helloextend/zen'
import { StackAlign } from '@helloextend/zen/src/components/stack'
import { Subheading } from '@helloextend/zen/src/tokens/typography'
import {
  useUpdateServicerMutation,
  useUpsertMerchantServicingSettingsMutation,
} from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import type { MerchantServicingSettings } from '@helloextend/extend-api-rtk-query/src/servicers/types'
import { LDFlag } from '@helloextend/portal/src/constants/ld-flags'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ServicingModal } from '../servicing-modal'
import { SaveChangesButtonGroup } from '../save-changes-button-group'
import { formatFulfillmentOption } from './utils'

type ReplacementFulfillmentProps = {
  servicer: Servicer
  merchantServicerSettings: MerchantServicingSettings
}

const ReplacementFulfillment: FC<ReplacementFulfillmentProps> = ({
  servicer,
  merchantServicerSettings,
}) => {
  const {
    [LDFlag.MerchantServicingSettings]: FF_MERCHANT_SERVICING_SETTINGS,
    [LDFlag.SODirectPayment]: FF_SO_DIRECT_PAYMENT,
  } = useFlags() // [CS-780] remove the merchant-servicing settings FF
  const servicerSettings = FF_MERCHANT_SERVICING_SETTINGS
    ? merchantServicerSettings
    : servicer.settings
  const { replacementFulfillmentMethod, fulfillmentEnabled } = servicerSettings
  const [activeDropdownSelection, setActiveDropdownSelection] = useState(
    replacementFulfillmentMethod || 'manual',
  )

  const [hasToggleChanged, setHasToggleChanged] = useState(false)

  const [isModalDisplayed, { on: showModal, off: hideModal }] = useToggle(false)
  const [isToggleOn, handleToggle] = useToggle(fulfillmentEnabled)

  const { toastCompleted, toastError } = useStandardToast()
  const [updateServicer] = useUpdateServicerMutation()
  const [upsertMerchantServicingSettings, { isSuccess, isError, isLoading }] =
    useUpsertMerchantServicingSettingsMutation()

  const handleModalSubmit = (): void => {
    const settings = {
      fulfillmentEnabled: !isToggleOn,
      replacementFulfillmentMethod,
    }
    if (!isToggleOn) {
      // merchant servicer can not fulfill using virtual cards so automatically switching to manual
      settings.replacementFulfillmentMethod = 'manual'
      setActiveDropdownSelection('manual')
    }
    updateServicer({
      servicerId: servicer.id,
      body: { settings },
    })
    upsertMerchantServicingSettings({
      sellerId: merchantServicerSettings.sellerId,
      ...settings,
    })
    setHasToggleChanged(true)
  }

  const handleSave = (): void => {
    updateServicer({
      servicerId: servicer.id,
      body: {
        settings: {
          replacementFulfillmentMethod: activeDropdownSelection,
        },
      },
    })
    upsertMerchantServicingSettings({
      sellerId: merchantServicerSettings.sellerId,
      replacementFulfillmentMethod: activeDropdownSelection,
    })
  }

  const handleCancel = (): void => {
    setActiveDropdownSelection(replacementFulfillmentMethod || 'manual')
  }

  const handleNewDropdownSelection = (e: AdvancedSelectChangeEvent): void => {
    // disallow clearing the selection
    if (e.target.value) {
      setActiveDropdownSelection(e.target.value as ServicerReplacementFulfillmentMethod)
    }
  }

  useEffect(() => {
    if (isSuccess && hasToggleChanged) {
      hideModal()
      handleToggle.toggle()
      setHasToggleChanged(false)
    }
  }, [handleToggle, hasToggleChanged, hideModal, isSuccess])

  useEffect(() => {
    if (isSuccess) {
      toastCompleted('Servicing Settings have been successfully updated.')
    }
  }, [isSuccess, toastCompleted])

  useEffect(() => {
    if (isError) {
      toastError('There was an error updating the Servicing Settings. Please try again.')
    }
  }, [isError, toastError])

  return (
    <div data-cy="replacement-fulfillment">
      <ServicingModal
        isOpen={isModalDisplayed}
        isEnable={!isToggleOn}
        optionName="Replacement Fulfillment"
        onClickCancel={hideModal}
        onClickSubmit={handleModalSubmit}
        isProcessing={isLoading}
        enableMessage="Enabling replacement fulfillment will assign all replacement service orders to this merchant. The merchant is responsible for fulfilling its service orders."
        disableMessage="Disabling replacement fulfillment will assign all replacement service orders to Extend. Extend will service all new incoming claims. Claims that are currently in progress will still need to be resolved by the merchant."
      />
      <ContainerWithMargin>
        <Stack align={StackAlign.center}>
          <Subheading>Replacement fulfillment</Subheading>
          <Information buttonSize="xsmall">
            Control whether the Merchant or Extend fulfills replacement claims.
          </Information>
        </Stack>
      </ContainerWithMargin>
      <SwitchOrGridContainer>
        <Switch
          label="Merchant replacement fulfillment"
          isOn={isToggleOn}
          onChange={showModal}
          data-cy="replacement-fulfillment-toggle"
        />
      </SwitchOrGridContainer>
      <ContainerWithMargin>
        <SwitchOrGridContainer>
          <Grid columns={4}>
            <AdvancedSelect
              id="replacement-fulfillment"
              isNotClearable
              data-cy="replacement-fulfillment-dropdown"
              label="Fulfillment Method"
              multiple={false}
              onChange={handleNewDropdownSelection}
              value={activeDropdownSelection}
              options={formatFulfillmentOption(FF_SO_DIRECT_PAYMENT)}
            />
          </Grid>
        </SwitchOrGridContainer>
        {activeDropdownSelection !== replacementFulfillmentMethod && (
          <SaveChangesButtonGroup
            handleSave={handleSave}
            handleCancel={handleCancel}
            isLoading={isLoading}
            isSaveDisabled={isLoading}
            id="replacement-fulfillment"
          />
        )}
      </ContainerWithMargin>
    </div>
  )
}

const SwitchOrGridContainer = styled.div({
  marginBottom: 16,
})

const ContainerWithMargin = styled.div({
  marginBottom: 24,
})

export { ReplacementFulfillment }
