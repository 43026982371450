import type { UserGrant } from '@helloextend/extend-api-rtk-query'
import type { FC } from 'react'
import React from 'react'
import { getUserRolesFromGrants } from '../../utils/user-role-mapper'

type UserRoleProps = {
  grants: UserGrant[]
  roleFilter: 'internal' | 'merchant'
  accountId?: string
}

const UserRole: FC<UserRoleProps> = ({ grants, roleFilter, accountId }) => {
  return (
    <>
      <div data-cy="user-roles">{concatRoles(grants, roleFilter, accountId)}</div>
    </>
  )
}

const concatRoles = (
  grants: UserGrant[],
  roleFilter: 'internal' | 'merchant',
  accountId?: string,
): string => {
  const rolesFromGrants = getUserRolesFromGrants(grants, roleFilter, accountId)

  if (rolesFromGrants.length === 0) return ''

  if (rolesFromGrants.length > 2) {
    return `${rolesFromGrants
      .map((role) => role.display)
      .slice(0, 2)
      .join(', ')} + ${rolesFromGrants.length - 2} more`
  }

  return rolesFromGrants.map((role) => role.display).join(', ')
}

export default UserRole
