import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { Contract20220201GetResponse } from '@helloextend/extend-api-client'
import { ContractType } from '@helloextend/extend-api-client'
import { get } from 'lodash'
import { COLOR } from '@helloextend/zen'
import { getContractTypeCopy } from '../../../../../lib/contract-type'
import { headerInformationFields } from '../../fields'

export interface HeaderDetailsProps {
  contract?: Contract20220201GetResponse
}

const HeaderDetails: FC<HeaderDetailsProps> = ({ contract }) => {
  if (!contract) return null

  return (
    <div>
      {headerInformationFields.map(({ key, label }) => {
        let value = get(contract, key)

        if (key === 'type') value = getContractTypeCopy(value)
        else if (key === 'product.name' && contract.type === ContractType.SHIPPING_PROTECTION) {
          return null
        }

        return (
          <ContractDetail key={key}>
            <LabelHeading data-cy={`${key}-label`}>{`${label}:`}</LabelHeading>
            <LabelValue data-cy={`${key}-value`}>{value}</LabelValue>
          </ContractDetail>
        )
      })}
    </div>
  )
}

const LabelValue = styled.span()

const LabelHeading = styled.span({
  marginRight: 12,
  color: COLOR.NEUTRAL[600],
  fontWeight: 'bold',
})

const ContractDetail = styled.p({
  fontSize: 16,
  marginTop: 0,
  marginBottom: 7,
})

export { HeaderDetails }
