import type { VFC } from 'react'
import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import type { ColumnFilter } from '@tanstack/react-table'
import { format } from 'date-fns'
import { Chip } from '../../chip'
import { COLOR } from '../../../tokens/colors'
import { useDataTableContext } from '../data-table-context'
import { findFilterDef } from '../data-table-utils'
import { Filter } from './filter'
import type { FilterDef } from '../data-table-types'
import { intToCurrency } from '../../../utils'

export const getFilterText = (filter: ColumnFilter, filterDef: FilterDef): string => {
  switch (filterDef.type) {
    case 'select':
      return filterDef.isMultiSelect
        ? (filter.value as string[])
            .map((v) => {
              const option = filterDef.options.find((o) => o.value === v)
              return option ? option.display : v
            })
            .join(', ')
        : (filter.value as string)
    case 'numberRange':
      return (filter.value as number[])
        .map((v, i) => {
          const number = v
            ? v.toLocaleString(undefined, {
                useGrouping: true,
              })
            : undefined
          return number || (i === 0 ? 'No Min' : 'No Max')
        })
        .join(' – ')
    case 'dateRange':
      return Array.from(
        new Set(
          (filter.value as number[]).map((v, i) => {
            const date = v ? format(new Date(v), 'MMM d, yyyy') : undefined
            return date || (i === 0 ? 'No Min' : 'No Max')
          }),
        ),
      ).join(' – ')
    case 'currencyRange':
      return (filter.value as number[])
        .map((v, i) => {
          const { locale, currency } = filterDef
          const number = v
            ? intToCurrency(v, { currency, locale, currencyDisplay: 'narrowSymbol' })
            : undefined

          const noMinMax = i === 0 ? 'No Min' : 'No Max'
          return number || noMinMax
        })
        .join(' – ')
    default:
      return 'Unknown'
  }
}

export const AppliedFilters: VFC = () => {
  const { 'data-cy': dataCy, table, filterDefs } = useDataTableContext()

  const { globalFilter, columnFilters } = table.getState()

  const handleClearSearch = useCallback(() => {
    table.setGlobalFilter(null)
  }, [table])

  const handleClearFilter = useCallback(
    (id) => {
      table.getColumn(id).setFilterValue(null)
    },
    [table],
  )

  return (
    <Filters data-cy={dataCy && `${dataCy}:applied-filters`}>
      {globalFilter && (
        <Chip label="Search" text={globalFilter} onDismissClick={handleClearSearch} />
      )}
      {columnFilters.map((filter) => {
        const filterDef = findFilterDef(filter.id, filterDefs)
        return (
          <React.Fragment key={filter.id}>
            {filterDef ? (
              <Filter
                filterDef={filterDef}
                triggerRenderer={() => (
                  <Chip
                    label={(filterDef.type !== 'group' && filterDef.chipLabel) || filterDef.label}
                    text={getFilterText(filter, filterDef)}
                    onDismissClick={() => handleClearFilter(filter.id)}
                  />
                )}
              />
            ) : (
              <Chip
                label={table.getColumn(filter.id).columnDef.header?.toString()}
                text={typeof filter.value === 'string' ? filter.value : 'Unknown'}
                onDismissClick={() => handleClearFilter(filter.id)}
              />
            )}
          </React.Fragment>
        )
      })}
    </Filters>
  )
}

const Filters = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 4,
  borderColor: COLOR.NEUTRAL[300],
  borderStyle: 'solid',
  borderWidth: '1px 1px 0',
  padding: '10px',
})
