import React, { forwardRef } from 'react'
import { createPortal } from 'react-dom'
import { COLOR } from '@helloextend/zen'
import styled from '@emotion/styled'
import type { TooltipPosition } from '../../utils/calculate-tooltip-position'
import { InformationSize } from './types'

const informationWidths: Record<InformationSize, number> = {
  [InformationSize.small]: 240,
  [InformationSize.regular]: 360,
  [InformationSize.large]: 480,
}

interface InformationBubbleProps {
  children: React.ReactNode
  isVisible: boolean
  position: TooltipPosition
  size: InformationSize
  'data-cy'?: string
}

const InformationBubble = forwardRef<HTMLDivElement, InformationBubbleProps>(
  ({ children, isVisible, position, size, 'data-cy': dataCy }, ref) => {
    return createPortal(
      <InformationBubbleWrapper
        className={isVisible ? 'shown' : ''}
        ref={ref}
        position={position}
        size={size}
        data-cy={dataCy}
      >
        {children}
      </InformationBubbleWrapper>,
      document.body,
    )
  },
)

const InformationBubbleWrapper = styled.div(
  ({ position, size }: { position: TooltipPosition; size: InformationSize }) => ({
    position: 'absolute',
    top: position.top,
    bottom: position.bottom,
    left: -10000, // Position off screen when hidden so we can still calculate size but won't cover other elements
    right: position.right,
    maxWidth: informationWidths[size],
    backgroundColor: COLOR.NEUTRAL[800],
    color: COLOR.WHITE,
    padding: 16,
    borderRadius: 4,
    opacity: 0,
    overflow: 'hidden',
    zIndex: 1000,
    pointerEvents: 'none',
    transition: 'opacity 300ms',
    boxSizing: 'border-box',

    '&.shown': {
      left: position.left,
      opacity: 1,
      animation: `show 300ms ease forwards`,
      '@keyframes show': {
        '0%': {
          transform: 'translateY(-4px)',
        },
        '100%': {
          transform: 'translateY(0)',
        },
      },
    },
  }),
)

export { InformationBubble }
