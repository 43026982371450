import type { FC } from 'react'
import React, { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import type { Contract, Reply, Slot } from '@helloextend/extend-api-client'
import { claimsApi } from '@helloextend/extend-api-rtk-query'
import { Redirect } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ContactForm } from './contact-form'
import { AnswerTextArea } from './answer-text-area'
import { AnswerDatePicker } from './answer-date-picker'
import { AnswerSelection } from './answer-selection'
import { LeavePageGuard } from '../../../../../components/leave-page-guard'
import { ErrorMessage } from '../error-message'
import { AnswerImageUpload } from './answer-image-upload'

interface UserInputProps {
  contract: Contract
  hasUpdatedContract: boolean
  isIncredibotUpdating: boolean
  reply: Reply
  onIncredibotUpdate: (slot: Slot, slotValue: string | number) => void
  onUpdateContract: (updates: Partial<Contract>) => void
  toggleNavBlocked: (blocked: boolean) => void
  isNavBlocked: boolean
}

const UserInput: FC<UserInputProps> = ({
  contract,
  hasUpdatedContract,
  isIncredibotUpdating,
  reply,
  onIncredibotUpdate,
  onUpdateContract,
  toggleNavBlocked,
  isNavBlocked,
}) => {
  const { prompt, claimId } = reply

  const history = useHistory()

  const handleLeavePage = useCallback(
    (path: string): void => {
      history.push(path)
    },
    [history],
  )

  const pageGuardProps = useMemo(
    () => ({
      isNavBlocked: true,
      handleLeavePage,
      mainText: `Cancel this customer’s claim?`,
      confirmation: 'Your progress will not be saved on this claim. Are you sure you want to exit?',
      submitButtonText: 'Confirm',
    }),
    [handleLeavePage],
  )

  const dispatch = useDispatch()

  if (claimId) {
    dispatch(claimsApi.util.invalidateTags(['claims']))
    return <Redirect to={`/admin/contracts/${contract.id}/file-a-claim/${claimId}`} />
  }

  if (!hasUpdatedContract) {
    return (
      <UserInputWrapper>
        <LeavePageGuard {...pageGuardProps} isNavBlocked={isNavBlocked} />
        <ContactForm
          contract={contract}
          onSubmit={onUpdateContract}
          toggleNavBlocked={toggleNavBlocked}
        />
      </UserInputWrapper>
    )
  }

  switch (prompt?.type) {
    case 'input':
      return (
        <UserInputWrapper>
          <LeavePageGuard {...pageGuardProps} />
          <AnswerTextArea
            onSubmit={onIncredibotUpdate}
            reply={reply}
            isLoading={isIncredibotUpdating}
          />
        </UserInputWrapper>
      )
    case 'datepicker':
      return (
        <UserInputWrapper>
          <LeavePageGuard {...pageGuardProps} />
          <AnswerDatePicker
            onSubmit={onIncredibotUpdate}
            reply={reply}
            isLoading={isIncredibotUpdating}
          />
        </UserInputWrapper>
      )
    case 'imageUpload':
      return (
        <UserInputWrapper>
          <LeavePageGuard {...pageGuardProps} />
          <AnswerImageUpload
            onSubmit={onIncredibotUpdate}
            reply={reply}
            isLoading={isIncredibotUpdating}
          />
        </UserInputWrapper>
      )
    case 'multiselect':
    case 'buttons':
      return (
        <UserInputWrapper>
          <LeavePageGuard {...pageGuardProps} />
          <AnswerSelection
            onSubmit={onIncredibotUpdate}
            reply={reply}
            isLoading={isIncredibotUpdating}
          />
        </UserInputWrapper>
      )
    default:
      return <ErrorMessage contractId={contract.id} reply={reply} />
  }
}

const UserInputWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 32,
  width: '100%',
  h2: {
    textAlign: 'center',
  },
})

export { UserInput }
