import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Button, COLOR } from '@helloextend/zen'
import { H2 } from '@helloextend/merchants-ui/src/components/typography'
import logger from '@helloextend/client-logger'
import { ErrorBrokenLinks, ErrorCrossCircle } from '../../../../components/icons'

interface FileClaimErrorProps {
  handleOnClickContractDetails: () => void
  contractId: string
  claimId: string
  error: string
}

const FileClaimError: FC<FileClaimErrorProps> = ({
  handleOnClickContractDetails,
  contractId,
  claimId,
  error,
}) => {
  logger.error('User encountered an error while attempting to fetch claim details', {
    claimId,
    contractId,
    error,
  })

  return (
    <MessageContainer>
      <IconGroup>
        <ErrorCrossCircle />
        <ErrorBrokenLinks />
      </IconGroup>
      <H2>A problem occurred while submitting this claim...</H2>
      <ErrorText>
        There was a system error while submitting this claim. Extend has been notified of this
        failure, and will reach out to the customer directly.
      </ErrorText>
      <SupportText>
        If you need immediate help, contact our support team at{' '}
        <MessageLink href="mailto:support@extend.com">support@extend.com</MessageLink>, and provide
        this contract ID: {contractId}
      </SupportText>
      <Button size="small" text="Exit" onClick={handleOnClickContractDetails} />
    </MessageContainer>
  )
}

const IconGroup = styled.div({
  marginBottom: 32,
  svg: {
    margin: '0 12px',
  },
})

const MessageContainer = styled.div({
  margin: 'auto',
  position: 'relative',
  textAlign: 'center',
  top: '50%',
  transform: 'translateY(-50%)',
  width: 826,
  p: {
    fontSize: 20,
  },
})

const MessageLink = styled.a({
  color: COLOR.BLUE[600],
  textDecoration: 'underline',
  '&:visited': {
    color: COLOR.BLUE[600],
    textDecoration: 'underline',
  },
})

const ErrorText = styled.p({
  marginTop: 40,
})

const SupportText = styled.p({
  marginBottom: 56,
})

export { FileClaimError }
