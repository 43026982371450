import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { Button, TextArea } from '@helloextend/merchants-ui'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import type { ContractFormValuesUpdate } from '@helloextend/extend-api-client'
import type { ErrorObject, ErrorReducerState } from '@helloextend/core-api-redux/src/types/error'
import * as selectors from '../../reducers/selectors'
import type { RootState } from '../../reducers'
import * as contractsActions from '../../actions/contracts'
import * as productActions from '../../actions/products'
import { BasicModal } from '../basic-modal'
import { SuccessfulSubmit } from '../successful-submit'

type SaveContractModalProps = {
  isVisible: boolean
  contractId: string
  toggle: () => void
  updateValues?: ContractFormValuesUpdate
}

export const combineErrors = (errors: ErrorReducerState[]): ErrorObject => {
  const combinedError: ErrorObject = {
    message: '',
  }
  for (const error of errors) {
    if (error) {
      if (error.name) {
        combinedError.message += `name: ${error.name} - `
      }
      if (error.statusCode) {
        combinedError.message += `status: ${error.statusCode} - `
      }
      combinedError.message += `msg: ${error.message}\n`
    }
  }
  return combinedError
}

const SaveContractModal: FC<SaveContractModalProps> = ({
  isVisible,
  toggle,
  contractId,
  updateValues,
}) => {
  const dispatch = useDispatch()
  const accessToken = useSelector((state: RootState) => selectors.getAccessToken(state)) || ''
  const isContractUpdating = useSelector((state: RootState) =>
    selectors.getIsContractUpdating(state),
  )
  const isContractUpdateSuccessful = useSelector((state: RootState) =>
    selectors.getIsContractUpdateSuccess(state),
  )
  const contractErrors = useSelector((state: RootState) => selectors.getContractsError(state))

  const productErrors = useSelector((state: RootState) => selectors.getProductsError(state))

  const { values, dirty, errors, handleBlur, handleChange, resetForm } = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: { description: '' },
    validationSchema: Yup.object().shape({ description: Yup.string().required() }).defined(),
    onSubmit: () => {},
  })

  const handleSaveChanges = (): void => {
    if (updateValues) {
      dispatch(
        contractsActions.updateV2(contractId, accessToken, updateValues.contractUpdate.values),
      )
      if (updateValues.productUpdate) {
        dispatch(
          productActions.update(
            updateValues.productUpdate.args.storeId,
            updateValues.productUpdate.args.referenceId,
            updateValues.productUpdate.values,
            accessToken,
          ),
        )
      }
    }
  }

  useEffect(() => {
    if (!isContractUpdating && isContractUpdateSuccessful && isVisible) {
      resetForm()
      toggle()
    }
  }, [isContractUpdateSuccessful, isContractUpdating, isVisible, toggle, resetForm])

  return (
    <BasicModal isVisible={isVisible} onClickClose={toggle}>
      {isContractUpdating || isContractUpdateSuccessful ? (
        <SuccessfulSubmit
          isLoading={isContractUpdating}
          submitted={isContractUpdateSuccessful}
          error={combineErrors([contractErrors, productErrors])}
          errorTitle="An error occurred while trying to update the contract"
          successTitle="Contract changes saved!"
          buttonText="Done"
          handleClick={toggle}
        />
      ) : (
        <>
          <Title data-cy="saving-changes-header">Saving Changes</Title>
          <DetailText data-cy="saving-changes-copy">
            Please provide comments or a description to summarize the changes you are saving for
            this section.
          </DetailText>
          <TextArea
            id="description"
            dataCy="description-input"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            resize="none"
            width={100}
            height={160}
            placeholder=""
          />
          <ButtonGroup>
            <Button text="Cancel" kind="secondary" size="sm" onClick={toggle} />
            <Button
              text="Save changes"
              kind="primary"
              size="sm"
              disabled={Boolean(!dirty || errors.description)}
              onClick={handleSaveChanges}
            />
          </ButtonGroup>
        </>
      )}
    </BasicModal>
  )
}

const Title = styled.h3({
  fontSize: 20,
  lineHeight: '27px',
  marginBottom: 16,
})

const DetailText = styled.p({
  fontSize: 16,
  marginBottom: 16,
  lineHeight: '22px',
})

const ButtonGroup = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 16,
  marginTop: 24,
})

export { SaveContractModal, SaveContractModalProps }
