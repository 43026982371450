import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import { Link } from 'react-router-dom'
import { COLOR } from '../../tokens/colors'
import { Icon, IconSize } from '../icon'
import type { IconProps } from '../../tokens/icons/icon-props'
import { isStringUrl } from '../../utils'
import { OpenInNew } from '../../tokens/icons'

export interface MenuLinkItemProps {
  children?: React.ReactNode
  endAdornment?: React.ReactElement
  'data-cy'?: string
  icon?: IconProps
  label?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  openInNew?: boolean
  tabIndex?: number
  to: string
  _hasCursor?: boolean
}

export const MenuLinkItem: FC<MenuLinkItemProps> = ({
  children,
  endAdornment,
  'data-cy': dataCy,
  icon,
  label,
  onClick,
  openInNew,
  tabIndex,
  to,
  _hasCursor,
}) => {
  const isOpenInNew = openInNew || isStringUrl(to)

  return (
    <StyledMenuLinkItem
      data-cy={dataCy}
      to={isStringUrl(to) ? { pathname: to } : to}
      target={isOpenInNew ? '_blank' : undefined}
      onClick={onClick}
      hasCursor={_hasCursor}
      tabIndex={_hasCursor ? 0 : tabIndex}
      role="link"
      {...(_hasCursor !== undefined && { 'data-is-cursorable': true })}
    >
      <StyledArea>
        <Content>
          {!!icon && (
            <IconContainer>
              <Icon icon={icon} color={COLOR.NEUTRAL[800]} />
            </IconContainer>
          )}
          <Children role="none">
            {label}
            {children}
          </Children>
          {isOpenInNew && (
            <Icon icon={OpenInNew} color={COLOR.NEUTRAL[800]} size={IconSize.small} />
          )}
        </Content>
        {!!endAdornment && <EndAdornmentContainer>{endAdornment}</EndAdornmentContainer>}
      </StyledArea>
    </StyledMenuLinkItem>
  )
}

const StyledArea = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 4,
  borderRadius: 4,
  padding: '10px 12px',
})

const Content = styled.div({
  display: 'flex',
  gap: 8,
  minWidth: 0,
})

const Children = styled.div({
  position: 'relative',
  maxWidth: '100%',
})

const StyledMenuLinkItem = styled(Link, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{
  hasCursor?: boolean
}>(({ hasCursor }) => ({
  display: 'block',
  padding: '2px 0',
  color: COLOR.NEUTRAL[800],
  cursor: 'pointer',
  transition: '50ms',
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: 500,
  textDecoration: 'none',
  ...(hasCursor && {
    outline: 'none',
  }),
  '&:hover': {
    textDecoration: 'none',
  },
  '&:visited': {
    color: COLOR.NEUTRAL[800],
  },
  [`& > ${StyledArea}`]: {
    background: 'transparent',
  },
  [`&:hover > ${StyledArea}`]: {
    background: COLOR.NEUTRAL.OPACITY[12],
  },
  [`&:active > ${StyledArea}`]: {
    background: COLOR.NEUTRAL.OPACITY[25],
  },
  ...(hasCursor
    ? {
        [`& > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[12],
        },
        [`&:hover:not(:disabled) > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[25],
        },
        [`&:active:not(:disabled) > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[12],
        },
      }
    : {
        [`& > ${StyledArea}`]: {
          background: 'transparent',
        },
        [`&:hover:not(:disabled) > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[12],
        },
        [`&:active:not(:disabled) > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[25],
        },
      }),
}))

const IconContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  width: 24,
  height: 24,
  margin: '-2px 0 -2px -2px',
})

const EndAdornmentContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 4,
  flexShrink: 0,
  marginLeft: 'auto',
  margin: '-2px -4px -2px 4px',
})
