import type {
  ServicerLocationCreateRequest,
  ServicerLocation,
} from '@helloextend/extend-api-client/src/models/servicer'
import { createActionFailure } from '../../factories'

export const create = (
  servicerId: string,
  body: ServicerLocationCreateRequest,
  accessToken: string,
) => ({ type: 'SERVICER_LOCATIONS_CREATE', payload: { servicerId, accessToken, body } } as const)

export const createRequest = () => ({ type: 'SERVICER_LOCATIONS_CREATE_REQUEST' } as const)

export const createSuccess = (servicerLocation: ServicerLocation) =>
  ({
    type: 'SERVICER_LOCATIONS_CREATE_SUCCESS',
    payload: { servicerLocation },
  } as const)

export const createFailure = createActionFailure('SERVICER_LOCATIONS_CREATE_FAILURE')
