import type { HeaderIndexMap } from './csv-validation-models'
/**
 * Collects the indexes for each of the headers in a CSV file into a set of key-value pairs
 * where the key is the header name and the value is the index/column number in the CSV file
 * where values for that header are stored. Used to allow fast retrieveal of the value for a
 * given header
 */
export function getCsvHeaderIndexes<THeaderName extends string>(
  headers: readonly THeaderName[],
): HeaderIndexMap<typeof headers[number]> {
  return headers.reduce((indexMap: HeaderIndexMap<THeaderName>, header, index) => {
    return {
      ...indexMap,
      [header]: index,
    }
  }, {} as HeaderIndexMap<THeaderName>)
}
