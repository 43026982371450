// really simple query string parsing
export type QSParams = Record<string, string | number | boolean | undefined>
export function parse<T extends QSParams>(search: string): T {
  return search
    .replace(/^\?/, '')
    .split('&')
    .reduce((params, pair) => {
      const [key, value] = pair.split('=')
      return { ...params, [decodeURIComponent(key)]: castValue(value) }
    }, {}) as T
}
function castValue(value: string): string | number | boolean {
  if (/^\d+$/.test(value)) return parseInt(value, 10)
  if (/^(true|false)$/.test(value)) return value === 'true'
  return decodeURIComponent(value)
}

export function build(obj: Record<string, string | number | boolean | undefined>): string {
  const qs = Object.entries(obj).reduce((queryitems: string[], [key, value]) => {
    if (value) {
      return [...queryitems, `${key}=${value}`]
    }
    return queryitems
  }, [])

  return qs.join('&')
}
