import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { get } from '@helloextend/extend-api-fetch'
import type { ServiceOrderShipment } from '../models/service-order-shipments'

export async function fetchByServiceOrderId(
  serviceOrderId: string,
  accessToken: string,
): Promise<ApiResponse<ServiceOrderShipment[]>> {
  return get(`/service-orders/${serviceOrderId}/service-order-shipments`, {
    accessToken,
  })
}
