import styled from '@emotion/styled'
import { useGetProductQuery } from '@helloextend/extend-api-rtk-query'
import type { FC } from 'react'
import React from 'react'
import { useParams } from 'react-router-dom'
import { COLOR, Spinner } from '@helloextend/zen'
import { SectionContainer } from '../../../../../../../components/section-container'
import { ManufacturerInfoForm } from './components/manufacturer-info-form'
import { ProductHeader } from './components/product-header'
import { ProductInfoForm } from './components/product-info-form'
import { ProductIdentifiersForm } from './components/product-identifiers-form'
import { ProductPlans } from './components/product-plans/product-plans'
import { ProductShippingProtection } from './components/product-shipping-protection/product-shipping-protection'
import { BundleManagement } from './components/bundle-management/bundle-management'
import { ProductServicingSettings } from './components/product-servicing-settings/product-servicing-settings'

const ProductDetails: FC = () => {
  const { storeId, referenceId } = useParams<{ storeId: string; referenceId: string }>()
  const {
    data: product,
    isLoading,
    refetch,
  } = useGetProductQuery({
    storeId,
    referenceId,
    version: 'latest',
  })

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </SpinnerContainer>
    )
  }

  if (!product) {
    return null
  }

  return (
    <>
      <ProductHeaderContainer>
        <ProductHeader product={product} />
      </ProductHeaderContainer>
      <SectionContainer title="Product Info">
        <ProductInfoForm product={product} />
      </SectionContainer>
      <ProductPlans product={product} />
      {product?.settings?.isBundle && (
        <BundleManagement product={product} refetchProduct={refetch} />
      )}
      <SectionContainer title="Manufacturer Info">
        <ManufacturerInfoForm product={product} />
      </SectionContainer>
      <ProductShippingProtection product={product} />
      <ProductServicingSettings product={product} />
      <SectionContainer title="Product Identifiers">
        <ProductIdentifiersForm product={product} />
      </SectionContainer>
    </>
  )
}

const ProductHeaderContainer = styled.div({
  marginBottom: 24,
})

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export { ProductDetails }
