import { Modal } from '@helloextend/zen'
import type { FC } from 'react'
import React from 'react'

type DeleteProductsModalProps = {
  handleDeleteConfirm: () => void
  handleDeleteCancel: () => void
  isProcessing: boolean
}

const DeleteProductsModal: FC<DeleteProductsModalProps> = ({
  handleDeleteConfirm,
  handleDeleteCancel,
  isProcessing,
}) => {
  return (
    <Modal
      data-cy="delete-products-modal"
      heading="Delete Products"
      primaryButtonProps={{
        text: 'Delete Products',
        'data-cy': 'confirm-delete-products',
        onClick: handleDeleteConfirm,
        color: 'red',
        isProcessing,
      }}
      secondaryButtonProps={{
        text: 'Cancel',
        'data-cy': 'cancel-delete-products',
        onClick: handleDeleteCancel,
        isDisabled: isProcessing,
      }}
    >
      <p>This will remove all the selected products from the store and it can not be undone.</p>
      <p>Are you sure you want to delete the products?</p>
    </Modal>
  )
}

export { DeleteProductsModal }
