import type { SyntheticEvent } from 'react'
import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { matchPath } from 'react-router'
import { NavIcon } from '../../nav-icon'
import type { NavIconProps } from '../../../tokens/nav-icons'
import { COLOR } from '../../../tokens/colors'
import { Icon, IconSize } from '../../icon'
import { ChevronRight } from '../../../tokens/icons'

export interface SidebarMenuItemProps {
  children?: React.ReactNode
  icon?: React.ElementType<NavIconProps>
  showArrow?: boolean
  isToggled?: boolean
  matchRouteTo?: string
  matchRouteExact?: boolean
  onClick?: (event: EventType) => void
  'data-cy'?: string
}

type EventType = SyntheticEvent<HTMLDivElement>

export const SidebarMenuItem = forwardRef<HTMLDivElement, SidebarMenuItemProps>(
  (
    {
      children,
      icon,
      showArrow = false,
      isToggled = false,
      matchRouteTo,
      matchRouteExact = false,
      onClick,
      'data-cy': dataCy,
    },
    ref,
  ) => {
    const match = matchPath(matchRouteTo || '', {
      exact: matchRouteExact,
    })
    const isInteractive = !!onClick
    return (
      <StyledSidebarMenuItem
        ref={ref}
        data-cy={dataCy}
        isToggled={(!!matchRouteTo && !!match) || isToggled}
        onClick={onClick}
        isInteractive={isInteractive}
      >
        <StyledArea>
          <Content>
            {!!icon && (
              <NavIconContainer>
                <NavIcon
                  icon={icon}
                  filled={isToggled}
                  color={isToggled ? COLOR.BLUE[700] : COLOR.NEUTRAL[800]}
                />
              </NavIconContainer>
            )}
            <Children data-cy="children">{children}</Children>
          </Content>
          {showArrow && (
            <ArrowContainer>
              <Icon icon={ChevronRight} size={IconSize.small} color={COLOR.NEUTRAL[800]} />
            </ArrowContainer>
          )}
        </StyledArea>
      </StyledSidebarMenuItem>
    )
  },
)

const Content = styled.div({
  display: 'flex',
  gap: 8,
  minWidth: 0,
})

const Children = styled.div({
  position: 'relative',
  maxWidth: '100%',
})

const StyledArea = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 4,
  borderRadius: 4,
  padding: '10px 16px',
})

const StyledSidebarMenuItem = styled.div<{
  isToggled: boolean
  isInteractive: boolean
}>(({ isToggled, isInteractive }) => ({
  padding: '3px 0',
  color: isToggled ? COLOR.NEUTRAL[1000] : COLOR.NEUTRAL[700],
  transition: '50ms',
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: 600,
  background: 'transparent',
  ...(isToggled
    ? {
        WebkitTextStroke: '0.04em',
        [`& > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[12],
        },
        ...(isInteractive && {
          cursor: 'pointer',
          [`&:hover > ${StyledArea}`]: {
            background: COLOR.NEUTRAL.OPACITY[25],
          },
          [`&:active > ${StyledArea}`]: {
            background: COLOR.NEUTRAL.OPACITY[12],
          },
        }),
      }
    : {
        ...(isInteractive && {
          cursor: 'pointer',
          [`&:hover > ${StyledArea}`]: {
            background: COLOR.NEUTRAL.OPACITY[12],
          },
          [`&:active > ${StyledArea}`]: {
            background: COLOR.NEUTRAL.OPACITY[25],
          },
        }),
      }),
}))

const NavIconContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  width: 24,
  height: 24,
  margin: '-2px 4px -2px -4px',
})

const ArrowContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  marginLeft: 'auto',
  marginRight: -4,
})
