import { faker } from '@faker-js/faker/locale/en'
import type { ClaimNote } from '@helloextend/extend-api-client'

export function generateClaimNote(overrides: any = {}): ClaimNote {
  return {
    id: faker.datatype.uuid(),
    claimId: faker.datatype.uuid(),
    createdBy: faker.name.firstName(),
    createdAt: faker.date.past().getTime(),
    updatedAt: faker.date.past().getTime(),
    text: faker.random.words(200),
    type: 'basic',
    ...overrides,
  }
}
