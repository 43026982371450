import type { FC } from 'react'
import React from 'react'
import type { AdvancedSelectChangeEvent, AdvancedSelectOption } from '@helloextend/zen'
import { AdvancedSelect } from '@helloextend/zen'

export interface CategorySelectorProps {
  onChange: (e: AdvancedSelectChangeEvent<string>) => void
  options: AdvancedSelectOption[]
  value?: string
  isDisabled: boolean
  subtext?: string
}

const CategorySelector: FC<CategorySelectorProps> = ({
  onChange,
  value = '',
  options,
  isDisabled,
  subtext,
}) => {
  return (
    <AdvancedSelect
      id="plan-sets-category-select"
      data-cy="plan-sets-category-select"
      label="Plan Category"
      placeholder="Select"
      onChange={onChange}
      value={value}
      options={options}
      multiple={false}
      showSearch
      isDisabled={isDisabled}
      subtext={subtext}
    />
  )
}

export { CategorySelector }
