import type { ProductCsvValidatorPipe, ProductHeader } from './product-csv-headers'

const BOOLEAN_COLUMNS: ProductHeader[] = ['toDelete', 'shippable', 'shipEnabled']

const validateBooleanColumns = (meta: ProductCsvValidatorPipe): ProductCsvValidatorPipe => {
  BOOLEAN_COLUMNS.forEach((attr) => {
    const index = meta.headerIndexes[attr]

    // If attribute isn't found in header map, it must be optional so we skip it.
    if (index === undefined) return

    const colValue = meta.rowData[index].toLowerCase()

    if (colValue === undefined) {
      meta.errors.push(`"${attr}" column is missing`)
    } else if (colValue.length > 0 && colValue !== 'true' && colValue !== 'false') {
      meta.errors.push(
        `"${attr}" must be one of three values: ['TRUE', 'FALSE', '' (blank)] needs review (${meta.rowData[index]})`,
      )
    }
  })

  return meta
}

export { validateBooleanColumns }
