export const isFileValidSize = (file: File, maxSizeMb: number): boolean => {
  return file.size / 1024 / 1024 < maxSizeMb
}

export const getFileNameFromUrl = (url: string): string => {
  return url.substring(url.lastIndexOf('/') + 1)
}

export const formatHumanReadableFileSize = (bytes: number): string => {
  const thresh = 1024
  const dp = 1
  let newBytes = bytes

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let u = -1
  const r = 10 ** dp

  do {
    newBytes /= thresh
    u += 1
  } while (Math.round(Math.abs(newBytes) * r) / r >= thresh && u < units.length - 1)

  return `${newBytes.toFixed(dp)} ${units[u]}`
}
