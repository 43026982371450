import { faker } from '@faker-js/faker/locale/en'
import type {
  Lead,
  LeadProduct,
  LeadsCreatedConfigurationGetResponse,
} from '@helloextend/extend-api-client'
import { LeadStatus } from '@helloextend/extend-api-client'

export const generateLead = (overrides: Partial<Lead> = {}): Lead => {
  return {
    createdAt: faker.date.past().getTime(),
    customer: {
      email: faker.internet.exampleEmail(),
    },
    leadToken: faker.random.alphaNumeric(32),
    product: generateLeadProduct(),
    quantity: 1,
    quantityConsumed: 0,
    sellerId: faker.datatype.uuid(),
    updatedAt: faker.date.past().getTime(),
    status: LeadStatus.LIVE,
    ...overrides,
  }
}

export const generateLeadProduct = (overrides: Partial<LeadProduct> = {}): LeadProduct => ({
  manufacturerWarrantyLengthLabor: 12,
  manufacturerWarrantyLengthParts: 12,
  listPrice: { amount: faker.datatype.number(), currencyCode: 'USD' },
  purchasePrice: { amount: faker.datatype.number(), currencyCode: 'USD' },
  referenceId: faker.datatype.uuid(),
  title: faker.commerce.productName(),
  transactionDate: faker.date.past().getTime(),
  transactionId: faker.datatype.uuid(),
  ...overrides,
})

export const generateLeadsConfigurations = (
  overrides: Partial<LeadsCreatedConfigurationGetResponse> = {},
): LeadsCreatedConfigurationGetResponse => ({
  enabled: true,
  emailType: 'transactional',
  callToActionUri: 'https://google.com',
  storeName: faker.company.name(),
  sendFromEmail: faker.internet.exampleEmail(),
  replyToEmail: faker.internet.exampleEmail(),
  buildFirstCommunication: true,
  buildSecondCommunication: true,
  buildThirdCommunication: false,
  buildFourthCommunication: false,
  merchantLogo: '',
  primaryColor: faker.color.rgb({ format: 'hex', casing: 'upper' }),
  lightBackgroundColor: faker.color.rgb({ format: 'hex', casing: 'upper' }),
  googleFont: 'Times New Roman',
  headerFont: 'Impact',
  heroButtonTextColor: faker.color.rgb({ format: 'hex', casing: 'upper' }),
  merchantAddress: '221 Main St. San Francisco, CA 94147',
  merchantPrivacyPolicyLink: faker.internet.url(),
  merchantPhoneNumber: faker.phone.number('###-###-####'),
  ...overrides,
})
