import * as fetchActions from './fetch'
import * as fetchByContractActions from './fetch-by-contract'
import * as searchActions from './search'
import * as updateActions from './update'
import * as createActions from './create'
import * as errorActions from './error'
import * as resetActions from './list-reset'
import type { ExtractActions } from '../../types/utility'

export type Action =
  | ExtractActions<typeof fetchActions>
  | ExtractActions<typeof fetchByContractActions>
  | ExtractActions<typeof searchActions>
  | ExtractActions<typeof updateActions>
  | ExtractActions<typeof createActions>
  | ExtractActions<typeof errorActions>
  | ExtractActions<typeof resetActions>

export type ActionType = Action['type']

const actions = {
  ...fetchActions,
  ...fetchByContractActions,
  ...searchActions,
  ...updateActions,
  ...createActions,
  ...errorActions,
  ...resetActions,
}

export { actions }
