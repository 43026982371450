import { ClaimCode } from '@helloextend/extend-api-client'

export function isValidEmail(inputText: string): boolean {
  return new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ).test(inputText)
}

export const isValidZIPCode = (text: string): boolean => {
  return new RegExp(/^\d{5}(?:[-]\d{4})?$/).test(text)
}

export const isValidProvinceCode = (text: string): boolean => {
  return new RegExp(/^[A-Za-z]{2}$/i).test(text)
}

export const isValidUuid = (text: string): boolean => {
  return new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/).test(text)
}

// removes only parens and dashes i.e. (555)123-4567 => 5551234567
export function removeNonNumsFromPhone(str: string): string {
  return str.replace(/[()]/g, '').replace(/-/g, '').replace(/\s/g, '')
}

export function validateIsNumberEntered(str: string, length?: number): boolean {
  const phoneNumber = removeNonNumsFromPhone(str)
  if (length && phoneNumber.length < length) return false

  const testNums = /^[0-9\b]+$/
  return testNums.test(phoneNumber)
}

export function formatPhoneNumber(str: string): string {
  const phoneNumber = removeNonNumsFromPhone(str)
  if (!validateIsNumberEntered(str)) {
    return ''
  }

  if (phoneNumber.length === 0) {
    return ''
  }

  if (phoneNumber.length < 4) {
    return `${phoneNumber}`
  }

  if (phoneNumber.length >= 4 && phoneNumber.length < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}`
  }

  if (phoneNumber.length >= 7 && phoneNumber.length <= 10) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  if (phoneNumber.length > 10) {
    return `${phoneNumber}`
  }

  return ''
}

export const urlRegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/

export const validateUrl = (input: string): boolean => {
  return urlRegExp.test(input)
}

export function isImagesRequired(validationError?: ClaimCode): boolean {
  // TODO: [CUST-1213] remove is tire or wheel check - https://helloextend.atlassian.net/browse/CUST-1213
  if (!validationError) return false
  return [ClaimCode.is_tire_or_wheel, ClaimCode.images_required].includes(validationError)
}

const whitespaceRegex = /\s/
export const hasWhitespace = (input: string): boolean => {
  return whitespaceRegex.test(input)
}
