import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import type * as Yup from 'yup'
import { AdvancedSelect, Checkbox, Grid, Information, Spinner, Stack } from '@helloextend/zen'
import { StackAlign } from '@helloextend/zen/src/components/stack'
import { Subheading } from '@helloextend/zen/src/tokens/typography'
import { useStandardToast } from '@helloextend/merchants-ui'
import {
  useListServicersQuery,
  useUpsertMerchantServicingSettingsMutation,
} from '@helloextend/extend-api-rtk-query'
import { useFormik } from 'formik'
import { SaveChangesButtonGroup } from '../save-changes-button-group'
import { getServicerOptions } from './utils'
import type { DepotRepairValues, OnsiteRepairValues } from './schema'

type PrimaryServicerRepairProps = {
  sellerId: string
  serviceType: 'repair_depot' | 'repair_onsite'
  schema: Yup.ObjectSchema
  initialValues: DepotRepairValues | OnsiteRepairValues
  checkboxProperty: 'autoAssignDepotRepairServicer' | 'autoAssignOnsiteServicer'
  dropdownServicerIdProperty: 'primaryDepotRepairServicerId' | 'primaryOnsiteServicerId'
  repairTypeName: 'Depot' | 'Onsite'
  dataCy?: string
}

const PrimaryServicerRepair: FC<PrimaryServicerRepairProps> = ({
  sellerId,
  serviceType,
  schema,
  initialValues,
  checkboxProperty,
  dropdownServicerIdProperty,
  repairTypeName,
  dataCy = 'primary-repair',
}) => {
  const [updateSettings, { isLoading, isError, isSuccess }] =
    useUpsertMerchantServicingSettingsMutation()
  const { data: servicerData, isLoading: isListLoading } = useListServicersQuery({
    serviceType,
  })

  const { toastCompleted, toastError } = useStandardToast()

  // assert type as one of schema keys to avoid type errors below
  const dropdownPropertyName = dropdownServicerIdProperty as keyof typeof values
  const checkboxPropertyName = checkboxProperty as keyof typeof values

  const { values, setFieldValue, submitForm, errors, dirty, handleChange, handleReset } = useFormik<
    DepotRepairValues | OnsiteRepairValues
  >({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: (fieldValues) => {
      updateSettings({ ...fieldValues, sellerId })
    },
  })

  const toggleAutoAssign = (): void => {
    setFieldValue(checkboxProperty, !values[checkboxPropertyName])
  }

  const servicerOptions = useMemo(
    () => getServicerOptions(servicerData?.items, isListLoading),
    [isListLoading, servicerData],
  )

  useEffect(() => {
    if (isSuccess) {
      toastCompleted('Servicing Settings have been successfully updated.')
    }
  }, [isSuccess, toastCompleted])

  useEffect(() => {
    if (isError) {
      toastError('There was an error updating the Servicing Settings. Please try again.')
    }
  }, [isError, toastError])

  if (isListLoading) {
    return <Spinner />
  }

  return (
    <div data-cy={dataCy}>
      <ContainerWithMargin>
        <Stack align={StackAlign.center}>
          <Subheading>{repairTypeName} Repair - Primary Servicer</Subheading>
          <Information buttonSize="xsmall">
            Automatically assign which service provider will handle all{' '}
            {repairTypeName.toLowerCase()} repairs.
          </Information>
        </Stack>
      </ContainerWithMargin>
      <CheckboxContainer>
        <Checkbox
          name={checkboxProperty}
          label="Automatically assign"
          checked={values[checkboxPropertyName]}
          onChange={toggleAutoAssign}
        />
      </CheckboxContainer>
      {values[checkboxPropertyName] && (
        <Grid columns={4}>
          <ContainerWithMargin>
            <AdvancedSelect
              id={dropdownServicerIdProperty}
              isNotClearable
              data-cy={dropdownServicerIdProperty}
              label="Primary Servicer"
              multiple={false}
              onChange={handleChange}
              value={values[dropdownPropertyName]}
              options={servicerOptions}
              isError={Boolean(errors[dropdownPropertyName])}
              errorFeedback={errors[dropdownPropertyName]}
            />
          </ContainerWithMargin>
        </Grid>
      )}
      {dirty && (
        <ContainerWithMargin>
          <SaveChangesButtonGroup
            handleSave={submitForm}
            handleCancel={handleReset}
            isLoading={isLoading}
            isSaveDisabled={isLoading || Boolean(Object.keys(errors).length)}
            id={`${repairTypeName.toLowerCase()}-repair`}
          />
        </ContainerWithMargin>
      )}
    </div>
  )
}

const CheckboxContainer = styled.div({
  marginBottom: 16,
})

const ContainerWithMargin = styled.div({
  marginBottom: 24,
})

export { PrimaryServicerRepair, PrimaryServicerRepairProps }
