import type { FC } from 'react'
import React, { useCallback, useMemo, useState } from 'react'
import { DataTable, Download, Reset, Upload } from '@helloextend/zen'
import type {
  DataTableAction,
  ColumnFiltersState,
  PaginationState,
  SortingState,
  VisibilityState,
  ButtonProps,
} from '@helloextend/zen'
import type { ProductsSearchArguments } from '@helloextend/extend-api-rtk-query'
import {
  useGetPlanMappingsQuery,
  useExportProductsMutation,
  useGetSearchCountQuery,
  useGetStoreCategoriesQuery,
  useSearchProductsPaginatedQuery,
} from '@helloextend/extend-api-rtk-query'
import { useToggle } from '@helloextend/client-hooks'
import type { ProductsSearchItem } from '@helloextend/extend-api-client'
import { useHistory } from 'react-router-dom'
import { useStandardToast } from '@helloextend/merchants-ui'
import { useQueryStringState } from '@helloextend/client-hooks/src/use-query-string-state'
import {
  getTableFilters,
  getTableColumns,
  getTableFilterOptions,
  getSearchFiltersFromQSParams,
} from './products-data-table-config'
import { EditProducts } from '../components/edit-products/edit-products'
import { ProductRowDetails } from '../components/product-row-details/product-row-details'

type ProductsDataTableProps = {
  storeId: string
  parentReferenceId?: string
}

const ProductsDataTable: FC<ProductsDataTableProps> = ({ storeId, parentReferenceId }) => {
  const history = useHistory()
  const [isEditModalDisplayed, { on: handleModalOn, off: handleModalOff }] = useToggle(false)

  const [sortKey, setSortKey] = useQueryStringState('sortKey', '')
  const [sortDirection, setSortDirection] = useQueryStringState('sortDirection', 'asc')
  const [searchKey, setSearchKey] = useQueryStringState('searchKey', '')
  const [searchValue, setSearchValue] = useQueryStringState('searchValue', '')
  const [searchFilters, setSearchFilters] = useQueryStringState('filters', {}, { encode: true })

  const [selectedProducts, setSelectedProducts] = useState<ProductsSearchItem[]>([])
  const [sorting, setSorting] = useState<SortingState>([
    { id: sortKey, desc: sortDirection === 'desc' },
  ])
  const [hasAllRowsSelected, setHasAllRowsSelected] = useState<boolean>(false)

  const [pagination, setPagination] = useQueryStringState<PaginationState>(
    'pagination',
    {
      pageIndex: 0,
      pageSize: 100,
    },
    { encode: true },
  )

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    ...getSearchFiltersFromQSParams(searchFilters),
    ...(searchKey && searchValue ? [{ id: searchKey, value: searchValue }] : []),
  ])

  const [globalFilter, setGlobalFilter] = useState('')

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    brand: false,
    mfrWarrantyParts: false,
    mfrWarrantyLabor: false,
    createdDt: false,
    // TODO: PUPS-1233
    // shipEnabled: false,
    // shippable: false,
    // partialReplacement: false,
    // productReturn: false,
  })

  const setQueryStringParams = useCallback((): void => {
    setSortKey(sorting[0]?.id || '')
    setSortDirection(sorting[0]?.desc ? 'desc' : 'asc')
    const searchParam = columnFilters.find(({ id }) => id === 'name' || id === 'referenceId')

    setSearchKey(searchParam?.id || '')
    setSearchValue((searchParam?.value as string) || '')
    const filters = getTableFilters(columnFilters)
    setSearchFilters(filters)
  }, [
    columnFilters,
    setSearchFilters,
    setSearchKey,
    setSearchValue,
    setSortDirection,
    setSortKey,
    sorting,
  ])

  const columns = useMemo(() => {
    return getTableColumns(!!parentReferenceId)
  }, [parentReferenceId])

  const argsForApi: ProductsSearchArguments = useMemo(() => {
    let args: ProductsSearchArguments = {
      storeId,
      sortDirection: sorting[0]?.desc ? 'desc' : 'asc',
      sortField: sorting[0]?.id || '',
      offset: pagination.pageIndex * pagination.pageSize,
      limit: pagination.pageSize,
    }
    if (parentReferenceId) {
      args.parentReferenceId = parentReferenceId
    }
    if (columnFilters.length > 0) {
      const filters = getTableFilters(columnFilters)
      args = { ...args, ...filters }
    }
    setQueryStringParams()
    return args
  }, [columnFilters, pagination, parentReferenceId, setQueryStringParams, sorting, storeId])

  const {
    data: productsData,
    isFetching: isProductDataFetching,
    refetch: refetchProductsData,
  } = useSearchProductsPaginatedQuery(argsForApi)

  const {
    data: productsCountData,
    isFetching: isCountDataFetching,
    refetch: refetchProductsCount,
  } = useGetSearchCountQuery(argsForApi)

  const { data: productCategoriesData, isLoading: isProductCategoriesLoading } =
    useGetStoreCategoriesQuery(storeId)

  const { planIds, isLoading: isPlansListLoading } = useGetPlanMappingsQuery(
    { storeId },
    {
      selectFromResult: ({ data, ...rest }) => ({
        planIds: data?.items.map((item) => item.planId) || [],
        ...rest,
      }),
    },
  )
  const [getProductsExportUrl, { isLoading: isGettingExportUrl }] = useExportProductsMutation()

  const { toastError } = useStandardToast()

  const handleRefetchData = (): void => {
    refetchProductsData()
    refetchProductsCount()
  }

  const handleEditClick = (selectedRows: ProductsSearchItem[]): void => {
    setSelectedProducts(selectedRows)
    handleModalOn()
  }

  const handleImportClick = (): void => {
    history.push(`/admin/stores/${storeId}/products/import`)
  }

  const handleExportClick = async (): Promise<void> => {
    await getProductsExportUrl(storeId)
      .unwrap()
      .then((res) => window.open(res.url, '_blank'))
      .catch(() => toastError('Something went wrong. Please try again.'))
  }

  const getTableActions = (selectedRows: ProductsSearchItem[]): DataTableAction[] => {
    if (selectedRows.length) {
      return [
        {
          onClick: () => handleEditClick(selectedRows),
          text: `Edit ${
            hasAllRowsSelected ? productsCountData?.totalProducts : selectedRows.length
          } Selected Products`,
          emphasis: 'medium',
          'data-cy': 'edit-products-button',
          color: 'neutral',
        },
      ]
    }

    const resetButton = {
      onClick: handleRefetchData,
      emphasis: 'medium' as ButtonProps['emphasis'],
      icon: Reset,
      'data-cy': 'refetch-product-button',
    }

    return parentReferenceId
      ? [resetButton]
      : [
          {
            onClick: handleImportClick,
            text: 'Import',
            icon: Upload,
            emphasis: 'medium',
            'data-cy': 'import-products-button',
          },
          {
            onClick: handleExportClick,
            text: 'Export All Products',
            icon: Download,
            emphasis: 'medium',
            isProcessing: isGettingExportUrl,
            'data-cy': 'export-products-button',
          },
          resetButton,
        ]
  }

  const isTableLoading =
    isProductDataFetching || isCountDataFetching || isProductCategoriesLoading || isPlansListLoading

  return (
    <>
      <DataTable
        data-cy="products-data-table"
        isLoading={isTableLoading}
        data={productsData?.products || []}
        columns={columns}
        hasSelectableRows
        pageSizeOptions={[10, 25, 50, 100, 250]}
        onPaginationChange={setPagination}
        pagination={pagination}
        rowCount={productsCountData?.totalProducts}
        sorting={sorting}
        onSortingChange={setSorting}
        hasManualSorting
        hasManualFiltering
        hasManualPagination
        columnFilters={columnFilters}
        onColumnFiltersChange={setColumnFilters}
        globalFilter={globalFilter}
        onGlobalFilterChange={setGlobalFilter}
        filterDefs={getTableFilterOptions(productCategoriesData?.categories || [], planIds)}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={setColumnVisibility}
        getTableActions={getTableActions}
        onSelectAllRowsChange={setHasAllRowsSelected}
        getRowHasDetail={(row) => row.variantCount === 0}
        rowDetailRenderer={(row) => <ProductRowDetails product={row} storeId={storeId} />}
      />

      <EditProducts
        isEditModalDisplayed={isEditModalDisplayed}
        selectedProducts={selectedProducts}
        handleEditModalOff={handleModalOff}
        handleEditModalOn={handleModalOn}
        storeId={storeId}
        refetchProducts={handleRefetchData}
        filters={argsForApi}
        useJob={hasAllRowsSelected}
        totalProducts={productsCountData?.totalProducts}
      />
    </>
  )
}

export { ProductsDataTable }
