import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import styled from '@emotion/styled'
import type { IconProps } from '../../tokens/icons/icon-props'
import { Toast } from './toast'
import type { ToastColor, ToastDuration } from './types'

const animationDurationMs = 400

interface AnimatedToastProps {
  id: number
  afterClose: (id: number) => void
  message: React.ReactNode
  toastColor: ToastColor
  toastDuration: ToastDuration
  icon?: IconProps
  'data-cy'?: string
}

const AnimatedToast: FC<AnimatedToastProps> = ({
  id,
  message,
  toastColor,
  afterClose,
  toastDuration,
  icon,
  'data-cy': dataCy,
}) => {
  const [isClosing, setIsClosing] = useState(false)

  const onDismissInternal = useCallback(() => {
    setIsClosing(true)
    setTimeout(() => {
      afterClose(id)
    }, animationDurationMs)
  }, [afterClose, id])

  return (
    <StyledAnimatedToast className={isClosing ? 'closing' : ''}>
      <Toast
        message={message}
        toastColor={toastColor}
        onDismiss={onDismissInternal}
        toastDuration={toastDuration}
        icon={icon}
        data-cy={dataCy}
      />
    </StyledAnimatedToast>
  )
}

const StyledAnimatedToast = styled.div({
  position: 'relative',
  bottom: 0,
  maxHeight: '50vh',
  animation: `up ${animationDurationMs}ms ease forwards`,
  '@keyframes up': {
    from: {
      bottom: '-100vh',
      maxHeight: 0,
    },
  },
  '&.closing': {
    animation: `down ${animationDurationMs}ms ease forwards`,
    '@keyframes down': {
      to: {
        bottom: '-100vh',
        maxHeight: 0,
      },
    },
  },
})

export { AnimatedToast, AnimatedToastProps }
