import { createApi } from '@reduxjs/toolkit/query/react'
import type { Lead, LeadSearchResponse } from '@helloextend/extend-api-client'
import { baseQuery } from '../base-query'
import type { LeadSearchQueryStringOptions } from './types'
import { safeBtoa } from '../../helpers'

const buildCacheKey = (qs: LeadSearchQueryStringOptions): string => {
  const { cursor, minLimit, ...qsWithoutPagination } = qs
  return safeBtoa(JSON.stringify(qsWithoutPagination))
}

export const leadsApi = createApi({
  baseQuery,
  reducerPath: 'Leads',
  tagTypes: ['Leads'],
  endpoints: (build) => ({
    getLead: build.query<Lead, string>({
      query: (leadToken: string) => ({
        url: `leads/${leadToken}`,
      }),
      providesTags: (_, _err) => [{ type: 'Leads' }],
    }),
    searchLeads: build.query<LeadSearchResponse, LeadSearchQueryStringOptions>({
      query: (qs: LeadSearchQueryStringOptions) => ({
        url: 'leads/search',
        params: qs,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest',
        },
      }),
      providesTags: (_, _err, qs) => [{ type: 'Leads', id: buildCacheKey(qs) }],
      async onCacheEntryAdded(arg, { getState, updateCachedData, cacheDataLoaded }) {
        const { cursor } = arg
        const { Leads } = getState()
        if (cursor?.length) {
          const queryValues = Leads.queries
          const cacheKey = buildCacheKey(arg)
          const getCachedKeys = Leads.provided.Leads[cacheKey]
          const cachedQueries = getCachedKeys.reduce(
            (newArr: LeadSearchResponse['items'], cachedDataKey) => {
              const cache = queryValues[cachedDataKey]
              const { items } = cache?.data as LeadSearchResponse
              return [...newArr, ...items]
            },
            [],
          )
          try {
            const { data } = await cacheDataLoaded

            updateCachedData((draft) => ({
              ...draft,
              items: [...cachedQueries, ...data.items],
            }))
          } catch (err) {
            console.error(err)
          }
        }
      },
    }),
    exportLeads: build.mutation<LeadSearchResponse, LeadSearchQueryStringOptions | undefined>({
      query: (qs?: LeadSearchQueryStringOptions) => ({
        url: 'leads/export',
        params: qs,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest',
        },
      }),
    }),
    downloadLeads: build.query<string, string>({
      query: (keyName: string) => ({
        url: `leads/download?keyName=${encodeURIComponent(keyName)}`,
      }),
      providesTags: (_, _err) => [{ type: 'Leads' }],
    }),
  }),
})

export const {
  useGetLeadQuery,
  useSearchLeadsQuery,
  useLazySearchLeadsQuery,
  useExportLeadsMutation,
  useLazyDownloadLeadsQuery,
} = leadsApi
