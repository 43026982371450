import type { User } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

// users fetch all
// =================================================================================
export const fetchAll = (accessToken: string) =>
  ({
    type: 'USERS_FETCH_ALL',
    payload: { accessToken },
  } as const)

export const fetchAllRequest = () =>
  ({
    type: 'USERS_FETCH_ALL_REQUEST',
  } as const)

export const fetchAllSuccess = (users: User[]) =>
  ({
    type: 'USERS_FETCH_ALL_SUCCESS',
    payload: users,
  } as const)

export const fetchAllFailure = createActionFailure('USERS_FETCH_ALL_FAILURE')
