import type { FC } from 'react'
import React from 'react'
import type { Store } from '@helloextend/extend-api-client'
import { Grid, Input, Switch } from '@helloextend/zen'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import { useStandardToast } from '@helloextend/merchants-ui'
import { useUpdateStoreMutation } from '@helloextend/extend-api-rtk-query'
import type { Values } from './schema'
import { schema } from './schema'
import { SaveChangesButtonGroup } from '../save-changes-button-group'

type BasicInfoFormProps = {
  store: Store
}

const BasicInfoForm: FC<BasicInfoFormProps> = ({ store }) => {
  const { values, errors, dirty, isValid, handleChange, handleBlur, handleReset } =
    useFormik<Values>({
      initialValues: {
        name: store.name || '',
        secondaryName: store.secondaryName || '',
        id: store.id || '',
        accountId: store.accountId || '',
        domain: store.domain || '',
        adminDealerNumber: store.adminDealerNumber || '',
        adminLocationNumber: store.adminLocationNumber || '',
        adminManufacturer: store.adminManufacturer || '',
        syncOrderProducts: Boolean(store.syncOrderProducts),
      },
      onSubmit: () => {},
      validationSchema: schema,
      enableReinitialize: true,
    })
  const [updateStore, { isLoading }] = useUpdateStoreMutation()

  const { toastCompleted, toastError } = useStandardToast()

  const handleUpdateStore = async (): Promise<void> => {
    await updateStore({
      storeId: store.id,
      data: {
        name: values.name,
        secondaryName: values.secondaryName,
        domain: values.domain,
        adminDealerNumber: values.adminDealerNumber,
        adminLocationNumber: values.adminLocationNumber,
        adminManufacturer: values.adminManufacturer,
        syncOrderProducts: values.syncOrderProducts,
      },
      version: 'latest',
    }).unwrap()
    try {
      toastCompleted('Basic Settings have been successfully updated.')
    } catch {
      toastError('Basic Settings could not be updated. Please try again.')
    }
  }

  return (
    <>
      <FormContainer>
        <Grid columns={4} spacing={2}>
          <Input
            id="name"
            label="Store Name"
            value={values.name || ''}
            placeholder="Enter store name"
            isError={Boolean(errors.name)}
            errorFeedback={errors.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Input
            id="secondaryName"
            label="Alternate Name"
            value={values.secondaryName || ''}
            placeholder="Enter alternate name"
            isError={Boolean(errors.secondaryName)}
            errorFeedback={errors.secondaryName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Input
            id="id"
            label="Store ID"
            value={values.id || ''}
            placeholder="Enter store ID"
            isError={Boolean(errors.id)}
            errorFeedback={errors.id}
            onChange={handleChange}
            onBlur={handleBlur}
            isDisabled
          />
          <Input
            id="accountId"
            label="Account ID"
            value={values.accountId || ''}
            placeholder="Enter account ID"
            isError={Boolean(errors.accountId)}
            errorFeedback={errors.accountId}
            onChange={handleChange}
            onBlur={handleBlur}
            isDisabled
          />
          <Input
            id="domain"
            label="Store URL"
            value={values.domain || ''}
            placeholder="Enter store URL"
            isError={Boolean(errors.domain)}
            errorFeedback={errors.domain}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Input
            id="adminDealerNumber"
            label="PCMI Dealer Number"
            value={values.adminDealerNumber || ''}
            placeholder="Enter PCMI Dealer Number"
            isError={Boolean(errors.adminDealerNumber)}
            errorFeedback={errors.adminDealerNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Input
            id="adminLocationNumber"
            label="PCMI Seller Location"
            value={values.adminLocationNumber || ''}
            placeholder="Enter PCMI Seller Location"
            isError={Boolean(errors.adminLocationNumber)}
            errorFeedback={errors.adminLocationNumber}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Input
            id="adminManufacturer"
            label="PCMI Manufacturer"
            value={values.adminManufacturer || ''}
            placeholder="Enter PCMI Manufacturer"
            isError={Boolean(errors.adminManufacturer)}
            errorFeedback={errors.adminManufacturer}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Switch
            label="Sync Order Products"
            id="syncOrderProducts"
            isOn={Boolean(values.syncOrderProducts)}
            onChange={handleChange}
            data-cy="sync-order-products"
          />
        </Grid>
      </FormContainer>
      {dirty && (
        <SaveChangesButtonGroup
          id="basic-info-form-buttons"
          handleSave={handleUpdateStore}
          handleCancel={handleReset}
          isSaveDisabled={!isValid}
          isLoading={isLoading}
        />
      )}
    </>
  )
}

const FormContainer = styled.div({
  marginBottom: 24,
})

export { BasicInfoForm }
