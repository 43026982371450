import * as Yup from 'yup'
import type { SPPlanPriceBandMapping, SPPurchaseModel, Store } from '@helloextend/extend-api-client'
import {
  getDecimalFromPercentage,
  getPercentageFromDecimal,
} from '@helloextend/client-utils/src/number'
import { hasGapsInPriceband, hasOverlappingPriceBands } from '../../../../utils'

const mapPriceBandsToFormValues = (
  priceBands: SPPlanPriceBandMapping[],
): SPPlanPriceBandMapping[] =>
  priceBands.map(({ revenueShare, partialReimbursement, ...rest }) => ({
    ...rest,
    revenueShare: revenueShare ? getPercentageFromDecimal(revenueShare) : undefined,
    partialReimbursement: partialReimbursement
      ? getPercentageFromDecimal(partialReimbursement)
      : undefined,
  }))

const mapFormValuesToPriceBands = (
  priceBands: SPPlanPriceBandMapping[],
): SPPlanPriceBandMapping[] =>
  priceBands.map(({ revenueShare, high, fixedPremium, partialReimbursement, ...rest }) => ({
    ...rest,
    revenueShare: revenueShare ? getDecimalFromPercentage(revenueShare) : undefined,
    partialReimbursement:
      partialReimbursement && partialReimbursement > 0
        ? getDecimalFromPercentage(partialReimbursement)
        : undefined,
    high: high ? +high : undefined,
    fixedPremium: fixedPremium ? +fixedPremium : undefined,
  }))

const getInitialPurchaseModelValues = (
  store: Store,
  priceBands?: SPPlanPriceBandMapping[],
): Values => {
  const { shippingProtection, merchantSpRevenueSharePercentage } = store
  return {
    purchaseModel: shippingProtection?.purchaseModel || 'SINGLE_PLAN',
    partialReimbursement: false,
    priceBands: priceBands
      ? mapPriceBandsToFormValues(priceBands)
      : [getInitialPriceBandValues(true)],
    planId: shippingProtection?.planId || '',
    merchantSpRevenueSharePercentage: merchantSpRevenueSharePercentage
      ? getPercentageFromDecimal(merchantSpRevenueSharePercentage)
      : (undefined as unknown as number),
    offersByCategoryEnabled: shippingProtection?.offersByCategoryEnabled || false,
    // a temporary default value until backend is updated
    partialReimbursementSPPercentage: 10,
  } as Values
}

const getInitialPriceBandValues = (isFirstRow = false): SPPlanPriceBandMapping => ({
  low: isFirstRow ? 0 : (undefined as unknown as number),
  planId: '',
})

const purchaseModelLabels: {
  [key in SPPurchaseModel]: string
} = {
  SINGLE_PLAN: 'Single Plan',
  CATEGORY: 'Category',
  PRICE_BAND: 'Price Band',
}

const priceBandSchema = Yup.object<SPPlanPriceBandMapping>()
  .shape({
    low: Yup.number().required('Price band low is required'),
    high: Yup.number().notRequired(),
    planId: Yup.string().required('Plan is required'),
    fixedPremium: Yup.number().notRequired(),
    partialReimbursement: Yup.number().notRequired(),
    revenueShare: Yup.number().required('Revenue share is required'),
  })
  .test('checkOverlap', 'Price bands cannot overlap', function checkOverlap(value) {
    const isOverlapping = hasOverlappingPriceBands(this.parent, value as SPPlanPriceBandMapping)

    return isOverlapping
      ? this.createError({
          message: 'Price bands must not overlap',
          path: `${this.path}.high`,
        })
      : true
  })
  .test('checkGap', 'Price bands cannot have gaps', function testGapsInPricebands(value) {
    const hasGaps = hasGapsInPriceband(this.parent, value as SPPlanPriceBandMapping)
    return hasGaps
      ? this.createError({
          message: 'Price bands must not have gaps',
          path: `${this.path}.low`,
        })
      : true
  })
  .test(
    'price band high is required if not last',
    'Price band high is required',
    function checkIfRequired(value) {
      const priceBands = this.parent as SPPlanPriceBandMapping[]
      const isLastRow = priceBands.length > 0 && this.path.endsWith(`[${priceBands.length - 1}]`)
      return !isLastRow && !value?.high
        ? this.createError({ message: 'Price band high is required', path: `${this.path}.high` })
        : true
    },
  )
  .defined()

const schema = Yup.object()
  .shape({
    purchaseModel: Yup.string<SPPurchaseModel>()
      .required('Purchase Model is required')
      .oneOf(['SINGLE_PLAN', 'CATEGORY', 'PRICE_BAND']),
    partialReimbursement: Yup.boolean(),
    priceBands: Yup.array<SPPlanPriceBandMapping>()
      .of(priceBandSchema)
      .when('purchaseModel', {
        is: (purchaseModel: SPPurchaseModel) => purchaseModel === 'PRICE_BAND',
        then: Yup.array<SPPlanPriceBandMapping>()
          .of(priceBandSchema)
          .required('At least one price band is required'),
        otherwise: Yup.array<SPPlanPriceBandMapping>().of(priceBandSchema).notRequired(),
      }),
    planId: Yup.string().required('Plan ID is required'),
    merchantSpRevenueSharePercentage: Yup.number()
      .required('Must enter a revenue share percentage')
      .moreThan(0, 'Revenue share must be greater than 0')
      .max(100, 'Revenue share must be equal or less than 100'),
    partialReimbursementSPPercentage: Yup.number().when('partialReimbursement', {
      is: true,
      then: Yup.number()
        .required('Partial reimbursement percentage is required')
        .moreThan(0, 'Partial reimbursement must be greater than 0')
        .max(100, 'Partial reimbursement must be equal or less than 100'),
      otherwise: Yup.number().notRequired(),
    }),
    offersByCategoryEnabled: Yup.boolean().notRequired(),
  })
  .defined()

// workaround for regular Yup.InferType not working with conditional "when" schema properties
type Values = ReturnType<typeof schema.validateSync>

export {
  schema,
  Values,
  getInitialPurchaseModelValues,
  purchaseModelLabels,
  getInitialPriceBandValues,
  mapFormValuesToPriceBands,
}
