import type { IconProps } from './icon-props'

export const Mobile: IconProps = {
  name: 'Mobile',
  d: [
    'M14.5 5H0.5C0.224 5 0 4.776 0 4.5C0 4.224 0.224 4 0.5 4H14.5C14.776 4 15 4.224 15 4.5C15 4.776 14.776 5 14.5 5Z',
    'M7.5 22C6.949 22 6.5 21.551 6.5 21C6.5 20.449 6.949 20 7.5 20C8.051 20 8.5 20.449 8.5 21C8.5 21.551 8.051 22 7.5 22ZM7.5 20.999C7.5 21 7.5 21 7.5 20.999L8 21L7.5 20.999Z',
    'M8.5 3H6.5C6.224 3 6 2.776 6 2.5C6 2.224 6.224 2 6.5 2H8.5C8.776 2 9 2.224 9 2.5C9 2.776 8.776 3 8.5 3Z',
    'M12.5 24H2.5C1.122 24 0 22.878 0 21.5V2.5C0 1.122 1.122 0 2.5 0H12.5C13.878 0 15 1.122 15 2.5V21.5C15 22.878 13.878 24 12.5 24ZM2.5 1C1.673 1 1 1.673 1 2.5V21.5C1 22.327 1.673 23 2.5 23H12.5C13.327 23 14 22.327 14 21.5V2.5C14 1.673 13.327 1 12.5 1H2.5Z',
    'M14.5 19H0.5C0.224 19 0 18.776 0 18.5C0 18.224 0.224 18 0.5 18H14.5C14.776 18 15 18.224 15 18.5C15 18.776 14.776 19 14.5 19Z',
  ],
}
