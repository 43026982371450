import type { ServiceOrderAssignRequest } from '@helloextend/extend-api-client/src/models/service-order'
import { createActionFailure } from '../../factories'

export const assign = (
  serviceOrderId: string,
  body: ServiceOrderAssignRequest,
  claimId: string,
  accessToken: string,
) =>
  ({
    type: 'SERVICE_ORDERS_ASSIGN',
    payload: { serviceOrderId, body, claimId, accessToken },
  } as const)

export const assignRequest = () => ({ type: 'SERVICE_ORDERS_ASSIGN_REQUEST' } as const)

export const assignSuccess = () =>
  ({
    type: 'SERVICE_ORDERS_ASSIGN_SUCCESS',
  } as const)

export const assignFailure = createActionFailure('SERVICE_ORDERS_ASSIGN_FAILURE')
