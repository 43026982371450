import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import { Button } from '@helloextend/merchants-ui'
import { COLOR } from '@helloextend/client-branding'
import { Checkbox } from '@helloextend/zen'
import { connect } from 'react-redux'
import { isValidEmail } from '@helloextend/client-utils/validate'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import * as selectors from '../../reducers/selectors'
import { getParamsFromUrl } from '../../utils/get-params-from-url'
import Input from '../input/index'
import { LoginSuccessMessage } from '../login-success-message'
import Password from '../password/index'
import { auth } from '../../actions'
import type { RootState } from '../../reducers'
import { getRememberMeEmail, setRememberMeEmail } from '../../utils/local-storage'

const schema = Yup.object()
  .shape({
    username: Yup.string()
      .required('Invalid email address')
      .test('is-email-valid', 'Invalid email address', (email) =>
        email ? isValidEmail(email) : false,
      ),
    password: Yup.string().required('Please enter a password'),
    rememberMe: Yup.boolean(),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

interface SP {
  error: ReturnType<typeof selectors.getAuthError>
  isLoading: ReturnType<typeof selectors.getIsAuthLoading>
}

interface DP {
  onSubmit: typeof auth.login
  onUnload: typeof auth.errorReset
}

type EmailLoginFormProps = SP & DP

const Component: FC<EmailLoginFormProps> = ({ error, isLoading, onUnload, onSubmit }) => {
  const { touched, errors, values, handleChange, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      validationSchema: schema,
      validateOnChange: false,
      validateOnBlur: false,
      initialValues: {
        username: getParamsFromUrl('user') || getRememberMeEmail() || '',
        password: '',
        rememberMe: Boolean(getRememberMeEmail()),
      },
      onSubmit: ({ username, password, rememberMe }: Values): void => {
        if (rememberMe) {
          setRememberMeEmail(username)
        }

        onSubmit(username, password)
      },
    })

  const toggleRememberMe = (): void => {
    setFieldValue('rememberMe', !values.rememberMe)
  }

  useEffect(
    () => () => {
      onUnload()
    },
    [onUnload],
  )

  return (
    <Wrapper>
      <LoginSuccessMessage />
      <Form onSubmit={handleSubmit}>
        <InputWrapper>
          <Input
            autoComplete={values.username}
            errorMessage={errors.username}
            invalid={touched.username && Boolean(errors.username)}
            isDisabled={isLoading}
            label="Email Address"
            name="username"
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            value={values.username}
          />
        </InputWrapper>
        <Password
          label="Password"
          name="password"
          errorMessage={errors.password}
          invalid={touched.password && Boolean(errors.password)}
          isDisabled={isLoading}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
        />
        <ButtonText to={{ pathname: '/password/forgot', state: { username: values.username } }}>
          Forgot your password?
        </ButtonText>
        <CheckboxWrapper>
          <Checkbox
            checked={values.rememberMe}
            name="rememberUserLogin"
            disabled={isLoading}
            label="Remember me"
            onChange={toggleRememberMe}
          />
        </CheckboxWrapper>
        {error && (
          <ErrorMessage>
            {error.message.includes('Invalid credentials')
              ? 'The email address or password is not correct'
              : 'Uh oh, something went wrong! Please try again'}
          </ErrorMessage>
        )}

        <Button
          block
          size="xs"
          loading={isLoading}
          kind="primary"
          type="submit"
          dataQa="continue-button"
          text="Log in"
        />
      </Form>
    </Wrapper>
  )
}

const ErrorMessage = styled.aside({
  color: COLOR.STATE_DANGER,
  fontSize: 14,
  lineHeight: '16px',
  paddingBottom: 8,
})

const Form = styled.form({
  margin: '0 auto',
  maxWidth: 400,
})

const InputWrapper = styled.div({
  marginBottom: 24,
})

const ButtonText = styled(Link)({
  display: 'inline-block',
  fontSize: 14,
  backgroundColor: 'transparent',
  borderBottom: '1px solid transparent',
  color: COLOR.STRONG_BLUE,
  marginBottom: 32,
  cursor: 'pointer',
  transition: 'border .25s ease-in',
  '&:hover': {
    borderBottom: `1px solid ${COLOR.STRONG_BLUE}`,
  },
  '&:visited': {
    color: COLOR.STRONG_BLUE,
  },
})

const Wrapper = styled.div({
  width: 355,
})

const CheckboxWrapper = styled.div({
  paddingBottom: 32,
})

const EmailLoginForm = connect(
  (state: RootState) => ({
    isLoading: selectors.getIsAuthLoading(state),
    error: selectors.getAuthError(state),
  }),
  {
    onSubmit: auth.login,
    onUnload: auth.errorReset,
  },
)(Component)

export { Component, ErrorMessage, EmailLoginForm, EmailLoginFormProps }
