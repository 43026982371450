import type { FC, KeyboardEvent } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Information, InformationSize, Input, COLOR } from '@helloextend/zen'

type RangeSliderProps = {
  min: number
  max: number
  initialValue?: number
  onChange: (value: number) => void
  label?: string
  suffix?: string
  labelHelper?: string
}

const RangeSlider: FC<RangeSliderProps> = ({
  min,
  max,
  initialValue = 0,
  onChange,
  labelHelper,
  label,
  suffix,
}) => {
  const calculatedBkSize = `${((initialValue - min) * 100) / (max - min)}% 100%`

  const [backgroundSize, setBackgroundSize] = useState<string>(calculatedBkSize)
  const [currInitValue, setCurrInitValue] = useState(initialValue)
  const [inputValue, setInputValue] = useState(initialValue)

  if (calculatedBkSize !== backgroundSize) {
    setBackgroundSize(calculatedBkSize)
  }

  if (initialValue !== currInitValue) {
    setCurrInitValue(initialValue)
    setInputValue(initialValue)
  }

  const handleChange = (changeValue: number): void => {
    const newSize = `${((changeValue - min) * 100) / (max - min)}% 100%`
    setBackgroundSize(newSize)
    onChange(changeValue)
  }

  const handleChangeInput = (value: string): void => {
    // only allow change if numeric value
    if (!Number.isNaN(Number(value))) {
      setInputValue(Math.min(max, Number(value)))
    }
  }

  const handleBlur = (): void => {
    onChange(inputValue)
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      onChange(inputValue)
    }
  }

  return (
    <>
      <LabelContainer>
        {label && (
          <LabelWrapper>
            <Label htmlFor="range">{label}</Label>
          </LabelWrapper>
        )}
        {labelHelper && (
          <Information buttonSize="small" size={InformationSize.small}>
            {labelHelper}
          </Information>
        )}
      </LabelContainer>
      <InputContainer>
        <Slider
          type="range"
          id="range"
          name="range"
          min={min}
          max={max}
          value={initialValue}
          onChange={(e) => handleChange(Number(e.currentTarget.value))}
          backgroundSize={backgroundSize}
        />
        <TextInputContainer>
          <Input
            value={String(inputValue)}
            onChange={(e) => handleChangeInput(e.currentTarget.value)}
            onBlur={handleBlur}
            onKeyPress={handleKeyPress}
            suffix={suffix}
            id="range-input"
          />
        </TextInputContainer>
      </InputContainer>
    </>
  )
}

const Slider = styled.input<{ backgroundSize: string }>(({ backgroundSize }) => ({
  '&[type="range"]': {
    WebkitAppearance: 'none',
    marginRight: 15,
    width: 200,
    height: 1,
    borderRadius: 5,
    background: COLOR.NEUTRAL[300],
    backgroundImage: `linear-gradient(${COLOR.BLUE[700]}, ${COLOR.BLUE[700]})`,
    backgroundSize,
    backgroundRepeat: 'no-repeat',
  },

  '&[type="range"]::-webkit-slider-thumb': {
    WebkitAppearance: 'none',
    height: 20,
    width: 20,
    borderRadius: 10,
    background: COLOR.WHITE,
    cursor: 'pointer',
    transition: 'background .3s ease-in-out',
    border: `1px solid ${COLOR.NEUTRAL[300]}`,
  },

  '&[type="range"]::-moz-range-thumb': {
    WebkitAppearance: 'none',
    height: 20,
    width: 20,
    borderRadius: 10,
    background: COLOR.WHITE,
    cursor: 'pointer',
    transition: 'background .3s ease-in-out',
    border: `1px solid ${COLOR.NEUTRAL[300]}`,
  },

  '&[type="range"]::-ms-thumb': {
    WebkitAppearance: 'none',
    height: 20,
    width: 20,
    borderRadius: 10,
    background: COLOR.WHITE,
    cursor: 'pointer',
    transition: 'background .3s ease-in-out',
    border: `1px solid ${COLOR.NEUTRAL[300]}`,
  },

  '&[type=range]::-webkit-slider-runnable-track': {
    WebkitAppearance: 'none',
    boxShadow: 'none',
    border: 'none',
    background: 'transparent',
  },

  '&[type=range]::-moz-range-track': {
    WebkitAppearance: 'none',
    boxShadow: 'none',
    border: 'none',
    background: 'transparent',
  },

  'input[type="range"]::-ms-track': {
    WebkitAppearance: 'none',
    boxShadow: 'none',
    border: 'none',
    background: 'transparent',
  },
}))

const Label = styled.label({
  fontWeight: 700,
  fontSize: 14,
})

const LabelContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const LabelWrapper = styled.div({
  marginRight: 8,
})

const InputContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const TextInputContainer = styled.div({
  width: 70,
})

export { RangeSlider }
