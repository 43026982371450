import type { FC } from 'react'
import React from 'react'
import { useToggle, useClickOutside } from '@helloextend/client-hooks'
import type { Plan } from '@helloextend/extend-api-client'
import type { Sku } from '@helloextend/extend-api-client/src/models/sku'
import { Menu, List, ListItem } from '@helloextend/merchants-ui'
import { Button, COLOR } from '@helloextend/zen'
import { More } from '@helloextend/zen/src/tokens/icons'
import { useHistory } from 'react-router'
import styled from '@emotion/styled'

type RestoreHeaderButtonGroupProps = {
  isEditDisabled: boolean
  toggleIsEditDisabled: () => void
  resetForm: () => void
  restoreDetails: Plan | Sku | null
  toggleRestoreModal: () => void
  isRestoreDisabled: boolean
  isDuplicateDisplayed?: boolean
  exportFilePrefix?: string
  duplicateDetailsUrl?: string
  exportDetails?: Partial<Plan | Sku>
}

const RestoreHeaderButtonGroup: FC<RestoreHeaderButtonGroupProps> = ({
  isEditDisabled,
  toggleIsEditDisabled,
  resetForm,
  restoreDetails,
  toggleRestoreModal,
  isRestoreDisabled,
  isDuplicateDisplayed = true,
  exportFilePrefix = '',
  duplicateDetailsUrl = '',
  exportDetails,
}) => {
  const { push } = useHistory()

  const [isMenuOpen, { toggle: toggleMenu, off }] = useToggle()
  const { ref } = useClickOutside<HTMLDivElement>(() => {
    off()
  })

  const handleCancelEdit = (): void => {
    resetForm()
    toggleIsEditDisabled()
  }

  const handleDuplicateClick = (): void => {
    push(duplicateDetailsUrl, { restoreDetails })
  }

  const handleRestoreClick = (): void => {
    toggleRestoreModal()
    toggleMenu()
  }
  if (!restoreDetails) return null
  return (
    <ButtonGroup>
      {isEditDisabled ? (
        <>
          <Button
            data-cy="edit-button"
            text="Edit & Restore"
            emphasis="medium"
            onClick={toggleIsEditDisabled}
          />
          <div ref={ref}>
            <Button data-cy="more-button" icon={More} onClick={toggleMenu} emphasis="medium" />
            <Menu isOpen={isMenuOpen} width={200} position="right">
              <List fullWidth>
                {isDuplicateDisplayed && (
                  <ListItem
                    type="button"
                    id="duplicate"
                    onClick={handleDuplicateClick}
                    color={COLOR.BLACK}
                    hoverBackground={COLOR.BLUE.OPACITY[12]}
                  >
                    Duplicate
                  </ListItem>
                )}
                <a
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    JSON.stringify(exportDetails),
                  )}`}
                  download={`${exportFilePrefix}_${restoreDetails.id}.json`}
                  data-cy="sku-version-export"
                >
                  <ListItem
                    type="button"
                    id="export"
                    onClick={() => {}}
                    color={COLOR.BLACK}
                    hoverBackground={COLOR.BLUE.OPACITY[12]}
                  >
                    Export
                  </ListItem>
                </a>
                <ListItem
                  type="button"
                  id="restore"
                  onClick={handleRestoreClick}
                  color={COLOR.BLACK}
                  hoverBackground={COLOR.BLUE.OPACITY[12]}
                >
                  Restore
                </ListItem>
              </List>
            </Menu>
          </div>
        </>
      ) : (
        <>
          <Button
            data-cy="cancel-button"
            text="Cancel"
            emphasis="medium"
            onClick={handleCancelEdit}
          />
          <Button
            data-cy="restore-button"
            text="Restore"
            emphasis="high"
            onClick={handleRestoreClick}
            isDisabled={isRestoreDisabled}
          />
        </>
      )}
    </ButtonGroup>
  )
}

const ButtonGroup = styled.div({
  display: 'flex',
  gap: 8,
  flexWrap: 'wrap',
})

export { RestoreHeaderButtonGroup }
