import type { Store } from '@helloextend/extend-api-client'
import type { ReducerState } from './reducers'
import type { ErrorReducerState } from '../types/error'

export function getById(state: ReducerState, storeId: string): Store | null {
  return state.byId[storeId]
}

export function getError(state: ReducerState): ErrorReducerState {
  return state.error
}

export function getIsLoading(state: ReducerState): boolean {
  return state.isLoading
}

export function getIsUpdating(state: ReducerState): boolean {
  return state.isUpdating
}

export function getAll(state: ReducerState): Store[] {
  return Object.values(state.byId)
}
