import React from 'react'
import type { StoreUser, StoreUserRole } from '@helloextend/extend-api-client'
import { BadgeDescriptions, CellText } from '@helloextend/merchants-ui'
import { Badge } from '@helloextend/zen'
import type { CellProps } from 'react-table'
import type { BadgeProps } from '@helloextend/zen/src/components/badge'
import { mapUserType } from '../../../../../../utils/user-role-mapper'
import { statusDescriptions } from './user-status'

export type UserStatus = 'Active' | 'Pending' | 'Deactivated'

export interface TableUser extends StoreUser {
  name: string
  status: UserStatus
  [key: string]: unknown
}

const getUserStatus = (user: StoreUser): UserStatus => {
  if (user.deletedAt !== undefined) {
    return 'Deactivated'
  }

  if (user.loginStatus === 'active') {
    return 'Active'
  }

  return 'Pending'
}

const toTableUser = (user: StoreUser): TableUser => ({
  ...user,
  name: `${user.lastName}, ${user.firstName}`,
  status: getUserStatus(user),
})

const toTableUsers = (users: StoreUser[]): TableUser[] => users.map(toTableUser)

const usersColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value }: CellProps<StoreUser, string>): JSX.Element => <CellText>{value}</CellText>,
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: ({ value }: CellProps<StoreUser, string>): JSX.Element => <CellText>{value}</CellText>,
  },
  {
    Header: 'Role',
    accessor: 'role',
    disableSortBy: true,
    Cell: ({ value }: CellProps<StoreUser, StoreUserRole>): JSX.Element => (
      <CellText>{mapUserType(value)}</CellText>
    ),
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: true,
    HeaderAdornment: <BadgeDescriptions descriptions={statusDescriptions} buttonSize="xsmall" />,
    Cell: ({ value }: CellProps<TableUser>): JSX.Element => {
      let badgeColor: BadgeProps['color']
      switch (value) {
        case 'Pending':
          badgeColor = 'yellow'
          break
        case 'Deactivated':
          badgeColor = 'neutral'
          break
        case 'Active':
          badgeColor = 'green'
          break
      }
      return <Badge color={badgeColor} emphasis="medium" text={value} />
    },
  },
]

const searchOptions = [
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
]

export { toTableUser, toTableUsers, usersColumns, searchOptions }
