import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/client-utils/src/request'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.fetchTermsVersionLanguages>

export default function* fetchTermsVersionLanguages(action: Action): SagaIterator {
  const { accessToken, termsId, version } = action.payload

  yield put(actions.fetchTermsVersionLanguagesRequest())

  const response = yield call(
    client.planTerms.fetchTermsVersionLanguages,
    accessToken,
    termsId,
    version,
  )

  if (isErrorResponse(response)) {
    yield put(actions.fetchTermsVersionLanguagesFailure(response.data.message, response.status))
    return
  }
  yield put(actions.fetchTermsVersionLanguagesSuccess(response.data))
  yield put(actions.fetchTermsVersionLanguageRequest())

  const fileResponse = yield call(
    client.planTerms.fetchTermsVersionLanguageUrl,
    termsId,
    version,
    response.data[0].language,
  )

  if (isErrorResponse(fileResponse)) {
    yield put(actions.fetchTermsVersionLanguageFailure(response.data.message, response.status))
    return
  }

  yield put(actions.fetchTermsVersionLanguageSuccess(fileResponse.data.url))
}
