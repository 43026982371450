import type { PlanAttributesMap } from './plan-attributes-dropdown-mapping'

interface ValidationInfo {
  validARs: string[]
  validBSDs: string[]
  regionToSubdivMap: Record<string, string[]>
}

const validationInfo: ValidationInfo = {
  validARs: [],
  validBSDs: [],
  regionToSubdivMap: {},
}

/** Intializes the `ValidationInfo` needed to validate user input in the Allowed
 * Regions and Blocked Subdivision fields using the data from the `allowed_regions`
 * and `blocked_sub_divisions` properties of the fetched `planAttributes`. After
 * validation is performed, any error messages are passed to Yup.
 *
 * `AR` = shorthand for "Allowed Regions""
 * `BSD` = shorthand for "Blocked Sub Divisions"
 */
export function configureARandBSDFieldValidationInfo(planAttributes: PlanAttributesMap): void {
  validationInfo.validARs = planAttributes?.allowed_regions?.map((option) => option.value)
  validationInfo.validBSDs = planAttributes?.blocked_sub_division?.map((option) => option.value)

  // Constructs an object that maps each region name to an array of all of the valid subdivisions within that region
  validationInfo.regionToSubdivMap = validationInfo.validBSDs?.reduce((regionMap, bsd: string) => {
    const [region, subdiv] = bsd.split('-')
    return {
      ...regionMap,
      [region]: regionMap[region]?.concat(subdiv) ?? [subdiv],
    }
  }, {} as Record<string, string[]>)
}

/** Validate user inputs in the blocked_sub_divisions input field and return an
 * error message indicating the invalid inputs */
export function validateBSDs(value = '', ARText = ''): string {
  const { regionToSubdivMap, validARs, validBSDs } = validationInfo

  // Don't validate input if validation info isn't available (plansAttributes data hasn't loaded)
  if ([validARs, validBSDs, regionToSubdivMap].some((arr) => arr.length === 0)) {
    return ''
  }

  const enteredARs = splitOnCommas(ARText)
  const enteredBSDs = splitOnCommas(value)

  const duplicateBSDs = findDuplicates(enteredBSDs)
  const invalidBSDs = [] as string[]
  const nonMatchingBSDs = [] as string[]

  enteredBSDs.forEach((bsd) => {
    const isInvalid = !validARs.find((ar) => {
      return validBSDs.includes(`${ar}-${bsd}`)
    })

    if (bsd === '') {
      invalidBSDs.push('trailing comma')
      return
    }

    if (isInvalid) {
      invalidBSDs.push(bsd)
      return
    }

    const isNonMatching = !enteredARs.some((region) => {
      return regionToSubdivMap[region]?.includes(bsd) || bsd === ''
    })

    if (isNonMatching) {
      nonMatchingBSDs.push(bsd)
    }
  })

  const messages: string[] = []

  if (nonMatchingBSDs.length > 0) {
    messages.push(`No region entered for subdivision(s): [${nonMatchingBSDs.join(', ')}]`)
  }

  if (invalidBSDs.length > 0) {
    messages.push(`Invalid subdivision(s): [${invalidBSDs.join(', ')}]`)
  }

  if (duplicateBSDs.length > 0) {
    messages.push(`Duplicate subdivision(s): [${duplicateBSDs.join(', ')}]`)
  }

  return messages.join(' & ')
}

/** Validate user inputs in the allowed_regions input field and return an error
 * message indicating the invalid inputs */
export function validateARs(value: string): string {
  const { validARs } = validationInfo

  // Don't validate input if validation info isn't available (plansAttributes data hasn't loaded)
  if (validARs.length === 0) return ''

  const enteredARs = splitOnCommas(value)

  const duplicateARs = findDuplicates(enteredARs)
  const invalidARs = enteredARs.reduce((accum, ar) => {
    if (validARs.includes(ar)) {
      return accum
    }

    if (ar === '') {
      return [...accum, 'trailing comma']
    }

    return [...accum, ar]
  }, [] as string[])

  const messages: string[] = []

  if (invalidARs.length > 0) {
    messages.push(`Invalid region(s): [${invalidARs.join(', ')}]`)
  }

  if (duplicateARs.length > 0) {
    messages.push(`Duplicate region(s): [${duplicateARs.join(', ')}]`)
  }

  return messages.join(' & ')
}

const findDuplicates = (values: string[]): string[] => {
  const duplicateMap: { [key: string]: boolean } = {}

  return values.filter((value) => {
    if (duplicateMap[value]) {
      return true
    }

    duplicateMap[value] = true
    return false
  })
}

const COMMA_SPLIT_REGEX = /\s*,\s*/

const splitOnCommas = (value: string): string[] => {
  return value.length > 0 ? value.split(COMMA_SPLIT_REGEX) : []
}
