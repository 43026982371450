import { chunk } from 'lodash/fp'
import type {
  Contract,
  PlanContract,
  ContractSearchKey,
  ContractType,
} from '@helloextend/extend-api-client'
import type { Action } from '../actions'
import { mapContractsGetToContract } from './by-id'

const PAGE_SIZE = 25

type ContractSearchResponse = Contract & {
  planDetails?: PlanContract | undefined
}

export type ContractsSearchReducerState = {
  currentPage: ContractSearchResponse[]
  page: number
  pageGroup: ContractSearchResponse[][]
  searchKey: ContractSearchKey
  value: string
  typeFilter?: ContractType[]
  totalCount: number
}

export const initialState: ContractsSearchReducerState = {
  currentPage: [],
  page: 0,
  pageGroup: [],
  value: '',
  searchKey: 'contractId',
  typeFilter: [],
  totalCount: 0,
}

export default function search(state = initialState, action: Action): ContractsSearchReducerState {
  switch (action.type) {
    case 'CONTRACTS_SEARCH': {
      return {
        ...state,
        value: action.payload.value,
        searchKey: action.payload.searchKey,
        typeFilter: action.payload.typeFilter,
      }
    }
    case 'CONTRACTS_SEARCH_SUCCESS': {
      const pageGroup = chunk(
        action.pageSize || PAGE_SIZE,
        action.payload.map(mapContractsGetToContract),
      )
      const totalCount = action.payload.length
      return {
        ...state,
        pageGroup,
        page: 0,
        currentPage: pageGroup[0] || [],
        totalCount,
      }
    }
    case 'CONTRACTS_NEXT_PAGE': {
      const page = state.page + 1
      return {
        ...state,
        page,
        currentPage: state.pageGroup[page],
      }
    }
    case 'CONTRACTS_PREV_PAGE': {
      const page = state.page - 1
      return {
        ...state,
        page,
        currentPage: state.pageGroup[page],
      }
    }
    case 'CONTRACTS_FIRST_PAGE': {
      const page = 0
      return {
        ...state,
        page,
        currentPage: state.pageGroup[page],
      }
    }
    case 'CONTRACTS_LAST_PAGE': {
      const page = state.pageGroup.length - 1
      return {
        ...state,
        page,
        currentPage: state.pageGroup[page],
      }
    }
    case 'CONTRACT_SEARCH_RESET':
      return initialState
    default:
      return state
  }
}
