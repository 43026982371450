import { createActionFailure } from '../../factories'

export const requestForPayment = (serviceOrderId: string, claimId: string, accessToken: string) =>
  ({
    type: 'SERVICE_ORDERS_REQUEST_FOR_PAYMENT',
    payload: { serviceOrderId, claimId, accessToken },
  } as const)

export const requestForPaymentRequest = () =>
  ({ type: 'SERVICE_ORDERS_REQUEST_FOR_PAYMENT_REQUEST' } as const)

export const requestForPaymentSuccess = () =>
  ({
    type: 'SERVICE_ORDERS_REQUEST_FOR_PAYMENT_SUCCESS',
  } as const)

export const requestForPaymentFailure = createActionFailure(
  'SERVICE_ORDERS_REQUEST_FOR_PAYMENT_FAILURE',
)
