import type { ServiceOrderShipment } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetchServiceOrderShipmentsByServiceOrderId = (
  serviceOrderId: string,
  accessToken: string,
) => ({ type: 'SERVICE_ORDER_SHIPMENTS_FETCH', payload: { serviceOrderId, accessToken } } as const)

export const fetchServiceOrderShipmentsSuccess = (serviceOrderShipments: ServiceOrderShipment[]) =>
  ({
    type: 'SERVICE_ORDER_SHIPMENTS_FETCH_SUCCESS',
    payload: { serviceOrderShipments },
  } as const)

export const fetchServiceOrderShipmentsFailure = createActionFailure(
  'SERVICE_ORDER_SHIPMENTS_FETCH_FAILURE',
)

export const fetchServiceOrderShipmentsRequest = () =>
  ({ type: 'SERVICE_ORDER_SHIPMENTS_FETCH_REQUEST' } as const)
