import React from 'react'
import { currency } from '@helloextend/client-utils'
import type {
  Contract,
  LineItemContract,
  LineItemLead,
  LineItemNonWarrantable,
  LineItem,
  ClaimItem,
} from '@helloextend/extend-api-client'
import { ReadOnlyItems, ReadOnlyWrapper, ReadOnlyValueLink } from './read-only-items'
import type { CollapsibleInfoGroupProps } from '../../../../../../components/collapsible-info-group'
import { fieldMapper } from '../../../../../../components/collapsible-info-group'

interface ShippingCostTaxValues {
  name: string
  detail: string
  purchasePrice: number
  replacementValue: number
}

interface TotalCostValues {
  name: string
  totalCost: number
}

type OrderLineItemProduct = LineItemContract | LineItemNonWarrantable | LineItemLead

const getProductInfo = (contract: Contract): CollapsibleInfoGroupProps['data'] => {
  const fields = [
    {
      key: 'product.title',
      label: 'Item Name',
    },
    {
      key: 'product.serialNumber',
      label: 'Serial Number',
    },
    {
      key: 'product.listPrice',
      label: 'List Price',
      transformFn: currency.format,
    },
    {
      key: 'product.purchasePrice',
      label: 'Purchase Price',
      transformFn: currency.format,
    },
  ]

  return [
    {
      values: fieldMapper<Contract, undefined>(contract, fields),
    },
  ]
}

const getClaimItemInfo = (
  contract: Contract,
  item: ClaimItem,
): CollapsibleInfoGroupProps['data'] => {
  const fields = [
    {
      key: 'item.title',
      label: 'Item Name',
    },
    {
      key: 'contract.product.serialNumber',
      label: 'Serial Number',
    },
    {
      key: 'item.listPrice',
      label: 'List Price',
      transformFn: currency.format,
    },
    {
      key: 'item.purchasePrice',
      label: 'Purchase Price',
      transformFn: (price: number) => {
        if (!price) return '—'
        return currency.format(price)
      },
    },
  ]

  return [
    {
      values: fieldMapper<{ contract: Contract; item: ClaimItem }, undefined>(
        { contract, item },
        fields,
      ),
    },
  ]
}

const getShippingProtectionProductsInfo = (
  lineItem: OrderLineItemProduct,
): CollapsibleInfoGroupProps['data'] => {
  const hasExtendedWarranty = (lineItem as LineItemContract).plan
  const fields = [
    {
      key: 'product.name',
      label: 'Item',
      isCustomComponent: true,
      columnCount: 1,
      CustomComponent: () => {
        const values = [lineItem.product?.name ?? '']
        if (hasExtendedWarranty) {
          const extendedWarrantyItem = 'Protected by Extended Warranty'
          values.push(extendedWarrantyItem)
        }
        return <ReadOnlyItems label="Item" values={values} width="100%" />
      },
    },
    {
      key: 'detail',
      label: 'Detail',
      isCustomComponent: true,
      columnCount: 2,
      CustomComponent: () => {
        if (hasExtendedWarranty) {
          const lineItemContract = lineItem as LineItemContract
          return (
            <ReadOnlyWrapper label="Detail" width="100%">
              <span>&nbsp;&nbsp;</span>
              <ReadOnlyValueLink
                label="Detail"
                value="ContractId: "
                contractId={lineItemContract.contractId}
              />
            </ReadOnlyWrapper>
          )
        }
        return <ReadOnlyItems label="Detail" />
      },
    },
    {
      key: 'product.purchasePrice',
      label: 'Purchase Price',
      isCustomComponent: true,
      CustomComponent: () => {
        const values = [currency.format(lineItem.product?.purchasePrice)]
        if (hasExtendedWarranty) {
          const lineItemContract = lineItem as LineItemContract
          values.push(currency.format(lineItemContract?.plan?.purchasePrice))
        }
        return <ReadOnlyItems label="Purchase Price" values={values} width="100%" />
      },
    },
  ]

  return [
    {
      values: fieldMapper<LineItem, undefined>(lineItem, fields),
    },
  ]
}

const getShippingCostTaxInfo = (
  shippingCostTavValue: ShippingCostTaxValues,
): CollapsibleInfoGroupProps['data'] => {
  const fields = [
    { key: 'name', label: 'Item' },
    { key: 'detail', label: 'Detail', columnCount: 2 },
    { key: 'purchasePrice', label: 'Purchase Price', transformFn: currency.format },
  ]
  return [
    {
      values: fieldMapper<ShippingCostTaxValues, undefined>(shippingCostTavValue, fields),
    },
  ]
}

const getTotalCostInfo = (totalCostValue: TotalCostValues): CollapsibleInfoGroupProps['data'] => {
  const fields = [
    { key: 'name', label: 'Item', columnCount: 3 },
    { key: 'totalCost', label: 'Purchase Price', transformFn: currency.format },
  ]

  return [
    {
      values: fieldMapper<TotalCostValues, undefined>(totalCostValue, fields),
    },
  ]
}

export {
  getProductInfo,
  getClaimItemInfo,
  getShippingCostTaxInfo,
  getTotalCostInfo,
  getShippingProtectionProductsInfo,
}
