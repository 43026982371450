import { usePrevious } from '@helloextend/client-hooks'
import type { ThreadResponse, ConversationResponse } from '@helloextend/extend-api-rtk-query'
import { useGetConversationQuery, useLazyGetThreadQuery } from '@helloextend/extend-api-rtk-query'
import type { SerializedError } from '@reduxjs/toolkit'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useState, useEffect, useMemo } from 'react'
import type { FullConversation } from '../types/conversations'

function useGetFullConversation(id: string): {
  isLoading: boolean
  error?: SerializedError | FetchBaseQueryError | null
  conversation: FullConversation | null
} {
  const [conversation, setConversation] = useState<FullConversation | null>(null)
  const {
    data: partialConversation,
    isFetching,
    error: conversationError,
  } = useGetConversationQuery(id)
  const [fetchThread, { isFetching: isThreadsFetching, error: threadError }] =
    useLazyGetThreadQuery()
  const [fetchedThreads, setFetchedThreads] = useState<{ [key: string]: ThreadResponse } | null>(
    null,
  )
  const [hasFetched, setHasFetched] = useState<boolean>(false)
  const prevConvo = usePrevious<ConversationResponse | undefined>(partialConversation)

  useEffect(() => {
    if (prevConvo && prevConvo.version !== partialConversation?.version) {
      setHasFetched(false)
      setFetchedThreads(null)
    }
  }, [partialConversation, prevConvo])

  useEffect(() => {
    if (
      partialConversation &&
      !hasFetched &&
      !isThreadsFetching &&
      !threadError &&
      partialConversation.threads.length > 0
    ) {
      const fetchThreads = async (): Promise<void> => {
        const threadPromises = partialConversation.threads.map(async (fullThreadId) => {
          const threadId = fullThreadId.split(':').shift() as string
          return fetchThread(threadId, true).unwrap()
        })
        try {
          const threadResponses = await Promise.all(threadPromises)
          const threadMap: { [key: string]: ThreadResponse } = {}
          threadResponses.forEach((thread) => {
            threadMap[thread.id] = thread
          })
          setFetchedThreads(threadMap)
          setHasFetched(true)
        } catch (e: unknown) {
          console.error(e)
        } finally {
          setHasFetched(true)
        }
      }
      fetchThreads()
    } else if (partialConversation && partialConversation.threads.length === 0) {
      setHasFetched(true)
    }
  }, [fetchThread, fetchedThreads, hasFetched, isThreadsFetching, partialConversation, threadError])

  useEffect(() => {
    if (fetchedThreads && Object.values(fetchedThreads).length > 0 && partialConversation) {
      const fullConversation: FullConversation = {
        ...partialConversation,
        threads: partialConversation.threads.map((fullThreadId) => {
          const threadId = fullThreadId.split(':').shift() as string
          return fetchedThreads[threadId]
        }),
      }
      setConversation(fullConversation)
    } else if (partialConversation) {
      const fullConversation: FullConversation = {
        ...partialConversation,
        threads: [],
      }
      setConversation(fullConversation)
    }
  }, [partialConversation, fetchedThreads])

  const error = useMemo(() => {
    return conversationError || threadError
  }, [conversationError, threadError])

  return { isLoading: isFetching, error, conversation }
}

export { useGetFullConversation }
