import type { FC } from 'react'
import React from 'react'
import { Global, css } from '@emotion/core'

const GlobalStyle: FC = () => (
  <Global
    styles={css({
      'html, body': {
        fontFamily: '"Nunito Sans", sans-serif',
        minWidth: '100%',
        overflow: 'hidden',
      },
      'html, body, #root': {
        height: '100%',
      },
      body: {
        fontSize: '100%',
        margin: '0',
      },
      '*': {
        fontFamily: '"Nunito Sans", sans-serif',
      },
      a: {
        textDecoration: 'inherit',
      },
    })}
  />
)

export { GlobalStyle }
