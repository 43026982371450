import type { VFC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { InlineAlert, InlineAlertColor } from '../../inline-alert'
import { LoadingText } from '../../loading-text'
import { useTransition } from '../../../transitions'
import { useDataTableContext } from '../data-table-context'

interface LoaderProps {
  delayMs?: number
  isOnlySecondary?: boolean
  message?: string
}

export const Loader: VFC<LoaderProps> = ({
  delayMs = 3000,
  isOnlySecondary,
  message = 'This may take a while',
}) => {
  const { isVisible, transitionDurationMs } = useTransition()
  const { 'data-cy': dataCy } = useDataTableContext()

  return (
    <StyledLoader
      isVisible={isVisible}
      transitionDurationMs={transitionDurationMs}
      data-cy={dataCy && `${dataCy}:loader`}
      delay={isOnlySecondary ? `${delayMs}ms` : '750ms'}
    >
      <LoaderContainer>
        <InlineAlert color={InlineAlertColor.blue}>
          <LoadingText
            secondary={message}
            {...(isOnlySecondary && { isOnlySecondary: true, secondaryDelayMs: 0 })}
          />
        </InlineAlert>
      </LoaderContainer>
    </StyledLoader>
  )
}

const StyledLoader = styled.div<{
  delay: string
  isVisible?: boolean
  transitionDurationMs?: number
}>(({ delay, isVisible, transitionDurationMs }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 4,
  height: 0,
  transition: `${transitionDurationMs}ms`,
  transitionDelay: isVisible ? delay : '0ms',
  opacity: isVisible ? 1 : 0,
}))

const LoaderContainer = styled.div({
  position: 'absolute',
  left: '50%',
  top: 52,
  transform: 'translateX(-50%) scale(0.8)',
})
