import type { DropdownOption } from '../../components/dropdown'
import type { UploadValidatorPipe } from './csv-validation'
import { getColumnValue } from './csv-validation'

/**
 * Used to validate program or subprogram columns
 */
const validateTaxonomyDropdown = (
  meta: UploadValidatorPipe,
  columnName: string,
  taxonomyData?: DropdownOption[],
): UploadValidatorPipe => {
  if (!taxonomyData?.length) {
    meta.warnings.push(`Could not verify ${columnName}`)
  }
  const columnValue = getColumnValue(meta, columnName)
  const isAllowed = taxonomyData?.some((data) => {
    return data.value.toLowerCase() === columnValue.toLowerCase()
  })
  if (!isAllowed) {
    meta.errors.push(`${columnName} ${columnValue} is not a valid value`)
  }
  return meta
}

export { validateTaxonomyDropdown }
