import type { SyntheticEvent } from 'react'
import type { IconProps } from '../../tokens/icons/icon-props'

export interface BadgeProps {
  color?: 'blue' | 'green' | 'neutral' | 'red' | 'yellow'
  /**
   * Displays text following the primary badge text and separated by a colon
   */
  details?: string
  emphasis?: 'low' | 'medium' | 'high'
  icon?: IconProps
  /**
   * Set to `true` if used on a dark background
   */
  invert?: boolean
  size?: 'small' | 'regular'
  text: string
  onClick?: (e: SyntheticEvent<HTMLDivElement>) => void
  'data-cy'?: string
}

export type BadgeColor = NonNullable<BadgeProps['color']>
export type BadgeSize = NonNullable<BadgeProps['size']>
export type BadgeEmphasis = NonNullable<BadgeProps['emphasis']>

export enum BadgeTheme {
  blue = 'blue',
  blueInverted = 'blueInverted',
  green = 'green',
  greenInverted = 'greenInverted',
  neutral = 'neutral',
  neutralInverted = 'neutralInverted',
  yellow = 'yellow',
  yellowInverted = 'yellowInverted',
  red = 'red',
  redInverted = 'redInverted',
}

export type ColorArgs = {
  contentColor: string
  backgroundColor?: string
  dotColor?: string
}

export type MeasurementArgs = {
  lineHeight: string
  fontSizePx: number
  dotSizePx: number
  dotTopMarginPx: number
  iconWrapperSizePx: number
}
