import { Datacenter, datadogLogs, HandlerType } from '@datadog/browser-logs'
import { EXTEND_APP_NAME, EXTEND_ENV } from '@helloextend/client-constants'
import { REDACTED } from '@helloextend/client-logger/src/redact'
import { isEnv } from '@helloextend/client-utils'

datadogLogs.init({
  clientToken: 'pubb345a3a245937e3a1a7b08e168cd6656',
  datacenter: Datacenter.US,
})

if (isEnv(['production', 'demo'])) datadogLogs.setLoggerGlobalContext({ view: REDACTED })

datadogLogs.addLoggerGlobalContext('project_name', EXTEND_APP_NAME)
datadogLogs.addLoggerGlobalContext('env', EXTEND_ENV)

if (isEnv(['local'])) datadogLogs.logger.setHandler(HandlerType.console)

export default datadogLogs
