import type { FC } from 'react'
import React from 'react'
import { matchPath, useLocation } from 'react-router'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { Icon } from '../../icon'
import type { IconProps } from '../../../tokens/icons/icon-props'
import { COLOR } from '../../../tokens/colors'

export interface HeaderMenuLinkProps {
  children?: React.ReactNode
  icon?: IconProps
  tooltip?: string
  to: string
  exact?: boolean
  'data-cy'?: string
}

export const HeaderMenuLink: FC<HeaderMenuLinkProps> = ({
  children,
  icon,
  tooltip,
  to,
  exact = false,
  'data-cy': dataCy,
}) => {
  const location = useLocation()
  const match = matchPath(location.pathname, {
    path: to,
    exact,
  })
  const isToggled = !!match
  return (
    <StyledHeaderLink data-cy={dataCy} data-tooltip={tooltip} to={to} istoggled={Number(isToggled)}>
      <Content>
        {!!icon && (
          <IconContainer>
            <Icon icon={icon} color={COLOR.WHITE} />
          </IconContainer>
        )}
        {children}
      </Content>
    </StyledHeaderLink>
  )
}

const StyledHeaderLink = styled(Link)<{
  // istoggled is all lowercase because it renders on the DOM and it has to be a valid html attribute name (no camelCase)
  // it is converted to a number because boolean is not a valid html attribute value
  istoggled: number
}>(({ istoggled }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 4,
  padding: `4px 10px`,
  border: 'none',
  color: COLOR.WHITE,
  '&:visited': {
    color: COLOR.WHITE,
  },
  cursor: 'pointer',
  borderRadius: 4,
  transition: '50ms',
  lineHeight: '32px',
  whiteSpace: 'nowrap',
  textDecoration: 'none',
  ...(istoggled
    ? {
        WebkitTextStroke: '0.04em',
        background: COLOR.NEUTRAL.OPACITY[12],
        '&:hover': {
          background: COLOR.NEUTRAL.OPACITY[25],
          textDecoration: 'none',
        },
        '&:active': {
          background: COLOR.NEUTRAL.OPACITY[12],
        },
      }
    : {
        background: 'transparent',
        '&:hover': {
          background: COLOR.NEUTRAL.OPACITY[12],
          textDecoration: 'none',
        },
        '&:active': {
          background: COLOR.NEUTRAL.OPACITY[25],
        },
      }),
}))

const Content = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
})

const IconContainer = styled.div({
  display: 'flex',
  padding: '4px 0',
  margin: '0 -2px',
})
