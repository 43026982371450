import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, useToaster, ToastDuration, ToastColor } from '@helloextend/zen'
import type { CreateUserRequest } from '@helloextend/extend-api-rtk-query'
import { useCreateUserMutation } from '@helloextend/extend-api-rtk-query'
import { useParams } from 'react-router'
import { BasicModal } from '../../../../../../components/basic-modal'
import { UserForm } from '../user-form/user-form'

type CreateUserModalProps = {
  isVisible: boolean
  toggleOff: () => void
}

const CreateUserModal: FC<CreateUserModalProps> = ({ isVisible, toggleOff }) => {
  const { accountId } = useParams<{ accountId: string }>()
  const { toast } = useToaster()
  const [createUser, { isLoading: isUserCreating }] = useCreateUserMutation()

  const handleOnClickCreate = async (values: CreateUserRequest): Promise<void> => {
    try {
      await createUser({ ...values, accountId }).unwrap()
      toast({
        message: `The invite has been sent successfully!`,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
      toggleOff()
    } catch {
      toast({
        message: 'Something went wrong. Please try again.',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.red,
      })
    }
  }

  return (
    <BasicModal isVisible={isVisible} onClickClose={toggleOff}>
      <>
        <Title>Invite a new user</Title>
        <DetailText data-cy="invite-text">
          Send an email to invite a new user. The added users will be able to access all the stores
          under the account.
        </DetailText>
        <UserForm
          isLoading={isUserCreating}
          submitButtonText="Add User"
          onSubmit={handleOnClickCreate}
          handleCancelClick={toggleOff}
        />
      </>
    </BasicModal>
  )
}

const Title = styled.h3({
  color: COLOR.NEUTRAL[1000],
  fontSize: 20,
  marginBottom: 16,
  marginTop: 0,
})

const DetailText = styled.p({
  color: COLOR.NEUTRAL[1000],
  fontSize: 16,
  marginTop: 0,
  marginBottom: 24,
})

export { CreateUserModal, CreateUserModalProps }
