import type { FC } from 'react'
import React from 'react'
import type { CurrencyInputProps } from '@helloextend/zen'
import { CurrencyInput } from '@helloextend/zen'

export type PriceInputProps = Pick<
  CurrencyInputProps,
  | 'id'
  | 'data-cy'
  | 'ariaLabel'
  | 'label'
  | 'value'
  | 'onChange'
  | 'isError'
  | 'isDisabled'
  | 'currency'
>

const PriceInput: FC<CurrencyInputProps> = (props) => {
  return <CurrencyInput {...props} />
}

export { PriceInput }
