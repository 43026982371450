import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Information } from '../information'
import { COLOR } from '../../tokens/colors'

export interface LabelProps {
  /**
   * Most of the time should be a string but accepts a ReactNode for more versitility if necessary
   */
  children?: React.ReactNode
  helperText?: string | React.ReactElement
  /**
   * The ID of the associated HTML field element
   */
  htmlFor?: string
  isMuted?: boolean
  'data-cy'?: string
}

export const Label: FC<LabelProps> = ({
  children,
  helperText,
  htmlFor,
  isMuted,
  'data-cy': dataCy,
}) =>
  !!children || !!helperText ? (
    <StyledLabel>
      {children && (
        <Text
          htmlFor={htmlFor}
          isClickable={!!htmlFor}
          data-cy={dataCy && `${dataCy}:label`}
          isMuted={isMuted}
        >
          {children}
        </Text>
      )}
      {helperText && (
        <InformationWrapper>
          <Information data-cy={dataCy && `${dataCy}:information`} buttonSize="xsmall">
            {helperText}
          </Information>
        </InformationWrapper>
      )}
    </StyledLabel>
  ) : (
    <></>
  )

const StyledLabel = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
})

const Text = styled.label<{
  isClickable: boolean
  isMuted?: boolean
}>(({ isClickable, isMuted }) => ({
  ...(!isClickable && { cursor: 'auto' }),
  fontSize: 14,
  lineHeight: '22px',
  fontWeight: 700,
  color: isMuted ? COLOR.NEUTRAL[600] : COLOR.NEUTRAL[1000],
}))

const InformationWrapper = styled.div({
  margin: -4,
})
