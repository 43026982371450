import type { ProductCsvValidatorPipe, ProductHeader } from './product-csv-headers'
import { MAX_TOTAL_PRODUCT_PLANS } from '../constants'

/**
 * checks the existence of the planID in the DB
 * @param meta - piped object
 */
const validatePlanId = (
  meta: ProductCsvValidatorPipe,
  planIds: Set<string> | undefined,
): ProductCsvValidatorPipe => {
  if (!planIds) {
    meta.errors.push("Couldn't fetch list of plans from the system")
    return meta
  }

  for (let planIdNumber = 1; planIdNumber <= MAX_TOTAL_PRODUCT_PLANS; planIdNumber += 1) {
    const planIdIndex = meta.headerIndexes[`planId${planIdNumber}` as ProductHeader]

    if (meta.rowData[planIdIndex]) {
      const planId = meta.rowData[planIdIndex]
      // allow 'tobedeleted' planID (used by Warranty Ops to remove plans from unwarrantable products)
      if (planId !== 'tobedeleted') {
        const doesPlanExist = planIds.has(planId.toUpperCase())

        if (!doesPlanExist) {
          meta.errors.push(`The Plan ID ${planId} must be mapped to the store`)
        }
      }
    }
  }

  return meta
}

export { validatePlanId }
