import type { FC, SyntheticEvent } from 'react'
import React, { useCallback, useEffect } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import type { Contract } from '@helloextend/extend-api-client'
import {
  Button,
  ButtonGroup,
  InlineAlert,
  InlineAlertColor,
  ToastColor,
  ToastDuration,
  useToaster,
  COLOR,
  Input,
  Checkbox,
} from '@helloextend/zen'
import { Info } from '@helloextend/zen/src/tokens/icons'
import { useFulfillServiceOrderMutation, useGetStoreQuery } from '@helloextend/extend-api-rtk-query'
import type {
  ProductReplacementServiceOrdersFulfillRequest,
  ServiceOrder,
  ServiceOrderItemProduct,
  ShippingCarrier,
} from '@helloextend/extend-api-client/src/models/service-order'
import { formSchema } from './schema'
import type { Values } from './schema'
import { setClaimDetailsActiveView } from '../../../../../../store/slices/claim-details'
import { CurrencyInputGroup } from '../../../../../../components/currency-input-group'
import { Select, SelectItem } from '../../../../../../components/select'

interface ResolveProductReplacementFormProps {
  serviceOrder: ServiceOrder
  contract: Contract
}

const ResolveProductReplacementForm: FC<ResolveProductReplacementFormProps> = ({
  contract,
  serviceOrder,
}) => {
  const dispatch = useDispatch()
  const { toast } = useToaster()
  const [fulfillServiceOrder, { isSuccess, isLoading }] = useFulfillServiceOrderMutation()
  const { data: store } = useGetStoreQuery({ storeId: contract.sellerId })

  const shippingCarrierMap: Record<ShippingCarrier, string> = {
    usps: 'USPS',
    ups: 'UPS',
    fedex_ground: 'Fedex Ground',
    dhl_express: 'DHL Express',
    ontrac: 'OnTrac',
    newgistics: 'Newgistics',
    fedex_standard_overnight: 'Fedex Standard Overnight',
  }

  const {
    values,
    errors,
    handleSubmit,
    handleChange: formikHandleChange,
    setFieldValue,
  } = useFormik<Values>({
    enableReinitialize: true,
    initialValues: {
      method: 'product_replacement',
      product: {
        referenceId: serviceOrder.items?.length
          ? serviceOrder.items[0].referenceItemId
          : contract.product.referenceId,
        name: serviceOrder.items?.length ? serviceOrder.items[0].title : contract.product.title,
        // This modal only expects 1 item EW contracts (no bundles or SP)
        value: {
          amount:
            store?.offerPriceType === 'product_purchase_price'
              ? (serviceOrder.items?.[0] as ServiceOrderItemProduct)?.purchasePrice ||
                contract.product.purchasePrice
              : (serviceOrder.items?.[0] as ServiceOrderItemProduct).listPrice ||
                contract.product.listPrice,
        },
      },
      orderNumber: '',
      expenseDescription: '',
      isTrackingInfo: false,
      carrier: '',
      trackingNumber: '',
    },
    validationSchema: formSchema,
    onSubmit: (formValues: Values) => {
      fulfillServiceOrder({
        serviceOrderId: serviceOrder?.id ?? '',
        body: formValues as ProductReplacementServiceOrdersFulfillRequest,
      })
    },
  })

  useEffect(() => {
    if (isSuccess) {
      toast({
        message: values.isTrackingInfo
          ? 'Resolution pending! Resolution requires tracking info.'
          : 'SO Resolved!',
        toastColor: values.isTrackingInfo ? ToastColor.yellow : ToastColor.green,
        toastDuration: ToastDuration.short,
      })
      dispatch(setClaimDetailsActiveView(''))
    }
  }, [isSuccess, values, dispatch, toast])

  const handleChange = (field: string, value: string | number | undefined): void => {
    setFieldValue(field, value)
  }

  const handleSelectChange = (field: string, e: SyntheticEvent<Element>): void => {
    const { value } = e.currentTarget as HTMLInputElement
    handleChange(field, value)
  }

  const handleCheckBoxChange = useCallback(() => {
    setFieldValue('isTrackingInfo', !values.isTrackingInfo)
  }, [values, setFieldValue])

  const alertText = values.isTrackingInfo
    ? 'The service order resolution will remain pending until tracking information for replacement is added. Please return at a later time to enter return product carrier and tracking number.'
    : 'Resolution cannot be edited once submitted. Please review before clicking “Resolve”.'

  return (
    <form onSubmit={handleSubmit}>
      <ContentWrapper>
        <ContentItemWrapper width="25%">
          <LabelWrapper>
            <LabelText data-cy="product-label">Requiring Fulfillment</LabelText>
          </LabelWrapper>
          <ValueWrapper data-cy="product-title">{contract.product.title || ''}</ValueWrapper>
        </ContentItemWrapper>
        <ContentItemWrapper width="25%">
          <Input
            data-cy="replacement-item-name-input-field"
            label="Replacement Item Name"
            onChange={formikHandleChange}
            value={values.product.name || ''}
            isError={Boolean(errors.expenseDescription)}
            errorFeedback={errors.expenseDescription}
            id="product.name"
          />
        </ContentItemWrapper>
        <ContentItemWrapper width="22%" margin={5}>
          <CurrencyInputGroup
            data-cy="fulfillment-expense-currency-input-field"
            label="Fulfillment Expense"
            name="fulfillment-expense"
            onChange={(v) => handleChange('product.value.amount', v)}
            value={values.product.value.amount}
            currencyProps={{
              codeFieldName: 'product.value.currencyCode',
              codeValue: serviceOrder.product.value.currencyCode || 'USD',
            }}
            isCurrencyCodeDisabled={Boolean(true)}
            showSymbol={false}
          />
        </ContentItemWrapper>
        <ContentItemWrapper width="28%">
          <Input
            data-cy="expense-description-input-field"
            label="Expense Description"
            maxLength={3000}
            onChange={formikHandleChange}
            value={values.expenseDescription}
            isError={Boolean(errors.expenseDescription)}
            errorFeedback={errors.expenseDescription}
            id="expenseDescription"
          />
        </ContentItemWrapper>
      </ContentWrapper>
      <ContentWrapper>
        <ContentItemWrapper width="25%" />
        <ContentItemWrapper width="25%">
          <Input
            data-cy="order-number-input-field"
            label="Order Number"
            onChange={formikHandleChange}
            value={values.orderNumber}
            isError={Boolean(errors.orderNumber)}
            errorFeedback={errors.orderNumber}
            id="orderNumber"
          />
        </ContentItemWrapper>
        <ContentItemWrapper width="22%">
          <Select
            label="Carrier"
            labelMarginBottom={2}
            labelDisplayType="block"
            menuHeight={175}
            onChange={(e: SyntheticEvent<Element>) => handleSelectChange('carrier', e)}
            value={values.carrier || ''}
            error={Boolean(errors.carrier)}
            errorMessage={errors.carrier}
            darkErrorMessageLabel={Boolean(false)}
            data-cy="carrier-select"
          >
            {Object.keys(shippingCarrierMap).map((key: string) => (
              <SelectItem
                key={key}
                value={key}
                label={shippingCarrierMap[key as ShippingCarrier]}
                data-cy={`select-item-${key}`}
              />
            ))}
          </Select>
        </ContentItemWrapper>
        <ContentItemWrapper width="28%">
          <Input
            data-cy="tracking-number-input-field"
            label="Tracking Number"
            onChange={formikHandleChange}
            value={values.trackingNumber || ''}
            isError={Boolean(errors.trackingNumber)}
            errorFeedback={errors.trackingNumber}
            id="trackingNumber"
          />
        </ContentItemWrapper>
      </ContentWrapper>
      <ContentWrapper>
        <ContentItemWrapper>
          <Checkbox
            checked={values.isTrackingInfo}
            label="Tracking info is not available at this time."
            data-cy="service-order-resolve-tracking-info-checkbox"
            onChange={handleCheckBoxChange}
          />
        </ContentItemWrapper>
      </ContentWrapper>
      <ContentWrapper>
        <ContentItemWrapper width="100%" margin={10}>
          <InlineAlert
            color={values.isTrackingInfo ? InlineAlertColor.yellow : InlineAlertColor.blue}
            icon={Info}
            data-cy="service-order-resolve-tracking-info-alert"
            isDismissable={false}
          >
            <Text> {alertText} </Text>
          </InlineAlert>
        </ContentItemWrapper>
      </ContentWrapper>
      <ButtonWrapper>
        <ButtonGroup>
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => dispatch(setClaimDetailsActiveView(''))}
            isDisabled={isLoading}
          />
          <Button
            type="submit"
            text="Resolve"
            data-cy="resolve-button"
            isDisabled={isLoading}
            isProcessing={isLoading}
          />
        </ButtonGroup>
      </ButtonWrapper>
    </form>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 20,
})

const ContentItemWrapper = styled.div<{ width?: string; margin?: number }>(({ width, margin }) => ({
  width,
  ...(margin && { marginTop: margin }),
}))

const ButtonWrapper = styled.div({
  marginTop: 10,
  display: 'flex',
  justifyContent: 'flex-end',
})

const Text = styled.p({
  fontSize: 14,
  margin: 0,
  fontWeight: 400,
})

const LabelWrapper = styled.label({
  fontFamily: 'Nunito Sans, sans-serif',
})

const ValueWrapper = styled.div({
  marginTop: 4,
  lineHeight: '24px',
  display: 'block',
})

const LabelText = styled.div({
  color: COLOR.NEUTRAL[600],
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
  lineHeight: '24px',
})

export { ResolveProductReplacementForm }
