import { combineReducers } from 'redux'
import type { ServicersSearchFilterReducerState } from './search-filter'
import searchFilter from './search-filter'
import type { ServicersSearchDropdownFilterReducerState } from './search-dropdown-filter'
import searchDropdownFilter from './search-dropdown-filter'

export interface LocalServicersReducerState {
  searchFilter: ServicersSearchFilterReducerState
  searchDropdownFilter: ServicersSearchDropdownFilterReducerState
}

export default combineReducers<LocalServicersReducerState>({
  searchFilter,
  searchDropdownFilter,
})
