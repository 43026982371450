import React, { forwardRef } from 'react'
import { PopoverPanel } from './popover-panel'
import { AnimatePresence } from '../../transitions/animate-presence'
import type { BoundingBox } from './types'
import { PopoverAlignment, PopoverDirection } from './types'

const TRANSITION_DURATION_MS = 120

interface PopoverProps {
  children: React.ReactNode
  header?: React.ReactNode
  footer?: React.ReactNode
  isPresent: boolean
  maxWidth?: number
  maxHeight?: number
  direction?: PopoverDirection
  alignment?: PopoverAlignment
  triggerBoundingBox: BoundingBox | null
  isInitiallyFocused?: boolean
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void
  returnFocusOnHide?: boolean
  'data-cy'?: string
}

const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  (
    {
      children,
      header,
      footer,
      isPresent,
      maxWidth,
      maxHeight,
      direction = PopoverDirection.down,
      alignment = PopoverAlignment.start,
      triggerBoundingBox,
      isInitiallyFocused = false,
      onScroll,
      returnFocusOnHide = true,
      'data-cy': dataCy,
    },
    ref,
  ) => {
    return (
      <AnimatePresence isPresent={isPresent} transitionDurationMs={TRANSITION_DURATION_MS}>
        <PopoverPanel
          ref={ref}
          header={header}
          footer={footer}
          direction={direction}
          alignment={alignment}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          triggerBoundingBox={triggerBoundingBox}
          data-cy={dataCy}
          isInitiallyFocused={isInitiallyFocused}
          onScroll={onScroll}
          returnFocusOnHide={returnFocusOnHide}
        >
          {children}
        </PopoverPanel>
      </AnimatePresence>
    )
  },
)

export { Popover }
