import type { FC, SyntheticEvent, ChangeEvent } from 'react'
import React, { useState, useEffect, useMemo } from 'react'
import type { FormikErrors, FormikHelpers, FormikTouched } from 'formik'
import { InputType } from '@helloextend/zen/src/components/fields/input/types'
import { CurrencyInputAlignment } from '../../../components/currency-input/currency-input'
import type { CurrencyInputItem } from '../../../components/form-text-group'
import { FormTextGroup } from '../../../components/form-text-group'
import type { FormTextGroupProps } from '../../../components/form-text-group/form-text-group'
import {
  calculateObligorFee,
  calculatePremium,
  calculateReserve,
  roundToTwoDecimalPlaces,
} from '../../../utils/sku-rates-calculations'
import type { Values } from './schema'
import { useDecimalsOnBlur } from '../../../hooks/use-decimals-on-blur'

interface SkuRatesProps {
  values: Values
  errors: FormikErrors<Values>
  handleChange: FormTextGroupProps['handleChange']
  handleBlur: (e: SyntheticEvent) => void
  touched: FormikTouched<Values>
  setFieldValue: FormikHelpers<Values>['setFieldValue']
  setErrors: FormikHelpers<Values>['setErrors']
  isDisabled?: boolean
  getFormikError: (errors: FormikErrors<Values>, fieldName: string) => string
}

const SkuRates: FC<SkuRatesProps> = ({
  handleChange,
  handleBlur,
  isDisabled,
  values,
  errors,
  touched,
  setFieldValue,
  getFormikError,
}) => {
  const { lossCost, targetLossRatio, reserve, obligorFeeRate, underwritingProfit, obligorFee } =
    values
  const [warnings, setWarnings] = useState<string[]>([])

  const reserveValue = useMemo(
    () =>
      calculateReserve({
        lossCost,
        targetLossRatio: Number(targetLossRatio),
      }),
    [lossCost, targetLossRatio],
  )

  const premiumValue = useMemo(
    () =>
      calculatePremium({
        reserve: Number(reserve),
        obligorFeeRate: Number(obligorFeeRate),
        underwritingProfit,
      }),
    [obligorFeeRate, reserve, underwritingProfit],
  )

  const obligorFeeValue = useMemo(
    () =>
      calculateObligorFee({
        reserve: Number(reserve),
        obligorFeeRate: Number(obligorFeeRate),
      }),
    [obligorFeeRate, reserve],
  )

  useEffect(() => {
    if (!premiumValue || isDisabled) return

    setFieldValue('premium', premiumValue)
  }, [underwritingProfit, obligorFeeValue, reserve, premiumValue, setFieldValue, isDisabled])

  useEffect(() => {
    const warningsArr: string[] = []
    if (reserve && reserve !== reserveValue && targetLossRatio) {
      warningsArr.push(
        `Reserve ($${roundToTwoDecimalPlaces(
          reserve / 100,
        )}) doesn’t equal to Loss Cost ($${roundToTwoDecimalPlaces(
          lossCost / 100,
        )}) / Target Loss Ratio (${targetLossRatio}%)`,
      )
    }
    if (obligorFee && obligorFee !== obligorFeeValue && obligorFeeRate) {
      warningsArr.push(
        `Obligor Fee Rate Amount ($${roundToTwoDecimalPlaces(
          obligorFee / 100,
        )}) doesn’t equal to Reserve ($${roundToTwoDecimalPlaces(
          reserve / 100,
        )}) * Obligor Fee Rate (${obligorFeeRate}%)`,
      )
    }
    setWarnings(warningsArr)
  }, [
    lossCost,
    obligorFee,
    obligorFeeRate,
    obligorFeeValue,
    reserve,
    reserveValue,
    targetLossRatio,
  ])

  const handleLossCostChange = (val: string | number, fieldName: string): void => {
    const targetLossRatioTemp = fieldName === 'lossCost' ? targetLossRatio : Number(val || 0)

    const reserveTemp = calculateReserve({
      lossCost: Number(val || 0),
      targetLossRatio: targetLossRatioTemp,
    })
    const obligorFeeTemp = calculateObligorFee({
      reserve: reserveTemp,
      obligorFeeRate,
    })
    setFieldValue(fieldName, val)
    setFieldValue('obligorFee', obligorFeeTemp)
    setFieldValue('reserve', reserveTemp)
  }

  const handleTargetLossRatioChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value, id } = e.currentTarget
    const reserveTemp = calculateReserve({
      lossCost,
      targetLossRatio: Number(value || 0),
    })
    const obligorFeeTemp = calculateObligorFee({
      reserve: reserveTemp,
      obligorFeeRate,
    })
    setFieldValue(id, value)
    setFieldValue('obligorFee', obligorFeeTemp)
    setFieldValue('reserve', reserveTemp)
  }

  const handleObligorFeeRateChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value, id } = e.currentTarget
    const obligorFeeRateTemp = calculateObligorFee({
      reserve,
      obligorFeeRate: Number(value),
    })
    setFieldValue(id, value)
    setFieldValue('obligorFee', obligorFeeRateTemp)
  }

  const handleReserveChange = (val: string | number, fieldName: string): void => {
    const obligorFeeTemp = calculateObligorFee({
      reserve: Number(val || 0),
      obligorFeeRate,
    })
    setFieldValue(fieldName, val)
    setFieldValue('obligorFee', obligorFeeTemp)
  }

  const handleCurrencyChange = (val: string | number, fieldName: string): void => {
    setFieldValue(fieldName, val)
  }

  const { handleOnBlurCustom } = useDecimalsOnBlur(setFieldValue)

  return (
    <FormTextGroup
      title="Rates"
      handleChange={handleChange}
      handleBlur={handleBlur}
      isDisabled={isDisabled}
      numColumns={2}
      warnings={!isDisabled ? warnings : []}
      warningHeader={warnings.length && !isDisabled ? 'Overriding Values' : undefined}
      values={[
        {
          name: 'priceBandLow',
          label: 'Price Band Low',
          value: values.priceBandLow,
          error: errors.priceBandLow,
          placeholder: 'Enter dollar amount',
          touched: touched.priceBandLow,
          fieldType: 'currency',
          showSymbol: false,
          currencyProps: {
            codeValue: values.currencyCode ?? 'USD',
            codeFieldName: 'priceBandLow',
          },
          isCurrencyCodeDisplayed: false,
          handleCurrencyChange,
          isGroup: false,
          alignment: CurrencyInputAlignment.Left,
          isZeroAllowed: true,
        } as CurrencyInputItem,
        {
          name: 'priceBandHigh',
          label: 'Price Band High',
          value: values.priceBandHigh,
          error: errors.priceBandHigh,
          placeholder: 'Enter dollar amount',
          touched: touched.priceBandHigh,
          fieldType: 'currency',
          showSymbol: false,
          currencyProps: {
            codeValue: values.currencyCode ?? 'USD',
            codeFieldName: 'priceBandHigh',
          },
          isCurrencyCodeDisplayed: false,
          handleCurrencyChange,
          isGroup: false,
          alignment: CurrencyInputAlignment.Left,
        } as CurrencyInputItem,
        {
          name: 'lossCost',
          label: 'Loss Cost',
          value: values.lossCost,
          error: getFormikError(errors, 'lossCost'),
          placeholder: 'Enter dollar amount',
          touched: Boolean(touched.lossCost),
          columnCount: 2,
          fieldType: 'currency',
          showSymbol: false,
          currencyProps: {
            codeValue: values.currencyCode ?? 'USD',
            codeFieldName: 'lossCost',
          },
          isCurrencyCodeDisplayed: false,
          handleCurrencyChange: handleLossCostChange,
          isGroup: false,
          alignment: CurrencyInputAlignment.Left,
        } as CurrencyInputItem,
        {
          name: 'targetLossRatio',
          label: 'Target Loss Ratio',
          value: values.targetLossRatio,
          error: getFormikError(errors, 'targetLossRatio'),
          placeholder: 'Enter percentage',
          touched: Boolean(touched.targetLossRatio),
          suffix: '%',
          type: InputType.number,
          handleOnBlurCustom,
          handleCustomOnChange: handleTargetLossRatioChange,
        },
        {
          name: 'reserve',
          label: 'Reserve',
          value: values.reserve,
          error: errors.reserve,
          placeholder: 'Enter dollar amount',
          touched: touched.reserve,
          fieldType: 'currency',
          showSymbol: false,
          currencyProps: {
            codeValue: values.currencyCode ?? 'USD',
            codeFieldName: 'reserve',
          },
          isCurrencyCodeDisplayed: false,
          handleCurrencyChange: handleReserveChange,
          isGroup: false,
          alignment: CurrencyInputAlignment.Left,
        } as CurrencyInputItem,
        {
          name: 'obligorFeeRate',
          label: 'Obligor Fee Rate',
          value: values.obligorFeeRate,
          error: errors.obligorFeeRate,
          placeholder: 'Enter percentage',
          touched: touched.obligorFeeRate,
          suffix: '%',
          type: InputType.number,
          handleOnBlurCustom,
          handleCustomOnChange: handleObligorFeeRateChange,
        },
        {
          name: 'obligorFee',
          label: 'Obligor Fee Rate Amount',
          value: values.obligorFee,
          error: errors.obligorFee,
          placeholder: 'Enter dollar amount',
          touched: touched.obligorFee,
          fieldType: 'currency',
          isItemDisabled: true,
          showSymbol: false,
          currencyProps: {
            codeValue: values.currencyCode ?? 'USD',
            codeFieldName: 'obligorFee',
          },
          isCurrencyCodeDisplayed: false,
          handleCurrencyChange,
          isGroup: false,
          alignment: CurrencyInputAlignment.Left,
          helperText: 'The formula for the auto generated value: Reserve * Obligor Fee Rate',
        } as CurrencyInputItem,
        {
          name: 'underwritingProfit',
          label: 'Obligor Fee Amount',
          value: values.underwritingProfit,
          error: errors.underwritingProfit,
          placeholder: 'Enter dollar amount',
          touched: touched.underwritingProfit,
          fieldType: 'currency',
          showSymbol: false,
          currencyProps: {
            codeValue: values.currencyCode ?? 'USD',
            codeFieldName: 'underwritingProfit',
          },
          isCurrencyCodeDisplayed: false,
          handleCurrencyChange,
          isGroup: false,
          alignment: CurrencyInputAlignment.Left,
          helperText: "This is a fixed amount doesn't associate with Obligor Fee Rate",
        } as CurrencyInputItem,
        {
          name: 'premium',
          label: 'Premium',
          value: values.premium,
          isItemDisabled: true,
          fieldType: 'currency',
          showSymbol: false,
          currencyProps: {
            codeValue: values.currencyCode ?? 'USD',
            codeFieldName: 'premium',
          },
          isCurrencyCodeDisplayed: false,
          handleCurrencyChange,
          isGroup: false,
          alignment: CurrencyInputAlignment.Left,
          helperText:
            'If Obligor Fee Rate is present, then: Premium = (Reserve / (1 - Obligor Fee Rate)) + Obligor Fee Amount; If Obligor Fee Rate is NOT present, then: Premium = Reserve + Obligor Fee Amount',
        } as CurrencyInputItem,
      ]}
    />
  )
}

export { SkuRates }
