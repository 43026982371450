import type { CategoryCsvValidatorPipe } from './category-csv-headers'
import { isEncoded, isScientificNotation } from '../validation-helpers'

/**
 * checks the existence and formatting of the category
 * @param meta - piped object
 */
export const validateCategory = (meta: CategoryCsvValidatorPipe): CategoryCsvValidatorPipe => {
  const category = meta.rowData[meta.headerIndexes.category]

  if (!category) {
    meta.errors.push('"category" must be defined')
  }

  if (category.trim() !== category) {
    meta.errors.push(`"category" should not have leading or trailing whitespace (${category})`)
  }

  if (isEncoded(category)) {
    meta.errors.push(`"category" should not be encoded (${category})`)
  }

  if (isScientificNotation(category)) {
    meta.errors.push(`"category" should not be formatted in scientific notation (${category})`)
  }

  return meta
}
