import type { FC } from 'react'
import React from 'react'
import { Submenu } from '../../menu'
import { MenuButtonItem } from '../../menu-item'
import { PopoverDirection } from '../../popover'
import type { FilterDef } from '../data-table-types'
import { Filter } from './filter'

interface FilterSubmenuProps {
  filterDefs?: FilterDef[]
}

export const FilterSubmenu: FC<FilterSubmenuProps> = ({ filterDefs }) => (
  <>
    {filterDefs?.map((filterDef) =>
      filterDef.type === 'group' ? (
        <Submenu key={filterDef.label} label={filterDef.label}>
          <FilterSubmenu filterDefs={filterDef.filterDefs} />
        </Submenu>
      ) : (
        <Filter
          key={filterDef.id}
          triggerRenderer={() => <MenuButtonItem showArrow>{filterDef.label}</MenuButtonItem>}
          filterDef={filterDef}
          direction={PopoverDirection.right}
        />
      ),
    )}
  </>
)
