import type { Action } from '../actions'
import { createBooleanReducer } from '../../factories'

const isLoading = createBooleanReducer<Action>(
  [
    'PLANS_FETCH_REQUEST',
    'PLANS_FETCH_ALL_REQUEST',
    'PLANS_CREATE_REQUEST',
    'PLANS_SEARCH_REQUEST',
  ],
  [
    'PLANS_FETCH_SUCCESS',
    'PLANS_FETCH_ALL_SUCCESS',
    'PLANS_FETCH_FAILURE',
    'PLANS_FETCH_ALL_FAILURE',
    'PLANS_SEARCH_SUCCESS',
    'PLANS_SEARCH_FAILURE',
    'PLANS_CREATE_SUCCESS',
    'PLANS_CREATE_FAILURE',
  ],
)

export default isLoading
