import type { Plan, PlanCreateRequest } from '@helloextend/extend-api-client'

export const skip = () =>
  ({
    type: 'IMPORT_MODAL_SKIP',
  } as const)

export const cancel = () =>
  ({
    type: 'IMPORT_MODAL_CANCEL',
  } as const)

export const confirm = () =>
  ({
    type: 'IMPORT_MODAL_CONFIRM',
  } as const)

export const confirmAll = () =>
  ({
    type: 'IMPORT_MODAL_CONFIRM_ALL',
  } as const)

export const batchError = (errorMessage: string) =>
  ({
    type: 'IMPORT_BATCH_ERROR',
    payload: errorMessage,
  } as const)

export const clearMessages = () =>
  ({
    type: 'IMPORT_CLEAR',
  } as const)

export const beginValidation = () =>
  ({
    type: 'IMPORT_VALIDATION_START',
  } as const)

export const beginImport = (batch: PlansValidationResult) =>
  ({
    type: 'IMPORT_BATCH_START',
    payload: batch,
  } as const)
export const importComplete = (result: PlansBatchResult) =>
  ({
    type: 'IMPORT_BATCH_COMPLETE',
    payload: result,
  } as const)

export type PlansBatchResult = {
  success: Array<{ name: string; plan: Plan }>
  failure: Array<{ name: string; message: string }>
  skipped: string[]
}
export interface PlansValidationResult {
  existingPlans: ModalPlan[]
  newPlans: PlanImportFile[]
  failedPlans: string[]
  errorMessage: string
}

export interface ModalPlan extends PlanImportFile {
  version: string
}

export interface PlanImportFile {
  file: File
  plan: PlanCreateRequest
}
