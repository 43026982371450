import type { StoreUserRole } from '@helloextend/extend-api-client'
import type { Action } from '../../actions'

export type UserProfileReducerState = {
  accountId: string
  email: string
  firstName?: string
  id: string
  lastName?: string
  role?: StoreUserRole | null
}

const initialState: UserProfileReducerState = {
  accountId: '',
  email: '',
  firstName: '',
  id: '',
  lastName: '',
  role: null,
}

export default function profile(state = initialState, action: Action): UserProfileReducerState {
  switch (action.type) {
    case 'USERS_GETME_SUCCESS':
      return action.payload
    default:
      return state
  }
}
