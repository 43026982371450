import type { ChangeEvent, FC, SyntheticEvent } from 'react'
import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { Else, If, Then, When } from 'react-if'
import { Badge } from '../../../badge'
import { MenuCheckbox, MenuButtonItem } from '../../../menu-item'
import type { OptionProps } from '../advanced-select-types'
import { emphasizeSearchString } from '../utils'

export const Option: FC<OptionProps> = ({
  badgePosition,
  dataCy,
  option,
  isCheckbox,
  isIndented,
  onSelectOption,
  onMultiSelectOption,
  searchString,
  popoverRef,
  isPopoverPresent,
  isSelected,
  isDisabled,
}) => {
  const selectedOptionRef = useRef<HTMLButtonElement>(null)

  useLayoutEffect(() => {
    if (isPopoverPresent) {
      setTimeout(() => {
        selectedOptionRef.current?.scrollIntoView({ block: 'center' })
      })
    }
  }, [isPopoverPresent, popoverRef])

  const {
    display,
    badgeText,
    badgeColor = 'neutral',
    badgeEmphasis = 'medium',
    value: optionValue,
  } = option

  const handleSelectOption = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      onSelectOption(e, optionValue)
    },
    [onSelectOption, optionValue],
  )

  const handleMultiSelectOption = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onMultiSelectOption(e)
    },
    [onMultiSelectOption],
  )

  return (
    <If condition={isCheckbox}>
      <Then>
        <MenuCheckbox
          value={optionValue}
          onChange={handleMultiSelectOption}
          checked={isSelected}
          data-cy={dataCy && `${dataCy}:option-${optionValue}`}
          disabled={isDisabled}
        >
          {badgePosition === 'end' && emphasizeSearchString(display, searchString)}
          <When condition={badgeText}>
            {' '}
            <Badge
              text={badgeText || ''}
              color={badgeColor}
              emphasis={badgeEmphasis}
              size="small"
            />{' '}
          </When>
          {badgePosition === 'start' && emphasizeSearchString(display, searchString)}
        </MenuCheckbox>
      </Then>
      <Else>
        <MenuButtonItem
          onClick={handleSelectOption}
          data-cy={dataCy && `${dataCy}:option-${optionValue}`}
          isToggled={isSelected}
          isIndented={isIndented}
          disabled={isDisabled}
          {...(isSelected && { ref: selectedOptionRef })}
        >
          {badgePosition === 'end' && emphasizeSearchString(display, searchString)}
          <When condition={badgeText}>
            {' '}
            <Badge
              text={badgeText || ''}
              color={badgeColor}
              emphasis={badgeEmphasis}
              size="small"
            />{' '}
          </When>
          {badgePosition === 'start' && emphasizeSearchString(display, searchString)}
        </MenuButtonItem>
      </Else>
    </If>
  )
}
