import { combineReducers } from 'redux'
import type { ByClaimReducerState } from './by-claim'
import byClaim from './by-claim'
import type { ApproveReplacementReducerState } from './approve-replacement'
import approveReplacement from './approve-replacement'
import error from './error'
import isLoading from './is-loading'
import isFulfilling from './is-fulfilling'
import isSubmittingForPayment from './is-submitting-for-payment'
import type { ErrorReducerState } from '../../types/error'
import type { Action } from '../actions'

export interface ReducerState {
  byClaim: ByClaimReducerState
  approveReplacement: ApproveReplacementReducerState
  error: ErrorReducerState
  isLoading: boolean
  isFulfilling: boolean
  isSubmittingForPayment: boolean
}

export default combineReducers<ReducerState, Action>({
  byClaim,
  approveReplacement,
  error,
  isLoading,
  isFulfilling,
  isSubmittingForPayment,
})
