import type { FC } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'
import {
  Badge,
  Breadcrumbs,
  Grid,
  DataProperty,
  COLOR,
  Button,
  More,
  useToaster,
  ToastColor,
  ToastDuration,
  Spinner,
  InlineAlert,
  InlineAlertColor,
  Menu,
} from '@helloextend/zen'
import type { User } from '@helloextend/extend-api-rtk-query'
import {
  useGetAccountQuery,
  useGetUserDetailsQuery,
  useGetUserQuery,
  useLazyGetUserGrantsListQuery,
} from '@helloextend/extend-api-rtk-query'
import { getUserRolesFromGrants } from '../../../../../../utils/user-role-mapper'
import { PageHeader } from '../../../../../../components/page-header'
import { DeactivateUserModal } from '../../../components/deactivate-user-modal/deactivate-user-modal'
import AdminToggle from './admin-toggle'
import { UserDetailsToastErrorText } from '../user-details-toast-error-text'

const UserDetails: FC = () => {
  const { toast } = useToaster()
  const { userId, accountId } = useParams<{ userId: string; accountId: string }>()
  const [isDeactivateModalVisible, setIsDeactivateModalVisible] = useState<boolean>(false)

  const [userDetails, setUserDetails] = useState<User>()

  const {
    data: v3user,
    isLoading: isGettingUserDetails,
    isError: isErrorGettingUserDetails,
  } = useGetUserDetailsQuery(userId)

  const {
    data: v2user,
    isLoading: isGettingV2User,
    isError: isErrorGettingV2User,
  } = useGetUserQuery(userId)

  const { data: accountDetails, isLoading: isGettingAccountDetails } = useGetAccountQuery({
    accountId,
  })

  const [getUserGrants, { data: userGrants, isLoading: isGettingGrants }] =
    useLazyGetUserGrantsListQuery()

  const toggleDeactivateModal = useCallback((): void => {
    setIsDeactivateModalVisible(!isDeactivateModalVisible)
  }, [isDeactivateModalVisible])

  useEffect(() => {
    if (v3user) {
      setUserDetails(v3user)
      getUserGrants(v3user.email)
    } else if (v2user)
      setUserDetails({
        firstName: v2user.firstName ?? '',
        lastName: v2user.lastName ?? '',
        login: v2user.email,
        email: v2user.email,
        status: 'PROVISIONED',
        uuid: v2user.id,
      })
  }, [getUserGrants, v2user, v3user])

  const isUserMigrated = !!v3user

  const isActiveUser = isUserMigrated && userGrants?.grants?.length

  if (isGettingUserDetails || isGettingV2User || isGettingAccountDetails) {
    return (
      <SpinnerContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </SpinnerContainer>
    )
  }

  if (isErrorGettingUserDetails && isErrorGettingV2User) {
    toast({
      message: <UserDetailsToastErrorText />,
      toastColor: ToastColor.red,
      toastDuration: ToastDuration.short,
    })
    return (
      <SpinnerContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </SpinnerContainer>
    )
  }

  return (
    <>
      <BreadcrumbsWrapper>
        <Breadcrumbs
          data-cy="account-user-breadcrumbs"
          crumbs={[
            { text: 'Accounts', to: '/admin/accounts' },
            {
              text: `${accountId}`,
              to: `/admin/accounts/${accountId}/${
                accountDetails && `isLegacyAccount` in accountDetails ? 'stores' : 'organizations'
              }`,
            },
            { text: `Users`, to: `/admin/accounts/${accountId}/users` },
            { text: `${userDetails?.firstName} ${userDetails?.lastName}` },
          ]}
        />
      </BreadcrumbsWrapper>
      {userDetails && (
        <>
          <FlexCol>
            {!isUserMigrated ? (
              <InlineAlert color={InlineAlertColor.yellow} data-cy="v2-user-warning">
                This user is not editable until after their next login. Please have the user login
                to Merchant Portal
              </InlineAlert>
            ) : null}
            <HeaderContainer>
              <HeaderLeft>
                <PageHeaderContainer>
                  <PageHeader
                    data-cy="page-header-title"
                    title={`${userDetails.firstName} ${userDetails.lastName}`}
                  />
                  {userDetails.federated && (
                    <FederatedBadge>
                      <Badge text="Federated" color="neutral" data-cy="federated-status-badge" />
                    </FederatedBadge>
                  )}
                </PageHeaderContainer>
                {userGrants && isActiveUser ? (
                  <AdminToggle
                    user={userDetails}
                    accountId={accountId}
                    userGrants={userGrants.grants}
                    refetch={() => getUserGrants(userDetails.email)}
                  />
                ) : null}
              </HeaderLeft>
              {isActiveUser ? (
                <HeaderRight>
                  <Menu
                    items={[
                      {
                        label: 'Deactivate',
                        type: 'button',
                        onClick: toggleDeactivateModal,
                        'data-cy': 'account-user-deactivate',
                      },
                    ]}
                    triggerRenderer={() => (
                      <Button data-cy="user-actions-button" emphasis="medium" icon={More} />
                    )}
                  />
                </HeaderRight>
              ) : null}
            </HeaderContainer>
            <GridContainer>
              <Grid
                columns={{
                  lg: 3,
                  md: 2,
                  sm: 1,
                }}
                data-cy="merchant-account-user-details"
                spacing={{
                  lg: 3,
                  md: 2,
                  sm: 1,
                }}
              >
                <DataProperty
                  data-cy="firstName"
                  label="First Name"
                  value={userDetails.firstName}
                />
                <DataProperty label="Last Name" value={userDetails.lastName} />
                <DataProperty label="Email" value={userDetails.email} />
                <DataProperty
                  label="Role(s)"
                  value={getUserRolesFromGrants(userGrants?.grants ?? [], 'merchant', accountId)
                    .map((x) => x.display)
                    .join(',  ')}
                  data-cy="roles"
                  isLoading={isGettingGrants}
                />
              </Grid>
            </GridContainer>
          </FlexCol>
          <DeactivateUserModal
            user={userDetails}
            isVisible={isDeactivateModalVisible}
            toggle={toggleDeactivateModal}
          />
        </>
      )}
    </>
  )
}

const FlexCol = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
})

const HeaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const HeaderLeft = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const HeaderRight = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const BreadcrumbsWrapper = styled.div({
  marginBottom: 24,
})

const GridContainer = styled.div({
  justifyContent: 'space-between',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  padding: 40,
})

const SpinnerContainer = styled.div({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  flexWrap: 'wrap',
  padding: 24,
})

const PageHeaderContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const FederatedBadge = styled.div({
  marginBottom: 24,
  marginLeft: 10,
})

export { UserDetails }
