import { createActionFailure } from '../../factories'

export const resend = (contractId: string, accessToken: string, forwardTo?: string) =>
  ({
    type: 'CONTRACTS_RESEND',
    payload: { contractId, forwardTo, accessToken },
  } as const)

export const resendRequest = () =>
  ({
    type: 'CONTRACTS_RESEND_REQUEST',
  } as const)

export const resendSuccess = (contractId: string) =>
  ({
    type: 'CONTRACTS_RESEND_SUCCESS',
    payload: contractId,
  } as const)

export const resendFailure = createActionFailure('CONTRACTS_RESEND_FAILURE')
