import { obligorOptionsList } from '../obligor-options-list'
import type { UploadValidatorPipe } from './csv-validation'
import { getColumnValue } from './csv-validation'

const expectedDropdownColumns: Array<Record<string, string | string[]>> = [
  {
    name: 'coverageType',
    userFriendlyColumnName: 'Coverage Type',
    allowedValues: ['base', 'adh'],
  },
  {
    name: 'serviceType',
    userFriendlyColumnName: 'Service Type',
    allowedValues: [
      'repair',
      'repairOnsite',
      'repairIrobot',
      'repairDepot',
      'replace',
      'partialReplace',
    ],
  },
  {
    name: 'lineOfBusiness',
    userFriendlyColumnName: 'Line of Business',
    allowedValues: ['serviceContract', 'shippingProtection'],
  },
  {
    name: 'currency',
    userFriendlyColumnName: 'Currency',
    allowedValues: ['USD', 'CAD', 'EUR'],
  },
  {
    name: 'productCondition',
    userFriendlyColumnName: 'Product Condition',
    allowedValues: ['new', 'used', 'refurbished', 'other', 'all'],
  },
  {
    name: 'purchasePriceOfProduct',
    userFriendlyColumnName: 'Purchase Price of Product',
    allowedValues: ['item', 'cart', 'bundle', 'membership'],
  },
  {
    name: 'limitOfLiabilityBasedUpon',
    userFriendlyColumnName: 'Limit of Liability Based Upon',
    allowedValues: ['product', 'contractLimit'],
  },
  {
    name: 'paymentModel',
    userFriendlyColumnName: 'Payment Model',
    allowedValues: ['single', 'monthly', 'quarterly'],
  },
  { name: 'cancellationId', userFriendlyColumnName: 'Cancellation', allowedValues: ['TBD'] },
  {
    name: 'underwriter',
    userFriendlyColumnName: 'Underwriter',
    allowedValues: obligorOptionsList.map((obligor) => obligor.value),
  },
  {
    name: 'deductible',
    userFriendlyColumnName: 'Deductible',
    allowedValues: ['TRUE', 'FALSE', 'true', 'false'],
  },
]

const validateDropdownColumns = (
  validationPipeResults: UploadValidatorPipe,
): UploadValidatorPipe => {
  expectedDropdownColumns.forEach((column) => {
    const value = getColumnValue(validationPipeResults, column.name as string)
    const isAllowedValue = column.allowedValues.includes(value)
    if (!isAllowedValue) {
      validationPipeResults.errors.push(`${column.userFriendlyColumnName} is not a valid value`)
    }
  })
  return validationPipeResults
}

export { validateDropdownColumns }
