import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const AdjudicationLanding: FC = () => {
  const history = useHistory()

  useEffect(() => {
    history.push('/admin/adjudication-management/conversations')
  })

  return <></>
}

export { AdjudicationLanding }
