import type { Product } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetch = (storeId: string, referenceId: string, accessToken: string) =>
  ({
    type: 'PRODUCTS_FETCH',
    payload: { storeId, referenceId, accessToken },
  } as const)

export const fetchRequest = () => ({ type: 'PRODUCTS_FETCH_REQUEST' } as const)

export const fetchSuccess = (product: Product) =>
  ({
    type: 'PRODUCTS_FETCH_SUCCESS',
    payload: product,
  } as const)

export const fetchFailure = createActionFailure('PRODUCTS_FETCH_FAILURE')
