import type { ApiResponse } from '../../../extend-api-fetch'
import { get, post, put } from '../../../extend-api-fetch'
import type {
  Claim,
  ClaimCreateRequest,
  ClaimSearchKey,
  ClaimUpdateRequest,
  GetClaimsReponse,
} from '../models/claim'

export async function create(
  accessToken: string,
  contractId: string,
  body: ClaimCreateRequest,
  shouldUseClaimsManagement: boolean,
): Promise<ApiResponse<Claim>> {
  return post(
    shouldUseClaimsManagement
      ? '/claims-management/claims-internal'
      : `/contracts/${contractId}/claims`,
    {
      body,
      apiVersion: 'latest',
      accessToken,
    },
  )
}

/**
 *
 * @param accessToken auth
 * @param storeId storeId
 *
 * @success {Object} data.url - presigned url good for 24 hours to fetch contracts as CSV from S3 bucket
 * @successExample {
      "url": "https://extendcoreapidevextendco-contractsdownloadbucket34-tbrajzuk2pco.s3.amazonaws.com/63989478-c3f7-4e5c-a98f-12b2e43f853f-1580493668057.csv?AWSAccessKeyId=...
    }
 */
export async function exportAll(
  accessToken: string | null,
  storeId?: string,
): Promise<ApiResponse> {
  return post(`/contracts/claims/export`, {
    accessToken,
    body: {
      storeId,
    },
  })
}

export async function fetch(
  contractId: string,
  claimId: string,
  accessToken: string,
): Promise<ApiResponse<GetClaimsReponse>> {
  return get(`/contracts/${contractId}/claims/${claimId}`, {
    accessToken,
  })
}

export async function fetchByContract(
  contractId: string,
  accessToken: string,
): Promise<ApiResponse<Record<'claims', Claim[]>>> {
  return get(`/contracts/claims/search?contractId=${contractId}`, {
    accessToken,
  })
}

export async function search(
  accessToken: string,
  searchKey: ClaimSearchKey,
  value: string,
): Promise<ApiResponse<Record<'claims', Claim[]>>> {
  return get('/contracts/claims/search', {
    accessToken,
    qs: { [searchKey]: value },
  })
}

export async function update(
  contractId: string,
  claimId: string,
  accessToken: string,
  body: ClaimUpdateRequest,
): Promise<ApiResponse<Claim>> {
  return put(`/contracts/${contractId}/claims/${claimId}`, {
    accessToken,
    body,
  })
}
