import type { FC, SyntheticEvent } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import {
  COLOR,
  Button,
  InlineAlert,
  InlineAlertColor,
  Grid,
  DataProperty,
  GridItem,
  Stack,
  Input,
  TextArea,
  Icon,
} from '@helloextend/zen'
import { Error, ExpandMore } from '@helloextend/zen/src/tokens/icons'
import { StackAlign } from '@helloextend/zen/src/components/stack'
import { FormDatePicker } from './form-date-picker'

type GroupItem = BaseItem | StringItem | DateItem

type CustomComponentProps = {
  name: string
  value?: any
  label?: string
  error?: string
  onChange: SetFieldFunction
}

interface BaseItem {
  name: string
  value?: any
  label: string
  block?: boolean
  editable?: boolean
  date?: boolean
  maxCount?: number
  error?: string
  textArea?: boolean
  fillWidth?: boolean
  isCustomComponent?: boolean

  CustomComponent?: FC<CustomComponentProps>
}

interface DateItem extends BaseItem {
  date: true
  value?: number
}

interface StringItem extends BaseItem {
  date?: false
  value?: string
}

interface ButtonItem {
  id: string
  text: string
  handleClick(): any
}

export interface GroupSection {
  header?: string
  values: Array<GroupItem | null>
  footerButton?: ButtonItem
}

type ChangeFunction = (e: SyntheticEvent) => void
type SetFieldFunction = (name: string, value: any, shouldValidate?: boolean) => void

type CollapsibleInfoGroupProps = {
  'data-cy'?: string
  title: string
  warning?: string
  sectionError?: boolean
  errorMessage?: string
  data: GroupSection[]
  numColumns?: number
  className?: string
  isCollapsible?: boolean
  isDefaultCollapsed?: boolean
  headerBadge?: FC
  handleChange: ChangeFunction & SetFieldFunction
  primaryButtonText?: string
  secondaryButtonText?: string
  onPrimaryButtonClick?: () => void
  onSecondaryButtonClick?: () => void
  buttonAlignment?: 'right' | 'left'
  isEditing?: boolean
  hasEditButton?: boolean
  onEditButtonClick?: () => void
}

const CollapsibleInfoGroup: FC<CollapsibleInfoGroupProps> = ({
  title,
  warning,
  data,
  numColumns = 1,
  className,
  isCollapsible = true,
  isDefaultCollapsed = true,
  headerBadge: HeaderBadge,
  children,
  primaryButtonText,
  secondaryButtonText,
  handleChange,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  hasEditButton = false,
  buttonAlignment = 'left',
  isEditing = false,
  onEditButtonClick,
  'data-cy': dataCy,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(isDefaultCollapsed)

  const handleCollapseToggle = (): void => {
    if (!isCollapsible) return
    setIsCollapsed(!isCollapsed)
  }

  const handleEditButtonClick = (): void => {
    if (!onEditButtonClick) return
    onEditButtonClick()
    if (isCollapsed) {
      handleCollapseToggle()
    }
  }

  return (
    <Container className={className} data-cy={dataCy ?? 'collapsible-info-group'}>
      <HeaderStack align={StackAlign.center}>
        <Header isCollapsible={isCollapsible && !isEditing}>
          <TitleBadgeWrapper
            onClick={() => {
              if (!isEditing) {
                handleCollapseToggle()
              }
            }}
          >
            <Stack doesWrap>
              <Title data-cy={title}>{title}</Title>
              {HeaderBadge && <HeaderBadge />}
            </Stack>
          </TitleBadgeWrapper>
          <Stack align={StackAlign.center} spacing={1}>
            {hasEditButton && !isEditing && (
              <Button
                emphasis="low"
                text="Edit"
                data-cy="edit-btn"
                size="small"
                onClick={handleEditButtonClick}
              />
            )}
            {isCollapsible && !isEditing && (
              <CaretWrapper
                data-cy="collapse-caret"
                isCollapsed={isCollapsed}
                onClick={handleCollapseToggle}
              >
                <Icon icon={ExpandMore} />
              </CaretWrapper>
            )}
          </Stack>
        </Header>
      </HeaderStack>
      <ContentWrapper isCollapsible={isCollapsible} isCollapsed={isCollapsed}>
        {warning && (
          <WarningWrapper>
            <InlineAlert icon={Error} color={InlineAlertColor.red}>
              {warning}
            </InlineAlert>
          </WarningWrapper>
        )}
        {data.map((section: GroupSection, idx: number) => {
          return (
            <SectionWrapper isLast={Boolean(!data[idx + 1])} key={`section-${idx + 1}`}>
              {Boolean(section.header) && <SectionHeader>{section.header}</SectionHeader>}
              <Grid
                columns={{ lg: numColumns ?? 2, md: 2, sm: 1 }}
                spacing={{ lg: 3, md: 2, sm: 2 }}
              >
                {section.values.map((item: GroupItem | null) => {
                  if (item == null) return null

                  const {
                    editable = false,
                    date = false,
                    isCustomComponent = false,
                    CustomComponent,
                    ...itemProps
                  } = item

                  return (
                    <GridItem key={itemProps.name} fillWidth={itemProps.fillWidth ?? false}>
                      {editable && (
                        <EditableItemWrapper data-cy={itemProps.label}>
                          {CustomComponent && (
                            <CustomComponent
                              onChange={(name, v, shouldValidate): void => {
                                handleChange(name || itemProps.name, v, shouldValidate)
                              }}
                              {...itemProps}
                            />
                          )}
                          {!CustomComponent && date && (
                            <FormDatePicker
                              label={itemProps.label}
                              date={itemProps.value ? new Date(itemProps.value) : null}
                              onChange={(v): void => {
                                handleChange(itemProps.name, v)
                              }}
                              invalid={Boolean(itemProps.error)}
                              errorMessage={itemProps.error}
                            />
                          )}
                          {!CustomComponent &&
                            !date &&
                            (itemProps.textArea ? (
                              <TextArea
                                id={itemProps.name}
                                label={itemProps.label}
                                value={itemProps.value ?? ''}
                                maxLength={itemProps.maxCount}
                                onChange={(e): void => {
                                  handleChange(itemProps.name, e.target.value)
                                }}
                                isError={Boolean(itemProps.error)}
                                errorFeedback={itemProps.error}
                                data-cy={itemProps.name}
                              />
                            ) : (
                              <Input
                                label={itemProps.label}
                                id={itemProps.name}
                                value={itemProps.value || ''}
                                max={itemProps.maxCount}
                                onChange={(e): void => {
                                  handleChange(itemProps.name, e.target.value)
                                }}
                                isError={Boolean(itemProps.error)}
                                errorFeedback={itemProps.error}
                                data-cy={itemProps.name}
                              />
                            ))}
                        </EditableItemWrapper>
                      )}

                      {isCustomComponent && CustomComponent && (
                        <CustomComponent name={itemProps.name} onChange={() => {}} />
                      )}

                      {!editable && !isCustomComponent && (
                        <DataProperty
                          label={itemProps.label}
                          value={itemProps.value}
                          data-cy={itemProps.label}
                        />
                      )}
                    </GridItem>
                  )
                })}
                {section.footerButton && (
                  <ButtonWrapper id={section.footerButton.id}>
                    <Button
                      text={section.footerButton.text}
                      emphasis="medium"
                      onClick={section.footerButton.handleClick}
                    />
                  </ButtonWrapper>
                )}
              </Grid>
            </SectionWrapper>
          )
        })}
        {children}
      </ContentWrapper>
      {(primaryButtonText || secondaryButtonText) && (
        <ButtonRow alignment={buttonAlignment}>
          {primaryButtonText && (
            <Button
              data-cy={`${primaryButtonText}-btn`}
              emphasis="high"
              text={primaryButtonText}
              size="regular"
              onClick={onPrimaryButtonClick}
            />
          )}
          {secondaryButtonText && (
            <Button
              emphasis="medium"
              data-cy={`${secondaryButtonText}-btn`}
              text={secondaryButtonText}
              size="regular"
              onClick={onSecondaryButtonClick}
            />
          )}
        </ButtonRow>
      )}
    </Container>
  )
}

const SectionHeader = styled.h5({
  textTransform: 'uppercase',
})

const SectionWrapper = styled.div<{ isLast: boolean }>(({ isLast }) => ({
  marginTop: 32,
  borderBottom: isLast ? undefined : `1px solid ${COLOR.NEUTRAL[300]}`,
  paddingBottom: isLast ? 0 : 16,
  width: '100%',
}))

const HeaderStack = styled(Stack)()

const Header = styled.div<{ isCollapsible: boolean }>(({ isCollapsible }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  cursor: isCollapsible ? 'pointer' : 'default',
}))

const ContentWrapper = styled.div<{ isCollapsible: boolean; isCollapsed?: boolean }>(
  ({ isCollapsible, isCollapsed }) => ({
    width: '100%',
    ...(!isCollapsible && { display: 'flex', flexDirection: 'column' }),
    ...(isCollapsible && {
      overflow: isCollapsed ? 'hidden' : undefined,
      maxHeight: isCollapsed ? 0 : 1000,
      transition: '250ms max-height ease-in-out',
    }),
  }),
)

const CaretWrapper = styled.div<{ isCollapsed: boolean }>(({ isCollapsed }) => ({
  '& > svg': {
    transform: isCollapsed ? 'scale(1.5)' : 'scale(1.5) rotate(-180deg)',
    transition: '250ms transform ease-in-out',
  },
  cursor: 'pointer',
}))

const EditableItemWrapper = styled.div({
  width: '100%',
  marginRight: 24,
})

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  marginBottom: 32,
  padding: 40,
})

const ButtonRow = styled.div<{ alignment: 'right' | 'left' }>(({ alignment }) => ({
  display: 'flex',
  flexDirection: alignment === 'right' ? 'row-reverse' : 'row',
  justifyContent: alignment,
  gap: 20,
  marginTop: 40,
}))

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '33px',
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  marginRight: 12,
})

const TitleBadgeWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
})

const WarningWrapper = styled.div({
  marginTop: 24,
  marginBottom: 24,
})

const ButtonWrapper = styled.div({
  marginTop: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export { CollapsibleInfoGroup, CollapsibleInfoGroupProps, GroupItem, CustomComponentProps }
