import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import type { UpdateUserRequest } from '@helloextend/extend-api-rtk-query'
import {
  useGetUsersQuery,
  useResendInviteMutation,
  useUpdateUserMutation,
} from '@helloextend/extend-api-rtk-query'
import { Breadcrumbs, Button, ButtonGroup, COLOR, ModalController, Spinner } from '@helloextend/zen'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useStandardToast } from '@helloextend/merchants-ui'
import { useToggle } from '@helloextend/client-hooks'
import type { RootState } from '../../../../../../reducers'
import * as selectors from '../../../../../../reducers/selectors'
import { UserForm } from '../../../components/v2/user-form/user-form'
import { DeleteUserModal } from '../../../components/v2/delete-user-modal/delete-user-modal'

const AccountUser: FC = () => {
  const { accountId, userId } = useParams<{ accountId: string; userId: string }>()
  const { toastError, toastCompleted } = useStandardToast()
  const currentUserId = useSelector((state: RootState) => selectors.getUserId(state))
  const { goBack } = useHistory()
  const [isModalDisplayed, { on: displayModalOn, off: displayModalOff }] = useToggle(false)

  const { user, isUserLoading, isUserLoadingSuccess, isUserError } = useGetUsersQuery(
    accountId || '',
    {
      selectFromResult: ({ data, isLoading, isSuccess, isError }) => ({
        user: data?.find((userItem) => userItem.id === userId),
        isUserLoading: isLoading,
        isUserLoadingSuccess: isSuccess,
        isUserError: isError,
      }),
    },
  )

  const [resendInvite, { isLoading: isResendEmailLoading }] = useResendInviteMutation()
  const [updateUser, { isLoading: isUpdateUserLoading }] = useUpdateUserMutation()

  const isMe = useMemo(() => userId === currentUserId, [currentUserId, userId])

  const handleResendEmailInvite = async (): Promise<void> => {
    if (user) {
      try {
        await resendInvite({ email: user.email }).unwrap()
        toastCompleted('Email invite resent!')
      } catch {
        toastError('Something went wrong. Please try again.')
      }
    }
  }

  const handleCancelClick = useCallback(() => {
    goBack()
  }, [goBack])

  const handleSaveChangesClick = async ({
    firstName,
    lastName,
    role,
  }: UpdateUserRequest['data']): Promise<void> => {
    if (userId) {
      try {
        await updateUser({
          userId,
          data: {
            firstName,
            lastName,
            role,
          },
        }).unwrap()
        toastCompleted('User info has been updated successfully.')
      } catch {
        toastError('Something went wrong. Please try again.')
      }
    }
  }

  useEffect(() => {
    if (isUserError) {
      toastError('There was an unexpected error loading the user. Please try again.')
    }
  }, [isUserError, toastError])

  useEffect(() => {
    if (isUserLoadingSuccess && !user) {
      toastError('The user is not found.')
    }
  }, [isUserLoadingSuccess, toastError, user])

  if (isUserLoading || !user) {
    return (
      <LoadingContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </LoadingContainer>
    )
  }

  return (
    <>
      <Header>
        <Breadcrumbs
          crumbs={[
            {
              text: 'Stores',
              to: '/admin/stores',
            },
            {
              text: `Account ID: ${accountId}`,
              to: `/admin/accounts/${accountId}/stores`,
            },
            {
              text: 'Users',
              to: `/admin/accounts/${accountId}/users`,
            },
            {
              text: 'User Details',
            },
          ]}
          data-cy="user-details-breadcrumbs"
        />
        <ButtonGroup>
          <Button
            text="Resend Email Invite"
            onClick={handleResendEmailInvite}
            isProcessing={isResendEmailLoading}
            isDisabled={isResendEmailLoading}
            emphasis="medium"
            data-cy="resend-email-invite-button"
          />
          {!isMe && (
            <>
              <Button
                text="Delete User"
                color="red"
                emphasis="medium"
                onClick={displayModalOn}
                data-cy="delete-user-button"
              />
              <ModalController isOpen={isModalDisplayed}>
                <DeleteUserModal toggleOff={displayModalOff} user={user} />
              </ModalController>
            </>
          )}
        </ButtonGroup>
      </Header>
      <UserForm
        submitButtonText="Save Changes"
        onSubmit={handleSaveChangesClick}
        handleCancelClick={handleCancelClick}
        isLoading={isUpdateUserLoading}
        isFullWidth={false}
        isEditForm
        userData={user}
      />
    </>
  )
}

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

const LoadingContainer = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export { AccountUser }
