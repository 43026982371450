import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import {
  COLOR,
  useToaster,
  ToastDuration,
  ToastColor,
  Modal,
  ModalController,
  Grid,
  Input,
  GridItem,
  AdvancedSelect,
} from '@helloextend/zen'
import * as Yup from 'yup'
import type { User } from '@helloextend/extend-api-rtk-query'
import { useCreateUserGrantMutation } from '@helloextend/extend-api-rtk-query'
import { useFormik } from 'formik'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../../../../../constants/ld-flags'
import { enterpriseOptions, legacyInternalOptions } from '../../../../../utils/user-role-mapper'

type ActivateUserModalProps = {
  user: User
  visible: boolean
  onClickClose: () => void
}

const ActivateUserModal: FC<ActivateUserModalProps> = ({ user, visible, onClickClose }) => {
  const { toast } = useToaster()
  const { [LDFlag.EnterpriseRoles]: FF_ENTERPRISE_ROLES } = useFlags()

  const [addGrantToUser, { isLoading: isAddGrantToUserLoading }] = useCreateUserGrantMutation()

  const { errors, values, dirty, handleChange, handleSubmit, resetForm } = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object()
      .shape({
        roles: Yup.array().of(Yup.string()).required('Please select at least one user role'),
      })
      .defined(),
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      roles: [],
    },
    onSubmit: async (formData): Promise<void> => {
      try {
        await addGrantsToUser(formData.roles)
      } catch (error) {
        toast({
          message: 'Error activating user',
          toastDuration: ToastDuration.short,
          toastColor: ToastColor.red,
        })
      }
    },
  })

  const addGrantsToUser = async (roles: string[]): Promise<void> => {
    const results = await Promise.all(
      roles.map(async (role) => addGrantToUser({ userId: user.email, role, ern: 'ERN:ACC:*' })),
    )

    if (results.some((result) => 'error' in result)) {
      resetForm()
      throw new Error()
    }

    toast({
      message: `The user has been activated`,
      toastDuration: ToastDuration.short,
      toastColor: ToastColor.blue,
    })
    resetForm()
    onClickClose()
  }

  const hasErrors = useMemo(() => Object.entries(errors).length > 0, [errors])

  return (
    <ModalController isOpen={visible}>
      <Modal
        size="md"
        heading="Activate User"
        primaryButtonProps={{
          onClick: function noRefCheck() {
            handleSubmit()
          },
          text: 'Activate',
          isDisabled: !dirty || hasErrors || isAddGrantToUserLoading,
          isProcessing: isAddGrantToUserLoading,
          'data-cy': 'submit-activate-user-button',
        }}
        secondaryButtonProps={{
          onClick: function noRefCheck() {
            onClickClose()
          },
          text: 'Cancel',
          'data-cy': 'cancel-activate-user-button',
        }}
        data-cy="activate-user-modal"
      >
        <DetailText>Add roles to activate this user.</DetailText>

        <FormGroup isFullWidth>
          <Grid columns={{ lg: 2, md: 2, sm: 1 }} spacing={{ lg: 3, md: 3, sm: 2 }}>
            <Input
              id="firstName"
              label="First Name"
              data-cy="firstName"
              value={user.firstName}
              isDisabled
            />
            <Input
              id="lastName"
              data-cy="lastName"
              label="Last Name"
              value={user.lastName}
              isDisabled
            />
            <GridItem fillWidth>
              <Input id="email" label="Email" data-cy="email" value={user.email} isDisabled />
            </GridItem>
            <GridItem fillWidth>
              <AdvancedSelect
                id="roles"
                label="Role"
                maxQuantityToDisplay={8}
                multiple
                data-cy="select-roles"
                onChange={handleChange}
                options={FF_ENTERPRISE_ROLES ? enterpriseOptions : legacyInternalOptions}
                placeholder="Select"
                value={values.roles}
              />
            </GridItem>
          </Grid>
        </FormGroup>
      </Modal>
    </ModalController>
  )
}

const DetailText = styled.p({
  color: COLOR.NEUTRAL[1000],
  fontSize: 16,
  marginTop: 0,
  marginBottom: 24,
})

const FormGroup = styled.div<{ isFullWidth: boolean }>(({ isFullWidth }) => ({
  width: isFullWidth ? '100%' : '55%',
}))

export default ActivateUserModal
