import { createActionFailure } from '../../factories'

export const forgotPassword = (username: string) =>
  ({
    type: 'FORGOT_PASSWORD',
    payload: { username },
  } as const)

export const forgotPasswordRequest = () =>
  ({
    type: 'FORGOT_PASSWORD_REQUEST',
  } as const)

export const forgotPasswordSuccess = () =>
  ({
    type: 'FORGOT_PASSWORD_SUCCESS',
  } as const)

export const forgotPasswordFailure = createActionFailure('FORGOT_PASSWORD_FAILURE')
