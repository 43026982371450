import type { Product } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const update = (storeId: string, referenceId: string, data: any, accessToken: string) =>
  ({
    type: 'PRODUCTS_UPDATE',
    payload: { storeId, referenceId, data, accessToken },
  } as const)

export const updateRequest = () => ({ type: 'PRODUCTS_UPDATE_REQUEST' } as const)

export const updateSuccess = (product: Product) =>
  ({
    type: 'PRODUCTS_UPDATE_SUCCESS',
    payload: product,
  } as const)

export const updateFailure = createActionFailure('PRODUCTS_UPDATE_FAILURE')
