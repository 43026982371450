import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useParams } from 'react-router'
import { COLOR } from '@helloextend/client-branding'
import { useGetServicerQuery } from '@helloextend/extend-api-rtk-query'
import { TabMenu } from '../../../components/tab-menu/tab-menu'
import { ServicerPageWrapper } from './servicer-page-wrapper'
import { LocationsTab } from './tabs/locations-tab'
import { ProfileTab } from './tabs/profile'
import { ServiceCapabilitiesTab } from './tabs/service-capabilities'
import { ShippingLabelTab } from './tabs/shipping'
import { PaymentTab } from './tabs/payment'

export enum ServicerTab {
  Profile = 'profile',
  Locations = 'locations',
  ServiceCapabilities = 'serviceCapabilities',
  Shipping = 'shipping',
  Payment = 'payment',
}

const Servicer: FC = () => {
  const { servicerId } = useParams<{ servicerId: string }>()
  const { data: servicer } = useGetServicerQuery(servicerId)
  const [activeTab, setActiveTab] = useState<ServicerTab>(ServicerTab.Profile)

  return (
    <ServicerPageWrapper>
      <Header>
        <Title data-cy="servicer-business-name">{servicer?.businessName}</Title>
        <TitleWrapper>
          <Subtitle data-cy="servicer-id">
            <strong>ID:</strong> {servicer?.id}
          </Subtitle>
        </TitleWrapper>
      </Header>
      <TabContent>
        <TabMenu
          tabs={[
            { text: 'Profile', key: ServicerTab.Profile },
            { text: 'Locations', key: ServicerTab.Locations },
            { text: 'Service Capabilities', key: ServicerTab.ServiceCapabilities },
            { text: 'Shipping', key: ServicerTab.Shipping },
            { text: 'Payment', key: ServicerTab.Payment },
          ]}
          onClick={(tab) => setActiveTab(tab as ServicerTab)}
          activeTab={activeTab}
        />
      </TabContent>

      {activeTab === ServicerTab.Profile && <ProfileTab />}
      {activeTab === ServicerTab.Locations && <LocationsTab />}
      {activeTab === ServicerTab.ServiceCapabilities && <ServiceCapabilitiesTab />}
      {activeTab === ServicerTab.Shipping && <ShippingLabelTab />}
      {activeTab === ServicerTab.Payment && <PaymentTab />}
    </ServicerPageWrapper>
  )
}

const TitleWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const Title = styled.h1({
  color: COLOR.EXTEND_OXFORD,
  fontSize: 32,
  lineHeight: '44px',
  fontWeight: 800,
  marginTop: 0,
  marginBottom: 8,
  marginRight: 12,
})

const Header = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingBottom: 16,
  flex: 1,
})

const Subtitle = styled.p({
  margin: 0,
  marginBottom: 4,
  fontSize: 16,
  color: COLOR.EXTEND_OXFORD,
})

const TabContent = styled.div({
  marginBottom: 40,
})

export { Servicer }
