import { faker } from '@faker-js/faker/locale/en'
import type { MerchantServicingSettings } from '@helloextend/extend-api-rtk-query/src/servicers/types'

export const generateMerchantServicingSettings = (
  overrides: Partial<MerchantServicingSettings> = {},
): MerchantServicingSettings => ({
  sellerId: faker.datatype.uuid(),
  fulfillmentEnabled: false,
  fieldDestroyEnabled: false,
  primaryOnsiteServicerId: faker.datatype.uuid(),
  primaryDepotRepairServicerId: faker.datatype.uuid(),
  autoAssignDepotRepairServicer: false,
  autoAssignOnsiteServicer: false,
  replacementFulfillmentMethod: 'discount_code',
  ...overrides,
})
