import type { Action } from '../actions'
import type { FSAction } from '../../types/utility'

type ClaimIdReducer<TAction extends FSAction> = (
  state: string | null | undefined,
  action: TAction,
) => string | null

function createClaimIdReducer<TAction extends FSAction>(): ClaimIdReducer<TAction> {
  return function reducer(state = null, action: TAction): string | null {
    switch (action.type) {
      case 'CLAIMS_CREATE_SUCCESS':
        return action.payload.id
      default:
        return state
    }
  }
}

const newClaimId = createClaimIdReducer<Action>()

export default newClaimId
