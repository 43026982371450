import { skuCsvHeaders } from './skus-csv-headers'

interface CsvHeaderErrors {
  unidentifiableColumns: string[]
  missingColumns: string[]
}

/**
 * Converts an array of header errors to a string
 * with a list of errors
 */
const convertHeaderErrors = (errors: CsvHeaderErrors): string[] => {
  const errorStrings = []
  if (errors.missingColumns.length > 0) {
    errorStrings.push(`Missing columns: ${errors.missingColumns.join(', ')}`)
  }
  if (errors.unidentifiableColumns.length > 0) {
    errorStrings.push(`Unidentifiable columns: ${errors.unidentifiableColumns.join(', ')}`)
  }
  return errorStrings
}

/**
 * Validates the header of a CSV file and and returns either missing or extra columns
 */
const validateHeader = (headers: string[]): string[] => {
  const unidentifiableColumns: string[] = []
  const missingColumns: string[] = []

  const allowedHeadersSet = new Set(skuCsvHeaders)
  const existingHeadersSet = new Set(headers)

  existingHeadersSet.forEach((header) => {
    if (!allowedHeadersSet.has(header)) {
      unidentifiableColumns.push(header)
    }
  })

  allowedHeadersSet.forEach((header) => {
    if (!existingHeadersSet.has(header)) {
      missingColumns.push(header)
    }
  })
  return convertHeaderErrors({ unidentifiableColumns, missingColumns })
}

export { validateHeader }
