import type { FC, ReactNode } from 'react'
import React, { Component } from 'react'
import logger from '@helloextend/client-logger'

interface ErrorBoundaryProps {
  page: FC
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary<T extends ErrorBoundaryProps> extends Component<T, ErrorBoundaryState> {
  state = {
    hasError: false,
  }

  componentDidCatch(error: Error, errorInfo: { componentStack: string }): void {
    logger.error(error.message, errorInfo)
  }

  render(): ReactNode {
    const {
      state: { hasError },
      props: { children },
    } = this

    if (hasError) {
      const { page } = this.props
      const Page: FC = page
      return <Page />
    }

    return children
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true }
  }
}

export { ErrorBoundary }
