import type { RefObject } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { BoundingBox } from './types'
import {
  addToTree,
  attachScrollEvent,
  detachScrollEvent,
  isClickInSelfOrChild,
  removeFromTree,
} from './utils'

interface UsePopover<TElementType> {
  triggerRef: RefObject<TElementType>
  popoverRef: RefObject<HTMLDivElement>
  isPresent: boolean
  hide: () => void
  show: () => void
  toggle: () => void
  hideAll: () => void
  triggerBoundingBox: BoundingBox | null
}

interface UsePopoverOptions {
  closeOnClickOutside?: boolean
  onShow?: () => void
  onHide?: () => void
}

export function usePopover<TElementType extends HTMLElement>({
  closeOnClickOutside = true,
  onShow = () => {},
  onHide = () => {},
}: UsePopoverOptions = {}): UsePopover<TElementType> {
  const triggerRef = useRef<TElementType>(null)
  const popoverRef = useRef<HTMLDivElement>(null)

  const [isPresent, setIsPresent] = useState(false)

  const hide = useCallback(() => {
    if (isPresent) {
      removeFromTree(popoverRef)
      setIsPresent(false)
      setTimeout(() => onHide())
    }
  }, [isPresent, onHide])

  const show = useCallback(() => {
    if (!isPresent) {
      addToTree(triggerRef.current, popoverRef)
      setIsPresent(true)
      onShow()
    }
  }, [isPresent, onShow])

  const hideAll = useCallback(() => {
    document.dispatchEvent(new Event('popoverHideAll'))
  }, [])

  const toggle = useCallback(() => {
    if (isPresent) {
      hide()
    } else {
      show()
    }
  }, [isPresent, show, hide])

  useEffect(() => {
    const handleClick = (e: Event): void => {
      if (
        closeOnClickOutside &&
        e.target instanceof Node &&
        triggerRef.current &&
        !triggerRef.current.contains(e.target) &&
        !isClickInSelfOrChild(e.target, popoverRef)
      ) {
        hide()
      }
    }

    const parentNode = triggerRef.current?.parentNode ?? null
    if (isPresent) {
      document.addEventListener('mousedown', handleClick)
      document.addEventListener('popoverHideAll', hide)
      attachScrollEvent(parentNode, hide)
      window.addEventListener('resize', hide)
    }
    return () => {
      setTimeout(() => {
        document.removeEventListener('mousedown', handleClick)
        document.removeEventListener('popoverHideAll', hide)
        detachScrollEvent(parentNode, hide)
        window.addEventListener('resize', hide)
      })
    }
  }, [closeOnClickOutside, hide, isPresent, triggerRef])

  useEffect(() => {
    removeFromTree(popoverRef)
  }, [])

  const handleKeyDown = useCallback(
    (e: globalThis.KeyboardEvent) => {
      if (e.key === 'Escape') {
        hide()
      }
    },
    [hide],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  const targetBounding = !triggerRef.current ? null : triggerRef.current.getBoundingClientRect()

  const triggerBoundingBox = useMemo(
    () =>
      targetBounding?.left
        ? {
            left: targetBounding?.left,
            top: targetBounding?.top,
            width: targetBounding?.width,
            height: targetBounding?.height,
          }
        : null,
    [targetBounding?.left, targetBounding?.top, targetBounding?.width, targetBounding?.height],
  )

  return { triggerRef, popoverRef, isPresent, show, hide, toggle, hideAll, triggerBoundingBox }
}
