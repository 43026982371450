import type { ClaimPhotosCreateRequest } from '@helloextend/extend-api-client'
import type { PresignedPost } from 'aws-sdk/clients/s3'
import { createActionFailure } from '../../factories'

export const create = (
  contractId: string,
  claimId: string,
  body: ClaimPhotosCreateRequest,
  accessToken: string,
) =>
  ({
    type: 'CONTRACTS_CLAIMS_PHOTOS_CREATE',
    payload: { contractId, claimId, body, accessToken },
  } as const)

export const createRequest = () => ({ type: 'CONTRACTS_CLAIMS_PHOTOS_CREATE_REQUEST' } as const)

export const createSuccess = (name: string, presignedPost: PresignedPost) =>
  ({
    type: 'CONTRACTS_CLAIMS_PHOTOS_CREATE_SUCCESS',
    payload: { name, presignedPost },
  } as const)

export const createFailure = createActionFailure('CONTRACTS_CLAIMS_PHOTOS_CREATE_FAILURE')
