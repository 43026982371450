import type {
  ContractsUpdateResponse,
  ContractsV2UpdateResponse,
} from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const update = (contractId: string, accessToken: string, data: any) =>
  ({
    type: 'CONTRACTS_UPDATE',
    payload: { contractId, accessToken, data },
  } as const)

export const updateRequest = () => ({ type: 'CONTRACTS_UPDATE_REQUEST' } as const)

export const updateSuccess = (contract: ContractsUpdateResponse) =>
  ({
    type: 'CONTRACTS_UPDATE_SUCCESS',
    payload: contract,
  } as const)

export const updateFailure = createActionFailure('CONTRACTS_UPDATE_FAILURE')

export const updateSuccessReset = () => ({ type: 'CONTRACTS_UPDATE_RESET' } as const)
// Contracts V2

export const updateV2 = (contractId: string, accessToken: string, data: any) =>
  ({
    type: 'CONTRACTS_UPDATE_V2',
    payload: { contractId, accessToken, data },
  } as const)

export const updateRequestV2 = () => ({ type: 'CONTRACTS_UPDATE_REQUEST_V2' } as const)

export const updateSuccessV2 = (contract: ContractsV2UpdateResponse) =>
  ({
    type: 'CONTRACTS_UPDATE_SUCCESS_V2',
    payload: contract,
  } as const)

export const updateFailureV2 = createActionFailure('CONTRACTS_UPDATE_FAILURE_V2')
