// When adding a new color check the hexcode in colorhexa.com "color description" for name
// If there is already a color with the same name, check with design to see if necessary

// TODO: review colors with number endings with design

/**
 * @deprecated Use Zen Colors instead: `import { COLOR } from '@helloextend/zen'`. Check with UX for appropriate color code when matching.
 */
export const COLOR = {
  BLACK: '#000',
  BLACK_PRIMARY: '#000C19',
  BLACK_MUTED: '#666D75',
  BLACK_SECONDARY: '#404953',
  BOX_SHADOW_INSET:
    'inset 1px 0 0 #dadce0,0 1px 2px 0 rgba(60,64,67,.3),0 1px 3px 1px rgba(60,64,67,.15)',
  BRIGHT_BLUE: '#27AEE4',
  BRIGHT_RED_1: '#DC3545',
  BRIGHT_RED: '#E13B3B',
  // SOLID BLUES WITH WEIGHT
  BLUE_SOLID_100: '#EEF3FF',
  BLUE_SOLID_800: '#0033CC',
  DANGER: '#DE3535',
  DANGER_DARK: '#991300',
  DANGER_LIGHT: '#ED9AA2',
  DARK_CYAN: '#15a78f',
  DARK_GRAY_1: '#7f7f7f',
  DARK_GRAY: '#979797',
  DARK_GRAYISH_BLUE_1: '#596673',
  DARK_GRAYISH_BLUE: '#80898f',
  DARK_MODERATE_LIME_GREEN: '#3c763d',
  DARK_MODERATE_RED: '#a94442',
  EXTEND_BLUE: '#0033CC',
  EXTEND_WHITE: '#F3F6F9',
  EXTEND_LIGHT_BLUE: '#F7F6FF',
  EXTEND_BLUE_DARK: '#13068E',
  EXTEND_BLUE_LIGHT: '#597BDE',
  EXTEND_GREEN: '#00663B',
  EXTEND_YELLOW: '#884900',
  EXTEND_RED: '#B50318',
  EXTEND_OXFORD: '#080636',
  GRADIENT_BLUE: 'linear-gradient(45deg,  #0033cc,  #c8c2ff)',
  REVERSE_GRADIENT_BLUE: `linear-gradient(
    45deg, #c8c2ff, #0033cc)`,
  GRAYISH_BLUE_0: '#B2BCC3',
  GRAYISH_BLUE_1: '#969EBD',
  GHOST: '#D2D7DB',
  GREY: '#B6B6B6',
  // SOLID GREENS WITH WEIGHT
  GREEN_SOLID_100: '#DFFFEA',
  GREEN_SOLID_800: '#015735',
  LIGHT_BLUE: '#f4f6fc',
  LIGHT_BLUE_2: '#F5FAFF',
  LIGHT_GRAY: '#CBCBCB',
  LIGHT_GRAYISH_BLUE_0: '#D5DCE0',
  LIGHT_GRAYISH_BLUE_1: '#F6F7F8',
  LIGHT_GRAYISH_BLUE_2: '#ECEEEF',
  LIGHT_GRAYISH_BLUE_3: '#DEE2E6',
  LIGHT_GRAYISH_BLUE_4: '#E7EAED',
  LIGHT_GRAYISH_CYAN: '#fafbfb',
  LIGHT_GRAYISH_VIOLET: '#f6f4fb',
  LIGHT_LIME_GREEN: '#C0EDD7',
  LIGHT_YELLOW: '#FFD964',
  LIME_GREEN: '#24BD54',
  MIDNIGHT: '#1D1961',
  MODERATE_BLUE: '#4c6dcf',
  MODERATE_VIOLET: '#683ab8',
  MOSTLY_PURE_ORANGE: '#FF4900',
  MOSTLY_PURE_RED: '#E20000',
  MOSTLY_WHITE_0: '#f7f7f7',
  MOSTLY_WHITE_1: '#f5f5f5',
  PURE_ORANGE: '#F7B500',
  // SOLID REDS WITH WEIGHT
  RED_SOLID_100: '#FFE8EA',
  RED_SOLID_800: '#8A0011',
  SOFT_YELLOW: '#FDE073',
  SLATE: '#e4e7ea',
  SNOW: '#F6F7F8',
  STATE_INFO_VERY_LIGHT: '#EEF3FF',
  STATE_SUCCESS: '#2d912c',
  STATE_SUCCESS_VERY_LIGHT: '#DFFFEA',
  STATE_WARNING: '#967103',
  STATE_WARNING_LIGHT: '#FFF8E1',
  STATE_WARNING_VERY_LIGHT: '#FFF8D4',
  STATE_DANGER: '#B22C0A',
  STATE_DANGER_VERY_LIGHT: '#FFE8EA',
  STRONG_BLUE_1: '#0275d8',
  STRONG_BLUE_2: '#0033CC',
  STRONG_BLUE_3: '#12098E',
  STRONG_BLUE: '#224BC5',
  STRONG_CYAN_LIME_GREEN: '#2dc47c',
  STRONG_CYAN: '#18bba8',
  STRONG_RED: '#C7213B',
  VERY_DARK_BLUE_0: '#090637',
  VERY_DARK_BLUE_1: '#152935',
  VERY_DARK_DESATURATED_BLUE_1: '#2d2b54',
  VERY_DARK_DESATURATED_BLUE: '#403e60',
  VERY_DARK_GRAYISH_BLUE_0: '#6c6f71',
  VERY_DARK_GRAYISH_BLUE_1: '#44484c',
  VERY_DARK_GRAYISH_BLUE_2: '#373a3c',
  VERY_DARK_RED: '#721c24',
  VERY_LIGHT_BLUE: '#70D6FF',
  VERY_LIGHT_GRAY: '#FAFAFA',
  VERY_PALE_RED: '#fff1f2',
  VERY_SOFT_ORANGE: '#FCE8B2',
  VIVID_ORANGE: '#FFB816',
  VIVID_RED: '#E02020',

  WHITE: '#FFFFFF',
  WHITE_SECONDARY: '#BFBFBF',
}
