import { fetchPlan } from '@helloextend/extend-api-rtk-query'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { parsePlans } from '../../../../utils/plans-parser'
import type { PlansValidationResult } from '../actions/import'

const usePerformValidation = (): {
  performValidation: (files: File[]) => Promise<PlansValidationResult>
} => {
  const dispatch = useDispatch()
  const performValidation = useMemo(
    () =>
      async (files: File[]): Promise<PlansValidationResult> => {
        const valResult: PlansValidationResult = {
          existingPlans: [],
          newPlans: [],
          failedPlans: [],
          errorMessage: '',
        }
        if (files.length > 6) {
          valResult.errorMessage = 'Please upload a maximum of 6 files.'
          return Promise.resolve(valResult)
        }

        const result = await parsePlans(files)

        // batch level error, return error message
        valResult.errorMessage = result.errorMessage
        if (valResult.errorMessage) {
          return valResult
        }
        valResult.failedPlans = result.failed
        const promises = result.parsed.map(async (planFile): Promise<void> => {
          // TODO: [PUPS-88] refactor the use of any here
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const queryResult: any = dispatch(fetchPlan.initiate(planFile.plan.id))
          queryResult.unsubscribe()
          const planResponse = await queryResult
          if (planResponse.data) {
            valResult.existingPlans.push({
              file: planFile.file,
              plan: planFile.plan,
              version: planResponse.data.version,
            })
          } else {
            valResult.newPlans.push({ file: planFile.file, plan: planFile.plan })
          }
        })
        await Promise.all(promises)
        return valResult
      },
    [dispatch],
  )
  return { performValidation }
}

export { usePerformValidation }
