import type { FC } from 'react'
import React from 'react'
import { useFormik } from 'formik'
import { AdvancedSelect, Button, ButtonGroup, Grid, GridItem, Input } from '@helloextend/zen'
import styled from '@emotion/styled'
import type { StoreUser } from '@helloextend/extend-api-client'
import { StoreUserRole } from '@helloextend/extend-api-client'
import type { CreateUserRequest, UpdateUserRequest } from '@helloextend/extend-api-rtk-query'
import { roleOptions, schema } from './schema'
import { NewUserRoleDescription } from './create-user-role-description'

type UserFormProps = {
  isLoading: boolean
  submitButtonText: string
  isDisabled?: boolean
  isFullWidth?: boolean
  isEditForm?: boolean
  userData?: StoreUser
  onSubmit: (formData: UpdateUserRequest['data'] | CreateUserRequest) => void
  handleCancelClick: () => void
}

const UserForm: FC<UserFormProps> = ({
  isLoading,
  submitButtonText,
  isDisabled = false,
  isFullWidth = true,
  isEditForm = false,
  userData,
  onSubmit,
  handleCancelClick,
}) => {
  const { errors, values, dirty, isValid, handleChange, handleBlur } = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      firstName: userData?.firstName ?? '',
      lastName: userData?.lastName ?? '',
      email: userData?.email ?? '',
      role: userData?.role ? StoreUserRole[userData.role] : '',
    },
    onSubmit: () => {},
  })

  const handleSubmit = (): void => {
    const { firstName, lastName, email, role } = values

    const submitData = {
      firstName,
      lastName,
      role: StoreUserRole[role as keyof typeof StoreUserRole],
      ...(isEditForm ? {} : { email }),
    }
    onSubmit(submitData)
  }

  return (
    <div data-cy="user-form">
      <FormGroup isFullWidth={isFullWidth}>
        <Grid columns={2} spacing={2}>
          <Input
            id="firstName"
            label="First Name"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={Boolean(errors.firstName)}
            errorFeedback={errors.firstName}
            isDisabled={isDisabled}
          />
          <Input
            id="lastName"
            label="Last Name"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={Boolean(errors.lastName)}
            errorFeedback={errors.lastName}
            isDisabled={isDisabled}
          />
          <GridItem fillWidth={isFullWidth}>
            <Input
              id="email"
              label="Email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              isError={Boolean(errors.email)}
              errorFeedback={errors.email}
              isDisabled={isEditForm}
            />
          </GridItem>
          <GridItem fillWidth={isFullWidth}>
            <AdvancedSelect
              id="role"
              label="Role"
              value={values.role}
              onChange={handleChange}
              isNotClearable
              multiple={false}
              options={roleOptions}
              errorFeedback={errors.role}
              isError={Boolean(errors.role)}
              isDisabled={isDisabled}
              data-cy="user-form-role-select"
              helperText={<NewUserRoleDescription />}
            />
          </GridItem>
        </Grid>
        <ButtonGroupWrapper>
          <ButtonGroup>
            <Button
              isDisabled={isLoading}
              emphasis="medium"
              text="Cancel"
              onClick={handleCancelClick}
              data-cy="user-details-cancel-button"
            />
            <Button
              isProcessing={isLoading}
              isDisabled={!dirty || !isValid || isLoading}
              onClick={handleSubmit}
              text={submitButtonText}
              data-cy="user-form-submit-button"
            />
          </ButtonGroup>
        </ButtonGroupWrapper>
      </FormGroup>
    </div>
  )
}

const ButtonGroupWrapper = styled.div({
  marginTop: 32,
  display: 'flex',
  justifyContent: 'flex-end',
})

const FormGroup = styled.div<{ isFullWidth: boolean }>(({ isFullWidth }) => ({
  width: isFullWidth ? '100%' : '55%',
}))

export { UserForm }
