import type { AdvancedSelectChangeEvent } from '@helloextend/zen'
import { AdvancedSelect } from '@helloextend/zen'
import styled from '@emotion/styled'
import type { FC } from 'react'
import React from 'react'
import type { StoreIdAndName } from '@helloextend/extend-api-client'
import { getStoresSelectOptions } from './utils'

interface StoreCatalogSelectProps {
  selectedStore?: StoreIdAndName
  handleSelectedStore: (e: AdvancedSelectChangeEvent) => void
  stores: StoreIdAndName[]
  isLoading: boolean
}

const StoreSelector: FC<StoreCatalogSelectProps> = ({
  selectedStore,
  handleSelectedStore,
  stores,
  isLoading,
}) => {
  return (
    <StoreSelectContainer>
      <AdvancedSelect
        data-cy="store-catalog-select"
        isLoading={isLoading}
        showSearch
        badgePosition="start"
        multiple={false}
        id="store-catalog-select"
        label="Store Catalog"
        onChange={handleSelectedStore}
        pageSize={50}
        options={getStoresSelectOptions(stores)}
        placeholder="Select"
        value={selectedStore?.id || ''}
      />
    </StoreSelectContainer>
  )
}

const StoreSelectContainer = styled.div({
  width: 320,
})

export { StoreSelector }
