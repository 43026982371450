import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'

type PasswordToggleProps = {
  isChecked: boolean
  onClick: () => void
}

const PasswordToggle: FC<PasswordToggleProps> = ({ isChecked, onClick }) => (
  <Label>
    <Icon isChecked={isChecked} />
    <HiddenToggle type="checkbox" onClick={onClick} checked={isChecked} readOnly tabIndex={-1} />
  </Label>
)

const Icon: FC<{ isChecked: boolean }> = ({ isChecked = false }) => (
  <svg width="25" height="16" xmlns="http://www.w3.org/2000/svg">
    <g fill="#9DA6AC" fillRule="evenodd">
      <path d="M15.74 8.16a3.5 3.5 0 1 0-7 0 3.5 3.5 0 0 0 7 0zm-6 0a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z" />
      {/* conditionally appends cross mark on the eye to indicate for password toggle */}
      {isChecked && (
        <path d="M20.69 0a.5.5 0 0 0-.33.13L17.78 2.4l-.8.71-2.58 2.27-.75.67-3.63 3.2-.76.67-2.9 2.56-.8.7-2.2 1.95a.5.5 0 0 0 .33.87.5.5 0 0 0 .33-.12l2.44-2.16.82-.73 2.61-2.3.76-.67 3.69-3.25.76-.67 2.82-2.49.77-.68L21.02.88a.5.5 0 0 0-.33-.88z" />
      )}
      <path d="M23.14 8.24c-7.13-8.32-14.4-8.32-21.8 0 7.23 8.1 14.57 8.1 21.8 0zM.6 8.91L0 8.24l.6-.66c7.8-8.78 15.78-8.77 23.3.01l.57.67-.58.65c-3.82 4.27-7.74 6.43-11.65 6.43S4.41 13.18.59 8.91z" />
    </g>
  </svg>
)

const HiddenToggle = styled.input({
  height: 0,
  opacity: 0,
  overflow: 'hidden',
  width: 0,
})

const Label = styled.label({
  height: 16, // per design icon size constraint
  position: 'absolute',
  right: 17, // right-gutter from right-most edge of input
  top: '50%',
  transform: 'translateY(-50%)',
  width: 25, // per design icon size constraint
})

export { PasswordToggle }
