import type { RefObject } from 'react'
import { createContext, useContext } from 'react'

export interface ModalContextValue {
  getModalFooterRef: () => RefObject<HTMLDivElement>
  dismissModal?: () => void
}

export const ModalContext = createContext<ModalContextValue | null>(null)

export const useModalContext = (): ModalContextValue => {
  const context = useContext(ModalContext)

  if (!context) {
    throw new Error('useModalContext() can only be used within the subtree of a <Modal />')
  }

  return context
}
