export const uploadClaimPhoto = (contractId: string, claimId: string, name: string, file: File) =>
  ({
    type: 'ADMIN_CLAIM_PHOTO_UPLOAD',
    payload: { contractId, claimId, name, file },
  } as const)

export const uploadClaimPhotoRequest = (name: string) =>
  ({
    type: 'ADMIN_CLAIM_PHOTO_UPLOAD_REQUEST',
    payload: { name },
  } as const)

export const uploadClaimPhotoSuccess = (name: string) =>
  ({
    type: 'ADMIN_CLAIM_PHOTO_UPLOAD_SUCCESS',
    payload: { name },
  } as const)

export const uploadClaimPhotoFailure = (name: string, error?: string, statusCode?: number) =>
  ({
    type: 'ADMIN_CLAIM_PHOTO_UPLOAD_FAILURE',
    payload: { name, error, statusCode },
  } as const)
