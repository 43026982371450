import * as Yup from 'yup'

const schema = Yup.object()
  .shape({
    firstName: Yup.string().default('').required('Required'),
    lastName: Yup.string().default('').required('Required'),
    email: Yup.string()
      .email('Must be @extend.com address')
      .test({
        name: 'isExtendEmail',
        message: 'Must be @extend.com address',
        test: (value) => value?.endsWith('@extend.com'),
      })
      .default('')
      .required('Must be @extend.com address'),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

export { schema }
export type { Values }
