import { createBooleanReducer } from '@helloextend/core-api-redux/src/factories'
import type { Action } from '../actions'

const isLoading = createBooleanReducer<Action>(
  ['USERS_FETCH_ALL_REQUEST', 'ACCOUNT_USERS_FETCH_ALL_REQUEST', 'RESEND_INVITE_EMAIL_REQUEST'],
  [
    'USERS_FETCH_ALL_SUCCESS',
    'USERS_FETCH_ALL_FAILURE',
    'ACCOUNT_USERS_FETCH_ALL_SUCCESS',
    'ACCOUNT_USERS_FETCH_ALL_FAILURE',
    'RESEND_INVITE_EMAIL_SUCCESS',
    'RESEND_INVITE_EMAIL_FAILURE',
  ],
)

export default isLoading
