export interface User {
  id: string
  email: string
  createdAt: number
  firstName: string
  lastName: string
  activatedAt?: number
  deletedAt?: number
  loginStatus: 'active' | 'pending'
  accountId: string
  role: StoreUserRole
}

export type UpdateProfileRequest = Pick<User, 'firstName' | 'lastName'>

export enum StoreUserRole {
  superadmin = 'superadmin',
  admin = 'admin',
  agent = 'agent',
  merchantagent = 'merchantagent',
  user = 'user',
  salesassociate = 'salesassociate',
  conversationseditor = 'conversationseditor',
  conversationsviewer = 'conversationsviewer',
  conversationsadmin = 'conversationsadmin',
}

export interface StoreUser extends Omit<User, 'firstName' | 'lastName'> {
  firstName?: string
  lastName?: string
}
