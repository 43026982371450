import type { FC } from 'react'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Breadcrumbs, ToastColor, ToastDuration, useToaster } from '@helloextend/zen'
import { useHistory, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import { useCreateSkuMutation } from '@helloextend/extend-api-rtk-query'
import type { Sku } from '@helloextend/extend-api-client/src/models/sku'
import { SkuForm } from '../sku/sku-form'
import type { Values } from '../sku/schema'
import { schema } from '../sku/schema'
import { skuPropertiesMapper, skuFormInitialValues } from '../../../utils/sku-property-mapper'
import { CreateFormHeader } from '../../../components/create-form-header/create-form-header'

const SkusCreate: FC = () => {
  const history = useHistory()
  const { toast } = useToaster()
  const [create, { isLoading, isSuccess, isError, data: createdSku }] = useCreateSkuMutation()
  const location = useLocation<{ skuDetails?: Sku }>()
  const skuDetails = location.state?.skuDetails

  const {
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setErrors,
    resetForm,
  } = useFormik<Values>({
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      ...skuFormInitialValues((skuDetails as Sku) ?? {}),
      isActive: 'yes',
      // TODO: PUPS-556 update to use service to generate IDs
      id: 'vlad-test-id-2',
    },
    onSubmit: (): void => {},
  })

  useEffect(() => {
    if (isSuccess && createdSku) {
      toast({
        message: `SKU ${createdSku?.id} has been created.`,
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
      })
      history.push(`/admin/premiums/${createdSku.id}`)
    }
    if (isError) {
      toast({
        message: 'Something went wrong. Please try again.',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [isSuccess, isError, toast, createdSku, history])

  const handleSave = (): void => {
    const mappedValues = skuPropertiesMapper(values)
    create(mappedValues)
  }
  return (
    <>
      <BreadcrumbsWrapper>
        <Breadcrumbs
          crumbs={[{ text: 'Premiums', to: '/admin/premiums' }, { text: 'Create Premium' }]}
          data-cy="crumbs"
        />
      </BreadcrumbsWrapper>
      <CreateFormHeader
        title="Create Premium"
        titleCy="create-sku-title"
        createButtonText="Create Premium"
        createButtonCy="create-sku-button"
        isProcessing={isLoading}
        isFormDirty={dirty}
        isCreateSuccess={isSuccess}
        isFormValid={isValid}
        handleSave={handleSave}
        resetForm={resetForm}
      />
      <SkuForm
        values={values}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        setFieldValue={setFieldValue}
        setErrors={setErrors}
        handleSubmit={handleSubmit}
      />
    </>
  )
}

const BreadcrumbsWrapper = styled.div({
  margin: '-8px 0 16px',
})

export { SkusCreate }
