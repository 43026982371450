import type { FC } from 'react'
import React, { useMemo, useState, useEffect } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import type { ButtonSize } from '@helloextend/zen'
import { Button, useToaster, ToastDuration, ToastColor } from '@helloextend/zen'
import { ArrowDropDown } from '@helloextend/zen/src/tokens/icons'
import { useClickOutside, useToggle } from '@helloextend/client-hooks'
import { ListItemButton, Menu } from '@helloextend/merchants-ui'
import type { Claim } from '@helloextend/extend-api-client'
import { ClaimSelectStatus } from '@helloextend/extend-api-client'
import { setClaimDetailsActiveView } from '../../../../../../store/slices/claim-details'
import { LDFlag } from '../../../../../../constants/ld-flags'
import { useUpdateClaim } from '../../../../../../hooks/use-update-claim'

interface ClaimActionsMenuProps {
  claim?: Claim
  hasServiceOrder?: boolean
  cyPrefix?: string
  buttonText?: string
  buttonSize?: ButtonSize
}

interface ActionConfig {
  label: string
  onClick: () => void
}

const ClaimActionsMenu: FC<ClaimActionsMenuProps> = ({
  claim,
  hasServiceOrder,
  cyPrefix,
  buttonText,
  buttonSize,
}) => {
  const dispatch = useDispatch()
  const { toast } = useToaster()
  const [toastMessage, setToastMessage] = useState('')
  const { [LDFlag.ClaimsClose]: FF_CLAIMS_CLOSE } = useFlags()
  const [isMenuOpen, { toggle, off }] = useToggle()
  const { updateClaim, isSuccess, isLoading: isUpdating } = useUpdateClaim()

  const actions: ActionConfig[] | null = useMemo(() => {
    switch (claim?.status) {
      case 'approved':
        if (hasServiceOrder) return null
        return [
          ...(FF_CLAIMS_CLOSE
            ? [
                {
                  label: 'Close claim',
                  onClick: () => {
                    dispatch(setClaimDetailsActiveView('closeClaimModal'))
                  },
                },
              ]
            : []),
          {
            label: 'Deny claim',
            onClick: () => {
              updateClaim({
                claimId: claim.id,
                contractId: claim.contractId,
                body: {
                  status: ClaimSelectStatus.denied,
                  customer: claim.customer,
                  incident: claim.incident,
                },
              })
              setToastMessage('Claim denied!')
            },
          },
        ]
      default:
        return null
    }
  }, [claim, hasServiceOrder, updateClaim, dispatch, FF_CLAIMS_CLOSE])

  const { ref } = useClickOutside<HTMLDivElement>(() => {
    off()
  })

  const handleClick = (handler: () => void): void => {
    handler()
    off()
  }

  useEffect(() => {
    if (isSuccess && toastMessage) {
      toast({
        message: toastMessage,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.green,
      })
    }
  }, [isSuccess, dispatch, toastMessage, toast])

  if (!actions) return null

  return (
    <ActionsWrapper
      data-cy={cyPrefix ? `${cyPrefix}-claim-actions-wrapper` : 'claim-actions-wrapper'}
    >
      <div ref={ref}>
        <Button
          emphasis="medium"
          isToggled={isMenuOpen}
          data-cy="claim-actions-button"
          text={buttonText || 'Actions'}
          size={buttonSize}
          onClick={toggle}
          icon={ArrowDropDown}
          iconPosition="right"
          isDisabled={isUpdating}
        />
        <MenuWrapper>
          <Menu isOpen={isMenuOpen} position="right" width={164}>
            {actions.map((action) => (
              <ItemWrapper key={action.label}>
                <MenuButton
                  data-cy={`${action.label}-btn`}
                  onClick={() => handleClick(action.onClick)}
                >
                  {action.label}
                </MenuButton>
              </ItemWrapper>
            ))}
          </Menu>
        </MenuWrapper>
      </div>
    </ActionsWrapper>
  )
}

const ActionsWrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  position: 'relative',
}))

const MenuWrapper = styled.div({
  width: '100%',
  position: 'absolute',
  top: 38,
})
const ItemWrapper = styled.div({
  position: 'relative',
  '&:first-child > .menu-button': {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  '&:last-child > .menu-button': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
})

const MenuButton = styled(ListItemButton)({
  backgroundColor: 'transparent',
  borderRadius: 0,
  display: 'flex',
  justifyContent: 'space-between',
})

export { ClaimActionsMenu }
