import type { PricingRow } from '../types/plans'

const hasOverlappingPriceBands = (
  priceBands: PricingRow[],
  currentRow?: PricingRow | null,
): boolean => {
  for (let i = 0; i < priceBands.length; i += 1) {
    const item = priceBands[i]
    if (item.priceBandLow > item.priceBandHigh) return true
    if (i !== 0 && item.priceBandLow <= priceBands[i - 1].priceBandHigh) {
      // Only return errors for current row
      if (currentRow && currentRow === item) {
        return true
      }
    }
  }

  return false
}

const hasGapsInPriceband = (priceBands: PricingRow[], currentRow?: PricingRow | null): boolean => {
  for (let i = 0; i < priceBands.length; i += 1) {
    const item = priceBands[i]
    if (i !== 0 && item.priceBandLow !== priceBands[i - 1].priceBandHigh + 1) {
      // Only return errors for current row
      if (currentRow && currentRow === item) {
        return true
      }
    }
  }
  return false
}

export { hasOverlappingPriceBands, hasGapsInPriceband }
