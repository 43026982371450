import type { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import type { ActionType } from '../actions'
import create from './create'
import fetch from './fetch'
import fetchV2 from './fetchV2'
import fetchAll from './fetch-all'
import fetchByStore from './fetch-by-store'
import refund from './refund'
import resend from './resend'
import search from './search'
import update from './update'
import updateV2 from './updateV2'
import searchByStore from './search-by-store'
import fetchAuditHistory from './fetch-audit-history'

export default function* sagas(): SagaIterator {
  yield all([
    takeLatest('CONTRACTS_FETCH' as ActionType, fetch),
    takeLatest('CONTRACTS_FETCH_V2' as ActionType, fetchV2),
    takeLatest('CONTRACTS_FETCH_ALL' as ActionType, fetchAll),
    takeLatest('CONTRACTS_UPDATE' as ActionType, update),
    takeLatest('CONTRACTS_UPDATE_V2' as ActionType, updateV2),
    takeLatest('CONTRACTS_SEARCH' as ActionType, search),
    takeLatest('CONTRACTS_REFUND' as ActionType, refund),
    takeLatest('CONTRACTS_RESEND' as ActionType, resend),
    takeLatest('CONTRACTS_CREATE' as ActionType, create),
    takeLatest('CONTRACTS_SEARCH_BY_STORE', searchByStore),
    takeLatest('CONTRACTS_FETCH_BY_STORE' as ActionType, fetchByStore),
    takeLatest(
      [
        'CONTRACT_FETCH_AUDIT_HISTORY' as ActionType,
        'CONTRACT_AUDIT_HISTORY_NEXT_PAGE' as ActionType,
      ],
      fetchAuditHistory,
    ),
  ])
}
