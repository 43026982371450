import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import type { VirtualCardFulfillmentMetaData } from '@helloextend/extend-api-client/src/models/service-order'
import { useFlags } from 'launchdarkly-react-client-sdk'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import type { ButtonSize } from '@helloextend/zen'
import { Button } from '@helloextend/zen'
import { ArrowDropDown } from '@helloextend/zen/src/tokens/icons'
import { useClickOutside, useToggle } from '@helloextend/client-hooks'
import { ListItemButton, Menu } from '@helloextend/merchants-ui'
import type { Claim, ServiceOrder } from '@helloextend/extend-api-client'
import {
  claimsApi,
  serviceOrdersApi,
  useGetWalletQuery,
  useResendPopaEmailMutation,
} from '@helloextend/extend-api-rtk-query'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import { LDFlag } from '../../../../../../constants/ld-flags'
import { useServiceOrderExpenses } from '../tab-section/hooks/use-service-orders-expenses'
import type { RootState } from '../../../../../../reducers'
import * as selectors from '../../../../../../reducers/selectors'
import { useUpdateClaim } from '../../../../../../hooks/use-update-claim'

interface ServiceOrderActionsMenuProps {
  serviceOrder?: ServiceOrder
  claim?: Claim
  cyPrefix?: string
  buttonText?: string
  buttonSize?: ButtonSize
}

interface ActionConfig {
  label: string
  action: string
}

const serviceOrderClosingStatusCriteria = [
  'created',
  'assigned',
  'accepted',
  'repair_shipped',
  'checked_in',
]

const closeDisabledStatuses = ['paid', 'closed', 'payment_approved']

const getReplacementServiceOrderActions = (
  status?: ServiceOrder['status'],
  isFlexibleServicingEnabled?: boolean,
  isVirtualCard?: boolean,
  isVirtualCardCancelEnabled?: boolean,
): ActionConfig[] | null => {
  if (!status || closeDisabledStatuses.includes(status)) {
    const config: ActionConfig[] = []
    if (status !== 'closed' && isVirtualCard) {
      config.push({
        label: 'Resend Fulfillment email',
        action: 'resendVirtualCardEmail',
      })

      if (isVirtualCardCancelEnabled) {
        config.push({
          label: 'Cancel Virtual Card',
          action: 'cancelVirtualCardModal',
        })
      }
    }

    if (isFlexibleServicingEnabled) {
      config.push({
        label: 'Create service order',
        action: 'createServiceOrderModal',
      })
    }
    return config.length > 0 ? config : null
  }

  if (isFlexibleServicingEnabled) {
    return [
      ...(status === 'accepted'
        ? [
            {
              label: 'Approve SO for replacement',
              action: 'approveReplacementModal',
            },
          ]
        : []),
      {
        label: 'Create service order',
        action: 'createServiceOrderModal',
      },
      {
        label: 'Close service order',
        action: 'closeServiceOrderModal',
      },
    ]
  }

  switch (status) {
    case 'accepted':
      return [
        {
          label: 'Approve SO for replacement',
          action: 'approveReplacementModal',
        },
        {
          label: 'Close service order',
          action: 'closeServiceOrderModal',
        },
      ]
    default:
      return null
  }
}

const getClaimActions = (claim?: Claim): ActionConfig[] => {
  if (claim?.status !== 'fulfilled') return []
  return [
    {
      label: 'Reopen claim',
      action: 'reopenClaim',
    },
  ]
}

const getRepairServiceOrderActions = (
  serviceOrder: ServiceOrder,
  isClaimCloseEnabled?: boolean,
  hasExpenses?: boolean,
  isFlexibleServicingEnabled?: boolean,
): ActionConfig[] | null => {
  if (!isClaimCloseEnabled || (!isFlexibleServicingEnabled && hasExpenses)) return null
  if (
    (isFlexibleServicingEnabled && !closeDisabledStatuses.includes(serviceOrder?.status || '')) ||
    serviceOrderClosingStatusCriteria.includes(serviceOrder?.status || '')
  ) {
    const actions = [
      {
        label: 'Close service order',
        action: 'closeServiceOrderModal',
      },
      ...(isFlexibleServicingEnabled
        ? [
            {
              label: 'Create service order',
              action: 'createServiceOrderModal',
            },
          ]
        : []),
    ]

    if (serviceOrder?.serviceType === 'repair_depot' && serviceOrder?.status === 'accepted') {
      actions.push({
        label: 'Mark as shipped',
        action: 'markAsShippedModal',
      })
    }

    return actions
  }
  return isFlexibleServicingEnabled && serviceOrder.closedMetaData?.resolution !== 'no_service'
    ? [
        {
          label: 'Create service order',
          action: 'createServiceOrderModal',
        },
      ]
    : null
}

const ServiceOrderActionsMenu: FC<ServiceOrderActionsMenuProps> = ({
  claim,
  serviceOrder,
  cyPrefix,
  buttonText,
  buttonSize,
}) => {
  const {
    [LDFlag.ClaimsClose]: FF_CLAIMS_CLOSE,
    [LDFlag.ServiceOrdersFlexibleServicing]: FF_FLEXIBLE_SERVICING,
    [LDFlag.CancelVirtualCard]: FF_CANCEL_VIRTUAL_CARD,
    [LDFlag.ReopenClaim]: FF_REOPEN_CLAIM,
  } = useFlags()
  const dispatch = useDispatch()
  const [isMenuOpen, { toggle, off }] = useToggle()
  const { expenses } = useServiceOrderExpenses(serviceOrder?.id)
  const hasExpenses = expenses && expenses.length > 0
  const activeView = useSelector((state: RootState) => selectors.getActiveClaimDetailsView(state))
  const [resendVirtualCardEmail] = useResendPopaEmailMutation()
  const { updateClaim } = useUpdateClaim()

  const { data: wallet } = useGetWalletQuery(
    serviceOrder
      ? (serviceOrder.fulfillmentMetaData as VirtualCardFulfillmentMetaData)?.walletId ?? ''
      : '',
    {
      skip:
        !FF_CANCEL_VIRTUAL_CARD ||
        !serviceOrder ||
        !serviceOrder.fulfillmentMetaData ||
        !(serviceOrder.fulfillmentMetaData as VirtualCardFulfillmentMetaData).walletId,
    },
  )

  useEffect(() => {
    if (activeView === 'resendVirtualCardEmail' && serviceOrder) {
      dispatch(setClaimDetailsActiveView(''))
      resendVirtualCardEmail({
        serviceOrderId: serviceOrder.id,
        body: {
          iterableEventName: 'virtual-card-service-order-fulfilled',
          customerEmail: serviceOrder.customerEmail,
        },
      })
      dispatch(setClaimDetailsToast('Virtual Card email resent!'))
      dispatch(
        serviceOrdersApi.util.invalidateTags([{ type: 'ServiceOrder', id: serviceOrder?.claimId }]),
      )
    }

    if (activeView === 'reopenClaim' && claim) {
      dispatch(setClaimDetailsActiveView(''))
      updateClaim({
        claimId: claim.id,
        contractId: claim.contractId,
        body: { status: 'approved' },
      })
      dispatch(setClaimDetailsToast('Claim reopened!'))
      dispatch(
        serviceOrdersApi.util.invalidateTags([{ type: 'ServiceOrder', id: serviceOrder?.claimId }]),
      )
    }
  }, [activeView, dispatch, resendVirtualCardEmail, serviceOrder, claim, updateClaim])

  const actions: ActionConfig[] | null = useMemo(() => {
    const claimActions = FF_REOPEN_CLAIM ? getClaimActions(claim) : []
    let serviceOrderActions: ActionConfig[] | null
    switch (serviceOrder?.serviceType) {
      case 'replace':
        serviceOrderActions = getReplacementServiceOrderActions(
          serviceOrder?.status,
          FF_FLEXIBLE_SERVICING,
          serviceOrder?.fulfillmentMetaData?.method === 'virtual_card',
          FF_CANCEL_VIRTUAL_CARD && wallet?.status === 'active',
        )
        break
      case 'repair_depot':
      case 'repair_onsite':
        serviceOrderActions = getRepairServiceOrderActions(
          serviceOrder,
          FF_CLAIMS_CLOSE,
          hasExpenses,
          FF_FLEXIBLE_SERVICING,
        )
        break
      default:
        serviceOrderActions =
          claim?.status === 'approved'
            ? [
                {
                  label: 'Create service order',
                  action: 'createServiceOrderModal',
                },
              ]
            : null
    }

    if (!serviceOrderActions) {
      return claimActions.length > 0 ? claimActions : null
    }

    return [...claimActions, ...serviceOrderActions]
  }, [
    serviceOrder,
    claim,
    FF_CLAIMS_CLOSE,
    hasExpenses,
    FF_FLEXIBLE_SERVICING,
    FF_REOPEN_CLAIM,
    FF_CANCEL_VIRTUAL_CARD,
    wallet,
  ])

  const { ref } = useClickOutside<HTMLDivElement>(() => {
    off()
  })

  const handleClick = (action: string): void => {
    dispatch(setClaimDetailsActiveView(action))
    setTimeout(() => {
      dispatch(claimsApi.util.invalidateTags([{ type: 'claims', id: serviceOrder?.claimId }]))
    }, 8000)
    off()
  }

  if (!actions) return null

  return (
    <ActionsWrapper data-cy={cyPrefix ? `${cyPrefix}-so-actions-wrapper` : 'so-actions-wrapper'}>
      <div ref={ref}>
        <Button
          emphasis="medium"
          isToggled={isMenuOpen}
          data-cy="so-actions-button"
          text={buttonText || 'More Actions'}
          size={buttonSize}
          onClick={toggle}
          icon={ArrowDropDown}
          iconPosition="right"
        />
        <MenuWrapper>
          <Menu isOpen={isMenuOpen} position="right" width={320}>
            {actions.map((action) => (
              <ItemWrapper key={action.label}>
                <MenuButton
                  data-cy={`${action.action}-btn`}
                  onClick={() => handleClick(action.action)}
                >
                  {action.label}
                </MenuButton>
              </ItemWrapper>
            ))}
          </Menu>
        </MenuWrapper>
      </div>
    </ActionsWrapper>
  )
}

const ActionsWrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  position: 'relative',
}))

const MenuWrapper = styled.div({
  width: '100%',
  position: 'absolute',
  top: 38,
})
const ItemWrapper = styled.div({
  position: 'relative',
  '&:first-child > .menu-button': {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  '&:last-child > .menu-button': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
})

const MenuButton = styled(ListItemButton)({
  backgroundColor: 'transparent',
  borderRadius: 0,
  display: 'flex',
  justifyContent: 'space-between',
})

export { ServiceOrderActionsMenu }
