import type { FC } from 'react'
import React from 'react'
import { Link, useParams } from 'react-router-dom'

type ReferenceIdLinkProps = {
  referenceId: string
  productName: string
  isVariantsTable?: boolean
}

const ReferenceIdLink: FC<ReferenceIdLinkProps> = ({
  referenceId,
  productName,
  isVariantsTable = false,
}) => {
  const { storeId, referenceId: parentReferenceId } = useParams<{
    storeId: string
    referenceId?: string
  }>()

  const pathUrl = isVariantsTable ? `${parentReferenceId}/variants/${referenceId}` : referenceId
  return <Link to={`/admin/stores/${storeId}/products/${pathUrl}`}>{productName}</Link>
}

export { ReferenceIdLink }
