import type { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/client-utils/src/request'
import type {
  ServiceOrderStartRepairRequest,
  ServiceOrder,
} from '@helloextend/extend-api-client/src/models/service-order'
import { getServiceOrderError } from './get-service-order-error'
import { actions } from '../actions'
import { fetchServiceOrders } from './fetch-by-claim-id'

type Action = ReturnType<typeof actions.startRepair>

function* startRepair(payload: {
  serviceOrderId: ServiceOrder['id']
  body: ServiceOrderStartRepairRequest
  accessToken: string
}): SagaIterator {
  const { serviceOrderId, body, accessToken } = payload
  yield put(actions.startRepairRequest())
  try {
    const response = yield call(client.serviceOrders.startRepair, serviceOrderId, body, accessToken)

    if (isErrorResponse(response)) {
      yield put(actions.startRepairFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(actions.startRepairFailure(`An unknown error occurred`, response.status))
      return
    }

    yield put(actions.startRepairSuccess())
  } catch (e) {
    yield put(actions.startRepairFailure(e.message))
  }
}

export default function* startRepairAndRefetchServiceOrders(action: Action): SagaIterator {
  // These are not run in parallel because fetchServiceOrders should only be triggered
  // when startRepair is successful
  yield call(startRepair, action.payload)
  const error = yield select(getServiceOrderError)
  if (!error) {
    yield call(fetchServiceOrders, action.payload)
  }
}
