import type { FC } from 'react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import type { Claim, Money, ServiceOrder } from '@helloextend/extend-api-client'
import { usePrevious } from '@helloextend/client-hooks'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { TabMenu } from '../../../../../../components/tab-menu/tab-menu'
import { AdjudicationTab } from './tabs/adjudication'
import { ServiceOrderTab } from './tabs/service-order'
import { ExpensesTab } from './tabs/expenses'
import { ClaimPageTab } from './types'
import { AttachmentsTab } from './tabs/attachments'
import { IncidentReportTab } from './tabs/incident-report'
import * as selectors from '../../../../../../reducers/selectors'
import type { RootState } from '../../../../../../reducers'
import { setClaimDetailsActiveView } from '../../../../../../store/slices/claim-details'
import { LDFlag } from '../../../../../../constants/ld-flags'

interface TabSectionProps {
  claim: Claim
  serviceOrders?: ServiceOrder[] | undefined
  entitlements?: { coverageAmountRemaining: Money }
}

const serviceOrderTabs = [
  { text: 'Service Orders', key: ClaimPageTab.ServiceOrder },
  { text: 'Expenses', key: ClaimPageTab.Expenses },
]
const initialClaimsTabs = [
  { text: 'Incident Report', key: ClaimPageTab.IncidentReport },
  { text: 'Attachments', key: ClaimPageTab.Attachments },
  { text: 'Adjudication', key: ClaimPageTab.Adjudication },
]

const TabSection: FC<TabSectionProps> = ({ claim, serviceOrders, entitlements }) => {
  const {
    [LDFlag.ExpandedServiceOrdersSection]: FF_EXPANDED_SERVICE_ORDER_SECTION,
    [LDFlag.ClaimsDetailsAdjudication]: FF_CLAIM_ADJUDICATION,
  } = useFlags()

  const claimTabs = useMemo(() => {
    if (FF_CLAIM_ADJUDICATION && claim.conversationId) {
      return initialClaimsTabs
    }
    return initialClaimsTabs.filter((tab) => tab.key !== ClaimPageTab.Adjudication)
  }, [FF_CLAIM_ADJUDICATION, claim.conversationId])

  const dispatch = useDispatch()
  const scrollRef = useRef<HTMLDivElement>(null)
  const activeView = useSelector((state: RootState) => selectors.getActiveClaimDetailsView(state))
  const filteredServiceOrderTabs = serviceOrderTabs.filter((tab) => {
    if (tab.key === ClaimPageTab.Expenses) {
      return !FF_EXPANDED_SERVICE_ORDER_SECTION
    }
    return true
  })
  const tabs = useMemo(
    () => [...((serviceOrders?.length && filteredServiceOrderTabs) || []), ...claimTabs],
    [serviceOrders?.length, filteredServiceOrderTabs, claimTabs],
  )
  const [activeTab, setActiveTab] = useState<ClaimPageTab>(
    serviceOrders?.length ? ClaimPageTab.ServiceOrder : ClaimPageTab.IncidentReport,
  )
  const prevServiceOrder = usePrevious(serviceOrders?.length)
  const prevActiveView = usePrevious(activeView)

  useEffect(() => {
    if (serviceOrders?.length && !prevServiceOrder) {
      setActiveTab(ClaimPageTab.ServiceOrder)
    }

    if (activeView !== prevActiveView && tabs.some(({ key }) => key === activeView)) {
      setActiveTab(activeView as ClaimPageTab)
      const element = scrollRef?.current
      if (element) {
        setTimeout(() =>
          element.scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'nearest' }),
        )
        dispatch(setClaimDetailsActiveView(''))
      }
    }
    if (activeView === ClaimPageTab.Expenses && FF_EXPANDED_SERVICE_ORDER_SECTION) {
      setActiveTab(ClaimPageTab.ServiceOrder)
    }
  }, [
    activeView,
    dispatch,
    serviceOrders?.length,
    prevServiceOrder,
    prevActiveView,
    scrollRef,
    tabs,
    FF_EXPANDED_SERVICE_ORDER_SECTION,
  ])

  return (
    <div data-cy="tab-section">
      <TabMenu
        tabs={tabs}
        onClick={(tab) => dispatch(setClaimDetailsActiveView(tab))}
        activeTab={activeTab}
      />
      <TabContent>
        {activeTab === ClaimPageTab.ServiceOrder && (
          <ServiceOrderTab serviceOrders={serviceOrders} entitlements={entitlements} />
        )}
        {activeTab === ClaimPageTab.Expenses && (
          // using first service order for now until we refactor expenses tab to use multiple service orders in a separate story
          <ExpensesTab serviceOrder={serviceOrders?.[0]} />
        )}
        {activeTab === ClaimPageTab.Attachments && (
          // using first service order for now until we refactor attachments tab to use multiple service orders in a separate story
          <AttachmentsTab serviceOrder={serviceOrders?.[0]} claim={claim} />
        )}
        {activeTab === ClaimPageTab.IncidentReport && <IncidentReportTab claim={claim} />}
        {activeTab === ClaimPageTab.Adjudication && <AdjudicationTab claim={claim} />}
      </TabContent>
      <div ref={scrollRef} />
    </div>
  )
}

const TabContent = styled.div({
  marginTop: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
})

export { TabSection }
