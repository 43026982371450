import type { Plan } from '@helloextend/extend-api-client'
import type { Action } from '../actions'

export type ByIdReducerState = Record<string, Plan>

export const initialState: ByIdReducerState = {}

export default function byId(state = initialState, action: Action): ByIdReducerState {
  switch (action.type) {
    case 'PLANS_FETCH_REQUEST':
    case 'PLANS_FETCH_ALL_REQUEST':
      return {}
    case 'PLANS_FETCH_ALL_SUCCESS':
      return action.payload.reduce<ByIdReducerState>(addPlan, {})
    case 'PLANS_FETCH_SUCCESS':
    case 'PLANS_CREATE_SUCCESS':
      return addPlan(state, action.payload)
    case 'PLANS_RESET':
      return initialState
    default:
      return state
  }
}

function addPlan(state: ByIdReducerState, plan: Plan): ByIdReducerState {
  return { ...state, [plan.id]: plan }
}
