import type { FC } from 'react'
import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import { Badge } from '@helloextend/zen'
import { date } from '@helloextend/client-utils'

dayjs.extend(isToday)

const FollowUpBadge: FC<{ timestamp: number; isCompleted?: boolean }> = ({
  timestamp,
  isCompleted = false,
}) => {
  const today = useMemo(() => {
    const d = new Date()
    d.setHours(9, 0, 0, 0)
    return d
  }, [])

  const isOverdue = !isCompleted && timestamp < today.getTime()

  const details = useMemo(() => {
    if (isOverdue && dayjs(timestamp).add(1, 'day').isToday()) {
      return 'Yesterday'
    }
    if (dayjs(timestamp).add(-1, 'day').isToday()) {
      return 'Tomorrow'
    }
    return dayjs(timestamp).isToday()
      ? 'Today'
      : `in ${date.getRelativeTimeSinceTimestamp(timestamp)}`
  }, [isOverdue, timestamp])

  const color = isOverdue ? 'yellow' : 'blue'

  return (
    <Badge
      data-cy="claim-note-follow-up"
      color={isCompleted ? 'neutral' : color}
      text={isOverdue ? 'OVERDUE' : 'Follow-up'}
      details={isCompleted ? 'Completed' : details}
    />
  )
}

export { FollowUpBadge }
