import type { VFC } from 'react'
import React, { useEffect, useState } from 'react'
import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'

export interface LoadingTextProps {
  secondary?: string
  secondaryDelayMs?: number
  /**
   * Optionally change the text displayed before the animated ellipsis.
   */
  text?: string
  'data-cy'?: string
}

export const LoadingText: VFC<LoadingTextProps> = ({
  text,
  'data-cy': dataCy,
  secondary,
  secondaryDelayMs = 5000,
}) => {
  const [displayText, setDisplayText] = useState(text || 'Loading')

  useEffect(() => {
    if (secondary) {
      const timer = setTimeout(() => {
        setDisplayText(secondary)
      }, secondaryDelayMs)
      return () => clearTimeout(timer)
    }
    return () => {}
  })

  return <StyledLoadingText data-cy={dataCy}>{displayText}</StyledLoadingText>
}

const appendDots = keyframes({
  '0%': { content: '"\u2008\u2008\u2008"' },
  '25%': { content: '".\u2008\u2008"' },
  '50%': { content: '"..\u2008"' },
  '75%': { content: '"..."' },
})

const StyledLoadingText = styled.span({
  whiteSpace: 'nowrap',
  '&::after': {
    content: '"\u2008\u2008\u2008"',
    animation: `${appendDots} 1500ms linear infinite`,
  },
})
