import type { ComponentClass, FC } from 'react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RouteComponentProps } from 'react-router'
import { Redirect, Route, useLocation } from 'react-router'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { usePermissions } from '../hooks/use-permissions'
import type { Permission } from '../lib/permissions'
import type { RootState } from '../reducers'
import { router as routerActions } from '../actions'
import * as selectors from '../reducers/selectors'
import { LDFlag } from '../constants/ld-flags'

interface ProtectedRouteProps {
  path: string
  exact?: boolean
  requiredPermissions?: Permission[]
  errorRedirectPath?: string
  component: React.FC<RouteComponentProps> | ComponentClass
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  path,
  exact,
  requiredPermissions = [],
  errorRedirectPath = '/404',
  component: PropsComponent,
}) => {
  const flags = useFlags()
  const dispatch = useDispatch()
  const { hasPermission } = usePermissions()
  const { pathname } = useLocation()

  const isUserLoggedIn = useSelector((state: RootState) =>
    selectors.getIsLoggedIn(state, flags[LDFlag.OktaLogin]),
  )

  const isAllowed =
    isUserLoggedIn && requiredPermissions.every((permission) => hasPermission(permission))

  useEffect(() => {
    if (!isUserLoggedIn) {
      // redirect user to intended path after login
      dispatch(routerActions.setLoginRedirectPath(pathname))
    }
  }, [isUserLoggedIn, dispatch, pathname])

  return (
    <Route
      path={path}
      exact={exact}
      render={(props: RouteComponentProps) =>
        isAllowed ? (
          <PropsComponent {...props} />
        ) : (
          <Redirect
            to={{
              pathname: !isUserLoggedIn ? '/' : errorRedirectPath,
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  )
}

export { ProtectedRoute }
