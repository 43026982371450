import { Modal } from '@helloextend/zen'
import { Paragraph } from '@helloextend/zen/src/tokens/typography'
import type { FC } from 'react'
import React from 'react'
import type { ProductShippingProtectionModalType } from '../../../types'
import { getProductSPModalDescription } from '../../../utils'

type ProductShippingProtectionModalProps = {
  modalType: ProductShippingProtectionModalType
  isEnabled: boolean
  isLoading: boolean
  handleConfirmClick: (modalType: ProductShippingProtectionModalType, isEnabled: boolean) => void
  handleCancelClick: () => void
}

const ProductShippingProtectionModal: FC<ProductShippingProtectionModalProps> = ({
  modalType,
  isEnabled,
  isLoading,
  handleConfirmClick,
  handleCancelClick,
}) => {
  const heading = `${isEnabled ? 'Disable' : 'Enable'} ${
    modalType === 'shippable' ? '"Shippable"' : '"Shipping Protection"'
  }`
  const confirmButtonText = `${isEnabled ? 'Disable' : 'Enable'}`
  return (
    <Modal
      heading={heading}
      onDismissRequest={handleCancelClick}
      data-cy="product-shipping-protection-modal"
      primaryButtonProps={{
        text: confirmButtonText,
        onClick: () => handleConfirmClick(modalType, isEnabled),
        isProcessing: isLoading,
      }}
      secondaryButtonProps={{
        text: 'Cancel',
        onClick: handleCancelClick,
        isDisabled: isLoading,
      }}
    >
      <Paragraph>{getProductSPModalDescription(modalType, isEnabled)}</Paragraph>
    </Modal>
  )
}

export { ProductShippingProtectionModal, ProductShippingProtectionModalProps }
