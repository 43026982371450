import type { FC } from 'react'
import React, { memo } from 'react'
import styled from '@emotion/styled'
import { Checkbox as CheckboxComponent } from '@helloextend/zen'
import type { ChangeFunction, SetFieldFunction, StringInputItem } from './types'

type CheckboxItemProps = {
  handleChange: ChangeFunction & SetFieldFunction
  isDisabled: boolean
  item: StringInputItem
}

const Checkbox: FC<CheckboxItemProps> = ({ handleChange, isDisabled, item }) => {
  const { value } = item
  const isTrue = value === 'true'

  return (
    <CheckboxWrapper>
      <CheckboxLabel>{item.label}</CheckboxLabel>
      <CheckboxComponent
        disabled={isDisabled || !!item?.isItemDisabled}
        onChange={handleChange}
        label=""
        value={value}
        checked={isTrue}
      />
    </CheckboxWrapper>
  )
}

const CheckboxWrapper = styled.div({
  width: '100%',
  marginRight: 24,
  display: 'flex',
  flexDirection: 'column',
})

const CheckboxLabel = styled.div({
  fontSize: '14px',
  lineHeight: '22px',
  fontWeight: 800,
})

const CheckboxItem = memo(Checkbox, (prevProps, nextProps) => {
  return prevProps.item.value === nextProps.item.value
})

export { CheckboxItem, CheckboxItemProps }
