import type { FC, MouseEvent } from 'react'
import React, { useCallback } from 'react'
import { useFetchPlanVersionsListQuery } from '@helloextend/extend-api-rtk-query'
import { DataReactTable } from '@helloextend/merchants-ui'
import { useHistory } from 'react-router'
import type { Plan } from '@helloextend/extend-api-client'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { planVersionsColumns } from './table-config'
import { LDFlag } from '../../../constants/ld-flags'

type ProductsImportModalProps = {
  planId: string
}

const PlanVersions: FC<ProductsImportModalProps> = ({ planId }) => {
  const { push, replace } = useHistory()
  const { [LDFlag.PlanManagerPlansVersionBrowser]: FF_PLAN_MANAGER_PLANS_VERSION_BROWSER } =
    useFlags()

  const { planVersions, isFetching: isFetchingVersions } = useFetchPlanVersionsListQuery(planId, {
    skip: !planId || !FF_PLAN_MANAGER_PLANS_VERSION_BROWSER,
    selectFromResult: ({ data, isFetching }) => ({
      isFetching,
      // filter out the current/latest plan version
      planVersions: [...(data?.versions || [])].sort((a, b) => a.version - b.version).slice(0, -1),
    }),
  })
  const handleRowClick = useCallback(
    (_e: MouseEvent, rowData: Plan) => {
      push(`/admin/plans/${planId}/versions/${rowData.version}`)
    },
    [push, planId],
  )
  if (!FF_PLAN_MANAGER_PLANS_VERSION_BROWSER) {
    replace(`/admins/plans/${planId}`)
    return null
  }
  if (!planVersions) return null
  return (
    <DataReactTable
      isLoading={isFetchingVersions}
      type="versions"
      data={planVersions}
      columns={planVersionsColumns}
      initialState={{
        sortBy: [{ id: 'version', desc: true }],
        pageSize: 10,
      }}
      autoResetPage={false}
      onRowClick={handleRowClick}
    />
  )
}

export { PlanVersions }
