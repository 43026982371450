import type { PlanDetails } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetchDetails = (id: string) =>
  ({ type: 'PLAN_DETAILS_FETCH', payload: { id } } as const)

export const fetchDetailsRequest = () => ({ type: 'PLAN_DETAILS_FETCH_REQUEST' } as const)

export const fetchDetailsSuccess = (plan: PlanDetails) =>
  ({
    type: 'PLAN_DETAILS_FETCH_SUCCESS',
    payload: plan,
  } as const)

export const fetchDetailsFailure = createActionFailure('PLAN_DETAILS_FETCH_FAILURE')
