import {
  useCreateStoresContractMutation,
  useLazyFetchPlanQuery,
  useLazyGetProductQuery,
  useLazyGetStoreQuery,
} from '@helloextend/extend-api-rtk-query'
import { ToastColor, ToastDuration, useToaster } from '@helloextend/zen'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import type { FormikErrors } from 'formik/dist/types'
import { mapContractV1ToCreateRequest } from '../../../utils/contract-property-mapper'
import type { V1Values } from './schema'

type UseContractsCreateState = {
  isCreating: boolean
  createContract: () => Promise<void>
}

type UseContractsCreateProps = {
  values: V1Values
  validateForm: (values?: V1Values) => Promise<FormikErrors<V1Values>>
  setFieldError: (field: string, value: string | undefined) => void
}

export function useContractsCreate(props: UseContractsCreateProps): UseContractsCreateState {
  const { values, validateForm, setFieldError } = props

  const history = useHistory()
  const { toast } = useToaster()

  const [fetchStore, { isLoading: isStoreLoading }] = useLazyGetStoreQuery()
  const [fetchPlan, { isLoading: isPlanLoading }] = useLazyFetchPlanQuery()
  const [fetchProduct, { isLoading: isProductLoading }] = useLazyGetProductQuery()
  const [createContract, { isLoading: isContractLoading }] = useCreateStoresContractMutation()

  const isSaveLoading = isStoreLoading || isPlanLoading || isProductLoading || isContractLoading

  const saveContract = useCallback(async (): Promise<void> => {
    const validationErrors = await validateForm(values)
    if (Object.keys(validationErrors).length) {
      return
    }

    const [store, plan, product] = await Promise.allSettled([
      fetchStore({
        storeId: values.storeId,
        version: 'latest',
      }).unwrap(),
      fetchPlan(values.planId).unwrap(),
      fetchProduct({ storeId: values.storeId, referenceId: values.referenceId }).unwrap(),
    ])

    if (store.status === 'rejected') {
      setFieldError('storeId', 'Store not found')
    }
    if (plan.status === 'rejected') {
      setFieldError('planId', 'Plan not found')
    }
    if (product.status === 'rejected') {
      setFieldError('referenceId', 'Product not found')
    }

    if (
      store.status === 'fulfilled' &&
      plan.status === 'fulfilled' &&
      product.status === 'fulfilled'
    ) {
      const contractCreateValues = mapContractV1ToCreateRequest(values)

      const contracts = await Promise.allSettled(
        Array.from({ length: Number(values.quantity) }).map(() =>
          createContract(contractCreateValues).unwrap(),
        ),
      )

      contracts.forEach((contract) => {
        if (contract.status === 'fulfilled') {
          toast({
            toastColor: ToastColor.blue,
            message: `Contract ${contract.value.id} created!`,
            toastDuration: ToastDuration.indefinite,
          })
        } else {
          toast({
            toastColor: ToastColor.red,
            message: `Contract failed to created`,
            toastDuration: ToastDuration.indefinite,
          })
        }
      })

      history.push('/admin/contracts')
    }
  }, [
    createContract,
    fetchPlan,
    fetchProduct,
    fetchStore,
    history,
    setFieldError,
    toast,
    validateForm,
    values,
  ])

  return {
    isCreating: isSaveLoading,
    createContract: saveContract,
  }
}
