import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { Product } from '@helloextend/extend-api-client'
import {
  useGetServicersBySellerIdQuery,
  useUpdateProductMutation,
} from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import { AdvancedSelect, COLOR, Grid, Spinner } from '@helloextend/zen'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import { SaveChangesButtonGroup } from '../../../settings/components/save-changes-button-group'
import { getServicerLocationOptions } from '../../../settings/components/servicing/utils'

type DefaultServicingLocationProps = {
  product: Product
}

const DefaultServicingLocation: FC<DefaultServicingLocationProps> = ({ product }) => {
  const { servicer, isLoading } = useGetServicersBySellerIdQuery(product.storeId, {
    selectFromResult: ({ data, ...rest }) => ({
      servicer: data?.[0],
      ...rest,
    }),
  })

  const [updateProduct, { isLoading: isProductUpdating }] = useUpdateProductMutation()
  const { toastError, toastCompleted } = useStandardToast()

  const { push } = useHistory()

  const handleManageLocationsClick = (): void => {
    push(`/admin/stores/${product.storeId}/products/${product.referenceId}/locations`)
  }

  const { values, dirty, handleChange, handleReset } = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    initialValues: {
      servicingLocation: product.settings?.servicingLocationId ?? 'extend',
    },
    onSubmit: () => {},
  })

  const handleSave = async (): Promise<void> => {
    try {
      const { servicingLocation } = values
      await updateProduct({
        storeId: product.storeId,
        productId: product.referenceId,
        data: {
          settings: {
            servicingLocationId: servicingLocation === 'extend' ? null : servicingLocation,
          },
        },
        version: 'latest',
      }).unwrap()
      toastCompleted('Servicing Settings have been successfully updated.')
    } catch {
      toastError('Something went wrong. Please try again.')
    }
  }

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner color={COLOR.BLUE[800]} />
      </SpinnerContainer>
    )
  }

  return (
    <>
      <Container>
        <Grid columns={4}>
          <AdvancedSelect
            id="servicingLocation"
            isNotClearable
            label="Default Servicing Location"
            data-cy="default-servicing-location-dropdown"
            multiple={false}
            onChange={handleChange}
            value={values.servicingLocation}
            options={getServicerLocationOptions(servicer?.locations || [])}
            footerActions={[
              {
                onClick: handleManageLocationsClick,
                children: 'Manage Servicing Locations',
                'data-cy': 'manage-servicing-locations-button',
              },
            ]}
          />
        </Grid>
      </Container>
      {dirty && (
        <SaveChangesButtonGroup
          id="servicing-location-buttons"
          handleSave={handleSave}
          handleCancel={handleReset}
          isLoading={isProductUpdating}
          saveButtonText="Save Changes"
        />
      )}
    </>
  )
}

const Container = styled.div({
  margin: '16px 0',
})

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export { DefaultServicingLocation }
