import type { FC, SyntheticEvent } from 'react'
import React, { useEffect } from 'react'
import { useFormik, getIn } from 'formik'
import styled from '@emotion/styled'
import { Button, Input, Grid, GridItem, InputType, Select } from '@helloextend/zen'
import type { Contract } from '@helloextend/extend-api-client'
import { AddressSearch } from '../../../../../../components/address-search'
import { transformAddress } from '../../../../../../utils/transform-claim-address'
import { schema, states, toValidPhoneNumber } from './schema'

interface ContactFormProps {
  contract: Contract
  onSubmit: (updates: Partial<Contract>) => void
  toggleNavBlocked: (blocked: boolean) => void
}

const ContactForm: FC<ContactFormProps> = ({
  contract: { customer },
  onSubmit,
  toggleNavBlocked,
}) => {
  const shippingAddress = customer?.shippingAddress || customer?.billingAddress
  const customerPhoneNumber = customer?.phone && toValidPhoneNumber(customer.phone)

  const { setFieldValue, handleSubmit, handleChange, handleBlur, values, errors, touched, dirty } =
    useFormik({
      initialValues: {
        name: customer?.name || '',
        email: customer?.email || '',
        phone: customerPhoneNumber || '',
        shippingAddress: {
          address1: shippingAddress?.address1 || '',
          address2: shippingAddress?.address2 || '',
          city: shippingAddress?.city || '',
          provinceCode: shippingAddress?.provinceCode || '',
          postalCode: shippingAddress?.postalCode || '',
          countryCode: 'US',
        },
      },
      onSubmit: (formValues) => {
        onSubmit({ customer: formValues })
      },
      validationSchema: schema,
      validateOnBlur: false,
    })

  const onHandleBlur = (e: SyntheticEvent): void => {
    const { name, value } = e.target as HTMLInputElement
    setFieldValue(name, value.trim())
    handleBlur(e)
  }

  useEffect(() => {
    toggleNavBlocked(dirty)
  }, [dirty, toggleNavBlocked])

  const error = (field: string): string => {
    return getIn(touched, field) && getIn(errors, field)
  }

  return (
    <ContentWrapper>
      <h2>Please confirm the correct contact information for this customer&rsquo;s claim.</h2>
      <form onSubmit={handleSubmit}>
        <Grid columns={{ lg: 2, md: 2, sm: 1 }} spacing={{ lg: 3, md: 2, sm: 2 }}>
          <GridItem fillWidth>
            <Input
              label="Name"
              isError={!!error('name')}
              id="name"
              onChange={handleChange}
              onBlur={onHandleBlur}
              value={values.name}
              errorFeedback={error('name')}
            />
          </GridItem>
          <Input
            label="Email"
            type={InputType.email}
            id="email"
            onChange={handleChange}
            onBlur={onHandleBlur}
            value={values.email}
            isError={!!error('email')}
            errorFeedback={error('email')}
          />
          <Input
            label="Phone"
            type={InputType.tel}
            id="phone"
            onChange={(e) => {
              e.target.value = toValidPhoneNumber(e.target.value)
              handleChange(e)
            }}
            onBlur={onHandleBlur}
            value={values.phone}
            isError={!!error('phone')}
            errorFeedback={error('phone')}
          />
          <GridItem fillWidth={false}>
            {/* TODO: update AddressSearch styling to match */}
            <AddressSearch
              label="Shipping Address"
              errorMessage={error('shippingAddress.address1')}
              value={transformAddress(values.shippingAddress)}
              onChange={(changedFields) => {
                setFieldValue('shippingAddress', changedFields)
              }}
            />
          </GridItem>
          <Input
            label="Apt, Suite #"
            id="shippingAddress.address2"
            onChange={handleChange}
            onBlur={onHandleBlur}
            value={values.shippingAddress.address2}
            isError={!!error('shippingAddress.address2')}
            errorFeedback={error('shippingAddress.address2')}
          />
          <Input
            label="City"
            id="shippingAddress.city"
            onChange={handleChange}
            onBlur={onHandleBlur}
            value={values.shippingAddress.city}
            isError={!!error('shippingAddress.city')}
            errorFeedback={error('shippingAddress.city')}
          />
          <Grid columns={2} spacing={1}>
            <Select
              id="shippingAddress.provinceCode"
              onChange={handleChange}
              onBlur={onHandleBlur}
              value={values.shippingAddress.provinceCode}
              isError={!!error('shippingAddress.provinceCode')}
              errorFeedback={error('shippingAddress.provinceCode')}
              label="State"
            >
              <option value="" disabled>
                -Select-
              </option>
              {states.map((state: string) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </Select>
            <Input
              label="Zip Code"
              id="shippingAddress.postalCode"
              onChange={handleChange}
              onBlur={onHandleBlur}
              value={values.shippingAddress.postalCode}
              isError={!!error('shippingAddress.postalCode')}
              errorFeedback={error('shippingAddress.postalCode')}
            />
          </Grid>
        </Grid>
        <ButtonWrapper>
          <Button data-cy="continue-button" text="Continue" type="submit" />
        </ButtonWrapper>
      </form>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({
  width: '100%',
  maxWidth: 694,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
})

const ButtonWrapper = styled.div({
  marginTop: 32,
  display: 'flex',
  justifyContent: 'center',
})

export { ContactForm }
