import type { Product } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetchAll = (storeId: string, accessToken: string) =>
  ({
    type: 'PRODUCTS_FETCH_ALL',
    payload: { storeId, accessToken },
  } as const)

export const fetchAllRequest = () => ({ type: 'PRODUCTS_FETCH_ALL_REQUEST' } as const)

export const fetchAllSuccess = (products: Product[]) =>
  ({
    type: 'PRODUCTS_FETCH_ALL_SUCCESS',
    payload: products,
  } as const)

export const fetchAllFailure = createActionFailure('PRODUCTS_FETCH_ALL_FAILURE')
