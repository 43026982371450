import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../../tokens/colors'

export interface HeaderUserInfoProps {
  initials: string
  label: string
  'data-cy'?: string
}

export const HeaderUserInfo: FC<HeaderUserInfoProps> = ({ initials, label, 'data-cy': dataCy }) => {
  return (
    <StyledUserInfo data-cy={dataCy}>
      <Avatar>{initials}</Avatar>
      <Label>{label}</Label>
    </StyledUserInfo>
  )
}

const StyledUserInfo = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 12,
  color: COLOR.WHITE,
})

const Avatar = styled.div({
  background: COLOR.BLUE[500],
  width: 32,
  height: 32,
  marginLeft: -4,
  borderRadius: 16,
  fontSize: 12,
  lineHeight: '32px',
  textAlign: 'center',
  fontWeight: 'bolder',
})

const Label = styled.div({
  fontSize: 14,
  fontWeight: 'bold',
})
