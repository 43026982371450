import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import type { AdvancedSelectChangeEvent } from '@helloextend/zen'
import { AdvancedSelect, COLOR } from '@helloextend/zen'
import styled from '@emotion/styled'
import { v4 as uuid } from 'uuid'
import { resetReportingValue, updatePromptSlot } from '../../../../../store/slices/amp-slice'
import type { MessageBlockTypes } from '../../utils'
import {
  getThreadConnectorOptionsByMessageBlockType,
  ThreadConnectorDropdownValues,
} from '../../utils'

type ThreadConnectorDropdownProps = {
  value: string
  isDisabled: boolean
  messageBlockType: MessageBlockTypes
}

const ThreadConnectorDropdown: FC<ThreadConnectorDropdownProps> = ({
  value,
  isDisabled,
  messageBlockType,
}) => {
  const dispatch = useDispatch()

  const handleThreadConnectorSelect = (e: AdvancedSelectChangeEvent): void => {
    const v = e.target.value || uuid()
    dispatch(resetReportingValue())
    dispatch(updatePromptSlot(v))
  }

  const options = useMemo(
    () => getThreadConnectorOptionsByMessageBlockType(messageBlockType),
    [messageBlockType],
  )

  const isSlotReportingValue = useMemo(
    () =>
      Object.values(ThreadConnectorDropdownValues).find(
        (reportingValue) => reportingValue === value,
      ),
    [value],
  )

  return (
    <Container>
      <Title>Connect Customer Response To</Title>
      <AdvancedSelect
        data-cy="connect-to-dropdown"
        id="selectorValue"
        value={isSlotReportingValue ? value : ''}
        onChange={handleThreadConnectorSelect}
        multiple={false}
        options={options}
        maxQuantityToDisplay={8}
        isDisabled={isDisabled}
      />
    </Container>
  )
}

const Container = styled.div({
  marginBottom: 32,
})

const Title = styled.h3({
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '28px',
  color: COLOR.NEUTRAL[1000],
  margin: '0 0 16px 0',
})

export { ThreadConnectorDropdown, ThreadConnectorDropdownProps }
