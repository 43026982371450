import type { ChangeEvent } from 'react'
import React, { forwardRef, useRef, useCallback, useEffect, useImperativeHandle } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../../tokens/colors'
import { InputType } from '../input/types'
import { Field } from '../field'
import { Icon } from '../../icon'
import { ArrowDropDown } from '../../../tokens/icons'
import type { InputProps } from '../input/input'

export interface InputWithSelectProps
  extends Omit<InputProps, 'prefix' | 'maxLength' | 'startAdornment'> {
  selectValue: string
  children: React.ReactNode
  selectPlaceholder?: string
  disableSelectPlaceholder?: boolean
  onSelectChange?: (e: ChangeEvent<HTMLSelectElement>) => void
}

/**
 * The `value` prop is associated with the text input value of the field and the `selectValue` prop with the select
 * portion. The children of a InputWithSelect component are associated with the select element and can be either
 * `<option>` or `<optgroup>` and configured just like you would for a regular html `<Select>` element.
 */
export const InputWithSelect = forwardRef<HTMLInputElement, InputWithSelectProps>(
  (
    {
      'data-cy': dataCy,
      type = InputType.text,
      id,
      label,
      value,
      suffix,
      ariaLabel,
      icon,
      autoFocus,
      min,
      max,
      isDisabled = false,
      isError = false,
      isMonospace = false,
      errorFeedback,
      placeholder,
      subtext,
      helperText,
      actionButtonProps,
      selectValue,
      selectPlaceholder,
      disableSelectPlaceholder = false,
      children,
      onChange = () => {},
      onClick,
      onBlur = () => {},
      onFocus = () => {},
      onKeyPress,
      onKeyUp,
      onKeyDown,
      onSelectChange = () => {},
      onClearRequest,
    },
    ref,
  ) => {
    const select = useRef<HTMLSelectElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement)

    const changeSelectWidth = useCallback(() => {
      if (!select.current) {
        return
      }
      const tempSelect = document.createElement('select')
      const tempOption = document.createElement('option')

      tempOption.textContent = select.current.options[select.current.selectedIndex].text
      tempSelect.className = select.current.className
      tempSelect.style.cssText += `
        visibility: hidden;
        position: fixed;
        `
      tempSelect.appendChild(tempOption)
      select.current.after(tempSelect)

      const tempSelectWidth = tempSelect.getBoundingClientRect().width
      select.current.style.width = `${tempSelectWidth}px`
      tempSelect.remove()
    }, [select])

    const handleOnSelectChange = useCallback(
      (e) => {
        onSelectChange(e)
        inputRef.current?.focus()
      },
      [onSelectChange],
    )

    useEffect(() => {
      changeSelectWidth()
    }, [changeSelectWidth, selectValue])

    return (
      <Field
        id={id}
        label={label}
        value={value}
        suffix={suffix}
        icon={icon}
        isDisabled={isDisabled}
        isError={isError}
        errorFeedback={errorFeedback}
        subtext={subtext}
        helperText={helperText}
        actionButtonProps={actionButtonProps}
        onClearRequest={onClearRequest}
        data-cy={dataCy && `${dataCy}:field`}
      >
        <SelectContainer shiftForIcon={!!icon}>
          <Select
            data-cy={dataCy && `${dataCy}:select`}
            hasValue={!!selectValue}
            placeholder={selectPlaceholder}
            ref={select}
            onChange={handleOnSelectChange}
            shiftForIcon={!!icon}
            disabled={isDisabled}
          >
            {selectPlaceholder && (
              <option value="" disabled={disableSelectPlaceholder}>
                {selectPlaceholder}
              </option>
            )}
            {children}
          </Select>
          <DownIcon>
            <Icon icon={ArrowDropDown} {...(isDisabled && { color: COLOR.NEUTRAL[600] })} />
          </DownIcon>
        </SelectContainer>
        <StyledInput
          value={value}
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          aria-label={ariaLabel}
          type={type}
          id={id}
          disabled={isDisabled}
          placeholder={placeholder}
          isMonospace={isMonospace}
          autoFocus={autoFocus}
          min={min}
          max={max}
          ref={inputRef}
          data-cy={dataCy && `${dataCy}:input`}
        />
      </Field>
    )
  },
)

const StyledInput = styled.input<{
  isMonospace: boolean
  disabled: boolean
}>(({ isMonospace, disabled }) => ({
  flex: 1,
  border: 'none',
  borderRadius: 4,
  color: disabled ? COLOR.NEUTRAL[600] : COLOR.NEUTRAL[1000],
  padding: '8px 12px',
  width: '100%',
  boxSizing: 'border-box',
  fontFamily: isMonospace ? 'monospace, monospace' : '"Nunito Sans", sans-serif',
  fontSize: 16,
  lineHeight: '24px',
  outline: 'none',
  background: 'none',
  '&::placeholder': {
    color: COLOR.NEUTRAL[600],
  },

  /* Override browser default margins */
  margin: 0,
}))

const SelectContainer = styled.div<{
  shiftForIcon: boolean
}>(({ shiftForIcon }) => ({
  position: 'relative',
  maxWidth: '50%',
  marginLeft: shiftForIcon ? -28 : 'unset',
  '&::after': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: 8,
    bottom: 8,
    width: 1,
    backgroundColor: COLOR.NEUTRAL[300],
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    inset: 0,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: 'transparent',
    borderRadius: 4,
    pointerEvents: 'none',
    transition: '50ms',
  },
  '&:has(:focus-visible)::before': {
    borderColor: COLOR.BLUE[700],
  },
}))

const Select = styled.select<{
  hasValue: boolean
  shiftForIcon: boolean
  disabled: boolean
}>(({ hasValue, shiftForIcon, disabled }) => ({
  appearance: 'none',
  background: 'none',
  border: 'none',
  padding: `8px 32px 8px ${shiftForIcon ? 40 : 12}px`,
  fontFamily: '"Nunito Sans", sans-serif',
  fontSize: 16,
  lineHeight: '24px',
  maxWidth: '100%',
  boxSizing: 'border-box',
  textOverflow: 'ellipsis',
  outline: 'none',
  color: hasValue && !disabled ? COLOR.NEUTRAL[1000] : COLOR.NEUTRAL[600],
}))

const DownIcon = styled.div({
  position: 'absolute',
  right: 6,
  top: 8,
  pointerEvents: 'none',
})
