import { createBooleanReducer } from '../../factories'
import type { Action } from '../actions'

const isSuccess = createBooleanReducer<Action>(
  ['CONTRACTS_UPDATE_SUCCESS', 'CONTRACTS_UPDATE_SUCCESS_V2'],
  [
    'CONTRACTS_UPDATE_REQUEST',
    'CONTRACTS_UPDATE_FAILURE',
    'CONTRACTS_UPDATE_REQUEST_V2',
    'CONTRACTS_UPDATE_FAILURE_V2',
    'CONTRACTS_UPDATE_RESET',
    'CONTRACTS_FETCH',
    'CONTRACTS_FETCH_V2',
  ],
)

export default isSuccess
