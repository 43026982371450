import type { Action } from '../actions'

export type ByNameErrorReducerState = Record<string, boolean>

export const initialState: ByNameErrorReducerState = {}

export default function errorByName(state = initialState, action: Action): ByNameErrorReducerState {
  switch (action.type) {
    case 'ADMIN_CLAIM_PHOTO_UPLOAD_FAILURE':
      return { ...state, [action.payload.name]: true }
    case 'ADMIN_CLAIM_PHOTO_UPLOAD_REQUEST':
      return { ...state, [action.payload.name]: false }
    default:
      return state
  }
}
