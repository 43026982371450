import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@helloextend/client-branding'
import { AlertText, AlertContainer } from './styles'

interface SuccessMessageProps {
  message: string
  // will need when icons are established
  hasIcon?: boolean
}

const SuccessMessage: FC<SuccessMessageProps> = ({ message }) => (
  <AlertContainer backgroundColor={COLOR.GREEN_SOLID_100}>
    <AlertText title={message} color={COLOR.GREEN_SOLID_800}>
      {message}
    </AlertText>
  </AlertContainer>
)

export { SuccessMessage }
