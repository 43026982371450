import type { Action } from '../actions'
import { createBooleanReducer } from '../../factories'

export default createBooleanReducer<Action>(
  [
    'LOGIN_REQUEST',
    'FORGOT_PASSWORD_REQUEST',
    'RESET_PASSWORD_REQUEST',
    'REGISTER_REQUEST',
    'CHANGE_PASSWORD_REQUEST',
  ],
  [
    'LOGIN_SUCCESS',
    'LOGIN_FAILURE',
    'FORGOT_PASSWORD_SUCCESS',
    'FORGOT_PASSWORD_FAILURE',
    'RESET_PASSWORD_SUCCESS',
    'RESET_PASSWORD_FAILURE',
    'REGISTER_SUCCESS',
    'REGISTER_FAILURE',
    'CHANGE_PASSWORD_SUCCESS',
    'CHANGE_PASSWORD_FAILURE',
  ],
)
