import type { FC } from 'react'
import React, { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import type { AdvancedSelectChangeEvent } from '@helloextend/zen'
import { AdvancedSelect, Checkbox, Grid, Information, Stack } from '@helloextend/zen'
import { StackAlign } from '@helloextend/zen/src/components/stack'
import { Subheading } from '@helloextend/zen/src/tokens/typography'
import type { Servicer, ServicerLocation } from '@helloextend/extend-api-client'
import { useToggle } from '@helloextend/client-hooks'
import { useStandardToast } from '@helloextend/merchants-ui'
import {
  useUpdateServicerMutation,
  useUpsertMerchantServicingSettingsMutation,
} from '@helloextend/extend-api-rtk-query'
import { useHistory } from 'react-router-dom'
import type { MerchantServicingSettings } from '@helloextend/extend-api-rtk-query/src/servicers/types'
import { LDFlag } from '@helloextend/portal/src/constants/ld-flags'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { SaveChangesButtonGroup } from '../save-changes-button-group'
import { ServicingModal } from '../servicing-modal'
import { getServicerLocationOptions } from './utils'

type ReplacementLocationProps = {
  servicer: Servicer
  merchantServicerSettings: MerchantServicingSettings
  storeId: string
}

const ReplacementLocation: FC<ReplacementLocationProps> = ({
  servicer,
  merchantServicerSettings,
  storeId,
}) => {
  const savedLocation = useMemo(
    () => findSavedServicerLocation(servicer)?.id || 'extend',
    [servicer],
  )
  // [CS-780] remove the merchant-servicing settings FF
  const { [LDFlag.MerchantServicingSettings]: FF_MERCHANT_SERVICING_SETTINGS } = useFlags()
  const fieldDestroyEnabled = FF_MERCHANT_SERVICING_SETTINGS
    ? merchantServicerSettings.fieldDestroyEnabled
    : servicer.settings.fieldDestroyEnabled

  // ! used because product return enabled/disabled is the opposite of field destroy enabled/disabled
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(!fieldDestroyEnabled)
  const [hasCheckboxChanged, setHasCheckboxChanged] = useState(false)

  const [isModalDisplayed, { on: showModal, off: hideModal }] = useToggle(false)

  const { toastCompleted, toastError } = useStandardToast()
  const [updateServicer] = useUpdateServicerMutation()
  const [upsertMerchantServicingSettings, { isSuccess, isError, isLoading }] =
    useUpsertMerchantServicingSettingsMutation()

  const [activeDropdownSelection, setActiveDropdownSelection] = useState(
    findSavedServicerLocation(servicer)?.id || 'extend',
  )

  const { push } = useHistory()

  const handleSave = (): void => {
    updateServicer({
      servicerId: servicer.id,
      body: {
        settings: {
          defaultServicingLocation:
            activeDropdownSelection === 'extend' ? null : activeDropdownSelection,
        },
      },
    })
  }

  const handleOnClickSubmit = (): void => {
    updateServicer({
      servicerId: servicer.id,
      body: {
        settings: {
          fieldDestroyEnabled: isCheckboxChecked,
        },
      },
    })
    upsertMerchantServicingSettings({
      sellerId: merchantServicerSettings.sellerId,
      fieldDestroyEnabled: isCheckboxChecked,
    })
    setHasCheckboxChanged(true)
  }

  const handleCancel = (): void => {
    setActiveDropdownSelection(savedLocation)
  }

  const handleNewDropdownSelection = (e: AdvancedSelectChangeEvent): void => {
    // disallow clearing the selection
    if (e.target.value) {
      setActiveDropdownSelection(e.target.value)
    }
  }

  const handleManageLocationsClick = (): void => {
    push(`/admin/stores/${storeId}/settings/locations`)
  }

  useEffect(() => {
    if (isSuccess && hasCheckboxChanged) {
      setIsCheckboxChecked(!isCheckboxChecked)
      setHasCheckboxChanged(false)
      hideModal()
    }
  }, [hasCheckboxChanged, hideModal, isCheckboxChecked, isSuccess])

  useEffect(() => {
    if (isSuccess) {
      toastCompleted('Servicing Settings have been successfully updated.')
    }
  }, [isSuccess, toastCompleted])

  useEffect(() => {
    if (isError) {
      toastError('There was an error updating the Servicing Settings. Please try again.')
    }
  }, [isError, toastError])

  return (
    <div data-cy="replacement-location">
      <ServicingModal
        isOpen={isModalDisplayed}
        isEnable={!isCheckboxChecked}
        optionName="Product Return"
        onClickCancel={hideModal}
        onClickSubmit={handleOnClickSubmit}
        isProcessing={isLoading}
        enableMessage="Enabling product return will mean that customers have to return defective products."
        disableMessage="Disabling product return will mean that customers do not have to return their defective products."
      />
      <ContainerWithMargin>
        <Stack align={StackAlign.center}>
          <Subheading>Replacement Servicing Location</Subheading>
          <Information buttonSize="xsmall">
            Control where customers return their defective products.
          </Information>
        </Stack>
      </ContainerWithMargin>
      <CheckboxContainer>
        <Checkbox
          label="Product return"
          data-cy="product-return-checkbox"
          checked={isCheckboxChecked}
          onChange={showModal}
        />
      </CheckboxContainer>
      {isCheckboxChecked && (
        <>
          <Grid columns={4}>
            <ContainerWithMargin>
              <AdvancedSelect
                id="default-servicing-location"
                isNotClearable
                label="Default Servicing Location"
                data-cy="default-servicing-location-dropdown"
                multiple={false}
                onChange={handleNewDropdownSelection}
                value={activeDropdownSelection}
                options={getServicerLocationOptions(servicer?.locations)}
                footerActions={[
                  {
                    onClick: handleManageLocationsClick,
                    children: 'Manage Servicing Locations',
                    'data-cy': 'manage-servicing-locations-button',
                  },
                ]}
              />
            </ContainerWithMargin>
          </Grid>
          {savedLocation !== activeDropdownSelection && (
            <ContainerWithMargin>
              <SaveChangesButtonGroup
                handleSave={handleSave}
                handleCancel={handleCancel}
                isLoading={isLoading}
                isSaveDisabled={isLoading}
                id="replacement-location"
              />
            </ContainerWithMargin>
          )}
        </>
      )}
    </div>
  )
}

const findSavedServicerLocation = (servicer: Servicer): ServicerLocation | undefined => {
  return servicer.locations?.find(
    (locations) => servicer.settings.defaultServicingLocation === locations.id,
  )
}

const CheckboxContainer = styled.div({
  marginBottom: 16,
})

const ContainerWithMargin = styled.div({
  marginBottom: 24,
})

export { ReplacementLocation }
