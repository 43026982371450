import type { FC } from 'react'
import React, { useMemo, useEffect } from 'react'
import {
  useCreateOrganizationMutation,
  useGetAccountQuery,
} from '@helloextend/extend-api-rtk-query'
import * as Yup from 'yup'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { useStandardToast } from '@helloextend/merchants-ui'
import { Input, Button, ButtonGroup } from '@helloextend/zen'

const validationSchema = Yup.object()
  .shape({
    organizationName: Yup.string().required().default(''),
  })
  .defined()

type AddAccountSchema = Yup.InferType<typeof validationSchema>

type CreateAccountModalFormProps = {
  onClickClose: () => void
}

const CreateOrganizationModalForm: FC<CreateAccountModalFormProps> = ({ onClickClose }) => {
  const { accountId } = useParams<{ accountId: string }>()
  const { data: accountData } = useGetAccountQuery({ accountId })
  const { toastError, toastCompleted } = useStandardToast()
  const [createOrganizationMutation, { error, isError }] = useCreateOrganizationMutation()

  const organizationNameExistsError = useMemo(() => {
    if (error && 'status' in error) {
      if (error.status === 409) {
        return 'This organization name already exists.'
      }
    }
    return ''
  }, [error])

  useEffect(() => {
    if (isError && !organizationNameExistsError) {
      toastError(`Error creating organization`)
    }
  }, [isError, toastError, organizationNameExistsError])

  const { values, isValid, errors, handleSubmit, handleChange, handleBlur } =
    useFormik<AddAccountSchema>({
      enableReinitialize: true,
      initialValues: validationSchema.default(),
      validationSchema,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: async (formValues: AddAccountSchema) => {
        const response = await createOrganizationMutation({
          accountId,
          body: { name: formValues.organizationName },
        })

        if ('data' in response) {
          onClickClose()
          toastCompleted('Organization created successfully')
        }
      },
    })

  return (
    <Form onSubmit={handleSubmit}>
      <FieldWrapper>
        <Input
          data-cy="merchant-account"
          id="accountName"
          label="Merchant Account"
          value={accountData?.name ?? ''}
          isDisabled
        />
      </FieldWrapper>
      <FieldWrapper>
        <Input
          data-cy="organization-name"
          id="organizationName"
          label="Organization Name"
          value={values.organizationName || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          errorFeedback={errors.organizationName || organizationNameExistsError}
          isError={isError}
        />
      </FieldWrapper>
      <ButtonWrapper>
        <ButtonGroup>
          <Button emphasis="medium" data-cy="cancel-button" text="Cancel" onClick={onClickClose} />
          <Button emphasis="high" text="Create Organization" type="submit" isDisabled={!isValid} />
        </ButtonGroup>
      </ButtonWrapper>
    </Form>
  )
}

const FieldWrapper = styled.div({
  width: '100%',
  marginBottom: 24,
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

const Form = styled.form({})

export { CreateOrganizationModalForm }
