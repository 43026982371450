import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { useGetServicerQuery, useUpdateServicerMutation } from '@helloextend/extend-api-rtk-query'
import { useToaster, ToastDuration, ToastColor } from '@helloextend/zen'
import type { CollapsibleInfoGroupProps } from '../../../../../components/collapsible-info-group'
import { CollapsibleInfoGroup } from '../../../../../components/collapsible-info-group'
import { schema, getFormData } from './schema'
import type { Values } from './schema'

const ProfileTab: FC = () => {
  const { servicerId } = useParams<{ servicerId: string }>()
  const { data: servicer } = useGetServicerQuery(servicerId)
  const [isEditing, setIsEditing] = useState(false)
  const { toast } = useToaster()
  const [updateServicer] = useUpdateServicerMutation()

  const { values, errors, resetForm, submitForm, setFieldValue } = useFormik<Values>({
    enableReinitialize: true,
    initialValues: {
      businessName: servicer?.businessName ?? '',
      contact: {
        phone: servicer?.contact?.phone ?? '',
        email: servicer?.contact?.email ?? '',
        name: servicer?.contact?.name ?? '',
      },
    },
    validationSchema: schema,
    onSubmit: (body) => {
      updateServicer({ servicerId, body })
      toast({
        message: 'Changes saved!',
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.blue,
      })
      toggleIsEditing()
    },
  })

  const toggleIsEditing = useCallback(() => {
    setIsEditing(!isEditing)
  }, [setIsEditing, isEditing])

  if (!servicer) {
    return null
  }

  // TODO: [DEPOT-1880] Remove dependency on setFieldValue from CollapsibleInfoGroup
  const handleChange = (key: string, value: any): void => {
    setFieldValue(key, value)
  }

  return (
    <CollapsibleInfoGroup
      data-cy="profile-tab"
      title="Contact Information"
      handleChange={handleChange as CollapsibleInfoGroupProps['handleChange']}
      numColumns={2}
      isCollapsible={false}
      primaryButtonText={isEditing ? 'Save' : undefined}
      secondaryButtonText={isEditing ? 'Cancel' : undefined}
      onPrimaryButtonClick={submitForm}
      onSecondaryButtonClick={() => {
        resetForm()
        toggleIsEditing()
      }}
      data={getFormData(isEditing, servicer, values, errors)}
      hasEditButton
      isEditing={isEditing}
      onEditButtonClick={toggleIsEditing}
      buttonAlignment="right"
    />
  )
}

export { ProfileTab }
