import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'

interface PageHeaderProps {
  title: string
  'data-cy'?: string
}

const PageHeader: FC<PageHeaderProps> = ({ title, 'data-cy': dataCy }) => {
  return (
    <Header>
      <Title data-cy={dataCy}>{title}</Title>
    </Header>
  )
}

export { PageHeader }

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 24,
})

const Title = styled.h3({
  fontSize: 32,
  margin: 0,
  padding: 0,
})
