import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { ClaimsUser } from '@helloextend/extend-api-client'
import { BasicModal } from '../../../../../components/basic-modal'
import { RemoveUserForm } from './remove-user-form'

interface RemoveUserModalProps {
  user?: ClaimsUser
  isVisible: boolean
  handleModalToggle: () => void
}

const RemoveUserModal: FC<RemoveUserModalProps> = ({ isVisible, user, handleModalToggle }) => {
  return (
    <BasicModal data-cy="remove-user-modal" isVisible={isVisible} onClickClose={handleModalToggle}>
      <Title>Permanently delete this user?</Title>
      <WarningText>
        This will remove the user from the Claim Management List, preventing assignment of claims to
        this user.
      </WarningText>
      <RemoveUserForm user={user} handleClose={handleModalToggle} />
    </BasicModal>
  )
}

const Title = styled.h2({
  fontSize: 20,
  lineHeight: '27px',
  fontWeight: 700,
  marginTop: 0,
})

const WarningText = styled.div({
  marginBottom: 24,
})

export { RemoveUserModal }
