import type { VFC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { ModalFooterProps } from './modal-types'
import { ButtonGroup } from '../button-group'
import { Button } from '../button'

export const SharedFooter: VFC<ModalFooterProps> = ({
  altButtonProps,
  footerContent,
  primaryButtonProps,
  secondaryButtonProps,
  'data-cy': dataCy,
}) => {
  return (
    <>
      {footerContent && (
        <FooterContent data-cy={dataCy && `${dataCy}:footer-content`}>
          {footerContent}
        </FooterContent>
      )}
      {(primaryButtonProps || secondaryButtonProps || altButtonProps) && (
        <FooterActions data-cy={dataCy && `${dataCy}:footer-actions`}>
          {(primaryButtonProps || secondaryButtonProps) && (
            <ButtonGroup isReversed>
              {primaryButtonProps && <Button {...primaryButtonProps} />}
              {secondaryButtonProps && <Button {...secondaryButtonProps} emphasis="medium" />}
            </ButtonGroup>
          )}
          {altButtonProps && <Button {...altButtonProps} emphasis="medium" />}
        </FooterActions>
      )}
    </>
  )
}

const FooterActions = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row-reverse',
  gap: 8,
})

const FooterContent = styled.div({})
