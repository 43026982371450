import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import type { Money, ServiceOrder } from '@helloextend/extend-api-client'
import { Badge, Button, COLOR, InlineAlert, InlineAlertColor } from '@helloextend/zen'
import { Info } from '@helloextend/zen/src/tokens/icons'
import type { BadgeDescriptionItem } from '../../../../../../../service-order-status'
import { getServiceOrderStatusDetails } from '../../../../../../../service-order-status'
import { setClaimDetailsActiveView } from '../../../../../../../../../../store/slices/claim-details'
import { getServiceOrderInfoData } from './schema'
import { DataDisplayGroup } from '../../../../../../../../../../components/data-display-group'
import { ServiceOrderActionsMenu } from '../../../../../service-order-actions-menu'
import { getServiceOrderModalMap } from '../../../../../../../service-order-modal-map'

interface ActiveServiceOrderSectionProps {
  serviceOrder?: ServiceOrder
  entitlements?: { coverageAmountRemaining: Money }
}

const ActiveServiceOrderSection: FC<ActiveServiceOrderSectionProps> = ({
  serviceOrder,
  entitlements,
}) => {
  const dispatch = useDispatch()
  const badgeDescription = useMemo((): BadgeDescriptionItem | null => {
    return getServiceOrderStatusDetails(serviceOrder)
  }, [serviceOrder])

  const buttonProps = useMemo(() => {
    if (!serviceOrder?.status) return null
    const {
      text,
      action,
      buttonProps: props,
    } = getServiceOrderModalMap(serviceOrder.status, serviceOrder?.serviceType) ?? {}
    if (!action) return null
    return {
      text: `${text}`,
      onClick: () => {
        dispatch(setClaimDetailsActiveView(action))
      },
      emphasis: props?.emphasis ?? 'high',
    }
  }, [serviceOrder?.status, serviceOrder?.serviceType, dispatch])

  const serviceOrderInfo = useMemo(
    () => getServiceOrderInfoData(serviceOrder, entitlements?.coverageAmountRemaining),
    [serviceOrder, entitlements],
  )

  const isEligibleForPartialReplacement = serviceOrder?.configurations?.isPartialReplacement

  return (
    <Container>
      <Header>
        <TitleBadgeWrapper>
          <Title data-cy="active-service-order">
            {serviceOrder?.status === 'closed' ? 'Service Order' : 'Active Service Order'}
          </Title>
          {badgeDescription && (
            <Badge
              text={badgeDescription.badgeText}
              color={badgeDescription.badgeColor}
              details={badgeDescription.badgeDetails}
            />
          )}
        </TitleBadgeWrapper>
        {serviceOrder?.status !== 'closed' && (
          <ServiceOrderActionsMenu
            serviceOrder={serviceOrder}
            buttonText="SO Actions"
            buttonSize="small"
            cyPrefix="so-tab"
          />
        )}
      </Header>
      {isEligibleForPartialReplacement && (
        <AlertContainer>
          <InlineAlert
            data-cy="partial-replacement-eligibility-banner"
            color={InlineAlertColor.blue}
            icon={Info}
          >
            Eligible for partial replacement
          </InlineAlert>
        </AlertContainer>
      )}
      <DataDisplayGroup data={serviceOrderInfo} numColumns={2} />
      {buttonProps && (
        <ButtonRow>
          <Button size="regular" data-cy={`${buttonProps.text}-btn`} {...buttonProps} />
        </ButtonRow>
      )}
    </Container>
  )
}

const AlertContainer = styled.div({
  marginTop: 20,
})

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  marginBottom: 32,
  padding: 40,
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
})

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '33px',
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  marginRight: 12,
})

const TitleBadgeWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const ButtonRow = styled.div({
  display: 'flex',
  gap: 20,
  marginTop: 40,
})

export { ActiveServiceOrderSection }
