import type { FC } from 'react'
import React, { memo, useContext } from 'react'
import { ContractType } from '@helloextend/extend-api-client'
import { PCRS, ShippingProtection } from './forms'
import type { ContractContextValue } from './components/contract-container'
import { ContractContainer, ContractContext } from './components/contract-container'

const DisplayContractType: FC = memo(() => {
  const { contract, toggleNavBlocked, saveUpdates, planTermsUrl } = useContext(
    ContractContext,
  ) as ContractContextValue

  const contractType = contract?.type
  if (contractType === ContractType.SHIPPING_PROTECTION) {
    return (
      <ShippingProtection
        saveUpdates={saveUpdates}
        toggleNavBlocked={toggleNavBlocked}
        contract={contract}
        planTermsUrl={planTermsUrl}
      />
    )
  }

  return (
    <PCRS
      saveUpdates={saveUpdates}
      toggleNavBlocked={toggleNavBlocked}
      contract={contract}
      planTermsUrl={planTermsUrl}
    />
  )
})

const Contract: FC = () => {
  return (
    <ContractContainer>
      <DisplayContractType />
    </ContractContainer>
  )
}

export { Contract, DisplayContractType }
