import { Add, Button } from '@helloextend/zen'
import type { FC, MouseEvent } from 'react'
import React, { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import type { ConversationConfigurationResponse } from '@helloextend/extend-api-rtk-query'
import { useListConfigurationsQuery } from '@helloextend/extend-api-rtk-query'
import { ClipboardSearchIcon, DataReactTable } from '@helloextend/merchants-ui'
import { useHistory } from 'react-router-dom'
import { bp } from '@helloextend/client-utils/breakpoints'
import { Permission } from '../../../../lib/permissions'
import { AdjudicationTabs } from '../components'
import { configurationColumns, mapConfigurationsTotableData } from './table-config'
import { usePermissions } from '../../../../hooks/use-permissions'

const ConversationConfigurationLanding: FC = () => {
  const { data, isFetching } = useListConfigurationsQuery()
  const { push } = useHistory()
  const { hasPermission } = usePermissions()

  const hasConfigurations = Boolean((data && data.length !== 0) || isFetching)

  const tableData = useMemo(() => mapConfigurationsTotableData(data), [data])

  const handleRowClick = useCallback(
    (_e: MouseEvent, rowData: ConversationConfigurationResponse) => {
      push(`/admin/adjudication-management/conversation-configuration/${rowData.id}`)
    },
    [push],
  )

  const handleCreateConversation = (): void => {
    push('/admin/adjudication-management/conversation-configuration/create')
  }

  return (
    <>
      <AdjudicationTabs />
      <InfoWrapper>
        <IntroText>
          The defined configuration will determine what conversation a customer will see when they
          file their claim via Kaley.
        </IntroText>
      </InfoWrapper>
      <TopRowButtonContainer>
        {hasPermission(Permission.ManageAdjudication) && (
          <ButtonWrapper>
            <Button
              data-cy="create-configuration-button"
              text="Create Configuration"
              icon={Add}
              emphasis="medium"
              onClick={handleCreateConversation}
            />
          </ButtonWrapper>
        )}
      </TopRowButtonContainer>
      {hasConfigurations && (
        <DataReactTable
          data-cy="conversation-configuration-list"
          type="configurations"
          isLoading={isFetching}
          data={tableData}
          columns={configurationColumns}
          onRowClick={handleRowClick}
          pageCount={-1}
          searchMode="fuzzy"
          enableSearch
          hasSearchBar
          searchOptions={[
            {
              label: 'Adjudication Category',
              value: 'category',
            },
            {
              label: 'Store ID',
              value: 'storeId',
            },
            {
              label: 'Plan ID',
              value: 'planId',
            },
          ]}
        />
      )}
      {!hasConfigurations && (
        <NoConfigurationsContainer>
          <ClipboardSearchIcon sizePx={48} />
          <NoConfigurationsHeader>No configurations yet</NoConfigurationsHeader>
          <NoConfigurationsText>
            The defined configuration will determine what conversation a customer will see when they
            file their claim via Kaley.
          </NoConfigurationsText>
        </NoConfigurationsContainer>
      )}
    </>
  )
}

const TopRowButtonContainer = styled.div({
  display: 'flex',
  gap: 40,
  marginBottom: 24,
  [bp.lg]: {
    justifyContent: 'flex-end',
    marginBottom: -40,
  },
})

const InfoWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 40,
})

const ButtonWrapper = styled.div({
  flexShrink: 0,
})

const IntroText = styled.div({
  fontSize: 16,
  lineHeight: '24px',
  marginBottom: 28,
})

const NoConfigurationsContainer = styled.div({
  maxWidth: 584,
  margin: 'auto',
  marginTop: '10%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
})

const NoConfigurationsHeader = styled.p({
  margin: '32px 0 0 0',
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '34px',
})

const NoConfigurationsText = styled.p({
  fontWeight: 400,
  fontSize: 20,
  lineHeight: '28px',
  marginTop: 24,
})

export { ConversationConfigurationLanding }
