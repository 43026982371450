import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR, Icon } from '@helloextend/zen'
import { ChevronLeft, ChevronRight } from '@helloextend/zen/src/tokens/icons'

type PaginationType =
  | 'plans'
  | 'contracts'
  | 'users'
  | 'claims'
  | 'events'
  | 'service orders'
  | 'servicers'

type PaginationProps = {
  count: number
  pageSize?: number
  type: PaginationType
  currPage: number
  hasNext?: boolean
  hasPrev?: boolean
  onPrevPage: () => void
  onNextPage: () => void
}

const Pagination: FC<PaginationProps> = ({
  count,
  pageSize = 25,
  type,
  currPage,
  hasNext,
  hasPrev,
  onPrevPage,
  onNextPage,
}) => {
  const totalOnPage = count < pageSize ? count : pageSize

  const handlePrev = (): void => {
    if (hasPrev) {
      onPrevPage()
    }
  }

  const handleNext = (): void => {
    if (hasNext) {
      onNextPage()
    }
  }

  return (
    <Wrapper>
      {(count > 1 || currPage > 1) && (
        <>
          <Container>
            <span>
              Showing <DarkText>{totalOnPage}</DarkText> {type}
            </span>
          </Container>
          <Container>
            {hasPrev && (
              <PageButton type="button" data-qa="prev" onClick={handlePrev}>
                <Icon icon={ChevronLeft} color={COLOR.BLUE[800]} />
              </PageButton>
            )}
            {currPage >= 1 && (hasNext || hasPrev) && (
              <span>
                Page <DarkText>{currPage}</DarkText>
              </span>
            )}
            {hasNext && (
              <PageButton type="button" data-qa="next" onClick={handleNext}>
                <Icon icon={ChevronRight} color={COLOR.BLUE[800]} />
              </PageButton>
            )}
          </Container>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})

const Container = styled.p({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  fontSize: 14,
  color: COLOR.NEUTRAL[600],
})

const PageButton = styled.button({
  appearance: 'none',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
})

const DarkText = styled.span({
  color: COLOR.BLACK,
})
export { Pagination, PaginationProps, PaginationType }
