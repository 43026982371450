import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useHistory } from 'react-router-dom'
import type { RootState } from '../reducers'
import * as selectors from '../reducers/selectors'
import { users as userActions } from '../actions'
import type { UserProfileReducerState } from '../reducers/user/profile'
import { LDFlag } from '../constants/ld-flags'
import { getV3UserInfo } from '../lib/jwt'
import { getUserSelectedGrant, setAccessToken } from '../utils/local-storage'

const useUser = (): {
  user: UserProfileReducerState
  isLoaded: boolean
  isLoggedIn: boolean
} => {
  const history = useHistory()
  const dispatch = useDispatch()
  const savedSelectedGrant = getUserSelectedGrant()

  const flags = useFlags()
  const accessToken = useSelector((state: RootState) => selectors.getAccessToken(state))
  const user = useSelector((state: RootState) => selectors.getUserInformation(state))

  const isLoggedIn = useSelector((state: RootState) =>
    selectors.getIsLoggedIn(state, flags[LDFlag.OktaLogin]),
  )
  const [isLoaded, setIsLoaded] = useState(false)

  const isEmpty = Object.values(user).every((val) => val === null || val === '')

  useEffect(() => {
    if (!isLoggedIn) {
      setIsLoaded(true)
    }

    if (isEmpty && accessToken && isLoggedIn) {
      if (flags[LDFlag.OktaLogin]) {
        const v3UserInfo = getV3UserInfo(accessToken, savedSelectedGrant)

        setIsLoaded(false)
        if (v3UserInfo) {
          dispatch(userActions.getMeSuccess(v3UserInfo))
        } else {
          setAccessToken(null)
          setIsLoaded(true)
          history.push('/login')
        }
      } else {
        setIsLoaded(false)
        dispatch(userActions.getMe(accessToken))
      }
    } else {
      setIsLoaded(true)
    }
  }, [accessToken, dispatch, isEmpty, isLoggedIn, flags, user.role, savedSelectedGrant, history])

  return {
    user,
    isLoaded,
    isLoggedIn,
  }
}

export { useUser }
