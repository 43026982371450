import type { Action } from '../actions'
import { createBooleanReducer } from '../../factories'

const isUpdating = createBooleanReducer<Action>(
  ['CONTRACTS_UPDATE_REQUEST', 'CONTRACTS_UPDATE_REQUEST_V2'],
  [
    'CONTRACTS_UPDATE_SUCCESS',
    'CONTRACTS_UPDATE_FAILURE',
    'CONTRACTS_UPDATE_SUCCESS_V2',
    'CONTRACTS_UPDATE_FAILURE_V2',
  ],
)

export default isUpdating
