import type { FC } from 'react'
import React from 'react'
import { connect } from 'react-redux'
import { useLocation, withRouter } from 'react-router-dom'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import { querystring } from '@helloextend/client-utils'
import { COLOR } from '@helloextend/client-branding'
import { Button } from '@helloextend/merchants-ui'
import * as Yup from 'yup'
import { PasswordIndicator } from '../password-indicator'
import { auth } from '../../actions'
import Password from '../password'
import type { RootState } from '../../reducers'
import * as selectors from '../../reducers/selectors'
import { getParamsFromUrl } from '../../utils/get-params-from-url'

const schema = Yup.object()
  .shape({
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*=+_)(~`':;,./|<>{}[\]-])[a-zA-Z0-9#?!@$%^&*=+_)(~`':;,./|<>{}[\]-]{8,}$/,
      ),
    passwordConfirm: Yup.string()
      .required()
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value
      }),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

type QueryStringProps = {
  usage?: string
}

interface SP {
  isLoading: ReturnType<typeof selectors.getIsAuthLoading>
}

interface DP {
  resetPassword: typeof auth.resetPassword
}

type ResetPasswordFormProps = SP & DP

const Component: FC<ResetPasswordFormProps> = ({ isLoading, resetPassword }) => {
  const { touched, errors, values, handleChange, handleBlur, handleSubmit, dirty } = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validationSchema: schema,
    onSubmit: (vals: Values): void => {
      const { password } = vals
      const token = getParamsFromUrl('code')
      resetPassword(password, token ?? '')
    },
  })

  const { search } = useLocation<{ search: string }>()
  const { usage } = querystring.parse<QueryStringProps>(search)
  const headerText = usage === 'set' ? 'Set Password' : 'Create a new password'
  const buttonText = usage === 'set' ? 'Set my password' : 'Set new password'

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <Heading>
          <Header>{headerText}</Header>
        </Heading>
        <Password
          label="New password"
          name="password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
        />
        <PassIndicatorWrapper>
          <PasswordIndicator
            isVisible={Boolean((dirty && values.password) || touched.password)}
            word={values.password}
          />
        </PassIndicatorWrapper>
        <Password
          errorMessage="Password does not match"
          invalid={Boolean(errors.passwordConfirm && touched.passwordConfirm)}
          label="Confirm new password"
          name="passwordConfirm"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.passwordConfirm}
        />
        <ResetPasswordButton
          block
          size="xs"
          loading={isLoading}
          kind="primary"
          type="submit"
          dataQa="continue-button"
          text={buttonText}
        />
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  width: 335,
})

const Form = styled.form({
  margin: '0 auto',
})

const Heading = styled.header({
  textAlign: 'center',
})

const Header = styled.h2({
  fontSize: 20,
  fontWeight: 700,
  color: COLOR.VERY_DARK_BLUE_0,
  margin: '40px 0 32px 0',
})

const PassIndicatorWrapper = styled.div({
  display: 'block',
  height: 'auto',
  overflow: 'hidden',
  maxHeight: 350,
  opacity: 1,
})

const ResetPasswordButton = styled(Button)({
  width: '100%',
  marginTop: 24,
})

const ResetPasswordForm = withRouter(
  connect(
    (state: RootState) => ({
      isLoading: selectors.getIsAuthLoading(state),
    }),
    {
      resetPassword: auth.resetPassword,
    },
  )(Component),
)

export { Component, ResetPasswordForm }
