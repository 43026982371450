import type { SagaIterator } from 'redux-saga'
import { all, takeLatest } from 'redux-saga/effects'
import type { ActionType } from '../actions'
import create from './create'
import fetch from './fetch'
import search from './search'
import update from './update'

export default function* sagas(): SagaIterator {
  yield all([
    takeLatest('CLAIMS_CREATE' as ActionType, create),
    takeLatest('CLAIMS_FETCH' as ActionType, fetch),
    takeLatest('CLAIMS_SEARCH' as ActionType, search),
    takeLatest('CLAIMS_UPDATE' as ActionType, update),
  ])
}
