import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const claimPhotosApi = createApi({
  reducerPath: 'ClaimsPhotos',
  baseQuery: fetchBaseQuery({}),
  tagTypes: ['claim-photos'],
  endpoints: (build) => ({
    uploadClaimPhoto: build.mutation<void, { url: string; body: FormData }>({
      query: ({ url, body }) => ({
        url,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['claim-photos'],
    }),
  }),
})

export const { useUploadClaimPhotoMutation } = claimPhotosApi
