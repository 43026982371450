import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/client-utils/src/request'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.fetchByServiceOrderId>

export function* fetch(payload: { serviceOrderId: string; accessToken: string }): SagaIterator {
  const { serviceOrderId, accessToken } = payload
  yield put(actions.fetchServiceOrderExpensesRequest())

  try {
    const response: ResolvedType<typeof client.serviceOrders.fetchExpensesByServiceOrder> =
      yield call(client.serviceOrders.fetchExpensesByServiceOrder, serviceOrderId, accessToken)

    if (isErrorResponse(response)) {
      yield put(actions.fetchServiceOrderExpensesFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(
        actions.fetchServiceOrderExpensesFailure(`An unknown error occurred`, response.status),
      )
      return
    }
    yield put(actions.fetchServiceOrderExpensesSuccess(response.data))
  } catch (e) {
    yield put(actions.fetchServiceOrderExpensesFailure(e.message))
  }
}

export default function* fetchByServiceOrderId(action: Action): SagaIterator {
  yield call(fetch, action.payload)
}
