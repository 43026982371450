import type { FC } from 'react'
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Button, COLOR, InlineAlert, InlineAlertColor } from '@helloextend/zen'
import { Add, Trash, Info } from '@helloextend/zen/src/tokens/icons'
import { useSelector } from 'react-redux'
import {
  useServiceOrderApprovePaymentMutation,
  useCreateServiceOrderExpenseMutation,
  useServiceOrderRequestPaymentMutation,
  useVoidExpenseMutation,
} from '@helloextend/extend-api-rtk-query'
import { currency } from '@helloextend/client-utils'
import type {
  ServiceOrder,
  ServiceOrderExpensesCreateRequest,
  ServiceOrderStatus,
  ServiceType,
} from '@helloextend/extend-api-client'
import {
  Table,
  ColumnHead,
  TableHead,
  TableRow,
  TableFoot,
  TableBody,
  TableCell,
  CellText,
} from '../../../../../../../../components/table'
import { getTableColumns } from './table-config'
import { EmptyTableMessage } from './empty-table-message'
import { EstimateInfoBanner } from './estimate-info-banner'
import { AddExpenseForm } from './add-expense-form'
import { useServiceOrderExpenses } from '../../hooks/use-service-orders-expenses'
import type { RootState } from '../../../../../../../../reducers'
import { LDFlag } from '../../../../../../../../constants/ld-flags'
import * as selectors from '../../../../../../../../reducers/selectors'

interface ExpensesTabProps {
  serviceOrder?: ServiceOrder
}

const validateStatusAndRoleForAddingExpense = (
  status?: ServiceOrderStatus,
  serviceType?: ServiceType,
  role?: string,
): boolean => {
  if (status === 'payment_approved' && serviceType !== 'replace') return false
  if (status === 'payment_requested' && role !== 'admin' && role !== 'superadmin') return false
  return true
}

const ExpensesTab: FC<ExpensesTabProps> = ({ serviceOrder }) => {
  const {
    [LDFlag.ClaimsPayment]: FF_CLAIMS_PAYMENT_ENABLED,
    [LDFlag.ExpandedServiceOrdersSection]: FF_EXPANDED_SERVICE_ORDER_SECTION,
  } = useFlags()

  const tableColumns = getTableColumns({ isClaimsPaymentEnabled: FF_CLAIMS_PAYMENT_ENABLED })

  const userRole = useSelector((state: RootState) => selectors.getRoleFromToken(state)) ?? ''
  const [canDeleteExpense, setCanDeleteExpense] = useState(
    serviceOrder?.status !== 'payment_approved',
  )
  const { expenses, expenseTotal } = useServiceOrderExpenses(serviceOrder?.id)
  const [requestPayment, { isLoading: isLoadingRequestPayment }] =
    useServiceOrderRequestPaymentMutation()
  const [voidExpense] = useVoidExpenseMutation()
  const [
    createExpense,
    { isLoading: isLoadingCreate, isSuccess: isSuccessCreate, isError: isErrorCreate },
  ] = useCreateServiceOrderExpenseMutation()
  const [approvePayment, { isLoading: isLoadingApprovePayment }] =
    useServiceOrderApprovePaymentMutation()
  const [isAddExpenseFormVisible, setIsAddExpenseFormVisible] = useState(false)

  const toggleAddExpenseForm = (): void => {
    setIsAddExpenseFormVisible(!isAddExpenseFormVisible)
  }

  const handleAddExpenseFormClose = (body?: ServiceOrderExpensesCreateRequest): void => {
    if (serviceOrder && body) {
      createExpense({ serviceOrderId: serviceOrder.id, body })
    }
    toggleAddExpenseForm()
  }

  const handleVoidExpense = (expenseId: string): void => {
    if (serviceOrder) {
      voidExpense({ serviceOrderId: serviceOrder.id, expenseId })
    }
  }

  const canAddExpense = validateStatusAndRoleForAddingExpense(
    serviceOrder?.status,
    serviceOrder?.serviceType,
    userRole,
  )
  const canApprovePayment =
    serviceOrder?.status === 'payment_requested' &&
    (userRole === 'admin' || userRole === 'superadmin')
  const canRequestPayment = serviceOrder?.status === 'fulfilled'

  useEffect(() => {
    if (isLoadingCreate || !canAddExpense) {
      setCanDeleteExpense(false)
    } else if (isSuccessCreate || isErrorCreate) {
      setCanDeleteExpense(true)
    }
  }, [isLoadingCreate, isSuccessCreate, isErrorCreate, canAddExpense])

  return (
    <ContentWrapper>
      <AlertsContainer>
        <InlineAlert
          color={InlineAlertColor.blue}
          icon={Info}
          data-cy="service-order-expenses-alert"
        >
          <BoldText>Expenses Total: {currency.format(expenseTotal)}</BoldText>
          <Text>
            Update expenses before requesting payment. Expenses cannot be changed once you Request
            Payment.
          </Text>
        </InlineAlert>
      </AlertsContainer>
      <SectionWrapper>
        {
          // header should be removed when FF is removed
          !FF_EXPANDED_SERVICE_ORDER_SECTION && (
            <Header>
              <Title>Expenses</Title>
            </Header>
          )
        }
        {serviceOrder && (
          <EstimateInfoBanner serviceOrder={serviceOrder} expenseTotal={expenseTotal || 0} />
        )}
        <Table data-cy="expenses-table">
          <TableHead>
            {tableColumns.map((column) => (
              <ColumnHead
                key={`head-${column.accessor}`}
                columnWidth={column.columnWidth ?? 100}
                active={false}
                textAlign={column.align ?? 'left'}
              >
                {column.Header}
              </ColumnHead>
            ))}
            <ColumnHead columnWidth={7} active={false} />
          </TableHead>
          <TableBody>
            {(!expenses || !expenses.length) && <EmptyTableMessage cols={tableColumns.length} />}
            {expenses &&
              expenses
                .sort((a, b) => a.createdAt - b.createdAt)
                .map((row) => (
                  <TableRow data-cy={row.id} key={row.id as string}>
                    {tableColumns.map((column) => {
                      const cell = row[column.accessor as keyof typeof row]
                      const element = column.Cell?.(row) ?? <CellText>{`${cell ?? '--'}`}</CellText>

                      return (
                        <TableCell
                          columnWidth={column.columnWidth ?? 100}
                          key={column.accessor}
                          data-cy={`${column.accessor}-cell`}
                        >
                          {element}
                        </TableCell>
                      )
                    })}
                    <TableCell align="center">
                      {canDeleteExpense && (
                        <Button
                          data-cy={`void-expense-button-${row.id}`}
                          icon={Trash}
                          size="small"
                          onClick={() => handleVoidExpense(row.id)}
                          emphasis="low"
                          color="neutral"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
          <TableFoot>
            <tr>
              <TableCell colspan={4}>
                <CellText>Total</CellText>
              </TableCell>
              <TableCell align="right" data-cy="expenses-total-cell">
                {currency.format(expenseTotal).slice(1)}
              </TableCell>
            </tr>
          </TableFoot>
        </Table>
        <ExpenseFormWrapper>
          {canAddExpense && isAddExpenseFormVisible && (
            <AddExpenseForm
              handleClose={handleAddExpenseFormClose}
              isLoading={isLoadingCreate}
              isSuccess={isSuccessCreate}
              serviceType={serviceOrder?.serviceType}
              status={serviceOrder?.status}
            />
          )}
        </ExpenseFormWrapper>
        <ButtonWrapper>
          {canAddExpense && !isAddExpenseFormVisible && (
            <Button
              data-cy="add-expense-button"
              text="Add Expense"
              icon={Add}
              iconPosition="left"
              onClick={toggleAddExpenseForm}
              emphasis="low"
            />
          )}
          {canRequestPayment && (
            <Button
              data-cy="request-payment-button"
              text="Request Payment"
              emphasis="high"
              onClick={() => requestPayment(serviceOrder?.id || '')}
              isProcessing={isLoadingRequestPayment}
              isDisabled={isLoadingRequestPayment || (expenses && expenses.length === 0)}
            />
          )}
          {canApprovePayment && (
            <Button
              data-cy="approve-payment-button"
              text="Approve Payment"
              emphasis="high"
              isProcessing={isLoadingApprovePayment}
              isDisabled={isLoadingApprovePayment}
              onClick={() => approvePayment(serviceOrder?.id || '')}
            />
          )}
        </ButtonWrapper>
      </SectionWrapper>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div({})

const ExpenseFormWrapper = styled.div({
  marginTop: 12,
  marginBottom: 12,
})

const SectionWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  marginBottom: 32,
  padding: 40,
})

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  marginBottom: 16,
})

const Title = styled.h2({
  fontSize: 24,
  lineHeight: '32px',
  color: COLOR.NEUTRAL[1000],
  margin: 0,
  marginRight: 12,
})

const ButtonWrapper = styled.div({
  marginTop: 26,
  display: 'flex',
  gap: 16,
})

const AlertsContainer = styled.div({
  marginBottom: 16,
})

const Text = styled.p({
  fontSize: 14,
  margin: 0,
  fontWeight: 400,
})

const BoldText = styled(Text)({
  fontWeight: 700,
})

export { ExpensesTab }
