import type { FC } from 'react'
import React from 'react'
import type { Store } from '@helloextend/extend-api-client'
import { SectionContainer } from '../../../../../../../../components/section-container'
import { ProductProtectionForm } from '../product-protection-form'

type ProductProtectionProps = {
  store: Store
}

const ProductProtection: FC<ProductProtectionProps> = ({ store }) => {
  return (
    <SectionContainer title="Product Protection">
      <ProductProtectionForm store={store} />
    </SectionContainer>
  )
}

export { ProductProtection }
