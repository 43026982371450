import { startCase } from 'lodash/fp'
import { currency, date } from '@helloextend/client-utils'
import type {
  Money,
  ShippingTracking,
  Order,
  LineItem,
  LineItemShipments,
  LineItemContract,
  LineItemNonWarrantable,
  LineItemLead,
} from '@helloextend/extend-api-client'

type OrderLineItemProduct = LineItemContract | LineItemNonWarrantable | LineItemLead

const formatStartCase = (value?: string): string => startCase(value ?? '')
const formatDate = (value?: number): string => {
  return (typeof value === 'number' && date.format(value, 'MMM DD YYYY')) || ''
}
const formatClaimType = (value?: string): string => {
  return value === 'shipping_protection' ? 'Shipping Protection' : 'Extended Warranty'
}
const formatCurrency = (value?: Money): string => {
  if (!value) return '—'
  return currency.format(value.amount, value.currencyCode)
}
const formatDateTime = (value?: number, omitTime?: boolean): string => {
  const format = omitTime ? 'MM/DD/YYYY' : 'MM/DD/YYYY hh:mm:ss A'
  return typeof value === 'number' ? date.format(value, format) : ''
}
const isShippingProtectionType = (value?: string): boolean => {
  return value === 'shipping_protection'
}
const formatShippingStatus = (tracking: ShippingTracking): string => {
  const [event] = tracking.events
  const status = event.status !== 'unknown' ? event.status : tracking.status
  const occurredAt = formatDate(event.occurredAt)
  return `${status}: ${occurredAt}`
}

const getShipmentLineItem = (order: Order): LineItemShipments | null => {
  const shipmentsLineItems = order.lineItems.filter((lineItem) => lineItem.type === 'shipments')
  if (shipmentsLineItems.length) {
    const [shipmentLineItem] = shipmentsLineItems as LineItemShipments[]
    return shipmentLineItem
  }
  return null
}

const getShippingProtectionProducts = (
  lineItems: LineItem[],
  lineItemIds?: string[],
): OrderLineItemProduct[] => {
  return lineItems.filter(
    (lineItem) => lineItemIds?.includes(lineItem.id) && lineItem.type !== 'shipments',
  ) as OrderLineItemProduct[]
}

const getTotalCost = (order?: Order): number | null => {
  if (!order) return null
  const shippingCostTaxTotal = (order.shippingCostTotal ?? 0) + (order.taxCostTotal ?? 0)
  const shipmentLineItem = getShipmentLineItem(order)
  const shippingProtectionProducts = getShippingProtectionProducts(
    order.lineItems,
    shipmentLineItem?.lineItemIds,
  )

  const shippingProtectionProductsCost = shippingProtectionProducts.reduce((cost, item) => {
    let currentCost = item.product?.purchasePrice ?? 0
    if ((item as LineItemContract).plan) {
      const contractProduct = item as LineItemContract
      currentCost += contractProduct.plan.purchasePrice ?? 0
    }

    return cost + currentCost
  }, 0)

  return shippingProtectionProductsCost + shippingCostTaxTotal
}

export {
  formatDate,
  formatStartCase,
  formatCurrency,
  formatDateTime,
  formatShippingStatus,
  getShippingProtectionProducts,
  getTotalCost,
  isShippingProtectionType,
  formatClaimType,
}
