import type { ChangeEvent, FC } from 'react'
import React, { useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import type { AdvancedSelectChangeEvent, AdvancedSelectOption } from '@helloextend/zen'
import { Button, AdvancedSelect, COLOR, Input } from '@helloextend/zen'
import { v4 as uuid } from 'uuid'
import { Close, Add } from '@helloextend/zen/src/tokens/icons'
import type { PromptType, ScriptItem, DefaultReply } from '@helloextend/extend-api-rtk-query'
import { useDispatch, useSelector } from 'react-redux'
import type { AdvancedSelectOptionGroup } from '@helloextend/zen/src/components/fields/advanced-select/advanced-select-types'
import type { RootState } from '../../../../../../reducers'
import * as selectors from '../../../../../../reducers/selectors'
import {
  addReplyPromptOption,
  removeReplyPromptOption,
  updateCustomerResponseTitle,
  updateJumpToValue,
  updateReportingValue,
} from '../../../../../../store/slices/amp-slice'
import { getPromptOptionValue, isUUID, ThreadConnectorDropdownValues } from '../../../utils'
import { reportingValueMap } from '../../../reporting-value-map'

type ResponseBlockProps = {
  type: PromptType
  title: string
  choiceText?: string
  selectorValue: string
  selectorOptions: AdvancedSelectOption[]
  index: number
  isThreadStructureLocked: boolean
  isRemoveDisabled: boolean
  addKey: (index: number) => void
  removeKey: (index: number) => void
  scriptItem: ScriptItem
}

const ResponseBlock: FC<ResponseBlockProps> = ({
  type,
  title,
  choiceText,
  selectorValue,
  selectorOptions,
  index,
  isThreadStructureLocked,
  isRemoveDisabled,
  addKey,
  removeKey,
  scriptItem,
}) => {
  const isMultiChoice = type === 'buttons' || type === 'multiselect'
  const { reply } = scriptItem
  const slot = (reply as DefaultReply)?.prompt?.slot
  const promptOptionValue = (reply as DefaultReply)?.prompt?.options?.[index]?.value
  const reportingValue = promptOptionValue && !isUUID(promptOptionValue) ? promptOptionValue : ''

  const dispatch = useDispatch()

  const isPublishValidationModeActive = useSelector((state: RootState) =>
    selectors.getIsPublishValidationModeActive(state),
  )

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    dispatch(updateCustomerResponseTitle({ index, text: e.target.value }))
  }

  const onPromptOptionRemove = (_index: number): void => {
    dispatch(removeReplyPromptOption(_index))
    removeKey(_index)
  }

  const onPromptOptionAdd = (_index: number): void => {
    dispatch(addReplyPromptOption(_index))
    addKey(_index + 1)
  }

  const handleJumpToSelect = (e: AdvancedSelectChangeEvent): void => {
    const { value } = e.target
    if (value === selectorValue) return
    const pattern = getPromptOptionValue(reply, index)
    dispatch(updateJumpToValue({ routingValue: value, pattern }))
  }

  const handleReportingValueSelect = (e: AdvancedSelectChangeEvent): void => {
    if (!promptOptionValue) return
    if (e.target.value === '') {
      dispatch(updateReportingValue({ index, oldValue: promptOptionValue, newValue: uuid() }))
    } else {
      dispatch(
        updateReportingValue({ index, oldValue: promptOptionValue, newValue: e.target.value }),
      )
    }
  }

  const hasError = useMemo(() => {
    return choiceText?.trim().length === 0
  }, [choiceText])

  const hasDropdownError = useMemo(() => {
    return isPublishValidationModeActive && selectorValue.trim().length === 0
  }, [isPublishValidationModeActive, selectorValue])

  const isReportingValueEnabled = useMemo(() => {
    return Boolean(
      slot && slot !== ThreadConnectorDropdownValues.ProductSection && reportingValueMap[slot],
    )
  }, [slot])

  const hasReportingValueError = useMemo(() => {
    return isPublishValidationModeActive && isReportingValueEnabled && !reportingValue
  }, [isPublishValidationModeActive, isReportingValueEnabled, reportingValue])
  function isAdvancedSelectOptionGroup(
    option: AdvancedSelectOptionGroup | AdvancedSelectOption,
  ): option is AdvancedSelectOptionGroup {
    return (option as AdvancedSelectOptionGroup).label !== undefined
  }

  const getCurrentOptionGroup = useCallback((): AdvancedSelectOption | null => {
    if (!slot) return null
    let currentOption = null

    reportingValueMap[slot].forEach((option: AdvancedSelectOption | AdvancedSelectOptionGroup) => {
      if (isAdvancedSelectOptionGroup(option)) {
        const groupOption = option.options.find(
          ({ value }: AdvancedSelectOption) => value === reportingValue,
        )
        if (groupOption) {
          currentOption = groupOption
        }
      }
    })

    return currentOption
  }, [reportingValue, slot])

  const filteredReportingValueOptions = useMemo(() => {
    if (!isReportingValueEnabled || !slot) return []
    const currentOption = reportingValueMap[slot].find((option) => {
      return !isAdvancedSelectOptionGroup(option) ? option.value === reportingValue : false
    })
    const currentOptionGroup = getCurrentOptionGroup()

    const options = (reply as DefaultReply)?.prompt?.options?.map(({ value }) => ({ value }))
    if (reportingValueMap[slot].every((option) => isAdvancedSelectOptionGroup(option))) {
      const availableOptions = reportingValueMap[slot].map((option) => {
        if (isAdvancedSelectOptionGroup(option)) {
          const availableGroupOptions = option.options.filter(
            ({ value: groupedOptionValue }: AdvancedSelectOption) => {
              return !options
                ?.map(({ value: promptOption }) => promptOption)
                .includes(groupedOptionValue)
            },
          )
          return { ...option, options: availableGroupOptions }
        }
        return option
      })

      // Ensure currently selected option is added to the group
      if (currentOptionGroup) {
        // Get the index of the group
        const currentOptionGroupIndex = reportingValueMap[slot].findIndex(
          (option) =>
            isAdvancedSelectOptionGroup(option) && option.options.includes(currentOptionGroup),
        )
        // Get the label of the group
        const currentOptionGroupLabel = (
          reportingValueMap[slot][currentOptionGroupIndex] as AdvancedSelectOptionGroup
        ).label

        // Add the current option to the correct group
        if (
          (availableOptions[currentOptionGroupIndex] as AdvancedSelectOptionGroup).label ===
          currentOptionGroupLabel
        ) {
          ;(availableOptions[currentOptionGroupIndex] as AdvancedSelectOptionGroup).options.unshift(
            currentOptionGroup,
          )
        }
      }

      return availableOptions
    }

    const availableOptions = reportingValueMap[slot].filter(
      (option) =>
        !isAdvancedSelectOptionGroup(option) &&
        !options?.some(({ value }) => value === option.value),
    )

    // Ensure currently selected option is aways shown
    return currentOption ? [currentOption, ...availableOptions] : availableOptions
  }, [getCurrentOptionGroup, isReportingValueEnabled, reply, reportingValue, slot])

  const hasReusableReportingValues = slot === 'FailureType' || slot === 'FailureCause'

  return (
    <Container data-cy={`response-block-${index}`}>
      <BlockContainer>
        <HeaderContainer>
          <Title>{title}</Title>
          {isMultiChoice && (
            <Button
              data-cy="customer-response-remove-choice"
              emphasis="low"
              icon={Close}
              color="neutral"
              isDisabled={isRemoveDisabled || isThreadStructureLocked}
              onClick={() => onPromptOptionRemove(index)}
            />
          )}
        </HeaderContainer>
        <InputsContainer isMultiChoice={isMultiChoice}>
          {isMultiChoice && (
            <Input
              data-cy={`choice-text-${index}`}
              id="choiceText"
              label="Text"
              value={choiceText ?? ''}
              onChange={handleChange}
              isError={hasError}
              errorFeedback="Text is required"
            />
          )}
          {slot && isReportingValueEnabled && (
            <AdvancedSelect
              data-cy={`choice-reporting-value-selector-${index}`}
              id="reportingSelectorValue"
              label={`${slot} Reporting Value`}
              value={reportingValue}
              onChange={handleReportingValueSelect}
              isError={hasReportingValueError}
              errorFeedback="Reporting Value is required"
              multiple={false}
              options={
                hasReusableReportingValues ? reportingValueMap[slot] : filteredReportingValueOptions
              }
              maxQuantityToDisplay={8}
              badgePosition="start"
              isDisabled={isThreadStructureLocked}
              placeholder="Select"
              showSearch
            />
          )}
          <AdvancedSelect
            data-cy={`choice-selector-${index}`}
            id="selectorValue"
            label="Jump To"
            value={selectorValue}
            onChange={handleJumpToSelect}
            isError={hasDropdownError}
            errorFeedback="Jump To is required"
            multiple={false}
            options={selectorOptions}
            maxQuantityToDisplay={8}
            badgePosition="start"
            isDisabled={isThreadStructureLocked}
            placeholder="Select"
            isNotClearable
          />
        </InputsContainer>
      </BlockContainer>
      {isMultiChoice && (
        <div>
          <Button
            emphasis="low"
            color="neutral"
            data-cy="customer-response-add-choice"
            icon={Add}
            onClick={() => onPromptOptionAdd(index)}
            isDisabled={isThreadStructureLocked}
          />
        </div>
      )}
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
})

const BlockContainer = styled.div({
  backgroundColor: COLOR.NEUTRAL[100],
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
  gap: 8,
})

const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

const Title = styled.p({
  fontWeight: 800,
  fontSize: 14,
  lineHeight: '18px',
})

const InputsContainer = styled.div<{ isMultiChoice: boolean }>(({ isMultiChoice }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  width: isMultiChoice ? 376 : 424,
}))

export { ResponseBlock, ResponseBlockProps }
