import type { FC } from 'react'
import React from 'react'
import { ContractContainer } from '../contract/components/contract-container'
import { ClaimsTable } from './claims-table'

const ContractClaims: FC = () => {
  return (
    <ContractContainer>
      <ClaimsTable />
    </ContractContainer>
  )
}

export { ContractClaims }
