type Validator = Record<string, RegExp>

const RegExpValidator: Validator = {
  // check for non-empty fields for now
  username: RegExp(/^(?=[a-zA-Z0-9]).{1,}$/),
  // check for at 1 special character #?!@$%^&*=+_)(-~`{}[]<>|:;',./, 1 number, and at least 8 characters
  password: RegExp(
    /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*=+_)(~`':;,./|<>{}[\]-])[a-zA-Z0-9#?!@$%^&*=+_)(~`':;,./|<>{}[\]-]{8,}$/,
  ),
}

const PasswordIncludesValidator: Validator = {
  valid: RegExp(
    /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*=+_)(~`':;,./|<>{}[\]-])[a-zA-Z0-9#?!@$%^&*=+_)(~`':;,./|<>{}[\]-]{8,}$/,
  ),
  length: RegExp(/^(.){8,}$/),
  letter: RegExp(/[a-zA-Z]+/),
  number: RegExp(/[0-9]+/),
  symbol: RegExp(/[#?!@$%^&*=+_)(~`':;,./|<>{}[\]-]+/),
  invalid: RegExp(/["\\]/),
}

export const validatePassword = (password: string, criteria: string): boolean => {
  const combinatorTest = (word: string): boolean => {
    return (
      ['LETTER', 'SYMBOL', 'NUMBER'].reduce((total, test) => {
        if (validatePassword(word, test)) {
          return total + 1
        }
        return total
      }, 0) === 3
    )
  }

  const { length, letter, number, symbol, valid, invalid } = PasswordIncludesValidator

  if (!password) return false

  switch (criteria) {
    case 'LENGTH':
      return length.test(password)
    case 'STRENGTH':
      return combinatorTest(password)
    case 'LETTER':
      return letter.test(password)
    case 'NUMBER':
      return number.test(password)
    case 'SYMBOL':
      return symbol.test(password)
    case 'VALID':
      return valid.test(password)
    case 'INVALID':
      return invalid.test(password)
    default:
      return false
  }
}

export const validateField = (name: string, value = ''): boolean => {
  const validator = RegExpValidator[name] || RegExp(/^.+$/)
  return validator.test(value)
}
