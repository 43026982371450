// This file is derived from react-colorful
// https://github.com/omgovich/react-colorful/blob/a85e5b36b55cae7e95c73c8ecde0bc881e8e3b1f/src/components/common/Saturation.tsx

import type { FC } from 'react'
import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import type { Interaction } from './interactive'
import { Interactive } from './interactive'
import { Pointer } from './pointer'
import type { HsvColor } from '../../../../utils/colors'
import { hsvToRgbString } from '../../../../utils/colors'
import { round } from '../../../../utils/math'

interface SaturationProps {
  hsv: HsvColor
  onChange: (newColor: { s: number; v: number }) => void
  onMouseUp?: () => void
  onMouseDown?: () => void
}

const SaturationBase: FC<SaturationProps> = ({ hsv, onChange, onMouseDown, onMouseUp }) => {
  const handleMove = useCallback(
    (interaction: Interaction): void => {
      onChange({
        s: interaction.left * 100,
        v: 100 - interaction.top * 100,
      })
    },
    [onChange],
  )

  return (
    <Container hue={hsv.h}>
      <Interactive
        onMove={handleMove}
        aria-label="Color"
        aria-valuetext={`Saturation ${round(hsv.s)}%, Brightness ${round(hsv.v)}%`}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
      >
        <Pointer top={1 - hsv.v / 100} left={hsv.s / 100} color={hsvToRgbString(hsv)} />
      </Interactive>
    </Container>
  )
}

const Container = styled.div<{ hue: number }>(({ hue }) => ({
  height: 187,
  backgroundColor: `hsl(${hue}, 100%, 50%)`,
  position: 'relative',
  borderRadius: 4,
  backgroundImage: `linear-gradient(to top, #000, rgba(0, 0, 0, 0)),
    linear-gradient(to right, #fff, rgba(255, 255, 255, 0))`,
}))

const Saturation = React.memo(SaturationBase)
export { Saturation }
