import type { PlanSearch } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const search = (accessToken: string, filter: string, cursor?: string, limit = 25) =>
  ({
    type: 'PLANS_SEARCH',
    payload: {
      accessToken,
      filter,
      limit,
      cursor,
    },
  } as const)

export const nextPage = (accessToken: string, searchValue: string, cursor?: string, limit = 25) =>
  ({
    type: 'PLANS_SEARCH_NEXT_PAGE',
    payload: { accessToken, limit, searchValue, cursor },
  } as const)

export const incrementNextPage = () =>
  ({
    type: 'PLANS_INCREMENT_NEXT_PAGE',
  } as const)

export const prevPage = () =>
  ({
    type: 'PLANS_SEARCH_PREV_PAGE',
  } as const)

export const searchRequest = () =>
  ({
    type: 'PLANS_SEARCH_REQUEST',
  } as const)

export const searchSuccess = (plans: PlanSearch[], nextPageCursor?: string) =>
  ({
    type: 'PLANS_SEARCH_SUCCESS',
    payload: {
      plans,
      nextPageCursor,
    },
  } as const)

export const searchFailure = createActionFailure('PLANS_SEARCH_FAILURE')
