import { useCallback } from 'react'
import { useGetAccessTokenMutation } from '@helloextend/extend-api-rtk-query'
import { useDispatch } from 'react-redux'
import { authActions } from '@helloextend/core-api-redux'
import { useHistory } from 'react-router'
import { isEmpty } from 'lodash/fp'
import { getV3UserInfo } from '../lib/jwt'
import { users as userActions } from '../actions'
import { getUserSelectedGrant, saveUserSelectedGrant } from '../utils/local-storage'

type ExchangeTokenProps = {
  accessToken: string
  grant?: {
    ern: string
    role: string
  }
}

const useOktaExchangeToken = (): {
  exchangeToken: (props: ExchangeTokenProps) => Promise<void>
  isLoading: boolean
} => {
  const [getExtendToken, { isLoading }] = useGetAccessTokenMutation()
  const dispatch = useDispatch()
  const history = useHistory()

  const exchangeToken = useCallback(
    async (props: ExchangeTokenProps): Promise<void> => {
      const savedSelectedGrant = getUserSelectedGrant()
      if (isEmpty(savedSelectedGrant) && isEmpty(props.grant)) {
        history.push('/role-selector')
        return
      }

      const grant = props.grant ?? savedSelectedGrant

      const accessTokenResponse = await getExtendToken({
        subject_token: props.accessToken,
        subject_token_type: 'urn:ietf:params:oauth:token-type:access_token',
        grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
        ern: grant?.ern,
        role: grant?.role,
        extend_token_ver: 'V2',
      })

      if ('error' in accessTokenResponse) {
        saveUserSelectedGrant(null)
        throw new Error('error while getting access token')
      }

      if (!accessTokenResponse.data?.access_token) throw new Error('No token was returned')

      dispatch(authActions.loginSuccess(accessTokenResponse.data.access_token))
      const v3UserInfo = getV3UserInfo(accessTokenResponse.data.access_token, grant)

      if (v3UserInfo) dispatch(userActions.getMeSuccess(v3UserInfo))

      if (props.grant) {
        saveUserSelectedGrant(props.grant)
      }

      history.push('/')
    },
    [getExtendToken, dispatch, history],
  )

  return { exchangeToken, isLoading }
}

export { useOktaExchangeToken }
