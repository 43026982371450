import { SHOPINT2_HOST } from '@helloextend/client-constants'
import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { destroy as fetchDestroy, get, post, put } from '@helloextend/extend-api-fetch'
import type {
  AccessToken,
  Store,
  User,
  StoreUser,
  ContractSearchKey,
  ContractsGetResponse,
  Claim,
  ClaimSearchKey,
  StoreIntegrationSettings,
} from '../models'

export async function fetch(
  storeId: string,
  accessToken: AccessToken,
  includeDeleted = false,
): Promise<ApiResponse<Store>> {
  return get(`/stores/${storeId}?includeDeleted=${includeDeleted}`, {
    accessToken,
    apiVersion: '2020-08-01',
  })
}

export async function create(body: any, accessToken: AccessToken): Promise<ApiResponse<Store>> {
  return post(`/stores`, { accessToken, body })
}

export async function fetchAll(accessToken: AccessToken): Promise<ApiResponse<Store[]>> {
  return get('/stores', { accessToken })
}

export async function getUsers(accessToken: AccessToken): Promise<ApiResponse<User[]>> {
  return get('/auth/users', { accessToken })
}

export async function getUserById(
  userId: string,
  accessToken: AccessToken,
): Promise<ApiResponse<StoreUser>> {
  return get(`/auth/users/${userId}`, { accessToken })
}

export async function getStoreUsers(
  accountId: string,
  accessToken: AccessToken,
): Promise<ApiResponse<StoreUser[]>> {
  return get(`/auth/accounts/${accountId}/users`, { accessToken })
}

export async function update(
  storeId: string,
  body: DeepPartial<Store>,
  accessToken: AccessToken,
): Promise<ApiResponse<Store>> {
  return put(`/stores/${storeId}`, { body, accessToken, apiVersion: 'latest' })
}

export async function deleteLogo(storeId: string, accessToken: string): Promise<ApiResponse<void>> {
  return fetchDestroy(`/stores/${storeId}/logo`, { accessToken })
}

export async function updateLogo(
  storeId: string,
  body: string,
  accessToken: AccessToken,
): Promise<ApiResponse<string>> {
  return put(`/stores/${storeId}/logo`, {
    body,
    accessToken,
    dataType: 'text',
  })
}

export async function destroy(
  storeId: string,
  accessToken: AccessToken,
): Promise<ApiResponse<void>> {
  return fetchDestroy(`/stores/${storeId}`, { accessToken })
}

// leads URL-generation request
export async function createLeadsImportUrl(
  storeId: string,
  originalFilename: string,
  accessToken: string,
): Promise<ApiResponse<{ url: string }>> {
  return post(`/stores/${storeId}/leads/upload`, { accessToken, qs: { originalFilename } })
}

// uploading a file to S3 URL
export async function uploadToS3Url(url: string, file: ArrayBuffer): Promise<ApiResponse> {
  return put(url, { body: file, dataType: 'text/csv' })
}

// store-contracts search
export async function searchContracts({
  accessToken,
  storeId,
  searchKey,
  value,
}: {
  accessToken: string
  storeId: string
  searchKey: ContractSearchKey
  value: string
}): Promise<ApiResponse<ContractsGetResponse[]>> {
  return get(`/stores/${storeId}/contracts/search`, {
    accessToken,
    apiVersion: '2021-01-01',
    qs: { [searchKey]: value },
  })
}

export async function searchContractClaims({
  accessToken,
  storeId,
  searchKey,
  value,
}: {
  accessToken: string
  storeId: string
  searchKey: ClaimSearchKey
  value: string
}): Promise<ApiResponse<Claim[]>> {
  return get(`/stores/${storeId}/contracts/claims/search`, {
    accessToken,
    apiVersion: '2021-01-01',
    qs: { [searchKey]: value },
  })
}

export async function fetchShopifyIntegrationUrl(
  shopUrl: string,
  accessToken: string,
): Promise<ApiResponse<{ url: string }>> {
  return get(`https://${SHOPINT2_HOST}/auth/start?shop=${shopUrl}`, { accessToken })
}

// Store Integration Settings
export async function fetchStoreIntegrationSettings(
  storeId: string,
  accessToken: AccessToken,
): Promise<ApiResponse<StoreIntegrationSettings>> {
  return get(`/stores/${storeId}/integration-config`, { accessToken })
}

export async function updateStoreIntegrationSettings(
  storeId: string,
  accessToken: AccessToken,
  body: StoreIntegrationSettings,
): Promise<ApiResponse<StoreIntegrationSettings>> {
  return put(`/stores/${storeId}/integration-config`, { accessToken, body })
}
