import type { FC } from 'react'
import React, { useState } from 'react'
import type { Claim } from '@helloextend/extend-api-client'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/zen'
import { Notes } from '@helloextend/zen/src/tokens/icons'
import { useGetClaimNotesQuery } from '@helloextend/extend-api-rtk-query'
import { OverlayButton } from '../../../../../../components/overlay-button'
import {
  BasicModal,
  ModalPosition,
  ModalBackground,
} from '../../../../../../components/basic-modal'
import { ClaimNotesForm } from './claim-notes-form'
import { ClaimNotesEntry } from './claim-notes-entry'

interface ClaimNotesProps {
  claim: Claim
}

const ClaimNotes: FC<ClaimNotesProps> = ({ claim }) => {
  const [claimFormIsVisible, setClaimFormIsVisible] = useState(false)
  const { claimNotes } = useGetClaimNotesQuery(
    { claimId: claim.id },
    {
      selectFromResult: ({ data }) => ({
        claimNotes: [...(data?.items || [])].sort((a, b) => b.createdAt - a.createdAt),
      }),
    },
  )

  if (!claimFormIsVisible) {
    return (
      <OverlayButton
        data-cy="claim-notes-button"
        text="Claim Notes"
        size="large"
        icon={Notes}
        onClick={() => {
          setClaimFormIsVisible(true)
        }}
      />
    )
  }

  return (
    <BasicModal
      isVisible={claimFormIsVisible}
      onClickClose={() => {
        setClaimFormIsVisible(false)
      }}
      width="574px"
      preventAutoFocus
      data-cy="claim-notes"
      maxHeight="650px"
      modalPosition={ModalPosition.bottomRight}
      modalBackground={ModalBackground.boxShadow}
      excludeBackground
    >
      <Title data-cy="claim-notes-title">{`${claim.customer?.name}'s Claim Notes`}</Title>
      <ClaimNotesForm claimId={claim.id} />
      <ClaimNotesCollection>
        {claimNotes?.map((note) => (
          <ClaimNotesEntry claimNote={note} key={note.id} />
        ))}
      </ClaimNotesCollection>
    </BasicModal>
  )
}

const ClaimNotesCollection = styled.div({
  marginTop: 8,
})

const Title = styled.h3({
  color: COLOR.BLACK,
  fontSize: 20,
  marginBottom: 16,
  marginTop: 0,
})

export { ClaimNotes }
