import { createActionFailure } from '@helloextend/core-api-redux/src/factories'
import type { AuthForgotPasswordResponse } from '@helloextend/extend-api-client'

export const resendInvite = (email: string) =>
  ({
    type: 'RESEND_INVITE_EMAIL',
    payload: { email },
  } as const)

export const resendInviteRequest = () =>
  ({
    type: 'RESEND_INVITE_EMAIL_REQUEST',
  } as const)

export const resendInviteSuccess = (response: AuthForgotPasswordResponse) =>
  ({
    type: 'RESEND_INVITE_EMAIL_SUCCESS',
    payload: response,
  } as const)

export const resendInviteFailure = createActionFailure('RESEND_INVITE_EMAIL_FAILURE')

export const resendInviteFlowReset = () =>
  ({
    type: 'RESEND_INVITE_EMAIL_FLOW_RESET',
  } as const)
