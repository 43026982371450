import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { ArrowForward } from '@helloextend/zen/src/tokens/icons'
import { Badge, COLOR, Icon, IconSize } from '@helloextend/zen'

interface PromptWrapperProps {
  badgeValue: number | string
  hasBottomBorder?: boolean
  onBadgeClick: (val: number | string) => void
  promptIndex?: number
}

const AdjudicationPromptWrapper: FC<PromptWrapperProps> = ({
  children,
  badgeValue,
  hasBottomBorder,
  onBadgeClick,
  promptIndex,
}) => {
  const isEmptyPrompt = badgeValue === '-'
  const badgeEmphasis = typeof badgeValue === 'number' || isEmptyPrompt ? 'medium' : 'high'

  const onClick = (): void => {
    if (!isEmptyPrompt) {
      onBadgeClick(badgeValue)
    }
  }

  return (
    <PromptWrapper
      hasBottomBorder={hasBottomBorder}
      data-cy={`adjudication-management-prompt-wrapper-${promptIndex || 0}`}
    >
      {children}
      <ArrowBadgeWrapper>
        <Icon
          icon={ArrowForward}
          size={IconSize.xsmall}
          color={COLOR.NEUTRAL[300]}
          data-cy="publish-modal:arrow-icon"
        />
        <Badge
          data-cy="adjudication-management-execute-badge"
          color="neutral"
          emphasis={badgeEmphasis}
          size="small"
          text={String(badgeValue)}
          onClick={onClick}
        />
      </ArrowBadgeWrapper>
    </PromptWrapper>
  )
}

const PromptWrapper = styled.div<{ hasBottomBorder?: boolean }>(({ hasBottomBorder }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    borderBottom: hasBottomBorder ? `1px solid ${COLOR.NEUTRAL[300]}` : undefined,
    ':last-child': hasBottomBorder ? { borderBottom: 0 } : undefined,
  }
})

const ArrowBadgeWrapper = PromptWrapper

export { AdjudicationPromptWrapper, PromptWrapperProps }
