import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import { PlaceholderImage } from '../icons'

type ImageMetaCellProps = {
  title?: string
  imageUrl?: string
}

const CellImage: FC<ImageMetaCellProps> = ({ title, imageUrl }) => (
  <CellWrapper>
    <ImageColumn>
      {imageUrl ? (
        <Image data-cy="meta-image" src={imageUrl} alt={`shop display, ${title}`} />
      ) : (
        <PlaceholderImage data-cy="meta-placeholder" />
      )}
    </ImageColumn>
  </CellWrapper>
)

export const CellWrapper = styled.div({
  padding: '8px 0',
})

export const ImageColumn = styled.div({
  display: 'block',
  width: 40,
  height: 40,
})

export const Image = styled.img({
  height: '100%',
  width: '100%',
  objectFit: 'contain',
})

export const MetadataColumn = styled.div(
  {
    color: COLOR.VERY_DARK_GRAYISH_BLUE_0,
    fontSize: '0.75rem',
    flex: '1 0',
    lineHeight: '16px',
    marginLeft: 14,
  },
  ({ isSingle }: { isSingle: boolean }) => ({
    paddingLeft: isSingle ? 32 : 92,
  }),
)

export const Name = styled.div({
  color: COLOR.BLACK_PRIMARY,
  fontSize: '0.875rem',
  fontWeight: 600,
  maxWidth: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

export { CellImage }
