import type { FC } from 'react'
import React from 'react'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import { Button } from '@helloextend/merchants-ui'
import * as Yup from 'yup'
import {
  formatPhoneNumber,
  removeNonNumsFromPhone,
  validateIsNumberEntered,
} from '@helloextend/client-utils/src/validate'
import type { support as supportActions } from '../../../actions'
import Input from '../../input'

const PHONE_NUMBER_MIN_LENGTH_USA = 10
const schema = Yup.object()
  .shape({
    name: Yup.string().required('Please enter your name'),
    email: Yup.string().email('Please enter a valid email').required(),
    phoneNumber: Yup.string().test(
      'phone-number-validation',
      'Please enter a valid phone number',
      (value): boolean => {
        return !value || validateIsNumberEntered(value, PHONE_NUMBER_MIN_LENGTH_USA)
      },
    ),
    message: Yup.string().required('Send us a message'),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

interface SupportModalFormProps {
  name: string
  email: string
  onSubmit: typeof supportActions.createSupport
  toggleSupport: typeof supportActions.toggleSupport
  isSupportLoading: boolean
  show: boolean
  enter: boolean
}

const Component: FC<SupportModalFormProps> = ({
  name,
  email,
  onSubmit,
  toggleSupport,
  isSupportLoading,
  show,
  enter,
}) => {
  const { touched, values, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    initialValues: {
      name,
      email,
      phoneNumber: '',
      message: '',
    },
    onSubmit: (vals: Values): void => {
      onSubmit(vals.name, vals.email, vals.message, vals.phoneNumber)
    },
  })
  const formattedNumber =
    touched.phoneNumber && errors.phoneNumber
      ? removeNonNumsFromPhone(values?.phoneNumber ?? '')
      : formatPhoneNumber(values?.phoneNumber ?? '')

  return (
    <Form onSubmit={handleSubmit} show={show} enter={enter}>
      <Input
        label="Name"
        name="name"
        value={values.name}
        invalid={Boolean(touched.name && errors.name)}
        errorMessage={errors.name}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        formGroupCSS={{ height: 96 }}
      />
      <Input
        label="Email"
        inputCSS={{ paddingBottom: 8 }}
        name="email"
        value={values.email}
        errorMessage={errors.email}
        invalid={Boolean(touched.email && errors.email)}
        isDisabled={false}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="ContactInfo@gmail.com"
        type="text"
        formGroupCSS={{ height: 96 }}
      />
      <Input
        label="Phone Number (Optional)"
        errorMessage={errors.phoneNumber}
        name="phoneNumber"
        value={formattedNumber}
        isDisabled={false}
        invalid={Boolean(touched.phoneNumber && errors.phoneNumber)}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="(408) 123-4567"
        type="text"
        formGroupCSS={{ height: 96 }}
      />
      <Input
        label="How can we help you?"
        name="message"
        value={values.message}
        errorMessage={errors.message}
        invalid={Boolean(touched.message && errors.message)}
        inputCSS={{
          height: 80,
          overflowWrap: 'break-word',
          lineHeight: '18px',
          paddingTop: 8,
          boxShadow: 'none',
        }}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        textArea
        formGroupCSS={{ height: 152 }}
      />
      <Buttons>
        <SupportButton
          name="cancel"
          onClick={toggleSupport}
          text="Cancel"
          kind="secondary"
          size="sm"
        />
        <SupportButton type="submit" loading={isSupportLoading} text="Send" size="sm" />
      </Buttons>
    </Form>
  )
}

const Form = styled.form<{ show: boolean; enter: boolean }>(
  {
    display: 'none',
    flexDirection: 'column',
  },
  (props) => [
    props.show && { display: 'flex', opacity: '0', transform: 'translateY(0.5rem)' },
    props.enter && {
      opacity: '1',
      transform: 'translateY(0)',
      transition: 'all 300ms 50ms linear',
    },
  ],
)

const Buttons = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

const SupportButton = styled(Button)({
  width: 144,
})

export default Component
