import type { FC, ReactNode, CSSProperties } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { OverlayButtonPosition } from './types'

interface OverlayButtonContainerProps {
  buttonPosition?: OverlayButtonPosition
  children: ReactNode
  'data-cy'?: string
}

const OverlayButtonContainer: FC<OverlayButtonContainerProps> = ({
  children,
  buttonPosition = OverlayButtonPosition.right,
  'data-cy': dataCy,
}) => {
  return (
    <StyledOverlayButtonContainer buttonPosition={buttonPosition} data-cy={dataCy}>
      {children}
    </StyledOverlayButtonContainer>
  )
}

export const getPositionCss = (buttonPosition: OverlayButtonPosition): CSSProperties => {
  switch (buttonPosition) {
    case OverlayButtonPosition.center:
      return {
        alignItems: 'center',
      }
    case OverlayButtonPosition.left:
      return {
        alignItems: 'flex-start',
      }
    case OverlayButtonPosition.right:
    default:
      return {
        alignItems: 'flex-end',
      }
  }
}

const StyledOverlayButtonContainer = styled.div(
  ({ buttonPosition }: { buttonPosition: OverlayButtonPosition }) => ({
    position: 'fixed',
    zIndex: 2,
    left: 0,
    right: 0,
    bottom: 0,
    maxHeight: '100%',
    padding: 40,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    pointerEvents: 'none',
    ...getPositionCss(buttonPosition),
  }),
)

export { OverlayButtonContainer }
