import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@helloextend/client-branding'

type ErrorIconProps = {
  fill?: string
  sizePx?: number
}

/**
 * @deprecated Use Zen Icon component instead: `import { Icon } from '@helloextend/zen'`
 */
const ErrorIcon: FC<ErrorIconProps> = ({ fill = COLOR.BLACK_MUTED, sizePx = 47 }) => (
  <svg
    width={sizePx}
    height={sizePx}
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 23.5C0 36.4577 10.5423 47 23.5 47C36.4577 47 47 36.4577 47 23.5C47 10.5423 36.4577 0 23.5 0C10.5423 0 0 10.5423 0 23.5ZM2.04348 23.5C2.04348 11.6683 11.6683 2.04348 23.5 2.04348C35.3317 2.04348 44.9565 11.6683 44.9565 23.5C44.9565 35.3317 35.3317 44.9565 23.5 44.9565C11.6683 44.9565 2.04348 35.3317 2.04348 23.5Z"
      fill={fill}
    />
    <path
      d="M32.6955 33.7174C32.434 33.7174 32.1724 33.6173 31.9721 33.4191L13.5808 15.0278C13.1824 14.6293 13.1824 13.9815 13.5808 13.583C13.9793 13.1846 14.6271 13.1846 15.0256 13.583L33.4169 31.9743C33.8154 32.3728 33.8154 33.0206 33.4169 33.4191C33.2187 33.6173 32.9571 33.7174 32.6955 33.7174Z"
      fill={fill}
    />
    <path
      d="M14.3042 33.7174C14.0427 33.7174 13.7811 33.6173 13.5808 33.4191C13.1824 33.0206 13.1824 32.3728 13.5808 31.9743L31.9721 13.583C32.3706 13.1846 33.0184 13.1846 33.4169 13.583C33.8154 13.9815 33.8154 14.6293 33.4169 15.0278L15.0256 33.4191C14.8274 33.6173 14.5658 33.7174 14.3042 33.7174Z"
      fill={fill}
    />
  </svg>
)

export { ErrorIcon }
