import { createBooleanReducer } from '../../factories'
import type { Action } from '../actions'

const isUpdating = createBooleanReducer<Action>(
  ['PRODUCTS_UPDATE_BATCH_REQUEST', 'PRODUCTS_UPDATE_REQUEST'],
  [
    'PRODUCTS_UPDATE_SUCCESS',
    'PRODUCTS_UPDATE_FAILURE',
    'PRODUCTS_UPDATE_BATCH_FAILURE',
    'PRODUCTS_UPDATE_BATCH_SUCCESS',
  ],
)

export default isUpdating
