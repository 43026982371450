import type { BadgeColor } from '@helloextend/zen'
import type { Claim, ClaimStatus } from '@helloextend/extend-api-client'
import type {
  PendingClaimDisposition,
  ClaimClosedResolution,
} from '@helloextend/extend-api-client/src/models/claim'

export interface BadgeDescriptionItem {
  badgeText: string
  badgeColor: BadgeColor
  description: string
  badgeDetails?: string
}

export const claimStatusDetails: Record<ClaimStatus, BadgeDescriptionItem> = {
  approved: {
    badgeText: 'Approved',
    description:
      "The customer's reported issue is approved for replacement or repair and is currently in progress.",
    badgeColor: 'green',
  },
  fulfilled: {
    badgeText: 'Fulfilled',
    description: 'The customer has received their replacement or repair.',
    badgeColor: 'neutral',
  },
  closed: {
    badgeText: 'Closed',
    description: "The customer's reported issue has been closed with no further action required.",
    badgeColor: 'neutral',
  },
  denied: {
    badgeText: 'Denied',
    description: "The customer's issue is not eligible based on the limit of liability.",
    badgeColor: 'red',
  },
  review: {
    badgeText: 'In Review',
    description: "The customer's reported issue is being reviewed.",
    badgeColor: 'yellow',
  },
  pending: {
    badgeText: 'Pending',
    description: 'The customer must take an action to progress the claim forward.',
    badgeColor: 'yellow',
  },
}

const pendingDispositionMap: Record<PendingClaimDisposition, string> = {
  ship_product: 'Ship defective product',
}

const closedResolutionMap: Record<ClaimClosedResolution, string> = {
  no_service: 'No service required',
}

export const getStatusBadgeDetails = (claim: Claim): BadgeDescriptionItem | null => {
  const { status, pendingClaimDisposition, closedMetaData, reopened } = claim
  const badgeData = claimStatusDetails[status]

  if (!badgeData) return null

  if (status === 'pending' && pendingClaimDisposition) {
    return {
      ...badgeData,
      badgeDetails: pendingDispositionMap[pendingClaimDisposition],
    }
  }

  if (status === 'closed' && closedMetaData) {
    return {
      ...badgeData,
      badgeDetails: closedResolutionMap[closedMetaData.resolution],
    }
  }

  if (status === 'approved' && reopened) {
    return {
      ...badgeData,
      badgeText: 'Reopened',
    }
  }

  return badgeData
}
