import type { FC, PropsWithChildren } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../tokens/colors'

interface ButtonDividerProps {
  'data-cy'?: string
}

const ButtonGroupDivider: FC<PropsWithChildren<ButtonDividerProps>> = ({ 'data-cy': dataCy }) => {
  return <StyledButtonGroupDivider data-cy={dataCy} />
}

export { ButtonGroupDivider }

const StyledButtonGroupDivider = styled.div({
  width: 1,
  flexGrow: 0,
  flexShrink: 0,
  alignSelf: 'stretch',
  background: COLOR.NEUTRAL[300],
})
