import type { AuditItem, Contract, ContractSearchKey } from '@helloextend/extend-api-client'
import type { PlanContract } from '@helloextend/extend-api-client/src/models/plan'
import type { ReducerState } from './reducers'
import type { ErrorReducerState } from '../types/error'

type Selector<T> = (state: ReducerState, ...rest: any[]) => T

export const getAll: Selector<Contract[]> = (state) => Object.values(state.byId)
export const getById: Selector<(Contract & { planDetails?: PlanContract }) | null> = (
  state,
  id: string,
) => state.byId[id]
export const getError: Selector<ErrorReducerState> = (state) => state.error
export const getIsLoading: Selector<boolean> = (state) => state.isLoading
export const getIsSearching: Selector<boolean> = (state) => state.isSearching
export const getIsResending: Selector<boolean> = (state) => state.isResending
export const getIsCreating: Selector<boolean> = (state) => state.isCreating
export const getIsSuccess: Selector<boolean> = (state) => state.isSuccess
export const getIsUpdating = (state: ReducerState): boolean => {
  return state.isUpdating
}

export const getContractsPage = (state: ReducerState): number => {
  return state.search.page + 1
}

export const getContractsSearchItems = (state: ReducerState): Contract[] => {
  return state.search.currentPage
}

export const getContractsSearchItemsCount = (state: ReducerState): number => {
  return state.search.totalCount
}

export const hasNextPage = (state: ReducerState): boolean => {
  return state.search.page < state.search.pageGroup.length - 1
}

export const hasPrevPage = (state: ReducerState): boolean => {
  return state.search.page > 0
}

export const getAuditHistory: Selector<Array<AuditItem<Contract>>> = (state: ReducerState) =>
  state.auditLog.currentPage

export const getAuditHistoryCursor: Selector<string | null | undefined> = (state: ReducerState) =>
  state.auditLog.nextPageCursor

export const hasPrevPageAuditHistory: Selector<boolean> = (state: ReducerState) =>
  state.auditLog.page > 1

export const getLastFetchedPageAuditHistory: Selector<number> = (state: ReducerState) =>
  state.auditLog.lastFetched

export const getAuditHistoryCurrentPage: Selector<number> = (state: ReducerState) =>
  state.auditLog.page

export const getSearchTerm: Selector<string> = (state) => state.search.value
export const getSearchKey: Selector<ContractSearchKey> = (state) => state.search.searchKey
