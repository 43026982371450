import type {
  DefaultMessage,
  Message,
  ThreadResponse,
  DefaultReply,
  ScriptItem,
  Reply,
  Option,
  PromptOption,
  RuleCreate,
  RulesetBase,
  NonNumericConditionWithScript,
} from '@helloextend/extend-api-rtk-query'
import {
  findDefaultCollectOption,
  findDefaultCollectOptionIndex,
  isUUID,
  identifyCondition,
} from '../../pages/admin/adjudication-management/utils'
import type { Conversation, MessageBlockReferenceMap } from '../../types/conversations'

export const getIsMessageTextType = (message: Message): boolean => {
  return /^text$|^textSelect$/.test((message as DefaultMessage)?.type)
}

export interface ReusableThreadsTypeCount {
  [key: string]: number
}

export interface PublishValidationDetails {
  isConversationEmpty?: boolean
  isIntroThreadMissing?: boolean
  isOutroThreadMissing?: boolean
  isFirstThreadIntro?: boolean
  hasDuplicateThreadTypes?: boolean
  reusableThreadsTypeCounts?: ReusableThreadsTypeCount
  isAnyMessageBlockOrphaned?: boolean
  isEveryJumpToAssigned?: boolean
}

export interface RemoveMessageBlockReferenceCountChangeSet {
  updates?: Array<[string, number]>
  deletes: string[]
}

export const isConversationStructureValid = (
  conversation: Conversation,
): [boolean, PublishValidationDetails] => {
  const isConversationEmpty =
    conversation.singleUseThreads?.length === 0 && conversation.reusableThreadsTypes?.length === 0
  const isIntroThreadMissing = !conversation.reusableThreadsTypes?.some(
    (threadType) => threadType === 'intro',
  )
  const isFirstThreadIntro = conversation.firstThreadType === 'intro'
  const isOutroThreadMissing =
    conversation.reusableThreadsTypes?.some((threadType) => threadType === 'adjudication') &&
    !conversation.reusableThreadsTypes?.some((threadType) => threadType === 'outro')

  const reusableThreadsTypeCounts: ReusableThreadsTypeCount | undefined =
    conversation.reusableThreadsTypes?.reduce((acc: ReusableThreadsTypeCount, current) => {
      if (!acc[current]) {
        acc[current] = 0
      }
      acc[current] += 1
      return acc
    }, {})

  const hasDuplicateThreadTypes =
    reusableThreadsTypeCounts && Object.values(reusableThreadsTypeCounts).some((count) => count > 1)

  // we should not consider entries that end with '.0', meaning first message blocks in single-use threads. They are allowed to be unreferenced for the structure to be valid.
  const isAnyMessageBlockOrphaned =
    conversation.messageBlockReferenceMap &&
    Object.entries(conversation.messageBlockReferenceMap)
      .filter(([key]) => !key.endsWith('.0'))
      .some(([, refCount]) => {
        return refCount === 0
      })

  // Does any single_use thread have a message block with an empty Jump To value
  const isEveryJumpToAssigned = Boolean(
    conversation.singleUseThreads &&
      conversation.singleUseThreads.every((thread) =>
        thread.script.every((scriptItem) =>
          scriptItem.collect.options.every((option) => isJumpToAssignedToValidRoute(option)),
        ),
      ),
  )

  // Return a tuple of [boolean, PublishValidationDetails]
  return [
    !isConversationEmpty &&
      !isIntroThreadMissing &&
      !isOutroThreadMissing &&
      !hasDuplicateThreadTypes &&
      isFirstThreadIntro &&
      !isAnyMessageBlockOrphaned &&
      isEveryJumpToAssigned,
    {
      isConversationEmpty,
      isIntroThreadMissing,
      isOutroThreadMissing,
      isFirstThreadIntro,
      hasDuplicateThreadTypes,
      reusableThreadsTypeCounts,
      isAnyMessageBlockOrphaned,
      isEveryJumpToAssigned,
    },
  ]
}

export const isThreadTextAssigned = (thread: ThreadResponse | null): boolean => {
  if (!thread) return false
  return thread.script?.every((script) => isMessageBlockTextAssigned(script))
}

export const getUnreferencedMessageBlocks = (thread: ThreadResponse | null): ScriptItem[] => {
  const difference = (setA: Set<number>, setB: Set<number>): Set<number> => {
    const diff = new Set(setA)

    for (const elem of setB) {
      diff.delete(elem)
    }

    return diff
  }

  if (!thread) {
    return []
  }

  const assignedScriptIndices = new Set(
    thread.script.flatMap((script) => {
      return script.collect.options.map((option) => option.execute?.scriptIndex ?? -1)
    }),
  )

  const allScriptIndices = new Set(thread.script.map((_script, index) => index))
  const unreferencedScriptIndices = [...difference(allScriptIndices, assignedScriptIndices)]

  return thread.script.filter((_script, index) => unreferencedScriptIndices.includes(index))
}

export const isMessageBlockReferenced = (
  messageBlock: ScriptItem,
  thread: ThreadResponse,
): boolean => {
  return !getUnreferencedMessageBlocks(thread).includes(messageBlock)
}

export const isDefaultReply = (reply: Reply | undefined): reply is DefaultReply => {
  if (reply) {
    return 'messages' in reply
  }
  return false
}

export const getDefaultReply = (scriptItem: ScriptItem): DefaultReply | undefined => {
  return isDefaultReply(scriptItem.reply) ? (scriptItem.reply as DefaultReply) : undefined
}

export const hasKaleyMessages = (defaultReply: DefaultReply): boolean => {
  return defaultReply.messages !== undefined
}

// we only consider default messages(which have content & type === text/textSelect)
export const isEachKaleyTextAssigned = (defaultReply: DefaultReply): boolean => {
  return hasKaleyMessages(defaultReply)
    ? defaultReply.messages.every((message) =>
        // Regex is testing that the message type is either 'text' or 'textSelect' to perform a content length check
        getIsMessageTextType(message) ? (message as DefaultMessage).content.length > 0 : true,
      )
    : true
}

// we perform the every check if the prompt type is 'multiselect' or 'buttons', otherwise return true
export const isEachChoiceTextAssigned = (defaultReply: DefaultReply): boolean => {
  if (defaultReply.prompt && !['multiselect', 'buttons'].includes(defaultReply.prompt.type))
    return true

  return (
    (defaultReply.prompt &&
      ['multiselect', 'buttons'].includes(defaultReply.prompt.type) &&
      defaultReply.prompt.options?.every((option) => option.title.length > 0)) ??
    true
  )
}

export const isReportingValueAssigned = (option: PromptOption): boolean => {
  return !isUUID(option.value) && option.value.length > 0
}
export const isReportingValueCapableScriptItem = (scriptItem: ScriptItem): boolean => {
  const defaultReply = getDefaultReply(scriptItem)

  // if there are no reporting value prompts, return false
  if (!defaultReply || (defaultReply && !defaultReply.prompt)) return false
  return (
    (defaultReply.prompt && defaultReply.prompt.type === 'multiselect') ||
    (defaultReply.prompt && defaultReply.prompt.type === 'buttons') ||
    false
  )
}

export const getIsEachReportingValueAssigned = (scriptItem: ScriptItem): boolean => {
  if (!isReportingValueCapableScriptItem(scriptItem)) return true

  const defaultReply = getDefaultReply(scriptItem)
  if (!defaultReply || (defaultReply && !defaultReply.prompt)) return false
  const isEach = defaultReply?.prompt?.options?.every((option) => isReportingValueAssigned(option))
  return isEach ?? false
}

export const isJumpToAssignedToValidRoute = (jumpTo: Option): boolean => {
  return (
    (jumpTo.action === 'execute' &&
      jumpTo.execute?.scriptIndex !== undefined &&
      jumpTo.execute?.scriptIndex >= 0) ||
    jumpTo.action === 'next' ||
    jumpTo.action === 'stop'
  )
}

// Note: yes other prompt types can have routes but only these 4 types are editable in
// the application.
export const isRoutingCapableScriptItem = (scriptItem: ScriptItem): boolean => {
  const defaultReply = getDefaultReply(scriptItem)

  // if there are no routings, return false
  if (!defaultReply || (defaultReply && !defaultReply.prompt)) return false

  // only consider prompt types that we support edit their routings in the application
  return (
    (defaultReply.prompt && defaultReply.prompt.type === 'datepicker') ||
    (defaultReply.prompt && defaultReply.prompt.type === 'multiselect') ||
    (defaultReply.prompt && defaultReply.prompt.type === 'buttons') ||
    (defaultReply.prompt && defaultReply.prompt.type === 'imageUpload') ||
    (defaultReply.prompt && defaultReply.prompt.type === 'input') ||
    false
  )
}

export const getIsMessageBlockRoutingsAssigned = (scriptItem: ScriptItem): boolean => {
  if (!isRoutingCapableScriptItem(scriptItem)) return true
  return scriptItem.collect.options.every((option) => isJumpToAssignedToValidRoute(option))
}

export const getIsMessageBlockTextAssigned = (scriptItem: ScriptItem): boolean => {
  const defaultReply = getDefaultReply(scriptItem)
  if (!defaultReply) return true

  return isEachKaleyTextAssigned(defaultReply) && isEachChoiceTextAssigned(defaultReply)
}

export const updateMessageBlockReferenceCountsForAddingMessageBlock = (
  messageBlockReferenceCountMap: MessageBlockReferenceMap,
  indexToAdd: number,
  thread: ThreadResponse,
): Array<[string, number]> => {
  let updateChangeset: Array<[string, number]> = []

  // if the indexToAdd is at the end, just add a new key with value 0 to the reference map.
  if (indexToAdd === thread.script.length) {
    const payload: [string, number] = [`${thread.id}.${indexToAdd}`, 0]
    updateChangeset.push(payload)
  }
  // if the indexToAdd is before the last element, then in addition to adding a new key for that, we need to increment all the other keys below it
  else if (indexToAdd < thread.script.length) {
    // original to shifted key(+1) map
    const keyMap: Array<[string, string]> = Array.from(
      Array(thread.script.length - indexToAdd).keys(),
    )
      .map((val) => val + indexToAdd)
      .map((val) => [`${thread.id}.${val}`, `${thread.id}.${val + 1}`])

    const insertedRefCount: Array<[string, number]> = [[`${thread.id}.${indexToAdd}`, 0]]
    const updatedRefCounts: Array<[string, number]> = keyMap.map((keyMapItem) => {
      const existingKey = keyMapItem[0]
      const newKey = keyMapItem[1]
      return [newKey, messageBlockReferenceCountMap[existingKey]]
    })
    updateChangeset = insertedRefCount.concat(updatedRefCounts) as Array<[string, number]>
  }

  return updateChangeset
}

export const updateMessageBlockReferenceCountsForRemovingMessageBlock = (
  messageBlockReferenceCountMap: MessageBlockReferenceMap,
  indexToDelete: number,
  thread: ThreadResponse,
): RemoveMessageBlockReferenceCountChangeSet => {
  const changeset: RemoveMessageBlockReferenceCountChangeSet = { deletes: [] }

  // if the indexToDelete is at the end, just remove that key from the reference map.
  if (indexToDelete === thread.script.length - 1) {
    const payload: string[] = [`${thread.id}.${indexToDelete}`]
    changeset.deletes = payload
  }
  // if the indexToDelete is before the last element, then in addition to removing that key, we need to decrement all the other keys below it
  else if (indexToDelete < thread.script.length - 1) {
    // original to shifted key(-1) map
    const keyMap: Array<[string, string]> = Array.from(
      Array(thread.script.length - 1 - indexToDelete).keys(),
    )
      .map((val) => val + 1 + indexToDelete)
      .map((val) => [`${thread.id}.${val}`, `${thread.id}.${val - 1}`])

    // in addition to the key that is removed by the editor, we want to remove the soon-to-be non-existant last key in the single use thread since we're shifting all the indices up
    const removedRefCount: string[] = [
      `${thread.id}.${indexToDelete}`,
      `${thread.id}.${thread.script.length - 1}`,
    ]
    changeset.deletes = removedRefCount

    const updatedRefCounts: Array<[string, number]> = keyMap.map((keyMapItem) => {
      const existingKey = keyMapItem[0]
      const newKey = keyMapItem[1]
      return [newKey, messageBlockReferenceCountMap[existingKey]]
    })

    changeset.updates = updatedRefCounts
  }
  return changeset
}

export const isMessageBlockTextAssigned = (scriptItem: ScriptItem): boolean => {
  const defaultReply = getDefaultReply(scriptItem)
  if (!defaultReply) return true

  return isEachKaleyTextAssigned(defaultReply) && isEachChoiceTextAssigned(defaultReply)
}

export const getReferenceCountForMessageBlock = (
  messageBlockIndex: number,
  thread: ThreadResponse,
): number => {
  let refCount = 0
  thread.script.forEach((messageBlock, _index) => {
    if (_index === messageBlockIndex) return
    const { collect, reply } = messageBlock
    const promptType = (reply as DefaultReply)?.prompt?.type
    let currentJumpToValue = 0
    if (!promptType) return

    if (promptType !== 'buttons' && promptType !== 'multiselect') {
      // message block types that always mutate default
      const defaultCollectOption = findDefaultCollectOption(collect)
      const defaultCollectOptionIndex = findDefaultCollectOptionIndex(collect)

      if (!defaultCollectOption || defaultCollectOptionIndex < 0) return

      currentJumpToValue = Number(defaultCollectOption?.execute?.scriptIndex)
      if (currentJumpToValue === messageBlockIndex) refCount += 1
    } else {
      collect.options.forEach((option) => {
        if (option.execute?.scriptIndex === messageBlockIndex) {
          refCount += option.patterns?.length ?? 1
        }
      })
    }
  })
  return refCount
}

export const hasRequiredSlots = (thread: ThreadResponse | null): boolean => {
  if (!thread) return false
  return hasFailureTypeSlot(thread)
}

export const hasFailureTypeSlot = (thread: ThreadResponse | null): boolean => {
  if (!thread) return false
  return thread.script.some(
    (scriptItem) => (scriptItem?.reply as DefaultReply)?.prompt?.slot === 'FailureType',
  )
}

export const isMessageBlockOrphaned = (
  key: string,
  messageBlockReferenceMap: MessageBlockReferenceMap,
): boolean => {
  if (messageBlockReferenceMap[key] === undefined) return false
  return messageBlockReferenceMap[key] === 0
}

export const hasMissingKeySelectorValues = (ruleset: RuleCreate[]): boolean => {
  return ruleset.some((rule) => {
    return rule.conditions.some((condition) => {
      const conditionType = identifyCondition(condition)
      if (conditionType && conditionType.includes('Script')) {
        const script = (condition as NonNumericConditionWithScript).script as number
        return script === -1
      }
      return false
    })
  })
}

export const hasValidConditions = (ruleset: RulesetBase | null): boolean => {
  if (!ruleset) return false
  // first validation check: Key selector is not empty
  const hasMissingApproveKey = hasMissingKeySelectorValues(ruleset.approveRules)
  const hasMissingDenyKey = hasMissingKeySelectorValues(ruleset.denyRules)
  const hasMissingReviewKey = hasMissingKeySelectorValues(ruleset.reviewRules)
  // TODO: Phase 2 [CCS-1317] - Add validation for rest of condition inputs/selectors

  return !hasMissingApproveKey && !hasMissingDenyKey && !hasMissingReviewKey
}
