enum InputType {
  email = 'email',
  number = 'number',
  password = 'password',
  search = 'search',
  tel = 'tel',
  text = 'text',
  url = 'url',
}

export { InputType }
