import styled from '@emotion/styled'
import { COLOR } from '@helloextend/zen'
import type { FC } from 'react'
import React from 'react'

interface ThreadPlaceholderProps {
  description: string
}

const ThreadPlaceholder: FC<ThreadPlaceholderProps> = ({ description }) => {
  return (
    <>
      <Placeholder data-cy={`amp-${description}-thread-placeholder`}>
        <Text>The selected thread will appear here</Text>
      </Placeholder>
    </>
  )
}

const Placeholder = styled.div({
  marginTop: 16,
  border: 'solid',
  borderWidth: 1,
  borderRadius: 8,
  borderColor: COLOR.BLUE[700],
  width: 360,
  marginLeft: 44,
})

const Text = styled.p({
  margin: '16px 48px 16px 16px ',
  fontSize: 16,
  lineHeight: '24px',
  color: COLOR.NEUTRAL[600],
})

export { ThreadPlaceholder }
