import type { CategoryHeader } from './category-csv-headers'
import { orderedCategoriesCsvHeaders, orderedCategoriesCsvHeadersLen } from './category-csv-headers'

export function getLowerCaseTrimmedStr(s: string): string {
  return s.toLowerCase().trim()
}

/**
 * @param header
 * @returns boolean
 */
export function validateCategoriesHeader(header: string[]): header is CategoryHeader[] {
  if (header.length !== orderedCategoriesCsvHeadersLen) {
    return false
  }

  const downCasedHeaders = header.map(getLowerCaseTrimmedStr)

  for (let i = 0; i < orderedCategoriesCsvHeadersLen; i += 1) {
    if (orderedCategoriesCsvHeaders[i].toLowerCase() !== downCasedHeaders[i]) {
      return false
    }
  }

  return true
}
