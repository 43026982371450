import type { FC, ChangeEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import type { AdvancedSelectChangeEvent } from '@helloextend/zen'
import { AdvancedSelect, Input } from '@helloextend/zen'
import { Paragraph } from '@helloextend/zen/src/tokens/typography'
import type { NumericCondition, RuleStatus } from '@helloextend/extend-api-rtk-query'
import type { RootState } from '../../../../../../reducers'
import * as selectors from '../../../../../../reducers/selectors'
import { identifyCondition } from '../../../utils'
import { updateOffsetAmount, updateOffsetAddend } from '../../../../../../store/slices/amp-slice'

type OffsetSelectorProps = {
  rulesetType: RuleStatus
  ruleIndex: number
  conditionIndex: number
  isDisabled?: boolean
}

const OffsetSelector: FC<OffsetSelectorProps> = ({
  rulesetType,
  ruleIndex,
  conditionIndex,
  isDisabled,
}) => {
  const dispatch = useDispatch()

  const condition = useSelector((state: RootState) =>
    selectors.getCondition({ state, rulesetType, ruleIndex, conditionIndex }),
  )

  const conditionType = condition ? identifyCondition(condition) : null
  const conditionOffset =
    conditionType === 'numericScript' ? (condition as NumericCondition)?.offset : null

  let inputValue = ''
  let selectorValue = ''
  if (conditionOffset) {
    selectorValue = (conditionOffset as number) > -1 ? 'after' : 'before'
    inputValue = Math.abs(conditionOffset).toString()
  }
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    dispatch(updateOffsetAmount({ rulesetType, ruleIndex, conditionIndex, value }))
  }

  const handleSelectorChange = (e: AdvancedSelectChangeEvent): void => {
    const { value } = e.target
    dispatch(updateOffsetAddend({ rulesetType, ruleIndex, conditionIndex, value }))
  }

  const isSelectorDisabled = inputValue === ''

  return (
    <OffsetWrapper>
      <InputContainer>
        <Input
          data-cy="claim-validation-offset-input"
          id="claim-validation-offset-input"
          onChange={handleInputChange}
          value={inputValue}
          isDisabled={isDisabled}
        />
      </InputContainer>
      <Paragraph>days</Paragraph>
      <SelectorContainer>
        <AdvancedSelect
          data-cy="claim-validation-offset-selector"
          id="claim-validation-offset-selector"
          onChange={handleSelectorChange}
          options={[
            { display: 'After', value: 'after' },
            { display: 'Before', value: 'before' },
          ]}
          value={selectorValue}
          multiple={false}
          isDisabled={isDisabled || isSelectorDisabled}
          isNotClearable
        />
      </SelectorContainer>
    </OffsetWrapper>
  )
}

const OffsetWrapper = styled.div({
  display: 'flex',
  gap: 12,
  alignItems: 'center',
})

const InputContainer = styled.div({
  width: 56,
})

const SelectorContainer = styled.div({
  width: 100,
})

export { OffsetSelector }
