import type { RefObject } from 'react'
import { useEffect, useState, createRef } from 'react'
import { debounce } from 'lodash/fp'

interface MeasureRef {
  measureRef: RefObject<HTMLDivElement>
  height: number
  width: number
}

const useMeasureRef = (): MeasureRef => {
  const measureRef = createRef<HTMLDivElement>()
  const [height, setHeight] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)

  useEffect(() => {
    const handleResize = (): void => {
      if (!measureRef.current) return
      const { width: clientWidth, height: clientHeight } =
        measureRef.current.getBoundingClientRect()

      setHeight(clientHeight)
      setWidth(clientWidth)
    }
    const debouncedHandleResize = debounce(500, handleResize)

    handleResize() // invoke once on mount to set initial state
    window.addEventListener('resize', debouncedHandleResize)
    return () => window.removeEventListener('resize', debouncedHandleResize)
  }, [measureRef])

  return { measureRef, height, width }
}

export default useMeasureRef
