import type { FC } from 'react'
import React, { useState } from 'react'
import type { Store } from '@helloextend/extend-api-client'
import {
  AdvancedSelect,
  Button,
  ButtonGroup,
  Grid,
  Input,
  InputType,
  Switch,
} from '@helloextend/zen'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import { useUpdateStoreMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import type { MerchantExtendedWarrantyRevenueAgreementType } from '@helloextend/extend-api-client/src/models/store'
import type { RevenueValues } from './schema'
import {
  customExtendCutSchema,
  partialReimbursementSchema,
  revenueModelOptions,
  revenueSchema,
} from './schema'
import { Divider } from '../../../../../../../../components/divider'
import { useDecimalsOnBlur } from '../../../../../../../../hooks/use-decimals-on-blur'
import { CheckboxWithInput } from '../checkbox-with-input'
import type { SaveClickProps } from '../checkbox-with-input/checkbox-with-input'

type ProductProtectionFormProps = {
  store: Store
}

const ProductProtectionForm: FC<ProductProtectionFormProps> = ({ store }) => {
  const [updateStore, { isLoading, isSuccess }] = useUpdateStoreMutation()
  const { toastError, toastCompleted } = useStandardToast()
  const { merchantEwRevenueAgreement, merchantEwRevenueSharePercentage, locationFilteringEnabled } =
    store
  const [isLocationFilteringEnabled, setIsLocationFilteringEnabled] = useState(
    locationFilteringEnabled || false,
  )
  const { values, errors, dirty, isValid, handleChange, handleReset, setFieldValue } =
    useFormik<RevenueValues>({
      initialValues: {
        merchantEwRevenueAgreement: merchantEwRevenueAgreement || 'net_revenue_share',
        merchantEwRevenueSharePercentage: merchantEwRevenueSharePercentage
          ? merchantEwRevenueSharePercentage * 100
          : 0,
      },
      validationSchema: revenueSchema,
      onSubmit: () => {},
      enableReinitialize: true,
    })

  const { handleOnBlurCustom } = useDecimalsOnBlur(setFieldValue)

  const handleUpdateStore = async (data: Partial<Store>): Promise<void> => {
    try {
      await updateStore({
        storeId: store.id,
        data,
        version: 'latest',
      }).unwrap()
      toastCompleted('Product Protection Settings have been successfully updated.')
    } catch {
      toastError('Something went wrong. Please try again.')
      setIsLocationFilteringEnabled(locationFilteringEnabled || false)
    }
  }

  const handleSaveClick = (): void => {
    if (values.merchantEwRevenueAgreement && values.merchantEwRevenueSharePercentage) {
      const isWholesale = values.merchantEwRevenueAgreement === 'wholesale'
      handleUpdateStore({
        merchantEwRevenueAgreement:
          values.merchantEwRevenueAgreement as MerchantExtendedWarrantyRevenueAgreementType,
        merchantEwRevenueSharePercentage: isWholesale
          ? null
          : values.merchantEwRevenueSharePercentage / 100,
      })
    }
  }

  const handleCheckboxSave = (data: SaveClickProps): void => {
    const valueToFixed = (Number(data.value) / 100).toFixed(3)

    handleUpdateStore({
      [data.id]: data.isChecked ? Number(valueToFixed) : null,
    })
  }

  const handleLocationFilteringChange = (): void => {
    setIsLocationFilteringEnabled(!isLocationFilteringEnabled)
    handleUpdateStore({
      locationFilteringEnabled: !store?.locationFilteringEnabled,
    })
  }

  const partialReimbursementPercentage = store.partialReimbursementPercentage
    ? Number((store.partialReimbursementPercentage * 100).toFixed(1))
    : undefined

  const customExtendCutPercentage = store.extendCut
    ? Number((store.extendCut * 100).toFixed(1))
    : undefined

  return (
    <>
      <RevenueSection>
        <Grid columns={4} spacing={2}>
          <AdvancedSelect
            label="Revenue Model"
            isNotClearable
            id="merchantEwRevenueAgreement"
            value={values.merchantEwRevenueAgreement as string}
            onChange={handleChange}
            placeholder="Select"
            options={revenueModelOptions}
            multiple={false}
            isError={Boolean(errors?.merchantEwRevenueAgreement)}
            errorFeedback={errors?.merchantEwRevenueAgreement}
            data-cy="revenue-model-select"
          />
          {values.merchantEwRevenueAgreement !== 'wholesale' && (
            <Input
              id="merchantEwRevenueSharePercentage"
              onChange={handleChange}
              onBlur={handleOnBlurCustom}
              type={InputType.number}
              value={values.merchantEwRevenueSharePercentage?.toString() || ''}
              isError={Boolean(errors?.merchantEwRevenueSharePercentage)}
              errorFeedback={errors?.merchantEwRevenueSharePercentage}
              label="Merchant Revenue Share"
              suffix="%"
              placeholder="Enter a number"
              min={0}
              max={100}
              data-cy="merchant-cut-input"
            />
          )}
        </Grid>
        {dirty && (
          <ButtonGroupWrapper>
            <ButtonGroup>
              <Button
                text="Cancel"
                onClick={handleReset}
                emphasis="medium"
                isDisabled={isLoading}
                data-cy="pp-cancel-button"
              />
              <Button
                text="Save"
                onClick={handleSaveClick}
                emphasis="high"
                isDisabled={!isValid || !dirty || isLoading}
                isProcessing={isLoading}
                data-cy="pp-save-button"
              />
            </ButtonGroup>
          </ButtonGroupWrapper>
        )}
      </RevenueSection>
      <Divider margin="0 0 24px 0" />
      <CheckboxWithInput
        schema={partialReimbursementSchema}
        checkboxId="partialReimbursementPercentageCheckbox"
        checkboxLabel="Partial Reimbursement Merchant"
        inputId="partialReimbursementPercentage"
        inputValue={partialReimbursementPercentage?.toString()}
        inputLabel="Partial Reimbursement Percentage"
        suffix="%"
        inputType={InputType.number}
        isInitialChecked={Boolean(partialReimbursementPercentage)}
        placeholder="Enter a number"
        isInputAlwaysDisplayed={false}
        isLoading={isLoading}
        handleSave={handleCheckboxSave}
        gridColumns={4}
        gridSpacing={2}
        isSuccess={isSuccess}
      />
      <CheckboxWithInput
        schema={customExtendCutSchema}
        checkboxId="extendCutCheckbox"
        checkboxLabel="Custom Extend Cut"
        inputId="extendCut"
        inputValue={customExtendCutPercentage?.toString()}
        inputLabel="Custom Extend Cut Percentage"
        suffix="%"
        inputType={InputType.number}
        isInitialChecked={Boolean(customExtendCutPercentage)}
        placeholder="Enter a number"
        isInputAlwaysDisplayed={false}
        isLoading={isLoading}
        handleSave={handleCheckboxSave}
        gridColumns={4}
        gridSpacing={2}
        isSuccess={isSuccess}
      />
      <Switch
        data-cy="location-filtering-toggle"
        label="Location Filtering"
        isOn={isLocationFilteringEnabled}
        onChange={handleLocationFilteringChange}
      />
    </>
  )
}

const ButtonGroupWrapper = styled.div({
  marginTop: 16,
})

const RevenueSection = styled.div({
  paddingBottom: 24,
})

export { ProductProtectionForm }
