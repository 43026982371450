import type { ServiceOrderShipment } from '@helloextend/extend-api-client'
import type { ErrorReducerState } from '../types/error'
import type { ReducerState } from './reducers'

type Selector<T> = (state: ReducerState, ...rest: any[]) => T

export const getByServiceOrderId: Selector<ServiceOrderShipment[]> = (state, claimId: string) =>
  state.byServiceOrder[claimId] || []

export const getIsLoading: Selector<boolean> = (state) => state.isLoading
export const getError: Selector<ErrorReducerState> = (state) => state.error
