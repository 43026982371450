import type { FC, ChangeEvent, MouseEvent } from 'react'
import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import { Icon, IconSize } from '../icon'
import { COLOR } from '../../tokens/colors'
import { Lock } from '../../tokens/icons'
import { Spinner } from '../spinner'

interface SwitchProps {
  id?: string
  label?: string
  labelPosition?: 'before' | 'after'
  isOn: boolean
  isTentative?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  isDisabled?: boolean
  isProcessing?: boolean
  disabledTooltip?: string
  'data-cy'?: string
}

const Switch: FC<SwitchProps> = ({
  id,
  label = '',
  labelPosition = 'after',
  isOn,
  onChange,
  isDisabled = false,
  isProcessing = false,
  isTentative,
  disabledTooltip,
  'data-cy': dataCy,
}) => {
  const handleClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }, [])

  return (
    <Container onClick={handleClick}>
      {label && labelPosition === 'before' && <Label htmlFor={id}>{label}</Label>}
      <LabelStyledAsSwitch
        htmlFor={id}
        isOn={isOn}
        isDisabled={isDisabled}
        isProcessing={isProcessing}
        data-tooltip={isDisabled ? disabledTooltip : undefined}
        data-cy={dataCy}
      >
        <InvisibleCheckbox
          checked={isOn}
          disabled={isDisabled || isProcessing}
          id={id}
          type="checkbox"
          onChange={onChange}
        />
        <Indicator isTentative={isTentative} isOn={isOn}>
          {isDisabled && (
            <Icon
              icon={Lock}
              size={IconSize.xsmall}
              color={isOn ? COLOR.GREEN[700] : COLOR.NEUTRAL[800]}
            />
          )}
          {isProcessing && (
            <Spinner size={IconSize.xsmall} color={isOn ? COLOR.GREEN[700] : COLOR.NEUTRAL[800]} />
          )}
        </Indicator>
      </LabelStyledAsSwitch>
      {label && labelPosition === 'after' && <Label htmlFor={id}>{label}</Label>}
    </Container>
  )
}

const transitionTiming = '150ms linear'

const Container = styled.div({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
})

const Label = styled.label({
  fontSize: 14,
  color: COLOR.NEUTRAL[1000],
})

const LabelStyledAsSwitch = styled.label<{
  isDisabled: boolean
  isOn: boolean
  isProcessing: boolean
}>(({ isDisabled, isOn, isProcessing }) => ({
  height: 24,
  width: 44,
  backgroundColor: isOn ? COLOR.GREEN[500] : COLOR.NEUTRAL[400],
  borderRadius: 12,
  position: 'relative',
  transition: `background-color ${transitionTiming}`,
  ...(!isDisabled && !isProcessing
    ? {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: isOn ? COLOR.GREEN[600] : COLOR.NEUTRAL[500],
        },
      }
    : {
        pointerEvents: 'none',
      }),
}))

const Indicator = styled.div<{ isOn: boolean; isTentative?: boolean }>(({ isOn, isTentative }) => ({
  boxSizing: 'border-box',
  height: 20,
  width: 20,
  backgroundColor: COLOR.WHITE,
  borderRadius: '50%',
  content: '""',
  ...(isTentative
    ? {
        left: 12,
      }
    : {
        left: isOn ? 22 : 2,
      }),
  position: 'absolute',
  top: 2,
  transition: `left ${transitionTiming}`,
  padding: 2,
}))

const InvisibleCheckbox = styled.input({
  display: 'none',
})

export { Switch, SwitchProps }
