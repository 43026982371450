import type { ExtractActions } from '@helloextend/core-api-redux/src/types/utility'
import * as importActions from './import'

export type ActionType = Action['type']

export type Action = ExtractActions<typeof importActions>

const actions = {
  ...importActions,
}

export { actions }
