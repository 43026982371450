import { contractsActions } from '@helloextend/core-api-redux'

export const {
  fetch,
  fetchV2,
  errorReset,
  fetchFailure,
  fetchRequest,
  update,
  updateV2,
  updateRequest,
  updateSuccess,
  updateFailure,
  updateSuccessReset,
  search,
  searchRequest,
  searchSuccess,
  searchFailure,
  nextPage,
  prevPage,
  firstPage,
  lastPage,
  fetchByStore,
  fetchByStoreRequest,
  fetchByStoreSuccess,
  fetchByStoreFailure,
  searchByStore,
  searchByStoreRequest,
  searchByStoreSuccess,
  searchByStoreFailure,
  searchReset,
  fetchAuditHistory,
  prevAuditPage,
  nextAuditPage,
  incrementNextAuditPage,
} = contractsActions
