import type { PlanSearch } from '@helloextend/extend-api-client'
import type { TablePlansSearch } from '../../../types/plans'

export const toTablePlanItem = (planSearch: PlanSearch): TablePlansSearch => ({
  id: planSearch.planId,
  planId: planSearch.planId,
  version: planSearch.versions.sort((a, b) => b.version - a.version)[0].version,
})

export const toTablePlanItems = (planSearchItems: PlanSearch[]): TablePlansSearch[] =>
  planSearchItems.map(toTablePlanItem)
