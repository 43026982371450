import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/client-utils/src/request'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.resend>

export default function* resend(action: Action): SagaIterator {
  const { contractId, accessToken, forwardTo } = action.payload

  yield put(actions.resendRequest())

  try {
    const response: ResolvedType<typeof client.contracts.resendContractEmail> = yield call(
      client.contracts.resendContractEmail,
      contractId,
      accessToken,
      forwardTo,
    )

    if (isErrorResponse(response)) {
      yield put(actions.resendFailure(response.data.message, response.status))
      return
    }

    // not applicable here since we actually don't get a response body back
    // if (isExceptionResponse(response)) {
    //   yield put(actions.resendFailure(`An unknown error occurred`))
    //   return
    // }

    yield put(actions.resendSuccess(contractId))
  } catch (e) {
    yield put(actions.resendFailure(e.message))
  }
}
