import type { User, UpdateProfileRequest, StoreUser } from '@helloextend/extend-api-client'
import { usersActions } from '@helloextend/core-api-redux'
import { createActionFailure } from '@helloextend/core-api-redux/src/factories'
import type { V3UserDetails } from '../types/users'

// user fetch (get me)
// ==================================================================================
export const getMe = (accessToken: string) =>
  ({
    type: 'USERS_GETME',
    payload: accessToken,
  } as const)

export const getMeRequest = () =>
  ({
    type: 'USERS_GETME_REQUEST',
  } as const)

export const getMeSuccess = (user: User | StoreUser | V3UserDetails) =>
  ({
    type: 'USERS_GETME_SUCCESS',
    payload: user,
  } as const)

export const getMeFailure = createActionFailure('USERS_GETME_FAILURE')

// user update
// ==================================================================================
export const update = (data: UpdateProfileRequest) =>
  ({
    type: 'USERS_UPDATE',
    payload: { data },
  } as const)

export const updateRequest = () =>
  ({
    type: 'USERS_UPDATE_REQUEST',
  } as const)

export const updateSuccess = (message: string) =>
  ({
    type: 'USERS_UPDATE_SUCCESS',
    payload: {
      message,
    },
  } as const)

export const clearSuccessMessage = () =>
  ({
    type: 'CLEAR_PROFILE_SUCCESS_MESSAGE',
  } as const)

export const updateFailure = createActionFailure('USERS_UPDATE_FAILURE')

export const { fetchAllForStore } = usersActions
