import type { FC } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { createPortal } from 'react-dom'
import FocusLock from 'react-focus-lock'
import { COLOR } from '../../tokens/colors'
import { Button } from '../button'
import { Close } from '../../tokens/icons'
import { getMeasurements } from './modal-utils'
import { AnimatePresence, useTransition } from '../../transitions'
import type { ModalProps, ModalControllerProps, MeasurementArgs } from './modal-types'
import { ModalContext } from './modal-context'
import { SharedFooter } from './shared-footer'
import { Spinner } from '../spinner'
import { IconSize } from '../icon'

const checkOverflow = (el: HTMLElement): boolean => {
  const { style, clientWidth, scrollWidth, clientHeight, scrollHeight } = el
  const curOverflow = el.style.overflow

  if (!curOverflow || curOverflow === 'visible') {
    style.overflow = 'hidden'
  }

  const isOverflowing = clientWidth < scrollWidth || clientHeight < scrollHeight

  style.overflow = curOverflow

  return isOverflowing
}

/**
 * Used to provide `AnimatePresence` and to guarantee that the state of everything inside the modal is always reset when
 * the modal is closed and reopened.
 */
export const ModalController: FC<ModalControllerProps> = ({ isOpen, children }) => {
  return (
    <AnimatePresence isPresent={isOpen} transitionDurationMs={200}>
      {children}
    </AnimatePresence>
  )
}

/**
 * Modal interactions are effective at bringing focus to user-initiated tasks when there is no need to access or
 * reference other on-page content in order to perform that task.
 *
 * > **Note:** Every modal must be wrapped in a `ModalController` that handles the presence (isOpen) of the `Modal`
 * itself.
 */
export const Modal: FC<ModalProps> = ({
  altButtonProps,
  children,
  footerContent,
  hasBodyPadding = true,
  heading,
  maxHeight,
  onDismissRequest,
  isLoading,
  primaryButtonProps,
  secondaryButtonProps,
  size = 'md',
  'data-cy': dataCy,
}) => {
  const measurements = getMeasurements(size)
  const bodyRef = useRef<HTMLDivElement>(null)
  const modalContainerRef = useRef<HTMLDivElement>(null)
  const primaryButtonRef = useRef<HTMLButtonElement>(null)
  const [isScrollable, setIsScrollable] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const { isVisible, transitionDurationMs } = useTransition()
  const modalFooterRef = useRef<HTMLDivElement>(null)
  const getModalFooterRef = useCallback(() => modalFooterRef, [])
  const hasLoadingState = !!isLoading

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isVisible])

  const handleKeyDown = useCallback(
    (e: globalThis.KeyboardEvent) => {
      if (e.key === 'Escape' && onDismissRequest) {
        onDismissRequest()
      }

      if (e.key === 'Enter') {
        primaryButtonRef.current?.click()
      }
    },
    [onDismissRequest],
  )

  /**
   * Used to prevent enter key events on buttons other than the primary button from also triggering the primary button
   * onClick event.
   */
  const captureEnterKey = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }, [])

  const checkIsScrollable = useCallback(() => {
    if (isVisible && bodyRef.current) {
      const isOverflowing = checkOverflow(bodyRef.current)
      setIsScrollable(isOverflowing)

      requestAnimationFrame(() => {
        setIsInitialized(true)
      })
    }
  }, [isVisible])

  useEffect(() => {
    const currentModalContainerRef = modalContainerRef.current
    window.addEventListener('resize', checkIsScrollable)
    currentModalContainerRef?.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('resize', checkIsScrollable)
      currentModalContainerRef?.removeEventListener('keydown', handleKeyDown)
    }
  }, [checkIsScrollable, handleKeyDown])

  useEffect(() => {
    setTimeout(() => {
      checkIsScrollable()
    })
  }, [children, size, checkIsScrollable])

  const dismissModal = useCallback(() => {
    if (onDismissRequest) onDismissRequest()
  }, [onDismissRequest])

  return createPortal(
    <ModalContext.Provider value={{ getModalFooterRef, dismissModal }}>
      <FocusLock returnFocus autoFocus={false}>
        <ModalContainer data-cy={dataCy} ref={modalContainerRef}>
          <Overlay
            isVisible={isVisible}
            transitionDuration={transitionDurationMs}
            onClick={onDismissRequest}
          />
          {isLoading && (
            <SpinnerContainer isVisible={isVisible} data-cy={dataCy && `${dataCy}:spinner`}>
              <Spinner color={COLOR.WHITE} size={IconSize.large} />
            </SpinnerContainer>
          )}
          <ModalWrapper
            measurements={measurements}
            isVisible={isVisible && !isLoading}
            transitionDuration={transitionDurationMs}
          >
            <StyledModal
              hasLoadingState={hasLoadingState}
              isVisible={isVisible && !isLoading}
              maxHeight={maxHeight}
            >
              <Header>
                <Heading>{heading}</Heading>
                {!!onDismissRequest && (
                  <Actions onKeyDown={captureEnterKey}>
                    <Button
                      data-cy={dataCy && `${dataCy}:close-button`}
                      emphasis="low"
                      size="small"
                      color="neutral"
                      icon={Close}
                      onClick={onDismissRequest}
                    />
                  </Actions>
                )}
              </Header>
              <Body ref={bodyRef} isScrollable={isScrollable}>
                <BodyContent
                  hasBodyPadding={hasBodyPadding}
                  isScrollable={isScrollable}
                  isInitialized={isInitialized}
                >
                  {children}
                </BodyContent>
              </Body>
              <Footer onKeyDown={captureEnterKey} ref={modalFooterRef}>
                <SharedFooter
                  data-cy={dataCy}
                  footerContent={footerContent}
                  primaryButtonProps={primaryButtonProps}
                  secondaryButtonProps={secondaryButtonProps}
                  altButtonProps={altButtonProps}
                />
              </Footer>
            </StyledModal>
          </ModalWrapper>
        </ModalContainer>
      </FocusLock>
    </ModalContext.Provider>,
    document.body,
  )
}

const ModalContainer = styled.div({
  position: 'fixed',
  zIndex: 100,
  inset: 0,
  padding: 12,
})

const Overlay = styled.div<{
  isVisible: boolean
  transitionDuration: number
}>(({ isVisible, transitionDuration }) => ({
  position: 'absolute',
  inset: 0,
  background: COLOR.NEUTRAL.OPACITY[75],
  transitionProperty: 'opacity',
  transitionDuration: `${transitionDuration}ms`,
  opacity: isVisible ? 1 : 0,
}))

const SpinnerContainer = styled.div<{
  isVisible: boolean
}>(({ isVisible }) => ({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  pointerEvents: 'none',
  transitionProperty: 'opacity',
  transitionDelay: '300ms',
  transitionDuration: '200ms',
  opacity: isVisible ? 1 : 0,
}))

const ModalWrapper = styled.div<{
  measurements: MeasurementArgs
  isVisible: boolean
  transitionDuration: number
}>(({ measurements, isVisible, transitionDuration }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: measurements.widthPx,
  height: '100%',
  margin: 'auto',
  pointerEvents: 'none',
  transitionProperty: 'transform, opacity',
  transitionDuration: `${transitionDuration}ms`,
  transform: isVisible ? 'translateY(0px)' : 'translateY(4px)',
  opacity: isVisible ? 1 : 0,
}))

const StyledModal = styled.div<{
  maxHeight?: number | string
  isVisible?: boolean
  hasLoadingState: boolean
}>(({ maxHeight, isVisible, hasLoadingState }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr auto',
  gridTemplateAreas: '"header" "body" "footer"',
  background: COLOR.WHITE,
  borderRadius: 4,
  maxHeight: maxHeight || '100%',
  pointerEvents: isVisible || !hasLoadingState ? 'all' : 'none',
}))

const Header = styled.header({
  gridArea: 'header',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 16px 16px 24px',
})

const Heading = styled.header({
  fontSize: 20,
  padding: '2px 0',
  lineHeight: '28px',
  fontWeight: 'bold',
})

const Actions = styled.div({
  display: 'flex',
  gap: 8,
  alignSelf: 'flex-start',
})

const Body = styled.div<{
  isScrollable: boolean
}>(({ isScrollable }) => ({
  gridArea: 'body',
  borderStyle: 'solid',
  borderWidth: '1px 0',
  borderColor: isScrollable ? COLOR.NEUTRAL[300] : 'transparent',
  overflow: 'auto',
  transitionProperty: 'border-color',
  transitionDuration: '300ms',
}))

const BodyContent = styled.div<{
  isScrollable: boolean
  isInitialized: boolean
  hasBodyPadding: boolean
}>(({ isScrollable, isInitialized, hasBodyPadding }) => ({
  overflow: 'auto',
  ...(hasBodyPadding && {
    padding: `${isScrollable ? '24px' : '0'} 24px`,
  }),
  ...(isInitialized && {
    transitionProperty: 'padding',
    transitionDuration: '300ms',
  }),
}))

const Footer = styled.footer({
  gridArea: 'footer',
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  width: '100%',
  padding: '20px 24px',
  boxSizing: 'border-box',
})
