import type { CurrencyFormatting } from './currency-input-types'

const USER_LOCALE =
  navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language

export const getFormattedNumber = (v: string, locale: string = USER_LOCALE): string => {
  return parseFloat(v).toLocaleString(locale)
}

export const getCurrencyFormatting = (
  currency: string,
  locale: string = USER_LOCALE,
): CurrencyFormatting => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  })

  const parts = formatter.formatToParts(9999.99)

  const groupIndex = parts.findIndex((part) => part.type === 'group')
  const decimalIndex = parts.findIndex((part) => part.type === 'decimal')
  const symbolIndex = parts.findIndex((part) => part.type === 'currency')

  return {
    groupSeparator: parts[groupIndex].value,
    decimalSeparator: parts[decimalIndex].value,
    symbol: parts[symbolIndex].value,
    hasSuffixSymbol: symbolIndex !== 0,
  }
}

export const getNumericValue = (value: string, groupSeparator: string): number =>
  Math.round(parseFloat(value.split(groupSeparator).join('').replace(/,/g, '.')) * 100)

export const getFormattedValue = (value: string, locale: string = USER_LOCALE): string =>
  value !== '' ? (parseFloat(value) / 100).toLocaleString(locale) : ''
