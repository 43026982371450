import { configureStore as rtkConfigureStore } from '@reduxjs/toolkit'
import {
  claimsApi,
  claimPhotosApi,
  contractsApi,
  productsApi,
  ordersApi,
  serviceOrdersApi,
  plansApi,
  servicersApi,
  productsMappingApi,
  entitlementsApi,
  skusApi,
  claimsAssignmentUsersApi,
  incredibotApi,
  insuranceProgramsApi,
  storesApi,
  usersApi,
  virtualCardsApi,
  shippingProtectionPlansApi,
  refundsApi,
  accountsApi,
  conversationsApi,
  usersV3Api,
  oktaApi,
  grantsApi,
  productsRdbApi,
  productCategoriesRdbApi,
  plansTermsApi,
  wdOrchestratorApi,
} from '@helloextend/extend-api-rtk-query'
import createSagaMiddleware from 'redux-saga'
import { EXTEND_ENV } from '@helloextend/client-constants'
import { logErrors } from '@helloextend/core-api-redux/src/middleware'
import type { DeepPartial, Store } from 'redux'
import rootReducer from '../reducers'
import type { RootState } from '../reducers'
import rootSaga from '../sagas'
import { load as loadState, save as saveState } from './persistence'

export default function configureStore(preLoadedState?: DeepPartial<RootState>): Store {
  const sagaMiddleware = createSagaMiddleware()

  const store = rtkConfigureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        sagaMiddleware,
        logErrors,
        serviceOrdersApi.middleware,
        plansApi.middleware,
        claimsApi.middleware,
        claimPhotosApi.middleware,
        contractsApi.middleware,
        productsApi.middleware,
        servicersApi.middleware,
        productsMappingApi.middleware,
        entitlementsApi.middleware,
        skusApi.middleware,
        claimsAssignmentUsersApi.middleware,
        ordersApi.middleware,
        incredibotApi.middleware,
        insuranceProgramsApi.middleware,
        storesApi.middleware,
        usersApi.middleware,
        refundsApi.middleware,
        shippingProtectionPlansApi.middleware,
        accountsApi.middleware,
        virtualCardsApi.middleware,
        conversationsApi.middleware,
        oktaApi.middleware,
        usersV3Api.middleware,
        grantsApi.middleware,
        productsRdbApi.middleware,
        productCategoriesRdbApi.middleware,
        plansTermsApi.middleware,
        wdOrchestratorApi.middleware,
      ),
    preloadedState: preLoadedState ?? loadState(),
  })

  sagaMiddleware.run(rootSaga)

  if (EXTEND_ENV === 'local' && module.hot) {
    module.hot.accept('../reducers', () => store.replaceReducer(rootReducer))
  }

  store.subscribe(() => {
    saveState(store.getState())
  })

  return store
}
