import type { FC, SyntheticEvent } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { Checkbox, InlineAlert } from '@helloextend/merchants-ui'
import { FormTextGroupItem } from './form-text-group-item'
import type { ChangeFunction, SetFieldFunction, InputItem } from './types'

type CollapsibleFormTextGroupProps = {
  'data-cy'?: string
  warning?: string
  handleChange: ChangeFunction & SetFieldFunction
  handleBlur: (e: SyntheticEvent) => void
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
  isDisabled?: boolean
  isStatic?: boolean
  sectionError?: boolean
  errorMessage?: string
  values: InputItem[]
  numColumns?: number
  className?: string
  autoFocus?: boolean
  copyLabel?: string
  onCopyChange?: (checked: boolean) => void
  copyChecked?: boolean
  subsectionTitle?: string
}

const calculateSpacingPerItem = (values: InputItem[], columns: number): number[] => {
  let columnsInRow = 0
  return values.map((input: InputItem) => {
    columnsInRow += input.columnCount ?? 1
    if (columnsInRow % columns === 0) {
      columnsInRow = 0
      return 0
    }
    // 16px is the base spacing between items
    return (16 * (columns - 1)) / (columns - 1)
  })
}

const CollapsibleFormTextGroup: FC<CollapsibleFormTextGroupProps> = ({
  'data-cy': dataCy,
  warning,
  sectionError,
  errorMessage,
  handleChange,
  handleBlur,
  setFieldValue,
  isDisabled = false,
  isStatic = false,
  values,
  numColumns = 1,
  className,
  autoFocus,
  copyLabel,
  onCopyChange,
  copyChecked = false,
  subsectionTitle,
}) => {
  const firstActiveInputIndex = useMemo(() => {
    return values.findIndex((v) => !v.isItemDisabled)
  }, [values])

  const margins = useMemo(() => {
    return calculateSpacingPerItem(values, numColumns)
  }, [numColumns, values])

  const handleCopyChange = (e: SyntheticEvent<HTMLInputElement>): void => {
    if (onCopyChange) {
      onCopyChange(e.currentTarget.checked)
    }
  }

  return (
    <Container data-cy={dataCy} className={className}>
      {subsectionTitle && <SubSectionTitle>{subsectionTitle}</SubSectionTitle>}

      {warning && (
        <WarningWrapper>
          <InlineAlert type="warning" message={warning} hasIcon />
        </WarningWrapper>
      )}
      {sectionError && errorMessage && (
        <WarningWrapper>
          <InlineAlert type="error" message={errorMessage} />
        </WarningWrapper>
      )}
      {!isStatic && copyLabel && (
        <InputGroup css={{ padding: '1.2em 0 1.5em' }}>
          <Checkbox onChange={handleCopyChange} checked={copyChecked} label={copyLabel} />
        </InputGroup>
      )}
      <InputGroup>
        {values.map((item, i) => (
          <FormTextGroupItem
            key={item.label}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            isDisabled={sectionError || isDisabled}
            isStatic={isStatic}
            numColumns={numColumns}
            item={item}
            margin={margins[i]}
            autoFocus={firstActiveInputIndex === i && autoFocus}
          />
        ))}
      </InputGroup>
    </Container>
  )
}

const InputGroup = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: 16,
})

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
})

const WarningWrapper = styled.div({
  marginBottom: 24,
})

const SubSectionTitle = styled.h2({
  fontSize: 20,
  fontWeight: 'bold',
  paddingTop: '0.5em',
})

export { CollapsibleFormTextGroup }
