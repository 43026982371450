import type { FC } from 'react'
import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import { COLOR, Checkbox } from '@helloextend/zen'
import type { CheckboxFilterValues } from './types'

type CheckboxFilterProps = {
  onFilterChange: (property: string, values: CheckboxFilterValues | null) => void
  property: string
  values?: CheckboxFilterValues
  options: Record<string, string>
}

const CheckboxFilter: FC<CheckboxFilterProps> = ({
  property,
  options,
  values = {
    type: 'checkbox',
    values: [],
  },
  onFilterChange,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      let selectedValues = values.values

      const { value, checked } = e.target

      if (!checked) {
        selectedValues = selectedValues.filter((v) => v !== value)
      } else if (!selectedValues.includes(value)) {
        selectedValues = [...selectedValues, value]
      }

      onFilterChange(
        property,
        selectedValues.length > 0
          ? {
              type: 'checkbox',
              values: selectedValues,
            }
          : null,
      )
    },
    [property, values, onFilterChange],
  )

  if (!Object.keys(options).length) {
    return <NoOptionsWrapper>No options</NoOptionsWrapper>
  }

  return (
    <>
      {Object.entries(options).map(([value, label]) => (
        <CheckboxWrapper key={value}>
          <Checkbox
            checked={values.values.includes(value, 0) ?? false}
            label={label}
            onChange={handleChange}
            value={value}
          />
        </CheckboxWrapper>
      ))}
    </>
  )
}
const CheckboxWrapper = styled.div({
  padding: '8px 16px',
  input: {
    display: 'none',
  },
  '&:hover': {
    backgroundColor: COLOR.NEUTRAL[100],
  },
})

const NoOptionsWrapper = styled.div({
  padding: '8px 16px',
  color: COLOR.NEUTRAL[600],
})

export { CheckboxFilter }
