import type { Store } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const create = (data: DeepPartial<Store>, accessToken: string) =>
  ({
    type: 'STORES_CREATE',
    payload: { data, accessToken },
  } as const)

export const createRequest = () => ({ type: 'STORES_CREATE_REQUEST' } as const)

export const createSuccess = (store: Store) =>
  ({
    type: 'STORES_CREATE_SUCCESS',
    payload: store,
  } as const)

export const createFailure = createActionFailure('STORES_CREATE_FAILURE')
