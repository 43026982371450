import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface ClaimBreadcrumbsState {
  claimsUrl: string
}

const initialState: ClaimBreadcrumbsState = {
  claimsUrl: '',
}

const claimBreadcrumbsSlice = createSlice({
  name: 'ClaimsBreadcrumbs',
  initialState,
  reducers: {
    setClaimsUrl(state, action: PayloadAction<string>) {
      return {
        ...state,
        claimsUrl: action.payload,
      }
    },
  },
})

const getClaimsUrl = (state: ClaimBreadcrumbsState): string => {
  return state.claimsUrl
}

const claimBreadcrumbsReducer = claimBreadcrumbsSlice.reducer
const { setClaimsUrl } = claimBreadcrumbsSlice.actions

export { claimBreadcrumbsReducer, setClaimsUrl, getClaimsUrl, ClaimBreadcrumbsState }
