import { faker } from '@faker-js/faker/locale/en'
import type { ClaimPhotoDetail, ClaimPhotosCreateRequest } from '@helloextend/extend-api-client'
import type { PresignedPost } from 'aws-sdk/clients/s3'

export function generateClaimPhotosCreateRequest(
  overrides: Partial<ClaimPhotosCreateRequest> = {},
): ClaimPhotosCreateRequest {
  return {
    createdBy: faker.internet.exampleEmail(),
    name: 'tire_tread_depth',
    source: 'ops_admin',
    ...overrides,
  }
}

export function generatePresignedPost(): PresignedPost {
  return {
    url: 'https://www.extend.com',
    fields: {
      Policy: 'string',
      'X-Amz-Signature': 'string',
    },
  }
}

export function generateClaimPhotoDetails(): ClaimPhotoDetail[] {
  return [
    {
      content: {
        description: `1. Photo showing current tread depth of failed tire. To do this, place a penny into a
  tread groove upside down.`,
      },
      name: 'wheel_damage_close_up',
    },
    {
      content: { description: `2. Close up photo showing manufacturer and model/size of tire.` },
      name: 'wheel_damage_wide',
    },
    {
      content: { description: `3. Photo showing road hazard damage to tire.` },
      name: 'wheel_damage_wide',
    },
    {
      content: { description: `4. Photo showing full side view of vehicle and wheels.` },
      name: 'wheel_full_side_view',
    },
  ]
}
