import styled from '@emotion/styled'
import type {
  Collect,
  Message,
  Option,
  Prompt,
  PromptType,
  ScriptItem,
} from '@helloextend/extend-api-rtk-query'
import { MessageType } from '@helloextend/extend-api-rtk-query'
import { COLOR } from '@helloextend/zen'
import type { FC } from 'react'
import React from 'react'
import { getIsReplacementReply, getMessageBlockTitle } from '../../utils'
import { AdjudicationMessage } from './adjudication-message'
import { renderPromptElements } from './prompt-types/adjudication-prompt-utils'

interface MessageTemplateProps {
  scriptItem: Required<ScriptItem>
  onBadgeClick: (val: number | string) => void
}

const AdjudicationMessageTemplate: FC<MessageTemplateProps> = ({ scriptItem, onBadgeClick }) => {
  const { reply, collect } = scriptItem

  const messages = getIsReplacementReply(reply)
    ? [
        {
          content: `The content here is dynamic based on the customer input. This message block doesn't represent what consumers see in Kaley.`,
          type: MessageType.text,
        },
      ]
    : reply.messages

  const prompt = getIsReplacementReply(reply)
    ? { type: 'replacement' as PromptType, slot: 'REPLACEMENT' }
    : reply.prompt

  const bubbleText = isEndOfConversation(collect, prompt) ? 'End of Conversation' : undefined

  return (
    <TemplateWrapper>
      <HeadingRow>
        <MessageTitle>{getMessageBlockTitle(prompt?.type)}</MessageTitle>
        {bubbleText && (
          <BubbleRow data-cy="adjudication-management-statement-bubble-row">
            <Bubble />
            <BubbleText>{bubbleText}</BubbleText>
          </BubbleRow>
        )}
      </HeadingRow>
      <MessagesWrapper>
        {messages.map((message: Message, index: number) => {
          return (
            <AdjudicationMessage
              key={JSON.stringify(message)}
              message={message}
              isReplacementReply={getIsReplacementReply(reply)}
              messageIndex={index}
            />
          )
        })}
        {prompt && renderPromptElements({ reply: { messages, prompt }, collect }, onBadgeClick)}
      </MessagesWrapper>
    </TemplateWrapper>
  )
}

function isEndOfConversation(collect: Collect, prompt?: Prompt): boolean {
  return !!collect.options.find((option: Option) => {
    return option.action === 'stop' && !prompt
  })
}

const TemplateWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const HeadingRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const MessageTitle = styled.div({
  color: COLOR.NEUTRAL[600],
  fontSize: 10,
  lineHeight: '18px',
  marginLeft: 4,
})

const BubbleRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
})

const Bubble = styled.div({
  width: 10,
  height: 10,
  borderRadius: '50%',
  background: COLOR.NEUTRAL[600],
})

const BubbleText = styled.div({
  marginLeft: 4,
  color: COLOR.NEUTRAL[700],
  fontWeight: 700,
  fontSize: 13,
  lineHeight: '18px',
})

const MessagesWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  margin: '8px 0 0 0',
})

export { AdjudicationMessageTemplate }
