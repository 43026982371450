import type { UploadValidatorPipe } from './csv-validation'
import { getColumnValue } from './csv-validation'

const validateNameColumn = (validationPipeResults: UploadValidatorPipe): UploadValidatorPipe => {
  const nameValue = getColumnValue(validationPipeResults, 'skuName')
  if (nameValue === '') {
    validationPipeResults.errors.push(`Name is required`)
  }
  return validationPipeResults
}

export { validateNameColumn }
