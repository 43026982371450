import type { FC } from 'react'
import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { Close } from '../../tokens/icons'
import type { IconProps } from '../../tokens/icons/icon-props'
import { Button } from '../button'
import { Icon, IconSize } from '../icon'
import type { ToastColor } from './types'
import { ToastDuration } from './types'
import { toastColors } from './toast-colors'
import { Timer } from '../../utils'

interface ToastProps {
  message: React.ReactNode
  onDismiss: () => void
  toastColor: ToastColor
  toastDuration: ToastDuration
  icon?: IconProps
  'data-cy'?: string
}

const Toast: FC<ToastProps> = ({
  message,
  onDismiss,
  toastColor,
  toastDuration,
  icon,
  'data-cy': dataCy,
}) => {
  const timer = useRef<Timer | null>(null)
  const [isPaused, setIsPaused] = useState(false)

  const durationMs = useMemo(() => {
    switch (toastDuration) {
      case ToastDuration.indefinite:
        return 0
      case ToastDuration.long:
        return 10000
      case ToastDuration.short:
      default:
        return 5000
    }
  }, [toastDuration])

  useEffect(() => {
    if (timer.current) {
      timer.current.clear()
      timer.current = null
    }

    if (durationMs) {
      timer.current = new Timer(() => {
        onDismiss()
      }, durationMs)
    }

    return () => {
      if (timer.current) {
        timer.current.clear()
        timer.current = null
      }
    }
  }, [durationMs, onDismiss])

  useEffect(() => {
    if (!timer.current) return

    if (isPaused) {
      timer.current.pause()
    } else {
      timer.current.resume()
    }
  }, [isPaused])

  const onClick = useCallback(() => {
    onDismiss()
  }, [onDismiss])

  const handleOnMouseEnter = useCallback(() => {
    setIsPaused(true)
  }, [])

  const handleOnMouseLeave = useCallback(() => {
    setIsPaused(false)
  }, [])

  return (
    <StyledToast
      toastColor={toastColor}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      data-cy={dataCy}
    >
      <LayoutLeft>
        {!!icon && (
          <Icon icon={icon} color={toastColors[toastColor].iconColor} size={IconSize.small} />
        )}
        <div>{message}</div>
      </LayoutLeft>
      <InlineWithText>
        <Button
          emphasis="low"
          color={toastColors[toastColor].buttonColor}
          size="small"
          icon={Close}
          onClick={onClick}
        />
      </InlineWithText>
      {!!durationMs && (
        <DurationContainer toastColor={toastColor}>
          <DurationIndicator toastColor={toastColor} durationMs={durationMs} isPaused={isPaused} />
        </DurationContainer>
      )}
    </StyledToast>
  )
}

const StyledToast = styled.div(({ toastColor }: { toastColor: ToastColor }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: 8,
  padding: 16,
  borderRadius: 6,
  lineHeight: '20px',
  fontSize: 15,
  backgroundColor: toastColors[toastColor].background,
  color: toastColors[toastColor].font,
  maxWidth: 480,
  minWidth: 240,
  boxSizing: 'border-box',
  overflow: 'hidden',
  pointerEvents: 'all',
  marginBottom: 8,
  boxShadow: '0 8px 24px rgba(0,0,0,0.08)',
  '& a': {
    color: toastColors[toastColor].font,
  },
}))

const LayoutLeft = styled.div({
  display: 'flex',
  gap: 8,
})

const InlineWithText = styled.div({
  margin: -6,
})

const DurationContainer = styled.div(({ toastColor }: { toastColor: ToastColor }) => ({
  backgroundColor: toastColors[toastColor].indicatorContainer,
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: 4,
}))

const DurationIndicator = styled.div(
  ({
    toastColor,
    durationMs,
    isPaused,
  }: {
    toastColor: ToastColor
    durationMs: number
    isPaused: boolean
  }) => ({
    backgroundColor: toastColors[toastColor].indicator,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    animation: `reducewidth ${durationMs}ms linear forwards`,
    '@keyframes reducewidth': {
      from: { width: '100%' },
      to: { width: 0 },
    },
    ...(isPaused && {
      animationPlayState: 'paused',
    }),
  }),
)

export { Toast, ToastProps }
