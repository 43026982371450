import type { FC } from 'react'
import React, { useState, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import {
  Breadcrumbs,
  COLOR,
  InlineAlert,
  Accordion,
  InlineAlertColor,
  Error,
} from '@helloextend/zen'
import type { ConversationConfigCreateRequest } from '@helloextend/extend-api-rtk-query'
import {
  useCreateConversationConfigurationMutation,
  AdjudicationCategory,
  useListConfigurationsQuery,
} from '@helloextend/extend-api-rtk-query'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useHistory } from 'react-router'
import { useStandardToast } from '@helloextend/merchants-ui'
import type { ConfigurationFormValues } from './conversation-configuration-form'
import { ConversationConfigurationForm } from './conversation-configuration-form'
import { useWaitForEvent } from '../../../../hooks/use-wait-for-event'

const ConversationConfigurationCreate: FC = () => {
  const [isInlineAlertVisible, setIsInlineAlertVisible] = useState(false)
  const [isWaitingToRedirect, setIsWaitingToRedirect] = useState(false)
  const [configurationId, setConfigurationId] = useState<string>('')
  const { push } = useHistory()
  const { toastCompleted, toastError } = useStandardToast()
  const [saveConfiguration, { isLoading: isCreateProcessing, reset }] =
    useCreateConversationConfigurationMutation()
  const { data: configList, refetch: refetchConfigList } = useListConfigurationsQuery()

  const createSuccessCallback = useCallback(() => {
    toastCompleted('A conversation configuration has been successfully created.')
    push('/admin/adjudication-management/conversation-configuration')
  }, [toastCompleted, push])

  const errorCallback = useCallback(() => {
    toastError('Something went wrong. Please refresh the page.')
    push('/admin/adjudication-management/conversation-configuration')
  }, [toastError, push])

  const hasCreateEventProcessed = useMemo(() => {
    if (configList) {
      return Boolean(configList.find((config) => config.id === configurationId))
    }
    return false
  }, [configList, configurationId])

  const { waitForUpdate } = useWaitForEvent({
    successCallback: createSuccessCallback,
    errorCallback,
    refetchCallback: refetchConfigList,
    hasEventProcessed: hasCreateEventProcessed,
  })

  const handleToggleInlineAlert = (): void => {
    setIsInlineAlertVisible(!isInlineAlertVisible)
  }

  const handleCreateConfiguration = async (
    configuration: ConfigurationFormValues,
  ): Promise<void> => {
    const configRequest: ConversationConfigCreateRequest = {
      category: AdjudicationCategory[configuration.category as keyof typeof AdjudicationCategory],
      conversationId: configuration.conversation,
      storeId: configuration.storeId,
      planId: configuration.planId,
    }

    try {
      const { id } = await saveConfiguration(configRequest).unwrap()
      setIsWaitingToRedirect(true)
      setConfigurationId(id)
      waitForUpdate()
    } catch (error) {
      const { status } = error as FetchBaseQueryError
      if (status === 409) {
        handleToggleInlineAlert()
      } else {
        toastError('Something went wrong. Please try again.')
        reset()
      }
    }
  }

  return (
    <Wrapper>
      <Breadcrumbs
        crumbs={[
          {
            text: 'Adjudication Management',
            to: '/admin/adjudication-management',
          },
          {
            text: `Conversation Configurations`,
            to: `/admin/adjudication-management/conversation-configuration`,
          },
          {
            text: 'Create new configuration',
          },
        ]}
        data-cy="user-details-breadcrumbs"
      />
      <Header data-cy="create-configuration-header">Create new configuration</Header>
      <Subheader>Learn more about the configuration settings.</Subheader>
      <InlineAlertWrapper isInlineAlertVisible={isInlineAlertVisible}>
        <Accordion isExpanded={isInlineAlertVisible} transitionDurationMs={250}>
          <InlineAlert
            color={InlineAlertColor.red}
            icon={Error}
            data-cy="create-configuration-inline-alert"
          >
            <InlineAlertText>The configuration already exists.</InlineAlertText>
          </InlineAlert>
        </Accordion>
      </InlineAlertWrapper>
      <FormWrapper>
        <ConversationConfigurationForm
          isSaveProcessing={isCreateProcessing || isWaitingToRedirect}
          handleSave={handleCreateConfiguration}
          mode="create"
          isInlineAlertVisible={isInlineAlertVisible}
          toggleInlineAlert={handleToggleInlineAlert}
        />
      </FormWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const Header = styled.h2({
  weight: 700,
  fontSize: 24,
  lineHeight: '32px',
  color: COLOR.BLUE[1000],
})

const Subheader = styled.h6({
  color: COLOR.NEUTRAL[800],
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 400,
  margin: `0 0 24px`,
})

const FormWrapper = styled.div({
  maxWidth: 708,
})

const InlineAlertWrapper = styled.div<{ isInlineAlertVisible: boolean }>(
  ({ isInlineAlertVisible }) => ({
    maxWidth: 708,
    paddingBottom: isInlineAlertVisible ? 24 : 0,
  }),
)

const InlineAlertText = styled.p({
  fontWeight: 400,
  fontSize: 15,
  lineHeight: '20px',
  margin: 0,
})

export { ConversationConfigurationCreate }
