import type { Theme } from '@emotion/react'
import { consumers } from './consumers'
import { merchants } from './merchants'

export type ThemeName = 'consumers' | 'merchants'

export const getThemeByName = (name: ThemeName): Theme => {
  const themeMap: Record<ThemeName, Theme> = {
    consumers,
    merchants,
  }

  return themeMap[name]
}
