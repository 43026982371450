import { faker } from '@faker-js/faker/locale/en'
import { AccountStatus } from '@helloextend/extend-api-rtk-query'
import type { AccountEnterprise, AccountLegacy } from '@helloextend/extend-api-rtk-query'

export function generateAccountEnterprise(
  account?: Partial<Omit<AccountEnterprise, 'createdAt' | 'editedAt'>>,
): AccountEnterprise {
  return {
    id: account?.id ?? faker.datatype.uuid(),
    name: account?.name ?? `${faker.company.name()}`,
    phoneNumber: account?.phoneNumber ?? `${faker.phone.number('###-###-####')}`,
    status: AccountStatus.Approved,
    ...(Math.random() < 0.5 && { url: account?.url ?? faker.internet.url() }), // generate url if not provided 50/50 chance
    createdAt: new Date().getTime(),
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function generateLegacyAccount(account?: Partial<Omit<AccountLegacy, 'createdAt'>>): any {
  return {
    id: account?.id ?? faker.datatype.uuid(),
    name: account?.name ?? `${faker.company.name()}`,
    features: account?.features ?? 'merchant',
    terms: account?.terms ?? {
      acceptedAt: new Date().getTime(),
      ipAddress: 'ipAddress',
      ppVersion: 'ppVersion',
      tosVersion: 'tosVersion',
    },
    isLegacyAccount: true,
    createdAt: new Date().getTime(),
  }
}
