import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { Fee } from '@helloextend/extend-api-client'
import {
  CellText,
  ColumnHead,
  EmptyTableMessage,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@helloextend/merchants-ui'
import { Button, COLOR } from '@helloextend/zen'
import { Trash } from '@helloextend/zen/src/tokens/icons'
import { tableColumns } from './table-config'

type InsuranceProgramFeesTableProps = {
  fees: Fee[]
  obligorIndex: number
  handleRemoveFee: (index: number) => void
  isDisabled?: boolean
}

const InsuranceProgramFeesTable: FC<InsuranceProgramFeesTableProps> = ({
  fees,
  obligorIndex,
  handleRemoveFee,
  isDisabled = false,
}) => {
  return (
    <TableWrapper>
      <TableHead>
        {tableColumns.map((column) => (
          <ColumnHead key={column.Header}>{column.Header}</ColumnHead>
        ))}
        <ColumnHead columnWidth={20} active={false} />
      </TableHead>
      <TableBodyWrapper>
        {(!fees || !fees.length) && (
          <EmptyTableMessage cols={tableColumns.length} message="No fees added." />
        )}
        {fees.map((row: Fee, index) => (
          <TableRowWrapper
            key={`${row.type}-${row.amount}`}
            data-cy={`fee-${obligorIndex}-${index}`}
          >
            {tableColumns.map((column) => {
              const cellData = row[column.accessor as keyof typeof row]
              const element = column.Cell?.(row) ?? <CellText>{`${cellData}`}</CellText>
              return (
                <TableCell
                  columnWidth={column.columnWidth ?? 100}
                  key={`${column.accessor}-${cellData}`}
                >
                  {element}
                </TableCell>
              )
            })}
            <TableCell align="center" key={`delete-${row.type}-${row.amount}`}>
              <Button
                icon={Trash}
                color="neutral"
                size="small"
                onClick={() => handleRemoveFee(index)}
                emphasis="low"
                data-cy={`remove-fee-button-${obligorIndex}-${index}`}
                isDisabled={isDisabled}
              />
            </TableCell>
          </TableRowWrapper>
        ))}
      </TableBodyWrapper>
    </TableWrapper>
  )
}

const TableBodyWrapper = styled(TableBody)({
  borderWidth: '1px 0 0 0 ',
})

const TableRowWrapper = styled(TableRow)({
  borderBottom: 0,
})

const TableWrapper = styled(Table)({
  marginTop: 24,
  th: {
    textTransform: 'none',
    lineHeight: '2',
    color: COLOR.NEUTRAL[800],
  },
})

export { InsuranceProgramFeesTable }
