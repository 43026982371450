import styled from '@emotion/styled'
import type { Product } from '@helloextend/extend-api-client'
import { useUpdateProductMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import { Grid, Input } from '@helloextend/zen'
import { useFormik } from 'formik'
import type { FC } from 'react'
import React from 'react'
import { SaveChangesButtonGroup } from '../../../../settings/components/save-changes-button-group'
import type { Values } from './schema'
import { schema } from './schema'

type ProductIdentifiersFormProps = {
  product: Product
}

const ProductIdentifiersForm: FC<ProductIdentifiersFormProps> = ({ product }) => {
  const { values, errors, dirty, isValid, handleChange, handleBlur, handleReset, handleSubmit } =
    useFormik<Values>({
      initialValues: {
        isbn: product?.identifiers?.isbn,
        sku: product?.identifiers?.sku,
        barcode: product?.identifiers?.barcode,
        gtin: product?.identifiers?.gtin,
        upc: product?.identifiers?.upc,
        asin: product?.identifiers?.asin,
      },
      onSubmit: () => {},
      validationSchema: schema,
      enableReinitialize: true,
    })
  const { toastError, toastCompleted } = useStandardToast()

  const [updateProduct, { isLoading }] = useUpdateProductMutation()

  const handleUpdateProduct = async (): Promise<void> => {
    try {
      await updateProduct({
        version: 'latest',
        productId: product.referenceId,
        storeId: product.storeId,
        data: {
          identifiers: {
            isbn: values.isbn,
            sku: values.sku,
            barcode: values.barcode,
            gtin: values.gtin,
            upc: values.upc,
            asin: values.asin,
          },
        },
      }).unwrap()

      toastCompleted('Product Settings have been updated successfully.')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error as Error)
      toastError('Product Settings could not be updated. Please try again.')
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <Grid columns={4} spacing={2}>
        <Input
          id="isbn"
          value={values.isbn || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter brand name"
          label="Brand"
          isError={Boolean(errors.isbn)}
          errorFeedback={errors.isbn}
        />
        <Input
          id="sku"
          value={values.sku || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter SKU"
          label="SKU"
          isError={Boolean(errors.sku)}
          errorFeedback={errors.sku}
        />
        <Input
          id="barcode"
          value={values.barcode || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter barcode"
          label="Barcode"
          isError={Boolean(errors.barcode)}
          errorFeedback={errors.barcode}
        />
        <Input
          id="gtin"
          value={values.gtin || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter GTIN"
          label="GTIN"
          isError={Boolean(errors.gtin)}
          errorFeedback={errors.gtin}
        />
        <Input
          id="upc"
          value={values.upc || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter UPC"
          label="UPC"
          isError={Boolean(errors.upc)}
          errorFeedback={errors.upc}
        />
        <Input
          id="asin"
          value={values.asin || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter ASIN"
          label="ASIN"
          isError={Boolean(errors.asin)}
          errorFeedback={errors.asin}
        />
      </Grid>
      {dirty && (
        <ButtonGroup>
          <SaveChangesButtonGroup
            id="manufacturer-info-save-buttons-group"
            isSaveDisabled={!dirty || !isValid}
            handleCancel={handleReset}
            handleSave={handleUpdateProduct}
            isLoading={isLoading}
            saveButtonText="Save Changes"
          />
        </ButtonGroup>
      )}
    </form>
  )
}

const ButtonGroup = styled.div({
  marginTop: 24,
})

export { ProductIdentifiersForm }
