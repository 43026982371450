import type { FC } from 'react'
import React from 'react'
import { COLOR } from '@helloextend/client-branding'

type ServicersIconProps = {
  fill?: string
}

/**
 * @deprecated Use Zen Icon component instead: `import { Icon } from '@helloextend/zen'`
 */
const ServicersIcon: FC<ServicersIconProps> = ({ fill = COLOR.BLACK_MUTED }) => (
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 1.25H5.32599C5.59928 1.25 5.86503 1.33956 6.08258 1.50497L7.25 2.39265V3.67648L6.08258 4.56415C5.86503 4.72956 5.59928 4.81912 5.32599 4.81912H1C0.861929 4.81912 0.75 4.7072 0.75 4.56912V1.5C0.75 1.36193 0.861929 1.25 1 1.25ZM7.5397 1.67074L6.53653 0.907958C6.18846 0.643299 5.76325 0.5 5.32599 0.5H1C0.447715 0.5 0 0.947716 0 1.5V4.56912C0 5.12141 0.447715 5.56912 1 5.56912H2.51005L2.16991 7.93993L1.25082 8.87173L1.15 9.11751V9.62442V11.1452L1.5 11.4952H6.5L6.85 11.1452V9.62442V9.11751L6.67045 8.81182L5.76136 8.30491L5.07041 7.91963L5.48087 5.63091L5.4932 5.56212C5.87086 5.53044 6.23302 5.39194 6.53653 5.16117L7.5397 4.39839H7.7002H9.0002L9.35019 4.04839V3.40956H9.5H11.3C11.5071 3.40956 11.675 3.24167 11.675 3.03456C11.675 2.82746 11.5071 2.65956 11.3 2.65956H9.5H9.35019V2.02074L9.0002 1.67074H7.7002H7.5397ZM4.78078 5.56912H3.21722L3.21009 5.61883L2.84645 8.15339L2.74918 8.34947L1.85 9.26108V9.27442H6.06273L5.42046 8.91629L4.51136 8.40938L4.33731 8.0419L4.78078 5.56912ZM8 3.69839V2.37074H8.6502V3.69839H8ZM1.85 9.97442H6.15V10.7952H1.85V9.97442Z"
      fill={fill}
    />
  </svg>
)

export { ServicersIcon }
