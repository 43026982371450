import { round } from './math'

interface RgbColor {
  r: number
  g: number
  b: number
}

interface HsvColor {
  h: number
  s: number
  v: number
}

/**
 * Takes a string and returns the six digit hex color code if one can be found.
 * If none can be found, then returns an empty string.
 * Examples:
 * "ABC"     -> "#AABBCC",
 * "#a12345" -> "#A12345",
 * "foo"     -> ""
 */
const parseHexColor = (value: string): string => {
  let hex = value.toUpperCase().replace(/[^A-F0-9]/g, '')

  if (hex.length === 3) {
    hex = [hex[0], hex[0], hex[1], hex[1], hex[2], hex[2]].join('')
  }

  return hex.length === 6 ? `#${hex}` : ''
}

const equalHsv = (first: HsvColor, second: HsvColor): boolean => {
  if (first === second) {
    return true
  }

  return first.h === second.h && first.s === second.s && first.v === second.v
}

const hexToHsv = (hex: string): HsvColor => rgbToHsv(hexToRgb(hex))

const hexToRgb = (input: string): RgbColor => {
  const parsed = parseHexColor(input)

  if (!parsed) {
    return {
      r: 0,
      g: 0,
      b: 0,
    }
  }

  const hex = parsed.substring(1)

  return {
    r: parseInt(hex.substring(0, 2), 16),
    g: parseInt(hex.substring(2, 4), 16),
    b: parseInt(hex.substring(4, 6), 16),
  }
}

const hsvToHex = (hsv: HsvColor): string => rgbToHex(hsvToRgb(hsv))

/**
 * Convert HSV (hue, saturation, value) to RGB (red, green, blue). Derived from
 * https://github.com/omgovich/react-colorful/blob/a85e5b36b55cae7e95c73c8ecde0bc881e8e3b1f/src/utils/convert.ts#L100
 */
const hsvToRgb = (input: HsvColor): RgbColor => {
  const h = (input.h / 360) * 6
  const s = input.s / 100
  const v = input.v / 100

  const hh = Math.floor(h)
  const b = v * (1 - s)
  const c = v * (1 - (h - hh) * s)
  const d = v * (1 - (1 - h + hh) * s)
  const module = hh % 6

  return {
    r: round([v, c, b, b, d, v][module] * 255),
    g: round([d, v, v, c, b, b][module] * 255),
    b: round([b, b, d, v, v, c][module] * 255),
  }
}

const hsvToRgbString = (hsv: HsvColor): string => {
  const rgb = hsvToRgb(hsv)
  return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`
}

const rgbValueToHexValue = (number: number): string => {
  const hex = Math.round(number).toString(16).toUpperCase()
  return hex.length < 2 ? `0${hex}` : hex
}

const rgbToHex = ({ r, g, b }: RgbColor): string => {
  return parseHexColor(
    [rgbValueToHexValue(r), rgbValueToHexValue(g), rgbValueToHexValue(b)].join(''),
  )
}

/**
 * Convert RGB (red, green, blue) to HSV (hue, saturation, value). Derived from
 * https://github.com/omgovich/react-colorful/blob/a85e5b36b55cae7e95c73c8ecde0bc881e8e3b1f/src/utils/convert.ts#L170
 */
const rgbToHsv = ({ r, g, b }: RgbColor): HsvColor => {
  const max = Math.max(r, g, b)
  const delta = max - Math.min(r, g, b)

  let hh = 0

  if (delta) {
    if (max === r) {
      hh = (g - b) / delta
    } else if (max === g) {
      hh = 2 + (b - r) / delta
    } else {
      hh = 4 + (r - g) / delta
    }
  }

  return {
    h: round(60 * (hh < 0 ? hh + 6 : hh), 2),
    s: round(max ? (delta / max) * 100 : 0, 2),
    v: round((max / 255) * 100, 2),
  }
}

export {
  parseHexColor,
  equalHsv,
  hexToHsv,
  hsvToRgbString,
  hexToRgb,
  rgbToHex,
  rgbToHsv,
  hsvToHex,
  hsvToRgb,
  RgbColor,
  HsvColor,
}
