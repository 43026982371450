import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/zen'
import type { Claim } from '@helloextend/extend-api-client'
import { ErrorDenial } from './error-denial'
import { ManualReview } from './manual-review'
import type { AdjudicationClaim } from './adjudication-rule'
import { AdjudicationRule } from './adjudication-rule'

// Typeguard to keep TS happy when we're rending this component
export function claimWithRuleset(claim: Claim): AdjudicationClaim {
  if (claim.ruleset !== undefined) {
    return claim as AdjudicationClaim
  }
  throw new Error("Claim object is missing the 'ruleset' property.")
}
type AdjudicationTabProps = {
  claim: Claim
}

const AdjudicationTab: FC<AdjudicationTabProps> = ({ claim }) => {
  const isInManualReview = useMemo(() => {
    return Boolean(claim && claim.status === 'review' && claim.conversationId)
  }, [claim])

  const isClaimDenied = useMemo(() => {
    return Boolean(claim && claim.validationError && !isInManualReview)
  }, [claim, isInManualReview])

  return (
    <Container>
      <Title data-cy="claim-adjudication-title">Adjudication</Title>
      {isInManualReview && <ManualReview />}
      {isClaimDenied && claim.validationError && (
        <ErrorDenial validationError={claim.validationError} />
      )}
      {claim && claim.ruleset !== undefined && <AdjudicationRule claim={claimWithRuleset(claim)} />}
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  marginBottom: 32,
  padding: 40,
})

const Title = styled.h2({
  fontWeight: 700,
  fontSize: 20,
  lineHeight: '28px',
})

export { AdjudicationTab }
