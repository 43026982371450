import type { RefObject } from 'react'
import { createContext, useContext } from 'react'

export interface ShellContextValue {
  getScrollableRegionRef: () => RefObject<HTMLElement>
  setViewportPadding: (padding: number | string | null) => void
}

export const ShellContext = createContext<ShellContextValue | null>(null)

export const useShellContext = (): ShellContextValue => {
  const context = useContext(ShellContext)

  if (!context) {
    throw new Error('useShellContext() can only be used within the subtree of a <Shell />')
  }

  return context
}
