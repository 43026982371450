import type { StoreUserRole } from '@helloextend/extend-api-client'
import type { LDFlagSet } from 'launchdarkly-js-sdk-common'
import type { LDFlag } from '../../constants/ld-flags'
import type { Permission, RuleDefinition } from './rules'
import { rules } from './rules'

export const getPermissions = (userRole: StoreUserRole | null, flags: LDFlagSet): Permission[] => {
  return Object.entries(rules)
    .filter(([, ruleDefinition]) => testRule(userRole, flags, ruleDefinition))
    .map(([permission]) => permission as Permission)
}

const testRule = (
  userRole: StoreUserRole | null,
  flags: LDFlagSet,
  { allowedRoles, requiredFeatureFlags }: RuleDefinition,
): boolean => {
  return (
    isAllowedUserRole(userRole, allowedRoles) &&
    matchesRequiredFeatureFlags(flags, requiredFeatureFlags)
  )
}

// Determine if the current user role matches the allowed roles for the permission rule
const isAllowedUserRole = (
  userRole: StoreUserRole | null,
  allowedRoles: StoreUserRole[],
): boolean => {
  if (!userRole) return false

  return allowedRoles.some((role) => role === userRole)
}

// Determine if the feature flags required for the rule are all enabled
const matchesRequiredFeatureFlags = (
  flags: LDFlagSet,
  requiredFeatureFlags?: LDFlag[],
): boolean => {
  if (!requiredFeatureFlags?.length) return true

  return requiredFeatureFlags.every((flag) => flags[flag])
}
