import type { FC } from 'react'
import React, { useEffect, createRef, useCallback } from 'react'
import { createPortal } from 'react-dom'
import type { StyledComponent } from '@emotion/styled'
import styled from '@emotion/styled'
import { Icon, COLOR } from '@helloextend/zen'
import { Close } from '@helloextend/zen/src/tokens/icons'
import { ModalBackground, ModalPosition, ModalPositioning } from './types'

type BasicModalProps = {
  isVisible: boolean
  onClickClose: () => void
  width?: string
  preventAutoFocus?: boolean
  'data-cy'?: string
  modalPosition?: keyof typeof ModalPositioning
  modalBackground?: keyof typeof ModalBackground
  excludeBackground?: boolean
  maxHeight?: string
  overflow?: string
}

/**
 * @deprecated
 */
const BasicModal: FC<BasicModalProps> = ({
  children,
  onClickClose,
  isVisible,
  width = '536px',
  preventAutoFocus = false,
  'data-cy': dataCy,
  modalPosition = ModalPosition.center,
  modalBackground = ModalBackground.default,
  excludeBackground = false,
  maxHeight = 'none',
  overflow = 'scroll',
}) => {
  const modalRef = createRef<HTMLDivElement>()

  const getFocusableElements = useCallback((): HTMLElement[] => {
    return Array.prototype.slice
      .call(modalRef.current?.querySelectorAll('select, input, textarea, button, a'), 0)
      .filter((element) => window.getComputedStyle(element).display !== 'none')
  }, [modalRef])

  // Adds key listeners
  useEffect(() => {
    const handleClickKeydown = (e: KeyboardEvent): void => {
      const focusableElements = getFocusableElements()
      const lastFocusableElement = focusableElements[focusableElements.length - 1]
      const firstFocusableElement = focusableElements[0]

      if (e.key === 'Escape') {
        onClickClose()
      }
      if (e.key !== 'Tab') {
        return
      }

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus()
          e.preventDefault()
        }
      } else if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus()
        e.preventDefault()
      }
    }

    if (modalRef.current && isVisible) {
      document.addEventListener('keydown', handleClickKeydown)
    }

    return () => {
      document.removeEventListener('keydown', handleClickKeydown)
    }
  }, [modalRef, onClickClose, getFocusableElements, isVisible])

  // Set focus on modal
  useEffect(() => {
    if (modalRef.current && isVisible) {
      const focusableElements = getFocusableElements()
      const inputNode = focusableElements.find(
        (el) =>
          el.tagName.toLowerCase() === 'input' ||
          el.tagName.toLowerCase() === 'textarea' ||
          el.tagName.toLowerCase() === 'select',
      )
      if (inputNode && !preventAutoFocus) {
        inputNode.focus()
      } else {
        focusableElements[0].focus()
      }
    }
  }, [modalRef, isVisible, getFocusableElements, preventAutoFocus])

  if (!isVisible) return null

  // Current selector for background. Can be refactored to a collection for extension
  const Background = BackgroundCollection[modalBackground]

  const modal = (
    <Modal
      aria-modal
      data-cy={dataCy}
      role="dialog"
      tabIndex={-1}
      ref={modalRef}
      width={width}
      position={modalPosition}
      maxHeight={maxHeight}
      overflow={overflow}
    >
      <CloseButton aria-labelledby="close-modal" onClick={onClickClose}>
        <Icon icon={Close} data-cy="close-icon" />
      </CloseButton>
      <Content>{children}</Content>
    </Modal>
  )

  return excludeBackground
    ? createPortal(<>{modal}</>, document.body)
    : createPortal(<Background>{modal}</Background>, document.body)
}

export const CloseButton = styled.button({
  background: 'none',
  border: 'none',
  position: 'absolute',
  right: 16,
  top: 16,
  cursor: 'pointer',
  padding: 0,
})

const Content = styled.div({
  display: 'block',
})

const Modal = styled.aside<{
  width?: string
  maxHeight: string
  position: keyof typeof ModalPosition
  overflow?: string
}>(({ width, position, maxHeight, overflow }) => ({
  background: COLOR.WHITE,
  borderRadius: 4,
  padding: '48px 32px 32px',
  overflow,
  width,
  maxHeight,
  ...ModalPositioning[position],
}))

const BaseBackground = styled.div({
  display: 'block',
  height: '100vh',
  position: 'absolute',
  width: '100vw',
  top: 0,
  left: 0,
  zIndex: 12,
})

const BackgroundCollection: Record<ModalBackground, StyledComponent<unknown, unknown, object>> = {
  [ModalBackground.default]: styled(BaseBackground)({
    background: 'rgba(0,0,0,0.5)',
  }),
  [ModalBackground.boxShadow]: styled(BaseBackground)({
    Modal: {
      border: `1px solid ${COLOR.NEUTRAL[300]}`,
      boxShadow: '0px 4px 40px 0px #0000001F',
    },
  }),
}

export { BasicModal }
