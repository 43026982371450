import type { Ref } from 'react'
import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/zen'

type AdjudicationTitleBlockProps = {
  onClick?: () => void
  ref?: Ref<HTMLDivElement>
}

const AdjudicationTitleBlock = forwardRef<HTMLDivElement, AdjudicationTitleBlockProps>(
  ({ onClick }, ref) => {
    return (
      <TitleWrapper ref={ref} data-cy="adjudication-title-block-wrapper">
        <TitleText onClick={onClick}>
          <>Thread Start</>
        </TitleText>
      </TitleWrapper>
    )
  },
)

const TitleWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: '44px',
})

const TitleText = styled.div({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  width: 360,
  padding: 16,
  background: COLOR.WHITE,
  borderRadius: 8,
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  transition: 'box-shadow 0.3s',
  '&:hover': {
    cursor: 'pointer',
  },
})

export { AdjudicationTitleBlock }
