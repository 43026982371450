import type { StoreUser } from '@helloextend/extend-api-client'

export type UpdateUserRequest = { userId: string; data: Partial<StoreUser> }

export type CreateUserRequest = Partial<StoreUser> & { portal?: Portal }

export type ResendInviteRequest = { email: string; portal?: Portal }

export type ChangePasswordRequest = { token: string; password: string }

export type TokenTypeIdentifier = 'urn:ietf:params:oauth:token-type:access_token'
export type ExchangeTokenRequest = {
  grant_type?: string
  subject_token?: string
  subject_token_type?: TokenTypeIdentifier
  ern?: string
  role?: string
  extend_token_ver?: 'V2' | 'V3'
}

export type ExchangeTokenResponse = {
  token_type: string
  access_token: string
  issued_token_type: string
}

export const Portals = Object.freeze({
  MERCHANT: 'merchant',
  INSTORE: 'instore',
} as const)
export type Portal = typeof Portals[keyof typeof Portals]
