import type { FC } from 'react'
import React, { useEffect, useState, useRef } from 'react'
import styled from '@emotion/styled'
import type { AdvancedSelectChangeEvent } from '@helloextend/zen'
import {
  ToastColor,
  ToastDuration,
  useToaster,
  Box,
  Grid,
  DataProperty,
  Stack,
  Button,
  Edit,
  COLOR,
  Input,
  InputType,
  AdvancedSelect,
} from '@helloextend/zen'
import { StackAlign, StackDirection } from '@helloextend/zen/src/components/stack'
import type { User } from '@helloextend/extend-api-rtk-query'
import {
  useCreateUserGrantMutation,
  useRevokeUserGrantMutation,
  useGetUserGrantsListQuery,
} from '@helloextend/extend-api-rtk-query'
import { useSelector } from 'react-redux'
import { DEFAULT_ERN } from './constants'
import {
  filterGrantsByAccount,
  getUserRolesFromGrants,
  legacyInternalOptions,
} from '../../../../utils/user-role-mapper'
import type { RootState } from '../../../../reducers'
import * as selectors from '../../../../reducers/selectors'
import { getUserRoleFromToken } from '../../../../lib/jwt'

type UserDetailsFormProps = {
  user: User
}
const UserDetailsForm: FC<UserDetailsFormProps> = ({ user }) => {
  const [isEditable, setIsEditable] = useState(false)
  const { toast, dismiss } = useToaster()
  const [selectedRoles, setSelectedRoles] = useState<string[]>([])
  const accessToken = useSelector((state: RootState) => selectors.getAccessToken(state))
  const showEditButton = getUserRoleFromToken(accessToken) === 'superadmin'
  const { data: userGrants, isLoading: isGettingGrants } = useGetUserGrantsListQuery(user.email)
  const [createGrant, { isLoading: isCreatingGrant, isError: isCreatingGrantError }] =
    useCreateUserGrantMutation()
  const [revokeGrant, { isLoading: isrevokingGrant, isError: isRevokingGrantError }] =
    useRevokeUserGrantMutation()

  const toastNumber = useRef<number>(0)

  const handleRolesEdit = (e: AdvancedSelectChangeEvent<string[]>): void => {
    setSelectedRoles(e.target.value)
  }

  const handleUserDetailsSave = async (): Promise<void> => {
    if (userGrants) {
      const grants = filterGrantsByAccount(userGrants.grants)
      const grantsTobeCreated = selectedRoles.filter((x) => !grants.map((g) => g.role).includes(x))

      const grantsTobeRevoked = grants
        .filter((x) => !selectedRoles.includes(x.role))
        .map((x) => x.role)

      await Promise.allSettled([
        grantsTobeCreated.map(async (x) =>
          createGrant({ userId: user.email, role: x, ern: DEFAULT_ERN }),
        ),
        grantsTobeRevoked.map(async (x) =>
          revokeGrant({ userId: user.email, role: x, ern: DEFAULT_ERN }),
        ),
      ])

      setIsEditable(false)
    }
  }

  // not very efficient might remove this later and organize the component better
  useEffect(() => {
    if (userGrants) {
      const roles = getUserRolesFromGrants(userGrants.grants, 'internal')
      setSelectedRoles(roles.map((x) => x.role))
    }
  }, [userGrants])

  useEffect(() => {
    if (isCreatingGrantError || isRevokingGrantError) {
      dismiss(toastNumber.current)
      toastNumber.current = toast({
        message: 'Something went wrong while editing grants',
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
      })
    }
  }, [dismiss, isCreatingGrantError, isRevokingGrantError, toast])

  if (!user) return null
  return (
    <Box padding={4} data-cy="user-details-form">
      <Stack align={StackAlign.start} direction={StackDirection.row} spacing={3}>
        <DetailsText>User Details</DetailsText>
        {!isEditable && showEditButton && (
          <EditButton>
            <Button
              color="blue"
              emphasis="low"
              icon={Edit}
              onClick={() => setIsEditable(true)}
              size="regular"
              text="Edit"
              data-cy="edit-button"
            />
          </EditButton>
        )}
      </Stack>

      <Stack align={StackAlign.stretch} direction={StackDirection.column} spacing={3}>
        <Grid
          columns={{
            lg: 3,
            md: 2,
            sm: 1,
          }}
          spacing={{
            lg: 3,
            md: 2,
            sm: 1,
          }}
        >
          {isEditable ? (
            <>
              <Input
                data-cy="first-name"
                id="firstName"
                label="First Name"
                type={InputType.text}
                isDisabled // first name is not editable
                placeholder="Please Add First Name"
                value={user.firstName}
              />

              <Input
                data-cy="last-name"
                id="lastName"
                label="Last Name"
                type={InputType.text}
                isDisabled // last name is not editable
                placeholder="Please Add Last Name"
                value={user.lastName}
              />

              <Input
                data-cy="email"
                id="email"
                label="Email"
                type={InputType.email}
                isDisabled // email is not editable
                placeholder="Please Add Email Address"
                value={user.email}
              />

              <AdvancedSelect
                data-cy="roles"
                id="roles"
                label="Role(s)"
                maxQuantityToDisplay={5}
                multiple
                onChange={handleRolesEdit}
                options={legacyInternalOptions}
                placeholder="Select roles"
                value={selectedRoles}
                isLoading={isCreatingGrant || isrevokingGrant}
              />
            </>
          ) : (
            <>
              <DataProperty label="First Name" value={user?.firstName} data-cy="first-name" />
              <DataProperty label="Last Name" value={user?.lastName} data-cy="last-name" />
              <DataProperty label="Email" value={user?.email} data-cy="email" />
              <DataProperty
                label="Role(s)"
                value={
                  userGrants
                    ? getUserRolesFromGrants(userGrants.grants, 'internal')
                        .map((x) => x.display)
                        .join(',  ')
                    : ''
                }
                data-cy="roles"
                isLoading={isGettingGrants}
              />
            </>
          )}
        </Grid>
      </Stack>
      {isEditable ? (
        <Stack align={StackAlign.stretch} direction={StackDirection.rowReverse} spacing={3}>
          <Button
            type="submit"
            color="blue"
            data-cy="save-changes-button"
            emphasis="high"
            isDisabled={
              selectedRoles.length === userGrants?.grants.length &&
              selectedRoles.every((x) => userGrants?.grants.map((g) => g.role).includes(x))
            }
            size="regular"
            text="Save Changes"
            onClick={handleUserDetailsSave}
          />
          <Button
            color="blue"
            data-cy="cancel-button"
            onClick={() => setIsEditable(false)}
            size="regular"
            text="Cancel"
          />
        </Stack>
      ) : null}
    </Box>
  )
}

const DetailsText = styled.p({
  width: '90%',
  minWidth: '50%',
  fontFamily: 'Nunito Sans',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '20px',
  lineHeight: '28px',
  color: COLOR.BLUE[1000],
})

const EditButton = styled.p({
  width: '10%',
})

export { UserDetailsForm }
