import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../base-query'
import type { GetStoresPlanCategoryRequest, GetStoresPlanCategoryResponse } from './types'

export const wdOrchestratorApi = createApi({
  reducerPath: 'WDOrchestratorAPI',
  baseQuery,
  endpoints: (build) => ({
    // downstream routes for /wd-orchestrator/stores/
    planCategoryIds: build.query<GetStoresPlanCategoryResponse, GetStoresPlanCategoryRequest>({
      query: ({ storeId }) => ({
        url: `/wd-orchestrator/stores/${storeId}/plan-categories`,
      }),
    }),
  }),
})

export const { useLazyPlanCategoryIdsQuery } = wdOrchestratorApi
