import { isArray, isPlainObject, isRegExp } from 'lodash/fp'

type BlackList = Array<string | RegExp>

const BLACKLIST: BlackList = [
  /authorization/i,
  /password/i,
  /access-?token/i,
  /provider_?/i,
  /email/i,
  /(first|last|full)[_-]?name/i,
  /phone/i,
  /address?/i,
  /browser_ip/i,
  /city/i,
  /zip/i,
  /province/i,
  /country/i,
  /company/i,
  /latitude/i,
  /longitude/i,
]

function isBlacklisted(key: string, blacklist: BlackList): boolean {
  for (const blacklistItem of blacklist) {
    if (isRegExp(blacklistItem) && blacklistItem.test(key)) {
      return true
    }
    if (blacklistItem === key) {
      return true
    }
  }
  return false
}

export const REDACTED = '[REDACTED]'

export function redact(obj: any, blacklist: BlackList = BLACKLIST): any {
  if (isArray(obj)) {
    return obj.map((o) => redact(o, blacklist))
  }
  return Object.entries(obj).reduce((memo, [key, value]) => {
    let returnValue = value
    if (isBlacklisted(key, blacklist)) {
      returnValue = REDACTED
    } else if (isPlainObject(value)) {
      returnValue = redact(value, blacklist)
    } else if (isArray(value) && isPlainObject(value[0])) {
      returnValue = value.map((v) => redact(v, blacklist))
    }
    if (typeof returnValue === 'string') {
      returnValue = returnValue.replace(/(accesstoken|token)[^&]+/i, REDACTED)
    }
    return { ...memo, [key]: returnValue }
  }, {})
}

export function redactJsonReplacer(key: string, value: any): any {
  if (isBlacklisted(key, BLACKLIST)) {
    return REDACTED
  }
  return value
}
