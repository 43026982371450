import type { PricingRow } from '../types/plans'

const convertPercentageToDecimal = (percentage: string): number => {
  if (!percentage.includes('%')) return Number(percentage)
  return Number(percentage.replace('%', '')) / 100
}

const cleanNumberInput = (input: string): number => {
  return Number(input.replace(/,/g, ''))
}

const mapClipboardToPricing = (data: string): PricingRow[] => {
  return data
    .split('\n')
    .map((row) => row.split('\t'))
    .filter((row) => row.length === 7)
    .map((row) => ({
      vendorSku: row[0],
      priceBandLow: cleanNumberInput(row[1]),
      priceBandHigh: cleanNumberInput(row[2]),
      cost: cleanNumberInput(row[3]),
      retailTarget: convertPercentageToDecimal(row[4]),
      fixedPrice: Number(row[5]) === 0 ? '' : cleanNumberInput(row[5]),
      retailMax: convertPercentageToDecimal(row[6]),
    }))
}

const mapPricingToClipboard = (data: PricingRow[]): string => {
  return data.reduce((previous: string, current: PricingRow) => {
    return `${previous}${current.vendorSku}\t${current.priceBandLow}\t${current.priceBandHigh}\t${
      current.cost
    }\t${current.retailTarget}\t${current.fixedPrice ?? ''}\t${current.retailMax}\n`
  }, '')
}

const mergePricingBySku = (currentData: PricingRow[], newData: PricingRow[]): PricingRow[] => {
  if (Object.keys(currentData).length === 1) return newData
  const mergedData = currentData.map((currentRow) =>
    Object.assign(
      currentRow,
      newData.find((newRow) => newRow.vendorSku === currentRow.vendorSku),
    ),
  )
  const filteredNewData = newData.filter(
    (newRow) => !mergedData.find((currentRow) => currentRow.vendorSku === newRow.vendorSku),
  )
  const finalData = [...mergedData, ...filteredNewData].filter((row) => row.vendorSku !== '')
  return finalData.sort((a, b) => (a.priceBandLow > b.priceBandLow ? 1 : -1))
}

const isClipboardCSV = (data: string): boolean => {
  const parsedData = data.split('\n').map((row) => row.split('\t'))
  // check if rows are either empty, or have the correct number of columns
  return parsedData.every((row) => row.length === 7 || (row.length === 1 && row[0] === ''))
}

export { isClipboardCSV, mapClipboardToPricing, mapPricingToClipboard, mergePricingBySku }
