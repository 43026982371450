import { debounce } from 'lodash'
import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import useResizeObserver from 'use-resize-observer'
import { useDataTableContext } from '../data-table-context'

export interface CellResizerProps {
  cellRef?: React.RefObject<HTMLTableCellElement>
}

export const CellResizer: FC<CellResizerProps> = ({ cellRef }) => {
  const { table } = useDataTableContext()

  const onResize = useMemo(
    () =>
      debounce(
        () => {
          const currentRef = cellRef?.current
          if (currentRef) {
            currentRef.style.left = 'unset'
            currentRef.style.left = `${currentRef.offsetLeft}px`
          }
        },
        200,
        { leading: true },
      ),
    [cellRef],
  )
  useResizeObserver<HTMLTableCellElement>({
    ref: cellRef,
    onResize,
  })

  const tableState = table.getState()
  useEffect(() => {
    onResize()
  }, [onResize, tableState])

  return <></>
}
