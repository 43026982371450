import type { SyntheticEvent } from 'react'
import React, { forwardRef } from 'react'
import styled from '@emotion/styled'
import { matchPath } from 'react-router'
import { Icon, IconSize } from '../../icon'
import { ArrowDropDown } from '../../../tokens/icons'
import type { IconProps } from '../../../tokens/icons/icon-props'
import { COLOR } from '../../../tokens/colors'

export interface HeaderMenuItemProps {
  children?: React.ReactNode
  icon?: IconProps
  showArrow?: boolean
  isToggled?: boolean
  tooltip?: string
  matchRouteTo?: string
  matchRouteExact?: boolean
  onClick?: (event: EventType) => void
  'data-cy'?: string
}

type EventType = SyntheticEvent<HTMLDivElement>

export const HeaderMenuItem = forwardRef<HTMLDivElement, HeaderMenuItemProps>(
  (
    {
      children,
      icon,
      showArrow = false,
      isToggled = false,
      tooltip,
      matchRouteTo,
      matchRouteExact = false,
      onClick,
      'data-cy': dataCy,
    },
    ref,
  ) => {
    const match = matchPath(matchRouteTo || '', {
      exact: matchRouteExact,
    })

    return (
      <StyledHeaderMenuItem
        ref={ref}
        data-cy={dataCy}
        isToggled={(!!matchRouteTo && !!match) || isToggled}
        data-tooltip={tooltip}
        onClick={onClick}
      >
        <Content>
          {!!icon && (
            <IconContainer>
              <Icon icon={icon} color={COLOR.WHITE} />
            </IconContainer>
          )}
          {children}
        </Content>
        {showArrow && (
          <ArrowContainer>
            <Icon icon={ArrowDropDown} size={IconSize.small} color={COLOR.WHITE} />
          </ArrowContainer>
        )}
      </StyledHeaderMenuItem>
    )
  },
)

const StyledHeaderMenuItem = styled.div<{
  isToggled: boolean
}>(({ isToggled }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 4,
  padding: `4px 10px`,
  border: 'none',
  color: COLOR.WHITE,
  cursor: 'pointer',
  borderRadius: 4,
  transition: '50ms',
  lineHeight: '32px',
  whiteSpace: 'nowrap',
  textDecoration: 'none',
  ...(isToggled
    ? {
        WebkitTextStroke: '0.04em',
        background: COLOR.NEUTRAL.OPACITY[12],
        '&:hover': {
          background: COLOR.NEUTRAL.OPACITY[25],
        },
        '&:active': {
          background: COLOR.NEUTRAL.OPACITY[12],
        },
      }
    : {
        background: 'transparent',
        '&:hover': {
          background: COLOR.NEUTRAL.OPACITY[12],
        },
        '&:active': {
          background: COLOR.NEUTRAL.OPACITY[25],
        },
      }),
}))

const Content = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
})

const IconContainer = styled.div({
  display: 'flex',
  padding: '4px 0',
  margin: '0 -2px',
})

const ArrowContainer = styled.div({
  display: 'flex',
  marginRight: -4,
})
