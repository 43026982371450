import type { PlanAttribute } from '@helloextend/extend-api-client'
import type { DropdownOption } from '../components/dropdown'

export interface PlanAttributesMap {
  [key: string]: DropdownOption[]
}
/**
 * @description transforms an array of plan attributes into a dictionary with plan attribute values to pass to dropdowns
 */
const toPlanAttributesDropdownOptions = (attributesList: PlanAttribute[]): PlanAttributesMap => {
  return attributesList?.reduce(
    (map, attribute) => ({
      ...map,
      [attribute.id]: [
        ...attribute.values.map((value) => ({
          value,
          label: value,
        })),
      ],
    }),
    {} as PlanAttributesMap,
  )
}

export { toPlanAttributesDropdownOptions }
