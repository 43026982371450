import type {
  ConversationResponse,
  DefaultMessage,
  DefaultReply,
  PromptType,
  RuleCreate,
  RulesetBase,
  RuleStatus,
  ScriptItem,
  ThreadListItem,
  ThreadResponse,
  ThreadTypes,
  Condition,
  Operator,
  NumericCondition,
  NumericConditionWithValue,
  NumericConditionWithScript,
  NumericInvokableFunction,
} from '@helloextend/extend-api-rtk-query'
import { MessageType } from '@helloextend/extend-api-rtk-query'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'
import { isEqual } from 'lodash/fp'
import {
  getReferenceCountForMessageBlock,
  getUnreferencedMessageBlocks,
  updateMessageBlockReferenceCountsForAddingMessageBlock,
  updateMessageBlockReferenceCountsForRemovingMessageBlock,
  isThreadTextAssigned,
  hasValidConditions,
} from './amp-validation'
import {
  findCollectOptionByInputPattern,
  findCollectOptionByRoutingMatch,
  updateCollectOptionRouting,
  findDefaultCollectOption,
  findDefaultCollectOptionIndex,
  updateCollectOptions,
  updatePatternsArray,
  getCollectOptionsIndexByPattern,
  identifyCondition,
  cleanEmptyCollectOptionsByPattern,
  generateNewCollectOption,
} from '../../pages/admin/adjudication-management/utils'
import type { FullConversation, MessageBlockReferenceMap } from '../../types/conversations'

interface SelectedMessageBlock {
  script: Required<ScriptItem>
  index: number
}

interface SingleUseThreadsMap {
  [key: string]: ThreadResponse
}

enum MessageBlockInsertionType {
  Start,
  End,
}

interface AMPState {
  selectedThread: ThreadResponse | null
  selectedMessageBlock: number | null
  placeholderMessageBlock: number | null
  isEditorPanelVisible: boolean
  isReusableThreadPickerVisible: boolean
  selectedConversation: ConversationResponse | null

  singleUseThreads: SingleUseThreadsMap
  conversationAdjudicationThread: ThreadResponse | null
  selectedThreadIdToReplace: string | null
  messageBlockReferenceCountMap: MessageBlockReferenceMap
  isPublishValidationModeActive: boolean
  isDuplicateThreadModalVisible: boolean
  selectedThreadListItem: ThreadListItem | null
  selectedRuleset: RulesetBase | null
}

interface ScriptItemUsage {
  script: ScriptItem
  scriptIndex: number
  inUse: boolean
}

const initialState: AMPState = {
  selectedThread: null,
  selectedMessageBlock: null,
  placeholderMessageBlock: null,
  isEditorPanelVisible: false,
  isReusableThreadPickerVisible: false,
  selectedConversation: null,
  singleUseThreads: {},
  conversationAdjudicationThread: null,
  selectedThreadIdToReplace: null,
  messageBlockReferenceCountMap: {},
  isPublishValidationModeActive: false,
  isDuplicateThreadModalVisible: false,
  selectedThreadListItem: null,
  selectedRuleset: null,
}

const ampSlice = createSlice({
  name: 'AMPSlice',
  initialState,
  reducers: {
    // Thread reducers
    setThread(state, action: PayloadAction<ThreadResponse>) {
      return {
        ...state,
        selectedThread: action.payload,
      }
    },
    setSelectedMessageBlock(state, action: PayloadAction<number | null>) {
      return {
        ...state,
        selectedMessageBlock: action.payload,
      }
    },
    setMessageBlockReferenceCountMap(state, action: PayloadAction<MessageBlockReferenceMap>) {
      Object.assign(state, { messageBlockReferenceCountMap: action.payload })
    },
    setPlaceholderMessageBlock(state, action: PayloadAction<number | null>) {
      return {
        ...state,
        placeholderMessageBlock: action.payload,
      }
    },
    resetThread(state) {
      return {
        ...state,
        selectedThread: null,
        selectedMessageBlock: null,
        placeholderMessageBlock: null,
        isPublishValidationModeActive: false,
      }
    },
    addReplyMessage(state, action: PayloadAction<number>) {
      const selectedMessageBlock = getSelectedMessageBlock(state)
      ;(selectedMessageBlock?.script?.reply as DefaultReply)?.messages.splice(
        action.payload + 1 ?? 0,
        0,
        {
          type: MessageType.text,
          content: '',
        },
      )
      syncSingleUseThreadsMapWithSelectedThread(state)
    },
    removeReplyMessage(state, action: PayloadAction<number>) {
      const selectedMessageBlock = getSelectedMessageBlock(state)
      ;(selectedMessageBlock?.script?.reply as DefaultReply)?.messages.splice(
        action.payload ?? 0,
        1,
      )
      syncSingleUseThreadsMapWithSelectedThread(state)
    },
    updateReplyMessage(state, action: PayloadAction<{ index: number; content: string }>) {
      const selectedMessageBlock = getSelectedMessageBlock(state)
      if (!selectedMessageBlock) return

      const currentReplyMessage = getDefaultReply(selectedMessageBlock)?.messages.find(
        (_, index) => index === action.payload.index,
      )
      if (!currentReplyMessage) return
      ;(currentReplyMessage as DefaultMessage).content = action.payload.content

      syncSingleUseThreadsMapWithSelectedThread(state)
    },
    updateReplyImageSamplePhoto(
      state,
      action: PayloadAction<{ imageUrl: string; content: string }>,
    ) {
      const selectedMessageBlock = getSelectedMessageBlock(state) || undefined
      const currentReply = getDefaultReply(selectedMessageBlock)
      if (!currentReply) return
      const currentReplyMessages = currentReply.messages as DefaultMessage[]
      const currentImage = currentReplyMessages.find(
        (message) => message.type === MessageType.image,
      )

      if (!currentImage) {
        currentReplyMessages.push({
          type: MessageType.image,
          content: action.payload.content,
          imageUrl: action.payload.imageUrl,
        })
      } else {
        currentImage.content = action.payload.content
        currentImage.imageUrl = action.payload.imageUrl
      }

      if (currentReply.prompt?.presignedPost?.replace) {
        currentReply.prompt.presignedPost.replace.arguments = { photoId: action.payload.content }
      }

      syncSingleUseThreadsMapWithSelectedThread(state)
    },
    removeReplyImageSamplePhoto(state) {
      const selectedMessageBlock = getSelectedMessageBlock(state) || undefined
      const currentReply = getDefaultReply(selectedMessageBlock)
      if (!currentReply) return
      currentReply.messages = (currentReply.messages as DefaultMessage[]).filter(
        (message) => message.type !== MessageType.image,
      )

      if (currentReply.prompt?.presignedPost?.replace.arguments) {
        delete currentReply.prompt.presignedPost.replace.arguments
      }
      syncSingleUseThreadsMapWithSelectedThread(state)
    },
    updateCustomerResponseTitle(state, action: PayloadAction<{ index: number; text: string }>) {
      const selectedMessageBlock = getSelectedMessageBlock(state)
      if (!selectedMessageBlock) return

      const currentCustomerResponse =
        getDefaultReply(selectedMessageBlock)?.prompt?.options?.[action.payload.index]
      if (!currentCustomerResponse) return

      currentCustomerResponse.title = action.payload.text
      currentCustomerResponse.outputText = action.payload.text
      syncSingleUseThreadsMapWithSelectedThread(state)
    },
    addReplyPromptOption(state, action: PayloadAction<number>) {
      const selectedMessageBlock = getSelectedMessageBlock(state)
      if (!selectedMessageBlock) return

      const { collect, reply } = selectedMessageBlock?.script
      const value = uuid()

      // add PromptOptions object
      const promptOptions = (reply as DefaultReply)?.prompt?.options
      if (!promptOptions) return

      promptOptions.splice(action.payload + 1 ?? 0, 0, {
        title: '',
        value,
        outputText: '',
      })

      let promptType: PromptType = 'buttons'

      if (promptOptions.length > 3) {
        // update Prompt type
        promptType = 'multiselect'

        const messages = (reply as DefaultReply)?.messages

        // add the 'textSelect' message if prompt options have gone above 3 items
        if (
          !messages.some((message) => (message as DefaultMessage).type === MessageType.textSelect)
        ) {
          messages.push({
            content: 'Click this bubble to show all choices.',
            type: MessageType.textSelect,
          })
        }
      }

      const prompt = (selectedMessageBlock?.script.reply as DefaultReply)?.prompt
      if (!prompt) return
      prompt.type = promptType

      // add new CollectOptions
      collect.options.splice(collect.options.length - 1, 0, {
        action: 'execute',
        execute: {
          scriptIndex: -1,
        },
        patterns: [
          {
            input: value,
          },
        ],
      })
      syncSingleUseThreadsMapWithSelectedThread(state)
    },
    removeReplyPromptOption(state, action: PayloadAction<number>) {
      const selectedMessageBlock = getSelectedMessageBlock(state)
      const promptOptions = (selectedMessageBlock?.script.reply as DefaultReply)?.prompt?.options

      if (!promptOptions || !selectedMessageBlock) return

      const { collect, reply } = selectedMessageBlock.script

      // remove PromptOption
      const [promptOption] = promptOptions.splice(action.payload ?? 0, 1)

      let promptType: PromptType = 'multiselect'

      if (promptOptions.length <= 3) {
        // update Prompt type
        promptType = 'buttons'

        const messages = (reply as DefaultReply)?.messages

        // remove the 'textSelect' message if prompt options have gone to 3 or fewer items
        const index = messages.findIndex(
          (message) => (message as DefaultMessage).type === MessageType.textSelect,
        )
        if (index >= 0) {
          messages.splice(index, 1)
        }
      }

      const prompt = (selectedMessageBlock?.script.reply as DefaultReply)?.prompt
      if (!prompt) return
      prompt.type = promptType

      // update CollectOptions
      const collectObject = findCollectOptionByInputPattern(collect, promptOption.value)

      if (!collectObject || !collectObject.patterns) return

      const hasMultiplePatterns = collectObject.patterns?.length > 1
      const isDefault = collectObject.default

      if (hasMultiplePatterns || isDefault) {
        // modify the patterns array
        const patternIndex = collectObject.patterns.findIndex(
          (pattern) => pattern.input === promptOption.value,
        )
        if (patternIndex > -1) {
          collectObject.patterns.splice(patternIndex, 1)
        }
      } else {
        // remove CollectObject
        const collectOptionIndex = collect.options.findIndex((currCollectObject) => {
          return isEqual(collectObject, currCollectObject)
        })
        if (collectOptionIndex > -1) {
          collect.options.splice(collectOptionIndex, 1)
        }
      }
      syncSingleUseThreadsMapWithSelectedThread(state)
    },
    setIsEditorPanelVisible(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isEditorPanelVisible: action.payload,
      }
    },
    updatePromptSlot(state, action: PayloadAction<string>) {
      const selectedMessageBlock = getSelectedMessageBlock(state)
      const { prompt } = selectedMessageBlock?.script.reply as DefaultReply
      if (prompt) prompt.slot = action.payload
      syncSingleUseThreadsMapWithSelectedThread(state)
    },
    resetReportingValue(state) {
      const selectedMessageBlock = getSelectedMessageBlock(state)
      if (!selectedMessageBlock) return

      const promptOptions = (selectedMessageBlock?.script.reply as DefaultReply)?.prompt?.options
      const { collect } = selectedMessageBlock?.script
      if (!promptOptions) return

      const optionValues = promptOptions.map((option, index) => {
        const value = uuid()
        promptOptions.splice(index, 1, {
          title: option.title,
          value,
          outputText: option.outputText,
        })
        return {
          oldValue: option.value,
          newValue: value,
        }
      })

      optionValues.forEach((value) => {
        const collectIndex = getCollectOptionsIndexByPattern(collect, value.oldValue)
        updatePatternsArray(
          collect.options[collectIndex].patterns,
          value.oldValue,
          'update',
          value.newValue,
        )
      })

      syncSingleUseThreadsMapWithSelectedThread(state)
    },
    updateReportingValue(
      state,
      action: PayloadAction<{
        index: number
        oldValue: string
        newValue: string
      }>,
    ) {
      const { index, oldValue, newValue } = action.payload
      const selectedMessageBlock = getSelectedMessageBlock(state)
      if (!selectedMessageBlock) return

      const promptOptions = (selectedMessageBlock?.script.reply as DefaultReply)?.prompt?.options

      if (!promptOptions) return
      const { collect } = selectedMessageBlock?.script

      // check if pattern already exists in collectOptions
      const existingCollectIndex = getCollectOptionsIndexByPattern(collect, newValue)
      const collectIndex = getCollectOptionsIndexByPattern(collect, oldValue)

      promptOptions[index].value = newValue
      const patternStillUsedByReply = promptOptions.filter((option) => option.value === oldValue)

      if (patternStillUsedByReply.length !== 0) {
        // add new collect option
        const { options } = collect
        options.splice(collect.options.length - 1, 0, generateNewCollectOption('0', newValue))
      }
      // check if more than one reply option values are the same

      if (existingCollectIndex === -1) {
        // collect options and prompt options are not always in the same order
        // need to make sure we are updating based on pattern rather than prompt option index
        updatePatternsArray(collect.options[collectIndex].patterns, oldValue, 'update', newValue)
      }

      if (patternStillUsedByReply.length === 0) {
        // remove the old pattern from the collect options
        updatePatternsArray(collect.options[collectIndex].patterns, oldValue, 'remove')
        cleanEmptyCollectOptionsByPattern(collect)
      }

      syncSingleUseThreadsMapWithSelectedThread(state)
    },
    setIsDuplicateThreadModalVisible(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isDuplicateThreadModalVisible: action.payload,
      }
    },
    setSelectedThreadListItem(state, action: PayloadAction<ThreadListItem | null>) {
      return {
        ...state,
        selectedThreadListItem: action.payload,
      }
    },

    // Conversation reducers
    setSelectedConversation(state, action: PayloadAction<ConversationResponse>) {
      return {
        ...state,
        selectedConversation: action.payload,
      }
    },
    resetSelectedConversation(state) {
      return {
        ...state,
        selectedConversation: null,
        messageBlockReferenceCountMap: {},
      }
    },
    setIsReusableThreadPickerVisible(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isReusableThreadPickerVisible: action.payload,
      }
    },
    setMessageBlockReferenceCountingMapFromFullConversation(
      state,
      action: PayloadAction<FullConversation>,
    ) {
      const fullConversation: FullConversation = action.payload
      const singleUseThreads = fullConversation.threads.filter(
        (thread) => thread?.type === 'single_use',
      )
      const refCountMap: MessageBlockReferenceMap = {}
      singleUseThreads.forEach((singleUseThread) => {
        singleUseThread?.script.forEach((_messageBlock, messageBlockIndex) => {
          const refCount = getReferenceCountForMessageBlock(messageBlockIndex, singleUseThread)
          refCountMap[`${singleUseThread.id}.${messageBlockIndex}`] = refCount
        })
      })
      ampSlice.caseReducers.setMessageBlockReferenceCountMap(state, {
        type: 'AMPSlice/setMessageBlockReferenceCountMap',
        payload: refCountMap,
      })
    },
    setMessageBlockReferenceCountingMapFromThread(state, action: PayloadAction<ThreadResponse>) {
      const thread: ThreadResponse = action.payload
      const refCountMap: MessageBlockReferenceMap = {}

      thread?.script.forEach((_messageBlock, messageBlockIndex) => {
        const refCount = getReferenceCountForMessageBlock(messageBlockIndex, thread)
        refCountMap[`${thread.id}.${messageBlockIndex}`] = refCount
      })

      ampSlice.caseReducers.setMessageBlockReferenceCountMap(state, {
        type: 'AMPSlice/setMessageBlockReferenceCountMap',
        payload: refCountMap,
      })
    },
    addThreadToCurrentConversation(
      state,
      action: PayloadAction<{ threadId: string; threadType: string }>,
    ) {
      const { threadId, threadType } = action.payload
      if (!state.selectedConversation) return
      const initialIndex = state.selectedThread
        ? state.selectedConversation.threads.findIndex(
            (thread) => thread.split(':')[0] === state.selectedThread?.id,
          )
        : -1

      const isReplacingThread = Boolean(
        state.selectedThread && state.selectedThread.id === state.selectedThreadIdToReplace,
      )

      let index: number
      if (isReplacingThread && initialIndex !== -1) {
        // when there is an index and are replacing a thread, don't change the index
        index = initialIndex
      } else if (initialIndex !== -1) {
        // when there is an index and are not replacing threads, insert after index
        index = initialIndex + 1
      } else {
        // all other situations add thread to end of convo
        index = state.selectedConversation.threads.length
      }

      state.selectedConversation.threads.splice(
        index,
        isReplacingThread ? 1 : 0,
        `${threadId}:${threadType}`,
      )
    },
    removeThreadFromCurrentConversation(state, action: PayloadAction<string>) {
      if (!state.selectedConversation) return
      const index = state.selectedConversation.threads.findIndex(
        (thread) => thread.split(':')[0] === action.payload,
      )
      if (index > -1) {
        state.selectedConversation.threads.splice(index, 1)
      }
    },
    updateSingleUseThreads(state, action: PayloadAction<ThreadResponse>) {
      const singleUseThreads = { ...state.singleUseThreads, [action.payload.id]: action.payload }
      return {
        ...state,
        singleUseThreads,
      }
    },
    removeThreadFromSingleUseThreads(state, action: PayloadAction<string>) {
      const singleUseThreads = Object.entries(state.singleUseThreads).reduce(
        (acc, [id, thread]) => {
          if (id === action.payload) {
            return acc
          }
          return {
            ...acc,
            [id]: thread,
          }
        },
        {},
      )

      Object.assign(state, { singleUseThreads })
    },
    updateMessageBlockReferenceCountInMap(state, action: PayloadAction<Array<[string, number]>>) {
      const updateObj = action.payload.reduce((acc, [key, refCount]) => {
        return {
          ...acc,
          [key]: refCount,
        }
      }, {})

      const messageBlockReferenceCountMap = {
        ...state.messageBlockReferenceCountMap,
        ...updateObj,
      }

      Object.assign(state, { messageBlockReferenceCountMap })
    },
    removeMessageBlockReferenceCountsFromMap(state, action: PayloadAction<string[]>) {
      const updatedObj = Object.entries(state.messageBlockReferenceCountMap).reduce(
        (acc, [key, refCount]) => {
          if (action.payload.includes(key)) {
            return acc
          }
          return {
            ...acc,
            [key]: refCount,
          }
        },
        {},
      )

      Object.assign(state, { messageBlockReferenceCountMap: updatedObj })
    },
    addMessageBlockToThread(
      state,
      action: PayloadAction<{
        scriptItem: ScriptItem
        messageBlockInsertionType?: MessageBlockInsertionType
      }>,
    ) {
      if (!state.selectedThread) return

      const nextNonSelectedIndex =
        action.payload.messageBlockInsertionType === MessageBlockInsertionType.Start
          ? 0
          : state.selectedThread.script.length
      const indexToAdd =
        state.selectedMessageBlock !== null ? state.selectedMessageBlock + 1 : nextNonSelectedIndex
      const { script } = state.selectedThread

      for (const scriptItem of script) {
        for (const option of scriptItem.collect.options) {
          if (option.execute?.scriptIndex) {
            let { scriptIndex } = option.execute
            if (scriptIndex >= indexToAdd) {
              // routing value is greater than or equal to the index of new item, value should be incremented by 1 to reflect the new script order
              scriptIndex += 1
            }
            option.execute.scriptIndex = scriptIndex
          }
        }
      }

      const updatedRefCounts = updateMessageBlockReferenceCountsForAddingMessageBlock(
        state.messageBlockReferenceCountMap,
        indexToAdd,
        state.selectedThread,
      )

      ampSlice.caseReducers.updateMessageBlockReferenceCountInMap(state, {
        type: 'AMPSlice/updateMessageBlockReferenceCountInMap',
        payload: updatedRefCounts,
      })

      // add the new item after re-indexing so the new item does not get re-indexed
      state.selectedThread.script.splice(indexToAdd, 0, action.payload.scriptItem)

      syncSingleUseThreadsMapWithSelectedThread(state)
    },
    removeMessageBlockFromThread(state, action: PayloadAction<number>) {
      if (!state.selectedThread) return

      const selectedThreadId = state.selectedThread.id
      const indexToDelete = action.payload
      const { script } = state.selectedThread

      // recalculate message block reference counts before modifying the script array
      const changeset = updateMessageBlockReferenceCountsForRemovingMessageBlock(
        state.messageBlockReferenceCountMap,
        indexToDelete,
        state.selectedThread,
      )

      ampSlice.caseReducers.removeMessageBlockReferenceCountsFromMap(state, {
        type: 'AMPSlice/removeMessageBlockReferenceCountsFromMap',
        payload: changeset.deletes,
      })

      ampSlice.caseReducers.updateMessageBlockReferenceCountInMap(state, {
        type: 'AMPSlice/updateMessageBlockReferenceCountInMap',
        payload: changeset.updates ?? [],
      })

      // remove the item before re-indexing so it does not get unnecessarily re-indexed
      script.splice(indexToDelete, 1)

      for (const scriptItem of script) {
        for (const option of scriptItem.collect.options) {
          if (option.execute?.scriptIndex) {
            let { scriptIndex } = option.execute
            if (scriptIndex === indexToDelete) {
              // routing value is equal to the index of removed item, value should be set to -1 to indicate it routes to nothing
              scriptIndex = -1
            } else if (scriptIndex > indexToDelete) {
              // routing value is greater than the index of removed item, value should be decremented by 1 to reflect the new script order
              scriptIndex -= 1
            }
            option.execute.scriptIndex = scriptIndex
          }
        }
      }

      // if editing a conversation && we removed last scriptItem from the thread
      if (state.selectedConversation && script.length === 0) {
        // remove thread from conversation
        ampSlice.caseReducers.removeThreadFromCurrentConversation(state, {
          type: 'AMPSlice/removeThreadFromCurrentConversation',
          payload: selectedThreadId,
        })

        // remove thread from singleUseThreadsMap
        ampSlice.caseReducers.removeThreadFromSingleUseThreads(state, {
          type: 'AMPSlice/removeThreadFromSingleUseThreads',
          payload: selectedThreadId,
        })
      } else {
        // sync the singleUseThreadsMap with edits to selectedThread
        syncSingleUseThreadsMapWithSelectedThread(state)
      }
    },
    replaceMessageBlockInThread(state, action: PayloadAction<ScriptItem>) {
      if (!state.selectedThread) return
      const index = state.selectedMessageBlock !== null ? state.selectedMessageBlock : 0
      state.selectedThread.script.splice(index, 1, action.payload)

      syncSingleUseThreadsMapWithSelectedThread(state)
    },
    updateJumpToValue(
      state,
      action: PayloadAction<{ routingValue: string; pattern: string | null }>,
    ) {
      const messageBlock = getSelectedMessageBlock(state)
      if (!messageBlock) return

      const { collect, reply } = messageBlock.script
      // when routingValue is an int, its value is always 1 greater than target index (display value vs 0 indexing of arrays)
      // utils functions will adj for this difference as needed
      const { routingValue, pattern } = action.payload

      const promptType = (reply as DefaultReply).prompt?.type
      if (!promptType) return

      if (promptType !== 'buttons' && promptType !== 'multiselect') {
        // message block types that always mutate default
        const defaultCollectOption = findDefaultCollectOption(collect)
        const defaultCollectOptionIndex = findDefaultCollectOptionIndex(collect)

        if (!defaultCollectOption || defaultCollectOptionIndex < 0) return

        // Message Block reference count updates
        const fromJumpToValue = Number(defaultCollectOption?.execute?.scriptIndex)
        if (!Number.isNaN(fromJumpToValue) && fromJumpToValue !== -1) {
          const newRefCount =
            state.messageBlockReferenceCountMap[`${state.selectedThread?.id}.${fromJumpToValue}`] -
            1
          ampSlice.caseReducers.updateMessageBlockReferenceCountInMap(state, {
            type: 'AMPSlice/updateMessageBlockReferenceCountInMap',
            payload: [[`${state.selectedThread?.id}.${fromJumpToValue}`, newRefCount]],
          })
        }

        const toJumpToValue = Number(routingValue)
        if (!Number.isNaN(toJumpToValue) && toJumpToValue !== -1) {
          const newRefCount =
            state.messageBlockReferenceCountMap[
              `${state.selectedThread?.id}.${toJumpToValue - 1}`
            ] + 1
          ampSlice.caseReducers.updateMessageBlockReferenceCountInMap(state, {
            type: 'AMPSlice/updateMessageBlockReferenceCountInMap',
            payload: [[`${state.selectedThread?.id}.${toJumpToValue - 1}`, newRefCount]],
          })
        }

        // change routing of default CollectOption
        collect.options.splice(
          defaultCollectOptionIndex,
          1,
          updateCollectOptionRouting(defaultCollectOption, routingValue),
        )
        syncSingleUseThreadsMapWithSelectedThread(state)
        return
      }

      if (!pattern) return

      // check for current CollectOption mapping
      const collectOptionMap = findCollectOptionByInputPattern(collect, pattern)

      // check for target routing match CollectOption
      const collectOptionTargetRouting = findCollectOptionByRoutingMatch(collect, routingValue)

      // Message Block reference count updates
      const fromJumpToValue = Number(collectOptionMap?.execute?.scriptIndex ?? 0)

      if (!Number.isNaN(fromJumpToValue) && fromJumpToValue !== -1) {
        const newRefCount =
          state.messageBlockReferenceCountMap[`${state.selectedThread?.id}.${fromJumpToValue}`] - 1
        ampSlice.caseReducers.updateMessageBlockReferenceCountInMap(state, {
          type: 'AMPSlice/updateMessageBlockReferenceCountInMap',
          payload: [[`${state.selectedThread?.id}.${fromJumpToValue}`, newRefCount]],
        })
      }

      const toJumpToValue = Number(routingValue)
      if (!Number.isNaN(toJumpToValue) && toJumpToValue !== -1) {
        const newRefCount =
          state.messageBlockReferenceCountMap[`${state.selectedThread?.id}.${toJumpToValue - 1}`] +
          1
        ampSlice.caseReducers.updateMessageBlockReferenceCountInMap(state, {
          type: 'AMPSlice/updateMessageBlockReferenceCountInMap',
          payload: [[`${state.selectedThread?.id}.${toJumpToValue - 1}`, newRefCount]],
        })
      }

      updateCollectOptions({
        collect,
        collectOptionMap,
        collectOptionTargetRouting,
        pattern,
        routingValue,
      })
      syncSingleUseThreadsMapWithSelectedThread(state)
    },
    setSelectedThreadIdToReplace(state, action: PayloadAction<string | null>) {
      return {
        ...state,
        selectedThreadIdToReplace: action.payload,
      }
    },
    resetSingleUseThreads(state) {
      return {
        ...state,
        singleUseThreads: {},
      }
    },
    setIsPublishValidationModeActive(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isPublishValidationModeActive: action.payload,
      }
    },
    resetIsPublishValidationModeActive(state) {
      return {
        ...state,
        isPublishValidationModeActive: false,
      }
    },
    setConversationAdjudicationThread(state, action: PayloadAction<ThreadResponse | null>) {
      return {
        ...state,
        conversationAdjudicationThread: action.payload,
      }
    },

    // Ruleset reducers
    setRuleset(state, action: PayloadAction<RulesetBase>) {
      return {
        ...state,
        selectedRuleset: action.payload,
      }
    },
    resetRuleset(state) {
      return {
        ...state,
        selectedRuleset: null,
      }
    },
    addRuleToRuleset(state, action: PayloadAction<{ rule: RuleCreate; rulesetType: RuleStatus }>) {
      if (!state.selectedRuleset) return

      const rules = getRulesForStatus(state.selectedRuleset, action.payload.rulesetType)
      rules.push(action.payload.rule)
    },

    removeRuleFromRuleset(state, action: PayloadAction<{ ruleIndex: number; status: RuleStatus }>) {
      if (!state.selectedRuleset) return

      const rules = getRulesForStatus(state.selectedRuleset, action.payload.status)
      rules.splice(action.payload.ruleIndex, 1)
    },
    addConditionToRule(
      state,
      action: PayloadAction<{
        ruleIndex: number
        rulesetType: RuleStatus
      }>,
    ) {
      const { ruleIndex, rulesetType } = action.payload
      const rule = getRule({ state, ruleIndex, rulesetType })

      if (!rule) return
      rule.conditions.push({ script: -1, value: [] })
    },
    removeConditionFromRule(
      state,
      action: PayloadAction<{
        ruleIndex: number
        conditionIndex: number
        rulesetType: RuleStatus
      }>,
    ) {
      if (!state.selectedRuleset) return

      // TODO: grab context from thread or conversation https://helloextend.atlassian.net/browse/CIACX-791
      const rules = getRulesForStatus(state.selectedRuleset, action.payload.rulesetType)
      rules[action.payload.ruleIndex].conditions.splice(action.payload.conditionIndex, 1)
      if (rules[action.payload.ruleIndex].conditions.length === 0) {
        ampSlice.caseReducers.removeRuleFromRuleset(state, {
          type: 'AMPSlice/removeRuleFromRuleset',
          payload: { ruleIndex: action.payload.ruleIndex, status: action.payload.rulesetType },
        })
      }
    },
    updateConditionType(
      state,
      action: PayloadAction<{
        rulesetType: RuleStatus
        ruleIndex: number
        conditionIndex: number
        value: string
      }>,
    ) {
      const { rulesetType, ruleIndex, conditionIndex, value } = action.payload
      const rule = getRule({
        state,
        rulesetType,
        ruleIndex,
      })
      const thread = getThread(state)
      if (!rule || !thread) return

      // is the value a scriptItem index?
      if (Number.isInteger(Number(value))) {
        // identify the type of message block
        const messageBlockType =
          (thread?.script[Number(value)].reply as DefaultReply)?.prompt?.type || ''

        // This handles NonNumericConditionWithScript & NumericConditionWithScript
        if (messageBlockType) {
          // handle MessageBlocks with nonNumericSlot types
          if (['buttons', 'multiselect'].includes(messageBlockType)) {
            rule.conditions.splice(conditionIndex, 1, {
              script: Number(value),
              value: [],
            })
          } else if (['datepicker'].includes(messageBlockType)) {
            rule.conditions.splice(conditionIndex, 1, {
              script: Number(value),
              comparand: 'contractTransactionDate',
              operator: '>',
              offset: 0,
            })
          }
          // this handles NonNumericConditionWithComparand
        }
      }

      if (value === 'planHasCoverageType') {
        rule.conditions.splice(conditionIndex, 1, {
          value: [],
          comparand: 'planHasCoverageType',
        })
      }

      if (value === 'totalOfPurchasePrices') {
        rule.conditions.splice(conditionIndex, 1, {
          comparand: 'totalOfPurchasePrices',
          value: 0,
          operator: '>',
        })
      }

      if (value === 'numberOfShipments') {
        rule.conditions.splice(conditionIndex, 1, {
          comparand: 'numberOfShipments',
          value: 0,
          operator: '>',
        })
      }
    },
    updateConditionValue(
      state,
      action: PayloadAction<{
        rulesetType: RuleStatus
        ruleIndex: number
        conditionIndex: number
        value: string[]
      }>,
    ) {
      const { rulesetType, ruleIndex, conditionIndex, value } = action.payload
      const condition = getCondition({ state, rulesetType, ruleIndex, conditionIndex })

      if (!condition) return

      const conditionType = identifyCondition(condition)
      if (conditionType === 'nonNumericScript' || conditionType === 'nonNumericComparand') {
        condition.value = value
      }
    },
    updateConditionOperator(
      state,
      action: PayloadAction<{
        rulesetType: RuleStatus
        ruleIndex: number
        conditionIndex: number
        value: Operator
      }>,
    ) {
      const { rulesetType, ruleIndex, conditionIndex, value } = action.payload
      const condition = getCondition({ state, rulesetType, ruleIndex, conditionIndex })

      if (!condition) return

      const conditionType = identifyCondition(condition)
      if (conditionType?.includes('numeric')) {
        ;(condition as NumericCondition).operator = value
      }
    },
    updateConditionNumericValue(
      state,
      action: PayloadAction<{
        rulesetType: RuleStatus
        ruleIndex: number
        conditionIndex: number
        value: string
      }>,
    ) {
      const { rulesetType, ruleIndex, conditionIndex, value } = action.payload
      const condition = getCondition({ state, rulesetType, ruleIndex, conditionIndex })

      if (!condition) return

      const conditionType = identifyCondition(condition)
      if (conditionType === 'numericValue' && Number.isInteger(Number(condition.value))) {
        ;(condition as NumericConditionWithValue).value = Number(value)
      }
    },
    updateOffsetAmount(
      state,
      action: PayloadAction<{
        rulesetType: RuleStatus
        ruleIndex: number
        conditionIndex: number
        value: string
      }>,
    ) {
      const { rulesetType, ruleIndex, conditionIndex, value } = action.payload
      const condition = getCondition({ state, rulesetType, ruleIndex, conditionIndex })
      if (!condition) return

      const conditionType = identifyCondition(condition)

      if (conditionType === 'numericScript') {
        const offset = (condition as NumericConditionWithScript)?.offset as number
        const isNegative = offset < 0
        ;(condition as NumericConditionWithScript).offset = isNegative
          ? -Math.abs(Number(value))
          : Number(value)
      }
    },
    updateOffsetAddend(
      state,
      action: PayloadAction<{
        rulesetType: RuleStatus
        ruleIndex: number
        conditionIndex: number
        value: string
      }>,
    ) {
      const { rulesetType, ruleIndex, conditionIndex, value } = action.payload
      const condition = getCondition({ state, rulesetType, ruleIndex, conditionIndex })

      if (!condition) return

      const conditionType = identifyCondition(condition)
      if (conditionType === 'numericScript') {
        const offset = (condition as NumericConditionWithScript)?.offset as number
        if (value === 'after' && offset < 0) {
          ;(condition as NumericConditionWithScript).offset = Math.abs(offset)
        }
        if (value === 'before' && offset > 0) {
          ;(condition as NumericConditionWithScript).offset = offset * -1
        }
      }
    },
    updateComparandValue(
      state,
      action: PayloadAction<{
        rulesetType: RuleStatus
        ruleIndex: number
        conditionIndex: number
        value: NumericInvokableFunction
      }>,
    ) {
      const { rulesetType, ruleIndex, conditionIndex, value } = action.payload
      const condition = getCondition({ state, rulesetType, ruleIndex, conditionIndex })
      if (!condition) return
      if (value) (condition as NumericConditionWithScript).comparand = value
    },
  },
})

const getRulesForStatus = (ruleset: RulesetBase, status: RuleStatus): RuleCreate[] => {
  const statusMap: { [key in RuleStatus]: 'approveRules' | 'denyRules' | 'reviewRules' } = {
    approved: 'approveRules',
    denied: 'denyRules',
    review: 'reviewRules',
  }
  const statusArray = statusMap[status]

  return ruleset[statusArray]
}

const getThread = (state: AMPState): ThreadResponse | null => {
  return state.selectedThread
}

const getReusableThreadTypes = (conversation: ConversationResponse): ThreadTypes[] => {
  return conversation.threads
    .filter((fullThreadId) => {
      const threadType = fullThreadId.split(':')[1] as string
      return threadType !== 'single_use'
    })
    .map((fullThreadId) => fullThreadId.split(':')[1] as ThreadTypes)
}

const getIsThreadTextAssigned = (state: AMPState): boolean => {
  return isThreadTextAssigned(state.selectedThread)
}

const getUnreferencedMessageBlocksFromSelectedThread = (state: AMPState): ScriptItem[] => {
  return getUnreferencedMessageBlocks(state.selectedThread)
}

const getMessageBlockIndex = (state: AMPState): number | null => {
  return state.selectedMessageBlock
}

const getPlaceholderMessageBlockIndex = (state: AMPState): number | null => {
  return state.placeholderMessageBlock
}

const getSelectedMessageBlock = (state: AMPState): SelectedMessageBlock | null => {
  const thread = state.selectedThread

  if (!thread || state.selectedMessageBlock === null) return null

  const selectedScript = thread.script.find(
    (_: ScriptItem, index: number) => index === state.selectedMessageBlock,
  )

  if (!selectedScript) return null
  return {
    script: selectedScript as Required<ScriptItem>,
    index: state.selectedMessageBlock,
  }
}

const getDefaultReply = (selectedMessageBlock?: SelectedMessageBlock): DefaultReply | null => {
  if (!selectedMessageBlock) return null
  return selectedMessageBlock.script.reply as DefaultReply
}

const getIsEditorPanelVisible = (state: AMPState): boolean => {
  return state.isEditorPanelVisible
}

const getIsReusableThreadPickerVisible = (state: AMPState): boolean => {
  return state.isReusableThreadPickerVisible
}

const getConversation = (state: AMPState): ConversationResponse | null => {
  return state.selectedConversation
}

const getSingleUseThreads = (state: AMPState): SingleUseThreadsMap => {
  return state.singleUseThreads
}

const getConversationAdjudicationThread = (state: AMPState): ThreadResponse | null => {
  return state.conversationAdjudicationThread
}

const syncSingleUseThreadsMapWithSelectedThread = (state: AMPState): void => {
  if (
    !state.singleUseThreads ||
    !state.selectedThread ||
    !state.singleUseThreads[state.selectedThread.id]
  )
    return
  const singleUseThread = state.singleUseThreads[state.selectedThread.id]
  singleUseThread.script = state.selectedThread.script
}

const getThreadIdToReplace = (state: AMPState): string | null => {
  return state.selectedThreadIdToReplace
}

const getMessageBlockReferenceMap = (state: AMPState): MessageBlockReferenceMap => {
  return state.messageBlockReferenceCountMap
}

const getIsPublishValidationModeActive = (state: AMPState): boolean => {
  return state.isPublishValidationModeActive
}

const getIsDuplicateThreadModalVisible = (state: AMPState): boolean => {
  return state.isDuplicateThreadModalVisible
}

const getSelectedThreadListItem = (state: AMPState): ThreadListItem | null => {
  return state.selectedThreadListItem
}

const getRuleset = (state: AMPState): RulesetBase | null => {
  return state.selectedRuleset
}

const getRule = ({
  state,
  rulesetType,
  ruleIndex,
}: {
  state: AMPState
  rulesetType: RuleStatus
  ruleIndex: number
}): RuleCreate | null => {
  const { selectedRuleset } = state

  if (!selectedRuleset) return null

  let ruleset = null
  if (rulesetType === 'approved') {
    ruleset = selectedRuleset.approveRules
  }
  if (rulesetType === 'denied') {
    ruleset = selectedRuleset.denyRules
  }
  if (rulesetType === 'review') {
    ruleset = selectedRuleset.reviewRules
  }

  if (!ruleset) return null

  const selectedRule = ruleset[ruleIndex]
  if (!selectedRule) return null
  return selectedRule
}

const getCondition = ({
  state,
  rulesetType,
  ruleIndex,
  conditionIndex,
}: {
  state: AMPState
  rulesetType: RuleStatus
  ruleIndex: number
  conditionIndex: number
}): Condition | null => {
  const selectedRule = getRule({ state, rulesetType, ruleIndex })
  return selectedRule?.conditions[conditionIndex] ?? null
}

export const getAvailableMessageBlocksForRule = ({
  state,
  source,
  rulesetType,
  ruleIndex,
  conditionIndex,
}: {
  state: AMPState
  source: 'conversation' | 'thread'
  rulesetType: RuleStatus
  ruleIndex?: number
  conditionIndex?: number
}): ScriptItemUsage[] => {
  const startingScriptItems: Array<{
    script: ScriptItem
    scriptIndex: number
    inUse: boolean
  }> =
    (source === 'thread'
      ? state.selectedThread?.script.map((script, index) => ({
          script,
          scriptIndex: index,
          inUse: false,
        }))
      : state.conversationAdjudicationThread?.script.map((script, index) => ({
          script,
          scriptIndex: index,
          inUse: false,
        }))) || []

  if (ruleIndex === undefined || conditionIndex === undefined) return startingScriptItems

  const selectedRule = getRule({ state, rulesetType, ruleIndex })
  const inUseScriptItems: number[] =
    selectedRule?.conditions
      .filter((condition) => {
        return Number(condition.script) >= 0
      })
      .map((condition) => {
        return Number(condition.script)
      }) || []

  const availableScriptItems = startingScriptItems.map((scriptObject) => {
    if (inUseScriptItems.includes(scriptObject.scriptIndex)) {
      return { ...scriptObject, inUse: true }
    }
    return { ...scriptObject, inUse: false }
  })

  return availableScriptItems
}

const getHasValidConditions = (state: AMPState): boolean => {
  return hasValidConditions(state.selectedRuleset)
}

const ampReducer = ampSlice.reducer
const {
  setThread,
  setSelectedMessageBlock,
  setPlaceholderMessageBlock,
  setMessageBlockReferenceCountingMapFromFullConversation,
  setMessageBlockReferenceCountingMapFromThread,
  setMessageBlockReferenceCountMap,
  resetThread,
  addReplyMessage,
  removeReplyMessage,
  updateReplyMessage,
  updateReplyImageSamplePhoto,
  removeReplyImageSamplePhoto,
  updateCustomerResponseTitle,
  setIsEditorPanelVisible,
  setIsReusableThreadPickerVisible,
  updatePromptSlot,
  setSelectedConversation,
  resetSelectedConversation,
  addThreadToCurrentConversation,
  removeThreadFromCurrentConversation,
  updateSingleUseThreads,
  removeThreadFromSingleUseThreads,
  addMessageBlockToThread,
  removeMessageBlockFromThread,
  replaceMessageBlockInThread,
  addReplyPromptOption,
  removeReplyPromptOption,
  updateJumpToValue,
  setSelectedThreadIdToReplace,
  resetSingleUseThreads,
  setIsPublishValidationModeActive,
  resetIsPublishValidationModeActive,
  updateMessageBlockReferenceCountInMap,
  removeMessageBlockReferenceCountsFromMap,
  setIsDuplicateThreadModalVisible,
  setSelectedThreadListItem,
  resetReportingValue,
  updateReportingValue,
  setConversationAdjudicationThread,
  setRuleset,
  resetRuleset,
  addRuleToRuleset,
  removeRuleFromRuleset,
  addConditionToRule,
  removeConditionFromRule,
  updateConditionType,
  updateComparandValue,
  updateConditionValue,
  updateConditionOperator,
  updateConditionNumericValue,
  updateOffsetAmount,
  updateOffsetAddend,
} = ampSlice.actions

export {
  SelectedMessageBlock,
  SingleUseThreadsMap,
  MessageBlockReferenceMap,
  MessageBlockInsertionType,
  ScriptItemUsage,
  ampReducer,
  getSelectedMessageBlock,
  getMessageBlockIndex,
  getPlaceholderMessageBlockIndex,
  getThread,
  getReusableThreadTypes,
  getIsThreadTextAssigned,
  getIsEditorPanelVisible,
  getIsReusableThreadPickerVisible,
  getIsPublishValidationModeActive,
  getConversation,
  getSingleUseThreads,
  getConversationAdjudicationThread,
  addMessageBlockToThread,
  removeMessageBlockFromThread,
  replaceMessageBlockInThread,
  getUnreferencedMessageBlocksFromSelectedThread,
  getThreadIdToReplace,
  getMessageBlockReferenceMap,
  getIsDuplicateThreadModalVisible,
  getSelectedThreadListItem,
  getRuleset,
  getRulesForStatus,
  getRule,
  getCondition,
  getHasValidConditions,
  AMPState,
  setThread,
  setSelectedMessageBlock,
  setMessageBlockReferenceCountingMapFromFullConversation,
  setMessageBlockReferenceCountingMapFromThread,
  setMessageBlockReferenceCountMap,
  setPlaceholderMessageBlock,
  resetThread,
  addReplyMessage,
  removeReplyMessage,
  updateReplyMessage,
  updateReplyImageSamplePhoto,
  removeReplyImageSamplePhoto,
  updateCustomerResponseTitle,
  setIsEditorPanelVisible,
  setIsReusableThreadPickerVisible,
  updatePromptSlot,
  setSelectedConversation,
  resetSelectedConversation,
  addThreadToCurrentConversation,
  removeThreadFromCurrentConversation,
  updateSingleUseThreads,
  removeThreadFromSingleUseThreads,
  addReplyPromptOption,
  removeReplyPromptOption,
  updateJumpToValue,
  setSelectedThreadIdToReplace,
  resetSingleUseThreads,
  setIsPublishValidationModeActive,
  resetIsPublishValidationModeActive,
  updateMessageBlockReferenceCountInMap,
  removeMessageBlockReferenceCountsFromMap,
  setIsDuplicateThreadModalVisible,
  setSelectedThreadListItem,
  resetReportingValue,
  updateReportingValue,
  setConversationAdjudicationThread,
  setRuleset,
  resetRuleset,
  addRuleToRuleset,
  removeRuleFromRuleset,
  addConditionToRule,
  removeConditionFromRule,
  updateConditionType,
  updateComparandValue,
  updateConditionValue,
  updateConditionOperator,
  updateConditionNumericValue,
  updateOffsetAmount,
  updateOffsetAddend,
}
