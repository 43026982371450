import type { Servicer } from '@helloextend/extend-api-client'
import type { Action } from '../actions'

export type ByIdReducerState = Record<string, Servicer>

export const initialState: ByIdReducerState = {}

export default function byClaim(state = initialState, action: Action): ByIdReducerState {
  switch (action.type) {
    case 'SERVICERS_LIST_SUCCESS':
      return action.payload.servicers.reduce<ByIdReducerState>(addServicer, {})
    case 'SERVICERS_CREATE_SUCCESS':
      return addServicer(state, action.payload.servicer)
    case 'SERVICERS_RESET':
      return initialState
    default:
      return state
  }
}

function addServicer(state: ByIdReducerState, servicer: Servicer): ByIdReducerState {
  return { ...state, [servicer.id]: servicer }
}
