import type { ScriptItem } from '@helloextend/extend-api-rtk-query'
import { MessageType } from '@helloextend/extend-api-rtk-query'
import { uuid } from '@helloextend/extend-sdk-client/lib/utils/util'

export const generateFreeformTextMessageBlock = (): ScriptItem => ({
  collect: {
    options: [
      {
        action: 'execute',
        execute: {
          scriptIndex: -1,
        },
        default: true,
      },
    ],
  },
  reply: {
    messages: [
      {
        type: MessageType.text,
        content: '',
      },
    ],
    prompt: {
      type: 'input',
      slot: uuid(),
      placeholder: 'Input Text',
      options: [],
    },
  },
})
