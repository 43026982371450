import type { Plan } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetch = (id: string, accessToken?: string) =>
  ({ type: 'PLANS_FETCH', payload: { id, accessToken } } as const)

export const fetchRequest = () => ({ type: 'PLANS_FETCH_REQUEST', payload: undefined } as const)

export const fetchSuccess = (plans: Plan) =>
  ({
    type: 'PLANS_FETCH_SUCCESS',
    payload: plans,
  } as const)

export const fetchFailure = createActionFailure('PLANS_FETCH_FAILURE')
