import type {
  CurrencyCode,
  StoresContractsCreateRequest,
  ContractFormValuesUpdate,
  Contract20220201GetResponse,
} from '@helloextend/extend-api-client'
import { ContractStatus } from '@helloextend/extend-api-client'
import { date } from '@helloextend/client-utils'
import type { V2Values } from '../pages/admin/contract/schema'
import type { V1Values } from '../pages/admin/contracts-create/schema'

const currencyValues: CurrencyCode[] = [
  'AUD',
  'BGN',
  'BRL',
  'CAD',
  'CHF',
  'CNY',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'HUF',
  'IDR',
  'ILS',
  'JPY',
  'KRW',
  'MXN',
  'MYR',
  'NOK',
  'NZD',
  'PHP',
  'PLN',
  'RON',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'USD',
  'ZAR',
]

const mapContractV2ToLatestUpdateRequest = (
  values: V2Values,
  contract: Contract20220201GetResponse,
): ContractFormValuesUpdate => {
  return {
    contractUpdate: {
      args: {
        contractId: contract.id,
      },
      values: {
        currency: currencyValues.find((currency) => currency === values.currencyCode),
        customer: {
          billingAddress: {
            address1: values.billingAddress,
            address2: values.billingAddressTwo,
            city: values.billingCity,
            countryCode: values.billingCountryCode,
            postalCode: values.billingPostalCode,
            provinceCode: values.billingProvinceCode,
          },
          email: values.customerEmail,
          phone: values.phoneNumber,
          name: values.fullName,
          shippingAddress: {
            address1: values.shippingAddress,
            address2: values.shippingAddressTwo,
            city: values.shippingCity,
            countryCode: values.shippingCountryCode,
            postalCode: values.shippingPostalCode,
            provinceCode: values.shippingProvinceCode,
          },
        },
        listPrice: Number(values.planListPrice),
        isTest: String(values?.isTest) === 'true',
        merchantCustomerId: values.merchantCustomerId,
        plan: {
          id: values.planId,
          skuId: values.planWarrantySku,
          termsVersion: values.termVersion,
          termsId: values.termsId,
          version: values.planVersion,
        },
        purchaseDate:
          values.planPurchaseDate === undefined
            ? values.planPurchaseDate
            : date.formatToMilliseconds(values.planPurchaseDate),
        purchasePrice: Number(values.planPurchasePrice),
        product: {
          referenceId: values.referenceId,
          serialNumber: values.serialNumber,
          purchasePrice: Number(values.productPurchasePrice),
          name: values.productName,
          listPrice: values.productListPrice,
          manufacturerWarrantyLengthLabor: Number(values.mfrsWarrantyLengthLabor),
          manufacturerWarrantyLengthParts: Number(values.mfrsWarrantyLengthParts),
          purchaseDate: date.formatToMilliseconds(values.productPurchaseDate),
        },
        sellerId: values.storeId,
        status: Object.values(ContractStatus).find((status) => status === values.contractStatus),
        transactionId: values.transactionId,
        limitOfLiabilityAmount: values.limitOfLiabilityAmount,
        limitOfLiabilityAmountType: values?.limitOfLiabilityAmountType,
      },
    },
    productUpdate: {
      args: {
        storeId: contract.sellerId,
        referenceId: contract.product?.referenceId,
      },
      values: {
        brand: values.brand,
        identifiers: {
          sku: values.sku,
        },
      },
    },
  }
}

const mapContractV1ToCreateRequest = (values: V1Values): StoresContractsCreateRequest => {
  const billingAddress = values.billingAddress
    ? {
        billingAddress: {
          address1: values.billingAddress,
          address2: values.billingAddressTwo,
          city: values.billingCity,
          countryCode: values.billingCountryCode,
          postalCode: values.billingPostalCode,
          provinceCode: values.billingProvinceCode,
        },
      }
    : {}

  const shippingAddress = values.shippingAddress
    ? {
        shippingAddress: {
          address1: values.shippingAddress,
          address2: values.shippingAddressTwo,
          city: values.shippingCity,
          countryCode: values.shippingCountryCode,
          postalCode: values.shippingPostalCode,
          provinceCode: values.shippingProvinceCode,
        },
      }
    : {}

  return {
    storeId: values.storeId,
    currency: values.currencyCode,
    customer: {
      ...billingAddress,
      email: values.customerEmail,
      phone: values.phoneNumber,
      name: values.fullName,
      ...shippingAddress,
    } as StoresContractsCreateRequest['customer'],
    plan: {
      planId: values.planId,
      purchasePrice: { amount: Number(values.planPurchasePrice) },
    },
    product: {
      referenceId: values.referenceId,
      purchasePrice: { amount: Number(values.productPurchasePrice) },
    },
    transactionDate: date.formatToMilliseconds(values.transactionDate),
    transactionId: values.transactionId,
  }
}

export { mapContractV2ToLatestUpdateRequest, mapContractV1ToCreateRequest }
