import type { ClaimFiler, ClaimSource, InitialReply, Slot } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const connect = (source: ClaimSource, slot: Slot, slotValue: string, filedBy: ClaimFiler) =>
  ({
    type: 'INCREDIBOT_SESSION_CONNECT',
    payload: { source, slot, slotValue, filedBy },
  } as const)

export const connectRequest = () =>
  ({
    type: 'INCREDIBOT_SESSION_CONNECT_REQUEST',
  } as const)

export const connectSuccess = (initialReply: InitialReply) =>
  ({
    type: 'INCREDIBOT_SESSION_CONNECT_SUCCESS',
    payload: initialReply,
  } as const)

export const connectFailure = createActionFailure('INCREDIBOT_SESSION_CONNECT_FAILURE')
