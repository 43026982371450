import type { FC, FormEvent } from 'react'
import React, { createRef, Fragment, useEffect, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled'
import {
  Button,
  COLOR,
  Icon,
  IconSize,
  Information,
  InformationSize,
  Spinner,
  Stack,
} from '@helloextend/zen'
import { Add, Close, Download, Trash } from '@helloextend/zen/src/tokens/icons'

const MAX_UPLOAD_SIZE = 10
const VALID_FILE_TYPES = ['jpg', 'png']

type ImageRequirement = {
  title: string
  error: boolean
}

type ImageUploadProps = {
  onChange: (file: File) => void
  onDownloadClick: () => void
  onDeleteClick: () => void
  title?: string
  titleHelper?: string
  otherRequirements?: ImageRequirement[]
  currentImage?: string
  isProcessing?: boolean
}

const ImageUpload: FC<ImageUploadProps> = ({
  onDownloadClick,
  onDeleteClick,
  onChange,
  title,
  titleHelper,
  otherRequirements,
  currentImage,
  isProcessing,
}) => {
  const fileInput = createRef<HTMLInputElement>()
  const drop = useRef<HTMLDivElement>(null)
  const [image, setImage] = useState<string>(currentImage || '')
  const [imageName, setImageName] = useState<string>('')
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const [isValidFileType, setIsValidFileType] = useState<boolean>(true)
  const [isValidFileSize, setIsValidFileSize] = useState<boolean>(true)
  const imageRequirements = useMemo(
    () => [
      ...(otherRequirements || []),
      { title: 'JPG or PNG', error: !isValidFileType },
      { title: `${MAX_UPLOAD_SIZE}MB size`, error: !isValidFileSize },
    ],
    [otherRequirements, isValidFileType, isValidFileSize],
  )

  const isValidImage = (file: File): boolean => {
    const validImageType =
      file.type.includes('image') && VALID_FILE_TYPES.includes(file.type.split('/')[1])
    const isLessThan10MB = file.size / 1024 / 1024 < MAX_UPLOAD_SIZE

    setIsValidFileType(validImageType)
    setIsValidFileSize(isLessThan10MB)

    return validImageType && isLessThan10MB
  }

  useEffect(() => {
    if (currentImage !== image) {
      setImage(currentImage ?? '')
    }
    const handleDragOver = (e: DragEvent): void => {
      e.preventDefault()
      e.stopPropagation()
    }

    const handleDragLeave = (e: DragEvent): void => {
      e.preventDefault()
      e.stopPropagation()
      setIsDragging(false)
    }

    const handleDragEnter = (e: DragEvent): void => {
      e.preventDefault()
      e.stopPropagation()
      setIsDragging(true)
    }

    const handleDrop = (e: DragEvent): void => {
      e.preventDefault()
      e.stopPropagation()

      if (!e.dataTransfer || isProcessing) return

      const { files } = e.dataTransfer
      if (files && files.length > 0) {
        const file = files[0]
        if (!isValidImage(file)) {
          return
        }
        onChange(file)
        setImage(URL.createObjectURL(file))
        setImageName(file.name)
        setIsDragging(false)
      }
    }

    if (drop && drop.current) {
      drop.current.addEventListener('dragover', handleDragOver)
      drop.current.addEventListener('dragenter', handleDragEnter)
      drop.current.addEventListener('dragleave', handleDragLeave)
      drop.current.addEventListener('drop', handleDrop)
    }

    const dropRef = drop.current

    return () => {
      if (dropRef) {
        dropRef.removeEventListener('dragover', handleDragOver)
        dropRef.removeEventListener('dragenter', handleDragEnter)
        dropRef.removeEventListener('dragleave', handleDragLeave)
        dropRef.removeEventListener('drop', handleDrop)
      }
    }
  }, [drop, onChange, isProcessing, setImage, currentImage, image])

  const handleUploadClick = (): void => {
    fileInput.current?.click()
  }

  const handleInputChange = (e: FormEvent<HTMLInputElement>): void => {
    if (!e.currentTarget.files) return
    const currentFile = e.currentTarget.files[0]
    if (!isValidImage(currentFile)) {
      return
    }
    onChange(currentFile)
    setImage(URL.createObjectURL(currentFile))
    setImageName(currentFile.name)
  }

  return (
    <Container>
      <LabelWrapper>
        {title && <Title>{title}</Title>}
        {titleHelper && (
          <Information buttonSize="small" size={InformationSize.small}>
            {titleHelper}
          </Information>
        )}
      </LabelWrapper>

      {image && !isProcessing ? (
        <div ref={drop}>
          <ImageContainer
            isError={!isValidFileType || !isValidFileSize}
            backgroundImage={image}
            onClick={handleUploadClick}
            isDragging={isDragging}
          />
          <FileName type="button" onClick={handleUploadClick}>
            {imageName}
          </FileName>
        </div>
      ) : (
        <div ref={drop}>
          <ImagePicker isDragging={isDragging} isError={!isValidFileType || !isValidFileSize}>
            <IconWrapper onClick={handleUploadClick}>
              {isProcessing ? <Spinner /> : <Icon color={COLOR.NEUTRAL[400]} icon={Add} />}
            </IconWrapper>
          </ImagePicker>
        </div>
      )}
      <HiddenInput
        accept="image/*"
        ref={fileInput}
        type="file"
        id="upload-photo"
        data-cy="upload-photos"
        onChange={handleInputChange}
        disabled={isProcessing}
      />
      {imageRequirements && imageRequirements.length > 0 && (
        <>
          <Subheader>Requirements</Subheader>
          <BadgeWrapper>
            {imageRequirements.map((requirement) => (
              <Fragment key={requirement.title}>
                <Requirement hasError={requirement.error} data-cy={requirement.title}>
                  {requirement.error ? (
                    <Icon size={IconSize.small} color={COLOR.RED[700]} icon={Close} />
                  ) : (
                    <BulletWrapper>&bull;</BulletWrapper>
                  )}
                  {requirement.title}
                </Requirement>
              </Fragment>
            ))}
          </BadgeWrapper>
        </>
      )}
      {currentImage && (
        <Stack spacing={1}>
          <Button
            text="Download"
            icon={Download}
            size="xsmall"
            emphasis="medium"
            onClick={onDownloadClick}
            data-cy="download-image-button"
          />
          <Button
            text="Delete"
            icon={Trash}
            size="xsmall"
            emphasis="medium"
            color="red"
            onClick={onDeleteClick}
            data-cy="delete-image-button"
          />
        </Stack>
      )}
    </Container>
  )
}

const Container = styled.div({
  marginBottom: 24,
})

const LabelWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const ImageContainer = styled.div<{
  backgroundImage: string
  isDragging: boolean
  isError: boolean
}>(({ backgroundImage, isDragging, isError }) => ({
  border: `1px dashed ${isError ? COLOR.RED[700] : COLOR.NEUTRAL[300]}`,
  borderRadius: 4,
  width: 400,
  height: 200,
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  '::after': {
    content: isDragging === true ? '""' : 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.12)',
  },
  '&:hover': {
    cursor: 'pointer',
    '::after': {
      content: "' '",
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.12)',
    },
  },
}))

const FileName = styled.button({
  marginTop: 4,
  fontWeight: 400,
  fontSize: 15,
  color: COLOR.BLUE[700],
  lineHeight: '20px',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
  padding: 0,
})

const ImagePicker = styled.a<{ isDragging: boolean; isError: boolean }>(
  ({ isDragging, isError }) => ({
    border: `1px dashed ${isError ? COLOR.RED[700] : COLOR.NEUTRAL[300]}`,
    borderRadius: 4,
    width: 400,
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: isDragging === true ? COLOR.NEUTRAL[100] : 'transparent',
  }),
)

const IconWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(153, 178, 202, 0.12)',
    height: 40,
    width: 40,
    '& > svg > path': {
      fill: COLOR.NEUTRAL[800],
    },
  },
})

const Title = styled.label({
  marginBottom: 5,
  fontWeight: 700,
  fontSize: 14,
})

const Requirement = styled.p<{ hasError: boolean }>(({ hasError }) => ({
  color: hasError ? COLOR.RED[700] : COLOR.NEUTRAL[600],
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '22px',
  display: 'flex',
  alignItems: 'center',
  margin: 0,
}))

const Subheader = styled.p({
  marginTop: 8,
  marginBottom: 0,
  fontSize: 14,
  lineHeight: '22px',
  fontWeight: 700,
  color: COLOR.NEUTRAL[600],
})

const BulletWrapper = styled.span({
  paddingRight: 4,
})

const BadgeWrapper = styled.div({
  marginBottom: 5,
  display: 'flex',
  gap: 8,
})

const HiddenInput = styled.input({
  display: 'none',
})

export { ImageUpload }
