import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { BasicModal } from '@helloextend/merchants-ui'
import { Button } from '@helloextend/zen'

type RestoreVersionModalProps = {
  isOpen: boolean
  handleModalToggle: () => void
  handleRestoreClick: () => void
  id: string
  latestVersionId: number
  isProcessing: boolean
  type: string
}

const RestoreVersionModal: FC<RestoreVersionModalProps> = ({
  isOpen,
  handleModalToggle,
  handleRestoreClick,
  id,
  latestVersionId,
  isProcessing,
  type,
}) => {
  const handleModalSuccessClose = (): void => {
    handleModalToggle()
  }

  return (
    <BasicModal isVisible={isOpen} onClickClose={handleModalSuccessClose}>
      <Title>Restore Version</Title>
      <Subtext>
        You are about to restore this version of the {type}. The restored version will become
        version <BoldText data-cy="version">{latestVersionId + 1}</BoldText> of {type} ID:{' '}
        <BoldText data-cy="id">{id}</BoldText>.
      </Subtext>
      <Subtext>
        This may affect existing offers. Are you sure you want to restore the version?
      </Subtext>
      <ButtonGroup>
        <Button
          onClick={handleModalToggle}
          text="Cancel"
          data-cy="cancel-button"
          type="button"
          size="regular"
          emphasis="medium"
          isDisabled={isProcessing}
        />
        <Button
          onClick={handleRestoreClick}
          text="Restore"
          type="button"
          size="regular"
          emphasis="high"
          isProcessing={isProcessing}
          data-cy="modal-restore-version-button"
        />
      </ButtonGroup>
    </BasicModal>
  )
}

const Title = styled.h2({
  fontSize: 20,
  lineHeight: '28px',
  fontWeight: 700,
  marginTop: 0,
})

const Subtext = styled.p({
  fontSize: 16,
  lineHeight: '24px',
})

const BoldText = styled.span({
  fontWeight: 700,
})

const ButtonGroup = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 8,
  marginTop: 32,
})

export { RestoreVersionModal, RestoreVersionModalProps }
