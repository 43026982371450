import type {
  ExtendFee,
  Fee,
  InsuranceProgram,
  Obligor,
} from '@helloextend/extend-api-client/src/models/insurance-program'
import * as Yup from 'yup'

const sharedFeeSchema = Yup.object()
  .shape({
    type: Yup.string().required('Fee Type is required'),
    amount: Yup.number()
      .required('Fee Amount is required')
      .min(0, 'Fee Amount should be greater than or equal to 0')
      .transform((currentValue, originalValue) => {
        return originalValue === '' || originalValue === null ? undefined : currentValue
      })
      .test(
        'is percentage',
        'Fee Amount should be less than or equal to 100',
        function isAmountTypePercentage(value) {
          const isUnitPercentage: boolean = this.parent.feeAmountOfType === 'percentage'
          if (!isUnitPercentage) {
            return true
          }
          return isUnitPercentage && (value || 0) <= 100
        },
      ),
    feeAmountOfType: Yup.string().required('Fee Amount Type is required'),
    basedOn: Yup.string<Fee['basedOn']>().when('feeAmountOfType', (value: string) =>
      value === 'percentage'
        ? Yup.string().required('Based On is required')
        : Yup.string().notRequired(),
    ),
  })
  .defined()

const extendFeeSchema = sharedFeeSchema
  .shape({
    thirdPartyProvider: Yup.string().required('Third Party Provider is required'),
  })
  .defined()

const obligorFeeSchema = sharedFeeSchema
  .shape({
    routing: Yup.string().required('Routing is required'),
    recognition: Yup.string().required('Recognition is required'),
    otherFee: Yup.string().when('type', (value: string) =>
      value === 'Other'
        ? Yup.string().required('Other Fee is required')
        : Yup.string().notRequired(),
    ),
  })
  .defined()

const obligorSchema = Yup.object()
  .shape({
    name: Yup.string().required('Obligor is required'),
    riskOwnership: Yup.number()
      .required('Risk Ownership is required')
      .min(0, 'Risk Ownership should be greater than or equal to 0')
      .max(100, 'Profit Share Primary Obligor should be less than or equal to 100')
      .test('one obligor', 'Risk Ownership should be 100%', function checkRiskOwnership() {
        const { obligor } = (this?.options as TestContextExtended)?.from?.[1]?.value
        // from[1] is the main schema with access to obligor array
        if (obligor.length === 1) {
          return Number(obligor[0].riskOwnership) === 100
        }
        return true
      })
      .test(
        'multiple obligors',
        'The combined value of Risk Ownership and Risk Transfer should be 100%',
        function totalRiskOwnership() {
          const { from } = this.options as TestContextExtended
          // from[1] is the main schema with access to obligor array
          const total = from[1].value.obligor.reduce(
            (previousValue: number, currentObligor: Obligor) => {
              return previousValue + Number(currentObligor?.riskOwnership)
            },
            0,
          )
          if (from[1].value.obligor.length === 1) {
            return true
          }
          return total === 100
        },
      ),
    notes: Yup.string().notRequired(),
    fees: Yup.array()
      .of(obligorFeeSchema)
      .required('Fees is required')
      .min(1, 'Must have at least one fee'),
    extendFee: Yup.mixed<ExtendFee>()
      .nullable()
      .notRequired()
      .when('name', (value: string) =>
        value === 'EWSC/EPC' || value === 'Extend, Inc'
          ? extendFeeSchema.notRequired()
          : Yup.object().nullable().notRequired(),
      ),
  })
  .defined()

const schema = Yup.object()
  .shape({
    id: Yup.string()
      .required('Insurance Program ID is required')
      .default('')
      .test(
        'No spaces',
        'Insurance Program ID must not contain any spaces',
        (value) => !value?.includes(' '),
      ),
    name: Yup.string().required('Insurance Program Name is required'),
    activateFrom: Yup.mixed<undefined | number>()
      .required('Activate From is required')
      .default(undefined),
    activateTo: Yup.mixed<undefined | number>()
      .notRequired()
      .default(undefined)
      .test(
        'activateTo',
        'Activate To must be later than Activate From',
        function testActivateTo(value) {
          const { activateFrom } = this.parent
          if (!activateFrom || value === null || value === undefined || value === 0) {
            return true
          }
          return value >= activateFrom
        },
      ),
    permittedGeo: Yup.string().required('Permitted Geo is required'),
    insuranceProgramNotes: Yup.string().default('').notRequired(),
    profitSharePrimaryObligor: Yup.number()
      .required('Profit Share for Primary Obligor is required')
      .min(0, 'Profit Share for Primary Obligor should be greater than or equal to 0')
      .max(100, 'Profit Share for Primary Obligor should be less than or equal to 100')
      .nullable(),
    profitSharePartner: Yup.string().notRequired(),
    extendProfitShare: Yup.number()
      .required('Extend Profit Share is required')
      .min(0, 'Extend Profit Share should be greater than or equal to 0')
      .max(100, 'Extend Profit Share should be less than or equal to 100')
      .nullable(),
    lossAccrual: Yup.number()
      .required('Loss Accrual is required')
      .min(0, 'Loss Accrual should be greater than or equal to 0')
      .max(100, 'Loss Accrual should be less than or equal to 100')
      .nullable(),
    targetLossToReserveRatio: Yup.number()
      .required('Target Loss to Reserve Ratio is required')
      .min(0, 'Target Loss to Reserve Ratio should be greater than or equal to 0')
      .max(100, 'Target Loss to Reserve Ratio should be less than or equal to 100')
      .nullable(),
    actualLossToReserveRatio: Yup.number().notRequired().nullable(),
    plannedLossToPremiumRatio: Yup.number()
      .required('Planned Loss to Premium Ratio is required')
      .min(0, 'Planned Loss to Premium Ratio should be greater than or equal to 0')
      .max(100, 'Planned Loss to Premium Ratio should be less than or equal to 100')
      .nullable(),
    actualLossToPremiumRatio: Yup.number().notRequired().nullable(),
    obligor: Yup.array().of(obligorSchema).min(1, 'Should have at least one obligor').required(),
  })
  .defined()

const updatedNoteSchema = Yup.object()
  .shape({
    updatedNote: Yup.string().required('Updated Reason is required'),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

type UpdatedNoteValues = Yup.InferType<typeof updatedNoteSchema>

// used to access different portions of schema within sub-schemas
interface TestContextExtended extends Yup.TestContext {
  from: Array<{
    schema: Yup.ObjectSchema
    value: InsuranceProgram
  }>
}

export { schema, updatedNoteSchema, Values, UpdatedNoteValues }
