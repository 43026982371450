import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'

const UserDetailsToastErrorText: FC = () => {
  const handleRefreshClick = (): void => {
    return window.location.reload()
  }

  return (
    <>
      Unable to retrieve user data. Please{' '}
      <ReloadText onClick={handleRefreshClick}>refresh page</ReloadText> to reload.
    </>
  )
}

const ReloadText = styled.span({
  textDecoration: 'underline',
  cursor: 'pointer',
})

export { UserDetailsToastErrorText }
