import { OKTA_RESET_PASSWORD_URL } from '@helloextend/client-constants'
import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Button, ButtonGroup, Info, MenuButtonItem } from '@helloextend/zen'
import { BasicModal } from '../../../../../components/basic-modal'

const RESET_PASSWORD_TEXT = 'Click here to navigate to the platform Okta reset password page'
type ResetPasswordModalProps = {
  isVisible: boolean
  onClickClose: () => void
}

const clickHandler = (): void => {
  window.open(OKTA_RESET_PASSWORD_URL, '_blank', 'noreferrer')
}

const ResetPasswordModal: FC<ResetPasswordModalProps> = ({ isVisible, onClickClose }) => {
  return (
    <BasicModal onClickClose={onClickClose} isVisible={isVisible}>
      <Title>Reset Password</Title>
      <LinkText>
        <MenuButtonItem data-cy="reset-password-link" icon={Info} onClick={clickHandler}>
          {RESET_PASSWORD_TEXT}
        </MenuButtonItem>
      </LinkText>
      <ButtonGroupWrapper>
        <ButtonGroup>
          <Button onClick={onClickClose} emphasis="medium" text="Cancel" isDisabled />
          <Button
            onClick={() => {}}
            color="blue"
            text="Send"
            isDisabled
            isProcessing={false}
            data-cy="send-reset-password-button"
          />
        </ButtonGroup>
      </ButtonGroupWrapper>
    </BasicModal>
  )
}

const Title = styled.h3({
  fontSize: 20,
})

const LinkText = styled.div({
  marginTop: 16,
})

const ButtonGroupWrapper = styled.div({
  marginTop: 48,
  display: 'flex',
  justifyContent: 'flex-end',
})

export { ResetPasswordModal, ResetPasswordModalProps }
