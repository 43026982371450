import styled from '@emotion/styled'

export const Subheading = styled.h4({
  margin: 0,
  padding: 0,
  fontSize: 14,
  lineHeight: '18px',
  fontWeight: 700,
  textTransform: 'uppercase',
})
