export enum LDFlag {
  PlanSetsAdmin = 'plan-sets-admin',
  SettingsManagement = 'settings-management',
  ServiceOrderManagement = 'service-order-management',
  ProductsManagement = 'products-management',
  CategoriesManagement = 'categories-management',
  SkuManagement = 'sku-management',
  ServiceOrderSearchList = 'service-order-search-list',
  DefaultPackingSlip = 'default-packing-slip',
  PlanManagerPlansVersionBrowser = 'plan-manager-plans-version-browser',
  ByoReturnLabel = 'byo-return-label',
  ClaimsManagement = 'claims-management',
  ClaimNotesEnabled = 'claim-notes-enabled',
  AdjudicationManagement = 'adjudication-management',
  ClaimsClose = 'claims-close',
  AssignUser = 'assign-user',
  ClaimsPayment = 'claims-payment',
  ClaimPhotosManagement = 'claim-photos-management',
  StoresManagement = 'stores-management',
  ThreadEditEnabled = 'adjudication-management-thread-edit',
  /* AO FF */
  OktaLogin = 'extend-portal-okta-login',
  ExtendUserManagement = 'extend-user-management',
  MerchantV3UserManagement = 'merchant-v3-user-management',
  AccountsManagement = 'accounts-management',
  EnterpriseRoles = 'enterprise-roles',
  EnterpriseAccountManagement = 'enterprise-accounts-management',
  EnterpriseAccountProvision = 'enterprise-account-provision',
  OrganizationsManagement = 'organizations-management',
  AssignedPlanSets = 'assigned-plan-sets',
  /* AO FF */
  ServiceOrdersFlexibleServicing = 'service-orders-flexible-servicing',
  ExpandedServiceOrdersSection = 'expanded-service-orders-section',
  MerchantServicingSettings = 'merchant-servicing-settings',
  UseClaimsManagement = 'cs-use-claims-management',
  ClaimsManagementClaimsPut = 'claims-management-claims-put',
  StorePlanMapping = 'store-plan-mappings',
  CancelVirtualCard = 'cancel-virtual-card',
  ReopenClaim = 'cs-reopen-claim',
  StoresProducts = 'stores-products',
  ServicingIntegrationSuite = 'servicing-integration-suite',
  CustomDefectiveLabel = 'servicer-support-custom-defective-label',
  TermsManagement = 'terms-management',
  CloseNoService = 'close-no-service',
  ClaimsDetailsAdjudication = 'claims-details-adjudication',
  SODirectPayment = 'so-direct-payment',
  SPPurchaseModel = 'sp-purchase-model',
}

type LDFlagType = boolean | string | number | JSON

// when changing, match apps/portal/cypress/support/ff-helper.ts with this
export const defaultLDFlagValues: Record<LDFlag, LDFlagType> = {
  [LDFlag.PlanSetsAdmin]: false,
  [LDFlag.SettingsManagement]: false,
  [LDFlag.ServiceOrderManagement]: false,
  [LDFlag.ProductsManagement]: false,
  [LDFlag.CategoriesManagement]: false,
  [LDFlag.SkuManagement]: false,
  [LDFlag.ServiceOrderSearchList]: false,
  [LDFlag.DefaultPackingSlip]: false,
  [LDFlag.PlanManagerPlansVersionBrowser]: false,
  [LDFlag.ByoReturnLabel]: false,
  [LDFlag.ClaimsManagement]: false,
  [LDFlag.UseClaimsManagement]: false,
  [LDFlag.ClaimNotesEnabled]: false,
  [LDFlag.AdjudicationManagement]: false,
  [LDFlag.ClaimsClose]: false,
  [LDFlag.AssignUser]: false,
  [LDFlag.ClaimsPayment]: false,
  [LDFlag.ClaimPhotosManagement]: false,
  [LDFlag.StoresManagement]: false,
  /* AO FF */
  [LDFlag.OktaLogin]: false,
  [LDFlag.ExtendUserManagement]: false,
  [LDFlag.MerchantV3UserManagement]: false,
  [LDFlag.AccountsManagement]: false,
  [LDFlag.EnterpriseRoles]: false,
  [LDFlag.EnterpriseAccountManagement]: false,
  [LDFlag.EnterpriseAccountProvision]: false,
  [LDFlag.OrganizationsManagement]: false,
  [LDFlag.AssignedPlanSets]: false,
  /* AO FF */
  [LDFlag.ThreadEditEnabled]: false,
  [LDFlag.ServiceOrdersFlexibleServicing]: false,
  [LDFlag.ExpandedServiceOrdersSection]: false,
  [LDFlag.MerchantServicingSettings]: false,
  [LDFlag.ClaimsManagementClaimsPut]: false,
  [LDFlag.StorePlanMapping]: false,
  [LDFlag.CancelVirtualCard]: false,
  [LDFlag.StoresProducts]: false,
  [LDFlag.ServicingIntegrationSuite]: false,
  [LDFlag.CustomDefectiveLabel]: false,
  [LDFlag.ReopenClaim]: false,
  [LDFlag.TermsManagement]: false,
  [LDFlag.CloseNoService]: false,
  [LDFlag.ClaimsDetailsAdjudication]: false,
  [LDFlag.SODirectPayment]: false,
  [LDFlag.SPPurchaseModel]: false,
}
