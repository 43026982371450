import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { COLOR } from '@helloextend/client-branding'

export const SplashScreen: FC = () => {
  return (
    <Splash role="main">
      <Page>
        <svg width="52" height="52" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <path
              d="M1.3 2.9C6.6 1.5 10.6.7 13.2.2 15 0 16.4.2 18 1l1 .4.4.2.5.2c2 1 3.5 1.4 5.9 1.4 2.3 0 4-.4 6.5-1.4l1.4-.5 1.5-.6a9 9 0 0 1 4.3-.3c2.4.4 6 1.3 10.8 2.6l1.1.3v1.1c0 9.6-1.8 18.2-5.7 25.6a63.9 63.9 0 0 1-18.4 20.8l-1 .7-1-.7A64.8 64.8 0 0 1 6 30C2 22.5 0 14 .1 4.3V3.2l1.2-.3z"
              fill="#090637"
              fillRule="nonzero"
            />
            <path
              d="M8.8 28.5c3.6 6.8 9.5 13.1 17.5 19.1a50.5 50.5 0 0 0 22-42.2c-4.1-1-7.2-1.8-9.3-2.1-1-.2-1.8-.1-2.9.2L35 4l-1.5.5c-2.7 1-4.8 1.6-7.6 1.6A16 16 0 0 1 18 4.2l-.5-.2-.8-.3c-1.1-.5-2-.7-3.1-.5-2.3.4-5.8 1.1-10.4 2.3a48 48 0 0 0 5.5 23z"
              fill="#FFF"
              fillRule="nonzero"
            />
            <path d="M37 30.1h-7L25.6 25 21.4 30h-7l7.7-9-7.3-8.2h7L37 30z" fill="#090637" />
            <path fill="#27AEE4" d="M26.7 16.3l2.8-3.4h6.7l-6.1 7.3z" />
          </g>
        </svg>
        <LoadingBar data-cy="loading-bar">
          <LoadingFill />
        </LoadingBar>
      </Page>
    </Splash>
  )
}

const Splash = styled.div({
  width: '100vw',
  height: '100vh',
  margin: 0,
  fontFamily: 'Nunito Sans',
  boxSizing: 'border-box',
})

const Page = styled.div({
  alignItems: 'center',
  backgroundColor: COLOR.MOSTLY_WHITE_0,
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  justifyContent: 'center',
  width: '100vw',
})

const LoadingBar = styled.div({
  boxShadow: 'inset 0px 0px 3px 1px rgba(155,155,155,0.7)',
  borderRadius: '2px',
  height: '3px',
  marginTop: '1.5rem',
  overflow: 'hidden',
  position: 'relative',
  width: '100px',
})

const bounceAround = keyframes({
  '0%': {
    transform: 'translateX(-5px)',
  },
  '50%': {
    transform: 'translateX(75px)',
  },
  '100%': {
    transform: ' translateX(-5px)',
  },
})

const LoadingFill = styled.div({
  animation: `${bounceAround} 1250ms ease-in-out infinite`,
  backgroundColor: COLOR.VERY_DARK_DESATURATED_BLUE,
  borderRadius: '2px',
  display: 'block',
  height: '4px',
  position: 'absolute',
  width: '30px',
})
