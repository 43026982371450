import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/client-utils/src/request'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.fetchAuditHistory>

export default function* fetchAuditHistory(action: Action): SagaIterator {
  const { contractId, accessToken, cursor, limit } = action.payload

  yield put(actions.auditHistoryRequest())

  try {
    const response = yield call(
      client.audit.getHistory,
      accessToken,
      'CONTRACT',
      contractId,
      cursor,
      limit,
    )

    if (isErrorResponse(response)) {
      yield put(actions.auditHistoryFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(actions.auditHistoryFailure('An unknown error occurred', response.status))
      return
    }

    yield put(actions.auditHistorySuccess(response.data.items, response.data.nextPageCursor))
  } catch (e) {
    yield put(actions.auditHistoryFailure(e.message))
  }
}
