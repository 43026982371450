import type {
  CarouselPromptOption,
  MultiSelectPromptOption,
  SelectPromptOption,
  ClaimPhotoDetail,
} from '@helloextend/extend-api-client'
import type { ClaimSessionLog } from '@helloextend/extend-api-rtk-query/src/incredibot/types'
import type { AdjudicationAnswerMessageList } from '../../../../types'

export const compareByCreatedAt = (a: ClaimSessionLog, b: ClaimSessionLog): number => {
  if (a.createdAt < b.createdAt) return -1
  if (a.createdAt > b.createdAt) return 1
  return 0
}

const omittedLogs = [
  'HelpType',
  'ClaimType',
  'ContractsSearchKey',
  'ContractId',
  'AddressVerificationChoice',
  'EndClaim',
]

export const transformSortedClaimSessionLogs = (
  sortedLogs: ClaimSessionLog[],
  photos?: ClaimPhotoDetail[],
): AdjudicationAnswerMessageList => {
  return sortedLogs.reduce((acc, log, index, arr) => {
    if (!log.slot || omittedLogs.includes(log.slot)) return acc
    let answer = log.slotValue
    let message
    let options
    const messages = log?.reply?.messages

    if (messages?.length > 0) {
      message = messages[messages.length - 1].content
    }

    const previous = arr[index - 1]
    const previousReply = previous?.reply
    const previousPrompt = previousReply?.prompt
    const textMessages = previousReply?.messages.filter((msg) => msg.type === 'text')
    const previousMessage = textMessages[textMessages.length - 1].content ?? ''
    const photo = photos?.find((item: ClaimPhotoDetail) => item.name === previous.slotValue)
    let type: string = previousPrompt?.type ?? 'input'

    switch (type) {
      case 'buttons':
      case 'carousel':
      case 'multiselect':
        if (previousPrompt && 'options' in previousPrompt) {
          const previousPromptOptions: Array<
            MultiSelectPromptOption | SelectPromptOption | CarouselPromptOption
          > = previousPrompt?.options
          options = previousPromptOptions.filter(({ value }) => value !== 'difference')
        }
        type = 'radio'
        break
      case 'addressInput':
        if (typeof log?.slotValue !== 'object') {
          throw new Error('address inputs can only be of type `Object`')
        }

        answer = Object.values(log.slotValue).reduce(
          (str: string, address) => str.concat(`${address} `),
          '',
        )
        break
      case 'imageUpload':
        answer = photo?.url
        break
    }
    acc.push({ type, answer, message, prompt: previousMessage, options })
    return acc
  }, [] as AdjudicationAnswerMessageList)
}
