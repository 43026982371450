import styled from '@emotion/styled'
import type { ScriptItem } from '@helloextend/extend-api-rtk-query'
import { COLOR } from '@helloextend/zen'
import type { FC } from 'react'
import React from 'react'
import type { DecisionValues } from './adjudication-decision-block.utils'
import { getDecisionBlockTitle, getDecisionBlockValues } from './adjudication-decision-block.utils'
import { AdjudicationPromptWrapper } from './prompt-types/adjudication-prompt-wrapper'

interface DecisionBlockProps {
  scriptItem: ScriptItem
  onBadgeClick: (val: number | string) => void
}

const AdjudicationDecisionBlock: FC<DecisionBlockProps> = ({ scriptItem, onBadgeClick }) => {
  const {
    collect: { options, needs = [] },
  } = scriptItem

  const values: DecisionValues[] = getDecisionBlockValues(options)
  const title: string | undefined = getDecisionBlockTitle(needs)

  return (
    <TemplateWrapper>
      <HeadingRow>
        <MessageTitle>Non-Customer-Facing Kaley Decision</MessageTitle>
      </HeadingRow>
      {title && (
        <DecisionBlockTitle data-cy="adjudication-management-block-title">
          {title}
        </DecisionBlockTitle>
      )}
      <MessagesWrapper>
        {values.map((option) => (
          <AdjudicationPromptWrapper
            hasBottomBorder
            key={JSON.stringify(option)}
            badgeValue={option.index}
            onBadgeClick={onBadgeClick}
          >
            <StyledItem data-cy="adjudication-management-option-value">{option.value}</StyledItem>
          </AdjudicationPromptWrapper>
        ))}
      </MessagesWrapper>
    </TemplateWrapper>
  )
}

const DecisionBlockTitle = styled.div({
  backgroundColor: COLOR.NEUTRAL.OPACITY[12],
  color: COLOR.NEUTRAL[800],
  margin: '5px 0',
  padding: 8,
  borderRadius: 8,
  fontStyle: 'italic',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
})

const StyledItem = styled.div({
  padding: '4px 12px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  fontStyle: 'italic',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
  color: COLOR.NEUTRAL[800],
  flexGrow: 1,
})

const TemplateWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const HeadingRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const MessageTitle = styled.div({
  color: COLOR.NEUTRAL[600],
  fontSize: 10,
  lineHeight: '18px',
  marginLeft: 4,
})

const MessagesWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  margin: '8px 0 0 0',
})

export { AdjudicationDecisionBlock }
