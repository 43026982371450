import styled from '@emotion/styled'
import { Button, DataProperty, Edit, Grid } from '@helloextend/zen'
import { useFormik } from 'formik'
import type { FC } from 'react'
import React, { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { usePermissions } from '../../../../hooks/use-permissions'
import { Permission } from '../../../../lib/permissions'
import type {
  ConversationConfigurationDetail,
  ConversationConfigurationPresentationMode,
} from '../../../../types/conversations'
import { ConversationsDropdown } from '../components/conversations-dropdown'
import { PlansDropdown } from '../components/plans-dropdown'
import { StoresDropdown } from '../components/stores-dropdown'
import { CategoryDropdown } from '../components/category-dropdown'
import { LeavePageGuard, LeavePageModal } from '../../../../components/leave-page-guard'

const schema = Yup.object()
  .shape({
    category: Yup.string().required(),
    conversation: Yup.string().required(),
    planId: Yup.string().required(),
    storeId: Yup.string().required(),
  })
  .defined()

type ConfigurationFormValues = Yup.InferType<typeof schema>

interface ConversationConfigurationFormProps {
  configurationDetail?: ConversationConfigurationDetail
  mode?: ConversationConfigurationPresentationMode
  isSaveProcessing: boolean
  handleSave: (configuration: ConfigurationFormValues) => void
  onDelete?: () => void
  toggleInlineAlert?: () => void
  isInlineAlertVisible?: boolean
}

const ConversationConfigurationForm: FC<ConversationConfigurationFormProps> = ({
  configurationDetail,
  isSaveProcessing,
  handleSave: detailsHandleSave,
  onDelete,
  toggleInlineAlert,
  isInlineAlertVisible,
  mode = 'view-only',
}) => {
  const [selectedMode, setSelectedMode] = useState<ConversationConfigurationPresentationMode>(mode)
  const [isLeaveSectionModalVisible, setIsLeaveSectionModalVisible] = useState<boolean>(false)
  const { hasPermission } = usePermissions()
  const isUserAssumedEditorRole = hasPermission(Permission.ManageAdjudication)
  const { push } = useHistory()

  const isEditMode = selectedMode === 'edit'
  const isCreateMode = selectedMode === 'create'

  const handleClearInlineAlert = (): void => {
    if (isInlineAlertVisible && toggleInlineAlert) {
      toggleInlineAlert()
    }
  }

  const {
    values,
    resetForm,
    dirty: isDirty,
    handleChange,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: schema,
    validate: handleClearInlineAlert,
    initialValues: {
      conversation: configurationDetail?.conversationId ?? '',
      category: configurationDetail?.category ?? '',
      planId: configurationDetail?.planId ?? '',
      storeId: configurationDetail?.storeId ?? '',
    },
    onSubmit: () => {},
  })

  const handleEdit = (): void => {
    setSelectedMode('edit')
  }

  const handleCancel = (): void => {
    if (isCreateMode) {
      push('/admin/adjudication-management/conversation-configuration')
      return
    }
    if (isDirty) {
      setIsLeaveSectionModalVisible(true)
    } else {
      setSelectedMode('view-only')
    }
  }

  const handleSave = (): void => {
    detailsHandleSave(values)
  }

  const handleLeaveSectionModalLeave = useCallback((): void => {
    resetForm()
    setSelectedMode('view-only')
    setIsLeaveSectionModalVisible(false)
  }, [resetForm])

  const handleLeaveSectionModalClose = useCallback((): void => {
    setIsLeaveSectionModalVisible(false)
  }, [])

  const handleLeavePage = (path: string): void => {
    push(path)
  }

  return (
    <>
      <LeavePageGuard
        isNavBlocked={isDirty && !isCreateMode}
        handleLeavePage={handleLeavePage}
        mainText="Hang on! You have some unsaved changes"
        detail="If you leave the page without saving, all unsaved changes will be lost."
        overflow="auto"
      />
      <LeavePageModal
        isVisible={isLeaveSectionModalVisible}
        handleCloseModal={handleLeaveSectionModalClose}
        handleLeavePage={handleLeaveSectionModalLeave}
        mainText="Hang on! You have some unsaved changes"
        detail="If you leave the page without saving, all unsaved changes will be lost."
        overflow="auto"
      />
      <GridContainer hasPadding={!isCreateMode} data-cy="conversation-configuration-form">
        <Grid
          data-cy="conversation-configuration-details-presenter"
          columns={1}
          spacing={{
            md: 4,
          }}
        >
          <TopRowContainer>
            {selectedMode === 'create' || selectedMode === 'edit' ? (
              <DropdownWrapper>
                <ConversationsDropdown
                  conversationsType={selectedMode === 'edit' ? 'published' : 'all'}
                  value={values.conversation}
                  onChange={handleChange}
                />
              </DropdownWrapper>
            ) : (
              <DataProperty
                label="Conversation*"
                value={configurationDetail?.conversationTitle}
                data-cy="configuration-conversation-data-property"
              />
            )}
            {isUserAssumedEditorRole && !isEditMode && !isCreateMode && (
              <Button
                emphasis="low"
                text="Edit"
                onClick={handleEdit}
                icon={Edit}
                data-cy="conversation-configuration-edit-button"
              />
            )}
          </TopRowContainer>
          {selectedMode === 'create' ? (
            <CategoryDropdown value={values.category} onChange={handleChange} />
          ) : (
            <DataProperty
              label="Adjudication Category*"
              value={values.category}
              data-cy="configuration-category-data-property"
            />
          )}
          {isCreateMode ? (
            <StoresDropdown value={values.storeId} onChange={handleChange} />
          ) : (
            <DataProperty
              label="Store ID"
              value={values.storeId}
              data-cy="configuration-storeId-data-property"
            />
          )}
          {isCreateMode ? (
            <PlansDropdown value={values.planId} onChange={handleChange} />
          ) : (
            <DataProperty
              label="Plan ID"
              value={values.planId}
              data-cy="configuration-planId-data-property"
            />
          )}
          {(isEditMode || isCreateMode) && (
            <ButtonContainer>
              {isEditMode && (
                <Button
                  data-cy="conversation-configuration-delete-button"
                  emphasis="medium"
                  color="red"
                  onClick={onDelete}
                  size="regular"
                  text="Delete"
                />
              )}
              <Button
                data-cy="conversation-configuration-cancel-button"
                emphasis="medium"
                color="blue"
                onClick={handleCancel}
                size="regular"
                text="Cancel"
              />
              <Button
                data-cy="conversation-configuration-save-button"
                emphasis="high"
                color="blue"
                onClick={handleSave}
                size="regular"
                text="Save"
                isDisabled={!isDirty}
                isProcessing={isSaveProcessing}
              />
            </ButtonContainer>
          )}
        </Grid>
      </GridContainer>
    </>
  )
}

const GridContainer = styled.div<{ hasPadding: boolean }>(({ hasPadding }) => ({
  padding: hasPadding ? 40 : 0,
}))

const TopRowContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
})

const DropdownWrapper = styled.div({
  flex: 1,
})

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  button: {
    marginRight: 8,
  },
})

export {
  ConversationConfigurationForm,
  ConversationConfigurationFormProps,
  ConfigurationFormValues,
}
