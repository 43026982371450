import type { Action } from '../../actions'

export type SupportStatusReducer = string | null

const initialState: SupportStatusReducer = null

export function supportStatus(state = initialState, action: Action): SupportStatusReducer {
  switch (action.type) {
    case 'CREATE_SUPPORT_SUCCESS': {
      return 'SUCCESS'
    }
    case 'TOGGLE_SUPPORT':
      return null
    default:
      return state
  }
}
