import { faker } from '@faker-js/faker/locale/en'
import type {
  Store,
  StoreAddress,
  StoreBranding,
  StoreIntegrationSettings,
  StoreOfferConfig,
  StorePlatformMetaData,
  StoreShippingProtection,
  StoreConsumerReadableProperties,
  StoresSearchItem,
  StorePricing,
  SPPlanPriceBandMapping,
} from '@helloextend/extend-api-client'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function generateStore(overrides: any = {}): Store {
  const storeId: string = faker.datatype.uuid()

  const platformMetaData: StorePlatformMetaData = {
    platformStoreName: faker.company.name(),
    ...overrides.platformMetaData,
  }

  const branding: StoreBranding = {
    primaryColor: '#27AEE4',
    font: 'Comic Sans',
    fontUrl: 'https://tiny.cc/n3ft8y',
    ...overrides.branding,
  }

  const offerConfig: StoreOfferConfig = {
    submitForm: true,
    addToCartSelector: '#add-to-cart',
    productOfferSelector: '.extend-product-offer',
    modalOfferEnabled: true,
    productOfferEnabled: true,
    productOfferPosition: 'before',
    ...overrides.offerConfig,
  }

  const address: StoreAddress = {
    address1: faker.address.streetAddress(),
    address2: faker.address.secondaryAddress(),
    city: faker.address.city(),
    country: faker.address.country(),
    countryCode: faker.address.countryCode(),
    province: faker.address.state(),
    provinceCode: faker.address.stateAbbr(),
    zipCode: faker.address.zipCode(),
    ...overrides.address,
  }

  const integrationSettings: StoreIntegrationSettings = {
    autoFulfillPlans: true,
    autoRefunds: true,
    barcodes: false,
    catalogImport: true,
    claimsFullfillment: true,
    contractEvent: 'orderFulfill',
    createContracts: true,
    createLeads: true,
    createdAt: faker.date.past().getTime(),
    offerSync: true,
    offerSyncType: 'directMap',
    platform: 'shopify',
    platformStoreKey: 'testing-shopify-store',
    productPriceField: 'default',
    productsCreate: true,
    productsDelete: false,
    productsUpdate: false,
    storeId,
    updatedAt: faker.date.past().getTime(),
  }

  const shippingProtection: StoreShippingProtection = {
    approved: true,
    enabled: true,
    planId: 'sp-plan-123',
    offerType: 'OPT_IN',
    ...overrides.shippingProtection,
  }

  return {
    id: storeId,
    address,
    logoKey: faker.internet.url(),
    logoUrl: `https://extendcoreapidevextendco-storelogosbucket03b65aac-1lfcr4xthv9ir.s3.amazonaws.com/stores/${storeId}/${faker.internet.url()}`,
    annualSales: faker.datatype.number(),
    branding,
    offerConfig,
    currencyCode: 'USD',
    domain: faker.internet.domainName(),
    enabled: false,
    name: faker.company.name(),
    platform: 'shopify',
    platformMetaData,
    storeType: faker.commerce.productMaterial(),
    shippingProtection,
    claimsManagementEnabled: true,
    approved: true,
    ...overrides,
    integrationSettings,
    createdAt: faker.date.past().getTime(),
    updatedAt: faker.date.past().getTime(),
  }
}

export function generateStoreConsumerReadableProperties(
  overrides?: Partial<StoreConsumerReadableProperties>,
): StoreConsumerReadableProperties {
  return {
    name: faker.company.name(),
    domain: faker.internet.domainName(),
    logoUrl: faker.internet.url(),
    ...overrides,
  }
}

export const generateStoresSearchItem = (
  overrides?: Partial<StoresSearchItem>,
): StoresSearchItem => {
  return {
    accountId: faker.datatype.uuid(),
    storeId: faker.datatype.uuid(),
    storeName: faker.company.name(),
    approved: true,
    enabled: true,
    secondaryName: faker.company.name(),
    shippingProtectionApproved: true,
    shippingProtectionEnabled: true,
    merchantEwRevenueAgreement: 'net_revenue_share',
    merchantEwRevenueSharePercentage: 0.1,
    ...overrides,
  }
}

export const generateStorePricingItem = (overrides?: Partial<StorePricing>): StorePricing => ({
  storeId: faker.datatype.uuid(),
  planId: faker.datatype.uuid(),
  skuId: faker.datatype.uuid(),
  wholesalePrice: faker.datatype.number(),
  createdAt: faker.date.past().getTime(),
  active: true,
  version: 1,
  ...overrides,
})

export const generateSPPlanPriceBandMapping = (
  overrides: Partial<SPPlanPriceBandMapping> = {},
): SPPlanPriceBandMapping => ({
  low: 0,
  high: faker.datatype.number({ min: 1, max: 1000 }),
  planId: faker.datatype.uuid(),
  fixedPremium: faker.datatype.number({ min: 1, max: 1000 }),
  partialReimbursement: faker.datatype.number({ min: 0.01, max: 1, precision: 0.01 }),
  ...overrides,
})
