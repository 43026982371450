import type { PlanTermsLanguage } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetchTermsVersionLanguages = (accessToken: string, termsId: string, version: string) =>
  ({
    type: 'PLAN_TERMS_VERSION_LANGUAGES',
    payload: { accessToken, termsId, version },
  } as const)

export const fetchTermsVersionLanguagesRequest = () =>
  ({
    type: 'PLAN_TERMS_VERSION_LANGUAGES_REQUEST',
  } as const)

export const fetchTermsVersionLanguagesSuccess = (planTermsLanguages: PlanTermsLanguage[]) =>
  ({
    type: 'PLAN_TERMS_VERSION_LANGUAGES_SUCCESS',
    payload: planTermsLanguages,
  } as const)

export const fetchTermsVersionLanguagesFailure = createActionFailure(
  'PLAN_TERMS_VERSION_LANGUAGES_FAILURE',
)

export const fetchTermsVersionLanguageRequest = () =>
  ({
    type: 'PLAN_TERMS_VERSION_LANGUAGE_REQUEST',
  } as const)

export const fetchTermsVersionLanguageSuccess = (url: string) =>
  ({
    type: 'PLAN_TERMS_VERSION_LANGUAGE_SUCCESS',
    payload: url,
  } as const)

export const fetchTermsVersionLanguageFailure = createActionFailure(
  'PLAN_TERMS_VERSION_LANGUAGE_FAILURE',
)

export const planTermsVersionLanguageOpened = () =>
  ({
    type: 'PLAN_TERMS_VERSION_LANGUAGE_OPENED',
  } as const)
