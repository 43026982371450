import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { Middleware } from '@reduxjs/toolkit'
import logger from '@helloextend/client-logger'

export const logRtkQueryErrors: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    logger.warn('Error from RTK query', { error: action.error })
  }

  return next(action)
}
