import type { MouseEventHandler } from 'react'
import type { IconProps } from '../../tokens/icons/icon-props'
import type { IconSize } from '../icon'

export type ButtonOrLinkButtonProps = ButtonProps | LinkButtonProps

export interface ButtonProps extends SharedButtonProps {
  formId?: string
  /**
   * Should only be used after the button has been clicked and an event is processing that may take time.
   * > Note: This prop also renders the button functionally disabled preventing the need to also apply isDisabled.
   */
  isProcessing?: boolean
  isToggled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  onMouseDown?: MouseEventHandler<HTMLButtonElement>
  type?: 'submit' | 'button'
}

export interface DivButtonProps extends SharedButtonProps {
  isToggled?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
  onMouseDown?: MouseEventHandler<HTMLDivElement>
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void
}

export interface LinkButtonProps extends SharedButtonProps {
  to: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  onMouseDown?: MouseEventHandler<HTMLAnchorElement>
  openInNew?: boolean
}

interface SharedButtonProps {
  ariaLabel?: string
  ariaExpanded?: boolean
  ariaHasPopup?: 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog'
  isBold?: boolean
  emphasis?: 'low' | 'medium' | 'high'
  size?: 'xsmall' | 'small' | 'regular' | 'large'
  color?: 'blue' | 'green' | 'neutral' | 'yellow' | 'red'
  fillContainer?: boolean
  icon?: IconProps
  iconPosition?: 'left' | 'right'
  iconTransitionDurationMs?: number
  tooltip?: string
  isInverted?: boolean
  /**
   * Generally used if the button needs to be disabled while waiting for some requirement to be fulfilled.
   */
  isDisabled?: boolean
  hasReducedPadding?: boolean
  text?: string
  'data-cy'?: string
  tabIndex?: number
}

export type ButtonEmphasis = NonNullable<ButtonProps['emphasis']>
export type ButtonSize = NonNullable<ButtonProps['size']>
export type ButtonColor = NonNullable<ButtonProps['color']>
export type ButtonType = NonNullable<ButtonProps['type']>
export type ButtonIconPosition = NonNullable<ButtonProps['iconPosition']>

export enum ButtonTheme {
  blue = 'blue',
  blueInverted = 'blueInverted',
  green = 'green',
  greenInverted = 'greenInverted',
  neutral = 'neutral',
  neutralInverted = 'neutralInverted',
  yellow = 'yellow',
  yellowInverted = 'yellowInverted',
  red = 'red',
  redInverted = 'redInverted',
}

export type ColorArgs = {
  contentColor: string
  disabledContentColor: string
  borderColor?: string
  disabledBorderColor?: string
  backgroundColor?: string
  hoverBackgroundColor: string
  activeBackgroundColor: string
  disabledBackgroundColor?: string
}

export enum ButtonLayout {
  textOnly = 'textOnly',
  textOnlyReducedPadding = 'textOnlyReducedPadding',
  graphicOnly = 'graphicOnly',
  textWithGraphicLeft = 'textWithGraphicLeft',
  textWithGraphicRight = 'textWithGraphicRight',
}

export type MeasurementArgs = {
  lineHeight: string
  fontSize: string
  gap: string
  iconSize: IconSize
}
