import styled from '@emotion/styled'
import type { FC } from 'react'
import React from 'react'

type ListProps = {
  element?: 'nav' | 'ul'
  fullWidth?: boolean
}

/**
 * @deprecated This wrapper component is no longer needed when using the Zen MenuItem or MenuLink.
 */
const List: FC<ListProps> = ({ children, element: ComponentProp = 'ul', fullWidth = false }) => (
  <ListWrapper fullWidth={fullWidth}>
    <ComponentProp>{children}</ComponentProp>
  </ListWrapper>
)

const ListWrapper = styled.div<{
  fullWidth?: boolean
}>(({ fullWidth = false }) => ({
  fontFamily: 'Nunito Sans, sans-serif',
  fontWeight: 300,
  lineHeight: '22px',
  width: '100%',
  position: 'relative',
  'ul, nav': {
    margin: 0,
    padding: fullWidth ? '8px 0px' : 8,
  },
  li: {
    marginLeft: 0,
    listStyle: 'none',
  },
}))

export { List }
