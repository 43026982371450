import jwt from 'jsonwebtoken'
import type { StoreUserRole, AccessToken } from '@helloextend/extend-api-client'
import type { UserGrant } from '@helloextend/extend-api-rtk-query'
import type { V3UserDetails } from '../types/users'
import { parseTenancyErn } from './tenancy-ern'

type DecodedUserJwtToken = null | {
  admin: boolean
  exp: number
  role: StoreUserRole
}

type V3AccessToken = {
  firstName: string
  lastName: string
  ern: string
  sub: string
  email: string
  scope: string
  exp: number
  iss: string
}

export function getUserRoleFromToken(token?: string | null): StoreUserRole | null {
  if (!token) {
    return null
  }

  try {
    const decoded = jwt.decode(token) as DecodedUserJwtToken
    if (!decoded || isExpired(decoded.exp)) {
      return null
    }

    return decoded.role
  } catch (e) {
    return null
  }
}

export function decodeToken(token: AccessToken | undefined | null): V3AccessToken | undefined {
  if (!token) return undefined
  return jwt.decode(token) as V3AccessToken
}
export function getV3UserInfo(token?: string, grant?: UserGrant): V3UserDetails | undefined {
  if (!token || token === '' || !grant) return undefined

  const decodedToken = decodeToken(token)
  if (!decodedToken) return undefined
  const parsedErn = parseTenancyErn(grant.ern)

  return {
    firstName: decodedToken.firstName,
    lastName: decodedToken.lastName,
    email: decodedToken.email,
    id: decodedToken.sub,
    accountId: parsedErn?.accountResourceId ?? '',
    role: grant.role as StoreUserRole,
  }
}

export function getUserEmailFromToken(token?: string | null): string | null {
  if (!token) {
    return null
  }

  try {
    const decoded = jwt.decode(token) as V3AccessToken
    if (!decoded || isExpired(decoded.exp)) {
      return null
    }

    return decoded.email
  } catch (e) {
    return null
  }
}

function isExpired(expiration: number): boolean {
  return Date.now() / 1000 > expiration
}
