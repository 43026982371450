export const setLoginRedirectPath = (path: string | null) =>
  ({
    type: 'SET_LOGIN_REDIRECT_PATH',
    payload: path,
  } as const)

export const clearLoginRedirectPath = () =>
  ({
    type: 'CLEAR_LOGIN_REDIRECT_PATH',
  } as const)
