import type { ContractsGetResponse } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const fetchAll = (accessToken: string) =>
  ({ type: 'CONTRACTS_FETCH_ALL', payload: { accessToken } } as const)

export const fetchAllRequest = () =>
  ({ type: 'CONTRACTS_FETCH_ALL_REQUEST', payload: undefined } as const)

export const fetchAllSuccess = (collection: { contracts: ContractsGetResponse[] }) =>
  ({
    type: 'CONTRACTS_FETCH_ALL_SUCCESS',
    payload: collection.contracts,
  } as const)

export const fetchAllFailure = createActionFailure('CONTRACTS_FETCH_ALL_FAILURE')
