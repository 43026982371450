import type { FC } from 'react'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import type { FormikValues } from 'formik'
import { getIn, useFormik } from 'formik'
import * as Yup from 'yup'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import {
  InlineAlert,
  InlineAlertColor,
  Button,
  ButtonGroup,
  TextArea,
  Input,
} from '@helloextend/zen'
import { useAcceptServiceOrderMutation } from '@helloextend/extend-api-rtk-query'
import { Info } from '@helloextend/zen/src/tokens/icons'
import type {
  Claim,
  Contract,
  ServiceOrder,
  ServiceOrderAcceptRequest,
} from '@helloextend/extend-api-client'
import { generateSchema } from '../tab-section/tabs/attachments/schema'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import { ClaimPageTab } from '../tab-section/types'

interface AcceptServiceOrderContentProps {
  claim: Claim
  serviceOrder: ServiceOrder
  contract: Contract
}

const AcceptServiceOrderContent: FC<AcceptServiceOrderContentProps> = ({
  claim,
  serviceOrder,
  contract,
}) => {
  const schema = useMemo(
    () => generateSchema(serviceOrder, claim, contract),
    [serviceOrder, claim, contract],
  )

  const getFormikSchema = (rmaNumberRequired: boolean): FormikValues => {
    return Yup.object()
      .shape({
        instructions: Yup.string().default(''),
        ...(rmaNumberRequired && { rmaNumber: Yup.string().default('').required('Required') }),
      })
      .defined()
  }

  const filteredSchema = schema.filter(({ key }) => {
    if (key === 'instructions') return true
    const packingSlipConfiguration = serviceOrder?.configurations?.packingSlipConfiguration
    return packingSlipConfiguration && packingSlipConfiguration[key]
  })

  const alertText = serviceOrder.configurations?.packingSlipRequired
    ? 'Please review and complete the packing slip information before accepting the service order. Once the service order has been accepted, the packing slip cannot be modified.'
    : 'RMA Number required to accept this service order.'

  const dispatch = useDispatch()
  const [acceptServiceOrder, { isSuccess, isLoading }] = useAcceptServiceOrderMutation()

  const formikSchema = getFormikSchema(Boolean(serviceOrder.configurations?.rmaNumberRequired))
  type Values = Yup.InferType<typeof formikSchema>

  const initialValues = { ...formikSchema.default() } as Values

  const { errors, values, handleSubmit, handleChange } = useFormik<Values>({
    enableReinitialize: true,
    initialValues,
    validationSchema: formikSchema,
    onSubmit: (formValues: Values) => {
      acceptServiceOrder({
        serviceOrderId: serviceOrder.id,
        body: formValues as ServiceOrderAcceptRequest,
      })
    },
  })

  useEffect(() => {
    if (isSuccess) {
      dispatch(setClaimDetailsToast('Service order accepted!'))
      dispatch(setClaimDetailsActiveView(ClaimPageTab.ServiceOrder))
    }
  }, [isSuccess, dispatch])

  return (
    <>
      <ContentWrapper>
        <InlineAlert
          color={InlineAlertColor.blue}
          icon={Info}
          data-cy="service-order-accept-alert"
          isDismissable={false}
        >
          <Text> {alertText} </Text>
        </InlineAlert>
        {serviceOrder.configurations?.packingSlipRequired && (
          <SlipDataContainer>
            {filteredSchema.map(({ key, label, value }) => (
              <SlipRow key={key}>
                <RowKey>{label}:</RowKey>
                <RowValue>{value}</RowValue>
              </SlipRow>
            ))}
          </SlipDataContainer>
        )}
        {serviceOrder.configurations?.packingSlipRequired && (
          <TextArea
            label="Additional Information"
            maxLength={3000}
            onChange={handleChange}
            value={getIn(values, 'instructions')}
            isError={Boolean(getIn(errors, 'instructions'))}
            errorFeedback={getIn(errors, 'instructions') ?? ''}
            id="instructions"
            data-cy="instructions"
          />
        )}
        {serviceOrder.configurations?.rmaNumberRequired && (
          <Input
            label="RMA Number"
            maxLength={3000}
            onChange={handleChange}
            value={getIn(values, 'rmaNumber')}
            isError={Boolean(getIn(errors, 'rmaNumber'))}
            errorFeedback={getIn(errors, 'rmaNumber') ?? ''}
            id="rmaNumber"
            data-cy="rma-number"
          />
        )}
      </ContentWrapper>
      <ButtonWrapper>
        <ButtonGroup>
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => dispatch(setClaimDetailsActiveView(''))}
            isDisabled={isLoading}
          />
          <Button
            type="submit"
            text="Accept"
            data-cy="accept-button"
            isDisabled={isLoading}
            onClick={() => handleSubmit()}
            isProcessing={isLoading}
          />
        </ButtonGroup>
      </ButtonWrapper>
    </>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  maxHeight: 650,
  overflow: 'scroll',
  scrollbarWidth: 'none',
})

const SlipDataContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 20,
})

const SlipRow = styled.div({
  display: 'flex',
  marginTop: -15,
  flexDirection: 'row',
  lineHeight: '24px',
})

const RowKey = styled.p({
  minWidth: 200,
})

const RowValue = styled.p({
  overflowWrap: 'anywhere',
})

const Text = styled.p({
  fontSize: 14,
  margin: 0,
  fontWeight: 400,
})

const ButtonWrapper = styled.div({
  borderTop: `1px solid ${COLOR.SLATE}`,
  display: 'flex',
  paddingTop: 20,
  marginLeft: -32,
  padding: '20px 32px 0 32px',
  width: '100%',
  justifyContent: 'flex-end',
})

export { AcceptServiceOrderContent }
