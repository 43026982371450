import type { Action } from '../actions'
import { createErrorReducer } from '../../factories'

const error = createErrorReducer<Action>(
  ['CLAIMS_CREATE_FAILURE', 'CLAIMS_FETCH_BY_CONTRACT_FAILURE'],
  [
    'CLAIMS_CREATE_REQUEST',
    'CLAIMS_FETCH_BY_CONTRACT_REQUEST',
    'CLAIMS_FETCH_BY_CONTRACT_ID',
    'CLAIMS_ERROR_RESET',
  ],
)

export default error
