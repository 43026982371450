import { createApi } from '@reduxjs/toolkit/query/react'
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import type { StoreUser } from '@helloextend/extend-api-client'
import type {
  GetUserListResponse,
  GetUserListRequest,
  GetUserListWithGrantsRequest,
  User,
  InviteUserRequest,
  AddAccountToUserRequest,
  UserWithGrants,
} from './types'
import type { FilteredUserGrant, FilteredUserGrantsResponse } from '../grants-api/types'

import { baseQuery } from '../base-query'

export const usersV3Api = createApi({
  baseQuery,
  reducerPath: 'UsersV3',
  tagTypes: ['UsersList', 'UserDetails'],
  endpoints: (build) => ({
    inviteUser: build.mutation<User, InviteUserRequest>({
      query: ({ firstName, lastName, email }: InviteUserRequest) => ({
        url: `/auth/v3/users`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        method: 'POST',
        body: { firstName, lastName, email },
      }),
      invalidatesTags: (_, _err) => [{ type: 'UsersList' }],
    }),
    addAccountToUser: build.mutation<User, AddAccountToUserRequest>({
      query: ({ email, accountId }: AddAccountToUserRequest) => ({
        url: `/auth/v3/users/${email}/accounts?account=${accountId}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        method: 'PUT',
      }),
      invalidatesTags: (_, _err) => [{ type: 'UsersList' }],
    }),
    getUsersList: build.query<GetUserListResponse, GetUserListRequest>({
      query: (qs) => ({
        url: `/auth/v3/users`,
        params: {
          ...(!qs.account ? { internal: qs.internal } : { account: qs.account }),
          cursor: qs.cursor,
          limit: qs.limit,
        },
        headers: {
          'content-type': 'application/json',
          accept: `application/json`,
        },
      }),
      providesTags: (_, _err) => [{ type: 'UsersList' }],
    }),
    getUsersListWithGrants: build.query<UserWithGrants[], GetUserListWithGrantsRequest>({
      async queryFn({ internal, account }, _queryApi, _extraOptions, fetchWithBQ) {
        const v3UserList: User[] = []
        let v2UserList: StoreUser[] = []
        const grantsList: FilteredUserGrant[] = []
        let nextPageCursor
        let grantsNextPageCursor

        if (!internal && account) {
          const v2UserListRes: QueryReturnValue = await fetchWithBQ({
            url: `/auth/accounts/${account}/users`,
            method: 'GET',
          })

          if (v2UserListRes.error) throw v2UserListRes.error

          v2UserList = v2UserListRes.data as StoreUser[]
        }

        do {
          // eslint-disable-next-line no-await-in-loop
          const userRes: QueryReturnValue = await fetchWithBQ({
            url: `/auth/v3/users`,
            method: 'GET',
            params: {
              ...(!account ? { internal } : { account }),
              cursor: nextPageCursor,
            },
          })

          if (userRes.error) throw userRes.error

          const payload = userRes.data as GetUserListResponse

          if (payload.users.length) {
            do {
              // eslint-disable-next-line no-await-in-loop
              const grantsRes: QueryReturnValue = await fetchWithBQ({
                url: '/auth/grants/filter-grants-for-ern',
                body: {
                  ern: `ERN:ACC:${account ?? '*'}`,
                  partialErn: false,
                  userIds: payload.users.map((user) => user.email),
                  cursor: grantsNextPageCursor,
                },
                method: 'POST',
              })

              if (grantsRes.error) throw grantsRes.error

              const grantPayload = grantsRes.data as FilteredUserGrantsResponse

              grantsList.push(...grantPayload.grants)
              grantsNextPageCursor = grantPayload.nextPageCursor
            } while (grantsNextPageCursor)
          }

          v3UserList.push(...(userRes.data as GetUserListResponse).users)

          nextPageCursor = payload.cursor
        } while (nextPageCursor)

        const mapFilteredGrantsToUsers = (
          users: User[],
          v2Users: StoreUser[],
          grants: FilteredUserGrant[],
        ): UserWithGrants[] => {
          const mergedUsers = (v2UsersList: StoreUser[], v3UsersList: User[]): User[] => {
            const merged = v2UsersList.reduce((v3Users, v2user) => {
              const v2UserInV3List = v3Users.find((item: User) => item.email === v2user.email)

              if (!v2UserInV3List) {
                v3Users.push({
                  firstName: v2user.firstName ?? '',
                  lastName: v2user.lastName ?? '',
                  login: v2user.email,
                  email: v2user.email,
                  status: 'PROVISIONED',
                  uuid: v2user.id,
                })
              }

              return v3Users
            }, v3UsersList)

            return merged
          }

          return mergedUsers(v2Users, users).map((user) => {
            return {
              ...user,
              grants: grants
                .filter((grant) => grant.userId === user.email)
                .map((grant) => ({
                  ern: grant.ern,
                  role: grant.role,
                })),
            }
          })
        }

        return { data: mapFilteredGrantsToUsers(v3UserList, v2UserList, grantsList) }
      },
      providesTags: (_, _err) => [{ type: 'UsersList' }],
    }),
    getUserDetails: build.query<User, string>({
      query: (uuid) => ({
        url: `/auth/v3/users/${uuid}`,
        headers: {
          'content-type': 'application/json',
          accept: `application/json`,
        },
      }),
      providesTags: (_, _err, userId) => [{ type: 'UserDetails', id: userId }],
    }),
    resendInviteUser: build.query<void, string>({
      query: (email: string) => ({
        url: `/auth/v3/users/${email}/regenerate-invite`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useInviteUserMutation,
  useAddAccountToUserMutation,
  useGetUsersListQuery,
  useLazyGetUsersListQuery,
  useGetUserDetailsQuery,
  useLazyGetUserDetailsQuery,
  useLazyResendInviteUserQuery,
  useGetUsersListWithGrantsQuery,
} = usersV3Api
