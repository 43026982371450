import { createBooleanReducer } from '@helloextend/core-api-redux/src/factories'

const isLoading = createBooleanReducer(
  ['USERS_GETME_REQUEST', 'USER_UPDATE_REQUEST', 'ACCOUNT_USERS_FETCH_ALL_REQUEST'],
  [
    'USERS_GETME_SUCCESS',
    'USERS_GETME_FAILURE',
    'ACCOUNT_USERS_FETCH_ALL_SUCCESS',
    'ACCOUNT_USERS_FETCH_ALL_FAILURE',
    'USER_UPDATE_SUCCESS',
    'USER_UPDATE_FAILURE',
  ],
)

export default isLoading
