import type { Product } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const updateBatch = (
  storeId: string,
  referenceIds: string[],
  data: Array<DeepPartial<Product>>,
  accessToken: string,
) =>
  ({
    type: 'PRODUCTS_UPDATE_BATCH',
    payload: { storeId, referenceIds, data, accessToken },
  } as const)

export const updateBatchRequest = () => ({ type: 'PRODUCTS_UPDATE_BATCH_REQUEST' } as const)

export const updateBatchSuccess = (
  storeId: string,
  referenceIds: string[],
  data: Array<DeepPartial<Product>>,
) =>
  ({
    type: 'PRODUCTS_UPDATE_BATCH_SUCCESS',
    payload: { storeId, referenceIds, data },
  } as const)

export const updateBatchFailure = createActionFailure('PRODUCTS_UPDATE_BATCH_FAILURE')
