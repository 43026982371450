import React from 'react'
import type { FormikErrors } from 'formik'
import * as Yup from 'yup'
import type { ClaimCustomerInformation } from '@helloextend/extend-api-client'
import { formatPhoneNumber } from '@helloextend/client-utils/src/number'
import type {
  CollapsibleInfoGroupProps,
  CustomComponentProps,
} from '../../../../../../components/collapsible-info-group'
import { fieldMapper } from '../../../../../../components/collapsible-info-group'
import { AddressSearch } from '../../../../../../components/address-search'
import { transformAddress } from '../../../../../../utils/transform-claim-address'

const schema = Yup.object()
  .shape({
    shippingAddress: Yup.object()
      .shape({
        address1: Yup.string().default('').required('Required'),
        address2: Yup.string().default(''),

        city: Yup.string().default('').required('Required'),
        postalCode: Yup.string().default('').required('Required'),

        provinceCode: Yup.string().default('').required('Required'),
        countryCode: Yup.string().default('USA').required(),
      })
      .defined(),
    phone: Yup.string().default(''),
    email: Yup.string().default('').required('Required'),
    name: Yup.string().default('').required('Required'),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

const getCustomerInfo = <S extends Record<string, any>>(
  isEditable: boolean,
  customer: ClaimCustomerInformation,
  formValues?: { [key: string]: any },
  formErrors?: FormikErrors<S>,
): CollapsibleInfoGroupProps['data'] => {
  if (!customer) return []

  const fields = [
    { key: 'email', label: 'email' },
    { key: 'phone', label: 'Phone Number', transformFn: formatPhoneNumber, editable: isEditable },
    {
      key: 'shippingAddress',
      label: 'Shipping Address',
      editable: isEditable,
      ...(!isEditable && { transformFn: transformAddress }),
      ...(isEditable && {
        CustomComponent: ({ onChange, error, value }: CustomComponentProps) => (
          <AddressSearch
            label="Shipping Address"
            errorMessage={error}
            value={transformAddress(value)}
            onChange={(changedFields) => {
              onChange('shippingAddress', changedFields)
            }}
          />
        ),
      }),
    },
  ]

  return [
    {
      values: fieldMapper<ClaimCustomerInformation, S>(customer, fields, formValues, formErrors),
    },
  ]
}

export { getCustomerInfo, schema }
export type { Values }
