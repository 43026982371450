import { combineReducers } from 'redux'
import type { ByIdReducerState } from './by-id'
import byId from './by-id'
import type { DownloadUrlState } from './download-url'
import downloadUrl from './download-url'
import type { IsImportingdReducerState } from './is-importing'
import isImporting from './is-importing'
import type { PlanTermsErrorReducerState } from './error'
import error from './error'
import type { Action } from '../actions'

export interface ReducerState {
  byId: ByIdReducerState
  downloadUrl: DownloadUrlState
  isImporting: IsImportingdReducerState
  error: PlanTermsErrorReducerState
}

export default combineReducers<ReducerState, Action>({
  byId,
  downloadUrl,
  isImporting,
  error,
})
