import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../base-query'
import type {
  Integration,
  GetPermissionsRequest,
  GetPermissionsResponse,
  GetIntegrationListRequest,
  GetIntegrationResponse,
  PostIntegrationRequest,
  DeleteIntegrationRequest,
  DeleteIntegrationResponse,
} from './types'

export const integrationsApi = createApi({
  baseQuery,
  reducerPath: 'Integrations',
  tagTypes: ['Integrations'],
  endpoints: (build) => ({
    getPermissions: build.query<GetPermissionsResponse, GetPermissionsRequest>({
      query: ({ version = 'default' }) => ({
        url: '/integrations/permissions',
        headers: {
          accept: `application/json; version=${version};`,
        },
      }),
    }),
    getIntegrationList: build.query<GetIntegrationResponse, GetIntegrationListRequest>({
      query: ({ organizationId, accountId }) => ({
        url: `/integrations/accounts/${accountId}/organizations/${organizationId}`,
        headers: {
          accept: `application/json; version=latest;`,
        },
      }),
      providesTags: ['Integrations'],
    }),
    createIntegration: build.mutation<{ integration: Integration }, PostIntegrationRequest>({
      query: ({ data, version }) => {
        return {
          url: '/integrations',
          method: 'POST',
          body: data,
          headers: {
            accept: `application/json; version=${version};`,
          },
        }
      },
      invalidatesTags: ['Integrations'],
    }),
    deleteIntegration: build.mutation<DeleteIntegrationResponse, DeleteIntegrationRequest>({
      query: ({ id, version = 'latest', accountId, organizationId }) => {
        return {
          url: `/integrations/accounts/${accountId}/organizations/${organizationId}/integrations/${id}`,
          method: 'DELETE',
          headers: {
            accept: `application/json; version=${version};`,
          },
        }
      },
      invalidatesTags: ['Integrations'],
    }),
  }),
})

export const {
  useGetPermissionsQuery,
  useGetIntegrationListQuery,
  useCreateIntegrationMutation,
  useDeleteIntegrationMutation,
} = integrationsApi
