import type { Theme } from '@emotion/react'
import { useTheme } from '@emotion/react'

export const useThemeSafe = (): Theme => {
  const theme = useTheme()

  if (theme && Object.keys(theme).length > 0) return theme

  return {
    unit: 0,
    button: {
      borderRadius: '',
    },
  }
}
