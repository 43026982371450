import type { Plan, PlanSearch } from '@helloextend/extend-api-client'
import type { ReducerState } from './reducers'
import type { ErrorReducerState } from '../types/error'

type Selector<T> = (state: ReducerState, ...rest: any[]) => T

export const getAll: Selector<Plan[]> = (state) => Object.values(state.byId)
export const getById: Selector<Plan | null> = (state, id: string) => state.byId[id]
export const getError: Selector<ErrorReducerState> = (state) => state.error
export const getIsLoading: Selector<boolean> = (state) => state.isLoading
export const getPlansSearchItems: Selector<PlanSearch[]> = (state) => state.search.currentPage
export const getPlansSearchCursor: Selector<string | null | undefined> = (state) =>
  state.search.nextPageCursor
export const getPlansPage: Selector<number> = (state) => state.search.page
export const getPlansSearchFilter: Selector<string | undefined> = (state) => state.search.filter
export const getPlansLastFetchedPage: Selector<number> = (state) => state.search.lastFetched
export const getPlansSuccess: Selector<boolean> = (state) => state.isSuccess
export const hasPrevPage: Selector<boolean> = (state) => state.search.page > 1
export const getIsCreating: Selector<boolean> = (state) => state.isCreating
