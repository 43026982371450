import type { ParseResult } from 'papaparse'
import Papa from 'papaparse'
import { pipe } from 'lodash/fp'
import { validateProductId } from './validate-product-id'
import { validateBooleanColumns } from './validate-boolean-columns'
import { validateWarrantyStatus } from './validate-warranty-status'
import {
  validateHeader,
  validateHeaderNewFields,
  getNonSPAndAltLngHeaders,
} from './validate-header'
import { formatPipeErrorMessage } from '../shared-csv-validation'
import { validateNumericColumns } from './validate-numeric-columns'
import { validatePlanId } from './validate-plan-id'
import { getCsvHeaderIndexes } from '../get-header-indexes'
import { validateAlternateLanguages } from './validate-alternate-languages'
import type { ProductHeader } from './product-csv-headers'
import type { CsvImportParseResults, HeaderIndexMap } from '../csv-validation-models'

const INVALID_HEADERS = 'The CSV headers in the uploaded file should match the sample file provided'
const DUPLICATE_ID = 'The product reference IDs in the file must be unique'

async function parseProductCsv(
  file: File,
  newFields = false,
  additionalData: Set<string> | undefined = undefined,
  isSPEnabled = false,
): Promise<CsvImportParseResults> {
  const parseResults: CsvImportParseResults = {
    data: [],
    errors: [],
  }

  const productIdSet = new Set()
  const planIds = additionalData
    ? new Set([...additionalData].map((planId) => planId.toUpperCase()))
    : additionalData
  let isHeaderScanned = false
  let headerIndexes: HeaderIndexMap<ProductHeader>
  let currRowStep = 0 // for row #

  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      step: (result: ParseResult<string>, parser): void => {
        currRowStep += 1 // track current row being scanned in step fn
        if (!isHeaderScanned) {
          // terminate the process in the instance
          // of an invalid header, due to the likelihood of the rest of
          // the CSVs data rows failing data validation; if columns are
          // out of order, the validity of the pipe-checks/error messaging
          // may be misleading
          let headers = result.data
          if (!isSPEnabled) {
            // Passing false here as the second arg will give us the camelCase headers
            // which is needed for the getCsvHeaderIndexes func call below
            headers = getNonSPAndAltLngHeaders(headers, false)
          }
          isHeaderScanned = true
          if (
            (!newFields && !validateHeader(headers)) ||
            (newFields && !validateHeaderNewFields(headers))
          ) {
            parseResults.errors.push(INVALID_HEADERS)
            resolve(parseResults)
            parser.abort()
          }

          headerIndexes = getCsvHeaderIndexes(headers)
        } else {
          // TODO: [PUPS-293] separate validation from parsing logic
          const validationResults = pipe(
            validateProductId,
            validateNumericColumns,
            validateWarrantyStatus,
            validateBooleanColumns,
            validateAlternateLanguages,
            (meta) => {
              if (planIds) {
                return validatePlanId(meta, planIds)
              }
              return meta
            },
          )({ errors: [], rowData: result.data, headerIndexes })

          // Ensures that reference ID rows in the file are unique
          const productIdIndex = headerIndexes.referenceId
          const productId = result.data[productIdIndex]
          if (productIdSet.has(productId)) {
            validationResults.errors.push(`${DUPLICATE_ID} (${productId})`)
          }
          productIdSet.add(productId)

          if (validationResults.errors.length) {
            const errorMessage = formatPipeErrorMessage(currRowStep, validationResults.errors)
            parseResults.errors.push(errorMessage)
          } else {
            parseResults.data.push(validationResults.rowData)
          }
        }
      },
      complete: (): void => {
        resolve(parseResults)
      },
      error(error): void {
        reject(error)
      },
    })
  })
}

export { parseProductCsv }
