import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'
import { connect } from 'react-redux'
import { usePrevious } from '@helloextend/client-hooks'
import * as selectors from '../../reducers/selectors'
import { getParamsFromUrl } from '../../utils/get-params-from-url'
import { auth } from '../../actions'
import type { RootState } from '../../reducers'
import { ForgotPasswordForm } from '../../components/forgot-password-form'

interface SP {
  error: ReturnType<typeof selectors.getAuthError>
  isLoading: ReturnType<typeof selectors.getIsAuthLoading>
}

interface DP {
  onSubmit: typeof auth.forgotPassword
  onUnload: typeof auth.errorReset
}

type ForgotPasswordProps = SP & DP

const Component: FC<ForgotPasswordProps> = ({ error, isLoading, onSubmit, onUnload }) => {
  const paramsError = getParamsFromUrl('error')
  const prevLoading = usePrevious(isLoading)
  const [isSubmitSuccess, setIsSubmitSuccess] = useState<boolean>(false)

  // sets error reset on unmount
  useEffect(() => {
    if (prevLoading && !isLoading && !error) {
      setIsSubmitSuccess(true)
    }
    return () => {
      onUnload()
    }
  }, [prevLoading, isLoading, error, onUnload])

  return (
    <>
      <Header>Reset your Password</Header>
      {isSubmitSuccess && (
        <Subheader>Instructions from Extend have been sent to your email address.</Subheader>
      )}

      {paramsError === 'expired_token' && !isSubmitSuccess && (
        <RedirectErrorWrapper>
          {`Your reset password request has expired.\nPlease re-enter your email address to get a new link`}
        </RedirectErrorWrapper>
      )}
      {!isSubmitSuccess && <ForgotPasswordForm onSubmit={onSubmit} isLoading={isLoading} />}
    </>
  )
}

const RedirectErrorWrapper = styled.aside({
  color: COLOR.STATE_DANGER,
  fontSize: 14,
  lineHeight: '16px',
  marginBottom: 8,
  textAlign: 'left',
  whiteSpace: 'pre',
  marginTop: 24,
})

const Header = styled.h2({
  fontSize: 20,
  fontWeight: 700,
  margin: 0,
  marginTop: 32,
})

export const Subheader = styled.h2({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '18px',
  padding: '32px 0 8px',
  margin: 0,
  textAlign: 'center',
})

const ForgotPassword = connect(
  (state: RootState): SP => ({
    error: selectors.getAuthError(state),
    isLoading: selectors.getIsAuthLoading(state),
  }),
  {
    onSubmit: auth.forgotPassword,
    onUnload: auth.errorReset,
  },
)(Component)

export { Component, RedirectErrorWrapper, ForgotPassword }
