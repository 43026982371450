import { FC, useMemo } from 'react'
import React, { useCallback, useState } from 'react'
import type { AdvancedSelectChangeEvent } from '@helloextend/zen'
import { ModalController } from '@helloextend/zen'
import type { CategoryMapping, StoreIdAndName } from '@helloextend/extend-api-client'
import {
  useListAllStoreIdsWithNameQuery,
  useListPlanCategoriesQuery,
} from '@helloextend/extend-api-rtk-query'
import { StoreSelector } from './store-catalog-select'
import { CategoryMapperTable } from './category-mapper-table'
import { CategoryMapperModal } from './modal'

const CategoryMapper: FC = () => {
  const [selectedStore, setSelectedStore] = useState<StoreIdAndName>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedMappings, setSelectedMappings] = useState<CategoryMapping[]>([])
  const { data: { values: unSortedCategories = [] } = {} } = useListPlanCategoriesQuery()
  const { data: stores = [], isLoading } = useListAllStoreIdsWithNameQuery()

  const categories = useMemo(() => {
    return [...unSortedCategories].sort().map((category) => ({
      display: category,
      value: category,
    }))
  }, [unSortedCategories])

  const toggleModal = useCallback((): void => {
    setIsModalOpen(!isModalOpen)
  }, [isModalOpen])

  const handleSelectedStore = useCallback(
    (e: AdvancedSelectChangeEvent): void => {
      const store = stores.find(({ id }) => id === e.target.value)
      setSelectedStore(store)
    },
    [stores],
  )

  const handleSelectedMappings = useCallback(
    (mappings?: CategoryMapping[]): void => {
      if (mappings) {
        setSelectedMappings(mappings)
        toggleModal()
      }
    },
    [toggleModal],
  )

  return (
    <>
      <StoreSelector
        selectedStore={selectedStore}
        stores={stores}
        handleSelectedStore={handleSelectedStore}
        isLoading={isLoading}
      />
      {selectedStore && (
        <CategoryMapperTable
          selectedStore={selectedStore}
          toggleModal={toggleModal}
          handleSelectedMappings={handleSelectedMappings}
          categories={unSortedCategories}
        />
      )}
      <ModalController isOpen={isModalOpen}>
        <CategoryMapperModal
          toggleModal={toggleModal}
          mappings={selectedMappings}
          categories={categories}
          storeIdAndName={selectedStore ?? { id: '', name: '' }}
        />
      </ModalController>
    </>
  )
}
  
export { CategoryMapper }
