import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { BasicModal } from '../../../../../components/basic-modal'
import { CreateOrganizationModalForm } from './create-organization-modal-form'

type CreateOrganizationModalProps = {
  isVisible: boolean
  onClickClose: () => void
}

const CreateOrganizationModal: FC<CreateOrganizationModalProps> = ({ isVisible, onClickClose }) => {
  return (
    <BasicModal
      data-cy="create-organization-modal"
      isVisible={isVisible}
      onClickClose={onClickClose}
      width="600px"
    >
      <Title>Create Organization</Title>
      <CreateOrganizationModalForm onClickClose={onClickClose} />
    </BasicModal>
  )
}

const Title = styled.div({
  fontSize: 20,
  marginBottom: 16,
  weight: 700,
})

export { CreateOrganizationModal }
