export type AccountEnterprise = {
  id: string
  name: string
  phoneNumber: string
  url?: string
  createdAt: number
  editedAt?: number
  status?: AccountStatus
}

export enum AccountStatus {
  Approved = 'Approved',
  Unapproved = 'Unapproved',
  Suspended = 'Suspended',
  Deactivated = 'Deactivated',
}

export type AccountLegacy = {
  id: string
  name: string
  createdAt?: number
  features?: 'merchant' | 'servicer'
  terms?: {
    acceptedAt: number
    ipAddress: string
    ppVersion: string
    tosVersion: string
  }
  isLegacyAccount: boolean
  status?: AccountStatus
}

export interface Organization {
  id: string
  accountId: string
  name: string
  createdAt: number
  editedAt?: number
}

export type OrganizationsCreateResponse = Pick<Organization, 'id'>

export type OrganizationsCreateRequest = {
  body: Partial<Organization>
  version?: string
  accountId: string
}

export type AccountsListResponse = {
  items: Account[]
  nextPageCursor?: string
  limit?: number
}

export type AccountOrganizationsListResponse = {
  items: Organization[]
  nextPageCursor?: string
  limit?: number
}

export type AccountsListQueryParams = {
  limit?: number
  cursor?: string
  accountIds?: string
  name?: string
}

export type GetAccountRequest = {
  accountId: string | null | undefined
  version?: string
}

export type GetAccountOrganizationsRequest = {
  accountId: string
  version?: string
  cursor?: string
  limit?: number
}

export type DeleteAccountRequest = {
  accountId: string
  version?: string
}

export type CreateLegacyAccountRequest = {
  body: AccountLegacy
  version?: string
}

export type UpdateLegacyAccountRequest = {
  body: Partial<AccountLegacy>
  version?: string
  accountId: string
}

export type CreateEnterpriseAccountRequest = {
  body: AccountEnterprise
  version?: string
}

export type UpdateEnterpriseAccountRequest = {
  body: Partial<AccountEnterprise>
  version?: string
  accountId: string
}

export type CreateAccountResponse = {
  id: string
}

export type AccountsListQueryStringOptions = {
  limit?: number
  cursor?: string
  accountIds?: string
  name?: string
  version?: string
}

export type CreateAccountProvision = {
  user: {
    firstName: string
    lastName: string
    email: string
  }
  account: {
    name: string
    status: string
  }
  organization?: {
    name?: string
  }
}

export type CreateAccountProvisionRequest = {
  body: CreateAccountProvision
  version?: string
}

export type Account = AccountLegacy | AccountEnterprise
