import type { FC } from 'react'
import React from 'react'
import {
  ModalController,
  Modal,
  COLOR,
  useToaster,
  ToastColor,
  ToastDuration,
} from '@helloextend/zen'
import type { ThreadListItem } from '@helloextend/extend-api-rtk-query'
import { useDeleteThreadMutation } from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'

type DeleteThreadModalProps = {
  isVisible: boolean
  handleCancel: () => void
  thread: ThreadListItem
}

const DeleteThreadModal: FC<DeleteThreadModalProps> = ({ isVisible, handleCancel, thread }) => {
  const [deleteThread, { isLoading }] = useDeleteThreadMutation()
  const { toast } = useToaster()
  const handleDeleteThread = async (): Promise<void> => {
    try {
      await deleteThread(thread.id).unwrap()
      toast({
        toastColor: ToastColor.blue,
        toastDuration: ToastDuration.short,
        message: `${thread.title} thread has been successfully deleted.`,
      })
    } catch (error: unknown) {
      console.error(error)
      toast({
        toastColor: ToastColor.red,
        toastDuration: ToastDuration.short,
        message: 'Something went wrong. Please try again.',
      })
    }
  }

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        data-cy="amp-delete-thread-modal"
        heading="Delete draft thread?"
        primaryButtonProps={{
          'data-cy': 'amp-delete-thread-submit',
          onClick: handleDeleteThread,
          text: 'Delete',
          color: 'red',
          isProcessing: isLoading,
        }}
        secondaryButtonProps={{
          'data-cy': 'amp-delete-thread-cancel',
          onClick: handleCancel,
          text: 'Cancel',
        }}
      >
        <Text data-cy="amp-delete-thread-warning-message">
          This can not be undone. Are you sure you want to delete the draft thread?
        </Text>
      </Modal>
    </ModalController>
  )
}

const Text = styled.p({
  color: COLOR.NEUTRAL[800],
  fontSize: 16,
  lineHeight: '24px',
})

export { DeleteThreadModal }
