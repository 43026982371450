import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import type { Claim, ServiceOrder } from '@helloextend/extend-api-client'
import { ClaimSelectStatus } from '@helloextend/extend-api-client'
import type { ButtonProps } from '@helloextend/zen'
import { ButtonGroup, Button, Stack, useToaster, ToastDuration, ToastColor } from '@helloextend/zen'
import { useLazySearchServiceOrdersQuery } from '@helloextend/extend-api-rtk-query'
import { StackAlign, StackJustify } from '@helloextend/zen/src/components/stack'
import { isNil } from 'lodash'
import { setClaimDetailsActiveView } from '../../../../../../store/slices/claim-details'
import { ServiceOrderActionsMenu } from '../service-order-actions-menu'
import { ClaimActionsMenu } from '../claim-actions-menu'
import { LDFlag } from '../../../../../../constants/ld-flags'
import { getServiceOrderModalMap } from '../../../service-order-modal-map'
import { useUpdateClaim } from '../../../../../../hooks/use-update-claim'

interface ActionButtonsProps {
  claim: Claim
  serviceOrder?: ServiceOrder
}

const ActionButtons: FC<ActionButtonsProps> = ({ claim, serviceOrder }) => {
  const { [LDFlag.ClaimsClose]: FF_CLAIMS_CLOSE } = useFlags()
  const dispatch = useDispatch()
  const { toast } = useToaster()
  const { updateClaim, isSuccess, isLoading: isUpdating } = useUpdateClaim()
  const [searchServiceOrders] = useLazySearchServiceOrdersQuery()
  const [toastMessage, setToastMessage] = useState('')

  const serviceOrderStatusButton = useMemo((): ButtonProps[] => {
    if (!serviceOrder?.status) return []
    const { text, nextStep, action, buttonProps } =
      getServiceOrderModalMap(serviceOrder.status, serviceOrder?.serviceType) ?? {}
    if (!action) return []
    return [
      {
        text: `${text}`,
        'data-cy': `${nextStep}-service-order-button`,
        onClick: () => {
          dispatch(setClaimDetailsActiveView(action))
        },
        ...buttonProps,
      },
    ]
  }, [dispatch, serviceOrder?.status, serviceOrder?.serviceType])

  const fetchServiceOrder = useCallback(() => {
    let retryAttempts = 0
    const intervalId = setInterval(() => {
      if (retryAttempts >= 4 || !isNil(serviceOrder)) {
        clearInterval(intervalId)
      }
      searchServiceOrders({ claimId: claim.id, includeShippingLabels: true })
      retryAttempts += 1
    }, 3000)
  }, [claim.id, serviceOrder, searchServiceOrders])

  const actions: ButtonProps[] = useMemo(() => {
    const approveButtonConfig: ButtonProps = {
      text: 'Approve Claim',
      emphasis: 'medium',
      'data-cy': 'approve-claim-button',
      isDisabled: isUpdating,
      onClick: () => {
        updateClaim({
          claimId: claim.id,
          contractId: claim.contractId,
          body: {
            status: ClaimSelectStatus.approved,
            customer: claim.customer,
            incident: claim.incident,
          },
        })
        setToastMessage('Claim approved!')
      },
    }

    const denyButtonConfig: ButtonProps = {
      text: 'Deny Claim',
      emphasis: 'medium',
      'data-cy': 'deny-claim-button',
      isDisabled: isUpdating,
      onClick: () => {
        updateClaim({
          claimId: claim.id,
          contractId: claim.contractId,
          body: {
            status: ClaimSelectStatus.denied,
            customer: claim.customer,
            incident: claim.incident,
          },
        })
        setToastMessage('Claim denied!')
      },
    }

    const closeClaimButton: ButtonProps[] = FF_CLAIMS_CLOSE
      ? [
          {
            text: 'Close Claim',
            emphasis: 'low',
            'data-cy': 'close-claim-button',
            isDisabled: isUpdating,
            onClick: () => {
              dispatch(setClaimDetailsActiveView('closeClaimModal'))
            },
          },
        ]
      : []

    switch (claim?.status) {
      case 'review':
        return [approveButtonConfig, denyButtonConfig, ...closeClaimButton]
      case 'approved':
      case 'fulfilled':
        return serviceOrderStatusButton.length ? serviceOrderStatusButton : []
      case 'denied':
        return [approveButtonConfig]
      default:
        return []
    }
  }, [updateClaim, claim, isUpdating, serviceOrderStatusButton, dispatch, FF_CLAIMS_CLOSE])

  useEffect(() => {
    if (isSuccess && toastMessage) {
      toast({
        message: toastMessage,
        toastDuration: ToastDuration.short,
        toastColor: ToastColor.green,
      })

      fetchServiceOrder()
    }
  }, [isSuccess, dispatch, toastMessage, fetchServiceOrder, toast])

  return (
    <ButtonContainer data-cy="action-buttons">
      <Stack align={StackAlign.end} justify={StackJustify.end} spacing={1} doesWrap>
        <ButtonGroup>
          {actions.map((props) => {
            return <Button {...props} key={props.text} />
          })}
        </ButtonGroup>
        <ClaimActionsMenu claim={claim} hasServiceOrder={Boolean(serviceOrder)} />
        <ServiceOrderActionsMenu
          serviceOrder={serviceOrder}
          cyPrefix="claim-details-page-header"
          claim={claim}
        />
      </Stack>
    </ButtonContainer>
  )
}

const ButtonContainer = styled.div({})

export { ActionButtons }
