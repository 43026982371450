import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../../tokens/colors'

export interface HeaderProps {
  start?: React.ReactElement
  center?: React.ReactElement
  end?: React.ReactElement
  title?: string
  subtitle?: string
  color?: string
  'data-cy'?: string
}

export const Header: FC<HeaderProps> = ({
  start,
  center,
  end,
  title = '',
  subtitle = '',
  color = COLOR.BLUE[1000],
  'data-cy': dataCy,
}) => {
  return (
    <StyledHeader data-cy={dataCy} headerColor={color}>
      <Section>
        <Start>{start}</Start>
      </Section>
      {!!center && (
        <Section>
          <Center>{center}</Center>
        </Section>
      )}
      {(title || subtitle) && (
        <Section column>
          {title && (
            <Title>
              <span>{title}</span>
            </Title>
          )}
          {subtitle && (
            <Subtitle>
              <span>{subtitle}</span>
            </Subtitle>
          )}
        </Section>
      )}
      <Section>
        <End>{end}</End>
      </Section>
    </StyledHeader>
  )
}

const StyledHeader = styled.header<{
  headerColor: string
}>(({ headerColor }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 16,
  height: 56,
  padding: '0 16px',
  background: headerColor,
}))

const Section = styled.div<{
  column?: boolean
}>(({ column = false }) => ({
  display: 'flex',
  ...(column && {
    flexDirection: 'column',
  }),
  flex: '1',
  justifyContent: 'center',
  textAlign: 'center',
  whiteSpace: 'nowrap',
}))

const Start = styled.div({
  marginRight: 'auto',
})

const Center = styled.div({
  marginLeft: 'auto',
  marginRight: 'auto',
})

const End = styled.div({
  marginLeft: 'auto',
})

const Title = styled.div({
  fontSize: 14,
  lineHeight: '16px',
  fontWeight: 'bold',
  color: COLOR.WHITE,
  pointerEvents: 'all',
})

const Subtitle = styled.div({
  fontSize: 12,
  lineHeight: '16px',
  color: COLOR.NEUTRAL[200],
  pointerEvents: 'all',
})
