import { crypto as securityUtil } from '@helloextend/client-security-utils'
import {
  OKTA_AUTHORIZATION_URL,
  OKTA_BASE_URL,
  OKTA_CLIENT_ID,
  PORTAL_APP_REDIRECT_URI,
} from '@helloextend/client-constants'
import { setPkceCodeVerifier, setPkceState } from '../utils/local-storage'

const scope = 'openid profile email'
const codeChallengeMethod = 'S256'

type AuthorizeUrlParams = {
  codeChallenge: string
  state: string
}

const getOktaRedirectUrl = async (): Promise<string> => {
  const state = generateRandomString(16)
  setPkceState(state)

  const pair = await securityUtil.generateKeyPair()
  setPkceCodeVerifier(pair.codeVerifier)

  const url = generateAuthorizeUrl({ codeChallenge: pair.codeChallenge, state })

  return url
}

const generateAuthorizeUrl = ({ codeChallenge, state }: AuthorizeUrlParams): string => {
  const url = new URL(OKTA_AUTHORIZATION_URL, OKTA_BASE_URL)

  url.searchParams.append('response_type', 'code')
  url.searchParams.append('client_id', `${OKTA_CLIENT_ID}`)
  url.searchParams.append('redirect_uri', `${PORTAL_APP_REDIRECT_URI}`)
  url.searchParams.append('scope', `${scope}`)
  url.searchParams.append('state', `${state}`)

  url.searchParams.append('code_challenge_method', `${codeChallengeMethod}`)
  url.searchParams.append('code_challenge', `${codeChallenge}`)

  return url.toString()
}

const generateRandomString = (size: number): string => {
  const array = new Uint32Array(size)
  window.crypto.getRandomValues(array)
  return Array.from(array, dec2hex).join('')
}

const dec2hex = (dec: number): string => {
  return dec.toString(16).padStart(2, '0')
}

export { getOktaRedirectUrl }
