import type { FC } from 'react'
import React from 'react'
import type { Store } from '@helloextend/extend-api-client'
import { DataProperty, Grid } from '@helloextend/zen'
import { getPercentageStringFromDecimal } from '@helloextend/client-utils/src/string'
import { revenueModelOptions } from '../../../settings/components/product-protection-form/schema'

type ProductProtectionInfoProps = {
  store: Store
}

const ProductProtectionInfo: FC<ProductProtectionInfoProps> = ({ store }) => {
  const {
    merchantEwRevenueAgreement,
    merchantEwRevenueSharePercentage,
    partialReimbursementPercentage,
    partialReimbursementEnabled,
    extendCut,
    locationFilteringEnabled,
  } = store
  const revenueModelDisplayValue = revenueModelOptions?.find(
    (option) => option.value === merchantEwRevenueAgreement,
  )?.display

  if (!store) {
    return null
  }
  return (
    <div data-cy="product-protection-info">
      <Grid spacing={2} columns={5}>
        <DataProperty
          data-cy="revenue-model"
          label="Revenue Model"
          value={revenueModelDisplayValue ?? ''}
        />
        <DataProperty
          label="Merchant Revenue Share"
          data-cy="merchant-revenue-share"
          value={
            merchantEwRevenueAgreement !== 'wholesale' && merchantEwRevenueSharePercentage
              ? getPercentageStringFromDecimal(merchantEwRevenueSharePercentage)
              : ''
          }
        />
        <DataProperty
          label="Partial Reimbursement Merchant"
          data-cy="partial-reimbursement-merchant"
          value={
            partialReimbursementEnabled && partialReimbursementPercentage
              ? getPercentageStringFromDecimal(partialReimbursementPercentage)
              : 'Off'
          }
        />
        <DataProperty
          label="Custom ExtendCut"
          data-cy="custom-extendCut"
          value={extendCut ? getPercentageStringFromDecimal(extendCut) : 'Off'}
        />
        <DataProperty
          label="Location Filtering"
          data-cy="location-filtering"
          value={locationFilteringEnabled ? 'On' : 'Off'}
        />
      </Grid>
    </div>
  )
}

export { ProductProtectionInfo }
