import type { DefaultMessage } from '@helloextend/extend-api-rtk-query'
import { MessageType } from '@helloextend/extend-api-rtk-query'

export const getMessagesContent = (messages: DefaultMessage[]): string | undefined =>
  messages
    .reduce(
      (output: string, message: DefaultMessage) =>
        message.type === MessageType.text ? `${output} ${message.content.trim()}` : output,
      ``,
    )
    .trim()
