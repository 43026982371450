import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../tokens/colors'
import type { IconProps as IconPathProps } from '../../tokens/icons/icon-props'

export interface IconProps {
  icon: IconPathProps
  color?: string
  size?: IconSize
  transitionDurationMs?: number
  'data-cy'?: string
}

export enum IconSize {
  xsmall = 16,
  small = 20,
  medium = 24,
  large = 28,
  xlarge = 32,
}

export const Icon: FC<IconProps> = ({
  icon,
  color = COLOR.BLACK,
  size = IconSize.medium,
  transitionDurationMs,
  'data-cy': dataCy,
}) => {
  const path = icon.d
  const useStroke = icon.stroke
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={{ display: 'inline-block', flexGrow: 0, flexShrink: 0 }}
      data-cy={dataCy}
    >
      {Array.isArray(path) ? (
        path.map((p: string) => (
          <Path
            color={color}
            d={p}
            key={p}
            transitionDurationMs={transitionDurationMs}
            useStroke={useStroke}
          />
        ))
      ) : (
        <Path
          color={color}
          d={path}
          transitionDurationMs={transitionDurationMs}
          useStroke={useStroke}
        />
      )}
    </svg>
  )
}

const Path = styled.path<{
  color: string
  d: string
  transitionDurationMs: number | undefined
  useStroke: boolean | undefined
}>(({ color, d, transitionDurationMs, useStroke }) => ({
  ...(useStroke
    ? { stroke: color, strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 2 }
    : { fill: color }),
  d: `path("${d}")`,
  ...(transitionDurationMs && {
    transition: `${transitionDurationMs}ms`,
  }),
}))
