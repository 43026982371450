import type { FC, ReactNode } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { ColorArgs } from './types'
import { InlineAlertColor } from './types'
import { getColor } from './utils'
import { Icon, IconSize } from '../icon'
import { Close } from '../../tokens/icons'
import type { IconProps } from '../../tokens/icons/icon-props'
import { Button } from '../button'
import { ButtonGroup } from '../button-group'
import type { ButtonProps } from '../button/types'

interface InlineAlertProps {
  children: ReactNode
  color?: InlineAlertColor
  'data-cy'?: string
  icon?: IconProps
  isDismissable?: boolean
  onDismiss?: () => void
  /* Omit is used here to prevent the option to change emphasis, size and color of buttons within an inline alert. These are set below. */
  primaryButtonProps?: Omit<ButtonProps, 'emphasis' | 'size' | 'color'>
  secondaryButtonProps?: Omit<ButtonProps, 'emphasis' | 'size' | 'color'>
}

const InlineAlert: FC<InlineAlertProps> = ({
  children,
  color = InlineAlertColor.neutral,
  'data-cy': dataCy,
  icon,
  isDismissable = false,
  onDismiss,
  primaryButtonProps,
  secondaryButtonProps,
}: InlineAlertProps) => {
  const inlineAlertColor = getColor(color)
  return (
    <StyledInlineAlert colors={inlineAlertColor} data-cy={dataCy}>
      <LayoutLeft>
        {icon && <Icon icon={icon} size={IconSize.small} color={inlineAlertColor.iconColor} />}
        <div>
          {children}
          {(primaryButtonProps || secondaryButtonProps) && (
            <ButtonGroupContainer>
              <ButtonGroup>
                {primaryButtonProps && (
                  <Button
                    {...primaryButtonProps}
                    emphasis="medium"
                    size="small"
                    color={inlineAlertColor.buttonColor}
                  />
                )}
                {secondaryButtonProps && (
                  <Button
                    {...secondaryButtonProps}
                    emphasis="low"
                    size="small"
                    color={inlineAlertColor.buttonColor}
                  />
                )}
              </ButtonGroup>
            </ButtonGroupContainer>
          )}
        </div>
      </LayoutLeft>
      {isDismissable && (
        <InlineWithText data-cy={dataCy && `${dataCy}:dismiss`}>
          <Button
            emphasis="low"
            color={inlineAlertColor.buttonColor}
            size="small"
            icon={Close}
            onClick={onDismiss}
          />
        </InlineWithText>
      )}
    </StyledInlineAlert>
  )
}

const StyledInlineAlert = styled.div<{
  colors: ColorArgs
}>(({ colors }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: 8,
  padding: 16,
  borderRadius: 6,
  lineHeight: '20px',
  fontSize: 15,
  border: `1px solid ${colors.borderColor}`,
  backgroundColor: colors.backgroundColor,
  color: colors.textColor,
}))

const LayoutLeft = styled.div({
  display: 'flex',
  gap: 8,
})

const ButtonGroupContainer = styled.div({
  marginTop: 8,
})

const InlineWithText = styled.div({
  margin: -6,
})

export { InlineAlert, InlineAlertProps }
