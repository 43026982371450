import type { Servicer } from '@helloextend/extend-api-client'
import { createActionFailure } from '../../factories'

export const list = (accessToken: string) =>
  ({ type: 'SERVICERS_LIST', payload: { accessToken } } as const)

export const listSuccess = (servicers: Servicer[]) =>
  ({
    type: 'SERVICERS_LIST_SUCCESS',
    payload: { servicers },
  } as const)

export const listFailure = createActionFailure('SERVICERS_LIST_FAILURE')

export const listRequest = () => ({ type: 'SERVICERS_LIST_REQUEST' } as const)
