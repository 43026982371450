import React from 'react'
import { CellText } from '@helloextend/merchants-ui'
import type { Column } from '@helloextend/merchants-ui'
import type { TableContractsAuditItem } from '../../../types/contracts'

const auditColumns: Array<Column<TableContractsAuditItem>> = [
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
    columnWidth: 13.5,
    Cell: (data) => <CellText title={data.timestamp}>{data.timestamp}</CellText>,
  },
  {
    Header: 'Event',
    accessor: 'event',
    columnWidth: 13.5,
    Cell: (data) => (
      <CellText title={data.event}>
        <strong>{data.event}</strong>
      </CellText>
    ),
  },
  {
    Header: 'Changes',
    accessor: 'changes',
    columnWidth: 36.5,
    isCollapsible: true,
    Cell: (data) => (
      <CellText title={`${data.changes.length}`}>
        <strong>{data.changes.length}</strong>
      </CellText>
    ),
  },
  {
    Header: 'User',
    accessor: 'user',
    columnWidth: 14.5,
    Cell: (data) => <CellText title={data.user}>{data.user}</CellText>,
  },
  {
    Header: 'Comments',
    accessor: 'comments',
    columnWidth: 22,
    Cell: (data) => <CellText title={data.comments}>{data.comments}</CellText>,
  },
]

export { auditColumns }
