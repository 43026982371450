import type {
  ProductReplacementServiceOrdersFulfillRequest,
  SerivceOrdersFulfillResponse,
} from '@helloextend/extend-api-client/src/models/service-order'
import { createActionFailure } from '../../factories'

export const fulfill = (
  serviceOrderId: string,
  claimId: string,
  body: ProductReplacementServiceOrdersFulfillRequest,
  accessToken: string,
) =>
  ({
    type: 'SERVICE_ORDERS_FULFILL',
    payload: { serviceOrderId, claimId, body, accessToken },
  } as const)

export const fulfillRequest = () => ({ type: 'SERVICE_ORDERS_FULFILL_REQUEST' } as const)

export const fulfillSuccess = (payload: SerivceOrdersFulfillResponse) =>
  ({
    type: 'SERVICE_ORDERS_FULFILL_SUCCESS',
    payload,
  } as const)

export const fulfillFailure = createActionFailure('SERVICE_ORDERS_FULFILL_FAILURE')
