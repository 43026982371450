import React from 'react'
import { CellText } from '@helloextend/merchants-ui'
import type { CellProps, Column } from 'react-table'
import type { InsuranceProgram } from '@helloextend/extend-api-client'

const insuranceProgramColumns: Column[] = [
  {
    Header: 'Insurance Program ID',
    accessor: 'id',
    Cell: ({ value }: CellProps<InsuranceProgram, string>): JSX.Element => (
      <CellText>{value}</CellText>
    ),
    width: 25,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ value }: CellProps<InsuranceProgram, string>): JSX.Element => (
      <CellText>{value}</CellText>
    ),
    width: 25,
  },
  {
    Header: 'Primary Obligor',
    accessor: 'obligor.',
    Cell: ({ row }: CellProps<InsuranceProgram, string>): JSX.Element => {
      const { obligor } = row.original as InsuranceProgram
      return <CellText>{obligor[0].name}</CellText>
    },
    width: 12.5,
  },
  {
    Header: 'Risk Ownership',
    accessor: 'riskOwnership',
    Cell: ({ row }: CellProps<InsuranceProgram, string>): JSX.Element => {
      const { obligor } = row.original as InsuranceProgram
      return <CellText align="right">{obligor[0].riskOwnership}%</CellText>
    },
    textAlign: 'right',
    width: 12.5,
  },
  {
    Header: 'Ceded Quota Share',
    accessor: 'cededQuotaShare',
    Cell: ({ row }: CellProps<InsuranceProgram, string>): JSX.Element => {
      const { obligor } = row.original as InsuranceProgram
      return <CellText align="right">{100 - obligor[0].riskOwnership}%</CellText>
    },
    textAlign: 'right',
    width: 12.5,
  },
  {
    Header: 'Obligor Fee',
    accessor: 'obligorFee',
    Cell: ({ row }: CellProps<InsuranceProgram, string>): JSX.Element => {
      const { obligor } = row.original as InsuranceProgram
      // obligor[0] is the primary obligor
      const obligorFee = obligor[0].fees.find((fee) => fee.type === 'obligor')
      const isPercentage = obligorFee?.feeAmountOfType === 'percentage'
      const text = obligorFee?.amount
        ? `${obligorFee?.amount}${isPercentage ? '%' : ` ${obligorFee?.feeAmountOfType}`}`
        : '\u2014'
      return <CellText align="right">{text}</CellText>
    },
    textAlign: 'right',
    width: 12.5,
  },
]

export { insuranceProgramColumns }
