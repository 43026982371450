import { ContractStatus } from '@helloextend/extend-api-client'
import type { BadgeDescriptionItem } from '@helloextend/merchants-ui'

const getContractStatusCopy = (contractStatus: ContractStatus): BadgeDescriptionItem => {
  switch (contractStatus) {
    case ContractStatus.CREATED:
      return {
        badgeText: 'Created',
        description: '',
        badgeColor: 'neutral',
      }
    case ContractStatus.LIVE:
      return {
        badgeText: 'Live',
        description: '',
        badgeColor: 'green',
      }
    case ContractStatus.CANCELLED:
      return {
        badgeText: 'Canceled',
        description: 'Canceled by the request of the customer. Future claims will be rejected.',
        badgeColor: 'red',
      }
    case ContractStatus.REFUND_REQUESTED:
      return {
        badgeText: 'Refund Requested',
        description: 'Refunded by the request of the customer. Future claims will be rejected.',
        badgeColor: 'red',
      }
    case ContractStatus.REFUND_ACCEPTED:
      return {
        badgeText: 'Refund Acccepted',
        description: 'Refunded by the request of the customer. Future claims will be rejected.',
        badgeColor: 'red',
      }

    case ContractStatus.DELIVERED:
      return {
        badgeText: 'Delivered',
        description:
          'All shipments covered by the shipping protection plan have been delivered. Future claims will be reviewed manually.',
        badgeColor: 'red',
      }
    case ContractStatus.EXPIRED:
      return {
        badgeText: 'Expired',
        description:
          'Product protection coverage has ended. Future claims against will be rejected.',
        badgeColor: 'red',
      }
    case ContractStatus.FULFILLED:
      return {
        badgeText: 'Fulfilled',
        description:
          'All available entitlements have been consumed. Future claims will be rejected.',
        badgeColor: 'red',
      }
    case ContractStatus.REFUND_DENIED:
      return {
        badgeText: 'Refund Denied',
        description: '',
        badgeColor: 'neutral',
      }
    case ContractStatus.REFUND_PAID:
      return {
        badgeText: 'Refund Paid',
        description: '',
        badgeColor: 'red',
      }
    case ContractStatus.VOIDED:
      return {
        badgeText: 'Voided',
        description:
          'Voided by your organization due to fraud. Future claims against will be rejected.',
        badgeColor: 'red',
      }
    case ContractStatus.TERMINATED:
      return {
        badgeText: 'Terminated',
        description: 'Terminated by Extend due to fraud. Future claims against will be rejected.',
        badgeColor: 'red',
      }
    default:
      return {
        badgeText: 'Unknown',
        description: '',
        badgeColor: 'neutral',
      }
  }
}

export { getContractStatusCopy }
