export const PRODUCT_COLUMNS = [
  'enabled',
  'brand',
  'category',
  'subcategory',
  'mfrWarrantyLabor',
  'mfrWarrantyParts',
  'mfrWarrantyUrl',
  'plans',
  'priceCents',
  'referenceId',
  'title',
  'createdAt',
  'warrantyStatus',
]

export const DEFAULT_PRODUCT_COLUMNS = [
  'title',
  'referenceId',
  'priceCents',
  'enabled',
  'warrantyStatus',
  'shipEnabled',
  'shippable',
]

// Should mimic the identical variable within the Plans' service PlanValidator
export const MAX_TOTAL_PRODUCT_PLANS = 18
