import type { Middleware } from 'redux'
import logger from '@helloextend/client-logger'

export const logErrors: Middleware = () => (next) => (action) => {
  const { error, payload, meta } = action

  if (error) {
    logger.error(payload, meta)
  }

  return next(action)
}
