import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import { COLOR, Icon } from '@helloextend/zen'
import { ChevronRight } from '@helloextend/zen/src/tokens/icons'
import { Chevron, ItemWrapper, MenuButton, MenuWrapper } from './filter-styles'
import { Menu } from '../menu'
import type { FilterGroupOptions, FilterOptions, FilterValues } from './types'
import { Filter } from './filter'
import { MENU_MAX_HEIGHT } from './constants'

export type FiltersMenuProps = {
  filters: Record<string, FilterOptions>
  values: Record<string, FilterValues | null>
  onFilterChange: (property: string, values: FilterValues | null) => void
  onMenuLock: (isLocked: boolean) => void
}

export const getAppliedFilterCount = (
  property: string,
  filters: Record<string, FilterOptions>,
  values: Record<string, FilterValues | null>,
): number => {
  const filterValues = values[property]
  const filter = filters[property]
  switch (filter?.type) {
    case 'checkbox':
      if (filterValues?.type === 'checkbox') {
        return filterValues.values.length
      }
      break
    case 'nestedCheckbox':
      if (filterValues?.type === 'nestedCheckbox') {
        return Object.keys(filterValues.values).length
      }
      break
    case 'dateRange':
      if (filterValues?.type === 'dateRange') {
        return 1
      }
      break
    case 'group':
      return Object.entries(filter.filters).reduce((acc, [nestedProperty]) => {
        return acc + getAppliedFilterCount(nestedProperty, filter.filters, values)
      }, 0)
      break
    case 'text':
      if (filterValues?.type === 'text') {
        return 1
      }
      break
    case 'numberRange':
      if (filterValues?.type === 'numberRange') {
        return 1
      }
      break
    case 'advancedText':
      if (filterValues?.type === 'advancedText') {
        return Object.values(filterValues).filter((value) => value).length - 1
      }
  }
  return 0
}

const FiltersMenu: FC<FiltersMenuProps> = ({ filters, values, onFilterChange, onMenuLock }) => {
  const [selectedChild, setSelectedChild] = useState('')

  const handleMenuSelect = useCallback((label: string): void => {
    setSelectedChild(label)
  }, [])

  return (
    <>
      {Object.entries(filters).map(([key, filter]) => {
        const filterValues = values[key]
        const appliedFilterCount = getAppliedFilterCount(key, filters, values)
        const isActive = selectedChild === key

        const isFilterGroup = filter.type === 'group'
        const shouldHaveOverflow = filter.type === 'checkbox' || filter.type === 'nestedCheckbox'
        const menuMaxHeight = shouldHaveOverflow ? MENU_MAX_HEIGHT : undefined

        return (
          <ItemWrapper key={key}>
            <MenuButton
              id={key}
              isActive={isActive}
              hoverBackground={COLOR.NEUTRAL[100]}
              onClick={() => handleMenuSelect(key)}
              className="menu-button"
            >
              {filter.label} {appliedFilterCount > 0 && `(${appliedFilterCount})`}
              <Chevron>
                <Icon icon={ChevronRight} color={isActive ? COLOR.WHITE : COLOR.NEUTRAL[800]} />
              </Chevron>
            </MenuButton>
            <MenuWrapper>
              <Menu isOpen={isActive} position="right" width={216} maxHeight={menuMaxHeight}>
                {isFilterGroup && (
                  <FiltersMenu
                    filters={(filter as FilterGroupOptions).filters}
                    values={values}
                    onFilterChange={onFilterChange}
                    onMenuLock={onMenuLock}
                  />
                )}
                {!isFilterGroup && (
                  <Filter
                    property={key}
                    filter={filter}
                    values={filterValues}
                    onFilterChange={onFilterChange}
                    onMenuLock={onMenuLock}
                  />
                )}
              </Menu>
            </MenuWrapper>
          </ItemWrapper>
        )
      })}
    </>
  )
}

export { FiltersMenu }
