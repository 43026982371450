/** Round a value to the specified number of digits */
const round = (number: number, digits = 0): number => {
  if (digits < 0) {
    throw new Error('digits must be 0 or greater')
  }

  const factor = 10 ** digits
  return Math.round((number + Number.EPSILON) * factor) / factor
}

/** Clamp a value between an upper and lower bound */
const clamp = (number: number, min = 0, max = 1): number => {
  if (min > max) {
    throw new Error('min cannot be greater than max')
  }

  if (number > max) {
    return max
  }

  if (number < min) {
    return min
  }

  return number
}

export { round, clamp }
