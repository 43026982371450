import type { Claim, Fulfillment } from '@helloextend/extend-api-client'
import type { ReducerState } from './reducers'
import type { ErrorReducerState } from '../types/error'

type Selector<T> = (state: ReducerState, ...rest: any[]) => T

export const getById: Selector<Claim | (Claim & { fulfillments: Fulfillment[] }) | undefined> = (
  state,
  id: string,
) => state.byId[id]
export const getAll: Selector<Claim[]> = (state) => Object.values(state.byId)
export const getByContract: Selector<Claim[] | undefined> = (state, id: string) =>
  state.byContract[id]
export const getIsCreating: Selector<boolean> = (state) => state.isCreating
export const getIsLoading: Selector<boolean> = (state) => state.isLoading
export const getIsUpdating: Selector<boolean> = (state) => state.isUpdating
export const getError: Selector<ErrorReducerState> = (state) => state.error
export const getNewClaimId: Selector<string | null> = (state) => state.newClaimId
