import * as fetchActions from './fetch'
import * as fulfillActions from './fulfill'
import * as assignActions from './assign'
import * as approveReplacementActions from './approve-replacement'
import * as resetActions from './reset'
import * as startRepairActions from './start-repair'
import * as approveForPaymentActions from './approve-for-payment'
import * as requestForPaymentActions from './request-payment'
import * as errorActions from './error'
import type { ExtractActions } from '../../types/utility'

export type Action =
  | ExtractActions<typeof fetchActions>
  | ExtractActions<typeof fulfillActions>
  | ExtractActions<typeof assignActions>
  | ExtractActions<typeof approveReplacementActions>
  | ExtractActions<typeof resetActions>
  | ExtractActions<typeof startRepairActions>
  | ExtractActions<typeof approveForPaymentActions>
  | ExtractActions<typeof requestForPaymentActions>
  | ExtractActions<typeof errorActions>

export type ActionType = Action['type']

const actions = {
  ...fetchActions,
  ...fulfillActions,
  ...assignActions,
  ...approveReplacementActions,
  ...resetActions,
  ...startRepairActions,
  ...approveForPaymentActions,
  ...requestForPaymentActions,
  ...errorActions,
}

export { actions }
