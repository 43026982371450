import type { ExtractActions } from '@helloextend/core-api-redux/src/types/utility'
import * as auth from './auth'
import * as contracts from './contracts'
import * as stores from './stores'
import * as users from './users'
import * as plans from './plans'
import * as support from './support'
import * as servicers from './servicers'
import * as router from './router'

export { auth, contracts, stores, users, plans, support, servicers, router }

export type Action =
  | ExtractActions<typeof auth>
  | ExtractActions<typeof contracts>
  | ExtractActions<typeof stores>
  | ExtractActions<typeof users>
  | ExtractActions<typeof plans>
  | ExtractActions<typeof support>
  | ExtractActions<typeof servicers>
  | ExtractActions<typeof router>

export type ActionType = Action['type']
