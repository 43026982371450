import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { get, put, post, destroy } from '@helloextend/extend-api-fetch'
import type { User, UpdateProfileRequest, StoreUser } from '../models'

export async function getUsers(accessToken: string): Promise<ApiResponse<User[]>> {
  return get('/users', { accessToken })
}

export async function updateProfile(
  userId: string,
  accessToken: string,
  body: UpdateProfileRequest,
): Promise<ApiResponse<null>> {
  return put(`/auth/users/${userId}`, { accessToken, body })
}

export async function updateUser(
  userId: string,
  accessToken: string,
  body: StoreUser,
): Promise<ApiResponse<StoreUser>> {
  return put(`/auth/users/${userId}`, { accessToken, body })
}

export async function createUser(
  accessToken: string,
  body: Partial<StoreUser>,
): Promise<ApiResponse<StoreUser>> {
  return post(`/auth/invite`, { accessToken, body })
}

export async function deleteUser(
  userId: string,
  accessToken: string,
): Promise<ApiResponse<StoreUser>> {
  return destroy(`/auth/users/${userId}`, { accessToken })
}
