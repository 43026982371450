import type { FC, SyntheticEvent } from 'react'
import React, { useCallback } from 'react'
import type { MenuButtonItemProps } from '../../menu-item'
import { MenuButtonItem } from '../../menu-item'
import { useMenuContext } from '../../menu/menu-context'

export const SelectMenuItem: FC<MenuButtonItemProps> = (props) => {
  const { hideAll } = useMenuContext()
  const handleMenuItemClick = useCallback(
    (e: SyntheticEvent<HTMLButtonElement>) => {
      hideAll()
      if (props.onClick) {
        props.onClick(e)
      }
    },
    [hideAll, props],
  )
  return <MenuButtonItem {...props} onClick={handleMenuItemClick} />
}
