import type { FC } from 'react'
import React from 'react'
import { CurrencyInput, Grid, Input, InputType } from '@helloextend/zen'
import type { FormikErrors, FormikHandlers, FormikValues } from 'formik'
import type { SPPlanPriceBandMapping } from '@helloextend/extend-api-client/src/models/store'
import { getFormikError } from '../../../../../../../../utils/form-utils'
import { ShippingProtectionPlanDropdown } from '../shipping-protection-plan-dropdown/shipping-protection-plan-dropdown'

type PriceBandModelRowProps = {
  index: number
  data: SPPlanPriceBandMapping
  errors: FormikErrors<FormikValues>
  isLabelDisplayed?: boolean
  isPartialReimbursementEnabled?: boolean
  handleOnChange: FormikHandlers['handleChange']
  spPlansList: string[]
  isSpListLoading?: boolean
}

const PriceBandModelRow: FC<PriceBandModelRowProps> = ({
  index,
  data,
  errors,
  isLabelDisplayed,
  isPartialReimbursementEnabled,
  handleOnChange,
  spPlansList,
  isSpListLoading,
}) => {
  const priceBandsFormikId = `priceBands[${index}]`
  return (
    <Grid columns={isPartialReimbursementEnabled ? 6 : 5} spacing={2}>
      <CurrencyInput
        data-cy={`price-band-low-${index}`}
        value={data?.low?.toString() || ''}
        id={`${priceBandsFormikId}low`}
        onChange={handleOnChange}
        currency="USD"
        label={isLabelDisplayed ? 'Price Band Low' : undefined}
        isError={Boolean(getFormikError(errors, `${priceBandsFormikId}low`))}
        errorFeedback={getFormikError(errors, `${priceBandsFormikId}low`)}
      />
      <CurrencyInput
        data-cy={`price-band-high-${index}`}
        value={data.high?.toString() || ''}
        id={`${priceBandsFormikId}high`}
        onChange={handleOnChange}
        currency="USD"
        label={isLabelDisplayed ? 'Price Band High' : undefined}
        isError={Boolean(getFormikError(errors, `${priceBandsFormikId}high`))}
        errorFeedback={getFormikError(errors, `${priceBandsFormikId}high`)}
      />
      <ShippingProtectionPlanDropdown
        dataCy={`sp-plan-${index}`}
        value={data?.planId || ''}
        id={`${priceBandsFormikId}planId`}
        onChange={handleOnChange}
        options={spPlansList}
        isLoading={isSpListLoading}
        label={isLabelDisplayed ? 'Shipping Protection Plan' : ''}
        isError={Boolean(getFormikError(errors, `${priceBandsFormikId}planId`))}
        errorFeedback={getFormikError(errors, `${priceBandsFormikId}planId`)}
        isPlanDetailsDisplayed
      />
      <Input
        type={InputType.number}
        id={`${priceBandsFormikId}revenueShare`}
        suffix="%"
        data-cy={`revenue-share-per-shipment-${index}`}
        onChange={handleOnChange}
        value={data?.revenueShare?.toString() || ''}
        label={isLabelDisplayed ? 'Revenue Share Per Shipment' : undefined}
        isError={Boolean(getFormikError(errors, `${priceBandsFormikId}revenueShare`))}
        errorFeedback={getFormikError(errors, `${priceBandsFormikId}revenueShare`)}
        placeholder="Enter percentage"
      />
      <CurrencyInput
        data-cy={`price-band-fixed-premium-${index}`}
        value={data?.fixedPremium?.toString() || ''}
        id={`${priceBandsFormikId}fixedPremium`}
        onChange={handleOnChange}
        currency="USD"
        label={isLabelDisplayed ? 'Fixed Premium' : undefined}
        isError={Boolean(getFormikError(errors, `${priceBandsFormikId}fixedPremium`))}
        errorFeedback={getFormikError(errors, `${priceBandsFormikId}fixedPremium`)}
      />
      {isPartialReimbursementEnabled && (
        <Input
          type={InputType.number}
          id={`${priceBandsFormikId}partialReimbursement`}
          suffix="%"
          data-cy={`partial-reimbursement-${index}`}
          value={data?.partialReimbursement?.toString() || ''}
          label={isLabelDisplayed ? 'Partial Reimbursement' : undefined}
          isError={Boolean(getFormikError(errors, `${priceBandsFormikId}partialReimbursement`))}
          errorFeedback={getFormikError(errors, `${priceBandsFormikId}partialReimbursement`)}
          onChange={handleOnChange}
          placeholder="Enter percentage"
        />
      )}
    </Grid>
  )
}

export { PriceBandModelRow }
