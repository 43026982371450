import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/client-utils/src/request'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.create>

export default function* create(action: Action): SagaIterator {
  const { data, accessToken } = action.payload

  yield put(actions.createRequest())
  try {
    const response: ResolvedType<typeof client.stores.create> = yield call(
      client.stores.create,
      data,
      accessToken,
    )

    if (isErrorResponse(response)) {
      yield put(actions.createFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(actions.createFailure(`An unknown error occurred`, response.status))
      return
    }

    yield put(actions.createSuccess(response.data))
  } catch (e) {
    yield put(actions.createFailure(e.message))
  }
}
