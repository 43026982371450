import type { FC } from 'react'
import React from 'react'
import { CellText, TableCell, CollapsibleTableRow } from '@helloextend/merchants-ui'
import { date } from '@helloextend/client-utils'
import styled from '@emotion/styled'
import type { Diff } from 'deep-diff'

export type ChangesRowProps = {
  offsetColumn?: number
  data: Array<Diff<string | number>>
}

const DATE_KEYS = ['syncedAt', 'transactionDate', 'purchaseDate', 'fulfillmentDate']

const ChangesRow: FC<ChangesRowProps> = ({ offsetColumn = 0, data }) => {
  return (
    <>
      {data.map((item) => {
        const isDate = DATE_KEYS.includes(item.path?.[item?.path.length - 1])

        let oldVal: string | number | undefined
        let newVal: string | number | undefined

        if (item.kind === 'E') {
          oldVal = isDate ? date.format(item.lhs as number, 'MMMM DD, YYYY HH:MM:ss') : item.lhs
          newVal = isDate ? date.format(item.rhs as number, 'MMMM DD, YYYY HH:MM:ss') : item.rhs
        }

        if (item.kind === 'N') {
          newVal = isDate ? date.format(item.rhs as number, 'MMMM DD, YYYY HH:MM:ss') : item.rhs
        }

        if (item.kind === 'D') {
          oldVal = isDate ? date.format(item.lhs as number, 'MMMM DD, YYYY HH:MM:ss') : item.lhs
        }

        const changes = item.path ? buildChangeTitle(item.path) : 'Field Unavailable'

        return (
          <CollapsibleTableRow key={changes}>
            {[...Array(offsetColumn)].map(() => (
              <TableCell key={Math.random()} columnWidth={13.5} />
            ))}
            <TableCell colspan={1}>
              <StyledCell data-qa="changes-title" title={changes}>
                {changes.trim()}
              </StyledCell>
            </TableCell>
            <TableCell colspan={1}>
              <ChangesWrapper>
                <StyledCell data-qa="old-value" title={`${oldVal ?? 'old-value'}`}>
                  {oldVal ?? '--'}
                </StyledCell>
                <MiddleCell>→</MiddleCell>
                <StyledCell data-qa="new-value" title={`${newVal ?? 'new-value'}`}>
                  {newVal ?? '--'}
                </StyledCell>
              </ChangesWrapper>
            </TableCell>
          </CollapsibleTableRow>
        )
      })}
    </>
  )
}

const StyledCell = styled(CellText)({
  display: 'inline-block',
  width: 158,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

const MiddleCell = styled(CellText)({
  display: 'inline-block',
  width: 20,
})

const ChangesWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
})

function buildChangeTitle(path: string[]): string {
  return path.length > 1
    ? path
        .reduce((acc: string, change: string, index: number): string => {
          if (index !== 0) return `${acc} ${sanitizePath(change)}`
          return acc
        }, '')
        .trim()
    : sanitizePath(path[0]).trim()
}

function sanitizePath(path: string): string {
  if (path === undefined) return '--'
  const splitString = path.split(/(?=[A-Z])/).join(' ')
  return splitString.charAt(0).toUpperCase() + splitString.slice(1)
}

export { ChangesRow }
