import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse, isExceptionResponse } from '@helloextend/client-utils/src/request'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.login>

export function* login(action: Action): SagaIterator {
  const { username, password } = action.payload

  yield put(actions.loginRequest())

  try {
    const response: ResolvedType<typeof client.auth.loginUser> = yield call(
      client.auth.loginUser,
      username,
      password,
    )

    if (isErrorResponse(response)) {
      yield put(actions.loginFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(actions.loginFailure(`An unknown error occurred`, response.status))
      return
    }

    yield put(actions.loginSuccess(response.data.accessToken))
  } catch (e) {
    yield put(actions.loginFailure(e.message))
  }
}
