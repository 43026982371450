import type { ReactElement, PropsWithChildren } from 'react'
import React, { createContext, useCallback, useContext, useMemo, useState, useEffect } from 'react'
import type { DataTableContextProviderProps, DataTableContextValue } from './data-table-types'

const DataTableContext = createContext<DataTableContextValue<unknown> | null>(null)

export const DataTableContextProvider = <TData,>({
  children,
  'data-cy': dataCy,
  data,
  filterDefs,
  getRowActions,
  getRowHasDetail,
  getRowMenuItems,
  getTableActions,
  getTableMenuItems,
  hasConfigurableColumns = true,
  hasMoreRows,
  hasSelectableRows = false,
  heading,
  highlightedRowIds,
  isLoading,
  itemName = 'item',
  onSelectAllRowsChange,
  pageSizeOptions = [10, 25, 50, 100],
  rowDetailRenderer,
  rowCount,
  table,
}: PropsWithChildren<DataTableContextProviderProps<TData>>): ReactElement => {
  const [hasAllTableRowsSelected, setHasAllTableRowsSelected] = useState(false)
  const [isConfiguringColumns, setIsConfiguringColumns] = useState(false)
  const [isTableHorizontallyScrollable, setIsTableHorizontallyScrollable] = useState(false)
  const [tableContainerWidth, setTableContainerWidth] = useState(0)
  const [expandedRows, setExpandedRows] = useState<number[]>([])

  const toggleRow = useCallback(
    (index: number) => {
      if (expandedRows.indexOf(index) >= 0) {
        setExpandedRows(expandedRows.filter((i) => i !== index))
      } else {
        setExpandedRows([...expandedRows, index])
      }
    },
    [expandedRows],
  )

  const expandAllRows = useCallback(() => {
    setExpandedRows(table.getRowModel().rows.map((row) => row.index))
  }, [table])

  const collapseAllRows = useCallback(() => {
    setExpandedRows([])
  }, [])

  const getIsRowExpanded = useCallback(
    (i: number) => {
      return expandedRows.indexOf(i) >= 0
    },
    [expandedRows],
  )

  const getAreAllRowsExpanded = useCallback(() => {
    const { rows } = table.getRowModel()
    return rows.filter((row) => expandedRows.indexOf(row.index) >= 0).length === rows.length
  }, [expandedRows, table])

  const columnCount = table.getVisibleFlatColumns().length

  const columnsPinnedQuantity = table
    .getAllColumns()
    .filter((c) => c.getIsPinned() && c.getIsVisible()).length

  const hasAllDataRowsSelected = table.getIsAllRowsSelected()

  useEffect(() => {
    if (rowCount === undefined && hasMoreRows === undefined) {
      setHasAllTableRowsSelected(hasAllDataRowsSelected)
    }
  }, [hasAllDataRowsSelected, rowCount, hasMoreRows])

  const value = useMemo<DataTableContextValue<TData>>(
    () => ({
      collapseAllRows,
      columnCount,
      columnsPinnedQuantity,
      data,
      'data-cy': dataCy,
      expandAllRows,
      filterDefs,
      getAreAllRowsExpanded,
      getIsRowExpanded,
      getRowActions,
      getRowHasDetail,
      getRowMenuItems,
      getTableActions,
      getTableMenuItems,
      hasAllTableRowsSelected,
      hasConfigurableColumns,
      hasMoreRows,
      hasSelectableRows,
      heading,
      highlightedRowIds,
      isConfiguringColumns,
      isLoading,
      isTableHorizontallyScrollable,
      itemName,
      onSelectAllRowsChange,
      pageSizeOptions,
      rowDetailRenderer,
      rowCount,
      setHasAllTableRowsSelected,
      setIsConfiguringColumns,
      setIsTableHorizontallyScrollable,
      setTableContainerWidth,
      table,
      tableContainerWidth,
      toggleRow,
    }),
    [
      collapseAllRows,
      columnCount,
      columnsPinnedQuantity,
      data,
      dataCy,
      expandAllRows,
      filterDefs,
      getAreAllRowsExpanded,
      getIsRowExpanded,
      getRowActions,
      getRowHasDetail,
      getRowMenuItems,
      getTableActions,
      getTableMenuItems,
      hasAllTableRowsSelected,
      hasConfigurableColumns,
      hasMoreRows,
      hasSelectableRows,
      heading,
      highlightedRowIds,
      isConfiguringColumns,
      isLoading,
      isTableHorizontallyScrollable,
      itemName,
      onSelectAllRowsChange,
      pageSizeOptions,
      rowDetailRenderer,
      rowCount,
      table,
      tableContainerWidth,
      toggleRow,
    ],
  )

  return (
    <DataTableContext.Provider value={value as DataTableContextValue<unknown>}>
      {children}
    </DataTableContext.Provider>
  )
}

export const useDataTableContext = <TData,>(): DataTableContextValue<TData> => {
  const context = useContext(DataTableContext) as DataTableContextValue<TData>

  if (!context) {
    throw new Error(
      'useDataTableContext() can only be used within the subtree of a <DataTableProvider>',
    )
  }

  return context
}
