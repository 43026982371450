import { createApi } from '@reduxjs/toolkit/query/react'
import type { StoreUser, AuthForgotPasswordResponse } from '@helloextend/extend-api-client'
import type { AuthActivateResponse } from '@helloextend/extend-api-client/src/models/auth'
import type {
  CreateUserRequest,
  UpdateUserRequest,
  ResendInviteRequest,
  ChangePasswordRequest,
  ExchangeTokenRequest,
  ExchangeTokenResponse,
} from './types'
import { baseQuery } from '../base-query'

export const usersApi = createApi({
  baseQuery,
  // TODO: MEXP-614 update these paths once we remove redux from the merchants portal
  reducerPath: 'Users',
  tagTypes: ['Users'],
  endpoints: (build) => ({
    createUser: build.mutation<Partial<StoreUser>, CreateUserRequest>({
      query: (body: CreateUserRequest) => ({
        url: `/auth/invite`,
        method: 'Post',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    deleteUser: build.mutation<StoreUser, string>({
      query: (userId: string) => ({
        url: `/auth/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    resendInvite: build.mutation<AuthForgotPasswordResponse, ResendInviteRequest>({
      query: (request: ResendInviteRequest) => ({
        url: '/auth/password-reset',
        method: 'POST',
        body: {
          email: request.email,
          portal: request.portal,
          usage: 'set',
        },
      }),
    }),
    changePassword: build.mutation<AuthForgotPasswordResponse, ChangePasswordRequest>({
      query: (request: ChangePasswordRequest) => ({
        url: '/auth/password-reset',
        method: 'POST',
        body: {
          token: request.token,
          password: request.password,
        },
      }),
    }),
    getUsers: build.query<StoreUser[], string>({
      query: (accountId: string) => ({
        url: `/auth/accounts/${accountId}/users`,
      }),
      providesTags: (_, _err, userId) => [{ type: 'Users', id: userId }],
    }),
    getUser: build.query<StoreUser, string>({
      query: (email: string) => ({
        url: `/auth/users/${email}`,
      }),
      providesTags: (_, _err, email) => [{ type: 'Users', id: email }],
    }),
    updateUser: build.mutation<StoreUser, UpdateUserRequest>({
      query: ({ userId, data }: UpdateUserRequest) => ({
        url: `/auth/users/${userId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Users'],
    }),
    activate: build.mutation<AuthActivateResponse, string>({
      query: (code: string) => ({
        url: `/auth/activate`,
        method: 'POST',
        body: {
          code,
        },
      }),
    }),
    getAccessToken: build.mutation<ExchangeTokenResponse, ExchangeTokenRequest>({
      query: (body: ExchangeTokenRequest) => ({
        url: `/auth/oauth/token`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useChangePasswordMutation,
  useGetUsersQuery,
  useGetUserQuery,
  useResendInviteMutation,
  useActivateMutation,
  useGetAccessTokenMutation,
} = usersApi
