// This file is derived from react-colorful
// https://github.com/omgovich/react-colorful/blob/a85e5b36b55cae7e95c73c8ecde0bc881e8e3b1f/src/components/common/Pointer.tsx

import styled from '@emotion/styled'
import type { FC } from 'react'
import React from 'react'
import { COLOR } from '../../../../tokens/colors'

interface PointerProps {
  top?: number
  left: number
  color: string
}

const Pointer: FC<PointerProps> = ({ color, left, top = 0.5 }): JSX.Element => {
  const style = {
    top: `${top * 100}%`,
    left: `${left * 100}%`,
  }

  return (
    <Wrapper style={style}>
      <Selector style={{ backgroundColor: color }} />
    </Wrapper>
  )
}

const Wrapper = styled.div({
  zIndex: 2,
  position: 'absolute',
  width: 20,
  height: 20,
  transform: 'translate(-50%, -50%)',
  backgroundColor: COLOR.WHITE,
  border: `4px solid ${COLOR.WHITE}`,
  borderRadius: 20,
  boxSizing: 'border-box',
})

const Selector = styled.div({
  content: '""',
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  pointerEvents: 'none',
  borderRadius: 'inherit',
})

export { Pointer }
