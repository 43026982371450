import type { FC, ChangeEvent } from 'react'
import React from 'react'
import { ModalController, Modal, Input } from '@helloextend/zen'
import styled from '@emotion/styled'
import { useFormik } from 'formik'
import { useCreateConversationMutation } from '@helloextend/extend-api-rtk-query'
import { useHistory } from 'react-router'
import * as Yup from 'yup'

type CreateConversationModalProps = {
  isVisible: boolean
  onCancel: () => void
}

const CreateConversationModal: FC<CreateConversationModalProps> = ({ isVisible, onCancel }) => {
  const history = useHistory()

  // *** RTK Queries ***//
  const [
    createConversation,
    { isLoading: isCreateConversationProcessing, isError: isCreateConversationError, reset },
  ] = useCreateConversationMutation()

  // **** Formik Setup *** //
  const schema = Yup.object()
    .shape({
      name: Yup.string().required('Conversation name is required'),
      description: Yup.string().required('Conversation description is required'),
    })
    .defined()

  const {
    handleChange,
    setFieldValue,
    values,
    errors,
    handleBlur,
    touched,
    dirty: isDirty,
  } = useFormik<Yup.InferType<typeof schema>>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: schema,
    onSubmit: () => {},
  })

  // **** Event handlers *** //
  const onProceed = async (): Promise<void> => {
    createConversation({
      title: values.name,
      description: values.description,
      threads: [],
    })
      .unwrap()
      .then((conversation) => {
        history.push(`/admin/adjudication-management/conversations/${conversation.id}/edit`)
      })
      // We need to at least catch the error to prevent the component from being unmounted
      .catch((err) => {
        console.log('err', err)
        // do nothing
      })
  }

  let nameError = isCreateConversationError ? 'The conversation name is taken' : ''
  nameError = errors.name || nameError

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (nameError) {
      reset()
    }
    setFieldValue('name', e.target.value)
  }

  return (
    <ModalController isOpen={isVisible}>
      <Modal
        data-cy="amp-create-conversation-modal"
        heading="Create a new conversation"
        primaryButtonProps={{
          'data-cy': 'amp-create-conversation-modal-submit',
          onClick: onProceed,
          text: 'Proceed to Conversation Builder',
          isDisabled: !isDirty || isCreateConversationProcessing,
          isProcessing: isCreateConversationProcessing,
        }}
        secondaryButtonProps={{
          'data-cy': 'amp-create-conversation-modal-cancel',
          onClick: onCancel,
          text: 'Cancel',
        }}
        onDismissRequest={onCancel}
      >
        <>
          <NameWrapper>
            <Input
              data-cy="amp-create-conversation-name"
              id="name"
              label="Name"
              onChange={onChange}
              onBlur={handleBlur}
              placeholder="Name the new conversation"
              value={values.name}
              isError={(Boolean(errors.name) && touched.name) || isCreateConversationError}
              errorFeedback={nameError}
            />
          </NameWrapper>
          <Input
            data-cy="amp-create-conversation-description"
            id="description"
            label="Description"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Add conversation description"
            value={values.description}
            isError={Boolean(errors.description) && touched.description}
            errorFeedback={errors.description}
          />
        </>
      </Modal>
    </ModalController>
  )
}

const NameWrapper = styled.div({
  marginBottom: 16,
})

export { CreateConversationModal, CreateConversationModalProps }
