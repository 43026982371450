import { authActions } from '@helloextend/core-api-redux/auth'
import { createActionFailure } from '@helloextend/core-api-redux/src/factories'

export const {
  login,
  loginFailure,
  loginSuccess,
  logout,
  forgotPassword,
  registerRequest,
  registerFailure,
  registerSuccess,
  resetPassword,
  errorReset,
  changePassword,
} = authActions

/**
 * TODO: once the pages in MERCH-283 are being worked on
 * this action should be deprecated in favor of core-api-redux actions/sagas:
 *  - authActions.register for account creation
 *  - storeActions.create for store creation
 */
export const registerUser = ({
  email,
  password,
  firstName,
  lastName,
  storeName,
  storeUrl,
  platform,
}: Record<string, string>) =>
  ({
    type: 'REGISTER_USER',
    payload: { email, password, firstName, lastName, storeName, storeUrl, platform },
  } as const)

export const fetchApiKey = (password: string) =>
  ({
    type: 'FETCH_API_KEY',
    payload: password,
  } as const)

export const fetchApiKeyRequest = () =>
  ({
    type: 'FETCH_API_KEY_REQUEST',
  } as const)

export const fetchApiKeySuccess = (apiKey: string) =>
  ({
    type: 'FETCH_API_KEY_SUCCESS',
    payload: apiKey,
  } as const)

export const fetchApiKeyFailure = createActionFailure('FETCH_API_KEY_FAILURE')
