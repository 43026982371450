import { faker } from '@faker-js/faker/locale/en'
import type { Fee, InsuranceProgram } from '@helloextend/extend-api-client'
import type {
  InsuranceProgramCreateRequest,
  InsuranceProgramListResponse,
  InsuranceProgramVersionsListResponse,
} from '@helloextend/extend-api-rtk-query'

export const generateInsuranceProgramCreate = (
  overrides: Partial<InsuranceProgram> = {},
): InsuranceProgramCreateRequest => ({
  id: faker.datatype.uuid(),
  name: faker.random.words(2),
  activateFrom: faker.date.recent().getTime(),
  activateTo: faker.date.future().getTime(),
  permittedGeo: 'US',
  notes: faker.random.words(3),
  obligor: [
    {
      name: 'EWSC/EPC',
      riskOwnership: 80,
      notes: faker.random.words(3),
      fees: [generateFee()],
    },
    {
      name: 'Other',
      riskOwnership: 20,
      notes: faker.random.words(3),
      fees: [generateFee({ otherFee: faker.random.words(2) })],
    },
  ],
  profitSharePrimaryObligor: 17,
  profitSharePartner: 'AIG',
  extendProfitShare: faker.datatype.number(100),
  lossAccrual: faker.datatype.number(100),
  targetLossToReserveRatio: faker.datatype.number(100),
  actualLossToReserveRatio: 0,
  plannedLossToPremiumRatio: faker.datatype.number(100),
  actualLossToPremiumRatio: 0,
  isActive: true,
  ...overrides,
})
export const generateInsuranceProgram = (
  overrides: Partial<InsuranceProgram> = {},
): InsuranceProgram => {
  return {
    ...generateInsuranceProgramCreate(),
    createdAt: faker.date.recent().getTime(),
    createdBy: faker.name.firstName(),
    updatedBy: faker.name.firstName(),
    updatedAt: faker.date.recent().getTime(),
    insuranceProgramParentCreatedAt: faker.date.recent().getTime(),
    version: faker.datatype.number(20),
    updatedNote: faker.random.words(3),
    schemaVersion: 1,
    ...overrides,
  }
}

export const generateFee = (overrides: Partial<Fee> = {}): Fee => {
  return {
    type: faker.helpers.arrayElement(['Admin', 'Ceding', 'Clip', 'Obligor']),
    amount: faker.datatype.number(100),
    feeAmountOfType: 'USD',
    basedOn: faker.helpers.arrayElement(['Premium', 'Reserve']),
    routing: faker.helpers.arrayElement(['Disburse', 'Retain']),
    recognition: faker.helpers.arrayElement(['Immediate', 'Over Time']),
    ...overrides,
  }
}

export const generateInsuranceProgramList = (
  overrides?: Partial<InsuranceProgram>,
): InsuranceProgramListResponse => {
  return {
    items: Array.from({ length: faker.datatype.number({ min: 1, max: 10 }) }, () =>
      generateInsuranceProgram({ ...overrides }),
    ),
  }
}

export const generateInsuranceProgramVersionsList = (
  overrides?: Partial<InsuranceProgram>,
): InsuranceProgramVersionsListResponse => {
  return {
    createdAt: faker.date.recent().getTime(),
    insuranceProgramId: overrides?.id ?? faker.datatype.uuid(),
    versions: Array.from(
      { length: faker.datatype.number({ min: 1, max: 10 }) },
      (_program, index) => generateInsuranceProgram({ version: index + 1, ...overrides }),
    ),
  }
}
