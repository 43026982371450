import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { Product } from '@helloextend/extend-api-client'
import { useUpdateProductMutation } from '@helloextend/extend-api-rtk-query'
import { useStandardToast } from '@helloextend/merchants-ui'
import { ModalController, Switch } from '@helloextend/zen'
import { useToggle } from '@helloextend/client-hooks'
import { SectionContainer } from '../../../../../../../../../components/section-container'
import { ProductShippingProtectionModal } from '../product-shipping-protection-modal/product-shipping-protection-modal'

type ProductShippingProtectionProps = {
  product: Product
}

const ProductShippingProtection: FC<ProductShippingProtectionProps> = ({ product }) => {
  const [updateProduct, { isLoading }] = useUpdateProductMutation()
  const [isShipEnabledModalOpen, { on: toggleShipEnabledModalOn, off: toggleShipEnabledModalOff }] =
    useToggle(false)
  const [isShippableModalOpen, { on: toggleShippableModalOn, off: toggleShippableModalOff }] =
    useToggle(false)

  const { toastCompleted, toastError } = useStandardToast()

  const handleToggleChange = async (settingType: 'shippable' | 'shipEnabled'): Promise<void> => {
    try {
      const isShippable = settingType === 'shippable'
      await updateProduct({
        storeId: product.storeId,
        productId: product.referenceId,
        data: {
          [settingType]: !product[settingType],
        },
        version: 'latest',
      }).unwrap()
      toastCompleted('Shipping Protection Settings have been successfully updated.')

      if (isShippable) {
        toggleShippableModalOff()
      } else {
        toggleShipEnabledModalOff()
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      toastError('Something went wrong. Please try again.')
    }
  }

  return (
    <SectionContainer title="Shipping Protection">
      <ContainerWithBottomMargin>
        <Switch
          isOn={Boolean(product.shippable)}
          label="Product Shippable"
          onChange={toggleShippableModalOn}
          data-cy="product-shippable-toggle"
        />
      </ContainerWithBottomMargin>
      <Switch
        isOn={Boolean(product.shipEnabled)}
        label="Shipping Protection Enabled"
        onChange={toggleShipEnabledModalOn}
        data-cy="shipping-protection-enabled-toggle"
      />
      <ModalController isOpen={isShipEnabledModalOpen}>
        <ProductShippingProtectionModal
          isEnabled={Boolean(product.shipEnabled)}
          modalType="shipEnabled"
          isLoading={isLoading}
          handleCancelClick={toggleShipEnabledModalOff}
          handleConfirmClick={() => handleToggleChange('shipEnabled')}
        />
      </ModalController>
      <ModalController isOpen={isShippableModalOpen}>
        <ProductShippingProtectionModal
          isEnabled={Boolean(product.shippable)}
          modalType="shippable"
          isLoading={isLoading}
          handleCancelClick={toggleShippableModalOff}
          handleConfirmClick={() => handleToggleChange('shippable')}
        />
      </ModalController>
    </SectionContainer>
  )
}

const ContainerWithBottomMargin = styled.div({
  marginBottom: 16,
})

export { ProductShippingProtection }
