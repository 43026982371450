import { useFlags } from 'launchdarkly-react-client-sdk'
import type { FC } from 'react'
import React from 'react'
import { LDFlag } from '../../../../../constants/ld-flags'
import { UserDetails as V3UserDetails } from './v3/user-details'
import { AccountUser as V2UserDetails } from './v2/account-user'

const UserDetails: FC = () => {
  const { [LDFlag.MerchantV3UserManagement]: FF_MERCHANT_V3_USER_MGMT } = useFlags()
  return (
    <>
      {FF_MERCHANT_V3_USER_MGMT ? (
        <>
          <V3UserDetails />
        </>
      ) : (
        <>
          <V2UserDetails />
        </>
      )}
    </>
  )
}

export { UserDetails }
