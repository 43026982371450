import type { Action } from '../actions'
import { createBooleanReducer } from '../../factories'

const isLoading = createBooleanReducer<Action>(
  ['CLAIMS_FETCH_REQUEST', 'CLAIMS_SEARCH_REQUEST'],
  [
    'CLAIMS_FETCH_SUCCESS',
    'CLAIMS_FETCH_FAILURE',
    'CLAIMS_SEARCH_SUCCESS',
    'CLAIMS_SEARCH_FAILURE',
  ],
)

export default isLoading
