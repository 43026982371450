import type { VFC, ChangeEvent } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '../../tokens/colors'
import RadioGroupContext from '../radio-group/radio-group-context'

export interface RadioProps {
  label: string
  'aria-label'?: string
  subtext?: string
  value: string
  name?: string
  checked?: boolean
  disabled?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  'data-cy'?: string
}

export const Radio: VFC<RadioProps> = ({
  label,
  'aria-label': ariaLabel,
  subtext,
  value,
  name,
  checked = false,
  disabled = false,
  onChange = () => {},
  'data-cy': dataCy,
}) => {
  const radioGroup = React.useContext(RadioGroupContext)
  const externallyChecked = radioGroup?.value === value
  return (
    <StyledRadio data-cy={dataCy}>
      <Label disabled={radioGroup?.disabled || disabled}>
        <RadioContainer>
          <Input
            type="radio"
            name={radioGroup?.name || name}
            checked={externallyChecked || checked}
            value={value}
            disabled={radioGroup?.disabled || disabled}
            onChange={radioGroup?.onChange || onChange}
            aria-label={ariaLabel}
          />
        </RadioContainer>
        <LabelText>
          {label}
          {subtext && <Subtext>{subtext}</Subtext>}
        </LabelText>
      </Label>
    </StyledRadio>
  )
}

const StyledRadio = styled.div({
  display: 'flex',
})

const Input = styled.input<{
  disabled: boolean
}>(({ disabled }) => ({
  position: 'relative',
  appearance: 'none',
  width: 32,
  height: 32,
  margin: -4,
  borderRadius: '50%',
  cursor: disabled ? 'default' : 'pointer',
  flexShrink: 0,
  transition: '50ms',
  outline: 'none',
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 6,
    borderRadius: '50%',
    boxShadow: disabled
      ? `inset 0 0 0 1px ${COLOR.NEUTRAL[300]}`
      : `inset 0 0 0 1px ${COLOR.NEUTRAL[400]}`,
    backgroundColor: disabled ? COLOR.NEUTRAL.OPACITY[25] : 'transparent',
    transition: '120ms',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    inset: 16,
    borderRadius: '50%',
    backgroundColor: COLOR.WHITE,
    opacity: 0,
    transition: '120ms',
  },
  ...(!disabled && {
    '&:hover': {
      backgroundColor: COLOR.NEUTRAL.OPACITY[12],
    },
    '&:focus-visible': {
      boxShadow: `inset 0 0 0 2px ${COLOR.BLUE[700]}`,
    },
  }),
  '&:checked': {
    '&::before': {
      boxShadow: 'none',
      backgroundColor: disabled ? COLOR.NEUTRAL[600] : COLOR.BLUE[800],
    },
    '&::after': {
      inset: 12,
      opacity: 1,
    },
  },
}))

const RadioContainer = styled.span({
  display: 'block',
  paddingTop: 4,
  marginBottom: -2,
})

const Label = styled.label<{
  disabled: boolean
}>(({ disabled }) => ({
  display: 'inline-flex',
  gap: 8,
  fontSize: 15,
  lineHeight: '20px',
  color: disabled ? COLOR.NEUTRAL[600] : COLOR.NEUTRAL[1000],
  cursor: disabled ? 'default' : 'pointer',
}))

const LabelText = styled.span({
  display: 'inline-block',
  padding: '7px 0 5px',
})

const Subtext = styled.span({
  display: 'block',
  fontSize: 13,
  lineHeight: '18px',
})
