import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { put } from '@helloextend/extend-api-fetch'
import { get, post } from '../../../extend-api-fetch'
import type { CurrencyCode, Money } from '../models'
import type {
  Contract,
  ContractSearchKey,
  ContractsGetResponse,
  ContractsV2GetResponse,
  ContractsUpdateResponse,
  ContractsV2UpdateResponse,
  ContractsUpdateRequest,
  ContractsV2UpdateRequest,
  Contracts20220201UpdateRequest,
  Contract20220201GetResponse,
  Address,
} from '../models/contract'
import { ContractType } from '../models/contract'

interface AdminContractsCreateRequest {
  transactionId: string
  /** The total dollar amount for the order */
  transactionTotal?: {
    amount: number
    currencyCode?: CurrencyCode
  }
  customer: {
    name: string
    phone?: string // should be required for aon
    email: string
    billingAddress?: Address
    shippingAddress?: Address
  }
  /** Date of the transaction in millis epoch */
  transactionDate: number
  adminContractId?: string
  product: {
    referenceId: string
    purchasePrice: Money
    title?: string
    brand?: string
    imageUrl?: string
    serialNumber?: string
  }
  plan: {
    purchasePrice: Money
    planId: string
  }
}

export type ContractsResponseObject = ApiResponse<{ contracts: ContractsV2GetResponse[] }>

export async function fetch(
  id: string,
  accessToken: string,
): Promise<ApiResponse<ContractsGetResponse>> {
  return get(`/contracts/${id}`, { accessToken, apiVersion: '2021-04-01' })
}

export async function fetchV2(
  id: string,
  accessToken: string,
): Promise<ApiResponse<ContractsV2GetResponse>> {
  return get(`/contracts/${id}`, { accessToken, apiVersion: '2021-07-01' })
}

export async function fetchAll(
  accessToken: string,
): Promise<ApiResponse<{ contracts: ContractsGetResponse[] }>> {
  return searchV1(accessToken, 'status', 'live')
}

export async function searchV1(
  accessToken: string,
  searchKey: ContractSearchKey,
  value: string,
): Promise<ApiResponse<{ contracts: ContractsGetResponse[] }>> {
  return get('/contracts/search', {
    accessToken,
    qs: { [searchKey]: value },
    apiVersion: '2021-04-01',
  })
}

export async function search(
  accessToken: string,
  searchKey: ContractSearchKey,
  value: string,
  typeFilter?: ContractType[],
): Promise<ContractsResponseObject> {
  return get('/contracts/search', {
    accessToken,
    qs: {
      [searchKey]: value,
      typeFilter: typeFilter ? typeFilter.join(',') : [ContractType.PCRS].join(','),
    },
    apiVersion: 'latest',
  })
}

export type ContractsResendEmailResponseObject = ApiResponse<null>

export async function resendContractEmail(
  contractId: string,
  accessToken: string,
  forwardTo?: string,
): Promise<ContractsResendEmailResponseObject> {
  return post(`/contracts/${contractId}/resend-email`, {
    accessToken,
    body: { forwardTo },
  })
}

export async function refund(
  accessToken: string,
  storeId: string,
  contractId: string,
  commit?: boolean,
): Promise<ApiResponse<Contract>> {
  return post(`/stores/${storeId}/contracts/${contractId}/refund`, {
    accessToken,
    qs: { commit: commit !== undefined ? commit : true },
  })
}

export async function update(
  contractId: string,
  accessToken: string,
  body: ContractsUpdateRequest,
): Promise<ApiResponse<ContractsUpdateResponse>> {
  return put(`/contracts/${contractId}`, {
    accessToken,
    body,
    apiVersion: '2021-04-01',
  })
}

export async function updateV2(
  contractId: string,
  accessToken: string,
  body: ContractsV2UpdateRequest,
): Promise<ApiResponse<ContractsV2UpdateResponse>> {
  return put(`/contracts/${contractId}`, {
    accessToken,
    body,
    apiVersion: '2021-07-01',
  })
}

export async function update20220201(
  contractId: string,
  accessToken: string,
  body: Contracts20220201UpdateRequest,
): Promise<ApiResponse<Contract20220201GetResponse>> {
  return put(`/contracts/${contractId}`, {
    accessToken,
    body,
    apiVersion: '2022-02-01',
  })
}

export async function create(
  storeId: string,
  accessToken: string,
  body: AdminContractsCreateRequest,
): Promise<ApiResponse<Contract>> {
  return post(`/stores/${storeId}/contracts`, {
    accessToken,
    body,
    apiVersion: '2020-08-01',
  })
}

export async function sync(accessToken: string, contractId: string): Promise<ApiResponse> {
  return post(`/contracts/${contractId}/sync`, { accessToken })
}

export interface BatchUpdateResponse {
  synced: string[]
  failed: string[]
}

export async function batchSync(
  accessToken: string,
  contractIds: string[],
): Promise<BatchUpdateResponse> {
  const response: BatchUpdateResponse = {
    synced: [],
    failed: [],
  }

  await Promise.all(
    contractIds.map(async (contractId) => {
      try {
        const res = await sync(accessToken, contractId)
        if (res.status === 201) {
          response.synced.push(contractId)
        } else {
          response.failed.push(contractId)
        }
      } catch (e) {
        response.failed.push(contractId)
      }
    }),
  )

  return response
}
