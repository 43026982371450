import type { Servicer } from '@helloextend/extend-api-client'
import type { FormikErrors } from 'formik'
import * as Yup from 'yup'
import type { CollapsibleInfoGroupProps } from '../../../../../components/collapsible-info-group'
import { fieldMapper } from '../../../../../components/collapsible-info-group'

const schema = Yup.object()
  .shape({
    businessName: Yup.string().required('Required'),
    contact: Yup.object().shape({
      phone: Yup.string().required('Required'),
      email: Yup.string().email('Required').required('Required'),
      name: Yup.string().required('Required'),
    }),
  })
  .defined()

type Values = Yup.InferType<typeof schema>

const getFormData = <S extends Record<string, any>>(
  isEditable: boolean,
  servicer?: Servicer,
  formValues?: { [key: string]: any },
  formErrors?: FormikErrors<S>,
): CollapsibleInfoGroupProps['data'] => {
  if (!servicer) return []

  const fields = [
    { key: 'businessName', label: 'Servicer Name', editable: isEditable },
    { key: 'contact.phone', label: 'Servicer Phone Number', editable: isEditable },
    { key: 'contact.email', label: 'Servicer Email', editable: isEditable },
    { key: 'contact.name', label: 'Servicer Contact Name', editable: isEditable },
  ]

  return [
    {
      values: fieldMapper<Servicer, S>(servicer, fields, formValues, formErrors),
    },
  ]
}

export { schema, getFormData }
export type { Values }
