import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { matchPath, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import isPropValid from '@emotion/is-prop-valid'
import { NavIcon } from '../../nav-icon'
import type { NavIconProps } from '../../../tokens/nav-icons'
import { COLOR } from '../../../tokens/colors'

export interface SidebarMenuLinkProps {
  children?: React.ReactNode
  icon?: React.ElementType<NavIconProps>
  to: string
  exact?: boolean
  'data-cy'?: string
}

export const SidebarMenuLink: FC<SidebarMenuLinkProps> = ({
  children,
  icon,
  to,
  exact = false,
  'data-cy': dataCy,
}) => {
  const location = useLocation()
  const match = matchPath(location.pathname, {
    path: to,
    exact,
  })
  const isToggled = !!match
  return (
    <StyledSidebarMenuLink
      data-cy={`${dataCy}${isToggled ? ':active' : ''}`}
      to={to}
      isToggled={isToggled}
    >
      <StyledArea>
        <Content>
          {!!icon && (
            <NavIconContainer>
              <NavIcon
                icon={icon}
                filled={isToggled}
                color={isToggled ? COLOR.BLUE[700] : COLOR.NEUTRAL[800]}
              />
            </NavIconContainer>
          )}
          <Children data-cy="children">{children}</Children>
        </Content>
      </StyledArea>
    </StyledSidebarMenuLink>
  )
}

const Content = styled.div({
  display: 'flex',
  gap: 8,
  minWidth: 0,
})

const Children = styled.div({
  position: 'relative',
  maxWidth: '100%',
})

const StyledArea = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  gap: 4,
  borderRadius: 4,
  padding: '10px 16px',
})

const StyledSidebarMenuLink = styled(Link, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{
  isToggled: boolean
}>(({ isToggled }) => ({
  display: 'block',
  padding: '3px 0',
  color: isToggled ? COLOR.NEUTRAL[1000] : COLOR.NEUTRAL[700],
  '&:hover': {
    textDecoration: 'none',
  },
  '&:visited': {
    color: isToggled ? COLOR.NEUTRAL[1000] : COLOR.NEUTRAL[700],
  },
  cursor: 'pointer',
  transition: '50ms',
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: 600,
  textDecoration: 'none',
  ...(isToggled
    ? {
        WebkitTextStroke: '0.04em',
        [`& > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[12],
        },
        [`&:hover > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[25],
        },
        [`&:active > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[12],
        },
      }
    : {
        [`& > ${StyledArea}`]: {
          background: 'transparent',
        },
        [`&:hover > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[12],
        },
        [`&:active > ${StyledArea}`]: {
          background: COLOR.NEUTRAL.OPACITY[25],
        },
      }),
}))

const NavIconContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  width: 24,
  height: 24,
  margin: '-2px 4px -2px -4px',
})
