import type { AMPModalType } from '../utils'

interface WarningTextTemplate {
  editWarningNotReferenced: (title: string) => string
  editWarningReferenced: (title: string) => string
  saveAndPublishReferenced: (title: string) => string
  saveAndPublishNotReferenced: () => string
}

export function generateModalWarningText(
  modalType: AMPModalType,
  threadTitle: string,
  hasReferencedConversations: boolean,
): string {
  const warningTextTemplates: WarningTextTemplate = {
    editWarningNotReferenced: (title: string) =>
      `You are about to edit the ${title}. Only the texts of the published thread can be modified.`,
    editWarningReferenced: (title: string) =>
      `You are about to edit the ${title}. Only the texts of the published thread can be modified. ${title} is assigned to the following conversation(s):`,
    saveAndPublishReferenced: (title: string) =>
      `You are about to save and publish the changes made to the thread. ${title} is assigned to the following conversation(s):`,
    saveAndPublishNotReferenced: () =>
      `You are about to save and publish the changes made to the thread. The published threads can be used to compose conversations.`,
  }
  const referencedKey = `${modalType}Referenced`
  const nonReferencedKey = `${modalType}NotReferenced`

  return hasReferencedConversations
    ? warningTextTemplates[referencedKey as keyof WarningTextTemplate](threadTitle)
    : warningTextTemplates[nonReferencedKey as keyof WarningTextTemplate](threadTitle)
}
