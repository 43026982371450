import type { FC } from 'react'
import React from 'react'
import { connect } from 'react-redux'
import { SupportModal } from '../../components/merchant-portal/support/support-modal'
import * as selectors from '../../reducers/selectors'
import type { RootState } from '../../reducers'

interface SupportProps {
  showSupport: ReturnType<typeof selectors.getSupportIsVisible>
}

const Support: FC<SupportProps> = ({ showSupport }) => {
  if (!showSupport) {
    return null
  }
  return <SupportModal />
}

export default connect((state: RootState) => ({
  showSupport: selectors.getSupportIsVisible(state),
}))(Support)
