import { useListServiceOrderExpensesQuery } from '@helloextend/extend-api-rtk-query'
import type { Expense } from '@helloextend/extend-api-client'

const useServiceOrderExpenses = (
  serviceOrderId?: string,
): {
  expenses?: Expense[]
  isLoading: boolean
  expenseTotal?: number
} => {
  const { expenses, isFetching } = useListServiceOrderExpensesQuery(serviceOrderId ?? '', {
    skip: !serviceOrderId,
    selectFromResult: ({ data, ...rest }) => ({
      expenses: data?.filter((expense) => expense.status !== 'voided'),
      ...rest,
    }),
  })

  const expenseTotal = (expenses || ([] as Expense[])).reduce(
    (sum, current) => sum + (current?.totalCost || current?.cost?.amount || 0),
    0,
  )

  return { expenses, isLoading: isFetching, expenseTotal }
}

export { useServiceOrderExpenses }
