import type { ApiResponse } from '@helloextend/extend-api-fetch'
import { post } from '@helloextend/extend-api-fetch'

// Categories upload
export async function createCategoryImportUrl(
  storeId: string,
  accessToken: string,
  originalFilename: string,
): Promise<ApiResponse<{ url: string }>> {
  return post(`/stores/${storeId}/categories/import`, { accessToken, qs: { originalFilename } })
}
