interface BrowserInfo {
  isAndroid: boolean
  isIos: boolean
  isMobile: boolean
}

export const browser = ((): BrowserInfo => {
  const { userAgent } = navigator
  const isAndroid = /Android/i.test(userAgent)
  const isIos = /iPhone|iPad|iPod/i.test(userAgent)
  const isOpera = /Opera Mini/i.test(userAgent)
  const isWindows = /IEMobile/i.test(userAgent)
  const isMobile = Boolean(isAndroid || isIos || isOpera || isWindows)

  return { isAndroid, isIos, isMobile }
})()
