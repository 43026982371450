import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import useResizeObserver from 'use-resize-observer'
import { StackDirection, StackJustify, StackAlign } from './stack-types'
import type { BreakpointValues } from '../../hooks'
import { useBreakpoints, useThemeSafe } from '../../hooks'

interface StackProps {
  children: React.ReactNode
  direction?: BreakpointValues<StackDirection> | StackDirection
  justify?: StackJustify
  align?: StackAlign
  spacing?: BreakpointValues<number> | number
  horizontalSpacing?: BreakpointValues<number> | number
  verticalSpacing?: BreakpointValues<number> | number
  padding?: BreakpointValues<number> | number
  doesWrap?: boolean
  'data-cy'?: string
}

const StackDirectionMap: Record<string, 'row' | 'row-reverse' | 'column' | 'column-reverse'> = {
  [StackDirection.row]: 'row',
  [StackDirection.rowReverse]: 'row-reverse',
  [StackDirection.column]: 'column',
  [StackDirection.columnReverse]: 'column-reverse',
}

const StackJustifyMap: Record<string, 'flex-start' | 'center' | 'flex-end' | 'space-between'> = {
  [StackJustify.start]: 'flex-start',
  [StackJustify.center]: 'center',
  [StackJustify.end]: 'flex-end',
  [StackJustify.spaceBetween]: 'space-between',
}

const StackAlignMap: Record<string, 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline'> =
  {
    [StackAlign.start]: 'flex-start',
    [StackAlign.center]: 'center',
    [StackAlign.end]: 'flex-end',
    [StackAlign.stretch]: 'stretch',
    [StackAlign.baseline]: 'baseline',
  }

const Stack: FC<StackProps> = ({
  children,
  direction = StackDirection.row,
  justify = StackJustify.start,
  align = StackAlign.start,
  spacing = 0,
  horizontalSpacing,
  verticalSpacing,
  padding = 0,
  doesWrap = false,
  'data-cy': dataCy,
}) => {
  const { unit } = useThemeSafe()

  const [responsiveDirection, setResponsiveDirection] = useState(StackDirection.row)
  const [responsiveSpacingHorizontal, setResponsiveSpacingHorizontal] = useState(0)
  const [responsiveSpacingVertical, setResponsiveSpacingVertical] = useState(0)
  const [responsivePadding, setResponsivePadding] = useState(0)

  const { ref, width = 0 } = useResizeObserver<HTMLDivElement>()

  useBreakpoints(width, direction, setResponsiveDirection)
  useBreakpoints(width, horizontalSpacing || spacing, setResponsiveSpacingHorizontal)
  useBreakpoints(width, verticalSpacing || spacing, setResponsiveSpacingVertical)
  useBreakpoints(width, padding, setResponsivePadding)

  return (
    <StyledStack
      ref={ref}
      data-cy={dataCy}
      direction={responsiveDirection}
      justify={justify}
      align={align}
      horizontalSpacing={responsiveSpacingHorizontal}
      verticalSpacing={responsiveSpacingVertical}
      padding={responsivePadding}
      doesWrap={doesWrap}
      unit={unit}
    >
      {children}
    </StyledStack>
  )
}

const StyledStack = styled.div<{
  direction: StackDirection
  justify: StackJustify
  align: StackAlign
  horizontalSpacing: number
  verticalSpacing: number
  padding: number
  doesWrap: boolean
  unit: number
}>(
  ({ direction, justify, align, horizontalSpacing, verticalSpacing, padding, doesWrap, unit }) => ({
    display: 'flex',
    flexDirection: StackDirectionMap[direction],
    justifyContent: StackJustifyMap[justify],
    alignItems: StackAlignMap[align],
    gap: `${verticalSpacing * unit}px ${horizontalSpacing * unit}px`,
    height: '100%',
    boxSizing: 'border-box',
    padding: padding * unit,
    ...(doesWrap && {
      flexWrap: 'wrap',
    }),
  }),
)

export { Stack, StackProps }
