import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { PriceBandModelForm } from '../price-band-model-form/price-band-model-form'

type PriceBandModelProps = {
  spPlansList: string[]
  isSpListLoading?: boolean
}

const PriceBandModel: FC<PriceBandModelProps> = ({ spPlansList, isSpListLoading }) => {
  return (
    <div data-cy="price-band-model-form">
      <ContainerWithMargin>
        <PriceBandModelForm spPlansList={spPlansList} isSpListLoading={isSpListLoading} />
      </ContainerWithMargin>
    </div>
  )
}

const ContainerWithMargin = styled.div({
  marginTop: 32,
})

export { PriceBandModel }
