import { DataTable, ToastColor, Visibility } from '@helloextend/zen'
import type { DataTableAction, DataTableMenuItem } from '@helloextend/zen'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import {
  useLazyGetPlanTermsVersionLanguageUrlQuery,
  useLazyGetPlanTermsVersionLanguagesQuery,
  useListPlanTermsQuery,
} from '@helloextend/extend-api-rtk-query'
import { useHistory, useLocation } from 'react-router-dom'
import type { PlanTermsType } from '@helloextend/extend-api-client'
import { useCopyToClipboard, useStandardToast } from '@helloextend/merchants-ui'
import { findLatestVersion, tableColumns } from './table-config'

const TermsDataTable: FC = () => {
  const location = useLocation<{ isRefetch: boolean }>()
  const { data, isFetching, refetch } = useListPlanTermsQuery()
  const [getVersionLanguages] = useLazyGetPlanTermsVersionLanguagesQuery()
  const [getVersionLanguageUrl] = useLazyGetPlanTermsVersionLanguageUrlQuery()

  const history = useHistory()

  const { toastError } = useStandardToast()

  const copyToClipboard = useCopyToClipboard()

  const getTableActions = (): DataTableAction[] => {
    return [
      {
        text: 'Create Terms',
        onClick: handleCreateTerms,
        emphasis: 'medium',
        'data-cy': 'create-terms-button',
      },
    ]
  }

  const getRowActions = (row: PlanTermsType): DataTableAction[] => {
    return [
      {
        icon: Visibility,
        onClick: () => handleGetVersionUrlClick(row),
        emphasis: 'low',
        'data-cy': 'view-terms-button',
      },
    ]
  }

  const getRowMenuItems = (row: PlanTermsType): DataTableMenuItem[] => {
    return [
      ...(row.versions.length > 1
        ? [
            {
              children: 'View versions',
              onClick: () => handleViewVersionsClick(row),
              'data-cy': 'view-versions-button',
            },
          ]
        : []),
      {
        children: 'Copy link',
        onClick: () => handleCopyToClipboard(row),
        'data-cy': 'copy-link-button',
      },
      {
        children: 'Upload new version',
        onClick: () => handleUploadNewVersion(row),
        'data-cy': 'upload-new-version-button',
      },
    ]
  }

  const getVersionPdfUrl = async (row: PlanTermsType): Promise<string> => {
    const latestVersion = findLatestVersion(row.versions).version
    let languages

    try {
      languages = await getVersionLanguages({
        termsId: row.termsId,
        version: latestVersion,
      }).unwrap()
    } catch (error) {
      toastError('Something went wrong. Please try again.')
    }

    const url = ''
    if (!languages) return url

    try {
      const res = await getVersionLanguageUrl({
        version: latestVersion,
        termsId: row.termsId,
        language: languages[0].language || 'en',
      }).unwrap()
      return res.url
    } catch (error) {
      toastError('Something went wrong. Please try again.')
    }

    return url
  }

  const handleCreateTerms = (): void => {
    history.push('/admin/terms/create')
  }

  const handleUploadNewVersion = (row: PlanTermsType): void => {
    history.push(`/admin/terms/${row.termsId}/create-version`)
  }

  const handleGetVersionUrlClick = async (row: PlanTermsType): Promise<void> => {
    const url = await getVersionPdfUrl(row)
    window.open(url, '_blank')
  }

  const handleCopyToClipboard = async (row: PlanTermsType): Promise<void> => {
    const url = await getVersionPdfUrl(row)
    copyToClipboard(`${row.termsId} URL`, url, undefined, ToastColor.blue)
  }

  const handleViewVersionsClick = (row: PlanTermsType): void => {
    history.push(`/admin/terms/${row.termsId}/versions`)
  }

  // refetch data when coming back from the create terms or create new version pages to avoid stale data
  useEffect(() => {
    if (location.state?.isRefetch) {
      refetch()
    }
  }, [location.state, refetch])

  return (
    <DataTable
      data-cy="terms-data-table"
      isLoading={isFetching}
      columns={tableColumns}
      data={data || []}
      getTableActions={getTableActions}
      getRowMenuItems={getRowMenuItems}
      getRowActions={getRowActions}
    />
  )
}

export { TermsDataTable }
