import type { ServicerLocation } from '@helloextend/extend-api-client'
import type { Action } from '../actions'

export type ByIdReducerState = Record<string, ServicerLocation>

export const initialState: ByIdReducerState = {}

export default function byClaim(state = initialState, action: Action): ByIdReducerState {
  switch (action.type) {
    case 'SERVICER_LOCATIONS_CREATE_SUCCESS':
      return addServicerLocation(state, action.payload.servicerLocation)
    case 'SERVICER_LOCATIONS_RESET':
      return initialState
    default:
      return state
  }
}

function addServicerLocation(
  state: ByIdReducerState,
  servicerLocation: ServicerLocation,
): ByIdReducerState {
  return { ...state, [servicerLocation.id]: servicerLocation }
}
