import type { Action } from 'redux'
import type {
  ErrorAction,
  ErrorReducer,
  ErrorReducerState,
  ErrorActionFactory,
} from './types/error'
import type { BooleanReducer, FSAction } from './types/utility'

export function createActionFailure<T extends string>(type: T) {
  return function failure(error: Error | string, statusCode?: number): ErrorActionFactory<T> {
    return {
      type,
      payload: typeof error === 'string' ? new Error(error) : error,
      error: true,
      meta: {
        statusCode,
      },
    }
  }
}

export function createBooleanReducer<TAction extends Action>(
  onTypes: Array<TAction['type']>,
  offTypes: Array<TAction['type']>,
): BooleanReducer<TAction> {
  return function reducer(state = false, action: TAction): boolean {
    if (onTypes.includes(action.type)) {
      return true
    }
    if (offTypes.includes(action.type)) {
      return false
    }
    return state
  }
}

export function createErrorReducer<TAction extends FSAction>(
  errorTypes: Array<TAction['type']>,
  resetTypes: Array<TAction['type']>,
): ErrorReducer<TAction> {
  return function reducer(state = null, action: TAction): ErrorReducerState {
    if (errorTypes.includes(action.type)) {
      return (action as ErrorAction<string>).payload
    }
    if (resetTypes.includes(action.type)) {
      return null
    }
    return state
  }
}
