import type { AdvancedSelectOption } from '@helloextend/zen'
import type { AdvancedSelectOptionGroup } from '@helloextend/zen/src/components/fields/advanced-select/advanced-select-types'

const generateAdvancedSelectOption = (value: string): AdvancedSelectOption => ({
  display: value,
  value,
})

const generateAdvancedSelectOptions = (values: string[]): AdvancedSelectOption[] =>
  values.map(generateAdvancedSelectOption)

const generateAdvancedSelectOptionGroup = (
  label: string,
  values: string[],
): AdvancedSelectOptionGroup => ({
  label,
  options: generateAdvancedSelectOptions(values),
})

export const reportingValueMap: {
  [key: string]: Array<AdvancedSelectOption | AdvancedSelectOptionGroup>
} = {
  AdjudicationServiceType: generateAdvancedSelectOptions(['repair', 'service']),
  AdjudicationType: generateAdvancedSelectOptions(['accident', 'random_failure']),
  EndClaim: generateAdvancedSelectOptions([
    'END_VALID_CLAIM',
    'END_INVALID_CLAIM',
    'END_TRACK_CLAIM',
    'END_APPROVED_CLAIM',
    'END_INVALID_ADH_CLAIM',
  ]),
  FailureLocation: generateAdvancedSelectOptions(['in_transit', 'on_site', 'other']),
  FailureType: [
    generateAdvancedSelectOptionGroup('Battery Failure', ['battery_failure']),
    generateAdvancedSelectOptionGroup('Burn', ['burn', 'burn_other']),
    generateAdvancedSelectOptionGroup('Electrical', [
      'usb_port_broken',
      'electrical_component_failure',
      'electrical_failure',
      'electrical_power_outlet_failure',
      'electrical_wiring_connection_failure',
      'heating_mechanism_failure',
      'illumination_failure',
      'power_surge',
      'remote_failure',
      'vibrating_mechanism_failure',
    ]),
    generateAdvancedSelectOptionGroup('Fabric Failure', [
      'border_tear',
      'broken_zipper_button',
      'fabric_color_change_fade_loss',
      'foam_loss',
      'material_scrape_scratch_scuff',
      'puncture_cut_tear_rip',
      'seam_separation',
      'textile_fray_fringe_pill',
    ]),
    generateAdvancedSelectOptionGroup('Liquid', [
      'condensation_damage',
      'liquid_damage',
      'mold_mildew',
    ]),
    generateAdvancedSelectOptionGroup('Lost/Stolen', ['lost_gems_or_stones', 'lost_or_stolen']),
    generateAdvancedSelectOptionGroup('Maintenance_Service', [
      'servicing_refinishing',
      'servicing_resetting',
      'servicing_reshanking',
      'servicing_resizing',
      'servicing_rhodium_plating',
    ]),
    generateAdvancedSelectOptionGroup('Mechanical', [
      'belt_failure',
      'brake_failure',
      'cable_rope_failure',
      'mechanical_failure',
      'motor_failure',
      'overheating',
      'pedal_failure',
      'performance',
      'wheel_failure',
    ]),
    generateAdvancedSelectOptionGroup('Other', [
      'damage_breakage',
      'odors',
      'other_or_unsure',
      'tire_blowout',
      'tire_puncture',
      'tire_bubble',
    ]),
    generateAdvancedSelectOptionGroup('Scratch/Break', [
      'cracked_screen',
      'stem_case_or_crystal breakage',
    ]),
    generateAdvancedSelectOptionGroup('Shipping', [
      'invalid_tracking_number',
      'multiple_shipments_in_order',
      'no_shipments_in_order',
      'shipment_damaged',
      'shipment_lost',
      'shipment_stolen',
    ]),
    generateAdvancedSelectOptionGroup('Stain', [
      'stain',
      'stain_liquid_mark_ring',
      'stain_other',
      'stain_unknown',
    ]),
    generateAdvancedSelectOptionGroup('Structural', [
      'breakage',
      'broken_bent',
      'broken_handles_straps',
      'frame_damage',
      'jewelry_bends_breaks',
      'jewelry_chipped_cracked',
      'lost_part',
      'stretched',
      'weld_breakage',
    ]),
    generateAdvancedSelectOptionGroup('Surface Failure', [
      'chip_scratch_gouge_break',
      'cosmetic_damage',
      'delaminate_peel',
      'desilvering',
      'erode_rust',
      'finish_check_crack_bubble_peel',
      'metal_alloy_scrape_scratch_scuff',
    ]),
  ],
  FailureCause: [
    generateAdvancedSelectOptionGroup('Act of God', ['act_of_god', 'act_of_god_weather']),
    generateAdvancedSelectOptionGroup('Burn', [
      'burn_fire_candle',
      'burn_fire_house',
      'burn_fire_open_flame',
      'burn_heat',
      'burn_chemical',
      'smoke_damage',
    ]),
    generateAdvancedSelectOptionGroup('Damage', [
      'accident',
      'animal_damage',
      'collision_curb',
      'collision_motor',
      'collision_other',
      'collision_road_hazard',
      'dropped',
      'insect_damage',
      'pet_damage',
      'spring_failure',
      'vermin_damage',
    ]),
    generateAdvancedSelectOptionGroup('Degredation', [
      'product_aging',
      'wear_tear',
      'weather_exposure',
    ]),
    generateAdvancedSelectOptionGroup('Electrical', [
      'battery_charge',
      'battery_malfunction',
      'electrical_random_failure',
      'power_surge',
    ]),
    generateAdvancedSelectOptionGroup('Other/Unknown', [
      'human_error',
      'incorrect_assembly',
      'incorrect_installation',
      'maintenance',
      'other',
      'randon_failure_stopped_working_properly',
      'unboxing',
      'unknown',
    ]),
    generateAdvancedSelectOptionGroup('Stain', [
      'stain_ballpoint_ink',
      'stain_bleach',
      'stain_cosmetic_products_suntan_lotion_oil',
      'stain_food_beverage',
      'stain_human_bodily_fluid_waste',
      'stain_nail_polish_and_remover',
      'stain_perspiration_hair_body_oil',
      'stain_pet_bodily_fluid_waste',
      'stain_water_moisture_exposure',
    ]),
  ],
  JewelryType: generateAdvancedSelectOptions(['ring', 'other']),
  MissingStone: generateAdvancedSelectOptions(['no_missing_stone', 'missing_stone']),
  ProductSection: generateAdvancedSelectOptions([
    'adjustable_bed_base',
    'area_rug',
    'fabric_area_cushion_set',
    'fabric_vinyl_leather',
    'indoor_hard_surface',
    'mattress',
    'outdoor_hard_surface',
    'propane_fire_pit',
    'umbrella',
  ]),
  SelfTroubleshoot: generateAdvancedSelectOptions(['resolved', 'continue', 'ignore']),
  StoneInSetting: generateAdvancedSelectOptions(['stone_not_in_setting', 'stone_in_setting']),
  TiresAndWheelsValidation: generateAdvancedSelectOptions([
    'greater_than_three_thirty_seconds_inch',
    'less_than_three_thirty_seconds_inch',
    'damage_cosmetic',
    'damage_not_cosmetic',
  ]),
}
