import type { FC, SyntheticEvent } from 'react'
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import type {
  ProductMappedRecord,
  ExtendCategory,
  ProductSearchResponse,
  GetStoresPlanCategoryRequest,
} from '@helloextend/extend-api-rtk-query'
import {
  useLazyProductMappingsSearchQuery,
  useLazyPlanCategoryIdsQuery,
} from '@helloextend/extend-api-rtk-query'
import { useQueryStringState } from '@helloextend/client-hooks/src/use-query-string-state'
import type { FilterValues } from '@helloextend/merchants-ui'
import { Checkbox, DataReactTable, PaginationType } from '@helloextend/merchants-ui'
import styled from '@emotion/styled'
import type { MultiValue } from 'react-select'
import { Button, InlineAlert, InlineAlertColor } from '@helloextend/zen'
import type { Filters, Row, TableInstance, TableState } from 'react-table'
import type { SelectOption } from '../../../types/products'
import { productMappingColumns, getFilterOptions } from './table-config'
import { StoreInfo } from '../../../components/store-info'
import { ValidateProductModal } from './validate-product-modal'
import { useLoadOptionData, getProductMappingData } from './data-utils'
import { DropdownSelect } from './dropdown-select'

const PlanMapper: FC = () => {
  // TODO: [PUPS-1183] Refactor logic to be more maintainable and be broken down into more usable components
  const [hasFetchedProducts, setHasFetchedProducts] = useState<boolean>(false)
  const [merchantCategoryOptions, setMerchantCategoryOptions] = useState<SelectOption[]>()
  const [storeExtendCategoryList, setStoreExtendCategoryList] = useState<ExtendCategory[]>()
  const [selectedMerchantCategories, setSelectedMerchantCategories] =
    useState<MultiValue<SelectOption>>()
  const [selectedExtendCategories, setSelectedExtendCategories] =
    useState<MultiValue<SelectOption>>()
  const [selectedStore, setSelectedStore] = useState<SelectOption>()
  const [selectedWarrantyStatuses, setSelectedWarrantyStatuses] =
    useState<MultiValue<SelectOption>>()
  const [selectedMappingStatuses, setSelectedMappingStatuses] = useState<MultiValue<SelectOption>>([
    { label: 'validation_required', value: 'validation_required' },
  ])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [productsToValidate, setProductsToValidate] = useState<ProductMappedRecord[]>()
  const [sortedProductIndices, setSortedProductIndices] = useState<number[]>()
  const [indicesOnPage, setIndicesOnPage] = useState<number[]>()
  const [tableLength, setTableLength] = useState<string>()
  const [selectedProducts, setSelectedProducts] = useState<boolean[]>([])
  const [hasSelectedAll, setHasSelectedAll] = useState<boolean>(false)
  const [hasSelectedAllOnPage, setHasSelectedAllOnPage] = useState<boolean>(false)
  const [isBulkDisabled, setIsBulkDisabled] = useState<boolean>(true)
  const [pageSize, setPageSize] = useQueryStringState('pageSize', 100)
  const [filteredRowLength, setFilteredRowLength] = useState<number | null>(null)
  const [lastCheckboxIndexClicked, setLastCheckboxIndexClicked] = useState<number | null>(null)
  const [frontendFilters, setFrontendFilters] = useState<Filters<FilterValues> | []>([])

  const optionSort = (a: SelectOption, b: SelectOption): number => a.label.localeCompare(b.label)

  const [getPlanCategoryIds, { data: storePlanCategoryIds }] = useLazyPlanCategoryIdsQuery()
  const [getProductMappings, { data, isLoading, isFetching }] = useLazyProductMappingsSearchQuery()
  const productSearchResponseData: ProductSearchResponse | undefined = data
  const {
    storeOptions,
    warrantyStatusOptions,
    mappingStatusOptions,
    storeExtendCategories,
    isDropdownSelectDataLoading,
  } = useLoadOptionData()

  // send lazy call to backend API with filters to load the table data
  if (selectedStore && !hasFetchedProducts) {
    const productFilters = getProductMappingData(
      selectedStore,
      selectedMappingStatuses,
      selectedExtendCategories,
      selectedMerchantCategories,
      selectedWarrantyStatuses,
    )
    getProductMappings(productFilters)
    setHasFetchedProducts(true)
    getPlanCategoryIds({ storeId: selectedStore.value } as GetStoresPlanCategoryRequest)
  }

  const checkboxColumn = {
    Header: (table: TableInstance) => (
      <Checkbox
        label=""
        checked={hasSelectedAllOnPage}
        onChange={() => handleCheckboxHeaderClick(table)}
      />
    ),
    accessor: 'checkbox',
    width: 15,
    disableSortBy: true,
    Cell: (table: TableInstance): JSX.Element => (
      <Checkbox
        label=""
        checked={selectedProducts[table.row.index]}
        onChange={(event) => handleCheckboxClick(event, table)}
      />
    ),
  }

  const handleSelectAllClick = (): void => {
    const newSelectedProducts = selectedProducts
    if (sortedProductIndices)
      for (const index of sortedProductIndices) newSelectedProducts[index] = !hasSelectedAll
    setSelectedProducts(newSelectedProducts.map((lbl) => lbl))
    setHasSelectedAll(!hasSelectedAll)
  }

  const handleCheckboxHeaderClick = (table: TableInstance): void => {
    const sortedIndices = table.sortedRows.map((row: Row) => row.index) as number[]
    const sortedIndicesOnPage = table.page.map((row: Row) => row.index) as number[]
    let newSelectedProducts = selectedProducts
    if (hasSelectedAll) {
      newSelectedProducts = Array<boolean>(productSearchResponseData?.Items.length ?? 0).fill(false)
      setHasSelectedAll(!hasSelectedAll)
    } else {
      newSelectedProducts.forEach((_, index) => {
        if (sortedIndicesOnPage.includes(index)) newSelectedProducts[index] = !hasSelectedAllOnPage
        else newSelectedProducts[index] = false
      })
    }
    setSelectedProducts(newSelectedProducts.map((lbl) => lbl))
    setHasSelectedAllOnPage(!hasSelectedAllOnPage)
    setIndicesOnPage(sortedIndicesOnPage)
    setSortedProductIndices(sortedIndices)
  }

  const handleCheckboxClick = (event: SyntheticEvent, table: TableInstance): void => {
    const newSelectedProducts = selectedProducts
    const newSelectedValue = !selectedProducts[table.row.index]
    document.getSelection()?.removeAllRanges() // needed so that text in table is not highlighted when SHIFT+click is used
    const pointerEvent = event.nativeEvent as unknown as PointerEvent

    // range select is enabled when shift is pressed, at least one product has been previously selected,
    // a checkbox has been clicked and the clicked checkbox value was False
    if (
      pointerEvent.shiftKey &&
      selectedProducts.filter((s) => s).length > 0 &&
      lastCheckboxIndexClicked !== null &&
      newSelectedValue
    ) {
      const [start, end] =
        lastCheckboxIndexClicked > table.row.index
          ? [table.row.index, lastCheckboxIndexClicked]
          : [lastCheckboxIndexClicked, table.row.index + 1]
      const indexes = Array.from({ length: end - start }, (_, k) => k + start)
      indexes.forEach((e) => {
        newSelectedProducts[e] = newSelectedValue
      })
    } else {
      newSelectedProducts[table.row.index] = !selectedProducts[table.row.index]
    }

    setSelectedProducts(newSelectedProducts.map((lbl) => lbl))
    setSortedProductIndices(table.sortedRows.map((row: Row) => row.index) as number[])
    setLastCheckboxIndexClicked(table.row.index)
  }

  const resetProducts = (): void => {
    setSelectedProducts([])
    setHasFetchedProducts(false)
    setHasSelectedAll(false)
    setHasSelectedAllOnPage(false)
    setTableLength('0')
    setFilteredRowLength(null)
  }

  const handleBulkValidate = (): void => {
    setProductsToValidate(
      productSearchResponseData?.Items.filter((_, index) => selectedProducts[index]),
    )
    setIsModalOpen(true)
  }

  const handleModalClose = useCallback((): void => {
    setIsModalOpen(false)
  }, [])

  const handleRowsChange = useCallback(
    (rows: Array<Row<ProductMappedRecord>>, mappedFilters: Record<string, FilterValues | null>) => {
      const indices = rows.map((row) => row.index) as number[]
      setFilteredRowLength(indices.length === 0 ? null : indices.length)
      setSortedProductIndices(indices)
      setFrontendFilters(
        Object.entries(mappedFilters).map(([k, v]) => {
          return { id: k, value: v }
        }),
      )
    },
    [],
  )

  const filterOptions = useMemo(() => {
    return getFilterOptions(productSearchResponseData?.Items ?? [])
  }, [productSearchResponseData])

  const handlePagination = useCallback(
    (newPageSize: number) => {
      setPageSize(newPageSize)
    },
    [setPageSize],
  )

  useEffect(() => {
    if (productSearchResponseData) {
      if (selectedProducts) {
        setIsBulkDisabled(!selectedProducts.includes(true))
        sortedProductIndices?.forEach((value) => {
          if (!selectedProducts[value]) {
            setHasSelectedAll(false)
            if (indicesOnPage?.includes(value)) setHasSelectedAllOnPage(false)
          }
        })
      }
      if (selectedProducts.length !== productSearchResponseData.Items.length) {
        const newSelectedProducts = Array(productSearchResponseData.Items.length)
          .fill(false)
          .map(() => false)
        setSelectedProducts(newSelectedProducts)
      }
      if (!productSearchResponseData.last_evaluated_key)
        setTableLength(productSearchResponseData.Items.length.toString())
      else setTableLength(productSearchResponseData.Items.length.toString().concat('+'))
    }
  }, [selectedProducts, productSearchResponseData, pageSize, sortedProductIndices, indicesOnPage])

  const initialState = useMemo((): Partial<TableState<ProductMappedRecord>> => {
    return {
      pageSize,
      filters: frontendFilters ?? [],
    }
  }, [pageSize, frontendFilters])

  const getUniqueExtendCategoriesFromFetchedProducts = (
    productSearchResponse: ProductSearchResponse,
  ): {
    categorySet: Set<string>
    options: SelectOption[]
  } =>
    productSearchResponse.Items.reduce(
      (obj, product) => {
        const category = product.extend_category
        if (!obj.categorySet.has(category)) {
          obj.categorySet.add(category)
          obj.options.push({ label: category, value: category })
        }
        const categoryOptionObject = {
          categorySet: obj.categorySet,
          options: obj.options.sort(optionSort),
        }
        return categoryOptionObject
      },
      { categorySet: new Set<string>(), options: Array<SelectOption>() },
    )

  const uniqueExtendCategoriesFromFetchedProducts = productSearchResponseData
    ? getUniqueExtendCategoriesFromFetchedProducts(productSearchResponseData)
    : { options: [] }

  const numberSelectedProducts = selectedProducts.filter(Boolean).length.toString() ?? '0'
  const validationModalDataLoaded =
    selectedStore &&
    storeExtendCategories &&
    storeExtendCategoryList &&
    !isDropdownSelectDataLoading

  return (
    <>
      {!isDropdownSelectDataLoading && (
        <DropdownSelect
          resetProducts={resetProducts}
          setSelectedStore={setSelectedStore}
          setSelectedExtendCategories={setSelectedExtendCategories}
          setSelectedWarrantyStatuses={setSelectedWarrantyStatuses}
          setSelectedMerchantCategories={setSelectedMerchantCategories}
          setSelectedMappingStatuses={setSelectedMappingStatuses}
          setStoreExtendCategoryList={setStoreExtendCategoryList}
          setMerchantCategoryOptions={setMerchantCategoryOptions}
          uniqueExtendCategoriesFromFetchedProducts={uniqueExtendCategoriesFromFetchedProducts}
          storeOptions={storeOptions}
          warrantyStatusOptions={warrantyStatusOptions}
          mappingStatusOptions={mappingStatusOptions}
          storeExtendCategories={storeExtendCategories}
          isDropdownSelectDataLoading={isDropdownSelectDataLoading}
          selectedStore={selectedStore}
          selectedWarrantyStatuses={selectedWarrantyStatuses}
          selectedMappingStatuses={selectedMappingStatuses}
          selectedExtendCategories={selectedExtendCategories}
          merchantCategoryOptions={merchantCategoryOptions}
          selectedMerchantCategories={selectedMerchantCategories}
        />
      )}
      {validationModalDataLoaded && (
        <ValidateProductModal
          isOpen={isModalOpen}
          onClickClose={handleModalClose}
          productMappings={productsToValidate}
          storeExtendCategories={storeExtendCategoryList}
        />
      )}
      {selectedStore && (
        <StoreDataWrapper>
          <StoreInfoWrapper>
            <StoreInfo
              label={selectedStore.label}
              value={selectedStore.value}
              tableLength={tableLength}
            />
            <ValidationButtonWrapper>
              {storePlanCategoryIds && (
                <Button
                  text={'Validate '.concat(numberSelectedProducts, ' Selected Products')}
                  emphasis="medium"
                  onClick={() => {}}
                  data-cy="validate-button"
                  isDisabled
                />
              )}
              <Button
                text={'Legacy Validate '.concat(numberSelectedProducts, ' Selected Products')}
                emphasis="medium"
                isDisabled={isBulkDisabled}
                onClick={handleBulkValidate}
                data-cy="legacy-validate-button"
              />
            </ValidationButtonWrapper>
          </StoreInfoWrapper>
          {hasSelectedAllOnPage && hasSelectedAll && (
            <InlineAlert color={InlineAlertColor.blue}>
              All {numberSelectedProducts} products are selected.
            </InlineAlert>
          )}
          {hasSelectedAllOnPage && !hasSelectedAll && (
            <InlineAlert
              primaryButtonProps={{
                text: 'Select all '.concat(
                  sortedProductIndices?.length.toString() ?? '',
                  ' filtered products',
                ),
                onClick: handleSelectAllClick,
              }}
              color={InlineAlertColor.blue}
            >
              All {numberSelectedProducts} products are selected on this page.
            </InlineAlert>
          )}
          {filteredRowLength &&
            productSearchResponseData &&
            filteredRowLength < productSearchResponseData.Items.length &&
            filteredRowLength > 0 && (
              <InlineAlert color={InlineAlertColor.blue}>
                {filteredRowLength === 1
                  ? '1 product filtered'
                  : `${filteredRowLength} products filtered`}
              </InlineAlert>
            )}
          <DataReactTable
            data={productSearchResponseData?.Items ?? []}
            columns={productMappingColumns(checkboxColumn)}
            isLoading={isLoading || isFetching}
            type="products"
            setPagination={handlePagination}
            onRowLengthChange={handleRowsChange}
            paginationType={PaginationType.ENHANCED}
            initialState={initialState}
            filterOptions={filterOptions}
            isInitialReqFullfilled
          />
        </StoreDataWrapper>
      )}
    </>
  )
}

const StoreDataWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  marginTop: 24,
})

const ValidationButtonWrapper = styled.div({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  marginLeft: 10,
})

const StoreInfoWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export { PlanMapper }
