import type { Organization } from '@helloextend/extend-api-rtk-query'
import { faker } from '@faker-js/faker/locale/en'

export function generateOrganization(overrides: Partial<Organization> = {}): Organization {
  return {
    name: faker.company.name(),
    accountId: faker.datatype.uuid(),
    id: faker.datatype.uuid(),
    createdAt: new Date().getTime(),
    editedAt: new Date().getTime(),
    ...overrides,
  }
}
