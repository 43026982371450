import type { FC } from 'react'
import React, { useMemo } from 'react'
import { HeadingLarge } from '@helloextend/zen/src/tokens/typography'
import styled from '@emotion/styled'
import { Add, Button, DataTable, EmptyState, AccentIconSearchList } from '@helloextend/zen'
import type { StorePlanMapping } from '@helloextend/extend-api-client'
import { useToggle } from '@helloextend/client-hooks'
import { useListAllPlanIdsQuery } from '@helloextend/extend-api-rtk-query'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../../../../../../../../constants/ld-flags'
import { columns } from './table-config'

import { AddPlanModal } from '../add-plan-modal'

type AssignedPlansProps = {
  storeId: string
  planMappings?: StorePlanMapping[]
  isLoading: boolean
  isWholesale: boolean
}

const AssignedPlans: FC<AssignedPlansProps> = ({
  storeId,
  planMappings,
  isLoading,
  isWholesale,
}) => {
  const { [LDFlag.AssignedPlanSets]: FF_ASSIGNED_PLAN_SETS } = useFlags()

  const [isModalOpen, { on, off }] = useToggle(false)

  const { data: planIds, isLoading: isPlansListLoading } = useListAllPlanIdsQuery()

  const selectedPlanIds = useMemo(
    () => new Set(planMappings?.map((planMapping) => planMapping.planId)),
    [planMappings],
  )

  const unselectedPlanIds = useMemo(
    () => planIds?.filter((planId) => !selectedPlanIds.has(planId)),
    [planIds, selectedPlanIds],
  )

  const mappedTablePlanMappingsData = planMappings?.map((planMapping) => {
    // isWholesale is used to determine whether the button in the table row needs to be displayed
    return { ...planMapping, isWholesale }
  })

  const hasAssignedPlanSetsFF: boolean = FF_ASSIGNED_PLAN_SETS

  return (
    <>
      <HeaderContainer
        data-cy="assigned-plans-container"
        hasAssignedPlanSetsFF={hasAssignedPlanSetsFF}
      >
        {!FF_ASSIGNED_PLAN_SETS && (
          <HeadingLargeWrapper data-cy="heading">Assigned Plans</HeadingLargeWrapper>
        )}
        <Button
          text="Add Plan"
          icon={Add}
          emphasis="medium"
          onClick={on}
          data-cy="add-plan-button"
        />
      </HeaderContainer>
      {!planMappings?.length && !isPlansListLoading ? (
        <EmptyStateWrapper>
          <EmptyState
            description="Click “Add Plan” to select plans."
            heading="No plans yet"
            icon={AccentIconSearchList}
          />
        </EmptyStateWrapper>
      ) : (
        <DataTable
          data-cy="assigned-plans-table"
          data={mappedTablePlanMappingsData || []}
          columns={columns}
          isLoading={isLoading}
          hasConfigurableColumns={false}
        />
      )}
      <AddPlanModal
        storeId={storeId}
        isModalOpen={isModalOpen}
        closeModal={off}
        planIds={unselectedPlanIds}
        isLoading={isPlansListLoading}
      />
    </>
  )
}
interface HeaderContainerProps {
  hasAssignedPlanSetsFF: boolean
}

const HeadingLargeWrapper = styled(HeadingLarge)({
  fontSize: 20,
})

const HeaderContainer = styled.div<HeaderContainerProps>`
  display: flex;
  justify-content: ${(props) => (props.hasAssignedPlanSetsFF ? 'flex-end' : 'space-between')};
`

const EmptyStateWrapper = styled.div({
  marginTop: 40,
})
export { AssignedPlans }
