import styled from '@emotion/styled'
import { COLOR } from '@helloextend/client-branding'

const AlertContainer = styled.div<{ backgroundColor?: string }>(
  ({ backgroundColor = COLOR.GREEN_SOLID_100 }) => ({
    alignItems: 'center',
    backgroundColor,
    borderRadius: 6, // per design
    display: 'flex',
    fontFamily: 'Nunito Sans, sans-serif',
    height: 52,
    gap: 8,
    lineHeight: '20px',
    padding: '0 16px',
    width: '100%',
  }),
)

const AlertText = styled.p<{ color?: string }>(({ color = COLOR.GREEN_SOLID_800 }) => ({
  color,
  fontSize: 15,
  fontWeight: 400,
  lineHeight: '20px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  whiteSpace: 'nowrap',
}))

const AlertIconWrapper = styled.div({
  paddingTop: 4,
})

export { AlertContainer, AlertText, AlertIconWrapper }
