import styled from '@emotion/styled'
import type { VFC, MouseEventHandler } from 'react'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { When } from 'react-if'
import { COLOR } from '../../../tokens/colors'
import { Close, Search } from '../../../tokens/icons'
import { Input } from '../../fields'
import type { MenuProps } from '../../menu'
import { Menu } from '../../menu'
import { useDataTableContext } from '../data-table-context'
import type { SelectFilterDef, SelectFilterOption } from '../data-table-types'
import { SelectMenuItem } from './select-menu-item'

interface SelectFilterProps extends MenuProps {
  filterDef: SelectFilterDef
  hasSearch?: boolean
}

export const SelectFilter: VFC<SelectFilterProps> = ({
  direction,
  filterDef,
  hasSearch = true,
  triggerRenderer,
}) => {
  const { table } = useDataTableContext()

  const filterState = table.getState().columnFilters.find((filter) => filter.id === filterDef.id)

  const selectedValue = useMemo(
    () => (filterState?.value ? (filterState?.value as string) : ''),
    [filterState],
  )

  const handleMenuItemClick = (option: SelectFilterOption): void => {
    table.getColumn(filterDef.id).setFilterValue([option.value])
  }

  const [searchString, setSearchString] = useState('')

  const searchInputRef = useRef<HTMLInputElement>(null)

  const handleSearchChange = useCallback((e) => {
    setSearchString(e.target.value)
  }, [])

  const handleSearchClear = useCallback<MouseEventHandler<HTMLButtonElement>>((e) => {
    e.nativeEvent.stopImmediatePropagation()
    setSearchString('')
    searchInputRef.current?.focus()
  }, [])

  const filteredOptions = useMemo(
    () =>
      filterDef.options.filter((option) =>
        option.display.trim().toLowerCase().includes(searchString.toLowerCase()),
      ),
    [filterDef, searchString],
  )

  return (
    <Menu
      direction={direction}
      triggerRenderer={triggerRenderer}
      {...(hasSearch && {
        header: (
          <SearchContainer>
            <Input
              id="search"
              ref={searchInputRef}
              icon={Search}
              value={searchString}
              onChange={handleSearchChange}
              /* If actively searching a search clear button is displayed */
              {...(searchString && {
                actionButtonProps: {
                  emphasis: 'low',
                  icon: Close,
                  color: 'neutral',
                  onClick: handleSearchClear,
                },
              })}
            />
          </SearchContainer>
        ),
      })}
    >
      {filteredOptions.map((option) => (
        <SelectMenuItem
          isToggled={selectedValue[0] === option.value ?? false}
          onClick={() => handleMenuItemClick(option)}
          key={option.value}
        >
          {option.display}
        </SelectMenuItem>
      ))}
      <When condition={filteredOptions.length === 0}>
        <EmptyContainer>We can’t find a match for that...</EmptyContainer>
      </When>
    </Menu>
  )
}

const SearchContainer = styled.div({
  padding: 8,
  width: '100%',
  boxSizing: 'border-box',
})

const EmptyContainer = styled.div({
  fontSize: 14,
  lineHeight: '20px',
  padding: 12,
  color: COLOR.NEUTRAL[600],
})
