import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { Badge } from '@helloextend/zen'
import type { ClaimCode } from '@helloextend/extend-api-client'

type ErrorDenialProps = {
  validationError: ClaimCode
}

const getDenialReason = (validationError: ClaimCode): string | undefined => {
  const denialMap = {
    invalid_contract_status: 'Contract has an invalid status.',
    occurred_before_purchase: 'Incident occurred before the purchase date.',
    occurred_before_coverage: 'Incident occurred before the coverage period.',
    occurred_future_date: 'Incident occurred on a future date.',
    occurred_after_coverage: 'Incident occurred after the coverage period.',
    reported_after_coverage: 'Claim reported after the coverage period.',
    service_type_invalid: 'Service type is invalid.',
    // the following indexes are for legacy claims, and we will not display any message for them
    no_adh_coverage: '',
    no_explicit_failure_type: '',
    active_claim_found: '',
    is_tire_or_wheel: '',
    images_required: '',
  }

  return denialMap[validationError]
}

const ErrorDenial: FC<ErrorDenialProps> = ({ validationError }) => {
  const claimMessage = useMemo(() => getDenialReason(validationError), [validationError])

  return (
    <Container>
      <ClaimResult>
        <Description>AMP claim result:</Description>
        <Badge data-cy="claim-denial-badge" color="red" emphasis="medium" text="Claim Denied" />
      </ClaimResult>
      <Description data-cy="claim-denial-reason">{claimMessage}</Description>
    </Container>
  )
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
})

const ClaimResult = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 12,
})

const Description = styled.p({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '24px',
})

export { ErrorDenial }
