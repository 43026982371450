import type { FC } from 'react'
import React, { useEffect, useCallback } from 'react'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import {
  Grid,
  Button,
  GridItem,
  Input,
  useToaster,
  ToastColor,
  ToastDuration,
} from '@helloextend/zen'
import { useCreateClaimsUserMutation } from '@helloextend/extend-api-rtk-query'
import { schema } from './schema'
import type { Values } from './schema'

interface AddUserFormProps {
  handleClose: () => void
}

const AddUserForm: FC<AddUserFormProps> = ({ handleClose }) => {
  const { toast } = useToaster()
  const [addUser, { isLoading }] = useCreateClaimsUserMutation()
  const { values, errors, touched, handleSubmit, handleChange, handleBlur, resetForm, isValid } =
    useFormik<Values>({
      enableReinitialize: true,
      initialValues: { ...schema.default() },
      validationSchema: schema,
      validateOnBlur: true,
      validateOnMount: true,
      onSubmit: (body) => {
        addUser(body)
        toast({
          message: 'User added!',
          toastColor: ToastColor.blue,
          toastDuration: ToastDuration.short,
        })
        handleModalClose()
      },
    })

  const handleModalClose = useCallback((): void => {
    resetForm()
    handleClose()
  }, [resetForm, handleClose])

  useEffect(() => {
    return () => {
      resetForm()
    }
  }, [resetForm])

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        columns={{
          lg: 2,
          md: 2,
          sm: 1,
        }}
        spacing={{
          lg: 4,
          md: 2,
          sm: 1,
        }}
      >
        <Input
          id="firstName"
          value={values.firstName}
          label="First Name"
          onChange={handleChange}
          isError={touched.firstName && !!errors.firstName}
          errorFeedback={errors.firstName}
          data-cy="add-user-firstName"
        />
        <Input
          id="lastName"
          value={values.lastName}
          label="Last Name"
          onChange={handleChange}
          isError={touched.lastName && !!errors.lastName}
          errorFeedback={errors.lastName}
          data-cy="add-user-lastName"
        />
        <GridItem fillWidth>
          <Input
            id="email"
            value={values.email}
            label="Email"
            onChange={handleChange}
            onBlur={handleBlur}
            isError={touched.email && !!errors.email}
            errorFeedback={errors.email}
            data-cy="add-user-email"
          />
        </GridItem>
      </Grid>
      <ButtonGroup>
        <Button text="Cancel" emphasis="medium" onClick={handleModalClose} />
        <Button
          type="submit"
          text="Submit"
          isDisabled={!isValid}
          isProcessing={isLoading}
          data-cy="add-user-submit"
        />
      </ButtonGroup>
    </form>
  )
}

const ButtonGroup = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 16,
  marginTop: 24,
})

export { AddUserForm }
