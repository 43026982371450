import type {
  PlanTermsGetResponse,
  PlanTermsLanguage,
  PlanTermsListResponse,
  PlanTermsType,
} from '@helloextend/extend-api-client'
import { createApi } from '@reduxjs/toolkit/query/react'
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { baseQuery } from '../base-query'
import type {
  PlanTermsCreateRequest,
  PlanTermsUploadRequest,
  PlanTermsVersionLanguageUrlRequest,
  PlanTermsVersionLanguagesRequest,
} from './types'

export const plansTermsApi = createApi({
  baseQuery,
  reducerPath: 'Terms',
  tagTypes: ['Terms'],
  endpoints: (build) => ({
    listPlanTerms: build.query<PlanTermsType[], void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        let nextPageCursor
        const data: PlanTermsType[] = []

        do {
          // eslint-disable-next-line no-await-in-loop
          const res: QueryReturnValue = await fetchWithBQ(
            nextPageCursor ? `/plans/terms?cursor=${nextPageCursor}` : '/plans/terms',
          )
          if (res.error) throw res.error

          const payload = res.data as PlanTermsListResponse
          data.push(...payload.planTerms)
          nextPageCursor = payload.nextPageCursor
        } while (nextPageCursor)

        return { data }
      },
      providesTags: () => [{ type: 'Terms' }],
    }),
    createPlanTermsUploadUrl: build.mutation<PlanTermsGetResponse, PlanTermsCreateRequest>({
      query: ({ filename, language = 'en', description, termsId }) => ({
        url: `/plans/terms`,
        method: 'POST',
        body: {
          filename,
          language,
          description,
          termsId,
        },
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      invalidatesTags: ['Terms'],
    }),
    createPlanTermsVersionUploadUrl: build.mutation<PlanTermsGetResponse, PlanTermsCreateRequest>({
      query: ({ filename, language = 'en', termsId, description }) => ({
        url: `/plans/terms/${termsId}`,
        method: 'PUT',
        body: {
          filename,
          language,
          description,
        },
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
      invalidatesTags: ['Terms'],
    }),
    uploadTermsToS3Url: build.mutation<void, PlanTermsUploadRequest>({
      query: ({ url, file }) => ({
        url,
        method: 'PUT',
        body: file,
      }),
    }),
    getPlanTermsVersionLanguages: build.query<
      PlanTermsLanguage[],
      PlanTermsVersionLanguagesRequest
    >({
      query: ({ termsId, version }) => ({
        url: `/plans/terms/${termsId}/versions/${version}/languages`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
    }),
    getPlanTermsVersionLanguageUrl: build.query<
      PlanTermsGetResponse,
      PlanTermsVersionLanguageUrlRequest
    >({
      query: ({ termsId, version, language }) => ({
        url: `/plans/terms/${termsId}/versions/${version}/languages/${language}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
    }),
  }),
})

export const {
  usePrefetch: usePrefetchPlanTerms,
  useListPlanTermsQuery,
  useCreatePlanTermsUploadUrlMutation,
  useCreatePlanTermsVersionUploadUrlMutation,
  useUploadTermsToS3UrlMutation,
  useGetPlanTermsVersionLanguagesQuery,
  useLazyGetPlanTermsVersionLanguagesQuery,
  useGetPlanTermsVersionLanguageUrlQuery,
  useLazyGetPlanTermsVersionLanguageUrlQuery,
} = plansTermsApi
