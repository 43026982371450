import type { FC } from 'react'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlag } from '../../../constants/ld-flags'
import { TabMenu } from '../../../components/tab-menu/tab-menu'

enum ProductsTabsEnum {
  PlanMappingValidation = 'plan-mapping',
  CategoryMapping = 'category-mapping',
}

const PLAN_MAPPING_VALIDATION_TITLE = 'Plan Mapping Validation'
const CATEGORY_MAPPING_TITLE = 'Category Mapping'

const ProductsTabs: FC = () => {
  const history = useHistory()
  const flags = useFlags()
  const categoryMappingFF = flags[LDFlag.CategoriesManagement]

  const handleTabClick = (tab: string): void => {
    switch (tab) {
      case ProductsTabsEnum.PlanMappingValidation:
        history.push('/admin/products/plan-mapping')
        break
      case ProductsTabsEnum.CategoryMapping:
        history.push('/admin/products/category-mapping')
    }
  }

  const activeTab = useMemo((): ProductsTabsEnum => {
    const tabPath = history.location.pathname.split('/').pop()
    switch (tabPath) {
      case 'plan-mapping':
        return ProductsTabsEnum.PlanMappingValidation
      case 'category-mapping':
        return ProductsTabsEnum.CategoryMapping
      default:
        return ProductsTabsEnum.PlanMappingValidation
    }
  }, [history.location.pathname])

  // if categoryMappingFF is false, don't render the tab
  const categoryMappingTab = categoryMappingFF
    ? { text: CATEGORY_MAPPING_TITLE, key: ProductsTabsEnum.CategoryMapping }
    : { text: '', key: '' }

  return (
    <TabContent data-cy="products-management-tabs">
      <TabMenu
        tabs={[
          { text: PLAN_MAPPING_VALIDATION_TITLE, key: ProductsTabsEnum.PlanMappingValidation },
          categoryMappingTab,
        ]}
        onClick={handleTabClick}
        activeTab={activeTab}
      />
    </TabContent>
  )
}

const TabContent = styled.div({
  marginBottom: 40,
})

export { ProductsTabs }
