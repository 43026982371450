import React from 'react'
import { CellText } from '@helloextend/merchants-ui'
import type { Column, Cell } from 'react-table'
import type { Sku } from '@helloextend/extend-api-client/src/models/sku'
import { formatDateWithTimezone } from '../../../utils/date-formatting'

interface ColumnProps {
  Header: string
  accessor: string
  disableSortBy?: boolean
  width?: number
  Cell?: (cell: Cell) => JSX.Element
}
type SkusVersionColumnType = Column & ColumnProps

const skuVersionsColumns: SkusVersionColumnType[] = [
  { Header: 'version', accessor: 'version' },
  {
    Header: 'date activated',
    accessor: 'createdAt',
    Cell: ({ row, value }: Cell): JSX.Element => {
      const { createdBy } = row.original as Sku
      return (
        <CellText>
          {value ? formatDateWithTimezone(value) : '-'} by {createdBy}
        </CellText>
      )
    },
  },
  {
    Header: 'date deactivated',
    accessor: 'updatedAt',
    Cell: ({ row, value }: Cell): JSX.Element => {
      const { updatedBy } = row.original as Sku
      return (
        <CellText>
          {value ? formatDateWithTimezone(value) : '-'} by {updatedBy}
        </CellText>
      )
    },
  },
]

export { skuVersionsColumns }
