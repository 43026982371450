import { faker } from '@faker-js/faker/locale/en'
import type {
  ButtonsPrompt,
  InitialReply,
  SelectPromptOption,
  Reply,
} from '@helloextend/extend-api-client'
import { Slot, HelpTypeSlotValue } from '@helloextend/extend-api-client'
import { merge } from 'lodash/fp'

export const generateButtonsPrompt = (overrides: Partial<ButtonsPrompt> = {}): ButtonsPrompt => ({
  slot: Slot.HelpType,
  type: 'buttons',
  options: [
    generateButtonOption({ value: HelpTypeSlotValue.START_NEW_CLAIM, title: 'Start a Claim' }),
    generateButtonOption({ value: HelpTypeSlotValue.TRACK_CLAIM, title: 'Track a Claim' }),
  ],
  ...overrides,
})

const generateButtonOption = (overrides?: Partial<SelectPromptOption>): SelectPromptOption => ({
  title: 'Cool thing',
  value: faker.datatype.uuid(),
  outputText: faker.lorem.sentence(),
  redirectUrl: '',
  ...overrides,
})

export const generateInitialReply = (overrides?: Partial<InitialReply>): InitialReply => ({
  prompt: generateButtonsPrompt(),
  messages: [],
  accessToken: 'accessToken',
  ...overrides,
})

export const generateReply = (overrides: Partial<Reply> = {}): Reply => {
  return merge({
    prompt: { type: 'input', slot: Slot.FailureDescription },
    messages: [{ type: 'text', content: faker.random.words(2) }],
  })(overrides)
}
