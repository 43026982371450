import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { DatePicker } from '@helloextend/merchants-ui'
import { COLOR } from '@helloextend/client-branding'
import type { ServiceOrder } from '@helloextend/extend-api-client'
import { Button, ButtonGroup, TextArea } from '@helloextend/zen'
import { useServiceOrderStartRepairMutation } from '@helloextend/extend-api-rtk-query'
import {
  setClaimDetailsActiveView,
  setClaimDetailsToast,
} from '../../../../../../store/slices/claim-details'
import { formSchema } from './schema'
import type { Values } from './schema'

interface StartRepairFormProps {
  serviceOrder: ServiceOrder
}

const StartRepairForm: FC<StartRepairFormProps> = ({ serviceOrder }) => {
  const dispatch = useDispatch()
  const [startRepairServiceOrder, { isSuccess, isLoading }] = useServiceOrderStartRepairMutation()
  const {
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    values: { repairStartedAt, diagnosticCompletedAt, diagnosticExplanation },
  } = useFormik<Values>({
    enableReinitialize: true,
    initialValues: formSchema.default(),
    validationSchema: formSchema,
    onSubmit: (formValues: Values) => {
      if (
        !formValues.repairStartedAt ||
        !formValues.diagnosticCompletedAt ||
        !formValues.diagnosticExplanation
      )
        return
      startRepairServiceOrder({
        serviceOrderId: serviceOrder?.id ?? '',
        body: {
          repairStartedAt: formValues.repairStartedAt,
          diagnosticCompletedAt: formValues.diagnosticCompletedAt,
          diagnosticExplanation: formValues.diagnosticExplanation,
        },
      })
    },
  })

  useEffect(() => {
    if (isSuccess) {
      dispatch(setClaimDetailsToast('Repair started!'))
      dispatch(setClaimDetailsActiveView(''))
    }
  }, [isSuccess, dispatch])

  const handleRepairStartedDateChange = (selected: Date | null): void => {
    setFieldValue('repairStartedAt', selected?.getTime())
  }

  const handleDiagnosticCompletedAtChange = (selected: Date | null): void => {
    setFieldValue('diagnosticCompletedAt', selected?.getTime())
  }

  return (
    <form onSubmit={handleSubmit}>
      <GridContent>
        <GridItem>
          <LabelText>Repair Started Date</LabelText>
          <LabelWrapper invalid={Boolean(errors.repairStartedAt)}>
            <DatePicker
              maxDate={new Date()}
              onChange={handleRepairStartedDateChange}
              selected={repairStartedAt ? new Date(repairStartedAt) || null : null}
              placeHolder=""
              fullWidth
              data-cy="repair-started-at"
            />
            <ErrorMessage>{errors.repairStartedAt || ''}</ErrorMessage>
          </LabelWrapper>
        </GridItem>
        <GridItem>
          <LabelText>Diagnostic Date</LabelText>
          <LabelWrapper invalid={Boolean(errors.diagnosticCompletedAt)}>
            <DatePicker
              maxDate={new Date()}
              onChange={handleDiagnosticCompletedAtChange}
              selected={diagnosticCompletedAt ? new Date(diagnosticCompletedAt) || null : null}
              placeHolder=""
              fullWidth
              data-cy="diagnostic-date-picker"
            />
            <ErrorMessage>{errors.diagnosticCompletedAt || ''}</ErrorMessage>
          </LabelWrapper>
        </GridItem>
      </GridContent>

      <TextArea
        label="Diagnostic Explanation"
        maxLength={3000}
        onChange={handleChange}
        value={diagnosticExplanation}
        errorFeedback={errors.diagnosticExplanation}
        isError={Boolean(errors.diagnosticExplanation)}
        data-cy="diagnostic-explanation"
        id="diagnosticExplanation"
      />
      <ButtonWrapper>
        <ButtonGroup>
          <Button
            text="Cancel"
            emphasis="medium"
            data-cy="cancel-button"
            onClick={() => dispatch(setClaimDetailsActiveView(''))}
            isDisabled={isLoading}
          />
          <Button
            type="submit"
            text="Start Repair"
            data-cy="start-repair-button"
            isDisabled={isLoading}
            isProcessing={isLoading}
          />
        </ButtonGroup>
      </ButtonWrapper>
    </form>
  )
}

const LabelWrapper = styled.label<{ invalid?: boolean }>(({ invalid = false }) => ({
  fontFamily: 'Nunito Sans, sans-serif',
  display: 'flex',
  flexDirection: 'column',
  '.react-datepicker-wrapper': {
    marginTop: 5,
    input: {
      height: 40,
      border: `1px solid ${invalid ? COLOR.DANGER : COLOR.LIGHT_GRAYISH_BLUE_0} !important`,
      '&:focus': {
        border: `1px solid ${invalid ? COLOR.DANGER : COLOR.BRIGHT_BLUE} !important`,
        boxShadow: `0 0 0 1px inset ${invalid ? COLOR.DANGER : COLOR.BRIGHT_BLUE}`,
        outline: 'none',
      },
    },
  },
}))

const LabelText = styled.div({
  color: COLOR.BLACK_MUTED,
  display: 'block',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 4,
})

const GridContent = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: 20,
})

const GridItem = styled.div({})

export const ErrorMessage = styled.div({
  color: COLOR.STATE_DANGER,
  fontFamily: 'Nunito Sans',
  fontSize: 12,
  lineHeight: '16px',
  marginBottom: 16,
  marginTop: 8,
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
})

export { StartRepairForm }
