import React from 'react'
import type { FC } from 'react'
import type {
  Condition,
  NumericConditionWithValue,
  NumericCondition,
  AdjudicationRuleResponse,
  ScriptItem,
  AdjudicationRuleResponseExpanded,
} from '@helloextend/extend-api-rtk-query'
import styled from '@emotion/styled'
import { AdvancedSelect, Input } from '@helloextend/zen'
import { Paragraph } from '@helloextend/zen/src/tokens/typography'
import { identifyCondition } from '../../../../../../adjudication-management/utils'
import {
  getRuleKeySelectorValue,
  getRuleValue,
} from '../../../../../../adjudication-management/utils-ui'
import { options as comparandOptions } from '../../../../../../adjudication-management/components/claim-validation/comparand-selector/comparand-selector'
import { options as operatorOptions } from '../../../../../../adjudication-management/components/claim-validation/operator-selector/operator-selector'

type AdjudicationRowProps = {
  rule: AdjudicationRuleResponse
  condition: Condition
  index: number
}

const AdjudicationRow: FC<AdjudicationRowProps> = ({ rule, condition, index }) => {
  const conditionType = condition ? identifyCondition(condition) : null

  if (!conditionType) return null

  const isNonNumericCondition = conditionType.includes('nonNumeric')
  const isNumericCondition = conditionType === 'numericScript' || conditionType === 'numericValue'
  const isLastCondition = Boolean(rule && index === rule?.conditions.length - 1)
  const isMonetaryValue = isNumericCondition && condition?.comparand === 'totalOfPurchasePrices'

  const comparandValue = conditionType === 'numericScript' ? condition.comparand : ''
  const conditionOffset =
    conditionType === 'numericScript' ? (condition as NumericCondition)?.offset : null
  const conditionOffsetValue = (conditionOffset as number) > -1 ? 'after' : 'before'
  const operatorValue = (condition as NumericCondition)?.operator || ''
  const offsetValue = conditionOffset ? Math.abs(conditionOffset).toString() : ''

  const activeConditionScriptItem = (rule as AdjudicationRuleResponseExpanded).conditions[index]
    .script as ScriptItem
  if (!activeConditionScriptItem || activeConditionScriptItem === undefined) return null

  const { options: keyItemOptions } = getRuleKeySelectorValue(activeConditionScriptItem)
  const { options, value: selectorValue } = getRuleValue(condition, activeConditionScriptItem)

  return (
    <div data-cy={`adjudication-rule-item:${index}`}>
      <ConditionLine>
        <SelectorWrapper>
          <Paragraph>The answer of</Paragraph>
          <DropdownWrapper>
            <AdvancedSelect
              id="claim-validation-key-item"
              onChange={() => {}}
              options={keyItemOptions}
              value={keyItemOptions[0].value}
              multiple={false}
              badgePosition="start"
              data-cy="claim-validation-key-item"
              isDisabled
              isNotClearable
              hideSelectedMenuItems
            />
          </DropdownWrapper>
          <Paragraph>is</Paragraph>
          {isNonNumericCondition && (
            <DropdownWrapper>
              <AdvancedSelect
                id="claim-validation-value-item"
                onChange={() => {}}
                options={options}
                value={selectorValue}
                multiple
                badgePosition="start"
                data-cy="claim-validation-value-item"
                isDisabled
                isNotClearable
                hideSelectedMenuItems
              />
            </DropdownWrapper>
          )}
          {isNumericCondition && (
            <DropdownWrapper>
              <AdvancedSelect
                id="claim-validation-operator-item"
                onChange={() => {}}
                options={operatorOptions}
                value={operatorValue}
                multiple={false}
                badgePosition="start"
                data-cy="claim-validation-operator-item"
                isDisabled
                isNotClearable
                hideSelectedMenuItems
              />
            </DropdownWrapper>
          )}
        </SelectorWrapper>
      </ConditionLine>
      {isNumericCondition && (
        <ConditionLine>
          <SelectorWrapper>
            {conditionType === 'numericScript' ? (
              <>
                <Input
                  data-cy="claim-validation-offset-input"
                  id="claim-validation-offset-input"
                  onChange={() => {}}
                  value={offsetValue}
                  isDisabled
                />
                <Paragraph>days</Paragraph>
                <DropdownWrapper>
                  <AdvancedSelect
                    id="claim-validation-offset-item"
                    onChange={() => {}}
                    options={[
                      { display: 'After', value: 'after' },
                      { display: 'Before', value: 'before' },
                    ]}
                    value={conditionOffsetValue}
                    multiple={false}
                    badgePosition="start"
                    data-cy="claim-validation-offset-item"
                    isDisabled
                    isNotClearable
                    hideSelectedMenuItems
                  />
                </DropdownWrapper>
                <DropdownWrapper>
                  <AdvancedSelect
                    id="adjudication-comparand-item"
                    onChange={() => {}}
                    options={comparandOptions}
                    value={comparandValue ?? ''}
                    multiple={false}
                    badgePosition="start"
                    data-cy="claim-validation-comparand-item"
                    isDisabled
                    isNotClearable
                    hideSelectedMenuItems
                  />
                </DropdownWrapper>
              </>
            ) : (
              <DropdownWrapper>
                <Input
                  data-cy="claim-validation-numeric-input"
                  id="claim-validation-numeric-input"
                  onChange={() => {}}
                  value={(condition as NumericConditionWithValue).value.toString()}
                  isDisabled
                />
                {isMonetaryValue && <Paragraph>USD</Paragraph>}
              </DropdownWrapper>
            )}
          </SelectorWrapper>
        </ConditionLine>
      )}
      {!isLastCondition && <Paragraph data-cy="adjudication-rule-separator-text">and</Paragraph>}
    </div>
  )
}

const ConditionLine = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 0px',
})

const SelectorWrapper = styled.div({
  display: 'flex',
  gap: 12,
  alignItems: 'center',
  width: '100%',
})

const DropdownWrapper = styled.div({
  flex: 1,
})

export { AdjudicationRow }
