import type { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { client } from '@helloextend/extend-api-client'
import { isErrorResponse } from '@helloextend/client-utils/src/request'
import { actions } from '../actions'

type Action = ReturnType<typeof actions.fetchAll>

export default function* fetchAll(action: Action): SagaIterator {
  const { accessToken } = action.payload

  yield put(actions.fetchAllRequest())

  let cursor
  const planTerms = []

  let response
  do {
    response = yield call(client.planTerms.fetchAll, accessToken, cursor)
    if (isErrorResponse(response)) {
      yield put(actions.fetchAllFailure(response.data.message, response.status))
      return
    }

    planTerms.push(...response.data.planTerms)
    cursor = response.data.nextPageCursor
  } while (cursor)

  yield put(actions.fetchAllSuccess(planTerms))
}
