// This file is derived from react-colorful
// https://github.com/omgovich/react-colorful/blob/a85e5b36b55cae7e95c73c8ecde0bc881e8e3b1f/src/components/common/ColorPicker.tsx

import type { FC } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Hue } from './hue'
import { Saturation } from './saturation'
import type { HsvColor } from '../../../../utils/colors'
import { equalHsv, hexToHsv, hsvToHex, parseHexColor } from '../../../../utils/colors'

interface ColorPickerProps {
  color: string
  onChange: (newColor: string) => void
  onMouseUp?: () => void
  onMouseDown?: () => void
}

const ColorPicker: FC<ColorPickerProps> = ({ color, onChange, onMouseUp, onMouseDown }) => {
  const [hsv, setHsv] = useState(hexToHsv(color))
  const cache = useRef({ color, hsv })

  useEffect(() => {
    const isSame = parseHexColor(color) === parseHexColor(cache.current.color)

    if (!isSame) {
      const newHsv = hexToHsv(color)
      cache.current = { hsv: newHsv, color }
      setHsv(newHsv)
    }
  }, [color])

  useEffect(() => {
    const newHex = hsvToHex(hsv)
    const isSame =
      equalHsv(hsv, cache.current.hsv) ||
      parseHexColor(newHex) === parseHexColor(cache.current.color)

    if (!isSame) {
      cache.current = { hsv, color: newHex }
      onChange(newHex)
    }
  }, [hsv, onChange])

  const updateHsv = useCallback((params: Partial<HsvColor>) => {
    setHsv((current) => ({ ...current, ...params }))
  }, [])

  return (
    <Wrapper>
      <Hue hue={hsv.h} onChange={updateHsv} onMouseUp={onMouseUp} onMouseDown={onMouseDown} />
      <Saturation hsv={hsv} onChange={updateHsv} onMouseUp={onMouseUp} onMouseDown={onMouseDown} />
    </Wrapper>
  )
}

const Wrapper = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  userSelect: 'none',
  cursor: 'default',
  gap: 8,
})

export { ColorPicker }
