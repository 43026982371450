// Category Mapper validation errors
export const VALIDATION_MESSAGES = {
  NON_UNIQUE_WARRANTY_STATUS_IN_MAPPINGS:
    'You selected merchant categories with multiple warranty statuses. By saving the new mapping you might override the existing mapping.',
  INCONSISTENT_PLAN_SETS_FOR_EXTEND_CATEGORY: (
    category: string,
    count: number,
    storeName: string,
  ) =>
    `You are about to update the plan set mapping for the plan category: ${category}. This configuration is currently applying to ${count} merchant categories for ${storeName}.`,
}

export const NON_WARRANTABLE_INFO =
  'Extend plan category and plan set can only be selected for Warrantable merchant categories.'

export const OVERRIDE_MAPPING_DIFFERENT_PLAN_SET =
  'I am aware of the impact and still want to update the plan set mapping.'
