import type { AccountsBreadcrumbsState } from './accounts-breadcrumbs'
import { accountsBreadcrumbsReducer } from './accounts-breadcrumbs'
import type { ClaimBreadcrumbsState } from './claim-breadcrumb'
import { claimBreadcrumbsReducer } from './claim-breadcrumb'
import type { ClaimDetailsState } from './claim-details'
import { claimDetailsReducer } from './claim-details'
import type { AMPState } from './amp-slice'
import { ampReducer } from './amp-slice'

interface SlicesState {
  accountsBreadCrumbs: AccountsBreadcrumbsState
  claimBreadcrumbs: ClaimBreadcrumbsState
  claimDetails: ClaimDetailsState
  amp: AMPState
}

const slicesReducers = {
  accountsBreadCrumbs: accountsBreadcrumbsReducer,
  claimBreadcrumbs: claimBreadcrumbsReducer,
  claimDetails: claimDetailsReducer,
  amp: ampReducer,
}

export { slicesReducers, SlicesState }
