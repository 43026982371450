import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { Store } from '@helloextend/extend-api-client'
import {
  useGetMerchantServicingSettingsQuery,
  useGetServicersBySellerIdQuery,
} from '@helloextend/extend-api-rtk-query'
import { Spinner } from '@helloextend/zen'
import { Divider } from '../../../../../../../../components/divider'
import { ReplacementFulfillment } from './replacement-fulfillment'
import { ReplacementLocation } from './replacement-location'
import { PrimaryServicerRepair } from './primary-servicer-repair'
import { depotRepairSchema, onsiteRepairSchema } from './schema'

type ServicingProps = {
  store: Store
}

const Servicing: FC<ServicingProps> = ({ store }) => {
  const { servicer, isLoading } = useGetServicersBySellerIdQuery(store.id, {
    selectFromResult: ({ data, ...rest }) => ({
      servicer: data?.[0],
      ...rest,
    }),
  })

  const { data: merchantServicerSettings, isLoading: isMerchantSettingsLoading } =
    useGetMerchantServicingSettingsQuery({
      sellerId: store.id,
    })

  if (isLoading || isMerchantSettingsLoading) {
    return <Spinner />
  }

  if (!servicer || !merchantServicerSettings) {
    return null
  }

  return (
    <>
      <SelectOrDividerContainer>
        <ReplacementFulfillment
          servicer={servicer}
          merchantServicerSettings={merchantServicerSettings}
        />
      </SelectOrDividerContainer>
      <SelectOrDividerContainer>
        <Divider />
      </SelectOrDividerContainer>

      <ReplacementLocation
        servicer={servicer}
        merchantServicerSettings={merchantServicerSettings}
        storeId={store.id}
      />
      <SelectOrDividerContainer>
        <Divider />
      </SelectOrDividerContainer>

      <PrimaryServicerRepair
        sellerId={store.id}
        serviceType="repair_onsite"
        schema={onsiteRepairSchema}
        initialValues={{
          primaryOnsiteServicerId: merchantServicerSettings?.primaryOnsiteServicerId ?? '',
          autoAssignOnsiteServicer: merchantServicerSettings?.autoAssignOnsiteServicer ?? false,
        }}
        checkboxProperty="autoAssignOnsiteServicer"
        dropdownServicerIdProperty="primaryOnsiteServicerId"
        repairTypeName="Onsite"
        dataCy="primary-onsite-repair"
      />
      <SelectOrDividerContainer>
        <Divider />
      </SelectOrDividerContainer>

      <PrimaryServicerRepair
        sellerId={store.id}
        serviceType="repair_depot"
        schema={depotRepairSchema}
        initialValues={{
          primaryDepotRepairServicerId:
            merchantServicerSettings?.primaryDepotRepairServicerId ?? '',
          autoAssignDepotRepairServicer:
            merchantServicerSettings?.autoAssignDepotRepairServicer ?? false,
        }}
        checkboxProperty="autoAssignDepotRepairServicer"
        dropdownServicerIdProperty="primaryDepotRepairServicerId"
        repairTypeName="Depot"
        dataCy="primary-depot-repair"
      />
    </>
  )
}

const SelectOrDividerContainer = styled.div({
  marginBottom: 24,
})

export { Servicing }
