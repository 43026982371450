import type { FC } from 'react'
import React from 'react'
import { AnimatePresence } from '../../transitions'
import { AccordionContent } from './accordion-content'

export interface AccordionProps {
  children: React.ReactNode
  isExpanded: boolean
  transitionDurationMs?: number
}

export const Accordion: FC<AccordionProps> = ({
  children,
  isExpanded,
  transitionDurationMs = 200,
}) => {
  return (
    <AnimatePresence isPresent={isExpanded} transitionDurationMs={transitionDurationMs}>
      <AccordionContent>{children}</AccordionContent>
    </AnimatePresence>
  )
}
